<div class="d-flex flex-wrap col-12">

  <form *ngIf="updateAllocationsGroup" [formGroup]="updateAllocationsGroup" class="col-12 px-0">

    <label class="summary-row position-relative col-12 m-auto" [formGroupName]="'searchFilter'">
      <section class="filter-box">
        <label class="searchbox btn-sm w-100 mb-0 d-flex align-items-center" for="search">
          <input id="search" autocomplete="off" formControlName="accountFilter"
            [placeholder]="'fffUpdateAllocations.searchByAccountNumber' | cxTranslate">
          <div class="search-icon">
            <cx-icon class="cx-icon icon-sm" [type]="outlinedIconTypes.ICON_SEARCH"></cx-icon>
          </div>
        </label>
      </section>
    </label>

    <ngb-accordion #h="ngbAccordion" class="col-12" [activeIds]="activeIdsHistory">

      <ngb-panel *ngFor="let allocationItem of interactiveAllocationsList ; let interactiveAllocationIndex = index"
        title="Panel{{interactiveAllocationIndex}}" id="oa-panel-{{interactiveAllocationIndex}}"
        class="panel-card mb-0">

        <ng-template ngbPanelHeader let-opened="opened">
          <div class="accordion-button custom-header cursor-pointer" [class.collapsed]="!opened">

            <div class="header-container d-flex flex-wrap col-12">

              <div class="col-11 px-0">
                <p class="mb-0 title body-text-demi body-text-xl">
                  {{ 'fffUpdateAllocations.accountTitle' | cxTranslate: { accountId: allocationItem.account,
                  accountName: allocationItem.accountName } }}
                </p>
                <p class="mb-0 subtitle">{{ 'fffUpdateAllocations.attentionName' | cxTranslate: { attentionName:
                  allocationItem.attentionName } }}</p>
                <p class="mb-0 subtitle"><b>Monthly Allocation: {{ allocationItem.allocatedQty | number }}</b></p>
              </div>

              <div class="d-flex justify-content-end">
                <button class="ml-1 border-0 bg-transparent" ngbPanelToggle
                  (click)="openSectionHistory(interactiveAllocationIndex, allocationItem)">
                  <cx-icon class="cx-icon icon-sm" [class.is-expanded]="allocationItem.expand"
                    [type]="outlinedIconTypes.ICON_CHEVRON_DOWN">
                  </cx-icon>
                </button>
              </div>

            </div>
          </div>
        </ng-template>


        <ng-template ngbPanelContent>
          <div class="col-12 d-flex flex-wrap" [formGroupName]="allocationItem.account!">

            <div class="col-6 pl-0 pr-2 pt-4 pb-0">
              <label>
                <span class="body-text">{{ 'fffUpdateAllocations.currentAllocation' | cxTranslate }}</span>
                <input id="currentAllocation" autocomplete="off" class="form-control" type="text"
                  name="currentAllocation" formControlName="currentAllocation" />
              </label>
            </div>

            <div class="col-6 pl-2 pr-0 pt-4 pb-0">
              <span class="prefix-icon position-absolute">
                <cx-icon
                  *ngIf="$any(updateAllocationsGroup).controls[allocationItem.account!].get('deltaQty').value > 0"
                  class="cx-icon icon-md pe-auto success" [type]="outlinedIconTypes.ICON_ARROW_UP"></cx-icon>
                <cx-icon
                  *ngIf="$any(updateAllocationsGroup).controls[allocationItem.account!].get('deltaQty').value < 0"
                  class="cx-icon icon-md pe-auto error" [type]="outlinedIconTypes.ICON_ARROW_DOWN"></cx-icon>
              </span>

              <label>
                <span class="body-text">{{ 'fffUpdateAllocations.quantityToAdjust' | cxTranslate }}</span>
                <input id="deltaQty" autocomplete="off" class="form-control pl-5" type="number" name="deltaQty"
                  (keydown)="onKeydown($event)" (change)="changeValue($event, allocationItem.account!)"
                  formControlName="deltaQty" />
                <cx-form-errors
                  [control]="$any(updateAllocationsGroup).controls[allocationItem.account!].get('deltaQty')"></cx-form-errors>
              </label>
            </div>

            <div class="col-12 px-0 body-text">{{ allocationItem.allocatedUOM }}</div>

          </div>
        </ng-template>
      </ngb-panel>

    </ngb-accordion>

  </form>

</div>