export enum AccountNavigationEnum {
  orders = 'Orders',
  approvals = 'Approvals',
}

export enum StockEnum {
  inStock = 'inStock',
  outOfStock = 'outOfStock',
}
export enum PhoneEnum {
  tel = 'tel:',
}
export enum PathFileEnum {
  quickOrderTemplateFile = '/assets/files/quick-order/CVS_Import_Order_Template.xls',
}

export enum FileExtensionEnum {
  xlsx = '.xlsx',
  xls = '.xls',
}
export enum QuickOrderFileErrorEnum {
  error = 'error',
  empty = 'empty',
  maxLength = 'maxLength',
}
// Errors
export enum ErrorsEnum {
  errorInvalidResource = 'InvalidResourceError',
}
export enum FffAllocationNavNodes {
  currentAllocations = 'currentAllocations',
  interactiveAllocations = 'interactiveAllocations',
  allocationsHistory = 'allocationsHistory',
  newCommitmentRequest = 'newCommitmentRequest',
  approveCommitmentRequest = 'approveCommitmentRequest',
}
export enum PreBookStages {
  flu = 'vaccines-influenza',
  covid = 'vaccines-covid',
  rsv = 'vaccines-rsv',
}

export enum PreBookCategories {
  flu = 'Vaccines - Influenza',
  covid = 'Vaccines - Covid-19',
  rsv = 'Vaccines - RSV',
}
