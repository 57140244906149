import { Injectable } from '@angular/core';
import { WindowRef } from '@spartacus/core';

@Injectable({
  providedIn: 'root',
})

/**
 * Cookie Service to attach and execute custom funtion to anchor tag
 */
export class FffTrustCookieService {
  /**
   * Constructor
   * @param {windowRef} windowRef from spartacus is required
   */
  constructor(private windowRef: WindowRef) { }

  /**
   * Attaches function after searching for the anchor element in dom
   */
  public attachFunction() {
    let eleArr = this.windowRef.document.querySelectorAll('cx-generic-link > a');
    if (eleArr) {
      eleArr.forEach(ele => {
        if (ele.firstChild?.textContent?.includes('Do Not Sell My Personal Information')) {
          ele.removeAttribute('href');
          ele.addEventListener('click', this.openDialog);
          ele.classList.add('pointer-link');
        }
      });
    }
  }

  /**
   * Open Dialog function runs in the global context.
   */
  private openDialog = function () {
    /**
     * We can't use DOCUMENT from angular or WindowRef from Spartacus to point to this document
     * call since it will run in global context it won't reconize the 'this' of component.
     */
    const element = document.getElementById('onetrust-pc-sdk');
    if (element) {
      element.classList.remove('ot-hide');
      element.style.display = 'block';
      element.style.visibility = 'visible';
      element.style.opacity = '1';
    }
  };
}
