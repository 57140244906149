import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { I18nModule } from '@spartacus/core';
import { FormErrorsModule } from '@spartacus/storefront';
import { FffFileUploadComponent } from './fff-file-upload.component';



@NgModule({
  declarations: [
    FffFileUploadComponent,
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormErrorsModule,
    I18nModule,
  ],
  exports: [
    FffFileUploadComponent,
  ],
})
export class FffFileUploadModule { }
