import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FffInteractiveAllocationsEntry } from '@app/models/fff-allocations.model';
import { FFFUser } from '@app/reducers';
import { defaultPageSize } from '@config/pagination/pagination.config';
import { OUTLINED_ICON_TYPE } from '@model/fff-outline-icons.model';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { FffAllocationNavNodes } from "@shared/services/enum.service";
// import { ModalRef, ModalService } from '@spartacus/storefront';
import { Observable, of, Subject, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { FffAllocationsService } from '../fff-allocations.service';
import { FffUpdateInteractiveAllocationsDrawerComponent } from '../fff-update-interactive-allocations-drawer/fff-update-interactive-allocations-drawer.component';

@Component({
  selector: 'fff-interactive-allocations-list',
  templateUrl: './fff-interactive-allocations-list.component.html',
})
export class FffInteractiveAllocationsListComponent implements OnInit, OnDestroy {

  @Input() filters: any = {};

  private unsubscribe$ = new Subject<void>();

  userData: FFFUser | undefined;
  productManufacturer: string | undefined;
  allocationsList$: Observable<any> | undefined;
  allocationsList: FffInteractiveAllocationsEntry[] = [];
  allocationsListMerged: FffInteractiveAllocationsEntry[] = [];
  private subscription: Subscription = new Subscription();
  updateAllocationsDrawer: NgbModalRef | null = null;

  pageSize = defaultPageSize;
  columnsGrid: any = {
    desktop: '2fr 1fr 1fr 1fr 1fr 0.5fr',
    mobile: '1fr 1fr',
  };

  currentPage: number = 0;
  fffAllocationsNavLink = FffAllocationNavNodes;
  outlinedIconTypes = OUTLINED_ICON_TYPE;
  fields: any = [
    { title: 'fffInteractiveAllocations.productName', field: '' },
    { title: 'fffInteractiveAllocations.size', field: '' },
    { title: 'fffInteractiveAllocations.type', field: '' },
    { title: 'fffInteractiveAllocations.sku', field: '' },
    { title: 'fffInteractiveAllocations.ndc', field: '' },
    { title: 'fffInteractiveAllocations.action', field: '' },
  ];

  constructor(
    private fffAllocationsService: FffAllocationsService,
    public cdr: ChangeDetectorRef,
    private modalService: NgbModal,
    private storeUser: Store<FFFUser>
  ) { }

  ngOnInit(): void {
    localStorage.removeItem('allocationsInteractiveFilters');

    this.storeUser.select((state: any) => {
      return state.FFFUser;
    }).subscribe(userData => {
      if (userData.user) {
        this.userData = userData;
        this.getInventoryAllocationsData(userData.user.uid);
      }
    });

    this.fffAllocationsService.allocationsFilter$.subscribe((filtersData: any) => {
      if (filtersData && FffAllocationNavNodes.interactiveAllocations == filtersData.component) {
        this.productManufacturer = filtersData.filters.productManufacturer;
        this.currentPage = 0;
        this.getInventoryAllocationsData(this.userData?.user?.uid!, this.productManufacturer);
      }
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
    this.subscription?.unsubscribe();
  }

  pageChange(event: any): void {
    this.currentPage = event.page - 1;

    const startIndex = this.currentPage * this.pageSize;
    const endIndex = this.getEndIndex(startIndex);

    this.allocationsList$ = of({
      results: this.allocationsListMerged.slice(startIndex, endIndex),
      pagination: { totalResults: this.allocationsListMerged.length, currentPage: this.currentPage, pageSize: this.pageSize }
    });
    this.cdr.detectChanges();
  }

  private getEndIndex(startIndex: number): number {
    const maxIndex = this.allocationsListMerged.length;
    let endIndex = startIndex + this.pageSize;
    if (endIndex > maxIndex) {
      endIndex = maxIndex;
    }
    return endIndex;
  }

  getTableData(allocationsHistory: any | undefined) {
    if (allocationsHistory) {
      return { data: allocationsHistory.results, total: allocationsHistory?.pagination?.totalResults, loading: false, current: allocationsHistory?.pagination?.currentPage };
    } else {
      return { data: [], total: 0, loading: false, current: 0 };
    }
  }

  getTableSizeFromPagination(data: any): number {
    if (data && data.pagination && data.pagination.pageSize) {
      return data.pagination.pageSize;
    }
    return defaultPageSize;
  }

  private getInventoryAllocationsData(userId: string, productManufacturer?: string): void {
    this.allocationsList$ = this.fffAllocationsService.getInteractiveAllocationsList(userId, productManufacturer)
      .pipe(map((currentAllocationsList: any) => {
        this.allocationsList = currentAllocationsList.results;
        let allocationsData: FffInteractiveAllocationsEntry[] = [];
        currentAllocationsList.results.forEach((allocationItem: FffInteractiveAllocationsEntry) => {
          const hasAllocationProduct = allocationsData.some(item => item.productCode === allocationItem.productCode);
          if (!hasAllocationProduct) {
            allocationsData.push(allocationItem);
          }
        });
        this.allocationsListMerged = allocationsData;

        const startIndex = 0;
        const endIndex = this.getEndIndex(startIndex);
        return { results: this.allocationsListMerged.slice(startIndex, endIndex), pagination: { totalResults: this.allocationsListMerged.length, currentPage: this.currentPage, pageSize: this.pageSize } };
      }));
    this.cdr.detectChanges();
  }

  updateAllocations(item: any): void {
    this.updateAllocationsDrawer = this.modalService.open(FffUpdateInteractiveAllocationsDrawerComponent, {
      backdrop: 'static',
      modalDialogClass: 'fff-drawer',
      windowClass: 'fff-window',
      animation: true,
    });
    this.updateAllocationsDrawer.componentInstance.productCode = item.productCode;
    this.updateAllocationsDrawer.componentInstance.productName = item.productName;
    this.updateAllocationsDrawer.componentInstance.data = this.getItemDataToUpdateAllocation(this.allocationsList, item.productCode);
    this.updateAllocationsDrawer.result
      .then((data: any) => {
        if (data && data.refreshList) {
          this.currentPage = 0;
          this.getInventoryAllocationsData(this.userData?.user?.uid!);
        }
      })
      .catch(() => this.updateAllocationsDrawer = null);
  }

  private getItemDataToUpdateAllocation(allocationHistoryList: FffInteractiveAllocationsEntry[], productCode: string): any {
    let amountAvailable = 0;
    allocationHistoryList.forEach(allocationItem => {
      if (allocationItem.productCode == productCode) {
        amountAvailable += parseFloat(allocationItem.remainingQty)
      }
    });
    return {
      amountAvailableToReallocate: amountAvailable,
      interactiveAllocationsList: allocationHistoryList.filter(item => item.productCode == productCode),
      userData: this.userData,
    };
  }
}
