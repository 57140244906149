import { Action } from '@ngrx/store';
import { GlobalBannerMessage } from '@model/fff-global-message.model';
import { GlobalMessageType } from '@spartacus/core';

export const ADD_BANNER_MESSAGE = '[Global-banner-message] Add a Message';
export const REMOVE_BANNER_MESSAGE = '[Global-banner-message] Remove a Message';
export const REMOVE_BANNER_MESSAGES_BY_TYPE =
  '[Global-banner-message] Remove messages by type';

export class AddBannerMessage implements Action {
  readonly type = ADD_BANNER_MESSAGE;
  constructor(public payload: GlobalBannerMessage) {}
}

export class RemoveBannerMessage implements Action {
  readonly type = REMOVE_BANNER_MESSAGE;
  constructor(public payload: GlobalBannerMessage) {}
}

export class RemoveBannerMessagesByType implements Action {
  readonly type = REMOVE_BANNER_MESSAGES_BY_TYPE;
  constructor(public payload: GlobalMessageType) {}
}

export type GlobalBannerMessageAction =
  | AddBannerMessage
  | RemoveBannerMessage
  | RemoveBannerMessagesByType;
