<div class="acip-recommendation-toggle">
  <label (click)="toggleAcipRecommendation()">
    {{ 'fffPrebook.acipRecommendation' | cxTranslate }}
  </label>
</div>
<div>
  <button
    type="button"
    class="body-text-link-sm link skipLink"
    (click)="proceedToCheckout()"
    [disabled]="saving"
  >
    {{ 'fffPrebook.skipToCheckout' | cxTranslate }}
  </button>
</div>
