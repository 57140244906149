import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { NgbPopoverModule } from '@ng-bootstrap/ng-bootstrap';
import { AsmComponentsModule } from '@spartacus/asm/components';
import { I18nModule } from '@spartacus/core';
import { IconModule } from '@spartacus/storefront';
import { AsmCustomerEmulationComponent } from './asm-customer-emulation/asm-customer-emulation.component';
import { FffAsmMainUiComponent } from './asm-main-ui/asm-main-ui.component';

@NgModule({
  declarations: [FffAsmMainUiComponent],
  imports: [
    CommonModule,
    I18nModule,
    IconModule,
    HttpClientModule,
    NgbPopoverModule,
    AsmComponentsModule,
    AsmCustomerEmulationComponent
  ],
  exports: [FffAsmMainUiComponent],
})
export class FffAsmComponentsModule {}
