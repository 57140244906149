import { Component, Input } from '@angular/core';
import { Favorite } from '@app/models/fff-favorites.model';
import { FILLED_ICON_TYPE } from '@app/models/fff-filled-icons.model';
import { OUTLINED_ICON_TYPE } from '@app/models/fff-outline-icons.model';
import { FffFavoritesService } from '@enterprise/fff-common-services/fff-favorites.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'fff-add-to-favorites',
  templateUrl: './fff-add-to-favorites.component.html',
})
export class FffAddToFavoritesComponent {
  outlinedIconTypes = OUTLINED_ICON_TYPE;
  filledIconTypes = FILLED_ICON_TYPE;

  @Input() product: any | undefined;
  @Input() isPLPPage: boolean = false;

  listOfFavorites$: Observable<Favorite[]> =
    this.fffFavoritesService.listOfFavorites$;

  isFavorite$: Observable<boolean> = this.listOfFavorites$.pipe(
    map(
      listOfFavorites =>
        listOfFavorites.filter(
          (favoriteProduct: Favorite) =>
            favoriteProduct.product.code === this.product.code
        ).length > 0
    )
  );

  constructor(protected fffFavoritesService: FffFavoritesService) {}

  addToFavorites(): void {
    this.fffFavoritesService.addToFavorites(
      this.product.code,
      this.product.name
    );
  }

  removeFromFavorites(): void {
    this.fffFavoritesService.removeFromFavorites(
      this.product.code,
      this.product.name
    );
  }
}
