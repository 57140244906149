import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FffAllowOnlyNumbersDirective } from './allow-only-numbers.directive';

@NgModule({
  declarations: [FffAllowOnlyNumbersDirective],
  imports: [CommonModule],
  exports: [FffAllowOnlyNumbersDirective],
})
export class FffInputValidationUtilsModule {}
