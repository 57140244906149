import { Injectable, OnDestroy } from '@angular/core';
import {
  AuthRedirectStorageService,
  AuthStatePersistenceService,
  AuthStorageService,
  AuthToken,
  StatePersistenceService,
  UserIdService,
} from '@spartacus/core';
import { Observable, combineLatest } from 'rxjs';
import { filter, map } from 'rxjs/operators';

/**
 * Auth state synced to browser storage.
 */
export interface SyncedAuthState {
  userId?: string;
  token?: AuthToken;
  redirectUrl?: string;
}

/**
 * Responsible for saving the authorization data (userId, token, redirectUrl) in browser storage.
 */
@Injectable({
  providedIn: 'root',
})
export class FffAuthStatePersistenceService
  extends AuthStatePersistenceService
  implements OnDestroy
{
  constructor(
    protected statePersistenceService: StatePersistenceService,
    protected userIdService: UserIdService,
    protected authStorageService: AuthStorageService,
    protected authRedirectStorageService: AuthRedirectStorageService
  ) {
    super(
      statePersistenceService,
      userIdService,
      authStorageService,
      authRedirectStorageService
    );
  }

  /**
   * Gets and transforms state from different sources into the form that should
   * be saved in storage.
   */
  protected getAuthState(): Observable<SyncedAuthState> {
    return combineLatest([
      this.authStorageService.getToken().pipe(
        filter(state => !!state),
        map(state => {
          return {
            ...state,
          };
        })
      ),
      this.userIdService.getUserId(),
      this.authRedirectStorageService.getRedirectUrl(),
    ]).pipe(
      map(([authToken, userId, redirectUrl]) => {
        let token = authToken;
        if (token) {
          token = { ...token };
        }
        return { token, userId, redirectUrl };
      })
    );
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }
}
