<ng-container *ngIf="entry">
  <div class="container p-0 border-0 p-lg-3">
    <div class="row m-0">
      <div class="col-2 col-lg-1 p-0 text-center">
        <input class="checkbox checkbox-md" type="checkbox" id="showAddProduct-{{index}}"
               [checked]="entry.showAdd" (change)="entry.showAdd = !entry.showAdd">
        <label class="checkbox-label" for="showAddProduct-{{index}}"></label>
      </div>
      <div class="col-2 media-column">
        <cx-media [container]="entryMediaContainer ? entryMediaContainer: {}" format="cart"></cx-media>
      </div>
      <div class="col-8">
        <p class="mb-0 body-text-demi title">{{ entry.product?.name }}</p>
        <p class="mb-0">{{ entry.product?.manufacturer }}</p>
        <p class="mb-0">{{ 'fffReturns.ndc' | cxTranslate }} {{ entry.product?.ndc }}</p>
        <p class="mb-0">{{ 'fffReturns.sku' | cxTranslate }} {{ entry.product?.code }}</p>
        <p class="mb-0">{{ 'fffReturns.account' | cxTranslate }}: {{ entry.fffAccountNumber }}</p>
      </div>
    </div>
    <div class="row m-0">
      <div class="col-12 px-0 d-flex flex-wrap pt-5 pb-3" *ngIf="entry.showAdd">

        <div class="col-12 py-1 body-text-lg body-text-demi title">{{
          'fffReturns.fffRequest.addProductTitle' | cxTranslate }}
        </div>
        <div class="col-12 py-1 body-text-lg title">{{ 'fffReturns.fffRequest.addProductLabel' | cxTranslate
          }}
        </div>

        <div class="col-12 col-lg-10 py-2 px-0 d-flex flex-wrap" [formGroup]="entryBatchAndQuantityForm">
          <div class="d-flex flex-column col-lg-6 col-12">
            <div class="d-flex justify-content-between">
              <p class="mb-0 body-text-md">{{ 'fffReturns.fffRequest.batchNumber' | cxTranslate
                }}</p>
            </div>
            <input class="form-control form-control-md" type="text" formControlName="batchNumber">
            <cx-form-errors aria-live="assertive" aria-atomic="true"
                            [control]="$any(entryBatchAndQuantityForm).get('batchNumber')"></cx-form-errors>
          </div>

          <div class="d-flex flex-column col-lg-6 col-12">
            <div class="d-flex justify-content-between">
              <p class="mb-0 body-text-md">{{ 'fffReturns.fffRequest.quantity' | cxTranslate }}
              </p>
            </div>
            <input class="form-control form-control-md" type="text" formControlName="quantity"
                  (blur)="validateBatchQuantity($event)">
            <cx-form-errors aria-live="assertive" aria-atomic="true"
                            [control]="$any(entryBatchAndQuantityForm).get('quantity')"></cx-form-errors>
          </div>
        </div>

        <div class="d-lg-flex d-none col-lg-2 col-12 px-0 position-relative mt-3">
          <button class="btn btn-primary col-12 position-absolute mt-3"
                  [disabled]="!$any(entryBatchAndQuantityForm).valid" (click)="addProduct(entry)">
            {{ 'fffReturns.fffRequest.addProductButton' | cxTranslate }}
          </button>
        </div>

        <div class="d-flex d-lg-none col-lg-2 col-12 px-0 my-3">
          <button class="btn btn-primary col-12 mb-3" (click)="addProduct(entry)"
                  [disabled]="!$any(entryBatchAndQuantityForm).valid">
            {{ 'fffReturns.fffRequest.addProductButton' | cxTranslate }}
          </button>
        </div>

      </div>
    </div>
  </div>
</ng-container>
