import { Injectable } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { FFFShippingConditionsPayload } from '@app/models/fff-checkout.model';
import { BehaviorSubject, Observable } from 'rxjs';
import { FffCommunicationInterceptorService } from './fff-communication-interceptor.service';

@Injectable({
  providedIn: 'root'
})
export class FffCheckoutService {

  checkoutForm = new BehaviorSubject<UntypedFormGroup | undefined>(undefined);
  recurringOrdersForm = new BehaviorSubject<UntypedFormGroup | undefined>(undefined);
  hasErrors = new BehaviorSubject<boolean>(false);

  constructor(public interceptorService: FffCommunicationInterceptorService) { }

  getShippingOptions(payload: FFFShippingConditionsPayload): Observable<any> {
    const { cartId, position, uom, warehouse, date, inco } = payload;
    return this.interceptorService.getOcc(`orgUsers/current/carts/${cartId}/entries/${position}/shippingCondition?uom=${uom}&plant=${warehouse}&requestedDeliveryDate=${date}&inco=${inco}`);
  }

  getUpgradeShippingOptions(productCode: string, code?: any) {
    const httpParams = new URLSearchParams()

    if (code) {
      httpParams.set('code', code);
      httpParams.set('productSku', productCode);
    }

    return this.interceptorService.getOcc(`orgUsers/current/getUpgradeShippingOptions?` + httpParams);
  }

}
