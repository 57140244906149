<div class="minicart-footer-container">
  <div
    class="footer-subtotal w-100 text-brand-primary-80"
    *ngIf="cartData$ | async as cartData"
  >
    <ng-container *ngIf="cartData.entries && cartData.entries.length > 0">
      <div
        class="d-flex justify-content-between footer-subtitle"
        [ngClass]="{ 'mb-5': !showActions }"
      >
        <p>{{ 'fffQuickOrder.subtotal' | cxTranslate }}</p>
        <p>{{ cartData.subTotal?.formattedValue }}</p>
      </div>
      <div *ngIf="showActions" class="cx-dialog-buttons">
        <a
          (click)="navigateTo('checkout-single')"
          class="btn btn-primary btn-sm"
        >
          {{ 'fffMiniCart.checkout' | cxTranslate }}
        </a>

        <a
          *ngIf="!checkPrebookCartTrue(cartData); else prebookTrue"
          (click)="navigateTo('cart')"
          class="btn btn-secondary btn-sm"
        >
          {{
            (activeSite === BASE_URL_KEYS.MY_FLU_VACCINE
              ? 'fffQuickOrder.viewAndEditCart'
              : 'fffQuickOrder.viewAndEditSavedCart'
            ) | cxTranslate
          }}
        </a>
        <ng-template #prebookTrue>
          <a
            (click)="navigateTo('/my-cart', { prebook: 'true' })"
            class="btn btn-secondary btn-sm"
          >
            {{
              (activeSite === BASE_URL_KEYS.MY_FLU_VACCINE
                ? 'fffQuickOrder.viewAndEditCart'
                : 'fffQuickOrder.viewAndEditSavedCart'
              ) | cxTranslate
            }}
          </a>
        </ng-template>
        <a
          *ngIf="
            !checkPrebookCartTrue(cartData);
            else prebookTrueContinueShopping
          "
          class="btn btn-secondary btn-sm"
          (click)="closeDrawer()"
        >
          {{ 'fffEmptySearch.action' | cxTranslate }}
        </a>
        <ng-template #prebookTrueContinueShopping>
          <a
            (click)="
              navigateTo('/categories', {
                category: 'vaccines-influenza',
                prebook: 'true',
                'sort-relevance': 'true'
              })
            "
            class="btn btn-secondary btn-sm"
          >
            {{ 'fffEmptySearch.action' | cxTranslate }}
          </a>
        </ng-template>
      </div>
    </ng-container>
    <p
      (click)="openCustomCarePhone()"
      class="d-flex justify-content-center text-brand-primary-80 customCare-msg"
    >
      {{ 'fffQuickOrder.customCare' | cxTranslate }}
    </p>
  </div>
</div>
