<div class="container m-auto p-3 d-flex flex-wrap">
  <form [formGroup]="recurringOrdersForm" class="d-flex flex-wrap">
    <span class="col-12 px-0 summary-label body-text-demi">{{'fffRecurringOrders.title' | cxTranslate}}</span>
    <span class="col-12 px-0 ">{{'fffRecurringOrders.scheduledShipments' | cxTranslate}}</span>
    <div>
      <span class="col-12 px-0 "></span>
    </div>

    <div class="col-12 px-0">
      <input class="checkbox checkbox-sm" type="checkbox" id="isRecurringOrder" formControlName="isRecurringOrder">
      <label class="checkbox-label mx-0" for="isRecurringOrder">{{'fffRecurringOrders.isRecurringOrder' |
        cxTranslate}}</label>
    </div>

    <div *ngIf="$any(recurringOrdersForm).get('isRecurringOrder').value && !isInvalidCart">

      <div class="col-12 px-0 summary-divider my-2"></div>

      <label class="summary-row pt-2">
        <span class="body-text">{{'fffRecurringOrders.orderStartDate' | cxTranslate}}</span>
        <section class="filter-box">
          <label class="searchbox btn-sm w-100 mb-0 d-flex align-items-center justify-content-end" for="orderStartDate">
            <input id="orderStartDate" type="datetime" autocomplete="off" formControlName="orderStartDate" ngbDatepicker
              [minDate]="minDateTomorrow" #from="ngbDatepicker" readonly="true" [markDisabled]="isDisabled">
            <div class="search-icon" (click)="from.toggle()">
              <cx-icon class="cx-icon icon-sm" [type]="outlinedIconTypes.ICON_CALENDAR_EVENT"></cx-icon>
            </div>
          </label>
          <cx-form-errors [control]="$any(recurringOrdersForm).get('orderStartDate')"></cx-form-errors>
        </section>
      </label>

      <label class="summary-row" [class.pb-2]="!$any(recurringOrdersForm).get('frequency').value">
        <span class="body-text">{{'fffRecurringOrders.frequency' | cxTranslate}}</span>
        <select class="form-control form-control-md form-select" formControlName="frequency">
          <option value="WEEKLY">{{'fffRecurringOrders.weekly' | cxTranslate}}</option>
          <option value="MONTHLY">{{'fffRecurringOrders.monthly' | cxTranslate}}</option>
        </select>
        <cx-form-errors [control]="$any(recurringOrdersForm).get('frequency')"></cx-form-errors>
      </label>

      <label class="summary-row" *ngIf="'WEEKLY' == $any(recurringOrdersForm).get('frequency').value">
        <span class="body-text">{{'fffRecurringOrders.frequencyWeeklyOn' | cxTranslate}}</span>
        <input class="form-control form-control-sm" formControlName="frequencyWeeklyOn" [readOnly]="true" />
        <cx-form-errors [control]="$any(recurringOrdersForm).get('frequencyWeeklyOn')"></cx-form-errors>
      </label>

      <label class="summary-row pb-2" *ngIf="'WEEKLY' == $any(recurringOrdersForm).get('frequency').value">
        <span class="body-text">{{'fffRecurringOrders.frequencyWeeklyWeeks' | cxTranslate}}</span>
        <input class="form-control form-control-sm" autocomplete="off" aria-required="true" type="number" min="1"
          max="12" id="frequencyWeeklyWeeks" formControlName="frequencyWeeklyWeeks" />
        <cx-form-errors [control]="$any(recurringOrdersForm).get('frequencyWeeklyWeeks')"></cx-form-errors>
      </label>

      <label class="summary-row pb-2" *ngIf="'MONTHLY' == $any(recurringOrdersForm).get('frequency').value">
        <span class="body-text">{{'fffRecurringOrders.frequencyMonthlyOnDay' | cxTranslate}}</span>
        <input class="form-control form-control-sm" id="frequencyMonthlyOnDay" autocomplete="off" aria-required="true"
          min="1" max="31" type="number" formControlName="frequencyMonthlyOnDay" [readOnly]="true"/>
        <cx-form-errors [control]="$any(recurringOrdersForm).get('frequencyMonthlyOnDay')"></cx-form-errors>
      </label>

      <div class="col-12 px-0 summary-divider my-2"></div>

      <div class="d-flex flex-wrap summary-row summary-note pt-3 pb-0">
        <cx-icon class="col-1 px-0 cx-icon icon-sm" [type]="outlinedIconTypes.ICON_INFO_CIRCLE"></cx-icon>
        <span class="col-11 px-0">{{'fffRecurringOrders.infoText' | cxTranslate}}</span>
      </div>

    </div>

    <div class="col-12 px-0" *ngIf="$any(recurringOrdersForm).get('isRecurringOrder').value && isInvalidCart">
      <div class="col-12 px-0 summary-divider my-2"></div>
      <span class="error body-text-sm">{{'fffRecurringOrders.invalidCart' | cxTranslate}}</span>
    </div>

  </form>
</div>
