<div
  class="fff-tags-list-wrapper"
  [ngClass]="{ 'fff-tags-list-wrapper--multiple': !!multiple }"
>
  <div
    class="fff-tags-list"
    [ngClass]="{ 'fff-tags-list--multiple': !!multiple }"
  >
    <span
      class="fff-tag-item"
      *ngFor="let item of items | slice : 0 : truncate; let i = index"
    >
      <span>{{ item?.displayValue }}</span>
      <cx-icon
        class="icon-sm calendar-icons"
        [type]="outlinedIconTypes.ICON_X"
        (click)="removeItem(item, i)"
      ></cx-icon>
    </span>
    <span *ngIf="items.length > truncate">{{
      'fffReports.andOthers' | cxTranslate
    }}</span>
  </div>
</div>
