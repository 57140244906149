export const fffCheckoutOrderConfirmation = {
  fffCheckoutOrderConfirmation: {
    standardOrderHeaderText: 'Thank You for Your Order',
    orderRequestHeaderText: 'Thank You for Your Order Request',
    splitBillingOrderHeaderText: 'Thank You for Your Order',
    semiSplitBillingOrderHeaderText: 'Thank You for Your Order',
    recurringOrderHeaderText: 'Thank You for Your Recurring Order',
    standardOrderInfoText: `<p class="mb-0">We are sending an e-mail to {{email}} with your order confirmation and complete order details. If the email hasn't</p>
    <p>arrived within 24 hours, please check your spam folder to see if the email we sent was routed there.</p>`,
    orderRequestInfoText: `<p class="mb-0">Your items have been submitted for approval.</p>
    <p>You will receive your order confirmation upon approval.</p>`,
    splitBillingOrderInfoText: `<p class="mb-0">Your items have been submitted to your split biller, please see your quote number below.</p>
    <p>You will receive a detailed order confirmation once orders have been received from the split biller.</p>`,
    semiSplitBillingOrderInfoText: `<p class="mb-0">Your order contains both split biller items and regular items.</p>
    <p>You will receive a detailed order confirmation once orders have been received from the split biller.</p>
    <p class="mb-0">We are sending an e-mail to {{email}} with your order confirmation and complete order details. If the email hasn't</p>
    <p>arrived within 24 hours, please check your spam folder to see if the email we sent was routed there.</p>`,
    recurringOrderInfoText: `<p class="mb-0">Your items have been scheduled for a recurring shipment.</p>
    <p>You will receive an email confirming your recurring order and shipment notification before each shipment. </p>`,
    questions: `<p class="mb-0">If you have questions or concerns regarding your order,</p>
    <p>contact customer support using <a class="live-chat cursor-pointer text-underline">Live Chat</a> or by calling (800) 843-7477</p>`,
    errorHeaderTitle: 'This order requires signature prior to shipment.',
    errorHeaderText:
      'There are controlled substances in this order that require signature.',
    signOrderButton: 'Sign Order',
    recurringOrder: {
      actions: 'Actions',
      orderPlacedBy: 'Order Placed By',
      frequencyOfOrder: 'Frequency of Order',
      orderStartDate: 'Order Start Date',
      nextOrderDate: 'Next Order Date',
      recurringOrderId: 'Recurring Order',
      recurringOrders: 'Recurring Orders',
      editRecurringOrder: 'Edit Recurring Order',
      deleteRecurringOrder:
        'Your Recurring Order is currently empty. Once you click save, this Recurring Order will be canceled.',
      qty: 'Qty:',
      schedule: 'Schedule',
      noRecurringOrders: 'You have no recurring orders listed at this time',
      skip: 'Skip Next Order',
      skipOrderConfirmation:
        'Your next order for Recurring Order #{{ recurringOrder }}, on {{ skipDate }}, will now be skipped',
      cancelOrderConfirmation:
        'Are you sure you want to cancel Recurring Order #{{ recurringOrder }}?',
      close: 'Close',
      yesCancelOrder: 'Yes, Cancel Recurring Order',
      yesSkipOrder: 'Yes, Skip Recurring Order',
      cancelRecurringOrderLabel: 'Cancel Recurring Order',
      status: 'Status',
      resumed: 'Resumed',
      paused: 'Paused',
    },
    arrivalDate: 'Est. Arrival Date - ',
    cancelRecurringOrder: 'Cancel Recurring Order',
    datePlaced: 'Date Placed',
    fffDiscount: 'FFF Discount:',
    feTax: 'Federal Excise Tax',
    order: 'Order',
    po: 'PO',
    quantity: 'Qty:',
    warehouse: 'Warehouse:',
    quote: 'Quote',
    shipping: 'Shipping:',
    orderFooterExplain:
      '<p><b>IMPORTANT:</b> This is an order confirmation and not a final invoice.</p><p>For the 2024-25 Season, orders can be canceled or modified at any time on or before May 1, 2024</p><p>Your order confirmation supersedes the MyFluVaccine calendar dates if you signed a Direct Fulfillment Contract with a manufacturer.</p><p>Every order confirmation is subject to review and modification for reasons such as credit approval, changes in shipping method, product availability, or cost. This order confirmation should not be used to submit payment.  FFF is pleased to be able to accept influenza vaccine orders billed through Morris and Dickson. We will add your wholesaler’s upcharge (typically 2%) to the invoicing for your orders.</p><p>Each invoice line represents the value of the goods based on the manufacturer’s list price. The final invoice will be sent to the billing address listed on the account. After our manufacturing partners provide FFF with contract pricing for the 2024-2025 influenza season, FFF will adjust and load pricing based on your eligibility. Before shipment, a final order confirmation with confirmed pricing will be sent to your email address.</p><p>Your purchase of the above referenced Products, this Order Confirmation and your Order Acceptance is governed by FFF’s Terms and Conditions of Sale, which can be accessed here https://www.myfluvaccine.com/site/terms-of-sale.html.</p><p>The Terms and Conditions of Sale and the terms in this Order Confirmation constitute the entire agreement between FFF and Customer (you) regarding the subject matter herein and supersede all prior agreements, statements, or descriptions.  The Terms and Conditions of Sale include provisions that limit your rights, including warranty disclaimers, limitations of liability, and damages.</p><p>Please contact Wow! Customer Care at (800) 843-7477 if you have any questions.</p><p>We thank you for trusting FFF to supply your influenza vaccine needs.</p>',
  },
};

export const fffOrderHistory = {
  fffOrderHistory: {
    title: 'Orders',
    filter: 'Filter',
    order: 'Order #',
    orderDate: 'Order Date',
    status: 'Status',
    action: 'Actions',
    po: 'PO',
    total: 'Total',
    shipped: 'Shipped',
    multipleShipments: 'Multiple Shipments',
    signedSuccessfully: 'Signed Successfully',
    actions: {
      sendOrderConfirmation: 'Send Order Confirmation',
      sendOrderConfirmations: 'Send Order Confirmations',
      resendConfirmation: 'Resend Confirmation',
      print: 'Print 222',
      receiveOrder: 'Receive Order',
      signOrder: 'Sign Order',
      view: 'View Order Detail',
    },
    resendConfirmation: {
      title: 'Confirmation',
      message: 'Your order has been sent to your email address',
    },
    resendConfirmations: {
      message:
        '<strong>Success - </strong> Your order confirmations have been successfully resent.',
    },
    legisymMessages: {
      title: 'Warning',
      errorGetToken: 'You do not have access to sign CSOS Order',
      errorSignOrder:
        'We encountered an issue processing this request. Certificate is not registered, or password did not match. Please update your information and/or try again.',
    },
    enterMultipleEmails:
      'Enter recipient email addresses below separated by commas.',
    exportReport: 'Export report',
    fields: {
      orders: {
        label: 'Order Confirmations',
      },
      emails: {
        label: 'Email Addresses',
      },
    },
    clear: 'Clear',
  },
};

export const fffOrderHistoryDetail = {
  fffOrderHistoryDetail: {
    title: 'Order Details #',
    po: 'P.O #',
    items: 'Items',
    orderDate: 'Order Date',
    status: 'Status',
    arrivalDate: 'Est. Arrival Date:',
    fffDiscount: 'You saved with your discount:',
    feTax: 'Federal Excise Tax',
    shipping: 'Shipping:',
    quantity: 'Qty:',
    warehouse: 'Warehouse:',
    estimatedShipping: 'Estimated Shipping',
    actions: {
      resendConfirmation: 'Resend Confirmation',
    },
  },
};

export const fffOrderHistoryFilter = {
  fffOrderHistoryFilter: {
    apply: 'Apply',
    signed: 'Signed',
    signature: 'Requires Signature',
    csosActions: 'CSOS Actions',
    to: 'To',
    from: 'From',
    date: 'Date',
    fullyShipped: 'Fully Shipped',
    partiallyShipped: 'Partially Shipped',
    open: 'Open',
    status: 'Status',
  },
};
