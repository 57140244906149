import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { FffFormControlsModule } from '@app/shared/components/form/fff-form-controls/fff-form-controls.module';
import { FffInputValidationUtilsModule } from '@app/shared/components/input-validation-utils/input-validation-utils.module';
import { FffSharedPipesModule } from '@app/shared/pipes/fff-shared-pipes/fff-shared-pipes.module';
import { NgbToastModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { I18nModule, UrlModule } from '@spartacus/core';
import { FormErrorsModule, IconModule } from '@spartacus/storefront';
import { FffEditCreditCardDialogComponent } from './fff-edit-credit-card-dialog.component';

@NgModule({
  declarations: [FffEditCreditCardDialogComponent],
  imports: [
    UrlModule,
    CommonModule,
    I18nModule,
    IconModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    FormErrorsModule,
    NgSelectModule,
    NgbToastModule,
    FffFormControlsModule,
    FffInputValidationUtilsModule,
    FffSharedPipesModule,
  ],
  exports: [FffEditCreditCardDialogComponent],
})
export class FffEditCreditCardModule {}
