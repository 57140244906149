import { NgModule } from '@angular/core';
import { FffSearchBoxComponent } from './fff-search-box.component';
import { CommonModule } from '@angular/common';
import { ConfigModule, I18nModule, UrlModule } from '@spartacus/core';
import {IconModule, MediaModule, SearchBoxModule} from '@spartacus/storefront';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [FffSearchBoxComponent],
  imports: [
    CommonModule,
    UrlModule,
    I18nModule,
    IconModule,
    RouterModule,
    ConfigModule.withConfig({
      cmsComponents: {
        SearchBoxComponent: {
          component: FffSearchBoxComponent
        }
      }
    }),
    MediaModule,
    SearchBoxModule,
  ],
  exports: [FffSearchBoxComponent],
})
export class fffSearchBoxModule {}
