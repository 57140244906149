export const BannerComponentTypes: any = {
  HOMEPAGE_QUICK_LINKS: [
    'MFVHomepagePreBookBannerComponent',
    'MFVHomepageOrderHistoryBannerComponent',
    'FFFHomepageOrderHistoryBannerComponent',
    'FFFHomepageInvoicesBannerComponent',
    'FFFHomepageReportsBannerComponent',
    'FFFHomepageMyAccountsBannerComponent',
    'MFVHomepageReturnBannerComponent',
    'MFVHomepageFluVaccineResourcesBannerComponent',
  ],
};
