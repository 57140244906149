import { HttpErrorResponse } from '@angular/common/http';
import { AfterViewInit, Component, ElementRef } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { FFFCustomFormValidators } from '@app/shared/components/form/fff-form-validators';
import { BASE_URL_KEYS } from '@config/content/constants';
import {
  BaseSiteService,
  GlobalMessageService,
  RoutingService,
  TranslationService,
  WindowRef,
} from '@spartacus/core';
import { CustomFormValidators } from '@spartacus/storefront';
import { BehaviorSubject } from 'rxjs';
import { take } from 'rxjs/operators';
import { FILLED_ICON_TYPE } from 'src/app/models/fff-filled-icons.model';
import {
  ValidateFFFAccountData,
  ValidateFFFAccountResponse,
} from 'src/app/models/fff-register-data.model';
import { environment } from 'src/environments/environment';
import { FffCommunicationService } from '../../fff-common-services/fff-communication.service';
import { RegisterService } from '../fff-register.service';

@Component({
  selector: 'fff-register-one',
  templateUrl: './fff-register-one.component.html',
})
export class FffRegisterOneComponent implements AfterViewInit {
  isLoading$ = new BehaviorSubject(false);
  showNewPassword: boolean = false;
  showNewPasswordConfirmation: boolean = false;
  filledIconTypes = FILLED_ICON_TYPE;
  activeSite$ = this.baseSiteService.getActive().pipe(take(1));
  BASE_URL_KEYS = BASE_URL_KEYS;
  registerForm: UntypedFormGroup = this.fb.group(
    {
      fffAccountNumber: ['', [Validators.required]],
      firstName: [
        '',
        [Validators.required, FFFCustomFormValidators.nameValidator],
      ],
      lastName: [
        '',
        [Validators.required, FFFCustomFormValidators.nameValidator],
      ],
      phoneNumber: [
        { value: '', disabled: true },
        [Validators.required, FFFCustomFormValidators.phoneValidator],
      ],
      email: [
        { value: '', disabled: true },
        [Validators.required, CustomFormValidators.emailValidator],
      ],
      password: [
        { value: '', disabled: true },
        [Validators.required, FFFCustomFormValidators.passwordValidator],
      ],
      passwordConf: [{ value: '', disabled: true }, Validators.required],
    },
    {
      validators: CustomFormValidators.passwordsMustMatch(
        'password',
        'passwordConf'
      ),
    }
  );

  constructor(
    protected globalMessageService: GlobalMessageService,
    protected fb: UntypedFormBuilder,
    protected router: RoutingService,
    private registerService: RegisterService,
    private fffCommunicationService: FffCommunicationService,
    private el: ElementRef,
    public translationService: TranslationService,
    public windowRef: WindowRef,
    private baseSiteService: BaseSiteService
  ) {}

  ngAfterViewInit() {
    if (this.el.nativeElement.querySelector('.download-form-account')) {
      this.el.nativeElement
        .querySelector('.download-form-account')
        .addEventListener('click', this.downloadAccountForm.bind(this));
    }

    if (this.el.nativeElement.querySelector('.send-email')) {
      this.el.nativeElement
        .querySelector('.send-email')
        .addEventListener('click', this.openEmailApp.bind(this));
    }
  }
  isValidAccountNumber() {
    const accountNumber = this.registerForm.get('fffAccountNumber')?.value;
    return (
      accountNumber.length >= 8 && /^[Ff][A-Za-z0-9]+$/.test(accountNumber)
    );
  }

  hasABWInAccountNumber() {
    const accountNumber = this.registerForm.get('fffAccountNumber')?.value;
    return accountNumber.slice(-1).match(/[AaBbWw]/);
  }

  submitForm(): void {
    if (this.registerForm.valid) {
      this.registerUser();
    } else {
      this.registerForm.markAllAsTouched();
      this.scrollToFirstInvalidControl();
    }
  }

  private scrollToFirstInvalidControl() {
    const firstInvalidControl: HTMLElement =
      this.el.nativeElement.querySelector('form .ng-invalid');
    window.scroll({
      top: this.getTopOffset(firstInvalidControl),
      left: 0,
      behavior: 'smooth',
    });
  }

  private getTopOffset(controlEl: HTMLElement): number {
    const labelOffset = 50;
    return controlEl.getBoundingClientRect().top + window.scrollY - labelOffset;
  }

  registerUser(): void {
    const newRegisterData = this.collectDataFromRegisterForm(
      this.registerForm.value
    );
    this.registerService.registerEmail.next(newRegisterData.uid!);
    const registerUserRequest$ = this.registerService.register(newRegisterData);
    registerUserRequest$.subscribe(
      () => this.onRegisterUserSuccess(),
      (error: HttpErrorResponse) => this.handleErrors(error)
    );
  }

  private onRegisterUserSuccess(): void {
    this.router.go(['/register-verification']);
  }

  private handleErrors(error: HttpErrorResponse): void {
    const errorList = error?.error?.errors?.map((e: any) => e.message);
    const errorStatus = error?.status;

    if (409 === errorStatus || errorList.includes('User already exists')) {
      this.registerForm.get('email')?.setErrors({ emailAlreadyExists: true });
      return;
    }

    if (errorList.includes('This field is not a valid email address.')) {
      this.registerForm.get('email')?.setErrors({ cxInvalidEmail: true });
      return;
    }

    if (errorStatus.toString().startsWith('4')) {
      this.registerForm.setErrors({
        errorRegisterUser: { validationFails: errorList.join(',') },
      });
      return;
    }

    this.registerForm.setErrors({ errorRegisterUser: true });
  }

  collectDataFromRegisterForm(formData: any): ValidateFFFAccountData {
    const {
      firstName,
      lastName,
      email,
      password,
      fffAccountNumber,
      phoneNumber,
    } = formData;
    return {
      firstName,
      lastName,
      uid: email.toLowerCase(),
      password,
      fffAccountNumber: (fffAccountNumber || '')?.toUpperCase(),
      phoneNumber,
    };
  }

  openEmailApp(): void {
    if (this.windowRef.isBrowser()) {
      const emailAddress = environment.registerNewAccountEmail;
      this.windowRef.nativeWindow?.open(`mailto:${emailAddress}`, '_blank');
    }
  }

  downloadAccountForm(): void {
    this.fffCommunicationService.getConfigDetails().subscribe(res => {
      const url = res['storefront.startup.registration.creditForm.download'];
      window.open(url);
    });
  }

  enablePassword(): void {
    this.showNewPassword = !this.showNewPassword;
  }

  enablePasswordConfirmation(): void {
    this.showNewPasswordConfirmation = !this.showNewPasswordConfirmation;
  }

  validateFFFAccount(): void {
    if (this.registerForm.get('fffAccountNumber')?.valid) {
      if (!this.isValidAccountNumber()) {
        this.registerForm
          .get('fffAccountNumber')
          ?.setErrors({ invalidAccountNumber: true });
      } else if (this.hasABWInAccountNumber()) {
        this.registerForm
          .get('fffAccountNumber')
          ?.setErrors({ abwMessage: true });
      } else {
        const validAccount$ = this.registerService.validateFFFAccount(
          this.registerForm.get('fffAccountNumber')?.value?.toUpperCase()
        );
        validAccount$.subscribe((response: ValidateFFFAccountResponse) => {
          if (response.active) {
            this.registerForm.get('firstName')?.enable();
            this.registerForm.get('lastName')?.enable();
            this.registerForm.get('phoneNumber')?.enable();
            this.registerForm.get('email')?.enable();
            this.registerForm.get('password')?.enable();
            this.registerForm.get('passwordConf')?.enable();
            this.registerForm.get('fffAccountNumber')?.setErrors(null);
          } else {
            // show error message
            this.registerForm
              .get('fffAccountNumber')
              ?.setErrors({ invalidFFFAccount: true });
          }
        });
      }
    }
  }
}
