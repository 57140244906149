import { NgModule } from '@angular/core';
import { CmsConfig, ConfigModule } from '@spartacus/core';

@NgModule({
  imports: [
    ConfigModule.withConfig({
      cmsComponents: {
        // BreadcrumbComponent: {
        //   component: EmptyContentComponent,
        //   disableSSR: true,
        // },
      },
    } as CmsConfig),
  ]
})
export class FffNavigationBarModule { }
