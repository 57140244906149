<div class="product-more-info">
  <button
    type="button"
    class="btn btn-outline-primary"
    (click)="collapse.toggle(); getProductDetails()"
    [attr.aria-expanded]="!isCollapsed"
    aria-controls="collapseExample"
  >
    <span class="heading">{{
      'fffProduct.lblProductDetailHeading' | cxTranslate
    }}</span>
  </button>

  <div
    [class.is-loading]="isLoading$ | async"
    #collapse="ngbCollapse"
    [(ngbCollapse)]="isCollapsed"
  >
    <div class="card">
      <div class="card-body">
        <div class="row" *ngIf="product?.customerAllocation">
          <div class="feature col-md-6" *ngIf="isLoggedIn">
            <div class="annual-commitment">
              {{ 'fffProduct.lblAnnualCommitment' | cxTranslate }}
              {{ annualCommitment }}
            </div>
            <div class="annual-purchase">
              {{ 'fffProduct.lblPurchased' | cxTranslate }} {{ totalPurchase }}
            </div>
            <div class="mb-3">
              <fff-progress-bar
                [progress]="totalPurchase"
                [total]="annualCommitment"
              ></fff-progress-bar>
            </div>
          </div>
        </div>
        <div class="row pt-4">
          <div class="feature col-md-7">
            <div
              class="section-info"
              *ngIf="product?.summary || product?.description"
            >
              <h2
                class="h4 py-1"
                [innerHTML]="product.summary | fffSafeHtml"
              ></h2>
              <div [innerHTML]="product.description | fffSafeHtml"></div>
              <ng-template #NoPrescribingInfo>
                <p>
                  {{ 'fffProduct.noDataAvailable' | cxTranslate }}
                  {{ 'fffProduct.headingPrescribingInfo' | cxTranslate }}
                </p>
              </ng-template>
            </div>
            <div class="section-info">
              <h2 class="h4 py-1">
                {{ 'fffProduct.headingNdcInfo' | cxTranslate }}
              </h2>
              <ng-container *ngIf="product?.ndcInfo; else NoNdcInfo">
                <ul class="inline-flex classification-data">
                  <li>
                    {{ 'fffProduct.lblNdcDrugForm' | cxTranslate
                    }}<span>{{ product.ndcInfo?.ndcDrugForm }}</span>
                  </li>
                  <li>
                    {{ 'fffProduct.lblNdcStrength' | cxTranslate
                    }}<span>{{ product.ndcInfo?.ndcStrength }}</span>
                  </li>
                  <li>
                    {{ 'fffProduct.lblNdcDrugRoute' | cxTranslate
                    }}<span>{{ product.ndcInfo?.ndcDrugRoute }}</span>
                  </li>
                  <li>
                    {{ 'fffProduct.lblNdcName' | cxTranslate
                    }}<span>{{ product.ndcInfo?.ndcName }}</span>
                  </li>
                </ul>
              </ng-container>
              <ng-template #NoNdcInfo>
                <p>
                  {{ 'fffProduct.noDataAvailable' | cxTranslate }}
                  {{ 'fffProduct.headingNdcInfo' | cxTranslate }}
                </p>
              </ng-template>
            </div>
          </div>
          <div class="feature col-md pl-0">
            <div class="section-info">
              <h2 class="h4 py-1">
                {{ 'fffProduct.headingIndications' | cxTranslate }}
              </h2>
              <ng-container
                *ngIf="product?.indications; else NoProductIndications"
              >
                <div [innerHTML]="product.indications | fffSafeHtml"></div>
              </ng-container>
              <ng-template #NoProductIndications>
                <p>
                  {{ 'fffProduct.noDataAvailable' | cxTranslate }}
                  {{ 'fffProduct.headingIndications' | cxTranslate }}
                </p>
              </ng-template>
            </div>
            <div class="section-info">
              <h2 class="h4 py-1">
                {{ 'fffProduct.headingShippingDetails' | cxTranslate }}
              </h2>
              <p>{{ 'fffProduct.descShippingDetails' | cxTranslate }}</p>
            </div>
          </div>
        </div>
        <!--Prescribing Information -->
        <div class="row py-4">
          <div class="feature col-md d-flex justify-content-center">
            <a
              class="button-prescription"
              [attr.href]="
                product.prescribingInformation
                  ? product.prescribingInformation
                  : '/'
              "
              target="_blank"
              >{{ 'fffProduct.lblPrescribingInfoBtn' | cxTranslate }}</a
            >
          </div>
        </div>
      </div>
    </div>
    <ng-container *ngIf="isLoading$ | async">
      <fff-content-loader
        containerClassName="height-large"
        bodyClassName="d-flex price-row pt-2 pb-2 pl-3 pr-3"
      >
      </fff-content-loader>
    </ng-container>
  </div>
</div>
