import { Directive, ElementRef, Input, Renderer2 } from '@angular/core';

@Directive({
  selector: '[fffDisableDiv]',
})
export class DisableDivDirective {
  @Input() isDisabled!: boolean;

  constructor(private el: ElementRef, private renderer: Renderer2) {}

  ngOnInit() {
    if (this.isDisabled) {
      this.renderer.setStyle(this.el.nativeElement, 'pointer-events', 'none');
      this.renderer.setStyle(this.el.nativeElement, 'opacity', '0.6');
    }
  }
}
