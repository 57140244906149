import { FFFActiveCartService } from '@app/fff-enterprise/fff-custom-cart/fff-active-cart-service';
import { FFFCartEntryAdapter } from '@app/fff-enterprise/fff-custom-cart/fff-cart-entry.adapter';
import { FFFCartEntryConnector } from '@app/fff-enterprise/fff-custom-cart/fff-cart-entry.connector';
import { FFFCartEntryEffects } from '@app/fff-enterprise/fff-custom-cart/fff-cart-entry.effect';
import { CartModificationNormalizer } from '@app/fff-enterprise/fff-custom-cart/fff-cart-normalizer';
import { FFFMultiCartService } from '@app/fff-enterprise/fff-custom-cart/fff-multi-cart-service';
import { FFFOccCartEntryAdapter } from '@app/fff-enterprise/fff-custom-cart/fff-occ-cart-entry.adapter';
import { FffOccConfig } from '@app/models/fff-occ-config.model';
import {
  ActiveCartService,
  CartEntryConnector,
  MultiCartService,
} from '@spartacus/cart/base/core';
import { CART_MODIFICATION_NORMALIZER } from '@spartacus/cart/base/root';
// import { , CartEntryConnector, CART_MODIFICATION_NORMALIZER, MultiCartService, OccConfig } from '@spartacus/core';

export const customCartConfig: FffOccConfig = {
  backend: {
    occ: {
      endpoints: {
        /* eslint-disable max-len */
        carts:
          'users/${userId}/carts?fields=carts(DEFAULT,potentialProductPromotions,appliedProductPromotions,potentialOrderPromotions,appliedOrderPromotions,entries(totalPrice(formattedValue),product(images(FULL),stock(FULL)),basePrice(formattedValue,value),updateable),totalPrice(formattedValue),totalItems,totalPriceWithTax(formattedValue),totalDiscounts(value,formattedValue),subTotal(formattedValue),deliveryItemsQuantity,deliveryCost(formattedValue),totalTax(formattedValue, value),pickupItemsQuantity,net,appliedVouchers,productDiscounts(formattedValue),user,saveTime,name,description)',
        cart: 'users/${userId}/carts/${cartId}?fields=DEFAULT,potentialProductPromotions,appliedProductPromotions,potentialOrderPromotions,appliedOrderPromotions,entries(totalPrice(formattedValue),product(images(FULL),stock(FULL)),basePrice(formattedValue,value),updateable),totalPrice(formattedValue),totalItems,totalPriceWithTax(formattedValue),totalDiscounts(value,formattedValue),subTotal(formattedValue),deliveryItemsQuantity,deliveryCost(formattedValue),totalTax(formattedValue, value),pickupItemsQuantity,net,appliedVouchers,productDiscounts(formattedValue),user,saveTime,name,description',
        createCart:
          'users/${userId}/carts?fields=DEFAULT,potentialProductPromotions,appliedProductPromotions,potentialOrderPromotions,appliedOrderPromotions,entries(totalPrice(formattedValue),product(images(FULL),stock(FULL)),basePrice(formattedValue,value),updateable),totalPrice(formattedValue),totalItems,totalPriceWithTax(formattedValue),totalDiscounts(value,formattedValue),subTotal(formattedValue),deliveryItemsQuantity,deliveryCost(formattedValue),totalTax(formattedValue, value),pickupItemsQuantity,net,appliedVouchers,productDiscounts(formattedValue),user',
        addEntries: 'orgUsers/${userId}/carts/${cartId}/entries/',
        updateEntries:
          'orgUsers/${userId}/carts/${cartId}/entries/${entryNumber}',
        removeEntries:
          'orgUsers/${userId}/carts/${cartId}/entries/${entryNumber}',
        saveCart:
          '/users/${userId}/carts/${cartId}/save?saveCartName=${saveCartName}&saveCartDescription=${saveCartDescription}',
        validate: 'users/${userId}/carts/${cartId}/validate?fields=DEFAULT',
        /* eslint-enable */
      },
    },
  },
};

export const fffCartProviders = [
  { provide: ActiveCartService, useClass: FFFActiveCartService },
  { provide: MultiCartService, useClass: FFFMultiCartService },
  FFFCartEntryEffects,
  { provide: CartEntryConnector, useClass: FFFCartEntryConnector },
  { provide: FFFCartEntryAdapter, useClass: FFFOccCartEntryAdapter },
  {
    provide: CART_MODIFICATION_NORMALIZER,
    useClass: CartModificationNormalizer,
    multi: true,
  },
];
