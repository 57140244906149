import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { FFFRegisterConfirmationGuard } from '@app/shared/services/fff-register-confirmation.guard';
import {
  CmsConfig,
  ConfigModule,
  I18nConfig,
  I18nModule,
  NotAuthGuard,
  provideConfig,
  UrlModule,
} from '@spartacus/core';
import {
  FormErrorsModule,
  IconModule,
  SpinnerModule,
} from '@spartacus/storefront';
import { fffTranslationOverwrites } from 'src/assets/translations/en/fff-register';
import { FffAccountConfirmationComponent } from './fff-account-confirmation/fff-account-confirmation.component';
import { FffAccountVerificationComponent } from './fff-account-verification/fff-account-verification.component';
import { FffLoginComponent } from './fff-login/fff-login.component';
import { FffRegisterConfirmationComponent } from './fff-register-confirmation/fff-register-confirmation.component';
import { FffRegisterOneComponent } from './fff-register-one/fff-register-one.component';
import { FffUserFirstLoginPopupComponent } from './fff-user-first-login-popup/fff-user-first-login-popup.component';
import { FFFLoadersModule } from '@app/shared/components/fff-loaders/fff-loaders.module';
//import { FffLoginMessageBoxComponent } from './fff-login-message-box/fff-login-message-box.component';

@NgModule({
  declarations: [
    FffLoginComponent,
    FffAccountConfirmationComponent,
    FffAccountVerificationComponent,
    FffRegisterOneComponent,
    FffRegisterConfirmationComponent,
    FffUserFirstLoginPopupComponent,
    //FffLoginMessageBoxComponent,
  ],

  providers: [
    provideConfig(<I18nConfig>{
      i18n: {
        resources: fffTranslationOverwrites,
      },
    }),
  ],

  imports: [
    CommonModule,
    ReactiveFormsModule,
    RouterModule,
    UrlModule,
    I18nModule,
    SpinnerModule,
    FFFLoadersModule,
    FormErrorsModule,
    HttpClientModule,
    IconModule,
    ConfigModule.withConfig({
      cmsComponents: {
        ReturningCustomerLoginComponent: {
          component: FffLoginComponent,
          guards: [NotAuthGuard],
        },
        RegisterCustomerComponent: {
          component: FffRegisterOneComponent,
          guards: [NotAuthGuard],
        },
        VerifyEmailComponent: {
          component: FffRegisterConfirmationComponent,
          guards: [FFFRegisterConfirmationGuard],
        },
      },
    } as CmsConfig),
  ],
})
export class FffLoginModule {}
