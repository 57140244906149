<div class="d-flex flex-column justify-content-between h-100">

    <div class="orderHistoryContainer">

        <p class="mb-0 approval-filter">{{'fffApprovals.company' | cxTranslate}}</p>
        <ng-select class="mt-4" [items]="companyOptions" [(ngModel)]="companyFilter"></ng-select>

        <p class="mb-0 approval-filter mt-4 requesteedBy">{{'fffApprovals.requested' | cxTranslate}}</p>
        <ng-select class="mt-4" [items]="requesterOptions" [(ngModel)]="requestedFilter"></ng-select>

    </div>

    <div class="minicart-footer-container">
        <button class="btn btn-primary btn-xs w-100" (click)="apply()">
            {{'fffOrderHistoryFilter.apply' | cxTranslate}}
        </button>
    </div>


</div>