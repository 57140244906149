<ng-template cxOutlet="cx-storefront">
  <fff-global-notification></fff-global-notification>
  <fff-global-banner-message></fff-global-banner-message>
  <fff-account-global-restrictions-checker></fff-account-global-restrictions-checker>
  <fff-global-toasts aria-live="polite" aria-atomic="true"></fff-global-toasts>
  <ng-template cxOutlet="cx-header">
    <header cxSkipLink="cx-header" #header>
      <ng-container *ngIf="isSimplifiedHeader; else mainHeader">
        <div class="simplifiedHeader-conntainer">
          <cx-page-slot position="TopHeaderSlot"></cx-page-slot>
          <cx-page-layout section="simplifiedHeader">
            <button
              class="btn btn-secondary cart-btn align-self-center"
              (click)="goToCartPage()"
            >
              {{ 'fffCheckout.editCart' | cxTranslate }}
            </button>
            <div class="page-title align-self-center">
              {{ 'fffCheckout.pageTitle' | cxTranslate }}
            </div>
          </cx-page-layout>
        </div>
      </ng-container>
      <ng-template #mainHeader>
        <cx-page-layout section="header">
          <ng-template
            cxOutletRef="header"
            [cxOutletPos]="outletPosition.BEFORE"
          >
            <div class="top-bar d-lg-none">
              <fff-top-bar></fff-top-bar>
            </div>
          </ng-template>
        </cx-page-layout>
      </ng-template>
    </header>
    <cx-page-slot position="BottomHeaderSlot"></cx-page-slot>
  </ng-template>

  <main cxSkipLink="cx-main">
    <router-outlet></router-outlet>
  </main>

  <ng-template cxOutlet="cx-footer">
    <footer cxSkipLink="cx-footer">
      <cx-page-layout section="footer"></cx-page-layout>
    </footer>
  </ng-template>
</ng-template>

<fff-drawer #Drawer> </fff-drawer>

<fff-scroll-to-top></fff-scroll-to-top>
