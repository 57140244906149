<div class="container pt-4" *ngIf="formattingComplete; else loading">
  <div *ngIf="validUser" class="row verification-container">
    <div class="verification-box background-color col-md-12">
      {{ 'fffConfirmation.header' | cxTranslate }}
    </div>
    <div
      class="verification-text col-md-12"
      [innerHTML]="'fffConfirmation.linkText' | cxTranslate"
    ></div>
  </div>
  <div *ngIf="!validUser">
    <div class="row verification-container">
      <div class="verification-box background-color col-md-12">
        {{ 'fffConfirmation.inValidUserHeader' | cxTranslate }}
      </div>
      <div
        class="verification-text col-md-12"
        [innerHTML]="'fffConfirmation.inValidUserLinkText' | cxTranslate"
      ></div>
    </div>
  </div>
</div>
<ng-template #loading>
  <fff-drawer-loader></fff-drawer-loader>
</ng-template>
