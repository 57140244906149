<div class="prebook-summary-box">
  <div
    class="overview-container mt-2"
    [class.orange-border]="!isQtySufficient"
    [class.green-border]="isQtySufficient"
  >
    <div class="flex-grow-1 p-2">
      <div class="prebook-vaccine-Label">{{ vaccineLabel }}</div>
    </div>
    <div
      *ngIf="isSuggestedQtyAvailable && !hideSuggestion"
      class="flex-grow-1 p-2 season-info-data"
    >
      <div class="overview-item-label">{{ suggestedQtyLabel }}</div>
      <div class="overview-item-value">
        {{ suggestedQty }} bx ({{ suggestedTotalDoses }} ds)
      </div>
    </div>
    <div class="flex-grow-1 p-2 season-info-data">
      <div class="overview-item-label">{{ labelTodaysOrder }}</div>
      <div class="overview-item-value">
        {{ todaysQuantity }} bx ({{ todaysTotalDoses }} ds)
      </div>
    </div>
    <div class="flex-grow-1 p-2 season-info-data">
      <div class="overview-item-label">{{ labelTotal }} {{ season }}</div>
      <div
        [class.orange-label]="!isQtySufficient"
        [class.green-label]="isQtySufficient"
      >
        {{ totalQuantity }} bx ({{ totalDosesForCurrentYear }} ds)
      </div>
    </div>
  </div>
  <div *ngIf="!isQtySufficient" class="orange-label mt-2 font-italic">
    {{ warningLabel }}
  </div>
</div>
