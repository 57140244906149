<div class="container">
  <ng-container *ngIf="refreshList$ | async as entryList">
    <div class="row mb-3 mb-lg-0">
      <div class="col-12 text-center p-4 border-separator">
        <span class="body-text-demi-xl h4"> {{ 'fffReturns.fffRequest.summaryTitle' | cxTranslate: { returnListLength: entryList.length } }}</span>
      </div>
    </div>
    <div class="row mt-3" *ngFor="let entry of entryList;let index = index" [ngClass]="index < entryList.length-1 ? ['border-separator', 'pb-3']:''">
      <div class="col-3 px-0">
        <cx-media [container]="getData(entry.product!)" format="cart"></cx-media>
      </div>
      <div class="col-9">
        <p class="mb-0 body-text-demi title">{{ entry.product?.name }}</p>
        <p class="mb-0">{{ 'fffReturns.fffRequest.qtyAdded' | cxTranslate: { quantity: entry.quantity,
          unit: entry.product?.unit } }}</p>
        <p class="mb-0">{{ entry.product?.manufacturer }}</p>
        <p class="mb-0">{{ 'fffReturns.ndc' | cxTranslate }} {{ entry.product?.ndc }}</p>
        <p class="mb-0">{{ 'fffReturns.sku' | cxTranslate }} {{ entry.product?.code }}</p>
        <p class="mb-0">{{ 'fffReturns.account' | cxTranslate }}: {{ entry.fffAccountNumber }}</p>
        <div class="d-flex justify-content-between">
          <span class="mb-0">{{ 'fffReturns.batchNumber' | cxTranslate }}: {{ entry.batchId }}</span>
          <button class="remove-item text-underline p-0 m-0" (click)="removeItem(index, entry)">{{'fffReturns.fffRequest.remove' | cxTranslate }}</button>
        </div>
      </div>
    </div>
  </ng-container>

  <div class="row mt-3">
    <div class="col-12 fet-returns-cart-footer p-4">
      <button class="btn btn-primary col-12" [class.updating]="loading$ | async" [disabled]="editingMode || entries.length < 1"
      (click)="submitRequest()">
        {{ 'fffReturns.fffRequest.submitRequest' | cxTranslate }}
        <cx-icon *ngIf="loading$ | async" class="cx-icon icon-md ml-3"
                 [type]="outlinedIconTypes.ICON_ARROW_REPEAT"></cx-icon>
      </button>
      <button class="btn btn-secondary col-12 mt-2" (click)="cancelRequest()">{{'fffReturns.fffRequest.cancelRequest' | cxTranslate }}</button>
      <div class="col-12 text-center summary-customer-support px-0">
        <span>{{ 'fffReturns.fffRequest.supportText' | cxTranslate }}</span>
      </div>
    </div>
  </div>
</div>
