export const fffFacetFilter = {
  fffFacetFilter: {
    done: 'Done',
    filter: 'Filter',
    clearAll: 'Clear All',
    sort: 'Sort',
    viewMore: 'View More',
    viewLess: 'View Less'
  }
};
