import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  OnDestroy,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { VACCINES_CATEGORY_TYPES } from '@app/models/fff-product.model';
import { CsAgentAuthService } from '@spartacus/asm/root';
import { RoutingService } from '@spartacus/core';
import { Subject, Subscription } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
  take,
  tap,
} from 'rxjs/operators';
import { FffPrebookCartService } from '../services/fff-prebook-cart.service';
import {
  ORDERED_VACCINE_STEPS,
  PREBOOK_CART_LABEL,
} from '../services/fff-prebook-category.utils';

interface PrebookCategoryHeaderNavItemQueryParams {
  category?: VACCINES_CATEGORY_TYPES;
  prebook?: boolean;
}

interface PrebookCategoryHeaderNavItemIconConfig {
  visible: boolean;
  src?: string;
  activeSrc?: string;
}
interface PrebookCategoryHeaderNavItem {
  id: string;
  path: string;
  queryParams: PrebookCategoryHeaderNavItemQueryParams;
  fullPath?: string;
  labelI18n: string;
  icon: PrebookCategoryHeaderNavItemIconConfig;
  completed: boolean;
  current: boolean;
  isReachedStep: boolean;
}

@Component({
  selector: 'fff-prebook-header',
  templateUrl: './fff-prebook-header.component.html',
  styleUrls: ['./fff-prebook-header.component.scss'],
  host: {
    class: 'fff-prebook-header',
  },
})
export class FffPrebookHeaderComponent implements OnInit, OnDestroy {
  private navItemsConfig: PrebookCategoryHeaderNavItem[] = [
    {
      id: VACCINES_CATEGORY_TYPES.INFLUENZA,
      path: '/categories',
      queryParams: {
        category: VACCINES_CATEGORY_TYPES.INFLUENZA,
        prebook: true,
      },
      labelI18n: 'fffProduct.influenza',
      completed: false,
      current: false,
      isReachedStep: false,
      icon: {
        visible: false,
      },
    },
    {
      id: VACCINES_CATEGORY_TYPES.COVID,
      path: '/categories',
      queryParams: {
        category: VACCINES_CATEGORY_TYPES.COVID,
        prebook: true,
      },
      labelI18n: 'fffProduct.COVID',
      completed: false,
      current: false,
      isReachedStep: false,
      icon: {
        visible: false,
      },
    },
    {
      id: VACCINES_CATEGORY_TYPES.RSV,
      path: '/categories',
      queryParams: {
        category: VACCINES_CATEGORY_TYPES.RSV,
        prebook: true,
      },
      labelI18n: 'fffProduct.RSV',
      completed: false,
      current: false,
      isReachedStep: false,
      icon: {
        visible: false,
      },
    },
    {
      id: 'cart',
      path: '/my-cart',
      queryParams: {
        prebook: true,
      },
      labelI18n: 'fffProduct.orderSummary',
      completed: false,
      current: false,
      isReachedStep: false,
      icon: {
        visible: true,
        src: './assets/icons/summary-icon.png',
        activeSrc: './assets/icons/summary-icon-white.png',
      },
    },
  ];

  navItems: PrebookCategoryHeaderNavItem[] = [];
  orderedSteps: string[] = ORDERED_VACCINE_STEPS;

  pageId: string = '';
  queryParams: PrebookCategoryHeaderNavItemQueryParams = {};
  saving: boolean = false;

  unsubscribe$: Subject<boolean> = new Subject<boolean>();
  subs = new Subscription();
  isScrolledDown = false;
  private lastScrollTop = 0;
  progressWidth = 0;
  @ViewChildren('stepIcon') stepIcons!: QueryList<ElementRef>;
  @ViewChild('progressLine') progressLine!: ElementRef;
  resizeListener;
  isASMUser!: boolean;

  constructor(
    private routingService: RoutingService,
    private cd: ChangeDetectorRef,
    private fffPrebookCartService: FffPrebookCartService,
    private csAgentAuthService: CsAgentAuthService,
    private eRef: ElementRef
  ) {
    this.resizeListener = this.updateProgressLine.bind(this);
  }

  @HostListener('document:click', ['$event'])
  onClick(event: MouseEvent) {
    if ((event.target as any).alt == 'mfv logo') {
      console.log('Logo clicked!');
    }
  }

  ngOnInit(): void {
    // Init Prebook Cart
    this.fffPrebookCartService.initPrebookCart();

    this.subs.add(
      this.routingService
        .getRouterState()
        .pipe(
          filter(value => !!value.state?.context?.id),
          debounceTime(300),
          tap(value => {
            this.pageId = value.state.context.id;
            this.queryParams = value.state.queryParams;
            this.buildNavItems();
            this.updateProgressLine();
            this.cd.markForCheck();
          }),
          map(value => value.state.context.id),
          distinctUntilChanged()
        )
        .subscribe()
    );
    this.subs.add(
      this.csAgentAuthService
        .isCustomerSupportAgentLoggedIn()
        .pipe(
          tap(isCustomerSupportAgentLoggedIn => {
            this.isASMUser = !!isCustomerSupportAgentLoggedIn;
            this.cd.detectChanges();
          })
        )
        .subscribe()
    );
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();

    this.unsubscribe$.next(false);
    this.unsubscribe$.complete();
  }

  buildNavItems() {
    this.navItems = this.navItemsConfig.map((item, nIndex) => {
      const isCart = this.pageId === PREBOOK_CART_LABEL;
      const stepIndex = this.orderedSteps.findIndex(
        s => this.queryParams?.category === s
      );
      const isCategoryStepCompleted =
        stepIndex !== -1 ? nIndex < stepIndex : false;
      const isCategoryStepReached = isCart
        ? true
        : stepIndex !== -1
        ? nIndex <= stepIndex
        : false;

      return {
        ...item,
        fullPath: this.routingService.getUrl(item.path, {
          queryParams: item.queryParams,
        }),
        completed:
          isCart && item.id !== 'cart' ? true : isCategoryStepCompleted,
        current: item.id === 'cart' ? isCart : stepIndex === nIndex,
        isReachedStep: isCategoryStepReached,
      };
    });
  }
  ngAfterViewInit() {
    // Add event listener after view has initialized
    window.addEventListener('resize', this.resizeListener);
    // Initial update
    this.updateProgressLine();
  }
  navigateToPage(ev: Event, item: PrebookCategoryHeaderNavItem) {
    ev.preventDefault();

    // If current page is prebook cart, do straight redirect
    if (this.pageId === PREBOOK_CART_LABEL) {
      this.routingService.go([item.path], { queryParams: item.queryParams });
      return;
    }

    // If category page, do add-to-cart before redirecting
    this.saving = true;
    this.cd.markForCheck();
    this.fffPrebookCartService
      .addToCart(
        this.unsubscribe$,
        {
          pageId: item.id,
          redirectToCart: item.id === 'cart',
        },
        this.pageId
      )
      .pipe(take(1))
      .subscribe(() => {
        this.saving = false;
        this.cd.markForCheck();
      });
  }

  @HostListener('window:scroll', ['$event'])
  onScroll(event: Event): void {
    // Get the scroll position
    const scrollTop = window.scrollY || document.documentElement.scrollTop;

    // Determine if scrolled down or up
    if (scrollTop > this.lastScrollTop) {
      this.isScrolledDown = true;
    } else {
      this.isScrolledDown = scrollTop === 0 ? false : this.isScrolledDown;
    }

    // Update last scroll position
    this.lastScrollTop = scrollTop;
    this.cd.detectChanges();
  }

  updateProgressLine() {
    this.cd.detectChanges();
    const stepIndex = this.orderedSteps.findIndex(
      s => this.queryParams?.category === s
    );
    const reachedIndex = stepIndex !== -1 ? stepIndex : 3;
    this.navItemsConfig.forEach(
      (step, index) => (step.isReachedStep = index <= reachedIndex)
    );

    const stepElements = this.stepIcons.toArray();
    const viewportWidth = window.innerWidth;

    if (reachedIndex < stepElements.length) {
      const stepRect =
        stepElements[reachedIndex].nativeElement.getBoundingClientRect();
      const lineEndPosition =
        reachedIndex === stepElements.length - 1
          ? viewportWidth
          : stepRect.left + stepRect.width / 2;

      this.progressLine.nativeElement.style.width = `${lineEndPosition}px`;
    }
  }
}
