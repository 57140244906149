export enum CUSTOM_ICON_TYPE {
  VALUE_PROP_3 = 'VALUE_PROP_3',
  VALUE_PROP_2 = 'VALUE_PROP_2',
  VALUE_PROP_1 = 'VALUE_PROP_1',
  ICON_CHOICE = 'ICON_CHOICE',
  ICON_COMMITMENT = 'ICON_COMMITMENT',
  ICON_SAFETY = 'ICON_SAFETY',
  REPORTS = 'REPORTS',
  REPORTS_HOVER = 'REPORTS_HOVER',
  ORDER_HISTORY = 'ORDER_HISTORY',
  ORDER_HISTORY_HOVER = 'ORDER_HISTORY_HOVER',
  MY_ACCOUNTS = 'MY_ACCOUNTS',
  MY_ACCOUNTS_HOVER = 'MY_ACCOUNTS_HOVER',
  INVOICES = 'INVOICES',
  INVOICES_HOVER = 'INVOICES_HOVER',
  IC_CART = 'IC_CART',
  ICON_PAYMENT_PAYPAL = 'ICON_PAYMENT_PAYPAL',
  ICON_PAYMENT_AFTERPAY = 'ICON_PAYMENT_AFTERPAY',
  ICON_PAYMENT_AFFIRM = 'ICON_PAYMENT_AFFIRM',
  ICON_PAYMENT_APPLE = 'ICON_PAYMENT_APPLE',
  ICON_PAYMENT_MASTERCARD = 'ICON_PAYMENT_MASTERCARD',
  ICON_PAYMENT_VISA = 'ICON_PAYMENT_VISA',
  ICON_TIKTOK = 'ICON_TIKTOK',
  ICON_APPLE = 'ICON_APPLE',
  ICON_DISCORD = 'ICON_DISCORD',
  ICON_TWITTER = 'ICON_TWITTER',
  ICON_LINKEDIN = 'ICON_LINKEDIN',
  ICON_DRIBBBLE = 'ICON_DRIBBBLE',
  ICON_REDDIT = 'ICON_REDDIT',
  ICON_YOUTUBE = 'ICON_YOUTUBE',
  ICON_GOOGLE = 'ICON_GOOGLE',
  ICON_PINTEREST = 'ICON_PINTEREST',
  ICON_FACEBOOK = 'ICON_FACEBOOK',
  ICON_SNAPCHAT = 'ICON_SNAPCHAT',
  ICON_TELEGRAM = 'ICON_TELEGRAM',
  ICON_INSTAGRAM = 'ICON_INSTAGRAM',
  ICON_SIGNAL = 'ICON_SIGNAL',
  ICON_GITHUB = 'ICON_GITHUB',
  ICON_PAGE_NOT_FOUND = 'ICON_PAGE_NOT_FOUND',
  ICON_SHORT_DATED = 'ICON_SHORT_DATED',
  ICON_SHORT_DATED_HOVER = 'ICON_SHORT_DATED_HOVER',
  ICON_BIOSURGICAL = 'ICON_BIOSURGICAL',
  ICON_BIOSURGICAL_HOVER = 'ICON_BIOSURGICAL_HOVER',
  ICON_PHARMA = 'ICON_PHARMA',
  ICON_PHARMA_HOVER = 'ICON_PHARMA_HOVER',
  ICON_PROVIDEGX = 'ICON_PROVIDEGX',
  ICON_PROVIDEGX_HOVER = 'ICON_PROVIDEGX_HOVER',
  ICON_PAGE_INTERNAL_SERVER_ERROR = 'ICON_PAGE_INTERNAL_SERVER_ERROR',
  ICON_RETURNS = 'ICON_RETURNS',
  ICON_RETURNS_HOVER = 'ICON_RETURNS_HOVER',
  ICON_NEWS_ARTICLE = 'ICON_NEWS_ARTICLE',
  ICON_EMPTY_CART = 'ICON_EMPTY_CART',
  ICON_VACCINE= 'ICON_VACCINE',
  ICON_VACCINE_HOVER = 'ICON_VACCINE_HOVER'
}

// Symbols mapping
export const CUSTOM_ICON_SYMBOLS = {
  VALUE_PROP_3: 'icon-Value-prop-3',
  VALUE_PROP_2: 'icon-Value-prop-2',
  VALUE_PROP_1: 'icon-Value-prop-1',
  ICON_CHOICE: 'icon-choice',
  ICON_COMMITMENT: 'icon-commitment',
  ICON_SAFETY: 'icon-safety',
  REPORTS: 'icon-Reports',
  REPORTS_HOVER: 'icon-Reports-hover',
  ORDER_HISTORY: 'icon-Order-History',
  ORDER_HISTORY_HOVER: 'icon-Order-History-hover',
  MY_ACCOUNTS: 'icon-My-Accounts',
  MY_ACCOUNTS_HOVER: 'icon-My-Accounts-hover',
  INVOICES: 'icon-Invoices',
  INVOICES_HOVER: 'icon-Invoices-hover',
  IC_CART: 'icon-ic-cart',
  ICON_PAYMENT_PAYPAL: 'icon-payment-paypal',
  ICON_PAYMENT_AFTERPAY: 'icon-payment-afterpay',
  ICON_PAYMENT_AFFIRM: 'icon-payment-affirm',
  ICON_PAYMENT_APPLE: 'icon-payment-apple-pay',
  ICON_PAYMENT_MASTERCARD: 'icon-payment-mastercard',
  ICON_PAYMENT_VISA: 'icon-payment-visa',
  ICON_TIKTOK: 'icon-tiktok',
  ICON_APPLE: 'icon-apple',
  ICON_DISCORD: 'icon-discord',
  ICON_TWITTER: 'icon-twitter',
  ICON_LINKEDIN: 'icon-linkedin',
  ICON_DRIBBBLE: 'icon-dribbble',
  ICON_REDDIT: 'icon-reddit',
  ICON_YOUTUBE: 'icon-youtube',
  ICON_GOOGLE: 'icon-google',
  ICON_PINTEREST: 'icon-pinterest',
  ICON_FACEBOOK: 'icon-facebook',
  ICON_SNAPCHAT: 'icon-snapchat',
  ICON_TELEGRAM: 'icon-telegram',
  ICON_INSTAGRAM: 'icon-instagram',
  ICON_SIGNAL: 'icon-signal',
  ICON_GITHUB: 'icon-github',
  ICON_PAGE_NOT_FOUND: 'icon-page-not-found',
  ICON_SHORT_DATED: 'icon-short-dated',
  ICON_SHORT_DATED_HOVER: 'icon-short-dated-hover',
  ICON_BIOSURGICAL: 'icon-biosurgical',
  ICON_BIOSURGICAL_HOVER: 'icon-biosurgical-hover',
  ICON_PHARMA: 'icon-pharma',
  ICON_PHARMA_HOVER: 'icon-pharma-hover',
  ICON_PROVIDEGX: 'icon-provideGX',
  ICON_PROVIDEGX_HOVER: 'icon-provideGX-hover',
  ICON_PAGE_INTERNAL_SERVER_ERROR: 'icon-page-internal-server',
  ICON_RETURNS: 'icon-returns',
  ICON_RETURNS_HOVER: 'icon-returns-hover',
  ICON_NEWS_ARTICLE: 'icon-news-article',
  ICON_EMPTY_CART: 'icon-empty-cart',
  ICON_VACCINE: 'icon-vaccine',
  ICON_VACCINE_HOVER: 'icon-vaccine-hover'
};
