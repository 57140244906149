import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'fff-quickorder-status',
  templateUrl: './quickorder-status.component.html'
})
export class FffQuickorderStatusComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    // Remove the Breadcrum and Category Header
    document.getElementsByTagName("fff-breadcrumb")[0].innerHTML = "<div class='container'><h1 class='title h3'>Thank You!</h1></div>";
  }

}