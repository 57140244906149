import {
  ChangeDetectorRef,
  Component,
  HostBinding,
  Input,
} from '@angular/core';
import {
  NgbCalendar,
  NgbDate,
  NgbDatepicker,
} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'delivery-calendar',
  templateUrl: './delivery-calendar.component.html',
  host: {
    class: 'fff-delivery-calendar',
  },
})
export class DeliveryCalendarComponent {
  @Input() itemInfo: any;
  @Input() index: any;

  fromDate: any;
  toDate: any;
  displayMonths: number = 1;
  private _displayDynamicMonths: boolean = false;

  @HostBinding('class.fff-delivery-calendar--multiple-months')
  get displayDynamicMonths(): boolean {
    return this._displayDynamicMonths;
  }

  set displayDynamicMonths(value: boolean) {
    this._displayDynamicMonths = value;
  }

  @HostBinding('class.fff-delivery-calendar--two-months')
  get hasTwoMonths(): boolean {
    return this.displayMonths === 2;
  }

  @HostBinding('class.fff-delivery-calendar--more-months')
  get hasMoreThanTwoMonths(): boolean {
    return this.displayMonths > 2;
  }

  get hasThanTwoMonths(): boolean {
    return this.displayMonths > 2;
  }

  constructor(public calendar: NgbCalendar, private cd: ChangeDetectorRef) {}

  ngOnInit() {
    this.fromDate = this.calendar.getNext(
      this.toNgbDate(this.itemInfo.baseShipDate),
      'd',
      0
    );
    this.toDate = this.calendar.getNext(
      this.toNgbDate(this.itemInfo.baseArrivalDate),
      'd',
      0
    );

    if (this.fromDate && this.toDate) {
      this.displayMonths =
        (this.toDate.year - this.fromDate.year) * 12 +
        this.toDate.month -
        this.fromDate.month +
        1;
      this.displayDynamicMonths = this.displayMonths > 1;
      this.cd.markForCheck();
    }
  }

  onDateSelection(date: NgbDate) {
    return;
  }

  isInside(date: NgbDate, i: any) {
    let startDate = this.fromDate;
    let endDate = this.toDate;

    if (i >= 0) {
      startDate = this.toNgbDate(
        this.itemInfo.delivery.prebook.shippingWindows[i]
          .shippingWindowStartDate
      );
      endDate = this.toNgbDate(
        this.itemInfo.delivery.prebook.shippingWindows[i].shippingWindowEndDate
      );
    }

    return endDate && date.after(startDate) && date.before(endDate);
  }

  isRange(date: NgbDate, i: any) {
    let startDate = this.fromDate;
    let endDate = this.toDate;

    if (i >= 0) {
      startDate = this.toNgbDate(
        this.itemInfo.delivery.prebook.shippingWindows[i]
          .shippingWindowStartDate
      );
      endDate = this.toNgbDate(
        this.itemInfo.delivery.prebook.shippingWindows[i].shippingWindowEndDate
      );
    }

    return (
      date.equals(startDate) ||
      (endDate && date.equals(endDate)) ||
      this.isInside(date, i)
    );
  }

  isBorderLeft(date: NgbDate) {
    return date.equals(this.fromDate);
  }

  isBorderRight(date: NgbDate) {
    return this.toDate && date.equals(this.toDate);
  }

  getMonthName(monthNumber: number) {
    const date = new Date();
    date.setMonth(monthNumber - 1);
    // Using the browser's default locale.
    return date.toLocaleString([], { month: 'long' });
  }

  toNgbDate(time: number) {
    let date = new Date(time);
    return new NgbDate(
      date.getUTCFullYear(),
      date.getUTCMonth() + 1,
      date.getUTCDate()
    );
  }

  toCalendarParsedDate(time: any) {
    let date = new Date(time);
    return { year: date.getUTCFullYear(), month: date.getUTCMonth() + 1 };
  }

  getStartDate() {
    if (this.itemInfo.delivery.prebook) {
      let startDate =
        this.itemInfo.delivery.prebook.shippingWindows[this.index]
          .shippingWindowStartDate ||
        this.itemInfo.delivery.prebook.shippingWindows[this.index]
          .shippingWindowEndDate;
      return this.toCalendarParsedDate(startDate);
    } else {
      return this.fromDate;
    }
  }

  navigate(datepicker: NgbDatepicker, number: number) {
    const { state, calendar } = datepicker;
    datepicker.navigateTo(calendar.getNext(state.firstDate, 'm', number));
  }

  today(datepicker: NgbDatepicker) {
    const { calendar } = datepicker;
    datepicker.navigateTo(calendar.getToday());
  }
}
