import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { FffCommunicationService } from '@app/fff-enterprise/fff-common-services/fff-communication.service';
import { FffB2bUnit, FffB2bUnitList } from '@app/models/fff-b2b-unit.model';
import { FffProfile } from '@app/models/fff-profile.model';
import { FFFUser, SET_USER } from '@app/reducers';
import { Store } from '@ngrx/store';
import {
  AuthService,
  OccEndpointsService,
  User,
  UserIdService,
} from '@spartacus/core';
import { cloneDeep } from 'lodash';
import {
  BehaviorSubject,
  EMPTY,
  Observable,
  Subscription,
  iif,
  of,
} from 'rxjs';
import {
  distinctUntilChanged,
  filter,
  map,
  switchMap,
  take,
  tap,
} from 'rxjs/operators';

const defaultProfileState = {
  user: undefined,
  selected: {
    name: undefined,
    uid: undefined,
    paymentTermsDesc: undefined,
  },
  totalAccounts: 0,
  fetchable: false,
};

@Injectable({
  providedIn: 'root',
})
export class FffUserAccountService implements OnDestroy {
  private loadUser$ = new BehaviorSubject<void>(undefined);
  private personalInfo$ = new BehaviorSubject<FffProfile | any>(
    defaultProfileState
  );
  private subs = new Subscription();
  isLoggedInMsg$ = new BehaviorSubject<boolean>(false);
  isValidLicense: boolean = false;
  hasNoGPOAssigned: boolean = false;
  hasCreditBlock: boolean = false;

  constructor(
    private communicationService: FffCommunicationService,
    private storeProfile: Store<FFFUser>,
    private authService: AuthService,
    protected userIdService: UserIdService,
    private http: HttpClient,
    protected occEndpoints: OccEndpointsService
  ) {}

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  getProfile(): Observable<FffProfile | undefined> {
    return this.getProfileInformation();
  }

  loadProfile() {
    this.reloadCurrentAccount();
    return this.loadUser$.next();
  }

  removeProfile() {
    this.loadUser$.next(undefined);
  }

  private getProfileInformation(): Observable<FffProfile | any> {
    return this.authService.isUserLoggedIn().pipe(
      take(1),
      switchMap((isUserLoggedIn: boolean) =>
        iif(
          () => isUserLoggedIn,
          this.getPersonalInfo(),
          of({
            user: undefined,
            selected: {
              name: undefined,
              uid: undefined,
              paymentTermsDesc: undefined,
            },
          })
        )
      )
    );
  }

  private getPersonalInfo(): Observable<FffProfile> {
    return this.personalInfo$.asObservable();
  }

  private getB2BBAccounts(user: any): Observable<FffProfile> {
    return this.communicationService.getB2BAccounts(user).pipe(
      take(1),
      map((accounts: FffB2bUnitList) => {
        const selected = accounts.b2bunits.filter((b2b: FffB2bUnit) => {
          return b2b.isDefault;
        })[0];

        const profile: FffProfile = {
          user: user,
          accounts: accounts.b2bunits,
          selected: selected,
          totalAccounts: accounts.b2bunits?.length,
        };
        return profile;
      })
    );
  }

  loadUserState() {
    this.subs.add(
      this.authService
        .isUserLoggedIn()
        .pipe(
          distinctUntilChanged(),
          switchMap(isUserLoggedIn => {
            if (isUserLoggedIn) {
              return this.loadPersonalInfo();
            }
            this.personalInfo$.next(defaultProfileState);
            return EMPTY;
          })
        )
        .subscribe()
    );
  }

  private loadPersonalInfo() {
    return this.communicationService.getPersonalInfo().pipe(
      filter(user => Boolean(user)),
      take(1),
      tap(user => {
        this.storeProfile.dispatch({ type: SET_USER, payload: user });
        this.loadSelectedAccount(user);
        // this.loadB2bAccounts(user).subscribe();
      })
    );
  }

  private loadB2bAccounts(user: User) {
    return this.communicationService.getB2BAccounts(user).pipe(take(1));
  }

  private reloadCurrentAccount() {
    this.personalInfo$
      .pipe(
        take(1),
        switchMap(info =>
          this.communicationService.getCurrentAccount(info.user)
        ),
        tap(selected => {
          this.personalInfo$.next(
            cloneDeep({
              ...this.personalInfo$.value,
              selected: selected,
            })
          );
        })
      )
      .subscribe();
  }

  private loadSelectedAccount(user: User) {
    let profile: FffProfile = {
      ...this.personalInfo$.value,
    };
    let totalAccounts = 0;
    const MAX_ACCOUNTS_PER_PAGE = 30;
    this.communicationService
      .getCurrentAccount(user)
      .pipe(
        tap(selected => {
          profile = {
            ...this.personalInfo$.value,
            user,
            selected: selected,
          };
        }),
        switchMap(() => this.loadB2bAccounts(user)),
        // switchMap((res: FffB2bUnitList) => {
        //   const accounts = res.b2bunits || [];
        //   totalAccounts = accounts.length;
        //   if (totalAccounts === 0) {
        //     return this.communicationService.getAccountsByQuery(
        //       user.uid ?? '',
        //       profile.selected?.uid || ''
        //     );
        //   }
        //   return of(res);
        // }),
        tap((res: FffB2bUnitList) => {
          const accounts = cloneDeep(res.b2bunits || []);
          totalAccounts = accounts.length;
          this.personalInfo$.next({
            ...profile,
            user,
            accounts,
            totalAccounts,
            fetchable:
              totalAccounts === 0 || totalAccounts >= MAX_ACCOUNTS_PER_PAGE,
          });
        })
      )
      .subscribe();
  }
  getWalletDetails(): Observable<any> {
    const user$ = this.userIdService.getUserId();
    return user$.pipe(
      switchMap(userId => {
        const url = `/users/${userId}/paymentdetails?fields=DEFAULT&saved=false`;
        return this.http.get(this.occEndpoints.buildUrl(url), {
          headers: this.getRequestHeaders(),
        });
      })
    );
  }
  getRequestHeaders(): HttpHeaders {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    return headers;
  }
}
