<div class="d-flex flex-wrap body-text-lg">

    <div class="content-close-icon cursor-pointer ml-auto d-flex justify-content-between">
      <cx-icon class="cx-icon icon-lg" [type]="outlinedIconTypes.ICON_X" (click)="close()"></cx-icon>
    </div>
  
    <div class="col-12 text-center content-header">
      <cx-icon class="cx-icon icon-lg pb-3" [type]="outlinedIconTypes.ICON_CHECK_CIRCLE"></cx-icon>
      <span class="col-12 text-center">{{ 'fffApprovals.modalTitle' | cxTranslate }}</span>
    </div>
  
  
    <div class="col-12 text-center cx-dialog-body modal-body content-body">
      <p>
        {{ 'fffApprovals.modalText' | cxTranslate }}
      </p>
    </div>
  
    <div class="col-12 modal-actions">
      <button class="btn btn-primary col-12" (click)="continueApproving()">{{'fffApprovals.finish' | cxTranslate }}</button>
    </div>
  </div>