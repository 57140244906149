import { Params } from '@angular/router';
import { CxEvent, PageContext } from '@spartacus/core';

export class FffFacetChangedEvent extends CxEvent {
  static readonly type = 'FacetChangedEvent';
  code?: string;
  name?: string;
  valueCode?: string;
  valueName?: string;
  selected?: boolean;
  accountNumber?: string;
  accountName?: string;
  accountTerm?: string;
}

export class FffLoginEvent extends CxEvent {
  static readonly type = 'LoginEvent';
  accountNumber?: string;
  accountName?: string;
  accountTerm?: string;
}

export class FffLogoutEvent extends CxEvent {
  static readonly type = 'LogoutEvent';
  accountNumber?: string;
  accountName?: string;
  accountTerm?: string;
}

export class FffNavigationEvent extends CxEvent {
  static readonly type = 'NavigationEvent';
  context?: PageContext;
  semanticRoute?: string;
  url?: string;
  params?: Params;
  accountNumber?: string;
  accountName?: string;
  accountTerm?: string;
}

export class FffOrderPlacedEvent extends CxEvent {
  static readonly type = 'purchase';
  ecommerce?: any;
}

export class FffCartAddEntrySuccessEvent extends CxEvent {
  static readonly type = 'add_to_cart';
  ecommerce?: any;
}

export class FffCartRemoveEntrySuccessEvent extends CxEvent {
  static readonly type = 'remove_from_cart';
  ecommerce? : any;
}
export class FffUserAccountChangeEvent extends CxEvent {
  static readonly type = 'UserAccountChangeEvent';
  accountNumber?: string;
  accountName?: string;
  accountTerm?: string;
}

export class FffUserAccountChangedEvent extends CxEvent {
  static readonly type = 'UserAccountChangedEvent';
  accountNumber?: string;
  accountName?: string;
  accountTerm?: string;
}
