import { Component, Injector } from '@angular/core';
import { AbstractControl, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { OUTLINED_ICON_TYPE } from '@app/models/fff-outline-icons.model';
import { NgbDate, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FffCommunicationService } from '../fff-common-services/fff-communication.service';
import { FffReportsModalComponent } from './fff-reports-modal/fff-reports-modal.component';

//create function that recieves a string of emails separated by comma and check if every single one of them is valid


@Component({
  selector: 'fff-reports',
  templateUrl: './fff-reports.component.html',
})


export class FffReportsComponent {

  constructor(private modalService: NgbModal,
    private injector: Injector,
    private communicationService: FffCommunicationService) { }

  outlinedIconTypes = OUTLINED_ICON_TYPE;
  reportType: string | undefined;
  statusesSelected: any[] = [];
  toDate: NgbDate | undefined;
  fromDate: NgbDate | undefined;
  reportForm: any;

  readonly availableStatus: any = {
    'Order History': [{ id: 'open', label: 'Open' }, { id: 'pshipped', label: 'Partially Shipped' }, { id: 'fshipped', label: 'Fully Shipped' }],
    'Invoice History': [{ id: 'open', label: 'Open' }, { id: 'paid', label: 'Paid' }],
  }

  ngOnInit() {

    this.reportForm = new UntypedFormGroup({
      reportType: new UntypedFormControl('', [Validators.required]),
      fromDate: new UntypedFormControl('', [Validators.required]),
      toDate: new UntypedFormControl('', [Validators.required]),
      email: new UntypedFormControl('', [Validators.required, this.emailValidator]),
    }, { validators: this.dateValidator });

  }

  emailValidator(control: UntypedFormControl): { [key: string]: boolean } | null {
    const emails = control.value ? control.value.split(',') : [];
    //check if the amount of emails is greater than 4
    if (emails.length > 4) {
      return { maxFourEmail: true };
    }

    for (let email of emails) {
      if (!/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email.trim())) {
        return { invalidEmail: true };
      }
    }
    return null;
  }

  dateValidator(control: AbstractControl): { [key: string]: boolean } | null {
    const fromControl = control.get('fromDate');
    const toControl = control.get('toDate');
    const fromDate = new NgbDate(fromControl?.value?.year, fromControl?.value?.month, fromControl?.value?.day);
    const toDate = new NgbDate(toControl?.value?.year, toControl?.value?.month, toControl?.value?.day);

    if (fromDate && toDate) {
      if (fromDate.after(toDate)) {

        return { invalidDate: true };
      }
    }
    return null;
  }


  onReportTypeSelection(event: any) {
    this.statusesSelected = [];
  }

  onStatusSelection(status: any, event: any) {
    if (event.target.checked) {
      this.statusesSelected.push(status);
    }
    else {
      this.statusesSelected = this.statusesSelected.filter((item) => item.id !== status.id);
    }
  }

  async onSubmit() {

    this.reportForm.markAllAsTouched();

    let formValid = this.reportForm.status == 'VALID' && this.statusesSelected.length

    let modalRef = this.modalService.open(FffReportsModalComponent, {
      centered: true,
      backdrop: 'static',
      //keyboard: false,
      size: 'md',
      modalDialogClass: 'modal-container',
      injector: Injector.create({
        providers: [
          { provide: 'validations', useValue: { formValue: this.reportForm, statusList: this.statusesSelected } },
        ],
        parent: this.injector,
      }),
    });

    modalRef.dismissed?.subscribe((result) => {
      if (formValid) {
        this.reportForm.reset();
        this.statusesSelected = [];
      }
    });

    if (formValid) {
      let rf = { ...this.reportForm.value }
      await this.communicationService.sendReport(rf, this.statusesSelected).toPromise();
    }

  }

}
