import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { EMPTY, Observable, of } from 'rxjs';
import {
  concatMap,
  delay,
  filter,
  map,
  pluck,
  switchMap,
  take,
  withLatestFrom,
} from 'rxjs/operators';
import { GlobalBannerActions } from '@app/actions';
import { GlobalBannerMessage, StateWithGlobalBannerMessage } from '@model/fff-global-message.model';
import { GlobalBannerMessageSelectors } from '@app/selectors';
import { GlobalMessageConfig, isNotUndefined, Translatable } from '@spartacus/core';
import { FffGlobalBannerEffectsUtils } from '@enterprise/fff-header/fff-global-banner-message/effects/utils/fff-global-banner-effects.utils';


@Injectable()
export class GlobalBannerMessageEffect {
  removeDuplicated$: Observable<GlobalBannerActions.RemoveBannerMessage> =
    createEffect(() =>
      this.actions$.pipe(
        ofType(GlobalBannerActions.ADD_BANNER_MESSAGE),
        pluck('payload'),
        switchMap((message: GlobalBannerMessage) =>
          of(message.text).pipe(
            withLatestFrom(
              this.store.pipe(
                select(
                  GlobalBannerMessageSelectors.getGlobalBannerMessageEntitiesByType(
                    message.type
                  )
                )
              )
            ),
            filter(
              ([text, messages]: [Translatable, GlobalBannerMessage[]]) =>
                FffGlobalBannerEffectsUtils.countOfDeepEqualObjects(text, messages) >
                1
            ),
            map(([text, messages]: [Translatable, GlobalBannerMessage[]]) => {
              const index = FffGlobalBannerEffectsUtils.indexOfFirstOccurrence(
                text,
                messages
              );
              if (index !== undefined) {
                return new GlobalBannerActions.RemoveBannerMessage(message);
              }
              return undefined;
            }),
            filter(isNotUndefined)
          )
        )
      )
    );

  hideAfterDelay$:
    | Observable<GlobalBannerActions.RemoveBannerMessage>
    | (() => Observable<never>) = createEffect(() =>
      isPlatformBrowser(this.platformId) ? this.actions$.pipe(
        ofType(GlobalBannerActions.ADD_BANNER_MESSAGE),
        pluck('payload'),
        concatMap((message: GlobalBannerMessage) => {
          const config = this.config.globalMessages?.[message.type];
          return this.store.pipe(
            select(
              GlobalBannerMessageSelectors.getGlobalBannerMessageCountByType(message.type)
            ),
            take(1),
            filter(
              (count: number) =>
                ((config && config.timeout !== undefined) ||
                    message.timeout !== undefined) &&
                    count > 0
            ),
            delay((message.timeout as number) || (config?.timeout as number)),
            switchMap(() => {
              return of(
                new GlobalBannerActions.RemoveBannerMessage({
                  id: message.id,
                  isHTML: message.isHTML,
                  isPersistent: message.isPersistent,
                  text: message.text,
                  timeout: message.timeout,
                  type: message.type,
                  isTranslatable: message.isTranslatable,
                  prefix: message.prefix,
                }));
            })
          );
        })) : () => EMPTY);
  constructor(
    private actions$: Actions,
    private store: Store<StateWithGlobalBannerMessage>,
    private config: GlobalMessageConfig,
    @Inject(PLATFORM_ID) private platformId: any
  ) {
  }
}
