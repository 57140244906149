import { ChangeDetectorRef, Component, Input, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ReportStatusTypes } from '@app/fff-enterprise/fff-new-reports/fff-new-reports.model';

@Component({
  selector: 'fff-order-status-control',
  templateUrl: './fff-order-status-control.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FffOrderStatusControlComponent),
      multi: true,
    },
  ],
})
export class FffOrderStatusControlComponent implements ControlValueAccessor {
  @Input() inputId: string = '';
  @Input() inputName: string = '';

  _items: any[] = [];
  get items(): any[] {
    return this._items;
  }
  @Input() set items(value: any) {
    this._items = value || [];
    this.writeValue(this.value);
  }

  touched = false;
  disabled = false;
  value!: any;

  constructor(private cd: ChangeDetectorRef) {}

  onChange = (_value: any) => {};
  onTouched = () => {};

  writeValue(value: any): void {
    this.value = value;
    this.updateItemsSelection(value);
    this.onChange(value);
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  markAsTouched() {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }

  resetItemsSelection() {
    this.items.forEach((item: any) => {
      item.selected = false;
    });
    this.cd.markForCheck();
  }

  updateItemsSelection(ids: any) {
    this.items.forEach((item: any) => {
      item.selected = (ids || [])?.length
        ? (ids || []).includes(item.id)
        : false;
    });
    this.cd.markForCheck();
  }

  onToggle(status: any) {
    if (!status || this.disabled) {
      return;
    }
    status.selected = !status.selected;

    if (status.id === ReportStatusTypes.ALL) {
      if (status.selected) {
        this.updateItemsSelection([...this.items].map((item: any) => item.id));
      } else {
        this.resetItemsSelection();
      }
    } else {
      let isAllSelected = this.items.filter((item: any) => item.selected && item.id !== ReportStatusTypes.ALL).length === this.items.length - 1;

      this.items.map((item: any) => {
        if (item.id === ReportStatusTypes.ALL) {
          item.selected = isAllSelected;
        }
      });
    }

    this.onChange(
      this.items?.filter(item => item.selected)?.map(item => item?.id) || []
    );
    this.markAsTouched();
  }
}
