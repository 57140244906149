<div class="card personalDetailsCard">
  <div class="card-header">
    <h4> {{ 'fffAccount.fffProfileManagement.personalDetails' | cxTranslate }}</h4>
  </div>
  <div class="card-body body-text">
    <div class="personalDetails">
      <div class="body-text">
        {{user.firstName}} {{user.lastName}}
      </div>
      <div class="body-text">
        {{user.email}}
      </div>
      <div class="body-text">
        {{user.contactNumber}}
      </div>
    </div>
    <a class="cardLink" (click)="openUpdatePersonalDetailsDrawer()">
      {{ 'fffAccount.fffProfileManagement.update' | cxTranslate }}</a>
  </div>
</div>
