<div class="modal-header">
  <cx-icon
    class="text-reset cx-icon-close"
    (click)="useDifferentCard()"
    [type]="outlinedIconTypes.ICON_X"
  ></cx-icon>
</div>
<div class="modal-body text-center">
  <div class="mb-3 body-text-demi-xl">
    {{ 'fffInvoicePayment.paymentFailed.summary' | cxTranslate }}
  </div>

  <button type="button" class="btn btn-primary" (click)="useDifferentCard()">
    {{ 'fffInvoicePayment.paymentFailed.useDifferentCard' | cxTranslate }}
  </button>
</div>
