import { Component, OnInit } from '@angular/core';
import { FffDrawerService } from '@app/shared/drawer/fff-drawer.service';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-filter-approvals',
  templateUrl: './filter-approvals.component.html',
})
export class FilterApprovalsComponent implements OnInit {

  constructor(public drawerService: FffDrawerService) { }

  companyFilter: any;
  requestedFilter: any;

  companyOptions : any = []
  requesterOptions : any = []

  ngOnInit(): void {

    let filters = localStorage.getItem('approvalFilters')
    if(filters){
      let parsedFilters = JSON.parse(filters)
      this.companyFilter = parsedFilters.company;
      this.requestedFilter = parsedFilters.requested;
    }

    this.drawerService.getContent().pipe(take(1)).subscribe((res: any)=>{

      //getting all the options given
      let a = res.data.map((e: any) => {
        return e.unit.uid
      })

      let b = res.data.map((e: any) => {
        return e.user.uid
      })

      a.push('All')
      b.push('All')

      //removing duplicates
      this.companyOptions = Array.from(new Set(a))
      this.requesterOptions = Array.from(new Set(b))

    })
  }

  apply(){

    let filters = {
      company: this.companyFilter,
      requested: this.requestedFilter
    }

    localStorage.setItem('approvalFlters', JSON.stringify(filters))

    this.drawerService.closeDrawer(filters)
  }

}
