<div class="w-100">
  <div class="loader-animation price-row full"></div>
  <div
    class="d-flex justify-content-between full mb-3"
    *ngFor="let i of 'LOADER'.split('')"
  >
    <div class="loader-animation product-detail"></div>
    <div class="loader-animation product-detail"></div>
  </div>
  <div class="loader-animation link full mb-2"></div>
  <div class="loader-animation button full mb-2"></div>
  <div class="loader-animation link full mb-2"></div>
</div>
