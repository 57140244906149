import { Component } from '@angular/core';
import { FILLED_ICON_TYPE } from '@app/models/fff-filled-icons.model';

@Component({
  selector: 'fff-fet-info-tooltip',
  templateUrl: './fff-fet-info-tooltip.component.html',
})
export class FffFetInfoTooltipComponent {
  filledIconTypes = FILLED_ICON_TYPE;
}
