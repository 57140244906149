<div class="modal-header">
  <cx-icon (click)="close()" [type]="OUTLINED_ICON_TYPE.ICON_X"></cx-icon>
</div>
<div class="modal-body pt-3 pl-4 pr-4 pb-5">
  <div class="message deleteCardMsg">
    {{
      'fffAccount.fffProfileManagement.wallet.deletePopup.confirmationMsg'
        | cxTranslate
    }}
    {{ cardEndingDigits }}<span class="question">?</span>
  </div>
  <div class="d-flex justify-content-center">
    <button class="btn btn-secondary col-12 cancelBtn" (click)="close()">
      {{
        'fffAccount.fffProfileManagement.wallet.deletePopup.cancel'
          | cxTranslate
      }}
    </button>
    <button
      class="btn btn-primary col-12"
      [disabled]="loading"
      (click)="deleteCard()"
    >
      {{
        'fffAccount.fffProfileManagement.wallet.deletePopup.yesDelete'
          | cxTranslate
      }}
    </button>
  </div>
</div>
