import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'fffTrimTransactionId',
})
export class FffTrimTransactionIdPipePipe implements PipeTransform {
  transform(value: any): string {
    if (!value) return value;

    const length = value.length;
    if (length <= 12) return value;

    return value.substring(length - 12);
  }
}
