<div class="d-flex flex-column justify-content-between h-100">
  <div class="return-send-rga-copy-container">
    <form [formGroup]="sendRGACopyForm" (ngSubmit)="sendEmails()">
      <div class="label-container">
        <span>{{'fffReturns.enterMultipleEmails' | cxTranslate }}</span>
      </div>
      <div class="input-container mt-4">
        <div class="form-group">
          <label for="emailsTextArea">{{ 'fffCheckout.emailAddress' | cxTranslate }}</label>
          <textarea class="form-control" id="emailsTextArea" rows="3" formControlName="emails" (change)="checkEmail()"></textarea>
          <cx-form-errors aria-live="assertive" aria-atomic="true"
                          [control]="$any(sendRGACopyForm).get('emails')"></cx-form-errors>
        </div>
      </div>
      <div class="button-submit-container m-auto">
        <button type="submit" class="btn btn-primary w-100" [disabled]="!checkEmail() && sendRGACopyForm.invalid">{{ 'fffContactUs.send' | cxTranslate }}</button>
      </div>
    </form>
  </div>
</div>
