import { Component, OnDestroy, OnInit } from '@angular/core';
import { FffCommunicationService } from '@app/fff-enterprise/fff-common-services/fff-communication.service';
import { TranslationService } from '@spartacus/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { FffAllocationsService } from '../fff-allocations.service';

@Component({
  selector: 'fff-current-allocations',
  templateUrl: './fff-current-allocations.component.html',
})
export class FffCurrentAllocationsComponent implements OnInit, OnDestroy {

  private unsubscribe$: Subject<void> = new Subject<void>();
  allocationData$: BehaviorSubject<any> = new BehaviorSubject<any>(undefined);
  allocationResponse$: Observable<any> | undefined;

  columnsGrid: any = {
    desktop: '1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr',
    mobile: '1fr 1fr'
  }

  pageSize: number = 10;
  pagedResponse: any;
  pages: number = 0;
  total: number = 0;
  allAllocations: any;

  data: any = { 'data': [], 'total': 0, loading: false, current: 0 };

  fields: any = [
    { title: 'fffAllocations.productName', field: 'productName' },
    { title: 'fffAllocations.sku', field: 'material' },
    { title: 'fffAllocations.ndc', field: 'productNdc' },
    { title: 'fffAllocations.customer', field: 'customer' },
    { title: 'fffAllocations.monthlyAlloc', field: 'allocatedQty' },
    { title: 'fffAllocations.monthlyRemain', field: 'remainingQty' },
    { title: 'fffAllocations.annualAlloc', field: 'yearlyAllocatedQty' },
    { title: 'fffAllocations.annualRemain', field: 'yearlyRemainingQty' },
  ];

  constructor(
    private fffAllocationsService: FffAllocationsService,
    public communicationService: FffCommunicationService,
    public translationService: TranslationService
  ) { }

  ngOnInit(): void {
    this.allocationResponse$ = this.communicationService.getAllAllocations().pipe(takeUntil(this.unsubscribe$));
    this.allocationResponse$.subscribe(response => this.getAllAllocationsData(response));
  }

  getAllAllocationsData(result: any): void {
    if (!result || !result.results) {
      this.data = { 'data': [], 'total': 0, loading: false, current: this.data.current };
      this.dataAsObs(this.data);
    } else {
      result.results.sort((a: any, b: any) => (a.productName > b.productName) ? 1 : ((b.productName > a.productName) ? -1 : 0));
      result.results.forEach((allocatedItem: any) => {
        if (allocatedItem.productName) {
          allocatedItem.productName = allocatedItem.productName + ' (' + allocatedItem.productManufacturerName + ')';
        }
      })

      this.allAllocations = result.results;
      this.fffAllocationsService.allAllocations$.next(this.allAllocations);
      this.pages = Math.ceil(result.results.length / this.pageSize)
      this.pagedResponse = this.splitToChunks(result.results)
      this.total = result.results ? result.results.length : 0;

      this.data = { 'data': this.pagedResponse[0], 'total': this.total, loading: false, current: this.data.current };
      this.dataAsObs(this.data);
    }
  }

  getData(page: any): void {
    this.data = { 'data': this.pagedResponse[page.page - 1], 'total': this.total, loading: false, current: this.data.current };
    this.dataAsObs(this.data);
  }

  dataAsObs(data: any): void {
    this.allocationData$.next(data);
  }

  splitToChunks(array: any) {
    let mainArray: any = [];
    let pages = Math.ceil(array.length / this.pageSize);

    for (let i = 0; i < pages; i++) {
      mainArray.push([]);
    }

    let currentIndex = 0;

    for (let k = 0; k < mainArray.length; k++) {
      for (let j = 0; j < this.pageSize; j++) {
        if (array[currentIndex] != null) {
          mainArray[k][j] = array[currentIndex];
        }
        currentIndex += 1;
      }
    }

    return mainArray;
  }

  ngOnDestroy(): void {
    if (this.unsubscribe$) {
      this.unsubscribe$.unsubscribe();
    }
  }

}
