import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { I18nModule } from '@spartacus/core';
import { MediaModule } from '@spartacus/storefront';
import { FFFCartLoaderComponent } from './fff-cart-loader/fff-cart-loader.component';
import { FFFContentLoaderComponent } from './fff-content-loader/fff-content-loader.component';
import { FFFDrawerLoaderComponent } from './fff-drawer-loader/fff-drawer-loader.component';
import { FFFLineLoaderComponent } from './fff-line-loader/fff-line-loader.component';
import { FFFProductsLoaderComponent } from './fff-plp-loader/fff-products-loader.component';
import { FFFTableLoaderComponent } from './fff-table-loader/fff-table-loader.component';

@NgModule({
  declarations: [
    FFFCartLoaderComponent,
    FFFContentLoaderComponent,
    FFFDrawerLoaderComponent,
    FFFLineLoaderComponent,
    FFFProductsLoaderComponent,
    FFFTableLoaderComponent,
  ],
  imports: [CommonModule, MediaModule, RouterModule, I18nModule],
  exports: [
    FFFCartLoaderComponent,
    FFFContentLoaderComponent,
    FFFDrawerLoaderComponent,
    FFFLineLoaderComponent,
    FFFProductsLoaderComponent,
    FFFTableLoaderComponent,
  ],
})
export class FFFLoadersModule {}
