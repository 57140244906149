import { NgModule } from '@angular/core';
import { CmsConfig, I18nModule, provideDefaultConfig } from '@spartacus/core';
import {
  FffFetReturnsRequestComponent,
} from '@enterprise/fff-fet-returns/fff-fet-returns-request/fff-fet-returns-request.component';
import {
  FffFetReturnsRequestFormComponent,
} from '@enterprise/fff-fet-returns/fff-fet-returns-request-form/fff-fet-returns-request-form.component';
import {FormErrorsModule, IconModule, MediaModule} from '@spartacus/storefront';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import {
  FffFetReturnsRequestEntryComponent,
} from '@enterprise/fff-fet-returns/fff-fet-returns-request-entry/fff-fet-returns-request-entry.component';
import {
  FffFetReturnsRequestCartComponent,
} from '@enterprise/fff-fet-returns/fff-fet-returns-request-cart/fff-fet-returns-request-cart.component';

@NgModule({
  declarations: [
    FffFetReturnsRequestComponent,
    FffFetReturnsRequestFormComponent,
    FffFetReturnsRequestEntryComponent,
    FffFetReturnsRequestCartComponent
  ],
  exports: [],
    imports: [
        I18nModule,
        IconModule,
        CommonModule,
        ReactiveFormsModule,
        FormErrorsModule,
        MediaModule,
    ],
  providers: [
    provideDefaultConfig(<CmsConfig>{
      cmsComponents: {
        FFFFetReturnsRequestComponent: {
          component: FffFetReturnsRequestComponent,
        },
      },
    }),
  ],
})
export class FffFetReturnsModule {}
