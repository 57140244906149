import { Component } from '@angular/core';
import { FffJeffersonProductsResponse } from '@app/models/fff-jefferson-health.model';
import { FffCommunicationService } from '@enterprise/fff-common-services/fff-communication.service';
import { FffUserAccountService } from '@shared/services/fff-user-account.service';
import { ActiveCartFacade } from '@spartacus/cart/base/root';
import { BaseSiteService, Product } from '@spartacus/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'fff-jefferson-page',
  templateUrl: './fff-jefferson-page.component.html',
})
export class FffJeffersonPageComponent {

  products: Product[] = [];
  loading: any;

  profile$ = this.fffAccountService.getProfile();
  activeSite$ = this.baseSiteService.getActive();
  cart$ = this.fffActiveCartService.getActive();
  products$: Observable<Product[]> = this.fffCommunicationService.getJeffersonProducts(0, 500).pipe(
    map((response: FffJeffersonProductsResponse) => {
      return response.products;
    })
  );
  constructor(
    private fffAccountService: FffUserAccountService,
    public fffActiveCartService: ActiveCartFacade,
    private fffCommunicationService: FffCommunicationService,
    private baseSiteService: BaseSiteService
  ) { }
}
