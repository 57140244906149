import { Component, OnInit } from '@angular/core';
import { ReturnConfirmationState } from '@app/reducers';
import { Store } from '@ngrx/store';
import { Observable, of, Subject } from 'rxjs';

@Component({
  selector: 'fff-returns-confirmation',
  templateUrl: './fff-returns-confirmation.component.html',
})
export class FffReturnsConfirmationComponent implements OnInit {

  returnData$: Observable<any> = new Subject<any>();

  constructor(
    private store: Store<ReturnConfirmationState>,
  ) { }

  ngOnInit(): void {
    this.store.select((state: any) => {
      return state.ReturnConfirmationState;
    }).subscribe(returnConfirmationData => {
      if (returnConfirmationData && returnConfirmationData.data && returnConfirmationData.data.orders.length > 0) {
        this.returnData$ = of(returnConfirmationData.data);
      } else {
        this.returnData$ = of();
      }
    })
  }

}
