import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { OUTLINED_ICON_TYPE } from '@app/models/fff-outline-icons.model';
import { State, Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import {
  FILTER_PRICELIST_BY_CATEGORY,
  FILTER_PRICELIST_BY_CATEGORY_APPLIED,
  FilterState,
} from 'src/app/reducers';
import { FffDrawerService } from 'src/app/shared/drawer/fff-drawer.service';
import { FffCommunicationService } from '../fff-common-services/fff-communication.service';
import { FffUserPriceListService } from './fff-user-price-list.service';

@Component({
  selector: 'fff-user-price-list-drawer',
  templateUrl: './fff-user-price-list-drawer.component.html',
})
export class FffUserPriceListDrawerComponent implements OnInit, OnDestroy {
  options: any = [];
  outlinedIconTypes = OUTLINED_ICON_TYPE;
  searchTerm: string = '';
  subs = new Subscription();

  constructor(
    private communicationService: FffCommunicationService,
    protected drawerService: FffDrawerService,
    private store: Store<FilterState>,
    private state: State<FilterState>,
    private cd: ChangeDetectorRef,
    private fffUserPriceListService: FffUserPriceListService
  ) {}

  ngOnInit(): void {
    this.subs.add(
      this.communicationService.getPriceListFilters().subscribe((res: any) => {
        this.options = (res.filters || []).filter(
          (o: any) => !!(o.name && o.code)
        );
        this.formatOptions();

        this.fffUserPriceListService.toggleFiltersState(true);
        this.fffUserPriceListService.setProductTypes([...this.options]);
      })
    );
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  formatOptions() {
    const s = this.state.getValue();
    if (s.Filters && s.Filters.priceListByCategoryApplied) {
      this.options.forEach((o: any) => {
        if (s.Filters.priceListByCategoryApplied.includes(o.code))
          o.isChecked = true;
      });
    }

    this.cd.markForCheck();
  }

  filtersUpdated() {
    this.store.dispatch({
      type: FILTER_PRICELIST_BY_CATEGORY,
      payload: this.options
        .filter((o: any) => o.isChecked)
        .map((a: any) => a.code),
    });
  }

  isApplyDisabled() {
    return false;
  }

  applyFilter() {
    let statuses: any[] = this.options
      .filter((o: any) => o.isChecked)
      .map((a: any) => a.code);

    let savedStatus = {
      statuses,
    };

    sessionStorage.setItem('priceListFilter', JSON.stringify(savedStatus));

    this.fffUserPriceListService.setAppliedFilters(statuses);

    this.store.dispatch({
      type: FILTER_PRICELIST_BY_CATEGORY_APPLIED,
      payload: statuses,
    });
    this.drawerService.closeDrawer({ updated: true });
  }
}
