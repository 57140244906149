import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Region, RoutingService, UserAddressService } from '@spartacus/core';
import { UserProfileFacade } from '@spartacus/user/profile/root';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { FILLED_ICON_TYPE } from 'src/app/models/fff-filled-icons.model';
import { OUTLINED_ICON_TYPE } from 'src/app/models/fff-outline-icons.model';
import { FffAddress } from 'src/app/models/fff-request-address-data-model';
import { FffDrawerService } from 'src/app/shared/drawer/fff-drawer.service';
import { FffCommunicationService } from '../fff-common-services/fff-communication.service';


@Component({
  selector: 'fff-request-change-address-drawer',
  templateUrl: './fff-request-change-address-drawer.component.html',
})
export class FffRequestChangeAddressDrawerComponent implements OnInit, OnDestroy {

  RequestChangeAddressForm: UntypedFormGroup = this.fb.group({
    address: ['', Validators.required],
    building: [''],
    city: ['', Validators.required],
    state: ['', Validators.required],
    zipCode: ['', Validators.required],
    phoneNumber: [''], //AddressWSDTO.phone
    deaNumber: [''], ////AddressWSDTO.deaNumber
  })

  destroy$: Subject<boolean> = new Subject<boolean>();
  public regions!: Region[];
  public addresses: any | undefined;
  public account: any | undefined;
  public node: any | undefined;
  public user: any | undefined;
  public outlinedIconTypes = OUTLINED_ICON_TYPE;
  public shippingAddress: any | undefined;
  public billingAddress: any | undefined;
  public changeAddress: boolean = false;
  filledIconTypes = FILLED_ICON_TYPE;



  constructor(
    protected fb: UntypedFormBuilder,
    protected router: RoutingService,
    private drawerService: FffDrawerService,
    private userProfileFacade: UserProfileFacade,
    private communicationService: FffCommunicationService,
    private userAddressService: UserAddressService

  ) { }

  showButtons: boolean = false;

  ngOnDestroy(): void {
    this.destroy$.next(true),
      this.destroy$.unsubscribe()
    this.RequestChangeAddressForm.get
  }

  ngOnInit(): void {


    this.userProfileFacade.get().pipe(takeUntil(this.destroy$)).subscribe((data) => {
      this.communicationService.getCurrentAccount(data).subscribe(a => {
        this.account = a;
        this.showButtons = this.shouldDisplayButtons()
        this.shippingAddress = a.shippingAddress;
        this.billingAddress = a.billingAddress;
        this.showShippingAddress()
      });

      let country: string = 'US';

      this.userAddressService.getRegions(country).subscribe((regions) => {
        this.regions = regions
      })
      this.node = data;
    });

  }


  showShippingAddress() {
    this.addresses = this.shippingAddress;
    this.changeAddress = true;
  }

  showBillingAddress() {
    this.addresses = this.billingAddress;
    this.changeAddress = false;
  }

  submitForm() {
    if (this.RequestChangeAddressForm.valid) {
      const newRequestData: any = this.collectDataFromRegisterForm(this.RequestChangeAddressForm.value);
      if(this.changeAddress){
        newRequestData.shippingAddress = true;
      } else{
        newRequestData.billingAddress = true;
      }
      const registerAddrRequest$ = this.communicationService.requestChangeAddress(this.node.uid, newRequestData);
      registerAddrRequest$.subscribe(() => this.onRegisterAddrSuccess());
      this.drawerService.closeDrawer()
    }
    else {
      this.RequestChangeAddressForm.markAllAsTouched();
    }
  }

  private onRegisterAddrSuccess(): void {
    this.drawerService.closeDrawer();
  }

  collectDataFromRegisterForm(formData: any): FffAddress {
    const { address, building, city, state, zipCode, phoneNumber, deaNumber } = formData;
    return {
      line1: address,
      line2: building,
      town: city,
      region: state,
      postalCode: zipCode,
      phone: phoneNumber,
      deaNumber: deaNumber
    };
  }

  public errorForm(field: string): UntypedFormControl {
    return this.RequestChangeAddressForm.get(field) as UntypedFormControl
  }

  shouldDisplayButtons(){
    const addresses = this.account.addresses
    return addresses?.find((address: any) => address.billingAddress) && addresses?.find((address: any) => address.shippingAddress)
  }

}


