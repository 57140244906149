import { Injectable } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { BasePageMetaResolver, CategoryPageMetaResolver, CmsService, ProductSearchService, RoutingService, TranslationService } from '@spartacus/core';
import { FffCommunicationService } from './fff-communication.service';

@Injectable({
  providedIn: 'root',
})
export class CustomCategoryPageMetaResolver extends CategoryPageMetaResolver {
    
  constructor(public routingService: RoutingService, public ngMeta: Meta, public communicationService: FffCommunicationService, productSearchService: ProductSearchService, cms: CmsService, translation: TranslationService, basePageMetaResolver: BasePageMetaResolver)
  {
    super(productSearchService, cms, translation, basePageMetaResolver);

    this.routingService.getRouterState().subscribe((result)=>{

      let category = result.state.params['param1'];
      //when getting to the category through the menu
      if (category) {
        this.setDescription(result.state.params['categoryCode'])
        return
      }

      this.productSearchService.getResults().subscribe((res)=>{
        let category = res?.breadcrumbs?.find((elem: any) => elem.facetCode === 'category');
        if(category){
          this.setDescription(category?.facetValueCode)
        }
      })

    })
  }

  results$ = this.productSearchService.getResults()

  //ideally, using "resolveDescription" would do the trick in here, but it seems that it isn't working, so I do it manually
  setDescription(category: string | undefined){
    this.ngMeta.removeTag('name="description"');
    this.communicationService.getCategoryDetails(category).subscribe((res)=>{
      this.ngMeta.addTag({ name: 'description', content: res?.seoDescription || ''})
    }) 
  }

}

