import { Component, OnInit } from '@angular/core';
import { OUTLINED_ICON_TYPE } from '@app/models/fff-outline-icons.model';
import { Cart } from '@spartacus/cart/base/root';
import { FffRestoreSavedCartDrawerService } from './fff-restore-saved-cart-drawer.service';


@Component({
  selector: 'fff-restore-saved-cart-drawer-body',
  templateUrl: './fff-restore-saved-cart-drawer-body.component.html',
})
export class FffRestoreSavedCartDrawerBodyComponent implements OnInit {

  outlinedIconTypes = OUTLINED_ICON_TYPE;
  cartData: Cart | undefined;

  constructor(private restoreSavedCartDrawerService: FffRestoreSavedCartDrawerService) { }

  ngOnInit(): void {
    this.cartData = this.restoreSavedCartDrawerService.savedCart.value;
  }

}




