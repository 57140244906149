import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { OUTLINED_ICON_TYPE } from '@app/models/fff-outline-icons.model';
import { FffTagItem } from './fff-tags.model';

@Component({
  selector: 'fff-tags',
  templateUrl: './fff-tags.component.html',
})
export class FffTagsComponent {
  @Input() items: FffTagItem[] = [];
  @Input() multiple: boolean = false;

  @Output() removed = new EventEmitter<FffTagItem>();

  outlinedIconTypes = OUTLINED_ICON_TYPE;
  truncate: number = 50;

  constructor(private cd: ChangeDetectorRef) {}

  removeItem(item: FffTagItem, index: number) {
    this.items.splice(index, 1);
    this.cd.markForCheck();
    this.removed.emit(item);
  }
}
