<div>
  <div class="drawer-header">
    <div class="closed">
      <cx-icon
        class="cx-icon icon-lg"
        [type]="iconClose"
        (click)="close()"
      ></cx-icon>
    </div>
    <div class="title">
      <h3>
        {{ 'fffReports.selectProductGroups' | cxTranslate }}
      </h3>
    </div>
    <div class="ml-5 mt-2" *ngIf="isMultiAccount">
      <input
        type="checkbox"
        [(ngModel)]="selectAllChecked"
        (change)="toggleAll()"
      />
      <label class="pt-2 selectAllText">Select All</label>
    </div>
  </div>

  <div class="drawer-body">
    <section class="search-box">
      <label class="label" for="search">
        <input
          id="search"
          autocomplete="off"
          placeholder="{{ 'fffReports.search' | cxTranslate }}"
          [(ngModel)]="searchText"
        />

        <div *ngIf="!searchText" class="search-icon">
          <cx-icon
            class="cx-icon icon-sm"
            [type]="outlinedIconTypes.ICON_SEARCH"
          ></cx-icon>
        </div>
        <button *ngIf="searchText" class="reset">
          <cx-icon
            class="cx-icon icon-sm"
            [type]="outlinedIconTypes.ICON_X_CIRCLE"
            (click)="searchText = ''"
          ></cx-icon>
        </button>
      </label>
    </section>
    <div
      class="account-container"
      *ngFor="let productGroup of productGroups | searchFilter : searchText"
    >
      <div class="d-flex ml-5">
        <input
          class="position-relative"
          type="checkbox"
          [checked]="isProductGroupSelected(productGroup)"
          (change)="toggleProductGroups(productGroup)"
        />
        <label class="ml-2 pt-2">{{ productGroup.name }}</label>
      </div>
    </div>
  </div>
  <div class="drawer-footer">
    <button
      class="btn btn-primary btn-lg w-100"
      [disabled]="selectedProductGroups.length === 0"
      (click)="selectProductGroups()"
    >
      {{ 'fffReports.selectProductGroups' | cxTranslate }}
    </button>
  </div>
</div>
