import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { FffDrawerModule } from '@app/shared/drawer/fff-drawer.module';
import { MiniCartModule } from '@spartacus/cart/base/components/mini-cart';
import { ConfigModule, I18nModule, UrlModule } from '@spartacus/core';
import {
  IconModule,
  MediaModule,
  PageSlotModule,
} from '@spartacus/storefront';
import { FffCartModule } from '../../fff-cart/fff-cart.module';
import { FffMiniCartContentComponent } from './fff-mini-cart-content/fff-mini-cart-content.component';
import { FffMiniCartEmptyComponent } from './fff-mini-cart-empty/fff-mini-cart-empty.component';
import { FffMiniCartFooterComponent } from './fff-mini-cart-footer/fff-mini-cart-footer.component';
import { FffMiniCartHeaderAddedComponent } from './fff-mini-cart-header-added/fff-mini-cart-header-added.component';
import { FffMiniCartHeaderComponent } from './fff-mini-cart-header/fff-mini-cart-header.component';
import { FffCartItemComponent } from './fff-mini-cart-item/fff-cart-item.component';
import { CartButtonComponent } from './fff-mini-cart.component';
import { FFFLoadersModule } from '@app/shared/components/fff-loaders/fff-loaders.module';

@NgModule({
  declarations: [
    CartButtonComponent,
    FffMiniCartContentComponent,
    FffCartItemComponent,
    FffMiniCartEmptyComponent,
    FffMiniCartFooterComponent,
    FffMiniCartHeaderComponent,
    FffMiniCartHeaderAddedComponent,
  ],
  imports: [
    CommonModule,
    ConfigModule.withConfig({
      cmsComponents: {
        MiniCartComponent: {
          component: CartButtonComponent,
          disableSSR: true,
        },
      },
    }),
    MediaModule,
    MiniCartModule,
    UrlModule,
    PageSlotModule,
    IconModule,
    I18nModule,
    FffCartModule,
    FffDrawerModule,
    FFFLoadersModule,
    RouterModule,
  ],
  exports: [FffCartItemComponent],
})
export class FffCartButtonModule {}
