import { Component, Input } from '@angular/core';
import { OUTLINED_ICON_TYPE } from '@app/models/fff-outline-icons.model';
import { BASE_URL_KEYS } from '@config/content/constants';

@Component({
  selector: 'fff-shipping-dates',
  templateUrl: './fff-shipping-dates.component.html',
})
export class FffShippingDatesComponent {
  @Input() cart: any;

  _baseShipDate: string = '';
  get baseShipDate(): string {
    return this._baseShipDate;
  }
  @Input() set baseShipDate(value: string) {
    this._baseShipDate = value !== 'TBD' ? (value || '').slice(0, 10) : value;
  }

  _baseArrivalDate: string = '';
  get baseArrivalDate(): string {
    return this._baseArrivalDate;
  }
  @Input() set baseArrivalDate(value: string) {
    this._baseArrivalDate =
      value !== 'TBD' ? (value || '').slice(0, 10) : value;
  }
  // @Input() baseShipDate: any
  // @Input() baseArrivalDate: any;

  @Input() baseSite: any;
  @Input() item: any;

  BASE_URL_KEYS = BASE_URL_KEYS;

  outlinedIconTypes = OUTLINED_ICON_TYPE;

  isDeliveryWindowVisible():boolean{
    var isDeliveryWindowVisible = false;
    if(this.isPrebook()){
      if(this.isRSVType()){
          if(this.cart?.prebookCart){
              isDeliveryWindowVisible = true;
          }
      }else{
         isDeliveryWindowVisible = true;
      }
    }
    return isDeliveryWindowVisible;
  }

  isPrebook(): boolean {
    return this.item.product.productPreBookConfiguration &&
      this.item.product.productPreBookConfiguration.active == true
      ? true
      : false;
  }

  isRSVType(): boolean {
    return this.item?.product?.categories?.find(
      (category: any) =>
        category?.code === 'VACCNVACCN000P2400' ||
        category?.name === 'Vaccines - RSV'
    );
  }

  isValidBioStatus(): boolean {
    return (
      this.baseSite === BASE_URL_KEYS.BIOSUPPLY &&
      this.item?.sapStockLevelStatus != 'IN_TRANSIT' &&
      this.item?.sapStockLevelStatus != 'ON_DEMAND' &&
      this.item?.sapStockLevelStatus != 'DROP_SHIP'
    );
  }

  isValidMfvStatus(): boolean {
    return (
      this.baseSite === BASE_URL_KEYS.MY_FLU_VACCINE &&
      this.item?.sapStockLevelStatus != 'ON_DEMAND' &&
      this.item?.sapStockLevelStatus != 'DROP_SHIP'
    );
  }
}
