import { Component } from "@angular/core";
import { OUTLINED_ICON_TYPE } from "@model/fff-outline-icons.model";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: 'fff-add-to-cart-notice',
  templateUrl: './fff-add-to-cart-notice.component.html',
})
export class FffAddToCartNoticeComponent {
  outlinedIconTypes = OUTLINED_ICON_TYPE;
  addToCartNotice: string = '';
  constructor(private modalService: NgbActiveModal) {
  }
  continueAddToCart(): void {
    this.modalService.close({ addToCart: true });
  }

  close(): void {
    this.modalService.close({ addToCart: false });
  }
}
