import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MiniCartModule } from '@spartacus/cart/base/components/mini-cart';
import { ConfigModule, I18nModule, UrlModule } from '@spartacus/core';
import {
  GenericLinkModule,
  IconModule,
  MediaModule,
  NavigationModule,
  PageSlotModule
} from '@spartacus/storefront';
import { FffDrawerModule } from '../../../shared/drawer/fff-drawer.module';
import { FffChangeAccountDrawerModule } from '../fff-change-account/fff-change-account-drawer.module';
import { FffMyAccountComponent } from './fff-my-account/fff-my-account.component';
import { FffSiteLoginComponent } from './fff-site-login.component';
import { FffLoginMessageBoxComponent } from '@app/fff-enterprise/fff-login/fff-login-message-box/fff-login-message-box.component';


@NgModule({
  declarations: [FffSiteLoginComponent, FffMyAccountComponent, FffLoginMessageBoxComponent],
  imports: [
    CommonModule,
    ConfigModule.withConfig({
      cmsComponents: {
        LoginComponent: {
          component: FffSiteLoginComponent,
        },
      },
    }),
    MediaModule,
    MiniCartModule,
    UrlModule,
    PageSlotModule,
    I18nModule,
    IconModule,
    FffDrawerModule,
    FffChangeAccountDrawerModule,
    GenericLinkModule,
    NavigationModule
  ],
})
export class FffSiteLoginModule { }
