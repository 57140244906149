<div class="container py-3">
  <div class="row d-flex justify-content-center align-items-center m-auto px-0 pt-5">

    <div class="col-md-12 d-flex justify-content-center align-items-center error-page-header">
      <div>
        <cx-icon class="cx-icon icon-xl pe-auto" [type]="customIconTypes.ICON_PAGE_INTERNAL_SERVER_ERROR"></cx-icon>
        <h3 class="h3">{{ 'fffInternalServerPage.header' | cxTranslate }}</h3>
      </div>
    </div>

    <div class="col-xs-12 col-md-8 my-4">
      <div class="col-8 my-4 m-auto">
        <p class="error-message">
          <span class="host">{{ 'fffInternalServerPage.host' | cxTranslate }}</span> {{
          'fffInternalServerPage.errorMessage' |
          cxTranslate }}
        </p>
        <p class="error-message">{{ 'fffInternalServerPage.errorCode' | cxTranslate }}</p>
      </div>
    </div>

  </div>
</div>