<div class="d-flex flex-column justify-content-between h-100">
  <div class="price-list-filters-container">
    <div
      class="text-brand-primary-80 font-size-body-xl font-weight-bold filter-title mt-4"
    >
      {{ 'fffPriceList.productType' | cxTranslate }}
    </div>
    <div class="price-list-filter-search">
      <input
        [(ngModel)]="searchTerm"
        class="form-control price-list-filter-search-input"
        placeholder="Search"
      />

      <div
        class="w-100 icon-container"
        style="margin-left: -5px; pointer-events: none"
      >
        <cx-icon
          class="cx-icon icon-sm px-2 icon"
          [type]="outlinedIconTypes.ICON_SEARCH"
        ></cx-icon>
      </div>
    </div>

    <div
      class="options-filter"
      *ngFor="let option of options | fffFilterProductType : searchTerm"
    >
      <input
        class="checkbox checkbox-sm"
        type="checkbox"
        [id]="option.code"
        [(ngModel)]="option.isChecked"
        (ngModelChange)="filtersUpdated()"
      />
      <label class="checkbox-label d-flex" [for]="option.code">
        <p>{{ option.name }}</p>
      </label>
    </div>
  </div>
  <div class="minicart-footer-container">
    <button
      class="btn btn-primary btn-xs w-100"
      (click)="applyFilter()"
      [disabled]="isApplyDisabled()"
    >
      {{ 'fffOrderHistoryFilter.apply' | cxTranslate }}
    </button>
  </div>
</div>
