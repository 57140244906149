import { Injectable, inject } from '@angular/core';
import { FFFActiveCartService } from "@enterprise/fff-custom-cart/fff-active-cart-service";
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { CartActions } from '@spartacus/cart/base/core';
import { CartModification } from '@spartacus/cart/base/root';
import { LoggerService, SiteContextActions, normalizeHttpError, withdrawOn } from '@spartacus/core';
import { Observable, from, of } from 'rxjs';
import { catchError, concatMap, map } from 'rxjs/operators';
import {
  FFFCartAddEntry,
  FFFCartAddEntrySuccess,
  FFFCartRemoveEntry,
  FFFCartRemoveEntrySuccess,
  FFFCartUpdateEntry,
  FFFCartUpdateEntrySuccess,
  FFF_CART_ADD_ENTRY,
  FFF_CART_REMOVE_ENTRY,
  FFF_CART_UPDATE_ENTRY
} from './fff-cart-entry.action';
import { FFFCartEntryConnector } from './fff-cart-entry.connector';

@Injectable()
export class FFFCartEntryEffects {

  processMap: any = {};
  processMapRemoveEntry: any = {};
  processMapUpdateEntry: any = {};

  protected logger = inject(LoggerService);

  private contextChange$ = this.actions$.pipe(
    ofType(
      SiteContextActions.CURRENCY_CHANGE,
      SiteContextActions.LANGUAGE_CHANGE
    )
  );

  
  addEntry$: Observable<
    | FFFCartAddEntrySuccess
    | CartActions.CartAddEntryFail
    | CartActions.LoadCart
  > = createEffect(() => this.actions$.pipe(
    ofType(FFF_CART_ADD_ENTRY),
    map((action: FFFCartAddEntry) => action),
    concatMap((action) => {
      let payload = action.payload;

      if (!this.processMap[payload.orderEntries![0].key!]) {
        this.processMap[payload.orderEntries![0].key!] = { count: 1 };
      } else {
        this.processMap[payload.orderEntries![0].key!].count++;
      }

      if (!payload.orderEntries || payload.orderEntries.length == 0) {
        return this.processInvalidRequest(payload);
      }

      return this.fffCartEntryConnector
        .add(
          payload.userId,
          payload.cartId,
          payload.productCode,
          payload.quantity,
          payload.pickupStore,
          payload.orderEntries
        )
        .pipe(
          map(
            (cartModification: CartModification) =>
              new FFFCartAddEntrySuccess({
                ...payload,
                ...(cartModification as Required<CartModification>),
              })
          ),
          catchError((error) =>
            from([
              new CartActions.CartAddEntryFail({
                ...payload,
                error: normalizeHttpError(error, this.logger),
              }),
              new CartActions.LoadCart({
                cartId: payload.cartId,
                userId: payload.userId,
              }),
            ])
          )
        );
    }),
    withdrawOn(this.contextChange$)
  ));

  
  removeEntry$: Observable<
    | FFFCartRemoveEntrySuccess
    | CartActions.CartRemoveEntryFail
    | CartActions.LoadCart
  > = createEffect(() => this.actions$.pipe(
    ofType(FFF_CART_REMOVE_ENTRY),
    map((action: FFFCartRemoveEntry) => action.payload),
    concatMap((payload) => {
      if (!this.processMapRemoveEntry[payload.entryNumber]) {
        this.processMapRemoveEntry[payload.entryNumber] = { count: 1 };
      } else {
        this.processMapRemoveEntry[payload.entryNumber].count++;
      }

      return this.fffCartEntryConnector
        .remove(payload.userId, payload.cartId, payload.entryNumber)
        .pipe(
          map(() => {
            return new FFFCartRemoveEntrySuccess({
              ...payload,
            });
          }),
          catchError((error) =>
            from([
              new CartActions.CartRemoveEntryFail({
                ...payload,
                error: normalizeHttpError(error, this.logger),
              }),
              new CartActions.LoadCart({
                cartId: payload.cartId,
                userId: payload.userId,
              }),
            ])
          )
        )
    }),
    withdrawOn(this.contextChange$)
  ));

  
  updateEntry$: Observable<
    | FFFCartUpdateEntrySuccess
    | CartActions.CartUpdateEntryFail
    | CartActions.LoadCart
    | void
  > = createEffect(() => this.actions$.pipe(
    ofType(FFF_CART_UPDATE_ENTRY),
    map((action: FFFCartUpdateEntry) => action.payload),
    concatMap((payload) => {
      if (!this.processMapUpdateEntry[payload.entryNumber]) {
        this.processMapUpdateEntry[payload.entryNumber] = { count: 1 };
      } else {
        this.processMapUpdateEntry[payload.entryNumber].count++;
      }

      return this.fffCartEntryConnector
        .update(
          payload.userId,
          payload.cartId,
          payload.entryNumber,
          payload.quantity
        )
        .pipe(
          map(() => {
            return new FFFCartUpdateEntrySuccess({
              ...payload,
            });
          }),
          catchError((error) =>
            from([
              new CartActions.CartUpdateEntryFail({
                ...payload,
                error: normalizeHttpError(error, this.logger),
              }),
              new CartActions.LoadCart({
                cartId: payload.cartId,
                userId: payload.userId,
              }),
            ])
          )
        )
    }),
    withdrawOn(this.contextChange$)
  ));

  private processInvalidRequest(payload: any): Observable<any> {
    return of(
      new CartActions.LoadCart({
        cartId: payload.cartId,
        userId: payload.userId,
      }),
    );
  }

  constructor(
    private actions$: Actions,
    private fffCartEntryConnector: FFFCartEntryConnector,
    private fffActiveCartService: FFFActiveCartService
  ) {
    this.actions$
      .subscribe((ac) => {
        this.fffActiveCartService.emitCartEvent(ac);
      });
  }
}
