export const fffApprovalsHistory = {
  fffApprovalsHistory: {
    tabName: 'Approval History',
    requestDate: 'Request Date',
    orderNumber: 'Order #',
    requestBy: 'Requested By',
    sku: 'SKU',
    name: 'Product Name',
    qty: 'Qty',
    price: 'Price',
    total: 'Total',
    action: 'Action',
    approved: 'Approved by {{user}} on {{date}}',
    denied: 'Denied by {{user}} on {{date}}',
    purchaseOrderNumber: 'PO',
  },
};

export const fffApprovalsFilterHistory = {
  fffApprovalsFilterHistory: {
    onPlaceHolder: 'Search By Account #',
    units: 'Units',
    action: 'Action',
    apply: 'Apply',
    approved: 'Approved',
    rejected: 'Rejected',
    all: 'All',
  },
};
