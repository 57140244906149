import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, UrlTree } from '@angular/router';
import { FffSsoService } from '@app/fff-enterprise/fff-sso/fff-sso.service';
import { AsmComponentService } from '@spartacus/asm/components';
import { SemanticPathService } from '@spartacus/core';
import { Observable, of } from 'rxjs';
import { delay, map } from 'rxjs/operators';
import { FffCryptoService } from '../../shared/services/fff-crypto.service';

@Injectable({
  providedIn: 'root',
})
export class FffSsoGuard  {
  constructor(
    protected semanticPathService: SemanticPathService,
    protected router: Router,
    private fffSsoService: FffSsoService,
    private fffCryptoService: FffCryptoService,
    private asmComponentService: AsmComponentService
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot
  ): boolean | UrlTree | Observable<boolean | UrlTree> {
    const redirectUrl = atob(route.queryParams['redirectUrl'] || '');

    const parsedHomepageUrl = this.router.parseUrl(
      this.semanticPathService.get('home') ?? ''
    );
    const token = route.queryParams['token'];
    const asm = route.queryParams['asm'];

    // Handle when no token is present in the query
    if (!token) {
      return parsedHomepageUrl;
    }

    const parsedRedirecUrl = redirectUrl
      ? this.router.parseUrl(redirectUrl)
      : parsedHomepageUrl;

    return of(this.validateToken(token, redirectUrl || '/', asm)).pipe(
      delay(1000),
      map(() => parsedRedirecUrl)
    );
  }

  validateToken(token: string, redirectUrl: string, asm: string): boolean | UrlTree {
    const decryptedToken = this.fffCryptoService.decrypt(token);
    const parsedHomepageUrl = this.router.parseUrl(
      this.semanticPathService.get('home') ?? ''
    );

    // When decryption failed
    if (!decryptedToken) {
      return parsedHomepageUrl;
    }
    
     
    if(asm === "false"){
     this.asmComponentService.unload();
    }

    // Sync login state
    this.fffSsoService.syncLoginStateWithJwtToken(decryptedToken, redirectUrl);
    return this.router.parseUrl(redirectUrl);
  }
}
