<div class="plp">
  <ng-container *ngIf="activeSite$ | async as activeSite">
    <ng-container *ngIf="profile$ | async as profile">
      <ng-container *ngIf="(listOfFavorites$ | async) as listOfFavorites">
        <ng-container
        *ngIf="
          listOfFavorites &&
          listOfFavorites.length > 0;
          else emptyMessage
        "
      >
        <ng-container *ngFor="let favoriteProduct of listOfFavorites">
          <fff-product-list-item [product]="favoriteProduct.product" [user]="profile.user" [b2bunit]="profile.selected" [activeSite]="activeSite">
          </fff-product-list-item>
        </ng-container>
        </ng-container>
      </ng-container>
    </ng-container>
  </ng-container>

  <ng-template #emptyMessage>
    <div class="text-center mt-5">
      <h4 class="h4 mb-4">
        {{
          'fffFavourite.emptyListMessage'
            | cxTranslate
        }}
      </h4>
      <button class="btn btn-primary m-auto" (click)="goToHomePage()">
        {{ 'fffSavedCarts.goToHomePage' | cxTranslate }}
      </button>
    </div>
  </ng-template>
</div>

