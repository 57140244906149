import { Injectable } from '@angular/core';
import { RouterStateSnapshot, UrlTree } from '@angular/router';
import { getCategoryCodeByName } from '@app/fff-config/category/fff-category.utils';
import { isValidPrebookCategory } from '@app/fff-enterprise/fff-prebook-category/services/fff-prebook-category.utils';
import {
  CmsActivatedRouteSnapshot,
  CmsService,
  PageContext,
  PageType,
  ProtectedRoutesGuard,
  RouteLoadStrategy,
  RoutingConfigService,
  RoutingService,
  isNotUndefined,
} from '@spartacus/core';
import { CmsPageGuardService } from '@spartacus/storefront';
import { Observable, of } from 'rxjs';
import { filter, first, switchMap, take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class FffCmsPageGuard  {
  static guardName = 'CmsPageGuard';

  constructor(
    protected routingService: RoutingService,
    protected cmsService: CmsService,
    protected protectedRoutesGuard: ProtectedRoutesGuard,
    protected service: CmsPageGuardService,
    protected routingConfig: RoutingConfigService
  ) {}

  /**
   * Tries to load the CMS page data for the anticipated route and returns:
   * - `true` - if it can be activated
   * - `false` - if it cannot be activated
   * - `UrlTree` - if user should be redirected to a given `UrlTree`
   *
   * If the route can be activated, it fires additional calculations on the CMS components present on this CMS page,
   * based on their configuration (`cmsComponents` config).
   *
   * For more, see docs of the `CmsPageGuardService.canActivatePage`.
   */
  canActivate(
    route: CmsActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> {
    if (state.url.startsWith('/categories')) {
      let categoryCode = route.queryParams['category'];
      const prebook =
        route.queryParams['prebook'] == true ||
        route.queryParams['prebook'] == 'true';
      if (categoryCode) {
        if (isValidPrebookCategory(categoryCode) && !prebook) {
          categoryCode = getCategoryCodeByName(categoryCode);
        }
        const customPageContext: PageContext = {
          id: categoryCode,
          type: PageType.CATEGORY_PAGE,
        };
        this.routingService.changeNextPageContext(customPageContext);
      }
    }
    return this.protectedRoutesGuard.canActivate(route).pipe(
      switchMap(canActivate =>
        canActivate === true
          ? this.routingService.getNextPageContext().pipe(
              filter(isNotUndefined),
              take(1),
              switchMap(pageContext => {
                return this.cmsService
                  .getPage(pageContext, this.shouldReload())
                  .pipe(
                    first(),
                    switchMap(pageData =>
                      pageData
                        ? this.service.canActivatePage(
                            pageContext,
                            pageData,
                            route,
                            state
                          )
                        : this.service.canActivateNotFoundPage(
                            pageContext,
                            route,
                            state
                          )
                    )
                  );
              })
            )
          : of(canActivate)
      )
    );
  }

  /**
   * Returns whether we should reload the CMS page data, even when it was loaded before.
   */
  private shouldReload(): boolean {
    return this.routingConfig.getLoadStrategy() !== RouteLoadStrategy.ONCE;
  }
}
