import { ChangeDetectionStrategy, Component } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { ForgotPasswordComponent } from '@spartacus/user/profile/components';
import { OUTLINED_ICON_TYPE } from 'src/app/models/fff-outline-icons.model';
import { FffForgotPasswordService } from '../../fff-common-services/fff-forgot-password.service';

@Component({
  selector: 'fff-forgot-password',
  templateUrl: './fff-forgot-password.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FffForgotPasswordComponent extends ForgotPasswordComponent {

  outlinedIconTypes = OUTLINED_ICON_TYPE;

  constructor(protected service: FffForgotPasswordService) {
    super(service);
  }

  form: UntypedFormGroup = this.service.form;

  onSubmit(): void {
    if (!this.form.valid) {
      this.form.markAllAsTouched();
      return;
    }
    this.service.email.next(this.form.value.userEmail);
    this.service.requestEmail();
  }

}
