import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { I18nModule, UrlModule } from '@spartacus/core';
import { FffProductBadgeInfoComponent } from './fff-product-badge-info.component';

@NgModule({
    declarations: [
        FffProductBadgeInfoComponent
    ],
    imports: [
        UrlModule,
        BrowserModule,
        RouterModule,
        CommonModule,
        I18nModule,
    ],
    exports: [FffProductBadgeInfoComponent],
    providers: []
})
export class FffProductBadgeInfoModule { }
