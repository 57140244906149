import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ConfigModule, I18nConfig, I18nModule, provideConfig } from '@spartacus/core';
import { GenericLinkModule, IconModule } from '@spartacus/storefront';
import { FffDrawerModule } from 'src/app/shared/drawer/fff-drawer.module';
import { FffChangeAccountDrawerModule } from '../fff-header/fff-change-account/fff-change-account-drawer.module';
import { FffHamburgerHeaderComponent } from './fff-hamburger-header.component';
import { FffHamburgerMenuComponent } from './fff-hamburger-menu.component';
import { FffNavigationAccordionComponent } from './fff-navigation-accordion.component';



@NgModule({
  declarations: [
    FffHamburgerMenuComponent,
    FffNavigationAccordionComponent,
    FffHamburgerHeaderComponent,
  ],
  imports: [
    CommonModule,
    I18nModule,
    FffChangeAccountDrawerModule,
    ConfigModule.withConfig({
      cmsComponents: {
        HamburgerMenuComponent: {
          component: FffHamburgerMenuComponent
        }
      }
    }),
    IconModule,
    GenericLinkModule,
    FffDrawerModule,

  ],
  exports: [
    FffHamburgerMenuComponent
  ], providers: [
    provideConfig({
      i18n: {
        backend: {
          loadPath: 'assets/i18n/{{lng}}/{{ns}}.json',
        },
        chunks: {
          navigationAccordion: ['navigationAccordion'],
          general: ['navigationMenu'],
        },
      },
    } as I18nConfig),
  ],
})

export class FffHamburgerMenuModule { }
