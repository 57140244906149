export const fffSearch = {
  fffSearch: {
    placeholder: 'Search by Product name or NDC Number',
    clear: 'Clear',
    products: 'Products',
    seeAll: 'See all related products',
    noResults: 'No Results Found',
    suggestions: 'Did you mean "',
    ndc: 'NDC',
  },
};
