import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { FffFormControlsModule } from '@app/shared/components/form/fff-form-controls/fff-form-controls.module';
import { FffInputValidationUtilsModule } from '@app/shared/components/input-validation-utils/input-validation-utils.module';
import { NgbToastModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { I18nModule, UrlModule } from '@spartacus/core';
import {
  FormErrorsModule,
  IconModule,
  SpinnerModule,
} from '@spartacus/storefront';
import { FffAddCreditCardDialogComponent } from './fff-add-credit-card-dialog/fff-add-credit-card-dialog.component';

@NgModule({
  declarations: [FffAddCreditCardDialogComponent],
  imports: [
    UrlModule,
    CommonModule,
    I18nModule,
    IconModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    FormErrorsModule,
    NgSelectModule,
    NgbToastModule,
    FffFormControlsModule,
    FffInputValidationUtilsModule,
    SpinnerModule,
  ],
  exports: [FffAddCreditCardDialogComponent],
})
export class FffAddCreditCardModule {}
