import { Component } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { FffDrawerService } from '@app/shared/drawer/fff-drawer.service';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { take } from 'rxjs/operators';

@Component({
	selector: 'fff-estimated-delivery',
	templateUrl: './fff-estimated-delivery.component.html',
})
export class FffEstimatedDeliveryComponent {

	hoveredDate: NgbDate | null = null;
	fromDate: NgbDate | null = null;
	toDate: NgbDate | null = null;
	itemInfo: any;

	poNumber: any;
	maxLength: any;
	startValues: any;
	regex: any;
	regexNoSpecial = new RegExp(`^[A-Za-z0-9- ]+$`);
	poRules: any = {};

	checkoutLoginForm: UntypedFormGroup | undefined;

	constructor(
		private drawerService: FffDrawerService,
		protected formBuilder: UntypedFormBuilder,
	) {

		this.drawerService.getContent().pipe(take(1)).subscribe((res) => {
			this.itemInfo = res.data;
			this.poRules = this.itemInfo.poRules;
		})

	}

	ngOnInit(): void {
		this.drawerService.setCallbackResponse({});
		this.maxLength = this.poRules && this.poRules.maxCharacters ? this.poRules.maxCharacters : 20;
		this.startValues = this.poRules && this.poRules.startsWith ? this.poRules.startsWith.split(',') : [];
		this.regex = new RegExp(`^(${this.startValues.join('|')})`);

		let validators = [Validators.maxLength(this.maxLength), Validators.pattern(this.regexNoSpecial)];
		validators.push(Validators.pattern(this.regex));

		if (this.itemInfo?.delivery?.isOverThreshold) {
			const group: any = {};
			for (let i = 0; i < this.itemInfo.delivery.prebook.shippingWindows.length; i++) {
				const swindow = this.itemInfo.delivery.prebook.shippingWindows[i];
				group[i] = this.formBuilder.group({
					poNumber: [swindow.poNumber || '', validators],
				});
			}
			this.checkoutLoginForm = new UntypedFormGroup(group);
		} else {
			this.checkoutLoginForm = this.formBuilder.group({
				poNumber: [this.itemInfo?.delivery?.po || '', validators],
			});
		}
	}

	toNgbDate(time: number) {
		let date = new Date(time);
		return new NgbDate(date.getUTCFullYear(), date.getUTCMonth() + 1, date.getUTCDate())
	}

	apply(): void {
		if (this.itemInfo?.delivery?.isOverThreshold) {

			if (this.checkoutLoginForm && !this.checkoutLoginForm.valid) {
				this.checkoutLoginForm.markAllAsTouched();
				return;
			}

			for (let i = 0; i < this.itemInfo.delivery.prebook.shippingWindows.length; i++) {
				let element = this.itemInfo.delivery.prebook.shippingWindows[i];
				element.poNumber = this.checkoutLoginForm?.get(`${i}`)?.get('poNumber')?.value;
			}

			this.close();
		} else {
			if (this.checkoutLoginForm && !this.checkoutLoginForm.valid) {
				this.checkoutLoginForm.markAllAsTouched();
				return;
			}
			this.itemInfo.delivery.po = this.checkoutLoginForm?.value.poNumber;
			this.close();
		}
	}

	close(): void {
		this.drawerService.setCallbackResponse(this.itemInfo);
		this.drawerService.closeDrawer(this.itemInfo);
	}

	formatStartingPOs(str: string) {
		return str.split(',').join(', ').replace(/(,\s)(?!.*,\s)/, ' or ');
	}

}

