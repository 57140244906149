import { Component, HostBinding, TemplateRef } from '@angular/core';
import { FILLED_ICON_TYPE } from '@app/models/fff-filled-icons.model';
import { ToastService } from '@app/shared/services/toast.service';

@Component({
  selector: 'fff-global-toasts',
  templateUrl: './fff-global-toasts.component.html',
  host: {
    class: 'toast-container position-fixed top-0 end-0 p-3',
    style: 'z-index: 1200',
  },
})
export class FffGlobalToastsComponent {
  @HostBinding('d-none') get hasToasts(): boolean {
    return !this.toastService.toast;
  }

  filledIconTypes = FILLED_ICON_TYPE;

  constructor(public toastService: ToastService) {}

  isTemplate(toast: any) {
    return toast.textOrTpl instanceof TemplateRef;
  }
}
