<div class="d-flex flex-column justify-content-between h-100">


    <div class="orderHistoryContainer">
        <div>
            <input [(ngModel)]="code" class="form-control orderHistoryInput" placeholder="Search By P.O or Order Confirmation #" />

            <div class="w-100 icon-container" style="margin-left: -5px; pointer-events: none">
                <cx-icon class="cx-icon icon-sm px-2 icon" [type]="outlinedIconTypes.ICON_SEARCH"></cx-icon>
            </div>

        </div>

        <div class="text-brand-primary-80 font-size-body-xl font-weight-bold filter-title mt-4">
            {{'fffOrderHistoryFilter.status' | cxTranslate}}
        </div>

        <div>
            <input [(ngModel)]="open" class="checkbox checkbox-sm " type="checkbox" value="open" id="open">
            <label class="checkbox-label filter-option" for="open">
                {{'fffOrderHistoryFilter.open' | cxTranslate}}

            </label>
        </div>

        <div>
            <input [(ngModel)]="partiallyShipped" class="checkbox checkbox-sm" type="checkbox" value="partiallyShipped"
                id="shipped">
            <label class="checkbox-label filter-option" for="shipped">
                {{'fffOrderHistoryFilter.partiallyShipped' | cxTranslate}}
            </label>
        </div>

        <div>
            <input [(ngModel)]="fullyShipped" class="checkbox checkbox-sm" type="checkbox" value="fullyShipped"
                id="fullyshipped">
            <label class="checkbox-label filter-option" for="fullyshipped">
                {{'fffOrderHistoryFilter.fullyShipped' | cxTranslate}}
            </label>
        </div>

        <div class="text-brand-primary-80 font-size-body-xl filter-title font-weight-bold">
            {{'fffOrderHistoryFilter.date' | cxTranslate}}
        </div>

        {{'fffOrderHistoryFilter.from' | cxTranslate}}

        <div class="mb-3">
            <input type="text" [(ngModel)]="fromDate" class="form-control dateInput" ngbDatepicker #d="ngbDatepicker"/>
            <div class="w-100 icon-container">
                <button (click)="d.toggle()" style="background: none ; border: none">
                    <cx-icon class="cx-icon icon-sm px-2 icon" [type]="outlinedIconTypes.ICON_CALENDAR_EVENT"></cx-icon>
                </button>
            </div>
        </div>


        {{'fffOrderHistoryFilter.to' | cxTranslate}}
        <div class="mb-4">
            <input type="text" [(ngModel)]="toDate" class="form-control dateInput" ngbDatepicker #d1="ngbDatepicker"/>
            <div class="w-100 icon-container">
                <button (click)="d1.toggle()" style="background: none ; border: none">
                    <cx-icon class="cx-icon icon-sm px-2 icon" [type]="outlinedIconTypes.ICON_CALENDAR_EVENT"></cx-icon>
                </button>
            </div>
        </div>

        <div class="text-brand-primary-80 font-size-body-xl filter-title font-weight-bold">
            {{'fffOrderHistoryFilter.csosActions' | cxTranslate}}
        </div>

        <div>
            <input [(ngModel)]="requiresSignature" class="checkbox checkbox-sm" type="checkbox" value="" id="requiresSignature" (input)="removeParameter()">
            <label class="checkbox-label filter-option" for="requiresSignature">
                {{'fffOrderHistoryFilter.signature' | cxTranslate}}
            </label>
        </div>

        <div>
            <input [(ngModel)]="signed" class="checkbox checkbox-sm" type="checkbox" value="" id="signed">
            <label class="checkbox-label filter-option" for="signed">
                {{'fffOrderHistoryFilter.signed' | cxTranslate}}
            </label>
        </div>
    </div>

    <div class="minicart-footer-container">
        <button class="btn btn-primary btn-xs w-100" (click)="applyFilter()" [disabled]="isApplyDisabled()">
            {{'fffOrderHistoryFilter.apply' | cxTranslate}}

        </button>
    </div>


</div>