export const fffEmptySearch = {
  fffEmptySearch: {
    header: 'Your Shopping Cart (0 Items)',
    text: `
      <p class="text-center mb-0"> You have no items </p>
      <p class="text-center mb-0"> in your shopping cart </p>
    `,
    action: 'Continue Shopping',
  },
};

export const cart = {
  orderCost: {
    discount: 'FFF Discount',
    discountConfirmation: 'You saved with your discount',
    saved: 'You saved',
    withFFFDiscount: 'with your FFF discount',
    estimatedShipping: 'Estimated Shipping',
    freeDeliveryText: 'FREE',
    freeDeliveryPaid: '(paid by FFF)',
    salesTax: 'Federal Excise Tax',
    subtotal: 'Subtotal',
    total: 'Total',
    toBeDetermined: 'TBD',
    expiredLicense:
      'Your CSOS license is invalid. A valid license is required to receive this product.',
    uploadNow: 'Upload Now',
    yourShoppingCart: 'Your Shopping Cart ({{items}} Items)',
  },
};

export const fffCart = {
  fffCart: {
    loading: 'Loading ...',
    updateCartTitle:
      'Quantities in your cart have been changed or have been removed',
    updateCartSubtitle:
      'Please click update cart to refresh inventories and shipping locations.',
    updateCart: 'Update Cart',
    unavailableItemsMessage:
      'Please note these items are no longer available and have been removed from your cart.',
    unavailableItemMessage:
      'Please note this item is no longer available and have been removed from your cart.',
    unavailableItems: 'Unavailable Items',
    cartItems: 'Cart Items',
    contactSupport: 'Contact Support ',
    unavailableTooltipMsg:
      'for more information regarding why these product are unavailable.',
    showUnavailableItems: 'Show Unavailable Items',
    hideUnavailableItems: 'Hide Unavailable Items',
  },
};
export const fffAddCart = {
  fffAddCart: {
    category: {
      notRapidCommit: 'Rapid Commit items must be ordered separately',
    },
    customer: {
      blocked: 'Customer has been blocked',
    },
    mixed: {
      seasons: 'Cannot mix in season and pre-season products in a single cart',
    },
    unauthorized: 'User does not have the security to add to cart',
    schedule: {
      unauthorized:
        'User is not able to purchase product based on drug schedule',
    },
    gpo: 'There is no GPO assigned to your account',
    dea: {
      license: "Your license type doesn't match with your COT value",
    },
  },
};
export const fffCartSummary = {
  fffCartSummary: {
    checkout: 'Checkout',
    note: 'Some of the items in this order are being processed by a split biller',
    saveCart: 'Save Cart for Future Orders',
    supportText: 'Wow! Customer Care (800) 843-7477',
    text: 'or',
  },
};

export const fffOrderSummary = {
  fffOrderSummary: {
    supportText: 'Wow! Customer Care (800) 843-7477',
    placeOrder: 'Place Order',
    saveRecurringOrder: 'Save Recurring Order',
    termsConditions: `I confirm that I have read and agreed with the
      <a class="terms-conditions cursor-pointer text-underline">Terms & Conditions</a>`,
    messages: {
      cancelRecurringOrder:
        "Your recurring order '{{ recurringOrder }}' has been cancelled",
      saveRecurringOrder:
        "Your recurring order '{{ recurringOrder }}' has been created and is Resumed",
      skipRecurringOrder:
        'Your next order for Recurring Order #{{ recurringOrder }}, on {{ skipDate }}, will now be skipped',
      updateRecurringOrder:
        'The changes to your Recurring Order #{{ recurringOrder }} have been saved',
      recurringOrderResumed:
        'Your Recurring Order #{{ recurringOrder }} is Resumed now',
      recurringOrderPaused:
        'Your Recurring Order #{{ recurringOrder }} is Paused now',
    },
    messageTextForLockedcart:
      'Your submitted order is still being processed, please allow some time before proceeding. If unable to continue, please contact Wow! Customer Care at (800) 843-7477',
  },
};

export const fffSaveCart = {
  fffSaveCart: {
    header: 'Save Cart',
    subtitle: 'Identify your cart',
    form: {
      cartName: 'Cart Name',
      cartNamePlaceholder: 'Enter Cart Name',
      cartDescription: 'Cart Description',
      cartDescriptionPlaceholder: 'Enter description',
      removeItems: 'Remove items from the active cart when I save this one.',
      info: 'You can restore your cart at a later time from Saved Carts in My Account.',
    },
    actions: {
      saveCart: 'Save Cart',
      cancel: 'Cancel',
      edit: 'Edit Order',
    },
    messages: {
      createSavedCart:
        "Your cart '{{ cartName }}' has been saved for future use",
    },
  },
};

export const fffCartHeader = {
  fffCartHeader: {
    titleNoItems: 'Your Shopping Cart',
    title: 'Your Shopping Cart ({{items}} Items)',
    header:
      'The cut-off time for same day shipping has passed. This order will ship on next business day.',
    defaultMessage:
      'Expedited shipping options are available at checkout. If you have questions or concerns, contact Wow! Customer Care using <a class="live-chat cursor-pointer text-underline">Live Chat</a> or by calling (800) 843-7477.',
    ship: {
      past: {
        cutoff: {
          refrigerated:
            'It is past the cutoff for shipping refrigerated orders this week.  Please note this order will ship Monday for Tuesday delivery.',
        },
      },
      pr: {
        immediate: {
          processing:
            'Our standard shipping cutoff time for free immediate processing is 12:00 pm PT.  For immediate processing, select Ship Today below (shipping charges will apply).',
        },
        ship: {
          tuesday:
            'It is past the cutoff for shipping orders to Puerto Rico this week.  Please note this order will ship Monday for Tuesday delivery.',
          wednesday:
            'It is past the cutoff for shipping orders to Puerto Rico this week.  Please note this order will ship Tuesday for Wednesday delivery.',
        },
      },
    },
  },
};

export const fffSuggestedProducts = {
  fffSuggestedProducts: {
    title: 'Suggested Products',
    contractProducts: 'Contract Products',
  },
};
