import {
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { FFFUser } from '@app/reducers';
import { FffUserAccountService } from '@app/shared/services/fff-user-account.service';
import { OUTLINED_ICON_TYPE } from '@model/fff-outline-icons.model';
import { Store } from '@ngrx/store';
import { FffAllocationNavNodes } from '@shared/services/enum.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

export const APPROVAL_ROLE = 'fffapprovalgroup';

@Component({
  selector: 'fff-allocations-tabs',
  templateUrl: './fff-allocations-tabs.component.html',
})
export class FffAllocationsTabsComponent implements OnInit, OnDestroy {
  @Output() selectedNewNavLink: EventEmitter<FffAllocationNavNodes> =
    new EventEmitter<FffAllocationNavNodes>();
  currentNavLinkSelected = FffAllocationNavNodes.currentAllocations;
  allocationNavNodes = FffAllocationNavNodes;
  outlinedIconTypes = OUTLINED_ICON_TYPE;
  isApprovalRole: boolean = false;
  private unsubscribe$ = new Subject<void>();
  constructor(
    private storeProfile: Store<FFFUser>,
    private fffUserAccountService: FffUserAccountService
  ) {}

  ngOnInit(): void {
    this.fffUserAccountService
      .getProfile()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(profile => {
        if (profile && profile.user) {
          this.storeProfile
            .select((state: any) => {
              return state.FFFUser;
            })
            .subscribe(userData => {
              if (
                userData.user?.roles &&
                userData.user.roles.includes(APPROVAL_ROLE)
              ) {
                this.isApprovalRole = true;
              }
            });
        }
      });
  }
  emitSelectedNavLink(navLink: FffAllocationNavNodes): void {
    this.currentNavLinkSelected = navLink;
    this.selectedNewNavLink.emit(navLink);
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
