import {
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  ViewContainerRef,
} from '@angular/core';
import { FffCommunicationService } from '@app/fff-enterprise/fff-common-services/fff-communication.service';
import {
  FFFOrderEntry,
  FFFUpdateSavedCartEntry,
} from '@app/models/fff-cart-data.model';
import { OUTLINED_ICON_TYPE } from '@app/models/fff-outline-icons.model';
import { FffProfile } from '@app/models/fff-profile.model';
import { FffDrawerService } from '@app/shared/drawer/fff-drawer.service';
import { FFFCommonFunctions } from '@app/shared/fff-common-functions';
import { FffUserAccountService } from '@app/shared/services/fff-user-account.service';
import { ToastService } from '@app/shared/services/toast.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Actions, ofType } from '@ngrx/effects';
import { CartActions } from '@spartacus/cart/base/core';
import {
  SavedCartDetailsOverviewComponent,
  SavedCartDetailsService,
} from '@spartacus/cart/saved-cart/components';
import { SavedCartFacade } from '@spartacus/cart/saved-cart/root';
import { RoutingService, TranslationService } from '@spartacus/core';
import { LaunchDialogService, MediaContainer } from '@spartacus/storefront';
import { UserAccountFacade } from '@spartacus/user/account/root';
import { Observable, Subscription } from 'rxjs';
import { switchMap, take, tap } from 'rxjs/operators';
import { getProductImage } from 'src/assets/utils/fff-product-utils';
import { FffRestoreSavedCartDrawerBodyComponent } from '../fff-restore-saved-cart-drawer/fff-restore-saved-cart-drawer-body.component';
import { FffRestoreSavedCartDrawerFooterComponent } from '../fff-restore-saved-cart-drawer/fff-restore-saved-cart-drawer-footer.component';
import { FffRestoreSavedCartDrawerHeaderComponent } from '../fff-restore-saved-cart-drawer/fff-restore-saved-cart-drawer-header.component';
import { FffRestoreSavedCartDrawerService } from '../fff-restore-saved-cart-drawer/fff-restore-saved-cart-drawer.service';
import { FffSavedCartDetailService } from './fff-saved-cart-detail.service';

@Component({
  selector: 'fff-saved-cart-detail',
  templateUrl: './fff-saved-cart-detail.component.html',
})
export class FffSavedCartDetailComponent
  extends SavedCartDetailsOverviewComponent
  implements OnInit, OnDestroy
{
  userData$: Observable<any> = this.userAccountDetails.get();
  b2bUnitData$: Observable<any> = this.userData$.pipe(
    switchMap(user => {
      return this.communicationService.getCurrentAccount(user);
    })
  );
  entries: FFFOrderEntry[] = [];

  cartDetailData$ = this.savedCartDetailsService.getCartDetails().pipe(
    tap(cart => {
      this.entries = cart?.entries
        ? JSON.parse(JSON.stringify(cart?.entries))
        : [];
      this.updateAddonQuantities();
    })
  );

  outlinedIconTypes = OUTLINED_ICON_TYPE;
  showWarning: boolean = true;
  isEditable: boolean = false;
  isUpdating: boolean = false;
  subs = new Subscription();
  isOfVaccineTypeI = FFFCommonFunctions.isOfVaccineType;
  getAltPriceI = FFFCommonFunctions.getAltPrice;

  get isCartEmpty(): boolean {
    return (
      this.isEditable &&
      this.entries.filter(entry => !entry.addedToDeletion)?.length === 0
    );
  }

  get isModified(): boolean {
    return !!(this.isEditable && this.entries.find(entry => entry.touched));
  }

  get hasInvalidEntries(): boolean {
    return !!(this.isEditable && this.entries.some(entry => entry.invalid));
  }

  profile$: Observable<FffProfile | undefined> =
    this.fffAccountService.getProfile();

  constructor(
    private userAccountDetails: UserAccountFacade,
    private communicationService: FffCommunicationService,
    protected routing: RoutingService,
    protected savedCartService: SavedCartFacade,
    protected savedCartDetailsService: SavedCartDetailsService,
    protected translationService: TranslationService,
    protected vcr: ViewContainerRef,
    protected launchDialogService: LaunchDialogService,
    private restoreSavedCartDrawerService: FffRestoreSavedCartDrawerService,
    private drawerService: FffDrawerService,
    private cd: ChangeDetectorRef,
    private modal: NgbModal,
    private fffSavedCartDetailService: FffSavedCartDetailService,
    private actions: Actions,
    private toastService: ToastService,
    protected fffAccountService: FffUserAccountService
  ) {
    super(
      savedCartDetailsService,
      translationService,
      vcr,
      launchDialogService
    );
  }

  ngOnInit(): void {
    this.subs.add(
      this.routing.getRouterState().subscribe(context => {
        this.isEditable = !!context?.state.url?.endsWith('/edit');
        this.cd.markForCheck();
      })
    );
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this.subs.unsubscribe();
  }

  updateAddonQuantities() {
    let adminSetQuantity: number = 0;
    let adminSetGroupIndex: number = 0;
    let yConnectorQuantity: number = 0;
    let yConnectorGroupIndex: number = 0;
    this.entries.forEach((entry: FFFOrderEntry) => {
      if (entry?.entryGroupNumbers?.length) {
        entry?.entryGroupNumbers?.forEach((eNumber: number) => {
          let minOrderQuantity = entry?.minOrderQuantity ?? 0;
          if (
            entry?.adminSet &&
            this.entries[eNumber].product?.name
              ?.toLowerCase()
              ?.includes('admin')
          ) {
            if (adminSetQuantity > 0 && adminSetGroupIndex === eNumber) {
              adminSetQuantity += (entry?.quantity ?? 0) * minOrderQuantity;
              this.entries[eNumber].quantity = adminSetQuantity;
            } else {
              adminSetQuantity = (entry?.quantity ?? 0) * minOrderQuantity;
              adminSetGroupIndex = eNumber;
              this.entries[eNumber].quantity = adminSetQuantity;
            }
          } else if (entry?.yconnector) {
            if (yConnectorQuantity > 0 && yConnectorGroupIndex === eNumber) {
              yConnectorQuantity += +Math.ceil(
                (entry?.quantity ?? 0) * (minOrderQuantity / 2)
              );
              this.entries[eNumber].quantity = yConnectorQuantity;
            } else {
              yConnectorQuantity = +Math.ceil(
                (entry?.quantity ?? 0) * (minOrderQuantity / 2)
              );
              yConnectorGroupIndex = eNumber;
              this.entries[eNumber].quantity = yConnectorQuantity;
            }
          }
        });
      }
    });
  }

  getShippingAddress(b2bUnitData: any): string {
    let shippingAddress = '';
    b2bUnitData.addresses.forEach((addr: any) => {
      if (addr.shippingAddress && addr.formattedAddress) {
        shippingAddress = addr.formattedAddress;
      }
    });
    return shippingAddress;
  }

  getEntries(cartData: any): FFFOrderEntry[] {
    return cartData.entries;
  }

  getData(product: any) {
    return getProductImage(product.images) as MediaContainer;
  }

  createOrder(savedCartData: any): void {
    this.restoreSavedCartDrawerService.savedCart.next(savedCartData);

    this.drawerService.setContent({
      drawerHeader: FffRestoreSavedCartDrawerHeaderComponent as Component,
      animation: 'SideRTL',
      component: FffRestoreSavedCartDrawerBodyComponent as Component,
      drawerFooter: FffRestoreSavedCartDrawerFooterComponent as Component,
      class: 'face-filter-drawer-content',
    });
    this.drawerService.openDrawer();
  }

  deleteCart(savedCartData: any): void {
    this.savedCartService.deleteSavedCart(savedCartData.code);
    this.actions
      .pipe(
        ofType(CartActions.DELETE_CART_SUCCESS),
        take(1),
        tap(() => {
          this.savedCartService.loadSavedCarts();
          this.toastService.showSuccess(
            savedCartData?.isCartEmpty
              ? 'fffSavedCarts.messages.deletedAllEntriesSavedCart'
              : 'fffSavedCarts.messages.deletedSavedCart',
            {
              delay: 3000,
              i18nParams: {
                cartName: savedCartData?.name,
              },
            }
          );
          this.goToSavedCartsList();
        })
      )
      .subscribe();
  }

  goToSavedCartsList(): void {
    this.routing.go({
      cxRoute: 'savedCarts',
    });
  }

  goToEditSavedCartDetails(savedCartId: string): void {
    if (!savedCartId) {
      return;
    }
    this.routing.go({
      cxRoute: 'editSavedCartsDetails',
      params: { savedCartId },
    });
  }

  goToSavedCartDetails(savedCartId: string): void {
    if (!savedCartId) {
      return;
    }
    this.routing.go({
      cxRoute: 'savedCartsDetails',
      params: { savedCartId },
    });
  }

  toggleUpdateState(isUpdating: boolean): void {
    this.isUpdating = isUpdating;
    this.cd.markForCheck();
  }

  saveDetails(savedCart: any): void {
    this.toggleUpdateState(true);

    if (this.isCartEmpty) {
      this.deleteCart({ code: savedCart?.code, isCartEmpty: this.isCartEmpty });
      return;
    }

    let entries: FFFUpdateSavedCartEntry[] = this.entries.map(
      entry =>
        ({
          entryNumber: entry.entryNumber,
          operation: entry.addedToDeletion ? 'delete' : 'update',
          ...(!entry.addedToDeletion && { quantity: +(entry?.quantity || 0) }),
          // ...(entry.addedToDeletion
          //   ? { quantity: 0 }
          //   : { quantity: entry.quantity }),
        } as FFFUpdateSavedCartEntry)
    );

    this.fffSavedCartDetailService
      .editSavedCartEntries(savedCart?.code, entries)
      .pipe(
        tap(() => {
          this.goToSavedCartDetails(savedCart?.code);
        })
      )
      .subscribe(
        () => {
          this.toggleUpdateState(false);
          this.toastService.showSuccess(
            'fffSavedCarts.messages.updatedSavedCart',
            {
              delay: 3000,
              i18nParams: {
                cartName: savedCart?.name,
              },
            }
          );
        },
        () => {
          this.toggleUpdateState(false);
        }
      );
  }

  markEntryAsTouched(entry: FFFOrderEntry): void {
    entry.touched = true;
    this.cd.markForCheck();
  }

  onEntryChange(entry: FFFOrderEntry): void {
    this.markEntryAsTouched(entry);
    this.updateAddonQuantities();
  }

  deleteEntry(modalInstance: any, entry: FFFOrderEntry): void {
    this.markEntryAsTouched(entry);

    const modalRef = this.modal.open(modalInstance, {
      windowClass: 'fff-modal',
    });
    modalRef.dismissed.subscribe(reason => {
      if (reason) {
        entry.addedToDeletion = true;
        let adminSetQuantity: number = 0;
        let yConnectorQuantity: number = 0;
        this.entries.forEach((e: FFFOrderEntry) => {
          if (e?.product?.code === entry?.product?.code) {
            e?.entryGroupNumbers?.forEach((eNumber: number) => {
              let minOrderQuantity = e?.minOrderQuantity ?? 0;
              adminSetQuantity = yConnectorQuantity =
                this.entries[eNumber].quantity ?? 0;
              if (
                e?.adminSet &&
                this.entries[eNumber].product?.name
                  ?.toLowerCase()
                  ?.includes('admin')
              ) {
                if (adminSetQuantity > 0) {
                  adminSetQuantity -= (e?.quantity ?? 0) * minOrderQuantity;
                  this.entries[eNumber].quantity = adminSetQuantity;
                } else {
                  adminSetQuantity = (e?.quantity ?? 0) * minOrderQuantity;
                  this.entries[eNumber].quantity = adminSetQuantity;
                }
                this.entries[eNumber].addedToDeletion = !(adminSetQuantity > 0);
              } else if (e?.yconnector) {
                if (yConnectorQuantity > 0) {
                  yConnectorQuantity -= +Math.ceil(
                    (e?.quantity ?? 0) * (minOrderQuantity / 2)
                  );
                  this.entries[eNumber].quantity = yConnectorQuantity;
                } else {
                  yConnectorQuantity = +Math.ceil(
                    (e?.quantity ?? 0) * (minOrderQuantity / 2)
                  );
                }
                this.entries[eNumber].addedToDeletion = !(
                  yConnectorQuantity > 0
                );
              }
            });
          }
        });

        this.cd.markForCheck();
      }
    });
  }

  validateEntryQuantity(entry: FFFOrderEntry) {
    entry.canShowTooltip = true;
    const regex = new RegExp('^[0-9]+$');
    const isReqExpPassed = regex.test((entry.quantity || '').toString());
    entry.invalid = !isReqExpPassed || !entry.quantity || entry.quantity <= 0;
    this.cd.markForCheck();
  }

  isInvalidEntry(entry: FFFOrderEntry): boolean {
    return !!entry.invalid && !!entry.canShowTooltip;
  }

  getAccountTypeLabel(
    accountUid: string,
    accountType: string | undefined
  ): string {
    return `${accountUid}${accountType}`;
  }
}
