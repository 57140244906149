export const fffSavedCarts = {
  fffSavedCarts: {
    title: 'Saved Carts',
    filter: 'Filter',
    name: 'Cart Name',
    description: 'Description',
    dateSaved: 'Date Saved',
    numberItems: '# Items',
    actions: 'Actions',
    items: 'Items',
    createOrder: 'Create Order',
    delete: 'Delete',
    editCart: 'Edit Cart',
    deleteCart: 'Delete Cart',
    goToHomePage: 'Go to Homepage',
    emptyCartMessage: 'You have no carts in your Saved Carts list at this time',
    savedCartMessage: {
      title: 'Warning',
      errorCreateOrder: 'Failed to Create Order',
    },
    messages: {
      createOrderSavedCart:
        'Your order has been created, you may checkout or continue shopping',
      deleteConfirmationSavedCart:
        "Are you sure you would like to delete the Saved Cart, '{{ cartName }}'?",
      deletedSavedCart: "Your saved cart '{{ cartName }}' has been deleted",
      deletedAllEntriesSavedCart: 'All your saved cart has been deleted',
      updatedSavedCart:
        "The changes to your cart '{{ cartName }}' have been saved",
    },
  },
};

export const fffSavedCartDetail = {
  fffSavedCartDetail: {
    informationTitle:
      'Details about this saved cart may change when you create a new order.',
    informationMessage:
      'Pricing and availability is determined when the order is submitted and prices may be subject to change.',
    dateSaved: 'Date Saved:',
    quantity: 'Qty:',
    filter: 'Filter',
    createOrder: 'Create Order',
    editCart: 'Edit Saved Cart',
    deleteCart: 'Delete Saved Cart',
    or: 'or',
    customerCare: 'Wow! Customer Care (800) 843-7477',
    saveChanges: 'Save Changes',
    cancel: 'Cancel',
    notice: 'Notice',
    acknowledgeAndProceed: 'Acknowledge and Proceed',
    removeItemMessage:
      'Once you click save, items that are removed cannot be added back. If you wish to continue, click Acknowledge and Proceed.',
    emptyCartMessage:
      'Your saved cart is currently empty. Once you click save, this saved cart will be deleted.',
    invalidQuantityTooltipText: 'Please enter a valid quantity.',
  },
};

export const fffRestoreSavedCartDrawer = {
  fffRestoreSavedCartDrawer: {
    title: 'Create Order',
    subtitle: 'Make cart active',
    message:
      'This cart will be restored to your active shopping cart. When you restore, your current cart contents will be replaced. You can make changes and purchase once the cart is restored. ',
    restoreCart: 'Restore Cart',
    cancel: 'Cancel',
  },
};
