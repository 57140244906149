<div
  class="cart-item-container"
  *ngIf="profile && entries?.length; else loading"
>
  <ng-container *ngFor="let item of entries; let i = index">
    <div class="col-12 d-flex flex-wrap">
      <div class="col-lg-4 col-12 px-0">
        <fff-entry-simple
          [item]="item"
          [b2BUnitSplitBiller]="
            profile.selected ? profile.selected.splitBiller : false
          "
          [customerSplitBiller]="
            profile.selected ? profile.selected.autoSplitBillerActive : false
          "
          [accountUid]="profile.selected ? profile.selected.uid : ''"
        ></fff-entry-simple>
      </div>

      <div class="col-lg-5 col-12">
        <fff-shipping-select
          [item]="item"
          [pos]="i"
          (checkout)="updateShipping($event)"
          [poNumber]="poNumber"
          [cart]="currentCart"
          [poRules]="poRules"
        ></fff-shipping-select>
      </div>

      <div class="col-lg-3 col-12 px-0 text-right">
        <p class="body-text-xl body-text-demi mb-0 total">
          {{ item.totalPrice.formattedValue }}
        </p>
        <p class="mb-0" *ngIf="getTax(item) !== 0">
          {{ 'fffCheckout.federalExciseTax' | cxTranslate }}: ${{
            getTax(item) | number : '1.2-2'
          }}
        </p>
        <p class="mb-0" *ngIf="getShippingCost(i) !== 0">
          {{ 'fffCheckout.shipping' | cxTranslate }}:
          <span class="shipping-cost"
            >${{ getShippingCost(i) | number : '1.2-2' }}
          </span>
        </p>

        <ng-container>
          <p class="text-brand-primary-80 body-text-xl mb-1 item-price">
            {{ item.basePrice?.formattedValue }}/{{ item.uom | lowercase }}
          </p>
          <ng-container *ngIf="item?.altDiscPrice && item?.altUom">
            <p class="body-text-xl item-price">
              ${{ item.altDiscPrice }}/{{ item?.altUom | lowercase }}
            </p>
          </ng-container>
        </ng-container>
      </div>
    </div>

    <div class="d-flex flex-wrap cart-item-row">
      <div class="col-12 px-0 summary-divider"></div>
    </div>
  </ng-container>
</div>

<ng-template #loading>
  <fff-cart-loader [isCartItem]="true"></fff-cart-loader>
</ng-template>
