import { ResetPasswordComponentService } from '@spartacus/user/profile/components';
import { Injectable } from '@angular/core';
import { UserPasswordService } from '@spartacus/user/profile/core';
import {GlobalMessageService, GlobalMessageType, HttpErrorModel, RoutingService} from '@spartacus/core';
import { FormControl } from '@angular/forms';
@Injectable({
  providedIn: 'root',
})
export class FffPasswordResetService extends ResetPasswordComponentService {
  constructor(
    protected userPasswordService: UserPasswordService,
    protected routingService: RoutingService,
    protected globalMessage: GlobalMessageService
  ) {
    super(userPasswordService, routingService, globalMessage);
  }
  protected redirect() {
    this.routingService.go('/login', { state: { resettingPassword: true } });
  }
}
