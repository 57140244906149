<div class="container">
  <div class="warning-header p-4 my-4" *ngIf="showWarning">
    <div class="d-flex flex-wrap">
      <div class="col-lg-9 col-12 d-flex flex-wrap order-1 order-lg-0 px-0">
        <div class="col-2 m-auto px-0">
          <cx-icon
            class="cx-icon icon-xl accent-color info-icon"
            [type]="outlinedIconTypes.ICON_INFO_CIRCLE"
          ></cx-icon>
        </div>
        <div class="col-10">
          <p class="mb-0">
            <span class="title body-text-demi">{{
              'fffSavedCartDetail.informationTitle' | cxTranslate
            }}</span>
          </p>
          <p class="mb-0">
            <span>{{
              'fffSavedCartDetail.informationMessage' | cxTranslate
            }}</span>
          </p>
        </div>
      </div>

      <div class="col-lg-3 col-12 px-0">
        <cx-icon
          class="cx-icon cursor-pointer close"
          (click)="showWarning = false"
          [type]="outlinedIconTypes.ICON_X"
        >
        </cx-icon>
      </div>
    </div>
  </div>

  <div class="header-details p-4">
    <div class="row">
      <div class="col-md-6 col-sm-12">
        <ng-container *ngIf="userData$ | async as userData">
          <p class="mb-0">
            <span class="body-text-demi">{{
              'fffCheckout.accountNumber' | cxTranslate
            }}</span
            >&nbsp;
            <span>{{ userData.orgUnit.uid }}</span>
          </p>
          <p class="mb-0">
            <span class="body-text-demi">{{
              'fffCheckout.company' | cxTranslate
            }}</span
            >&nbsp;
            <span>{{ userData.orgUnit.name }}</span>
          </p>
          <p class="mb-0">
            <span class="body-text-demi">{{
              'fffCheckout.emailAddress' | cxTranslate
            }}</span
            >&nbsp;
            <span>{{ userData.uid }}</span>
          </p>
        </ng-container>
      </div>
      <div class="col-md-6 col-sm-12">
        <ng-container *ngIf="b2bUnitData$ | async as b2bUnitData">
          <p class="mb-0">
            <span class="body-text-demi">{{
              'fffCheckout.shipToAddress' | cxTranslate
            }}</span
            >&nbsp;
            <span>{{ getShippingAddress(b2bUnitData) }}</span>
          </p>
          <p class="mb-0">
            <span class="body-text-demi">{{
              'fffCheckout.deliveryTerms' | cxTranslate
            }}</span
            >&nbsp;
            <span>{{ b2bUnitData.deliveryTermsDesc }}</span>
          </p>
          <p class="mb-0">
            <span class="body-text-demi">{{
              'fffCheckout.paymentTerms' | cxTranslate
            }}</span
            >&nbsp;
            <span>{{ b2bUnitData.paymentTermsDesc }}</span>
          </p>
        </ng-container>
      </div>
    </div>
  </div>

  <div class="py-4" *ngIf="cartDetailData$ | async as cartData">
    <div class="d-flex flex-wrap">
      <div class="pl-0 col-12 col-lg-9">
        <div class="col-12 summary">
          <div class="header text-center">{{ cartData.name }}</div>
          <div class="d-flex flex-wrap col-12">
            <div class="col-9 px-0">{{ cartData.description }}</div>
            <div class="col-3 px-0 text-right">
              {{ 'fffSavedCartDetail.dateSaved' | cxTranslate }}
              {{ cartData.saveTime | date : 'MM/dd/yyyy' }}
            </div>
          </div>
          <fff-saved-cart-empty *ngIf="isCartEmpty"></fff-saved-cart-empty>
          <ng-container *ngFor="let item of entries">
            <div
              *ngIf="!item.addedToDeletion"
              class="d-flex flex-wrap col-12 px-0"
            >
              <div class="col-12 my-4">
                <div class="entry-divider"></div>
              </div>

              <div class="col-12">
                <div class="entry-content d-flex flex-wrap row">
                  <div class="col-3">
                    <cx-media
                      [container]="getData(item.product)"
                      class="media"
                      format="thumbnail"
                      role="presentation"
                    >
                    </cx-media>
                  </div>

                  <div class="col-9 col-lg-5">
                    <div class="entry-name">
                      <a
                        class="entry-accent"
                        [routerLink]="item?.product?.url"
                        >{{ item?.product?.name }}</a
                      >
                    </div>
                    <div>{{ item?.product?.manufacturer }}</div>
                    <div>
                      {{ 'fffProduct.lblNdc' | cxTranslate }}
                      {{ item?.product?.ndc }}
                    </div>
                    <div>
                      {{ 'fffProduct.lblSku' | cxTranslate }}
                      {{ item?.product?.code }}
                    </div>
                    <ng-container *ngIf="profile$ | async as profile">
                      <div *ngIf="'S' !== item?.accountType">
                        {{ 'fffProduct.lblAccount' | cxTranslate }}:
                        {{
                          'AUTO' === item?.accountType
                            ? ('fffProduct.lblAccountAutoSplitBiller'
                              | cxTranslate)
                            : getAccountTypeLabel(
                                profile.selected ? profile.selected.uid : '',
                                item?.accountType
                              )
                        }}
                      </div>
                    </ng-container>

                    <div *ngIf="item?.warehouse">
                      {{ 'fffProduct.lblWarehouse' | cxTranslate }}:
                      {{ item?.warehouse }}
                    </div>
                  </div>

                  <div class="col-12 col-lg-4">
                    <!-- Default quantity label -->
                    <ng-container *ngIf="!isEditable">
                      <div class="text-right entry-accent">
                        {{ 'fffSavedCartDetail.quantity' | cxTranslate }}
                        {{ item?.quantity }} {{ item?.uom }}
                      </div>
                      <ng-container
                        *ngTemplateOutlet="
                          priceDetails;
                          context: { item: item }
                        "
                      >
                      </ng-container>
                    </ng-container>

                    <!-- Display editable quantity field -->
                    <ng-container *ngIf="isEditable">
                      <div
                        class="d-flex align-items-center align-items-lg-end flex-column justify-content-center text-center"
                      >
                        <div class="input-price mt-3 mt-lg-0">
                          <div class="qty body-text-sm">
                            {{ 'fffSavedCartDetail.quantity' | cxTranslate }}
                          </div>
                          <div class="input-item position-relative">
                            <fff-tooltip
                              *ngIf="isInvalidEntry(item)"
                              [text]="
                                'fffSavedCartDetail.invalidQuantityTooltipText'
                                  | cxTranslate
                              "
                              class="warn-tooltip"
                              (closed)="item.canShowTooltip = false"
                            ></fff-tooltip>
                            <input
                              class="text-center"
                              [(ngModel)]="item.quantity"
                              maxlength="4"
                              name="quantity"
                              (input)="onEntryChange(item)"
                              (focus)="item.canShowTooltip = true"
                              (ngModelChange)="validateEntryQuantity(item)"
                              [disabled]="
                                !item?.adminSet &&
                                !item?.basePrice?.value &&
                                !item?.yconnector
                              "
                            />
                            {{ item?.uom }}
                          </div>

                          <ng-container
                            *ngTemplateOutlet="
                              priceDetails;
                              context: { item: item }
                            "
                          >
                          </ng-container>

                          <div class="qty">
                            <button
                              type="button"
                              class="delete-action mx-auto mt-2 cursor-pointer btn btn-xxs text-center text-danger"
                              (click)="deleteEntry(removeAlertModal, item)"
                            >
                              {{ 'common.delete' | cxTranslate }}
                            </button>
                          </div>
                        </div>
                      </div>
                    </ng-container>
                  </div>
                </div>
              </div>
            </div>
          </ng-container>
        </div>
      </div>

      <div class="col-12 col-lg-3 px-0">
        <div class="d-flex flex-wrap actions">
          <!-- Actions: Cart Details Page -->
          <ng-container *ngIf="!isEditable">
            <button
              class="col-12 btn btn-primary"
              (click)="createOrder(cartData)"
            >
              {{ 'fffSavedCartDetail.createOrder' | cxTranslate }}
            </button>
            <span class="col-12 text-center">{{
              'fffSavedCartDetail.or' | cxTranslate
            }}</span>
            <button
              class="col-12 btn btn-secondary"
              (click)="goToEditSavedCartDetails(cartData?.code ?? '')"
            >
              {{ 'fffSavedCartDetail.editCart' | cxTranslate }}
            </button>
            <button
              class="col-12 btn btn-secondary"
              (click)="deleteCart(cartData)"
            >
              {{ 'fffSavedCartDetail.deleteCart' | cxTranslate }}
            </button>
          </ng-container>

          <!-- Actions: Edit Cart Details Page -->
          <ng-container *ngIf="isEditable">
            <button
              class="col-12 btn btn-primary"
              (click)="saveDetails(cartData)"
              [disabled]="isUpdating || !isModified || hasInvalidEntries"
            >
              {{ 'fffSavedCartDetail.saveChanges' | cxTranslate }}
            </button>
            <span class="col-12 text-center">{{
              'fffSavedCartDetail.or' | cxTranslate
            }}</span>
            <button
              class="col-12 btn btn-secondary"
              (click)="goToSavedCartDetails(cartData?.code ?? '')"
            >
              {{ 'fffSavedCartDetail.cancel' | cxTranslate }}
            </button>
          </ng-container>

          <span class="body-text-demi accent-color">{{
            'fffSavedCartDetail.customerCare' | cxTranslate
          }}</span>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #removeAlertModal let-modal>
  <div class="modal-body">
    <div class="title">{{ 'fffSavedCartDetail.notice' | cxTranslate }}</div>
    <div class="message">
      {{ 'fffSavedCartDetail.removeItemMessage' | cxTranslate }}
    </div>
  </div>
  <div class="modal-footer d-flex flex-column">
    <button
      type="button"
      class="btn btn-primary w-100"
      (click)="modal.dismiss(true)"
    >
      {{ 'fffSavedCartDetail.acknowledgeAndProceed' | cxTranslate }}
    </button>
    <button
      type="button"
      class="btn btn-secondary w-100"
      (click)="modal.dismiss(false)"
    >
      {{ 'fffSavedCartDetail.cancel' | cxTranslate }}
    </button>
  </div>
</ng-template>

<ng-template #priceDetails let-item="item">
  <div
    class="d-flex mt-3"
    [ngClass]="isEditable ? 'justify-content-center' : 'justify-content-end'"
  >
    <ng-container
      *ngIf="
        item?.unitConversionFactor && item?.altUom && !isOfVaccineTypeI(item)
      "
    >
      <div class="volume body-text-sm d-inline-block">
        1 {{ item?.uom }} = {{ item?.unitConversionFactor }}
        {{ item?.altUom }}
      </div>
    </ng-container>
    <ng-container *ngIf="item?.altUnitConversion">
      <div class="volume body-text-sm d-inline-block">
        <span
          *ngIf="
            item?.unitConversionFactor &&
            item?.altUom &&
            !isOfVaccineTypeI(item)
          "
        >
          ,&nbsp;
        </span>
        {{ item?.altUnitConversion }}
      </div>
    </ng-container>

    <ng-container *ngIf="isOfVaccineTypeI(item)">
      <div class="body-text-xs mt-1 text-lowercase">
        1 {{ item.uom }} = 10 DS
      </div>
    </ng-container>
  </div>
</ng-template>
