import { Directive, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[fffAllowOnlyNumbers]',
})
export class FffAllowOnlyNumbersDirective {
  @Input() min: any;
  @Input() max: any;

  @HostListener('keypress', ['$event']) onKeyPress($event: KeyboardEvent) {
    const input = $event.key;

    if (/[0-9]/.test(input)) {
      return true;
    } else {
      $event.preventDefault();
      return false;
    }
  }

  @HostListener('paste', ['$event']) onPaste($event: ClipboardEvent) {
    let pastedText = undefined;

    if ($event.clipboardData && $event.clipboardData.getData) {
      pastedText = $event.clipboardData.getData('text/plain');
    }

    if (!pastedText) {
      return true;
    }

    if (/^[0-9]*$/.test(pastedText)) {
      return true;
    } else {
      $event.preventDefault();
      return false;
    }
  }
}
