import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';

export interface NewReportFilterOption {
  id: string;
  label: string;
}

export enum ReportTypes {
  ALLOCATIONS = 'ALLOCATIONS',
  INVOICE_HISTORY = 'INVOICE_HISTORY',
  ORDER_HISTORY = 'ORDER_HISTORY',
  PRICES = 'PRICES',
}

export enum ReportFrequencyTypes {
  ONCE = 'ONCE',
  WEEKLY = 'WEEKLY',
  MONTHLY = 'MONTHLY',
}

export enum ReportFormatTypes {
  EMAIL = 'EMAIL',
  PDF = 'PDF_DOWNLOAD',
  CSV = 'CSV_DOWNLOAD',
}

export enum ReportRangeTypes {
  PAST_30_DAYS = 'PAST_30_DAYS',
  PREVIOUS_MONTH = 'PREVIOUS_MONTH',
  MONTH_TO_DATE = 'MONTH_TO_DATE',
  PAST_7_DAYS = 'PAST_7_DAYS',
  PREVIOUS_WEEK = 'PREVIOUS_WEEK',
  WEEK_TO_DATE = 'WEEK_TO_DATE',
}

export enum ReportStatusTypes {
  ALL = 'All',
  OPEN = 'Open',
  PARTIALLY_SHIPPED = 'Partially_Shipped',
  FULLY_SHIPPED = 'Fully_Shipped',
  PAID = 'Paid',
}

export interface NewReport {
  reportType: string;
  reportID?: string;
  reportFormat?: string;
  accounts?: string;
  emailIds?: string;
  reportDate?: string;
  firstReportDate?: string;
  products?: string[];
  reportFrequency?: string;
  reportRange?: string;
  status?: string;
  productGroups?: string;
  fromDate?: string;
  toDate?: string;
}

export interface NewReportFormData {
  reportID?: string;
  reportType: string;
  reportFormat?: string;
  reportFrequency?: string;
  accounts: string[];
  emailIds?: string;
  products?: any[];
  productGroups?: string[];
  firstReportDate?: NgbDateStruct;
  fromDate?: NgbDateStruct;
  toDate?: NgbDateStruct;
  reportRange?: string;
  status?: string[];
}
