import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CmsConfig, ConfigModule } from '@spartacus/core';
import { IconModule, SearchBoxModule } from '@spartacus/storefront';
import { FffCommonServicesModule } from '../fff-common-services/fff-common-services.module';
import { FffUtilModule } from "../fff-util/fff-util.module";
import { FffSearchResultComponent } from './fff-search-result/fff-search-result.component';


@NgModule({
  declarations: [
    FffSearchResultComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    FffCommonServicesModule,
    FffUtilModule,
    SearchBoxModule,
    IconModule,
    ConfigModule.withConfig({
      cmsComponents: {
        AccountSearchFlexComponent: {
          component: FffSearchResultComponent,
        },
      },
    } as CmsConfig),
  ]
})
export class FffAccountSearchModule { }
