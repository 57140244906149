import { Breadcrumb } from '@spartacus/core';

export interface FffSearchBreadcrumb extends Breadcrumb {
  categoryUrl?: string;
  parentCategoryName?: string;
}
export interface FffProduct {
  indications?: string;
  ndcInfo?: FffNdcInfoPlP;
  customerAllocation?: FffCustomerAllocation;
  prescribingInformation?: string;
  shoppingDetails?: string;
  firstVariantImage?: string;
  b2bUnitPrices?: string;
  ndc?: string;
  notOrderable?: boolean;
  quantity?: number;
  name?: string;
  manufacturerName?: string;
  sku?: string;
  site?: string;
}

export interface FffNdcInfoPlP {
  ndcDrugForm?: string;
  ndcDrugRoute?: string;
  ndcName?: string;
  ndcStrength?: string;
}

export interface FffCustomerAllocation {
  annualTotalCollection?: number;
  consumedAllocation?: number;
  softAllocation?: number;
  totalAllocation?: number;
  usedAllocation?: number;
}

export enum VACCINES_CATEGORY_TYPES {
  INFLUENZA = 'vaccines-influenza',
  COVID = 'vaccines-covid',
  RSV = 'vaccines-rsv',
}

/**
 * Extending Product interface to accomodate FFF objects.
 * https://sap.github.io/spartacus-docs/type-augmentation/
 */
declare module '@spartacus/core' {
  interface Product extends FffProduct {}
}
