import { Injectable, isDevMode } from '@angular/core';
import { SsoEncDecPayload } from '@app/models/fff-crypto.model';
import { environment } from '@env/environment';
import * as CryptoJS from 'crypto-js';

@Injectable({
  providedIn: 'root',
})
export class FffCryptoService {
  private secretKey = environment.sso.jwt.secret;
  private sub = environment.sso.jwt.sub;

  // Encrypts the payload and returns a JWT token
  encrypt(payload: SsoEncDecPayload = {}): string {
    const header = { alg: 'HS512', typ: 'JWT' };
    const encodedHeader = this.base64UrlEncode(JSON.stringify(header));
    const encodedPayload = this.base64UrlEncode(
      JSON.stringify({
        ...payload,
        sub: this.sub,
      })
    );

    const signature = this.generateSignature(encodedHeader, encodedPayload);

    return `${encodedHeader}.${encodedPayload}.${signature}`;
  }

  // Decrypts a JWT token and returns the payload
  decrypt(token: string): SsoEncDecPayload | null {
    const [encodedHeader, encodedPayload, signature] = token.split('.');
    const decodedPayload = this.base64UrlDecode(encodedPayload);

    // Verify the signature (optional, but recommended)
    const expectedSignature = this.generateSignature(
      encodedHeader,
      encodedPayload
    );

    if (signature !== expectedSignature) {
      if (isDevMode()) {
        console.error('Invalid JWT signature');
      }
      return null;
    }

    return JSON.parse(decodedPayload);
  }

  private base64UrlEncode(data: string): string {
    const base64 = CryptoJS.enc.Base64.stringify(CryptoJS.enc.Utf8.parse(data));
    return base64.replace(/=/g, '').replace(/\+/g, '-').replace(/\//g, '_');
  }

  private base64UrlDecode(base64: string): string {
    const paddedBase64 = base64 + '==='.slice(base64.length % 4);
    return CryptoJS.enc.Base64.parse(paddedBase64).toString(CryptoJS.enc.Utf8);
  }

  private generateSignature(
    encodedHeader: string,
    encodedPayload: string
  ): string {
    const signature = CryptoJS.HmacSHA512(
      `${encodedHeader}.${encodedPayload}`,
      this.secretKey
    );
    return this.base64UrlEncode(signature.toString(CryptoJS.enc.Hex));
  }
}
