import { Component, Input } from '@angular/core';
import { FffCartService } from '@app/fff-enterprise/fff-common-services/fff-cart-service';
import { PhoneEnum } from '@app/shared/services/enum.service';
import { BASE_URL_KEYS } from '@config/content/constants';
import { environment } from '@env/environment';
import { RoutingService } from '@spartacus/core';
import { FffDrawerService } from 'src/app/shared/drawer/fff-drawer.service';
import { FffMiniCartDrawerService } from '../fff-save-cart-drawer.service';

@Component({
  selector: 'fff-mini-cart-footer',
  templateUrl: './fff-mini-cart-footer.component.html',
})
export class FffMiniCartFooterComponent {
  @Input() showActions: boolean = true;
  @Input()
  activeSite: string | undefined;
  cartData$ = this.fffMiniCartDrawerService.cartData$;

  BASE_URL_KEYS = BASE_URL_KEYS;
  constructor(
    private fffMiniCartDrawerService: FffMiniCartDrawerService,
    private drawerService: FffDrawerService,
    private routingService: RoutingService,
    private cartService: FffCartService
  ) {}

  navigateTo(url: String, queryParams: any = {}) {
    this.routingService.go([url], { queryParams });
    this.drawerService.closeDrawer();
  }

  openCustomCarePhone() {
    if (window.innerWidth <= 767) {
      window.open(PhoneEnum.tel + environment.customCarePhone);
    }
  }
  closeDrawer() {
    this.drawerService.closeDrawer();
  }

  checkPrebookCartTrue(cartInfo: any) {
    if (cartInfo.prebookCart) {
      return true;
    } else {
      return false;
    }
  }
}
