<div
  class="text-grey-90 body-text-sm mt-5 mb-2"
  *ngIf="
    cart && !cart.rapidCommit && (isValidBioStatus() || isValidMfvStatus())
  "
>
  <ng-container
    *ngIf="
      baseSite === BASE_URL_KEYS.MY_FLU_VACCINE && isDeliveryWindowVisible();
      else notPrebook
    "
  >
    {{ 'fffShippingSelector.arrivalDate' | cxTranslate }} -
    {{
      baseShipDate === 'TBD'
        ? baseShipDate
        : (baseShipDate | date : 'MM/dd/yyyy')
    }}
    -
  </ng-container>
  <ng-template #notPrebook>
    <span *ngIf="baseArrivalDate !== '0000-00-00'">
      {{ 'fffShippingSelector.arrivalDateInStock' | cxTranslate }} -
    </span>
  </ng-template>

  <span *ngIf="baseArrivalDate !== '0000-00-00'">
    {{ baseArrivalDate | date : 'MM/dd/yyyy' }}
  </span>
  <span *ngIf="item?.arrivalDatePushed">
    <fff-tooltip
      #tooltip
      text="{{ 'fffShippingSelector.arrivalDateTooltipText' | cxTranslate }}"
      class="warn-tooltip fluseason-tooltip-font-size"
    >
    </fff-tooltip>
    <span class="cursor-pointer" (click)="tooltip.isVisible = true">
      <cx-icon
        class="cx-icon d-inline"
        [type]="outlinedIconTypes.ICON_INFO_CIRCLE"
      ></cx-icon>
    </span>
  </span>
</div>
