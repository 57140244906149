import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ConfigModule } from '@spartacus/core';
import { IconModule } from '@spartacus/storefront';
import { SkipLinksComponent } from './fff-skip-links.component';
import { FFFLoadersModule } from '@app/shared/components/fff-loaders/fff-loaders.module';

@NgModule({
  declarations: [SkipLinksComponent],
  imports: [
    CommonModule,
    IconModule,
    FFFLoadersModule,
    ConfigModule.withConfig({
      cmsComponents: {
        CMSLinkComponent: {
          component: SkipLinksComponent,
        },
      },
    }),
  ],
})
export class FffSkipLinksModule { }
