import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Params, RouterStateSnapshot } from '@angular/router';
import { FFFActiveCartService } from '@app/fff-enterprise/fff-custom-cart/fff-active-cart-service';
import { FffPrebookAlertNotificationModalComponent } from '@app/fff-enterprise/fff-prebook-category/fff-prebook-alert-notification/fff-prebook-alert-notification-modal.component';
import { isValidPrebookCategory } from '@app/fff-enterprise/fff-prebook-category/services/fff-prebook-category.utils';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from '@spartacus/core';
import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class FffPrebookPagesGuard  {
  constructor(
    private authService: AuthService,
    private modalService: NgbModal,
    private fffActiveCartService: FFFActiveCartService
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    if (!this.isPrebook(route.queryParams)) {
      return of(true);
    }

    return this.fffActiveCartService.takeActive().pipe(
      switchMap((cart: any) => {
        if (cart?.entries?.length && cart?.prebookCart === false) {
          this.modalService.open(FffPrebookAlertNotificationModalComponent, {
            animation: true,
            backdrop: 'static',
            centered: true,
            windowClass: 'fff-modal',
          });
          return of(false);
        }
        return of(true);
      })
    );
  }

  private isPrebook(queryParams: Params): boolean {
    return (
      isValidPrebookCategory(queryParams['category']) &&
      !!queryParams['prebook']
    );
  }
}
