<ngb-toast
  *ngIf="toastService.toast"
  [class]="toastService.toast.classname"
  [autohide]="true"
  [delay]="toastService.toast.delay || 5000"
  (hidden)="toastService.clear()"
>
  <ng-template [ngIf]="isTemplate(toastService.toast)" [ngIfElse]="text">
    <ng-template
      [ngTemplateOutlet]="toastService.toast.textOrTpl"
    ></ng-template>
  </ng-template>

  <ng-template #text>
    <ng-container *ngIf="toastService.toast.success">
      <cx-icon
        class="cx-icon icon-sm text-primary"
        [type]="filledIconTypes.ICON_CHECK_CIRCLE_FILL"
      ></cx-icon>
      <span [innerHTML]="toastService.toast.textOrTpl"></span>
    </ng-container>
    <ng-container *ngIf="!toastService.toast.success">
      <span [innerHTML]="toastService.toast.textOrTpl"></span>
    </ng-container>
  </ng-template>
</ngb-toast>
