import { Component } from '@angular/core';
import { CsAgentAuthService } from '@spartacus/asm/root';
import { AuthService } from '@spartacus/core';
import { Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';

@Component({
  selector: 'fff-simple-responsive-spacer',
  templateUrl: './fff-simple-responsive-spacer.component.html',
})
export class FffSimpleResponsiveSpacerComponent {
  private unsubscribe$ = new Subject<void>();
  isASMuser!: boolean;
  isLoggedIn$ = this.authService.isUserLoggedIn();
  constructor(
    protected authService: AuthService,
    private csAgentAuthService: CsAgentAuthService
  ) {}
  ngOnInit(): void {
    this.csAgentAuthService
      .isCustomerSupportAgentLoggedIn()
      .pipe(
        takeUntil(this.unsubscribe$),
        tap((isCustomerSupportAgentLoggedIn: any) => {
          this.isASMuser = !!isCustomerSupportAgentLoggedIn;
        })
      )
      .subscribe();
  }
  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
