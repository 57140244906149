<div class="d-flex flex-column justify-content-between h-100">

    <div class="orderHistoryContainer">

        <div *ngIf="itemInfo.delivery.prebook">
            <form *ngIf="checkoutLoginForm" [formGroup]="checkoutLoginForm">

                <div *ngFor="let i of itemInfo.delivery.prebook.shippingWindows ; let index = index">

                    <div class="mb-4" [formGroupName]="index">
                        <div class="d-flex justify-content-between">
                            <p class="mb-0 body-text-lg">{{'fffCheckout.poNumber' | cxTranslate}}</p>
                        </div>
                        <input class="form-control form-control-md" type="text" formControlName="poNumber">

                        <p class="mb-0" style="font-size: 11px">
                            <span *ngIf="poRules.startsWith">
                                {{'fffCheckout.legend1' | cxTranslate}} {{maxLength}}
                                {{'fffCheckout.legend2' | cxTranslate}} {{formatStartingPOs(poRules.startsWith)}}.
                            </span>
                            <span *ngIf="!poRules.startsWith">
                                {{'fffCheckout.legend3' | cxTranslate}}{{maxLength}}
                                {{'fffCheckout.legend4' | cxTranslate}}
                            </span>
                        </p>

                        <cx-form-errors aria-live="assertive" aria-atomic="true"
                            [control]="$any(checkoutLoginForm).controls[index].get('poNumber')"></cx-form-errors>
                    </div>

                    <p class="text-brand-primary-80 body-text-xl body-text-demi mb-2">
                        {{ i.quantityCalculated }} {{ itemInfo.delivery.basicUOM }}
                    </p>

                    <div class="mb-3">

                        <delivery-calendar [itemInfo]="itemInfo" [index]="index"></delivery-calendar>

                    </div>

                </div>
            </form>

        </div>

        <div *ngIf="!itemInfo.delivery.prebook">
            <form *ngIf="checkoutLoginForm" [formGroup]="checkoutLoginForm">
                <div class="mb-4">
                    <span>{{'fffShipDrawer.poNumber' | cxTranslate}}</span>
                    <input type="text" class="form-control" formControlName="poNumber" />
                    <p class="mb-0" style="font-size: 11px">
                        <span *ngIf="poRules.startsWith">
                            {{'fffCheckout.legend1' | cxTranslate}} {{maxLength}}
                            {{'fffCheckout.legend2' | cxTranslate}} {{formatStartingPOs(poRules.startsWith)}}.
                        </span>
                        <span *ngIf="!poRules.startsWith">
                            {{'fffCheckout.legend3' | cxTranslate}}{{maxLength}}
                            {{'fffCheckout.legend4' | cxTranslate}}
                        </span>
                    </p>

                    <cx-form-errors aria-live="assertive" aria-atomic="true"
                        [control]="$any(checkoutLoginForm).get('poNumber')"></cx-form-errors>
                </div>

                <p class="text-brand-primary-80 body-text-xl body-text-demi mb-2">
                    {{itemInfo.delivery.qty}} {{ itemInfo.delivery.basicUOM }}
                </p>

                <delivery-calendar [itemInfo]="itemInfo" [index]="undefined"></delivery-calendar>
            </form>
        </div>

    </div>

    <div class="minicart-footer-container">
        <div class="footer-content">

            <button class="btn btn-primary btn-xs w-100" (click)="apply()">
                {{'fffOrderHistoryFilter.apply' | cxTranslate}}
            </button>

        </div>

    </div>


</div>