import { Cart, OrderEntry } from '@spartacus/cart/base/root';

export interface FFFProductInputEntry {
  key: string;
  unit: string;
  warehouse?: string;
  code: string;
  quantity: number;
  price?: number;
  taxValue: number;
  discountValue?: number;
  uom?: string;
  altUom?: string;
  unitConversionFactor?: number;
  mduConversionFactor?: number;
  altUnitConversion?: string;
  moq?: number;
  sapStockLevelStatus?: string;
  strength?: string;
  batch?: string;
  expirationDate?: string;
  adminSet?: boolean;
  yconnector?: boolean;
  action?: string;
  dropShip?: boolean;
  accountType?: string;
  tieredPricing?: any;
  altDiscPrice?: number;
  directOrder?: boolean;

  categories?: any[];
  entryNumber?: number;
  minOrderQuantity?: number;
}

export interface FFFOrderEntry extends OrderEntry {
  key?: string;
  accountType?: string;
  warehouse?: string;
  backOrder?: boolean;
  code?: string;
  taxValue?: number;
  discountValue?: number;
  uom?: string;
  altUom?: string;
  dropShip?: boolean;
  mduConversionFactor?: number;
  altUnitConversion?: string;
  unitConversionFactor?: number;
  sapStockLevelStatus?: string;
  strength?: string;
  batch?: string;
  expirationDate?: string;
  adminSet?: boolean;
  yconnector?: boolean;
  tieredPricing?: any;
  altDiscPrice?: number;
  directOrder?: boolean;
  addedToDeletion?: boolean;
  touched?: boolean;
  invalid?: boolean;
  canShowTooltip?: boolean;
  minOrderQuantity?: number;
  entryGroupNumbers?: number[];
}
export interface FFFCart extends Cart {
  rapidCommit?: boolean;
  prebookCart?: boolean;
  isLockedForOrdering?: boolean;
}

export interface FFFUpdateSavedCartEntry {
  entryNumber: number;
  quantity: number;
  operation?: 'update' | 'delete';
}
export interface FFFRecurringOrderEntry {
  entryNumber: number;
  quantity: number;
  operation?: 'update' | 'delete';
}

export enum CartSummaryAction {
  REDIRECT_TO_CHECKOUT = 'REDIRECT_TO_CHECKOUT',
  OPEN_SAVE_CART_DIALOG = 'OPEN_SAVE_CART_DIALOG',
}
