import { Component, OnDestroy, OnInit } from '@angular/core';
import { FffAllocationsService } from "@enterprise/fff-allocations/fff-allocations.service";
import { OUTLINED_ICON_TYPE } from '@model/fff-outline-icons.model';
import { FffDrawerService } from '@shared/drawer/fff-drawer.service';
import { FffAllocationNavNodes } from '@shared/services/enum.service';
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

@Component({
  selector: 'fff-search-interactive-allocations-drawer',
  templateUrl: './fff-search-interactive-allocations-drawer.component.html',
})
export class FffSearchInteractiveAllocationsDrawerComponent implements OnInit, OnDestroy {

  private unsubscribe$ = new Subject<void>()
  outlinedIconTypes = OUTLINED_ICON_TYPE;
  productManufacturer: string = '';
  fffAllocationsNavNodes = FffAllocationNavNodes;

  constructor(
    private drawerService: FffDrawerService,
    private fffAllocationsService: FffAllocationsService
  ) { }

  ngOnInit() {
    let filters = localStorage.getItem('allocationsInteractiveFilters');
    if (filters) {
      let parsedFilters = JSON.parse(filters);
      this.productManufacturer = parsedFilters.productManufacturer;
    }
    this.fffAllocationsService.allocationsFilter$.pipe(takeUntil(this.unsubscribe$)).subscribe((filtersData: any) => {
      if (filtersData && FffAllocationNavNodes.interactiveAllocations === filtersData.component) {
        this.productManufacturer = filtersData.filters.productManufacturer;
      }
    });
  }

  sendFilter(productManufacturer: string): void {
    const filters = { productManufacturer: productManufacturer };
    localStorage.setItem('allocationsInteractiveFilters', JSON.stringify(filters));
    this.drawerService.closeDrawer({ component: FffAllocationNavNodes.interactiveAllocations, filters: filters });
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
