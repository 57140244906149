import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import {
  CmsConfig,
  ConfigModule,
  I18nModule,
  UrlModule,
} from '@spartacus/core';
import {
  GenericLinkModule,
  ListNavigationModule,
  MediaModule,
  PageSlotModule,
} from '@spartacus/storefront';
import { FffEmptyListComponent } from './fff-empty-list/fff-empty-list.component';
import { FffEmptyListModule } from './fff-empty-list/fff-empty-list.module';
import { FffProductListItemModule } from './fff-product-list-item/fff-product-list-item.module';
import { FffProductListComponent } from './fff-product-list.component';
import { FffProductSortComponent } from './fff-product-sort/fff-product-sort.component';

@NgModule({
  declarations: [FffProductListComponent, FffProductSortComponent],
  imports: [
    UrlModule,
    BrowserModule,
    CommonModule,
    I18nModule,
    ListNavigationModule,
    FffProductListItemModule,
    FffEmptyListModule,
    PageSlotModule,
    ConfigModule.withConfig({
      cmsComponents: {
        CMSProductListComponent: {
          component: FffProductListComponent,
        },
        SearchResultsListComponent: {
          component: FffProductListComponent,
        },
        ProductGridComponent: {
          component: FffProductListComponent,
        },
        SearchEmptyFlexComponent: {
          component: FffEmptyListComponent,
        },
      },
    } as CmsConfig),
    GenericLinkModule,
    MediaModule,
  ],
  exports: [FffProductSortComponent],
  providers: [],
})
/**
 * Export FffProductList Module.
 * Contains changes for fff-enterprise.
 */
export class FffProductListModule {}
