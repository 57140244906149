import { Component, OnInit } from '@angular/core';
import { OUTLINED_ICON_TYPE } from '@app/models/fff-outline-icons.model';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'fff-update-interactive-allocations-popup',
  templateUrl: './fff-update-interactive-allocations-popup.component.html',
})
export class FffUpdateInteractiveAllocationsPopupComponent implements OnInit {

  outlinedIconTypes = OUTLINED_ICON_TYPE;

  hasErrorData: boolean = false;
  titleMessage: string = 'fffUpdateAllocationsPopUp.title';
  errorDescription: string = '';

  constructor(private modalService: NgbActiveModal) { }

  ngOnInit(): void { }

  close(): void {
    this.modalService.close();
  }

}
