import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CmsConfig, I18nModule, provideConfig } from '@spartacus/core';
import { FffNavigationModule } from '../navigation/navigation.module';
import { FffCategoryNavigationComponent } from './category-navigation.component';

@NgModule({
  imports: [CommonModule, FffNavigationModule, I18nModule],
  providers: [
    provideConfig(<CmsConfig>{
      cmsComponents: {
        CategoryNavigationComponent: {
          component: FffCategoryNavigationComponent,
          data: {
            resetMenuOnClose: true,
          },
        },
      },
    }),
  ],
  declarations: [FffCategoryNavigationComponent],
  exports: [FffCategoryNavigationComponent],
})
export class FffCategoryNavigationModule {}
