<div class="d-flex flex-wrap body-text-lg">

  <div class="content-close-icon cursor-pointer ml-auto d-flex justify-content-between p-2">
    <cx-icon *ngIf="!hasSuccessData" class="cx-icon icon-lg" [type]="outlinedIconTypes.ICON_X" (click)="close()">
    </cx-icon>

    <cx-icon *ngIf="hasSuccessData" class="cx-icon icon-lg" [type]="outlinedIconTypes.ICON_X"
      (click)="continueOrderConfirmation()">
    </cx-icon>
  </div>

  <div class="col-12 text-center content-header">
    <cx-icon class="cx-icon icon-lg pb-3" [type]="outlinedIconTypes.ICON_SHIELD_EXCLAMATION"></cx-icon>
    <span class="col-12 text-center">{{ 'fffCheckoutPlaceOrder.header' | cxTranslate }}</span>
  </div>


  <div class="col-12 text-center cx-dialog-body modal-body content-body">

    <p class="mb-0" *ngIf="hasErrorData">
      {{ 'fffCheckoutPlaceOrder.messageWithErrorReference' | cxTranslate }} <span class="body-text-demi">
        {{ 'fffCheckoutPlaceOrder.errorCode' | cxTranslate }} {{ errorData.code }}
      </span>
    </p>

    <p class="mb-0" *ngIf="!hasErrorData">
      {{ 'fffCheckoutPlaceOrder.message' | cxTranslate }}
    </p>

  </div>

</div>