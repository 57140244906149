import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnInit,
} from '@angular/core';
import { FffAllocationService } from '@app/fff-enterprise/fff-common-services/fff-allocation.service';
import { FffCommunicationService } from '@app/fff-enterprise/fff-common-services/fff-communication.service';
import { FffRingCentralChatBotService } from '@app/fff-enterprise/fff-service-third-party/fff-ring-central-chat-bot.service';
import { OUTLINED_ICON_TYPE } from '@app/models/fff-outline-icons.model';
import { ProductFocusState, UpdatedCartState } from '@app/reducers';
import { BASE_URL_KEYS } from '@config/content/constants';
import { Store } from '@ngrx/store';
import { FffDisplayShippingBannerService } from '@shared/services/fff-display-shipping-banner.service';
import { CartItemListComponent } from '@spartacus/cart/base/components';
import { SelectiveCartService } from '@spartacus/cart/base/core';
import { ActiveCartFacade, MultiCartFacade } from '@spartacus/cart/base/root';
import { TranslationService, UserIdService } from '@spartacus/core';
import { UserAccountFacade } from '@spartacus/user/account/root';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';

@Component({
  selector: 'fff-cart-header-message',
  templateUrl: './fff-cart-header-message.component.html',
})
export class FFFCartHeaderMessageComponent
  extends CartItemListComponent
  implements OnInit, AfterViewInit
{
  @Input() activeSite: string | undefined;
  changes = null;
  updating: boolean = false;

  cartData$: Observable<any> = this.fffActiveCartService.getActive();
  displayShippingBanner$ =
    this.fffDisplayShippingBannerService.getShippingBannerIsDisplayed();

  showWarning: boolean = true;
  outlinedIconTypes = OUTLINED_ICON_TYPE;

  currentTime: Date = this.getCurrentTime();

  DEFAULT_MESSAGE = 'fffCartHeader.defaultMessage';
  BASE_URL_KEYS = BASE_URL_KEYS;
  private SHIP_PR_IMMEDIATE_PROCESSING_MESSAGE =
    'fffCartHeader.ship.pr.immediate.processing';
  private SHIP_PR_SHIP_TUESDAY_MESSAGE = 'fffCartHeader.ship.pr.ship.tuesday';
  private SHIP_PR_SHIP_WEDNESDAY_MESSAGE =
    'fffCartHeader.ship.pr.ship.wednesday';
  private SHIP_PAST_CUTOFF_REFRIGERATED_MESSAGE =
    'fffCartHeader.ship.past.cutoff.refrigerated';

  message$: Observable<string> = this.translationService.translate(
    this.DEFAULT_MESSAGE
  );

  private PUERTO_RICO_PREFIX = 'FPR';
  private PRODUCT_REFRIGERATED = '02';

  private ZERO = 0;
  private TWELVE = 12;
  private FIFTEEN = 15;

  private MONDAY = 1;
  private TUESDAY = 2;
  private THURSDAY = 4;
  private FRIDAY = 5;

  private TODAY_AT_TWELVE = this.getSpecificDate(this.TWELVE, this.ZERO);
  private TODAY_AT_FIFTEEN = this.getSpecificDate(this.FIFTEEN, this.ZERO);

  userData$: Observable<any> = this.userAccountDetails.get();
  b2bUnitData$: Observable<any> = this.userData$.pipe(
    switchMap(user => {
      return this.communicationService.getCurrentAccount(user);
    })
  );

  constructor(
    protected fffActiveCartService: ActiveCartFacade,
    protected selectiveCartService: SelectiveCartService,
    protected userIdService: UserIdService,
    protected multiCartService: MultiCartFacade,
    private store: Store<ProductFocusState>,
    private cartEvent: Store<UpdatedCartState>,
    protected cd: ChangeDetectorRef,
    private userAccountDetails: UserAccountFacade,
    private communicationService: FffCommunicationService,
    private elementRef: ElementRef,
    private chatbotService: FffRingCentralChatBotService,
    public cdRef: ChangeDetectorRef,
    public translationService: TranslationService,
    private fffDisplayShippingBannerService: FffDisplayShippingBannerService,
    private fffAllocationService: FffAllocationService
  ) {
    super(
      fffActiveCartService,
      selectiveCartService,
      userIdService,
      multiCartService,
      cd
    );
  }

  cart$ = this.fffActiveCartService.getActive();
  innerCart: any = [];

  ngOnInit(): void {
    this.validateMessage();

    this.store
      .select((state: any) => {
        return state.IncomingCartUpdates;
      })
      .subscribe(c => {
        if (c != null && c !== this.changes) {
          this.changes = c.cartEntriesData;
          Object.keys(c.cartEntriesData).forEach((change: any) => {
            // modifying the inner cart as the input changes
            let changedEntry = c.cartEntriesData[change].entry;
            let f = this.innerCart.find(
              (cartItem: any) =>
                cartItem.code === changedEntry.product.code &&
                cartItem.accountType === changedEntry.accountType
            );
            // sometimes the entry is deleted so we don't want to update the quantity for those cases.
            if (f) {
              f.quantity = +changedEntry.quantity;
            }
          });
        }
      });

    this.cartEvent
      .select((state: any) => {
        return state.UpdatedCartState;
      })
      .subscribe(focusState => {
        this.updateCartData();
      });
  }

  ngAfterViewInit() {
    if (this.elementRef.nativeElement.querySelector('.live-chat')) {
      this.elementRef.nativeElement
        .querySelector('.live-chat')
        .addEventListener('click', this.openLiveChat.bind(this));
    }
  }

  getCurrentTime(): Date {
    var currentDate = new Date();
    let pstFull = currentDate.toLocaleString('en-CA', {
      timeZone: 'America/Los_Angeles',
    });
    pstFull = pstFull.replace(/\./g, '');
    return new Date(pstFull);
  }

  validateMessage(): void {
    this.b2bUnitData$.subscribe((b2bUnit: any) => {
      if (b2bUnit?.uid.startsWith(this.PUERTO_RICO_PREFIX)) {
        this.message$ = this.getMessagePRaccount();
        this.cdRef.detectChanges();
      } else {
        this.fffActiveCartService.getEntries().subscribe(entries => {
          this.message$ = this.getMessageValidatePRoducts(entries);
          this.cdRef.detectChanges();
        });
      }
    });
  }

  getMessagePRaccount(): Observable<string> {
    if (
      (this.currentTime.getDay() === this.MONDAY ||
        this.currentTime.getDay() === this.TUESDAY) &&
      this.currentTime.getTime() < this.TODAY_AT_TWELVE.getTime()
    ) {
      return this.translationService.translate(this.DEFAULT_MESSAGE);
    } else if (
      (this.currentTime.getDay() === this.MONDAY ||
        this.currentTime.getDay() === this.TUESDAY) &&
      this.currentTime.getTime() >= this.TODAY_AT_TWELVE.getTime() &&
      this.currentTime.getTime() < this.TODAY_AT_FIFTEEN.getTime()
    ) {
      return this.translationService.translate(
        this.SHIP_PR_IMMEDIATE_PROCESSING_MESSAGE
      );
    } else if (
      !(
        this.currentTime.getDay() === this.TUESDAY &&
        this.currentTime.getTime() < this.TODAY_AT_FIFTEEN.getTime()
      ) &&
      this.currentTime.getDay() >= this.TUESDAY &&
      this.currentTime.getDay() <= this.FRIDAY &&
      !(
        this.currentTime.getDay() === this.FRIDAY &&
        this.currentTime.getTime() > this.TODAY_AT_TWELVE.getTime()
      )
    ) {
      return this.translationService.translate(
        this.SHIP_PR_SHIP_TUESDAY_MESSAGE
      );
    } else if (
      this.currentTime.getDay() === this.FRIDAY &&
      this.currentTime.getTime() < this.TODAY_AT_TWELVE.getTime()
    ) {
      return this.translationService.translate(
        this.SHIP_PR_SHIP_WEDNESDAY_MESSAGE
      );
    } else {
      return this.translationService.translate(this.DEFAULT_MESSAGE);
    }
  }

  getMessageValidatePRoducts(entries: any[]): Observable<string> {
    const refrigeratedProducts = entries.filter(
      (entry: any) =>
        this.PRODUCT_REFRIGERATED === entry.product.storageCondition
    );
    if (
      refrigeratedProducts.length > 0 &&
      (this.currentTime.getDay() === this.THURSDAY ||
        this.currentTime.getDay() === this.FRIDAY)
    ) {
      return this.translationService.translate(
        this.SHIP_PAST_CUTOFF_REFRIGERATED_MESSAGE
      );
    } else {
      return this.translationService.translate(this.DEFAULT_MESSAGE);
    }
  }

  getSpecificDate(hour: number, minutes: number): Date {
    let specificDate = this.getCurrentTime();
    specificDate.setHours(hour);
    specificDate.setMinutes(minutes);
    specificDate.setSeconds(this.ZERO, this.ZERO);
    return specificDate;
  }

  openLiveChat(): void {
    this.chatbotService.openChatBot();
  }

  updateCartData(): void {
    this.cartData$ = this.fffActiveCartService.getActive();
    this.cd.detectChanges();
  }

  surpassesYearlyAlloc(allocation: any, totalQuantity: number) {
    return (
      +allocation.yearlyAllocatedQty > 0 &&
      totalQuantity > +allocation.yearlyRemainingQty
    );
  }

  surpassesMonthlyAlloc(allocation: any, totalQuantity: number) {
    return (
      +allocation.allocatedQty > 0 && totalQuantity > +allocation.remainingQty
    );
  }

  hasEvents(actions: any): boolean {
    return actions && Object.keys(actions).length > 0;
  }
}
