import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { I18nModule, UrlModule } from '@spartacus/core';
import { IconModule, MediaModule } from '@spartacus/storefront';
import { FffProductModule } from '../fff-product.module';
import { FffProductItemInfoComponent } from './fff-product-item-info.component';
import { TieredModalComponent } from './tiered-modal/tiered-modal.component';

@NgModule({
    declarations: [
        FffProductItemInfoComponent,
        TieredModalComponent
    ],
    imports: [
        UrlModule,
        BrowserModule,
        CommonModule,
        MediaModule,
        I18nModule,
        FffProductModule,
        IconModule
    ],
    exports: [FffProductItemInfoComponent],
    providers: []
})
export class FffProductItemInfoModule { }
