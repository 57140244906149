import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CmsConfig, I18nModule, provideConfig } from '@spartacus/core';
import { GenericLinkModule, IconModule } from '@spartacus/storefront';
import { FffNavigationUIComponent } from './navigation-ui.component';
import { FffNavigationComponent } from './navigation.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    IconModule,
    GenericLinkModule,
    I18nModule,
  ],
  providers: [
    provideConfig(<CmsConfig>{
      cmsComponents: {
        NavigationComponent: {
          component: FffNavigationComponent,
        },
      },
    }),
  ],
  declarations: [FffNavigationComponent, FffNavigationUIComponent],
  exports: [FffNavigationComponent, FffNavigationUIComponent],
})
export class FffNavigationModule {}
