<ng-container *ngIf="activeSite$ | async as activeSite">
  <ng-container *ngIf="profile$ | async as profile">
    <ng-container *ngIf="returnOrderDetails$ | async as returnOrder">
      <div class="container">
        <fff-return-user-details-header [accountNumber]="''+returnOrder.unit.uid" [accountName]="returnOrder.unit.name"
          [email]="returnOrder.returnContactInfo.email" [contactName]="returnOrder.returnContactInfo.name"
          [phoneNumber]="returnOrder.returnContactInfo.phone ? returnOrder.returnContactInfo.phone : ''"></fff-return-user-details-header>
        <div class="pt-4">
          <div class="summary p-4">
            <div class="d-flex flex-wrap">
              <div class="px-0 col-12">
                <div class="col-12">
                  <div class="header text-center" *ngIf="isRGAReturn"> {{ 'fffReturns.rgaTitle'| cxTranslate }} #{{ returnOrder.code }}</div>
                  <div class="header text-center" *ngIf="!isRGAReturn"> {{ 'fffReturns.fetTitle'| cxTranslate }} #{{ returnOrder.code }}</div>

                  <div class="subtitle text-center">{{ 'fffOrderHistoryDetail.po' | cxTranslate }}{{
                    returnOrder.purchaseOrderNumber }}</div>
                  <div class="subtitle return-status text-center mt-3 mb-2">
                    {{ 'fffReturns.returnStatus' | cxTranslate }}:&nbsp;
                    {{returnOrder.statusDisplay }}
                  </div>
                  <div class="col-12 py-3 border-separator">
                    <div class="col-12 px-0 text-right" *ngIf="showSendRGACopyButton(returnOrder)">
                      <span class="action-label d-inline-flex cursor-pointer"
                        (click)="openSendRGACopyDrawer(returnOrder.code)">
                        {{ 'fffReturns.sendCopyToEmail' | cxTranslate }}
                        <cx-icon class="cx-icon icon-sm pl-2" [type]="outlinedIconTypes.ICON_ENVELOPE"></cx-icon>
                      </span>
                    </div>
                  </div>
                  <div class="col-12 py-3">
                    <fff-returns-entry [items]="returnOrder.entries" [account]="''+returnOrder.unit.uid"
                      [displayConfirmationEntryQty]="false"></fff-returns-entry>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
  </ng-container>
</ng-container>
