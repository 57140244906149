export enum OUTLINED_ICON_TYPE {
  SEARCH = 'SEARCH',
  CARET_DOWN = 'CARET_DOWN',
  ICON_EXCLAMATION = 'ICON_EXCLAMATION',
  ICON_EYEGLASSES = 'ICON_EYEGLASSES',
  ICON_BOOKMARK = 'ICON_BOOKMARK',
  ICON_TEXT_RIGHT = 'ICON_TEXT_RIGHT',
  ICON_HEARTS = 'ICON_HEARTS',
  ICON_SIGNPOST_2 = 'ICON_SIGNPOST_2',
  ICON_OCTAGON = 'ICON_OCTAGON',
  ICON_TYPE_STRIKETHROUGH = 'ICON_TYPE_STRIKETHROUGH',
  ICON_PENCIL = 'ICON_PENCIL',
  ICON_HAMMER = 'ICON_HAMMER',
  ICON_PLUS_SLASH_MINUS = 'ICON_PLUS_SLASH_MINUS',
  ICON_RECEPTION_4 = 'ICON_RECEPTION_4',
  ICON_LAYOUT_SPLIT = 'ICON_LAYOUT_SPLIT',
  ICON_STOPLIGHTS = 'ICON_STOPLIGHTS',
  ICON_APP_INDICATOR = 'ICON_APP_INDICATOR',
  ICON_BOX = 'ICON_BOX',
  ICON_TELEPHONE_INBOUND = 'ICON_TELEPHONE_INBOUND',
  ICON_HOSPITAL = 'ICON_HOSPITAL',
  ICON_CLIPBOARD_MINUS = 'ICON_CLIPBOARD_MINUS',
  ICON_HOURGLASS_TOP = 'ICON_HOURGLASS_TOP',
  ICON_STOP = 'ICON_STOP',
  ICON_CAST = 'ICON_CAST',
  ICON_RECORD_BTN = 'ICON_RECORD_BTN',
  ICON_FILE_EARMARK_BREAK = 'ICON_FILE_EARMARK_BREAK',
  ICON_FILTER_CIRCLE = 'ICON_FILTER_CIRCLE',
  ICON_CARD_HEADING = 'ICON_CARD_HEADING',
  ICON_EMOJI_NEUTRAL = 'ICON_EMOJI_NEUTRAL',
  ICON_USB_C = 'ICON_USB_C',
  ICON_BAG_PLUS = 'ICON_BAG_PLUS',
  ICON_UMBRELLA = 'ICON_UMBRELLA',
  ICON_TYPE_H3 = 'ICON_TYPE_H3',
  ICON_RULERS = 'ICON_RULERS',
  ICON_JOURNAL = 'ICON_JOURNAL',
  ICON_PERSON_BOUNDING_BOX = 'ICON_PERSON_BOUNDING_BOX',
  ICON_HEART = 'ICON_HEART',
  ICON_TOGGLES2 = 'ICON_TOGGLES2',
  ICON_SORT_UP = 'ICON_SORT_UP',
  ICON_EASEL = 'ICON_EASEL',
  ICON_JOURNALS = 'ICON_JOURNALS',
  ICON_CHEVRON_DOUBLE_UP = 'ICON_CHEVRON_DOUBLE_UP',
  ICON_CART_X = 'ICON_CART_X',
  ICON_BAR_CHART_STEPS = 'ICON_BAR_CHART_STEPS',
  ICON_CLIPBOARD2 = 'ICON_CLIPBOARD2',
  ICON_BEHANCE = 'ICON_BEHANCE',
  ICON_LAYOUT_WTF = 'ICON_LAYOUT_WTF',
  ICON_MOON = 'ICON_MOON',
  ICON_FILM = 'ICON_FILM',
  ICON_CHEVRON_LEFT = 'ICON_CHEVRON_LEFT',
  ICON_PAINT_BUCKET = 'ICON_PAINT_BUCKET',
  ICON_TELEPHONE_MINUS = 'ICON_TELEPHONE_MINUS',
  ICON_CHEVRON_DOUBLE_DOWN = 'ICON_CHEVRON_DOUBLE_DOWN',
  ICON_BADGE_8K = 'ICON_BADGE_8K',
  ICON_HEART_PULSE = 'ICON_HEART_PULSE',
  ICON_CLIPBOARD_CHECK = 'ICON_CLIPBOARD_CHECK',
  ICON_CREDIT_CARD_2_BACK = 'ICON_CREDIT_CARD_2_BACK',
  ICON_SKIP_START = 'ICON_SKIP_START',
  ICON_THERMOMETER_SNOW = 'ICON_THERMOMETER_SNOW',
  ICON_DASH_LG = 'ICON_DASH_LG',
  ICON_FILE_POST = 'ICON_FILE_POST',
  ICON_FILE_PDF = 'ICON_FILE_PDF',
  ICON_FINGERPRINT = 'ICON_FINGERPRINT',
  ICON_TYPE_UNDERLINE = 'ICON_TYPE_UNDERLINE',
  ICON_CHEVRON_BAR_DOWN = 'ICON_CHEVRON_BAR_DOWN',
  ICON_CODE_SQUARE = 'ICON_CODE_SQUARE',
  ICON_BASKET = 'ICON_BASKET',
  ICON_FILE_LOCK = 'ICON_FILE_LOCK',
  ICON_FILE_PPT = 'ICON_FILE_PPT',
  ICON_ALARM = 'ICON_ALARM',
  ICON_CALENDAR2_CHECK = 'ICON_CALENDAR2_CHECK',
  ICON_FOLDER = 'ICON_FOLDER',
  ICON_FILE_EARMARK_PLUS = 'ICON_FILE_EARMARK_PLUS',
  ICON_GEAR_WIDE_CONNECTED = 'ICON_GEAR_WIDE_CONNECTED',
  ICON_BINOCULARS = 'ICON_BINOCULARS',
  ICON_BEZIER = 'ICON_BEZIER',
  ICON_BUILDING = 'ICON_BUILDING',
  ICON_FILE_EARMARK_PPT = 'ICON_FILE_EARMARK_PPT',
  ICON_CHAT_HEART = 'ICON_CHAT_HEART',
  ICON_CARET_RIGHT_SQUARE = 'ICON_CARET_RIGHT_SQUARE',
  ICON_FILE_BAR_GRAPH = 'ICON_FILE_BAR_GRAPH',
  ICON_FILE_EARMARK_MUSIC = 'ICON_FILE_EARMARK_MUSIC',
  ICON_BOX_ARROW_IN_DOWN_LEFT = 'ICON_BOX_ARROW_IN_DOWN_LEFT',
  ICON_SHIELD_CHECK = 'ICON_SHIELD_CHECK',
  ICON_FILE_EARMARK_PDF = 'ICON_FILE_EARMARK_PDF',
  ICON_SCREWDRIVER = 'ICON_SCREWDRIVER',
  ICON_PENTAGON = 'ICON_PENTAGON',
  ICON_MOUSE = 'ICON_MOUSE',
  ICON_ALIGN_TOP = 'ICON_ALIGN_TOP',
  ICON_CALCULATOR = 'ICON_CALCULATOR',
  ICON_SORT_DOWN = 'ICON_SORT_DOWN',
  ICON_123 = 'ICON_123',
  ICON_HDD = 'ICON_HDD',
  ICON_PATCH_MINUS = 'ICON_PATCH_MINUS',
  ICON_BACKSPACE = 'ICON_BACKSPACE',
  ICON_NODE_MINUS = 'ICON_NODE_MINUS',
  ICON_CALENDAR4_WEEK = 'ICON_CALENDAR4_WEEK',
  ICON_CHAT_QUOTE = 'ICON_CHAT_QUOTE',
  ICON_CALENDAR2_EVENT = 'ICON_CALENDAR2_EVENT',
  ICON_BOX_ARROW_UP_LEFT = 'ICON_BOX_ARROW_UP_LEFT',
  ICON_CHEVRON_BAR_RIGHT = 'ICON_CHEVRON_BAR_RIGHT',
  ICON_LAYOUT_TEXT_WINDOW_REVERSE = 'ICON_LAYOUT_TEXT_WINDOW_REVERSE',
  ICON_FILE_EARMARK_SPREADSHEET = 'ICON_FILE_EARMARK_SPREADSHEET',
  ICON_TELEPHONE_FORWARD = 'ICON_TELEPHONE_FORWARD',
  ICON_PLUS_CIRCLE_DOTTED = 'ICON_PLUS_CIRCLE_DOTTED',
  ICON_EAR = 'ICON_EAR',
  ICON_TELEPHONE_OUTBOUND = 'ICON_TELEPHONE_OUTBOUND',
  ICON_CHECK2 = 'ICON_CHECK2',
  ICON_CLOUD_DOWNLOAD = 'ICON_CLOUD_DOWNLOAD',
  ICON_LIST_OL = 'ICON_LIST_OL',
  ICON_BADGE_4K = 'ICON_BADGE_4K',
  ICON_PALETTE2 = 'ICON_PALETTE2',
  ICON_FILE_EARMARK_CODE = 'ICON_FILE_EARMARK_CODE',
  ICON_ARROW_LEFT_RIGHT = 'ICON_ARROW_LEFT_RIGHT',
  ICON_CURRENCY_DOLLAR = 'ICON_CURRENCY_DOLLAR',
  ICON_FILE_RICHTEXT = 'ICON_FILE_RICHTEXT',
  ICON_EASEL2 = 'ICON_EASEL2',
  ICON_FILE_DIFF = 'ICON_FILE_DIFF',
  ICON_EASEL3 = 'ICON_EASEL3',
  ICON_TABLET = 'ICON_TABLET',
  ICON_DASH_SQUARE_DOTTED = 'ICON_DASH_SQUARE_DOTTED',
  ICON_ASTERISK = 'ICON_ASTERISK',
  ICON_MINECART = 'ICON_MINECART',
  ICON_INFO_LG = 'ICON_INFO_LG',
  ICON_BOX_ARROW_IN_UP_LEFT = 'ICON_BOX_ARROW_IN_UP_LEFT',
  ICON_BRACES = 'ICON_BRACES',
  ICON_SHIELD_SLASH = 'ICON_SHIELD_SLASH',
  ICON_TEXTAREA_T = 'ICON_TEXTAREA_T',
  ICON_FORWARD = 'ICON_FORWARD',
  ICON_BALLOON = 'ICON_BALLOON',
  ICON_CLOUDY = 'ICON_CLOUDY',
  ICON_CLOUD_HAIL = 'ICON_CLOUD_HAIL',
  ICON_ARROW_DOWN_CIRCLE = 'ICON_ARROW_DOWN_CIRCLE',
  ICON_CALENDAR2_MONTH = 'ICON_CALENDAR2_MONTH',
  ICON_PAUSE = 'ICON_PAUSE',
  ICON_CALENDAR_X = 'ICON_CALENDAR_X',
  ICON_BOOKMARK_CHECK = 'ICON_BOOKMARK_CHECK',
  ICON_FONTS = 'ICON_FONTS',
  ICON_UPLOAD = 'ICON_UPLOAD',
  ICON_DROPLET_HALF = 'ICON_DROPLET_HALF',
  ICON_SORT_NUMERIC_DOWN = 'ICON_SORT_NUMERIC_DOWN',
  ICON_TELEPHONE_X = 'ICON_TELEPHONE_X',
  ICON_LIST_CHECK = 'ICON_LIST_CHECK',
  ICON_EMOJI_ANGRY = 'ICON_EMOJI_ANGRY',
  ICON_GRIP_VERTICAL = 'ICON_GRIP_VERTICAL',
  ICON_JUSTIFY = 'ICON_JUSTIFY',
  ICON_CUP = 'ICON_CUP',
  ICON_QUOTE = 'ICON_QUOTE',
  ICON_WINDOW = 'ICON_WINDOW',
  ICON_LAYER_FORWARD = 'ICON_LAYER_FORWARD',
  ICON_SHUFFLE = 'ICON_SHUFFLE',
  ICON_PCI_CARD = 'ICON_PCI_CARD',
  ICON_CLOUD = 'ICON_CLOUD',
  ICON_HR = 'ICON_HR',
  ICON_ALT = 'ICON_ALT',
  ICON_BOUNDING_BOX = 'ICON_BOUNDING_BOX',
  ICON_ENVELOPE_PAPER = 'ICON_ENVELOPE_PAPER',
  ICON_CLIPBOARD2_PULSE = 'ICON_CLIPBOARD2_PULSE',
  ICON_FILTER_LEFT = 'ICON_FILTER_LEFT',
  ICON_CALENDAR_DATE = 'ICON_CALENDAR_DATE',
  ICON_SLACK = 'ICON_SLACK',
  ICON_FILE_CODE = 'ICON_FILE_CODE',
  ICON_CLIPBOARD2_MINUS = 'ICON_CLIPBOARD2_MINUS',
  ICON_SHIELD_SHADED = 'ICON_SHIELD_SHADED',
  ICON_PLUS_SQUARE_DOTTED = 'ICON_PLUS_SQUARE_DOTTED',
  ICON_ARROW_UP_LEFT_CIRCLE = 'ICON_ARROW_UP_LEFT_CIRCLE',
  ICON_BOX_ARROW_UP_RIGHT = 'ICON_BOX_ARROW_UP_RIGHT',
  ICON_CHAT_RIGHT_TEXT = 'ICON_CHAT_RIGHT_TEXT',
  ICON_PC = 'ICON_PC',
  ICON_SEND_CHECK = 'ICON_SEND_CHECK',
  ICON_CLIPBOARD_X = 'ICON_CLIPBOARD_X',
  ICON_CARET_LEFT = 'ICON_CARET_LEFT',
  ICON_FILE_EARMARK_PERSON = 'ICON_FILE_EARMARK_PERSON',
  ICON_DIAMOND_HALF = 'ICON_DIAMOND_HALF',
  ICON_DISPLAY = 'ICON_DISPLAY',
  ICON_BOX_ARROW_IN_UP_RIGHT = 'ICON_BOX_ARROW_IN_UP_RIGHT',
  ICON_TOGGLE2_ON = 'ICON_TOGGLE2_ON',
  ICON_LAPTOP = 'ICON_LAPTOP',
  ICON_OCTAGON_HALF = 'ICON_OCTAGON_HALF',
  ICON_SNOW2 = 'ICON_SNOW2',
  ICON_ARROW_DOWN_RIGHT = 'ICON_ARROW_DOWN_RIGHT',
  ICON_WINDOW_SPLIT = 'ICON_WINDOW_SPLIT',
  ICON_CALENDAR_HEART = 'ICON_CALENDAR_HEART',
  ICON_ENVELOPE_CHECK = 'ICON_ENVELOPE_CHECK',
  ICON_BADGE_AR = 'ICON_BADGE_AR',
  ICON_ALIGN_CENTER = 'ICON_ALIGN_CENTER',
  ICON_ARROW_LEFT = 'ICON_ARROW_LEFT',
  ICON_GLOBE = 'ICON_GLOBE',
  ICON_SLIDERS2_VERTICAL = 'ICON_SLIDERS2_VERTICAL',
  ICON_TRASH2 = 'ICON_TRASH2',
  ICON_CHAT_LEFT_DOTS = 'ICON_CHAT_LEFT_DOTS',
  ICON_CALENDAR = 'ICON_CALENDAR',
  ICON_TEXT_LEFT = 'ICON_TEXT_LEFT',
  ICON_TRASH3 = 'ICON_TRASH3',
  ICON_BELL_SLASH = 'ICON_BELL_SLASH',
  ICON_JOURNAL_CHECK = 'ICON_JOURNAL_CHECK',
  ICON_CLIPBOARD2_HEART = 'ICON_CLIPBOARD2_HEART',
  ICON_UPC = 'ICON_UPC',
  ICON_EMOJI_SUNGLASSES = 'ICON_EMOJI_SUNGLASSES',
  ICON_LIGHTBULB_OFF = 'ICON_LIGHTBULB_OFF',
  ICON_LIGHTNING_CHARGE = 'ICON_LIGHTNING_CHARGE',
  ICON_SKIP_END = 'ICON_SKIP_END',
  ICON_BADGE_AD = 'ICON_BADGE_AD',
  ICON_WATER = 'ICON_WATER',
  ICON_PC_DISPLAY = 'ICON_PC_DISPLAY',
  ICON_SNOW3 = 'ICON_SNOW3',
  ICON_FILTER = 'ICON_FILTER',
  ICON_HOURGLASS_BOTTOM = 'ICON_HOURGLASS_BOTTOM',
  ICON_GENDER_FEMALE = 'ICON_GENDER_FEMALE',
  ICON_ACTIVITY = 'ICON_ACTIVITY',
  ICON_TREE = 'ICON_TREE',
  ICON_ROBOT = 'ICON_ROBOT',
  ICON_CHAT_RIGHT_QUOTE = 'ICON_CHAT_RIGHT_QUOTE',
  ICON_FILTER_SQUARE = 'ICON_FILTER_SQUARE',
  ICON_STACK = 'ICON_STACK',
  ICON_WINDOW_PLUS = 'ICON_WINDOW_PLUS',
  ICON_EXPLICIT = 'ICON_EXPLICIT',
  ICON_SLASH_SQUARE = 'ICON_SLASH_SQUARE',
  ICON_DASH_CIRCLE_DOTTED = 'ICON_DASH_CIRCLE_DOTTED',
  ICON_SLASH_LG = 'ICON_SLASH_LG',
  ICON_ETHERNET = 'ICON_ETHERNET',
  ICON_CALENDAR_MINUS = 'ICON_CALENDAR_MINUS',
  ICON_SYMMETRY_VERTICAL = 'ICON_SYMMETRY_VERTICAL',
  ICON_COLUMNS_GAP = 'ICON_COLUMNS_GAP',
  ICON_SLIDERS2 = 'ICON_SLIDERS2',
  ICON_WINDOW_DASH = 'ICON_WINDOW_DASH',
  ICON_BRIGHTNESS_HIGH = 'ICON_BRIGHTNESS_HIGH',
  ICON_SKIP_END_CIRCLE = 'ICON_SKIP_END_CIRCLE',
  ICON_FILE_EARMARK_DIFF = 'ICON_FILE_EARMARK_DIFF',
  ICON_STICKY = 'ICON_STICKY',
  ICON_ARROW_UP = 'ICON_ARROW_UP',
  ICON_EMOJI_LAUGHING = 'ICON_EMOJI_LAUGHING',
  ICON_BORDER_BOTTOM = 'ICON_BORDER_BOTTOM',
  ICON_TAGS = 'ICON_TAGS',
  ICON_MUSIC_NOTE_LIST = 'ICON_MUSIC_NOTE_LIST',
  ICON_INPUT_CURSOR_TEXT = 'ICON_INPUT_CURSOR_TEXT',
  ICON_SHARE = 'ICON_SHARE',
  ICON_THUNDERBOLT = 'ICON_THUNDERBOLT',
  ICON_LIST_COLUMNS_REVERSE = 'ICON_LIST_COLUMNS_REVERSE',
  ICON_DASH_SQUARE = 'ICON_DASH_SQUARE',
  ICON_ENVELOPE_SLASH = 'ICON_ENVELOPE_SLASH',
  ICON_MOISTURE = 'ICON_MOISTURE',
  ICON_GRAPH_UP = 'ICON_GRAPH_UP',
  ICON_BOUNDING_BOX_CIRCLES = 'ICON_BOUNDING_BOX_CIRCLES',
  ICON_FILE_MUSIC = 'ICON_FILE_MUSIC',
  ICON_ENVELOPE_X = 'ICON_ENVELOPE_X',
  ICON_GIFT = 'ICON_GIFT',
  ICON_BRIGHTNESS_ALT_LOW = 'ICON_BRIGHTNESS_ALT_LOW',
  ICON_CHAT_RIGHT_HEART = 'ICON_CHAT_RIGHT_HEART',
  ICON_SEND_SLASH = 'ICON_SEND_SLASH',
  ICON_CASH_STACK = 'ICON_CASH_STACK',
  ICON_QUESTION_LG = 'ICON_QUESTION_LG',
  ICON_QUESTION_SQUARE = 'ICON_QUESTION_SQUARE',
  ICON_CARD_CHECKLIST = 'ICON_CARD_CHECKLIST',
  ICON_BADGE_VO = 'ICON_BADGE_VO',
  ICON_PERSON_VIDEO3 = 'ICON_PERSON_VIDEO3',
  ICON_UI_RADIOS_GRID = 'ICON_UI_RADIOS_GRID',
  ICON_FILE_BINARY = 'ICON_FILE_BINARY',
  ICON_ARROWS_ANGLE_CONTRACT = 'ICON_ARROWS_ANGLE_CONTRACT',
  ICON_PERSON_HEARTS = 'ICON_PERSON_HEARTS',
  ICON_RADIOACTIVE = 'ICON_RADIOACTIVE',
  ICON_EYE = 'ICON_EYE',
  ICON_ARROW_COUNTERCLOCKWISE = 'ICON_ARROW_COUNTERCLOCKWISE',
  ICON_CALENDAR4_EVENT = 'ICON_CALENDAR4_EVENT',
  ICON_ARROW_LEFT_SQUARE = 'ICON_ARROW_LEFT_SQUARE',
  ICON_PERSON_VIDEO2 = 'ICON_PERSON_VIDEO2',
  ICON_CHEVRON_COMPACT_RIGHT = 'ICON_CHEVRON_COMPACT_RIGHT',
  ICON_ARROW_DOWN_LEFT_SQUARE = 'ICON_ARROW_DOWN_LEFT_SQUARE',
  ICON_PHONE = 'ICON_PHONE',
  ICON_BOX2_HEART = 'ICON_BOX2_HEART',
  ICON_MENU_APP = 'ICON_MENU_APP',
  ICON_GPU_CARD = 'ICON_GPU_CARD',
  ICON_ROUTER = 'ICON_ROUTER',
  ICON_PLUS_LG = 'ICON_PLUS_LG',
  ICON_ARROW_THROUGH_HEART = 'ICON_ARROW_THROUGH_HEART',
  ICON_CLOCK = 'ICON_CLOCK',
  ICON_SKIP_START_BTN = 'ICON_SKIP_START_BTN',
  ICON_FILE_EARMARK_LOCK = 'ICON_FILE_EARMARK_LOCK',
  ICON_COMMAND = 'ICON_COMMAND',
  ICON_DIAMOND = 'ICON_DIAMOND',
  ICON_CHAT_DOTS = 'ICON_CHAT_DOTS',
  ICON_FILE_PLUS = 'ICON_FILE_PLUS',
  ICON_TEXT_PARAGRAPH = 'ICON_TEXT_PARAGRAPH',
  ICON_ARROW_BAR_DOWN = 'ICON_ARROW_BAR_DOWN',
  ICON_CLOCK_HISTORY = 'ICON_CLOCK_HISTORY',
  ICON_FILE_EARMARK_POST = 'ICON_FILE_EARMARK_POST',
  ICON_CLOUD_RAIN = 'ICON_CLOUD_RAIN',
  ICON_PHONE_FLIP = 'ICON_PHONE_FLIP',
  ICON_POSTAGE = 'ICON_POSTAGE',
  ICON_SORT_ALPHA_DOWN = 'ICON_SORT_ALPHA_DOWN',
  ICON_REPLY_ALL = 'ICON_REPLY_ALL',
  ICON_THERMOMETER_SUN = 'ICON_THERMOMETER_SUN',
  ICON_CHECK2_CIRCLE = 'ICON_CHECK2_CIRCLE',
  ICON_HEART_HALF = 'ICON_HEART_HALF',
  ICON_TEXTAREA = 'ICON_TEXTAREA',
  ICON_GRID_3X3 = 'ICON_GRID_3X3',
  ICON_BOX_ARROW_UP = 'ICON_BOX_ARROW_UP',
  ICON_CALENDAR2 = 'ICON_CALENDAR2',
  ICON_CREDIT_CARD = 'ICON_CREDIT_CARD',
  ICON_BANK = 'ICON_BANK',
  ICON_CLOUD_MOON = 'ICON_CLOUD_MOON',
  ICON_BICYCLE = 'ICON_BICYCLE',
  ICON_CIRCLE_SQUARE = 'ICON_CIRCLE_SQUARE',
  ICON_BASKET2 = 'ICON_BASKET2',
  ICON_ARROW_UP_RIGHT_CIRCLE = 'ICON_ARROW_UP_RIGHT_CIRCLE',
  ICON_PLUS_CIRCLE = 'ICON_PLUS_CIRCLE',
  ICON_SUNGLASSES = 'ICON_SUNGLASSES',
  ICON_COMPASS = 'ICON_COMPASS',
  ICON_BORDER_LEFT = 'ICON_BORDER_LEFT',
  ICON_FILE_ARROW_DOWN = 'ICON_FILE_ARROW_DOWN',
  ICON_BOOKMARK_DASH = 'ICON_BOOKMARK_DASH',
  ICON_GEO_ALT = 'ICON_GEO_ALT',
  ICON_UNLOCK = 'ICON_UNLOCK',
  ICON_BOOKMARK_PLUS = 'ICON_BOOKMARK_PLUS',
  ICON_GEO = 'ICON_GEO',
  ICON_COLLECTION = 'ICON_COLLECTION',
  ICON_BRIGHTNESS_ALT_HIGH = 'ICON_BRIGHTNESS_ALT_HIGH',
  ICON_ENVELOPE_PAPER_HEART = 'ICON_ENVELOPE_PAPER_HEART',
  ICON_FILE_EARMARK_SLIDES = 'ICON_FILE_EARMARK_SLIDES',
  ICON_WRENCH_ADJUSTABLE_CIRCLE = 'ICON_WRENCH_ADJUSTABLE_CIRCLE',
  ICON_HAND_INDEX_THUMB = 'ICON_HAND_INDEX_THUMB',
  ICON_BUG = 'ICON_BUG',
  ICON_SKIP_BACKWARD = 'ICON_SKIP_BACKWARD',
  ICON_BADGE_SD = 'ICON_BADGE_SD',
  ICON_CASH = 'ICON_CASH',
  ICON_WINDOW_SIDEBAR = 'ICON_WINDOW_SIDEBAR',
  ICON_BASKET3 = 'ICON_BASKET3',
  ICON_CLOUD_ARROW_DOWN = 'ICON_CLOUD_ARROW_DOWN',
  ICON_MIC_MUTE = 'ICON_MIC_MUTE',
  ICON_CALENDAR3 = 'ICON_CALENDAR3',
  ICON_GRID_3X2 = 'ICON_GRID_3X2',
  ICON_FILE_EARMARK_RULED = 'ICON_FILE_EARMARK_RULED',
  ICON_ENVELOPE_PLUS = 'ICON_ENVELOPE_PLUS',
  ICON_SORT_DOWN_ALT = 'ICON_SORT_DOWN_ALT',
  ICON_TROPICAL_STORM = 'ICON_TROPICAL_STORM',
  ICON_MORTARBOARD = 'ICON_MORTARBOARD',
  ICON_BOX_ARROW_IN_DOWN = 'ICON_BOX_ARROW_IN_DOWN',
  ICON_ENVELOPE_DASH = 'ICON_ENVELOPE_DASH',
  ICON_ARROW_90DEG_LEFT = 'ICON_ARROW_90DEG_LEFT',
  ICON_TSUNAMI = 'ICON_TSUNAMI',
  ICON_GRID_1X2 = 'ICON_GRID_1X2',
  ICON_CHEVRON_COMPACT_DOWN = 'ICON_CHEVRON_COMPACT_DOWN',
  ICON_WRENCH = 'ICON_WRENCH',
  ICON_SUN = 'ICON_SUN',
  ICON_PERSON_CHECK = 'ICON_PERSON_CHECK',
  ICON_COLLECTION_PLAY = 'ICON_COLLECTION_PLAY',
  ICON_STAR = 'ICON_STAR',
  ICON_VR = 'ICON_VR',
  ICON_CLIPBOARD_DATA = 'ICON_CLIPBOARD_DATA',
  ICON_VOLUME_UP = 'ICON_VOLUME_UP',
  ICON_SKIP_FORWARD_CIRCLE = 'ICON_SKIP_FORWARD_CIRCLE',
  ICON_TELEPHONE = 'ICON_TELEPHONE',
  ICON_MEMORY = 'ICON_MEMORY',
  ICON_PLAY_BTN = 'ICON_PLAY_BTN',
  ICON_FILE_LOCK2 = 'ICON_FILE_LOCK2',
  ICON_SKIP_BACKWARD_BTN = 'ICON_SKIP_BACKWARD_BTN',
  ICON_LIST_COLUMNS = 'ICON_LIST_COLUMNS',
  ICON_CALENDAR3_EVENT = 'ICON_CALENDAR3_EVENT',
  ICON_RECORD_CIRCLE = 'ICON_RECORD_CIRCLE',
  ICON_LAYERS_HALF = 'ICON_LAYERS_HALF',
  ICON_WIFI_OFF = 'ICON_WIFI_OFF',
  ICON_LAYOUT_TEXT_SIDEBAR = 'ICON_LAYOUT_TEXT_SIDEBAR',
  ICON_EMOJI_HEART_EYES = 'ICON_EMOJI_HEART_EYES',
  ICON_LIFE_PRESERVER = 'ICON_LIFE_PRESERVER',
  ICON_BOX_ARROW_IN_RIGHT = 'ICON_BOX_ARROW_IN_RIGHT',
  ICON_TRASH = 'ICON_TRASH',
  ICON_ARROWS_EXPAND = 'ICON_ARROWS_EXPAND',
  ICON_HAND_INDEX = 'ICON_HAND_INDEX',
  ICON_GEM = 'ICON_GEM',
  ICON_JOURNAL_TEXT = 'ICON_JOURNAL_TEXT',
  ICON_CURRENCY_EURO = 'ICON_CURRENCY_EURO',
  ICON_INFINITY = 'ICON_INFINITY',
  ICON_BAG_HEART = 'ICON_BAG_HEART',
  ICON_TEXT_INDENT_LEFT = 'ICON_TEXT_INDENT_LEFT',
  ICON_PC_DISPLAY_HORIZONTAL = 'ICON_PC_DISPLAY_HORIZONTAL',
  ICON_INTERSECT = 'ICON_INTERSECT',
  ICON_PERSON_ROLODEX = 'ICON_PERSON_ROLODEX',
  ICON_EXCLAMATION_CIRCLE = 'ICON_EXCLAMATION_CIRCLE',
  ICON_TABLET_LANDSCAPE = 'ICON_TABLET_LANDSCAPE',
  ICON_CLOUD_MINUS = 'ICON_CLOUD_MINUS',
  ICON_MINECART_LOADED = 'ICON_MINECART_LOADED',
  ICON_BULLSEYE = 'ICON_BULLSEYE',
  ICON_FILE_EARMARK_CHECK = 'ICON_FILE_EARMARK_CHECK',
  ICON_SPELLCHECK = 'ICON_SPELLCHECK',
  ICON_EGG = 'ICON_EGG',
  ICON_VECTOR_PEN = 'ICON_VECTOR_PEN',
  ICON_BUCKET = 'ICON_BUCKET',
  ICON_BACKSPACE_REVERSE = 'ICON_BACKSPACE_REVERSE',
  ICON_SYMMETRY_HORIZONTAL = 'ICON_SYMMETRY_HORIZONTAL',
  ICON_VINYL = 'ICON_VINYL',
  ICON_WHATSAPP = 'ICON_WHATSAPP',
  ICON_LINK_45DEG = 'ICON_LINK_45DEG',
  ICON_CREDIT_CARD_2_FRONT = 'ICON_CREDIT_CARD_2_FRONT',
  ICON_STARS = 'ICON_STARS',
  ICON_TRUCK = 'ICON_TRUCK',
  ICON_ARROW_UP_RIGHT = 'ICON_ARROW_UP_RIGHT',
  ICON_BOX_ARROW_LEFT = 'ICON_BOX_ARROW_LEFT',
  ICON_HDD_RACK = 'ICON_HDD_RACK',
  ICON_FOLDER_MINUS = 'ICON_FOLDER_MINUS',
  ICON_EXCLUDE = 'ICON_EXCLUDE',
  ICON_BORDER_TOP = 'ICON_BORDER_TOP',
  ICON_BORDER_STYLE = 'ICON_BORDER_STYLE',
  ICON_MAGIC = 'ICON_MAGIC',
  ICON_SMARTWATCH = 'ICON_SMARTWATCH',
  ICON_SEND_DASH = 'ICON_SEND_DASH',
  ICON_ARROW_90DEG_RIGHT = 'ICON_ARROW_90DEG_RIGHT',
  ICON_PRINTER = 'ICON_PRINTER',
  ICON_CAMERA = 'ICON_CAMERA',
  ICON_NINTENDO_SWITCH = 'ICON_NINTENDO_SWITCH',
  ICON_SEND_PLUS = 'ICON_SEND_PLUS',
  ICON_CURRENCY_EXCHANGE = 'ICON_CURRENCY_EXCHANGE',
  ICON_CALENDAR4 = 'ICON_CALENDAR4',
  ICON_ARROW_DOWN_RIGHT_SQUARE = 'ICON_ARROW_DOWN_RIGHT_SQUARE',
  ICON_SPEAKER = 'ICON_SPEAKER',
  ICON_CALENDAR2_DAY = 'ICON_CALENDAR2_DAY',
  ICON_RECEPTION_0 = 'ICON_RECEPTION_0',
  ICON_PHONE_VIBRATE = 'ICON_PHONE_VIBRATE',
  ICON_JOURNAL_ARROW_DOWN = 'ICON_JOURNAL_ARROW_DOWN',
  ICON_CLIPBOARD2_X = 'ICON_CLIPBOARD2_X',
  ICON_SEND = 'ICON_SEND',
  ICON_TABLE = 'ICON_TABLE',
  ICON_USB_MINI = 'ICON_USB_MINI',
  ICON_STICKIES = 'ICON_STICKIES',
  ICON_CLIPBOARD2_DATA = 'ICON_CLIPBOARD2_DATA',
  ICON_FILE_EARMARK_RICHTEXT = 'ICON_FILE_EARMARK_RICHTEXT',
  ICON_ARROWS_COLLAPSE = 'ICON_ARROWS_COLLAPSE',
  ICON_PIP = 'ICON_PIP',
  ICON_BAG_X = 'ICON_BAG_X',
  ICON_COIN = 'ICON_COIN',
  ICON_EMOJI_SMILE = 'ICON_EMOJI_SMILE',
  ICON_FILE_CHECK = 'ICON_FILE_CHECK',
  ICON_ARROW_REPEAT = 'ICON_ARROW_REPEAT',
  ICON_CHAT = 'ICON_CHAT',
  ICON_CAPSLOCK = 'ICON_CAPSLOCK',
  ICON_PLAY = 'ICON_PLAY',
  ICON_EYE_SLASH = 'ICON_EYE_SLASH',
  ICON_SQUARE = 'ICON_SQUARE',
  ICON_SUIT_HEART = 'ICON_SUIT_HEART',
  ICON_PERCENT = 'ICON_PERCENT',
  ICON_PEACE = 'ICON_PEACE',
  ICON_CARD_LIST = 'ICON_CARD_LIST',
  ICON_MASK = 'ICON_MASK',
  ICON_BOX_ARROW_IN_UP = 'ICON_BOX_ARROW_IN_UP',
  ICON_CAMERA_VIDEO = 'ICON_CAMERA_VIDEO',
  ICON_ZOOM_OUT = 'ICON_ZOOM_OUT',
  ICON_AWARD = 'ICON_AWARD',
  ICON_FILE_EARMARK_LOCK2 = 'ICON_FILE_EARMARK_LOCK2',
  ICON_INPUT_CURSOR = 'ICON_INPUT_CURSOR',
  ICON_JOURNAL_RICHTEXT = 'ICON_JOURNAL_RICHTEXT',
  ICON_CURRENCY_YEN = 'ICON_CURRENCY_YEN',
  ICON_RECEPTION_1 = 'ICON_RECEPTION_1',
  ICON_WINDOW_STACK = 'ICON_WINDOW_STACK',
  ICON_CALENDAR_RANGE = 'ICON_CALENDAR_RANGE',
  ICON_X_SQUARE = 'ICON_X_SQUARE',
  ICON_BROADCAST_PIN = 'ICON_BROADCAST_PIN',
  ICON_BACK = 'ICON_BACK',
  ICON_GENDER_MALE = 'ICON_GENDER_MALE',
  ICON_CALENDAR2_PLUS = 'ICON_CALENDAR2_PLUS',
  ICON_CHAT_SQUARE = 'ICON_CHAT_SQUARE',
  ICON_ALIGN_MIDDLE = 'ICON_ALIGN_MIDDLE',
  ICON_BROADCAST = 'ICON_BROADCAST',
  ICON_PAPERCLIP = 'ICON_PAPERCLIP',
  ICON_VOLUME_MUTE = 'ICON_VOLUME_MUTE',
  ICON_PIN_MAP = 'ICON_PIN_MAP',
  ICON_HEADSET_VR = 'ICON_HEADSET_VR',
  ICON_SHIELD_EXCLAMATION = 'ICON_SHIELD_EXCLAMATION',
  ICON_QUORA = 'ICON_QUORA',
  ICON_CALENDAR3_WEEK = 'ICON_CALENDAR3_WEEK',
  ICON_CALENDAR2_WEEK = 'ICON_CALENDAR2_WEEK',
  ICON_WINDOW_DOCK = 'ICON_WINDOW_DOCK',
  ICON_BOOTSTRAP_REBOOT = 'ICON_BOOTSTRAP_REBOOT',
  ICON_MENU_DOWN = 'ICON_MENU_DOWN',
  ICON_THREE_DOTS_VERTICAL = 'ICON_THREE_DOTS_VERTICAL',
  ICON_OPTICAL_AUDIO = 'ICON_OPTICAL_AUDIO',
  ICON_CLOUD_LIGHTNING = 'ICON_CLOUD_LIGHTNING',
  ICON_BOOKMARKS = 'ICON_BOOKMARKS',
  ICON_FRONT = 'ICON_FRONT',
  ICON_IMAGES = 'ICON_IMAGES',
  ICON_FILE_RULED = 'ICON_FILE_RULED',
  ICON_LAMP = 'ICON_LAMP',
  ICON_ARROW_UP_SQUARE = 'ICON_ARROW_UP_SQUARE',
  ICON_SAVE = 'ICON_SAVE',
  ICON_CHEVRON_EXPAND = 'ICON_CHEVRON_EXPAND',
  ICON_RECEPTION_3 = 'ICON_RECEPTION_3',
  ICON_STOP_BTN = 'ICON_STOP_BTN',
  ICON_SUNSET = 'ICON_SUNSET',
  ICON_CHECK_CIRCLE = 'ICON_CHECK_CIRCLE',
  ICON_CHECK_CIRCLE_2 = 'ICON_CHECK_CIRCLE_2',
  ICON_LIGHTBULB = 'ICON_LIGHTBULB',
  ICON_LAYOUT_SIDEBAR_INSET_REVERSE = 'ICON_LAYOUT_SIDEBAR_INSET_REVERSE',
  ICON_BANDAID = 'ICON_BANDAID',
  ICON_TOGGLES = 'ICON_TOGGLES',
  ICON_IMAGE = 'ICON_IMAGE',
  ICON_MOUSE3 = 'ICON_MOUSE3',
  ICON_EGG_FRIED = 'ICON_EGG_FRIED',
  ICON_SORT_ALPHA_DOWN_ALT = 'ICON_SORT_ALPHA_DOWN_ALT',
  ICON_ARROW_RIGHT_CIRCLE = 'ICON_ARROW_RIGHT_CIRCLE',
  ICON_JOURNAL_X = 'ICON_JOURNAL_X',
  ICON_TERMINAL_SPLIT = 'ICON_TERMINAL_SPLIT',
  ICON_ALIGN_START = 'ICON_ALIGN_START',
  ICON_WINDOW_X = 'ICON_WINDOW_X',
  ICON_SUBTRACT = 'ICON_SUBTRACT',
  ICON_DEVICE_SSD = 'ICON_DEVICE_SSD',
  ICON_TRIANGLE_HALF = 'ICON_TRIANGLE_HALF',
  ICON_TICKET_DETAILED = 'ICON_TICKET_DETAILED',
  ICON_CARET_LEFT_SQUARE = 'ICON_CARET_LEFT_SQUARE',
  ICON_BOOKMARK_X = 'ICON_BOOKMARK_X',
  ICON_BORDER_RIGHT = 'ICON_BORDER_RIGHT',
  ICON_BOOKSHELF = 'ICON_BOOKSHELF',
  ICON_GRAPH_DOWN_ARROW = 'ICON_GRAPH_DOWN_ARROW',
  ICON_BAG = 'ICON_BAG',
  ICON_MOUSE2 = 'ICON_MOUSE2',
  ICON_POSTAGE_HEART = 'ICON_POSTAGE_HEART',
  ICON_THERMOMETER_LOW = 'ICON_THERMOMETER_LOW',
  ICON_CALENDAR2_X = 'ICON_CALENDAR2_X',
  ICON_WRENCH_ADJUSTABLE = 'ICON_WRENCH_ADJUSTABLE',
  ICON_ZOOM_IN = 'ICON_ZOOM_IN',
  ICON_SORT_NUMERIC_DOWN_ALT = 'ICON_SORT_NUMERIC_DOWN_ALT',
  ICON_PERSON_SQUARE = 'ICON_PERSON_SQUARE',
  ICON_PERSON_WORKSPACE = 'ICON_PERSON_WORKSPACE',
  ICON_CHAT_SQUARE_DOTS = 'ICON_CHAT_SQUARE_DOTS',
  ICON_RECEPTION_2 = 'ICON_RECEPTION_2',
  ICON_SEGMENTED_NAV = 'ICON_SEGMENTED_NAV',
  ICON_SAFE2 = 'ICON_SAFE2',
  ICON_RAINBOW = 'ICON_RAINBOW',
  ICON_MIC = 'ICON_MIC',
  ICON_GRIP_HORIZONTAL = 'ICON_GRIP_HORIZONTAL',
  ICON_FOLDER_PLUS = 'ICON_FOLDER_PLUS',
  ICON_BOX_ARROW_IN_DOWN_RIGHT = 'ICON_BOX_ARROW_IN_DOWN_RIGHT',
  ICON_MOON_STARS = 'ICON_MOON_STARS',
  ICON_FILE_X = 'ICON_FILE_X',
  ICON_CLOUD_FOG = 'ICON_CLOUD_FOG',
  ICON_BOX_ARROW_RIGHT = 'ICON_BOX_ARROW_RIGHT',
  ICON_FILE_EARMARK_MEDICAL = 'ICON_FILE_EARMARK_MEDICAL',
  ICON_SKIP_START_CIRCLE = 'ICON_SKIP_START_CIRCLE',
  ICON_INFO_CIRCLE = 'ICON_INFO_CIRCLE',
  ICON_PEOPLE = 'ICON_PEOPLE',
  ICON_JUSTIFY_RIGHT = 'ICON_JUSTIFY_RIGHT',
  ICON_CHAT_RIGHT = 'ICON_CHAT_RIGHT',
  ICON_FILE_EARMARK_FONT = 'ICON_FILE_EARMARK_FONT',
  ICON_SPEEDOMETER = 'ICON_SPEEDOMETER',
  ICON_GEAR = 'ICON_GEAR',
  ICON_CALENDAR2_HEART = 'ICON_CALENDAR2_HEART',
  ICON_PENTAGON_HALF = 'ICON_PENTAGON_HALF',
  ICON_ARROW_BAR_RIGHT = 'ICON_ARROW_BAR_RIGHT',
  ICON_PALETTE = 'ICON_PALETTE',
  ICON_FAN = 'ICON_FAN',
  ICON_BOX_ARROW_DOWN = 'ICON_BOX_ARROW_DOWN',
  ICON_FILE_EARMARK_BAR_GRAPH = 'ICON_FILE_EARMARK_BAR_GRAPH',
  ICON_CHEVRON_COMPACT_UP = 'ICON_CHEVRON_COMPACT_UP',
  ICON_INFO = 'ICON_INFO',
  ICON_CHAT_SQUARE_HEART = 'ICON_CHAT_SQUARE_HEART',
  ICON_CLIPBOARD_HEART = 'ICON_CLIPBOARD_HEART',
  ICON_SPEEDOMETER2 = 'ICON_SPEEDOMETER2',
  ICON_POSTCARD_HEART = 'ICON_POSTCARD_HEART',
  ICON_PATCH_EXCLAMATION = 'ICON_PATCH_EXCLAMATION',
  ICON_BAR_CHART_LINE = 'ICON_BAR_CHART_LINE',
  ICON_LIST_NESTED = 'ICON_LIST_NESTED',
  ICON_WINDOW_DESKTOP = 'ICON_WINDOW_DESKTOP',
  ICON_JOURNAL_ARROW_UP = 'ICON_JOURNAL_ARROW_UP',
  ICON_SUIT_DIAMOND = 'ICON_SUIT_DIAMOND',
  ICON_WATCH = 'ICON_WATCH',
  ICON_X_LG = 'ICON_X_LG',
  ICON_GRAPH_DOWN = 'ICON_GRAPH_DOWN',
  ICON_EMOJI_FROWN = 'ICON_EMOJI_FROWN',
  ICON_WIFI = 'ICON_WIFI',
  ICON_SKIP_END_BTN = 'ICON_SKIP_END_BTN',
  ICON_RSS = 'ICON_RSS',
  ICON_CLOUD_HAZE = 'ICON_CLOUD_HAZE',
  ICON_CART4 = 'ICON_CART4',
  ICON_HEADPHONES = 'ICON_HEADPHONES',
  ICON_CALENDAR2_MINUS = 'ICON_CALENDAR2_MINUS',
  ICON_PIE_CHART = 'ICON_PIE_CHART',
  ICON_BLUETOOTH = 'ICON_BLUETOOTH',
  ICON_LAYOUT_SIDEBAR_INSET = 'ICON_LAYOUT_SIDEBAR_INSET',
  ICON_CLIPBOARD_PULSE = 'ICON_CLIPBOARD_PULSE',
  ICON_NUT = 'ICON_NUT',
  ICON_DASH = 'ICON_DASH',
  ICON_MENU_BUTTON = 'ICON_MENU_BUTTON',
  ICON_CHECK = 'ICON_CHECK',
  ICON_MUSIC_NOTE = 'ICON_MUSIC_NOTE',
  ICON_GEAR_WIDE = 'ICON_GEAR_WIDE',
  ICON_PLUS = 'ICON_PLUS',
  ICON_BATTERY_FULL = 'ICON_BATTERY_FULL',
  ICON_SHIELD_MINUS = 'ICON_SHIELD_MINUS',
  ICON_HASH = 'ICON_HASH',
  ICON_TROPHY = 'ICON_TROPHY',
  ICON_EMOJI_DIZZY = 'ICON_EMOJI_DIZZY',
  ICON_ENVELOPE_OPEN = 'ICON_ENVELOPE_OPEN',
  ICON_FILE_SLIDES = 'ICON_FILE_SLIDES',
  ICON_GENDER_AMBIGUOUS = 'ICON_GENDER_AMBIGUOUS',
  ICON_MAILBOX2 = 'ICON_MAILBOX2',
  ICON_CALENDAR3_RANGE = 'ICON_CALENDAR3_RANGE',
  ICON_CHEVRON_COMPACT_LEFT = 'ICON_CHEVRON_COMPACT_LEFT',
  ICON_RECORD = 'ICON_RECORD',
  ICON_JOURNAL_ALBUM = 'ICON_JOURNAL_ALBUM',
  ICON_FOLDER_X = 'ICON_FOLDER_X',
  ICON_CARET_RIGHT = 'ICON_CARET_RIGHT',
  ICON_BORDER = 'ICON_BORDER',
  ICON_FILE_EARMARK_ARROW_UP = 'ICON_FILE_EARMARK_ARROW_UP',
  ICON_CPU = 'ICON_CPU',
  ICON_CART_CHECK = 'ICON_CART_CHECK',
  ICON_CURSOR = 'ICON_CURSOR',
  ICON_CAMERA_VIDEO_OFF = 'ICON_CAMERA_VIDEO_OFF',
  ICON_ARROW_RETURN_LEFT = 'ICON_ARROW_RETURN_LEFT',
  ICON_STAR_HALF = 'ICON_STAR_HALF',
  ICON_ENVELOPE_EXCLAMATION = 'ICON_ENVELOPE_EXCLAMATION',
  ICON_FILE_MEDICAL = 'ICON_FILE_MEDICAL',
  ICON_MARKDOWN = 'ICON_MARKDOWN',
  ICON_DISC = 'ICON_DISC',
  ICON_ARROW_90DEG_DOWN = 'ICON_ARROW_90DEG_DOWN',
  ICON_NEWSPAPER = 'ICON_NEWSPAPER',
  ICON_BOX_ARROW_IN_LEFT = 'ICON_BOX_ARROW_IN_LEFT',
  ICON_ARROW_BAR_UP = 'ICON_ARROW_BAR_UP',
  ICON_FILES_ALT = 'ICON_FILES_ALT',
  ICON_USB_DRIVE = 'ICON_USB_DRIVE',
  ICON_ARROW_DOWN_SQUARE = 'ICON_ARROW_DOWN_SQUARE',
  ICON_BORDER_MIDDLE = 'ICON_BORDER_MIDDLE',
  ICON_PATCH_CHECK = 'ICON_PATCH_CHECK',
  ICON_BATTERY = 'ICON_BATTERY',
  ICON_CARET_UP_SQUARE = 'ICON_CARET_UP_SQUARE',
  ICON_ARROW_LEFT_SHORT = 'ICON_ARROW_LEFT_SHORT',
  ICON_SHOP_WINDOW = 'ICON_SHOP_WINDOW',
  ICON_FLAG = 'ICON_FLAG',
  ICON_THERMOMETER = 'ICON_THERMOMETER',
  ICON_SQUARE_HALF = 'ICON_SQUARE_HALF',
  ICON_BOOKMARK_HEART = 'ICON_BOOKMARK_HEART',
  ICON_CODE = 'ICON_CODE',
  ICON_FUNNEL = 'ICON_FUNNEL',
  ICON_BADGE_HD = 'ICON_BADGE_HD',
  ICON_CONTROLLER = 'ICON_CONTROLLER',
  ICON_BELL = 'ICON_BELL',
  ICON_THERMOMETER_HIGH = 'ICON_THERMOMETER_HIGH',
  ICON_FILE_IMAGE = 'ICON_FILE_IMAGE',
  ICON_BADGE_3D = 'ICON_BADGE_3D',
  ICON_WALLET = 'ICON_WALLET',
  ICON_CHEVRON_BAR_EXPAND = 'ICON_CHEVRON_BAR_EXPAND',
  ICON_TERMINAL_PLUS = 'ICON_TERMINAL_PLUS',
  ICON_EYEDROPPER = 'ICON_EYEDROPPER',
  ICON_SHIELD_X = 'ICON_SHIELD_X',
  ICON_PLUG = 'ICON_PLUG',
  ICON_PARAGRAPH = 'ICON_PARAGRAPH',
  ICON_TERMINAL_DASH = 'ICON_TERMINAL_DASH',
  ICON_CHAT_SQUARE_QUOTE = 'ICON_CHAT_SQUARE_QUOTE',
  ICON_HDMI = 'ICON_HDMI',
  ICON_CARD_IMAGE = 'ICON_CARD_IMAGE',
  ICON_FILTER_RIGHT = 'ICON_FILTER_RIGHT',
  ICON_BOOK = 'ICON_BOOK',
  ICON_MUSIC_PLAYER = 'ICON_MUSIC_PLAYER',
  ICON_SLASH = 'ICON_SLASH',
  ICON_CAMERA_REELS = 'ICON_CAMERA_REELS',
  ICON_LAYERS = 'ICON_LAYERS',
  ICON_CLOUD_ARROW_UP = 'ICON_CLOUD_ARROW_UP',
  ICON_PATCH_PLUS = 'ICON_PATCH_PLUS',
  ICON_BOOKMARK_STAR = 'ICON_BOOKMARK_STAR',
  ICON_FOLDER2 = 'ICON_FOLDER2',
  ICON_BATTERY_CHARGING = 'ICON_BATTERY_CHARGING',
  ICON_ARROW_CLOCKWISE = 'ICON_ARROW_CLOCKWISE',
  ICON_CHAT_RIGHT_DOTS = 'ICON_CHAT_RIGHT_DOTS',
  ICON_PENCIL_SQUARE = 'ICON_PENCIL_SQUARE',
  ICON_FILE_BREAK = 'ICON_FILE_BREAK',
  ICON_SKIP_FORWARD = 'ICON_SKIP_FORWARD',
  ICON_APP = 'ICON_APP',
  ICON_TV = 'ICON_TV',
  ICON_SERVER = 'ICON_SERVER',
  ICON_FLOWER1 = 'ICON_FLOWER1',
  ICON_PROJECTOR = 'ICON_PROJECTOR',
  ICON_CLIPBOARD2_CHECK = 'ICON_CLIPBOARD2_CHECK',
  ICON_SLASH_CIRCLE = 'ICON_SLASH_CIRCLE',
  ICON_CHECK2_ALL = 'ICON_CHECK2_ALL',
  ICON_JOURNAL_PLUS = 'ICON_JOURNAL_PLUS',
  ICON_WEBCAM = 'ICON_WEBCAM',
  ICON_CHAT_LEFT_TEXT = 'ICON_CHAT_LEFT_TEXT',
  ICON_ARROW_DOWN_SHORT = 'ICON_ARROW_DOWN_SHORT',
  ICON_PIN = 'ICON_PIN',
  ICON_WINDOW_FULLSCREEN = 'ICON_WINDOW_FULLSCREEN',
  ICON_BRACES_ASTERISK = 'ICON_BRACES_ASTERISK',
  ICON_KANBAN = 'ICON_KANBAN',
  ICON_DOWNLOAD = 'ICON_DOWNLOAD',
  ICON_CART2 = 'ICON_CART2',
  ICON_SHIELD = 'ICON_SHIELD',
  ICON_PATCH_QUESTION = 'ICON_PATCH_QUESTION',
  ICON_FILE_EARMARK_IMAGE = 'ICON_FILE_EARMARK_IMAGE',
  ICON_MAGNET = 'ICON_MAGNET',
  ICON_SUIT_SPADE = 'ICON_SUIT_SPADE',
  ICON_CART3 = 'ICON_CART3',
  ICON_BORDER_WIDTH = 'ICON_BORDER_WIDTH',
  ICON_ARROW_UP_LEFT_SQUARE = 'ICON_ARROW_UP_LEFT_SQUARE',
  ICON_BRIGHTNESS_LOW = 'ICON_BRIGHTNESS_LOW',
  ICON_CARET_UP = 'ICON_CARET_UP',
  ICON_PERSON_X = 'ICON_PERSON_X',
  ICON_EMOJI_EXPRESSIONLESS = 'ICON_EMOJI_EXPRESSIONLESS',
  ICON_ARCHIVE = 'ICON_ARCHIVE',
  ICON_IMAGE_ALT = 'ICON_IMAGE_ALT',
  ICON_ASPECT_RATIO = 'ICON_ASPECT_RATIO',
  ICON_JOURNAL_MINUS = 'ICON_JOURNAL_MINUS',
  ICON_BORDER_INNER = 'ICON_BORDER_INNER',
  ICON_ENVELOPE = 'ICON_ENVELOPE',
  ICON_CART = 'ICON_CART',
  ICON_BANK2 = 'ICON_BANK2',
  ICON_TYPE = 'ICON_TYPE',
  ICON_HOUSE_HEART = 'ICON_HOUSE_HEART',
  ICON_BRICKS = 'ICON_BRICKS',
  ICON_LIST_TASK = 'ICON_LIST_TASK',
  ICON_CALENDAR_WEEK = 'ICON_CALENDAR_WEEK',
  ICON_CLOUD_SNOW = 'ICON_CLOUD_SNOW',
  ICON_BORDER_ALL = 'ICON_BORDER_ALL',
  ICON_MUSIC_NOTE_BEAMED = 'ICON_MUSIC_NOTE_BEAMED',
  ICON_MENU_UP = 'ICON_MENU_UP',
  ICON_STOPWATCH = 'ICON_STOPWATCH',
  ICON_ENVELOPE_HEART = 'ICON_ENVELOPE_HEART',
  ICON_ARROWS_FULLSCREEN = 'ICON_ARROWS_FULLSCREEN',
  ICON_ARROW_DOWN_UP = 'ICON_ARROW_DOWN_UP',
  ICON_PAUSE_BTN = 'ICON_PAUSE_BTN',
  ICON_CALENDAR_CHECK = 'ICON_CALENDAR_CHECK',
  ICON_ARROWS_ANGLE_EXPAND = 'ICON_ARROWS_ANGLE_EXPAND',
  ICON_INBOXES = 'ICON_INBOXES',
  ICON_TERMINAL_X = 'ICON_TERMINAL_X',
  ICON_ARROW_LEFT_CIRCLE = 'ICON_ARROW_LEFT_CIRCLE',
  ICON_FILE_SPREADSHEET = 'ICON_FILE_SPREADSHEET',
  ICON_STOP_CIRCLE = 'ICON_STOP_CIRCLE',
  ICON_HURRICANE = 'ICON_HURRICANE',
  ICON_AT = 'ICON_AT',
  ICON_SKIP_FORWARD_BTN = 'ICON_SKIP_FORWARD_BTN',
  ICON_ARROW_RIGHT_SHORT = 'ICON_ARROW_RIGHT_SHORT',
  ICON_JOURNAL_BOOKMARK = 'ICON_JOURNAL_BOOKMARK',
  ICON_BRUSH = 'ICON_BRUSH',
  ICON_CALENDAR_PLUS = 'ICON_CALENDAR_PLUS',
  ICON_ARROW_RIGHT = 'ICON_ARROW_RIGHT',
  ICON_CLOUD_SUN = 'ICON_CLOUD_SUN',
  ICON_DOOR_OPEN = 'ICON_DOOR_OPEN',
  ICON_USB_MICRO = 'ICON_USB_MICRO',
  ICON_BLOCKQUOTE_RIGHT = 'ICON_BLOCKQUOTE_RIGHT',
  ICON_FLOWER2 = 'ICON_FLOWER2',
  ICON_HEXAGON_HALF = 'ICON_HEXAGON_HALF',
  ICON_ARROW_DOWN_LEFT_CIRCLE = 'ICON_ARROW_DOWN_LEFT_CIRCLE',
  ICON_KEY = 'ICON_KEY',
  ICON_TOGGLE_OFF = 'ICON_TOGGLE_OFF',
  ICON_LAYOUT_THREE_COLUMNS = 'ICON_LAYOUT_THREE_COLUMNS',
  ICON_LINK = 'ICON_LINK',
  ICON_PERSON_BADGE = 'ICON_PERSON_BADGE',
  ICON_DIAGRAM_2 = 'ICON_DIAGRAM_2',
  ICON_CAMERA2 = 'ICON_CAMERA2',
  ICON_CLIPBOARD = 'ICON_CLIPBOARD',
  ICON_HOURGLASS_SPLIT = 'ICON_HOURGLASS_SPLIT',
  ICON_TOGGLE2_OFF = 'ICON_TOGGLE2_OFF',
  ICON_CHEVRON_RIGHT = 'ICON_CHEVRON_RIGHT',
  ICON_BOOMBOX = 'ICON_BOOMBOX',
  ICON_FILE_FONT = 'ICON_FILE_FONT',
  ICON_PEN = 'ICON_PEN',
  ICON_BOX_ARROW_DOWN_RIGHT = 'ICON_BOX_ARROW_DOWN_RIGHT',
  ICON_DISTRIBUTE_VERTICAL = 'ICON_DISTRIBUTE_VERTICAL',
  ICON_CALENDAR_EVENT = 'ICON_CALENDAR_EVENT',
  ICON_DASH_CIRCLE = 'ICON_DASH_CIRCLE',
  ICON_DEVICE_HDD = 'ICON_DEVICE_HDD',
  ICON_CLOUD_DRIZZLE = 'ICON_CLOUD_DRIZZLE',
  ICON_ARROW_RETURN_RIGHT = 'ICON_ARROW_RETURN_RIGHT',
  ICON_SOUNDWAVE = 'ICON_SOUNDWAVE',
  ICON_CALENDAR_MONTH = 'ICON_CALENDAR_MONTH',
  ICON_RECORD2 = 'ICON_RECORD2',
  ICON_JOURNAL_CODE = 'ICON_JOURNAL_CODE',
  ICON_SD_CARD = 'ICON_SD_CARD',
  ICON_DIAGRAM_3 = 'ICON_DIAGRAM_3',
  ICON_PIGGY_BANK = 'ICON_PIGGY_BANK',
  ICON_POSTCARD = 'ICON_POSTCARD',
  ICON_NODE_PLUS = 'ICON_NODE_PLUS',
  ICON_THREE_DOTS = 'ICON_THREE_DOTS',
  ICON_CLOUD_UPLOAD = 'ICON_CLOUD_UPLOAD',
  ICON_LOCK = 'ICON_LOCK',
  ICON_QUESTION = 'ICON_QUESTION',
  ICON_FLOWER3 = 'ICON_FLOWER3',
  ICON_SKIP_BACKWARD_CIRCLE = 'ICON_SKIP_BACKWARD_CIRCLE',
  ICON_QUESTION_CIRCLE = 'ICON_QUESTION_CIRCLE',
  ICON_DOT = 'ICON_DOT',
  ICON_EXCLAMATION_TRIANGLE = 'ICON_EXCLAMATION_TRIANGLE',
  ICON_SEND_EXCLAMATION = 'ICON_SEND_EXCLAMATION',
  ICON_QR_CODE = 'ICON_QR_CODE',
  ICON_BAR_CHART = 'ICON_BAR_CHART',
  ICON_JOYSTICK = 'ICON_JOYSTICK',
  ICON_USB_SYMBOL = 'ICON_USB_SYMBOL',
  ICON_X = 'ICON_X',
  ICON_BOX2 = 'ICON_BOX2',
  ICON_KEYBOARD = 'ICON_KEYBOARD',
  ICON_DROPLET = 'ICON_DROPLET',
  ICON_CHAT_TEXT = 'ICON_CHAT_TEXT',
  ICON_FILE_TEXT = 'ICON_FILE_TEXT',
  ICON_ARROW_DOWN_LEFT = 'ICON_ARROW_DOWN_LEFT',
  ICON_CHAT_LEFT = 'ICON_CHAT_LEFT',
  ICON_HDD_NETWORK = 'ICON_HDD_NETWORK',
  ICON_DPAD = 'ICON_DPAD',
  ICON_EMOJI_KISS = 'ICON_EMOJI_KISS',
  ICON_FOLDER_SYMLINK = 'ICON_FOLDER_SYMLINK',
  ICON_CHEVRON_UP = 'ICON_CHEVRON_UP',
  ICON_EMOJI_WINK = 'ICON_EMOJI_WINK',
  ICON_GRID_3X3_GAP = 'ICON_GRID_3X3_GAP',
  ICON_JOURNAL_MEDICAL = 'ICON_JOURNAL_MEDICAL',
  ICON_BOOK_HALF = 'ICON_BOOK_HALF',
  ICON_CASH_COIN = 'ICON_CASH_COIN',
  ICON_TERMINAL = 'ICON_TERMINAL',
  ICON_VOICEMAIL = 'ICON_VOICEMAIL',
  ICON_FILE_EARMARK_EXCEL = 'ICON_FILE_EARMARK_EXCEL',
  ICON_FILES = 'ICON_FILES',
  ICON_BOX_SEAM = 'ICON_BOX_SEAM',
  ICON_BADGE_CC = 'ICON_BADGE_CC',
  ICON_LIST_STARS = 'ICON_LIST_STARS',
  ICON_MENU_BUTTON_WIDE = 'ICON_MENU_BUTTON_WIDE',
  ICON_PUZZLE = 'ICON_PUZZLE',
  ICON_FILE = 'ICON_FILE',
  ICON_CALENDAR2_RANGE = 'ICON_CALENDAR2_RANGE',
  ICON_WALLET2 = 'ICON_WALLET2',
  ICON_CHECK2_SQUARE = 'ICON_CHECK2_SQUARE',
  ICON_CHAT_LEFT_QUOTE = 'ICON_CHAT_LEFT_QUOTE',
  ICON_EARBUDS = 'ICON_EARBUDS',
  ICON_TELEPHONE_PLUS = 'ICON_TELEPHONE_PLUS',
  ICON_CLIPBOARD2_PLUS = 'ICON_CLIPBOARD2_PLUS',
  ICON_CLOUDS = 'ICON_CLOUDS',
  ICON_LAYOUT_SIDEBAR_REVERSE = 'ICON_LAYOUT_SIDEBAR_REVERSE',
  ICON_CLOUD_SLASH = 'ICON_CLOUD_SLASH',
  ICON_CALENDAR_DAY = 'ICON_CALENDAR_DAY',
  ICON_POWER = 'ICON_POWER',
  ICON_PLUS_SQUARE = 'ICON_PLUS_SQUARE',
  ICON_ARROW_UP_RIGHT_SQUARE = 'ICON_ARROW_UP_RIGHT_SQUARE',
  ICON_BLOCKQUOTE_LEFT = 'ICON_BLOCKQUOTE_LEFT',
  ICON_INBOX = 'ICON_INBOX',
  ICON_MAP = 'ICON_MAP',
  ICON_CONE_STRIPED = 'ICON_CONE_STRIPED',
  ICON_HAND_THUMBS_DOWN = 'ICON_HAND_THUMBS_DOWN',
  ICON_HEADSET = 'ICON_HEADSET',
  ICON_USB_PLUG = 'ICON_USB_PLUG',
  ICON_DISTRIBUTE_HORIZONTAL = 'ICON_DISTRIBUTE_HORIZONTAL',
  ICON_CLOUD_PLUS = 'ICON_CLOUD_PLUS',
  ICON_CHEVRON_BAR_CONTRACT = 'ICON_CHEVRON_BAR_CONTRACT',
  ICON_THERMOMETER_HALF = 'ICON_THERMOMETER_HALF',
  ICON_PHONE_LANDSCAPE = 'ICON_PHONE_LANDSCAPE',
  ICON_CHEVRON_BAR_UP = 'ICON_CHEVRON_BAR_UP',
  ICON_CURSOR_TEXT = 'ICON_CURSOR_TEXT',
  ICON_PLUGIN = 'ICON_PLUGIN',
  ICON_FILE_EARMARK_X = 'ICON_FILE_EARMARK_X',
  ICON_HEPTAGON_HALF = 'ICON_HEPTAGON_HALF',
  ICON_FILE_EARMARK_EASEL = 'ICON_FILE_EARMARK_EASEL',
  ICON_EXCLAMATION_SQUARE = 'ICON_EXCLAMATION_SQUARE',
  ICON_ALIGN_BOTTOM = 'ICON_ALIGN_BOTTOM',
  ICON_EMOJI_SMILE_UPSIDE_DOWN = 'ICON_EMOJI_SMILE_UPSIDE_DOWN',
  ICON_BRIEFCASE = 'ICON_BRIEFCASE',
  ICON_OPTION = 'ICON_OPTION',
  ICON_TAG = 'ICON_TAG',
  ICON_BAG_CHECK = 'ICON_BAG_CHECK',
  ICON_CLOUD_HAZE2 = 'ICON_CLOUD_HAZE2',
  ICON_CARET_DOWN_SQUARE = 'ICON_CARET_DOWN_SQUARE',
  ICON_CROP = 'ICON_CROP',
  ICON_CLOUD_SLEET = 'ICON_CLOUD_SLEET',
  ICON_TICKET = 'ICON_TICKET',
  ICON_CHAT_LEFT_HEART = 'ICON_CHAT_LEFT_HEART',
  ICON_USB = 'ICON_USB',
  ICON_CONE = 'ICON_CONE',
  ICON_FILE_EARMARK_ARROW_DOWN = 'ICON_FILE_EARMARK_ARROW_DOWN',
  ICON_DISPLAYPORT = 'ICON_DISPLAYPORT',
  ICON_SORT_ALPHA_UP = 'ICON_SORT_ALPHA_UP',
  ICON_CHEVRON_BAR_LEFT = 'ICON_CHEVRON_BAR_LEFT',
  ICON_DOOR_CLOSED = 'ICON_DOOR_CLOSED',
  ICON_SIGNPOST = 'ICON_SIGNPOST',
  ICON_LAYOUT_TEXT_SIDEBAR_REVERSE = 'ICON_LAYOUT_TEXT_SIDEBAR_REVERSE',
  ICON_HEARTBREAK = 'ICON_HEARTBREAK',
  ICON_SHIELD_PLUS = 'ICON_SHIELD_PLUS',
  ICON_GLOBE2 = 'ICON_GLOBE2',
  ICON_TEXT_CENTER = 'ICON_TEXT_CENTER',
  ICON_TRUCK_FLATBED = 'ICON_TRUCK_FLATBED',
  ICON_BORDER_CENTER = 'ICON_BORDER_CENTER',
  ICON_SORT_NUMERIC_UP_ALT = 'ICON_SORT_NUMERIC_UP_ALT',
  ICON_RECEIPT_CUTOFF = 'ICON_RECEIPT_CUTOFF',
  ICON_EXCLAMATION_OCTAGON = 'ICON_EXCLAMATION_OCTAGON',
  ICON_HEXAGON = 'ICON_HEXAGON',
  ICON_CLOUD_CHECK = 'ICON_CLOUD_CHECK',
  ICON_PERSON_HEART = 'ICON_PERSON_HEART',
  ICON_FILE_EARMARK_MINUS = 'ICON_FILE_EARMARK_MINUS',
  ICON_CHEVRON_DOUBLE_LEFT = 'ICON_CHEVRON_DOUBLE_LEFT',
  ICON_CHEVRON_DOWN = 'ICON_CHEVRON_DOWN',
  ICON_CLOUD_FOG2 = 'ICON_CLOUD_FOG2',
  ICON_ARROW_90DEG_UP = 'ICON_ARROW_90DEG_UP',
  ICON_FULLSCREEN_EXIT = 'ICON_FULLSCREEN_EXIT',
  ICON_ARROW_UP_LEFT = 'ICON_ARROW_UP_LEFT',
  ICON_EJECT = 'ICON_EJECT',
  ICON_RECYCLE = 'ICON_RECYCLE',
  ICON_ARROW_UP_SHORT = 'ICON_ARROW_UP_SHORT',
  ICON_CHEVRON_DOUBLE_RIGHT = 'ICON_CHEVRON_DOUBLE_RIGHT',
  ICON_EXCLAMATION_DIAMOND = 'ICON_EXCLAMATION_DIAMOND',
  ICON_SEND_X = 'ICON_SEND_X',
  ICON_CLOUD_LIGHTNING_RAIN = 'ICON_CLOUD_LIGHTNING_RAIN',
  ICON_CLOUD_RAIN_HEAVY = 'ICON_CLOUD_RAIN_HEAVY',
  ICON_SLIDERS = 'ICON_SLIDERS',
  ICON_HANDBAG = 'ICON_HANDBAG',
  ICON_FILE_PLAY = 'ICON_FILE_PLAY',
  ICON_ENVELOPE_OPEN_HEART = 'ICON_ENVELOPE_OPEN_HEART',
  ICON_MAILBOX = 'ICON_MAILBOX',
  ICON_UI_CHECKS = 'ICON_UI_CHECKS',
  ICON_SAFE = 'ICON_SAFE',
  ICON_BATTERY_HALF = 'ICON_BATTERY_HALF',
  ICON_SHOP = 'ICON_SHOP',
  ICON_BALLOON_HEART = 'ICON_BALLOON_HEART',
  ICON_UI_CHECKS_GRID = 'ICON_UI_CHECKS_GRID',
  ICON_PERSON = 'ICON_PERSON',
  ICON_VIEW_STACKED = 'ICON_VIEW_STACKED',
  ICON_VIEW_LIST = 'ICON_VIEW_LIST',
  ICON_CIRCLE = 'ICON_CIRCLE',
  ICON_PERSON_PLUS = 'ICON_PERSON_PLUS',
  ICON_HDD_STACK = 'ICON_HDD_STACK',
  ICON_FOLDER2_OPEN = 'ICON_FOLDER2_OPEN',
  ICON_BOX_ARROW_DOWN_LEFT = 'ICON_BOX_ARROW_DOWN_LEFT',
  ICON_LAYOUT_SIDEBAR = 'ICON_LAYOUT_SIDEBAR',
  ICON_MOTHERBOARD = 'ICON_MOTHERBOARD',
  ICON_ARROWS_MOVE = 'ICON_ARROWS_MOVE',
  ICON_FOLDER_CHECK = 'ICON_FOLDER_CHECK',
  ICON_CALENDAR4_RANGE = 'ICON_CALENDAR4_RANGE',
  ICON_PERSON_DASH = 'ICON_PERSON_DASH',
  ICON_BAG_DASH = 'ICON_BAG_DASH',
  ICON_ARROW_BAR_LEFT = 'ICON_ARROW_BAR_LEFT',
  ICON_HEPTAGON = 'ICON_HEPTAGON',
  ICON_TYPE_BOLD = 'ICON_TYPE_BOLD',
  ICON_X_CIRCLE = 'ICON_X_CIRCLE',
  ICON_FILE_MINUS = 'ICON_FILE_MINUS',
  ICON_SHIFT = 'ICON_SHIFT',
  ICON_HAND_THUMBS_UP = 'ICON_HAND_THUMBS_UP',
  ICON_BEZIER2 = 'ICON_BEZIER2',
  ICON_CART_DASH = 'ICON_CART_DASH',
  ICON_HEART_ARROW = 'ICON_HEART_ARROW',
  ICON_CARD_TEXT = 'ICON_CARD_TEXT',
  ICON_BADGE_TM = 'ICON_BADGE_TM',
  ICON_MODEM = 'ICON_MODEM',
  ICON_FILE_EARMARK = 'ICON_FILE_EARMARK',
  ICON_FULLSCREEN = 'ICON_FULLSCREEN',
  ICON_CART_PLUS = 'ICON_CART_PLUS',
  ICON_VOLUME_DOWN = 'ICON_VOLUME_DOWN',
  ICON_BADGE_VR = 'ICON_BADGE_VR',
  ICON_QR_CODE_SCAN = 'ICON_QR_CODE_SCAN',
  ICON_FILE_PERSON = 'ICON_FILE_PERSON',
  ICON_EXCLAMATION_LG = 'ICON_EXCLAMATION_LG',
  ICON_FILE_EARMARK_PLAY = 'ICON_FILE_EARMARK_PLAY',
  ICON_UI_RADIOS = 'ICON_UI_RADIOS',
  ICON_TRANSLATE = 'ICON_TRANSLATE',
  ICON_X_DIAMOND = 'ICON_X_DIAMOND',
  ICON_SNOW = 'ICON_SNOW',
  ICON_TYPE_H1 = 'ICON_TYPE_H1',
  ICON_UPC_SCAN = 'ICON_UPC_SCAN',
  ICON_GENDER_TRANS = 'ICON_GENDER_TRANS',
  ICON_ARROW_DOWN_RIGHT_CIRCLE = 'ICON_ARROW_DOWN_RIGHT_CIRCLE',
  ICON_CHECK_ALL = 'ICON_CHECK_ALL',
  ICON_SUNRISE = 'ICON_SUNRISE',
  ICON_BODY_TEXT = 'ICON_BODY_TEXT',
  ICON_REPLY = 'ICON_REPLY',
  ICON_TEXT_INDENT_RIGHT = 'ICON_TEXT_INDENT_RIGHT',
  ICON_SORT_ALPHA_UP_ALT = 'ICON_SORT_ALPHA_UP_ALT',
  ICON_BOXES = 'ICON_BOXES',
  ICON_WIFI_1 = 'ICON_WIFI_1',
  ICON_HOURGLASS = 'ICON_HOURGLASS',
  ICON_CURRENCY_POUND = 'ICON_CURRENCY_POUND',
  ICON_META = 'ICON_META',
  ICON_SHIELD_LOCK = 'ICON_SHIELD_LOCK',
  ICON_SUIT_CLUB = 'ICON_SUIT_CLUB',
  ICON_FILE_EASEL = 'ICON_FILE_EASEL',
  ICON_FILE_EARMARK_WORD = 'ICON_FILE_EARMARK_WORD',
  ICON_X_OCTAGON = 'ICON_X_OCTAGON',
  ICON_STRAVA = 'ICON_STRAVA',
  ICON_CHEVRON_CONTRACT = 'ICON_CHEVRON_CONTRACT',
  ICON_TOOLS = 'ICON_TOOLS',
  ICON_SIGNPOST_SPLIT = 'ICON_SIGNPOST_SPLIT',
  ICON_SEARCH_HEART = 'ICON_SEARCH_HEART',
  ICON_GRID_3X2_GAP = 'ICON_GRID_3X2_GAP',
  ICON_BORDER_OUTER = 'ICON_BORDER_OUTER',
  ICON_FILE_ZIP = 'ICON_FILE_ZIP',
  ICON_FILE_EARMARK_BINARY = 'ICON_FILE_EARMARK_BINARY',
  ICON_PLAY_CIRCLE = 'ICON_PLAY_CIRCLE',
  ICON_SCISSORS = 'ICON_SCISSORS',
  ICON_PERSON_CIRCLE = 'ICON_PERSON_CIRCLE',
  ICON_TYPE_ITALIC = 'ICON_TYPE_ITALIC',
  ICON_SAVE2 = 'ICON_SAVE2',
  ICON_LAYOUT_TEXT_WINDOW = 'ICON_LAYOUT_TEXT_WINDOW',
  ICON_FILE_EARMARK_ZIP = 'ICON_FILE_EARMARK_ZIP',
  ICON_TICKET_PERFORATED = 'ICON_TICKET_PERFORATED',
  ICON_WIND = 'ICON_WIND',
  ICON_PC_HORIZONTAL = 'ICON_PC_HORIZONTAL',
  ICON_TEXTAREA_RESIZE = 'ICON_TEXTAREA_RESIZE',
  ICON_SORT_NUMERIC_UP = 'ICON_SORT_NUMERIC_UP',
  ICON_HOUSE = 'ICON_HOUSE',
  ICON_PERSON_VIDEO = 'ICON_PERSON_VIDEO',
  ICON_CIRCLE_HALF = 'ICON_CIRCLE_HALF',
  ICON_HOUSE_DOOR = 'ICON_HOUSE_DOOR',
  ICON_FILE_EXCEL = 'ICON_FILE_EXCEL',
  ICON_FILE_EARMARK_TEXT = 'ICON_FILE_EARMARK_TEXT',
  ICON_INFO_SQUARE = 'ICON_INFO_SQUARE',
  ICON_MEGAPHONE = 'ICON_MEGAPHONE',
  ICON_OUTLET = 'ICON_OUTLET',
  ICON_VOLUME_OFF = 'ICON_VOLUME_OFF',
  ICON_PIN_ANGLE = 'ICON_PIN_ANGLE',
  ICON_LIST = 'ICON_LIST',
  ICON_GRAPH_UP_ARROW = 'ICON_GRAPH_UP_ARROW',
  ICON_ALIGN_END = 'ICON_ALIGN_END',
  ICON_QUESTION_DIAMOND = 'ICON_QUESTION_DIAMOND',
  ICON_CHECK_LG = 'ICON_CHECK_LG',
  ICON_TYPE_H2 = 'ICON_TYPE_H2',
  ICON_BADGE_WC = 'ICON_BADGE_WC',
  ICON_SORT_UP_ALT = 'ICON_SORT_UP_ALT',
  ICON_ARROW_DOWN = 'ICON_ARROW_DOWN',
  ICON_CHECK_SQUARE = 'ICON_CHECK_SQUARE',
  ICON_PAUSE_CIRCLE = 'ICON_PAUSE_CIRCLE',
  ICON_RECEIPT = 'ICON_RECEIPT',
  ICON_ARROW_UP_CIRCLE = 'ICON_ARROW_UP_CIRCLE',
  ICON_LIST_UL = 'ICON_LIST_UL',
  ICON_ERASER = 'ICON_ERASER',
  ICON_CLIPBOARD_PLUS = 'ICON_CLIPBOARD_PLUS',
  ICON_CALENDAR2_DATE = 'ICON_CALENDAR2_DATE',
  ICON_INCOGNITO = 'ICON_INCOGNITO',
  ICON_WIFI_2 = 'ICON_WIFI_2',
  ICON_CURRENCY_BITCOIN = 'ICON_CURRENCY_BITCOIN',
  ICON_SEARCH = 'ICON_SEARCH',
  ICON_CODE_SLASH = 'ICON_CODE_SLASH',
  ICON_SIM = 'ICON_SIM',
  ICON_MEDIUM = 'ICON_MEDIUM',
  ICON_TOGGLE_ON = 'ICON_TOGGLE_ON',
  ICON_TRIANGLE = 'ICON_TRIANGLE',
  ICON_GRID = 'ICON_GRID',
  ICON_CHAT_SQUARE_TEXT = 'ICON_CHAT_SQUARE_TEXT',
  ICON_CARET_DOWN = 'ICON_CARET_DOWN',
  ICON_LIGHTNING = 'ICON_LIGHTNING',
  ICON_JUSTIFY_LEFT = 'ICON_JUSTIFY_LEFT',
  ICON_QUESTION_OCTAGON = 'ICON_QUESTION_OCTAGON',
  ICON_ARROW_RIGHT_SQUARE = 'ICON_ARROW_RIGHT_SQUARE',
  ICON_BOOTSTRAP = 'ICON_BOOTSTRAP',
  ICON_CUP_STRAW = 'ICON_CUP_STRAW',
  ICON_FILE_ARROW_UP = 'ICON_FILE_ARROW_UP',
  ICON_LADDER = 'ICON_LADDER',
  ICON_HYPNOTIZE = 'ICON_HYPNOTIZE',
  ICON_LAYER_BACKWARD = 'ICON_LAYER_BACKWARD',
  ICON_COLUMNS = 'ICON_COLUMNS',
}

export const OUTLINED_ICON_SYMBOLS = {
  SEARCH: 'icon-search',
  CARET_DOWN: 'icon-chevron-down',
  ICON_EXCLAMATION: 'icon-exclamation',
  ICON_EYEGLASSES: 'icon-eyeglasses',
  ICON_BOOKMARK: 'icon-bookmark',
  ICON_TEXT_RIGHT: 'icon-text-right',
  ICON_HEARTS: 'icon-hearts',
  ICON_SIGNPOST_2: 'icon-signpost-2',
  ICON_OCTAGON: 'icon-octagon',
  ICON_TYPE_STRIKETHROUGH: 'icon-type-strikethrough',
  ICON_PENCIL: 'icon-pencil',
  ICON_HAMMER: 'icon-hammer',
  ICON_PLUS_SLASH_MINUS: 'icon-plus-slash-minus',
  ICON_RECEPTION_4: 'icon-reception-4',
  ICON_LAYOUT_SPLIT: 'icon-layout-split',
  ICON_STOPLIGHTS: 'icon-stoplights',
  ICON_APP_INDICATOR: 'icon-app-indicator',
  ICON_BOX: 'icon-box',
  ICON_TELEPHONE_INBOUND: 'icon-telephone-inbound',
  ICON_HOSPITAL: 'icon-hospital',
  ICON_CLIPBOARD_MINUS: 'icon-clipboard-minus',
  ICON_HOURGLASS_TOP: 'icon-hourglass-top',
  ICON_STOP: 'icon-stop',
  ICON_CAST: 'icon-cast',
  ICON_RECORD_BTN: 'icon-record-btn',
  ICON_FILE_EARMARK_BREAK: 'icon-file-earmark-break',
  ICON_FILTER_CIRCLE: 'icon-filter-circle',
  ICON_CARD_HEADING: 'icon-card-heading',
  ICON_EMOJI_NEUTRAL: 'icon-emoji-neutral',
  ICON_USB_C: 'icon-usb-c',
  ICON_BAG_PLUS: 'icon-bag-plus',
  ICON_UMBRELLA: 'icon-umbrella',
  ICON_TYPE_H3: 'icon-type-h3',
  ICON_RULERS: 'icon-rulers',
  ICON_JOURNAL: 'icon-journal',
  ICON_PERSON_BOUNDING_BOX: 'icon-person-bounding-box',
  ICON_HEART: 'icon-heart',
  ICON_TOGGLES2: 'icon-toggles2',
  ICON_SORT_UP: 'icon-sort-up',
  ICON_EASEL: 'icon-easel',
  ICON_JOURNALS: 'icon-journals',
  ICON_CHEVRON_DOUBLE_UP: 'icon-chevron-double-up',
  ICON_CART_X: 'icon-cart-x',
  ICON_BAR_CHART_STEPS: 'icon-bar-chart-steps',
  ICON_CLIPBOARD2: 'icon-clipboard2',
  ICON_BEHANCE: 'icon-behance',
  ICON_LAYOUT_WTF: 'icon-layout-wtf',
  ICON_MOON: 'icon-moon',
  ICON_FILM: 'icon-film',
  ICON_CHEVRON_LEFT: 'icon-chevron-left',
  ICON_PAINT_BUCKET: 'icon-paint-bucket',
  ICON_TELEPHONE_MINUS: 'icon-telephone-minus',
  ICON_CHEVRON_DOUBLE_DOWN: 'icon-chevron-double-down',
  ICON_BADGE_8K: 'icon-badge-8k',
  ICON_HEART_PULSE: 'icon-heart-pulse',
  ICON_CLIPBOARD_CHECK: 'icon-clipboard-check',
  ICON_CREDIT_CARD_2_BACK: 'icon-credit-card-2-back',
  ICON_SKIP_START: 'icon-skip-start',
  ICON_THERMOMETER_SNOW: 'icon-thermometer-snow',
  ICON_DASH_LG: 'icon-dash-lg',
  ICON_FILE_POST: 'icon-file-post',
  ICON_FILE_PDF: 'icon-file-pdf',
  ICON_FINGERPRINT: 'icon-fingerprint',
  ICON_TYPE_UNDERLINE: 'icon-type-underline',
  ICON_CHEVRON_BAR_DOWN: 'icon-chevron-bar-down',
  ICON_CODE_SQUARE: 'icon-code-square',
  ICON_BASKET: 'icon-basket',
  ICON_FILE_LOCK: 'icon-file-lock',
  ICON_FILE_PPT: 'icon-file-ppt',
  ICON_ALARM: 'icon-alarm',
  ICON_CALENDAR2_CHECK: 'icon-calendar2-check',
  ICON_FOLDER: 'icon-folder',
  ICON_FILE_EARMARK_PLUS: 'icon-file-earmark-plus',
  ICON_GEAR_WIDE_CONNECTED: 'icon-gear-wide-connected',
  ICON_BINOCULARS: 'icon-binoculars',
  ICON_BEZIER: 'icon-bezier',
  ICON_BUILDING: 'icon-building',
  ICON_FILE_EARMARK_PPT: 'icon-file-earmark-ppt',
  ICON_CHAT_HEART: 'icon-chat-heart',
  ICON_CARET_RIGHT_SQUARE: 'icon-caret-right-square',
  ICON_FILE_BAR_GRAPH: 'icon-file-bar-graph',
  ICON_FILE_EARMARK_MUSIC: 'icon-file-earmark-music',
  ICON_BOX_ARROW_IN_DOWN_LEFT: 'icon-box-arrow-in-down-left',
  ICON_SHIELD_CHECK: 'icon-shield-check',
  ICON_FILE_EARMARK_PDF: 'icon-file-earmark-pdf',
  ICON_SCREWDRIVER: 'icon-screwdriver',
  ICON_PENTAGON: 'icon-pentagon',
  ICON_MOUSE: 'icon-mouse',
  ICON_ALIGN_TOP: 'icon-align-top',
  ICON_CALCULATOR: 'icon-calculator',
  ICON_SORT_DOWN: 'icon-sort-down',
  ICON_123: 'icon-123',
  ICON_HDD: 'icon-hdd',
  ICON_PATCH_MINUS: 'icon-patch-minus',
  ICON_BACKSPACE: 'icon-backspace',
  ICON_NODE_MINUS: 'icon-node-minus',
  ICON_CALENDAR4_WEEK: 'icon-calendar4-week',
  ICON_CHAT_QUOTE: 'icon-chat-quote',
  ICON_CALENDAR2_EVENT: 'icon-calendar2-event',
  ICON_BOX_ARROW_UP_LEFT: 'icon-box-arrow-up-left',
  ICON_CHEVRON_BAR_RIGHT: 'icon-chevron-bar-right',
  ICON_LAYOUT_TEXT_WINDOW_REVERSE: 'icon-layout-text-window-reverse',
  ICON_FILE_EARMARK_SPREADSHEET: 'icon-file-earmark-spreadsheet',
  ICON_TELEPHONE_FORWARD: 'icon-telephone-forward',
  ICON_PLUS_CIRCLE_DOTTED: 'icon-plus-circle-dotted',
  ICON_EAR: 'icon-ear',
  ICON_TELEPHONE_OUTBOUND: 'icon-telephone-outbound',
  ICON_CHECK2: 'icon-check2',
  ICON_CLOUD_DOWNLOAD: 'icon-cloud-download',
  ICON_LIST_OL: 'icon-list-ol',
  ICON_BADGE_4K: 'icon-badge-4k',
  ICON_PALETTE2: 'icon-palette2',
  ICON_FILE_EARMARK_CODE: 'icon-file-earmark-code',
  ICON_ARROW_LEFT_RIGHT: 'icon-arrow-left-right',
  ICON_CURRENCY_DOLLAR: 'icon-currency-dollar',
  ICON_FILE_RICHTEXT: 'icon-file-richtext',
  ICON_EASEL2: 'icon-easel2',
  ICON_FILE_DIFF: 'icon-file-diff',
  ICON_EASEL3: 'icon-easel3',
  ICON_TABLET: 'icon-tablet',
  ICON_DASH_SQUARE_DOTTED: 'icon-dash-square-dotted',
  ICON_ASTERISK: 'icon-asterisk',
  ICON_MINECART: 'icon-minecart',
  ICON_INFO_LG: 'icon-info-lg',
  ICON_BOX_ARROW_IN_UP_LEFT: 'icon-box-arrow-in-up-left',
  ICON_BRACES: 'icon-braces',
  ICON_SHIELD_SLASH: 'icon-shield-slash',
  ICON_TEXTAREA_T: 'icon-textarea-t',
  ICON_FORWARD: 'icon-forward',
  ICON_BALLOON: 'icon-balloon',
  ICON_CLOUDY: 'icon-cloudy',
  ICON_CLOUD_HAIL: 'icon-cloud-hail',
  ICON_ARROW_DOWN_CIRCLE: 'icon-arrow-down-circle',
  ICON_CALENDAR2_MONTH: 'icon-calendar2-month',
  ICON_PAUSE: 'icon-pause',
  ICON_CALENDAR_X: 'icon-calendar-x',
  ICON_BOOKMARK_CHECK: 'icon-bookmark-check',
  ICON_FONTS: 'icon-fonts',
  ICON_UPLOAD: 'icon-upload',
  ICON_DROPLET_HALF: 'icon-droplet-half',
  ICON_SORT_NUMERIC_DOWN: 'icon-sort-numeric-down',
  ICON_TELEPHONE_X: 'icon-telephone-x',
  ICON_LIST_CHECK: 'icon-list-check',
  ICON_EMOJI_ANGRY: 'icon-emoji-angry',
  ICON_GRIP_VERTICAL: 'icon-grip-vertical',
  ICON_JUSTIFY: 'icon-justify',
  ICON_CUP: 'icon-cup',
  ICON_QUOTE: 'icon-quote',
  ICON_WINDOW: 'icon-window',
  ICON_LAYER_FORWARD: 'icon-layer-forward',
  ICON_SHUFFLE: 'icon-shuffle',
  ICON_PCI_CARD: 'icon-pci-card',
  ICON_CLOUD: 'icon-cloud',
  ICON_HR: 'icon-hr',
  ICON_ALT: 'icon-alt',
  ICON_BOUNDING_BOX: 'icon-bounding-box',
  ICON_ENVELOPE_PAPER: 'icon-envelope-paper',
  ICON_CLIPBOARD2_PULSE: 'icon-clipboard2-pulse',
  ICON_FILTER_LEFT: 'icon-filter-left',
  ICON_CALENDAR_DATE: 'icon-calendar-date',
  ICON_SLACK: 'icon-slack',
  ICON_FILE_CODE: 'icon-file-code',
  ICON_CLIPBOARD2_MINUS: 'icon-clipboard2-minus',
  ICON_SHIELD_SHADED: 'icon-shield-shaded',
  ICON_PLUS_SQUARE_DOTTED: 'icon-plus-square-dotted',
  ICON_ARROW_UP_LEFT_CIRCLE: 'icon-arrow-up-left-circle',
  ICON_BOX_ARROW_UP_RIGHT: 'icon-box-arrow-up-right',
  ICON_CHAT_RIGHT_TEXT: 'icon-chat-right-text',
  ICON_PC: 'icon-pc',
  ICON_SEND_CHECK: 'icon-send-check',
  ICON_CLIPBOARD_X: 'icon-clipboard-x',
  ICON_CARET_LEFT: 'icon-caret-left',
  ICON_FILE_EARMARK_PERSON: 'icon-file-earmark-person',
  ICON_DIAMOND_HALF: 'icon-diamond-half',
  ICON_DISPLAY: 'icon-display',
  ICON_BOX_ARROW_IN_UP_RIGHT: 'icon-box-arrow-in-up-right',
  ICON_TOGGLE2_ON: 'icon-toggle2-on',
  ICON_LAPTOP: 'icon-laptop',
  ICON_OCTAGON_HALF: 'icon-octagon-half',
  ICON_SNOW2: 'icon-snow2',
  ICON_ARROW_DOWN_RIGHT: 'icon-arrow-down-right',
  ICON_WINDOW_SPLIT: 'icon-window-split',
  ICON_CALENDAR_HEART: 'icon-calendar-heart',
  ICON_ENVELOPE_CHECK: 'icon-envelope-check',
  ICON_BADGE_AR: 'icon-badge-ar',
  ICON_ALIGN_CENTER: 'icon-align-center',
  ICON_ARROW_LEFT: 'icon-arrow-left',
  ICON_GLOBE: 'icon-globe',
  ICON_SLIDERS2_VERTICAL: 'icon-sliders2-vertical',
  ICON_TRASH2: 'icon-trash2',
  ICON_CHAT_LEFT_DOTS: 'icon-chat-left-dots',
  ICON_CALENDAR: 'icon-calendar',
  ICON_TEXT_LEFT: 'icon-text-left',
  ICON_TRASH3: 'icon-trash3',
  ICON_BELL_SLASH: 'icon-bell-slash',
  ICON_JOURNAL_CHECK: 'icon-journal-check',
  ICON_CLIPBOARD2_HEART: 'icon-clipboard2-heart',
  ICON_UPC: 'icon-upc',
  ICON_EMOJI_SUNGLASSES: 'icon-emoji-sunglasses',
  ICON_LIGHTBULB_OFF: 'icon-lightbulb-off',
  ICON_LIGHTNING_CHARGE: 'icon-lightning-charge',
  ICON_SKIP_END: 'icon-skip-end',
  ICON_BADGE_AD: 'icon-badge-ad',
  ICON_WATER: 'icon-water',
  ICON_PC_DISPLAY: 'icon-pc-display',
  ICON_SNOW3: 'icon-snow3',
  ICON_FILTER: 'icon-filter',
  ICON_HOURGLASS_BOTTOM: 'icon-hourglass-bottom',
  ICON_GENDER_FEMALE: 'icon-gender-female',
  ICON_ACTIVITY: 'icon-activity',
  ICON_TREE: 'icon-tree',
  ICON_ROBOT: 'icon-robot',
  ICON_CHAT_RIGHT_QUOTE: 'icon-chat-right-quote',
  ICON_FILTER_SQUARE: 'icon-filter-square',
  ICON_STACK: 'icon-stack',
  ICON_WINDOW_PLUS: 'icon-window-plus',
  ICON_EXPLICIT: 'icon-explicit',
  ICON_SLASH_SQUARE: 'icon-slash-square',
  ICON_DASH_CIRCLE_DOTTED: 'icon-dash-circle-dotted',
  ICON_SLASH_LG: 'icon-slash-lg',
  ICON_ETHERNET: 'icon-ethernet',
  ICON_CALENDAR_MINUS: 'icon-calendar-minus',
  ICON_SYMMETRY_VERTICAL: 'icon-symmetry-vertical',
  ICON_COLUMNS_GAP: 'icon-columns-gap',
  ICON_SLIDERS2: 'icon-sliders2',
  ICON_WINDOW_DASH: 'icon-window-dash',
  ICON_BRIGHTNESS_HIGH: 'icon-brightness-high',
  ICON_SKIP_END_CIRCLE: 'icon-skip-end-circle',
  ICON_FILE_EARMARK_DIFF: 'icon-file-earmark-diff',
  ICON_STICKY: 'icon-sticky',
  ICON_ARROW_UP: 'icon-arrow-up',
  ICON_EMOJI_LAUGHING: 'icon-emoji-laughing',
  ICON_BORDER_BOTTOM: 'icon-border-bottom',
  ICON_TAGS: 'icon-tags',
  ICON_MUSIC_NOTE_LIST: 'icon-music-note-list',
  ICON_INPUT_CURSOR_TEXT: 'icon-input-cursor-text',
  ICON_SHARE: 'icon-share',
  ICON_THUNDERBOLT: 'icon-thunderbolt',
  ICON_LIST_COLUMNS_REVERSE: 'icon-list-columns-reverse',
  ICON_DASH_SQUARE: 'icon-dash-square',
  ICON_ENVELOPE_SLASH: 'icon-envelope-slash',
  ICON_MOISTURE: 'icon-moisture',
  ICON_GRAPH_UP: 'icon-graph-up',
  ICON_BOUNDING_BOX_CIRCLES: 'icon-bounding-box-circles',
  ICON_FILE_MUSIC: 'icon-file-music',
  ICON_ENVELOPE_X: 'icon-envelope-x',
  ICON_GIFT: 'icon-gift',
  ICON_BRIGHTNESS_ALT_LOW: 'icon-brightness-alt-low',
  ICON_CHAT_RIGHT_HEART: 'icon-chat-right-heart',
  ICON_SEND_SLASH: 'icon-send-slash',
  ICON_CASH_STACK: 'icon-cash-stack',
  ICON_QUESTION_LG: 'icon-question-lg',
  ICON_QUESTION_SQUARE: 'icon-question-square',
  ICON_CARD_CHECKLIST: 'icon-card-checklist',
  ICON_BADGE_VO: 'icon-badge-vo',
  ICON_PERSON_VIDEO3: 'icon-person-video3',
  ICON_UI_RADIOS_GRID: 'icon-ui-radios-grid',
  ICON_FILE_BINARY: 'icon-file-binary',
  ICON_ARROWS_ANGLE_CONTRACT: 'icon-arrows-angle-contract',
  ICON_PERSON_HEARTS: 'icon-person-hearts',
  ICON_RADIOACTIVE: 'icon-radioactive',
  ICON_EYE: 'icon-eye',
  ICON_ARROW_COUNTERCLOCKWISE: 'icon-arrow-counterclockwise',
  ICON_CALENDAR4_EVENT: 'icon-calendar4-event',
  ICON_ARROW_LEFT_SQUARE: 'icon-arrow-left-square',
  ICON_PERSON_VIDEO2: 'icon-person-video2',
  ICON_CHEVRON_COMPACT_RIGHT: 'icon-chevron-compact-right',
  ICON_ARROW_DOWN_LEFT_SQUARE: 'icon-arrow-down-left-square',
  ICON_PHONE: 'icon-phone',
  ICON_BOX2_HEART: 'icon-box2-heart',
  ICON_MENU_APP: 'icon-menu-app',
  ICON_GPU_CARD: 'icon-gpu-card',
  ICON_ROUTER: 'icon-router',
  ICON_PLUS_LG: 'icon-plus-lg',
  ICON_ARROW_THROUGH_HEART: 'icon-arrow-through-heart',
  ICON_CLOCK: 'icon-clock',
  ICON_SKIP_START_BTN: 'icon-skip-start-btn',
  ICON_FILE_EARMARK_LOCK: 'icon-file-earmark-lock',
  ICON_COMMAND: 'icon-command',
  ICON_DIAMOND: 'icon-diamond',
  ICON_CHAT_DOTS: 'icon-chat-dots',
  ICON_FILE_PLUS: 'icon-file-plus',
  ICON_TEXT_PARAGRAPH: 'icon-text-paragraph',
  ICON_ARROW_BAR_DOWN: 'icon-arrow-bar-down',
  ICON_CLOCK_HISTORY: 'icon-clock-history',
  ICON_FILE_EARMARK_POST: 'icon-file-earmark-post',
  ICON_CLOUD_RAIN: 'icon-cloud-rain',
  ICON_PHONE_FLIP: 'icon-phone-flip',
  ICON_POSTAGE: 'icon-postage',
  ICON_SORT_ALPHA_DOWN: 'icon-sort-alpha-down',
  ICON_REPLY_ALL: 'icon-reply-all',
  ICON_THERMOMETER_SUN: 'icon-thermometer-sun',
  ICON_CHECK2_CIRCLE: 'icon-check2-circle',
  ICON_HEART_HALF: 'icon-heart-half',
  ICON_TEXTAREA: 'icon-textarea',
  ICON_GRID_3X3: 'icon-grid-3x3',
  ICON_BOX_ARROW_UP: 'icon-box-arrow-up',
  ICON_CALENDAR2: 'icon-calendar2',
  ICON_CREDIT_CARD: 'icon-credit-card',
  ICON_BANK: 'icon-bank',
  ICON_CLOUD_MOON: 'icon-cloud-moon',
  ICON_BICYCLE: 'icon-bicycle',
  ICON_CIRCLE_SQUARE: 'icon-circle-square',
  ICON_BASKET2: 'icon-basket2',
  ICON_ARROW_UP_RIGHT_CIRCLE: 'icon-arrow-up-right-circle',
  ICON_PLUS_CIRCLE: 'icon-plus-circle',
  ICON_SUNGLASSES: 'icon-sunglasses',
  ICON_COMPASS: 'icon-compass',
  ICON_BORDER_LEFT: 'icon-border-left',
  ICON_FILE_ARROW_DOWN: 'icon-file-arrow-down',
  ICON_BOOKMARK_DASH: 'icon-bookmark-dash',
  ICON_GEO_ALT: 'icon-geo-alt',
  ICON_UNLOCK: 'icon-unlock',
  ICON_BOOKMARK_PLUS: 'icon-bookmark-plus',
  ICON_GEO: 'icon-geo',
  ICON_COLLECTION: 'icon-collection',
  ICON_BRIGHTNESS_ALT_HIGH: 'icon-brightness-alt-high',
  ICON_ENVELOPE_PAPER_HEART: 'icon-envelope-paper-heart',
  ICON_FILE_EARMARK_SLIDES: 'icon-file-earmark-slides',
  ICON_WRENCH_ADJUSTABLE_CIRCLE: 'icon-wrench-adjustable-circle',
  ICON_HAND_INDEX_THUMB: 'icon-hand-index-thumb',
  ICON_BUG: 'icon-bug',
  ICON_SKIP_BACKWARD: 'icon-skip-backward',
  ICON_BADGE_SD: 'icon-badge-sd',
  ICON_CASH: 'icon-cash',
  ICON_WINDOW_SIDEBAR: 'icon-window-sidebar',
  ICON_BASKET3: 'icon-basket3',
  ICON_CLOUD_ARROW_DOWN: 'icon-cloud-arrow-down',
  ICON_MIC_MUTE: 'icon-mic-mute',
  ICON_CALENDAR3: 'icon-calendar3',
  ICON_GRID_3X2: 'icon-grid-3x2',
  ICON_FILE_EARMARK_RULED: 'icon-file-earmark-ruled',
  ICON_ENVELOPE_PLUS: 'icon-envelope-plus',
  ICON_SORT_DOWN_ALT: 'icon-sort-down-alt',
  ICON_TROPICAL_STORM: 'icon-tropical-storm',
  ICON_MORTARBOARD: 'icon-mortarboard',
  ICON_BOX_ARROW_IN_DOWN: 'icon-box-arrow-in-down',
  ICON_ENVELOPE_DASH: 'icon-envelope-dash',
  ICON_ARROW_90DEG_LEFT: 'icon-arrow-90deg-left',
  ICON_TSUNAMI: 'icon-tsunami',
  ICON_GRID_1X2: 'icon-grid-1x2',
  ICON_CHEVRON_COMPACT_DOWN: 'icon-chevron-compact-down',
  ICON_WRENCH: 'icon-wrench',
  ICON_SUN: 'icon-sun',
  ICON_PERSON_CHECK: 'icon-person-check',
  ICON_COLLECTION_PLAY: 'icon-collection-play',
  ICON_STAR: 'icon-star',
  ICON_VR: 'icon-vr',
  ICON_CLIPBOARD_DATA: 'icon-clipboard-data',
  ICON_VOLUME_UP: 'icon-volume-up',
  ICON_SKIP_FORWARD_CIRCLE: 'icon-skip-forward-circle',
  ICON_TELEPHONE: 'icon-telephone',
  ICON_MEMORY: 'icon-memory',
  ICON_PLAY_BTN: 'icon-play-btn',
  ICON_FILE_LOCK2: 'icon-file-lock2',
  ICON_SKIP_BACKWARD_BTN: 'icon-skip-backward-btn',
  ICON_LIST_COLUMNS: 'icon-list-columns',
  ICON_CALENDAR3_EVENT: 'icon-calendar3-event',
  ICON_RECORD_CIRCLE: 'icon-record-circle',
  ICON_LAYERS_HALF: 'icon-layers-half',
  ICON_WIFI_OFF: 'icon-wifi-off',
  ICON_LAYOUT_TEXT_SIDEBAR: 'icon-layout-text-sidebar',
  ICON_EMOJI_HEART_EYES: 'icon-emoji-heart-eyes',
  ICON_LIFE_PRESERVER: 'icon-life-preserver',
  ICON_BOX_ARROW_IN_RIGHT: 'icon-box-arrow-in-right',
  ICON_TRASH: 'icon-trash',
  ICON_ARROWS_EXPAND: 'icon-arrows-expand',
  ICON_HAND_INDEX: 'icon-hand-index',
  ICON_GEM: 'icon-gem',
  ICON_JOURNAL_TEXT: 'icon-journal-text',
  ICON_CURRENCY_EURO: 'icon-currency-euro',
  ICON_INFINITY: 'icon-infinity',
  ICON_BAG_HEART: 'icon-bag-heart',
  ICON_TEXT_INDENT_LEFT: 'icon-text-indent-left',
  ICON_PC_DISPLAY_HORIZONTAL: 'icon-pc-display-horizontal',
  ICON_INTERSECT: 'icon-intersect',
  ICON_PERSON_ROLODEX: 'icon-person-rolodex',
  ICON_EXCLAMATION_CIRCLE: 'icon-exclamation-circle',
  ICON_TABLET_LANDSCAPE: 'icon-tablet-landscape',
  ICON_CLOUD_MINUS: 'icon-cloud-minus',
  ICON_MINECART_LOADED: 'icon-minecart-loaded',
  ICON_BULLSEYE: 'icon-bullseye',
  ICON_FILE_EARMARK_CHECK: 'icon-file-earmark-check',
  ICON_SPELLCHECK: 'icon-spellcheck',
  ICON_EGG: 'icon-egg',
  ICON_VECTOR_PEN: 'icon-vector-pen',
  ICON_BUCKET: 'icon-bucket',
  ICON_BACKSPACE_REVERSE: 'icon-backspace-reverse',
  ICON_SYMMETRY_HORIZONTAL: 'icon-symmetry-horizontal',
  ICON_VINYL: 'icon-vinyl',
  ICON_WHATSAPP: 'icon-whatsapp',
  ICON_LINK_45DEG: 'icon-link-45deg',
  ICON_CREDIT_CARD_2_FRONT: 'icon-credit-card-2-front',
  ICON_STARS: 'icon-stars',
  ICON_TRUCK: 'icon-truck',
  ICON_ARROW_UP_RIGHT: 'icon-arrow-up-right',
  ICON_BOX_ARROW_LEFT: 'icon-box-arrow-left',
  ICON_HDD_RACK: 'icon-hdd-rack',
  ICON_FOLDER_MINUS: 'icon-folder-minus',
  ICON_EXCLUDE: 'icon-exclude',
  ICON_BORDER_TOP: 'icon-border-top',
  ICON_BORDER_STYLE: 'icon-border-style',
  ICON_MAGIC: 'icon-magic',
  ICON_SMARTWATCH: 'icon-smartwatch',
  ICON_SEND_DASH: 'icon-send-dash',
  ICON_ARROW_90DEG_RIGHT: 'icon-arrow-90deg-right',
  ICON_PRINTER: 'icon-printer',
  ICON_CAMERA: 'icon-camera',
  ICON_NINTENDO_SWITCH: 'icon-nintendo-switch',
  ICON_SEND_PLUS: 'icon-send-plus',
  ICON_CURRENCY_EXCHANGE: 'icon-currency-exchange',
  ICON_CALENDAR4: 'icon-calendar4',
  ICON_ARROW_DOWN_RIGHT_SQUARE: 'icon-arrow-down-right-square',
  ICON_SPEAKER: 'icon-speaker',
  ICON_CALENDAR2_DAY: 'icon-calendar2-day',
  ICON_RECEPTION_0: 'icon-reception-0',
  ICON_PHONE_VIBRATE: 'icon-phone-vibrate',
  ICON_JOURNAL_ARROW_DOWN: 'icon-journal-arrow-down',
  ICON_CLIPBOARD2_X: 'icon-clipboard2-x',
  ICON_SEND: 'icon-send',
  ICON_TABLE: 'icon-table',
  ICON_USB_MINI: 'icon-usb-mini',
  ICON_STICKIES: 'icon-stickies',
  ICON_CLIPBOARD2_DATA: 'icon-clipboard2-data',
  ICON_FILE_EARMARK_RICHTEXT: 'icon-file-earmark-richtext',
  ICON_ARROWS_COLLAPSE: 'icon-arrows-collapse',
  ICON_PIP: 'icon-pip',
  ICON_BAG_X: 'icon-bag-x',
  ICON_COIN: 'icon-coin',
  ICON_EMOJI_SMILE: 'icon-emoji-smile',
  ICON_FILE_CHECK: 'icon-file-check',
  ICON_ARROW_REPEAT: 'icon-arrow-repeat',
  ICON_CHAT: 'icon-chat',
  ICON_CAPSLOCK: 'icon-capslock',
  ICON_PLAY: 'icon-play',
  ICON_EYE_SLASH: 'icon-eye-slash',
  ICON_SQUARE: 'icon-square',
  ICON_SUIT_HEART: 'icon-suit-heart',
  ICON_PERCENT: 'icon-percent',
  ICON_PEACE: 'icon-peace',
  ICON_CARD_LIST: 'icon-card-list',
  ICON_MASK: 'icon-mask',
  ICON_BOX_ARROW_IN_UP: 'icon-box-arrow-in-up',
  ICON_CAMERA_VIDEO: 'icon-camera-video',
  ICON_ZOOM_OUT: 'icon-zoom-out',
  ICON_AWARD: 'icon-award',
  ICON_FILE_EARMARK_LOCK2: 'icon-file-earmark-lock2',
  ICON_INPUT_CURSOR: 'icon-input-cursor',
  ICON_JOURNAL_RICHTEXT: 'icon-journal-richtext',
  ICON_CURRENCY_YEN: 'icon-currency-yen',
  ICON_RECEPTION_1: 'icon-reception-1',
  ICON_WINDOW_STACK: 'icon-window-stack',
  ICON_CALENDAR_RANGE: 'icon-calendar-range',
  ICON_X_SQUARE: 'icon-x-square',
  ICON_BROADCAST_PIN: 'icon-broadcast-pin',
  ICON_BACK: 'icon-back',
  ICON_GENDER_MALE: 'icon-gender-male',
  ICON_CALENDAR2_PLUS: 'icon-calendar2-plus',
  ICON_CHAT_SQUARE: 'icon-chat-square',
  ICON_ALIGN_MIDDLE: 'icon-align-middle',
  ICON_BROADCAST: 'icon-broadcast',
  ICON_PAPERCLIP: 'icon-paperclip',
  ICON_VOLUME_MUTE: 'icon-volume-mute',
  ICON_PIN_MAP: 'icon-pin-map',
  ICON_HEADSET_VR: 'icon-headset-vr',
  ICON_SHIELD_EXCLAMATION: 'icon-shield-exclamation',
  ICON_QUORA: 'icon-quora',
  ICON_CALENDAR3_WEEK: 'icon-calendar3-week',
  ICON_CALENDAR2_WEEK: 'icon-calendar2-week',
  ICON_WINDOW_DOCK: 'icon-window-dock',
  ICON_BOOTSTRAP_REBOOT: 'icon-bootstrap-reboot',
  ICON_MENU_DOWN: 'icon-menu-down',
  ICON_THREE_DOTS_VERTICAL: 'icon-three-dots-vertical',
  ICON_OPTICAL_AUDIO: 'icon-optical-audio',
  ICON_CLOUD_LIGHTNING: 'icon-cloud-lightning',
  ICON_BOOKMARKS: 'icon-bookmarks',
  ICON_FRONT: 'icon-front',
  ICON_IMAGES: 'icon-images',
  ICON_FILE_RULED: 'icon-file-ruled',
  ICON_LAMP: 'icon-lamp',
  ICON_ARROW_UP_SQUARE: 'icon-arrow-up-square',
  ICON_SAVE: 'icon-save',
  ICON_CHEVRON_EXPAND: 'icon-chevron-expand',
  ICON_RECEPTION_3: 'icon-reception-3',
  ICON_STOP_BTN: 'icon-stop-btn',
  ICON_SUNSET: 'icon-sunset',
  ICON_CHECK_CIRCLE: 'icon-check-circle',
  ICON_CHECK_CIRCLE_2: 'icon-check-circle-2',
  ICON_LIGHTBULB: 'icon-lightbulb',
  ICON_LAYOUT_SIDEBAR_INSET_REVERSE: 'icon-layout-sidebar-inset-reverse',
  ICON_BANDAID: 'icon-bandaid',
  ICON_TOGGLES: 'icon-toggles',
  ICON_IMAGE: 'icon-image',
  ICON_MOUSE3: 'icon-mouse3',
  ICON_EGG_FRIED: 'icon-egg-fried',
  ICON_SORT_ALPHA_DOWN_ALT: 'icon-sort-alpha-down-alt',
  ICON_ARROW_RIGHT_CIRCLE: 'icon-arrow-right-circle',
  ICON_JOURNAL_X: 'icon-journal-x',
  ICON_TERMINAL_SPLIT: 'icon-terminal-split',
  ICON_ALIGN_START: 'icon-align-start',
  ICON_WINDOW_X: 'icon-window-x',
  ICON_SUBTRACT: 'icon-subtract',
  ICON_DEVICE_SSD: 'icon-device-ssd',
  ICON_TRIANGLE_HALF: 'icon-triangle-half',
  ICON_TICKET_DETAILED: 'icon-ticket-detailed',
  ICON_CARET_LEFT_SQUARE: 'icon-caret-left-square',
  ICON_BOOKMARK_X: 'icon-bookmark-x',
  ICON_BORDER_RIGHT: 'icon-border-right',
  ICON_BOOKSHELF: 'icon-bookshelf',
  ICON_GRAPH_DOWN_ARROW: 'icon-graph-down-arrow',
  ICON_BAG: 'icon-bag',
  ICON_MOUSE2: 'icon-mouse2',
  ICON_POSTAGE_HEART: 'icon-postage-heart',
  ICON_THERMOMETER_LOW: 'icon-thermometer-low',
  ICON_CALENDAR2_X: 'icon-calendar2-x',
  ICON_WRENCH_ADJUSTABLE: 'icon-wrench-adjustable',
  ICON_ZOOM_IN: 'icon-zoom-in',
  ICON_SORT_NUMERIC_DOWN_ALT: 'icon-sort-numeric-down-alt',
  ICON_PERSON_SQUARE: 'icon-person-square',
  ICON_PERSON_WORKSPACE: 'icon-person-workspace',
  ICON_CHAT_SQUARE_DOTS: 'icon-chat-square-dots',
  ICON_RECEPTION_2: 'icon-reception-2',
  ICON_SEGMENTED_NAV: 'icon-segmented-nav',
  ICON_SAFE2: 'icon-safe2',
  ICON_RAINBOW: 'icon-rainbow',
  ICON_MIC: 'icon-mic',
  ICON_GRIP_HORIZONTAL: 'icon-grip-horizontal',
  ICON_FOLDER_PLUS: 'icon-folder-plus',
  ICON_BOX_ARROW_IN_DOWN_RIGHT: 'icon-box-arrow-in-down-right',
  ICON_MOON_STARS: 'icon-moon-stars',
  ICON_FILE_X: 'icon-file-x',
  ICON_CLOUD_FOG: 'icon-cloud-fog',
  ICON_BOX_ARROW_RIGHT: 'icon-box-arrow-right',
  ICON_FILE_EARMARK_MEDICAL: 'icon-file-earmark-medical',
  ICON_SKIP_START_CIRCLE: 'icon-skip-start-circle',
  ICON_INFO_CIRCLE: 'icon-info-circle',
  ICON_PEOPLE: 'icon-people',
  ICON_JUSTIFY_RIGHT: 'icon-justify-right',
  ICON_CHAT_RIGHT: 'icon-chat-right',
  ICON_FILE_EARMARK_FONT: 'icon-file-earmark-font',
  ICON_SPEEDOMETER: 'icon-speedometer',
  ICON_GEAR: 'icon-gear',
  ICON_CALENDAR2_HEART: 'icon-calendar2-heart',
  ICON_PENTAGON_HALF: 'icon-pentagon-half',
  ICON_ARROW_BAR_RIGHT: 'icon-arrow-bar-right',
  ICON_PALETTE: 'icon-palette',
  ICON_FAN: 'icon-fan',
  ICON_BOX_ARROW_DOWN: 'icon-box-arrow-down',
  ICON_FILE_EARMARK_BAR_GRAPH: 'icon-file-earmark-bar-graph',
  ICON_CHEVRON_COMPACT_UP: 'icon-chevron-compact-up',
  ICON_INFO: 'icon-info',
  ICON_CHAT_SQUARE_HEART: 'icon-chat-square-heart',
  ICON_CLIPBOARD_HEART: 'icon-clipboard-heart',
  ICON_SPEEDOMETER2: 'icon-speedometer2',
  ICON_POSTCARD_HEART: 'icon-postcard-heart',
  ICON_PATCH_EXCLAMATION: 'icon-patch-exclamation',
  ICON_BAR_CHART_LINE: 'icon-bar-chart-line',
  ICON_LIST_NESTED: 'icon-list-nested',
  ICON_WINDOW_DESKTOP: 'icon-window-desktop',
  ICON_JOURNAL_ARROW_UP: 'icon-journal-arrow-up',
  ICON_SUIT_DIAMOND: 'icon-suit-diamond',
  ICON_WATCH: 'icon-watch',
  ICON_X_LG: 'icon-x-lg',
  ICON_GRAPH_DOWN: 'icon-graph-down',
  ICON_EMOJI_FROWN: 'icon-emoji-frown',
  ICON_WIFI: 'icon-wifi',
  ICON_SKIP_END_BTN: 'icon-skip-end-btn',
  ICON_RSS: 'icon-rss',
  ICON_CLOUD_HAZE: 'icon-cloud-haze',
  ICON_CART4: 'icon-cart4',
  ICON_HEADPHONES: 'icon-headphones',
  ICON_CALENDAR2_MINUS: 'icon-calendar2-minus',
  ICON_PIE_CHART: 'icon-pie-chart',
  ICON_BLUETOOTH: 'icon-bluetooth',
  ICON_LAYOUT_SIDEBAR_INSET: 'icon-layout-sidebar-inset',
  ICON_CLIPBOARD_PULSE: 'icon-clipboard-pulse',
  ICON_NUT: 'icon-nut',
  ICON_DASH: 'icon-dash',
  ICON_MENU_BUTTON: 'icon-menu-button',
  ICON_CHECK: 'icon-check',
  ICON_MUSIC_NOTE: 'icon-music-note',
  ICON_GEAR_WIDE: 'icon-gear-wide',
  ICON_PLUS: 'icon-plus',
  ICON_BATTERY_FULL: 'icon-battery-full',
  ICON_SHIELD_MINUS: 'icon-shield-minus',
  ICON_HASH: 'icon-hash',
  ICON_TROPHY: 'icon-trophy',
  ICON_EMOJI_DIZZY: 'icon-emoji-dizzy',
  ICON_ENVELOPE_OPEN: 'icon-envelope-open',
  ICON_FILE_SLIDES: 'icon-file-slides',
  ICON_GENDER_AMBIGUOUS: 'icon-gender-ambiguous',
  ICON_MAILBOX2: 'icon-mailbox2',
  ICON_CALENDAR3_RANGE: 'icon-calendar3-range',
  ICON_CHEVRON_COMPACT_LEFT: 'icon-chevron-compact-left',
  ICON_RECORD: 'icon-record',
  ICON_JOURNAL_ALBUM: 'icon-journal-album',
  ICON_FOLDER_X: 'icon-folder-x',
  ICON_CARET_RIGHT: 'icon-caret-right',
  ICON_BORDER: 'icon-border',
  ICON_FILE_EARMARK_ARROW_UP: 'icon-file-earmark-arrow-up',
  ICON_CPU: 'icon-cpu',
  ICON_CART_CHECK: 'icon-cart-check',
  ICON_CURSOR: 'icon-cursor',
  ICON_CAMERA_VIDEO_OFF: 'icon-camera-video-off',
  ICON_ARROW_RETURN_LEFT: 'icon-arrow-return-left',
  ICON_STAR_HALF: 'icon-star-half',
  ICON_ENVELOPE_EXCLAMATION: 'icon-envelope-exclamation',
  ICON_FILE_MEDICAL: 'icon-file-medical',
  ICON_MARKDOWN: 'icon-markdown',
  ICON_DISC: 'icon-disc',
  ICON_ARROW_90DEG_DOWN: 'icon-arrow-90deg-down',
  ICON_NEWSPAPER: 'icon-newspaper',
  ICON_BOX_ARROW_IN_LEFT: 'icon-box-arrow-in-left',
  ICON_ARROW_BAR_UP: 'icon-arrow-bar-up',
  ICON_FILES_ALT: 'icon-files-alt',
  ICON_USB_DRIVE: 'icon-usb-drive',
  ICON_ARROW_DOWN_SQUARE: 'icon-arrow-down-square',
  ICON_BORDER_MIDDLE: 'icon-border-middle',
  ICON_PATCH_CHECK: 'icon-patch-check',
  ICON_BATTERY: 'icon-battery',
  ICON_CARET_UP_SQUARE: 'icon-caret-up-square',
  ICON_ARROW_LEFT_SHORT: 'icon-arrow-left-short',
  ICON_SHOP_WINDOW: 'icon-shop-window',
  ICON_FLAG: 'icon-flag',
  ICON_THERMOMETER: 'icon-thermometer',
  ICON_SQUARE_HALF: 'icon-square-half',
  ICON_BOOKMARK_HEART: 'icon-bookmark-heart',
  ICON_CODE: 'icon-code',
  ICON_FUNNEL: 'icon-funnel',
  ICON_BADGE_HD: 'icon-badge-hd',
  ICON_CONTROLLER: 'icon-controller',
  ICON_BELL: 'icon-bell',
  ICON_THERMOMETER_HIGH: 'icon-thermometer-high',
  ICON_FILE_IMAGE: 'icon-file-image',
  ICON_BADGE_3D: 'icon-badge-3d',
  ICON_WALLET: 'icon-wallet',
  ICON_CHEVRON_BAR_EXPAND: 'icon-chevron-bar-expand',
  ICON_TERMINAL_PLUS: 'icon-terminal-plus',
  ICON_EYEDROPPER: 'icon-eyedropper',
  ICON_SHIELD_X: 'icon-shield-x',
  ICON_PLUG: 'icon-plug',
  ICON_PARAGRAPH: 'icon-paragraph',
  ICON_TERMINAL_DASH: 'icon-terminal-dash',
  ICON_CHAT_SQUARE_QUOTE: 'icon-chat-square-quote',
  ICON_HDMI: 'icon-hdmi',
  ICON_CARD_IMAGE: 'icon-card-image',
  ICON_FILTER_RIGHT: 'icon-filter-right',
  ICON_BOOK: 'icon-book',
  ICON_MUSIC_PLAYER: 'icon-music-player',
  ICON_SLASH: 'icon-slash',
  ICON_CAMERA_REELS: 'icon-camera-reels',
  ICON_LAYERS: 'icon-layers',
  ICON_CLOUD_ARROW_UP: 'icon-cloud-arrow-up',
  ICON_PATCH_PLUS: 'icon-patch-plus',
  ICON_BOOKMARK_STAR: 'icon-bookmark-star',
  ICON_FOLDER2: 'icon-folder2',
  ICON_BATTERY_CHARGING: 'icon-battery-charging',
  ICON_ARROW_CLOCKWISE: 'icon-arrow-clockwise',
  ICON_CHAT_RIGHT_DOTS: 'icon-chat-right-dots',
  ICON_PENCIL_SQUARE: 'icon-pencil-square',
  ICON_FILE_BREAK: 'icon-file-break',
  ICON_SKIP_FORWARD: 'icon-skip-forward',
  ICON_APP: 'icon-app',
  ICON_TV: 'icon-tv',
  ICON_SERVER: 'icon-server',
  ICON_FLOWER1: 'icon-flower1',
  ICON_PROJECTOR: 'icon-projector',
  ICON_CLIPBOARD2_CHECK: 'icon-clipboard2-check',
  ICON_SLASH_CIRCLE: 'icon-slash-circle',
  ICON_CHECK2_ALL: 'icon-check2-all',
  ICON_JOURNAL_PLUS: 'icon-journal-plus',
  ICON_WEBCAM: 'icon-webcam',
  ICON_CHAT_LEFT_TEXT: 'icon-chat-left-text',
  ICON_ARROW_DOWN_SHORT: 'icon-arrow-down-short',
  ICON_PIN: 'icon-pin',
  ICON_WINDOW_FULLSCREEN: 'icon-window-fullscreen',
  ICON_BRACES_ASTERISK: 'icon-braces-asterisk',
  ICON_KANBAN: 'icon-kanban',
  ICON_DOWNLOAD: 'icon-download',
  ICON_CART2: 'icon-cart2',
  ICON_SHIELD: 'icon-shield',
  ICON_PATCH_QUESTION: 'icon-patch-question',
  ICON_FILE_EARMARK_IMAGE: 'icon-file-earmark-image',
  ICON_MAGNET: 'icon-magnet',
  ICON_SUIT_SPADE: 'icon-suit-spade',
  ICON_CART3: 'icon-cart3',
  ICON_BORDER_WIDTH: 'icon-border-width',
  ICON_ARROW_UP_LEFT_SQUARE: 'icon-arrow-up-left-square',
  ICON_BRIGHTNESS_LOW: 'icon-brightness-low',
  ICON_CARET_UP: 'icon-caret-up',
  ICON_PERSON_X: 'icon-person-x',
  ICON_EMOJI_EXPRESSIONLESS: 'icon-emoji-expressionless',
  ICON_ARCHIVE: 'icon-archive',
  ICON_IMAGE_ALT: 'icon-image-alt',
  ICON_ASPECT_RATIO: 'icon-aspect-ratio',
  ICON_JOURNAL_MINUS: 'icon-journal-minus',
  ICON_BORDER_INNER: 'icon-border-inner',
  ICON_ENVELOPE: 'icon-envelope',
  ICON_CART: 'icon-cart',
  ICON_BANK2: 'icon-bank2',
  ICON_TYPE: 'icon-type',
  ICON_HOUSE_HEART: 'icon-house-heart',
  ICON_BRICKS: 'icon-bricks',
  ICON_LIST_TASK: 'icon-list-task',
  ICON_CALENDAR_WEEK: 'icon-calendar-week',
  ICON_CLOUD_SNOW: 'icon-cloud-snow',
  ICON_BORDER_ALL: 'icon-border-all',
  ICON_MUSIC_NOTE_BEAMED: 'icon-music-note-beamed',
  ICON_MENU_UP: 'icon-menu-up',
  ICON_STOPWATCH: 'icon-stopwatch',
  ICON_ENVELOPE_HEART: 'icon-envelope-heart',
  ICON_ARROWS_FULLSCREEN: 'icon-arrows-fullscreen',
  ICON_ARROW_DOWN_UP: 'icon-arrow-down-up',
  ICON_PAUSE_BTN: 'icon-pause-btn',
  ICON_CALENDAR_CHECK: 'icon-calendar-check',
  ICON_ARROWS_ANGLE_EXPAND: 'icon-arrows-angle-expand',
  ICON_INBOXES: 'icon-inboxes',
  ICON_TERMINAL_X: 'icon-terminal-x',
  ICON_ARROW_LEFT_CIRCLE: 'icon-arrow-left-circle',
  ICON_FILE_SPREADSHEET: 'icon-file-spreadsheet',
  ICON_STOP_CIRCLE: 'icon-stop-circle',
  ICON_HURRICANE: 'icon-hurricane',
  ICON_AT: 'icon-at',
  ICON_SKIP_FORWARD_BTN: 'icon-skip-forward-btn',
  ICON_ARROW_RIGHT_SHORT: 'icon-arrow-right-short',
  ICON_JOURNAL_BOOKMARK: 'icon-journal-bookmark',
  ICON_BRUSH: 'icon-brush',
  ICON_CALENDAR_PLUS: 'icon-calendar-plus',
  ICON_ARROW_RIGHT: 'icon-arrow-right',
  ICON_CLOUD_SUN: 'icon-cloud-sun',
  ICON_DOOR_OPEN: 'icon-door-open',
  ICON_USB_MICRO: 'icon-usb-micro',
  ICON_BLOCKQUOTE_RIGHT: 'icon-blockquote-right',
  ICON_FLOWER2: 'icon-flower2',
  ICON_HEXAGON_HALF: 'icon-hexagon-half',
  ICON_ARROW_DOWN_LEFT_CIRCLE: 'icon-arrow-down-left-circle',
  ICON_KEY: 'icon-key',
  ICON_TOGGLE_OFF: 'icon-toggle-off',
  ICON_LAYOUT_THREE_COLUMNS: 'icon-layout-three-columns',
  ICON_LINK: 'icon-link',
  ICON_PERSON_BADGE: 'icon-person-badge',
  ICON_DIAGRAM_2: 'icon-diagram-2',
  ICON_CAMERA2: 'icon-camera2',
  ICON_CLIPBOARD: 'icon-clipboard',
  ICON_HOURGLASS_SPLIT: 'icon-hourglass-split',
  ICON_TOGGLE2_OFF: 'icon-toggle2-off',
  ICON_CHEVRON_RIGHT: 'icon-chevron-right',
  ICON_BOOMBOX: 'icon-boombox',
  ICON_FILE_FONT: 'icon-file-font',
  ICON_PEN: 'icon-pen',
  ICON_BOX_ARROW_DOWN_RIGHT: 'icon-box-arrow-down-right',
  ICON_DISTRIBUTE_VERTICAL: 'icon-distribute-vertical',
  ICON_CALENDAR_EVENT: 'icon-calendar-event',
  ICON_DASH_CIRCLE: 'icon-dash-circle',
  ICON_DEVICE_HDD: 'icon-device-hdd',
  ICON_CLOUD_DRIZZLE: 'icon-cloud-drizzle',
  ICON_ARROW_RETURN_RIGHT: 'icon-arrow-return-right',
  ICON_SOUNDWAVE: 'icon-soundwave',
  ICON_CALENDAR_MONTH: 'icon-calendar-month',
  ICON_RECORD2: 'icon-record2',
  ICON_JOURNAL_CODE: 'icon-journal-code',
  ICON_SD_CARD: 'icon-sd-card',
  ICON_DIAGRAM_3: 'icon-diagram-3',
  ICON_PIGGY_BANK: 'icon-piggy-bank',
  ICON_POSTCARD: 'icon-postcard',
  ICON_NODE_PLUS: 'icon-node-plus',
  ICON_THREE_DOTS: 'icon-three-dots',
  ICON_CLOUD_UPLOAD: 'icon-cloud-upload',
  ICON_LOCK: 'icon-lock',
  ICON_QUESTION: 'icon-question',
  ICON_FLOWER3: 'icon-flower3',
  ICON_SKIP_BACKWARD_CIRCLE: 'icon-skip-backward-circle',
  ICON_QUESTION_CIRCLE: 'icon-question-circle',
  ICON_DOT: 'icon-dot',
  ICON_EXCLAMATION_TRIANGLE: 'icon-exclamation-triangle',
  ICON_SEND_EXCLAMATION: 'icon-send-exclamation',
  ICON_QR_CODE: 'icon-qr-code',
  ICON_BAR_CHART: 'icon-bar-chart',
  ICON_JOYSTICK: 'icon-joystick',
  ICON_USB_SYMBOL: 'icon-usb-symbol',
  ICON_X: 'icon-x',
  ICON_BOX2: 'icon-box2',
  ICON_KEYBOARD: 'icon-keyboard',
  ICON_DROPLET: 'icon-droplet',
  ICON_CHAT_TEXT: 'icon-chat-text',
  ICON_FILE_TEXT: 'icon-file-text',
  ICON_ARROW_DOWN_LEFT: 'icon-arrow-down-left',
  ICON_CHAT_LEFT: 'icon-chat-left',
  ICON_HDD_NETWORK: 'icon-hdd-network',
  ICON_DPAD: 'icon-dpad',
  ICON_EMOJI_KISS: 'icon-emoji-kiss',
  ICON_FOLDER_SYMLINK: 'icon-folder-symlink',
  ICON_CHEVRON_UP: 'icon-chevron-up',
  ICON_EMOJI_WINK: 'icon-emoji-wink',
  ICON_GRID_3X3_GAP: 'icon-grid-3x3-gap',
  ICON_JOURNAL_MEDICAL: 'icon-journal-medical',
  ICON_BOOK_HALF: 'icon-book-half',
  ICON_CASH_COIN: 'icon-cash-coin',
  ICON_TERMINAL: 'icon-terminal',
  ICON_VOICEMAIL: 'icon-voicemail',
  ICON_FILE_EARMARK_EXCEL: 'icon-file-earmark-excel',
  ICON_FILES: 'icon-files',
  ICON_BOX_SEAM: 'icon-box-seam',
  ICON_BADGE_CC: 'icon-badge-cc',
  ICON_LIST_STARS: 'icon-list-stars',
  ICON_MENU_BUTTON_WIDE: 'icon-menu-button-wide',
  ICON_PUZZLE: 'icon-puzzle',
  ICON_FILE: 'icon-file',
  ICON_CALENDAR2_RANGE: 'icon-calendar2-range',
  ICON_WALLET2: 'icon-wallet2',
  ICON_CHECK2_SQUARE: 'icon-check2-square',
  ICON_CHAT_LEFT_QUOTE: 'icon-chat-left-quote',
  ICON_EARBUDS: 'icon-earbuds',
  ICON_TELEPHONE_PLUS: 'icon-telephone-plus',
  ICON_CLIPBOARD2_PLUS: 'icon-clipboard2-plus',
  ICON_CLOUDS: 'icon-clouds',
  ICON_LAYOUT_SIDEBAR_REVERSE: 'icon-layout-sidebar-reverse',
  ICON_CLOUD_SLASH: 'icon-cloud-slash',
  ICON_CALENDAR_DAY: 'icon-calendar-day',
  ICON_POWER: 'icon-power',
  ICON_PLUS_SQUARE: 'icon-plus-square',
  ICON_ARROW_UP_RIGHT_SQUARE: 'icon-arrow-up-right-square',
  ICON_BLOCKQUOTE_LEFT: 'icon-blockquote-left',
  ICON_INBOX: 'icon-inbox',
  ICON_MAP: 'icon-map',
  ICON_CONE_STRIPED: 'icon-cone-striped',
  ICON_HAND_THUMBS_DOWN: 'icon-hand-thumbs-down',
  ICON_HEADSET: 'icon-headset',
  ICON_USB_PLUG: 'icon-usb-plug',
  ICON_DISTRIBUTE_HORIZONTAL: 'icon-distribute-horizontal',
  ICON_CLOUD_PLUS: 'icon-cloud-plus',
  ICON_CHEVRON_BAR_CONTRACT: 'icon-chevron-bar-contract',
  ICON_THERMOMETER_HALF: 'icon-thermometer-half',
  ICON_PHONE_LANDSCAPE: 'icon-phone-landscape',
  ICON_CHEVRON_BAR_UP: 'icon-chevron-bar-up',
  ICON_CURSOR_TEXT: 'icon-cursor-text',
  ICON_PLUGIN: 'icon-plugin',
  ICON_FILE_EARMARK_X: 'icon-file-earmark-x',
  ICON_HEPTAGON_HALF: 'icon-heptagon-half',
  ICON_FILE_EARMARK_EASEL: 'icon-file-earmark-easel',
  ICON_EXCLAMATION_SQUARE: 'icon-exclamation-square',
  ICON_ALIGN_BOTTOM: 'icon-align-bottom',
  ICON_EMOJI_SMILE_UPSIDE_DOWN: 'icon-emoji-smile-upside-down',
  ICON_BRIEFCASE: 'icon-briefcase',
  ICON_OPTION: 'icon-option',
  ICON_TAG: 'icon-tag',
  ICON_BAG_CHECK: 'icon-bag-check',
  ICON_CLOUD_HAZE2: 'icon-cloud-haze2',
  ICON_CARET_DOWN_SQUARE: 'icon-caret-down-square',
  ICON_CROP: 'icon-crop',
  ICON_CLOUD_SLEET: 'icon-cloud-sleet',
  ICON_TICKET: 'icon-ticket',
  ICON_CHAT_LEFT_HEART: 'icon-chat-left-heart',
  ICON_USB: 'icon-usb',
  ICON_CONE: 'icon-cone',
  ICON_FILE_EARMARK_ARROW_DOWN: 'icon-file-earmark-arrow-down',
  ICON_DISPLAYPORT: 'icon-displayport',
  ICON_SORT_ALPHA_UP: 'icon-sort-alpha-up',
  ICON_CHEVRON_BAR_LEFT: 'icon-chevron-bar-left',
  ICON_DOOR_CLOSED: 'icon-door-closed',
  ICON_SIGNPOST: 'icon-signpost',
  ICON_LAYOUT_TEXT_SIDEBAR_REVERSE: 'icon-layout-text-sidebar-reverse',
  ICON_HEARTBREAK: 'icon-heartbreak',
  ICON_SHIELD_PLUS: 'icon-shield-plus',
  ICON_GLOBE2: 'icon-globe2',
  ICON_TEXT_CENTER: 'icon-text-center',
  ICON_TRUCK_FLATBED: 'icon-truck-flatbed',
  ICON_BORDER_CENTER: 'icon-border-center',
  ICON_SORT_NUMERIC_UP_ALT: 'icon-sort-numeric-up-alt',
  ICON_RECEIPT_CUTOFF: 'icon-receipt-cutoff',
  ICON_EXCLAMATION_OCTAGON: 'icon-exclamation-octagon',
  ICON_HEXAGON: 'icon-hexagon',
  ICON_CLOUD_CHECK: 'icon-cloud-check',
  ICON_PERSON_HEART: 'icon-person-heart',
  ICON_FILE_EARMARK_MINUS: 'icon-file-earmark-minus',
  ICON_CHEVRON_DOUBLE_LEFT: 'icon-chevron-double-left',
  ICON_CHEVRON_DOWN: 'icon-chevron-down',
  ICON_CLOUD_FOG2: 'icon-cloud-fog2',
  ICON_ARROW_90DEG_UP: 'icon-arrow-90deg-up',
  ICON_FULLSCREEN_EXIT: 'icon-fullscreen-exit',
  ICON_ARROW_UP_LEFT: 'icon-arrow-up-left',
  ICON_EJECT: 'icon-eject',
  ICON_RECYCLE: 'icon-recycle',
  ICON_ARROW_UP_SHORT: 'icon-arrow-up-short',
  ICON_CHEVRON_DOUBLE_RIGHT: 'icon-chevron-double-right',
  ICON_EXCLAMATION_DIAMOND: 'icon-exclamation-diamond',
  ICON_SEND_X: 'icon-send-x',
  ICON_CLOUD_LIGHTNING_RAIN: 'icon-cloud-lightning-rain',
  ICON_CLOUD_RAIN_HEAVY: 'icon-cloud-rain-heavy',
  ICON_SLIDERS: 'icon-sliders',
  ICON_HANDBAG: 'icon-handbag',
  ICON_FILE_PLAY: 'icon-file-play',
  ICON_ENVELOPE_OPEN_HEART: 'icon-envelope-open-heart',
  ICON_MAILBOX: 'icon-mailbox',
  ICON_UI_CHECKS: 'icon-ui-checks',
  ICON_SAFE: 'icon-safe',
  ICON_BATTERY_HALF: 'icon-battery-half',
  ICON_SHOP: 'icon-shop',
  ICON_BALLOON_HEART: 'icon-balloon-heart',
  ICON_UI_CHECKS_GRID: 'icon-ui-checks-grid',
  ICON_PERSON: 'icon-person',
  ICON_VIEW_STACKED: 'icon-view-stacked',
  ICON_VIEW_LIST: 'icon-view-list',
  ICON_CIRCLE: 'icon-circle',
  ICON_PERSON_PLUS: 'icon-person-plus',
  ICON_HDD_STACK: 'icon-hdd-stack',
  ICON_FOLDER2_OPEN: 'icon-folder2-open',
  ICON_BOX_ARROW_DOWN_LEFT: 'icon-box-arrow-down-left',
  ICON_LAYOUT_SIDEBAR: 'icon-layout-sidebar',
  ICON_MOTHERBOARD: 'icon-motherboard',
  ICON_ARROWS_MOVE: 'icon-arrows-move',
  ICON_FOLDER_CHECK: 'icon-folder-check',
  ICON_CALENDAR4_RANGE: 'icon-calendar4-range',
  ICON_PERSON_DASH: 'icon-person-dash',
  ICON_BAG_DASH: 'icon-bag-dash',
  ICON_ARROW_BAR_LEFT: 'icon-arrow-bar-left',
  ICON_HEPTAGON: 'icon-heptagon',
  ICON_TYPE_BOLD: 'icon-type-bold',
  ICON_X_CIRCLE: 'icon-x-circle',
  ICON_FILE_MINUS: 'icon-file-minus',
  ICON_SHIFT: 'icon-shift',
  ICON_HAND_THUMBS_UP: 'icon-hand-thumbs-up',
  ICON_BEZIER2: 'icon-bezier2',
  ICON_CART_DASH: 'icon-cart-dash',
  ICON_HEART_ARROW: 'icon-heart-arrow',
  ICON_CARD_TEXT: 'icon-card-text',
  ICON_BADGE_TM: 'icon-badge-tm',
  ICON_MODEM: 'icon-modem',
  ICON_FILE_EARMARK: 'icon-file-earmark',
  ICON_FULLSCREEN: 'icon-fullscreen',
  ICON_CART_PLUS: 'icon-cart-plus',
  ICON_VOLUME_DOWN: 'icon-volume-down',
  ICON_BADGE_VR: 'icon-badge-vr',
  ICON_QR_CODE_SCAN: 'icon-qr-code-scan',
  ICON_FILE_PERSON: 'icon-file-person',
  ICON_EXCLAMATION_LG: 'icon-exclamation-lg',
  ICON_FILE_EARMARK_PLAY: 'icon-file-earmark-play',
  ICON_UI_RADIOS: 'icon-ui-radios',
  ICON_TRANSLATE: 'icon-translate',
  ICON_X_DIAMOND: 'icon-x-diamond',
  ICON_SNOW: 'icon-snow',
  ICON_TYPE_H1: 'icon-type-h1',
  ICON_UPC_SCAN: 'icon-upc-scan',
  ICON_GENDER_TRANS: 'icon-gender-trans',
  ICON_ARROW_DOWN_RIGHT_CIRCLE: 'icon-arrow-down-right-circle',
  ICON_CHECK_ALL: 'icon-check-all',
  ICON_SUNRISE: 'icon-sunrise',
  ICON_BODY_TEXT: 'icon-body-text',
  ICON_REPLY: 'icon-reply',
  ICON_TEXT_INDENT_RIGHT: 'icon-text-indent-right',
  ICON_SORT_ALPHA_UP_ALT: 'icon-sort-alpha-up-alt',
  ICON_BOXES: 'icon-boxes',
  ICON_WIFI_1: 'icon-wifi-1',
  ICON_HOURGLASS: 'icon-hourglass',
  ICON_CURRENCY_POUND: 'icon-currency-pound',
  ICON_META: 'icon-meta',
  ICON_SHIELD_LOCK: 'icon-shield-lock',
  ICON_SUIT_CLUB: 'icon-suit-club',
  ICON_FILE_EASEL: 'icon-file-easel',
  ICON_FILE_EARMARK_WORD: 'icon-file-earmark-word',
  ICON_X_OCTAGON: 'icon-x-octagon',
  ICON_STRAVA: 'icon-strava',
  ICON_CHEVRON_CONTRACT: 'icon-chevron-contract',
  ICON_TOOLS: 'icon-tools',
  ICON_SIGNPOST_SPLIT: 'icon-signpost-split',
  ICON_SEARCH_HEART: 'icon-search-heart',
  ICON_GRID_3X2_GAP: 'icon-grid-3x2-gap',
  ICON_BORDER_OUTER: 'icon-border-outer',
  ICON_FILE_ZIP: 'icon-file-zip',
  ICON_FILE_EARMARK_BINARY: 'icon-file-earmark-binary',
  ICON_PLAY_CIRCLE: 'icon-play-circle',
  ICON_SCISSORS: 'icon-scissors',
  ICON_PERSON_CIRCLE: 'icon-person-circle',
  ICON_TYPE_ITALIC: 'icon-type-italic',
  ICON_SAVE2: 'icon-save2',
  ICON_LAYOUT_TEXT_WINDOW: 'icon-layout-text-window',
  ICON_FILE_EARMARK_ZIP: 'icon-file-earmark-zip',
  ICON_TICKET_PERFORATED: 'icon-ticket-perforated',
  ICON_WIND: 'icon-wind',
  ICON_PC_HORIZONTAL: 'icon-pc-horizontal',
  ICON_TEXTAREA_RESIZE: 'icon-textarea-resize',
  ICON_SORT_NUMERIC_UP: 'icon-sort-numeric-up',
  ICON_HOUSE: 'icon-house',
  ICON_PERSON_VIDEO: 'icon-person-video',
  ICON_CIRCLE_HALF: 'icon-circle-half',
  ICON_HOUSE_DOOR: 'icon-house-door',
  ICON_FILE_EXCEL: 'icon-file-excel',
  ICON_FILE_EARMARK_TEXT: 'icon-file-earmark-text',
  ICON_INFO_SQUARE: 'icon-info-square',
  ICON_MEGAPHONE: 'icon-megaphone',
  ICON_OUTLET: 'icon-outlet',
  ICON_VOLUME_OFF: 'icon-volume-off',
  ICON_PIN_ANGLE: 'icon-pin-angle',
  ICON_LIST: 'icon-list',
  ICON_GRAPH_UP_ARROW: 'icon-graph-up-arrow',
  ICON_ALIGN_END: 'icon-align-end',
  ICON_QUESTION_DIAMOND: 'icon-question-diamond',
  ICON_CHECK_LG: 'icon-check-lg',
  ICON_TYPE_H2: 'icon-type-h2',
  ICON_BADGE_WC: 'icon-badge-wc',
  ICON_SORT_UP_ALT: 'icon-sort-up-alt',
  ICON_ARROW_DOWN: 'icon-arrow-down',
  ICON_CHECK_SQUARE: 'icon-check-square',
  ICON_PAUSE_CIRCLE: 'icon-pause-circle',
  ICON_RECEIPT: 'icon-receipt',
  ICON_ARROW_UP_CIRCLE: 'icon-arrow-up-circle',
  ICON_LIST_UL: 'icon-list-ul',
  ICON_ERASER: 'icon-eraser',
  ICON_CLIPBOARD_PLUS: 'icon-clipboard-plus',
  ICON_CALENDAR2_DATE: 'icon-calendar2-date',
  ICON_INCOGNITO: 'icon-incognito',
  ICON_WIFI_2: 'icon-wifi-2',
  ICON_CURRENCY_BITCOIN: 'icon-currency-bitcoin',
  ICON_SEARCH: 'icon-search',
  ICON_CODE_SLASH: 'icon-code-slash',
  ICON_SIM: 'icon-sim',
  ICON_MEDIUM: 'icon-medium',
  ICON_TOGGLE_ON: 'icon-toggle-on',
  ICON_TRIANGLE: 'icon-triangle',
  ICON_GRID: 'icon-grid',
  ICON_CHAT_SQUARE_TEXT: 'icon-chat-square-text',
  ICON_CARET_DOWN: 'icon-caret-down',
  ICON_LIGHTNING: 'icon-lightning',
  ICON_JUSTIFY_LEFT: 'icon-justify-left',
  ICON_QUESTION_OCTAGON: 'icon-question-octagon',
  ICON_ARROW_RIGHT_SQUARE: 'icon-arrow-right-square',
  ICON_BOOTSTRAP: 'icon-bootstrap',
  ICON_CUP_STRAW: 'icon-cup-straw',
  ICON_FILE_ARROW_UP: 'icon-file-arrow-up',
  ICON_LADDER: 'icon-ladder',
  ICON_HYPNOTIZE: 'icon-hypnotize',
  ICON_LAYER_BACKWARD: 'icon-layer-backward',
  ICON_COLUMNS: 'icon-columns'
}
