import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { defaultPageSize } from '@app/fff-config/pagination/pagination.config';
import { FffAllocationHistory } from '@app/models/fff-allocation-history.model';
import { OUTLINED_ICON_TYPE } from '@app/models/fff-outline-icons.model';
import { FFFUser } from '@app/reducers';
import { FffAllocationNavNodes } from '@app/shared/services/enum.service';
import { Store } from '@ngrx/store';
import { User } from '@spartacus/core';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { FffAllocationsService } from '../fff-allocations.service';

@Component({
  selector: 'fff-allocations-history',
  templateUrl: './fff-allocations-history.component.html',
})
export class FffAllocationsHistoryComponent implements OnInit, OnDestroy {

  private unsubscribe$: Subject<void> = new Subject<void>();
  allocationsHistory$: Observable<FffAllocationHistory[]> | undefined;
  user: User | undefined;
  currentPage: number = 1;
  pageSize = defaultPageSize;

  columnsGrid: any = {
    desktop: '3fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr',
    mobile: '1fr 1fr'
  }
  outlinedIconTypes = OUTLINED_ICON_TYPE;
  filters: any;
  fields: any = [
    { title: 'fffAllocationsHistory.productName', field: 'productName' },
    { title: 'fffAllocationsHistory.account', field: 'account' },
    { title: 'fffAllocationsHistory.sku', field: 'productCode' },
    { title: 'fffAllocationsHistory.ndc', field: 'productNdc' },
    { title: 'fffAllocationsHistory.dateModified', field: 'dateModified' },
    { title: 'fffAllocationsHistory.modifiedBy', field: 'modifiedBy' },
    { title: 'fffAllocationsHistory.qtyChanged', field: 'changeValue' },
    { title: 'fffAllocationsHistory.actions', field: 'changeValue' },
  ];

  constructor(
    private fffAllocationsService: FffAllocationsService,
    public cdr: ChangeDetectorRef,
    private storeUser: Store<FFFUser>
  ) { }

  ngOnInit(): void {
    this.storeUser.select((state: any) => {
      return state.FFFUser;
    }).subscribe(userData => {
      if (userData.user) {
        this.user = userData.user;
        this.currentPage = 1;
        this.allocationsHistory$ = this.fffAllocationsService.getHistory(userData.user.uid, this.currentPage, this.pageSize)
          .pipe(takeUntil(this.unsubscribe$));
        this.cdr.detectChanges();
      }
    });

    this.fffAllocationsService.allocationsFilter$.subscribe((filtersData: any) => {
      if (filtersData && FffAllocationNavNodes.allocationsHistory === filtersData.component) {
        this.filters = filtersData;
        this.currentPage = 1;
        this.allocationsHistory$ = this.fffAllocationsService.getHistory(this.user?.uid!, this.currentPage, this.pageSize,
          filtersData.filters.b2bunit, this.formateDateFilter(filtersData.filters.dateFrom), this.formateDateFilter(filtersData.filters.dateTo), filtersData.filters.skuNdc);
        this.cdr.detectChanges();
      }
    });
  }

  private formateDateFilter(dateFilter: any): string {
    if (dateFilter && dateFilter.year && dateFilter.month && dateFilter.day) {
      const month = dateFilter.month < 10 ? `0${dateFilter.month}` : dateFilter.month;
      const day = dateFilter.day < 10 ? `0${dateFilter.day}` : dateFilter.day;
      return `${dateFilter.year}${month}${day}`;
    } else {
      return '';
    }
  }

  getData(page: any): void {
    this.currentPage = page.page;
    let b2bUnit;
    let dateFrom;
    let dateTo;
    let skuNdc;
    if(this.filters) {
      b2bUnit = this.filters.filters.b2bunit;
      dateFrom = this.formateDateFilter(this.filters.filters.dateFrom);
      dateTo = this.formateDateFilter(this.filters.filters.dateTo);
      skuNdc = this.filters.filters.skuNdc;
    }
    this.allocationsHistory$ = this.fffAllocationsService.getHistory(this.user?.uid!, page.page, this.pageSize, b2bUnit, dateFrom, dateTo, skuNdc)
      .pipe(takeUntil(this.unsubscribe$));
  }

  getTableSizeFromPagination(data: any): number {
    if (data && data.pagination && data.pagination.pageSize) {
      return data.pagination.pageSize;
    }
    return defaultPageSize;
  }

  getTableData(allocationsHistory: any | undefined) {
    if (allocationsHistory) {
      return { data: allocationsHistory.results, total: allocationsHistory.count, loading: false, current: (this.currentPage - 1) };
    } else {
      return { data: [], total: 0, loading: false, current: 0 };
    }
  }

  ngOnDestroy(): void {
    if (this.unsubscribe$) {
      this.unsubscribe$.unsubscribe();
    }
  }

}
