import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FFFActiveCartService } from '@app/fff-enterprise/fff-custom-cart/fff-active-cart-service';
import {
  CartSummaryAction,
  FFFOrderEntry,
} from '@app/models/fff-cart-data.model';
import { UpdatedCartState } from '@app/reducers';
import { BASE_URL_KEYS } from '@config/content/constants';
import { FFFCart } from '@model/fff-cart-data.model';
import { Store } from '@ngrx/store';
import { RoutingService } from '@spartacus/core';
import { Observable, Subscription } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { FffDrawerService } from 'src/app/shared/drawer/fff-drawer.service';
import { FffSaveCartDrawerBodyComponent } from '../fff-save-cart-drawer/fff-save-cart-drawer-body.component';
import { FffSaveCartDrawerFooterComponent } from '../fff-save-cart-drawer/fff-save-cart-drawer-footer.component';
import { FffSaveCartDrawerHeaderComponent } from '../fff-save-cart-drawer/fff-save-cart-drawer-header.component';

@Component({
  selector: 'fff-cart-totals',
  templateUrl: './fff-cart-totals.component.html',
})
export class FffCartTotalsComponent implements OnInit, OnDestroy {
  @Input()
  activeSite: string | undefined;
  cart$: Observable<FFFCart> = this.fffActiveCartService.getActive();
  entries$: Observable<FFFOrderEntry[]> = this.fffActiveCartService
    .getEntries()
    .pipe(
      map(
        entries => entries.filter((entry: any) => entry?.product?.isExcluded), // Filter out excluded entries
        filter((filteredEntries: any) => filteredEntries.length > 0) // Only emit if there are entries left after filtering
      )
    );
  BASE_URL_KEYS = BASE_URL_KEYS;
  cartValidationInProgress = false;
  protected subscription = new Subscription();
  cart: any;
  loading: boolean = true;

  constructor(
    protected fffActiveCartService: FFFActiveCartService,
    private drawerService: FffDrawerService,
    private cartEvent: Store<UpdatedCartState>,
    private routingService: RoutingService
  ) {}

  ngOnInit() {
    this.subscription.add(
      this.cartEvent
        .select((state: any) => {
          return state.UpdatedCartState;
        })
        .subscribe(focusState => {
          this.cart$ = this.fffActiveCartService.getActive();
          this.entries$ = this.fffActiveCartService.getEntries().pipe(
            map(
              entries =>
                entries.filter((entry: any) => entry?.product?.isExcluded) // Filter out excluded entries
            )
          );
          this.handleActions();
          this.fffActiveCartService.performActionAfterStable = false;
        })
    );
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  handleActions() {
    if (!this.fffActiveCartService.performActionAfterStable) {
      return;
    }

    if (
      this.fffActiveCartService.selectedActionAfterStable ===
      CartSummaryAction.REDIRECT_TO_CHECKOUT
    ) {
      this.navigateToCheckout();
    }

    if (
      this.fffActiveCartService.selectedActionAfterStable ===
      CartSummaryAction.OPEN_SAVE_CART_DIALOG
    ) {
      this.openSaveCartDrawer();
    }

    this.fffActiveCartService.clearAfterStableActions();
  }

  navigateToCheckout() {
    this.routingService.go({ cxRoute: 'checkout' });
  }

  disableButtonWhileNavigation(): void {
    this.cartValidationInProgress = true;
  }

  handleCheckoutRedirection(): void {
    if (this.fffActiveCartService.performActionAfterStable) {
      this.fffActiveCartService.selectedActionAfterStable =
        CartSummaryAction.REDIRECT_TO_CHECKOUT;
    } else {
      this.navigateToCheckout();
    }
  }

  handleOpenSaveCartDrawer(): void {
    if (this.fffActiveCartService.performActionAfterStable) {
      this.fffActiveCartService.selectedActionAfterStable =
        CartSummaryAction.OPEN_SAVE_CART_DIALOG;
    } else {
      this.openSaveCartDrawer();
    }
  }

  openSaveCartDrawer() {
    this.drawerService.setContent({
      drawerHeader: FffSaveCartDrawerHeaderComponent as Component,
      animation: 'SideRTL',
      component: FffSaveCartDrawerBodyComponent as Component,
      drawerFooter: FffSaveCartDrawerFooterComponent as Component,
      class: 'face-filter-drawer-content',
    });
    this.drawerService.openDrawer();
  }
}
