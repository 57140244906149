import { Injectable } from '@angular/core';
import { BASE_URL_KEYS } from '@app/fff-config/content/constants';
import {
  FffCartAddEntrySuccessEvent,
  FffCartRemoveEntrySuccessEvent,
  FffFacetChangedEvent,
  FffLoginEvent,
  FffLogoutEvent,
  FffNavigationEvent,
  FffOrderPlacedEvent,
  FffUserAccountChangedEvent
} from '@app/spartacus/features/tracking/gmt/event';
import {
  BaseSiteService,
  CxEvent,
  EventService, WindowRef
} from '@spartacus/core';
import {
  TmsCollector,
  TmsCollectorConfig,
  WindowObject
} from '@spartacus/tracking/tms/core';
import { GtmCollectorConfig } from '@spartacus/tracking/tms/gtm/config/default-gtm.config';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class FffGtmCollectorService implements TmsCollector {
  private unsubscribe$: Subject<void> = new Subject<void>();
  constructor(
    protected winRef: WindowRef,
    protected eventService: EventService,
    protected baseSiteService: BaseSiteService
  ) {}
  /**
   * If the `TmsCollectorConfig.dataLayerProperty` is not specified, it uses the default `dataLayer`
   */
  init(config: GtmCollectorConfig, windowObject: WindowObject): void {
    const dataLayerProperty = config.dataLayerProperty ?? 'dataLayer';
    windowObject[dataLayerProperty] = windowObject[dataLayerProperty] ?? [];
    this.baseSiteService.getActive().pipe(
      takeUntil(this.unsubscribe$))
      .subscribe((site: string) => {
        const gtmId = site === BASE_URL_KEYS.BIOSUPPLY ? environment.gtmIdBioSupply : environment.gtmIdMvn;
        if (gtmId) {
          (function (
            w: WindowObject,
            d: Document,
            s: string,
            l: string,
            i: string
          ) {
            w[l] = w[l] || [];
            w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
            const f = d.getElementsByTagName(s)[0];
            const j = d.createElement(s) as HTMLScriptElement;
            const dl = l !== 'dataLayer' ? '&l=' + l : '';
            j.async = true;
            j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
            f.parentNode?.insertBefore(j, f);
          })(
            windowObject,
            this.winRef.document,
            'script',
            dataLayerProperty,
            gtmId
          );
          this.unsubscribe$.next();
        }
      });
  }

  pushEvent<T extends CxEvent>(
    config: TmsCollectorConfig,
    windowObject: WindowObject,
    event: T | any
  ): void {
    const dataLayerProperty = config.dataLayerProperty ?? 'dataLayer';
    windowObject[dataLayerProperty].push(event);
  }

  // eslint-disable-next-line @typescript-eslint/ban-types
  map?<T extends CxEvent>(event: T): object | T {
    if (event instanceof FffFacetChangedEvent) {
      return this.getFffFacetChangedEventMapped(event);
    }
    if (event instanceof FffLoginEvent) {
      return this.getFffLoginEventMapped(event);
    }

    if (event instanceof FffLogoutEvent) {
      return this.getFffLogoutEventMapped(event);
    }

    if (event instanceof FffNavigationEvent) {
      return this.getFffNavigationEventMapped(event);
    }

    if (event instanceof FffOrderPlacedEvent) {
      return this.getOrderPlacedEventMapped(event);
    }

    if (event instanceof FffCartAddEntrySuccessEvent) {
      return this.getCartAddEntrySuccessEventMapped(event);
    }

    if (event instanceof FffCartRemoveEntrySuccessEvent) {
      return this.getCartRemoveEntrySuccessEventMapped(event);
    }

    if (event instanceof FffUserAccountChangedEvent) {
      return this.getFffAccountChangedEventMapped(event);
    }
    return {
      event: event?.constructor?.name,
      ...event,
    };
  }

  protected getFffFacetChangedEventMapped(event: FffFacetChangedEvent) {
    return {
      event: FffFacetChangedEvent.type,
      ...event,
    };
  }

  protected getFffLoginEventMapped(event: FffLoginEvent) {
    return {
      event: FffLoginEvent.type,
      accountName: event?.accountName || '',
      accountNumber: event?.accountNumber || '',
      accountTerm: event?.accountTerm || '',
    };
  }

  protected getFffLogoutEventMapped(event: FffLogoutEvent) {
    return {
      event: FffLogoutEvent.type,
      ...event,
    };
  }

  protected getFffAccountChangedEventMapped(event: FffUserAccountChangedEvent) {
    return {
      event: FffUserAccountChangedEvent.type,
      ...event,
    };
  }

  protected getFffNavigationEventMapped(event: FffNavigationEvent) {
    return {
      event: FffNavigationEvent.type,
      ...event,
      accountName: event?.accountName || '',
      accountNumber: event?.accountNumber || '',
      accountTerm: event?.accountTerm || '',
    };
  }

  protected getOrderPlacedEventMapped(event: FffOrderPlacedEvent) {
    return {
      event: FffOrderPlacedEvent.type,
      ...event,
    };
  }

  protected getCartAddEntrySuccessEventMapped(event: FffCartAddEntrySuccessEvent) {
    return {
      event: FffCartAddEntrySuccessEvent.type,
      ...event,
    };
  }

  protected getCartRemoveEntrySuccessEventMapped(event: FffCartRemoveEntrySuccessEvent) {
    return {
      event: FffCartRemoveEntrySuccessEvent.type,
      ...event,
    };
  }
}
