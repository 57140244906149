import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FffRequestChangeAddressDrawerComponent } from '@app/fff-enterprise/fff-request-change-address/fff-request-change-address-drawer.component';
import { FffB2bUnit } from '@app/models/fff-b2b-unit.model';
import { FffDrawerService } from '@app/shared/drawer/fff-drawer.service';
import { Address, TranslationService } from '@spartacus/core';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';

@Component({
  selector: 'fff-profile-detail-account',
  templateUrl: './fff-profile-detail-account.component.html',
})
export class FffProfileDetailAccountComponent implements OnInit, OnDestroy {
  private subscription: Subscription = new Subscription();
  @Input() selected: FffB2bUnit | undefined;
  @Output() reloadAccount: EventEmitter<void> = new EventEmitter<void>();
  @Output() isUpdating: EventEmitter<boolean> = new EventEmitter<boolean>(false);
  @Output() setResult: EventEmitter<boolean> = new EventEmitter<boolean>(false);
  drawerTitle = '';
  billingAddress: Address | undefined;
  shippingAddress: Address | undefined;
  constructor(
    private drawerService: FffDrawerService,
    private translationService: TranslationService) { }

  ngOnInit(): void {
    this.subscription.add(this.translationService.translate('fffAccount.fffRequestChangeAddress.title')
      .subscribe(title => {
        this.drawerTitle = title;
      })
    );
    if(this.selected){
      this.getAddressToDisplay(this.selected.addresses as any);
    }
  }

  openChangeAddressDrawer() {
    this.drawerService.setContent({
      title: this.drawerTitle,
      paragraph: '',
      class: 'minicart-drawer',
      animation: 'SideRTL',
      component: FffRequestChangeAddressDrawerComponent as Component,

    });
    this.drawerService.openDrawer();
    this.subscription = this.drawerService.getDrawerResponse
      .pipe(take(1))
      .subscribe(
        (result: any) => {
          if (result && result.updated){
            this.setResult.emit(true);
            this.reloadAccount.emit();
          } else {
            this.setResult.emit(false);
          }
          this.isUpdating.emit(false);
        },
        () => {
          this.setResult.emit(false);
          this.isUpdating.emit(false);
        }
      );

  }
  getAddressToDisplay(addresses: any[]){
    if(addresses) {
      addresses.every((address) => {
        if(!this.billingAddress){
          if(address.billingAddress === true){
            this.billingAddress = address;
          }
        }
        if(!this.shippingAddress) {
          if(address.shippingAddress === true){
            this.shippingAddress = address;
          }
        }
        if(this.billingAddress && this.shippingAddress){
          return false;
        }
        return true;
      });
    }
  }
  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }
}
