import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { OccEndpointsService } from '@spartacus/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class FFFCartService {
  getRequestHeaders(): HttpHeaders {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    return headers;
  }

  constructor(
    private http: HttpClient,
    protected occEndpoints: OccEndpointsService
  ) {}

  saveCart(
    userId: string,
    cartId: string,
    cartName: string,
    cartDescription: string
  ): Observable<any> {
    const url = `/users/${userId}/carts/${cartId}/save?saveCartDescription=${cartDescription}&saveCartName=${cartName}`;
    return this.http.patch(
      this.occEndpoints.buildUrl(url),
      {},
      { headers: this.getRequestHeaders() }
    );
  }

  createCart(userId: string): Observable<any> {
    const url = `/users/${userId}/carts`;
    return this.http.post(
      this.occEndpoints.buildUrl(url),
      {},
      { headers: this.getRequestHeaders() }
    );
  }

  cloneCart(userId: string, cartId: string): Observable<any> {
    const url = `/users/${userId}/carts/${cartId}/clonesavedcart`;
    return this.http.post(
      this.occEndpoints.buildUrl(url),
      {},
      { headers: this.getRequestHeaders() }
    );
  }
}
