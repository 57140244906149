import { createFeatureSelector, createSelector, MemoizedSelector } from '@ngrx/store';
import {
  FFF_GLOBAL_MESSAGE,
  GlobalBannerMessage,
  GlobalBannerMessageEntities,
  GlobalBannerMessageState,
  StateWithGlobalBannerMessage,
} from '@model/fff-global-message.model';
import { GlobalMessageType } from '@spartacus/core';

export const getGlobalBannerMessageState: MemoizedSelector<
  StateWithGlobalBannerMessage,
  GlobalBannerMessageState
  > = createFeatureSelector<GlobalBannerMessageState>(FFF_GLOBAL_MESSAGE);

export const getGlobalBannerMessageEntitiesByType = (
  type: GlobalMessageType
): MemoizedSelector<StateWithGlobalBannerMessage, GlobalBannerMessage[]> => {
  return createSelector(
    getGlobalMessageEntities,
    (entities) => entities && entities[type]
  );
};

export const getGlobalBannerMessageCountByType = (
  type: GlobalMessageType
): MemoizedSelector<StateWithGlobalBannerMessage, number> => {
  return createSelector(
    getGlobalBannerMessageEntitiesByType(type),
    (entities) => entities && entities.length
  );
};
export const getGlobalMessageEntities: MemoizedSelector<
  StateWithGlobalBannerMessage,
  GlobalBannerMessageEntities
  > = createSelector(
    getGlobalBannerMessageState,
    (state: GlobalBannerMessageState) => state?.entities);
