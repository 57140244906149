import { Injectable } from '@angular/core';
import { Event, NavigationEnd, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import {
  BaseSiteService,
  SiteContextConfig,
  StateWithSiteContext
} from '@spartacus/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class FffSiteContextService extends BaseSiteService {
  focusPageUrl = ['checkout'];
  isFocusPage$: Subject<boolean> = new Subject<boolean>();

  constructor(
    store: Store<StateWithSiteContext>,
    config: SiteContextConfig,
    protected router: Router
  ) {
    super(store, config);

    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        this.focusPageUrl.every((url) => {
          this.isFocusPage$.next(event.url.includes(url));
        });
      }
    });
  }
}
