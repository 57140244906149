import { Component, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { FFFReturnsService } from '@app/fff-enterprise/fff-common-services/fff-returns.service';
import { OUTLINED_ICON_TYPE } from '@app/models/fff-outline-icons.model';
import { FffProfile } from '@app/models/fff-profile.model';
import {
  FffReturn,
  FffReturnEntry,
  FffReturnEntryValidateResponse,
} from '@app/models/fff-return.model';
import {
  RETURN_CONFIRMATION_DATA,
  ReturnConfirmationState,
} from '@app/reducers';
import { FffUserAccountService } from '@app/shared/services/fff-user-account.service';
import { getProductImage } from '@assets/utils/fff-product-utils';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { Product, RoutingService } from '@spartacus/core';
import { CustomFormValidators, MediaContainer } from '@spartacus/storefront';
import { BehaviorSubject, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { FffReturnsErrorRequestPopupComponent } from '../fff-returns-error-request-popup/fff-returns-error-request-popup.component';

@Component({
  selector: 'fff-returns-request',
  templateUrl: './fff-returns-request.component.html',
})
export class FffReturnsRequestComponent implements OnInit {
  outlinedIconTypes = OUTLINED_ICON_TYPE;
  editMode: boolean = false;
  contactInformationData: any = {};
  returnList: FffReturnEntry[] = [];
  DEFAULT_PO_NUMBER: string = 'FLU RETURN';
  remainingEtQuantity: number = 0;
  updatedEligibleQty!: number;

  profile: FffProfile | undefined;
  profile$: Observable<FffProfile | undefined> = this.fffAccountService
    .getProfile()
    .pipe(
      tap((profile: FffProfile | undefined) => {
        if (profile) {
          this.profile = profile;
          if (this.profile?.user?.uid) {
            this.getReturnData(profile?.user?.uid);
          }
        }
      })
    );

  returnData$: Observable<FffReturn> | undefined;
  loading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  contactInformationForm: UntypedFormGroup = this.formBuilder.group({
    name: ['', [Validators.required]],
    phone: ['', [Validators.required]],
    email: ['', [Validators.required, CustomFormValidators.emailValidator]],
    poNumber: ['', [Validators.required, Validators.maxLength(20)]],
  });

  addEntryGroup: UntypedFormGroup | undefined;

  constructor(
    protected formBuilder: UntypedFormBuilder,
    private fffFReturnsService: FFFReturnsService,
    private fffAccountService: FffUserAccountService,
    private store: Store<ReturnConfirmationState>,
    protected routingService: RoutingService,
    private modalService: NgbModal,
    private routing: RoutingService
  ) {}

  ngOnInit(): void {}

  private getReturnData(userId: string): void {
    this.contactInformationData = {
      name: this.profile?.user.name,
      phone: this.profile?.user.contactNumber,
      email: this.profile?.user.uid,
      poNumber: this.DEFAULT_PO_NUMBER,
    };
    this.returnData$ = this.fffFReturnsService.getReturns(userId).pipe(
      tap(data => {
        if (data && data.returnEntries) {
          data.returnEntries.forEach(entry => (entry.showAdd = false));
          this.createData(data.returnEntries);
        }
      })
    );
  }

  private createData(returnEntries: FffReturnEntry[]): void {
    const group: any = {};
    for (let i = 0; i < returnEntries.length; i++) {
      group[i] = this.formBuilder.group({
        batchNumber: ['', Validators.required],
        quantity: ['', [Validators.required]],
      });
    }

    this.addEntryGroup = new UntypedFormGroup(group);
  }

  getData(product: Product) {
    return getProductImage(product.images) as MediaContainer;
  }

  showAddProduct(returnEntry: FffReturnEntry): void {
    returnEntry.showAdd = !returnEntry.showAdd;
  }

  handleStandardReturn(
    returnEntry: FffReturnEntry,
    batchNumber: string,
    quantity: any
  ) {
    let newReturnEntry = { ...returnEntry };
    newReturnEntry.batchId = batchNumber;
    newReturnEntry.quantity = parseInt(quantity);

    // Validate if exist a entry with same product/batchId
    const entriesToMerge = this.returnList.filter(
      currentEntry =>
        newReturnEntry.product.code == currentEntry.product.code &&
        newReturnEntry.fffAccountNumber == currentEntry.fffAccountNumber &&
        newReturnEntry.batchId == currentEntry.batchId
    );

    if (entriesToMerge.length > 0) {
      const indexToMerge = this.returnList.indexOf(entriesToMerge[0]);
      let returnAvailable = this.returnList[indexToMerge];
      returnAvailable.quantity! += newReturnEntry.quantity!;
    } else {
      this.returnList.push(newReturnEntry);
    }
  }

  updateReturnEntryByType(
    returnEntry: FffReturnEntry,
    batchNumber: string,
    quantity: number,
    returnType: 'standard' | 'fet'
  ) {
    let newReturnEntry = JSON.parse(JSON.stringify(returnEntry));
    newReturnEntry.batchId = batchNumber;
    newReturnEntry.quantity = quantity;

    if (returnType === 'fet') {
      newReturnEntry.product.code = newReturnEntry.fetProduct.code;
    }
    //checks whether entry contains item with same batch
    const entriesWithSameBatch = this.returnList.filter(
      currentEntry =>
        newReturnEntry.product.code == currentEntry.product.code &&
        newReturnEntry.fffAccountNumber == currentEntry.fffAccountNumber &&
        newReturnEntry.batchId == currentEntry.batchId
    );

    if (entriesWithSameBatch.length > 0) {
      const indexToMerge = this.returnList.indexOf(entriesWithSameBatch[0]);
      let returnAvailablecase1 = this.returnList[indexToMerge];
      if (returnType === 'standard') {
        const eligibleQty = newReturnEntry.eligibleQuantity;
        const baseEntriesWithDifferentBranch = this.returnList.filter(
          currentEntry =>
            newReturnEntry.product.code == currentEntry.product.code &&
            newReturnEntry.fffAccountNumber == currentEntry.fffAccountNumber &&
            !currentEntry.product.code.includes('ET')
        );
        let totalBaseEntriesQuantity = 0;
        if (
          baseEntriesWithDifferentBranch &&
          baseEntriesWithDifferentBranch.length > 0
        ) {
          baseEntriesWithDifferentBranch.forEach(item => {
            if (item.quantity) {
              totalBaseEntriesQuantity += item.quantity;
            }
          });
          if (totalBaseEntriesQuantity && totalBaseEntriesQuantity > 0) {
            this.updatedEligibleQty =
              newReturnEntry.eligibleQuantity - totalBaseEntriesQuantity;
          }
        }

        let newTotalQuantity =
          returnAvailablecase1.quantity + newReturnEntry.quantity;
        if (newReturnEntry.quantity + totalBaseEntriesQuantity > eligibleQty) {
          this.remainingEtQuantity =
            newReturnEntry.quantity + totalBaseEntriesQuantity - eligibleQty;
          newTotalQuantity = newTotalQuantity - this.remainingEtQuantity;
        }
        if (newTotalQuantity) {
          returnAvailablecase1.quantity! = newTotalQuantity!;
        }
        //handle the scanrio of remaining quantity after filling the maximum eligible quantity in the non FET item
        if (this.remainingEtQuantity > 0) {
          const availableFetEntry = this.returnList.filter(
            currentEntry =>
              newReturnEntry.product.code + 'ET' == currentEntry.product.code &&
              newReturnEntry.fffAccountNumber ==
                currentEntry.fffAccountNumber &&
              newReturnEntry.batchId == currentEntry.batchId
          );
          if (availableFetEntry && availableFetEntry.length > 0) {
            const indexToMerge = this.returnList.indexOf(availableFetEntry[0]);
            let returnAvailableCase2 = this.returnList[indexToMerge];
            if (returnAvailableCase2.quantity) {
              const newFetQty =
                returnAvailableCase2.quantity + this.remainingEtQuantity;
              returnAvailableCase2.quantity! = newFetQty!;
            }
          } else {
            let newFetReturnEntry = JSON.parse(
              JSON.stringify(entriesWithSameBatch[0])
            );
            newFetReturnEntry.batchId = newReturnEntry.batchId;
            newFetReturnEntry.quantity = this.remainingEtQuantity;
            newFetReturnEntry.product.code = newReturnEntry.product.code + 'ET';
            this.returnList.push(newFetReturnEntry);
          }
        }
      } else {
        const newFetTotalQty =
          newReturnEntry.quantity + returnAvailablecase1.quantity;
        returnAvailablecase1.quantity! = newFetTotalQty!;
      }
    } else {
      //filtering non FET items available with all the batches of same product.
      const baseEntriesWithDifferentBranch = this.returnList.filter(
        currentEntry =>
          newReturnEntry.product.code == currentEntry.product.code &&
          newReturnEntry.fffAccountNumber == currentEntry.fffAccountNumber &&
          !currentEntry.product.code.includes('ET')
      );

      //checks whether entry contains FET items for which correspnding non FET item is not available.
      const standAloneFetEntriesWithSameBatch = this.returnList.filter(
        currentEntry =>
          newReturnEntry.fetProduct.code === currentEntry.product.code &&
          newReturnEntry.fffAccountNumber == currentEntry.fffAccountNumber &&
          newReturnEntry.batchId == currentEntry.batchId
      );
      let totalBaseEntriesQuantity = 0;
      if (
        standAloneFetEntriesWithSameBatch &&
        standAloneFetEntriesWithSameBatch.length > 0
      ) {
        const indexToMerge = this.returnList.indexOf(
          standAloneFetEntriesWithSameBatch[0]
        );
        let returnAvailableCase4 = this.returnList[indexToMerge];
        if (returnAvailableCase4.quantity) {
          const newFetQty =
            returnAvailableCase4.quantity + newReturnEntry.quantity;
          returnAvailableCase4.quantity! = newFetQty!;
        }
      } else if (
        baseEntriesWithDifferentBranch &&
        baseEntriesWithDifferentBranch.length > 0
      ) {
        baseEntriesWithDifferentBranch.forEach(item => {
          if (item.quantity) {
            totalBaseEntriesQuantity += item.quantity;
          }
        });
        if (totalBaseEntriesQuantity && totalBaseEntriesQuantity > 0) {
          let newEligibleQty =
            newReturnEntry.eligibleQuantity - totalBaseEntriesQuantity;
          let newRemainingEtQuantity = 0;
          const newQty = newReturnEntry.quantity;
          if (newQty > newEligibleQty) {
            let newBaseEntry = JSON.parse(JSON.stringify(returnEntry));
            newBaseEntry.batchId = batchNumber;
            newRemainingEtQuantity = newQty - newEligibleQty;
            newBaseEntry.quantity = newEligibleQty;
            if (newEligibleQty) {
              this.returnList.push(newBaseEntry);
            }
          } else {
            this.returnList.push(newReturnEntry);
          }
          if (newRemainingEtQuantity > 0) {
            //adding the missing FET item to the list with updated quantity
            let newFetEntry = JSON.parse(JSON.stringify(returnEntry));
            newFetEntry.batchId = batchNumber;
            newFetEntry.product.code = newReturnEntry.fetProduct.code;
            newFetEntry.quantity = newRemainingEtQuantity;
            if (newRemainingEtQuantity) {
              this.returnList.push(newFetEntry);
            }
          }
        }
      } else {
        this.returnList.push(newReturnEntry);
      }
    }
  }

  handleFETReturn(
    returnEntry: FffReturnEntry,
    batchNumber: string,
    validationRes: FffReturnEntryValidateResponse
  ) {
    const standardReturnQty = validationRes?.standardReturnQty || 0;
    const fetReturnQty = validationRes?.fetReturnQty || 0;

    let newReturnEntry = { ...returnEntry };
    newReturnEntry.batchId = batchNumber;
    newReturnEntry.quantity = standardReturnQty;

    if (standardReturnQty) {
      this.updateReturnEntryByType(
        returnEntry,
        batchNumber,
        standardReturnQty,
        'standard'
      );
    }

    if (fetReturnQty) {
      this.updateReturnEntryByType(
        returnEntry,
        batchNumber,
        fetReturnQty,
        'fet'
      );
    }
    this.remainingEtQuantity = 0;
  }

  addProduct(index: number, returnEntry: FffReturnEntry): void {
    this.validateQuantity(index, returnEntry, null, true);
    if (this.addEntryGroup?.get(`${index}`)?.valid && this.profile) {
      const batchNumber = this.addEntryGroup
        ?.get(`${index}`)
        ?.get('batchNumber')
        ?.value.toUpperCase();
      const quantity = this.addEntryGroup
        ?.get(`${index}`)
        ?.get('quantity')?.value;

      this.fffFReturnsService
        .validateBatchNumber(
          this.profile?.user?.uid,
          batchNumber,
          quantity,
          returnEntry.product.code,
          returnEntry.fffAccountNumber
        )
        .subscribe(res => {
          if (!res?.isError) {
            if (!!returnEntry?.fetProduct) {
              this.handleFETReturn(returnEntry, batchNumber, res);
            } else {
              this.handleStandardReturn(returnEntry, batchNumber, quantity);
            }

            returnEntry.showAdd = false;
            this.addEntryGroup?.get(`${index}`)?.reset();
          } else {
            this.addEntryGroup
              ?.get(`${index}`)
              ?.get('batchNumber')
              ?.setErrors({ errorInvalidBatch: true });
          }
        });
    } else {
      this.addEntryGroup?.get(`${index}`)?.markAllAsTouched();
    }
  }

  removeProduct(index: number): void {
    const itemToRemove = this.returnList[index];
    if (itemToRemove && itemToRemove.product && itemToRemove.product.code) {
      const fetItemCode = itemToRemove.product.code + 'ET';
      const fetItems = this.returnList.filter(
        entryData =>
          fetItemCode == entryData.product.code &&
          itemToRemove.batchId == entryData.batchId
      );
      if (fetItems && fetItems.length > 0) {
        const indexofFet = this.returnList.indexOf(fetItems[0]);
        let itemData = this.returnList[index];
        let fetItemData = this.returnList[indexofFet];
        const fetQty = fetItemData.quantity;
        const baseEntriesWithDiffBatch = this.returnList.filter(
          currentEntry =>
            itemData.product.code == currentEntry.product.code &&
            itemData.fffAccountNumber == currentEntry.fffAccountNumber &&
            !currentEntry.product.code.includes('ET')
        );
        const filteredEntriesWithDiffBatch = baseEntriesWithDiffBatch.filter(
          currentEntry => currentEntry.batchId != itemToRemove.batchId
        );
        let totalBaseQuantity = 0;
        filteredEntriesWithDiffBatch.forEach(item => {
          if (item.quantity) {
            totalBaseQuantity += item.quantity;
          }
        });
        const eligibleQty =
          fetItemData.eligibleQuantity &&
          fetItemData.eligibleQuantity > totalBaseQuantity
            ? fetItemData.eligibleQuantity - totalBaseQuantity
            : fetItemData.eligibleQuantity;
        if (eligibleQty != undefined && fetQty && fetQty > eligibleQty) {
          const newFetQty = fetQty - eligibleQty;
          if (eligibleQty) {
            itemData.quantity! = eligibleQty!;
          }
          if (newFetQty) {
            fetItemData.quantity! = newFetQty!;
          } else {
            this.returnList.splice(indexofFet, 1);
          }
        } else {
          itemData.quantity! = fetQty!;
          this.returnList.splice(indexofFet, 1);
        }
        const baseEntriesWithDifferentBranch = this.returnList.filter(
          currentEntry =>
            itemData.product.code == currentEntry.product.code &&
            itemData.fffAccountNumber == currentEntry.fffAccountNumber &&
            !currentEntry.product.code.includes('ET')
        );
        let totalBaseEntriesQuantity = 0;
        baseEntriesWithDifferentBranch.forEach(item => {
          if (item.quantity) {
            totalBaseEntriesQuantity += item.quantity;
          }
        });
        totalBaseEntriesQuantity = parseInt(
          totalBaseEntriesQuantity.toString()
        );
        if (fetItemData.eligibleQuantity) {
          const newEligibleQty = parseInt(
            fetItemData.eligibleQuantity.toString()
          );
          if (
            newEligibleQty &&
            totalBaseEntriesQuantity < newEligibleQty &&
            fetItems.length > 0
          ) {
            const withET = this.returnList.filter(obj =>
              obj.product.code.endsWith('ET')
            );
            const withoutET = this.returnList.filter(
              obj => !obj.product.code.endsWith('ET')
            );

            const standAloneItems = withET.filter(obj => {
              const keyWithoutET = obj.product.code.slice(0, -2);
              return withoutET.some(o => o.product.code === keyWithoutET);
            });
            if (standAloneItems && standAloneItems.length > 0) {
              const newNonFetQty = newEligibleQty - totalBaseEntriesQuantity;
              let newNonFetReturnEntry = JSON.parse(
                JSON.stringify(standAloneItems[0])
              );
              if (newNonFetQty) {
                newNonFetReturnEntry.quantity = newNonFetQty;
                newNonFetReturnEntry.product.code =
                  newNonFetReturnEntry.product.code.replace('ET', '');
                this.returnList.push(newNonFetReturnEntry);
              }
              const indexToMerge = this.returnList.indexOf(standAloneItems[0]);
              let returnAvailablecase5 = this.returnList[indexToMerge];
              if (
                returnAvailablecase5.quantity &&
                returnAvailablecase5.quantity - newNonFetQty > 0
              ) {
                returnAvailablecase5.quantity! =
                  (returnAvailablecase5.quantity - newNonFetQty)!;
              } else {
                this.returnList.splice(indexToMerge, 1);
              }
            }
          }
        }
      } else {
        const itemToBeremoved = this.returnList[index];
        this.returnList.splice(index, 1);
        const baseEntriesWithDiffBatch = this.returnList.filter(
          currentEntry =>
            itemToBeremoved.product.code == currentEntry.product.code &&
            itemToBeremoved.fffAccountNumber == currentEntry.fffAccountNumber
        );
        const fetItemCode = itemToRemove.product.code + 'ET';
        const fetEntriesWithDiffBatch = this.returnList.filter(
          currentEntry =>
            fetItemCode == currentEntry.product.code &&
            itemToBeremoved.fffAccountNumber == currentEntry.fffAccountNumber
        );
        let totalBaseEntriesQuantity = 0;
        baseEntriesWithDiffBatch.forEach(item => {
          if (item.quantity) {
            totalBaseEntriesQuantity += item.quantity;
          }
        });
        const indexToMerge = this.returnList.indexOf(
          baseEntriesWithDiffBatch[0]
        );
        let returnAvailableCase6 = this.returnList[indexToMerge];
        if (
          returnAvailableCase6 &&
          returnAvailableCase6.quantity &&
          itemToBeremoved.quantity
        ) {
          const fetEntriesofUpdatedItem = this.returnList.filter(
            currentEntry =>
              returnAvailableCase6.product.code + 'ET' ==
                currentEntry.product.code &&
              returnAvailableCase6.fffAccountNumber ==
                currentEntry.fffAccountNumber
          );
          if (fetEntriesofUpdatedItem && fetEntriesofUpdatedItem.length > 0) {
            const fetIndexToMerge = this.returnList.indexOf(
              fetEntriesofUpdatedItem[0]
            );
            let returnAvailableCase7 = this.returnList[fetIndexToMerge];
            if (
              returnAvailableCase7.quantity &&
              returnAvailableCase7.quantity - itemToBeremoved.quantity
            ) {
              returnAvailableCase7.quantity! = (returnAvailableCase7.quantity -
                itemToBeremoved.quantity)!;
            } else {
              this.returnList.splice(fetIndexToMerge, 1);
            }
          }
        } else if (
          fetEntriesWithDiffBatch &&
          fetEntriesWithDiffBatch.length > 0
        ) {
          //This part handles the scenario of only FET items with different batch number are present after removing the desired item.

          let newNonFetReturnEntryForStandAloneItem = JSON.parse(
            JSON.stringify(fetEntriesWithDiffBatch[0])
          );
          if (newNonFetReturnEntryForStandAloneItem) {
            newNonFetReturnEntryForStandAloneItem.quantity =
              newNonFetReturnEntryForStandAloneItem.quantity >
              newNonFetReturnEntryForStandAloneItem.eligibleQuantity
                ? newNonFetReturnEntryForStandAloneItem.eligibleQuantity
                : newNonFetReturnEntryForStandAloneItem.quantity;
            newNonFetReturnEntryForStandAloneItem.product.code =
              newNonFetReturnEntryForStandAloneItem.product.code.replace(
                'ET',
                ''
              );

            //Creating a new non-FET entry and pushing into entries with quantity as the FET quantity compared with eligible qty

            this.returnList.push(newNonFetReturnEntryForStandAloneItem);
          }
          const indexToMerge = this.returnList.indexOf(
            fetEntriesWithDiffBatch[0]
          );
          let returnAvailablecase9 = this.returnList[indexToMerge];

          // Updating the remaining non-FET qty by comparing with filled FET qty
          if (
            returnAvailablecase9.quantity &&
            returnAvailablecase9.eligibleQuantity &&
            returnAvailablecase9.quantity >
              returnAvailablecase9.eligibleQuantity
          ) {
            returnAvailablecase9.quantity! = (returnAvailablecase9.quantity -
              returnAvailablecase9.eligibleQuantity)!;
          } else {
            //Removing the non-FET item if quantity is 0
            this.returnList.splice(indexToMerge, 1);
          }
        }
      }
    }
  }

  validateQuantity(
    index: number,
    returnEntry: FffReturnEntry,
    event?: any,
    isFromAddToCart?: boolean
  ): void {
    this.addEntryGroup?.get(`${index}`)?.get('batchNumber')?.setErrors(null);
    let enteredQuantity;
    if (!isFromAddToCart) {
      if (!event?.target?.value) {
        return;
      }
      enteredQuantity = parseInt(event?.target?.value);
    } else {
      const enteredValue = this.addEntryGroup?.value[index]?.quantity;
      enteredQuantity = parseInt(enteredValue);
    }

    // Frontend validtion is not required for FET Product
    if (returnEntry.fetProduct) {
      return;
    }
    let totalQuantityAdded = 0;
    const entryAdded = this.returnList.filter(
      entryData =>
        returnEntry.fffAccountNumber == entryData.fffAccountNumber &&
        returnEntry.product.code == entryData.product.code
    );

    entryAdded.forEach(entry => (totalQuantityAdded += entry.quantity!));
    totalQuantityAdded += enteredQuantity;

    if (totalQuantityAdded > returnEntry.eligibleQuantity!) {
      this.addEntryGroup
        ?.get(`${index}`)
        ?.get('quantity')
        ?.setErrors({ errorQuantity: true });
    }
  }

  changeEditMode(): void {
    if (!this.editMode) {
      this.contactInformationForm.patchValue(this.contactInformationData);
      this.contactInformationForm.enable();
    } else {
      if (!this.contactInformationForm.valid) {
        this.contactInformationForm.markAllAsTouched();
        return;
      }
      this.contactInformationData = this.contactInformationForm.value;
      this.contactInformationForm.disable();
    }
    this.editMode = !this.editMode;
  }

  cancelRequest(): void {
    this.returnList = [];
  }

  submitRequest(): void {
    let returnRequest: any = {};
    if (this.editMode) {
      if (!this.contactInformationForm.valid) {
        this.contactInformationForm.markAllAsTouched();
        return;
      }
      returnRequest = this.contactInformationForm.value;
    } else {
      returnRequest = this.contactInformationData;
    }
    if (!returnRequest.poNumber) {
      returnRequest.poNumber = this.DEFAULT_PO_NUMBER;
    }
    returnRequest.returnEntries = this.returnList;

    this.loading$.next(true);

    const submitReturn$ = this.fffFReturnsService.submitReturn(
      this.profile?.user.uid!,
      returnRequest
    );
    submitReturn$.subscribe(
      response => {
        if (response) {
          const ordersWithError = response.orders.filter(
            (order: any) => !order.isSuccess
          );
          const ordersSuccess = response.orders.filter(
            (order: any) => order.isSuccess
          );

          if (ordersWithError.length > 0 || ordersSuccess.length == 0) {
            this.showErrorReturnRequest(response);
          } else {
            this.goToReturnConfirmation(response);
          }
        } else {
          this.showErrorReturnRequest(response);
        }
      },
      error => {
        this.showErrorReturnRequest(error);
      }
    );
  }

  private goToReturnConfirmation(response: any): void {
    this.store.dispatch({ type: RETURN_CONFIRMATION_DATA, payload: response });
    this.loading$.next(false);
    this.routingService.go(['/my-account/returns/confirmation']);
  }

  private showErrorReturnRequest(response: any): void {
    this.loading$.next(false);

    const modalRef = this.modalService.open(
      FffReturnsErrorRequestPopupComponent,
      {
        centered: true,
        size: 'md',
        backdrop: 'static',
        modalDialogClass: 'modal-container',
      }
    );
    const ordersErrorCodes: string[] = this.getErrorsFromRequest(response);

    modalRef.componentInstance.hasErrorData = ordersErrorCodes.length > 0;
    modalRef.componentInstance.errorData = {
      code: `${ordersErrorCodes.toString()}`,
    };
  }

  private getErrorsFromRequest(response: any): any[] {
    const errorCodes: string[] = [];
    if (response && response.orders) {
      response.orders.forEach((tx: any) => {
        if (!tx.isSuccess) {
          errorCodes.push(tx.integrationResponseMessage);
        }
      });
    }
    return errorCodes;
  }

  goToHomePage(): void {
    this.routing.go(['/']);
  }
}
