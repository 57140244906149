import { Component, OnInit } from '@angular/core';
import { OUTLINED_ICON_TYPE } from '@app/models/fff-outline-icons.model';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'fff-approval-sent',
  templateUrl: './fff-approval-sent.component.html',
})
export class FffApprovalSentComponent implements OnInit {

  constructor(public modalService: NgbActiveModal) { }
  outlinedIconTypes = OUTLINED_ICON_TYPE;

  ngOnInit(): void {
  }

  close() {
    this.modalService.close();
  }

  continueApproving(): void {
    this.modalService.close({ accept: true });
  }

}
