import { Component } from '@angular/core';
import { OUTLINED_ICON_TYPE } from 'src/app/models/fff-outline-icons.model';
import { FffDrawerService } from 'src/app/shared/drawer/fff-drawer.service';


@Component({
  selector: 'fff-restore-saved-cart-drawer-header',
  templateUrl: './fff-restore-saved-cart-drawer-header.component.html',
})
export class FffRestoreSavedCartDrawerHeaderComponent {

  outlinedIconTypes = OUTLINED_ICON_TYPE;

  constructor(private drawerService: FffDrawerService) { }

  public closeDrawer(): void {
    this.drawerService.closeDrawer();
  }
}

