export const fffReports = {
  fffReports: {
    title: 'Create New Report',
    editReport: 'Edit Report',
    editReportId: '(ID: {{ reportId }})',
    errorsFound: 'The following errors were found',
    reportType: 'Report Type',
    from: 'From',
    to: 'To',
    submit: 'Submit',
    email: 'E-mail addresses',
    status: 'Status',
    submitted:
      'Your Request has been submitted. Your report will be delivered to your email in the next 10-20 minutes',
    edited:
      'Your request has been submitted. Your report will be delivered to your email on the date scheduled.',
    emailIssue: 'There is an issue with the email addresses you entered.',
    reportTypeIssue: 'Please select a report type',
    dateIssue: 'Please enter a valid Date Range.',
    statusIssue: 'Please select at least one status.',
    emailExplain:
      'Enter where the CSV should be sent, separated by a comma (ex: example1@gmail.com,example2@gmail.com). 4 emails maximum. ',
    maxAllowed: 'You have exceeded maximum number of emails allowed (4).',
    required: 'Please provide a list of emails.',
    andOthers: 'and Others …',
    saveReport: 'Save Report',
    sendReport: 'Send Report',
    cancel: 'Cancel',
    downloadPdfReport: 'Download PDF Report',
    downloadCsvReport: 'Download CSV Report',
    successModal: {
      title: 'Success',
    },
    warningModal: {
      text: 'Please try back again later. For immediate assistance email <a class="text-primary" href="/contact" target="_blank">Wow! Customer Care</a> or call (800) 843-7477',
      ok: 'Ok',
    },
    fields: {
      reportType: {
        label: 'Type',
      },
      frequency: {
        label: 'Frequency',
      },
      dateRange: {
        label: 'Date Range',
      },
      reportRange: {
        label: 'Range',
      },
      reportFormat: {
        label: 'Report Format',
      },
      firstReportDate: {
        label: 'First Report Date',
      },
      reportDate: {
        label: 'Next Report Date',
      },
      accounts: {
        label: 'Selected Accounts',
      },
      account: {
        label: 'Selected Account',
      },
      orderStatus: {
        label: 'Order Status',
      },
      invoiceStatus: {
        label: 'Invoice Status',
      },
      email: {
        label: 'Email Recipients',
        caption:
          'Enter the email addresse(s) where the report should be sent, separated by commas.',
      },
      productGroups: {
        label: 'Selected Product Groups',
      },
      products: {
        label: 'Selected Products',
      },
    },
    selectAccounts: 'Select Accounts',
    selectAccount: 'Select Account',
    selectProducts: 'Select Products',
    search: 'Search',
    ndc: 'NDC',
    sku: 'SKU',
    selectProductGroups: 'Select Product Groups',
    reportsTable: {
      reportId: 'Report ID',
      reportType: 'Report Type',
      status: 'Status',
      frequency: 'Frequency',
      nextReportDate: 'Next Report Date',
      accounts: 'Accounts',
      emailRecipients: 'Email Recipients',
      actions: 'Actions',
      edit: 'Edit',
      cancel: 'Cancel',
      products: 'Products',
      productGroups: 'Product Groups',
    },
    recurringReports: 'Recurring Reports',
    cancelRecurringReport: 'Cancel recurring Report',
    stopConfirmation: 'Are you sure you want to stop this recurring report?',
    yesCancelOrder: 'Yes Cancel this Recurring Report',
  },
};
