<div class="pricing-modal-container">
  <div style="display: flex; justify-content: space-between">
    <h3>
      <span class="label label-default" style="color: #003159"
        >Pricing Tiers</span
      >
    </h3>

    <cx-icon
      class="cx-icon icon-lg p-2"
      style="cursor: pointer"
      [type]="outlinedIconTypes.ICON_X"
      (click)="close()"
    ></cx-icon>
  </div>

  <div class="table-container">
    <ng-container
      *ngFor="let account of tieredPricing.prices; let isNotFirstRow = index"
    >
      <div class="d-flex table-row">
        <div
          class="d-flex table-actype"
          *ngIf="account.price.toMatScale.results.length > 0"
        >
          <b>{{ account.accountType }}</b>
        </div>
        <div
          *ngFor="
            let tierPrice of account.price.toMatScale.results;
            let i = index
          "
          class="table-content"
        >
          <div *ngIf="!isNotFirstRow" class="table-header">
            {{ getIntervalStrings(account.price)[i] }}
          </div>
          <span class="price-icon">$</span>{{ tierPrice.scaleDiscAmt }}
        </div>
      </div>
    </ng-container>
  </div>
</div>
