<cx-icon
  class="cx-icon icon-sm cx-icon-info fetInfoIcon info-icon"
  [ngbTooltip]="emailTooltipContent"
  triggers="click"
  placement="top"
  ngbTooltipClass="tooltip-custom"
  [type]="filledIconTypes.ICON_INFO_CIRCLE_FILL"
></cx-icon>
<ng-template #emailTooltipContent>
  <div class="tooltip-scrollable-content">
    The tax that is being added to the price of the flu vaccine is not a Sales
    Tax but is an Excise Tax that is for a mandated National Vaccine Injury
    Compensation Program (VICP) which is legislated by the Department of
    Justice. There are no entities that are exempt from this program. For more
    information please review the U.S. Department of Health and Human Services
    report at;
    <a
      href="http://www.hrsa.gov/vaccinecompensation/index.html"
      target="_blank"
      class="siteLink"
      >National Vaccine Injury Compensation Program | HRSA</a
    >
  </div>
  <div><div class="pointer"></div></div>
</ng-template>
