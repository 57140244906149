<div>
  <div class="drawer-header">
    <div class="closed">
      <cx-icon
        class="cx-icon icon-lg"
        [type]="iconClose"
        (click)="close()"
      ></cx-icon>
    </div>
    <div class="title">
      <h3>
        {{ 'fffReports.cancelRecurringReport' | cxTranslate }}
      </h3>
    </div>
  </div>

  <div class="drawer-body">
    <div class="account-container">
      <div class="label-container">
        <span class="body-text-demi-xl">{{
          'fffReports.stopConfirmation' | cxTranslate
        }}</span>
      </div>
    </div>
    <div *ngIf="!loading; else loadingTemplate"></div>
  </div>
  <div class="drawer-footer">
    <button class="btn btn-primary btn-lg w-100" (click)="cancelReport()">
      {{ 'fffReports.yesCancelOrder' | cxTranslate }}
    </button>
  </div>
</div>
<ng-template #loadingTemplate>
  <fff-drawer-loader></fff-drawer-loader>
</ng-template>
