import {
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { Router } from '@angular/router';
import { FffPrebookCartService } from '@app/fff-enterprise/fff-prebook-category/services/fff-prebook-cart.service';
import { OUTLINED_ICON_TYPE } from '@app/models/fff-outline-icons.model';
import { BASE_URL_KEYS } from '@config/content/constants';
import { FffB2bUnit } from '@model/fff-b2b-unit.model';
import { AuthService, BaseSiteService } from '@spartacus/core';
import { DeviceDetectorService } from 'ngx-device-detector';
import { BehaviorSubject, Observable, Subject, of } from 'rxjs';
import { catchError, map, take, takeUntil } from 'rxjs/operators';
import { FffCommunicationService } from 'src/app/fff-enterprise/fff-common-services/fff-communication.service';
import { getProductImage } from '../../../../../assets/utils/fff-product-utils';

@Component({
  selector: 'fff-product-list-item',
  templateUrl: './fff-product-list-item.component.html',
})
export class FffProductListItemComponent
  implements OnInit, OnChanges, OnDestroy
{
  @Input() user!: any;
  @Input() product!: any;
  @Input() b2bunit: FffB2bUnit | undefined;
  @Input() activeSite: string | undefined;
  @Input() prebookFormEnabled: boolean = false;
  @Output() itemAddedToCart = new EventEmitter<boolean>();

  private unsubscribe$: Subject<void> = new Subject<void>();
  contractProduct: boolean = false;
  customerAllocation: any;
  stockData$: Observable<any> = new BehaviorSubject<any>({});
  pricesData$: Observable<any> = new BehaviorSubject<any>({});
  productData$: Observable<any> | undefined;
  activeSite$ = this.baseSiteService.getActive();

  BASE_URL_KEYS = BASE_URL_KEYS;
  productImages = new BehaviorSubject<any>({});
  isBaseProduct$ = new BehaviorSubject([false, '']);
  isCartActive: Boolean = false;
  imageObject: any;
  alloc: any = null;
  stock: any = null;
  tooltipText = '';
  isInRapidCommitCategory: boolean = false;
  showAsSoldOutIfExcluded: boolean = false;
  isLoggedIn$: Observable<boolean> | undefined;

  combinedProductData$: Observable<any> = new BehaviorSubject<any>({});
  outlinedIconTypes = OUTLINED_ICON_TYPE;
  isProductAPIFailed!: boolean;
  amountOfFieldsShown = 4;
  ndcFields: any;

  @HostBinding('class.fff-product-list-item--sold-out')
  get isProductSoldOut(): boolean {
    return this.activeSite === BASE_URL_KEYS.MY_FLU_VACCINE
      ? !!(
          this.product.soldOut ||
          (this.product.showAsSoldOutIfExcluded && this.showAsSoldOutIfExcluded)
        ) && this.user
      : false;
  }

  constructor(
    protected communicationService: FffCommunicationService,
    public deviceService: DeviceDetectorService,
    protected baseSiteService: BaseSiteService,
    private router: Router,
    private fffPrebookCartService: FffPrebookCartService,
    private authService: AuthService,
    public elementRef: ElementRef
  ) {}

  ngOnInit(): void {
    if (
      this.router.url.includes('RapidCommit') ||
      this.router.url.includes('RAPIDCOMMIT')
    ) {
      this.isInRapidCommitCategory = true;
    }
    this.getNdcInfo();
    this.getPricingInfo();
    this.isLoggedIn$ = this.authService.isUserLoggedIn();
  }

  getNdcInfo() {
    if (this.user) {
      this.communicationService
        .getPDPDetails(this.product.code)
        .pipe(take(1))
        .subscribe(details => {
          this.ndcFields = details?.classifications[0]?.features.slice(
            0,
            this.amountOfFieldsShown
          );
        });
    }
  }

  getPricingInfo() {
    if (this.user) {
      this.combinedProductData$ = this.communicationService
        .getCombinedProductDetails(this.product.code || '')
        .pipe(
          takeUntil(this.unsubscribe$),
          catchError(error => {
            this.isProductAPIFailed = true;
            return of(null);
          })
        );
    } else {
      this.combinedProductData$ = of({});
    }

    this.productData$ = this.combinedProductData$.pipe(
      map(combinedProductData => {
        this.showAsSoldOutIfExcluded =
          combinedProductData?.price?.prices?.some(
            (p: any) => p.showAsSoldOutIfExcluded
          ) || false;
        this.fffPrebookCartService.setStockDataByProduct(
          this.product.code,
          combinedProductData?.custToCustAllocVint
        );
        this.isProductAPIFailed = !(
          combinedProductData?.custToCustAllocVint && combinedProductData?.price
        );
        this.fffPrebookCartService.setPricesDataByProduct(
          this.product.code,
          combinedProductData?.price
        );
        return {
          stockData: combinedProductData?.custToCustAllocVint,
          pricesData: combinedProductData?.price,
        };
      }),
      catchError(error => {
        console.error('Error processing product data:', error);
        return of({
          stockData: null,
          pricesData: null,
        });
      })
    );
  }

  getStockInfo(stockData: any): any {
    return stockData?.results[0];
  }

  cartErrorEventEmitter(label: string) {
    this.tooltipText = label;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.user && changes?.user?.currentValue) {
      this.user = changes?.user?.currentValue;
      this.getPricingInfo();
    }

    if (changes?.product) {
      this.customerAllocation = this.product.customerAllocation;
      this.imageObject = getProductImage(this.product?.images);
    }
  }

  handleAddToCartEvent() {
    this.itemAddedToCart.emit(true);
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
