import { FFFOrderEntry } from '@app/models/fff-cart-data.model';
import { MULTI_CART_DATA } from '@spartacus/cart/base/core';
import { OrderEntry } from '@spartacus/cart/base/root';
import { FFFEntityProcessesDecrementAction, FFFEntityProcessesIncrementAction } from './fff-process-loader';

export const FFF_CART_ADD_ENTRY = "[FFF-Cart-entry] Add Entry";
export const FFF_CART_ADD_ENTRY_SUCCESS = "[FFF-Cart-entry] Add Entry Success";
export const FFF_CART_UPDATE_ENTRY = '[FFF-Cart-entry] Update Entry';
export const FFF_CART_UPDATE_ENTRY_SUCCESS = '[FFF-Cart-entry] Update Entry Success';
export const FFF_CART_REMOVE_ENTRY = '[FFF-Cart-entry] Remove Entry';
export const FFF_CART_REMOVE_ENTRY_SUCCESS = '[FFF-Cart-entry] Remove Entry Success';

export class FFFCartAddEntry extends FFFEntityProcessesIncrementAction {
  readonly type = FFF_CART_ADD_ENTRY;
  constructor(
    public payload: {
      cartId: string;
      userId: string;
      productCode: string;
      quantity: number;
      pickupStore?: string;
      orderEntries?: FFFOrderEntry[];
    }
  ) {
    super(MULTI_CART_DATA, payload.cartId);
  }
}

export class FFFCartAddEntrySuccess extends FFFEntityProcessesDecrementAction {
  readonly type = FFF_CART_ADD_ENTRY_SUCCESS;
  constructor(
    public payload: {
      userId: string;
      cartId: string;
      productCode: string;
      quantity: number;
      deliveryModeChanged?: boolean;
      entry?: OrderEntry;
      quantityAdded?: number;
      statusCode?: string;
      statusMessage?: string;
    }
  ) {
    super(MULTI_CART_DATA, payload.cartId);
  }
}

export class FFFCartUpdateEntry extends FFFEntityProcessesIncrementAction {
  readonly type = FFF_CART_UPDATE_ENTRY;
  constructor(
    public payload: {
      userId: string;
      cartId: string;
      entryNumber: string;
      quantity: number;
    }
  ) {
    super(MULTI_CART_DATA, payload.cartId);
  }
}

export class FFFCartUpdateEntrySuccess extends FFFEntityProcessesDecrementAction {
  readonly type = FFF_CART_UPDATE_ENTRY_SUCCESS;
  constructor(
    public payload: {
      userId: string;
      cartId: string;
      entryNumber: string;
      quantity?: number;
    }
  ) {
    super(MULTI_CART_DATA, payload.cartId);
  }
}

export class FFFCartRemoveEntry extends FFFEntityProcessesIncrementAction {
  readonly type = FFF_CART_REMOVE_ENTRY;
  constructor(
    public payload: { cartId: string; userId: string; entryNumber: string }
  ) {
    super(MULTI_CART_DATA, payload.cartId);
  }
}

export class FFFCartRemoveEntrySuccess extends FFFEntityProcessesDecrementAction {
  readonly type = FFF_CART_REMOVE_ENTRY_SUCCESS;
  constructor(
    public payload: { userId: string; cartId: string; entryNumber: string }
  ) {
    super(MULTI_CART_DATA, payload.cartId);
  }
}
