import { Injectable } from '@angular/core';
import { NgbPopover } from '@ng-bootstrap/ng-bootstrap';
import { WindowRef } from '@spartacus/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { animationTime } from '../../fff-config/content/constants';
import { DrawerMetadata } from './fff-drawer.model';

@Injectable({
  providedIn: 'root',
})
export class FffDrawerService {
  popover!: NgbPopover;
  metaData = {} as DrawerMetadata;
  content: BehaviorSubject<DrawerMetadata> = new BehaviorSubject(this.metaData);
  data: BehaviorSubject<unknown> = new BehaviorSubject<unknown>(null);
  callbackResponse: any;

  private drawerOpen$ = new Subject<boolean>();
  private drawerResult$ = new Subject<any>();

  constructor(
    protected winRef: WindowRef) {
  }

  setPopover(popover: NgbPopover) {
    this.popover = popover;
  }

  openDrawer(closable = true) {
    this.popover.open();
    //p.open();
    document.querySelectorAll('body')[0]?.classList.add('popOverOpen');
    document.querySelectorAll('ngb-popover-window')[0]?.classList.add('drawerRunningAnimation');
    setTimeout(() => {
      document.querySelectorAll('ngb-popover-window')[0]?.classList.remove('drawerRunningAnimation');
    }, animationTime);
    if (closable) {
      this.drawerOpen$.next(true);
    }
  }

  isCurrentlyOpen(): boolean {
    return this.winRef.document.body.classList.contains('popOverOpen');
  }

  closeDrawer(result?: any) {
    try {
      document.querySelectorAll('ngb-popover-window')[0]?.classList.add('animation-close');
      document.querySelectorAll('ngb-popover-window')[0]?.classList.add('drawerRunningAnimation');
    } catch (error) {
      console.error(error)
    }

    setTimeout(() => {
      this.popover.close();
      try {
        document.querySelectorAll('body')[0]?.classList.remove('popOverOpen');
        document.querySelectorAll('ngb-popover-window')[0]?.classList.remove('drawerRunningAnimation');
      } catch (error) {
        console.error(error)
      }

      this.setContent(this.metaData);
      this.setComponentData(undefined);
    }, animationTime);
    if (result) {
      this.drawerResult$.next(result);
    }
    this.drawerOpen$.next(false);
  }

  setCallbackResponse(object: any) {
    this.callbackResponse = object
  }

  closeDrawerSync() {

    try {
      document.querySelectorAll('ngb-popover-window')[0].classList.add('animation-close');
      document.querySelectorAll('ngb-popover-window')[0].classList.add('drawerRunningAnimation');
    } catch (error) {
      console.error(error)
    }

    this.popover.close();
    try {
      document.querySelectorAll('body')[0].classList.remove('popOverOpen');
      document.querySelectorAll('ngb-popover-window')[0].classList.remove('drawerRunningAnimation');
    } catch (error) {
      console.error(error)
    }

    this.setContent(this.metaData);
    this.setComponentData(undefined);

    this.drawerOpen$.next(false);

  }

  getContent(): Observable<DrawerMetadata> {
    return this.content.asObservable();
  }

  setContent(content: DrawerMetadata) {
    this.content.next(content);
  }

  setComponentData(data: unknown) {
    this.data.next(data);
  }

  getComponentData(): Observable<unknown> {
    return this.data.asObservable();
  }

  get getDrawerResponse(): Observable<unknown> {
    return this.drawerResult$.asObservable();
  }

  get isDrawerOpen(): Observable<boolean> {
    return this.drawerOpen$.asObservable();
  }
}
