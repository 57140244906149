<div class="d-flex flex-column justify-content-between h-100">

  <form [formGroup]="form">

    <div class="orderHistoryContainer">

      <label class="summary-row position-relative">
        <section class="filter-box">
          <label class="searchbox btn-sm w-100 mb-0 d-flex align-items-center" for="search">
            <input id="search" autocomplete="off"
              [placeholder]="'fffAllocationsHistoryFilter.searchBySkuOrNdc' | cxTranslate" formControlName="skuNdc">
            <div class="search-icon">
              <cx-icon class="cx-icon icon-sm" [type]="outlinedIconTypes.ICON_SEARCH"></cx-icon>
            </div>
          </label>
        </section>

        <hr class="sep-table-title my-4">

        <section class="filter-box">
          <label class="searchbox btn-sm w-100 mb-0 d-flex align-items-center" for="search">
            <input id="search" autocomplete="off"
              [placeholder]="'fffAllocationsHistoryFilter.searchByAccount' | cxTranslate" formControlName="b2bunit">
            <div class="search-icon">
              <cx-icon class="cx-icon icon-sm" [type]="outlinedIconTypes.ICON_SEARCH"></cx-icon>
            </div>
          </label>
        </section>
      </label>

      <div class="text-brand-primary-80 font-size-body-xl filter-title font-weight-bold pt-3">
        {{'fffAllocationsHistoryFilter.date' | cxTranslate}}
      </div>

      <label class="summary-row">
        <span class="body-text">{{ 'fffAllocationsHistoryFilter.dateFrom' | cxTranslate }}</span>
        <section class="filter-box filter-box-picker">
          <label class="searchbox btn-sm w-100 mb-0 d-flex align-items-center" for="dateFrom">
            <input id="dateFrom" type="datetime" autocomplete="off" formControlName="dateFrom" ngbDatepicker
              #from="ngbDatepicker">
            <div class="search-icon" (click)="from.toggle()">
              <cx-icon class="cx-icon icon-sm" [type]="outlinedIconTypes.ICON_CALENDAR_EVENT"></cx-icon>
            </div>
          </label>
        </section>
      </label>

      <label class="summary-row">
        <span class="body-text">{{ 'fffAllocationsHistoryFilter.dateTo' | cxTranslate }}</span>
        <section class="filter-box filter-box-picker">
          <label class="searchbox btn-sm w-100 mb-0 d-flex align-items-center" for="dateTo">
            <input id="dateTo" autocomplete="off" formControlName="dateTo" ngbDatepicker #to="ngbDatepicker">
            <div class="search-icon" (click)="to.toggle()">
              <cx-icon class="cx-icon icon-sm" [type]="outlinedIconTypes.ICON_CALENDAR_EVENT"></cx-icon>
            </div>
          </label>
        </section>
      </label>

    </div>
  </form>

  <div class="minicart-footer-container">
    <button class="btn btn-primary btn-xs w-100" (click)="apply()">
      {{'fffAllocationsHistoryFilter.apply' | cxTranslate}}
    </button>
  </div>


</div>