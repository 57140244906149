import { ChangeDetectionStrategy, Component, ElementRef } from '@angular/core';
import { OUTLINED_ICON_TYPE } from 'src/app/models/fff-outline-icons.model';
import { FffForgotPasswordService } from '../../fff-common-services/fff-forgot-password.service';

@Component({
  selector: 'fff-forgot-password-confirmation',
  templateUrl: './fff-forgot-password-confirmation.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FffForgotPasswordConfirmationComponent {

  outlinedIconTypes = OUTLINED_ICON_TYPE;

  constructor(protected service: FffForgotPasswordService,
    private elementRef: ElementRef) {
  }

  ngAfterViewInit() {
    if (this.elementRef.nativeElement.querySelector('.resent-email')) {
      this.elementRef.nativeElement.querySelector('.resent-email').addEventListener('click', this.resendEmail.bind(this));
    }
  }

  resendEmail(): void {
    if (this.service.email.value) {
      this.service.form.controls.userEmail.setValue(this.service.email.value);
      this.service.requestEmail();
    }
  }

}
