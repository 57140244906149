<div class="d-flex flex-wrap body-text-lg">
  <div
    class="content-close-icon cursor-pointer ml-auto d-flex justify-content-between"
  >
    <cx-icon
      class="cx-icon icon-lg p-2"
      [type]="outlinedIconTypes.ICON_X"
      (click)="close()"
    ></cx-icon>
  </div>

  <div class="col-12 text-center cx-dialog-body modal-body content-body">
    <div
      class="p-5"
      [innerHtml]="
        'fffProduct.productNotOrderable.text1' | cxTranslate | fffSafeHtml
      "
    ></div>
  </div>

  <div class="col-12 modal-actions">
    <button class="btn btn-primary col-12" (click)="close()">
      {{ 'fffShortDated.action' | cxTranslate }}
    </button>
  </div>
</div>
