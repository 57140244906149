import {
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { FffB2bUnit } from '@app/models/fff-b2b-unit.model';
import { OUTLINED_ICON_TYPE } from '@app/models/fff-outline-icons.model';
import { FffProfile } from '@app/models/fff-profile.model';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CsAgentAuthService } from '@spartacus/asm/root';
import { BehaviorSubject, Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';
import { FffNewReportsService } from '../fff-new-reports.service';

@Component({
  selector: 'fff-new-report-account-select-drawer',
  templateUrl: './fff-new-report-account-select-drawer.component.html',
})
export class FffNewReportAccountSelectDrawerComponent
  implements OnInit, OnDestroy
{
  @Input() profile!: FffProfile;
  @Input() isMultiAccount!: boolean;
  pageSize: number = 50;
  selectAllChecked = false;
  selectedAccounts: string[] = [];
  iconClose = OUTLINED_ICON_TYPE.ICON_X;
  searchText = '';
  outlinedIconTypes = OUTLINED_ICON_TYPE;
  private subscription: Subscription = new Subscription();
  accountsList: any = { data: [], total: 0, loading: false, current: 0 };
  accountListData$: BehaviorSubject<any> = new BehaviorSubject<any>(undefined);
  currentAccountsData: any;
  loading!: boolean;
  isASMUser!: boolean;
  constructor(
    private ActiveModalService: NgbActiveModal,
    private newReportService: FffNewReportsService,
    private csAgentAuthService: CsAgentAuthService,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.subscription.add(
      this.newReportService
        .getSelectedAccounts()
        .subscribe(selectedAccountTags => {
          if (selectedAccountTags && selectedAccountTags.length > 0) {
            const selectedAccounts = selectedAccountTags.map(obj =>
              obj.id.toString()
            );
            this.selectedAccounts = selectedAccounts;
            this.updateSelectAllStatus();
            this.cd.detectChanges();
          }
        })
    );
    this.subscription.add(
      this.csAgentAuthService
        .isCustomerSupportAgentLoggedIn()
        .pipe(
          tap(isCustomerSupportAgentLoggedIn => {
            this.isASMUser = !!isCustomerSupportAgentLoggedIn;
            if (!this.isASMUser) {
              this.searchAccounts();
            }
            this.cd.detectChanges();
          })
        )
        .subscribe()
    );
  }
  searchAccounts() {
    this.getAccountsData({ page: 0, size: this.pageSize });
  }

  getAccountsData(page: any, isFromPaginator?: boolean) {
    this.loading = true;
    const currentPage =
      isFromPaginator && page.page ? page.page - 1 : page.page;
    this.newReportService
      .getAccountsByPage(currentPage, this.searchText)
      .subscribe(
        (res: any) => {
          if (res.b2bunits && res.b2bunits.length > 0) {
            const total = res?.pagination?.totalResults
              ? res.pagination.totalResults
              : 1;

            this.accountsList = {
              data: res.b2bunits,
              total: total,
              loading: false,
              current: this.accountsList.current,
            };
            this.updateSelectAllStatus();
            this.emitNewAccountListData(this.accountsList);
            this.loading = false;
          } else {
            this.accountsList = {
              data: [],
              total: 0,
              loading: false,
              current: this.accountsList.current,
            };
            this.updateSelectAllStatus();
            this.emitNewAccountListData(this.accountsList);
            this.loading = false;
          }
        },
        (error: any) => {
          this.accountsList = {
            data: [],
            total: 0,
            loading: false,
            current: this.accountsList.current,
          };
          this.emitNewAccountListData(this.accountsList);
          this.loading = false;
        }
      );
    this.cd.detectChanges();
  }

  selectAccount() {
    const selectedAccountTags = this.selectedAccounts.map(acc => ({
      id: acc,
      displayValue: acc,
    }));
    this.newReportService.setSelectedAccounts(selectedAccountTags);
    this.close();
  }

  hasShippingAddress(b2bUnitData: any): boolean {
    if (b2bUnitData.addresses) {
      return b2bUnitData.addresses.some(
        (address: any) => address.shippingAddress
      );
    }
    return false;
  }

  getShippingAddress(b2bUnitData: any): any {
    const shippingAddressList = b2bUnitData.addresses.filter(
      (address: any) => address.shippingAddress
    );
    let shippingAddress =
      shippingAddressList.length > 0 ? shippingAddressList[0] : null;

    if (shippingAddress) {
      const regionName = shippingAddress.region?.name
        ? shippingAddress.region?.name
        : '';
      const data =
        this.addAddresField(shippingAddress.line1, '<br/>') +
        this.addAddresField(shippingAddress.line2, '<br/>') +
        this.addAddresField(shippingAddress.town, ', ') +
        this.addAddresField(regionName, '') +
        '<br/>' +
        this.addAddresField(shippingAddress.postalCode, '');
      return data;
    }
    return {};
  }

  private addAddresField(field: string, separator: string): string {
    if (field) {
      return field + separator;
    }
    return '';
  }

  close() {
    this.ActiveModalService.dismiss();
  }

  selectB2bUnit(unit: FffB2bUnit) {
    if (this.isMultiAccount) {
      const index = this.selectedAccounts.indexOf(unit.uid);
      if (index === -1) {
        this.selectedAccounts.push(unit.uid);
      } else {
        this.selectedAccounts.splice(index, 1);
      }
    } else {
      this.selectedAccounts = [unit.uid];
    }
    this.updateSelectAllStatus();
    this.cd.markForCheck();
  }
  isAccountSelected(unit: FffB2bUnit) {
    return this.selectedAccounts.includes(unit.uid);
  }
  toggleAll() {
    this.selectedAccounts = this.selectAllChecked
      ? [
          ...new Set(
            this.selectedAccounts.concat(
              this.accountsList.data.map((account: any) => account.uid)
            )
          ),
        ]
      : this.selectedAccounts.filter(
          (selectedUid: string) =>
            !this.accountsList.data.some(
              (account: any) => account.uid === selectedUid
            )
        );
  }

  updateSelectAllStatus() {
    let allAccountsAdded: boolean = true;
    if (
      this.accountsList &&
      this.accountsList.data &&
      this.accountsList.data.length > 0
    ) {
      this.accountsList.data.forEach((account: any) => {
        if (!this.selectedAccounts.includes(account.uid)) {
          allAccountsAdded = false;
          return;
        }
      });
    } else {
      allAccountsAdded = false;
    }
    this.selectAllChecked = allAccountsAdded;
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }
  emitNewAccountListData(data: any): void {
    this.accountListData$.next(data);
  }
}
