<div>
  <div class="drawer-header">
    <div class="closed">
      <cx-icon
        class="cx-icon icon-lg"
        [type]="iconClose"
        (click)="close()"
      ></cx-icon>
    </div>
    <div class="title">
      <h3>
        {{ 'fffReports.selectProducts' | cxTranslate }}
      </h3>
    </div>
    <div class="ml-5 mt-2" *ngIf="isMultiAccount">
      <input
        type="checkbox"
        [(ngModel)]="selectAllChecked"
        (change)="toggleAll()"
      />
      <label class="pt-2 selectAllText">Select All</label>
    </div>
  </div>

  <div class="drawer-body">
    <section class="search-box">
      <label class="label" for="search">
        <input
          id="search"
          autocomplete="off"
          placeholder="{{ 'fffReports.search' | cxTranslate }}"
          [(ngModel)]="searchText"
        />

        <div *ngIf="!searchText" class="search-icon">
          <cx-icon
            class="cx-icon icon-sm"
            [type]="outlinedIconTypes.ICON_SEARCH"
          ></cx-icon>
        </div>
        <button *ngIf="searchText" class="reset">
          <cx-icon
            class="cx-icon icon-sm"
            [type]="outlinedIconTypes.ICON_X_CIRCLE"
            (click)="searchText = ''"
          ></cx-icon>
        </button>
      </label>
    </section>
    <div
      class="account-container"
      *ngFor="let product of products | searchFilter : searchText"
    >
      <div class="block-icon" *ngIf="isProductSelected(product)">
        <div class="icon">
          <cx-icon
            class="cx-icon icon-sm icon-account"
            [type]="outlinedIconTypes.ICON_CHECK_LG"
          ></cx-icon>
        </div>
      </div>
      <div
        class="account-card"
        (click)="toggleProducts(product)"
        [ngClass]="{ selected: isProductSelected(product) }"
      >
        <div class="card-content">
          <div class="block-profile">
            <div class="fields">
              <div class="field productName">
                {{ product.name }}
              </div>
              <div class="field">
                {{ product.manufacturerName }}
              </div>
            </div>
          </div>

          <div class="block-profile" *ngIf="product.ndc">
            <div class="fields">
              <div class="field">{{ 'fffReports.ndc' | cxTranslate }}:</div>
            </div>
            <div class="values">
              <div class="value">
                {{ product.ndc }}
              </div>
            </div>
          </div>

          <div class="block-profile" *ngIf="product.sku">
            <div class="fields">
              <div class="field">{{ 'fffReports.sku' | cxTranslate }}:</div>
            </div>
            <div class="values">
              <div class="value">
                {{ product.sku }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="drawer-footer">
    <button
      class="btn btn-primary btn-lg w-100"
      [disabled]="selectedProducts.length === 0"
      (click)="selectProducts()"
    >
      {{ 'fffReports.selectProducts' | cxTranslate }}
    </button>
  </div>
</div>
