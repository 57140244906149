import { Component } from '@angular/core';
import { OUTLINED_ICON_TYPE } from '@app/models/fff-outline-icons.model';
import { FffOrderFacade } from '@enterprise/fff-common-services/fff-order-facade';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'fff-recurring-order-list-drawer',
  templateUrl: 'fff-recurring-order-list-modal.component.html',
})
export class FffRecurringOrderListModalComponent {
  outlinedIconTypes = OUTLINED_ICON_TYPE;
  isSkipOrCancel: boolean = false;
  cartCode: string = '';
  skipDate: string = '';
  constructor(
    private modalService: NgbActiveModal,
    private fffOrderFacade: FffOrderFacade
  ) {}

  skipOrder() {
    this.fffOrderFacade.skipRecurringOrder(this.cartCode).subscribe(() => {
      this.closeModal(true);
    });
  }

  cancelOrder() {
    this.fffOrderFacade.cancelRecurringOrder(this.cartCode).subscribe(() => {
      this.closeModal(true);
    });
  }

  closeModal(updateList: boolean): void {
    this.modalService.close({ updateList: updateList });
  }
}
