import { Component, ElementRef, OnDestroy, OnInit } from '@angular/core';
import { FffCommunicationService } from '@enterprise/fff-common-services/fff-communication.service';
import { FffRingCentralChatBotService } from '@enterprise/fff-service-third-party/fff-ring-central-chat-bot.service';
import { GlobalBannerMessage } from '@model/fff-global-message.model';
import { FffUser } from '@model/fff-user.model';
import { FffGlobalBannerMessagesService } from '@shared/services/fff-global-banner-messages.service';
import { FffGlobalCartRestrictionsService } from '@shared/services/fff-global-cart-restrictions.service';
import { FffUserAccountService } from '@shared/services/fff-user-account.service';
import { GlobalMessageType } from '@spartacus/core';
import { UserAccountFacade } from '@spartacus/user/account/root';
import { Subject, forkJoin, of } from 'rxjs';
import { concatMap, debounceTime, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'fff-account-global-restrictions-checker',
  template: '',
})
export class FffAccountGlobalRestrictionsCheckerComponent
  implements OnInit, OnDestroy
{
  unsubscribe$: Subject<boolean> = new Subject<boolean>();
  isValidLicense = true;
  licenseCheckTimeout: any;
  constructor(
    private userAccountDetails: UserAccountFacade,
    private userAccountService: FffUserAccountService,
    private communicationService: FffCommunicationService,
    private globalMessagesService: FffGlobalBannerMessagesService,
    private chatBotService: FffRingCentralChatBotService,
    private globalCartRestrictionsService: FffGlobalCartRestrictionsService,
    private elementRef: ElementRef
  ) {}

  ngOnInit() {
    this.userAccountDetails
      .get()
      .pipe(
        takeUntil(this.unsubscribe$),
        concatMap(user => {
          return forkJoin([
            of(user as FffUser),
            this.communicationService.getCurrentAccount(user),
          ]);
        })
      )
      .pipe(debounceTime(250))
      .subscribe(([currentUser, account]) => {
        if (currentUser && account) {
          const acc = JSON.parse(JSON.stringify(account));
          this.communicationService.normalizeB2BAccount(acc);
          this.userAccountService.isValidLicense =
            !this.checkCustomerInvalidLicense(acc);
          const hasBlockCode = this.checkCustomerBlockCode(acc);
          const cantPlaceOrder = this.checkCustomerCanPlaceOrder(currentUser);
          this.userAccountService.hasNoGPOAssigned =
            this.checkCustomerHasGPO(acc);
          const COTNoMatchWithLicenseDEAType =
            this.checkCOTWithLicenseDEAType(acc);
          if (
            !this.userAccountService.isValidLicense ||
            hasBlockCode ||
            cantPlaceOrder ||
            this.userAccountService.hasNoGPOAssigned ||
            COTNoMatchWithLicenseDEAType
          ) {
            this.globalCartRestrictionsService.emitGlobalCartRestriction(true);
          } else {
            this.globalCartRestrictionsService.emitGlobalCartRestriction(false);
          }
        } else {
          this.globalMessagesService.remove(
            {
              id: 'customer-can-place-order',
              type: GlobalMessageType.MSG_TYPE_ERROR,
              isPersistent: false,
            } as GlobalBannerMessage,
            -1
          );
          this.globalMessagesService.remove(
            {
              id: 'customer-blocked',
              type: GlobalMessageType.MSG_TYPE_ERROR,
              isPersistent: false,
            } as GlobalBannerMessage,
            -1
          );
          this.globalMessagesService.remove(
            {
              id: 'license-error',
              type: GlobalMessageType.MSG_TYPE_ERROR,
              isPersistent: false,
            } as GlobalBannerMessage,
            -1
          );
          this.globalMessagesService.remove(
            {
              id: 'customer-doesnt-has-gpo-assigned',
              type: GlobalMessageType.MSG_TYPE_ERROR,
              isPersistent: false,
            } as GlobalBannerMessage,
            -1
          );
          this.globalMessagesService.remove(
            {
              id: 'license-dea-cot-value-different-c4-104',
              type: GlobalMessageType.MSG_TYPE_ERROR,
              isPersistent: false,
            } as GlobalBannerMessage,
            -1
          );
          if (!this.isValidLicense) {
            this.removeClickEventToLicenseError();
            this.isValidLicense = true;
          }
        }
      });
  }

  checkCustomerInvalidLicense(account: any): boolean {
    if (!account.primaryLicenseDate) {
      this.isValidLicense = false;
    } else {
      const licenseDate = new Date(account.primaryLicenseDate);
      const currentDate = new Date();
      if (licenseDate < currentDate) {
        this.isValidLicense = false;
      }
    }
    if (!this.isValidLicense) {
      this.globalMessagesService.add(
        'license-error',
        "<span>Your license on file has expired, please <a id='contact-customer-service' class='contact-customer-service'> " +
          'Contact Customer Service </a>to resolve.</span>',
        GlobalMessageType.MSG_TYPE_ERROR,
        0,
        true,
        true,
        false,
        ''
      );
      this.licenseChecker();
      return true;
    }
    this.globalMessagesService.remove(
      {
        id: 'license-error',
        type: GlobalMessageType.MSG_TYPE_ERROR,
        isPersistent: false,
      } as GlobalBannerMessage,
      -1
    );
    this.removeClickEventToLicenseError();
    return false;
  }

  checkCustomerBlockCode(account: any): boolean {
    if (account.blockCode) {
      const code = (account.blockCode.code as string)
        .split('-')[0]
        .toLowerCase()
        .replace(/ /g, '');

      if (!code || code === '20' || code === 'none' || code === '') {
        return false;
      }

      const prefix = '[Code: ' + account.blockCode.code + ']';
      let message;

      if (account.blockCode.message) {
        message = account.blockCode.message;
      }

      if (account.blockCode.code === 99) {
        this.userAccountService.hasCreditBlock = true;
      }

      this.globalMessagesService.add(
        'customer-blocked',
        message ? message : 'fffAddCart.customer.blocked',
        GlobalMessageType.MSG_TYPE_ERROR,
        0,
        true,
        false,
        !message,
        prefix
      );

      if (
        code === '1' ||
        code === '3' ||
        code === '4' ||
        code === '5' ||
        code === '10' ||
        code === '90' ||
        code === '99'
      ) {
        return true;
      }
      return false;
    }
    this.globalMessagesService.remove(
      {
        id: 'customer-blocked',
        type: GlobalMessageType.MSG_TYPE_ERROR,
        isPersistent: false,
      } as GlobalBannerMessage,
      -1
    );
    return false;
  }

  checkCustomerCanPlaceOrder(currentUser: FffUser): boolean {
    if (!currentUser.canPlaceOrder) {
      this.globalMessagesService.add(
        'customer-can-place-order',
        'fffAddCart.unauthorized',
        GlobalMessageType.MSG_TYPE_ERROR,
        0,
        true,
        false,
        true,
        ''
      );
      return true;
    }

    this.globalMessagesService.remove(
      {
        id: 'customer-can-place-order',
        type: GlobalMessageType.MSG_TYPE_ERROR,
        isPersistent: false,
      } as GlobalBannerMessage,
      -1
    );
    return false;
  }

  checkCustomerHasGPO(account: any): boolean {
    if (!account.gpo) {
      this.globalMessagesService.add(
        'customer-doesnt-has-gpo-assigned',
        'fffAddCart.gpo',
        GlobalMessageType.MSG_TYPE_ERROR,
        0,
        true,
        false,
        true,
        ''
      );
      return true;
    }
    this.globalMessagesService.remove(
      {
        id: 'customer-doesnt-has-gpo-assigned',
        type: GlobalMessageType.MSG_TYPE_ERROR,
        isPersistent: false,
      } as GlobalBannerMessage,
      -1
    );
    return false;
  }

  checkCOTWithLicenseDEAType(account: any): boolean {
    if (account.primaryLicenseType === 'DEA') {
      if (!account.cot || !(account.cot === 'C4' || account.cot === '104')) {
        this.globalMessagesService.add(
          'license-dea-cot-value-different-c4-104',
          'fffAddCart.dea.license',
          GlobalMessageType.MSG_TYPE_ERROR,
          0,
          true,
          false,
          true,
          ''
        );
        return true;
      }
    }
    this.globalMessagesService.remove(
      {
        id: 'license-dea-cot-value-different-c4-104',
        type: GlobalMessageType.MSG_TYPE_ERROR,
        isPersistent: false,
      } as GlobalBannerMessage,
      -1
    );
    return false;
  }

  licenseChecker(): void {
    this.licenseCheckTimeout = setTimeout(() => {
      const el = document?.querySelector(
        '.contact-customer-service'
      ) as HTMLElement;
      if (el) {
        el.addEventListener('click', this.openLiveChat.bind(this));
        clearInterval(this.licenseCheckTimeout);
      }
    }, 1000); // 1seg
  }

  removeClickEventToLicenseError(): void {
    const el = this.elementRef.nativeElement.querySelector(
      '.contact-customer-service'
    ) as HTMLElement;
    if (el) {
      el.removeEventListener('click', this.openLiveChat.bind(this));
    }
  }

  openLiveChat(): void {
    this.chatBotService.openChatBot();
  }

  ngOnDestroy() {
    if (this.licenseCheckTimeout) {
      clearTimeout(this.licenseCheckTimeout);
    }
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();
  }
}
