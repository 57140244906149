<div class="d-flex w-100 justify-content-end text-brand-primary-80 close-drawer">
    <cx-icon class="cx-icon icon-lg" [type]="outlinedIconTypes.ICON_X" (click)="closeDrawer()"></cx-icon>
</div>
<div class="minicart-header-container">
    <h3 class="text-brand-primary-80" *ngIf="cartData$ | async as cartData; else emptyCart">
        {{'fffMiniCart.cart' | cxTranslate}} ({{ cartData.entries?.length }}
        <span *ngIf="cartData.entries && cartData.entries.length == 1 ">
            {{'fffMiniCart.singleItem' | cxTranslate}})
        </span>

        <span *ngIf="cartData.entries && cartData.entries.length != 1 ">
            {{'fffMiniCart.pluralItem' | cxTranslate}})
        </span>

    </h3>
</div>

<ng-template #emptyCart>
    <h3 class="text-brand-primary-80">
        {{'fffMiniCart.cart' | cxTranslate}} (0 {{'fffMiniCart.pluralItem' | cxTranslate}})
    </h3>
</ng-template>