<div class="d-flex flex-wrap body-text-lg">
  <div
    class="content-close-icon cursor-pointer ml-auto d-flex justify-content-between"
  >
    <cx-icon
      class="cx-icon icon-lg p-2"
      [type]="outlinedIconTypes.ICON_X"
      (click)="close()"
    ></cx-icon>
  </div>

  <div
    class="col-12 text-center body-text-demi-xl content-header text-brand-primary-80"
  >
    <span class="col-12 text-center">{{
      'fffSavedCarts.messages.deleteConfirmationSavedCart'
        | cxTranslate : { cartName: savedCartName }
    }}</span>
  </div>

  <div
    class="col-12 d-flex justify-content-between text-center cx-dialog-body modal-body content-body body-text-demi-xl"
  >
    <button class="col-6 btn btn-primary order-2" (click)="confirmDelete()">
      {{ 'fffSavedCarts.deleteCart' | cxTranslate }}
    </button>
    <button class="col-6 btn btn-secondary mr-2 order-1" (click)="close()">
      {{ 'common.cancel' | cxTranslate }}
    </button>
  </div>
</div>
