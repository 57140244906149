import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { InterceptorUtil, OCC_USER_ID_ANONYMOUS, OccEndpointsService, USE_CLIENT_TOKEN } from '@spartacus/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { END_POINTS } from 'src/app/fff-config/fff-endpoints.config';
import { ValidateFFFAccountData, ValidateFFFAccountResponse } from 'src/app/models/fff-register-data.model';

@Injectable({
  providedIn: 'root',
})
export class RegisterService {

  registerEmail = new BehaviorSubject('');

  getRequestHeaders(userId: string): HttpHeaders {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    if (userId === OCC_USER_ID_ANONYMOUS) {
      headers = InterceptorUtil.createHeader(USE_CLIENT_TOKEN, true, headers);
    }
    return headers;
  }

  constructor(
    private http: HttpClient,
    protected occEndpoints: OccEndpointsService
  ) {}

  register(request: ValidateFFFAccountData): Observable<any> {
    const url = this.occEndpoints.buildUrl(END_POINTS.REGISTER);
    return this.http.post(url, request, { headers: this.getRequestHeaders('anonymous') });
  }

  resendEmail(email: string): Observable<any> {
    const url = `${this.occEndpoints.buildUrl(END_POINTS.RESEND_EMAIL)}/${email}`;
    return this.http.post(url, {}, { headers: this.getRequestHeaders('anonymous') });
  }

  registerVerifyUserEmail(emailToken: any) {
    const url = `${this.occEndpoints.buildUrl(END_POINTS.REGISTER_VERIFY_EMAIL)}?token=${emailToken}`;
    return this.http.get(url, { headers: this.getRequestHeaders('') });
  }

  verifyUserEmail(emailToken: any) {
    const DEFAULT_URL: string = END_POINTS.VERIFYEMAIL;
    let url = this.occEndpoints.buildUrl(DEFAULT_URL);
    url += '?token=' + emailToken;
    const headers = this.getRequestHeaders('anonymous');
    return this.http.get(url, { headers: headers });
  }

  validateFFFAccount(accountUid: string): Observable<ValidateFFFAccountResponse> {
    const url = `${this.occEndpoints.buildUrl(END_POINTS.VALIDATE_FFF_ACCOUNT)}/${accountUid}`;
    return this.http.get(url, { headers: this.getRequestHeaders('') });
  }

  getAfterLoginMessage(): Observable<any> {
    const getLoginMessageUrl = this.occEndpoints.buildUrl(END_POINTS.LOGIN_MSG_URL);
    return this.http.get(getLoginMessageUrl);
  }

  popOutMessage(msgCode: string) {
    const url = this.occEndpoints.buildUrl(`users/current/messages/${msgCode}/saveViewedMessage`);
    console.log(url);
    return this.http.put(url, {}, { headers: this.getRequestHeaders('') });
  }

}
