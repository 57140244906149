import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { NgbPopover } from '@ng-bootstrap/ng-bootstrap';
import { ICON_TYPE } from '@spartacus/storefront';
import { Observable, Subject, Subscription, of, timer } from 'rxjs';
import { debounce, first, switchMap, tap } from 'rxjs/operators';
import { animationTime } from '../../fff-config/content/constants';
import { OUTLINED_ICON_TYPE } from '../../models/fff-outline-icons.model';
import { DrawerMetadata } from './fff-drawer.model';
import { FffDrawerService } from './fff-drawer.service';

@Component({
  selector: 'fff-drawer',
  templateUrl: 'fff-drawer.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FffDrawerComponent implements AfterViewInit, OnInit, OnDestroy {
  iconTypes = ICON_TYPE;
  outlinedIconTypes = OUTLINED_ICON_TYPE;
  @ViewChild('popover')
  popover!: NgbPopover;

  content$!: Observable<DrawerMetadata>;
  closePopoverSubject$ = new Subject();
  subscriptions = new Subscription();
  contentSubscription = new Subscription();
  private searchFieldValueOutputDelay = animationTime;
  cssClass!: String;

  constructor(
    protected drawerService: FffDrawerService,
    private el: ElementRef
  ) {}

  public openPop(): void {
    this.drawerService.openDrawer();
  }

  public closePopover(): void {
    this.drawerService.closeDrawer();
  }

  ngOnInit(): void {
    this.content$ = this.drawerService.getContent();
    this.contentSubscription = this.content$.subscribe(data => {
      this.cssClass = data.class ? data.class.toString() : '';
      if (data?.drawerClass) {
        this.el.nativeElement?.classList?.add(data.drawerClass);
      } else {
        this.el.nativeElement?.removeAttribute('class');
      }
    });
  }

  ngAfterViewInit(): void {
    this.drawerService.setPopover(this.popover);
    this.subscriptions.add(this.clickOutsideSubscription());
  }

  private clickOutsideSubscription(): Subscription {
    return this.drawerService.isDrawerOpen
      .pipe(
        debounce(() => timer(this.searchFieldValueOutputDelay)),
        switchMap(isOpen => {
          if (isOpen) {
            this.searchFieldValueOutputDelay = 0;
            return this.closePopoverSubject$.pipe(
              first(),
              tap(() => this.closePopover())
            );
          } else {
            this.searchFieldValueOutputDelay = animationTime;
            return of();
          }
        })
      )
      .subscribe();
  }

  clickOutside(): void {
    this.closePopoverSubject$.next(true);
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
    this.contentSubscription.unsubscribe();
  }
}
