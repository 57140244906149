export const fffContactUs = {
  fffContactUs: {
    contactUsForm: {
      firstName: 'First Name',
      lastName: 'Last Name',
      emailAddress: 'Email Address',
      accountNumber: {
        label: 'Account Number',
      },
      department: {
        label: 'Department',
        placeholder: 'Select a Department',
      },
      comments: 'Comments',
    },
    header: 'Contact Us',
    send: 'Send',
    immediateSupport: `<p>Need immediate support?</p>
    <p>Use our <a class="live-chat cursor-pointer text-underline">Live Chat</a> or call Wow! Customer Care <a class="phone-number cursor-pointer text-underline">(800) 843-7477</a></p>`,
  },
};

export const fffContactUsConfirmation = {
  fffContactUsConfirmation: {
    header: "We've Received Your Message",
    message:
      'An FFF representative will be with you within the next 24-48 hours.',
  },
};
