import { Component, OnInit } from '@angular/core';
import { EntryPoState, ENTRY_PO, OrderConfirmationState } from '@app/reducers';
import { Store } from '@ngrx/store';
import { Observable, of, Subject } from 'rxjs';


@Component({
  selector: 'fff-order-confirmation-message',
  templateUrl: './fff-order-confirmation-message.component.html',
})
export class FffOrderConfirmationMessageComponent implements OnInit {

  orderTypes = {
    standarOrder: 'STANDAR_ORDER',
    orderRequest: 'ORDER_REQUEST',
    splitBillingOrder: 'SPLIT_BILLING_ORDER',
    semiSplitBillingOrder: 'SEMI_SPLIT_BILLING_ORDER',
    recurringOrder: 'RECURRING_ORDER',
  };

  order$: Observable<any> = new Subject<any>();

  constructor(
    private storeEntryPo: Store<EntryPoState>,
    private store: Store<OrderConfirmationState>
  ) { }

  ngOnInit(): void {
    this.storeEntryPo.dispatch({ type: ENTRY_PO, payload: { key: 0, action: 'CLEAR', data: {} } });

    this.store.select((state: any) => {
      return state.OrderConfirmationState;
    }).subscribe(orderConfirmationData => {
      if (orderConfirmationData && orderConfirmationData.data) {
        this.order$ = of(orderConfirmationData.data);
      } else {
        this.order$ = of();
      }
    })
  }

  getOrderType(orderData: any): string {
    if (orderData.orders.length > 0 && orderData.quotes.length > 0) {
      return this.orderTypes.semiSplitBillingOrder;
    } else if (orderData.quotes.length > 0) {
      return this.orderTypes.splitBillingOrder;
    } else if (orderData.orderRequests.length > 0) {
      return this.orderTypes.orderRequest;
    } else {
      return this.orderTypes.standarOrder;
    }
  }

  getUserEmail(orderData: any): string {
    if (orderData.orders.length > 0) {
      return orderData.orders[0].order.user.uid;
    }
    return '';
  }

}


