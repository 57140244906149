<div class="container">
  <div
    *ngIf="profile$ | async as profile; else login"
    class="account-container d-flex flex-column align-items-end"
  >
    <ng-container>
      <div
        *ngIf="(isPrebookFormEnabled$ | async) ?? false; else nonPreBook"
        class="text-primary account-info-container"
      >
        <ng-container *ngIf="profile.selected">
          <div>{{ profile.selected.name }}</div>
          <div class="separator" style="display: none">-</div>
          <div>#{{ profile.selected.uid }}</div>
        </ng-container>
      </div>
      <ng-template #nonPreBook>
        <div class="greetings" *ngIf="profile.user">
          {{ miniCart.greetings }} {{ profile.user.firstName }}

          <fff-change-account-selector [isMobile]="false" [profile]="profile">
          </fff-change-account-selector>
          <fff-login-message-box></fff-login-message-box>
        </div>
        
        <fff-my-account
          *ngIf="navigationNode$ | async as data"
          [profile]="profile$ | async"
          [node]="{ title: '', children: [data] }"
          class="flyout"
        ></fff-my-account>
      </ng-template>
    </ng-container>
  </div>
  <ng-template #login>
    <button class="button-register" (click)="goToPage('register-account')">
      {{ miniCart.register }}
    </button>
    <button class="button-login" (click)="goToPage('login')">
      {{ miniCart.login }}
    </button>
  </ng-template>
</div>

<ng-template #loadingSpinner>
  <div class="loader-line__container">
    <div class="text-secondary">Loading...</div>
    <div class="loader-line"></div>
  </div>
</ng-template>
