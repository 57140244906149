import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthHttpHeaderService, AuthRedirectService, AuthService, AuthStorageService, BaseOccUrlProperties, GlobalMessageService, InterceptorUtil, OAuthLibWrapperService, OccEndpointsService, RoutingService, USE_CLIENT_TOKEN } from '@spartacus/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
/**
 * Custom Interceptor for integrating endpoints outside OCC
 */
export class FffCommunicationInterceptorService extends AuthHttpHeaderService {
  /**
   * Constructor
   * @param {authService} authService
   * @param {authStorageService} authStorageService
   * @param {oAuthLibWrapperService} oAuthLibWrapperService
   * @param {routingService} routingService
   * @param {occEndpoints} occEndpoints
   * @param {globalMessageService} globalMessageService
   * @param {authRedirectService} authRedirectService
   */
  constructor(
    protected httpClient: HttpClient,
    protected authService: AuthService,
    protected authStorageService: AuthStorageService,
    protected oAuthLibWrapperService: OAuthLibWrapperService,
    protected routingService: RoutingService,
    protected occEndpoints: OccEndpointsService,
    protected globalMessageService: GlobalMessageService,
    protected authRedirectService: AuthRedirectService,
    protected occEndpointsService: OccEndpointsService
  ) {
    super(authService, authStorageService, oAuthLibWrapperService, routingService, occEndpoints, globalMessageService, authRedirectService);
  }

  /**
   *
   * @param {url} url passed
   * @returns {boolean} boolean
   */
  protected isOccUrl(url: string): boolean {
    return url.includes(this.occEndpoints.getBaseUrl()) || url === 'my-custom-url';
  }

  /**
   * updated get call
   * @param {endpoint} endpoint
   * @param {updateUrl} updateUrl of type BaseOccUrlProperties
   * @param {headers} headers of type HttpHeaders
   * @returns {Observable<any>}
   */
  public getOcc(endpoint: string, updateUrl?: BaseOccUrlProperties, headers?: HttpHeaders): Observable<any> {
    return this.httpClient.get(this.occEndpointsService.buildUrl(endpoint, undefined, updateUrl), { headers: headers });
  }

  /**
   * updated post call
   * @param {endpoint} endpoint
   * @param {updateUrl} updateUrl of type BaseOccUrlProperties
   * @param {headers} headers of type HttpHeaders
   * @param {body} body of post calls
   * @returns {Observable<any>}
   *
   */
  public postOcc(endpoint: string, updateUrl?: BaseOccUrlProperties, headers?: HttpHeaders, body?: any): Observable<any> {
    return this.httpClient.post(this.occEndpointsService.buildUrl(endpoint, undefined, updateUrl), body, { headers: headers });
  }

  /**
   * updated patch call
   * @param {endpoint} endpoint
   * @param {updateUrl} updateUrl of type BaseOccUrlProperties
   * @param {headers} headers of type HttpHeaders
   * @param {body} body of post calls
   * @returns {Observable<any>}
   *
   */
  public patchOcc(endpoint: string, updateUrl?: BaseOccUrlProperties, headers?: HttpHeaders, body?: any): Observable<any> {
    return this.httpClient.patch(this.occEndpointsService.buildUrl(endpoint, undefined, updateUrl), body, { headers: headers });
  }

  /**
   * get HttpHeaders for OCC calls
   * @returns {HttpHeaders}
   */
  public updateHeader(): HttpHeaders {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    headers = InterceptorUtil.createHeader(USE_CLIENT_TOKEN, true, headers);
    return headers;
  }
}
