import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { OccEndpointsService } from '@spartacus/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class FffProductsService {
  // id & boolean to display or not the badge
  private isContractProduct = new Subject<[string, boolean]>();
  constructor(
    private http: HttpClient,
    protected occEndpoints: OccEndpointsService
  ) {}
  getIsContractProduct() {
    return this.isContractProduct.asObservable();
  }
  emitItIsContractProduct(productCode: string, value: boolean): void {
    this.isContractProduct.next([productCode, value]);
  }
  getRequestHeaders(): HttpHeaders {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    return headers;
  }

  getSuggestedProducts(cartId: string): Observable<any> {
    const url = this.occEndpoints.buildUrl(
      `/orgUsers/current/carts/${cartId}/suggestions/?fields=DEFAULT&isFilterPurchased=true&maxNumber=20`
    );
    return this.http.get(url, { headers: this.getRequestHeaders() });
  }

  // getAppProperties() {
  //   const url = this.occEndpoints.buildUrl(`applicationProperties`);
  //   return this.http.get(url, { headers: this.getRequestHeaders() });
  // }
}
