import { DeferLoadingStrategy } from '@spartacus/core';
import { LayoutConfig } from '@spartacus/storefront';

export const customLayoutConfig: LayoutConfig = {
  deferredLoading: {
    strategy: DeferLoadingStrategy.INSTANT,
    intersectionMargin: '50px',
  },

  layoutSlots: {
    header: {
      lg: {
        slots: [
          'PreHeader',
          'SiteContext',
          'SiteLinks',
          'SiteLogo',
          'SearchBox',
          'SiteLogin',
          'MiniCart',
          'NavigationBar',
        ],
      },
      slots: ['PreHeader', 'SiteLogo', 'SearchBox', 'MiniCart'],
    },
    simplifiedHeader: {
      slots: ['SiteLogo'],
    },
    LandingPage2Template: {
      pageFold: 'Section1',
      slots: [
        'Section1A', // Order Cut Off Component
        'Section1',
        'Section2',
        'Section2A',
        'Section2B',
        'Section2C',
        'Section2D',
        'Section3',
        'Section4',
        'Section5',
        'ExtendedFooterNavNode',
      ],
    },
    ContentPage2Template: {
      slots: ['Section1', 'Section2', 'Section3', 'Section4'],
    },
    ProductListPageTemplate: {
      pageFold: 'ProductListSlot',
      header: {
        lg: {
          slots: [
            'PreHeader',
            'SiteContext',
            'SiteLinks',
            'SiteLogo',
            'SearchBox',
            'SiteLogin',
            'MiniCart',
            'NavigationBar',
            'PrebookNavigationBar',
            'PrebookRecommendationSection',
          ],
        },
        slots: [
          'PreHeader',
          'SiteLogo',
          'SearchBox',
          'MiniCart',
          'PrebookNavigationBar',
          'PrebookRecommendationSection',
          'SiteLogin',
        ],
      },
      slots: ['ProductLeftRefinements', 'ProductListSlot'],
    },
    CartPageTemplate: {
      header: {
        lg: {
          slots: [
            'PreHeader',
            'SiteContext',
            'SiteLinks',
            'SiteLogo',
            'SearchBox',
            'SiteLogin',
            'MiniCart',
            'NavigationBar',
            'PrebookNavigationBar',
          ],
        },
        slots: [
          'PreHeader',
          'SiteLogo',
          'SearchBox',
          'MiniCart',
          'PrebookNavigationBar',
          'SiteLogin',
        ],
      },
      slots: ['BottomContentSlot'],
    },
    ProductDetailsPageTemplate: {
      slots: ['Summary', 'Tabs'],
    },
    AccountPageTemplate: {
      slots: ['BodyContent', 'SideContent', 'ReportsList'],
    },
  },
};
