import {
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import {
  MAX_DIFF_DAYS,
  NINETY_DAYS,
  THIRTY_DAYS,
} from '@app/fff-config/content/constants';
import { FffProductShortDatedPopupComponent } from '@app/fff-enterprise/fff-product/fff-product-short-dated-popup/fff-product-short-dated-popup.component';
import { OUTLINED_ICON_TYPE } from '@app/models/fff-outline-icons.model';
import {
  PRODUCT_SPECIAL_PRICE,
  PRODUCT_STRENGTH,
  ProductSpecialPriceState,
  ProductStrengthState,
} from '@app/reducers';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { TranslationService } from '@spartacus/core';

@Component({
  selector: 'fff-product-list-actual-strength',
  templateUrl: './fff-product-list-actual-strength.component.html',
})
export class FffProductListActualStrengthComponent
  implements OnInit, OnChanges
{
  @Input() product: any | undefined;
  @Input() stockData: any | undefined;

  outlinedIconTypes = OUTLINED_ICON_TYPE;
  dataList: any[] = [];
  specialPriceList: any[] = [];
  mapPrices: any = {};

  selectedOption: string = '';
  selectedStrength: any = {};
  selectedPriceOption: any = {};
  MAX_DAYS_SHORT_DATED: number = 121;
  dropdownDisabled: boolean = false;

  private ZERO: number = 0;
  private MAX_DIFF_DAYS: number = MAX_DIFF_DAYS;
  private THIRTY_DAYS: number = THIRTY_DAYS;
  private NINETY_DAYS: number = NINETY_DAYS;

  constructor(
    private storeProductStrength: Store<ProductStrengthState>,
    private storeProductSpecialPrice: Store<ProductSpecialPriceState>,
    private translationService: TranslationService,
    private modalService: NgbModal,
    public cd: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.translationService
      .translate('fffStrength.defaultOption')
      .subscribe(text => (this.selectedOption = text));

    if (this.stockData && this.stockData.results) {
      const dataList = this.stockData.results;
      dataList.forEach((stock: any) => {
        if (stock.custToMatInv.results) {
          stock.custToMatInv.results.forEach((data: any) => {
            if (data.batchStrength) {
              if (!this.mapPrices[data.batchStrength]) {
                this.mapPrices[data.batchStrength] = { data: data, values: [] };
              }
              this.mapPrices[data.batchStrength].values.push(data);
            }
          });

          stock.custToMatInv.results.forEach((r: any) => {
            if (r.expirationDate) {
              const parsedExpirationDate = this.parseDate(r.expirationDate);
              r.parsedExpirationDate = parsedExpirationDate;
              r.diffDays = this.getDiffDays(new Date(), parsedExpirationDate);
              r.diffDays = parseInt(r.diffDays);
              r.discountPercent = this.getDiscountPercent(r.diffDays);
            }
          });
        }
      });

      for (const key in this.mapPrices) {
        this.dataList.push(key);
      }

      this.cd.detectChanges();
    }

    if (!this.product.assayIndicator) {
      const allBatches: any[] = [];
      for (const key in this.mapPrices) {
        const currentStrenght = this.mapPrices[key];
        currentStrenght.values.forEach((batch: any) => {
          allBatches.push(batch);
        });
      }

      this.createPriceList(allBatches);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.stockData || this.product) {
      this.handleInitialSelection();
    }
  }

  handleInitialSelection() {
    setTimeout(() => {
      if (this.product?.assayIndicator && this.dataList.length === 1) {
        this.dropdownDisabled = true;
        this.selectOption(this.dataList[0]);
        this.cd.markForCheck();
      }
    }, 300);
  }

  selectOption(option: any): void {
    if (this.mapPrices[option]) {
      this.selectedStrength = this.mapPrices[option];

      if (this.selectedOption != option) {
        let defaultBatch = '';
        let isCallPriceApi: boolean =
          this.product.assayIndicator &&
          this.selectedStrength.values.length > 0 &&
          !(
            this.getDaysLessMaxDiffDays(this.selectedStrength.values).length > 0
          );

        if (isCallPriceApi) {
          defaultBatch = this.selectedStrength.values[0].batch;
        }
        let totalStockAvailable = 0;

        for (let is = 0; is < this.selectedStrength.values.length; is++) {
          const element = this.selectedStrength.values[is];
          if (element.batchStock) {
            totalStockAvailable += parseInt(element.batchStock);
          }
        }

        this.selectPriceOption({
          discountPercent: 0,
          batch: 'undefined',
          callPriceApi: isCallPriceApi,
          defaultBatch: defaultBatch,
          totalStockAvailable: totalStockAvailable,
        });
      }

      this.selectedOption = option;

      this.storeProductStrength.dispatch({
        type: PRODUCT_STRENGTH,
        payload: { key: this.product.code, data: this.selectedStrength },
      });

      this.createPriceList(this.selectedStrength.values);
    }
  }

  private getDaysLessMaxDiffDays(specialPricingList: []): any[] {
    return specialPricingList.filter(
      (data: any) => data.diffDays <= this.MAX_DIFF_DAYS
    );
  }

  createPriceList(specialPricingList: any): void {
    if (
      specialPricingList.filter(
        (data: any) => data.diffDays <= this.MAX_DIFF_DAYS
      )?.length > 0
    ) {
      this.specialPriceList = specialPricingList;
    } else {
      this.specialPriceList = [];
    }

    if (this.product?.requireBatchSelect) {
      this.specialPriceList = specialPricingList;
    }
  }

  selectPriceOption(option: any): void {
    this.selectedPriceOption = option;

    this.storeProductSpecialPrice.dispatch({
      type: PRODUCT_SPECIAL_PRICE,
      payload: { key: this.product.code, data: option },
    });

    if (
      option.batch &&
      option.batch !== 'undefined' &&
      option.diffDays <= this.MAX_DIFF_DAYS
    ) {
      this.showSelectedSpecialPricePopUp();
    }
  }

  showSelectedSpecialPricePopUp() {
    const modalRef = this.modalService.open(
      FffProductShortDatedPopupComponent,
      {
        centered: true,
        size: 'md',
        backdrop: 'static',
        modalDialogClass: 'modal-container',
      }
    );
    modalRef.componentInstance.product = this.product;
  }

  parseDate(timestampString: string) {
    let res = timestampString.match(/[^/Date(\[](.*)[^\])/]/g);
    const timestamp = parseInt(res![0]);
    return new Date(timestamp);
  }

  getDiffDays(dateFrom: Date, dateTo: Date): number {
    this.clearDateVales(dateFrom);
    this.clearDateVales(dateTo);
    var difference = dateTo.getTime() - dateFrom.getTime();
    return difference / (1000 * 3600 * 24);
  }

  private clearDateVales(date: Date): Date {
    date.setHours(0, 0, 0, 0);
    return date;
  }

  getDiscountPercent(diffDays: number): number {
    if (diffDays >= this.ZERO && diffDays <= this.THIRTY_DAYS) {
      return 50;
    } else if (diffDays > this.THIRTY_DAYS && diffDays <= this.NINETY_DAYS) {
      return 25;
    } else if (diffDays > this.NINETY_DAYS && diffDays <= this.MAX_DIFF_DAYS) {
      return 10;
    } else {
      return this.ZERO;
    }
  }

  getDaysDiscount(diffDays: number): number {
    if (diffDays >= this.ZERO && diffDays <= this.THIRTY_DAYS) {
      return this.THIRTY_DAYS;
    } else if (diffDays > this.THIRTY_DAYS && diffDays <= this.NINETY_DAYS) {
      return this.NINETY_DAYS;
    } else if (diffDays > this.NINETY_DAYS && diffDays <= this.MAX_DIFF_DAYS) {
      return this.MAX_DIFF_DAYS;
    } else {
      return this.ZERO;
    }
  }
}
