import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PreBookStages } from '@app/shared/services/enum.service';

@Component({
  selector: 'fff-prebook-title',
  templateUrl: './fff-prebook-title.component.html',
  styleUrls: ['./fff-prebook-title.component.scss'],
})
export class FffPrebookTitleComponent {
  seasonYear: number | undefined;
  vaccineLabelDesc: string = '';
  season: string = '';

  constructor(private activatedRoute: ActivatedRoute) {}

  ngOnInit(): void {
    this.activatedRoute.paramMap.subscribe(params => {
      const pageId = params.get('category') || 'vaccines-influenza';
      switch (pageId) {
        case PreBookStages.flu: {
          this.vaccineLabelDesc = 'Season Influenza Order';
          break;
        }
        case PreBookStages.covid: {
          this.vaccineLabelDesc = 'Covid 19 Order';
          break;
        }
        case PreBookStages.rsv: {
          this.vaccineLabelDesc = 'Season RSV Order';
          break;
        }
        default: {
          this.vaccineLabelDesc = 'Season Influenza Order';
          return;
        }
      }
    });
  }
}
