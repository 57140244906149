<div class="drawer-header p-2">
  <fff-update-interactive-allocations-header [amountAvailableToReallocate]="data.amountAvailableToReallocate"
    [productCode]="productCode" [productName]="productName"></fff-update-interactive-allocations-header>
</div>

<div class="drawer-body p-0">
  <fff-update-interactive-allocations-body [data]="data"></fff-update-interactive-allocations-body>
</div>

<div class="drawer-footer">
  <fff-update-interactive-allocations-footer [data]="data"></fff-update-interactive-allocations-footer>
</div>