import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { OccEndpointsService } from '@spartacus/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class FffCartService {
  constructor(
    private http: HttpClient,
    protected occEndpoints: OccEndpointsService
  ) {}

  getRequestHeaders(): HttpHeaders {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    return headers;
  }

  addEntry(userId: string, cartId: string, data: any): Observable<any> {
    const url = this.occEndpoints.buildUrl(
      `/orgUsers/${userId}/carts/current/entries/`
    );
    return this.http.post(url, data, { headers: this.getRequestHeaders() });
  }

  updateEntry(
    userId: string,
    cartId: string,
    entryNumber: number,
    data: any
  ): Observable<any> {
    const url = this.occEndpoints.buildUrl(
      `/orgUsers/${userId}/carts/${cartId}/entries/${entryNumber}`
    );
    return this.http.patch(url, data, { headers: this.getRequestHeaders() });
  }

  deleteEntry(
    userId: string,
    cartId: string,
    entryNumber: number
  ): Observable<any> {
    const url = this.occEndpoints.buildUrl(
      `/orgUsers/${userId}/carts/${cartId}/entries/${entryNumber}`
    );
    return this.http.delete(url, { headers: this.getRequestHeaders() });
  }

  addCartEntry(cartId: string, data: any): Observable<any> {
    const url = this.occEndpoints.buildUrl(
      `/orgUsers/current/carts/${cartId}/entries/`
    );
    return this.http.post(url, data, { headers: this.getRequestHeaders() });
  }

  getActiveCart(userId: string, cartId: string): Observable<any> {
    const url = this.occEndpoints.buildUrl(`/users/${userId}/carts/${cartId}`);
    return this.http.get(url, { headers: this.getRequestHeaders() });
  }

  getOrRestoreCart(userId: string): Observable<any> {
    const url = this.occEndpoints.buildUrl(`/users/${userId}/carts`);
    return this.http.post(url, {}, { headers: this.getRequestHeaders() });
  }

  getEntries(userId: string, cartId: string): Observable<any> {
    const url = this.occEndpoints.buildUrl(
      `/users/${userId}/carts/${cartId}/entries`
    );
    return this.http.get(url, { headers: this.getRequestHeaders() });
  }

  removeCart(userId: string, cartId: string) {
    const url = this.occEndpoints.buildUrl(`/users/${userId}/carts/${cartId}`);
    return this.http.delete(url, { headers: this.getRequestHeaders() });
  }

  getUpdatedCartATP(cartId: string): Observable<any> {
    const url = this.occEndpoints.buildUrl(
      `/orgUsers/current/carts/${cartId}/atp`
    );
    return this.http.get(url);
  }

  markCartAsPrebook(cartId: string, prebookCart: boolean): Observable<any> {
    const url = this.occEndpoints.buildUrl(
      `/orgUsers/current/carts/${cartId}?prebookCart=${prebookCart}`
    );
    return this.http.patch(url, {});
  }
}
