import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FffSharedDirectivesModule } from '@app/shared/directives/fff-shared-directives/fff-shared-directives.module';
import { FffSharedPipesModule } from '@app/shared/pipes/fff-shared-pipes/fff-shared-pipes.module';
import { AuthGuard, ConfigModule, I18nModule } from '@spartacus/core';
import {
  FormErrorsModule,
  IconModule,
  SpinnerModule,
} from '@spartacus/storefront';
import { FffAddCreditCardModule } from '../fff-invoice-history/fff-add-credit-card/fff-add-credit-card.module';
import { FffEditCreditCardModule } from '../fff-invoice-history/fff-edit-credit-card/fff-edit-credit-card.module';
import { FFFCsosVerifyAccountPopupComponent } from './fff-csos-verify-account-popup/fff-csos-verify-account-popup.component';
import { FFFCsosVerifyUserPopupComponent } from './fff-csos-verify-user-popup/fff-csos-verify-user-popup.component';
import { FffProfileDetailAccountComponent } from './fff-profile-detail-account/fff-profile-detail-account.component';
import { FffProfileDetailCsosComponent } from './fff-profile-detail-csos/fff-profile-detail-csos.component';
import { FffProfileDetailPersonalComponent } from './fff-profile-detail-personal/fff-profile-detail-personal.component';
import { FffProfileManagementComponent } from './fff-profile-management.component';
import { FffProfileReportsTileComponent } from './fff-profile-reports-tile/fff-profile-reports-tile.component';
import { FffProfileSwitchAccountComponent } from './fff-profile-switch-account/fff-profile-switch-account.component';
import { FffDeleteCardSuccessMessageComponent } from './fff-profile-wallet/fff-delete-card-success-message/fff-delete-card-success-message.component';
import { FffDeleteSavedCardConfirmationPopupComponent } from './fff-profile-wallet/fff-delete-saved-card-confirmation-popup/fff-delete-saved-card-confirmation-popup.component';
import { FffProfileWalletComponent } from './fff-profile-wallet/fff-profile-wallet.component';
import { FffUpdatePersonalInfoComponent } from './fff-update-personal-info/fff-update-personal-info.component';

@NgModule({
  declarations: [
    FffProfileManagementComponent,
    FffUpdatePersonalInfoComponent,
    FffProfileSwitchAccountComponent,
    FffProfileDetailAccountComponent,
    FffProfileDetailPersonalComponent,
    FffProfileDetailCsosComponent,
    FFFCsosVerifyAccountPopupComponent,
    FFFCsosVerifyUserPopupComponent,
    FffProfileReportsTileComponent,
    FffProfileWalletComponent,
    FffDeleteSavedCardConfirmationPopupComponent,
    FffDeleteCardSuccessMessageComponent,
  ],
  imports: [
    CommonModule,
    ConfigModule.withConfig({
      cmsComponents: {
        UpdateProfileComponent: {
          component: FffProfileManagementComponent,
          guards: [AuthGuard],
        },
      },
    }),
    IconModule,
    I18nModule,
    ReactiveFormsModule,
    FormsModule,
    FormErrorsModule,
    IconModule,
    SpinnerModule,
    FffSharedPipesModule,
    FffSharedDirectivesModule,
    FffAddCreditCardModule,
    FffEditCreditCardModule,
  ],
  exports: [
    FFFCsosVerifyAccountPopupComponent,
    FFFCsosVerifyUserPopupComponent,
  ],
})
export class FffProfileManagementModule {}
