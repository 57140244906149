<div class="refinement-filter">

  <ng-container *ngIf="(visibleFacets$ | async) as visibleFacets">
    <div class="facet-list" *ngIf="visibleFacets">
      <div class="facet-info" *ngFor="let facet of facets">

        <div class="col-12 facet-name d-flex flex-wrap" (click)="facet.expanded = !facet.expanded"
          [attr.aria-expanded]="!facet.expanded" aria-controls="collapseFilter"
          [class.facet-name-open]="!facet.expanded">
          <span class="facet-name-btn d-flex">
            {{ facet?.name }}
            <p *ngIf="getItemsSelectedCount(facet?.name, filtersSelected) > 0"
              class="ml-2 mb-0 p-0 text-center align-self-center text-white facet-count">{{
              getItemsSelectedCount(facet?.name, filtersSelected) }}</p>
          </span>

          <cx-icon *ngIf="facet.expanded" class="cx-icon d-flex ml-auto cursor-pointer"
            [type]="outlinedIconTypes.ICON_CHEVRON_DOWN">
          </cx-icon>
          <cx-icon *ngIf="!facet.expanded" class="cx-icon d-flex ml-auto cursor-pointer"
            [type]="outlinedIconTypes.ICON_CHEVRON_UP">
          </cx-icon>
        </div>

        <div #collapse="ngbCollapse" [ngbCollapse]="isExpanded(facet.expanded)" class="facet-value-list">

          <ng-container
            *ngFor="let facetInfo of getFacetValues(facet?.values!, facet.showMore!, facet.moreFiveItems!) ">
            <div class="col-12 facet-value">
              <input class="checkbox checkbox-md" type="checkbox" [checked]="facetInfo?.selected"
                (change)="toggleValue(facetInfo?.query?.query?.value)" id="{{ facetInfo?.name }}"
                name="{{ facetInfo?.name }}" />

              <label class="checkbox-label" for="{{ facetInfo?.name }}">
                {{ facetInfo?.name }}
              </label>
            </div>
          </ng-container>

          <div class="col-12 facet-value cursor-pointer" *ngIf="facet.moreFiveItems"
            (click)="facet.showMore = !facet.showMore">
            <span class="showMore d-flex" *ngIf="!facet.showMore">{{ 'fffFacetFilter.viewMore' | cxTranslate }} <cx-icon
                class="cx-icon icon-sm pl-1" [type]="outlinedIconTypes.ICON_CHEVRON_DOWN"> </cx-icon></span>
            <span class="showMore d-flex" *ngIf="facet.showMore">{{ 'fffFacetFilter.viewLess' | cxTranslate }} <cx-icon
                class="cx-icon icon-sm pl-1" [type]="outlinedIconTypes.ICON_CHEVRON_UP"></cx-icon></span>
          </div>

        </div>

      </div>
    </div>
  </ng-container>

</div>