import { Component } from '@angular/core';
import { OUTLINED_ICON_TYPE } from '@app/models/fff-outline-icons.model';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'fff-need-more-dialog',
  templateUrl: './fff-need-more-dialog.component.html',
})
export class FffNeedMoreDialogComponent {
  isHardAllocation: any;
  isPGX: any;
  outlinedIconTypes = OUTLINED_ICON_TYPE;

  constructor(private modalService: NgbActiveModal) { }

  close() {
    this.modalService.dismiss();
  }

}
