import { NgModule } from '@angular/core';
import { SavedCartComponentsModule } from '@spartacus/cart/saved-cart/components';
import { SavedCartOccModule } from '@spartacus/cart/saved-cart/occ';
import { FffSavedCartCoreModule } from './saved-cart-core.module';

@NgModule({
  imports: [
    FffSavedCartCoreModule,
    SavedCartOccModule,
    SavedCartComponentsModule,
  ],
})
export class FffSavedCartModule {}
