import { Component } from '@angular/core';
import { OUTLINED_ICON_TYPE } from '@app/models/fff-outline-icons.model';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-fff-delete-card-success-message',
  templateUrl: './fff-delete-card-success-message.component.html',
})
export class FffDeleteCardSuccessMessageComponent {
  showMessage: boolean = true;
  cardToken!: string;
  cardEndingDigits!: string;
  outlineIcons = OUTLINED_ICON_TYPE;

  constructor(protected modalService: NgbActiveModal) {}

  ngOnInit(): void {
    if (this.cardToken && this.cardToken.length > 12) {
      this.cardEndingDigits = this.cardToken.slice(-4);
    }
    setTimeout(() => {
      this.showMessage = false;
      this.modalService.close();
    }, 5000);
  }
}
