import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActiveCartFacade, Cart } from '@spartacus/cart/base/root';
import { GlobalMessageService, GlobalMessageType, User } from '@spartacus/core';
import { UserProfileFacade } from '@spartacus/user/profile/root';
import { Observable, Subscription } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { OUTLINED_ICON_TYPE } from 'src/app/models/fff-outline-icons.model';
import { FffCommunicationService } from '../../fff-common-services/fff-communication.service';
import { UnitNode } from '../../fff-util/fff-models/account-search.model';

@Component({
  selector: 'fff-search-result',
  templateUrl: './fff-search-result.component.html',
})
/**
 * Replaces AccountSearchFlexComponent
 */
export class FffSearchResultComponent implements OnInit, OnDestroy {
  public searchText = '';
  public data$: Observable<any> | undefined;
  public userDetails: User | undefined;
  outlinedIconTypes = OUTLINED_ICON_TYPE;
  private subscription: Subscription = new Subscription();
  cart!: Cart;

  /**
   * Constructor
   * @param {communicationService} communicationService is needed for getting account list
   */
  constructor(
    private communicationService: FffCommunicationService,
    private userProfileFacade: UserProfileFacade,
    private globalMessageService: GlobalMessageService,
    private fffActiveCartService: ActiveCartFacade
  ) {}

  ngOnInit(): void {
    this.data$ = this.userProfileFacade.get().pipe(
      switchMap(user => {
        this.userDetails = user;
        return this.communicationService.getAllB2BOrgUnitsForCustomer(user);
      })
    );
    this.subscription.add(
      this.fffActiveCartService.getActive().subscribe(cart => {
        this.cart = cart;
      })
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  /**
   * trigger Activation
   * @param {node} node of type UnitNode is required
   */
  public triggerActivate(node: UnitNode) {
    this.communicationService
      .postSwitchB2BUnit({
        uid: this.userDetails?.uid,
        activeId: node.id,
        cartCode: this.cart?.code,
      })
      .subscribe(data => {
        this.globalMessageService.add(
          data,
          GlobalMessageType.MSG_TYPE_INFO,
          5000
        );
      });
  }
}
