import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { FffCommunicationService } from '@app/fff-enterprise/fff-common-services/fff-communication.service';
import { OUTLINED_ICON_TYPE } from '@app/models/fff-outline-icons.model';
import { FffDrawerService } from '@app/shared/drawer/fff-drawer.service';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';

@Component({
  selector: 'fff-filter-approvals-history',
  templateUrl: './filter-approvals-history.component.html',
})
export class FilterApprovalsHistoryComponent implements OnInit {

  approverGroups$: Observable<any> = this.communicationService.getApproverGroups().pipe(take(1));
  requesterOptions: any = [];

  outlinedIconTypes = OUTLINED_ICON_TYPE;

  form: UntypedFormGroup = this.fb.group({
    b2bunit: [''],
    action: [],
  });

  constructor(
    public communicationService: FffCommunicationService,
    protected fb: UntypedFormBuilder,
    public drawerService: FffDrawerService
  ) { }

  ngOnInit(): void {
    let filters = localStorage.getItem('approvalHistoryFilters');
    if (filters) {
      let parsedFilters = JSON.parse(filters);
      this.form.patchValue(parsedFilters);
    }
  }

  apply(): void {
    let filters = this.form.value;
    localStorage.setItem('approvalHistoryFilters', JSON.stringify(filters));
    this.drawerService.closeDrawer(filters);
  }

}
