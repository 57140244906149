<ng-container *ngIf="activeSite$ | async">
  <ng-container *ngIf="timeLeft$">
    <ng-container *ngIf="timeLeft$ | async as cutoffMessage">
      <div class="order-cutoff-timer d-flex justify-content-center"
           *ngIf="cutoffMessage.content && cutoffMessage.timeEnd === false">
        <cx-icon [type]="outlinedIconTypes.ICON_CLOCK"></cx-icon>
        <span [innerHTML]="cutoffMessage.content"></span>
      </div>
    </ng-container>
  </ng-container>
</ng-container>
