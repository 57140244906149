import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FffTooltipModule } from '@app/shared/tooltip/tooltip.module';
import { FffRecurringOrderListModalComponent } from '@app/fff-enterprise/fff-recurring-orders/fff-recurring-order-list-modal/fff-recurring-order-list-modal.component';
import { FffRecurringOrdersDetailsComponent } from '@enterprise/fff-recurring-orders/fff-recurring-orders-details/fff-recurring-orders-details.component';
import { FffRecurringOrdersListComponent } from '@enterprise/fff-recurring-orders/fff-recurring-orders-list/fff-recurring-orders-list.component';
import { FffReturnsModule } from '@enterprise/fff-returns/fff-returns.module';
import { FffTableModule } from '@shared/table/fff-table.module';
import {
  AuthGuard,
  CmsConfig,
  I18nModule,
  provideDefaultConfig,
} from '@spartacus/core';
import {
  FormErrorsModule,
  IconModule,
  MediaModule,
} from '@spartacus/storefront';
import { FFFLoadersModule } from '@app/shared/components/fff-loaders/fff-loaders.module';

@NgModule({
  declarations: [
    FffRecurringOrdersListComponent,
    FffRecurringOrdersDetailsComponent,
    FffRecurringOrderListModalComponent,
  ],
  imports: [
    I18nModule,
    CommonModule,
    FormsModule,
    FffTooltipModule,
    FffTableModule,
    IconModule,
    FffReturnsModule,
    FFFLoadersModule,
    MediaModule,
    FormErrorsModule,
    ReactiveFormsModule,
  ],
  providers: [
    provideDefaultConfig(<CmsConfig>{
      cmsComponents: {
        FFFRecurringOrdersListComponent: {
          component: FffRecurringOrdersListComponent,
          guards: [AuthGuard],
        },
        FFFRecurringOrderDetailsComponent: {
          component: FffRecurringOrdersDetailsComponent,
          guards: [AuthGuard],
        },
      },
    }),
  ],
})
export class FffRecurringOrdersModule {}
