import { Injectable, TemplateRef } from '@angular/core';
import { TranslationService } from '@spartacus/core';
import { take } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class ToastService {
  toasts: any[] = [];
  toast: any;

  constructor(private i18n: TranslationService) {}

  show(textOrTpl: string | TemplateRef<any>, options: any = {}) {
    this.toasts.push({ textOrTpl, ...options });
  }

  clear() {
    this.toasts.splice(0, this.toasts.length);
    this.toast = null;
  }

  showSuccess(textOrTpl: string | TemplateRef<any>, options: any = {}) {
    if (typeof textOrTpl === 'string') {
      this.i18n
        .translate(textOrTpl, options.i18nParams || {})
        .pipe(take(1))
        .subscribe(text => {
          this.toast = {
            textOrTpl: text,
            success: true,
            delay: 5000,
            ...options,
          };
        });
      return;
    }

    this.toast = {
      textOrTpl,
      success: true,
      delay: 5000,
      ...options,
    };
  }
}
