import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { FFFLoadersModule } from '@app/shared/components/fff-loaders/fff-loaders.module';
import { FffInputValidationUtilsModule } from '@app/shared/components/input-validation-utils/input-validation-utils.module';
import { FffTooltipModule } from '@app/shared/tooltip/tooltip.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { I18nModule } from '@spartacus/core';
import { IconModule } from '@spartacus/storefront';
import { FffSplitBillingComponent } from './fff-split-billing.component';

@NgModule({
  declarations: [FffSplitBillingComponent],
  imports: [
    CommonModule,
    I18nModule,
    FormsModule,
    IconModule,
    NgbModule,
    FffTooltipModule,
    FFFLoadersModule,
    FffInputValidationUtilsModule,
  ],
  exports: [FffSplitBillingComponent],
})
export class FffSplitBillingModule {}
