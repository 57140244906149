import { Injectable } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class FffSaveCartDrawerService {

  saveCartForm = new BehaviorSubject<UntypedFormGroup | undefined>(undefined);

  constructor() { }


}
