import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { fffCSOSStatusOccEndpointsConfig } from '@app/fff-config/fff-endpoints.config';
import { OccEndpointsService } from '@spartacus/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FffCsosStatusService {

  getRequestHeaders(): HttpHeaders {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    return headers;
  }

  constructor(private http: HttpClient, protected occEndpoints: OccEndpointsService) { }

  getCsosStatus(): Observable<any> {
    const url = this.occEndpoints.buildUrl(fffCSOSStatusOccEndpointsConfig.csosOrders);
    return this.http.get(url, { headers: this.getRequestHeaders() });
  }
}
