import { Component, OnInit } from '@angular/core';
import { TranslationService } from '@spartacus/core';

@Component({
  selector: 'fff-global-notification',
  templateUrl: './fff-global-notification.component.html',
})
export class FffGlobalNotificationComponent implements OnInit {

  message: string = '';

  constructor(
    public translationService: TranslationService
  ) {}

  ngOnInit() {
    this.translationService.translate('text.fff.notification').subscribe(res => {
      if (!(res === 'text.fff.notification' || res === null)) {
         this.message = res;
      }
    });
  }
}
