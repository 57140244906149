<div class="footer-container text-center mt-5">
  <div class="row">
    <div class="col-12">
      <ng-container *ngIf="route$ | async as route;else noRoute">
        <span class="body-text-xl">{{'fffReturnsFooter.questionsOrConcerns' | cxTranslate}}</span>
        <ng-container *ngIf="route.state.url.includes('return');else displayRecurringOrder">
          <span class="body-text-xl">{{'fffReturnsFooter.return' | cxTranslate}}</span>
        </ng-container>
        <ng-template #displayRecurringOrder>
          <span class="body-text-xl">{{'fffReturnsFooter.recurringOrder' | cxTranslate}}</span>
        </ng-template>
      </ng-container>
      <ng-template #noRoute>
        <span class="body-text-xl">{{'fffReturnsFooter.questionsOrConcerns' | cxTranslate}}</span>
        <span class="body-text-xl">{{'fffReturnsFooter.return' | cxTranslate}}</span>
      </ng-template>

    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <span class="body-text-xl">{{'fffReturnsFooter.contactWowCustomerCare' | cxTranslate}}</span>
      <button (click)="openLiveChat()" class="body-text-xl p-0 border-0 text-underline">{{'fffReturnsFooter.chat' | cxTranslate}}</button>
      <span  class="body-text-xl">&nbsp;{{'fffReturnsFooter.orByCalling' | cxTranslate}}</span>
    </div>
  </div>
</div>

