<ng-container *ngIf="profile$ | async as profile">
  <div class="pt-0 approvals-component" *ngIf="carts">
    <div class="d-flex">
      <h3 class="pr-4">{{ 'fffApprovals.title' | cxTranslate }}</h3>
      <button class="btn btn-secondary btn-xs" (click)="openFilterDrawer()">
        {{ 'fffOrderHistory.filter' | cxTranslate }}
        <cx-icon class="cx-icon icon-sm" [type]="outlinedIconTypes.ICON_FILTER">
        </cx-icon>
      </button>
    </div>

    <hr class="sep-table-title" />

    <div class="d-flex account-nav-content pb-5">
      <span
        class="item mx-3 cursor-pointer"
        [class.selected]="'pendingApproval' === selectedTab"
        (click)="selectedTab = 'pendingApproval'"
        >{{ 'fffApprovals.tabName' | cxTranslate }}</span
      >
      <span
        class="item mx-3 cursor-pointer"
        [class.selected]="'approvalHistory' === selectedTab"
        (click)="selectedTab = 'approvalHistory'"
        >{{ 'fffApprovalsHistory.tabName' | cxTranslate }}</span
      >
    </div>

    <div *ngIf="'pendingApproval' === selectedTab">
      <ng-container *ngIf="!approvalTableLoading; else tableLoading">
        <ng-container *ngIf="!!carts?.length">
          <!--mobile view-->
          <ngb-accordion
            #a="ngbAccordion"
            [activeIds]="activeIds"
            class="mobile-approvals"
          >
            <ngb-panel
              *ngFor="let cart of carts; let cartIndex = index"
              title="Panel{{ cartIndex }}"
              id="panel-{{ cartIndex }}"
            >
              <ng-template ngbPanelHeader let-opened="opened">
                <div class="accordion-button custom-header accordion-mobile">
                  <div class="d-flex">
                    <div class="w-75">
                      <div class="title">
                        {{ cart.unit.uid }} • {{ cart.unit.name }}
                      </div>
                      <div class="title" *ngIf="cart?.purchaseOrderNumber">
                        {{
                          'fffApprovals.purchaseOrderNumber'
                            | cxTranslate
                              : {
                                  purchaseOrderNumber: cart?.purchaseOrderNumber
                                }
                        }}
                      </div>
                      <div class="title mb-2">
                        {{ 'fffApprovals.subtotal' | cxTranslate }}:
                        {{ cart.subTotal.formattedValue }}
                      </div>
                    </div>
                    <div class="d-flex justify-content-end w-25">
                      <button
                        class="ml-1 border-0 bg-transparent"
                        ngbPanelToggle
                        (click)="openSection(cartIndex, cart)"
                      >
                        <cx-icon
                          class="cx-icon icon-sm"
                          [class.is-expanded]="cart.expand"
                          [type]="outlinedIconTypes.ICON_CHEVRON_DOWN"
                        >
                        </cx-icon>
                      </button>
                    </div>
                  </div>

                  <div class="d-flex">
                    <button
                      [ngClass]="{ approved: allApproved(cartIndex) }"
                      class="button-approvals-mobile approve d-flex justify-content-center align-items-center"
                      (click)="approveOrder(cartIndex)"
                    >
                      <ng-container
                        *ngIf="allApproved(cartIndex); else approveOrderLabel"
                      >
                        <cx-icon
                          class="cx-icon icon-sm mr-2"
                          [type]="outlinedIconTypes.ICON_CHECK_CIRCLE"
                        ></cx-icon>
                        {{ 'fffApprovals.approved' | cxTranslate }}
                      </ng-container>
                      <ng-template #approveOrderLabel>
                        {{ 'fffApprovals.approve' | cxTranslate }}
                      </ng-template>
                    </button>

                    <button
                      class="button-approvals-mobile deny d-flex justify-content-center align-items-center"
                      (click)="rejectOrder(cartIndex)"
                    >
                      <ng-container
                        *ngIf="allRejected(cartIndex); else denyOrder"
                      >
                        <cx-icon
                          class="cx-icon icon-sm mr-2"
                          [type]="outlinedIconTypes.ICON_X_CIRCLE"
                        ></cx-icon>

                        {{ 'fffApprovals.denied' | cxTranslate }}
                      </ng-container>
                      <ng-template #denyOrder>
                        {{ 'fffApprovals.deny' | cxTranslate }}
                      </ng-template>
                    </button>
                  </div>
                  <div
                    *ngIf="!cart.expand"
                    class="d-flex mt-4 justify-content-center spinner-btn"
                  >
                    <button
                      [disabled]="!canSubmit(cartIndex)"
                      class="btn btn-primary btn-sm"
                      (click)="submitActions(cartIndex)"
                    >
                      <div class="px-4">
                        {{ 'fffApprovals.submit' | cxTranslate }}
                      </div>
                      <div
                        *ngIf="cart.isSubmitting"
                        class="cx-spinner button-loader"
                      >
                        <cx-spinner></cx-spinner>
                      </div>
                    </button>
                  </div>
                </div>
              </ng-template>

              <ng-template ngbPanelContent>
                <div
                  class="mobile-bg p-4"
                  *ngIf="!cartLoading; else loadingTemplate"
                >
                  <div
                    *ngFor="let entry of cart.entries; let entryNumber = index"
                  >
                    <div>
                      <hr *ngIf="entryNumber !== 0" />

                      <div class="d-flex mb-4 mt-1">
                        <div class="w-50 p-2">
                          <div class="mobile-title">
                            {{ 'fffApprovals.requestDate' | cxTranslate }}
                          </div>
                          <div class="mobile-info">
                            {{
                              cart.orderRequestSubmittedDate
                                | cxDate : 'shortDate'
                            }}
                          </div>
                        </div>

                        <div class="w-50 p-2">
                          <div class="mobile-title">
                            {{ 'fffApprovals.requestNumber' | cxTranslate }}
                          </div>
                          <div class="mobile-info">{{ cart.code }}</div>
                        </div>
                      </div>

                      <div class="d-flex mb-4 mt-1">
                        <div class="w-50 p-2">
                          <div class="mobile-title">
                            {{ 'fffApprovals.requester' | cxTranslate }}
                          </div>
                          <div
                            class="mobile-info d-flex cursor-pointer"
                            (click)="mailTo(cart.user.uid)"
                          >
                            <div class="mr-1 envelope">
                              <cx-icon
                                class="cx-icon"
                                [type]="outlinedIconTypes.ICON_ENVELOPE"
                              ></cx-icon>
                            </div>
                            <div class="user-name">
                              {{ cart.user?.name ? cart.user.name : 'TBD' }}
                            </div>
                          </div>
                        </div>

                        <div class="w-50 p-2">
                          <div class="mobile-title">
                            {{ 'fffApprovals.sku' | cxTranslate }}
                          </div>
                          <div class="mobile-info">
                            {{ entry.product.code }}
                          </div>
                        </div>
                      </div>

                      <div class="d-flex mb-4 mt-1">
                        <div class="w-50 p-2">
                          <div class="mobile-title">
                            {{ 'fffApprovals.name' | cxTranslate }}
                          </div>
                          <div class="mobile-info">
                            {{ entry.product.name }}
                          </div>
                        </div>

                        <div class="w-50 p-2">
                          <div class="mobile-title">
                            {{ 'fffApprovals.qty' | cxTranslate }}
                          </div>
                          <div class="mobile-info">
                            <input
                              class="qty-input w-50 text-center p-2"
                              [(ngModel)]="entry.quantity"
                              (blur)="
                                updateQuantity(
                                  $event,
                                  entry,
                                  cart.code,
                                  profile?.user?.uid,
                                  cartIndex
                                )
                              "
                            />
                          </div>
                        </div>
                      </div>

                      <div class="d-flex mb-4 mt-1">
                        <div class="w-50 p-2">
                          <div class="mobile-title">
                            {{ 'fffApprovals.price' | cxTranslate }}
                          </div>
                          <div class="mobile-info">
                            {{ getProductPrice(entry) }}
                          </div>
                        </div>
                      </div>
                      <div class="d-flex mb-4 mt-1">
                        <div class="w-50 p-2">
                          <div class="mobile-title">
                            {{ 'fffApprovals.total' | cxTranslate }}
                          </div>
                          <div class="mobile-info">
                            {{
                              entry.quantity === '0'
                                ? '--'
                                : '$' +
                                  (getProductTotalPrice(entry)
                                    | number : '1.2-2')
                            }}
                          </div>
                        </div>
                      </div>

                      <div class="d-flex">
                        <button
                          *ngIf="!isApproved(cartIndex, entry)"
                          class="small-approvals approve"
                          (click)="addEntry(cartIndex, entry, 'approve')"
                        >
                          <div>
                            {{ 'fffApprovals.approveSingle' | cxTranslate }}
                          </div>
                        </button>

                        <button
                          class="small-approvals approved d-flex justify-content-center align-items-center"
                          *ngIf="isApproved(cartIndex, entry)"
                          (click)="removeEntry(cartIndex, entry, 'approve')"
                        >
                          <cx-icon
                            class="cx-icon icon-sm mr-2"
                            [type]="outlinedIconTypes.ICON_CHECK_CIRCLE"
                          >
                          </cx-icon>
                          <div>{{ 'fffApprovals.approved' | cxTranslate }}</div>
                        </button>

                        <button
                          class="small-approvals deny"
                          *ngIf="!isRejected(cartIndex, entry)"
                          (click)="addEntry(cartIndex, entry, 'reject')"
                        >
                          <div>
                            {{ 'fffApprovals.denySingle' | cxTranslate }}
                          </div>
                        </button>

                        <button
                          class="small-approvals deny d-flex justify-content-center align-items-center"
                          *ngIf="isRejected(cartIndex, entry)"
                          (click)="removeEntry(cartIndex, entry, 'reject')"
                        >
                          <cx-icon
                            class="cx-icon icon-sm mr-2"
                            [type]="outlinedIconTypes.ICON_X_CIRCLE"
                          >
                          </cx-icon>
                          <div>{{ 'fffApprovals.denied' | cxTranslate }}</div>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="p-3 accordion-mobile">
                  <div class="d-flex">
                    <div class="w-75">
                      <label>
                        <ng-select
                          [(ngModel)]="productToAdd[cartIndex].product"
                          (search)="searchItems($event)"
                          style="width: 95%"
                          [loading]="loading"
                          [items]="products$ | async"
                          bindLabel="name"
                          placeholder="{{
                            'fffApprovals.selectProduct' | cxTranslate
                          }}"
                          [clearable]="false"
                        >
                        </ng-select>
                      </label>
                    </div>

                    <div class="w-25">
                      <div class="d-flex">
                        <input
                          class="qty-input final-input-mobile text-center p-2"
                          placeholder="{{
                            'fffApprovalsHistory.qty' | cxTranslate
                          }}"
                          [(ngModel)]="productToAdd[cartIndex].quantity"
                        />
                      </div>
                    </div>
                  </div>

                  <div class="d-flex ; w-100 ; justify-content-center ; mt-3">
                    <button
                      class="btn btn-secondary btn-md w-50"
                      (click)="addProduct(cartIndex, profile?.user?.uid)"
                      [disabled]="
                        !productToAdd[cartIndex]?.quantity ||
                        !productToAdd[cartIndex]?.product
                      "
                    >
                      <div>{{ 'fffApprovals.addItem' | cxTranslate }}</div>
                    </button>
                  </div>
                </div>

                <div class="p-3 accordion-mobile d-flex spinner-btn">
                  <div
                    class="w-50 d-flex justify-center align-items-center title flex-wrap"
                  >
                    <div class="d-inline-block">
                      {{ 'fffApprovals.subtotal' | cxTranslate }}: &nbsp;
                    </div>
                    {{ cart.subTotal.formattedValue }}
                  </div>
                  <button
                    [disabled]="!canSubmit(cartIndex)"
                    class="btn btn-primary btn-md w-50 d-flex"
                    (click)="submitActions(cartIndex)"
                  >
                    <div>{{ 'fffApprovals.submit' | cxTranslate }}</div>
                    <cx-icon
                      [ngClass]="{ hidden: cart.isSubmitting }"
                      class="cx-icon icon-sm ml-4 pl-2"
                      [type]="outlinedIconTypes.ICON_ARROW_RIGHT"
                    >
                    </cx-icon>
                    <div
                      *ngIf="cart.isSubmitting"
                      class="cx-spinner button-loader"
                    >
                      <cx-spinner></cx-spinner>
                    </div>
                  </button>
                </div>
              </ng-template>
            </ngb-panel>
          </ngb-accordion>

          <!--desktop view-->
          <ngb-accordion
            #a="ngbAccordion"
            [activeIds]="activeIds"
            class="desktop-approvals"
          >
            <ngb-panel
              *ngFor="let cart of carts; let cartIndex = index"
              title="Panel{{ cartIndex }}"
              id="panel-{{ cartIndex }}"
            >
              <ng-template ngbPanelHeader let-opened="opened">
                <div
                  class="accordion-button custom-header"
                  [class.collapsed]="!opened"
                >
                  <div class="order-container">
                    <div class="title">
                      {{ cart.unit.uid }} - {{ cart.unit.name }}
                    </div>

                    <div
                      class="title fff-po-number"
                      *ngIf="cart?.purchaseOrderNumber"
                    >
                      {{
                        'fffApprovals.purchaseOrderNumber'
                          | cxTranslate
                            : { purchaseOrderNumber: cart?.purchaseOrderNumber }
                      }}
                    </div>

                    <div class="d-flex justify-content-end w-100">
                      <div class="title">
                        {{ 'fffApprovals.subtotal' | cxTranslate }}:
                        {{ cart.subTotal.formattedValue }}
                      </div>

                      <button
                        [ngClass]="{ approved: allApproved(cartIndex) }"
                        class="button-approvals approve d-flex justify-content-center align-items-center"
                        (click)="approveOrder(cartIndex)"
                      >
                        <ng-container
                          *ngIf="allApproved(cartIndex); else approveOrderLabel"
                        >
                          <cx-icon
                            class="cx-icon icon-sm"
                            [type]="outlinedIconTypes.ICON_CHECK_CIRCLE"
                          ></cx-icon>
                          {{ 'fffApprovals.approved' | cxTranslate }}
                        </ng-container>
                        <ng-template #approveOrderLabel>
                          {{ 'fffApprovals.approve' | cxTranslate }}
                        </ng-template>
                      </button>

                      <button
                        class="button-approvals deny d-flex justify-content-center align-items-center"
                        (click)="rejectOrder(cartIndex)"
                      >
                        <ng-container
                          *ngIf="allRejected(cartIndex); else denyOrder"
                        >
                          <cx-icon
                            class="cx-icon icon-sm"
                            [type]="outlinedIconTypes.ICON_X_CIRCLE"
                          ></cx-icon>
                          {{ 'fffApprovals.denied' | cxTranslate }}
                        </ng-container>
                        <ng-template #denyOrder>
                          {{ 'fffApprovals.deny' | cxTranslate }}
                        </ng-template>
                      </button>
                      <div class="spinner-btn">
                        <button
                          *ngIf="!cart.expand"
                          [disabled]="!canSubmit(cartIndex)"
                          class="btn btn-primary btn-sm ml-4 d-flex"
                          (click)="submitActions(cartIndex)"
                        >
                          <div>{{ 'fffApprovals.submit' | cxTranslate }}</div>
                          <cx-icon
                            [ngClass]="{ hidden: cart.isSubmitting }"
                            class="cx-icon icon-sm ml-4 pl-2"
                            [type]="outlinedIconTypes.ICON_ARROW_RIGHT"
                          >
                          </cx-icon>
                          <div
                            *ngIf="cart.isSubmitting"
                            class="cx-spinner button-loader"
                          >
                            <cx-spinner></cx-spinner>
                          </div>
                        </button>
                      </div>

                      <button
                        class="ml-1 border-0 bg-transparent"
                        ngbPanelToggle
                        (click)="openSection(cartIndex, cart)"
                      >
                        <cx-icon
                          class="cx-icon icon-sm"
                          [class.is-expanded]="cart.expand"
                          [type]="outlinedIconTypes.ICON_CHEVRON_DOWN"
                        >
                        </cx-icon>
                      </button>
                    </div>
                  </div>
                </div>
              </ng-template>

              <ng-template ngbPanelContent>
                <div>
                  <div class="th grid-setup-1">
                    <div>{{ 'fffApprovals.requestDate' | cxTranslate }}</div>
                    <div>{{ 'fffApprovals.requestNumber' | cxTranslate }}</div>
                    <div>{{ 'fffApprovals.requester' | cxTranslate }}</div>
                    <div>{{ 'fffApprovals.sku' | cxTranslate }}</div>
                    <div>{{ 'fffApprovals.name' | cxTranslate }}</div>
                    <div>{{ 'fffApprovals.qty' | cxTranslate }}</div>
                    <div>{{ 'fffApprovals.price' | cxTranslate }}</div>
                    <div>{{ 'fffApprovals.total' | cxTranslate }}</div>
                    <div>{{ 'fffApprovals.action' | cxTranslate }}</div>
                  </div>

                  <div
                    class="td-container"
                    *ngIf="!cartLoading; else loadingTemplate"
                  >
                    <ng-container
                      *ngFor="
                        let entry of cart.entries;
                        let entryNumber = index
                      "
                    >
                      <div class="td grid-setup-1">
                        <div class="cell">
                          <div>
                            {{
                              cart.orderRequestSubmittedDate
                                | cxDate : 'shortDate'
                            }}
                          </div>
                        </div>

                        <div class="cell">
                          <div>{{ cart.code }}</div>
                        </div>

                        <div class="cell">
                          <div
                            class="d-flex cursor-pointer"
                            (click)="mailTo(cart.user.uid)"
                          >
                            <div class="mr-1 envelope">
                              <cx-icon
                                class="cx-icon"
                                [type]="outlinedIconTypes.ICON_ENVELOPE"
                              ></cx-icon>
                            </div>
                            <div class="user-name">
                              {{ cart.user?.name ? cart.user.name : 'TBD' }}
                            </div>
                          </div>
                        </div>

                        <div class="cell">
                          <div>{{ entry.product.code }}</div>
                        </div>

                        <div class="cell">
                          <div>{{ entry.product.name }}</div>
                        </div>

                        <div class="cell">
                          <div>
                            <input
                              class="qty-input w-50 text-center p-2"
                              [(ngModel)]="entry.quantity"
                              (blur)="
                                updateQuantity(
                                  $event,
                                  entry,
                                  cart.code,
                                  profile?.user?.uid,
                                  cartIndex
                                )
                              "
                            />
                          </div>
                        </div>

                        <div class="cell">
                          <div>
                            {{ getProductPrice(entry) }}
                          </div>
                        </div>
                        <div class="cell">
                          <div>
                            {{
                              entry.quantity === '0'
                                ? '--'
                                : '$' +
                                  (getProductTotalPrice(entry)
                                    | number : '1.2-2')
                            }}
                          </div>
                        </div>
                        <div class="cell">
                          <div class="d-flex">
                            <button
                              *ngIf="!isApproved(cartIndex, entry)"
                              class="small-approvals approve"
                              (click)="addEntry(cartIndex, entry, 'approve')"
                            >
                              <div>
                                {{ 'fffApprovals.approveSingle' | cxTranslate }}
                              </div>
                            </button>

                            <button
                              class="small-approvals approved d-flex justify-content-center align-items-center"
                              *ngIf="isApproved(cartIndex, entry)"
                              (click)="removeEntry(cartIndex, entry, 'approve')"
                            >
                              <cx-icon
                                class="cx-icon icon-sm mr-2"
                                [type]="outlinedIconTypes.ICON_CHECK_CIRCLE"
                              ></cx-icon>
                              <div>
                                {{ 'fffApprovals.approved' | cxTranslate }}
                              </div>
                            </button>

                            <button
                              class="small-approvals deny"
                              *ngIf="!isRejected(cartIndex, entry)"
                              (click)="addEntry(cartIndex, entry, 'reject')"
                            >
                              <div>
                                {{ 'fffApprovals.denySingle' | cxTranslate }}
                              </div>
                            </button>

                            <button
                              class="small-approvals deny d-flex justify-content-center align-items-center"
                              *ngIf="isRejected(cartIndex, entry)"
                              (click)="removeEntry(cartIndex, entry, 'reject')"
                            >
                              <cx-icon
                                class="cx-icon icon-sm mr-2"
                                [type]="outlinedIconTypes.ICON_X_CIRCLE"
                              ></cx-icon>
                              <div>
                                {{ 'fffApprovals.denied' | cxTranslate }}
                              </div>
                            </button>
                          </div>
                        </div>
                      </div>
                    </ng-container>

                    <div
                      class="td"
                      style="grid-template-columns: 6fr 1fr 1fr 2fr 1fr"
                    >
                      <div class="cell d-flex align-content-center flex-column">
                        <label>
                          <ng-select
                            [(ngModel)]="productToAdd[cartIndex].product"
                            (search)="searchItems($event)"
                            style="width: 95%"
                            [loading]="loading"
                            [items]="products$ | async"
                            bindLabel="name"
                            placeholder="{{
                              'fffApprovals.selectProduct' | cxTranslate
                            }}"
                            [clearable]="false"
                          >
                          </ng-select>
                        </label>
                      </div>

                      <div class="cell d-flex align-content-center">
                        <div>
                          <input
                            class="qty-input w-50 text-center p-2"
                            placeholder="{{
                              'fffApprovalsHistory.qty' | cxTranslate
                            }}"
                            [(ngModel)]="productToAdd[cartIndex].quantity"
                          />
                        </div>
                      </div>

                      <div class="cell">
                        <div></div>
                      </div>

                      <div class="cell">
                        <div></div>
                      </div>

                      <div class="cell d-flex">
                        <button
                          class="btn btn-secondary btn-md w-100"
                          (click)="addProduct(cartIndex, profile?.user?.uid)"
                          [disabled]="
                            !productToAdd[cartIndex]?.quantity ||
                            !productToAdd[cartIndex]?.product
                          "
                        >
                          <div>{{ 'fffApprovals.addItem' | cxTranslate }}</div>
                        </button>
                      </div>
                    </div>

                    <div
                      class="td"
                      style="grid-template-columns: 6fr 1fr 1fr 1fr 3fr"
                    >
                      <div class="cell"></div>

                      <div class="cell"></div>

                      <div class="cell"></div>

                      <div class="cell"></div>

                      <div class="cell d-flex spinner-btn">
                        <div
                          class="w-50 d-flex justify-center align-items-center title flex-wrap"
                        >
                          <div class="d-inline-block">
                            {{ 'fffApprovals.subtotal' | cxTranslate }}:&nbsp;
                          </div>
                          {{ cart.subTotal.formattedValue }}
                        </div>
                        <button
                          [disabled]="!canSubmit(cartIndex)"
                          class="btn btn-primary btn-md w-50 d-flex"
                          (click)="submitActions(cartIndex)"
                        >
                          <div>{{ 'fffApprovals.submit' | cxTranslate }}</div>
                          <cx-icon
                            [ngClass]="{ hidden: cart.isSubmitting }"
                            class="cx-icon icon-sm ml-4 pl-2"
                            [type]="outlinedIconTypes.ICON_ARROW_RIGHT"
                          >
                          </cx-icon>
                          <div
                            *ngIf="cart.isSubmitting"
                            class="cx-spinner button-loader"
                          >
                            <cx-spinner></cx-spinner>
                          </div>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </ng-template>
            </ngb-panel>
          </ngb-accordion>
        </ng-container>
      </ng-container>
    </div>

    <div *ngIf="'approvalHistory' === selectedTab">
      <ngb-accordion
        #h="ngbAccordion"
        [activeIds]="activeIdsHistory"
        *ngIf="approvalHistory$ | async; else loadingTemplate"
      >
        <ngb-panel
          *ngFor="
            let approvalOrder of approvalHistory;
            let approvalOrderIndex = index
          "
          title="Panel{{ approvalOrderIndex }}"
          id="oa-panel-{{ approvalOrderIndex }}"
        >
          <ng-template ngbPanelHeader let-opened="opened">
            <div
              class="accordion-button custom-header cursor-pointer"
              [class.collapsed]="!opened"
            >
              <div class="order-container">
                <div class="title w-100">
                  {{ approvalOrder.unit }}
                </div>
                <div class="d-flex justify-content-end w-100">
                  <button
                    class="ml-1 border-0 bg-transparent"
                    ngbPanelToggle
                    (click)="
                      openSectionHistory(approvalOrderIndex, approvalOrder)
                    "
                  >
                    <cx-icon
                      class="cx-icon icon-sm"
                      [class.is-expanded]="approvalOrder.expand"
                      [type]="outlinedIconTypes.ICON_CHEVRON_DOWN"
                    >
                    </cx-icon>
                  </button>
                </div>
              </div>
            </div>
          </ng-template>

          <ng-template ngbPanelContent>
            <div class="d-lg-block d-none">
              <div class="th grid-setup-2">
                <div>{{ 'fffApprovalsHistory.requestDate' | cxTranslate }}</div>
                <div>{{ 'fffApprovalsHistory.orderNumber' | cxTranslate }}</div>
                <div>
                  {{ 'fffApprovalsHistory.purchaseOrderNumber' | cxTranslate }}
                </div>
                <div>{{ 'fffApprovalsHistory.requestBy' | cxTranslate }}</div>
                <div>{{ 'fffApprovalsHistory.sku' | cxTranslate }}</div>
                <div>{{ 'fffApprovalsHistory.name' | cxTranslate }}</div>
                <div>{{ 'fffApprovalsHistory.qty' | cxTranslate }}</div>
                <div>{{ 'fffApprovalsHistory.price' | cxTranslate }}</div>
                <div>{{ 'fffApprovalsHistory.total' | cxTranslate }}</div>
                <div>{{ 'fffApprovalsHistory.action' | cxTranslate }}</div>
              </div>

              <div class="td-container">
                <ng-container *ngFor="let entry of approvalOrder.items">
                  <div class="td grid-setup-2">
                    <div class="cell">
                      {{ entry.requestDate | cxDate : 'shortDate' }}
                    </div>
                    <div class="cell">
                      <span *ngIf="'APPROVED' === entry.orderStatus">{{
                        entry.orderNumber
                      }}</span>
                    </div>
                    <div class="cell">{{ entry.poNumber || '' }}</div>
                    <div class="cell cell--wrap-all">{{ entry.requestBy }}</div>
                    <div class="cell">
                      <span class="px-2">{{ entry.productCode }}</span>
                    </div>
                    <div class="cell">{{ entry.productName }}</div>
                    <div class="cell">{{ entry.quantity }}</div>
                    <div
                      class="cell"
                      *ngIf="entry.unitPrice !== 0; else itemWithoutPrice"
                    >
                      {{ entry.unitPrice | currency }}
                    </div>
                    <div
                      class="cell"
                      *ngIf="entry.totalPrice !== 0; else itemWithoutPrice"
                    >
                      {{ entry.totalPrice | currency }}
                    </div>
                    <div class="cell d-flex cell--wrap-all">
                      <ng-container *ngIf="'APPROVED' === entry.orderStatus">
                        <span class="approved m-auto">
                          <cx-icon
                            class="cx-icon icon-sm mr-2"
                            [type]="outlinedIconTypes.ICON_CHECK_CIRCLE"
                          ></cx-icon>
                        </span>
                        <span class="approved">
                          {{
                            'fffApprovalsHistory.approved'
                              | cxTranslate
                                : {
                                    user: entry.approver,
                                    date:
                                      entry.approvedDate | cxDate : 'shortDate'
                                  }
                          }}
                        </span>
                      </ng-container>

                      <ng-container *ngIf="'REJECTED' === entry.orderStatus">
                        <span class="deny m-auto">
                          <cx-icon
                            class="cx-icon icon-sm mr-2"
                            [type]="outlinedIconTypes.ICON_X_CIRCLE"
                          ></cx-icon>
                        </span>
                        <span class="deny">
                          {{
                            'fffApprovalsHistory.denied'
                              | cxTranslate
                                : {
                                    user: entry.approver,
                                    date:
                                      entry.approvedDate | cxDate : 'shortDate'
                                  }
                          }}
                        </span>
                      </ng-container>
                    </div>
                  </div>
                </ng-container>
              </div>
            </div>

            <div class="d-block d-lg-none mobile-bg p-4">
              <div class="py-3" *ngFor="let entry of approvalOrder.items">
                <div>
                  <div class="d-flex mb-4 mt-1">
                    <div class="w-50 p-2">
                      <div class="mobile-title">
                        {{ 'fffApprovalsHistory.requestDate' | cxTranslate }}
                      </div>
                      <div class="mobile-info">
                        {{ entry.requestDate | cxDate : 'shortDate' }}
                      </div>
                    </div>
                    <div class="w-50 p-2">
                      <div class="mobile-title">
                        {{ 'fffApprovalsHistory.orderNumber' | cxTranslate }}
                      </div>
                      <div class="mobile-info">
                        <span *ngIf="'APPROVED' === entry.orderStatus">{{
                          entry.orderNumber
                        }}</span>
                      </div>
                    </div>
                  </div>

                  <div class="d-flex mb-4 mt-1">
                    <div class="w-50 p-2">
                      <div class="mobile-title">
                        {{
                          'fffApprovalsHistory.purchaseOrderNumber'
                            | cxTranslate
                        }}
                      </div>
                      <div class="mobile-info d-flex">
                        <div>{{ entry.poNumber }}</div>
                      </div>
                    </div>
                    <div class="w-50 p-2">
                      <div class="mobile-title">
                        {{ 'fffApprovalsHistory.requestBy' | cxTranslate }}
                      </div>
                      <div class="mobile-info d-flex mobile-info--wrap-all">
                        <div>{{ entry.requestBy }}</div>
                      </div>
                    </div>
                  </div>

                  <div class="d-flex mb-4 mt-1">
                    <div class="w-50 p-2">
                      <div class="mobile-title">
                        {{ 'fffApprovalsHistory.sku' | cxTranslate }}
                      </div>
                      <div class="mobile-info">{{ entry.productCode }}</div>
                    </div>
                    <div class="w-50 p-2">
                      <div class="mobile-title">
                        {{ 'fffApprovalsHistory.price' | cxTranslate }}
                      </div>
                      <div
                        class="mobile-info"
                        *ngIf="entry.unitPrice !== 0; else itemWithoutPrice"
                      >
                        {{ entry.unitPrice | number : '1.2-2' }}
                      </div>
                    </div>
                  </div>

                  <div class="d-flex mb-4 mt-1">
                    <div class="w-50 p-2">
                      <div class="mobile-title">
                        {{ 'fffApprovalsHistory.total' | cxTranslate }}
                      </div>
                      <div
                        class="mobile-info"
                        *ngIf="entry.totalPrice !== 0; else itemWithoutPrice"
                      >
                        {{ entry.totalPrice | number : '1.2-2' }}
                      </div>
                    </div>
                    <div class="w-50 p-2">
                      <div class="mobile-title">
                        {{ 'fffApprovalsHistory.name' | cxTranslate }}
                      </div>
                      <div class="mobile-info">{{ entry.productName }}</div>
                    </div>
                  </div>

                  <div class="d-flex mb-4 mt-1">
                    <div class="w-50 p-2">
                      <div class="mobile-title">
                        {{ 'fffApprovalsHistory.qty' | cxTranslate }}
                      </div>
                      <div class="mobile-info">{{ entry.quantity }}</div>
                    </div>
                  </div>

                  <div class="d-flex">
                    <ng-container *ngIf="'APPROVED' === entry.orderStatus">
                      <span class="approved m-auto">
                        <cx-icon
                          class="cx-icon icon-sm mr-2"
                          [type]="outlinedIconTypes.ICON_CHECK_CIRCLE"
                        ></cx-icon>
                      </span>
                      <span class="approved col-11">
                        {{
                          'fffApprovalsHistory.approved'
                            | cxTranslate
                              : {
                                  user: entry.approver,
                                  date:
                                    entry.approvedDate | cxDate : 'shortDate'
                                }
                        }}
                      </span>
                    </ng-container>

                    <ng-container *ngIf="'REJECTED' === entry.orderStatus">
                      <span class="deny m-auto">
                        <cx-icon
                          class="cx-icon icon-sm mr-2"
                          [type]="outlinedIconTypes.ICON_X_CIRCLE"
                        ></cx-icon>
                      </span>
                      <span class="deny col-11">
                        {{
                          'fffApprovalsHistory.denied'
                            | cxTranslate
                              : {
                                  user: entry.approver,
                                  date:
                                    entry.approvedDate | cxDate : 'shortDate'
                                }
                        }}
                      </span>
                    </ng-container>
                  </div>
                </div>
              </div>
            </div>
          </ng-template>
        </ngb-panel>
      </ngb-accordion>
    </div>
  </div>
</ng-container>

<ng-template #tableLoading>
  <fff-table-loader [fields]="'fields'.split('')"></fff-table-loader>
</ng-template>

<ng-template #loadingTemplate>
  <fff-content-loader
    containerClassName="height-large"
    bodyClassName="d-flex price-row pt-2 pb-2 pl-3 pr-3"
  >
  </fff-content-loader>
</ng-template>

<ng-template #itemWithoutPrice>
  <div class="cell">--</div>
</ng-template>
