import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { UPDATED_CART, UpdatedCartState } from '@app/reducers';
import { FFFCommonFunctions } from '@app/shared/fff-common-functions';
import { FffGtmEventService } from '@app/spartacus/features/tracking/gmt/event';
import { getProductImage } from '@assets/utils/fff-product-utils';
import { FFFActiveCartService } from '@enterprise/fff-custom-cart/fff-active-cart-service';
import { FFFOrderEntry } from '@model/fff-cart-data.model';
import { Store } from '@ngrx/store';

@Component({
  selector: 'fff-cart-item',
  templateUrl: './fff-cart-item.component.html',
})
export class FffCartItemComponent implements OnChanges {
  @Input() accountUid: string | undefined;
  @Input() entry: any;
  @Input() isCustomerSplitBiller: boolean = false;
  @Input() currentB2bUnitIsSplitBiller: boolean = false;
  @Input() showActions: boolean = true;
  imageObject: any;
  isOfVaccineTypeI = FFFCommonFunctions.isOfVaccineType;

  constructor(
    private activeCartService: FFFActiveCartService,
    private updatedCartStore: Store<UpdatedCartState>,
    private fffGtmEventService: FffGtmEventService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.entry) {
      this.imageObject = getProductImage(this.entry?.product?.images);
    }
  }

  getAccountTypeLabel(accountType: string): string {
    return `${this.accountUid}${accountType !== 'S' ? accountType : ''}`;
  }

  remove(e: FFFOrderEntry) {
    this.activeCartService.removeEntry(e);
    this.updatedCartStore.dispatch({ type: UPDATED_CART, payload: {} });
    this.fffGtmEventService.removeEntrySuccessEvent(e);
    this.activeCartService.emitRemovedEntry(e);
  }

  isValidDiscPrice(entry: any) {
    if (typeof entry?.altDiscPrice !== 'undefined' && entry?.altDiscPrice) {
      return this.entry?.altDiscPrice;
    }
  }
  isValidAltUom(entry: any) {
    if (typeof entry?.altUom !== 'undefined' && entry?.altUom) {
      return this.entry?.altUom;
    }
  }
}
