import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { FffFileUploadModule } from '@app/shared/components/form/fff-file-upload/fff-file-upload.module';
import { FffBasicModalModule } from '@app/shared/components/modal/fff-basic-modal/fff-basic-modal.module';
import { CartSharedModule } from '@spartacus/cart/base/components';
import { AddToCartModule } from '@spartacus/cart/base/components/add-to-cart';
import { ImportOrderEntriesModule } from '@spartacus/cart/import-export/components';
import { QuickOrderModule } from '@spartacus/cart/quick-order';
import { QuickOrderListModule } from '@spartacus/cart/quick-order/components';
import { CmsConfig, I18nModule, UrlModule, provideConfig } from '@spartacus/core';
import {
  FileUploadModule,
  FormErrorsModule,
  GenericLinkModule,
  IconModule,
  ItemCounterModule,
  MediaModule,
  MessageComponentModule,
  ProgressButtonModule
} from '@spartacus/storefront';
import { FffDrawerModule } from 'src/app/shared/drawer/fff-drawer.module';
import { FffCartButtonModule } from '../fff-header/fff-mini-cart/fff-mini-cart.module';
import { FffProductAllocationModule } from '../fff-product-allocation/fff-product-allocation.module';
import { FffProductModule } from '../fff-product/fff-product.module';
import { FffSplitBillingModule } from '../fff-product/fff-split-billing/fff-split-billing.module';
import { FffImportEntriesFormComponent } from './/fff-import-entries-form/fff-import-entries-form.component';
import { FffQuickOrderComponent } from './fff-quick-order.component';
import { FFFLoadersModule } from '@app/shared/components/fff-loaders/fff-loaders.module';

@NgModule({
  declarations: [
    FffQuickOrderComponent,
    FffImportEntriesFormComponent,
  ],
  providers: [
    provideConfig(<CmsConfig>{
      cmsComponents: {
        QuickOrderComponent: {
          component: FffQuickOrderComponent,
        },
      },
    }),
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    IconModule,
    GenericLinkModule,
    I18nModule,
    FffDrawerModule,
    RouterModule,
    QuickOrderModule,
    FormErrorsModule,
    ItemCounterModule,
    MediaModule,
    UrlModule,
    ProgressButtonModule,
    FileUploadModule,
    MessageComponentModule,
    QuickOrderListModule,
    ImportOrderEntriesModule,
    CartSharedModule,
    FffProductModule,
    FffSplitBillingModule,
    FffProductAllocationModule,
    AddToCartModule,
    FffCartButtonModule,
    FFFLoadersModule,
    FffFileUploadModule,
    FffBasicModalModule,
  ],
})
export class FffQuickOrderModule { }
