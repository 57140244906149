<div class="d-flex flex-column justify-content-between h-100">
  <div class="px-3 pt-3">
    <div class="form-control d-flex">
      <input [(ngModel)]="productManufacturer" class="orderHistoryInput w-100"
        [placeholder]="'fffAllocationsFilters.searchByProductName' | cxTranslate" />
      <cx-icon class="cx-icon icon-sm px-2 icon" [type]="outlinedIconTypes.ICON_SEARCH"></cx-icon>
    </div>
  </div>
  <div class="footer-container d-flex justify-content-center align-items-center">
    <button class="btn btn-primary btn-xs w-100" (click)="sendFilter(productManufacturer)">
      {{'fffOrderHistoryFilter.apply' | cxTranslate}}
    </button>
  </div>
</div>
