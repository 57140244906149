import { isPlatformBrowser } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  Injector,
  OnDestroy,
  OnInit,
  PLATFORM_ID,
} from '@angular/core';
import { FffCommunicationService } from '@app/fff-enterprise/fff-common-services/fff-communication.service';
import { FffProductService } from '@app/fff-enterprise/fff-common-services/fff-product.service';
import { TieredModalComponent } from '@app/fff-enterprise/fff-product/fff-product-item-info/tiered-modal/tiered-modal.component';
import {
  CustomerState,
  ProductSpecialPriceState,
  SET_CUSTOMER_SPLIT_BILLER,
} from '@app/reducers';
import { FFFCommonFunctions } from '@app/shared/fff-common-functions';
import { BASE_URL_KEYS, MAX_DIFF_DAYS } from '@config/content/constants';
import { FffProfile } from '@model/fff-profile.model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { FffUserAccountService } from '@shared/services/fff-user-account.service';
import {
  AuthService,
  BaseSiteService,
  RoutingService,
  isNotNullable
} from '@spartacus/core';
import { CurrentProductService } from '@spartacus/storefront';
import { Observable, Subject } from 'rxjs';
import { filter, map, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { OUTLINED_ICON_TYPE } from 'src/app/models/fff-outline-icons.model';

@Component({
  selector: 'fff-product-summary',
  templateUrl: './fff-product-summary.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FffProductSummaryComponent implements OnInit, OnDestroy {
  outlinedIconTypes = OUTLINED_ICON_TYPE;
  barSize = 224;
  tooltipText = '';
  private unsubscribe$: Subject<void> = new Subject<void>();
  baseSite$ = this.baseSiteService.getActive();
  profile: FffProfile | undefined;
  profile$: Observable<FffProfile | undefined> = this.fffAccountService
    .getProfile()
    .pipe(
      tap(user => this.updateB2bUnit(user)),
      tap(user => (this.profile = user)),
      takeUntil(this.unsubscribe$)
    );
  productData: any;
  displayLoading = true;
  productData$ = this.currentProductService.getProduct('DEFAULT').pipe(
    tap(() => {
      this.displayLoading = !this.displayLoading;
      this.cdr.markForCheck();
    }),
    filter(isNotNullable),
    switchMap(product => {
      return this.communicationService
        .getCombinedProductDetails(product.code || '')
        .pipe(
          take(1),
          map(response => {
            const productStock = this.getStockInfo(
              response.custToCustAllocVint
            );
            this.barProp =
              +productStock?.yearlyAllocatedQty -
              +productStock?.yearlyRemainingQty;

            this.fffProductService.setPricesData(
              product.code ? product.code : '',
              response.price
            );

            const shouldDisplayTieredPricesButton =
              this.verifyToMatScaleResultsLength(response.price);

            return {
              product,
              stockData: response.custToCustAllocVint,
              pricesData: response.price,
              shouldDisplayTieredPricesButton,
            };
          })
        );
    }),
    takeUntil(this.unsubscribe$)
  );
  BASE_URL_KEYS = BASE_URL_KEYS;
  barProp: number = 0;
  MAX_DIFF_DAYS = MAX_DIFF_DAYS;
  isShortDated: boolean = false;
  isLoggedIn$!: Observable<boolean>;
  prices$ = FFFCommonFunctions.prices$;
  isOfVaccineTypeI = FFFCommonFunctions.isOfVaccineType;
  getAltPriceI = FFFCommonFunctions.getAltPrice;
  isStorageAvailable!:boolean;

  constructor(
    protected currentProductService: CurrentProductService,
    private communicationService: FffCommunicationService,
    private storeCustomer: Store<CustomerState>,
    protected routingService: RoutingService,
    protected baseSiteService: BaseSiteService,
    private fffAccountService: FffUserAccountService,
    private cdr: ChangeDetectorRef,
    private modalService: NgbModal,
    private injector: Injector,
    private storeProductSpecialPrice: Store<ProductSpecialPriceState>,
    private fffProductService: FffProductService,
    private auth: AuthService,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {}

  getBarProp(a: number, b: number) {
    if (a == null || b == null || +a === 0 || +b === 0) {
      return 0;
    }

    return ((b - a) / b) * this.barSize;
  }

  ngOnInit() {
    this.isStorageAvailable = this.isLocalStorageAvailable();
    this.isLoggedIn$ = this.auth.isUserLoggedIn();
    this.productData$
      .pipe(
        switchMap(data => {
          this.productData = data;
          this.displayLoading = false;
          this.cdr.markForCheck();
          return this.storeProductSpecialPrice.select((state: any) => {
            return state.ProductSpecialPrice;
          });
        })
      )
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(specialPrice => {
        if (specialPrice?.data && this.productData) {
          let option = specialPrice.data[this.productData.product.code].data;
          if (option)
            this.isShortDated =
              option.batch &&
              option.batch !== 'undefined' &&
              option.diffDays <= this.MAX_DIFF_DAYS;
        }
      });
  }

  private isLocalStorageAvailable(): boolean {
    return isPlatformBrowser(this.platformId) && typeof localStorage !== 'undefined';
  }

  getStockInfo(stockData: any): any {
    if (stockData?.results?.length < 1) {
      return null;
    }
    return stockData?.results[0];
  }

  cartErrorEventEmitter(label: string) {
    this.tooltipText = label;
  }

  goTo(url: string): void {
    this.routingService.go(url);
  }

  private updateB2bUnit(userResponse: any): void {
    this.storeCustomer.dispatch({
      type: SET_CUSTOMER_SPLIT_BILLER,
      payload: userResponse,
    });
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private verifyToMatScaleResultsLength(pricesData: any): boolean {
    return pricesData?.prices.some((price: any) => {
      const results: any[] = price.price.toMatScale.results;
      return results.length !== 0;
    });
  }

  displayTieredPricing() {
    //open modal
    this.modalService.open(TieredModalComponent, {
      centered: true,
      backdrop: 'static',
      //keyboard: false,
      size: 'md',
      modalDialogClass: 'modal-container',
      injector: Injector.create({
        providers: [
          { provide: 'tieredPricing', useValue: this.productData.pricesData },
        ],
        parent: this.injector,
      }),
    });
  }
}
