import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'fffFilterProductType'
})
export class FffFilterProductTypePipe implements PipeTransform {

  transform(options: any[], searchTerm: any): any[] {
    const trimmedTerm = (searchTerm || '').trim().toLowerCase();
    const __options: any[] = options || []
    if (!trimmedTerm) {
      return __options;
    }
    return __options.filter((o) => (o.name)?.toLowerCase().includes(trimmedTerm));
  }

}
