<div class="popover-trigger hide" [ngbPopover]=templateAccordion [animation]="true" [popoverClass]="(content$ | async)?.quickClose ? 'quickClose open'+(content$ | async)?.animation:
  'open'+(content$ | async)?.animation" [closeDelay]="500" placement="top" autoClose="outside" #popover="ngbPopover"
  id="popover" (click)="openPop()">
</div>
<ng-template #templateAccordion>
  <ng-container *ngIf="content$ | async as data">
    <div (fffDrawerClickOutside)="clickOutside()" class="popover-container" id="popover-container"
      [class]="data?.class">
      <div class="popover-header">
        <ng-container *ngIf="data?.drawerHeader; else displayTitle">
          <ng-container *ngComponentOutlet="data?.drawerHeader"> </ng-container>
        </ng-container>
        <ng-template #displayTitle>
          <div class="container-close-icon">
            <cx-icon class="cx-icon icon-lg" [type]="outlinedIconTypes.ICON_X" (click)="closePopover()"></cx-icon>
          </div>
          <h3 class="popover-title">
            {{data?.title}}
          </h3>
        </ng-template>
      </div>
      <div class="popover-elements" [ngClass]="cssClass">
        <p *ngIf="data.paragraph" [innerHTML]="data.paragraph"></p>
        <ng-container *ngIf="data?.component" [ngComponentOutlet]="data?.component"></ng-container>
      </div>
      <div class="popover-footer" *ngIf="data?.drawerFooter">
        <ng-container *ngComponentOutlet="data?.drawerFooter"> </ng-container>
      </div>
    </div>
  </ng-container>
</ng-template>