<div class="col-12">

  <div class="col-12">
    <button class="btn btn-primary col-12" [class.updating]="loading" [disabled]="loading" (click)="restoreCart()">{{
      'fffRestoreSavedCartDrawer.restoreCart' |
      cxTranslate }}
      <cx-icon *ngIf="loading" class="cx-icon icon-md ml-3"
        [type]="outlinedIconTypes.ICON_ARROW_REPEAT"></cx-icon></button>
  </div>

  <div class="col-12 py-2">
    <button class="btn btn-secondary col-12" (click)="closeDrawer()">{{ 'fffRestoreSavedCartDrawer.cancel' |
      cxTranslate }}</button>
  </div>

</div>