<div class="modal-header">
  <h4 class="modal-title" *ngIf="currentStep < steps.CONFIRMATION">
    <ng-container *ngIf="currentStep === steps.SELECT_CARD">
      {{ 'fffInvoicePayment.payInvoices' | cxTranslate }}
    </ng-container>
    <ng-container *ngIf="currentStep === steps.REVIEW">
      {{ 'fffInvoicePayment.reviewCreditCardPayment' | cxTranslate }}
    </ng-container>
  </h4>

  <cx-icon
    *ngIf="currentStep === steps.REVIEW"
    class="text-reset cx-icon-navigation"
    (click)="navigateToSelectCard()"
    [type]="outlinedIconTypes.ICON_ARROW_LEFT"
  ></cx-icon>
  <cx-icon
    class="text-reset cx-icon-close"
    (click)="closeAndReset()"
    [type]="outlinedIconTypes.ICON_X"
  ></cx-icon>
</div>
<div class="modal-body" id="printSection">
  <ng-container *ngIf="currentStep === steps.CONFIRMATION">
    <fff-invoice-payment-confirmation-header></fff-invoice-payment-confirmation-header>
  </ng-container>
  <fff-invoice-payment-top-header
    [totalInvoices]="totalInvoices"
    [contactUser]="contactUser"
  ></fff-invoice-payment-top-header>

  <fff-select-credit-card
    *ngIf="currentStep === steps.SELECT_CARD"
    [newCreditCardAdded]="newCreditCardAdded"
  ></fff-select-credit-card>

  <fff-invoice-payment-entries
    *ngIf="currentStep !== steps.CONFIRMATION"
    [invoices]="invoices"
    (removed)="onInvoiceRemoved($event)"
  ></fff-invoice-payment-entries>
  <fff-invoice-payment-confirmation-entries
    *ngIf="currentStep === steps.CONFIRMATION"
    [invoices]="invoices"
  ></fff-invoice-payment-confirmation-entries>
</div>
