<div class="d-flex flex-column justify-content-between h-100">
  
  <div class="request-form-container">

    <div *ngIf="showButtons">
      <button class="billing-btn shippingBtn body-text" [ngClass]="{'selected': changeAddress }"
        (click)="showShippingAddress()">
        {{'fffAccount.fffRequestChangeAddress.shipping'|cxTranslate }}
      </button>
      <button class="billing-btn billingBtn body-text" [ngClass]="{'selected': !changeAddress }"
        (click)="showBillingAddress()">
        {{'fffAccount.fffRequestChangeAddress.billing'|cxTranslate }}
      </button>
    </div>

    <form [formGroup]="RequestChangeAddressForm" class="body-text mt-4">

      <div class="form-group">
        <label for="address-input" class="form-label">
          <span> {{'fffAccount.fffRequestChangeAddress.address'|cxTranslate }}</span>
          <input type="text" class="form-control" id="address-input" formControlName="address"
            placeholder="Enter Address">
          <cx-form-errors [control]="$any(RequestChangeAddressForm.get('address'))"></cx-form-errors>
        </label>

      </div>

      <div class="form-group">
        <div class="justify-content-between d-flex">
          <label for="building-input" class="form-label">
            {{'fffAccount.fffRequestChangeAddress.building'|cxTranslate}}
          </label>
          <div class="body-text-xxs"> {{'fffAccount.fffRequestChangeAddress.optional'|cxTranslate }}</div>
        </div>
        <input type="text" class="form-control" id="building-input" formControlName="building"
          placeholder="Enter Address Line 2">
      </div>


      <div class="form-group">
        <label for="city-input" class="form-label">
          <span>
            {{'fffAccount.fffRequestChangeAddress.city'|cxTranslate }}
          </span>
          <input type="text" class="form-control" id="city-input" formControlName="city" placeholder="Enter city"
            required="true">
          <cx-form-errors [control]="$any(RequestChangeAddressForm.get('city'))"></cx-form-errors>
        </label>
      </div>

      <div class="form-group">
        <label>
          <span class="label-content required"> {{'fffAccount.fffRequestChangeAddress.state'|cxTranslate }}</span>
          <ng-select formControlName="state" [items]="regions" bindLabel="name" placeholder="Select State" required
            [clearable]="false">
          </ng-select>
          <cx-form-errors [control]="$any(RequestChangeAddressForm.get('state'))"></cx-form-errors>
        </label>
      </div>

      <div class="form-group">
        <label for="zipCode-input" class="form-label">
          <span>
            {{'fffAccount.fffRequestChangeAddress.zipCode'|cxTranslate }}
          </span>
          <input type="text" class="form-control" id="address-input" formControlName="zipCode"
            placeholder="Enter zip Code" required>
          <cx-form-errors [control]="$any(RequestChangeAddressForm.get('zipCode'))"></cx-form-errors>
        </label>
      </div>

      <div class="form-group">
        <div class="d-flex justify-content-between">
          <label for="phoneNumber-input" class="form-label">
            {{'fffAccount.fffRequestChangeAddress.phoneNumber'|cxTranslate}}
          </label>
          <div class="body-text-xxs"> {{'fffAccount.fffRequestChangeAddress.optional'|cxTranslate }}</div>
        </div>
        <div class="input-wrapper">
          <cx-icon class="telephoneIcon" [type]="outlinedIconTypes.ICON_TELEPHONE"></cx-icon>
          <input type="text" class="form-control" id="phoneNumber-input" formControlName="phoneNumber"
            placeholder="Enter Phone Number">
        </div>
      </div>

      <div class="form-group">
        <label for="deaNumber-input" class="form-label">
          <span>
            {{'fffAccount.fffRequestChangeAddress.deaNumber'|cxTranslate}}
          </span>
          <input type="text" class="form-control" id="deaNumber-input" formControlName="deaNumber"
            placeholder="Enter DEA Number">
          <cx-form-errors [control]="$any(RequestChangeAddressForm.get('deaNumber'))"></cx-form-errors>
        </label>
      </div>

    </form>

  </div>

  <div class="request-footer-container">
    <button class="btn btn-primary btn-xs w-100" (click)="submitForm()">
      {{'fffAccount.fffRequestChangeAddress.submitChangeRequest'|cxTranslate }} 
    </button>
  </div>

</div>