<ng-container *ngIf="fetReturnsEntries$ | async as entries">
  <ng-container *ngIf="entries.returnEntries && entries.returnEntries.length > 0; else noData">
    <div class="container p-0">
      <div class="row">
        <div class="col-12 text-center">
          <h2 class="h2 title mb-4">{{ 'fffFetReturns.title' | cxTranslate}}</h2>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-lg-9 col-12 p-0">
          <fff-fet-returns-request-form [profile]="profile" [entries]="entries" (emitEditMode)="isEditingMode($event)" (personalDetails)="personalDetails($event)"></fff-fet-returns-request-form>
        </div>
        <div class="col-lg-3 col-12">
          <fff-fet-returns-request-cart [profile]="profile" [editingMode]="editMode" [personalData]="personalData"></fff-fet-returns-request-cart>
        </div>
      </div>
    </div>
  </ng-container>
</ng-container>
<ng-template #noData>
  <div class="text-center col-12 mt-5">
    <h4 class="h4 mb-4">{{'fffReturns.noProducts' | cxTranslate}}</h4>
    <button class="btn btn-primary m-auto" (click)="goToHomePage()">{{'fffSavedCarts.goToHomePage' | cxTranslate}}</button>
  </div>
</ng-template>
