<div class="container" *ngIf="suggestedProductsList$ | async as suggestedProductsList">
  <h3 class="section-title">{{'fffSuggestedProducts.title' | cxTranslate}}</h3>
  <div class="suggested-product_list snaps-inline snaps--individual">
    <div class="suggested-product_item media-element" *ngFor="let suggestedProduct of suggestedProductsList">
      <span class="contract-products body-text-demi-xs d-flex justify-content-center align-items-center">
        {{'fffSuggestedProducts.contractProducts' | cxTranslate}}
      </span>
      <p class="title body-text-demi-sm mb-0" >{{suggestedProduct.name}}</p>
      <div class="col-image">
          <cx-media [container]="getData(suggestedProduct)" format="cart">
          </cx-media>
      </div>
    </div>
  </div>
</div>
