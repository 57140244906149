import { ChangeDetectorRef, Component } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { FffCommunicationService } from '@app/fff-enterprise/fff-common-services/fff-communication.service';
import {
  FFFOrderEntry,
  FFFRecurringOrderEntry,
} from '@app/models/fff-cart-data.model';
import { FILLED_ICON_TYPE } from '@app/models/fff-filled-icons.model';
import { FFFCommonFunctions } from '@app/shared/fff-common-functions';
import { FffUserAccountService } from '@app/shared/services/fff-user-account.service';
import { ToastService } from '@app/shared/services/toast.service';
import { getProductImage } from '@assets/utils/fff-product-utils';
import { FffOrderFacade } from '@enterprise/fff-common-services/fff-order-facade';
import { OUTLINED_ICON_TYPE } from '@model/fff-outline-icons.model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BaseSiteService, Product, RoutingService } from '@spartacus/core';
import { MediaContainer } from '@spartacus/storefront';
import { UserProfileFacade } from '@spartacus/user/profile/root';
import { Observable, Subscription } from 'rxjs';
import { distinctUntilChanged, map, switchMap, tap } from 'rxjs/operators';
import { FffRecurringOrderDetailsService } from './fff-recurring-orders-details.service';

@Component({
  selector: 'fff-recurring-orders-details',
  templateUrl: './fff-recurring-orders-details.component.html',
})
export class FffRecurringOrdersDetailsComponent {
  profile$ = this.fffAccountService
    .getProfile()
    .pipe(map(value => value?.user));
  activeSite$ = this.baseSiteService.getActive();
  outlinedIconTypes = OUTLINED_ICON_TYPE;
  entries: FFFOrderEntry[] = [];
  showWarning: boolean = true;
  isEditable: boolean = false;
  isUpdating: boolean = false;
  subs = new Subscription();

  isDuplicate$: Observable<any> | undefined;
  filledIconTypes = FILLED_ICON_TYPE;

  maxLength: any;
  startValues: any;
  regex: any;
  regexNoSpecial = new RegExp('^[A-Za-z0-9- ]+$');
  editPOForm: UntypedFormGroup | undefined;
  userProfile$ = this.fffAccountService.getProfile();
  poRules: any;
  user$ = this.userProfile.get();
  currentPoNumber!: string;
  isUpdatedPONumberValid: boolean = false;
  updatedPONumber!: string;
  isOfVaccineTypeI = FFFCommonFunctions.isOfVaccineType;

  recurringOrderDetails$ = this.routingService
    .getRouterState()
    .pipe(
      map(routingData => {
        return routingData.state.params.cartCode;
      }),
      distinctUntilChanged()
    )
    .pipe(
      switchMap(cartCode => {
        return this.orderFacade.getRecurringOrderDetails(cartCode);
      })
    )
    .pipe(
      tap(replenishmentOrder => {
        this.entries = replenishmentOrder?.entries;
        this.currentPoNumber = replenishmentOrder?.purchaseOrderNumber;
        if (this.currentPoNumber && this.editPOForm) {
          const poNumberControl = this.editPOForm.get('poNumber');
          if (poNumberControl) {
            poNumberControl.setValue(this.currentPoNumber);
            this.fffRecurringOrderDetailsService.editPOForm.next(
              this.editPOForm
            );
          }
        }
      })
    );

  get isCartEmpty(): boolean {
    return (
      this.isEditable &&
      this.entries.filter(entry => !entry.addedToDeletion)?.length === 0
    );
  }

  get isModified(): boolean {
    return !!(
      this.isEditable &&
      (this.entries.find(entry => entry.touched) || this.isUpdatedPONumberValid)
    );
  }

  constructor(
    private routingService: RoutingService,
    private orderFacade: FffOrderFacade,
    private baseSiteService: BaseSiteService,
    private cd: ChangeDetectorRef,
    private modal: NgbModal,
    private fffAccountService: FffUserAccountService,
    private fffRecurringOrderDetailsService: FffRecurringOrderDetailsService,
    protected communicationService: FffCommunicationService,
    private changeDetectorRef: ChangeDetectorRef,
    protected userProfile: UserProfileFacade,
    protected formBuilder: UntypedFormBuilder,
    private toastService: ToastService
  ) {}

  ngOnInit(): void {
    this.subs.add(
      this.routingService.getRouterState().subscribe(context => {
        this.isEditable = !!context?.state.url?.endsWith('/edit');
        this.cd.markForCheck();
      })
    );
    this.initializePONumberField();

    this.editPOForm?.valueChanges.subscribe(() => {
      this.fffRecurringOrderDetailsService.editPOForm.next(this.editPOForm);
    });
    this.fffRecurringOrderDetailsService.editPOForm.subscribe((res: any) => {
      if (res) {
        this.isUpdatedPONumberValid =
          res.status == 'VALID' &&
          res?.value?.poNumber !== this.currentPoNumber;
        if (this.isUpdatedPONumberValid) {
          this.updatedPONumber = res?.value?.poNumber;
        }
        this.changeDetectorRef.detectChanges();
      }
    });
  }
  initializePONumberField() {
    this.userProfile$.subscribe(account => {
      if (account) {
        this.communicationService
          .getPOValidation(account.selected.uid)
          .subscribe((res: any) => {
            if (res) {
              this.poRules = res;
              this.maxLength =
                this.poRules && this.poRules.maxCharacters
                  ? this.poRules.maxCharacters
                  : 20;
              this.startValues =
                this.poRules && this.poRules.startsWith
                  ? this.poRules.startsWith.split(',')
                  : [];
              this.regex = new RegExp(`^(${this.startValues.join('|')})`);

              let validators = [
                Validators.maxLength(this.maxLength),
                Validators.pattern(this.regexNoSpecial),
              ];
              validators.push(
                Validators.pattern(this.regex),
                Validators.required
              );

              this.editPOForm = this.formBuilder.group({
                poNumber: ['', validators],
              });
              setTimeout(() => {
                if (this.currentPoNumber && this.editPOForm) {
                  const poNumberControl = this.editPOForm.get('poNumber');
                  if (poNumberControl) {
                    poNumberControl.setValue(this.currentPoNumber);
                  }
                }
              }, 0);
            }
          });
      }
    });
  }
  onPOchange() {
    this.fffRecurringOrderDetailsService.editPOForm.next(this.editPOForm);
  }
  formatStartingPOs(str: string) {
    return str
      .split(',')
      .join(', ')
      .replace(/(,\s)(?!.*,\s)/, ' or ');
  }

  clearTimeInSchedule(schedule: string): string {
    const TIME_REGEXP = new RegExp(/at ([0-9]?\d):([0-9]\d):([0-9]\d)/g);
    if (schedule) {
      return schedule.replace(TIME_REGEXP, '');
    }
    return '';
  }

  getData(product: Product) {
    return getProductImage(product.images) as MediaContainer;
  }

  goToRecurringOrdersDetails(cartCode: string): void {
    this.routingService.go(['/my-account/recurring-order/', cartCode]);
  }

  goToRecurringOrdersDetailsList(): void {
    this.routingService.go(['/my-account/recurring-orders']);
  }

  editRecurringOrder(cartCode: string): void {
    this.routingService.go([
      '/my-account/recurring-order/' + cartCode + '/edit',
    ]);
  }

  saveDetails(cartCode: string): void {
    this.toggleUpdateState(true);
    if (this.isCartEmpty) {
      this.cancelRecurringOrder(cartCode);
      this.toastService.showSuccess(
        'fffOrderSummary.messages.cancelRecurringOrder',
        {
          delay: 3000,
          i18nParams: {
            recurringOrder: cartCode,
          },
        }
      );
      return;
    }

    let entries: FFFRecurringOrderEntry[] = this.entries.map(
      entry =>
        ({
          entryNumber: entry.entryNumber,
          operation: entry.addedToDeletion ? 'delete' : 'update',
          ...(!entry.addedToDeletion && { quantity: +(entry?.quantity || 0) }),
        } as FFFRecurringOrderEntry)
    );

    this.fffRecurringOrderDetailsService
      .editRecurringOrderEntries(cartCode, entries)
      .pipe(
        tap(() => {
          if (this.isUpdatedPONumberValid) {
            this.fffRecurringOrderDetailsService
              .editRecurringOrderPOnumber(cartCode, this.updatedPONumber)
              .pipe(
                tap(() => {
                  this.goToRecurringOrdersDetails(cartCode);
                })
              )
              .subscribe(() => {
                this.toggleUpdateState(false);
              });
          } else {
            this.goToRecurringOrdersDetails(cartCode);
          }
        })
      )
      .subscribe(() => {
        this.toggleUpdateState(false);
        this.toastService.showSuccess(
          'fffOrderSummary.messages.updateRecurringOrder',
          {
            delay: 3000,
            i18nParams: {
              recurringOrder: cartCode,
            },
          }
        );
      });
  }

  isInvalidEntry(entry: FFFOrderEntry): boolean {
    return !!entry.invalid && !!entry.canShowTooltip;
  }

  markEntryAsTouched(entry: FFFOrderEntry, isTouched: boolean): void {
    entry.touched = isTouched;
    this.cd.markForCheck();
  }

  toggleUpdateState(isUpdating: boolean): void {
    this.isUpdating = isUpdating;
    this.cd.markForCheck();
  }

  deleteEntry(modalInstance: any, entry: FFFOrderEntry): void {
    this.markEntryAsTouched(entry, true);

    const modalRef = this.modal.open(modalInstance, {
      windowClass: 'fff-modal',
    });
    modalRef.dismissed.subscribe(reason => {
      if (reason) {
        entry.addedToDeletion = true;
        this.cd.markForCheck();
      }
    });
  }

  validateEntryQuantity(entry: FFFOrderEntry) {
    entry.canShowTooltip = true;
    const regex = new RegExp('^[0-9]+$');
    const isReqExpPassed = regex.test((entry.quantity || '').toString());
    entry.invalid = !isReqExpPassed || !entry.quantity || entry.quantity <= 0;

    if (!entry.invalid) {
      this.markEntryAsTouched(entry, true);
    } else {
      this.markEntryAsTouched(entry, false);
    }
    this.cd.markForCheck();
  }

  cancelRecurringOrder(cartCode: string): void {
    this.orderFacade.cancelRecurringOrder(cartCode).subscribe(() => {
      this.goToRecurringOrdersDetailsList();
    });
  }
}
