import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { AuthService, RoutingService } from '@spartacus/core';
import { Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { FffProductService } from '../fff-common-services/fff-product.service';

@Injectable({
  providedIn: 'root',
})
export class FFFProductExclusionGuard  {
  constructor(
    private productService: FffProductService,
    private routingService: RoutingService,
    private authService: AuthService
  ) {}

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
    return this.authService.isUserLoggedIn().pipe(
      switchMap(isLoggedIn => {
        if (isLoggedIn) {
          return this.productService.getProductExclusionData(
            route?.params?.productCode
          );
        }
        return of(null);
      }),
      map((productData: any) => {
        if (productData && !productData?.isExcluded) {
          this.routingService.go(['/']);
          return false;
        } else {
          return true;
        }
      })
    );
  }
}
