import { Component, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { FffOrderFacade } from "@enterprise/fff-common-services/fff-order-facade";
import {
  FFFOrderHistoryConfirmEmailPopupComponent
} from "@enterprise/fff-order/fff-order-history/fff-order-history-confirm-email-popup/fff-order-history-confirm-email-popup.component";
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FffDrawerService } from '@shared/drawer/fff-drawer.service';
import { CustomFormValidators } from '@spartacus/storefront';
import { take } from "rxjs/operators";

@Component({
  selector: 'fff-return-send-rga-copy.component',
  templateUrl: './fff-return-send-rga-copy.component.html',
})
export class FffReturnSendRGACopyComponent implements OnInit {
  sendRGACopyForm: UntypedFormGroup = {} as UntypedFormGroup;
  orderId: string = '';
  constructor(
    private drawerService: FffDrawerService,
    private formBuilder: UntypedFormBuilder,
    private orderFacade: FffOrderFacade,
    private modalService: NgbModal
  ) {
  }
  ngOnInit() {
    this.sendRGACopyForm = this.formBuilder.group({
      emails: [''],
    });
    this.drawerService.getContent().pipe(take(1)).subscribe((res: any) => {
      this.orderId = res.data;
    });
  }

  sendEmails(): void {
    if (this.sendRGACopyForm.invalid && this.sendRGACopyForm.get('emails')?.value === '') {
      return;
    }
    this.orderFacade.resendEmail(this.orderId, this.sendRGACopyForm.get('emails')?.value).subscribe((res: any) => {
      this.drawerService.closeDrawer();
      if (res) {
        this.modalService.open(FFFOrderHistoryConfirmEmailPopupComponent, {
          centered: true,
          size: 'md',
          backdrop: 'static',
          modalDialogClass: 'modal-container',
        });
      }
    });

  }
  checkEmail(): boolean {
    const emailInputValue = this.sendRGACopyForm.get('emails')?.value;
    const emails = emailInputValue.replace(/ /g, '').split(',');
    const emailsErrors = !emails.some((email: any) => {
      const validator = CustomFormValidators.emailValidator({ value: email } as AbstractControl);
      if (validator) {
        this.sendRGACopyForm.get('emails')?.setErrors(validator);
        return true;
      }
      return false;
    });
    return emailsErrors;
  }
}
