import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  FffAddToFavoritesComponent
} from '@enterprise/fff-favorites/shared-components/fff-add-to-favorites/fff-add-to-favorites.component';
import { I18nModule } from '@spartacus/core';
import { IconModule } from '@spartacus/storefront';



@NgModule({
  declarations: [ FffAddToFavoritesComponent ],
  imports: [
    CommonModule,
    I18nModule,
    IconModule,
  ],
  exports: [ FffAddToFavoritesComponent ]
})
export class FffAddToFavoritesModule { }
