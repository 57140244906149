import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { I18nModule } from '@spartacus/core';
import { IconModule } from '@spartacus/storefront';
import { FffOrderStatusControlComponent } from './fff-order-status-control/fff-order-status-control.component';
import { FffRadioListControlComponent } from './fff-radio-list-control/fff-radio-list-control.component';
import { FffTagsComponent } from './fff-tags/fff-tags.component';

@NgModule({
  declarations: [
    FffOrderStatusControlComponent,
    FffTagsComponent,
    FffRadioListControlComponent,
  ],
  imports: [CommonModule, FormsModule, I18nModule, IconModule],
  exports: [
    FffOrderStatusControlComponent,
    FffTagsComponent,
    FffRadioListControlComponent,
  ],
})
export class FffFormControlsModule {}
