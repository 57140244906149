<div class="col-12 d-flex flex-wrap mt-4 p-0">
  <div class="col-6 pl-0 pr-2" *ngIf="product.adminSet">
    <div class="features-product p-2">
      <input
        class="checkbox checkbox-sm"
        type="checkbox"
        [(ngModel)]="isAdminChecked"
        (change)="checkCheckbox('adminSet', $event)"
        [id]="'adminset' + product.code"
        name="adminset"
      />
      <label class="checkbox-label" [for]="'adminset' + product.code">{{
        'fffProduct.lblAdminSet' | cxTranslate
      }}</label>
    </div>
  </div>

  <div class="col-6 pl-1 pr-0" *ngIf="product.yconnector">
    <div class="features-product p-2">
      <input
        class="checkbox checkbox-sm"
        type="checkbox"
        [(ngModel)]="isYconnectorChecked"
        (change)="checkCheckbox('yconnector', $event)"
        [id]="'yconnector' + product.code"
        name="yconnector"
      />
      <label class="checkbox-label" [for]="'yconnector' + product.code">{{
        'fffProduct.lblYconnector' | cxTranslate
      }}</label>
    </div>
  </div>
</div>
