<div
  class="col-12 d-flex flex-wrap mt-4 px-0 dd-container"
  *ngIf="product?.assayIndicator && dataList.length > 0"
>
  <div class="col-12 features-product p-0">
    <div
      class="d-flex myc body-text cursor-pointer shipping-dropdown border-4 rounded fff-product-strength-dropdown"
      [attr.id]="'dropdownMenuString'"
      data-toggle="dropdown"
      aria-haspopup="true"
      aria-expanded="false"
      [ngClass]="{
        disabled: dropdownDisabled
      }"
    >
      <div
        class="col-9 p-3 body-text-demi dropdown-title text-brand-primary-80 mb-0"
      >
        <span>{{ selectedOption }}</span>
      </div>
      <div
        class="col-3 p-3 text-brand-primary-80 d-flex justify-content-end dropdown-icon"
      >
        <cx-icon
          class="cursor-pointer"
          [type]="outlinedIconTypes.ICON_CHEVRON_DOWN"
        ></cx-icon>
      </div>
    </div>

    <div
      class="dropdown-options mt-n1 p-1 w-100 body-text-sm dropdown-menu"
      [attr.aria-labelledby]="'dropdownMenuString'"
    >
      <div
        *ngFor="let data of dataList; let i = index"
        class="d-flex p-2 cursor-pointer"
        (click)="selectOption(data)"
      >
        <div class="col-12 px-0 row-style">
          <span>{{ data }}</span>
        </div>
      </div>
    </div>
  </div>

  <div
    class="col-12 features-product p-0 mt-4"
    *ngIf="specialPriceList.length > 0"
  >
    <div
      class="d-flex myc body-text cursor-pointer shipping-dropdown border-4 rounded"
      [attr.id]="'dropdownMenuPrice'"
      data-toggle="dropdown"
      aria-haspopup="true"
      aria-expanded="false"
    >
      <div
        *ngIf="
          !(
            selectedPriceOption.batch &&
            selectedPriceOption.batch !== 'undefined'
          )
        "
        class="col-9 p-3 body-text-demi dropdown-title text-brand-primary-80 mb-0"
      >
        <span>{{ 'fffSpecialPrice.defaultOption' | cxTranslate }}</span>
      </div>

      <div
        *ngIf="
          selectedPriceOption &&
          selectedPriceOption.batch &&
          selectedPriceOption.batch !== 'undefined'
        "
        class="col-9 p-3 d-flex body-text-demi dropdown-title text-brand-primary-80 mb-0"
      >
        <span
          >{{ selectedPriceOption.batch }} -
          {{ selectedPriceOption.parsedExpirationDate | date : 'MM/dd/yyyy' }} -
          {{ selectedPriceOption.batchStock }}
          {{ 'fffSpecialPrice.available' | cxTranslate }}</span
        >
        <div
          class="ml-2 bag"
          *ngIf="
            product?.vintage &&
            selectedPriceOption.diffDays < MAX_DAYS_SHORT_DATED
          "
        >
          {{ 'fffSpecialPrice.shortDated' | cxTranslate }}
        </div>
      </div>

      <div class="col-3 p-3 text-brand-primary-80 d-flex justify-content-end">
        <cx-icon
          class="cursor-pointer"
          [type]="outlinedIconTypes.ICON_CHEVRON_DOWN"
        ></cx-icon>
      </div>
    </div>

    <div
      class="dropdown-options mt-n1 p-1 w-100 body-text-sm dropdown-menu"
      [attr.aria-labelledby]="'dropdownMenuPrice'"
    >
      <div *ngFor="let priceData of specialPriceList" class="d-flex p-2">
        <div
          class="col-12 px-0 d-flex cursor-pointer row-style"
          (click)="selectPriceOption(priceData)"
        >
          <div class="col-6 px-0">
            <span>
              {{ priceData.batch }} -
              {{ priceData.parsedExpirationDate | date : 'MM/dd/yyyy' }} -
              {{ priceData.batchStock }}
              {{ 'fffSpecialPrice.available' | cxTranslate }}
            </span>
          </div>
          <div
            class="col-6 px-0 text-right"
            *ngIf="
              product?.vintage && priceData.diffDays < MAX_DAYS_SHORT_DATED
            "
          >
            <span class="bag">{{
              'fffSpecialPrice.shortDated' | cxTranslate
            }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div
  class="col-12 d-flex flex-wrap mt-4 px-0"
  *ngIf="!product?.assayIndicator && specialPriceList.length > 0"
>
  <div
    class="col-12 features-product p-0 mt-4"
    *ngIf="specialPriceList.length > 0"
  >
    <div
      class="d-flex myc body-text cursor-pointer shipping-dropdown border-4 rounded"
      [attr.id]="'dropdownMenuPrice'"
      data-toggle="dropdown"
      aria-haspopup="true"
      aria-expanded="false"
    >
      <div
        *ngIf="
          !(
            selectedPriceOption.batch &&
            selectedPriceOption.batch !== 'undefined'
          )
        "
        class="col-9 p-3 body-text-demi dropdown-title text-brand-primary-80 mb-0"
      >
        <span>{{ 'fffSpecialPrice.defaultOption' | cxTranslate }}</span>
      </div>

      <div
        *ngIf="
          selectedPriceOption &&
          selectedPriceOption.batch &&
          selectedPriceOption.batch !== 'undefined'
        "
        class="col-9 p-3 d-flex body-text-demi dropdown-title text-brand-primary-80 mb-0"
      >
        <span
          >{{ selectedPriceOption.batch }} -
          {{ selectedPriceOption.parsedExpirationDate | date : 'MM/dd/yyyy' }} -
          {{ selectedPriceOption.batchStock }}
          {{ 'fffSpecialPrice.available' | cxTranslate }}</span
        >
        <div
          class="ml-2 bag"
          *ngIf="
            product?.vintage &&
            selectedPriceOption?.diffDays < MAX_DAYS_SHORT_DATED
          "
        >
          {{ 'fffSpecialPrice.shortDated' | cxTranslate }}
        </div>
      </div>

      <div class="col-3 p-3 text-brand-primary-80 d-flex justify-content-end">
        <cx-icon
          class="cursor-pointer"
          [type]="outlinedIconTypes.ICON_CHEVRON_DOWN"
        ></cx-icon>
      </div>
    </div>

    <div
      class="dropdown-options mt-n1 p-1 w-100 body-text-sm dropdown-menu"
      [attr.aria-labelledby]="'dropdownMenuPrice'"
    >
      <div *ngFor="let priceData of specialPriceList" class="d-flex p-2">
        <div
          class="col-12 px-0 d-flex cursor-pointer row-style"
          (click)="selectPriceOption(priceData)"
        >
          <div class="col-6 px-0">
            <span>
              {{ priceData.batch }} -
              {{ priceData.parsedExpirationDate | date : 'MM/dd/yyyy' }} -
              {{ priceData.batchStock }}
              {{ 'fffSpecialPrice.available' | cxTranslate }}
            </span>
          </div>
          <div
            class="col-6 px-0 text-right"
            *ngIf="
              product?.vintage && priceData?.diffDays < MAX_DAYS_SHORT_DATED
            "
          >
            <span class="bag">{{
              'fffSpecialPrice.shortDated' | cxTranslate
            }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
