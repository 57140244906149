<div class="allocations-mobile-tabs d-lg-none d-block">
  <div class="row">
    <div class="col-12 text-center">
      <div ngbDropdown #dropdownButton="ngbDropdown" class="d-inline-block" [autoClose]="true">
        <button type="button" ngbDropdownToggle id="dropdownBasic1" class="body-text-lg allocations-tabs-dropdown">
          <span>{{ 'fffAllocationsTabs.allocationsTabsDropdownTitle' | cxTranslate }}</span>&nbsp;
          <cx-icon class="cx-icon" [type]="outlinedIconTypes.ICON_CHEVRON_DOWN"></cx-icon>
        </button>
        <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
          <ng-container *ngTemplateOutlet="allocationsTabs"></ng-container>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="allocations-desktop-tabs d-lg-block d-none">
  <ng-container *ngTemplateOutlet="allocationsTabs"></ng-container>
</div>
<ng-template #allocationsTabs>
  <div class="container mb-lg-5 mt-lg-3">
    <div class="row">
      <div class="col-12 d-flex flex-wrap">
        <div class="mr-auto item body-text-lg cursor-pointer"
          [class.selected]="allocationNavNodes.currentAllocations === currentNavLinkSelected"
          (click)="emitSelectedNavLink(allocationNavNodes.currentAllocations);dropdownButton.close()">
          {{ 'fffAllocationsTabs.currentAllocations' | cxTranslate }}
        </div>
        <div class="mr-auto item body-text-lg cursor-pointer" *ngIf="isApprovalRole"
          [class.selected]="allocationNavNodes.interactiveAllocations === currentNavLinkSelected"
          (click)="emitSelectedNavLink(allocationNavNodes.interactiveAllocations);dropdownButton.close()">
          {{ 'fffAllocationsTabs.interactiveAllocations' | cxTranslate }}
        </div>
        <div class="mr-auto item body-text-lg cursor-pointer"
          [class.selected]="allocationNavNodes.allocationsHistory === currentNavLinkSelected"
          (click)="emitSelectedNavLink(allocationNavNodes.allocationsHistory);dropdownButton.close()">
          {{ 'fffAllocationsTabs.allocationsHistory' | cxTranslate }}
        </div>
        <!--<div class="m-auto item body-text-lg cursor-pointer" [class.selected]="allocationNavNodes.newCommitmentRequest === currentNavLinkSelected"
             (click)="emitSelectedNavLink(allocationNavNodes.newCommitmentRequest)">
          {{ 'fffAllocationsTabs.newCommitmentRequest' | cxTranslate }}
        </div>
        <div class="mr-auto item body-text-lg cursor-pointer" [class.selected]="allocationNavNodes.approveCommitmentRequest === currentNavLinkSelected"
             (click)="emitSelectedNavLink(allocationNavNodes.approveCommitmentRequest);dropdownButton.close()">
          {{ 'fffAllocationsTabs.approveCommitmentRequest' | cxTranslate }}
        </div>-->
      </div>
    </div>
  </div>
</ng-template>
