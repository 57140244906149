import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { FffCommunicationService } from '@app/fff-enterprise/fff-common-services/fff-communication.service';
import { FffPrebookService } from '@app/fff-enterprise/fff-common-services/fff-prebook.service';
import {
  PreBookCategories,
  PreBookStages,
} from '@app/shared/services/enum.service';
import { fffPrebook } from '@assets/translations/en/fff-prebook';
import { UserAccountFacade } from '@spartacus/user/account/root';
import { Observable, Subject, Subscription } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import { FffPrebookCartService } from '../services/fff-prebook-cart.service';

@Component({
  selector: 'fff-prebook-checkout-recommendation',
  templateUrl: './fff-prebook-checkout-recommendation.component.html',
  styleUrls: ['./fff-prebook-checkout-recommendation.component.scss'],
})
export class FffPrebookCheckoutRecommendationComponent implements OnInit {
  season: string = '';
  previousSeason: string = '';
  suggestedQtyLabel: string = '';
  isSuggestedQtyAvailable: boolean = false;
  currentYearQty: number = 0;
  totalQuantity: number = 0;
  stageData: any;
  prebookCategory!: string;
  @Input() stageId!: string;
  vaccineLabel: string = '';
  isQtySufficient!: boolean;
  warningLabel!: string;
  saving: boolean = false;
  todaysQuantity: number = 0;
  unsubscribe$: Subject<void> = new Subject<void>();
  subs = new Subscription();
  labelTotal!: string;
  labelTodaysOrder!: string;
  suggestedQty: number = 0;
  hideSuggestion!: boolean;
  todaysTotalDoses: number = 0;
  suggestedTotalDoses: number = 0;
  currentYearDoses: number = 0;
  totalDosesForCurrentYear: number = 0;

  userData$: Observable<any> = this.userAccountDetails.get();
  b2bUnitData$: Observable<any> = this.userData$.pipe(
    switchMap(user => {
      return this.communicationService.getCurrentAccount(user);
    })
  );

  constructor(
    public preBookService: FffPrebookService,
    private communicationService: FffCommunicationService,
    private userAccountDetails: UserAccountFacade,
    private cdr: ChangeDetectorRef,
    private fffPrebookCartService: FffPrebookCartService
  ) {}

  ngOnInit(): void {
    this.preBookService.getAppProperties();
    this.preBookService.getSeasonData().subscribe(data => {
      this.season = data;
    });
    this.preBookService.getPreviousSeasonData().subscribe(data => {
      this.previousSeason = data;
    });
    this.labelTotal = fffPrebook.orderSummaryTotalLabel;
    this.labelTodaysOrder = fffPrebook.todaysOrder;
    switch (this.stageId) {
      case PreBookStages.flu: {
        this.isSuggestedQtyAvailable = true;
        this.suggestedQtyLabel =
          this.previousSeason + ' ' + fffPrebook.seasonQty;
        this.prebookCategory = PreBookCategories.flu;
        this.vaccineLabel = fffPrebook.flu;
        break;
      }
      case PreBookStages.covid: {
        this.isSuggestedQtyAvailable = true;
        this.suggestedQtyLabel = fffPrebook.suggestedQty;
        this.prebookCategory = PreBookCategories.covid;
        this.vaccineLabel = fffPrebook.covid;
        break;
      }
      case PreBookStages.rsv: {
        this.isSuggestedQtyAvailable = false;
        this.prebookCategory = PreBookCategories.rsv;
        this.vaccineLabel = fffPrebook.rsv;
        this.hideSuggestion = true;
        break;
      }
      default: {
        this.isSuggestedQtyAvailable = true;
        return;
      }
    }
    this.loadTotals();
    this.fetchSeasonalData(this.prebookCategory);
  }
  fetchSeasonalData(category: string): void {
    this.b2bUnitData$.subscribe((b2bUnit: any) => {
      if (b2bUnit && b2bUnit.crossUpSellingRecommendations) {
        this.stageData = b2bUnit.crossUpSellingRecommendations.filter(
          (data: any) => {
            return data.categoryName == category;
          }
        )[0];
        this.suggestedQty =
          this.stageId == PreBookStages.covid
            ? this.fffPrebookCartService.getFluTotalQuantity()
            : this.stageData.previousSeasonBoxesOrdered;
        this.suggestedTotalDoses =
          this.stageId == PreBookStages.covid
            ? this.fffPrebookCartService.getTotalFlueDoses()
            : this.stageData?.previousSeasonDosesOrdered
            ? this.stageData?.previousSeasonDosesOrdered
            : this.suggestedQty * 10;
        this.isSuggestedQtyAvailable = this.suggestedQty !== 0;
        this.currentYearQty = this.stageData.currentSeasonBoxesOrdered;
        this.currentYearDoses = this.stageData?.currentSeasonDosesOrdered
          ? this.stageData?.currentSeasonDosesOrdered
          : this.currentYearQty * 10;
        this.totalQuantity = this.currentYearQty + this.todaysQuantity;
        this.totalDosesForCurrentYear =
          this.currentYearDoses + this.todaysTotalDoses;
        if (this.stageId == PreBookStages.flu) {
          this.fffPrebookCartService.setFluTotalQuantity(this.totalQuantity);
          this.fffPrebookCartService.setTotalFluDoses(
            this.totalDosesForCurrentYear
          );
        }
        if (this.suggestedQty != null && this.totalQuantity != null) {
          this.setSuggestionColor(
            this.suggestedQty,
            this.totalQuantity,
            this.stageId
          );
          this.cdr.detectChanges();
        }
      }
    });
  }
  setSuggestionColor(
    suggestedQty: number,
    currentQty: number,
    stageId: string
  ) {
    if (stageId && stageId == PreBookStages.rsv) {
      this.isQtySufficient = currentQty > 0;
      this.warningLabel = fffPrebook.rsvRecommendationLabel;
    } else if (stageId == PreBookStages.covid) {
      this.isQtySufficient = currentQty >= suggestedQty;
      this.warningLabel = fffPrebook.belowRecommendedQty;
    } else {
      this.isQtySufficient = currentQty >= suggestedQty;
      this.warningLabel = fffPrebook.belowPreviousSeasonQty;
    }
  }
  ngOnDestroy(): void {
    this.subs.unsubscribe();

    this.unsubscribe$.next();
    this.unsubscribe$.complete();
    this.todaysQuantity = 0;
    this.todaysTotalDoses = 0;
  }

  loadTotals() {
    this.todaysQuantity = 0;
    this.todaysTotalDoses = 0;
    this.subs.add(
      this.fffPrebookCartService
        .getEntriesTotalByType(this.stageId)
        .pipe(
          tap(quantityInfo => {
            if (this.todaysQuantity !== quantityInfo.totalQuantity) {
              this.todaysQuantity = quantityInfo.totalQuantity || 0;
              this.todaysTotalDoses = quantityInfo.totalDoses || 0;
              this.totalQuantity = this.currentYearQty + this.todaysQuantity;
              this.totalDosesForCurrentYear =
                this.currentYearDoses + this.todaysTotalDoses;
              if (this.stageId == PreBookStages.flu) {
                this.fffPrebookCartService.setFluTotalQuantity(
                  this.totalQuantity
                );
                this.fffPrebookCartService.setTotalFluDoses(
                  this.totalDosesForCurrentYear
                );
              }
            }
            this.cdr.markForCheck();
          })
        )
        .subscribe()
    );
  }
}
