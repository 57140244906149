import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { FffDrawerModule } from '@app/shared/drawer/fff-drawer.module';
import { FffTableModule } from '@app/shared/table/fff-table.module';
import { NgbDatepickerModule, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import {
  AuthGuard,
  CmsConfig,
  I18nModule,
  UrlModule,
  provideDefaultConfig,
} from '@spartacus/core';
// import { OrderOverviewModule } from '@spartacus/order/components';
import { FFFLoadersModule } from '@app/shared/components/fff-loaders/fff-loaders.module';
import { FffSharedPipesModule } from '@app/shared/pipes/fff-shared-pipes/fff-shared-pipes.module';
import { OrderCoreModule } from '@spartacus/order/core';
import {
  FormErrorsModule,
  GenericLinkModule,
  IconModule,
  MediaModule,
  OutletModule,
} from '@spartacus/storefront';
import { FffAddCreditCardModule } from './fff-add-credit-card/fff-add-credit-card.module';
import { FffInvoiceDetailComponent } from './fff-invoice-detail/fff-invoice-detail.component';
import { FffInvoiceListDrawerBodyComponent } from './fff-invoice-list-drawer/fff-invoice-list-drawer-body.component';
import { FffInvoiceListDrawerFooterComponent } from './fff-invoice-list-drawer/fff-invoice-list-drawer-footer.component';
import { FffInvoiceListComponent } from './fff-invoice-list/fff-invoice-list.component';
import { FffInvoicePaymentConfirmationEntriesComponent } from './fff-invoice-payment-drawer/components/fff-invoice-payment-confirmation-entries/fff-invoice-payment-confirmation-entries.component';
import { FffInvoicePaymentConfirmationHeaderComponent } from './fff-invoice-payment-drawer/components/fff-invoice-payment-confirmation-header/fff-invoice-payment-confirmation-header.component';
import { FffInvoicePaymentEntriesComponent } from './fff-invoice-payment-drawer/components/fff-invoice-payment-entries/fff-invoice-payment-entries.component';
import { FffInvoicePaymentFailedDialogComponent } from './fff-invoice-payment-drawer/components/fff-invoice-payment-failed-dialog/fff-invoice-payment-failed-dialog.component';
import { FffInvoicePaymentTopHeaderComponent } from './fff-invoice-payment-drawer/components/fff-invoice-payment-top-header/fff-invoice-payment-top-header.component';
import { FffSelectCreditCardComponent } from './fff-invoice-payment-drawer/components/fff-select-credit-card/fff-select-credit-card.component';
import { FffInvoicePaymentDrawerComponent } from './fff-invoice-payment-drawer/fff-invoice-payment-drawer.component';

@NgModule({
  declarations: [
    FffInvoiceListComponent,
    FffInvoiceDetailComponent,
    FffInvoiceListDrawerBodyComponent,
    FffInvoiceListDrawerFooterComponent,
    FffInvoicePaymentDrawerComponent,
    FffInvoicePaymentTopHeaderComponent,
    FffInvoicePaymentEntriesComponent,
    FffSelectCreditCardComponent,
    FffInvoicePaymentConfirmationHeaderComponent,
    FffInvoicePaymentFailedDialogComponent,
    FffInvoicePaymentConfirmationEntriesComponent,
  ],
  imports: [
    UrlModule,
    BrowserModule,
    CommonModule,
    I18nModule,
    IconModule,
    OrderCoreModule,
    // OrderOverviewModule,
    OutletModule,
    MediaModule,
    RouterModule,
    FffTableModule,
    GenericLinkModule,
    FormsModule,
    ReactiveFormsModule,
    FormErrorsModule,
    NgbDatepickerModule,
    FffDrawerModule,
    FffAddCreditCardModule,
    FffSharedPipesModule,
    FFFLoadersModule,
    NgbModule,
  ],
  exports: [
    FffInvoicePaymentDrawerComponent,
    FffInvoicePaymentTopHeaderComponent,
    FffInvoicePaymentEntriesComponent,
    FffSelectCreditCardComponent,
    FffInvoicePaymentFailedDialogComponent,
  ],
  providers: [
    provideDefaultConfig(<CmsConfig>{
      cmsComponents: {
        FFFInvoiceHistoryComponent: {
          component: FffInvoiceListComponent,
          guards: [AuthGuard],
        },
        FFFInvoiceHistoryDetailsComponent: {
          component: FffInvoiceDetailComponent,
          guards: [AuthGuard],
        },
      },
    }),
  ],
})
export class FffInvoiceModule {}
