export const fffForgotPassword = {
  fffForgotPassword: {
    forgotPasswordForm: {
      emailAddress: 'Email Address',
    },
    header: 'Forgot Password',
    submit: 'Submit'
  }
};


export const fffForgotPasswordConfirmation = {
  fffForgotPasswordConfirmation: {
    header: 'Password Reset Link Sent to Your Email',
    text1: 'We\'ve sent a password reset link to your email address.',
    text2: `<p>If you didn\'t receive the email, check your spam folder,</p>
          <p>or <a class="resent-email cursor-pointer text-underline text-white">click here</a> to resend the password reset email.</p>`
  }
};

