import {
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { FffAllocationService } from '@app/fff-enterprise/fff-common-services/fff-allocation.service';
import { FffCommunicationService } from '@app/fff-enterprise/fff-common-services/fff-communication.service';
import { FffPrebookService } from '@app/fff-enterprise/fff-common-services/fff-prebook.service';
import { FffPrebookCartService } from '@app/fff-enterprise/fff-prebook-category/services/fff-prebook-cart.service';
import { FFFProductInputEntry } from '@app/models/fff-cart-data.model';
import { ProductFocusState } from '@app/reducers';
import { FFFCommonFunctions } from '@app/shared/fff-common-functions';
import { FffUserAccountService } from '@app/shared/services/fff-user-account.service';
import { BASE_URL_KEYS, MAX_DIFF_DAYS } from '@config/content/constants';
import { FffProductsService } from '@enterprise/fff-common-services/fff-products.service';
import { FffAddToCartNoticeComponent } from '@enterprise/fff-product/fff-add-to-cart-notice/fff-add-to-cart-notice.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { ActiveCartFacade } from '@spartacus/cart/base/root';
import { BaseSiteService, isNotUndefined } from '@spartacus/core';
import { Subject, Subscription } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { OUTLINED_ICON_TYPE } from 'src/app/models/fff-outline-icons.model';
import {
  ADD_PRODUCT_FOCUSED,
  B2BUnitState,
  CustomerState,
  ProductAdminSet,
  ProductSpecialPriceState,
  ProductStrengthState,
} from 'src/app/reducers';
import { FffProductNotOrderableComponent } from '../fff-product-not-orderable/fff-product-not-orderable.component';

@Component({
  selector: 'fff-split-billing',
  templateUrl: './fff-split-billing.component.html',
})
export class FffSplitBillingComponent implements OnInit, OnChanges, OnDestroy {
  @Input() product!: any;
  @Input() isSplitBillerAccount: boolean = false;
  @Input() allocation: any;
  @Input() pricesData: any;
  @Input() prebookFormEnabled: boolean = false;

  private subscription: Subscription = new Subscription();
  private unsubscribe$: Subject<void> = new Subject<void>();

  batchQtyValidation: any = null;

  private MAX_DIFF_DAYS: number = 180;
  batchStockAvailable: number = 0;

  isLoading: boolean = false;
  b2bUnit: B2BUnitState['B2BUnit'] = {} as B2BUnitState['B2BUnit'];
  price: any[] = [];
  outlinedIconTypes = OUTLINED_ICON_TYPE;
  specialPriceSelected: any = {};
  productStrengthSelected: any = {};
  cart: any;
  allocationMessageWarn: string = '';
  adminSetSelected: any = {};
  activeSite: string = '';
  entry: FFFProductInputEntry | undefined;
  selectedEntryPrices: any;
  tooltipMessage: string = '';
  BASE_URL_KEYS = BASE_URL_KEYS;
  shortDated: boolean = false;
  isDirectOrder: boolean = false;
  showAsSoldOutIfExcluded: boolean = false;
  preBookEnable: boolean = false;
  fluSeasonCurrent: string = '';
  isPrebookQuantityLoaded: boolean = false;
  isSeasonTooltipVisible: boolean = false;
  isShowAddToCartNotice: boolean = false;
  isStrengthNotSelected: boolean = false;
  getAltPriceI = FFFCommonFunctions.getAltPrice;
  isOfVaccineTypeI = FFFCommonFunctions.isOfVaccineType;
  getVaccineAltPriceI = FFFCommonFunctions.getVaccineAltPrice;

  constructor(
    private communicationService: FffCommunicationService,
    private store: Store<ProductFocusState>,
    private changeDetectorRef: ChangeDetectorRef,
    private storeB2b: Store<B2BUnitState>,
    private storeProductStrength: Store<ProductStrengthState>,
    private storeProductSpecialPrice: Store<ProductSpecialPriceState>,
    private storeAdminSet: Store<ProductAdminSet>,
    private storeCustomer: Store<CustomerState>,
    private fffActiveCartService: ActiveCartFacade,
    private baseSiteService: BaseSiteService,
    private fffProductService: FffProductsService,
    private fffAllocationService: FffAllocationService,
    private fffPrebookCartService: FffPrebookCartService,
    private preBookService: FffPrebookService,
    private userAccountService: FffUserAccountService,
    protected modalService: NgbModal
  ) {
    this.storeB2b
      .select(state => state.B2BUnit)
      .subscribe(b2bUnitId => {
        if (b2bUnitId && b2bUnitId.currentB2BUnit) {
          this.b2bUnit = b2bUnitId;
          this.isSplitBillerAccount =
            !!b2bUnitId.currentB2BUnit.autoSplitBillerActive;
          this.updatePrices();
        }
      });

    this.storeProductSpecialPrice
      .select((state: any) => {
        return state.ProductSpecialPrice;
      })
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(specialPrice => {
        if (
          specialPrice &&
          specialPrice.data[this.product?.code] &&
          specialPrice.data[this.product?.code].data &&
          this.isDifferentSpecialPrice(
            this.specialPriceSelected,
            specialPrice.data[this.product.code].data
          )
        ) {
          this.specialPriceSelected = specialPrice.data[this.product.code].data;
          this.shortDated =
            this.specialPriceSelected.batch &&
            this.specialPriceSelected.batch !== 'undefined' &&
            this.specialPriceSelected.diffDays <= MAX_DIFF_DAYS;
          this.updatePriceWithSpecialDropDown(
            specialPrice.data[this.product.code].data
          );
        }
      });

    this.storeProductStrength
      .select((state: any) => {
        return state.ProductStrength;
      })
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(productStrength => {
        if (
          productStrength &&
          productStrength.data[this.product?.code] &&
          productStrength.data[this.product?.code].data
        ) {
          this.productStrengthSelected =
            productStrength.data[this.product.code].data;
        }
      });

    this.storeAdminSet
      .select((state: any) => {
        return state.AdminSet;
      })
      .pipe(filter(isNotUndefined))
      .subscribe(adminSet => {
        this.adminSetSelected = adminSet.data;
        if (
          this.product &&
          adminSet &&
          adminSet.data[this.product.code] &&
          adminSet.data[this.product.code].data
        ) {
          if (this.entry && this.selectedEntryPrices) {
            this.entryQuantityChange(
              this.entry.quantity,
              this.selectedEntryPrices
            );
          }
        }
      });
  }

  private isDifferentSpecialPrice(
    oldSpecialPrice: any,
    newSpecialPrice: any
  ): boolean {
    const isFirstSpecialPriceSelected = !oldSpecialPrice && newSpecialPrice;

    const isDifferentBatch = oldSpecialPrice.batch !== newSpecialPrice.batch;

    const isAssayProductWhitLess181DaysStrength =
      'undefined' === newSpecialPrice.batch && newSpecialPrice.callPriceApi;
    const isDifferentDefaultBatchForAssayProducts =
      isAssayProductWhitLess181DaysStrength &&
      oldSpecialPrice.defaultBatch !== newSpecialPrice.defaultBatch;

    const isDifferentSpecialPriceSelected =
      oldSpecialPrice &&
      newSpecialPrice &&
      (isDifferentBatch || isDifferentDefaultBatchForAssayProducts);
    return isFirstSpecialPriceSelected || isDifferentSpecialPriceSelected;
  }

  ngOnInit() {
    this.updatePrices();
    this.changeDetectorRef.detectChanges();

    this.baseSiteService.getActive().subscribe(res => {
      this.activeSite = res;
    });
    // this.storeCustomer.select((state: any) => {
    //   return state.Customer;
    // }).subscribe(customerData => {
    //   if (customerData && customerData.splitBillerAuto && customerData.splitBillerAuto.user) {
    //     this.isSplitBillerAccount = customerData.splitBillerAuto.user.splitBiller;
    //     this.updatePrices();
    //   }
    // });

    this.communicationService.messageSource.subscribe(productAdded => {
      this.cartSubscriptionRef = this.fffActiveCartService
        .getActive()
        .subscribe(res => {
          this.cart = res;

          //Checking that we are cleaning the prices of the product we just added.

          if (
            !this.prebookFormEnabled &&
            this.price.length > 0 &&
            this.price[0].price.productCode === productAdded
          ) {
            //For every input, clean the quantity and send the proper event.

            for (let f of this.price) {
              f.quantity = null;
              this.entryQuantityChange(0, f);
            }
          }
          this.changeDetectorRef.detectChanges();
        });
    });

    this.preBookService.getApplicationProperties().subscribe(data => {
      this.fluSeasonCurrent = data.mfvPrebookCurrentSeason;
      this.preBookEnable = data?.mfvRespiratoryPrebookFormEnabled === 'true';
    });
  }
  ngOnChanges(changes: SimpleChanges) {
    if (changes && changes.pricesData) {
      this.updatePrices();
      this.initPrebookQuantity();
    }

    if (changes['product'] && changes['product'].currentValue?.quantity) {
      this.initPrebookQuantity();
    }
  }

  cartSubscriptionRef: Subscription = new Subscription();

  getB2bLabel(type: string) {
    switch (type) {
      case 'W':
        return 'fffProduct.lblWac';

      case 'B':
        return 'fffProduct.lbl340b';

      case 'A':
        return 'fffProduct.lblContract';

      default:
        return 'fffProduct.noLabel';
    }
  }

  occursAChange(event: any, oldData: any, newData: any) {
    return event != null && oldData !== newData;
  }

  updatePrices() {
    if (this.pricesData && this.product) {
      const stockResponse = this.pricesData?.prices?.sort((a: any, b: any) =>
        a.accountType.localeCompare(b.accountType)
      );

      let isAutoSplitBilling = FFFCommonFunctions.isAutoSplitBilling(
        this.b2bUnit.currentB2BUnit.splitBiller,
        this.isSplitBillerAccount,
        this.product
      );

      this.isDirectOrder = isAutoSplitBilling && stockResponse.length < 3;

      this.price = [];

      this.showAsSoldOutIfExcluded =
        this.pricesData?.prices?.some((p: any) => p.showAsSoldOutIfExcluded) ||
        false;

      const updatePricesData = FFFCommonFunctions.updatePrices(
        this.pricesData,
        this.product,
        isAutoSplitBilling
      );

      this.price = updatePricesData.price;
      this.fffProductService.emitItIsContractProduct(
        this.product.code,
        updatePricesData?.basicCP > 0
      );

      if (this.prebookFormEnabled) {
        this.price?.forEach(p => {
          p.quantity = this.product?.quantity || null;
          if (p.quantity) {
            this.entryQuantityChange(p.quantity, p);
          }
        });
      }

      this.changeDetectorRef.markForCheck();
    }
  }

  updatePricesData(batchNumber?: string): void {
    this.isLoading = true;
    this.subscription = this.communicationService
      .getPrices(this.product.code, batchNumber)
      .subscribe(
        stockResponse => {
          this.isLoading = false;
          this.pricesData = stockResponse;
          this.updatePrices();
        },
        () => {
          this.isLoading = false;
        }
      );
  }

  updatePriceWithSpecialDropDown(specialPrice: any): void {
    if (
      specialPrice &&
      specialPrice.batch &&
      ('undefined' !== specialPrice.batch ||
        ('undefined' === specialPrice.batch && specialPrice.callPriceApi))
    ) {
      if ('undefined' === specialPrice.batch) {
        this.updatePricesData(specialPrice.defaultBatch);
      } else {
        this.updatePricesData(specialPrice.batch);
      }
    }
  }

  entryQuantityChange(quantity: any, prod: any) {
    let priceProduct = 0;
    let taxProduct = 0;
    let discountValue = 0;
    let minOrderQuantity = 0;
    let moq = 0;
    this.selectedEntryPrices = prod;

    if (prod.price.priceMap[prod.price.basicUOM]) {
      priceProduct = prod.price.priceMap[prod.price.basicUOM].value;
      taxProduct = prod.price.priceMap[prod.price.basicUOM].tax;
      discountValue = prod.price.priceMap[prod.price.basicUOM].discount;
    }

    if (prod.price.moq) {
      moq = parseFloat(prod.price.moq);
    }

    if (prod.price.minOrderQuantity) {
      minOrderQuantity = parseFloat(prod.price.minOrderQuantity);
    }

    let entry: FFFProductInputEntry = {
      key: prod.key,
      unit:
        FFFCommonFunctions.isAutoSplitBilling(
          this.b2bUnit.currentB2BUnit.splitBiller || false,
          this.isSplitBillerAccount,
          this.product
        ) &&
        this.activeSite === this.BASE_URL_KEYS.BIOSUPPLY &&
        !this.isDirectOrder
          ? 'AUTO'
          : prod.accountType,
      warehouse: prod.warehouse,
      code: prod.price.productCode,
      quantity: quantity,
      taxValue: taxProduct,
      discountValue: discountValue,
      uom: prod.price.basicUOM,
      altUom: this.getAltPriceI(prod.price?.priceMap, this.product?.altUnit)
        ?.altUOM,
      altDiscPrice: this.getAltPriceI(
        prod.price?.priceMap,
        this.product?.altUnit
      )?.altDiscPrice,
      unitConversionFactor: this.getAltPriceI(
        prod.price.priceMap,
        this.product?.altUnit
      )?.min,
      price: priceProduct,
      strength:
        this.productStrengthSelected &&
        this.productStrengthSelected.data &&
        this.productStrengthSelected.data.batchStrength
          ? this.productStrengthSelected.data.batchStrength
          : '',
      batch:
        this.specialPriceSelected && this.specialPriceSelected.batch
          ? this.specialPriceSelected.batch
          : '',
      expirationDate:
        this.specialPriceSelected && this.specialPriceSelected.expirationDate
          ? this.specialPriceSelected.expirationDate
          : '',
      mduConversionFactor:
        prod.price?.priceMap[prod.price?.basicUOM]?.min > 1
          ? 1
          : minOrderQuantity,
      altUnitConversion: prod.price?.priceMap[prod.price?.basicUOM]
        ?.additionalConversion
        ? prod.price?.priceMap[prod.price?.basicUOM]?.additionalConversion
        : '',
      moq: moq,
      adminSet:
        this.adminSetSelected &&
        this.adminSetSelected[this.product.code] &&
        this.adminSetSelected[this.product.code].data.adminSet
          ? this.adminSetSelected[this.product.code].data.adminSet
          : false,
      yconnector:
        this.adminSetSelected &&
        this.adminSetSelected[this.product.code] &&
        this.adminSetSelected[this.product.code].data.yConnector
          ? this.adminSetSelected[this.product.code].data.yConnector
          : false,
      directOrder: this.isDirectOrder,
      minOrderQuantity,
    };
    this.entry = entry;
    this.store.dispatch({
      type: ADD_PRODUCT_FOCUSED,
      payload: entry,
    });
  }

  initPrebookQuantity() {
    if (
      !this.product?.quantity ||
      !this.price.length ||
      this.isPrebookQuantityLoaded
    ) {
      return;
    }

    this.prebookEntryQuantityChange(this.product.quantity, this.price[0]);
    this.isPrebookQuantityLoaded = true;
  }

  prebookEntryQuantityChange(quantity: any, prod: any) {
    this.isShowAddToCartNotice = false;
    if (!this.prebookFormEnabled) {
      return;
    }
    if (
      (this.differentSeason() ||
        this.rsvWithActivePrebookInCart() ||
        this.rsvWithPrebookCart()) &&
      quantity
    ) {
      this.isSeasonTooltipVisible = true;
      this.changeDetectorRef.markForCheck();
      this.fffPrebookCartService.removeUpdatableEntry(this.product.code);
      return false;
    }

    let priceProduct = 0;
    let taxProduct = 0;
    let discountValue = 0;
    let minOrderQuantity = 0;
    let moq = 0;
    this.selectedEntryPrices = prod;

    if (prod.price.priceMap[prod.price.basicUOM]) {
      priceProduct = prod.price.priceMap[prod.price.basicUOM].value;
      taxProduct = prod.price.priceMap[prod.price.basicUOM].tax;
      discountValue = prod.price.priceMap[prod.price.basicUOM].discount;
    }

    if (prod.price.moq) {
      moq = parseFloat(prod.price.moq);
    }

    if (prod.price.minOrderQuantity) {
      minOrderQuantity = parseFloat(prod.price.minOrderQuantity);
    }

    let entry: FFFProductInputEntry = {
      key: prod.key,
      unit:
        FFFCommonFunctions.isAutoSplitBilling(
          this.b2bUnit.currentB2BUnit.splitBiller || false,
          this.isSplitBillerAccount,
          this.product
        ) &&
        this.activeSite === this.BASE_URL_KEYS.BIOSUPPLY &&
        !this.isDirectOrder
          ? 'AUTO'
          : prod.accountType,
      warehouse: prod.warehouse,
      code: prod.price.productCode,
      quantity: quantity,
      taxValue: taxProduct,
      discountValue: discountValue,
      uom: prod.price.basicUOM,
      altUom: this.getAltPriceI(prod.price?.priceMap, this.product?.altUnit)
        ?.altUOM,
      altDiscPrice: this.getAltPriceI(
        prod.price?.priceMap,
        this.product?.altUnit
      )?.altDiscPrice,
      unitConversionFactor: this.getAltPriceI(
        prod.price.priceMap,
        this.product?.altUnit
      )?.min,
      price: priceProduct,
      strength:
        this.productStrengthSelected &&
        this.productStrengthSelected.data &&
        this.productStrengthSelected.data.batchStrength
          ? this.productStrengthSelected.data.batchStrength
          : '',
      batch:
        this.specialPriceSelected && this.specialPriceSelected.batch
          ? this.specialPriceSelected.batch
          : '',
      expirationDate:
        this.specialPriceSelected && this.specialPriceSelected.expirationDate
          ? this.specialPriceSelected.expirationDate
          : '',
      mduConversionFactor:
        prod.price?.priceMap[prod.price?.basicUOM]?.min > 1
          ? 1
          : minOrderQuantity,
      altUnitConversion: prod.price?.priceMap[prod.price?.basicUOM]
        ?.additionalConversion
        ? prod.price?.priceMap[prod.price?.basicUOM]?.additionalConversion
        : '',
      moq: moq,
      adminSet:
        this.adminSetSelected &&
        this.adminSetSelected[this.product.code] &&
        this.adminSetSelected[this.product.code].data.adminSet
          ? this.adminSetSelected[this.product.code].data.adminSet
          : false,
      yconnector:
        this.adminSetSelected &&
        this.adminSetSelected[this.product.code] &&
        this.adminSetSelected[this.product.code].data.yConnector
          ? this.adminSetSelected[this.product.code].data.yConnector
          : false,
      directOrder: this.isDirectOrder,
    };
    this.entry = entry;
    this.store.dispatch({
      type: ADD_PRODUCT_FOCUSED,
      payload: entry,
    });

    this.fffPrebookCartService.setUpdatableEntry({
      ...entry,
      dropShip: this.product.dropShip,
      batch:
        this.specialPriceSelected &&
        this.specialPriceSelected.batch &&
        'undefined' !== this.specialPriceSelected.batch
          ? this.specialPriceSelected.batch
          : '',
      expirationDate:
        this.specialPriceSelected && this.specialPriceSelected.expirationDate
          ? this.specialPriceSelected.expirationDate
          : '',
      adminSet:
        this.adminSetSelected &&
        this.adminSetSelected[this.product.code] &&
        this.adminSetSelected[this.product.code].data.adminSet
          ? this.adminSetSelected[this.product.code].data.adminSet
          : false,
      yconnector:
        this.adminSetSelected &&
        this.adminSetSelected[this.product.code] &&
        this.adminSetSelected[this.product.code].data.yConnector
          ? this.adminSetSelected[this.product.code].data.yConnector
          : false,
      categories: this.product.categories,
      entryNumber: this.product.entryNumber,
    });
  }

  validatePrebookEntryQuantity(ev: Event) {
    // Validate entered quantity
    if (
      this.differentSeason() ||
      this.rsvWithActivePrebookInCart() ||
      this.rsvWithPrebookCart()
    ) {
      ev.preventDefault();
      this.isSeasonTooltipVisible = true;
      this.changeDetectorRef.markForCheck();
      this.fffPrebookCartService.removeUpdatableEntry(this.product.code);
      return false;
    }

    return true;
  }

  isValidPriceData(priceData: any, variant: string): boolean {
    return priceData.price.priceMap[variant];
  }

  isValidAltPriceData(priceData: any): boolean {
    if (this.isOfVaccineTypeI(this.product)) {
      return !!priceData?.price?.toAltPrice?.results?.find(
        (r: any) => r.altUOM === 'DS'
      );
    }

    return (
      priceData.price.priceMap['EU'] ||
      priceData.price.priceMap['IU'] ||
      (priceData.price?.basicUOM !== this.product?.altUnit &&
        priceData.price.priceMap[this.product?.altUnit])
    );
  }

  isInvalidQty(entry: any) {
    if (entry.quantity > 0) {
      if (
        (this.cart.rapidCommit &&
          !this.checkProductIsInRapidCommitCategory(this.product)) ||
        (this.cart?.entries?.length > 0 &&
          !this.cart.rapidCommit &&
          this.checkProductIsInRapidCommitCategory(this.product))
      ) {
        this.tooltipMessage = 'fffAddCart.category.notRapidCommit';
        return true;
      }
      if (
        entry.price?.priceMap[entry.price?.basicUOM]?.min > 1 &&
        entry.price?.priceMap[entry.price?.basicUOM]?.min > entry.quantity
      ) {
        this.tooltipMessage = 'fffProduct.orderMinimum';
        return true;
      }
      if (entry.price?.priceMap[entry.price?.basicUOM]?.mdu) {
        const mdu = +entry.price?.priceMap[entry.price?.basicUOM]?.mdu;
        if (mdu > 1 && +entry.price.moq > 1 && +entry.quantity % mdu !== 0) {
          this.tooltipMessage = 'fffProduct.qtyMultipleMdu';
          return true;
        }
      }
    }
    this.tooltipMessage = '';
    return false;
  }

  checkBatchAvailable(p: any): boolean {
    let totalStockAvailable = 0;
    let totalInCart = 0;
    if (
      p.quantity > 0 &&
      this.specialPriceSelected &&
      this.specialPriceSelected.batchStock
    ) {
      totalStockAvailable = this.specialPriceSelected.batchStock;
      totalInCart = this.totalInCartByBatch(this.specialPriceSelected.batch);
    }

    if (
      this.product.assayIndicator &&
      this.productStrengthSelected &&
      this.productStrengthSelected.data &&
      this.specialPriceSelected
    ) {
      if (this.specialPriceSelected.totalStockAvailable) {
        totalStockAvailable = this.specialPriceSelected.totalStockAvailable;
        totalInCart = this.totalInCartByStrength(
          this.productStrengthSelected.data.batchStrength
        );
      } else {
        totalStockAvailable = this.specialPriceSelected.batchStock;
        totalInCart = this.totalInCartByBatch(this.specialPriceSelected.batch);
      }
    }

    if (
      this.product.assayIndicator ||
      (p.quantity > 0 &&
        this.specialPriceSelected &&
        this.specialPriceSelected.batchStock)
    ) {
      const isInvalid = this.isInvalidQuantity(
        totalStockAvailable,
        totalInCart
      );
      if (isInvalid) {
        this.batchStockAvailable = totalStockAvailable;
        this.batchQtyValidation = p.accountType;
        return true;
      }
    }

    this.batchQtyValidation = '';
    return false;
  }

  private totalInCartByBatch(batch: string): number {
    let totalInCart = 0;
    this.cart?.entries.forEach((e: any) => {
      if (this.product.code === e.product.code && batch === e.batch) {
        totalInCart += parseInt(e.quantity, 10);
      }
    });
    return totalInCart;
  }

  private totalInCartByStrength(strength: string): number {
    let totalInCart = 0;
    this.cart?.entries.forEach((e: any) => {
      if (this.product.code === e.product.code && strength === e.strength) {
        totalInCart += parseInt(e.quantity, 10);
      }
    });
    return totalInCart;
  }

  private isInvalidQuantity(
    stockAvailable: number,
    totalInCart: number
  ): boolean {
    let totalAccounts = 0;
    this.price.forEach((price: any) => {
      if (price.quantity) {
        totalAccounts += +price.quantity;
      }
    });

    const totalQuantity = totalInCart + totalAccounts;
    return totalQuantity > stockAvailable;
  }

  checkAllocation(p: any) {
    if (p.quantity === 0 && p.accountType === this.allocationMessage) {
      this.allocationMessage = null;
      for (let i of this.price) {
        this.checkAllocation(i);
      }
    }

    if (p.quantity > 0 && this.allocation) {
      let allocation = this.allocation;
      let qtyInCart = 0;

      this?.cart?.entries?.forEach((e: any) => {
        if (this.product.code === e.product.code) {
          qtyInCart += parseInt(e.quantity, 10);
        }
      });

      let sumOfAccounts = 0;
      this.price.forEach((price: any) => {
        if (price.quantity) {
          sumOfAccounts += +price.quantity;
        }
      });

      const hasMaterial = allocation.material;
      const totalQuantity = qtyInCart + sumOfAccounts;
      const indAmount = +p.quantity + qtyInCart;

      //checking which value is the one we need to compare the quantity with
      const allocCompareValue = this.fffAllocationService.transformUnits(
        this.fffAllocationService.getAllocationCompareValue(allocation),
        allocation,
        p.price.basicUOM,
        this.pricesData.prices[0]
      );
      const badIndependentAlloc = !!(
        allocCompareValue !== null &&
        +indAmount > 0 &&
        +allocCompareValue < +indAmount &&
        hasMaterial
      );
      const badGeneralAlloc = !!(
        allocCompareValue !== null &&
        +totalQuantity > 0 &&
        +allocCompareValue < +totalQuantity &&
        hasMaterial
      );

      //popup message related stuff
      if (badIndependentAlloc || badGeneralAlloc) {
        this.allocationMessage = p.accountType;
        return;
      }

      if (
        (!badIndependentAlloc || !badGeneralAlloc) &&
        p.accountType === this.allocationMessage
      ) {
        this.allocationMessage = null;
        for (let i of this.price) {
          this.checkAllocation(i);
        }
      }
    }

    return false;
  }

  differentSeason() {
    if (this.cart?.fluSeason === '') {
      return false;
    }
    return (
      this.product?.fluSeason &&
      this.cart?.fluSeason &&
      this.cart?.fluSeason !== this.product?.fluSeason
    );
  }

  rsvWithActivePrebookInCart() {
    let isActive = false;
    if (
      !this.prebookFormEnabled &&
      this.preBookEnable &&
      this.product?.fluSeason === this.fluSeasonCurrent
    ) {
      this.cart?.entries?.forEach((e: any) => {
        const rsvProducts = e?.product?.categories?.filter(
          (category: any) =>
            category?.code === 'VACCNVACCN000P2400' ||
            category?.name === 'Vaccines - RSV'
        );
        if (rsvProducts && rsvProducts.length > 0) {
          isActive = true;
        } else {
          isActive = false;
        }
      });
    } else {
      isActive = false;
    }
    return isActive;
  }

  rsvWithPrebookCart(): boolean {
    return this.preBookService.rsvWithPrebookCart(this.cart, this.product);
  }

  allocationMessage: any = null;

  isInvalidBatchOrStrength(product: any): boolean {
    let isInvalid = true;
    if (product.assayIndicator) {
      // for assay product
      this.isStrengthNotSelected =
        !this.productStrengthSelected.data ||
        !this.productStrengthSelected.data?.batchStrength;
      const invalidSpecialPrice =
        !this.specialPriceSelected ||
        !this.specialPriceSelected?.batch ||
        'undefined' === this.specialPriceSelected?.batch;
      const specialPricesAvailable =
        this.productStrengthSelected?.values?.filter(
          (data: any) => data.diffDays <= this.MAX_DIFF_DAYS
        );

      const batchSelected =
        this.product?.requireBatchSelect &&
        (!this.specialPriceSelected ||
          !this.specialPriceSelected.batch ||
          this.specialPriceSelected.batch === 'undefined');

      isInvalid =
        this.isStrengthNotSelected ||
        (specialPricesAvailable.length > 0 && invalidSpecialPrice) ||
        batchSelected;
    } else {
      let mapPrices: any = {};
      let specialPricesAvailable: any[] = [];

      if (
        !this.product?.assayIndicator &&
        this.allocation &&
        this.allocation.custToMatInv
      ) {
        if (this.allocation.custToMatInv.results) {
          this.allocation.custToMatInv.results.forEach((data: any) => {
            if (data.batchStrength) {
              if (!mapPrices[data.batchStrength]) {
                mapPrices[data.batchStrength] = { data: data, values: [] };
              }
              mapPrices[data.batchStrength].values.push(data);
            }
          });

          this.allocation.custToMatInv.results.forEach((r: any) => {
            if (r.expirationDate) {
              r = { ...r };
              let parsedExpirationDate = this.parseDate(r.expirationDate);
              r.parsedExpirationDate = parsedExpirationDate;
              r.diffDays = this.getDiffDays(new Date(), parsedExpirationDate);
              r.diffDays = parseInt(r.diffDays, 10);
            }
          });
        }

        for (const key in mapPrices) {
          const currentStrength = mapPrices[key];
          currentStrength.values.forEach((batch: any) => {
            specialPricesAvailable.push(batch);
          });
        }
      }
      specialPricesAvailable = specialPricesAvailable.filter(
        (data: any) => data.diffDays <= this.MAX_DIFF_DAYS
      );

      if (
        specialPricesAvailable.length === 0 ||
        (this.specialPriceSelected &&
          this.specialPriceSelected?.batch &&
          this.specialPriceSelected?.batch !== '')
      ) {
        isInvalid = false;
      }
    }
    return isInvalid;
  }

  parseDate(timestampString: string) {
    let res = timestampString.match(/[^/Date(\[](.*)[^\])/]/g);
    const timestamp = parseInt(res![0], 10);
    return new Date(timestamp);
  }

  getDiffDays(dateFrom: Date, dateTo: Date): number {
    this.clearDateVales(dateFrom);
    this.clearDateVales(dateTo);
    var difference = dateTo.getTime() - dateFrom.getTime();
    return difference / (1000 * 3600 * 24);
  }

  private clearDateVales(date: Date): Date {
    date.setHours(0, 0, 0, 0);
    return date;
  }

  surpassesYearlyAlloc(allocation: any, totalQuantity: number) {
    return (
      +allocation.yearlyAllocatedQty > 0 &&
      totalQuantity > +allocation.yearlyRemainingQty
    );
  }

  surpassesMonthlyAlloc(allocation: any, totalQuantity: number) {
    return (
      +allocation.allocatedQty > 0 && totalQuantity > +allocation.remainingQty
    );
  }

  isControlledSubstance(): boolean {
    return this.isCdsLicenseBlock() || this.isSomQuestionnaireInvalid();
  }

  isCdsLicenseBlock(): boolean {
    return (
      this.product.purchasableInfo &&
      this.product.purchasableInfo.isCdsLicenseBlock
    );
  }

  isSomQuestionnaireInvalid(): boolean {
    return (
      this.product.purchasableInfo &&
      !this.product.purchasableInfo.somQuestionnaireValid
    );
  }

  isAccountBlocked(p: any): boolean {
    return (
      this.b2bUnit.currentB2BUnit.abwUnits &&
      this.b2bUnit.currentB2BUnit.abwUnits.some(
        (unit: any) => unit.accountType === p.accountType && unit.blockCode
      )
    );
  }

  isProductSoldOut() {
    return this.activeSite === BASE_URL_KEYS.MY_FLU_VACCINE
      ? this.product.soldOut ||
          (this.product.showAsSoldOutIfExcluded && this.showAsSoldOutIfExcluded)
      : false;
  }

  hasNoProductAllocations(isAssayProduct: boolean) {
    return (
      isAssayProduct &&
      !this.allocation?.custToMatInv?.results?.filter(
        (data: any) => !!data.batchStrength
      ).length
    );
  }

  checkProductIsInRapidCommitCategory(product: any): boolean {
    if (!product) return false;
    if (!product.categories) return false;

    return product.categories.some((category: any) => {
      if (category.code === 'RAPIDCOMMIT') {
        return true;
      }
      return false;
    });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  updateTierPricing(p: any) {
    if (this.shortDated) return;
    if (p.price.toMatScale.results.length === 0) return;

    let tiers: any[] = p.price.toMatScale.results;
    let quantity = p.quantity;

    let result = tiers
      .sort((a, b) => a.scaleQty - b.scaleQty)
      .find((tier, i) => {
        if (tiers[i + 1]) {
          return (
            quantity >= parseInt(tier.scaleQty, 10) &&
            quantity < parseInt(tiers[i + 1].scaleQty, 10)
          );
        } else {
          return quantity >= parseInt(tier.scaleQty, 10);
        }
      });

    return result;
  }

  isPriceAllowed(p: any) {
    return (
      (this.activeSite === BASE_URL_KEYS.BIOSUPPLY ||
        (this.activeSite === BASE_URL_KEYS.MY_FLU_VACCINE &&
          (p.accountType === 'A' || p.accountType === 'S'))) &&
      p.price &&
      p.price.priceMap
    );
  }

  mduExists(p: any) {
    return parseInt(p.price.mdu, 10);
  }

  isBasePriceAvailable(p: any) {
    return p.price.basicDiscountPrice && p.price.basicUOM;
  }

  showProductNotOrderableComponent() {
    this.modalService.open(FffProductNotOrderableComponent, {
      centered: true,
      size: 'md',
      backdrop: 'static',
      modalDialogClass: 'modal-container',
    });
  }

  showAddToCartNoticeComponent(quantity: any, prod: any) {
    if (this.isShowAddToCartNotice) {
      const modalRef = this.modalService.open(FffAddToCartNoticeComponent, {
        centered: true,
        size: 'md',
        backdrop: 'static',
        modalDialogClass: 'modal-container',
      });
      modalRef.componentInstance.addToCartNotice = this.product.addToCartNotice;
      modalRef.result.then(data => {
        if (data && data.addToCart) {
          this.prebookEntryQuantityChange(quantity, prod);
        } else {
          this.prebookEntryQuantityChange(0, prod);
          this.product.quantity = '';
        }
        this.changeDetectorRef.markForCheck();
      });
      this.isShowAddToCartNotice = false;
    }
  }

  showAddToCartNotice() {
    this.isShowAddToCartNotice = true;
  }

  toggleTooltip(tooltip: any, ev: any) {
    if (!!tooltip?._ngbTooltip) {
      ev.currentTarget.readOnly = true;
      tooltip?.open();
    } else {
      ev.currentTarget.readOnly = false;
    }
  }

  isBlockQty() {
    if (this.activeSite === BASE_URL_KEYS.MY_FLU_VACCINE) {
      return (
        !this.userAccountService.isValidLicense ||
        this.userAccountService.hasNoGPOAssigned ||
        this.userAccountService.hasCreditBlock
      );
    }
    return false;
  }
}
