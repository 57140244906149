<div class="w-100 details-container">
  <div (click)="showDetails()" class="title d-flex justify-content-center">
    <p class="mb-0 mr-1">
      <b>{{ 'fffQuickOrder.productDetails' | cxTranslate }}</b>
    </p>

    <cx-icon
      *ngIf="hideDetails"
      class="cx-icon icon-sm"
      [type]="outlinedIconTypes.ICON_CHEVRON_DOWN"
    ></cx-icon>
    <cx-icon
      *ngIf="!hideDetails"
      class="cx-icon icon-sm"
      [type]="outlinedIconTypes.ICON_CHEVRON_UP"
    ></cx-icon>
  </div>
  <div class="text-detail" *ngIf="!hideDetails">
    <ng-container *ngIf="allocResults">
      <ng-container *ngIf="+allocResults?.actionCSY">
        <p class="body-text-xl details-title mt-0 mb-0">
          {{ 'fffProduct.details.annualCommitment' | cxTranslate }} :
          {{ +allocResults?.yearlyAllocatedQty }}
        </p>
        <p class="body-text-xl details-title mt-0">
          {{ 'fffProduct.details.purchased' | cxTranslate }} : {{ barProp }}
        </p>
      </ng-container>

      <div
        class="bar-background"
        [ngStyle]="{ width: barSize + 'px' }"
        *ngIf="allocResults?.yearlyAllocatedQty > 0 && allocResults.actionCSY"
      >
        <div
          class="blue-bar"
          [ngStyle]="{
            width: getBarProp(barProp, +allocResults?.yearlyAllocatedQty) + 'px'
          }"
        ></div>
      </div>
      <br />
    </ng-container>

    <div class="columns">
      <h1
        class="body-text-xl details-title mt-0 detailsSec1-plp"
        *ngIf="product?.detailsSec1"
        [innerHTML]="product?.detailsSec1"
      ></h1>

      <p
        class="body-text-xl"
        *ngIf="product?.detailsSec2"
        [innerHTML]="product?.detailsSec2"
      ></p>

      <ng-container *ngIf="activeSite === BASE_URL_KEYS.BIOSUPPLY">
        <p class="body-text-xl details-title">
          {{ 'fffProduct.details.ndcInfo' | cxTranslate }}
        </p>
        <div class="body-text-xl" *ngIf="ndcFields">
          <div *ngFor="let field of ndcFields">
            <div *ngIf="field.featureValues[0].value">
              {{ field.name }}: {{ field.featureValues[0].value }}
            </div>
          </div>
        </div>
      </ng-container>

      <p class="body-text-xl details-title mt-3">
        {{ 'fffProduct.details.indicators' | cxTranslate }}
      </p>
      <div class="body-text-xl" [innerHTML]="product?.detailsIndicators"></div>
    </div>

    <div class="d-flex justify-content-center">
      <a
        class="btn btn-secondary btn-md prescribingInformation"
        target="_blank"
        [href]="product?.prescribingInfoUrl"
        >{{ 'fffProduct.details.prescribingInformation' | cxTranslate }}</a
      >
    </div>
  </div>
</div>
