import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CmsConfig, I18nModule, provideConfig } from '@spartacus/core';
import { GenericLinkModule } from '@spartacus/storefront';
import { FffNavigationModule } from '../navigation/navigation.module';
import { FffFooterNavigationComponent } from './footer-navigation.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FffNavigationModule,
    GenericLinkModule,
    I18nModule,
  ],
  providers: [
    provideConfig(<CmsConfig>{
      cmsComponents: {
        FooterNavigationComponent: {
          component: FffFooterNavigationComponent,
        },
      },
    }),
  ],
  declarations: [FffFooterNavigationComponent],
  exports: [FffFooterNavigationComponent],
})
export class FffFooterNavigationModule {}
