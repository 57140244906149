<div
  *ngIf="reportList && reportList.length > 0"
  class="container expand-collapse-container"
  id="recurring-reports-list"
>
  <div class="d-flex justify-content-between top-title">
    <div class="d-flex">
      <h3 class="pr-4">{{ 'fffReports.recurringReports' | cxTranslate }}</h3>
    </div>
  </div>
  <div class="reports-container-desktop">
    <table class="recurring-reports-table fixed-reports-table">
      <thead>
        <tr>
          <th class="pl-3">
            {{ 'fffReports.reportsTable.reportId' | cxTranslate }}
          </th>
          <th>{{ 'fffReports.reportsTable.reportType' | cxTranslate }}</th>
          <th>
            {{ 'fffReports.reportsTable.status' | cxTranslate }}
          </th>
          <th>{{ 'fffReports.reportsTable.frequency' | cxTranslate }}</th>
          <th>{{ 'fffReports.reportsTable.nextReportDate' | cxTranslate }}</th>
          <th class="col-2">
            {{ 'fffReports.reportsTable.accounts' | cxTranslate }}
          </th>
          <th class="col-2">
            {{ 'fffReports.reportsTable.emailRecipients' | cxTranslate }}
          </th>
          <th class="pr-3">
            {{ 'fffReports.reportsTable.actions' | cxTranslate }}
          </th>
        </tr>
      </thead>
      <tbody>
        <ng-container *ngFor="let report of reportList">
          <tr
            [ngClass]="{
              rowWithoutBorder: isRowExpandable(report),
              expandedRow: report.expanded
            }"
          >
            <td class="pl-3">{{ report.reportID }}</td>
            <td>
              {{ getFormattedReportTypeValue(report.reportType) | titlecase }}
            </td>
            <td>
              {{ getFormattedReportTypeValue(report.status) | titlecase }}
            </td>
            <td>{{ report.reportFrequency | titlecase }}</td>
            <td>{{ report.reportDate | date : 'MM/dd/yyyy' }}</td>
            <td class="col-2" *ngIf="report.accounts">
              <span
                [innerHTML]="report.accounts | truncateTableCellValues : 20"
                class="cell-text"
              ></span>
              <span
                *ngIf="
                  report.accounts &&
                  getFormattedValueLength(report.accounts) > 20
                "
                class="email-bubble"
                [ngbTooltip]="accountTooltipContent"
                triggers="click"
                placement="top"
                ngbTooltipClass="tooltip-custom"
                >{{ report.accounts.split(',').length }}</span
              >
              <ng-template #accountTooltipContent>
                <div class="tooltip-scrollable-content">
                  {{ report.accounts }}
                </div>
                <div><div class="pointer"></div></div>
              </ng-template>
            </td>
            <td class="col-2" *ngIf="report.emailIds">
              <span
                [innerHTML]="report.emailIds | truncateTableCellValues : 25"
                class="cell-text"
              ></span>
              <span
                *ngIf="
                  report.emailIds &&
                  getFormattedValueLength(report.emailIds) > 25
                "
                class="email-bubble"
                [ngbTooltip]="emailTooltipContent"
                triggers="click"
                placement="top"
                ngbTooltipClass="tooltip-custom"
                >{{ report.emailIds.split(',').length }}</span
              >
              <ng-template #emailTooltipContent>
                <div class="tooltip-scrollable-content">
                  {{ report.emailIds }}
                </div>
                <div><div class="pointer"></div></div>
              </ng-template>
            </td>
            <td class="pr-3" *ngIf="report.reportID">
              <a class="report-action" (click)="editReport(report)">{{
                'fffReports.reportsTable.edit' | cxTranslate
              }}</a>
              |
              <a class="report-action" (click)="openDrawer(report.reportID)">{{
                'fffReports.reportsTable.cancel' | cxTranslate
              }}</a>
            </td>
          </tr>
          <tr
            *ngIf="isRowExpandable(report) && report.expanded"
            class="expanded-row rowWithoutBorder expandedRow"
          >
            <td colspan="8" class="expandedDataRow">
              <div class="mt-4">
                {{
                  report.reportType === 'PRICES'
                    ? ('fffReports.reportsTable.productGroups' | cxTranslate)
                    : ('fffReports.reportsTable.products' | cxTranslate)
                }}
              </div>
              <div
                class="fff-tags-list-wrapper fff-tags-list-wrapper--multiple"
              >
                <div class="fff-tags-list fff-tags-list--multiple">
                  <span
                    class="fff-tag-item"
                    *ngFor="
                      let item of getDataForRowExpansion(report);
                      let i = index
                    "
                  >
                    <span>{{ item }}</span>
                  </span>
                  <span *ngIf="report.productGroups > truncate">{{
                    'fffReports.andOthers' | cxTranslate
                  }}</span>
                </div>
              </div>
            </td>
          </tr>
          <tr
            class="expand-row"
            [ngClass]="{
              expandedRow: report.expanded
            }"
            *ngIf="isRowExpandable(report)"
          >
            <td colspan="8" class="button-cell pl-3 pr-3 centered-cell">
              <button
                class="expand-button"
                (click)="toggleRowExpansion(report)"
              >
                <cx-icon
                  class="cx-icon icon-md text-white"
                  [ngClass]="{ isExpanded: report.expanded }"
                  [type]="outlinedIconTypes.CARET_DOWN"
                ></cx-icon>
              </button>
            </td>
          </tr>
        </ng-container>
      </tbody>
    </table>
  </div>

  <div class="reports-container-mobile">
    <div *ngFor="let report of reportList" class="box">
      <div class="field-row">
        <div class="field-name">
          {{ 'fffReports.reportsTable.reportId' | cxTranslate }}
        </div>
        <div class="field-value">{{ report.reportID }}</div>
      </div>
      <div class="field-row">
        <div class="field-name">
          {{ 'fffReports.reportsTable.reportType' | cxTranslate }}
        </div>
        <div class="field-value">
          {{ getFormattedReportTypeValue(report.reportType) | titlecase }}
        </div>
      </div>
      <div class="field-row">
        <div class="field-name">
          {{ 'fffReports.reportsTable.status' | cxTranslate }}
        </div>
        <div class="field-value">
          {{ getFormattedReportTypeValue(report.status) | titlecase }}
        </div>
      </div>
      <div class="field-row">
        <div class="field-name">
          {{ 'fffReports.reportsTable.frequency' | cxTranslate }}
        </div>
        <div class="field-value">{{ report.reportFrequency | titlecase }}</div>
      </div>

      <div class="field-row">
        <div class="field-name">
          {{ 'fffReports.reportsTable.nextReportDate' | cxTranslate }}
        </div>
        <div class="field-value">
          {{ report.reportDate | date : 'MM/dd/yyyy' }}
        </div>
      </div>
      <div class="field-row">
        <div class="field-name">
          {{ 'fffReports.reportsTable.accounts' | cxTranslate }}
        </div>
        <div class="field-value" *ngIf="report.accounts">
          <span
            [innerHTML]="report.accounts | truncateTableCellValues : 12"
            class="cell-text"
          ></span>
          <span
            *ngIf="
              report.accounts && getFormattedValueLength(report.accounts) > 12
            "
            class="email-bubble mobile-bubble"
            [ngbTooltip]="accountTooltipContent"
            triggers="click"
            placement="top"
            ngbTooltipClass="tooltip-custom"
            >{{ report.accounts.split(',').length }}</span
          >
          <ng-template #accountTooltipContent>
            <div class="tooltip-scrollable-content">
              {{ report.accounts }}
            </div>
            <div><div class="pointer"></div></div>
          </ng-template>
        </div>
      </div>
      <div class="field-row">
        <div class="field-name">
          {{ 'fffReports.reportsTable.emailRecipients' | cxTranslate }}
        </div>
        <div class="field-value" *ngIf="report.emailIds">
          <span
            [innerHTML]="report.emailIds | truncateTableCellValues : 12"
            class="cell-text"
          ></span>
          <span
            *ngIf="
              report.emailIds && getFormattedValueLength(report.emailIds) > 12
            "
            class="email-bubble"
            [ngbTooltip]="emailTooltipContent"
            triggers="click"
            placement="top"
            ngbTooltipClass="tooltip-custom"
            >{{ report.emailIds.split(',').length }}</span
          >
          <ng-template #emailTooltipContent>
            <div class="tooltip-scrollable-content">
              {{ report.emailIds }}
            </div>
            <div><div class="pointer"></div></div>
          </ng-template>
        </div>
      </div>
      <div class="field-row">
        <div class="field-name">
          {{ 'fffReports.reportsTable.actions' | cxTranslate }}
        </div>
        <div class="field-value d-flex" *ngIf="report.reportID">
          <div class="report-action" (click)="editReport(report)">
            {{ 'fffReports.reportsTable.edit' | cxTranslate }}
          </div>
          <div class="ml-2 mr-2">|</div>
          <div class="report-action" (click)="openDrawer(report.reportID)">
            {{ 'fffReports.reportsTable.cancel' | cxTranslate }}
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
