<ng-container *ngIf="activeSite$ | async as activeSite">
  <div class="container px-0 cx-page-slot" *ngIf="entries$ | async as entries">
    <ng-container *ngIf="isStableCart$ | async; else loadingTemplate">
      <ng-container *ngIf="entries && entries.length > 0; else emptyCart">
        <div class="cx-page-slot TopContent">
          <h3 class="h3 body-text-link-xl cart-header-title">
            {{
              'fffCartHeader.title'
                | cxTranslate : { items: getEligibleItemsCount(entries) }
            }}
          </h3>
        </div>

        <div class="cx-page-slot TopContent">
          <fff-cart-header-message
            [activeSite]="activeSite"
          ></fff-cart-header-message>
        </div>

        <div class="cx-page-slot CenterLeftContentSlot">
          <ng-container *ngIf="cartData$ | async as data; else loading">
            <fff-cart-details
              [prices]="data.priceData"
              [stock]="data.stockData"
            ></fff-cart-details>
          </ng-container>
        </div>

        <div class="cx-page-slot CenterRightContentSlot">
          <fff-cart-totals [activeSite]="activeSite"></fff-cart-totals>
        </div>
      </ng-container>
    </ng-container>

    <ng-template #emptyCart>
      <div class="cx-page-slot TopContent">
        <h3 class="h3 body-text-link-xl cart-header-title">
          {{ 'fffCartHeader.title' | cxTranslate : { items: 0 } }}
        </h3>
      </div>

      <div class="cx-page-slot EmptyCartMiddleContent">
        <fff-empty-cart class="col-12"></fff-empty-cart>
      </div>
    </ng-template>

    <ng-template #loadingTemplate>
      <div class="cx-page-slot TopContent">
        <h3 class="h3 body-text-link-xl cart-header-title updating-cart">
          {{ 'fffCartHeader.titleNoItems' | cxTranslate }}
        </h3>
      </div>

      <fff-cart-header-message
        [activeSite]="activeSite"
      ></fff-cart-header-message>
      <div class="loading-container">
        <fff-cart-loader></fff-cart-loader>
      </div>
    </ng-template>
  </div>
</ng-container>

<ng-template #loading>
  <fff-cart-loader [isCartItem]="true"></fff-cart-loader>
</ng-template>
