import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { FFFCustomValidators } from '@app/shared/validators/fff-custom-validators';
import { getProductImage } from '@assets/utils/fff-product-utils';
import { FFFReturnsService } from '@enterprise/fff-common-services/fff-returns.service';
import { FffReturnEntry } from '@model/fff-return.model';
import { Product } from '@spartacus/core';
import { MediaContainer } from '@spartacus/storefront';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'fff-fet-returns-request-entry',
  templateUrl: 'fff-fet-returns-request-entry.component.html',
})
export class FffFetReturnsRequestEntryComponent implements OnInit, OnDestroy {
  @Input() entry: FffReturnEntry | undefined;
  @Input() userId: string = '';
  @Input() index: number = 0;
  private unsubscribe$ = new Subject<void>();
  entryMediaContainer: MediaContainer | undefined;
  entryBatchAndQuantityForm = this.formBuilder.group({
    batchNumber: ['', Validators.required],
    quantity: ['', [Validators.required, FFFCustomValidators.quantityIntegerPositiveValidator, Validators.min(1)]],
  });
  productMap: any = {};
  entryQuantityInFETReturnsRequestCart: number = 0;
  constructor(
    private formBuilder: UntypedFormBuilder,
    private fffReturnService: FFFReturnsService
  ) { }
  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
  ngOnInit() {
    if (this.entry) {
      this.entryMediaContainer = this.getData(this.entry.product);
    }
    this.fffReturnService.emitProductQuantitiesData.subscribe((productData: any) => this.productMap = productData);

    this.fffReturnService.getEmitFETReturnsFormReset()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((value) => {
        if(value) {
          this.entryBatchAndQuantityForm.reset();
          this.entryQuantityInFETReturnsRequestCart = 0;

          if(this.entry){
            this.entry.showAdd = false;
          }

          this.productMap = {};
        }
      });
  }

  getData(product: Product) {
    return getProductImage(product.images) as MediaContainer;
  }
  addProduct(entry: FffReturnEntry) {
    const batchValue = this.entryBatchAndQuantityForm.get('batchNumber')?.value.toUpperCase();
    const lineQuantity = this.entryBatchAndQuantityForm.get('quantity')?.value;
    const key = batchValue + '_' + entry.product.code + '_' + entry.fffAccountNumber;

    if (!this.productMap[key]) {
      this.productMap[key] = { totalQuantity: 0 };
    }

    const totalQuantity = this.productMap[key].totalQuantity + parseFloat(lineQuantity);

    this.fffReturnService.validateFETBatchNumber(this.userId, batchValue, totalQuantity, entry.product.code, entry.fffAccountNumber)
      .subscribe((validBatchValueAndQuantity) => {
        if (!validBatchValueAndQuantity) {
          this.entryBatchAndQuantityForm.get('batchNumber')?.setErrors({ errorInvalidBatch: true });
          return;
        }

        entry.showAdd = !entry.showAdd;
        let newReturnEntry = { ...entry };
        newReturnEntry.batchId = batchValue;
        newReturnEntry.quantity = totalQuantity;
        this.productMap[key].totalQuantity = totalQuantity;
        this.fffReturnService.emitProductQuantitiesData.next(this.productMap);
        this.entryBatchAndQuantityForm.reset();
        this.fffReturnService.emitReturnEntry(newReturnEntry);

      });
  }
  validateBatchQuantity(event: any): void {
    if (event?.target?.value) {

      this.entryBatchAndQuantityForm.get('batchNumber')?.setErrors(null);

      let totalQuantityAdded = 0;
      totalQuantityAdded += this.entryQuantityInFETReturnsRequestCart;
      totalQuantityAdded += parseInt(event?.target?.value, 10);

      if (event?.target?.value < 0) {
        this.entryBatchAndQuantityForm.get('quantity')?.setErrors({ errorQuantity: true });
        return;
      }

      this.entryQuantityInFETReturnsRequestCart = totalQuantityAdded;
    }
  }
}
