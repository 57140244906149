import { Injectable } from '@angular/core';
import { Config, Image } from '@spartacus/core';
import { MediaContainer, MediaService } from '@spartacus/storefront';

@Injectable({
  providedIn: 'root',
})
export class FffMediaService extends MediaService {

  constructor(protected config: Config) {
    super(config);
  }

  selectBestFormat(media: MediaContainer | Image) {
    const mediaList = super.reversedFormats.filter((format) => media.hasOwnProperty(format.code));
    const mediaFiltred = mediaList
      .filter(m => (m?.size?.width || 0) > window.outerWidth)
      .sort((n1, n2) => (n1.size?.width || 0) - (n2.size?.width || 0));
    return mediaFiltred?.length > 0 ? mediaFiltred[0] : (mediaList?.length > 0 ? mediaList[0] : undefined);
  }

}
