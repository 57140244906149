import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'fff-update-interactive-allocations-drawer',
  templateUrl: './fff-update-interactive-allocations-drawer.component.html',
})
export class FffUpdateInteractiveAllocationsDrawerComponent implements OnInit {

  data: any = {};
  productCode: string = '';
  productName: string = '';

  constructor() { }

  ngOnInit(): void { }

}
