import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { ICON_TYPE } from '@spartacus/storefront';
import {
  gridBreakpointsLG,
  gridBreakpointsMD,
} from 'src/app/fff-config/content/constants';
import { defaultPageSize } from 'src/app/fff-config/pagination/pagination.config';

@Component({
  selector: 'fff-table',
  templateUrl: 'fff-table.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FffTableComponent implements OnInit {
  @Input() data: any;
  @Input() fields: any;
  @Input() customClass: any;
  @Input() columnsGrid: any;

  @Input() hasPaginator: boolean = true;
  @Input() hasTemplate: boolean = false;
  @Input() itemTemplate: any = null;
  @Input() mobileSize: any = gridBreakpointsLG;
  @Input() size: number = defaultPageSize;
  @Input() sizeConversionFactor: number = 1;
  @Input()
  showOnlyPaginator!: boolean;
  @Input()
  isInvoiceHistoryPage!: boolean;

  @Output() pageChange: EventEmitter<any> = new EventEmitter<any>();
  @Output() sortChange: EventEmitter<any> = new EventEmitter<any>();

  iconType = ICON_TYPE;
  maxMobileSize: number = gridBreakpointsMD;
  isMobile: boolean = false;
  total: number = 0;

  @HostListener('window:resize', ['$event'])
  onResize(_event: any) {
    this.checkDeviceScreen();
  }

  ngOnInit() {
    this.maxMobileSize = this.mobileSize;
    this.checkDeviceScreen();
  }

  checkDeviceScreen() {
    this.isMobile = window.innerWidth < this.maxMobileSize;
  }

  getData(page: number) {
    this.data.current = page;
    this.data.loading = true;
    this.pageChange.emit({ page: page + 1, size: this.size });
  }

  existsMore(pos: number) {
    return (
      (this.data.current + pos) * (this.size * this.sizeConversionFactor) <
      this.data.total
    );
  }

  onSort(item: any) {
    if (!item?.sortable) {
      return;
    }

    item.sort = item?.sort === 1 ? -1 : 1;

    this.sortChange.emit({
      field: item.field,
      sort: item.sort,
    });
  }
}
