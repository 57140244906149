import { Component, Input } from '@angular/core';
import { FffProfile } from '@app/models/fff-profile.model';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { FffDrawerService } from '@shared/drawer/fff-drawer.service';
import { FffChangeAccountDrawerComponent } from '../fff-change-account/fff-change-account-drawer.component';

@Component({
  selector: 'fff-change-account-selector',
  templateUrl: './fff-change-account-selector.component.html',
})
export class FffChangeAccountSelectorComponent {
  @Input() isMobile: boolean = false;
  @Input() profile!: FffProfile;
  fffChangeAccountDrawer: NgbModalRef | null = null;

  constructor(
    private modalService: NgbModal,
    private drawerService: FffDrawerService
  ) {}

  openDrawer() {
    if (!this.fffChangeAccountDrawer) {
      this.drawerService.closeDrawer();
      this.fffChangeAccountDrawer = this.modalService.open(
        FffChangeAccountDrawerComponent,
        {
          modalDialogClass: 'fff-drawer',
          backdrop: 'static',
          windowClass: 'fff-window',
          animation: true,
        }
      );
      this.fffChangeAccountDrawer.componentInstance.profile = this.profile;
      this.fffChangeAccountDrawer.result
        .then(() => {
          this.cleanDrawer();
        })
        .catch(() => {
          this.cleanDrawer();
        });
    }
  }

  private cleanDrawer() {
    this.fffChangeAccountDrawer = null;
  }
}
