import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  CurrencyService,
  LanguageService,
  OccEndpointsService,
  SiteContextConfig,
  SiteContextInterceptor,
  WindowRef,
} from '@spartacus/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ErrorsEnum } from './shared/services/enum.service';

@Injectable({ providedIn: 'root' })
export class AppInterceptor extends SiteContextInterceptor {
  constructor(
    languageService: LanguageService,
    currencyService: CurrencyService,
    occEndpointsService: OccEndpointsService,
    config: SiteContextConfig,
    public winRef: WindowRef
  ) {
    super(languageService, currencyService, occEndpointsService, config);
  }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const skippedValues = ['current', 'anonymous'];
    const asm = this.winRef.localStorage?.getItem('asm_enabled') == 'true';
    let headers = {};
    if (asm) {
      const tokenTarget: any = JSON.parse(
        this.winRef.localStorage?.getItem('spartacus⚿⚿auth') || ''
      );
      const addToken = asm && !skippedValues.includes(tokenTarget.userId);
      if (addToken) {
        headers = { 'sap-commerce-cloud-user-id': tokenTarget.userId };
      }
    }
    return next.handle(request.clone({ setHeaders: headers })).pipe(
      catchError((response: HttpErrorResponse) => {
        if (response.url && response.url.includes('pages?')) {
          this.checkInvalidResourceError(response);
        }
        //return next.handle(request);
        return throwError(() => new Error(response.message));
      })
    );
  }

  private checkInvalidResourceError(response: HttpErrorResponse) {
    if (
      response.status === 400 &&
      response.error &&
      response.error.errors.length > 0
    ) {
      switch (response.error.errors[0].type) {
        case ErrorsEnum.errorInvalidResource: {
          window.location.href = '/';
        }
      }
    }
  }
}
