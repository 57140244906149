import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { OUTLINED_ICON_TYPE } from 'src/app/models/fff-outline-icons.model';
import { RegisterService } from '../fff-register.service';

@Component({
  selector: 'fff-register-confirmation',
  templateUrl: './fff-register-confirmation.component.html'
})
export class FffRegisterConfirmationComponent {

  outlinedIconTypes = OUTLINED_ICON_TYPE;

  constructor(private activatedRoute: ActivatedRoute, private registerService: RegisterService) {
    const encoded = encodeURIComponent(this.activatedRoute.snapshot.queryParams['token']);
    this.registerService.registerVerifyUserEmail(encoded).subscribe(() => { });
  }
}
