<div
  class="row row-large"
  [ngClass]="{
    'mb-3': currentStep === steps.CONFIRMATION
  }"
>
  <!-- Column 01 -->
  <div class="col-4">
    <div class="fff-invoice-payment-content-block">
      <h6 class="fff-invoice-payment-content-block__title">
        {{ 'fffInvoicePayment.accountInformation' | cxTranslate }}
      </h6>
      <div class="fff-invoice-payment-content-block__body">
        <ng-container *ngIf="account$ | async as account">
          <div class="fff-invoice-payment-content-block__row">
            <div class="fff-invoice-payment-content-block__col-label">
              {{ 'fffInvoicePayment.accountName' | cxTranslate }}
            </div>
            <div class="fff-invoice-payment-content-block__col-value">
              {{ account.name }}
            </div>
          </div>
          <div class="fff-invoice-payment-content-block__row">
            <div class="fff-invoice-payment-content-block__col-label">
              {{ 'fffInvoicePayment.accountNumber' | cxTranslate }}
            </div>
            <div class="fff-invoice-payment-content-block__col-value">
              {{ account.uid }}
            </div>
          </div>
        </ng-container>
        <ng-container *ngIf="currentStep > steps.SELECT_CARD">
          <div class="fff-invoice-payment-content-block__row">
            <div class="fff-invoice-payment-content-block__col-label">
              {{ 'fffInvoicePayment.contactName' | cxTranslate }}
            </div>
            <div
              class="fff-invoice-payment-content-block__col-value"
              *ngIf="contactUser"
            >
              {{ contactUser.name }}
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
  <!-- Column 02 -->
  <div class="col-4">
    <div
      class="fff-invoice-payment-content-block"
      *ngIf="currentStep === steps.SELECT_CARD"
    >
      <h6 class="fff-invoice-payment-content-block__title">
        {{ 'fffInvoicePayment.contactInformation' | cxTranslate }}
      </h6>
      <div class="fff-invoice-payment-content-block__body">
        <div class="fff-invoice-payment-content-block__row">
          <div class="fff-invoice-payment-content-block__col-label">
            {{ 'fffInvoicePayment.contactName' | cxTranslate }}
          </div>
          <div
            class="fff-invoice-payment-content-block__col-value"
            *ngIf="contactUser"
          >
            {{ contactUser.name }}
          </div>
        </div>
      </div>
    </div>

    <div
      class="fff-invoice-payment-content-block"
      *ngIf="currentStep > steps.SELECT_CARD"
    >
      <h6 class="fff-invoice-payment-content-block__title">
        {{ 'fffInvoicePayment.paymentMethod' | cxTranslate }}
      </h6>
      <div class="fff-invoice-payment-content-block__body">
        <div class="fff-invoice-payment-content-block__row">
          <div class="fff-invoice-payment-content-block__col-label">
            {{ 'fffInvoicePayment.cardType' | cxTranslate }}
          </div>
          <div class="fff-invoice-payment-content-block__col-value d-flex">
            <div
              class="notPrintable fff-select-credit-card__action-cc-image fff-select-credit-card__action-cc-image--{{
                selectedCard?.icon
              }}"
            ></div>
            <div
              class="confirmation-popup-card-type fff-invoice-payment-content-block__col-value"
            >
              {{ selectedCard?.cardType?.code }}
            </div>
          </div>
        </div>
        <div class="fff-invoice-payment-content-block__row">
          <div class="fff-invoice-payment-content-block__col-label">
            {{ 'fffInvoicePayment.cartNumber' | cxTranslate }}
          </div>
          <div class="fff-invoice-payment-content-block__col-value">
            {{
              selectedCard?.cardToken
                | fffCreditCardMask : selectedCard?.cardType?.code
            }}
          </div>
        </div>
        <div class="fff-invoice-payment-content-block__row">
          <div class="fff-invoice-payment-content-block__col-label">
            {{ 'fffInvoicePayment.expirationDate' | cxTranslate }}
          </div>
          <div class="fff-invoice-payment-content-block__col-value">
            {{ selectedCard?.expiryMonth }}/{{ selectedCard?.expiryYear }}
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Column 03 -->
  <div class="col-4">
    <div class="fff-invoice-payment-content-block">
      <h6 class="fff-invoice-payment-content-block__title">
        {{ 'fffInvoicePayment.summary' | cxTranslate }}
      </h6>
      <div class="fff-invoice-payment-content-block__body">
        <div
          class="fff-invoice-payment-content-block__row"
          [ngClass]="{
            'd-flex': currentStep === steps.CONFIRMATION
          }"
        >
          <div
            class="fff-invoice-payment-content-block__col-label d-flex"
            [ngClass]="{
              width80: currentStep === steps.CONFIRMATION
            }"
          >
            <div>{{ 'fffInvoicePayment.grandTotal' | cxTranslate }}</div>
            <div
              class="ml-1 surchargeInfoTooltipIcon"
              *ngIf="getNumberOfInvoicesWithProcessingFee() > 0"
            >
              <cx-icon
                class="cx-icon icon-sm"
                [type]="filledIconTypes.ICON_INFO_CIRCLE_FILL"
                [ngbTooltip]="surchargeInfoTooltip"
                triggers="hover"
                placement="top"
                ngbTooltipClass="tooltip-custom"
              ></cx-icon>
            </div>
          </div>
          <div
            [ngClass]="{
              'pl-3': currentStep !== steps.CONFIRMATION,
              totalOnConfirmationPage: currentStep === steps.CONFIRMATION
            }"
          >
            {{ getGrandTotal() | currency }}
          </div>
          <div
            class="pl-3 viewTotals"
            (click)="scrollToBottom()"
            *ngIf="currentStep !== steps.CONFIRMATION"
          >
            {{ 'fffInvoicePayment.viewTotals' | cxTranslate }}
          </div>
        </div>
        <div
          class="fff-invoice-payment-content-block__row"
          *ngIf="currentStep !== steps.CONFIRMATION"
        >
          <div class="fff-invoice-payment-content-block__col-label">
            {{ 'fffInvoicePayment.noOfEntries' | cxTranslate }}
          </div>
          <div class="fff-invoice-payment-content-block__col-value">
            {{ totalInvoices }}
          </div>
        </div>
        <div
          class="d-flex successTransactions"
          *ngIf="currentStep === steps.CONFIRMATION"
        >
          <div class="width80 confirmationFont">
            {{ 'fffInvoicePayment.successfulTransactions' | cxTranslate }}
          </div>
          <div>
            {{ getTotalSuccessTransactions() }}
          </div>
        </div>
        <div
          class="failedTransactions d-flex mt-2"
          *ngIf="
            currentStep === steps.CONFIRMATION &&
            getTotalFailureTransactions() > 0
          "
        >
          <div class="width80">
            {{ 'fffInvoicePayment.failedTransactions' | cxTranslate }}
          </div>
          <div>
            {{ getTotalFailureTransactions() }}
          </div>
        </div>
        <div
          class="fff-invoice-payment-content-block__row ml-0"
          *ngIf="
            currentStep === steps.REVIEW &&
            getNumberOfInvoicesWithProcessingFee() > 0
          "
        >
          <input
            class="checkbox checkbox-sm"
            type="checkbox"
            id="processingFeeCondition"
            [checked]="acceptProcessingFeeCondition"
            (change)="
              acceptProcessingFeeCondition = !acceptProcessingFeeCondition
            "
          />
          <label
            class="checkbox-label d-flex mx-0 acceptProcessingFeeCondition"
            for="processingFeeCondition"
          >
            <span
              class="acceptProcessingFeeConditionText"
              [ngClass]="{
                acceptedProcessingFeeConditionText: acceptProcessingFeeCondition
              }"
              >{{
                'fffInvoicePayment.acceptProcessingFeeCondition' | cxTranslate
              }}</span
            >
          </label>
        </div>
        <div
          class="d-flex mt-2"
          *ngIf="
            currentStep === steps.CONFIRMATION &&
            getNumberOfInvoicesWithProcessingFee() > 0
          "
        >
          <div class="consentConfirmation mr-1">
            <cx-icon
              class="cx-icon icon-sm"
              [type]="filledIconTypes.ICON_CHECK_CIRCLE_FILL"
            ></cx-icon>
          </div>
          <div class="confirmationFont">
            {{ 'fffInvoicePayment.consentConfirmation' | cxTranslate }}
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div
  class="row row-large justify-content-end"
  *ngIf="currentStep < steps.CONFIRMATION"
>
  <div class="col-4 col-offset-8">
    <div class="fff-invoice-payment-actions d-flex justify-content-between">
      <button
        type="button"
        class="btn btn-secondary btn-xs mr-2"
        tabindex="-1"
        (click)="cancel()"
      >
        {{ 'fffInvoicePayment.cancel' | cxTranslate }}
      </button>
      <button
        *ngIf="currentStep === steps.SELECT_CARD"
        type="button"
        class="btn btn-primary btn-xs"
        (click)="reviewPayment()"
        [disabled]="!selectedCard"
      >
        {{ 'fffInvoicePayment.reviewPayment' | cxTranslate }}
      </button>
      <button
        *ngIf="currentStep === steps.REVIEW"
        type="button"
        class="btn btn-primary btn-xs"
        (click)="payInvoices()"
        [disabled]="
          submitting ||
          (!acceptProcessingFeeCondition &&
            getNumberOfInvoicesWithProcessingFee() > 0)
        "
      >
        {{
          totalInvoices > 1
            ? ('fffInvoicePayment.payInvoices' | cxTranslate)
            : ('fffInvoicePayment.payInvoice' | cxTranslate)
        }}
      </button>
    </div>
  </div>
</div>
<ng-template #surchargeInfoTooltip>
  <div class="surchargeInfoTooltip">
    {{
      getNumberOfInvoicesWithProcessingFee() > 1
        ? ('fffInvoicePayment.totalTooltipContent'
          | cxTranslate
            : {
                processingFee: getProcessingFee(),
                noOfInvoicesWithProcessingfee:
                  getNumberOfInvoicesWithProcessingFee()
              })
        : ('fffInvoicePayment.totalTooltipContentForSingleInvoice'
          | cxTranslate
            : {
                processingFee: getProcessingFee(),
                noOfInvoicesWithProcessingfee:
                  getNumberOfInvoicesWithProcessingFee()
              })
    }}
  </div>
  <div><div class="pointer"></div></div>
</ng-template>
