import { AfterViewInit, Component, ElementRef } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FffRingCentralChatBotService } from 'src/app/fff-enterprise/fff-service-third-party/fff-ring-central-chat-bot.service';
import { OUTLINED_ICON_TYPE } from 'src/app/models/fff-outline-icons.model';


@Component({
  selector: 'fff-checkout-error-placing-order',
  templateUrl: './fff-checkout-error-placing-order.component.html'
})
export class FffCheckoutErrorPlacingOrderComponent implements AfterViewInit {

  hasSuccessData: boolean = false;
  hasErrorData: boolean = false;
  errorData: any;

  outlinedIconTypes = OUTLINED_ICON_TYPE;

  constructor(
    private modalService: NgbActiveModal,
    private elementRef: ElementRef,
    private chatbotService: FffRingCentralChatBotService
  ) { }

  ngAfterViewInit(): void {
    if (this.elementRef.nativeElement.querySelector('.live-chat')) {
      this.elementRef.nativeElement.querySelector('.live-chat').addEventListener('click', this.openLiveChat.bind(this));
    }
  }

  continueOrderConfirmation(): void {
    this.modalService.close({ continueOrderConfirmation: true });
  }

  close(): void {
    this.modalService.close({ continueOrderConfirmation: false });
  }

  openLiveChat(): void {
    this.chatbotService.openChatBot();
  }

}
