import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FffAuthenticationService } from '@app/fff-enterprise/fff-common-services/fff-user-authentication.service';
import { AuthHttpHeaderService } from '@spartacus/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

const OAUTH_ENDPOINT = '/authorizationserver/oauth/token';

@Injectable({ providedIn: 'root' })
export class RefreshTokenExpiryHandler implements HttpInterceptor {
  constructor(
    protected authHttpHeaderService: AuthHttpHeaderService,
    private fffAuthenticationService: FffAuthenticationService
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((errResponse: HttpErrorResponse) => {
        const invalidTokenError = errResponse.error?.errors?.filter(
          (o: any) => o.type === 'InvalidTokenError'
        );
        if (
          (errResponse.status === 500 &&
            request.url.includes(OAUTH_ENDPOINT) &&
            request.body.get('grant_type') === 'refresh_token') ||
          invalidTokenError?.length > 0
        ) {
          this.authHttpHeaderService.handleExpiredRefreshToken();
        } else if (
          errResponse.status === 400 &&
          request.url.includes(OAUTH_ENDPOINT) &&
          errResponse.error.error === 'invalid_grant' &&
          errResponse.error.error_description === 'User is disabled'
        ) {
          this.fffAuthenticationService.emitUserDisabledEvent();
        }
        return throwError(errResponse);
      })
    );
  }

  protected isServerError(resp: HttpErrorResponse): boolean {
    return resp.error?.error === 'server_error';
  }
}
