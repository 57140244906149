/* Spartacus Component Names
 */

import { CUSTOM_ICON_TYPE } from 'src/app/models/fff-custom-icons.model';

export const SPARTACUS_CMS_TYPECODE = {
  CMS_LOGIN: 'LoginComponent',
  CMS_MINICART: 'MiniCartComponent',
  CMS_SKIPLINKS: 'CMSLinkComponent',
  CMS_CONTEXTLINKS_BIO: 'BioSupplyLink',
  CMS_CONTEXTLINKS_MFV: 'MyFluVaccineLink',
  CMS_CONTEXTLINKS_FFF: 'FFFEnterprisesLink',
  CMS_SITELINKS_SUPPORT: 'CustomerSupportLink',
  CMS_SITELINKS_RESOURCE: 'ResourcesLink',
  CMS_SITELINKS_SERVICE: 'ServicesLink',
  CMS_SITELINKS_SUPPORTPHONENUMBER: 'PhoneNumberLink',
};

export const SPARTACUS_CMS_NAV_NODES = {
  InfluenzaNavNode: 'InfluenzaNavNode',
  RespiratoryNavNode: 'RespiratoryNavNode',
  OrderFluVaccineNavNode: 'OrderFluVaccineNavNode',
  OrderPharmaceuticalsNavNodeMFV: 'OrderPharmaceuticalsNavNodeMFV',
  OrderFluVaccineNavNodeMFV: 'OrderFluVaccineNavNodeMFV',
};

/* FFF enterprise port keys
 */

export const BASE_URL_KEYS = {
  BIOSUPPLY: 'biosupply',
  MY_FLU_VACCINE: 'mfv',
};

export const BASE_SITE_URLS = {
  PRODUCTION: {
    BIOSUPPLY: 'https://biosupply.fffenterprises.com',
    MY_FLU_VACCINE: 'https://ordermyflu.myfluvaccine.com',
  },
};

/* Routes in FFF - Biosupply Application
 */

export const FFF_ROUTES = {
  REGISTER_PAGE: 'register-account',
  LOGIN_PAGE: 'login',
  JEFFERSON_REQUEST_PAGE: 'my-account/order-request',
};

/* Routes in FFF - Biosupply Application
 */

export const Register_routes = {
  REGISTER_VERIFY: '/register-verification',
  REGISTER_CONFIRM: '/register-confirmation',
  REGISTER: '/register',
};
/* SVG icon components in homepage
 */

export const BANNER_IMAGES: any = {
  section_images: [
    {
      title: 'MFVHomepagePreBookBannerComponent',
      icon: CUSTOM_ICON_TYPE.INVOICES,
      activeIcon: CUSTOM_ICON_TYPE.INVOICES_HOVER,
    },
    {
      title: 'FFFHomepageOrderHistoryBannerComponent',
      icon: CUSTOM_ICON_TYPE.ORDER_HISTORY_HOVER,
      activeIcon: CUSTOM_ICON_TYPE.ORDER_HISTORY,
    },
    {
      title: 'MFVHomepageOrderHistoryBannerComponent',
      icon: CUSTOM_ICON_TYPE.ORDER_HISTORY,
      activeIcon: CUSTOM_ICON_TYPE.ORDER_HISTORY_HOVER,
    },
    {
      title: 'FFFHomepageMyAccountsBannerComponent',
      icon: CUSTOM_ICON_TYPE.MY_ACCOUNTS_HOVER,
      activeIcon: CUSTOM_ICON_TYPE.MY_ACCOUNTS,
    },
    {
      title: 'FFFHomepageReportsBannerComponent',
      icon: CUSTOM_ICON_TYPE.REPORTS_HOVER,
      activeIcon: CUSTOM_ICON_TYPE.REPORTS,
    },
    {
      title: 'FFFHomepageInvoicesBannerComponent',
      icon: CUSTOM_ICON_TYPE.INVOICES_HOVER,
      activeIcon: CUSTOM_ICON_TYPE.INVOICES,
    },
    {
      title: 'MFVHomepageReturnBannerComponent',
      icon: CUSTOM_ICON_TYPE.ICON_RETURNS,
      activeIcon: CUSTOM_ICON_TYPE.ICON_RETURNS_HOVER,
    },
    {
      title: 'MFVHomepageFluVaccineResourcesBannerComponent',
      icon: CUSTOM_ICON_TYPE.ICON_VACCINE,
      activeIcon: CUSTOM_ICON_TYPE.ICON_VACCINE_HOVER,
    },
  ],
};

export const animationTime = 900;

export const gridBreakpointsXS: number = 0;
export const gridBreakpointsSM: number = 576;
export const gridBreakpointsMD: number = 768;
export const gridBreakpointsLG: number = 992;
export const gridBreakpointsXL: number = 1200;
export const gridBreakpointsXXL: number = 1400;

export const STOCK_STATUS = {
  IN_STOCK: 'IN_STOCK',
  ON_DEMAND: 'ON_DEMAND',
  IN_TRANSIT: 'IN_TRANSIT',
  DROP_SHIP: 'DROP_SHIP',
};

export const ACCOUNT_STATUS = {
  WITHOUT_LICENSE: 'WITHOUT_LICENSE',
  FIRST_USER_IN_ACCOUNT: 'FIRST_USER_IN_ACCOUNT',
  USER_NEED_VERIFICATION: 'USER_NEED_VERIFICATION',
  ACCOUNT_HAS_TRADE_PARTNER: 'ACCOUNT_HAS_TRADE_PARTNER',
};

export const MAX_DIFF_DAYS = 180;
export const THIRTY_DAYS: number = 30;
export const NINETY_DAYS: number = 90;
