<ng-container *ngIf="!isCartItem; else cartItem">
  <div class="large-header loader-animation w-100 p-3"></div>
  <div class="d-flex w-100 p-3">
    <div class="d-flex w-75">
      <ng-container [ngTemplateOutlet]="cartItem"></ng-container>
    </div>
    <div class="w-25">
      <div class="loader-animation button full"></div>
      <div
        class="d-flex justify-content-between full mb-3"
        *ngFor="let i of 'CART'.split('')"
      >
        <div class="loader-animation product-detail"></div>
        <div class="loader-animation product-detail"></div>
      </div>
      <div class="loader-animation link full mb-2"></div>
      <div class="loader-animation button full mb-2"></div>
      <div class="loader-animation link full mb-2"></div>
    </div>
  </div>
</ng-container>

<ng-template #cartItem>
  <div class="row col-12">
    <div class="col-2 p-0 loader-animation image"></div>
    <div class="col-10 d-flex justify-content-between w-100">
      <div class="w-50">
        <div class="loader-animation button full"></div>
        <div
          class="loader-animation link"
          *ngFor="let i of [1, 2, 3, 4, 5]"
        ></div>
      </div>
      <div class="w-50 pl-3">
        <fff-content-loader
          containerClassName="height-large"
          bodyClassName="d-flex price-row pt-2 pb-2 pl-3 pr-3"
        >
        </fff-content-loader>
        <div class="d-flex justify-content-between full mb-3">
          <div class="loader-animation product-detail"></div>
          <div class="loader-animation product-detail"></div>
        </div>
      </div>
    </div>
  </div>
</ng-template>
