<div class="container px-0" *ngIf="profile$ | async">
  <ng-container *ngIf="returnData$ | async as returnData">
    <ng-container
      *ngIf="
        returnData.returnEntries && returnData.returnEntries.length > 0;
        else noData
      "
    >
      <div class="row">
        <div class="col-12 text-center">
          <h2 class="h2 title mb-4">
            {{ 'fffReturns.fffRequest.title' | cxTranslate }}
          </h2>
        </div>
      </div>
      <div class="d-flex flex-wrap col-12 px-0 mt-3">
        <div class="col-lg-9 col-12 pr-lg-3 pl-lg-0 px-0">
          <div class="d-flex flex-wrap container-items m-0">
            <div class="col-12 py-3 px-lg-3 px-0">
              <div class="col-12 p-3 d-flex position-relative">
                <span class="body-text-demi-xl title">{{
                  'fffReturns.fffRequest.stepOne' | cxTranslate
                }}</span>
                <cx-icon
                  class="cx-icon icon-md edit-information-icon position-absolute cursor-pointer"
                  *ngIf="!editMode"
                  (click)="changeEditMode()"
                  [type]="outlinedIconTypes.ICON_PENCIL"
                >
                </cx-icon>
                <cx-icon
                  class="cx-icon icon-md edit-information-icon position-absolute cursor-pointer"
                  *ngIf="editMode"
                  (click)="changeEditMode()"
                  [type]="outlinedIconTypes.ICON_SAVE"
                >
                </cx-icon>
              </div>

              <div
                class="col-12 p-0 pb-4 py-lg-3 d-flex flex-wrap border-separator"
              >
                <ng-container *ngIf="!editMode">
                  <span class="col-lg-4 col-12"
                    ><span class="body-text-demi">{{
                      'fffReturns.name' | cxTranslate
                    }}</span>
                    {{ contactInformationData.name }}</span
                  >
                  <span class="col-lg-4 col-12"
                    ><span class="body-text-demi">{{
                      'fffReturns.phone' | cxTranslate
                    }}</span>
                    {{ contactInformationData.phone }}</span
                  >
                  <span class="col-lg-4 col-12"
                    ><span class="body-text-demi">{{
                      'fffReturns.email' | cxTranslate
                    }}</span>
                    {{ contactInformationData.email }}</span
                  >
                  <span class="col-12 pt-lg-2 pt-0"
                    ><span class="body-text-demi">{{
                      'fffReturns.poNumber' | cxTranslate
                    }}</span>
                    {{ contactInformationData.poNumber }}</span
                  >
                </ng-container>

                <ng-container *ngIf="editMode">
                  <form
                    [formGroup]="contactInformationForm"
                    class="col-12 px-0 d-flex flex-wrap"
                  >
                    <div class="d-flex flex-column col-lg-4 col-12">
                      <div class="d-flex justify-content-between">
                        <p class="mb-0 body-text-md body-text-demi">
                          {{ 'fffReturns.name' | cxTranslate }} &#42;
                        </p>
                      </div>
                      <input
                        class="form-control form-control-md"
                        type="text"
                        formControlName="name"
                      />
                      <cx-form-errors
                        aria-live="assertive"
                        aria-atomic="true"
                        [control]="$any(contactInformationForm).get('name')"
                      ></cx-form-errors>
                    </div>

                    <div class="d-flex flex-column col-lg-4 col-12">
                      <div class="d-flex justify-content-between">
                        <p class="mb-0 body-text-md body-text-demi">
                          {{ 'fffReturns.phone' | cxTranslate }} &#42;
                        </p>
                      </div>
                      <input
                        class="form-control form-control-md"
                        type="text"
                        formControlName="phone"
                      />
                      <cx-form-errors
                        aria-live="assertive"
                        aria-atomic="true"
                        [control]="$any(contactInformationForm).get('phone')"
                      ></cx-form-errors>
                    </div>

                    <div class="d-flex flex-column col-lg-4 col-12">
                      <div class="d-flex justify-content-between">
                        <p class="mb-0 body-text-md body-text-demi">
                          {{ 'fffReturns.email' | cxTranslate }} &#42;
                        </p>
                      </div>
                      <input
                        class="form-control form-control-md"
                        type="text"
                        formControlName="email"
                      />
                      <cx-form-errors
                        aria-live="assertive"
                        aria-atomic="true"
                        [control]="$any(contactInformationForm).get('email')"
                      ></cx-form-errors>
                    </div>

                    <div class="d-flex flex-column col-lg-4 col-12">
                      <div class="d-flex justify-content-between">
                        <p class="mb-0 body-text-md body-text-demi">
                          {{ 'fffReturns.poNumber' | cxTranslate }} &#42;
                        </p>
                      </div>
                      <input
                        class="form-control form-control-md"
                        type="text"
                        formControlName="poNumber"
                      />
                      <cx-form-errors
                        aria-live="assertive"
                        aria-atomic="true"
                        [control]="$any(contactInformationForm).get('poNumber')"
                      ></cx-form-errors>
                    </div>
                  </form>
                </ng-container>
              </div>
            </div>

            <div class="col-12 py-3 px-lg-3 px-0">
              <span class="body-text-demi-xl p-3 title">{{
                'fffReturns.fffRequest.stepTwo' | cxTranslate
              }}</span>
              <div class="p-3">
                <div class="container-items p-0 p-lg-3">
                  <form
                    *ngIf="addEntryGroup"
                    [formGroup]="addEntryGroup"
                    (keydown.enter)="$event.preventDefault()"
                  >
                    <div
                      class="d-flex flex-wrap p-0 p-lg-3 col-12"
                      *ngFor="
                        let item of returnData.returnEntries;
                        let index = index
                      "
                    >
                      <div class="col-12 px-0 d-flex flex-wrap">
                        <div class="col-2 col-lg-1 px-0">
                          <input
                            class="checkbox checkbox-md"
                            type="checkbox"
                            id="showAddProduct-{{ index }}"
                            [checked]="item.showAdd"
                            (change)="showAddProduct(item)"
                          />
                          <label
                            class="checkbox-label"
                            for="showAddProduct-{{ index }}"
                          ></label>
                        </div>
                        <div class="col-2 px-0">
                          <cx-media
                            [container]="getData(item.product!)"
                            format="cart"
                          ></cx-media>
                        </div>
                        <div class="col-8 col-lg-5">
                          <p class="mb-0 body-text-demi title">
                            {{ item?.product?.name }}
                          </p>
                          <p class="mb-0">{{ item?.product?.manufacturer }}</p>
                          <p class="mb-0">
                            {{ 'fffReturns.ndc' | cxTranslate }}
                            {{ item?.product?.ndc }}
                          </p>
                          <p class="mb-0">
                            {{ 'fffReturns.sku' | cxTranslate }}
                            {{ item?.product?.code }}
                          </p>
                          <p class="mb-0">
                            {{ 'fffReturns.account' | cxTranslate }}:
                            {{ item?.fffAccountNumber }}
                          </p>
                        </div>
                        <div
                          class="col-9 col-lg-4 mx-auto text-left text-lg-right px-0 pt-4 pt-lg-0"
                        >
                          <p class="mb-0 body-text-demi title">
                            {{
                              'fffReturns.fffRequest.qtyEligible'
                                | cxTranslate
                                  : {
                                      quantity: item?.eligibleQuantity,
                                      unit: item?.product?.unit
                                    }
                            }}
                          </p>
                          <p class="mb-0">
                            {{
                              'fffReturns.fffRequest.qtyOrdered'
                                | cxTranslate
                                  : {
                                      quantity: item?.orderedQuantity,
                                      unit: item?.product?.unit
                                    }
                            }}
                          </p>
                          <p
                            class="mb-0 body-text-demi"
                            *ngIf="item?.fetProduct"
                          >
                            {{
                              'fffReturns.fffRequest.qtyDisclaimer'
                                | cxTranslate
                                  : {
                                      quantity: item?.eligibleQuantity,
                                      unit: item?.product?.unit
                                    }
                            }}
                          </p>
                        </div>
                      </div>

                      <div
                        class="col-12 px-0 d-flex flex-wrap pt-5 pb-3"
                        *ngIf="item.showAdd"
                      >
                        <div
                          class="col-12 py-1 body-text-lg body-text-demi title"
                        >
                          {{
                            'fffReturns.fffRequest.addProductTitle'
                              | cxTranslate
                          }}
                        </div>
                        <div class="col-12 py-1 body-text-lg title">
                          {{
                            'fffReturns.fffRequest.addProductLabel'
                              | cxTranslate
                          }}
                        </div>

                        <div
                          class="col-12 col-lg-10 py-2 px-0 d-flex flex-wrap"
                          [formGroupName]="index"
                        >
                          <div class="d-flex flex-column col-lg-6 col-12">
                            <div class="d-flex justify-content-between">
                              <p class="mb-0 body-text-md">
                                {{
                                  'fffReturns.fffRequest.batchNumber'
                                    | cxTranslate
                                }}
                              </p>
                            </div>
                            <input
                              class="form-control form-control-md"
                              type="text"
                              formControlName="batchNumber"
                            />
                            <cx-form-errors
                              aria-live="assertive"
                              aria-atomic="true"
                              [control]="
                                $any(addEntryGroup).controls[index].get(
                                  'batchNumber'
                                )
                              "
                            ></cx-form-errors>
                          </div>

                          <div class="d-flex flex-column col-lg-6 col-12">
                            <div class="d-flex justify-content-between">
                              <p class="mb-0 body-text-md">
                                {{
                                  'fffReturns.fffRequest.quantity' | cxTranslate
                                }}
                              </p>
                            </div>
                            <input
                              class="form-control form-control-md"
                              type="text"
                              formControlName="quantity"
                              (blur)="validateQuantity(index, item, $event)"
                              fffAllowOnlyNumbers
                            />
                            <cx-form-errors
                              aria-live="assertive"
                              aria-atomic="true"
                              [control]="
                                $any(addEntryGroup).controls[index].get(
                                  'quantity'
                                )
                              "
                            ></cx-form-errors>
                          </div>
                        </div>

                        <div
                          class="d-lg-flex d-none col-lg-2 col-12 px-0 position-relative mt-3"
                        >
                          <button
                            class="btn btn-primary col-12 position-absolute mt-3"
                            (click)="addProduct(index, item)"
                            [disabled]="
                              !$any(addEntryGroup).controls[index]?.valid
                            "
                          >
                            {{
                              'fffReturns.fffRequest.addProductButton'
                                | cxTranslate
                            }}
                          </button>
                        </div>

                        <div class="d-flex d-lg-none col-lg-2 col-12 px-0 my-3">
                          <button
                            class="btn btn-primary col-12 mb-3"
                            (click)="addProduct(index, item)"
                            [disabled]="
                              !$any(addEntryGroup).controls[index]?.valid
                            "
                          >
                            {{
                              'fffReturns.fffRequest.addProductButton'
                                | cxTranslate
                            }}
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          class="col-lg-3 col-12 d-flex flex-wrap summary-container-request px-0"
        >
          <div class="col-12 px-0 d-flex flex-wrap">
            <div class="col-12 title text-center px-0 summary-title mb-3 py-4">
              <h4 class="m-0">
                {{
                  'fffReturns.fffRequest.summaryTitle'
                    | cxTranslate : { returnListLength: returnList.length }
                }}
              </h4>
            </div>
            <div class="col-12 px-0">
              <div
                class="col-12 d-flex flex-wrap px-0 border-separator mb-3 pb-3"
                *ngFor="let entryProduct of returnList; let entryIndex = index"
              >
                <div class="col-3 px-0">
                  <cx-media
                    [container]="getData(entryProduct?.product!)"
                    format="cart"
                  ></cx-media>
                </div>
                <div class="col-9">
                  <p class="mb-0 body-text-demi title">
                    {{ entryProduct?.product?.name }}
                  </p>
                  <p class="mb-0">
                    {{
                      'fffReturns.fffRequest.qtyAdded'
                        | cxTranslate
                          : {
                              quantity: entryProduct?.quantity,
                              unit: entryProduct?.product?.unit
                            }
                    }}
                  </p>
                  <p class="mb-0">{{ entryProduct?.product?.manufacturer }}</p>
                  <p class="mb-0">
                    {{ 'fffReturns.ndc' | cxTranslate }}
                    {{ entryProduct?.product?.ndc }}
                  </p>
                  <p class="mb-0">
                    {{ 'fffReturns.sku' | cxTranslate }}
                    {{ entryProduct?.product?.code }}
                  </p>
                  <p class="mb-0">
                    {{ 'fffReturns.account' | cxTranslate }}:
                    {{ entryProduct?.fffAccountNumber }}
                  </p>
                  <p class="mb-0">
                    {{ 'fffReturns.batchNumber' | cxTranslate }}:
                    {{ entryProduct?.batchId }}
                  </p>
                </div>
                <div class="col-12 px-0 pt-3 px-3">
                  <a
                    class="body-text-sm remove-item"
                    (click)="removeProduct(entryIndex)"
                    >{{ 'fffReturns.fffRequest.remove' | cxTranslate }}</a
                  >
                </div>
              </div>
            </div>
          </div>

          <div class="col-12 m-auto summary-actions">
            <button
              class="btn btn-primary col-12"
              [class.updating]="loading$ | async"
              [disabled]="returnList.length < 1"
              (click)="submitRequest()"
            >
              {{ 'fffReturns.fffRequest.submitRequest' | cxTranslate }}
              <cx-icon
                *ngIf="loading$ | async"
                class="cx-icon icon-md ml-3"
                [type]="outlinedIconTypes.ICON_ARROW_REPEAT"
              ></cx-icon>
            </button>
            <button
              class="btn btn-secondary col-12 mt-2"
              (click)="cancelRequest()"
            >
              {{ 'fffReturns.fffRequest.cancelRequest' | cxTranslate }}
            </button>
            <div class="col-12 text-center summary-customer-support">
              <span>{{
                'fffReturns.fffRequest.supportText' | cxTranslate
              }}</span>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
  </ng-container>
  <ng-template #noData>
    <div class="text-center col-12 mt-5">
      <h4 class="h4 mb-4">{{ 'fffReturns.noProducts' | cxTranslate }}</h4>
      <button class="btn btn-primary m-auto" (click)="goToHomePage()">
        {{ 'fffSavedCarts.goToHomePage' | cxTranslate }}
      </button>
    </div>
  </ng-template>
</div>
