import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
} from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { FffDepartmentsResponse } from '@app/models/fff-contact-us-reason.model';
import { FffUserAccountService } from '@app/shared/services/fff-user-account.service';
import { WindowRef } from '@spartacus/core';
import { CustomFormValidators } from '@spartacus/storefront';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { FffContactUsData } from 'src/app/models/fff-contact-us-data.model';
import { OUTLINED_ICON_TYPE } from 'src/app/models/fff-outline-icons.model';
import { environment } from 'src/environments/environment';
import { FffRingCentralChatBotService } from '../../fff-service-third-party/fff-ring-central-chat-bot.service';
import { ContactUsService } from '../fff-contact-us.service';

@Component({
  selector: 'fff-contact-us',
  templateUrl: './fff-contact-us.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FffContactUsComponent implements OnInit, AfterViewInit, OnDestroy {
  outlinedIconTypes = OUTLINED_ICON_TYPE;
  departments$: Observable<FffDepartmentsResponse> = this.service.getDepartments();
  showFormRequest$ = new BehaviorSubject(true);

  sub = new Subscription();

  constructor(
    protected fb: UntypedFormBuilder,
    private service: ContactUsService,
    private chatbotService: FffRingCentralChatBotService,
    private elementRef: ElementRef,
    public windowRef: WindowRef,
    private userService: FffUserAccountService
  ) {}

  ngOnInit(): void {
    this.sub.add(
      this.userService.getProfile().subscribe(profile => {
        if (profile && profile.user?.orgUnit?.uid) {
          this.form.patchValue({
            accountNumber: profile.user?.orgUnit?.uid,
          });
        }
      })
    );
  }

  ngAfterViewInit() {
    if (this.elementRef.nativeElement.querySelector('.live-chat')) {
      this.elementRef.nativeElement
        .querySelector('.live-chat')
        .addEventListener('click', this.openLiveChat.bind(this));
    }

    if (this.elementRef.nativeElement.querySelector('.phone-number')) {
      this.elementRef.nativeElement
        .querySelector('.phone-number')
        .addEventListener('click', this.openPhoneApp.bind(this));
    }
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  form: UntypedFormGroup = this.fb.group({
    firstName: ['', [Validators.required]],
    lastName: ['', [Validators.required]],
    departmentCode: ['', [Validators.required]],
    comments: ['', [Validators.required]],
    email: ['', [Validators.required, CustomFormValidators.emailValidator]],
    accountNumber: [
      '',
      [Validators.pattern(new RegExp('^[a-zA-Z0-9 ,]+$', 'i'))],
    ],
  });

  onSubmit(): void {
    if (!this.form.valid) {
      this.form.markAllAsTouched();
      return;
    }

    let requestData: FffContactUsData = this.form.value;
    const response$ = this.service.submit(requestData);
    response$.subscribe(() => this.onSucess());
  }

  private onSucess(): void {
    this.showFormRequest$.next(false);
  }

  openLiveChat(): void {
    this.chatbotService.openChatBot();
  }

  openPhoneApp(): void {
    if (this.windowRef.isBrowser()) {
      const phoneNumber = environment.contactUsPhoneNumber;
      this.windowRef.nativeWindow?.open(`tel:${phoneNumber}`, '_blank');
    }
  }

  getCountNumber(): number {
    const comments: string = this.form.get('comments')?.value;
    return comments ? comments.length : 0;
  }
}
