import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostListener,
  OnInit,
} from '@angular/core';
import { OUTLINED_ICON_TYPE } from '@app/models/fff-outline-icons.model';
import { WindowRef } from '@spartacus/core';

@Component({
  selector: 'fff-scroll-to-top',
  templateUrl: './fff-scroll-to-top.component.html',
  styleUrls: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FffScrollToTopComponent implements OnInit {
  private offset = 50;
  outlinedIconTypes = OUTLINED_ICON_TYPE;
  showButton: boolean = false;

  @HostListener('window:scroll') onScroll() {
    let scrollY = this.winRef?.nativeWindow?.scrollY ?? 0;
    let bodyScrollTop = this.winRef?.document.body.scrollTop ?? 0;
    let documentElementScrollTop =
      this.winRef?.document.documentElement.scrollTop ?? 0;

    this.showButton =
      scrollY > this.offset ||
      bodyScrollTop > this.offset ||
      documentElementScrollTop > this.offset;
    this.cd.markForCheck();
  }

  constructor(private cd: ChangeDetectorRef, private winRef: WindowRef) {}

  ngOnInit(): void {
    this.onScroll();
  }

  scrollToTop(e: Event) {
    e.preventDefault();
    this.winRef?.nativeWindow?.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }
}
