import { Injectable } from '@angular/core';
import { BaseSiteService, Config, ConfigInitializer, I18nConfig, OccEndpointsService } from '@spartacus/core';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';

/**
 * Sets up the custom configuration for the i18n backend load path
 */
@Injectable({ providedIn: 'root' })
export class FFFI18nBackendLoadPathConfigInitializer implements ConfigInitializer {
  readonly scopes = ['i18n.backend.loadPath'];
  readonly configFactory = async (): Promise<Config> => {
    const config = await this.resolveConfig().toPromise();
    if (!config) {
      throw new Error('Configuration is undefined');
    }
    return config;
  };

  constructor(
    protected baseSiteService: BaseSiteService,
    protected occEndpointsService: OccEndpointsService
  ) { }

  /**
   * Resolves the i18n config
   * 
   * {{lng}} placeholder for language
   * {{ns}} placeholder for chunks/namespaces
   * 
   * OOB loadPath: 'assets/i18n-assets/{{lng}}/{{ns}}.json'
   */
  protected resolveConfig(): Observable<I18nConfig> {
    return this.baseSiteService.getActive().pipe(
      take(1),
      map(() => {
        const baseUrl = this.occEndpointsService.getBaseUrl({ baseSite: true, baseUrl: true, prefix: true });
        const i18nEndpoint = '/i18n-dictionary/getMessages';
        const params = '?namespace={{ns}}&locale={{lng}}';
        return {
          i18n: {
            backend: {
              loadPath: `${baseUrl}${i18nEndpoint}${params}`,
            },
          },
        } as I18nConfig;
      })
    );
  }
}
