import { Location } from '@angular/common';
import { Component, ElementRef } from '@angular/core';
import { CUSTOM_ICON_TYPE } from 'src/app/models/fff-custom-icons.model';

@Component({
  selector: 'fff-not-found',
  templateUrl: './fff-not-found.component.html'
})
export class FffNotFoundComponent {

  customIconTypes = CUSTOM_ICON_TYPE;

  constructor(private elementRef: ElementRef, private location: Location) { }

  ngAfterViewInit() {
    if (this.elementRef.nativeElement.querySelector('.go-back')) {
      this.elementRef.nativeElement.querySelector('.go-back').addEventListener('click', this.goBack.bind(this));
    }
  }

  goBack(): void {
    this.location.back();
  }

}
