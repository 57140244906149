import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { AsmComponentService, CustomerEmulationComponent } from '@spartacus/asm/components';
import { FeatureModulesService, I18nModule } from '@spartacus/core';
import { LaunchDialogService } from '@spartacus/storefront';
import { UserAccountFacade } from '@spartacus/user/account/root';
import { FFFAsmBindCartComponent } from '../asm-bind-cart/asm-bind-cart.component';

@Component({
  selector: 'cx-asm-customer-emulation',
  templateUrl: './asm-customer-emulation.component.html',
  standalone: true,
  imports: [FFFAsmBindCartComponent,I18nModule,CommonModule]
})
export class AsmCustomerEmulationComponent extends CustomerEmulationComponent {

  constructor(
    protected asmComponentService: AsmComponentService,
    protected userAccountFacade: UserAccountFacade,
    protected launchDialogService?: LaunchDialogService,
    protected featureModules?: FeatureModulesService
  ) {
    super(asmComponentService,userAccountFacade,launchDialogService,featureModules)
  }
}
