import { Component, Input, OnDestroy } from '@angular/core';
import { FffCartService } from '@app/fff-enterprise/fff-common-services/fff-cart-service';
import { OUTLINED_ICON_TYPE } from '@app/models/fff-outline-icons.model';
import { FffProfile } from '@app/models/fff-profile.model';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { CartActions, StateWithMultiCart } from '@spartacus/cart/base/core';
import {
  ActiveCartFacade,
  Cart,
  MultiCartFacade,
} from '@spartacus/cart/base/root';
import { Subscription } from 'rxjs';
import { take, tap } from 'rxjs/operators';

@Component({
  selector: 'fff-change-account-warning-modal',
  templateUrl: './fff-change-account-warning-modal.component.html',
})
export class FffChangeAccountWarningModalComponent implements OnDestroy {
  @Input() profile!: FffProfile;
  @Input() cart!: Cart;

  iconWarning = OUTLINED_ICON_TYPE.ICON_INFO_CIRCLE;
  iconClose = OUTLINED_ICON_TYPE.ICON_X;

  private subs: Subscription = new Subscription();

  constructor(
    protected modalService: NgbActiveModal,
    protected fffCartService: FffCartService,
    protected activeCartService: ActiveCartFacade,
    private multiCartFacade: MultiCartFacade,
    private store: Store<StateWithMultiCart>,
    private actions: Actions
  ) {}

  ngOnDestroy(): void {
    this.subs?.unsubscribe();
  }

  proceed() {
    if (this.cart.code && this.cart.entries && this.cart.entries.length) {
      const userId = this.profile.user.uid;
      const cartId = this.cart.code;

      this.removeCart(userId, cartId);

      this.subs.add(
        this.actions
          .pipe(
            ofType(CartActions.DELETE_CART_SUCCESS),
            take(1),
            tap(() => {
              this.multiCartFacade.createCart({
                userId,
                extraData: {
                  active: true,
                },
              });
            })
          )
          .subscribe()
      );

      this.subs.add(
        this.actions
          .pipe(
            ofType(CartActions.DELETE_CART_FAIL, CartActions.CREATE_CART_FAIL),
            take(1),
            tap(() => this.modalService.close(false))
          )
          .subscribe()
      );

      this.subs.add(
        this.actions
          .pipe(
            ofType(CartActions.CREATE_CART_SUCCESS),
            take(1),
            tap(() => this.modalService.close(true))
          )
          .subscribe()
      );
    }
  }

  close() {
    this.modalService.close(false);
  }

  removeCart(userId: string, cartId: string): void {
    this.store.dispatch(
      new CartActions.DeleteCart({
        userId,
        cartId,
      })
    );
  }
}
