import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'fffCreditCardMask',
})
export class FffCreditCardMaskPipe implements PipeTransform {
  transform(creditCardNumber: any, cardType: any): string {
    if (!creditCardNumber || creditCardNumber.length < 4) {
      return creditCardNumber;
    }

    const lastFourDigits = creditCardNumber.slice(-4);

    let maskedValue = '';

    // If it's Amex, prefix with 11 * and then show the last 4 digits
    if (cardType == 'AMEX') {
      maskedValue = '**** **** *** ' + lastFourDigits;
    } else {
      // Prefix with 12 * and then show the last 4 digits
      maskedValue = '**** **** **** ' + lastFourDigits;
    }

    return maskedValue;
  }
}
