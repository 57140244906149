import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FffTableModule } from '@app/shared/table/fff-table.module';
import {
  FffAllocationsTabsComponent
} from '@enterprise/fff-allocations/fff-allocations-tabs/fff-allocations-tabs.component';
import { NgbAccordionModule, NgbDatepickerModule, NgbDropdownModule, NgbModalModule } from '@ng-bootstrap/ng-bootstrap';
import { AuthGuard, ConfigModule, I18nModule } from '@spartacus/core';
import { FormErrorsModule, GenericLinkModule, IconModule, SpinnerModule } from '@spartacus/storefront';
import { FffAllocationsHistoryFilterComponent } from './fff-allocations-history/fff-allocations-history-filter/fff-allocations-history-filter.component';
import { FffAllocationsHistoryComponent } from './fff-allocations-history/fff-allocations-history.component';
import { FffAllocationsComponent } from './fff-allocations.component';
import { FffCurrentAllocationsComponent } from './fff-current-allocations/fff-current-allocations.component';
import { FffInteractiveAllocationsListComponent } from './fff-interactive-allocations-list/fff-interactive-allocations-list.component';
import { FffSearchInteractiveAllocationsDrawerComponent } from './fff-search-interactive-allocations-drawer/fff-search-interactive-allocations-drawer.component';
import { FffUpdateInteractiveAllocationsBodyComponent } from './fff-update-interactive-allocations-drawer/fff-update-interactive-allocations-body/fff-update-interactive-allocations-body.component';
import { FffUpdateInteractiveAllocationsDrawerComponent } from './fff-update-interactive-allocations-drawer/fff-update-interactive-allocations-drawer.component';
import { FffUpdateInteractiveAllocationsFooterComponent } from './fff-update-interactive-allocations-drawer/fff-update-interactive-allocations-footer/fff-update-interactive-allocations-footer.component';
import { FffUpdateInteractiveAllocationsHeaderComponent } from './fff-update-interactive-allocations-drawer/fff-update-interactive-allocations-header/fff-update-interactive-allocations-header.component';
import { FffUpdateInteractiveAllocationsPopupComponent } from './fff-update-interactive-allocations-drawer/fff-update-interactive-allocations-popup/fff-update-interactive-allocations-popup.component';
import { FFFLoadersModule } from "../../shared/components/fff-loaders/fff-loaders.module";

@NgModule({
  declarations: [
    FffAllocationsComponent,
    FffAllocationsTabsComponent,
    FffAllocationsHistoryComponent,
    FffCurrentAllocationsComponent,
    FffAllocationsHistoryFilterComponent,
    FffInteractiveAllocationsListComponent,
    FffSearchInteractiveAllocationsDrawerComponent,
    FffUpdateInteractiveAllocationsHeaderComponent,
    FffUpdateInteractiveAllocationsFooterComponent,
    FffUpdateInteractiveAllocationsBodyComponent,
    FffUpdateInteractiveAllocationsPopupComponent,
    FffUpdateInteractiveAllocationsDrawerComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    IconModule,
    GenericLinkModule,
    I18nModule,
    FffTableModule,
    SpinnerModule,
    NgbAccordionModule,
    ReactiveFormsModule,
    FormErrorsModule,
    NgbDatepickerModule,
    ConfigModule.withConfig({
        cmsComponents: {
            AllocationPageComponent: {
                component: FffAllocationsComponent,
                guards: [AuthGuard]
            },
        },
    }),
    NgbDropdownModule,
    NgbModalModule,
    FFFLoadersModule,
],
})

export class FffAllocationsModule { }
