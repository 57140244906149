import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { gridBreakpointsLG } from '@app/fff-config/content/constants';
import { FFFCartService } from '@app/fff-enterprise/fff-common-services/fff-cart.service';
import { OUTLINED_ICON_TYPE } from '@app/models/fff-outline-icons.model';
import { ToastService } from '@app/shared/services/toast.service';
import { FffChangeAccountWarningModalComponent } from '@enterprise/fff-header/fff-change-account/fff-change-account-warning-modal/fff-change-account-warning-modal.component';
import { FffProfile } from '@model/fff-profile.model';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { FffUserAccountService } from '@shared/services/fff-user-account.service';
import { ActiveCartFacade, Cart } from '@spartacus/cart/base/root';
import { SavedCartFacade } from '@spartacus/cart/saved-cart/root';
import { RoutingService } from '@spartacus/core';
import { Subject } from 'rxjs';
import { filter, take, takeUntil } from 'rxjs/operators';
import { FFFSavedCartDeleteConfirmationComponent } from '../fff-saved-cart-delete-confirmation/fff-saved-cart-delete-confirmation.component';
import { FFFSavedCartMessagePopupComponent } from '../fff-saved-cart-message-popup/fff-saved-cart-message-popup.component';

@Component({
  selector: 'fff-saved-cart-list',
  templateUrl: './fff-saved-cart-list.component.html',
})
export class FffSavedCartListComponent implements OnInit, OnDestroy {
  outlinedIconTypes = OUTLINED_ICON_TYPE;
  columnsGrid: any = {
    desktop: '1fr 1fr 1fr 1fr 2fr',
    mobile: '1fr 1fr',
  };
  fields: any = [
    { title: 'fffSavedCarts.name', field: 'name' },
    { title: 'fffSavedCarts.description', field: 'description' },
    { title: 'fffSavedCarts.dateSaved', field: 'saveTime' },
    { title: 'fffSavedCarts.numberItems', field: 'nItems' },
    { title: 'fffSavedCarts.actions', field: 'actions' },
  ];
  dataList$ = this.savedCartService.getList();
  mobileSize: any = gridBreakpointsLG;
  loading: boolean = false;
  profile: FffProfile | undefined;
  cart: Cart | null = null;
  private unsubscribe$: Subject<void> = new Subject<void>();
  fffWarningModal: NgbModalRef | null = null;

  constructor(
    protected routing: RoutingService,
    protected fffCartService: FFFCartService,
    private fffActiveCartService: ActiveCartFacade,
    private activeCartService: ActiveCartFacade,
    private fffUserAccountService: FffUserAccountService,
    protected savedCartService: SavedCartFacade,
    private modalService: NgbModal,
    public cd: ChangeDetectorRef,
    private toastService: ToastService
  ) {}

  ngOnInit(): void {
    this.fffUserAccountService
      .getProfile()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(profile => {
        this.profile = profile;
      });
    this.fffActiveCartService
      .getActive()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(cart => {
        this.cart = cart;
      });
    this.savedCartService.loadSavedCarts();
  }

  ngOnDestroy(): void {
    this.savedCartService.clearSavedCarts();
    this.savedCartService.clearSaveCart();
    this.savedCartService.clearRestoreSavedCart();
  }

  getTableData(data: any[]) {
    return { data: data, total: data.length, loading: false, current: 1 };
  }

  goToHomePage(): void {
    this.routing.go(['/']);
  }

  goToDetailCart(cartId: string): void {
    this.routing.go([`/my-account/saved-cart/${cartId}`]);
  }

  goToEditSavedCartDetails(savedCartId: string): void {
    this.routing.go({
      cxRoute: 'editSavedCartsDetails',
      params: { savedCartId },
    });
  }

  createOrder(cartId: string): void {
    /* Check if current cart is empty */
    if (!this.cart || !this.cart.entries || this.cart.entries.length === 0) {
      this.cloneAndRestoreCart(cartId);
    } else {
      this.openCartWarningDrawer(cartId);
    }
  }

  cloneAndRestoreCart(cartId: string): void {
    this.loading = true;

    this.fffCartService
      .cloneCart(this.profile?.user?.uid!, cartId)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (cart: any) => {
          this.savedCartService.restoreSavedCart(cart.savedCartData.code);
          this.savedCartService
            .getRestoreSavedCartProcessSuccess()
            .pipe(
              filter(state => !!state),
              take(1),
              takeUntil(this.unsubscribe$)
            )
            .subscribe(
              () => {
                this.loading = false;
                this.toastService.showSuccess(
                  'fffSavedCarts.messages.createOrderSavedCart',
                  {
                    delay: 3000,
                  }
                );
                this.cd.detectChanges();
                this.activeCartService.getActive();
                setTimeout(() => {
                  this.routing.go(['/cart']);
                }, 1000);
              },
              () => {
                this.loading = false;
                this.cd.detectChanges();
                this.showMessagePopup();
              }
            );
        },
        () => {
          this.loading = false;
          this.cd.detectChanges();
          this.showMessagePopup();
        }
      );
  }

  private openCartWarningDrawer(cartId: string) {
    if (!this.fffWarningModal) {
      this.fffWarningModal = this.modalService.open(
        FffChangeAccountWarningModalComponent,
        {
          animation: true,
          backdrop: 'static',
        }
      );
      this.fffWarningModal.componentInstance.profile = this.profile;
      this.fffWarningModal.componentInstance.cart = this.cart;
      this.fffWarningModal.result
        .then(result => {
          if (result) {
            this.cloneAndRestoreCart(cartId);
          }
          this.fffWarningModal = null;
        })
        .catch(() => {
          this.fffWarningModal = null;
        });
    }
  }

  private showMessagePopup(): void {
    this.modalService.open(FFFSavedCartMessagePopupComponent, {
      centered: true,
      size: 'md',
      backdrop: 'static',
      modalDialogClass: 'modal-container',
    });
  }

  deleteCart(cartId: string, savedCartName: string): void {
    const modalRef = this.modalService.open(
      FFFSavedCartDeleteConfirmationComponent,
      {
        centered: true,
        size: 'md',
        backdrop: 'static',
        modalDialogClass: 'modal-container',
      }
    );
    modalRef.componentInstance.savedCartName = savedCartName;

    modalRef.result.then(result => {
      if (result?.isDeleteConfirmed) {
        this.savedCartService.deleteSavedCart(cartId);
        this.toastService.showSuccess(
          'fffSavedCarts.messages.deletedSavedCart',
          {
            delay: 3000,
            i18nParams: {
              cartName: savedCartName,
            },
          }
        );
      }
    });
  }
}
