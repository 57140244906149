import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { FilterState, FILTER_PRICELIST_BY_CATEGORY_APPLIED } from 'src/app/reducers';
import { FffDrawerService } from 'src/app/shared/drawer/fff-drawer.service';

@Component({
  selector: 'fff-user-price-list-drawer-apply',
  templateUrl: './fff-user-price-list-drawer-apply.component.html',
})
export class FffUserPriceListDrawerApplyComponent {

  selected: string[] = [];

  constructor(
    protected drawerService: FffDrawerService,
    private store: Store<FilterState>,

  ) { }


  applyFilters() {

    this.store.dispatch({
      type: FILTER_PRICELIST_BY_CATEGORY_APPLIED,
      payload: this.selected
    });
    this.drawerService.closeDrawer();

  }

  ngOnInit() {

    this.store.select((state: any) => state.Filters).subscribe((e: FilterState) => {
      if (e.priceListByCategory) {
        this.selected = e.priceListByCategory;
      }

    })

  }

}


