<div
  class="container"
  *ngIf="invoiceDetail$ | async as invoiceDetail; else loading"
>
  <div class="header-details p-4">
    <div class="row">
      <div class="col-md-6 col-sm-12">
        <p class="mb-0">
          <span class="body-text-demi">
            {{ 'fffInvoiceHistoryDetail.accountName' | cxTranslate }}
          </span>
          &nbsp;
          <span>{{ invoiceDetail.soldToName }}</span>
        </p>
        <p class="mb-0">
          <span class="body-text-demi">
            {{ 'fffInvoiceHistoryDetail.accountNumber' | cxTranslate }}
          </span>
          &nbsp;
          <span>{{ invoiceDetail.uid }}</span>
        </p>
        <p class="mb-0">
          <span class="body-text-demi">
            {{ 'fffInvoiceHistoryDetail.orderDate' | cxTranslate }}
          </span>
          &nbsp;
          <span>{{ invoiceDetail.orderDate | date : 'MM/dd/yyyy' }}</span>
        </p>
        <p class="mb-0">
          <span class="body-text-demi">
            {{ 'fffInvoiceHistoryDetail.contactName' | cxTranslate }}
          </span>
          &nbsp;
          <span>{{ invoiceDetail.contactName }}</span>
        </p>
      </div>
      <div class="col-md-6 col-sm-12">
        <p class="mb-0">
          <span class="body-text-demi">
            {{ 'fffInvoiceHistoryDetail.status' | cxTranslate }}
          </span>
          &nbsp;
          <span>{{ invoiceDetail.invoiceStatus }}</span>
        </p>
        <p class="mb-0">
          <span class="body-text-demi">
            {{ 'fffInvoiceHistoryDetail.paymentTerms' | cxTranslate }}
          </span>
          &nbsp;
          <span>{{ invoiceDetail.paymentTerms }}</span>
        </p>
      </div>
    </div>
  </div>

  <div class="py-4">
    <div class="summary py-4">
      <div class="d-flex flex-wrap">
        <div class="px-0 col-12">
          <div class="col-12">
            <div class="header text-center">
              {{ 'fffInvoiceHistoryDetail.invoiceDate' | cxTranslate }}
              {{ invoiceDetail.invoiceDate | date : 'MM/dd/yyyy' }}
            </div>
            <div class="subtitle text-center">
              {{ 'fffInvoiceHistoryDetail.po' | cxTranslate }}
              {{ invoiceDetail.poNumber }}
            </div>
            <div class="subtitle text-center">
              {{ 'fffInvoiceHistoryDetail.orderNumber' | cxTranslate }}
              {{ invoiceDetail.orderNumber }}
            </div>
            <div class="d-flex flex-wrap col-12 py-3">
              <div
                class="col-12 px-0 text-right"
                (click)="downloadPDF(invoiceDetail.invoiceNumber)"
              >
                <span class="action-label d-inline-flex">
                  {{
                    'fffInvoiceHistoryDetail.actions.downloadPdf' | cxTranslate
                  }}
                  <cx-icon
                    class="cx-icon icon-sm pl-2"
                    [type]="outlinedIconTypes.ICON_DOWNLOAD"
                  ></cx-icon>
                </span>
              </div>
            </div>

            <ng-container *ngFor="let item of invoiceDetail.materials">
              <div class="d-flex flex-wrap col-12">
                <div class="item-entry d-flex pt-3 pb-5 col-12 px-0">
                  <div
                    class="col-2 px-0"
                    *ngIf="getProduct(item.productCode) | async as product"
                  >
                    <a
                      [routerLink]="product.url"
                      cxModal="dismiss"
                      tabindex="-1"
                    >
                      <cx-media
                        [container]="getData(product)"
                        format="product"
                      ></cx-media>
                    </a>
                  </div>

                  <div class="cx-info col-10">
                    <div class="cx-info-container row">
                      <div class="col-md-6 col-sm-12">
                        <div class="text-left">
                          <p class="name mb-0">{{ item.productName }}</p>
                          <p class="mb-0">
                            {{ 'fffProduct.lblNdc' | cxTranslate }}
                            {{ item.ndcNumber }}
                          </p>
                          <p class="mb-0">
                            {{ 'fffProduct.lblSku' | cxTranslate }}
                            {{ item.productCode }}
                          </p>
                          <p class="mb-0">
                            {{ 'fffOrderHistoryDetail.quantity' | cxTranslate }}
                            {{ item.invoiceQuantity }} /
                            {{ item.sellingUnitofMeasure }}
                          </p>
                        </div>
                      </div>

                      <div class="col-md-6 col-sm-12 px-0">
                        <div class="text-right">
                          <p class="total mb-0">
                            ${{ item.lineAmount | number : '1.2-2' }}
                          </p>
                          <p
                            class="mb-0"
                            *ngIf="item.fffDiscount && item.fffDiscount !== 0"
                          >
                            {{
                              'fffOrderHistoryDetail.fffDiscount' | cxTranslate
                            }}
                            -${{ item.fffDiscount | number : '1.2-2' }}
                          </p>
                          <p
                            class="mb-0"
                            *ngIf="
                              item.federalExciseTax &&
                              item.federalExciseTax !== 0
                            "
                          >
                            {{ 'fffOrderHistoryDetail.feTax' | cxTranslate }}
                            ${{ item.federalExciseTax | number : '1.2-2' }}
                          </p>
                          <p class="mb-0">
                            {{ 'fffOrderHistoryDetail.shipping' | cxTranslate }}
                            <span
                              [ngClass]="{
                                'shipping-cost': +item.shippingCost > 0
                              }"
                            >
                              ${{ item.shippingCost | number : '1.2-2' }}</span
                            >
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </ng-container>
          </div>
        </div>
      </div>

      <div class="d-flex flex-wrap justify-content-end">
        <div class="col-sm-12 col-md-6 col-lg-5 col-xl-4">
          <div class="col-12 py-3">
            <div class="d-flex flex-wrap summary-row">
              <div class="col-6 px-0 summary-label summary-total body-text-xl">
                {{ 'orderCost.total' | cxTranslate }}
              </div>
              <div
                class="col-6 px-0 summary-amount summary-total body-text-xl text-right"
              >
                ${{ invoiceDetail.grandTotal | number : '1.2-2' }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #loading>
  <fff-cart-loader [isCartItem]="true"></fff-cart-loader>
</ng-template>
