import { Component, OnDestroy, OnInit } from '@angular/core';
import { FFFCartService } from '@app/fff-enterprise/fff-common-services/fff-cart.service';
import { ToastService } from '@app/shared/services/toast.service';
import { ActiveCartFacade } from '@spartacus/cart/base/root';
import { SavedCartFacade } from '@spartacus/cart/saved-cart/root';
import { UserProfileFacade } from '@spartacus/user/profile/root';
import { Observable, Subject } from 'rxjs';
import { switchMap, take, takeUntil } from 'rxjs/operators';
import { OUTLINED_ICON_TYPE } from 'src/app/models/fff-outline-icons.model';
import { FffDrawerService } from 'src/app/shared/drawer/fff-drawer.service';
import { FffSaveCartDrawerService } from './fff-save-cart-drawer.service';

@Component({
  selector: 'fff-save-cart-drawer-footer',
  templateUrl: './fff-save-cart-drawer-footer.component.html',
})
export class FffSaveCartDrawerFooterComponent implements OnInit, OnDestroy {
  outlinedIconTypes = OUTLINED_ICON_TYPE;
  public account: any | undefined;
  cart$ = this.fffActiveCartService.getActive();
  cartId = '';
  loading: boolean = false;
  saveCartForm = this.saveCartDrawerService.saveCartForm.value;
  private unsubscribe$: Subject<void> = new Subject<void>();

  constructor(
    protected fffActiveCartService: ActiveCartFacade,
    protected fffCartService: FFFCartService,
    private userProfileFacade: UserProfileFacade,
    private drawerService: FffDrawerService,
    protected savedCartService: SavedCartFacade,
    private saveCartDrawerService: FffSaveCartDrawerService,
    private toastService: ToastService
  ) {}

  ngOnInit(): void {
    this.cart$.subscribe(cart => {
      this.cartId = cart.code!;
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  public saveCart(): void {
    if (this.saveCartForm && !this.saveCartForm.valid) {
      this.saveCartForm.markAllAsTouched();
      return;
    }
    this.loading = true;

    if (this.saveCartForm?.controls['cartDescription']?.value === '') {
      // add an empty space if cart description field is blank
      this.saveCartForm.controls['cartDescription'].setValue(' ');
    }

    let requestData = this.saveCartForm!.value;

    const response$ = this.userProfileFacade.get().pipe(
      take(1),
      switchMap(user => {
        return this.fffCartService.saveCart(
          user?.uid!,
          this.cartId,
          requestData.cartName,
          requestData.cartDescription
        );
      })
    );

    response$.pipe(take(1)).subscribe(
      () => {
        this.createNewCart(this.saveCartForm, requestData.removeItems);
      },
      error => {
        if (this.saveCartForm) {
          this.saveCartForm.get('cartName')?.setErrors({ errorSaveCart: true });
          this.loading = false;
        }
      }
    );
  }

  createNewCart(saveCartForm: any, removeItems: boolean): void {
    let action = '';
    let newCartResponse$ = null;
    if (removeItems) {
      // Get New Empty Cart
      action = 'CREATE_CART';
      newCartResponse$ = this.userProfileFacade.get().pipe(
        take(1),
        switchMap(user => {
          return this.fffCartService.createCart(user?.uid!);
        })
      );
    } else {
      // Get New Cart with same items
      action = 'CLONE_CART';
      newCartResponse$ = this.userProfileFacade.get().pipe(
        take(1),
        switchMap(user => {
          return this.fffCartService.cloneCart(user?.uid!, this.cartId);
        })
      );
    }
    this.processRequest(newCartResponse$, removeItems, saveCartForm, action);
  }

  private processRequest(
    newCartResponse$: Observable<any>,
    removeItems: boolean,
    saveCartForm: any,
    action: string
  ): void {
    newCartResponse$.pipe(take(1), takeUntil(this.unsubscribe$)).subscribe(
      cart => {
        if (removeItems) {
          this.savedCartService.restoreSavedCart(cart.code);
        } else {
          this.savedCartService.restoreSavedCart(cart.savedCartData.code);
        }

        this.toastService.showSuccess('fffSaveCart.messages.createSavedCart', {
          delay: 3000,
          i18nParams: {
            cartName: this.saveCartForm?.controls['cartName'].value,
          },
        });
        this.loading = false;
        this.drawerService.closeDrawer();
      },
      error => {
        if (saveCartForm) {
          if (action == 'CLONE_CART') {
            saveCartForm.get('cartName')?.setErrors({ errorCloneCart: true });
          } else {
            saveCartForm.get('cartName')?.setErrors({ errorSaveCart: true });
          }
          this.loading = false;
        }
      }
    );
  }

  public closeDrawer(): void {
    this.drawerService.closeDrawer();
  }
}
