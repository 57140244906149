import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FffNewReportsService } from '@app/fff-enterprise/fff-new-reports/fff-new-reports.service';
import { BASE_URL_KEYS } from '@config/content/constants';
import { BaseSiteService } from '@spartacus/core';
import { map } from 'rxjs/operators';
@Component({
  selector: 'fff-profile-reports-tile',
  templateUrl: './fff-profile-reports-tile.component.html',
})
export class FffProfileReportsTileComponent implements OnInit {
  reportCounts: any;
  isBioSupply$ = this.baseSiteService
    .getActive()
    .pipe(map(site => site === BASE_URL_KEYS.BIOSUPPLY));

  constructor(
    private fffNewReportsService: FffNewReportsService,
    private baseSiteService: BaseSiteService,
    public router: Router
  ) {}

  ngOnInit(): void {
    this.fetchReportCounts();
  }

  fetchReportCounts() {
    this.fffNewReportsService.getReportCounts().subscribe(res => {
      if (res && res.reportscount) {
        this.reportCounts = res.reportscount;
      }
    });
  }

  goToReportsPage() {
    this.router.navigateByUrl('my-account/reports');
  }
}
