import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {
  ElavonApprovedPayment,
  InvoicePaymentEvents,
} from '@app/models/fff-payment.model';
import { WindowRef } from '@spartacus/core';

@Component({
  selector: 'fff-payments-bridge',
  templateUrl: './fff-payments-bridge.component.html',
  styleUrls: ['./fff-payments-bridge.component.scss'],
})
export class FffPaymentsBridgeComponent implements OnInit {
  private paymentFields: any;
  constructor(
    private activatedRoute: ActivatedRoute,
    private windowRef: WindowRef
  ) {}

  ngOnInit() {
    const token = this.activatedRoute.snapshot.queryParams['token'];

    if (!token) {
      return;
    }

    try {
      this.paymentFields = JSON.parse(atob(token));
      this.loadPaymentView();
    } catch {
      console.error('Unable to load elavon.');
    }
  }

  loadPaymentView() {
    this.windowRef.nativeWindow?.top?.postMessage(
      {
        type: InvoicePaymentEvents.FRAME_LOADED,
      },
      '*'
    );

    // @ts-ignore
    PayWithConverge.open(this.paymentFields, {
      onError: (error: any) => {
        this.windowRef.nativeWindow?.top?.postMessage(
          {
            type: InvoicePaymentEvents.ERROR,
            error,
          },
          '*'
        );
      },
      onCancelled: () => {
        this.windowRef.nativeWindow?.top?.postMessage(
          {
            type: InvoicePaymentEvents.CANCELLED,
          },
          '*'
        );
      },
      onDeclined: (response: any) => {
        this.windowRef.nativeWindow?.top?.postMessage(
          {
            type: InvoicePaymentEvents.DECLINED,
            response,
          },
          '*'
        );
      },
      onApproval: (response: ElavonApprovedPayment) => {
        this.windowRef.nativeWindow?.top?.postMessage(
          {
            type: InvoicePaymentEvents.APPROVAL,
            response,
          },
          '*'
        );
      },
    });
  }
}
