<div *ngIf="updatePersonalInfoForm">
  <form
    [formGroup]="updatePersonalInfoForm"
    class="body-text personal-details-form"
  >
    <div
      class="personal-details-form-content"
      [ngClass]="{ 'pb-5 mb-5': !isOpen }"
    >
      <div class="form-group">
        <label for="firstName-input" class="form-label">
          <span>
            {{
              'fffAccount.fffUpdatePersonalInfo.firstName' | cxTranslate
            }}</span
          >
          <input
            type="text"
            class="form-control"
            id="firstName-input"
            formControlName="firstName"
            placeholder="Enter first Name"
            required="true"
          />
          <cx-form-errors
            [control]="$any(updatePersonalInfoForm.get('firstName'))"
          ></cx-form-errors>
        </label>
      </div>

      <div class="form-group">
        <label for="lastName-input" class="form-label">
          <span>
            {{ 'fffAccount.fffUpdatePersonalInfo.lastName' | cxTranslate }}
          </span>
          <input
            type="text"
            class="form-control"
            id="lastName-input"
            formControlName="lastName"
            placeholder="Enter last Name"
            required="true"
          />
          <cx-form-errors
            [control]="$any(updatePersonalInfoForm.get('lastName'))"
          ></cx-form-errors>
        </label>
      </div>

      <div class="form-group">
        <label for="phoneNumber-input" class="form-label">
          {{ 'fffAccount.fffUpdatePersonalInfo.phoneNumber' | cxTranslate }}
        </label>
        <div class="input-wrapper">
          <cx-icon
            class="telephoneIcon"
            [type]="outlinedIconTypes.ICON_TELEPHONE"
          ></cx-icon>
          <input
            type="text"
            class="form-control"
            id="phoneNumber-input"
            formControlName="phoneNumber"
            placeholder="Enter Phone Number"
          />
        </div>
        <cx-form-errors
          [control]="$any(updatePersonalInfoForm.get('phoneNumber'))"
        ></cx-form-errors>
      </div>

      <div class="form-group">
        <label for="email-input" class="form-label">
          <span>
            {{ 'fffAccount.fffUpdatePersonalInfo.email' | cxTranslate }}
          </span>
          <input
            type="text"
            class="form-control"
            id="email-input"
            formControlName="email"
            placeholder="Enter email"
            required="true"
          />
          <cx-form-errors
            [control]="$any(updatePersonalInfoForm.get('email'))"
          ></cx-form-errors>
        </label>
      </div>

      <a
        href="javascript:void(0)"
        (click)="openChangePassword()"
        class="changePassword body-text-sm"
        *ngIf="!isOpen"
        [ngClass]="{ disabled: hasAgentLogged }"
      >
        {{ 'fffAccount.fffUpdatePersonalInfo.changePassword' | cxTranslate }}
      </a>
    </div>

    <div *ngIf="isOpen">
      <div class="form-group">
        <label>
          <span class="label-content">
            {{
              'fffAccount.fffUpdatePersonalInfo.currentPassword' | cxTranslate
            }}
          </span>
          <div class="password-wrapper d-flex justify-content-between">
            <input
              class="form-control"
              id="currentPassword"
              aria-required="true"
              [type]="showCurrentPassword ? 'text' : 'password'"
              formControlName="currentPassword"
              placeholder="Enter Password"
            />
            <span class="prefix-icon" (click)="enableCurrentPassword()">
              <cx-icon
                *ngIf="showCurrentPassword"
                class="cx-icon icon-md pe-auto"
                [type]="filledIconTypes.ICON_EYE_FILL"
              >
              </cx-icon>
              <cx-icon
                *ngIf="!showCurrentPassword"
                class="cx-icon icon-md pe-auto"
                [type]="filledIconTypes.ICON_EYE_SLASH_FILL"
              >
              </cx-icon>
            </span>
          </div>
          <cx-form-errors
            [control]="$any(updatePersonalInfoForm.get('currentPassword'))"
          ></cx-form-errors>
        </label>
      </div>
      <div class="form-group">
        <label>
          <span class="label-content">
            {{ 'fffAccount.fffUpdatePersonalInfo.newPassword' | cxTranslate }}
          </span>
          <div class="password-wrapper d-flex justify-content-between">
            <input
              class="form-control"
              id="newPassword"
              aria-required="true"
              [type]="showNewPassword ? 'text' : 'password'"
              formControlName="newPassword"
              placeholder="Enter New Password"
            />
            <span class="prefix-icon" (click)="enableNewPassword()">
              <cx-icon
                *ngIf="showNewPassword"
                class="cx-icon icon-md pe-auto"
                [type]="filledIconTypes.ICON_EYE_FILL"
              >
              </cx-icon>
              <cx-icon
                *ngIf="!showNewPassword"
                class="cx-icon icon-md pe-auto"
                [type]="filledIconTypes.ICON_EYE_SLASH_FILL"
              >
              </cx-icon>
            </span>
          </div>
          <cx-form-errors
            [control]="$any(updatePersonalInfoForm.get('newPassword'))"
          ></cx-form-errors>
        </label>
      </div>
      <div class="form-group">
        <label>
          <span class="label-content">
            {{
              'fffAccount.fffUpdatePersonalInfo.confirmPassword' | cxTranslate
            }}
          </span>
          <div class="password-wrapper d-flex justify-content-between">
            <input
              class="form-control"
              id="confirmPassword"
              aria-required="true"
              [type]="showConfirmPassword ? 'text' : 'password'"
              formControlName="confirmPassword"
              placeholder="Confirm New Password"
            />
            <span class="prefix-icon" (click)="enableConfirmPassword()">
              <cx-icon
                *ngIf="showConfirmPassword"
                class="cx-icon icon-md pe-auto"
                [type]="filledIconTypes.ICON_EYE_FILL"
              >
              </cx-icon>
              <cx-icon
                *ngIf="!showConfirmPassword"
                class="cx-icon icon-md pe-auto"
                [type]="filledIconTypes.ICON_EYE_SLASH_FILL"
              >
              </cx-icon>
            </span>
          </div>
          <cx-form-errors
            [control]="$any(updatePersonalInfoForm.get('confirmPassword'))"
          ></cx-form-errors>
        </label>
      </div>
      <div class="form-group body-text" [ngClass]="{ 'pb-5 mb-5': isOpen }">
        <label
          class="password-requirements password-rule"
          [innerHTML]="'fffRegister.reset.passwordRules' | cxTranslate"
        ></label>
      </div>
    </div>

    <div class="backgroundButton" [ngClass]="{ selected: isOpen }">
      <button
        type="submit"
        class="btn btn-block btn-primary"
        (click)="submitForm()"
        [disabled]="
          updatePersonalInfoForm.pristine || updatePersonalInfoForm.invalid
        "
      >
        {{ 'fffAccount.fffRequestChangeAddress.saveChanges' | cxTranslate }}
      </button>
    </div>
  </form>
</div>
