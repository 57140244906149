import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { fffAllocationsOccEndpointsConfig } from '@app/fff-config/fff-endpoints.config';
import { FffAllocationHistory } from '@app/models/fff-allocation-history.model';
import { FffInteractiveAllocationsEntry } from '@app/models/fff-allocations.model';
import { OccEndpointsService } from '@spartacus/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FffAllocationsService {

  allAllocations$ = new BehaviorSubject<any[]>([]);
  allocationsFilter$ = new BehaviorSubject<any>({});

  getRequestHeaders(): HttpHeaders {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    return headers;
  }

  constructor(private http: HttpClient, protected occEndpoints: OccEndpointsService) { }

  getHistory(userUid: string, currentPage: number, pageSize: number, b2bUnit?: string, startDate?: string, endDate?: string, product?: string): Observable<any> {

    const httpParams = new URLSearchParams();

    if (startDate) {
      httpParams.set('startDate', startDate);
    }

    if (endDate) {
      httpParams.set('endDate', endDate);
    }

    if (product) {
      httpParams.set('product', product);
    }

    if (b2bUnit) {
      httpParams.set('b2bUnit', b2bUnit);
    }

    if (currentPage) {
      httpParams.set('currentPage', `${currentPage}`);
    }

    if (pageSize) {
      httpParams.set('pageSize', `${pageSize}`);
    }

    let url = this.occEndpoints.buildUrl(`orgUsers/${userUid}/${fffAllocationsOccEndpointsConfig.HISTORY}`);
    return this.http.get<FffAllocationHistory[]>(`${url}?${httpParams}`, { headers: this.getRequestHeaders() });
  }

  getInteractiveAllocationsList(userUid: string, productManufacturer?: string): Observable<any> {
    const httpParams = new URLSearchParams();

    if (productManufacturer) {
      httpParams.set('productName', productManufacturer);
    }

    let url = this.occEndpoints.buildUrl(`orgUsers/${userUid}/${fffAllocationsOccEndpointsConfig.INTERACTIVE_ALLOCATIONS}`);
    return this.http.get<FffAllocationHistory[]>(`${url}?${httpParams}`, { headers: this.getRequestHeaders() });
  }

  postInteractiveAllocationsList(userUid: string, data: FffInteractiveAllocationsEntry[]): Observable<any> {
    let url = this.occEndpoints.buildUrl(`orgUsers/${userUid}/${fffAllocationsOccEndpointsConfig.INTERACTIVE_ALLOCATIONS_SUBMIT}`);
    return this.http.post(url, data, { headers: this.getRequestHeaders() });
  }

}
