import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { FffTableModule } from '@app/shared/table/fff-table.module';
import { FffTooltipModule } from '@app/shared/tooltip/tooltip.module';
import { FffResendOrderConfirmationDrawerComponent } from '@enterprise/fff-order/fff-order-history/fff-resend-order-confirmation-drawer/fff-resend-order-confirmation-drawer.component';
import {
  NgbDatepickerModule,
  NgbModule,
  NgbToastModule,
} from '@ng-bootstrap/ng-bootstrap';
import {
  CmsConfig,
  I18nModule,
  UrlModule,
  provideDefaultConfig,
} from '@spartacus/core';
// import { OrderOverviewModule, } from '@spartacus/order/components';
// import { OrderConfirmationModule } from '@spartacus/order/components/order-confirmation/order-confirmation.module';
import { FffFormControlsModule } from '@app/shared/components/form/fff-form-controls/fff-form-controls.module';
import { OrderCoreModule } from '@spartacus/order/core';
import {
  FormErrorsModule,
  IconModule,
  MediaModule,
  OutletModule,
} from '@spartacus/storefront';
import { FffOrderConfirmationItemsComponent } from './fff-order-confirmation-items/fff-order-confirmation-items.component';
import { FffOrderConfirmationMessageComponent } from './fff-order-confirmation-message/fff-order-confirmation-message.component';
import { FffOrderConfirmationOverviewComponent } from './fff-order-confirmation-overview/fff-order-confirmation-overview.component';
import { FffOrderConfirmationTotalsComponent } from './fff-order-confirmation-totals/fff-order-confirmation-totals.component';
import { FFFLegisymMessagePopupComponent } from './fff-order-history/fff-legisym-message-popup/fff-legisym-message-popup.component';
import { FFFOrderHistoryConfirmEmailPopupComponent } from './fff-order-history/fff-order-history-confirm-email-popup/fff-order-history-confirm-email-popup.component';
import { FffOrderHistoryDetailComponent } from './fff-order-history/fff-order-history-detail/fff-order-history-detail.component';
import { FffOrderHistoryFilterComponent } from './fff-order-history/fff-order-history-list/fff-order-history-filter/fff-order-history-filter.component';
import { FffOrderHistoryListComponent } from './fff-order-history/fff-order-history-list/fff-order-history-list.component';
import { FFFLoadersModule } from '@app/shared/components/fff-loaders/fff-loaders.module';

@NgModule({
  declarations: [
    FffOrderConfirmationMessageComponent,
    FffOrderConfirmationOverviewComponent,
    FffOrderConfirmationItemsComponent,
    FffOrderConfirmationTotalsComponent,
    FffOrderHistoryDetailComponent,
    FffOrderHistoryListComponent,
    FffOrderHistoryFilterComponent,
    FFFOrderHistoryConfirmEmailPopupComponent,
    FFFLegisymMessagePopupComponent,
    FffResendOrderConfirmationDrawerComponent,
  ],
  imports: [
    UrlModule,
    BrowserModule,
    CommonModule,
    I18nModule,
    IconModule,
    OrderCoreModule,
    // OrderOverviewModule,
    OutletModule,
    MediaModule,
    RouterModule,
    FffTableModule,
    FormsModule,
    NgbDatepickerModule,
    FffTooltipModule,
    ReactiveFormsModule,
    FormErrorsModule,
    NgbToastModule,
    NgbModule,
    FffFormControlsModule,
    FFFLoadersModule,
  ],
  exports: [
    FFFOrderHistoryConfirmEmailPopupComponent,
    FFFLegisymMessagePopupComponent,
  ],
  providers: [
    provideDefaultConfig(<CmsConfig>{
      cmsComponents: {
        FFFOrderConfirmationThankYouMessageComponent: {
          component: FffOrderConfirmationMessageComponent,
        },
        FFFOrderConfirmationOverviewComponent: {
          component: FffOrderConfirmationOverviewComponent,
        },
        FFFOrderConfirmationItemsComponent: {
          component: FffOrderConfirmationItemsComponent,
        },
        FFFOrderHistoryComponent: {
          component: FffOrderHistoryListComponent,
        },
        FFFOrderHistoryDetailsComponent: {
          component: FffOrderHistoryDetailComponent,
        },
      },
    }),
  ],
})
export class FffOrderModule {}
