import { DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { User } from '@spartacus/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { FffAddress } from 'src/app/models/fff-request-address-data-model';
import { FffCommunicationInterceptorService } from './fff-communication-interceptor.service';

import { HttpClient } from '@angular/common/http';
import { FffB2bUnitList } from '@app/models/fff-b2b-unit.model';
import { FffUser } from '@app/models/fff-user.model';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { State, Store } from '@ngrx/store';
import * as CryptoJS from 'crypto-js';
import { map, tap } from 'rxjs/operators';
import { B2BUnitState, SET_CURRENT_B2B_UNIT } from 'src/app/reducers';
import { ApplicationProperties } from '../fff-util/fff-models/application-properties.model';

@Injectable({
  providedIn: 'root',
})
/**
 * Provide communication with the backend services
 */
export class FffCommunicationService {
  /**
   * Once user details starts getting populated we can leverage that
   * for time being we can get the info from here...
   */
  private userDetails = {
    userName: 'william.hunter@rustic-hw.com',
  };
  private notifications$ = new BehaviorSubject<any>(undefined);
  public applicationProperties = new BehaviorSubject<any>('');
  /**
   * Constructor
   * @param {interceptorService} interceptorService FffCommunicationInterceptorService
   */
  constructor(
    private interceptorService: FffCommunicationInterceptorService,
    private store: Store<B2BUnitState>,
    private state: State<B2BUnitState>,
    public http: HttpClient
  ) {}

  /**
   *  get backs search summary
   * @return {Observable<ay>}
   */

  getAllB2BOrgUnitsForCustomer(data: User | undefined): Observable<any> {
    if (!data || !data.uid) {
      return of(null);
    }

    return this.interceptorService.getOcc(
      `users/${data?.uid}/getAllB2BOrgUnitsForCustomer?fields=MAIN`
    );
  }

  getConfigDetails(): Observable<any> {
    return this.interceptorService.getOcc(
      'config/get-startup-config',
      undefined,
      this.interceptorService.updateHeader()
    );
  }

  getPDPDetails(productCode: string): Observable<any> {
    const url = this.validateCacheConfig(
      `products/${productCode}?fields=classifications`,
      'storefront.startup.cache.product.enabled',
      false
    );
    return this.interceptorService.getOcc(url);
  }

  /**
   *  get backs search summary
   * @return {Observable<ay>}
   */
  postSwitchB2BUnit(data: any): Observable<any> {
    return this.interceptorService.postOcc(
      `/users/${data.uid}/switchB2BUnit?uid=${data.activeId}&cartCode=${data.cartCode}`
    );
  }

  /**
   * Switch the selected b2bUnit
   * @return {Observable<ay>}
   */
  postSwitchB2BUnitUserAccount(data: any): Observable<any> {
    return this.interceptorService.postOcc(
      `/b2bUsers/account/${data.activeId}`
    );
  }

  getAssociatedAccounts(userId: any): Observable<any> {
    return this.interceptorService.getOcc(
      `users/${userId}/account/associated-accounts`
    );
  }

  /**
   *  get backs search summary
   * @return {Observable<ay>}
   */
  getUserPriceList(
    page: number,
    pageSize: number,
    filter: string[]
  ): Observable<any> {
    let url = `/products/priceList?page=${page}`;

    url = this.validateCacheConfig(
      url,
      'storefront.startup.cache.priceList.enabled',
      false
    );

    if (pageSize) {
      url += '&pageSize=' + pageSize;
    }
    if (filter && filter.length > 0) {
      url += '&filter=' + filter.join(',');
    }
    return this.interceptorService.getOcc(url);
  }

  getPriceListFilters(): Observable<any> {
    return this.interceptorService.getOcc('/products/priceList/filters');
  }

  getFullUserPriceList(unitUid: string, filter: string[]) {
    let url = `/products/priceList/csv-download?fields=DEFAULT&unitUid=${unitUid}`;

    if (filter && filter.length > 0) {
      url += '&filter=' + filter.join(',');
    }

    return this.interceptorService.getOcc(url);
  }

  /**
   *  get backs search summary
   * @return {Observable<ay>}
   */
  /* TODO: remove the old stock call */
  getStock(code: string): Observable<any> {
    return this.interceptorService.getOcc(`/customer/inventoryCheck/${code}`);
  }

  getPrices(code: string, batchNumber?: string): Observable<any> {
    const url = this.validateCacheConfig(
      `/products/single/${code}?batchNumber=${batchNumber}`,
      'storefront.startup.cache.priceSingle.enabled',
      false
    );
    return this.interceptorService.getOcc(url);
  }

  /* TODO: remove the old allocation call */
  checkAllocQty(code: string): Observable<any> {
    const dateNow = new DatePipe('en-US').transform(new Date(), 'yyyyMMdd');
    return this.interceptorService.getOcc(
      `/customer/checkAllocQty/${code}/` + dateNow
    );
  }

  getStockAndAlloc(code: any) {
    const url = this.validateCacheConfig(
      `/customer/inventoryset/check/${code}`,
      'storefront.startup.cache.invSet.enabled',
      true
    );
    return this.interceptorService.getOcc(url);
  }

  normalizeB2BAccount(account: any) {
    const CREDIT_BLOCK_CODE = '99';
    const unitWithCreditBlockCode = account.abwUnits?.find(
      (unit: any) => unit?.blockCode?.code === CREDIT_BLOCK_CODE
    );

    if (!!unitWithCreditBlockCode) {
      account.blockCode.code = CREDIT_BLOCK_CODE;
      account.blockCode.message = unitWithCreditBlockCode?.blockCode?.message;
    }
  }

  getB2BAccounts(user: any): Observable<FffB2bUnitList> {
    const url = this.validateCacheConfig(
      `users/${user?.uid}/account/accounts`,
      'storefront.startup.cache.custAccounts.enabled',
      true
    );
    return this.interceptorService.getOcc(url).pipe(
      map(b2bListResp => {
        const b2bListResp2: any = JSON.parse(JSON.stringify(b2bListResp));
        b2bListResp2.b2bunits.forEach((account: any) =>
          this.normalizeB2BAccount(account)
        );
        return b2bListResp2;
      }),
      tap((b2bListResp: any) => {
        const stateB2b = this.state.getValue().B2BUnit;
        let uid =
          stateB2b && stateB2b.currentB2BUnit
            ? stateB2b.currentB2BUnit.uid
            : user.orgUnit.uid;
        const account = b2bListResp.b2bunits.find((b: any) => b.uid === uid);
        this.setCurrentB2bUnit(uid);
        if (account) {
          this.store.dispatch({
            type: SET_CURRENT_B2B_UNIT,
            payload: account,
          });
        }
      })
    );
  }

  getCurrentAccount(data: User | undefined): Observable<any> {
    if (!data || !data.uid) {
      return of(null);
    }
    return this.interceptorService
      .getOcc(`users/${data?.uid}/account/current`)
      .pipe(
        map(account => {
          const acc: any = JSON.parse(JSON.stringify(account));
          this.normalizeB2BAccount(acc);
          this.setCurrentB2bUnit(acc.uid);
          this.store.dispatch({
            type: SET_CURRENT_B2B_UNIT,
            payload: acc,
          });
          return acc;
        })
      );
  }

  getAccountsByQuery(userId: string, searchTerm: string = ''): Observable<any> {
    if (!userId) {
      return of(null);
    }
    let updatedSearchText = searchTerm;
    if (searchTerm && searchTerm.includes('#')) {
      updatedSearchText = searchTerm.replace('#', '%23');
    }
    return this.interceptorService
      .getOcc(
        `users/${userId}/account/search-accounts?query=${
          updatedSearchText || ''
        }`
      )
      .pipe(
        map(b2bListResp => {
          const b2bListResp2: any = JSON.parse(JSON.stringify(b2bListResp));
          b2bListResp2.b2bunits.forEach((account: any) =>
            this.normalizeB2BAccount(account)
          );
          return b2bListResp2;
        })
      );
  }

  changeSplitBiller(userId: string, mode: boolean): Observable<any> {
    return this.interceptorService.postOcc(
      '/account/splitBiller',
      undefined,
      undefined,
      { splitBiller: mode }
    );
  }

  requestChangeAddress(userId: string, request: FffAddress): Observable<any> {
    return this.interceptorService.postOcc(
      '/account/address/change?fields=DEFAULT',
      undefined,
      undefined,
      request
    );
  }

  updatePersonalInfo(request: any): Observable<any> {
    return this.interceptorService.patchOcc(
      '/account/update-customer',
      undefined,
      undefined,
      request
    );
  }

  getPoNumber(data: User | undefined, poNumber: string): Observable<any> {
    if (!data || !data.uid) {
      return of(null);
    }
    return this.interceptorService.getOcc(
      `/orgUsers/${data.uid}/checkPurchaseOrder?purchaseOrderNumber=${poNumber}`
    );
  }

  getPersonalInfo(): Observable<FffUser> {
    return this.interceptorService.getOcc(
      '/orgUsers/current?fields=FULL',
      undefined,
      undefined
    );
  }

  getAllocations(page: number, size: number) {
    return this.interceptorService.getOcc(
      `/customer/inventoryset/check/all?page=${page}&pageSize=${size}`
    );
  }

  getAllAllocations() {
    const url = this.validateCacheConfig(
      '/customer/inventoryset/check/allocation',
      'storefront.startup.cache.invList.enabled',
      true
    );
    return this.interceptorService.getOcc(url);
  }

  private validateCacheConfig(
    url: string,
    key: string,
    firstParam: boolean
  ): string {
    let config: any = localStorage.getItem('configMap');
    config = JSON.parse(config);

    if (config && config[key] && 'true' === config[key]) {
      url += firstParam ? '?' : '&';
      url += 'key=' + this.getHash();
    }
    return url;
  }

  private getHash(): string {
    let unit = localStorage.getItem('unit');
    return CryptoJS.MD5(unit || '').toString();
  }

  private setCurrentB2bUnit(unit: string): void {
    localStorage.setItem('unit', unit);
  }

  getCartPrices(cartId: string) {
    const url = this.validateCacheConfig(
      `/orgUsers/current/carts/${cartId}/prices`,
      'storefront.startup.cache.priceCart.enabled',
      true
    );
    return this.interceptorService.getOcc(url);
  }
  getApprovals(units?: any, users?: any) {
    const httpParams = new URLSearchParams();

    if (units) {
      httpParams.set('units', units);
    }

    if (users) {
      httpParams.set('users', users);
    }

    return this.interceptorService.getOcc(
      '/orgUsers/current/orders/approval/requests?' + httpParams
    );
  }

  getApprovalsHistory(filtersData?: any): Observable<any> {
    const httpParams = new URLSearchParams();
    if (filtersData && filtersData.b2bunit) {
      httpParams.set('units', filtersData.b2bunit);
    }
    if (filtersData && filtersData.action) {
      httpParams.set('actions', filtersData.action);
    }
    return this.interceptorService.getOcc(
      `/orgUsers/current/orders/approvalRequestHistory?${httpParams}`
    );
  }

  getApproverGroups(): Observable<any> {
    return this.interceptorService.getOcc(
      '/orgUsers/current/orders/approvergroups'
    );
  }

  approveOrder(cartId: any) {
    return this.interceptorService.postOcc(
      `/orgUsers/current/orders/approvalRequests/${cartId}/approved`
    );
  }

  rejectOrder(cartId: any) {
    return this.interceptorService.postOcc(
      `/orgUsers/current/orders/approvalRequests/${cartId}/rejected`
    );
  }

  approveEntry(cartId: any, entryNumber: number) {
    return this.interceptorService.postOcc(
      `/orgUsers/current/orders/approvalRequests/${cartId}/${entryNumber}/approved`
    );
  }

  rejectEntry(cartId: any, entryNumber: number) {
    return this.interceptorService.postOcc(
      `/orgUsers/current/orders/approvalRequests/${cartId}/${entryNumber}/rejected`
    );
  }

  getJeffersonProducts(page: number, pageSize: number) {
    return this.interceptorService.getOcc(
      `/orgProducts/products/jefferson?currentPage=${page}&fields=DEFAULT&pageSize=${pageSize}`
    );
  }

  updateShippingCost(
    code: any,
    orderEntryId: any,
    shippingCost?: any,
    shippingMethodCode?: any,
    shipToday?: boolean | null,
    estArrivalDate?: string
  ) {
    const httpParams = new URLSearchParams();

    if (shippingCost) {
      httpParams.set('shippingCost', shippingCost);
    }
    if (estArrivalDate) {
      httpParams.set('estArrivalDate', estArrivalDate);
    }

    if (shippingMethodCode) {
      httpParams.set('shippingMethodCode', shippingMethodCode);
    }

    if (shipToday != null) {
      httpParams.set('shipToday', '' + shipToday);
    }

    return this.interceptorService.postOcc(
      `/${code}/orderEntries/${orderEntryId}/updateShippingCost?${httpParams}`
    );
  }

  resetShippingCosts(code: any) {
    return this.interceptorService.postOcc(
      `/${code}/orderEntries/resetShippingCostsAndMethods`
    );
  }

  messageSource = new BehaviorSubject<any>({});

  sendUpdateCartMessage(code?: string) {
    this.messageSource.next(code ? code : '');
  }

  getProductsForOrder(query: string) {
    const url = this.validateCacheConfig(
      `products/search?fields=&query=${query}:relevance:category:JEFFERSON&pageSize=5&lang=en`,
      'storefront.startup.cache.custAccounts.enabled',
      false
    );
    return this.interceptorService.getOcc(url);
  }

  getFilteredApprovals(units?: any, users?: any) {
    const httpParams = new URLSearchParams();

    if (units) {
      httpParams.set('units', units);
    }

    if (users) {
      httpParams.set('users', users);
    }

    return this.interceptorService.getOcc(
      '/orgUsers/current/orders/approvalRequests?' + httpParams
    );
  }

  placeJeffersonHealthOrder(cartCode: string, poNumber: string) {
    return this.interceptorService.postOcc(
      '/orgUsers/current/orders/request/' + cartCode + '/' + poNumber
    );
  }

  getPOValidation(b2bUnit: string) {
    return this.interceptorService.postOcc(
      `/users/current/validatePONumber?b2bUnit=${b2bUnit}`
    );
  }

  getNotifications(): Observable<any> {
    return this.notifications$.asObservable();
  }

  setNotifications(notifications: any) {
    this.notifications$.next(notifications);
  }

  loadNotifications(): any {
    return this.interceptorService.getOcc('orgUsers/current/notifications');
  }

  sendReport(reportForm: any, statusesSelected: any[]) {
    const httpParams = new URLSearchParams();

    if (reportForm.reportType) {
      httpParams.set('type', reportForm.reportType.split(' ')[0].toLowerCase());
    }

    if (reportForm.fromDate) {
      let startDate = this.ngbDateToDate(reportForm.fromDate)
        .toISOString()
        .split('T')[0];
      httpParams.set('startDate', startDate);
    }

    if (reportForm.toDate) {
      let endDate = this.ngbDateToDate(reportForm.toDate)
        .toISOString()
        .split('T')[0];
      httpParams.set('endDate', endDate);
    }

    if (reportForm.email) {
      httpParams.set('emailIds', reportForm.email);
    }

    if (statusesSelected) {
      let a = statusesSelected.map(e => {
        return e.id.charAt(0).toUpperCase();
      });
      httpParams.set('status', a.join(','));
    }

    return this.interceptorService.getOcc(
      '/account/generate-report?' + httpParams
    );
  }

  sendNewReport(formData: any) {
    return this.interceptorService.postOcc(
      '/users/current/reports',
      undefined,
      undefined,
      formData
    );
  }

  ngbDateToDate(date: NgbDateStruct): Date {
    return new Date(date.year, date.month - 1, date.day);
  }

  getCategoryDetails(category: string | undefined) {
    return this.interceptorService.getOcc('/categories/' + category);
  }

  getApplicationProperties(): Observable<ApplicationProperties> {
    return this.interceptorService.getOcc('/applicationProperties');
  }
  setApplicationProperties() {
    this.interceptorService.getOcc('/applicationProperties').subscribe(
      res => {
        const appProperties: any = res;
        if (appProperties) {
          this.applicationProperties.next(appProperties);
        }
      },
      error => {
        console.error('Failed to application properties', error);
      }
    );
  }
  getAppProperties() {
    return this.applicationProperties.asObservable();
  }

  getCombinedProductDetails(productCode: string): Observable<any> {
    const url = this.validateCacheConfig(
      `orgProducts/products/${productCode}?fields=DEFAULT`,
      'storefront.startup.cache.product.enabled',
      false
    );
    return this.interceptorService.getOcc(url);
  }
}
