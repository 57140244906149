<!-- Component UI for SkipLinks in Global Header -->

<div class="container" *ngIf="isNavigating !== true; else loadingSpinner">
  <span
    (click)="openUrl()"
    [ngClass]="[
      getActiveScss_class() === true
        ? 'quick-links'
        : active_item === ccd_result.uid
        ? 'bio-supply'
        : ccd_result.uid === FFF_TYPECODES.CMS_SITELINKS_SUPPORTPHONENUMBER
        ? 'no-cursor-pointer'
        : ''
    ]"
    class="d-flex"
  >
    <cx-icon
      *ngIf="
        ccd_result.uid === FFF_TYPECODES.CMS_CONTEXTLINKS_MFV ||
        ccd_result.uid === FFF_TYPECODES.CMS_CONTEXTLINKS_FFF
      "
      class="cx-icon icon-xs pr-2"
      [type]="outlinedIconTypes.ICON_ARROW_UP_RIGHT"
    ></cx-icon>
    {{ fff_quickLink_name }}
  </span>
</div>

<ng-template #loadingSpinner>
  <fff-line-loader></fff-line-loader>
</ng-template>
