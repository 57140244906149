import { ChangeDetectorRef, Component, OnDestroy } from '@angular/core';
import { FffUserAccountService } from '@app/shared/services/fff-user-account.service';
import { ToastService } from '@app/shared/services/toast.service';
import { gridBreakpointsLG } from '@config/content/constants';
import { defaultPageSize } from '@config/pagination/pagination.config';
import { FffOrderFacade } from '@enterprise/fff-common-services/fff-order-facade';
import { FILLED_ICON_TYPE } from '@model/fff-filled-icons.model';
import { OUTLINED_ICON_TYPE } from '@model/fff-outline-icons.model';
import { FffUser } from '@model/fff-user.model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { State } from '@ngrx/store';
import { RoutingService } from '@spartacus/core';
import { Observable, Subject, of } from 'rxjs';
import { map, switchMap, takeUntil } from 'rxjs/operators';
import { FffRecurringOrderListModalComponent } from '../fff-recurring-order-list-modal/fff-recurring-order-list-modal.component';

@Component({
  selector: 'fff-recurring-orders-list',
  templateUrl: './fff-recurring-orders-list.component.html',
})
export class FffRecurringOrdersListComponent implements OnDestroy {
  private unsubscribe$ = new Subject<void>();
  public user: FffUser | undefined;
  public currentPage = 0;
  public recurringOrders$!: Observable<any>;
  public fields: any = [
    {
      title: 'fffCheckoutOrderConfirmation.recurringOrder.recurringOrders',
      field: 'order',
    },
    { title: 'fffReturns.poNumber', field: 'poNumber' },
    { title: 'fffReturns.email', field: 'uid' },
    {
      title: 'fffCheckoutOrderConfirmation.recurringOrder.orderStartDate',
      field: 'placed',
    },
    {
      title: 'fffCheckoutOrderConfirmation.recurringOrder.schedule',
      field: 'schedule',
    },
    {
      title: 'fffCheckoutOrderConfirmation.recurringOrder.nextOrderDate',
      field: 'nextDelivery',
    },
    {
      title: 'fffCheckoutOrderConfirmation.recurringOrder.actions',
      field: 'actions',
    },
    {
      title: 'fffCheckoutOrderConfirmation.recurringOrder.status',
      field: 'status',
    },
  ];

  public mobileSize: any = gridBreakpointsLG;
  public columnsGrid: any = {
    desktop: '1fr 1fr 2fr 1fr 2fr 1fr 3fr 1fr',
    mobile: '1fr 1fr',
  };
  public filledIconTypes = FILLED_ICON_TYPE;
  public outlinedIconTypes = OUTLINED_ICON_TYPE;
  public defaultPageSize = defaultPageSize;

  constructor(
    private fffOrderFacade: FffOrderFacade,
    private routing: RoutingService,
    private state: State<FffUser>,
    private cdr: ChangeDetectorRef,
    private fffUserAccountService: FffUserAccountService,
    private toastService: ToastService,
    private modalService: NgbModal
  ) {}

  ngOnInit(): void {
    this.fffUserAccountService
      .getProfile()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(profile => {
        if (profile && profile.user) {
          this.recurringOrders$ = this.fetchRecurringOrdersWithUser();
        }
      });
  }

  updateCurrentPageAndFetchReturnOrders(
    user: FffUser,
    event: any
  ): Observable<any> {
    this.currentPage = event.page - 1;
    const currentPage = this.currentPage;
    return this.loadRecurringOrderList(user.uid, currentPage);
  }

  fetchRecurringOrdersWithUser(event?: any) {
    return of(this.state.getValue().FFFUser).pipe(
      switchMap((user: any) => {
        if (user && user.user) {
          this.user = user.user;
          const pageChange = this.updateCurrentPageAndFetchReturnOrders(
            this.user!,
            event ? event : { page: 1 }
          ).pipe(
            map(res => {
              res.profile = this.user;
              return res;
            })
          );
          if (pageChange) return pageChange;
        }
        return of(undefined);
      }),
      takeUntil(this.unsubscribe$)
    );
  }

  loadRecurringOrderList(uid: string, page: number): Observable<any> {
    return this.fffOrderFacade.loadRecurringOrderList(
      uid,
      this.defaultPageSize,
      page,
      {}
    );
  }

  pageChange(event: any) {
    if (this.user) {
      this.recurringOrders$ = this.updateCurrentPageAndFetchReturnOrders(
        this.user,
        event
      );
    }
  }

  skipOrCancelRecurringOrder(
    cartCode: string,
    skip: boolean,
    skipDate: string = new Date().toString()
  ): void {
    const modalRef = this.modalService.open(
      FffRecurringOrderListModalComponent,
      {
        centered: true,
        size: 'md',
        backdrop: 'static',
        modalDialogClass: 'modal-container',
      }
    );
    modalRef.componentInstance.isSkipOrCancel = skip;
    modalRef.componentInstance.cartCode = cartCode;
    modalRef.componentInstance.skipDate = this.formatSkipDate(skipDate);

    modalRef.result.then(response => {
      if (response.updateList) {
        this.refreshPageList();
        this.toastService.showSuccess(
          skip
            ? 'fffOrderSummary.messages.skipRecurringOrder'
            : 'fffOrderSummary.messages.cancelRecurringOrder',
          {
            delay: 5000,
            i18nParams: {
              skipDate: this.formatSkipDate(skipDate),
              recurringOrder: cartCode,
            },
          }
        );
      }
    });
  }

  activateOrInactivateRecurringOrder(cartCode: string, isToActivate: boolean) {
    this.fffOrderFacade
      .activateOrInactivateRecurringOrder(cartCode, isToActivate)
      .subscribe(() => {
        this.refreshPageList();
        this.toastService.showSuccess(
          isToActivate
            ? 'fffOrderSummary.messages.recurringOrderResumed'
            : 'fffOrderSummary.messages.recurringOrderPaused',
          {
            delay: 5000,
            i18nParams: {
              recurringOrder: cartCode,
            },
          }
        );
      });
  }

  formatSkipDate(skipDate: string) {
    let nextDate = new Date(skipDate);
    return `${nextDate.toLocaleString('default', {
      month: 'short',
    })} ${nextDate.getDate()}, ${nextDate.getFullYear()}`;
  }

  refreshPageList(): void {
    this.recurringOrders$ = this.loadRecurringOrderList(this.user?.uid!, 0);
    this.cdr.markForCheck();
  }

  formatTimeInSchedule(schedule: string): string {
    const TIME_REGEXP = new RegExp(/at ([0-9]?\d):([0-9]\d):([0-9]\d)/g);
    if (schedule) {
      const isMonthly = schedule.indexOf('day in a') !== -1;
      schedule = schedule.replace(/\./g, '');
      schedule = schedule.replace(TIME_REGEXP, '');

      if (isMonthly) {
        schedule = schedule.replace('day in a', 'of the');
        schedule = schedule.replace('Every', 'Day');
      } else {
        schedule = schedule.replace('at', 'on');
        const everyOneWeekRegExp = new RegExp(
          /Every (?:Monday|Tuesday|Wednesday|Thursday)/g
        );
        if (schedule.match(everyOneWeekRegExp)) {
          schedule = schedule.replace('Every', 'Every 1 week on ');
        }
      }
      return schedule;
    }
    return '';
  }

  viewRecurringOrderDetails(cartCode: string): void {
    this.routing.go(['/my-account/recurring-order/', cartCode]);
  }

  editRecurringOrder(cartCode: string): void {
    this.routing.go(['/my-account/recurring-order/' + cartCode + '/edit']);
  }

  goToHomePage(): void {
    this.routing.go('/');
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
