import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { OUTLINED_ICON_TYPE } from 'src/app/models/fff-outline-icons.model';

@Component({
  selector: 'fff-order-history-confirm-email-popup',
  templateUrl: './fff-order-history-confirm-email-popup.component.html',
})
export class FFFOrderHistoryConfirmEmailPopupComponent {
  outlinedIconTypes = OUTLINED_ICON_TYPE;

  constructor(private modalService: NgbActiveModal) {}

  close() {
    this.modalService.close();
  }
}
