import { ModuleWithProviders, NgModule } from '@angular/core';
import { CONFIG_INITIALIZER } from '@spartacus/core';
import { FFFI18nBackendLoadPathConfigInitializer } from './config/i18n-config-initializer';

@NgModule()
export class FFFI18nModule {
  static forRoot(): ModuleWithProviders<FFFI18nModule> {
    return {
      ngModule: FFFI18nModule,
      providers: [
        {
          provide: CONFIG_INITIALIZER,
          useExisting: FFFI18nBackendLoadPathConfigInitializer,
          multi: true,
        },
      ],
    };
  }
}
