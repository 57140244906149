import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'searchFilter',
})
/**
 * Search Filter pipe can be used to search objects in linst of items.
 */
export class SearchFilterPipe implements PipeTransform {
  transform(value: any, args?: any, searchable: boolean = true): any {
    if (!value) return null;
    if (!args) return value;
    if (!searchable) return value;
    return value.filter((item: any) => {
      return JSON.stringify(item).toLowerCase().includes(args.toLowerCase());
    });
  }
}
