<div
  class="d-flex product-item-holder mb-4"
  *ngIf="item?.product; else loading"
>
  <div class="col-image">
    <cx-media
      [container]="getData(item.product?.images)"
      [alt]="item?.product?.summary!"
      format="cart"
      [ngClass]="{ unavailableProductImage: isUnavailableItem }"
    >
    </cx-media>
  </div>
  <div class="col-descript">
    <h4 class="mb-0" [ngClass]="{ unavailableProductName: isUnavailableItem }">
      <a class="name" [routerLink]="item?.product?.url">{{
        item?.product?.name
      }}</a>
    </h4>
    <div *ngIf="!isUnavailableItem">
      <p class="manufacturer">{{ item?.product?.manufacturer }}</p>
      <p>{{ 'fffProduct.lblNdc' | cxTranslate }} {{ item?.product?.ndc }}</p>
      <p>{{ 'fffProduct.lblSku' | cxTranslate }} {{ item?.product?.code }}</p>
      <p *ngIf="item?.strength">
        {{ 'fffProduct.actualStrength' | cxTranslate }}: {{ item.strength }}
      </p>
      <p *ngIf="item?.batch">
        {{ 'fffProduct.lblBatch' | cxTranslate }}: {{ item?.batch }}
        <ng-container *ngIf="item?.expirationDate"
          >| {{ 'fffProduct.exp' | cxTranslate }}:
          {{
            parseDate(item?.expirationDate) | cxDate : 'shortDate'
          }}</ng-container
        >
      </p>
      <p *ngIf="'S' !== item?.accountType">
        {{ 'fffProduct.lblAccount' | cxTranslate }}:
        {{
          'AUTO' === item?.accountType
            ? ('fffProduct.lblAccountAutoSplitBiller' | cxTranslate)
            : getAccountTypeLabel(item?.accountType)
        }}
      </p>
      <p *ngIf="item?.warehouse">
        {{ 'fffProduct.lblWarehouse' | cxTranslate }}: {{ item?.warehouse }}
      </p>
    </div>
    <div *ngIf="isUnavailableItem">
      <p class="manufacturer">
        {{ item?.product?.manufacturer }} -
        {{ 'fffProduct.lblNdc' | cxTranslate }} {{ item?.product?.ndc }}
      </p>
    </div>
  </div>
</div>

<ng-template #loading>
  <fff-cart-loader [isCartItem]="true"></fff-cart-loader>
</ng-template>
