export const common = {
  pageMetaResolver: {
    search: {
      title: 'Showing {{count}} result for "{{query}}"',
      title_plural: 'Showing {{count}} results for "{{query}}"',
      title_other: 'Showing {{count}} results for "{{query}}"',
    },
    category: {
      title: '{{query}}',
      title_plural: '{{query}}',
      title_other: '{{query}}',
    },
  },
  formErrors: {
    quickOrderFile: {
      error:
        'There was an issue processing your file, please try again and if you continue to have issues contact support',
      empty: 'The file cannot be empty',
      maxLength: 'The file cannot have more than 1000 records',
    },
    errorInvalidBatch: 'The lot number entered is not valid',
    errorQuantity: 'The quantity entered is not valid',
    ngbDate: 'Invalid date format',
    min: 'Invalid number',
    max: 'Invalid number',
    invalidQuantity: 'Invalid Quantity',
    invalidPositiveQuantity: 'Invalid Quantity',
    emailsExceedsCount: 'Please enter 5 or fewer email addresses.',
    fffInvalidEmail: 'This is not a valid email format.',
    fffInvalidDateRange: 'Please enter a valid Date Range.',
    invalidExpiryDate: 'Please enter a valid Expiration Date.',
  },
};
