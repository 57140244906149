<div *ngIf="allocationsHistory$ | async as allocationsHistory; else loadingTemplate">
  <fff-table (pageChange)="getData($event)" [columnsGrid]="columnsGrid" [data]="getTableData(allocationsHistory)"
    [size]="getTableSizeFromPagination(allocationsHistory)" [fields]="fields" [customClass]="'user-price-list'"
    [hasPaginator]="true" [hasTemplate]="true" [itemTemplate]="allocationHistoryItem">
  </fff-table>

  <ng-template #allocationHistoryItem let-item="item">

    <div class="allocation-history-item td" [style.grid-template-columns]="columnsGrid.desktop">
      <div class="px-0 col-6 col-lg-12">
        <div class="d-none d-lg-block">
          <div class="col-12 px-0 d-block d-lg-none pb-2 pt-3">{{ 'fffAllocationsHistory.productName' | cxTranslate }}
          </div>
          <div>{{ item.productName }}</div>
        </div>

        <div class="d-block d-lg-none">
          <div class="col-12 px-0 d-block d-lg-none pb-2 pt-3">{{ 'fffAllocationsHistory.productName' | cxTranslate }}
          </div>
          <div>{{ item.productName }}</div>
        </div>
      </div>

      <div class="px-0 col-6 col-lg-12">
        <div class="d-none d-lg-block">
          <div class="col-12 px-0 d-block d-lg-none pb-2 pt-3">{{ 'fffAllocationsHistory.account' | cxTranslate }}</div>
          <div>{{ item.account }}</div>
        </div>

        <div class="d-block d-lg-none">
          <div class="col-12 px-0 d-block d-lg-none pb-2 pt-3">{{ 'fffAllocationsHistory.account' | cxTranslate }}</div>
          <div>{{ item.account }}</div>
        </div>
      </div>

      <div class="px-0 col-6 col-lg-12">
        <div class="d-none d-lg-block">
          <div class="col-12 px-0 d-block d-lg-none pb-2 pt-3">{{ 'fffAllocationsHistory.sku' | cxTranslate }}</div>
          <div>{{ item.productCode }}</div>
        </div>

        <div class="d-block d-lg-none">
          <div class="col-12 px-0 d-block d-lg-none pb-2 pt-3">{{ 'fffAllocationsHistory.sku' | cxTranslate }}</div>
          <div>{{ item.productCode }}</div>
        </div>
      </div>

      <div class="px-0 col-6 col-lg-12">
        <div class="d-none d-lg-block">
          <div class="col-12 px-0 d-block d-lg-none pb-2 pt-3">{{ 'fffAllocationsHistory.ndc' | cxTranslate }}</div>
          <div>{{ item.productNdc }}</div>
        </div>

        <div class="d-block d-lg-none">
          <div class="col-12 px-0 d-block d-lg-none pb-2 pt-3">{{ 'fffAllocationsHistory.ndc' | cxTranslate }}</div>
          <div>{{ item.productNdc }}</div>
        </div>
      </div>

      <div class="px-0 col-6 col-lg-12">
        <div class="d-none d-lg-block">
          <div class="col-12 px-0 d-block d-lg-none pb-2 pt-3">{{ 'fffAllocationsHistory.dateModified' | cxTranslate }}
          </div>
          <div>{{ item.dateModified }}</div>
        </div>

        <div class="d-block d-lg-none">
          <div class="col-12 px-0 d-block d-lg-none pb-2 pt-3">{{ 'fffAllocationsHistory.dateModified' | cxTranslate }}
          </div>
          <div>{{ item.dateModified }}</div>
        </div>
      </div>

      <div class="px-0 col-6 col-lg-12">
        <div class="d-none d-lg-block">
          <div class="col-12 px-0 d-block d-lg-none pb-2 pt-3">{{ 'fffAllocationsHistory.modifiedBy' | cxTranslate }}
          </div>
          <div>{{ item.user }}</div>
        </div>

        <div class="d-block d-lg-none">
          <div class="col-12 px-0 d-block d-lg-none pb-2 pt-3">{{ 'fffAllocationsHistory.modifiedBy' | cxTranslate }}
          </div>
          <div>{{ item.user }}</div>
        </div>
      </div>

      <div class="px-0 col-6 col-lg-12">
        <div class="d-none d-lg-block">
          <div class="col-12 px-0 d-block d-lg-none pb-2 pt-3">{{ 'fffAllocationsHistory.qtyChanged' | cxTranslate }}
          </div>
          <div>{{ item.changeValue }}</div>
        </div>

        <div class="d-block d-lg-none">
          <div class="col-12 px-0 d-block d-lg-none pb-2 pt-3">{{ 'fffAllocationsHistory.qtyChanged' | cxTranslate }}
          </div>
          <div>{{ item.changeValue }}</div>
        </div>
      </div>

      <div class="px-0 col-6 col-lg-12">
        <div class="d-none d-lg-block">
          <div class="col-12 px-0 d-block d-lg-none pb-2 pt-3">{{ 'fffAllocationsHistory.actions' | cxTranslate }}</div>
          <div>
            <cx-icon *ngIf="item.changeValue > 0" class="cx-icon icon-md arrow-positive justify-content-start"
              [type]="outlinedIconTypes.ICON_ARROW_UP"></cx-icon>
            <cx-icon *ngIf="item.changeValue < 0" class="cx-icon icon-md arrow-negative justify-content-start"
              [type]="outlinedIconTypes.ICON_ARROW_DOWN"></cx-icon>
          </div>
        </div>

        <div class="d-block d-lg-none">
          <div class="col-12 px-0 d-block d-lg-none pb-2 pt-3">{{ 'fffAllocationsHistory.actions' | cxTranslate }}</div>
          <div>
            <cx-icon *ngIf="item.changeValue > 0" class="cx-icon icon-md arrow-positive justify-content-start"
              [type]="outlinedIconTypes.ICON_ARROW_UP"></cx-icon>
            <cx-icon *ngIf="item.changeValue < 0" class="cx-icon icon-md arrow-negative justify-content-start"
              [type]="outlinedIconTypes.ICON_ARROW_DOWN"></cx-icon>
          </div>
        </div>
      </div>
    </div>

  </ng-template>
</div>

<ng-template #loadingTemplate>
  <fff-table-loader [fields]="fields"></fff-table-loader>
</ng-template>