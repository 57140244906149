import { Component } from '@angular/core';
import { FffInvoicePaymentService } from '@app/fff-enterprise/fff-common-services/fff-invoice-payment.service';
import { FILLED_ICON_TYPE } from '@app/models/fff-filled-icons.model';
import { OUTLINED_ICON_TYPE } from '@app/models/fff-outline-icons.model';

@Component({
  selector: 'fff-invoice-payment-confirmation-header',
  templateUrl: './fff-invoice-payment-confirmation-header.component.html',
  styleUrls: ['./fff-invoice-payment-confirmation-header.component.scss'],
})
export class FffInvoicePaymentConfirmationHeaderComponent {
  outlinedIconTypes = OUTLINED_ICON_TYPE;
  filledIconTypes = FILLED_ICON_TYPE;

  transactionIdString!: string;
  isSingleTransaction!: boolean;
  paymentDate = new Date();

  constructor(private fffInvoicePaymentService: FffInvoicePaymentService) {}
  ngOnInit() {
    this.processTransactionId();
  }
  processTransactionId() {
    this.fffInvoicePaymentService
      .getSuccessfulInvoices()
      .subscribe((successInvoices: any[]) => {
        const successfulInvoiceData = successInvoices ?? [];
        if (successfulInvoiceData && successfulInvoiceData.length > 0) {
          const transactionIds = successfulInvoiceData
            .map((invoice: any) => invoice.transactionId)
            .filter(
              (id: string | null | undefined) =>
                typeof id === 'string' && id.length >= 12
            )
            .map((id: string) => id.slice(-12));
          this.isSingleTransaction = transactionIds.length === 1;
          this.transactionIdString = transactionIds.join(', ');
        }
      });
  }
  printConfirmation() {
    this.fffInvoicePaymentService.emitPrintEvent();
  }
}
