<div class="container px-0" *ngIf="returnData$ | async as returnData">

  <div class="pt-4">
    <h2 class="text-center title mb-0 py-3">{{ 'fffReturnConfirmation.header' | cxTranslate }}</h2>
    <p class="text-center title mb-0 py-3">
      <span class="body-text-xl"
        [innerHTML]="'fffReturnConfirmation.subtitle' | cxTranslate : { email: returnData.orders[0]?.order?.returnContactInfo?.email } "></span>
    </p>
  </div>

  <div class="pb-3">
    <fff-return-user-details-header [accountNumber]="returnData.orders[0]?.order?.unit?.uid"
      [accountName]="returnData.orders[0]?.order?.unit?.name"
      [email]="returnData.orders[0]?.order?.returnContactInfo?.email"
      [contactName]="returnData.orders[0]?.order?.returnContactInfo?.name"
      [phoneNumber]="returnData.orders[0]?.order?.returnContactInfo?.phone">
    </fff-return-user-details-header>
  </div>

  <div class="p-lg-4 px-0 my-3 container-items" *ngFor="let orderData of returnData.orders">
    <div class="col-12">
      <h3 class="title text-center">
        {{'fffReturnConfirmation.returnNumber' | cxTranslate: { code: orderData.order?.code } }}
      </h3>
    </div>

    <div class="col-12">
      <p class="d-flex pb-3 border-separator">
        <span class="body-text-demi">
          {{'fffReturnConfirmation.poNumber' | cxTranslate: { po: orderData.order?.purchaseOrderNumber } }}
        </span>
        <span class="ml-auto">
          {{'fffReturnConfirmation.returnRequested' | cxTranslate}} {{ (orderData.order?.created ?
          orderData.order?.created : '') | date: 'MM/dd/yyyy'}}
        </span>
      </p>
    </div>

    <div class="col-12 px-0">
      <fff-returns-entry [items]="orderData.order?.entries" [displayConfirmationEntryQty]="true"
        [account]="orderData.order?.unit?.uid"></fff-returns-entry>
    </div>
  </div>

  <div class="py-3">
    <p class="text-center pb-0 title">
      <span [innerHTML]="'fffReturnConfirmation.footer' | cxTranslate "></span>
    </p>
  </div>

</div>