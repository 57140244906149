import { Injectable } from '@angular/core';
import { FffProfile } from '@app/models/fff-profile.model';
import { WindowRef } from '@spartacus/core';
import { Subject } from 'rxjs';

declare const icPatronChat: any;

@Injectable({
  providedIn: 'root',
})
/**
 * CUstomg service to implement Ring Central Chat Bot
 */
export class FffRingCentralChatBotService {
  unsubscribe$: Subject<boolean> = new Subject<boolean>();
  /**
   * Constructor.
   * @param {userAccountService} UserAccountFacade instance to construct the service.
   */
  constructor(private windowRef: WindowRef) { }

  /**
   * Initialize the service.
   */
  public init(data: any, profile: FffProfile | undefined): void {
    const self = this;
    try {
      const node = document.createElement('script');
      node.async = true;
      node.src = data['storefront.startup.chatClient.Url'];
      document.head.appendChild(node);
      node.onload = () => {
        self.setUpChatBot(data, profile);
      };
    } catch (err) {
      console.error('Chat bot intialization failed with this error message : ');
      console.error(err);
    }
  }

  public openChatBot() {
    icPatronChat.setDimension(false);
    icPatronChat.show();
  }

  /**
   * Sets up the chatbot and update the user information.
   */
  private setUpChatBot(dataObj: any, profile: FffProfile | undefined) {
    this.windowRef.document.querySelectorAll('.ie-div-position-customer-chat').forEach(el => el.remove());
    if (profile){
      icPatronChat.init({
        serverHost: dataObj['storefront.startup.ringcentral.serverHost'],
        bus_no: 4602682,
        poc: dataObj['storefront.startup.chatClient.Poc'],
        params: [
          profile?.user?.firstName,
          profile?.user?.lastName,
          profile?.user?.uid,
          profile?.user?.orgUnit?.uid,
        ],
      });
    } else {
      icPatronChat.init({
        serverHost: dataObj['storefront.startup.ringcentral.serverHost'],
        bus_no: 4602682,
        poc: dataObj['storefront.startup.chatClient.Poc'],
        params: [],
      });
    }
  }
}
