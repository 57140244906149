import { Injectable } from '@angular/core';
import { CartModification } from '@spartacus/cart/base/root';
import { Converter } from '@spartacus/core';

@Injectable()
export class CartModificationNormalizer implements Converter<any, CartModification> {

  convert(source: any, target: CartModification): CartModification {
    target = {};
    if (source.cartModifications && source.cartModifications.length > 0) {
      target = source.cartModifications[0];
    } else if (source.entry) {
      target.statusCode = source.statusCode;
      target.quantity = source.quantity;
      target.quantityAdded = source.quantity;
      target.entry = source.entry;
    }
    return target;
  }
}