import { ChangeDetectorRef, Component, OnDestroy } from '@angular/core';
import { FffCommunicationService } from '@app/fff-enterprise/fff-common-services/fff-communication.service';
import { FFFActiveCartService } from '@app/fff-enterprise/fff-custom-cart/fff-active-cart-service';
import { FffUserAccountService } from '@shared/services/fff-user-account.service';
import { Cart, MultiCartFacade } from '@spartacus/cart/base/root';
import { BaseSiteService } from '@spartacus/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'fff-checkout',
  templateUrl: './fff-checkout.component.html',
})
export class FffCheckoutComponent implements OnDestroy {
  cart$: Observable<Cart> = new BehaviorSubject<Cart>({});
  cartEntries$: BehaviorSubject<any> = new BehaviorSubject<any>([]);
  loading = true;
  invoiceApprover = false;
  poNumber: any;
  profile$ = this.fffAccountService.getProfile();
  activeSite$ = this.baseSiteService.getActive();
  poRules: any;

  private unsubscribe$: Subject<void> = new Subject<void>();

  constructor(
    protected baseSiteService: BaseSiteService,
    protected cartService: FFFActiveCartService,
    private multiCartService: MultiCartFacade,
    private changeDetectorRef: ChangeDetectorRef,
    private communicationService: FffCommunicationService,
    private fffAccountService: FffUserAccountService
  ) {}

  ngOnInit(): void {
    this.profile$.subscribe(account => {
      if (account) {
        this.communicationService
          .getPOValidation(account.selected.uid)
          .subscribe((res: any) => {
            if (res) {
              this.poRules = res;
              this.changeDetectorRef.detectChanges();
            }
          });
      }
    });

    this.cart$ = this.cartService
      .getActive()
      .pipe(take(1), takeUntil(this.unsubscribe$));
    this.cart$.subscribe((res: any) => {
      //making sure we're not sending the cart info to the components if we're getting the first empty response
      if (Object.keys(res).length > 0) {
        this.invoiceApprover = res.invoiceApprover;
        this.communicationService
          .resetShippingCosts(res.code)
          .subscribe(res => {});
        this.poNumber = res.purchaseOrderNumber;
        this.changeDetectorRef.detectChanges();

        this.cartService
          .getUpdatedCartATP(res.code!)
          .pipe(take(1), takeUntil(this.unsubscribe$))
          .subscribe(cart => {
            this.multiCartService.loadCart({
              cartId: cart.code,
              userId: 'current',
              extraData: { active: true },
            });
            if (cart.entries && cart.entries.length > 0) {
              this.cartEntries$.next(
                cart.entries.filter((item: any) => item.product.isExcluded)
              );
            }
          });
      }
    });
  }

  ngOnDestroy(): void {
    if (this.unsubscribe$) {
      this.unsubscribe$.unsubscribe();
    }
  }
}
