import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { RoutingService } from '@spartacus/core';
import { Subject, Subscription } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';
import { FffPrebookCartService } from '../services/fff-prebook-cart.service';

@Component({
  selector: 'fff-acip-recommendations',
  templateUrl: './fff-prebook-acip-recommendation.component.html',
  styleUrls: ['./fff-prebook-acip-recommendation.component.scss'],
})
export class FffPrebookAcipRecommendationComponent
  implements OnInit, OnDestroy
{
  unsubscribe$: Subject<boolean> = new Subject<boolean>();
  subs = new Subscription();
  saving: boolean = false;
  pageId: string = '';
  isParagraphVisible: boolean = false;

  constructor(
    private cdr: ChangeDetectorRef,
    private routingService: RoutingService,
    private fffPrebookCartservice: FffPrebookCartService,
    private el: ElementRef
  ) {}

  ngOnInit(): void {
    this.subs.add(
      this.routingService
        .getRouterState()
        .pipe(
          filter(value => !!value.state?.context?.id),
          map(value => value.state?.queryParams['category'] || '')
        )
        .subscribe(pageId => {
          this.pageId = pageId;
        })
    );
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();

    this.unsubscribe$.next(false);
    this.unsubscribe$.complete();
  }

  toggleAcipRecommendation() {
    this.isParagraphVisible = !this.isParagraphVisible;
    const acipContainerDiv = document.querySelector(
      '.PrebookAcipSection'
    ) as HTMLElement;
    if (acipContainerDiv) {
      const recommendationTextDiv = acipContainerDiv.querySelector(
        'cx-paragraph'
      ) as HTMLElement;
      if (recommendationTextDiv) {
        if (this.isParagraphVisible) {
          recommendationTextDiv.style.display = 'block';
        } else {
          recommendationTextDiv.style.display = 'none';
        }
      }
    }
  }

  proceedToCheckout() {
    this.saving = true;
    this.cdr.markForCheck();
    this.fffPrebookCartservice
      .addToCart(
        this.unsubscribe$,
        {
          pageId: this.pageId,
          redirectToCart: true,
        },
        this.pageId
      )
      .pipe(take(1))
      .subscribe(() => {
        this.saving = false;
        this.cdr.markForCheck();
      });
  }
}
