import { Component, OnInit } from '@angular/core';
import { OUTLINED_ICON_TYPE } from '@app/models/fff-outline-icons.model';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'fff-returns-error-request-popup',
  templateUrl: './fff-returns-error-request-popup.component.html',
})
export class FffReturnsErrorRequestPopupComponent implements OnInit {

  hasErrorData: boolean = false;
  errorData: any;

  outlinedIconTypes = OUTLINED_ICON_TYPE;

  constructor(
    private modalService: NgbActiveModal,
  ) { }

  ngOnInit(): void { }

  close(): void {
    this.modalService.close();
  }

}
