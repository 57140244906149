import { ChangeDetectorRef, Component, Input } from '@angular/core';
import { OUTLINED_ICON_TYPE } from '@app/models/fff-outline-icons.model';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { FffNewReportsService } from '../fff-new-reports.service';

@Component({
  selector: 'fff-new-report-product-group-select-drawer',
  templateUrl: './fff-new-report-product-group-select-drawer.component.html',
  styleUrls: ['./fff-new-report-product-group-select-drawer.component.scss'],
})
export class FffNewReportProductGroupSelectDrawerComponent {
  @Input() isMultiAccount!: boolean;
  @Input() products!: any[];
  @Input() productGroups!: any[];
  selectAllChecked = false;
  selectedProductGroups: string[] = [];
  previousSelectedAccounts: string[] = [];
  iconClose = OUTLINED_ICON_TYPE.ICON_X;
  searchText = '';
  outlinedIconTypes = OUTLINED_ICON_TYPE;
  private subscription: Subscription = new Subscription();

  constructor(
    private ActiveModalService: NgbActiveModal,
    private newReportService: FffNewReportsService,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.newReportService
      .getSelectedProductGroups()
      .subscribe(selectedProductGroupTags => {
        if (selectedProductGroupTags && selectedProductGroupTags.length > 0) {
          const selectedProductGroups = selectedProductGroupTags.map(obj =>
            obj.id.toString()
          );
          this.selectedProductGroups = selectedProductGroups;
          this.updateSelectAllStatus();
          this.cd.detectChanges();
        }
      });
  }

  selectProductGroups() {
    const selectedProductGroupTags = this.selectedProductGroups.map(item => ({
      id: item,
      displayValue: this.getProductGroupName(item),
    }));
    this.newReportService.setSelectedProductGroups(selectedProductGroupTags);
    this.close();
  }

  getProductGroupName(code: string) {
    const productGroup = this.productGroups.filter(
      productGroup => productGroup.code == code
    );
    if (productGroup && productGroup.length > 0 && productGroup[0].name) {
      return productGroup[0].name;
    } else {
      return code;
    }
  }

  close() {
    this.ActiveModalService.dismiss();
  }

  toggleProductGroups(productGroup: any) {
    if (this.isMultiAccount) {
      const index = this.selectedProductGroups.indexOf(productGroup.code);
      if (index === -1) {
        this.selectedProductGroups.push(productGroup.code);
      } else {
        this.selectedProductGroups.splice(index, 1);
      }
    } else {
      this.selectedProductGroups = [productGroup.code];
    }
    this.updateSelectAllStatus();
    this.cd.markForCheck();
  }
  isProductGroupSelected(productGroup: any) {
    return this.selectedProductGroups.includes(productGroup.code);
  }
  toggleAll() {
    this.selectedProductGroups = this.selectAllChecked
      ? this.productGroups.map(productGroup => productGroup.code)
      : [];
  }

  updateSelectAllStatus() {
    this.selectAllChecked =
      this.selectedProductGroups.length === this.productGroups.length;
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }
}
