import { AbstractControl, ValidationErrors } from '@angular/forms';

export class FFFCustomValidators {
  static quantityValidator(control: AbstractControl): ValidationErrors | null {
    let currentNumber = control.value as string;
    if (currentNumber) {
      currentNumber = '' + currentNumber;
      return currentNumber.match(/^-?[0-9]+$/)
        ? null
        : { invalidQuantity: true };
    }
    return null;
  }

  static quantityIntegerPositiveValidator(
    control: AbstractControl
  ): ValidationErrors | null {
    const currentNumber = control.value as string;
    return currentNumber &&
      (!currentNumber.length || currentNumber.match(/^[0-9]+$/))
      ? null
      : { invalidPositiveQuantity: true };
  }

  static emailsExceedsCountValidator(
    control: AbstractControl
  ): ValidationErrors | null {
    const emails = control.value as string;
    return emails && emails.length <= 5 ? null : { emailsExceedsCount: true };
  }

  static expiryDateValidator(
    control: AbstractControl
  ): ValidationErrors | null {
    let expiry = control.value as string;
    if (!expiry) {
      return null;
    }

    expiry = '' + expiry;
    if (!expiry.match(/^[0-9]{2}\/[0-9]{2}$/)) {
      return { invalidExpiryDate: true };
    }

    const [expiryMonth, expiryYear] = expiry.split('/');
    const today = new Date();
    let todayDate: string = today.getDate().toString();
    if (today.getDate() < 10) {
      todayDate = `0${todayDate}`;
    }
    const yearPrefix = today.getFullYear().toString().slice(0, 2);
    const fullYear = `${yearPrefix}${expiryYear}`;
    const expiryDate = `${fullYear}-${expiryMonth}-${todayDate}`;

    if (new Date(expiryDate).getTime() <= today.getTime()) {
      return { invalidExpiryDate: true };
    }
    return null;
  }

  static validateLicense(userDetails: any): boolean {
    const expiredCodes: string[] = ['03-License Block', '04-Block', '05-Block'];
    return (
      userDetails.blockCode &&
      expiredCodes.includes(userDetails.blockCode.code)
    );
  }
}
