import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CmsConfig, ConfigModule } from '@spartacus/core';
import { FffCookieConsentComponent } from './fff-cookie-consent/fff-cookie-consent.component';
import { FffTrustCookieService } from './fff-trust-cookie.service';

@NgModule({
  declarations: [
    FffCookieConsentComponent,
  ],
  imports: [
    CommonModule,
    ConfigModule.withConfig({
      cmsComponents: {
        AnonymousConsentManagementBannerComponent: {
          component: FffCookieConsentComponent,
        },
      },
    } as CmsConfig),
  ],
  providers: [
    FffTrustCookieService,
  ],
})
/**
 * Custom One Trust Cookie Module. It encapsulates the dialog box and dialog component
 * Replaces the current Cookies, with One Trust cookies.
 */
export class FffOneTrustCookieModule { }
