import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IconModule } from '@spartacus/storefront';
import { FffScrollToTopComponent } from './fff-scroll-to-top.component';

@NgModule({
  declarations: [FffScrollToTopComponent],
  imports: [CommonModule, IconModule],
  exports: [FffScrollToTopComponent],
})
export class FffScrollToTopModule {}
