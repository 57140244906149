import { Injectable } from '@angular/core';

declare const Munchkin: any;

@Injectable({
  providedIn: 'root',
})
/**
 * Custom Marketo service.
 */
export class FffMarketoService {
  /**
   * Create empty constructor
   */
  constructor() { }

  /**
   * Initialize the Marketo Js.
   */
  public init(data: any) {
    try {
      const node = document.createElement('script');
      node.async = true;
      node.src = data['storefront.startup.munchkin.Url'];
      document.head.appendChild(node);
      node.onload = () => {
        Munchkin.init(data['storefront.startup.marketo.id'], { wsInfo: data['storefront.startup.marketo.wsInfoid'] });
      };
    } catch (err) {
      console.error('Munchkin initialization failed with this error message : ');
      console.error(err);
    }
  }
}
