import {
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { FffInvoicePaymentService } from '@app/fff-enterprise/fff-common-services/fff-invoice-payment.service';
import { FILLED_ICON_TYPE } from '@app/models/fff-filled-icons.model';
import { InvoiceCreditCard } from '@app/models/fff-invoice.model';
import { OUTLINED_ICON_TYPE } from '@app/models/fff-outline-icons.model';
import { FffGoogleMapService } from '@app/shared/services/fff-google-map.service';
import { ToastService } from '@app/shared/services/toast.service';
import { FFFCustomValidators } from '@app/shared/validators/fff-custom-validators';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { omit } from 'lodash';
import { Subscription } from 'rxjs';

@Component({
  selector: 'fff-edit-credit-card-dialog',
  templateUrl: './fff-edit-credit-card-dialog.component.html',
})
export class FffEditCreditCardDialogComponent implements OnInit, OnDestroy {
  @Input() titleI18nKey: string =
    'fffAccount.fffProfileManagement.wallet.editCardDialog.modalTitle';
  @Input() cardData: any;
  selectedAddress: string = '';
  predictions: google.maps.places.AutocompletePrediction[] = [];
  outlinedIconTypes = OUTLINED_ICON_TYPE;
  filledIcons = FILLED_ICON_TYPE;

  regions: any[] = [];
  saving: boolean = false;
  subs = new Subscription();
  isSuggestionsLoaded!: boolean;

  form = this.fb.group({
    billingAddress: this.fb.group({
      firstName: [{ value: '', disabled: true }],
      lastName: [{ value: '', disabled: true }],
      line1: ['', [Validators.required]],
      line2: '',
      town: [{ value: '', disabled: true }, [Validators.required]],
      region: this.fb.control<any>({ value: '', disabled: true }, [
        Validators.required,
      ]),
      postalCode: [{ value: '', disabled: true }, [Validators.required]],
    }),
    cardToken: [{ value: '', disabled: true }],
    expiryMonth: ['', [Validators.required]],
    expiryYear: ['', [Validators.required]],
    expirationDate: [
      '',
      [Validators.required, FFFCustomValidators.expiryDateValidator],
    ],
  });

  constructor(
    private activeModalService: NgbActiveModal,
    private fffInvoicePaymentService: FffInvoicePaymentService,
    private fb: FormBuilder,
    private cd: ChangeDetectorRef,
    private toastService: ToastService,
    private fffGoogleMapService: FffGoogleMapService
  ) {}

  ngOnInit(): void {
    if (this.cardData) {
      const payload: InvoiceCreditCard = omit(this.cardData, [
        'icon',
        'index',
        'saved',
        'selected',
      ]);
      this.form.patchValue({
        ...payload,
        expirationDate: `${payload.expiryMonth}/${payload.expiryYear}`,
      });
    }
    this.form.markAsPristine();
    this.form.markAsUntouched();
    this.loadExpiryDateEvents();
    this.loadRegions();
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  loadRegions() {
    this.fffInvoicePaymentService.getRegions().subscribe((res: any) => {
      this.regions = res?.regions || [];
      const matchingRegion = this.regions.find(
        r => r.isocode === this.cardData?.billingAddress?.region?.isocode
      );
      if (matchingRegion) {
        this.form.patchValue(
          {
            billingAddress: {
              region: matchingRegion,
            },
          },
          { emitEvent: true }
        );
      }
      this.cd.markForCheck();
    });
  }

  loadExpiryDateEvents() {
    this.subs.add(
      this.form.get('expirationDate')?.valueChanges.subscribe(val => {
        if (this.form.get('expirationDate')?.valid && val) {
          const [expiryMonth, expiryYear] = val.split('/');
          this.form.patchValue({
            expiryMonth,
            expiryYear,
          });
        }
      })
    );
  }

  dismiss() {
    this.activeModalService.dismiss();
  }

  navigateToSelectCard() {
    this.activeModalService.close({
      initiatePayment: true,
    });
  }

  private getFormattedPayload() {
    const payload = omit(
      {
        ...this.form.getRawValue(),
      },
      ['savedPayment', 'expirationDate']
    );

    return payload;
  }

  toggleSaving(saving: boolean) {
    this.saving = saving;
    this.cd.markForCheck();
  }

  saveCard() {
    this.toggleSaving(true);
    const payload = this.getFormattedPayload();
    this.fffInvoicePaymentService
      .updatePaymentDetails(payload, this.cardData.paymentUUID)
      .subscribe(
        () => {
          this.activeModalService.close({
            updateSuccess: true,
            cardData: {
              ...this.cardData,
              ...payload,
            },
          });
          this.toastService.showSuccess(
            'fffAccount.fffProfileManagement.wallet.editCardDialog.creditCardUpdatedConfirmation',
            {
              delay: 5000,
            }
          );
          this.toggleSaving(false);
        },
        () => {
          this.toggleSaving(false);
        }
      );
  }
  onInput(event: Event) {
    const inputElement = event.target as HTMLInputElement;
    const address = inputElement.value.trim();
    if (address.trim() !== '') {
      this.fffGoogleMapService
        .getPlacePredictions(address)
        .subscribe(predictions => {
          this.predictions = predictions;
        });
    } else {
      this.predictions = [];
    }
    this.isSuggestionsLoaded = true;
  }

  selectPlace(prediction: google.maps.places.AutocompletePrediction) {
    this.predictions = [];
    this.isSuggestionsLoaded = false;
    this.fffGoogleMapService
      .getPlaceDetails(prediction.place_id)
      .subscribe(place => {
        let address1 = '';
        let postcode = '';

        for (const component of place.address_components as google.maps.GeocoderAddressComponent[]) {
          const componentType = component.types[0];

          switch (componentType) {
            case 'street_number': {
              address1 = `${component.long_name} ${address1}`;
              break;
            }

            case 'route': {
              address1 += component.long_name;
              break;
            }

            case 'postal_code': {
              postcode = `${component.long_name}${postcode}`;
              break;
            }

            case 'locality': {
              this.form
                .get('billingAddress.town')
                ?.setValue(component.long_name);
              break;
            }
            case 'administrative_area_level_1': {
              if (this.regions && this.regions.length > 0) {
                const selectedRegion = this.regions.filter(
                  (region: any) => region.isocodeShort == component.short_name
                );
                if (selectedRegion && selectedRegion.length > 0) {
                  this.form
                    .get('billingAddress.region')
                    ?.setValue(selectedRegion[0]);
                }
              }
              break;
            }
          }
        }
        this.form.get('billingAddress.line1')?.setValue(address1);
        this.form.get('billingAddress.postalCode')?.setValue(postcode);
      });
  }

  clearAddress() {
    this.form.get('billingAddress.line1')?.setValue('');
    this.form.get('billingAddress.line2')?.setValue('');
    this.form.get('billingAddress.town')?.setValue('');
    this.form.get('billingAddress.region')?.setValue('');
    this.form.get('billingAddress.postalCode')?.setValue('');
    this.predictions = [];
  }
}
