import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { AuthService, RoutingService } from '@spartacus/core';
import { Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class FFFRegisterConfirmationGuard  {
  constructor(
    private routingService: RoutingService,
    private authService: AuthService
  ) {}

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
    return this.authService.isUserLoggedIn().pipe(
      switchMap(isLoggedIn => {
        return of(isLoggedIn);
      }),
      map((isLoggedIn: any) => {
        if (isLoggedIn) {
          this.routingService.go(['/']);
          return false;
        } else {
          return true;
        }
      })
    );
  }
}
