import { ChangeDetectionStrategy, Component, ChangeDetectorRef } from '@angular/core';
import { Observable,of } from 'rxjs';
import { FffUserAccountService } from '@shared/services/fff-user-account.service';
import { BaseSiteService, RoutingService } from '@spartacus/core';
import { distinctUntilChanged, map, switchMap, tap } from 'rxjs/operators';
import { FffOrderFacade } from '@enterprise/fff-common-services/fff-order-facade';
import { BASE_URL_KEYS } from '@config/content/constants';
import { FffProfile } from '@model/fff-profile.model';
import { OUTLINED_ICON_TYPE } from '@model/fff-outline-icons.model';
import { FffDrawerService } from '@shared/drawer/fff-drawer.service';
import {
  FffReturnSendRGACopyComponent,
} from '@enterprise/fff-returns/fff-return-send-rga-copy/fff-return-send-rga-copy.component';

@Component({
  selector: 'fff-return-details',
  templateUrl: './fff-return-details.component.html',
})
export class FffReturnDetailsComponent {
  profile$: Observable<FffProfile | undefined> = this.fffAccountService.getProfile();
  activeSite$ = this.baseSiteService.getActive();
  isRGAReturn:boolean=true;
  returnOrderDetails$ = this.routingService.getRouterState().pipe(
    map((routingData) => {
      return routingData.state.params.returnCode;
    }),
    distinctUntilChanged()
  ).pipe(
    switchMap(
      (returnCode) => {
        const order = this.orderFacade.getOrderDetails(returnCode);
                  order.subscribe((returnOrder)=>{
                    this.isRGAReturn = !(returnOrder?.entries || [])?.every(
                                          (entry:any)=> !!entry?.product.code.endsWith('ET')
                                        )//every
                    this.cdr.markForCheck();
                  });
        return order;
      })
  );
  BASE_URL_KEYS = BASE_URL_KEYS;
  outlinedIconTypes = OUTLINED_ICON_TYPE;
  constructor(
    private fffAccountService: FffUserAccountService,
    private baseSiteService: BaseSiteService,
    private routingService: RoutingService,
    private orderFacade: FffOrderFacade,
    private drawerService: FffDrawerService,
    private cdr: ChangeDetectorRef
  ) {}
  openSendRGACopyDrawer(orderId: string) {
    this.drawerService.setContent({
      title: 'Send RGA Copy',
      component: FffReturnSendRGACopyComponent,
      animation: 'SideRTL',
      class: 'order_history',
      data: orderId,
    });
    this.drawerService.openDrawer();
  }
  showSendRGACopyButton(returnOrder: any) {
    //check if returnOrder.placed year is the current year
    const currentYear = new Date().getFullYear();
    const placedYear = new Date(returnOrder.created).getFullYear();
    return placedYear === currentYear;
  }

}
