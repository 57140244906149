import { Component } from '@angular/core';
import { ResetPasswordComponent, ResetPasswordComponentService } from '@spartacus/user/profile/components';
import { FILLED_ICON_TYPE } from 'src/app/models/fff-filled-icons.model';
import {FffPasswordResetService} from "@shared/services/fff-reset-password-service";

@Component({
  selector: 'fff-password-reset',
  templateUrl: './fff-password-reset.component.html'
})
export class FffPasswordResetComponent extends ResetPasswordComponent {

  showNewPassword: boolean = false;
  showNewPasswordConfirmation: boolean = false;
  filledIconTypes = FILLED_ICON_TYPE;

  constructor(protected service: FffPasswordResetService) {
    super(service);
  }

  enablePassword(): void {
    this.showNewPassword = !this.showNewPassword;
  }

  enablePasswordConfirmation(): void {
    this.showNewPasswordConfirmation = !this.showNewPasswordConfirmation;
  }
  onSubmit(token: string) {
    this.service.resetPassword(token);
  }
}
