import { ChangeDetectorRef, Component, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'fff-radio-list-control',
  templateUrl: './fff-radio-list-control.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: FffRadioListControlComponent,
    },
  ],
})
export class FffRadioListControlComponent implements ControlValueAccessor {
  @Input() inputId: string = '';
  @Input() inputName: string = '';
  @Input() items: any[] = [];

  touched = false;
  disabled = false;

  onChange = (_value: any) => {};
  onTouched = () => {};

  constructor(private cd: ChangeDetectorRef) {}

  writeValue(value: any): void {
    this.updateItemsSelection(value);
    this.onChange(value);
  }

  registerOnChange(onChange: any) {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any): void {
    this.onTouched = onTouched;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  markAsTouched() {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }

  updateItemsSelection(id: any) {
    this.items.forEach(item => {
      if (item.id !== id) {
        item.selected = false;
      } else {
        item.selected = true;
      }
    });
    this.cd.markForCheck();
  }

  onRadioToggle(id: any) {
    this.updateItemsSelection(id);
    this.onChange(this.items.find(item => item.selected)?.id);
    this.markAsTouched();
  }
}
