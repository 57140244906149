<div>
  <div class="drawer-header">
    <div class="closed">
      <cx-icon
        class="cx-icon icon-lg"
        [type]="iconClose"
        (click)="close()"
      ></cx-icon>
    </div>
    <div class="title">
      <h3>
        {{
          isMultiAccount
            ? ('fffReports.selectAccounts' | cxTranslate)
            : ('fffReports.selectAccount' | cxTranslate)
        }}
      </h3>
    </div>
    <div class="ml-5 mt-2" *ngIf="isMultiAccount">
      <input
        type="checkbox"
        [(ngModel)]="selectAllChecked"
        (change)="toggleAll()"
      />
      <label class="pt-2 selectAllText">Select All</label>
    </div>
  </div>

  <div
    class="drawer-body"
    [ngClass]="{
      accountsDrawerBody: !isASMUser && isMultiAccount,
      asmAccountsDrawerBody: !!isASMUser && isMultiAccount,
      accountDrawerBody: !isASMUser && !isMultiAccount,
      asmAccountDrawerBody: !!isASMUser && !isMultiAccount
    }"
  >
    <section class="search-box">
      <label class="label" for="search">
        <input
          id="search"
          autocomplete="off"
          placeholder="{{
            'fffAccount.fffChangeAccount.searchByAccount' | cxTranslate
          }}"
          [(ngModel)]="searchText"
          (keyup)="searchAccounts()"
        />

        <div *ngIf="!searchText" class="search-icon">
          <cx-icon
            class="cx-icon icon-sm"
            [type]="outlinedIconTypes.ICON_SEARCH"
          ></cx-icon>
        </div>
        <button *ngIf="searchText" class="reset">
          <cx-icon
            class="cx-icon icon-sm"
            [type]="outlinedIconTypes.ICON_X_CIRCLE"
            (click)="searchText = ''"
          ></cx-icon>
        </button>
      </label>
    </section>
    <ng-container *ngIf="!loading; else loadingTemplate">
      <ng-container *ngIf="accountListData$ | async as accountListData">
        <div
          class="account-container"
          *ngFor="let unit of accountListData.data"
        >
          <div class="block-icon" *ngIf="isAccountSelected(unit)">
            <div class="icon">
              <cx-icon
                class="cx-icon icon-sm icon-account"
                [type]="outlinedIconTypes.ICON_CHECK_LG"
              ></cx-icon>
            </div>
          </div>
          <div
            class="account-card"
            (click)="selectB2bUnit(unit)"
            [ngClass]="{ selected: isAccountSelected(unit) }"
          >
            <div class="card-content">
              <div class="block-profile">
                <div class="fields">
                  <div class="field">
                    {{ 'fffAccount.fffChangeAccount.account' | cxTranslate }}
                  </div>
                  <div class="field">
                    {{ 'fffAccount.fffChangeAccount.name' | cxTranslate }}
                  </div>
                </div>
                <div class="values">
                  <div class="value">
                    {{ unit.uid }}
                  </div>
                  <div class="value">
                    {{ unit.name }}
                  </div>
                </div>
              </div>

              <div class="block-profile" *ngIf="hasShippingAddress(unit)">
                <div class="fields">
                  <div class="field">
                    {{ 'fffAccount.fffChangeAccount.address' | cxTranslate }}
                  </div>
                </div>
                <div class="values">
                  <div class="value">
                    <span [innerHTML]="getShippingAddress(unit)"></span>
                  </div>
                </div>
              </div>

              <div class="block-profile" *ngIf="unit.attentionName">
                <div class="fields">
                  <div class="field">
                    {{
                      'fffAccount.fffChangeAccount.attentionName' | cxTranslate
                    }}
                  </div>
                </div>
                <div class="values">
                  <div class="value">
                    {{ unit.attentionName }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
    </ng-container>
  </div>
  <div
    class="drawer-footer"
    *ngIf="accountListData$ | async as accountListData"
  >
    <fff-table
      *ngIf="!isASMUser"
      [size]="pageSize"
      [showOnlyPaginator]="true"
      (pageChange)="getAccountsData($event, true)"
      [data]="accountListData"
    ></fff-table>

    <button
      class="btn btn-primary btn-lg w-100"
      [disabled]="selectedAccounts.length === 0"
      (click)="selectAccount()"
    >
      {{
        isMultiAccount
          ? ('fffReports.selectAccounts' | cxTranslate)
          : ('fffReports.selectAccount' | cxTranslate)
      }}
    </button>
  </div>
</div>
<ng-template #loadingTemplate>
  <fff-drawer-loader></fff-drawer-loader>
</ng-template>
