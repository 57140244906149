<div class="d-flex flex-column justify-content-between h-100">
  <div class="resend-order-confirmation-drawer-container">
    <form [formGroup]="emailsForm" (ngSubmit)="sendEmails()">
      <div class="mb-4">
        <div class="label-container form-label form-label--md mb-3">
          <span>{{ 'fffOrderHistory.fields.orders.label' | cxTranslate }}</span>
        </div>
        <ul *ngIf="orders.length" class="has-two-columns">
          <li *ngFor="let order of orders">{{ order }}</li>
        </ul>
      </div>
      <div class="label-container form-label form-label--md mb-3">
        <span>{{ 'fffOrderHistory.enterMultipleEmails' | cxTranslate }}</span>
      </div>
      <div class="input-container mt-4">
        <div class="form-group">
          <label class="form-label form-label--required" for="emailsTextArea">{{
            'fffOrderHistory.fields.emails.label' | cxTranslate
          }}</label>
          <textarea
            class="form-control"
            id="emailsTextArea"
            rows="3"
            formControlName="emails"
          ></textarea>
          <cx-form-errors
            aria-live="assertive"
            aria-atomic="true"
            [control]="$any(emailsForm).get('emails')"
          ></cx-form-errors>
        </div>
      </div>
      <div class="button-submit-container mt-auto">
        <button
          type="submit"
          class="btn btn-primary w-100"
          [disabled]="submitting"
        >
          {{ 'fffOrderHistory.actions.sendOrderConfirmations' | cxTranslate }}
        </button>
      </div>
    </form>
  </div>
</div>
