import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { fffInvoiceEndpointsConfig } from '@app/fff-config/fff-endpoints.config';
import { OccEndpointsService } from '@spartacus/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class FffInvoiceHistoryService {
  constructor(
    private http: HttpClient,
    protected occEndpoints: OccEndpointsService
  ) {}

  private getRequestHeaders(): HttpHeaders {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    return headers;
  }

  getInvoiceHistoryList(pageNumber: number, filters?: any): Observable<any> {
    let filtersBuilder = '';
    if (filters && filters.invoiceNumber && filters.invoiceNumber.length > 0) {
      filtersBuilder += `&number=${filters.invoiceNumber}`;
    }

    if (filters && filters.open) {
      filtersBuilder += `&status=OPEN`;
    }

    if (filters && filters.paid) {
      filtersBuilder += `&status=PAID`;
    }

    if (filters && filters.dateFrom) {
      filtersBuilder += `&startDate=${this.parseDateToString(
        filters.dateFrom
      )}`;
    }

    if (filters && filters.dateTo) {
      filtersBuilder += `&endDate=${this.parseDateToString(filters.dateTo)}`;
    }

    if (filters && filters.ccPaymentEligible) {
      filtersBuilder += `&ccPaymentEligible=true`;
    }

    if (filters && filters.manufacturer) {
      filtersBuilder += `&manufacturer=${encodeURIComponent(
        filters.manufacturer
      )}`;
    }

    const url = this.occEndpoints.buildUrl(
      `invoices?currentPage=${pageNumber}&fields=DEFAULT${filtersBuilder}`
    );

    return this.http.get(url, { headers: this.getRequestHeaders() });
  }

  parseDateToString(d: any) {
    if (!d) {
      return;
    }
    return (
      d.year.toString() +
      '-' +
      this.completeNumber(d.month) +
      '-' +
      this.completeNumber(d.day)
    );
  }

  private completeNumber(value: number): string {
    if (value > 9) {
      return value.toString();
    } else {
      return `0${value}`;
    }
  }

  getInvoiceHistoryDetail(invoiceNumber: string): Observable<any> {
    const url = this.occEndpoints.buildUrl(
      `account/invoice/${invoiceNumber}?fields=DEFAULT&invoiceNumber=${invoiceNumber}`
    );
    return this.http.get(url, { headers: this.getRequestHeaders() });
  }

  getInvoiceDownloadPDF(): string {
    return this.occEndpoints.buildUrl(fffInvoiceEndpointsConfig.downloadPDF);
  }

  getAllInvoices() {
    const url = this.occEndpoints.buildUrl(`account/invoices/csv-download`);
    return this.http.get(url, { headers: this.getRequestHeaders() });
  }
}
