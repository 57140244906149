import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { I18nModule, UrlModule } from '@spartacus/core';
import { IconModule, PageSlotModule } from '@spartacus/storefront';
import { FffEmptyListComponent } from './fff-empty-list.component';


@NgModule({
  declarations: [FffEmptyListComponent],
  imports: [
    UrlModule,
    BrowserModule,
    RouterModule,
    CommonModule,
    I18nModule,
    IconModule,
    PageSlotModule
  ],
  exports: [FffEmptyListComponent],
  providers: [],
})
export class FffEmptyListModule { }
