import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CmsConfig, ConfigModule, I18nModule } from '@spartacus/core';
import { GenericLinkModule, IconModule } from '@spartacus/storefront';
import { FffMyAccountNavigationComponent } from './fff-my-account-navigation.component';



@NgModule({
  declarations: [FffMyAccountNavigationComponent],
  imports: [
    CommonModule,
    GenericLinkModule,
    I18nModule,
    IconModule,
    ConfigModule.withConfig({
      cmsComponents: {
        FFFNavigationComponent: {
          component: FffMyAccountNavigationComponent
        },
      },
    } as CmsConfig)
  ],
})
export class FffMyAccountNavigationModule { }
