import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { FffSsoService } from '@app/fff-enterprise/fff-sso/fff-sso.service';
import { getProductImage } from '@assets/utils/fff-product-utils';
import { ActiveCartFacade } from '@spartacus/cart/base/root';
import { BaseSiteService, Product } from '@spartacus/core';
import { MediaContainer } from '@spartacus/storefront';
import { Options as SplideOptions } from '@splidejs/splide';
import { Subscription } from 'rxjs';
import { switchMap, take } from 'rxjs/operators';
import { FffRecommendedProductsService } from '../fff-recommended-products.service';

@Component({
  selector: 'fff-recommended-products',
  templateUrl: './fff-recommended-products.component.html',
})
export class FFFRecommendedProductsComponent implements OnInit, OnDestroy {
  options: SplideOptions = {
    type: 'loop',
    perPage: 4,
    arrows: true,
    pagination: false,
    rewind: true,
    gap: '1.5rem',
    autoScroll: {
      speed: 0.6,
    },
    breakpoints: {
      992: { perPage: 3 },
      767: {
        perPage: 1,
        arrows: false,
        gap: '0rem',
        autoWidth: true,
      },
    },
  };
  products: Product[] = [];
  currentSite: string = '';
  subs = new Subscription();

  constructor(
    private fffRecommendedProductsService: FffRecommendedProductsService,
    private baseSiteService: BaseSiteService,
    private activeCartService: ActiveCartFacade,
    private fffSsoService: FffSsoService,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.subs.add(
      this.baseSiteService
        .getActive()
        .pipe(take(1))
        .subscribe(site => {
          this.currentSite = site;
          this.loadProducts();
        })
    );
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  openAltSiteUrl(ev: Event, product: Product) {
    ev.preventDefault();

    if (!product?.site) {
      return;
    }

    this.fffSsoService.redirectToDynamicPageOnOtherSite(product.url ?? '');
  }

  getData(images: any) {
    return getProductImage(images) as MediaContainer;
  }

  getFormattedProducts(products: Product[]): Product[] {
    return products.map(({ site, url, ...product }) => ({
      ...product,
      site,
      url,
    }));
  }

  loadProducts() {
    this.activeCartService
      .getActiveCartId()
      .pipe(
        take(1),
        switchMap(cartId =>
          this.fffRecommendedProductsService.getProductRecommendations(cartId)
        )
      )
      .subscribe(products => {
        this.products = this.getFormattedProducts(products);
        this.cd.markForCheck();
      });
  }
}
