import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { OccEndpointsService } from '@spartacus/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class FffB2BUnitService {

  constructor(private http: HttpClient, protected occEndpoints: OccEndpointsService) { }

  private getRequestHeaders(): HttpHeaders {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    return headers;
  }

  tradePartner(userId: string, accountId: string, data: any): Observable<any> {
    const url = this.occEndpoints.buildUrl(`/users/${userId}/account/${accountId}/tradePartner`);
    return this.http.post(url, data, { headers: this.getRequestHeaders() });
  }

  certificateLoginToken(userId: string, accountId: string): Observable<any> {
    const url = this.occEndpoints.buildUrl(`/users/${userId}/account/${accountId}/certificateLoginToken`);
    return this.http.get(url, { headers: this.getRequestHeaders() });
  }

}
