import { Component } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { FffPrebookExitConfirmationModalComponent } from '../fff-prebook-exit-confirmation-modal/fff-prebook-exit-confirmation-modal.component';

@Component({
  selector: 'fff-prebook-site-logo',
  templateUrl: './fff-prebook-site-logo.component.html',
})
export class FffPrebookSiteLogoComponent {
  fffWarningModal: NgbModalRef | null = null;

  constructor(private modalService: NgbModal) {}

  ngOnInit(): void {}

  proceedToHomepage(e: Event) {
    e.preventDefault();
    e.stopImmediatePropagation();
    this.fffWarningModal = this.modalService.open(
      FffPrebookExitConfirmationModalComponent,
      {
        animation: true,
        backdrop: 'static',
        centered: true,
        windowClass: 'fff-modal',
      }
    );
  }
}
