
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FffDrawerModule } from '@app/shared/drawer/fff-drawer.module';
import { FffTableModule } from '@app/shared/table/fff-table.module';
import { FffTooltipModule } from '@app/shared/tooltip/tooltip.module';
import { FffFavoritesModule } from '@enterprise/fff-favorites/fff-favorites.module';
import { FffFetReturnsModule } from '@enterprise/fff-fet-returns/fff-fet-returns.module';
import {
  FffAccountGlobalRestrictionsCheckerModule
} from '@enterprise/fff-header/fff-account-global-restrictions/fff-account-global-restrictions-checker.module';
import {
  FffGlobalBannerMessageModule
} from '@enterprise/fff-header/fff-global-banner-message/fff-global-banner-message.module';
import {
  FffGlobalNotificationModule
} from '@enterprise/fff-header/fff-global-notification/fff-global-notification.module';
import { FffRecurringOrdersModule } from '@enterprise/fff-recurring-orders/fff-recurring-orders.module';
import { FffAccountSearchModule } from './fff-account-search/fff-account-search.module';
import { FffAddonsModule } from './fff-addons/fff-addons.module';
import { FffAllocationsModule } from './fff-allocations/fff-allocations.module';
import { FffApprovalsModule } from './fff-approvals/fff-approvals.module';
import { FffBreadcrumbModule } from './fff-breadcrumb/fff-breadcrumb.module';
import { FffCartModule } from './fff-cart/fff-cart.module';
import { FffCheckoutModule } from './fff-checkout/fff-checkout.module';
import { FffCommonServicesModule } from './fff-common-services/fff-common-services.module';
import { FffContactUsModule } from './fff-contact-us/fff-contact-us.module';
import { FffCsosStatusModule } from './fff-csos-status/fff-csos-status/fff-csos-status.module';
import { FffEntryModule } from './fff-entry/fff-entry.module';
import { FffErrorPagesModule } from './fff-error-pages/fff-error-pages.module';
import { FffHamburgerMenuModule } from './fff-hamburger-menu/fff-hamburger-menu.module';
import { FffChangeAccountDrawerModule } from './fff-header/fff-change-account/fff-change-account-drawer.module';
import { FffCartButtonModule } from './fff-header/fff-mini-cart/fff-mini-cart.module';
import { FffMyAccountNavigationModule } from './fff-header/fff-my-account-navigation/fff-my-account-navigation.module';
import { FffMyAccountDrawerModule } from './fff-header/fff-my-account/fff-my-account-drawer.module';
import { fffSearchBoxModule } from './fff-header/fff-search-box/fff-search-box.module';
import { FffSiteLoginModule } from './fff-header/fff-site-login/fff-site-login.module';
import { FffSkipLinksModule } from './fff-header/fff-skip-links/fff-skip-links.module';
import { FffTopBarModule } from './fff-header/fff-top-bar/fff-top-bar.module';
import { FffInvoiceModule } from './fff-invoice-history/fff-invoice.module';
import { FffJeffersonPageModule } from './fff-jefferson-page/fff-jefferson-page.module';
import { FffLoginModule } from './fff-login/fff-login.module';
import { FffNavigationBarModule } from './fff-navigation-bar/fff-navigation-bar.module';
import { FffOneTrustCookieModule } from './fff-one-trust-cookie/fff-one-trust-cookie.module';
import { FffOrderModule } from './fff-order/fff-order.module';
import { FffPasswordModule } from './fff-password/fff-password.module';
import { FffSimpleResponsiveModule } from './fff-popular-categories/fff-simple-responsive.module';
import { FffProductAllocationModule } from './fff-product-allocation/fff-product-allocation.module';
import { FffProductDetailModule } from './fff-product-detail/fff-product-detail.module';
import { FffProductListPageModule } from './fff-product-list-page/fff-product-list-page.module';
import { FffProfileManagementModule } from './fff-profile-management/fff-profile-management.module';
import { FffQuickOrderModule } from './fff-quick-order/fff-quick-order.module';
import { FffReportsModule } from './fff-reports/fff-reports.module';
import { FffRequestChangeAddressModule } from './fff-request-change-address/fff-request-change-address.module';
import { FffReturnsModule } from './fff-returns/fff-returns.module';
import { FffServiceThirdPartyModule } from './fff-service-third-party/fff-service-third-party.module';
import { FffUserPriceListModule } from './fff-user-price-list/fff-user-price-list.module';


@NgModule({
  imports: [
    CommonModule,
    FffOneTrustCookieModule,
    FffServiceThirdPartyModule,
    FffAddonsModule,
    FffSimpleResponsiveModule,
    FffCommonServicesModule,
    FffProductListPageModule,
    FffAccountSearchModule,
    FffNavigationBarModule,
    FffProductAllocationModule,
    FffLoginModule,
    FffPasswordModule,
    FffErrorPagesModule,
    FffFavoritesModule,
    FffProductDetailModule,
    FffContactUsModule,
    FffOrderModule,
    FffMyAccountNavigationModule,
    FffCartModule,
    FffInvoiceModule,
    FffBreadcrumbModule,
    FffSiteLoginModule,
    FffDrawerModule,
    FffTableModule,
    FffTopBarModule,
    fffSearchBoxModule,
    FffMyAccountDrawerModule,
    FffChangeAccountDrawerModule,
    FffUserPriceListModule,
    FffProfileManagementModule,
    FffEntryModule,
    FffCheckoutModule,
    FffHamburgerMenuModule,
    FffQuickOrderModule,
    FffRequestChangeAddressModule,
    FffSkipLinksModule,
    FffCartButtonModule,
    FffAllocationsModule,
    FffTooltipModule,
    FffJeffersonPageModule,
    FffGlobalBannerMessageModule,
    FffGlobalNotificationModule,
    FffAccountGlobalRestrictionsCheckerModule,
    FffApprovalsModule,
    FffReturnsModule,
    FffFetReturnsModule,
    FffReportsModule,
    FffRecurringOrdersModule,
    FffCsosStatusModule
  ],
  exports: [
    FffOneTrustCookieModule,
    FffTopBarModule,
    FffDrawerModule,
    FffGlobalBannerMessageModule,
    FffGlobalNotificationModule,
    FffAccountGlobalRestrictionsCheckerModule,
  ],
  declarations: [
  ],
})
/**
 * Export FFFEnterprise Module.
 * Contains changes for FFF-enterprise.
 */
export class FffEnterpriseModule { }
