<div [class]="loading ? 'loading' : ''" >
  <div class="drawer-header">
    <div class="closed">
      <cx-icon
        class="cx-icon icon-lg"
        [type]="iconClose"
        (click)="close()"
      ></cx-icon>
    </div>
    <div class="title">
      <h3>{{ 'fffAccount.fffChangeAccount.title' | cxTranslate }}</h3>
    </div>
  </div>

  <div
    class="drawer-body"
    [ngClass]="{
      accountDrawerBody: !isASMUser,
      asmAccountDrawerBody: !!isASMUser
    }"
  >
    <section class="search-box">
      <label class="label" for="search">
        <input
          id="search"
          autocomplete="off"
          [placeholder]="'fffAccount.fffChangeAccount.searchByAccount' | cxTranslate"
          [formControl]="searchTextInput"
        />

        <div *ngIf="!searchTextInput.value" class="search-icon">
          <cx-icon
            class="cx-icon icon-sm"
            [type]="outlinedIconTypes.ICON_SEARCH"
          ></cx-icon>
        </div>
        <div *ngIf="searchTextInput.value" class="reset">
          <cx-icon
            class="cx-icon icon-sm"
            [type]="outlinedIconTypes.ICON_X_CIRCLE"
            (click)="clear()"
          ></cx-icon>
        </div>
      </label>
    </section>
    <ng-container *ngIf="!loading; else loadingTemplate">
      <ng-container
        *ngIf="
          accountListData$ | async as accountListData;
          else asmUserSearchTemplate
        "
      >

        <div
          class="account-container sdfsdf"
          *ngFor="let unit of accountListData.data"
        >
          <div class="block-icon" *ngIf="unit.uid === selectedUnit.uid">
            <div class="icon">
              <cx-icon
                class="cx-icon icon-sm icon-account"
                [type]="outlinedIconTypes.ICON_CHECK_LG"
              ></cx-icon>
            </div>
          </div>
          <div
            class="account-card"
            (click)="selectB2bUnit(unit)"
            [ngClass]="{ selected: unit.uid === selectedUnit.uid }"
          >
            <div class="card-content">
              <div class="block-profile">
                <div class="fields">
                  <div class="field">
                    {{ 'fffAccount.fffChangeAccount.account' | cxTranslate }}
                  </div>
                  <div class="field">
                    {{ 'fffAccount.fffChangeAccount.name' | cxTranslate }}
                  </div>
                </div>
                <div class="values">
                  <div class="value">
                    {{ unit.uid }}
                  </div>
                  <div class="value">
                    {{ unit.name }}
                  </div>
                </div>
              </div>

              <div class="block-profile" *ngIf="hasShippingAddress(unit)">
                <div class="fields">
                  <div class="field">
                    {{ 'fffAccount.fffChangeAccount.address' | cxTranslate }}
                  </div>
                </div>
                <div class="values">
                  <div class="value">
                    <span [innerHTML]="getShippingAddress(unit)"></span>
                  </div>
                </div>
              </div>

              <div class="block-profile" *ngIf="unit.attentionName">
                <div class="fields">
                  <div class="field">
                    {{
                      'fffAccount.fffChangeAccount.attentionName' | cxTranslate
                    }}
                  </div>
                </div>
                <div class="values">
                  <div class="value">
                    {{ unit.attentionName }}
                  </div>
                </div>
              </div>
              <fff-drawer-loader
                *ngIf="loading && unit.uid === selectedUnit"
              ></fff-drawer-loader>
            </div>
          </div>
        </div>
        <div
          class="py-5 text-center"
          *ngIf="accountListData?.data?.length === 0"
        >
          {{ 'fffAccount.fffChangeAccount.noAccountsFound' | cxTranslate }}
        </div>
      </ng-container>
    </ng-container>
  </div>

  <div
    class="drawer-footer"
    *ngIf="accountListData$ | async as accountListData"
  >
    <div class="mb-2">
      <fff-table
        *ngIf="!isASMUser"
        [size]="pageSize"
        [showOnlyPaginator]="true"
        (pageChange)="getAccountsData($event, true)"
        [data]="accountListData"
      ></fff-table>
    </div>

    <button
      class="btn btn-primary btn-lg w-100"
      [disabled]="profile.selected && selectedUnit.uid === profile.selected.uid"
      (click)="changeAccount(cart?.code ?? '')"
    >
      {{ 'fffAccount.fffChangeAccount.apply' | cxTranslate }}
    </button>
  </div>
</div>

<ng-template #loadingTemplate>
  <fff-drawer-loader></fff-drawer-loader>
</ng-template>

<ng-template #asmUserSearchTemplate>
  <div class="py-5 text-center" *ngIf="isASMUser">
    {{ 'fffAccount.fffChangeAccount.searchToFindAnAccount' | cxTranslate }}
  </div>
</ng-template>
