import { Component, Input, OnInit } from '@angular/core';
import { getCategoryCodeByName } from '@app/fff-config/category/fff-category.utils';
import { getEntriesByCategoryCode } from '@app/fff-enterprise/fff-prebook-category/services/fff-prebook-category.utils';
import { FFFOrderEntry } from '@app/models/fff-cart-data.model';
import { VACCINES_CATEGORY_TYPES } from '@app/models/fff-product.model';
import { CartDetailsComponent } from '@spartacus/cart/base/components';
import {
  CartConfigService,
  SelectiveCartService,
} from '@spartacus/cart/base/core';
import { ActiveCartFacade } from '@spartacus/cart/base/root';
import { AuthService, BaseSiteService, RoutingService } from '@spartacus/core';
import { filter, map } from 'rxjs/operators';

@Component({
  selector: 'fff-prebook-cart-details',
  templateUrl: './fff-prebook-cart-details.component.html',
})
export class FffPrebookCartDetailsComponent
  extends CartDetailsComponent
  implements OnInit
{
  @Input() prices: any[] = [];
  @Input() stock: any[] = [];

  entryGroups: string[] = [
    VACCINES_CATEGORY_TYPES.INFLUENZA,
    VACCINES_CATEGORY_TYPES.COVID,
    VACCINES_CATEGORY_TYPES.RSV,
  ];

  groupedEntries$ = this.fffActiveCartService.getActive().pipe(
    filter(cart => !!cart.code),
    map(({ entries = [] }) => {
      let obj: {
        [key: string]: FFFOrderEntry[];
      } = {};

      this.entryGroups.forEach(group => {
        let code = getCategoryCodeByName(group);
        obj[group] = getEntriesByCategoryCode(entries, code);
      });

      return obj;
    })
  );

  activeSite$ = this.baseSiteService.getActive();

  constructor(
    protected fffActiveCartService: ActiveCartFacade,
    protected selectiveCartService: SelectiveCartService,
    protected authService: AuthService,
    protected routingService: RoutingService,
    protected cartConfig: CartConfigService,
    private baseSiteService: BaseSiteService
  ) {
    super(
      fffActiveCartService,
      selectiveCartService,
      authService,
      routingService,
      cartConfig
    );
  }

  ngOnInit(): void {
    super.ngOnInit();
  }
}
