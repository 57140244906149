<div class="container">
  <div class="user-price-list-header">
    <div class="d-flex justify-content-between top-title">
      <div class="d-flex">
        <h3>{{ 'fffPriceList.title' | cxTranslate }}</h3>
        <!-- CC-2604 -->
        <button class="btn btn-secondary btn-xs" (click)="openDrawer()">
          {{ 'fffPriceList.filter' | cxTranslate }}
          <cx-icon
            class="cx-icon icon-sm"
            [type]="outlinedIconTypes.ICON_FILTER"
          >
          </cx-icon>
        </button>
      </div>

      <div class="d-flex btn-container">
        <a
          class="d-flex align-items-center export-btn disabled"
          (click)="exportCSV()"
        >
          <span>{{ 'fffPriceList.exportCsv' | cxTranslate }}</span>
          <cx-icon
            class="cx-icon icon-sm"
            [type]="outlinedIconTypes.ICON_DOWNLOAD"
          >
          </cx-icon>
        </a>
        <!-- CC-2565 -->
        <a class="d-flex align-items-center disabled" (click)="exportPDF()">
          <span>{{ 'fffPriceList.downloadPdf' | cxTranslate }}</span>
          <cx-icon
            class="cx-icon icon-sm"
            [type]="outlinedIconTypes.ICON_DOWNLOAD"
          >
          </cx-icon>
        </a>
      </div>
    </div>
    <hr class="sep-table-title" />
  </div>
  <ng-container *ngIf="(loading$ | async) !== true; else loadingTemplate">
    <ng-container
      *ngIf="priceListData$ | async as priceListData; else loadingTemplate"
    >
      <table class="price-list-table">
        <thead>
          <tr>
            <th class="pl-4 w-13">
              {{ 'fffPriceList.productType' | cxTranslate }}
            </th>
            <th class="w-20">
              {{ 'fffPriceList.productAndManufacturer' | cxTranslate }}
            </th>
            <th class="w-12">
              {{ 'fffPriceList.skuAndNdc' | cxTranslate }}
            </th>
            <th class="w-13">
              {{ 'fffPriceList.productSize' | cxTranslate }}
            </th>
            <th class="w-12">
              {{ 'fffPriceList.accountNumber' | cxTranslate }}
            </th>
            <th class="w-15">
              {{ 'fffPriceList.price' | cxTranslate }}
            </th>
            <th class="pr-4 w-15 contractPdctColumnWidth">
              {{ 'fffPriceList.contractProduct' | cxTranslate }}
            </th>
          </tr>
        </thead>
        <tbody>
          <ng-container *ngFor="let data of priceListData.data">
            <tr class="align-top">
              <td class="pl-4 w-13">{{ data.category }}</td>
              <td class="w-20">
                {{ data.name }} <br />{{ data.manufacturer }}
              </td>
              <td class="w-12">{{ data.productCode }} <br />{{ data.ndc }}</td>
              <td class="w-13">{{ data.size }}</td>
              <td class="w-12">{{ data.accountNumber }}</td>
              <td class="w-15">
                <span class="fw-bold"
                  >{{ 'fffPriceList.price' | cxTranslate }}:</span
                >
                {{ data.priceColumn }} <br />
                <span class="fw-bold"
                  >{{ 'fffPriceList.altPrice' | cxTranslate }}:</span
                >
                {{ data.altPriceColumn }} <br />
                <span class="fw-bold"
                  >{{ 'fffPriceList.netPrice' | cxTranslate }}:</span
                >
                {{ data.basicDiscountPrice }} <br />
              </td>
              <td class="pr-4 w-15">
                <div *ngIf="data.contractedMaterial" class="tickmark-cell">
                  <div class="orange-tick"></div>
                </div>
              </td>
            </tr>
          </ng-container>
        </tbody>
      </table>
      <fff-table
        (pageChange)="getData($event)"
        [columnsGrid]="columnsGrid"
        [data]="priceListData"
        [fields]="fields"
        [customClass]="'user-price-list'"
        [size]="pageSize"
        [showOnlyPaginator]="true"
      ></fff-table>
    </ng-container>
  </ng-container>
</div>

<ng-template #loadingTemplate>
  <fff-table-loader [fields]="fields"></fff-table-loader>
</ng-template>
