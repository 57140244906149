import {
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  Pipe,
  PipeTransform,
} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { OUTLINED_ICON_TYPE } from '@app/models/fff-outline-icons.model';
import { FffProfile } from '@app/models/fff-profile.model';
import { FffDrawerService } from '@app/shared/drawer/fff-drawer.service';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { UserIdService } from '@spartacus/core';
import { Observable, Subscription, of } from 'rxjs';
import { FffNewReportsService } from '../fff-new-reports.service';
import { FffCancelRecurringReportDrawerComponent } from './fff-cancel-recurring-report-drawer/fff-cancel-recurring-report-drawer.component';

@Component({
  selector: 'fff-new-reports',
  templateUrl: './fff-new-reports.component.html',
})
export class FffNewReportsComponent implements OnInit, OnDestroy {
  reportList: any[] = [];
  userId!: string;
  profile$: Observable<FffProfile | undefined> = of(undefined);
  refreshReports!: Subscription;
  fffCancelReportDrawer: NgbModalRef | null = null;
  truncate: number = 50;
  outlinedIconTypes = OUTLINED_ICON_TYPE;
  constructor(
    private fffNewReportsService: FffNewReportsService,
    protected userIdService: UserIdService,
    private cd: ChangeDetectorRef,
    private sanitizer: DomSanitizer,
    private modalService: NgbModal,
    private drawerService: FffDrawerService
  ) {}

  ngOnInit(): void {
    this.refreshReports = this.fffNewReportsService.refresh.subscribe(() => {
      this.fetchReports();
    });

    this.fetchReports();
  }

  ngOnDestroy(): void {
    this.refreshReports?.unsubscribe();
  }

  fetchReports() {
    this.fffNewReportsService.getReports().subscribe(res => {
      if (res.reports) {
        this.reportList = res.reports;
        this.cd.markForCheck();
      }
    });
  }
  openDrawer(reportId: string) {
    if (!this.fffCancelReportDrawer) {
      this.drawerService.closeDrawer();
      this.fffCancelReportDrawer = this.modalService.open(
        FffCancelRecurringReportDrawerComponent,
        {
          modalDialogClass: 'fff-drawer',
          backdrop: 'static',
          windowClass: 'fff-window',
          animation: true,
        }
      );
      this.fffCancelReportDrawer.componentInstance.reportId = reportId;
      this.fffCancelReportDrawer.result
        .then(() => {
          this.cleanDrawer();
        })
        .catch(() => {
          this.cleanDrawer();
        });
    }
  }
  getFormattedValueLength(value: string) {
    const formattedValue = value.replace(/,/g, ', ').replace(/\s+/g, ' ');
    return formattedValue.length;
  }
  getFormattedReportTypeValue(value: string) {
    if (!value) return '';
    return value.replace(/_/g, ' ').replace(/,/g, ', ');
  }

  private cleanDrawer() {
    this.fffCancelReportDrawer = null;
  }

  editReport(report: any) {
    this.fffNewReportsService.sendEditReportEvent(report);
  }
  toggleRowExpansion(report: any) {
    report.expanded = !report.expanded;
    this.cd.markForCheck();
  }
  isRowExpandable(report: any) {
    return report &&
      report.reportType &&
      (report.reportType == 'ALLOCATIONS' || report.reportType == 'PRICES')
      ? true
      : false;
  }

  getDataForRowExpansion(report: any): string[] {
    let dataArray: string[] = [];
    if (
      report.reportType &&
      report.reportType == 'ALLOCATIONS' &&
      report.products
    ) {
      dataArray = report.products.map((product: any) => product.name);
    } else if (
      report.reportType &&
      report.reportType == 'PRICES' &&
      report.productGroups
    ) {
      const productGroupsString = report.productGroups.trim();
      const valuesArray = productGroupsString.split(',');
      const trimmedArray = valuesArray.map((value: string) => value.trim());
      if (trimmedArray && trimmedArray.length > 0) {
        dataArray = trimmedArray;
      }
    }
    return dataArray;
  }
}

@Pipe({
  name: 'truncateTableCellValues',
})
export class TruncateTableCellValuesPipe implements PipeTransform {
  constructor() {}
  transform(value: string, maxLength: number): any {
    if (!value) return '';
    const formattedValue = value.replace(/,/g, ', ').replace(/\s+/g, ' ');
    if (formattedValue.length <= maxLength) {
      return formattedValue;
    } else {
      return formattedValue.substring(0, maxLength) + '...';
    }
  }
}
