import { Injectable } from '@angular/core';

import { AuthService, RoutingService } from '@spartacus/core';
import { UserAccountFacade } from '@spartacus/user/account/root';
import { Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ProductGuard  {

  constructor(private userAccountDetails: UserAccountFacade,
    private routingService: RoutingService,
    private authService: AuthService) { }

  canActivate(): Observable<boolean> {
    return this.authService.isUserLoggedIn().pipe(
      switchMap((isLoggedIn) => {
        if (isLoggedIn) {
          return this.userAccountDetails
            .get();

        }
        return of(null);
      }), map((userData: any) => {
        if (userData && userData?.currentB2BUnit?.blockCode?.message) {
          this.routingService.go(['/account-status']);
          return false;
        } else {
          return true;
        }
      })
    );
  }
}
