import { Component, Input } from '@angular/core';
import { OUTLINED_ICON_TYPE } from '@app/models/fff-outline-icons.model';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';
import { FffNewReportsService } from '../../fff-new-reports.service';

@Component({
  selector: 'fff-cancel-recurring-report-drawer',
  templateUrl: './fff-cancel-recurring-report-drawer.component.html',
})
export class FffCancelRecurringReportDrawerComponent {
  @Input()
  reportId!: string;
  iconClose = OUTLINED_ICON_TYPE.ICON_X;
  loading: boolean = false;
  private unsubscribe$ = new Subject<void>();
  constructor(
    private ActiveModalService: NgbActiveModal,
    private newReportService: FffNewReportsService
  ) {}
  ngOnInit() {}

  cancelReport() {
    this.loading = true;
    this.newReportService.cancelRecurringReport(this.reportId).subscribe(
      response => {
        this.loading = false;
        this.newReportService.refreshResults();
        this.close();
      },
      error => {
        this.close();
        console.error('Error:', error);
      }
    );
  }
  close() {
    this.ActiveModalService.dismiss();
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
