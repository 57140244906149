<div class="container" *ngIf="!loading; else loaderTemplate">
  <div>
    <div class="d-flex justify-content-between top-title flex-wrap">
      <div class="d-flex align-items-center flex-wrap">
        <h3 class="pr-4">{{ 'fffInvoiceHistory.title' | cxTranslate }}</h3>
        <button
          class="btn btn-secondary btn-xs"
          (click)="openInvoiceFilterDrawer()"
          [disabled]="loading"
        >
          {{ 'fffInvoiceHistory.filter' | cxTranslate }}
          <cx-icon
            class="cx-icon icon-sm"
            [type]="outlinedIconTypes.ICON_FILTER"
          >
          </cx-icon>
        </button>

        <button
          class="btn btn-secondary btn-xs ml-3"
          (click)="goToReportsPage()"
          [disabled]="loading"
        >
          {{ 'fffOrderHistory.exportReport' | cxTranslate }}
        </button>
      </div>

      <!-- div class="d-flex btn-container">
        <a class="d-flex align-items-center export-btn disabled" (click)="exportCSV()">
          <span>{{ 'fffInvoiceHistory.exportCsv' | cxTranslate }}</span>
          <cx-icon class="cx-icon icon-sm" [type]="outlinedIconTypes.ICON_DOWNLOAD">
          </cx-icon>
        </a>
      </!-->

      <div class="d-flex btn-container mt-3 mt-lg-0 payBtnContainer">
        <div class="payment-btn-ellipse">
          <div class="payment-options-text">
            {{ 'fffInvoiceHistory.paymentOptions' | cxTranslate }}
          </div>
          <div class="card-icon icon-visa-card"></div>
          <div class="card-icon icon-master-card"></div>
          <div class="card-icon icon-discover-card"></div>
          <div class="card-icon icon-amex-card"></div>
        </div>
        <div class="payInvoiceBtn">
          <button
            *ngIf="isPaymentEnabled"
            type="button"
            class="btn btn-primary btn-xs payBtn"
            (click)="openPaymentDialog({ isFromPayBtn: true })"
            [ngClass]="{
              disabledPayInvoiceBtn:
                !selectedInvoices.length || disablePaymentActions
            }"
            [disabled]="!selectedInvoices.length || disablePaymentActions"
          >
            {{
              selectedInvoices && selectedInvoices.length < 2
                ? ('fffInvoiceHistory.actions.payInvoice' | cxTranslate)
                : ('fffInvoiceHistory.actions.payInvoices' | cxTranslate)
            }}
            <ng-container *ngIf="selectedInvoicesTotalAmount > 0">{{
              selectedInvoicesTotalAmount | currency
            }}</ng-container>
          </button>
        </div>
      </div>
    </div>
    <div
      *ngIf="selectedFilters && selectedFilters.length > 0"
      class="filter-tags-list pt-3 d-flex"
    >
      <span
        class="filter-tag-item"
        *ngFor="let item of selectedFilters; let i = index"
      >
        <span>{{ item?.displayValue }}</span>
        <cx-icon
          class="icon-sm calendar-icons"
          [type]="outlinedIconTypes.ICON_X"
          (click)="removeItem(item, i)"
        ></cx-icon>
      </span>
      <span class="clearFiltersText" (click)="clearAllFilters()">{{
        'fffOrderHistory.clear' | cxTranslate
      }}</span>
    </div>
    <hr class="sep-table-title" />
  </div>

  <div>
    <ng-container *ngIf="!invoicesTableState?.loading; else loaderTemplate">
      <fff-table
        [hasPaginator]="true"
        [data]="invoicesTableState"
        [fields]="fields"
        [hasTemplate]="true"
        [mobileSize]="mobileSize"
        [itemTemplate]="invoiceItem"
        [columnsGrid]="columnsGrid"
        (pageChange)="pageEvent($event)"
        (sortChange)="handleSortChange($event)"
        [customClass]="'user-price-list'"
        [isInvoiceHistoryPage]="true"
        [size]="getTableSizeFromPagination(invoicesData)"
        *ngIf="invoicesTableState.total > 0"
      >
      </fff-table>
    </ng-container>
  </div>
</div>

<ng-template #loaderTemplate>
  <fff-table-loader [fields]="'invoice'.split('')"></fff-table-loader>
</ng-template>

<ng-template #invoiceItem let-item="item">
  <div
    class="invoice-history-item td"
    [style.grid-template-columns]="columnsGrid.desktop"
  >
    <div class="pl-0 pr-2 col-6 col-lg-12 order-0 fff-invoice-action-item">
      <div class="col-12 px-0 d-block d-lg-none pb-2 pt-3">
        {{ 'fffInvoiceHistory.invoice' | cxTranslate }}
      </div>
      <div class="d-inline-flex flex-wrap cursor-pointer">
        <span
          class="action-label"
          (click)="goToInvoiceDetails(item.invoiceNumber)"
        >
          {{ item.invoiceNumber }}</span
        >
      </div>
    </div>

    <div class="pl-0 pr-2 col-12 order-1">
      <div class="col-12 px-0 d-block d-lg-none pb-2 pt-3">
        {{ 'fffInvoiceHistory.invoiceDate' | cxTranslate }}
      </div>
      <div>{{ item.invoiceDate | date : 'MM/dd/yyyy' }}</div>
    </div>

    <div class="pl-0 pr-2 col-6 col-lg-12 order-2">
      <div class="col-12 px-0 d-block d-lg-none pb-2 pt-3">
        {{ 'fffInvoiceHistory.invoiceDueDate' | cxTranslate }}
      </div>
      <div>{{ item.dueDate | date : 'MM/dd/yyyy' }}</div>
    </div>

    <div class="pl-0 pr-2 col-6 col-lg-12 order-3">
      <div class="col-12 px-0 d-block d-lg-none pb-2 pt-3">
        {{ 'fffInvoiceHistory.status' | cxTranslate }}
      </div>
      <div>{{ item.invoiceStatus }}</div>
    </div>

    <div class="pl-0 pr-2 col-6 col-lg-12 order-4">
      <div class="col-12 px-0 d-block d-lg-none pb-2 pt-3">
        {{ 'fffInvoiceHistory.po' | cxTranslate }}
      </div>
      <div>{{ item.poNumber }}</div>
    </div>

    <div class="pl-0 pr-2 col-6 col-lg-12 order-4">
      <div class="col-12 px-0 d-block d-lg-none pb-2 pt-3">
        {{ 'fffInvoiceHistory.manufacturer' | cxTranslate }}
      </div>
      <div>
        {{ item.primaryManufacturer }}
        <span *ngIf="!item.surchargeECheckPaymentEligible" class="starMark"
          >*</span
        >
      </div>
    </div>

    <div class="pl-0 pr-2 col-12 d-none d-lg-block order-5">
      <div class="fff-orders-actions-list">
        <div
          class="fff-orders-action-item"
          *ngIf="isPaymentEnabled && item.invoiceStatus === 'OPEN'"
        >
          <div class="checkbox-input-wrap">
            <input
              class="checkbox checkbox-sm"
              type="checkbox"
              [attr.id]="'pay-invoice-checkbox-' + item.invoiceNumber"
              [(ngModel)]="item.selected"
              (ngModelChange)="onInvoicePaySelectionChange($event, item)"
              [disabled]="disablePaymentActions"
            />
            <label
              class="checkbox-label action-label"
              [attr.for]="'pay-invoice-checkbox-' + item.invoiceNumber"
              >{{ 'fffInvoiceHistory.actions.payInvoice' | cxTranslate }}</label
            >
          </div>
        </div>
        <!-- <div class="fff-orders-action-item">
          <div class="d-inline-flex flex-wrap cursor-pointer">
            <span
              class="action-label"
              (click)="goToInvoiceDetails(item.invoiceNumber)"
            >
              {{ 'fffInvoiceHistory.actions.view' | cxTranslate }}</span
            >
          </div>
        </div> -->
      </div>
    </div>

    <div class="col-12 d-flex d-lg-none flex-wrap py-3 order-6">
      <div class="fff-orders-actions-list">
        <div
          class="fff-orders-action-item"
          *ngIf="isPaymentEnabled && item.invoiceStatus === 'OPEN'"
        >
          <div class="checkbox-input-wrap">
            <input
              class="checkbox checkbox-sm"
              type="checkbox"
              [attr.id]="'resend-confirmation-checkbox-' + item.invoiceNumber"
              [(ngModel)]="item.selected"
              (ngModelChange)="onInvoicePaySelectionChange($event, item)"
              [disabled]="disablePaymentActions"
            />
            <label
              class="checkbox-label action-label"
              [attr.for]="'resend-confirmation-checkbox-' + item.invoiceNumber"
              >{{ 'fffInvoiceHistory.actions.payInvoice' | cxTranslate }}</label
            >
          </div>
        </div>
        <div class="fff-orders-action-item">
          <button
            class="col-12 btn btn-tertiary btn-xs"
            (click)="goToInvoiceDetails(item.invoiceNumber)"
          >
            {{ 'fffInvoiceHistory.actions.view' | cxTranslate }}
          </button>
        </div>
      </div>
    </div>

    <div class="px-0 col-6 col-lg-12 order-7" *ngIf="isPaymentEnabled">
      <div class="col-12 px-0 d-block d-lg-none pb-2 pt-3">
        {{ 'fffInvoiceHistory.amount' | cxTranslate }}
      </div>
      <div>{{ item.grandTotal | currency }}</div>
    </div>
  </div>
</ng-template>
