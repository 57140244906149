import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { FffPrebookService } from '@app/fff-enterprise/fff-common-services/fff-prebook.service';
import { OUTLINED_ICON_TYPE } from '@app/models/fff-outline-icons.model';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-fff-prebook-alert-notification-modal',
  templateUrl: './fff-prebook-alert-notification-modal.component.html',
  styleUrls: ['./fff-prebook-alert-notification-modal.component.scss'],
})
export class FffPrebookAlertNotificationModalComponent
  implements OnInit, OnDestroy
{
  loading: boolean = false;
  OUTLINED_ICON_TYPE = OUTLINED_ICON_TYPE;
  season: string = '';

  private unsubscribe$: Subject<void> = new Subject<void>();

  constructor(
    private preBookService: FffPrebookService,
    private activeModalService: NgbActiveModal,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.loading = true;
    this.cd.markForCheck();
    this.preBookService.getAppProperties();
    this.preBookService
      .getSeasonData()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        data => {
          this.season = data;
          this.loading = false;
          this.cd.markForCheck();
        },
        () => {
          this.loading = false;
          this.cd.markForCheck();
        }
      );
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  acknowledge() {
    this.activeModalService.close();
  }
}
