import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FffInvoiceHistoryService } from '@app/fff-enterprise/fff-common-services/fff-invoice-history-service';
import { FFFOrderEntry } from '@app/models/fff-cart-data.model';
import { OUTLINED_ICON_TYPE } from '@app/models/fff-outline-icons.model';
import { ProductService, RoutingService, WindowRef } from '@spartacus/core';
import { MediaContainer } from '@spartacus/storefront';
import { of } from 'rxjs';
import { distinctUntilChanged, map, switchMap } from 'rxjs/operators';
import { getProductImage } from 'src/assets/utils/fff-product-utils';


@Component({
  selector: 'fff-invoice-detail',
  templateUrl: './fff-invoice-detail.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FffInvoiceDetailComponent {

  outlinedIconTypes = OUTLINED_ICON_TYPE;
  showWarning: boolean = true;

  invoiceDetail$ = this.routing.getRouterState().pipe(
    map((routingData) => routingData.state.queryParams.invoiceNumber),
    distinctUntilChanged()
  ).pipe(
    switchMap(
      (invoiceNumber) => {
        if (invoiceNumber) {
          return this.fffInvoiceHistoryService.getInvoiceHistoryDetail(invoiceNumber);
        } else {
          return of({});
        }
      })
  );

  constructor(
    private fffInvoiceHistoryService: FffInvoiceHistoryService,
    protected routing: RoutingService,
    public windowRef: WindowRef,
    private activatedRoute: ActivatedRoute,
    private productService: ProductService
  ) {
    let currentPage = 0;
    if (this.activatedRoute.snapshot.queryParams['page']) {
      currentPage = this.activatedRoute.snapshot.queryParams['page'];
    }

    history.pushState(null, '', location.href);
    window.onpopstate = function () {
      history.pushState(null, '', location.href);
      window.location.href = `/my-account/invoices?page=${currentPage}`;
    };
  }

  getEntries(orderDetail: any): FFFOrderEntry[] {
    return orderDetail.entries;
  }

  getProduct(product: any) {
    return this.productService.get(product, 'BASIC');
  }

  getData(product: any) {
    return getProductImage(product.images) as MediaContainer;
  }

  downloadPDF(invoiceNumber: string): void {
    if (this.windowRef.isBrowser()) {
      this.windowRef.nativeWindow?.open(`${this.fffInvoiceHistoryService.getInvoiceDownloadPDF()}?code=${invoiceNumber}`, '_blank');
    }
  }

}


