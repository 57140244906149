import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { OUTLINED_ICON_TYPE } from 'src/app/models/fff-outline-icons.model';


@Component({
  selector: 'fff-saved-cart-message-popup',
  templateUrl: './fff-saved-cart-message-popup.component.html'
})
export class FFFSavedCartMessagePopupComponent {

  outlinedIconTypes = OUTLINED_ICON_TYPE;

  constructor(
    private modalService: NgbActiveModal
  ) { }

  close() {
    this.modalService.close();
  }

}
