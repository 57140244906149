<ng-template #thumb let-item="item">
  <cx-media
    [container]="item.container"
    tabindex="0"
    (focus)="openImage(item.container)"
    [class.is-active]="isActive(item.container) | async"
    format="product"
  >
  </cx-media>
</ng-template>

<div class="col-12 d-flex flex-wrap my-3 px-0">
  <div class="col-12 col-lg-2 py-3 order-1 order-lg-0">
    <ng-container *ngIf="thumbs$ | async as thumbs">
      <cx-carousel
        *ngIf="thumbs.length"
        class="thumbs d-none d-lg-block product-carousel"
        [ngClass]="{
          'product-carousel--sold-out': isProductSoldOut
        }"
        [items]="thumbs"
        itemWidth="20%"
        [hideIndicators]="true"
        [template]="thumb"
      ></cx-carousel>

      <cx-carousel
        *ngIf="thumbs.length"
        class="thumbs d-block d-lg-none product-carousel"
        [ngClass]="{
          'product-carousel--sold-out': isProductSoldOut
        }"
        [items]="thumbs"
        itemWidth="25%"
        [hideIndicators]="true"
        [template]="thumb"
      ></cx-carousel>
    </ng-container>
  </div>

  <ng-container *ngIf="mainImage$ | async as main">
    <cx-media
      class="col-lg-10 px-0 product-image d-flex order-0 order-lg-1"
      [ngClass]="{
        'product-image--sold-out': isProductSoldOut
      }"
      [container]="main"
    ></cx-media>
  </ng-container>
</div>
