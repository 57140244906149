<div class="container">
  <div class="row register-container col-xs-12 col-md-8 d-flex justify-content-center align-items-center m-auto px-0">
    <cx-spinner class="overlay" *ngIf="isUpdating$ | async"></cx-spinner>
    <div class="col-md-12 d-flex justify-content-center align-items-center register-header register-background-content">
      <div>
        <h3 class="h3">{{ 'fffForgotPassword.header' | cxTranslate }}</h3>
      </div>
    </div>

    <div class="col-md-12 register-content">
      <form (ngSubmit)="onSubmit()" [formGroup]="form">

        <div class="col-xs-12 col-md-9 m-auto">
          <label>
            <span class="label-content">{{
              'fffForgotPassword.forgotPasswordForm.emailAddress' | cxTranslate
              }}</span>
            <input class="form-control form-control-lg" id="userEmail" aria-required="true" type="email"
              formControlName="userEmail" />
            <cx-form-errors [control]="$any(form).get('userEmail')"></cx-form-errors>
          </label>

          <div class="form-actions py-0">

            <button type="submit" class="btn btn-primary btn-md" [disabled]="form.disabled">
              {{ 'fffForgotPassword.submit' | cxTranslate }}
            </button>

          </div>
        </div>

      </form>
    </div>

  </div>

</div>