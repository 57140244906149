import { NgModule } from '@angular/core';
import {
  asmTranslationChunksConfig,
  asmTranslations,
} from '@spartacus/asm/assets';
import { ASM_FEATURE, AsmRootModule } from '@spartacus/asm/root';
import { CmsConfig, I18nConfig, provideConfig } from '@spartacus/core';
import { LayoutConfig } from '@spartacus/storefront';
import { FffAsmComponentsModule } from './components/asm-components/asm-components.module';
import { FffAsmMainUiComponent } from './components/asm-components/asm-main-ui/asm-main-ui.component';

@NgModule({
  declarations: [],
  imports: [AsmRootModule, FffAsmComponentsModule],
  providers: [
    provideConfig(<CmsConfig>{
      featureModules: {
        [ASM_FEATURE]: {
          module: () => import('@spartacus/asm').then(m => m.AsmModule),
        },
      },
    }),
    provideConfig(<I18nConfig>{
      i18n: {
        resources: asmTranslations,
        chunks: asmTranslationChunksConfig,
      },
    }),
    provideConfig(<LayoutConfig>{
      launch: {
        ASM: {
          outlet: 'cx-storefront',
          component: FffAsmMainUiComponent,
        },
      },
    }),
  ],
})
export class AsmFeatureModule {}
