import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import {
  NgbAccordionModule,
  NgbCollapseModule,
  NgbPopoverModule
} from '@ng-bootstrap/ng-bootstrap';
import { I18nModule } from '@spartacus/core';
import { IconModule } from '@spartacus/storefront';
import { FffTableComponent } from './fff-table.component';

@NgModule({
  declarations: [
    FffTableComponent
  ],
  exports: [FffTableComponent],
  imports: [
    CommonModule,
    NgbPopoverModule,
    IconModule,
    NgbCollapseModule,
    NgbAccordionModule,
    I18nModule,
  ],
})
export class FffTableModule { }
