import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { FFFLoadersModule } from '@app/shared/components/fff-loaders/fff-loaders.module';
import { NgbModalModule } from '@ng-bootstrap/ng-bootstrap';
import {
  CartCouponModule,
  CartSharedModule,
  CartValidationWarningsModule,
} from '@spartacus/cart/base/components';
import { FeaturesConfigModule, I18nModule, UrlModule } from '@spartacus/core';
import {
  IconModule,
  ItemCounterModule,
  MediaModule,
  OutletModule,
  ProgressButtonModule,
  PromotionsModule,
} from '@spartacus/storefront';
import { FffProductModule } from '../fff-product/fff-product.module';
import { FffEntrySimpleComponent } from './fff-entry-simple/fff-entry-simple.component';

@NgModule({
  declarations: [FffEntrySimpleComponent],
  imports: [
    UrlModule,
    BrowserModule,
    CommonModule,
    I18nModule,
    MediaModule,
    IconModule,
    ProgressButtonModule,
    RouterModule,
    CartSharedModule,
    CartCouponModule,
    PromotionsModule,
    FeaturesConfigModule,
    CartValidationWarningsModule,
    FffProductModule,
    FFFLoadersModule,
    ItemCounterModule,
    NgbModalModule,
    OutletModule,
  ],
  exports: [FffEntrySimpleComponent],
})
export class FffEntryModule {}
