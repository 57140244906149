import { ChangeDetectionStrategy, Component, OnDestroy } from '@angular/core';
import { FffProductService } from '@app/fff-enterprise/fff-common-services/fff-product.service';
import { FffProfile } from '@app/models/fff-profile.model';
import { FffUserAccountService } from '@app/shared/services/fff-user-account.service';
import { User, isNotNullable } from '@spartacus/core';
import { CurrentProductService } from '@spartacus/storefront';
import { UserAccountService } from '@spartacus/user/account/core';
import { EMPTY, Observable, Subject } from 'rxjs';
import { filter, map, switchMap, takeUntil, tap } from 'rxjs/operators';

@Component({
  selector: 'fff-product-intro',
  templateUrl: './fff-product-intro.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FffProductIntroComponent implements OnDestroy {

  product$: Observable<any> = this.currentProductService
    .getProduct('DEFAULT')
    .pipe(
      filter(isNotNullable),
      switchMap((product) => {
        if (!product?.code) {
          return EMPTY;
        }
        return this.fffProductService.getPricesData(product.code).pipe(
          map((pricesData) => ({
            ...product,
            pricesData
          }))
        );
      })
    );

  user$: Observable<User | undefined> = this.userAccountService.get();
  private unsubscribe$: Subject<void> = new Subject<void>();
  profile: FffProfile | undefined;
  profile$: Observable<FffProfile | undefined> = this.fffAccountService.getProfile().pipe(
    takeUntil(this.unsubscribe$),
    tap(user => this.profile = user),
  );

  constructor(
    protected currentProductService: CurrentProductService,
    public userAccountService: UserAccountService,
    private fffAccountService: FffUserAccountService,
    private fffProductService: FffProductService
  ) { 

    this.profile$.pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

}