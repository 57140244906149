import {
  CATEGORIES_SEO_CODE_MAPPING,
  PREBOOK_CATEGORIES_SEO_CODE_MAPPING,
} from './fff-category-codes.config';

export const getCategoryCodeByName = (name: string): string => {
  const obj = {
    ...CATEGORIES_SEO_CODE_MAPPING,
    ...PREBOOK_CATEGORIES_SEO_CODE_MAPPING,
  };
  return obj[name] || '';
};
