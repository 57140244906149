<div class="searchbox-container">
  <label class="searchbox" [class.dirty]="!!searchInput.value">
    <div class="search-input d-flex position-relative">
      <input
        class="form-control form-control-md"
        #searchInput
        [placeholder]="'fffSearch.placeholder' | cxTranslate"
        autocomplete="off"
        aria-describedby="initialDescription"
        aria-controls="results"
        [attr.aria-label]="'common.search' | cxTranslate"
        (focus)="open()"
        (click)="open()"
        (input)="search(searchInput.value)"
        (blur)="close($any($event))"
        (keydown.escape)="close($any($event))"
        (keydown.enter)="
          close($any($event), true);
          launchSearchResult($any($event), searchInput.value);
          updateChosenWord(searchInput.value)
        "
        (keydown.arrowup)="focusPreviousChild($any($event))"
        (keydown.arrowdown)="focusNextChild($any($event))"
        value="{{ chosenWord }}"
      />

      <button
        [attr.aria-label]="'common.reset' | cxTranslate"
        (mousedown)="clear(searchInput)"
        (keydown.enter)="clear(searchInput)"
        class="reset"
      >
        <cx-icon [type]="outlinedIconTypes.ICON_X"></cx-icon>
        {{ 'fffSearch.clear' | cxTranslate }}
      </button>

      <button
        [attr.aria-label]="'common.search' | cxTranslate"
        class="search-icon"
        (click)="open()"
      >
        <cx-icon
          class="cx-icon icon-lg"
          [type]="outlinedIconTypes.ICON_SEARCH"
        ></cx-icon>
      </button>
      <button
        class="active-search-btn btn btn-primary btn-xs align-self-center"
        (mousedown)="preventDefault($event)"
        (click)="
          close($any($event), true);
          launchSearchResult($any($event), searchInput.value);
          updateChosenWord(searchInput.value)
        "
      >
        {{ 'common.search' | cxTranslate }}
        <cx-icon
          class="cx-icon icon-sm"
          [type]="outlinedIconTypes.ICON_ARROW_RIGHT"
        ></cx-icon>
      </button>
    </div>
  </label>
</div>

<div
  *ngIf="results$ | async as result"
  class="results"
  id="results"
  (click)="close($any($event), true)"
  role="listbox"
>
  <p *ngIf="result.message" class="noItemsMessage">
    {{ 'fffSearch.noResults' | cxTranslate }}
  </p>

  <ul
    class="suggestions"
    attr.aria-label="{{ 'searchBox.ariaLabelSuggestions' | cxTranslate }}"
    tabindex="0"
  >
    <li *ngFor="let suggestion of result.suggestions">
      <a
        [innerHTML]="suggestion | cxHighlight : searchInput.value"
        (click)="selectSuggestion($event)"
        [routerLink]="
          {
            cxRoute: 'search',
            params: { query: suggestion }
          } | cxUrl
        "
        (keydown.arrowup)="focusPreviousChild($any($event))"
        (keydown.arrowdown)="focusNextChild($any($event))"
        (keydown.enter)="close($any($event), true)"
        (keydown.escape)="close($any($event), true)"
        (blur)="close($any($event))"
        (mousedown)="preventDefault($any($event))"
      >
      </a>
    </li>
  </ul>

  <div
    class="products-title"
    *ngIf="result.products"
  >
    {{ 'fffSearch.products' | cxTranslate }}
  </div>

  <p *ngIf="result.products && result.products.length === 0" class="noItemsMessage">
    {{ 'fffSearch.noResults' | cxTranslate }}
  </p>

  <ul
    class="products"
    *ngIf="result.products && result.products.length > 0"
    attr.aria-label="{{ 'searchBox.ariaLabelProducts' | cxTranslate }}"
    tabindex="0"
  >
    <li *ngFor="let product of result.products">
      <div
        class="search-result-card d-flex"
        [routerLink]="getUrlForProduct(product)"
        [class.has-media]="config.displayProductImages"
        (keydown.arrowup)="focusPreviousChild($any($event))"
        (keydown.arrowdown)="focusNextChild($any($event))"
        (keydown.enter)="close($any($event), true)"
        (keydown.escape)="close($any($event), true)"
        (blur)="close($event)"
        (mousedown)="preventDefault($event)"
        (click)="
          dispatchProductEvent({
            freeText: searchInput.value,
            productCode: product.code
          })
        "
      >
        <cx-media
          *ngIf="config.displayProductImages"
          [container]="product.images?.PRIMARY"
          format="thumbnail"
          role="presentation"
          class="thumbnail"
        ></cx-media>
        <div class="info">
          <div class="name" [innerHTML]="product.nameHtml"></div>
          <div class="ndc">
            {{ 'fffSearch.ndc' | cxTranslate }} {{ product.ndc }}
          </div>
        </div>
      </div>
    </li>
  </ul>
  <div
    class="see-all d-flex justify-content-end"
    *ngIf="result.products && result.products.length > 0"
    (mousedown)="preventDefault($any($event))"
    (click)="
      close($any($event), true);
      launchSearchResult($any($event), searchInput.value);
      updateChosenWord(searchInput.value)
    "
  >
    <span>{{ 'fffSearch.seeAll' | cxTranslate }}</span>
    <cx-icon [type]="outlinedIconTypes.ICON_ARROW_RIGHT"></cx-icon>
  </div>
  <span id="initialDescription" class="cx-visually-hidden">
    {{ 'searchBox.initialDescription' | cxTranslate }}
  </span>
  <div
    *ngIf="result.suggestions?.length || result.products?.length"
    aria-live="assertive"
    class="cx-visually-hidden"
  >
    {{
      'searchBox.suggestionsResult'
        | cxTranslate : { count: result.suggestions?.length }
    }}
    {{
      'searchBox.productsResult'
        | cxTranslate : { count: result.products?.length }
    }}
    {{ 'searchBox.initialDescription' | cxTranslate }}
  </div>
</div>
