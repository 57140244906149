import { HttpUrlEncodingCodec } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FffGtmEventService } from '@app/spartacus/features/tracking/gmt/event';

import { isPrebookEnabledCategory } from '@app/fff-enterprise/fff-prebook-category/services/fff-prebook-category.utils';
import { FffApplicationConfigService } from '@app/shared/services/fff-application-config.service';
import { Store } from '@ngrx/store';
import {
  AuthService,
  FacetValue,
  PRODUCT_FEATURE,
  PageType,
  ProductSearchService,
  ProductsState,
  RoutingService,
  StateWithProduct,
} from '@spartacus/core';
import { BehaviorSubject, Observable, Subscription, combineLatest } from 'rxjs';
import { filter, map, take, tap } from 'rxjs/operators';
import { FffFacet } from 'src/app/models/fff-facet.model';
import { OUTLINED_ICON_TYPE } from 'src/app/models/fff-outline-icons.model';
import { FffSearchBreadcrumb } from 'src/app/models/fff-product.model';
import { FffDrawerService } from 'src/app/shared/drawer/fff-drawer.service';
import { FffProductListComponentService } from '../fff-product-list-component.service';
import { FffFacetFilterDrawerBodyComponent } from '../fff-product-list/fff-facet-filter-drawer/fff-facet-filter-drawer-body.component';
import { FffFacetFilterDrawerHeaderComponent } from '../fff-product-list/fff-facet-filter-drawer/fff-facet-filter-drawer-header.component';

@Component({
  selector: 'fff-product-list-refinement',
  templateUrl: './fff-product-list-refinement.component.html',
})
export class FffProductListRefinementComponent implements OnInit, OnDestroy {
  private sub: Subscription = new Subscription();
  activeFacetValueCode: string = '';
  initialQuery: string = '';
  isCollapsed = true;
  protected queryCodec: HttpUrlEncodingCodec;
  searchResult$!: Observable<ProductsState>;
  filtersSelected!: FffSearchBreadcrumb[];
  visibleFacets$!: Observable<FffFacet[]>;
  productState$: Observable<ProductsState>;
  facets: FffFacet[] = [];
  isOpenFilter$ = this.productListComponentService.isFilterOpen$;

  outlinedIconTypes = OUTLINED_ICON_TYPE;
  showSortOption: boolean = false;
  initialPrebookCategoryQuery: string = '';

  total$: Observable<number | undefined> = this.productSearchService
    .getResults()
    .pipe(
      filter(data => !!data?.pagination),
      map(results => results.pagination?.totalResults)
    );

  isPrebookCategoryPage$ = this.routingService.getRouterState().pipe(
    filter(value => !!value.state?.context?.id),
    tap(value => {
      this.initialPrebookCategoryQuery =
        this.productListComponentService.getPrebookCategoryQuery(
          value.state?.context.id,
          value.state?.queryParams
        );
    }),
    map(
      value =>
        value.state.context.type === PageType.CATEGORY_PAGE &&
        isPrebookEnabledCategory(value.state.queryParams)
    )
  );
  applicationProperties$ =
    this.applicationConfigService.getApplicationProperties();
  isPrebookFormEnabled$ = combineLatest([
    this.applicationProperties$.pipe(
      map(value => !!value?.mfvRespiratoryPrebookFormEnabled)
    ),
    this.isPrebookCategoryPage$,
  ]).pipe(
    map(
      ([mfvRespiratoryPrebookFormEnabled, isPrebookCategoryPage]) =>
        mfvRespiratoryPrebookFormEnabled && isPrebookCategoryPage
    )
  );
  showActions$ = this.isPrebookFormEnabled$.pipe(map(enabled => !enabled));
  private hiddenPrebookFacets: string[] = ['Season', 'Vaccine Type'];

  constructor(
    private activatedRoute: ActivatedRoute,
    private productListComponentService: FffProductListComponentService,
    private store: Store<StateWithProduct>,
    private drawerService: FffDrawerService,
    protected productSearchService: ProductSearchService,
    protected fffGtmEventService: FffGtmEventService,
    private applicationConfigService: FffApplicationConfigService,
    private routingService: RoutingService,
    private authService: AuthService
  ) {
    this.queryCodec = new HttpUrlEncodingCodec();
    this.productState$ = this.store.select(PRODUCT_FEATURE);
  }

  ngOnInit(): void {
    this.sub = this.activatedRoute.params.subscribe(params => {
      this.activeFacetValueCode =
        params.categoryCode || params.brandCode || 'Refurbished';
    });

    this.searchResult$ = this.productState$.pipe(
      tap(state => {
        let searchResult = state.search?.results;
        // set initial query (for clear all functionality) - breadcrumbs first value is category or brand
        if (searchResult?.breadcrumbs) {
          this.initialQuery = this.getInitialQuery(searchResult.breadcrumbs[0]);
          this.filtersSelected = searchResult.breadcrumbs;
        }
        // updating expanded for collapsible menu
        searchResult?.facets?.map(facet => ({
          ...facet,
          expanded: false,
        }));
      })
    );

    this.visibleFacets$ = combineLatest([
      this.searchResult$,
      new BehaviorSubject(false),
      this.isPrebookFormEnabled$,
      this.authService.isUserLoggedIn(),
    ]).pipe(
      map(
        ([
          searchResult,
          hideCategoryFacet,
          isPrebookFormEnabled,
          isUserLoggedIn,
        ]) => {
          const facets = searchResult.search.results.facets || [];

          return facets.filter(facet => {
            if (hideCategoryFacet && facet.category) {
              return false;
            }

            if (
              isUserLoggedIn &&
              isPrebookFormEnabled &&
              facet.name &&
              this.hiddenPrebookFacets.includes(facet.name)
            ) {
              return false;
            }

            return (
              facet.visible &&
              facet.name !== this.activeFacetValueCode &&
              facet.name !== 'allCategories'
            );
          });
        }
      )
    );

    this.sub.add(
      this.visibleFacets$.subscribe(data => {
        this.facets = data.map(dataInfo => ({
          ...dataInfo,
          expanded: true,
          showMore: false,
          moreFiveItems: dataInfo.values && dataInfo.values?.length > 5,
        }));
        this.facets = this.facets.map((facet, i) => ({
          ...facet,
          expanded: i > 2,
        }));
      })
    );
  }

  getInitialQuery(breadcrumb: FffSearchBreadcrumb): string {
    if (
      breadcrumb?.truncateQuery?.query?.value &&
      breadcrumb?.removeQuery?.query
    ) {
      return breadcrumb.truncateQuery
        ? breadcrumb.truncateQuery.query.value
        : breadcrumb.removeQuery.query.value +
            ':' +
            breadcrumb.facetCode +
            ':' +
            breadcrumb.facetValueCode;
    } else {
      return '';
    }
  }

  toggleValue(query: string | undefined): void {
    this.fffGtmEventService.registerFacetChangedEvent();
    this.productListComponentService.setQuery(
      this.queryCodec.decodeValue(query!)
    );
  }

  clearFacets() {
    this.isPrebookFormEnabled$
      .pipe(
        take(1),
        tap(isPrebookFormEnabled => {
          if (isPrebookFormEnabled) {
            this.toggleValue(this.initialPrebookCategoryQuery);
          } else {
            this.toggleValue(this.initialQuery);
          }
        })
      )
      .subscribe();
    return false;
  }

  ngOnDestroy() {
    if (this.sub) {
      this.sub.unsubscribe();
    }
  }

  toggleFilter(filterValue: boolean) {
    this.productListComponentService.toggleFilterDisplayInMobile(filterValue);
  }

  isExpanded(value: boolean | undefined): boolean {
    if (value) return value;
    return false;
  }

  getFacetValues(
    values: FacetValue[],
    showMore: boolean,
    moreFiveItems: boolean
  ): FacetValue[] {
    if (moreFiveItems) {
      return showMore ? values : values.slice(0, 5);
    } else {
      return values;
    }
  }

  openDrawer() {
    this.drawerService.setContent({
      drawerHeader: FffFacetFilterDrawerHeaderComponent as Component,
      animation: 'SideRTL',
      component: FffFacetFilterDrawerBodyComponent as Component,
      class: 'face-filter-drawer-content',
    });
    this.drawerService.openDrawer();
  }
}
