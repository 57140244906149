export const fffPrebook = {
  previousQty: 'Previous Year Quantity',
  suggestedQty: 'Suggested Quantity',
  flu: 'Influenza',
  covid: 'COVID-19',
  rsv: 'RSV',
  rsvRecommendationLabel: 'No RSV Vaccine On Order For The Upcoming Season',
  belowRecommendedQty: 'Below Suggested Quantity',
  belowPreviousSeasonQty: 'Below Previous Season Quantity',
  orderSummary: 'Order Summary',
  exitConfirmationModal: {
    title: 'Warning',
    messageSplit1:
      ' Are you sure you want to leave your order? If you wish to continue your',
    messageSplit2: 'order later, Click the “Place your ',
    messageSplit3: 'Respiratory Order” button on the homepage',
    leave: 'Leave',
    stay: 'Stay',
  },
  orderSummaryTotalLabel: 'Total On Order For',
  todaysOrder: "Today's Order",
  seasonQty: 'Season Quantity',
  totalOrder: 'Total Order',
  season: 'Season',

  fffPrebook: {
    acknowledgeAndProceed: 'Acknowledge and Proceed',
    enterNotificationMessage:
      'Please complete your current order or empty your cart before placing your {{ season }} order.',
    acipRecommendation: '2023 ACIP Recommendation',
    skipToCheckout: 'Skip to Checkout',
    checkout: 'Checkout',
    addToCartContinue: 'Add To Cart & Continue',
  },
};
