export const fffProduct = {
  fffProduct: {
    addToCart: 'Add To Cart',
    orderNow: 'Order Now',
    soldOut: 'Sold Out',
    lblProductDetailHeading: 'Product Details',
    headingNdcInfo: 'NDC Information',
    headingPrescribingInfo: 'Prescribing Information',
    headingRightNowInv: 'RightNow Inventory Program',
    headingIndications: 'Indications',
    headingShippingDetails: 'Shipping and Return details',
    descShippingDetails:
      'Most products are shipped overnight when ordered by 12 pm PT. All products are non-returnable.',
    noDataAvailable: 'No data Available for',
    lblNdcDrugForm: 'NDC Drug Form: ',
    lblNdcStrength: 'NDC Strength: ',
    lblNdcDrugRoute: 'NDC Drug Route: ',
    lblNdcName: 'NDC Name: ',
    lblAnnualCommitment: 'Annual Commitment: ',
    lblPurchased: 'Purchased: ',
    lblPrescribingInfoBtn: 'Prescribing Information',
    headingInvProgram: 'RightNow Inventory Program',
    descInvProgram:
      'This is an eligible product for the Verified Inventory Program (VIPc). To add this item to your list of VIPc products, contact your FFF Sales Representative. To learn more and/or get started with the program, click here or call (800) 843-7477.',
    modal: {
      header: 'Need More?',
      PGXSoftAllocationMessage: `<p>This PGX item is on soft allocation. If you are needing more than your monthly commitment the system will allow you to place what you need now.</p>
      <p>Please note whatever is ordered over your monthly commitment will be deducted from your remaining annual total, thereby reducing your remaining monthly allocation quantities for the rest of the year.</p>`,
      PGXHardAllocationMessage: `<p>This PGX item is on hard allocation from the manufacturer.</p>
      <p>If you are needing more than your monthly commitment, please contact your FFF Sales Representative to see if your need can be met through “interactive allocations”
      (shifting the allocation from another member in your system who has not used their monthly allocation).</p>`,
      softAllocationMessage:
        '<p>This item is on soft allocation.<br>If you need more than your monthly commitment the system will allow you to place what you need now. Please note whatever is ordered over your monthly commitment will be deducted from your remaining annual total, thereby reducing your remaining monthly allocation quantities for the rest of the year.</p>',
      hardAllocationMessage:
        '<p>This item is on hard allocation from the manufacturer. If you need more than your monthly commitment, please contact your FFF Sales Representative to see if your need can be met through “interactive allocations” (shifting the allocation from another member in your system who has not used their monthly allocation).</p>',
    },
    badge: {
      manualOnly: 'Not Eligible to Run Through Split Biller',
      contractProduct: 'Contract Product',
      provideGX: 'ProvideGX',
    },
    lblNdc: 'NDC',
    lblSku: 'SKU',
    lblBatch: 'Batch',
    lblStrength: 'Strength',
    lblAccount: 'Account',
    basePrice: 'Base Price:',
    lblAccountAutoSplitBiller: 'auto-split biller',
    lblWarehouse: 'Warehouse',
    lblProductDesc: 'to see pricing and order.',
    lblNoAccount: 'Need a Biosupply Account?',
    lblSortBy: 'Sort By',
    lblRegisterNow: 'Register Now',
    addedToCart: 'Added to Cart.',
    lblAdminSet: 'Admin Set',
    lblYconnector: 'Y - Connector',
    lblFedexTax: 'Excl. Fed Excise Tax',
    lblCurrencyMeasure: '10ea = 5eu',
    lblInStock: 'In Stock',
    lblOnDemand: 'On Demand',
    lblInTransit: 'OUT OF STOCK',
    lblDropShip: 'Drop Ship',
    lblReserveNow: 'Reserve Now',
    lblQty: 'Qty',
    lblWac: 'WAC',
    lbl340b: '340B',
    lblContract: 'Contract Price',
    lblNeedMore: 'Need More?',
    lblRemaining: 'Remaining',
    hardAlloc: 'Hard Allocation',
    softAlloc: 'Soft Allocation',
    favorite: 'Favorite',
    addedToFavorites: 'Added to Favorites',
    removedFromFavorites: 'Removed from Favorites',
    addedProductToFavoritesMessage: `<p>You have added {{ productName }} to your Favorites <br><br>
        You can find these products under favorites in the main menu.</p>`,
    removedProductFromFavoritesMessage: `<p>You have removed {{ productName }} from your Favorites <br><br>
        You can find these products under favorites in the main menu.</p>`,
    findFavoritesMessage:
      'You can find these products under favorites in the main menu.',
    lblOutOfStockNote: 'You can still place your order now, replenishment inventory coming soon.',
    accountType: '340B',
    details: {
      annualCommitment: 'Annual Commitment',
      purchased: 'Purchased',
      ndcInfo: 'NDC Info',
      rightNowInventoryProgram: 'RightNow Inventory Program',
      indicators: 'Indicators',
      shippingandReturnDetails: 'Shipping and Return Details',
      prescribingInformation: 'Prescribing Information',
    },
    orderMultiples: 'You can only order in multiples of ',
    thisProduct: ' for this product',
    orderMinimum:
      'Please order Minimum Order Qty of at least {{ min }} {{ uom }}',
    qtyMultipleMdu: 'Please, the qty entered must be multiple of {{ mdu }}',
    exceedMaximumQtyAvailable:
      'You exceeded the quantity available of {{ max }} of this strength',
    quantityNotAllowed: 'Please select Product {{ reason }}',
    exceedsAlloc: 'You have exceeded your allocation for this product',
    differentFlu:
      'Please note: Cart can only contain products from one respiratory season at a time.',
    alloc: 'Allocation',
    remaining: 'Remaining',
    noLabel: '',
    actualStrength: 'Actual Strength',
    expirationDate: 'Expiration Date',
    exp: 'Exp',
    somQuestionnaireExpired: 'Required SOM Questionnaire expired',
    cdsLicenseInvalid: 'CDS License Invalid',
    showTieredPricing: 'Show Tiered Pricing',
    orderLimit: 'Order Limit',
    acknowledgeAndProceed: 'Acknowledge and Proceed',
    learnMore: 'Learn More',
    productNotOrderable: {
      text1:
        'Please note this item is not part of your accounts formulary and is therefore registered as a non-preferred item for your acct and/or system. If approved to order as an exception to this rule, please contact our WOW! Customer Care team at (800) 843-7477 to place your order.',
      text2: '',
    },
    influenza: 'Influenza',
    COVID: 'COVID-19',
    RSV: 'RSV',
    orderSummary: 'Order Summary',
    recommendedProducts: 'Suggested Products',
    viewProduct: 'View Product',
    unableToLoad: 'Unable to load the data,',
    pleaseTryLater: 'please try again later',
  },
};

export const fffStrength = {
  fffStrength: {
    defaultOption: 'Select Actual Strength',
  },
};

export const fffSpecialPrice = {
  fffSpecialPrice: {
    defaultOption: 'Select a Batch',
    specialPriceTitle: 'Short-Dated Batch - Less than {{ rangeDays }} Days',
    shortDated: 'Special Pricing',
    available: 'available',
  },
};

export const fffShortDated = {
  fffShortDated: {
    title: 'This is a Short-Dated Product',
    text1:
      'This is a short-dated product and not returnable. Selecting proceed acknowledges you have read this message.',
    text2:
      '<p>If you have questions or concerns, contact customer support using <a class="live-chat cursor-pointer text-underline">Live Chat</a> or by calling (800) 843-7477</p>',
    action: 'Acknowledge and proceed',
  },
};
