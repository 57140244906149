import { Component } from '@angular/core';
import { OUTLINED_ICON_TYPE } from '@app/models/fff-outline-icons.model';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'fff-invoice-payment-failed-dialog',
  templateUrl: './fff-invoice-payment-failed-dialog.component.html',
  styleUrls: ['./fff-invoice-payment-failed-dialog.component.scss'],
})
export class FffInvoicePaymentFailedDialogComponent {
  outlinedIconTypes = OUTLINED_ICON_TYPE;

  constructor(private activeModalService: NgbActiveModal) {}

  useDifferentCard() {
    this.activeModalService.close({
      useDifferentCard: true,
    });
  }
}
