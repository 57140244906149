import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard, NotAuthGuard } from '@spartacus/core';
import { CmsPageGuard, PageLayoutComponent } from '@spartacus/storefront';
import { ProductGuard } from './fff-enterprise/fff-addons/product.guard';
import { FffContactUsComponent } from './fff-enterprise/fff-contact-us/fff-contact-us/fff-contact-us.component';
import { FffCsosStatusComponent } from './fff-enterprise/fff-csos-status/fff-csos-status/fff-csos-status.component';
import { FffAccountConfirmationComponent } from './fff-enterprise/fff-login/fff-account-confirmation/fff-account-confirmation.component';
import { FffAccountVerificationComponent } from './fff-enterprise/fff-login/fff-account-verification/fff-account-verification.component';
import { FffRegisterOneComponent } from './fff-enterprise/fff-login/fff-register-one/fff-register-one.component';
import { FffNavigationBarComponent } from './fff-enterprise/fff-navigation-bar/fff-navigation-bar/fff-navigation-bar.component';
import { FffForgotPasswordConfirmationComponent } from './fff-enterprise/fff-password/fff-forgot-password-confirmation/fff-forgot-password-confirmation.component';
import { FffPaymentsBridgeComponent } from './fff-enterprise/fff-payments/fff-payments-bridge/fff-payments-bridge.component';
import { FFFProductExclusionGuard } from './fff-enterprise/fff-product-detail/fff-product-exclusion.guard';
import { FffQuickorderStatusComponent } from './fff-enterprise/fff-quick-order/quickorder-status/quickorder-status.component';
import { FffSsoBridgePageComponent } from './fff-enterprise/fff-sso/fff-sso-bridge-page/fff-sso-bridge-page.component';
import { FffSsoGuard } from './fff-enterprise/fff-sso/fff-sso.guard';
import { FffPrebookPagesGuard } from './shared/services/fff-prebook-pages.guard';

// Public routes should be added (only internals can added here)
export const routes: Routes = [
  {
    path: 'styleguide',
    loadChildren: () =>
      import('./supplement/styleguide/styleguide.module').then(
        mod => mod.StyleguideModule
      ),
  },
  {
    path: 'static-pages',
    loadChildren: () =>
      import('./fff-enterprise/fff-error-pages/fff-error-pages.module').then(
        mod => mod.FffErrorPagesModule
      ),
  },
  {
    path: 'my-account/return/:returnCode',
    component: PageLayoutComponent,
    canActivate: [CmsPageGuard],
  },
  {
    path: 'my-account/recurring-order/:cartCode',
    component: PageLayoutComponent,
    canActivate: [CmsPageGuard],
  },
  {
    path: 'my-account/recurring-order/:cartCode/edit',
    component: PageLayoutComponent,
    canActivate: [CmsPageGuard],
  },
  {
    path: 'register-account',
    component: FffRegisterOneComponent,
    canActivate: [CmsPageGuard],
  },
  {
    path: 'register-verification',
    component: FffAccountVerificationComponent,
    canActivate: [CmsPageGuard, NotAuthGuard],
  },
  {
    path: 'register-confirmation',
    component: FffAccountConfirmationComponent,
    canActivate: [CmsPageGuard],
  },
  {
    path: 'forgot-password-confirmation',
    component: FffForgotPasswordConfirmationComponent,
    canActivate: [CmsPageGuard],
  },
  {
    path: 'account-status',
    canActivate: [CmsPageGuard],
    component: FffNavigationBarComponent,
  },
  {
    path: 'Open-Catalogue',
    canActivate: [CmsPageGuard, ProductGuard],
    component: PageLayoutComponent,
    data: { cxRoute: 'category' },
  },
  {
    path: 'product',
    canActivate: [CmsPageGuard, ProductGuard, FFFProductExclusionGuard],
    component: PageLayoutComponent,
    data: { cxRoute: 'product' },
  },
  {
    path: 'contact',
    component: FffContactUsComponent,
    canActivate: [CmsPageGuard],
  },
  {
    path: 'csos-status',
    component: FffCsosStatusComponent,
    canActivate: [CmsPageGuard],
  },
  {
    path: 'my-account/quickorder-status',
    component: FffQuickorderStatusComponent,
    canActivate: [CmsPageGuard],
  },
  {
    path: 'my-account/reports',
    component: PageLayoutComponent,
    canActivate: [CmsPageGuard, AuthGuard],
  },
  {
    path: 'my-account/new-reports',
    component: PageLayoutComponent,
    canActivate: [CmsPageGuard, AuthGuard],
  },
  {
    path: 'categories',
    component: PageLayoutComponent,
    canActivate: [FffPrebookPagesGuard, CmsPageGuard],
    data: {
      isPrebookCategory: true,
      cxRoute: 'prebookCategories',
    },
    runGuardsAndResolvers: 'paramsOrQueryParamsChange',
  },
  {
    path: 'my-cart',
    component: PageLayoutComponent,
    canActivate: [AuthGuard, FffPrebookPagesGuard, CmsPageGuard],
    data: {
      pageLabel: 'prebookCartPage',
    },
  },
  {
    path: 'sso',
    component: FffSsoBridgePageComponent,
    canActivate: [FffSsoGuard],
  },
  {
    path: 'payments/invoice',
    component: FffPaymentsBridgeComponent,
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { initialNavigation: 'enabledBlocking' }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
