import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgbCollapseModule } from '@ng-bootstrap/ng-bootstrap';
import { I18nModule } from '@spartacus/core';
import {
  GenericLinkModule,
  IconModule
} from '@spartacus/storefront';
import { FffDrawerModule } from 'src/app/shared/drawer/fff-drawer.module';
import { FffFacetFilterDrawerBodyComponent } from './fff-facet-filter-drawer-body.component';
import { FffFacetFilterDrawerHeaderComponent } from './fff-facet-filter-drawer-header.component';



@NgModule({
  declarations: [
    FffFacetFilterDrawerBodyComponent,
    FffFacetFilterDrawerHeaderComponent
  ],
  imports: [
    CommonModule,
    IconModule,
    GenericLinkModule,
    I18nModule,
    NgbCollapseModule,
    FffDrawerModule
  ],
})
export class FffFacetFilterDrawerModule { }
