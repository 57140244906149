import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FffCreditCardMaskPipe } from './fff-credit-card-mask.pipe';
import { FffTrimTransactionIdPipePipe } from './fff-trim-transaction-id-pipe.pipe';

@NgModule({
  declarations: [FffCreditCardMaskPipe, FffTrimTransactionIdPipePipe],
  imports: [CommonModule],
  exports: [FffCreditCardMaskPipe, FffTrimTransactionIdPipePipe],
})
export class FffSharedPipesModule {}
