<div class="d-flex flex-column justify-content-between h-100">
  <form [formGroup]="filtersForm" (ngSubmit)="applyFilters(filtersForm)">
    <div class="filter-container form-group">
      <div class="searchbar-container form-control d-flex justify-content-between">
        <input type="text" class="w-100" formControlName="code"[placeholder]="'fffReturns.searchByFluReturnId' | cxTranslate" />
        <cx-icon class="icon-sm px-2 icon" [type]="outlinedIconTypes.ICON_SEARCH"></cx-icon>
      </div>

      <div class="status-container">
        <div class="text-brand-primary-80 font-size-body-xl font-weight-bold filter-title mt-4">
          {{'fffOrderHistoryFilter.status' | cxTranslate}}
        </div>
        <div class="status-in-process-container">
          <input class="checkbox checkbox-sm" name="process" formControlName="progress"  type="checkbox" id="process">
          <label class="checkbox-label filter-option" for="process">{{'fffReturns.inProcess' | cxTranslate}}</label>
        </div>

        <div class="status-complete-container">
          <input class="checkbox checkbox-sm" name="complete" formControlName="complete"  type="checkbox"  id="complete">
          <label class="checkbox-label filter-option" for="complete">{{'fffReturns.complete' | cxTranslate}}</label>
        </div>
      </div>
      <div class="text-brand-primary-80 font-size-body-xl filter-title font-weight-bold">
        {{'fffOrderHistoryFilter.date' | cxTranslate}}
      </div>

      <span>{{'fffOrderHistoryFilter.from' | cxTranslate}}</span>

      <div class="mb-3">
        <div class="form-control w-100 icon-container d-flex justify-content-between">
          <input type="text" class="w-100 border-0" name="fromDate" formControlName="startDate" ngbDatepicker #fromNgbDatepicker="ngbDatepicker" id="fromDate"/>
          <button class="date-button" type="button" (click)="fromNgbDatepicker.toggle()">
            <cx-icon class="cx-icon icon-sm px-2 icon" [type]="outlinedIconTypes.ICON_CALENDAR_EVENT"></cx-icon>
          </button>
        </div>
      </div>
      <span>{{'fffOrderHistoryFilter.to' | cxTranslate}}</span>

      <div class="mb-3">
        <div class="form-control w-100 icon-container d-flex justify-content-between">
          <input type="text" class="w-100 border-0" name="toDate" formControlName="endDate"  ngbDatepicker #toNgbDatepicker="ngbDatepicker" id="toDate"/>
          <button class="date-button" type="button" (click)="toNgbDatepicker.toggle()">
            <cx-icon class="cx-icon icon-sm px-2 icon" [type]="outlinedIconTypes.ICON_CALENDAR_EVENT"></cx-icon>
          </button>
        </div>
      </div>
    </div>
    <div class="footer-container">
      <button class="btn btn-primary btn-xs w-100" type="submit">
        {{'fffOrderHistoryFilter.apply' | cxTranslate }}
      </button>
    </div>
  </form>
</div>
