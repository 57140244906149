<div class="empty-cart-container">
  <div class="row d-flex flex-wrap content">
    <div class="col-12 text">
      <span [innerHTML]="'fffEmptySearch.text' | cxTranslate"></span>
    </div>

    <div class="col-12">
      <cx-icon
        class="cx-icon icon-xl"
        [type]="customIconTypes.ICON_EMPTY_CART"
      ></cx-icon>
    </div>

    <div class="col-12" *ngIf="showActions">
      <button
        type="submit"
        class="btn btn-primary btn-md m-auto"
        (click)="goToHomePage()"
      >
        {{ 'fffEmptySearch.action' | cxTranslate }}
      </button>
    </div>
  </div>
</div>
