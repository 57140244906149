<div class="d-flex flex-wrap body-text-lg">

  <div class="content-close-icon cursor-pointer ml-auto d-flex justify-content-between">
    <cx-icon class="cx-icon icon-lg p-2" [type]="outlinedIconTypes.ICON_X" (click)="close()"></cx-icon>
  </div>

  <div class="col-12 text-center body-text-demi-xl content-header text-brand-primary-80">
    <span class="col-12 text-center">{{ 'fffShortDated.title' | cxTranslate }}</span>
  </div>


  <div class="col-12 text-center cx-dialog-body modal-body content-body">
    <p>{{ 'fffShortDated.text1' | cxTranslate }}</p>
    <span [innerHTML]="'fffShortDated.text2' | cxTranslate"></span>
  </div>

  <div class="col-12 modal-actions">
    <button class="btn btn-primary col-12" (click)="continue()">{{
      'fffShortDated.action' | cxTranslate }}</button>
  </div>
</div>