import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {
  PREBOOK_CART_LABEL,
  isPrebookEnabledCategory,
} from '@app/fff-enterprise/fff-prebook-category/services/fff-prebook-category.utils';
import { FffSsoService } from '@app/fff-enterprise/fff-sso/fff-sso.service';
import { FffProfile } from '@app/models/fff-profile.model';
import { FffUserAccountService } from '@app/shared/services/fff-user-account.service';
import { FFF_MINICART } from '@assets/translations/en/fff-register';
import {
  AuthService,
  CmsService,
  PageType,
  RoutingService,
} from '@spartacus/core';
import { NavigationService } from '@spartacus/storefront';
import { Observable, Subject, combineLatest, of } from 'rxjs';
import { filter, map, switchMap, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'fff-site-login',
  templateUrl: './fff-site-login.component.html',
})
export class FffSiteLoginComponent implements OnInit {
  profile$: Observable<FffProfile | undefined> = of(undefined);
  miniCart: any = FFF_MINICART.FFF_DATA;
  node: any;
  isNavigating$ = this.ssoService.isNavigating();

  isPrebookCategoryPage$ = this.routingService.getRouterState().pipe(
    filter(value => !!value.state?.context?.id),
    map(
      value =>
        value.state.context.type === PageType.CATEGORY_PAGE &&
        isPrebookEnabledCategory(value.state.queryParams)
    )
  );
  isPrebookCartPage$ = this.routingService.getRouterState().pipe(
    filter(value => !!value.state?.context?.id),
    map(value => value.state.context.id === PREBOOK_CART_LABEL)
  );

  isPrebookFormEnabled$ = combineLatest([
    this.isPrebookCategoryPage$,
    this.isPrebookCartPage$,
  ]).pipe(
    map(
      ([isPrebookCategoryPage, isPrebookCartPage]) =>
        isPrebookCategoryPage || isPrebookCartPage
    )
  );

  constructor(
    private router: Router,
    private auth: AuthService,
    private fffUserAccountService: FffUserAccountService,
    public navigationService: NavigationService,
    public cmsService: CmsService,
    private routingService: RoutingService,
    public cdRef: ChangeDetectorRef,
    private ssoService: FffSsoService
  ) {}

  private unsubscribe$ = new Subject<void>();
  navigationNode$ = this.navigationService
    .getNavigationNode(this.cmsService.getComponentData('MyAccountComponent'))
    .pipe(takeUntil(this.unsubscribe$));

  async ngOnInit() {
    this.profile$ = this.auth.isUserLoggedIn().pipe(
      switchMap(isUserLoggedIn => {
        if (isUserLoggedIn) {
          return this.fffUserAccountService.getProfile();
        } else {
          this.fffUserAccountService.removeProfile();
          return of(undefined);
        }
      })
    );
  }

  goToPage(url: string) {
    this.router.navigate([url]);
  }

  ngOnDestroy() {
    this.unsubscribe$.unsubscribe();
  }
}
