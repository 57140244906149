<div class="container" *ngIf="order$ | async as orderData">

  <div class="cx-order-confirmation-message pt-5" *ngIf="getOrderType(orderData) == orderTypes.standarOrder">
    <h2 class="text-center">{{ 'fffCheckoutOrderConfirmation.standardOrderHeaderText' | cxTranslate }}</h2>
    <p class="text-center">
      <span
        [innerHTML]="'fffCheckoutOrderConfirmation.standardOrderInfoText' | cxTranslate : {email: getUserEmail(orderData)} "></span>
    </p>
  </div>

  <div class="cx-order-confirmation-message pt-5" *ngIf="getOrderType(orderData) == orderTypes.orderRequest">
    <h2 class="text-center">{{ 'fffCheckoutOrderConfirmation.orderRequestHeaderText' | cxTranslate }}</h2>
    <p class="text-center">
      <span [innerHTML]="'fffCheckoutOrderConfirmation.orderRequestInfoText' | cxTranslate"></span>
    </p>
  </div>

  <div class="cx-order-confirmation-message pt-5" *ngIf="getOrderType(orderData) == orderTypes.splitBillingOrder">
    <h2 class="text-center">{{ 'fffCheckoutOrderConfirmation.splitBillingOrderHeaderText' | cxTranslate }}</h2>
    <p class="text-center">
      <span [innerHTML]="'fffCheckoutOrderConfirmation.splitBillingOrderInfoText' | cxTranslate"></span>
    </p>
  </div>

  <div class="cx-order-confirmation-message pt-5" *ngIf="getOrderType(orderData) == orderTypes.semiSplitBillingOrder">
    <h2 class="text-center">{{ 'fffCheckoutOrderConfirmation.semiSplitBillingOrderHeaderText' | cxTranslate }}</h2>
    <p class="text-center">
      <span
        [innerHTML]="'fffCheckoutOrderConfirmation.semiSplitBillingOrderInfoText' | cxTranslate : {email: getUserEmail(orderData)}"></span>
    </p>
  </div>

  <div class="cx-order-confirmation-message pt-5" *ngIf="getOrderType(orderData) == orderTypes.recurringOrder">
    <h2 class="text-center">{{ 'fffCheckoutOrderConfirmation.recurringOrderHeaderText' | cxTranslate }}</h2>
    <p class="text-center">
      <span [innerHTML]="'fffCheckoutOrderConfirmation.recurringOrderInfoText' | cxTranslate"></span>
    </p>
  </div>

</div>