import { translationChunksConfig, translations } from '@spartacus/assets';
import { I18nConfig } from '@spartacus/core';
import { fffTranslations } from './fff-translations';

export function fffTranslationsFactory(): I18nConfig {
  return {
    i18n: {
      resources: {
        en: {
          ...translations.en,
          ...fffTranslations.en,
        },
      },
      chunks: {
        ...translationChunksConfig,
      },
      fallbackLang: 'en',
    },
  };
}
