export const fffApprovals = {
  fffApprovals: {
    tabName: 'Pending Approval',
    title: 'Approvals',
    subtotal: 'Subtotal',
    approve: 'Approve Order',
    deny: 'Deny Order',
    requestDate: 'Request Date',
    requestNumber: 'Request #',
    requester: 'Requested By',
    sku: 'SKU',
    name: 'Product Name',
    qty: 'Qty',
    price: 'Price',
    total: 'Total',
    action: 'Action',
    approveSingle: 'Approve',
    denySingle: 'Deny',
    denied: 'Denied',
    approved: 'Approved',
    addItem: '+ Add Item',
    submit: 'Submit',
    company: 'Company',
    requested: 'Requested by',
    selectProduct: 'Select Product',
    modalTitle: 'Submission Successful',
    modalText: 'Your response has been submitted successfully.',
    finish: 'Continue Approving',
    purchaseOrderNumber: 'PO: {{ purchaseOrderNumber }}',
  },
};
