import { Component, OnInit } from '@angular/core';
import { OUTLINED_ICON_TYPE } from 'src/app/models/fff-outline-icons.model';
import { FffDrawerService } from 'src/app/shared/drawer/fff-drawer.service';


@Component({
  selector: 'fff-save-cart-drawer-header',
  templateUrl: './fff-save-cart-drawer-header.component.html',
})
export class FffSaveCartDrawerHeaderComponent implements OnInit {

  outlinedIconTypes = OUTLINED_ICON_TYPE;

  constructor(
    private drawerService: FffDrawerService
  ) { }

  ngOnInit(): void {
  }

  public closeDrawer(): void {
    this.drawerService.closeDrawer();
  }
}

