import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'fff-tooltip',
  templateUrl: './fff-tooltip.component.html',
})
export class FffTooltipComponent {
  constructor() {}

  @Input() text: string = 'Sample Text';
  @Input() alwaysVisible: boolean = false;
  @Input() isTranslatableLabel: boolean | undefined;
  isVisible = true;

  @Output() closed = new EventEmitter();

  ngOnChanges() {
    this.isVisible = true;
  }
}
