<div class="modal-header">
  <cx-icon (click)="close()" [type]="OUTLINED_ICON_TYPE.ICON_X"></cx-icon>
</div>
<div class="modal-body">
  <div class="icon">
    <cx-icon [type]="OUTLINED_ICON_TYPE.ICON_CHECK"></cx-icon>
  </div>
  <div class="message">
    {{ 'fffPriceList.exportedDocumentSuccessfully' | cxTranslate }}
  </div>
</div>
<div class="modal-footer">

  <button class="btn btn-primary col-12" (click)="close()">
    {{ 'fffPriceList.continue' | cxTranslate }}
  </button>
</div>
