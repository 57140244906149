import { Component } from '@angular/core';
import { FffInvoicePaymentService } from '@app/fff-enterprise/fff-common-services/fff-invoice-payment.service';
import { OUTLINED_ICON_TYPE } from '@app/models/fff-outline-icons.model';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-fff-delete-saved-card-confirmation-popup',
  templateUrl: './fff-delete-saved-card-confirmation-popup.component.html',
})
export class FffDeleteSavedCardConfirmationPopupComponent {
  OUTLINED_ICON_TYPE = OUTLINED_ICON_TYPE;
  loading: boolean = false;
  unsubscribe$: any;
  cardToken: string = '';
  cardEndingDigits!: string;
  paymentUUID!: string;

  constructor(
    protected modalService: NgbActiveModal,
    private fffInvoicePaymentService: FffInvoicePaymentService
  ) {}

  ngOnInit() {
    if (this.cardToken && this.cardToken.length > 12) {
      this.cardEndingDigits = this.cardToken.slice(-4);
    }
  }

  deleteCard() {
    this.loading = true;
    this.fffInvoicePaymentService
      .deleteSavedCard(this.cardToken, this.paymentUUID)
      .subscribe(
        res => {
          this.loading = false;
          this.modalService.close({ isDeleted: true });
        },
        () => {
          this.loading = false;
          this.modalService.close({ isDeleted: false });
        }
      );
  }

  close() {
    this.loading = false;
    this.modalService.close({ isDeleted: false });
  }
}
