import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { OUTLINED_ICON_TYPE } from '@app/models/fff-outline-icons.model';
import { FffDrawerService } from '@app/shared/drawer/fff-drawer.service';
import { FffAllocationNavNodes } from '@app/shared/services/enum.service';

@Component({
  selector: 'fff-allocations-history-filter',
  templateUrl: './fff-allocations-history-filter.component.html',
})
export class FffAllocationsHistoryFilterComponent implements OnInit {

  outlinedIconTypes = OUTLINED_ICON_TYPE;

  form: UntypedFormGroup = this.fb.group({
    skuNdc: [''],
    b2bunit: [''],
    dateFrom: [],
    dateTo: [],
  });

  constructor(
    protected fb: UntypedFormBuilder,
    public drawerService: FffDrawerService,
  ) { }

  ngOnInit(): void {
    let filters = localStorage.getItem('allocationsHistoryFilters');
    if (filters) {
      let parsedFilters = JSON.parse(filters);
      this.form.patchValue(parsedFilters);
    }
  }

  apply(): void {
    let filters = this.form.value;
    localStorage.setItem('allocationsHistoryFilters', JSON.stringify(filters));
    this.drawerService.closeDrawer({ component: FffAllocationNavNodes.allocationsHistory, filters: filters });
  }


}
