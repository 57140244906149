import { Injectable } from '@angular/core';
import {
  CartSummaryAction,
  FFFOrderEntry,
} from '@app/models/fff-cart-data.model';
import { Action, Store } from '@ngrx/store';
// import {
//   ActiveCartService,
//   getCartIdByUserId,
//   StateWithMultiCart,
//   UserIdService,
// } from '@spartacus/core';
import { UPDATED_CART, UpdatedCartState } from '@app/reducers';
import { FffCartService } from '@enterprise/fff-common-services/fff-cart-service';
import { FFF_CART_ADD_ENTRY_SUCCESS } from '@enterprise/fff-custom-cart/fff-cart-entry.action';
import { ofType } from '@ngrx/effects';
import {
  ActiveCartService,
  StateWithMultiCart,
  getCartIdByUserId,
} from '@spartacus/cart/base/core';
import { UserIdService, WindowRef } from '@spartacus/core';
import { Observable, Subject, combineLatest, of, race } from 'rxjs';
import {
  finalize,
  switchMap,
  take,
  takeUntil,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import { FFFMultiCartService } from './fff-multi-cart-service';

@Injectable({
  providedIn: 'root',
})
export class FFFActiveCartService extends ActiveCartService {
  removedEntryFromCart$: Subject<FFFOrderEntry> = new Subject<FFFOrderEntry>();
  cartEvent$: Subject<Action> = new Subject<Action>();
  performActionAfterStable: boolean = false;
  selectedActionAfterStable: CartSummaryAction | undefined;

  constructor(
    protected userIdService: UserIdService,
    protected store: Store<StateWithMultiCart>,
    protected fffMultiCartService: FFFMultiCartService,
    private udpatedCartStore: Store<UpdatedCartState>,
    private fffCartService: FffCartService,
    protected winRef: WindowRef
  ) {
    super(fffMultiCartService, userIdService, winRef);
  }

  addEntries(cartEntries: FFFOrderEntry[]): void {
    this.requireLoadedCart()
      .pipe(withLatestFrom(this.userIdService.getUserId()))
      .subscribe(([cartState, userId]) => {
        if (cartState) {
          this.fffMultiCartService.addEntries(
            userId,
            getCartIdByUserId(cartState, userId),
            cartEntries
          );
          this.udpatedCartStore.dispatch({ type: UPDATED_CART, payload: {} });
        }
      });
  }
  addNewEntries(
    cartEntries: FFFOrderEntry[],
    unsubscribe$: Observable<boolean>
  ): Observable<any> {
    const finishSubscriptionAddEntry: Subject<void> = new Subject<void>();
    const finishMessages: Subject<void> = new Subject<void>();

    this.addEntries(cartEntries);

    this.getCartEvents()
      .pipe(
        takeUntil(unsubscribe$),
        ofType(FFF_CART_ADD_ENTRY_SUCCESS),
        take(3)
      )
      .subscribe(() => {
        finishSubscriptionAddEntry.next();
      });
    return combineLatest([
      this.getActive(),
      race([unsubscribe$, finishSubscriptionAddEntry, finishMessages]),
    ]).pipe(
      take(1),
      finalize(() => {
        finishMessages.next();
      })
    );
  }
  emitRemovedEntry(value: FFFOrderEntry) {
    this.removedEntryFromCart$.next(value);
  }
  getRemovedEntry(): Observable<Action> {
    return this.cartEvent$.asObservable();
  }
  emitCartEvent(action: Action) {
    this.cartEvent$.next(action);
  }
  getCartEvents(): Observable<Action> {
    return this.cartEvent$.asObservable();
  }

  getUpdatedCartATP(cartId: string) {
    return this.fffCartService.getUpdatedCartATP(cartId);
  }

  markCartAsPrebook(cartId: string, prebookCart: boolean) {
    return this.fffCartService.markCartAsPrebook(cartId, prebookCart);
  }

  updateActiveCartPrebookFlag(prebookCart: boolean = true) {
    let activeCartId: string = '';
    return this.takeActiveCartId().pipe(
      take(1),
      switchMap(cartId => {
        if (!cartId) {
          return of({});
        }
        activeCartId = cartId;
        return this.markCartAsPrebook(cartId, prebookCart);
      }),
      tap(() => {
        if (!activeCartId) {
          return;
        }
        this.multiCartFacade.reloadCart(activeCartId);
      })
    );
  }

  clearAfterStableActions() {
    this.performActionAfterStable = false;
    this.selectedActionAfterStable = undefined;
  }
}
