import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { FffQuickOrderFile, FffQuickOrderResponse } from "@app/models/fff-quick-order-file.model";
import { OccEndpointsService } from "@spartacus/core";
import { Observable, throwError } from "rxjs";
import { catchError } from "rxjs/operators";

@Injectable()
export class FffQuickOrderService {
  constructor(
    protected http: HttpClient,
    protected occEndpointService: OccEndpointsService) {

  }

  sendFile(files: FffQuickOrderFile[]): Observable<FffQuickOrderResponse> {
    const requestUrl: string = this.occEndpointService.buildUrl('/quickOrder');
    return this.http.post<FffQuickOrderResponse>(requestUrl, files)
      .pipe(
        catchError(
          error => throwError(error)
        )
      );
  }
}
