import { Injectable } from '@angular/core';
import {
  GlobalBannerMessageEntities,
  StateWithGlobalBannerMessage,
  GlobalBannerMessage,
} from '@model/fff-global-message.model';
import {
  GlobalMessageType,
  isNotUndefined,
} from '@spartacus/core';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter } from 'rxjs/operators';
import { GlobalBannerMessageSelectors } from '@app/selectors';
import { GlobalBannerActions } from '@app/actions';

@Injectable({
  providedIn: 'root',
})
export class FffGlobalBannerMessagesService{
  constructor(protected store: Store<StateWithGlobalBannerMessage> ) {}
  get(): Observable<GlobalBannerMessageEntities> {
    return this.store.pipe(
      select(GlobalBannerMessageSelectors.getGlobalMessageEntities),
      filter(isNotUndefined)
    );
  }
  add(
    id: string,
    text: string,
    type: GlobalMessageType,
    timeout?: number,
    isPersistent?: boolean,
    isHTML?: boolean,
    isTranslatable?: boolean,
    prefix?: string
  ): void {
    this.store.dispatch(
      new GlobalBannerActions.AddBannerMessage({
        id,
        text: { raw: text },
        type,
        timeout,
        isPersistent,
        isHTML,
        isTranslatable,
        prefix,
      })
    );
  }
  remove(msg: GlobalBannerMessage, index: number): void {
    this.store.dispatch(
      index !== undefined
        ? new GlobalBannerActions.RemoveBannerMessage(msg)
        : new GlobalBannerActions.RemoveBannerMessagesByType(msg.type)
    );
  }
}
