import { Component, OnDestroy, OnInit } from '@angular/core';
import { OUTLINED_ICON_TYPE } from '@app/models/fff-outline-icons.model';
import { FffDrawerService } from '@app/shared/drawer/fff-drawer.service';
import { FffAllocationNavNodes } from '@app/shared/services/enum.service';
import { TranslationService } from '@spartacus/core';
import { ExportCsvFileService, ExportFileOptions } from '@spartacus/storefront';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { take } from 'rxjs/operators';
import { FffAllocationsHistoryFilterComponent } from './fff-allocations-history/fff-allocations-history-filter/fff-allocations-history-filter.component';
import { FffAllocationsHistoryComponent } from './fff-allocations-history/fff-allocations-history.component';
import { FffAllocationsService } from './fff-allocations.service';
import { FffCurrentAllocationsComponent } from './fff-current-allocations/fff-current-allocations.component';
import { FffInteractiveAllocationsListComponent } from './fff-interactive-allocations-list/fff-interactive-allocations-list.component';
import { FffSearchInteractiveAllocationsDrawerComponent } from './fff-search-interactive-allocations-drawer/fff-search-interactive-allocations-drawer.component';

@Component({
  selector: 'fff-allocations',
  templateUrl: './fff-allocations.component.html',
})
export class FffAllocationsComponent implements OnInit, OnDestroy {

  constructor(
    public translationService: TranslationService,
    public exportCSVService: ExportCsvFileService,
    private drawerService: FffDrawerService,
    private fffAllocationsService: FffAllocationsService
  ) { }

  currentComponent: any = FffCurrentAllocationsComponent as Component;
  fffAllocationNavNodes = FffAllocationNavNodes;
  outlinedIconTypes = OUTLINED_ICON_TYPE;

  private unsubscribe$: Subject<void> = new Subject<void>();
  allAllocations: any[] = [];

  fields: any = [
    { title: 'fffAllocations.productName', field: 'productName' },
    { title: 'fffAllocations.sku', field: 'material' },
    { title: 'fffAllocations.ndc', field: 'productNdc' },
    { title: 'fffAllocations.customer', field: 'customer' },
    { title: 'fffAllocations.monthlyAlloc', field: 'allocatedQty' },
    { title: 'fffAllocations.monthlyRemain', field: 'remainingQty' },
    { title: 'fffAllocations.annualAlloc', field: 'yearlyAllocatedQty' },
    { title: 'fffAllocations.annualRemain', field: 'yearlyRemainingQty' },
  ];
  selectedTab$ = new BehaviorSubject(FffAllocationNavNodes.currentAllocations);
  fffAllocationsNavNodes = FffAllocationNavNodes;
  currentAllocationNode = FffAllocationNavNodes.currentAllocations;

  ngOnInit(): void {
    localStorage.removeItem('allocationsHistoryFilters');
    this.fffAllocationsService.allAllocations$.subscribe((allAllocationsData: any[]) => this.allAllocations = allAllocationsData);
  }

  emitNewSelectedTab(selectedTab: FffAllocationNavNodes) {
    this.selectedTab$.next(selectedTab);
  }

  async parseExportInfo() {
    let data: any[] = [];
    data = this.allAllocations ? this.allAllocations : [];

    let fileNames: any[] = [];

    this.fields.forEach((f: any) => {
      this.translationService.translate(f.title).subscribe(res => {
        fileNames.push(res);
      });
    });

    let exportFileEntries: string[][] = [];

    //Reserved for titles
    let fileTitles = this.fields.map((e: { field: any }) => { return e.field; });
    exportFileEntries[0] = fileNames;

    data.forEach((e: any, index: any) => {

      exportFileEntries[index + 1] = [];

      fileTitles.forEach((key: any) => {
        e[key] = e[key] ? e[key] : ' ';
        exportFileEntries[index + 1].push(e[key]);
      });

    });

    return exportFileEntries;
  }

  async downloadCSV() {
    let r = await this.parseExportInfo();

    //in case a name is not being sent
    r.forEach(row => {
      if (!row[0]) {
        row[0] = 'N/A';
      }
    });

    let options: ExportFileOptions = { fileName: 'allocationList', extension: 'csv', type: 'multipart/form-data' };
    this.exportCSVService.download(r, ',', options);
  }

  openFilterDrawer(selectedAllocationNavLink: FffAllocationNavNodes): void {
    let componentToDisplay: Component;
    let classDrawer = '';

    let titleObservable: Observable<string> | undefined;
    titleObservable = this.translationService.translate('fffAllocationsHistoryFilter.title');

    switch (selectedAllocationNavLink) {
    case FffAllocationNavNodes.allocationsHistory: {
      componentToDisplay = FffAllocationsHistoryFilterComponent as Component;
      classDrawer = 'mini-cart';
      break;
    }
    case FffAllocationNavNodes.interactiveAllocations: {
      componentToDisplay = FffSearchInteractiveAllocationsDrawerComponent as Component;
      classDrawer = 'order_history';
      break;
    }
    default: {
      componentToDisplay = FffAllocationsHistoryFilterComponent as Component;
      return;
    }
    }

    titleObservable?.subscribe(titleFilter => {
      this.drawerService.setContent({
        title: titleFilter,
        component: componentToDisplay,
        animation: 'SideRTL',
        class: classDrawer,
      });
      this.drawerService.openDrawer();
      this.drawerService.getDrawerResponse
        .pipe(take(1))
        .subscribe((result: any) => {
          this.fffAllocationsService.allocationsFilter$.next(result);
        });
    });
  }

  getComponent(selectedTab: any): any {
    let currentComponent: any = FffCurrentAllocationsComponent as Component;
    switch (selectedTab) {
    case FffAllocationNavNodes.allocationsHistory: {
      currentComponent = FffAllocationsHistoryComponent as Component;
      break;
    }
    case FffAllocationNavNodes.currentAllocations: {
      currentComponent = FffCurrentAllocationsComponent as Component;
      break;
    }
    case FffAllocationNavNodes.interactiveAllocations: {
      currentComponent = FffInteractiveAllocationsListComponent as Component;
      break;
    }
    default: {
      currentComponent = FffAllocationsHistoryFilterComponent as Component;
      return;
    }
    }
    return currentComponent;
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
    this.fffAllocationsService.allocationsFilter$.next(undefined);
  }

}
