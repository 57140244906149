import { Component, OnDestroy, OnInit } from '@angular/core';
import { getProductImage } from '@assets/utils/fff-product-utils';
import { FffCartFacade } from '@enterprise/fff-common-services/fff-cart-facade';
import { FffProductsService } from '@enterprise/fff-common-services/fff-products.service';
import { Product } from '@spartacus/core';
import { MediaContainer } from '@spartacus/storefront';
import { BehaviorSubject, Observable, Subscription, of } from 'rxjs';
import { filter, map, switchMap, take, tap } from 'rxjs/operators';

@Component({
  selector: 'fff-recommended-products',
  templateUrl: './fff-recommended-products.component.html',
})
export class FFFRecommendedProductsComponent implements OnInit, OnDestroy {
  private subscription: Subscription = new Subscription();

  cartId: string = '';
  productsArray: Array<Product> = [];

  suggestedProductsLoaded$: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);
  cartIdLoaded$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  suggestedProductsList$: Observable<Product[]> =
    this.suggestedProductsLoaded$.pipe(
      filter(hasLoaded => Boolean(hasLoaded)),
      switchMap(() => of(this.productsArray))
    );

  constructor(
    private fffProductsService: FffProductsService,
    private fffCartFacade: FffCartFacade
  ) {}

  ngOnInit(): void {
    this.fffCartFacade
      .getActive()
      .pipe(take(1))
      .subscribe(cart => {
        this.cartId = cart.code!;
        this.cartIdLoaded$.next(true);
      });

    this.cartIdLoaded$
      .pipe(
        filter(cartIdHasLoaded => cartIdHasLoaded),
        take(1),
        tap(() => this.getSuggestedProducts())
      )
      .subscribe();
  }

  getSuggestedProducts(): void {
    this.fffProductsService
      .getSuggestedProducts(this.cartId)
      .pipe(
        take(1),
        map((arrayOfProducts: any) => {
          arrayOfProducts.products?.forEach((product: Product) => {
            this.productsArray.push(product);
            this.suggestedProductsLoaded$.next(true);
          });
        })
      )
      .subscribe();
  }

  getData(imagesProduct: any) {
    return getProductImage(imagesProduct?.images) as MediaContainer;
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
