import { Component } from '@angular/core';
import { OUTLINED_ICON_TYPE } from 'src/app/models/fff-outline-icons.model';
import { FffDrawerService } from 'src/app/shared/drawer/fff-drawer.service';
import { FffMiniCartDrawerService } from '../fff-save-cart-drawer.service';

@Component({
  selector: 'fff-mini-cart-header',
  templateUrl: './fff-mini-cart-header.component.html',
})
export class FffMiniCartHeaderComponent {

  entries$: any;
  outlinedIconTypes = OUTLINED_ICON_TYPE;
  cartData$ = this.fffMiniCartDrawerService.cartData$;

  constructor(
    private fffMiniCartDrawerService: FffMiniCartDrawerService,
    public drawerService: FffDrawerService
  ) { }

  closeDrawer() {
    this.drawerService.closeDrawer()
  }

}
