import { Component } from '@angular/core';
import { OUTLINED_ICON_TYPE } from 'src/app/models/fff-outline-icons.model';
import { FffDrawerService } from 'src/app/shared/drawer/fff-drawer.service';
import { FffInvoiceListDrawerService } from './fff-invoice-list-drawer.service';


@Component({
  selector: 'fff-invoice-list-drawer-footer',
  templateUrl: './fff-invoice-list-drawer-footer.component.html',
})
export class FffInvoiceListDrawerFooterComponent {

  outlinedIconTypes = OUTLINED_ICON_TYPE;

  constructor(
    private drawerService: FffDrawerService,
    private fffInvoiceListDrawerService: FffInvoiceListDrawerService
  ) { }

  public applyFilters(): void {

    let filtersForm = this.fffInvoiceListDrawerService.filtersForm.value;
    this.drawerService.closeDrawer(filtersForm!.value);
  }

  public closeDrawer(): void {
    this.drawerService.closeDrawer();
  }
}

