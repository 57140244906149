import { FFFOrderEntry } from '@app/models/fff-cart-data.model';
import { CartEntryAdapter } from '@spartacus/cart/base/core';
import { CartModification } from '@spartacus/cart/base/root';
import { Observable } from 'rxjs';

export abstract class FFFCartEntryAdapter extends CartEntryAdapter {

  /**
   * Abstract method used to customize add entry to cart for FFF
   *
   * @param userId
   * @param cartId
   * @param orderEntries 
   */
  abstract addFFF(
    userId: string,
    cartId: string,
    orderEntries?: FFFOrderEntry[]
  ): Observable<CartModification>;

}
