import { AfterViewInit, Component, ElementRef } from '@angular/core';
import { FffRingCentralChatBotService } from '@app/fff-enterprise/fff-service-third-party/fff-ring-central-chat-bot.service';
import { ADD_PRODUCT_FOCUSED, ProductFocusState } from '@app/reducers';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { OUTLINED_ICON_TYPE } from 'src/app/models/fff-outline-icons.model';


@Component({
  selector: 'fff-product-short-dated-popup',
  templateUrl: './fff-product-short-dated-popup.component.html'
})
export class FffProductShortDatedPopupComponent implements AfterViewInit {

  outlinedIconTypes = OUTLINED_ICON_TYPE;
  product: any = {};

  constructor(
    private modalService: NgbActiveModal,
    private elementRef: ElementRef,
    private store: Store<ProductFocusState>,
    private chatbotService: FffRingCentralChatBotService
  ) { }

  close() {
    this.modalService.close();
  }

  ngAfterViewInit() {
    if (this.elementRef.nativeElement.querySelector('.live-chat')) {
      this.elementRef.nativeElement.querySelector('.live-chat').addEventListener('click', this.openLiveChat.bind(this));
    }
  }

  openLiveChat(): void {
    this.chatbotService.openChatBot();
  }

  continue(): void {
    this.modalService.close({ accept: true });
    this.clearStorage(this.product);
  }

  private clearStorage(prod: any): void {
    this.store.dispatch({
      type: ADD_PRODUCT_FOCUSED,
      payload: { key: prod.code, action: 'CLEAR' },
    });
  }

}
