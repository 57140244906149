import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ConfigModule, I18nModule } from '@spartacus/core';
import { FffBreadcrumbComponent } from './fff-breadcrumb.component';



@NgModule({
  declarations: [
    FffBreadcrumbComponent,
  ],
  imports: [
    CommonModule,
    ConfigModule.withConfig({
      cmsComponents: {
        BreadcrumbComponent: {
          component: FffBreadcrumbComponent,
        },
      },
    }),
    RouterModule,
    I18nModule,

  ],
  exports: [
    FffBreadcrumbComponent,
  ],

})
export class FffBreadcrumbModule { }
