<div class="col-12">

  <div class="col-12">
    <button class="btn btn-primary col-12" [class.updating]="loading" (click)="saveCart()"
      [disabled]="!saveCartForm?.valid || loading">{{
      'fffSaveCart.actions.saveCart' |
      cxTranslate }}
      <cx-icon *ngIf="loading" class="cx-icon icon-md ml-3" [type]="outlinedIconTypes.ICON_ARROW_REPEAT"></cx-icon>
    </button>
  </div>

  <div class="col-12 py-2">
    <button class="btn btn-secondary col-12" (click)="closeDrawer()">{{ 'fffSaveCart.actions.cancel' |
      cxTranslate }}</button>
  </div>

</div>