import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FFFUpdateSavedCartEntry } from '@app/models/fff-cart-data.model';
import { OccEndpointsService } from '@spartacus/core';

@Injectable({
  providedIn: 'root',
})
export class FffSavedCartDetailService {
  constructor(private http: HttpClient, private occ: OccEndpointsService) {}

  editSavedCartEntries(
    savedCartId: string,
    cartUpdateEntries: FFFUpdateSavedCartEntry[]
  ) {
    let url = this.occ.buildUrl(
      `/orgUsers/current/carts/${savedCartId}/entries/`
    );
    return this.http.patch(url, { cartUpdateEntries });
  }
}
