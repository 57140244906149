import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CmsConfig, I18nModule, provideConfig } from '@spartacus/core';
import { RoutingModule } from '@spartacus/storefront';
import { FffPrebookHeaderComponent } from './fff-prebook-header.component';

@NgModule({
  declarations: [FffPrebookHeaderComponent],
  imports: [CommonModule, I18nModule, RouterModule, RoutingModule],
  exports: [FffPrebookHeaderComponent],
  providers: [
    provideConfig(<CmsConfig>{
      cmsComponents: {
        MFVPrebookStepComponent: {
          component: FffPrebookHeaderComponent,
        },
      },
    }),
  ],
})
export class FffPrebookHeaderModule {}
