<div *ngIf="successfulInvoices && successfulInvoices.length > 0">
  <h6 class="fff-invoice-payment-content-block__title mt-0">
    {{ 'fffInvoicePayment.successfulTransactions' | cxTranslate }}
  </h6>
  <table class="invoice-confirmation-entries-table w-100">
    <thead>
      <tr class="rowForPrint">
        <th class="pl-4">
          {{ 'fffInvoiceHistory.invoice' | cxTranslate }}
        </th>
        <th>
          {{ 'fffInvoiceHistory.invoiceDate' | cxTranslate }}
        </th>
        <th>
          {{ 'fffInvoiceHistory.invoiceDueDate' | cxTranslate }}
        </th>
        <th>
          {{ 'fffInvoiceHistory.po' | cxTranslate }}
        </th>
        <th class="w-25">
          {{ 'fffInvoiceHistory.manufacturer' | cxTranslate }}
        </th>
        <th class="w-15">
          {{ 'fffInvoiceHistory.transactionNumber' | cxTranslate }}
        </th>
        <th class="w-10">
          {{ 'fffInvoiceHistory.status' | cxTranslate }}
        </th>
        <th class="w-10">
          {{ 'fffInvoiceHistory.amount' | cxTranslate }}
        </th>
      </tr>
    </thead>
    <tbody>
      <ng-container *ngFor="let data of successfulInvoices">
        <tr class="align-top invoice-border-bottom rowForPrint">
          <td class="pl-4">{{ data.invoiceNumber }}</td>
          <td>{{ data.invoiceDate | date : 'MM/dd/yyyy' }}</td>
          <td>{{ data.dueDate | date : 'MM/dd/yyyy' }}</td>
          <td>{{ data.poNumber }}</td>
          <td class="w-25">{{ data.materials?.[0]?.manufacturer }}</td>
          <td class="w-15">{{ data.transactionId | fffTrimTransactionId }}</td>
          <td class="w-10">
            <cx-icon
              class="invoiceSuccessIcon"
              [type]="outlinedIconTypes.ICON_CHECK"
            ></cx-icon>
            <div class="payment-status">
              {{ 'fffInvoicePayment.approved' | cxTranslate }}
            </div>
          </td>
          <td class="w-10">${{ data.grandTotal }}</td>
        </tr>
      </ng-container>
    </tbody>
  </table>
  <div class="fff-table-footer d-flex">
    <div class="fff-table-footer-cell ml-auto body-text-demi d-flex">
      <div class="mr-auto totalAmntForPrint">
        {{ 'fffInvoicePayment.total' | cxTranslate }}
      </div>
      <div class="totalAmntForPrint">
        {{ totalAmountForSuccess | currency }}
      </div>
    </div>
  </div>
</div>
<div class="mt-3" *ngIf="failureInvoices && failureInvoices.length > 0">
  <h6 class="fff-invoice-payment-content-block__title mt-0">
    {{ 'fffInvoicePayment.failedTransactions' | cxTranslate }}
  </h6>
  <table class="invoice-confirmation-entries-table w-100">
    <thead>
      <tr class="rowForPrint">
        <th class="pl-4">
          {{ 'fffInvoiceHistory.invoice' | cxTranslate }}
        </th>
        <th>
          {{ 'fffInvoiceHistory.invoiceDate' | cxTranslate }}
        </th>
        <th>
          {{ 'fffInvoiceHistory.invoiceDueDate' | cxTranslate }}
        </th>
        <th>
          {{ 'fffInvoiceHistory.po' | cxTranslate }}
        </th>
        <th class="w-25">
          {{ 'fffInvoiceHistory.manufacturer' | cxTranslate }}
        </th>
        <th class="w-15">
          {{ 'fffInvoiceHistory.transactionNumber' | cxTranslate }}
        </th>
        <th class="w-10">
          {{ 'fffInvoiceHistory.status' | cxTranslate }}
        </th>
        <th class="w-10">
          {{ 'fffInvoiceHistory.amount' | cxTranslate }}
        </th>
      </tr>
    </thead>
    <tbody>
      <ng-container *ngFor="let data of failureInvoices">
        <tr class="align-top invoice-border-bottom rowForPrint">
          <td class="pl-4">{{ data.invoiceNumber }}</td>
          <td>{{ data.invoiceDate | date : 'MM/dd/yyyy' }}</td>
          <td>{{ data.dueDate | date : 'MM/dd/yyyy' }}</td>
          <td>{{ data.poNumber }}</td>
          <td class="w-25">{{ data.materials?.[0]?.manufacturer }}</td>
          <td class="w-15">-</td>
          <td class="w-10">
            <cx-icon
              class="invoiceFailedIcon"
              [type]="outlinedIconTypes.ICON_X"
            ></cx-icon>
            <div class="payment-status">
              {{ 'fffInvoicePayment.declined' | cxTranslate }}
            </div>
          </td>
          <td class="w-10">${{ data.grandTotal }}</td>
        </tr>
      </ng-container>
    </tbody>
  </table>
  <div class="fff-table-footer d-flex">
    <div class="fff-table-footer-cell ml-auto body-text-demi d-flex">
      <div class="mr-auto totalAmntForPrint">
        {{ 'fffInvoicePayment.total' | cxTranslate }}
      </div>
      <div class="totalAmntForPrint">
        {{ totalAmountForFailure | currency }}
      </div>
    </div>
  </div>
</div>
