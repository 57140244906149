import { Component, ElementRef } from '@angular/core';
import { OUTLINED_ICON_TYPE } from 'src/app/models/fff-outline-icons.model';
import { RegisterService } from '../fff-register.service';

@Component({
  selector: 'fff-account-verification',
  templateUrl: './fff-account-verification.component.html'
})
export class FffAccountVerificationComponent {

  outlinedIconTypes = OUTLINED_ICON_TYPE;

  constructor(private registerService: RegisterService,
    private elementRef: ElementRef) {
  }

  ngAfterViewInit() {
    if (this.elementRef.nativeElement.querySelector('.resent-email')) {
      this.elementRef.nativeElement.querySelector('.resent-email').addEventListener('click', this.resendEmail.bind(this));
    }
  }

  resendEmail(): void {
    if (this.registerService.registerEmail.value) {
      const resentEmail$ = this.registerService.resendEmail(this.registerService.registerEmail.value);
      resentEmail$.subscribe();
    }
  }

}
