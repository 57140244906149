<div class="cx-dialog-header modal-header needMoreInfoDialogHeader">
  <button
    type="button"
    [attr.aria-label]="'common.close' | cxTranslate"
    class="close"
    tabindex="-1"
    (click)="close()"
  >
    <cx-icon class="cx-icon icon-lg" [type]="outlinedIconTypes.ICON_X"></cx-icon>
  </button>
  <div class="cx-dialog-title modal-title">
    {{ 'fffProduct.modal.header' | cxTranslate }}
  </div>
</div>

<ng-container>
  <div class="cx-dialog-body modal-body" class="needMoreInfoDialogContent">
    <ng-container *ngIf="isPGX; else notPGX">
      <div class="container">
        <p
          *ngIf="isHardAllocation"
          [innerHTML]="'fffProduct.modal.PGXHardAllocationMessage' | cxTranslate"
        ></p>
        <p
          *ngIf="!isHardAllocation"
          [innerHTML]="'fffProduct.modal.PGXSoftAllocationMessage' | cxTranslate"
        ></p>
      </div>
    </ng-container>
    <ng-template #notPGX>
      <div class="container">
        <p
          *ngIf="isHardAllocation"
          [innerHTML]="'fffProduct.modal.hardAllocationMessage' | cxTranslate"
        ></p>
        <p
          *ngIf="!isHardAllocation"
          [innerHTML]="'fffProduct.modal.softAllocationMessage' | cxTranslate"
        ></p>
      </div>
    </ng-template>
  </div>
</ng-container>
