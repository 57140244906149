import { Component, OnInit } from '@angular/core';
import { FffCsosStatusService } from '../fff-csos-status.service';

@Component({
  selector: 'app-fff-csos-status',
  templateUrl: './fff-csos-status.component.html',
  styleUrls: ['./fff-csos-status.component.scss'],
})
export class FffCsosStatusComponent implements OnInit {
  csosData: any;

  constructor(private service: FffCsosStatusService) {}

  ngOnInit(): void {
    this.service.getCsosStatus().subscribe((res: any) => {
      this.csosData = res;
      console.log(this.csosData);
      this.csosData.sort((a: any, b: any) => {
        return b.orderCode - a.orderCode;
      });
    });
  }
}
