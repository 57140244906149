<div class="col-md-12 register-content contact-us">
  <form [formGroup]="form">
    <div class="col-12 m-auto px-4">
      <label class="summary-row position-relative">
        <section class="filter-box">
          <label
            class="searchbox btn-sm w-100 mb-0 d-flex align-items-center"
            for="search"
          >
            <input
              id="search"
              autocomplete="off"
              [placeholder]="
                'fffInvoiceFilterHistory.poPlaceHolder' | cxTranslate
              "
              formControlName="invoiceNumber"
            />
            <div class="search-icon">
              <cx-icon
                class="cx-icon icon-sm"
                [type]="outlinedIconTypes.ICON_SEARCH"
              ></cx-icon>
            </div>
          </label>
        </section>
      </label>

      <div class="summary-row body-text-sm">
        <span class="filter-title">
          {{ 'fffInvoiceFilterHistory.status' | cxTranslate }}
        </span>
      </div>

      <div class="summary-row body-text-sm">
        <input
          class="checkbox checkbox-sm"
          type="checkbox"
          id="open"
          formControlName="open"
        />
        <label class="checkbox-label body-text-demi" for="open">
          {{ 'fffInvoiceFilterHistory.open' | cxTranslate }}
        </label>
      </div>

      <div class="summary-row body-text-sm">
        <input
          class="checkbox checkbox-sm"
          type="checkbox"
          id="paid"
          formControlName="paid"
        />
        <label class="checkbox-label body-text-demi" for="paid">
          {{ 'fffInvoiceFilterHistory.paid' | cxTranslate }}
        </label>
      </div>

      <ng-container *ngIf="isPaymentEnabled">
        <div class="summary-row body-text-sm" *ngIf="manufacturers.length > 0">
          <span class="filter-title">
            {{ 'fffInvoiceFilterHistory.manufacturers' | cxTranslate }}
          </span>
        </div>

        <div
          class="summary-row body-text-sm"
          *ngFor="let manufacturer of manufacturers; let i = index"
        >
          <input
            class="checkbox checkbox-sm"
            type="checkbox"
            [attr.id]="'field-manufacturer-' + i"
            [(ngModel)]="manufacturer.selected"
            [ngModelOptions]="{ standalone: true }"
            (ngModelChange)="onManufacturerSelectionChange()"
          />
          <label
            class="checkbox-label body-text-demi"
            [attr.for]="'field-manufacturer-' + i"
          >
            {{ manufacturer.name }}
          </label>
        </div>
      </ng-container>

      <div class="summary-row body-text-sm">
        <span class="filter-title">
          {{ 'fffInvoiceFilterHistory.date' | cxTranslate }}
        </span>
      </div>

      <label class="summary-row">
        <span class="body-text">{{
          'fffInvoiceFilterHistory.dateFrom' | cxTranslate
        }}</span>
        <section class="filter-box">
          <label
            class="searchbox btn-sm w-100 mb-0 d-flex align-items-center"
            for="dateFrom"
          >
            <input
              id="dateFrom"
              type="datetime"
              autocomplete="off"
              formControlName="dateFrom"
              ngbDatepicker
              #from="ngbDatepicker"
            />
            <div class="search-icon" (click)="from.toggle()">
              <cx-icon
                class="cx-icon icon-sm"
                [type]="outlinedIconTypes.ICON_CALENDAR_EVENT"
              ></cx-icon>
            </div>
          </label>
        </section>
      </label>

      <label class="summary-row">
        <span class="body-text">{{
          'fffInvoiceFilterHistory.dateTo' | cxTranslate
        }}</span>
        <section class="filter-box">
          <label
            class="searchbox btn-sm w-100 mb-0 d-flex align-items-center"
            for="dateTo"
          >
            <input
              id="dateTo"
              autocomplete="off"
              formControlName="dateTo"
              ngbDatepicker
              #to="ngbDatepicker"
            />
            <div class="search-icon" (click)="to.toggle()">
              <cx-icon
                class="cx-icon icon-sm"
                [type]="outlinedIconTypes.ICON_CALENDAR_EVENT"
              ></cx-icon>
            </div>
          </label>
        </section>
      </label>
    </div>
  </form>
</div>
