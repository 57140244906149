import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { I18nConfig, I18nModule, provideConfig, UrlModule } from '@spartacus/core';
import { FormErrorsModule, IconModule, SpinnerModule } from '@spartacus/storefront';
import { fffTranslationOverwrites } from 'src/assets/translations/en/fff-register';
import { FffContactUsComponent } from './fff-contact-us/fff-contact-us.component';


@NgModule({
  declarations: [
    FffContactUsComponent
  ],
  providers: [
    provideConfig(<I18nConfig>{
      i18n: {
        resources: fffTranslationOverwrites,
      },
    })
  ],
  imports: [
    CommonModule,
    IconModule,
    SpinnerModule,
    FormsModule,
    ReactiveFormsModule,
    SpinnerModule,
    I18nModule,
    FormErrorsModule,
    UrlModule,
    RouterModule,
  ]
})
export class FffContactUsModule { }
