import { Component, Input, OnInit } from '@angular/core';
import { CartDetailsComponent } from '@spartacus/cart/base/components';
import { CartConfigService, SelectiveCartService } from '@spartacus/cart/base/core';
import { ActiveCartFacade } from '@spartacus/cart/base/root';
import { AuthService, BaseSiteService, RoutingService } from '@spartacus/core';


@Component({
  selector: 'fff-cart-details',
  templateUrl: './fff-cart-details.component.html',
})
export class FffCartDetailsComponent extends CartDetailsComponent implements OnInit {

  @Input() prices: any[] = [];
  @Input() stock: any[] = [];

  activeSite$ = this.baseSiteService.getActive();

  constructor(
    protected fffActiveCartService: ActiveCartFacade,
    protected selectiveCartService: SelectiveCartService,
    protected authService: AuthService,
    protected routingService: RoutingService,
    protected cartConfig: CartConfigService,
    private baseSiteService: BaseSiteService
  ) {
    super(fffActiveCartService, selectiveCartService, authService, routingService, cartConfig);
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

}


