import {
  Component,
  OnInit,
} from '@angular/core';
import { Observable } from 'rxjs';
import {
  GlobalBannerMessageEntities,
  GlobalBannerMessage,
} from '@model/fff-global-message.model';
import { FffGlobalBannerMessagesService } from '@shared/services/fff-global-banner-messages.service';
import { FILLED_ICON_TYPE } from '@model/fff-filled-icons.model';
import { GlobalMessageService, GlobalMessageType } from '@spartacus/core';

@Component({
  selector: 'fff-global-banner-message',
  templateUrl: './fff-global-banner-message.component.html',
})
export class FffGlobalBannerMessageComponent implements OnInit {
  FILLED_ICON_TYPE = FILLED_ICON_TYPE;
  bannerMessages$: Observable<GlobalBannerMessageEntities>;
  messageType = GlobalMessageType;
  constructor(
    private globalMessagesService: GlobalMessageService,
    private fffGlobalMessagesService: FffGlobalBannerMessagesService
  ) {
    this.bannerMessages$ = this.fffGlobalMessagesService.get();

  }
  ngOnInit() {
  }
  remove(msg: GlobalBannerMessage, index: number): void {
    this.fffGlobalMessagesService.remove(msg, index);
  }
}
