<h5 class="body-text-xl">
  <ng-container *ngIf="(activeSite$ | async) === BASE_URL_KEYS.BIOSUPPLY">
  <ng-container *ngIf="product.dropShip; else noDropShip">
    <b class="in-stock">{{'fffProduct.lblDropShip' | cxTranslate }}</b>
  </ng-container>
  <ng-template #noDropShip>
    <ng-container *ngIf="product.assayIndicator && hasBatchStock; else noAssayProduct">
      <b class="in-stock">{{'fffProduct.lblInStock' | cxTranslate }}</b>
    </ng-container>
  </ng-template>
  <ng-template #noAssayProduct>
    <ng-container *ngIf="totalStock != null && totalStock > 0">
      <b class="in-stock">{{'fffProduct.lblInStock' | cxTranslate }}</b>
    </ng-container>
    <ng-container *ngIf="totalStock != null && totalStock < 1">
      <ng-container *ngIf="rapidCommitIsSuperCat$ | async; else notRapidCommit ">
        <!-- <b class="in-stock">{{'fffProduct.lblReserveNow' | cxTranslate }}</b> -->
      </ng-container>
      <ng-template #notRapidCommit>
        <b *ngIf="product.onDemand" class="on-demand">{{'fffProduct.lblOnDemand' | cxTranslate }}</b>
        <ng-container *ngIf="!product.onDemand">
          <b  class="in-transit">{{'fffProduct.lblInTransit' | cxTranslate }}</b>
          <p> <small>{{'fffProduct.lblOutOfStockNote' | cxTranslate }}</small></p>
        </ng-container>
      </ng-template>
    </ng-container>
  </ng-template>
</ng-container>

</h5>
