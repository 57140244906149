<div class="col-12 d-flex flex-wrap pr-4">

  <span class="p-3 body-text-sm">{{ 'fffRestoreSavedCartDrawer.message' | cxTranslate }}</span>

  <div class="col-12">
    <div class="p-3 cart-selected">
      <div class="icon-container d-flex justify-content-center align-items-center position-relative float-right">
        <cx-icon class="cx-icon icon-sm icon-account" [type]="outlinedIconTypes.ICON_CHECK_LG"></cx-icon>
      </div>

      <span class="body-text-demi px-3">{{ 'fffSavedCarts.name' | cxTranslate }} </span>
      <span class="body-text px-3" *ngIf="cartData">{{ cartData.name }}</span>
    </div>
  </div>

</div>