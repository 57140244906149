import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FffFormControlsModule } from '@app/shared/components/form/fff-form-controls/fff-form-controls.module';
import { FffTableModule } from '@app/shared/table/fff-table.module';
import { FffTooltipModule } from '@app/shared/tooltip/tooltip.module';
import {
  NgbDatepickerModule,
  NgbModalModule,
  NgbModule,
} from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import {
  CmsConfig,
  HttpModule,
  I18nModule,
  provideConfig,
} from '@spartacus/core';
import {
  FormErrorsModule,
  IconModule,
  ListNavigationModule,
  PageSlotModule,
} from '@spartacus/storefront';
import { FffUtilModule } from '../fff-util/fff-util.module';
import { FffNewReportAccountSelectDrawerComponent } from './fff-new-report-account-select-drawer/fff-new-report-account-select-drawer.component';
import { FffNewReportAccountSelectComponent } from './fff-new-report-account-select/fff-new-report-account-select.component';
import { FffNewReportFiltersComponent } from './fff-new-report-filters/fff-new-report-filters.component';
import { FffNewReportProductGroupSelectDrawerComponent } from './fff-new-report-product-group-select-drawer/fff-new-report-product-group-select-drawer.component';
import { FffNewReportProductGroupSelectComponent } from './fff-new-report-product-group-select/fff-new-report-product-group-select.component';
import { FffNewReportProductSelectDrawerComponent } from './fff-new-report-product-select-drawer/fff-new-report-product-select-drawer.component';
import { FffNewReportProductSelectComponent } from './fff-new-report-product-select/fff-new-report-product-select.component';
import { FffCancelRecurringReportDrawerComponent } from './fff-new-reports/fff-cancel-recurring-report-drawer/fff-cancel-recurring-report-drawer.component';
import {
  FffNewReportsComponent,
  TruncateTableCellValuesPipe,
} from './fff-new-reports/fff-new-reports.component';
import { FFFLoadersModule } from '@app/shared/components/fff-loaders/fff-loaders.module';

@NgModule({
  declarations: [
    FffNewReportsComponent,
    FffNewReportFiltersComponent,
    FffNewReportAccountSelectDrawerComponent,
    FffNewReportAccountSelectComponent,
    FffNewReportProductSelectComponent,
    FffNewReportProductSelectDrawerComponent,
    FffNewReportProductGroupSelectComponent,
    FffNewReportProductGroupSelectDrawerComponent,
    TruncateTableCellValuesPipe,
    FffCancelRecurringReportDrawerComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    HttpModule,
    I18nModule,
    FormErrorsModule,
    IconModule,
    NgSelectModule,
    NgbDatepickerModule,
    NgbModalModule,
    FffFormControlsModule,
    FFFLoadersModule,
    FffUtilModule,
    NgbModule,
    FffTooltipModule,
    FffTableModule,
    PageSlotModule,
    ListNavigationModule,
  ],
  providers: [
    provideConfig(<CmsConfig>{
      cmsComponents: {
        NewReportsFlexComponent: {
          component: FffNewReportFiltersComponent,
        },
        NewReportsListFlexComponent: {
          component: FffNewReportsComponent,
        },
      },
    }),
  ],
})
export class FffNewReportsModule {}
