import { Injectable } from '@angular/core';
import { Cart } from '@spartacus/cart/base/root';
import { BehaviorSubject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class FffMiniCartDrawerService {

  cartData$ = new BehaviorSubject<Cart | undefined>(undefined);

  constructor() { }


}
