<cx-spinner class="overlay" *ngIf="isUpdating$ | async"> </cx-spinner>

<div class="container">
  <div
    class="row register-container col-xs-12 col-md-8 d-flex justify-content-center align-items-center m-auto px-0"
  >
    <div
      class="col-12 d-flex justify-content-center align-items-center register-header register-background-content"
    >
      <div>
        <h3 class="h3">{{ 'fffLogin.header' | cxTranslate }}</h3>
      </div>
    </div>
    <div class="col-md-12 register-content">
      <form (ngSubmit)="onSubmit()" [formGroup]="form">
        <div class="col-xs-12 col-md-9 pt-4 m-auto">
          <label>
            <span class="label-content">{{
              'fffLogin.loginForm.emailAddress' | cxTranslate
            }}</span>

            <input
              class="form-control form-control-lg"
              id="userId"
              aria-required="true"
              type="email"
              (input)="clearLoginError($event)"
              formControlName="userId"
            />
            <cx-form-errors
              [control]="$any(form).get('userId')"
            ></cx-form-errors>
          </label>

          <div>
            <span class="prefix-icon" (click)="enablePassword()">
              <cx-icon
                *ngIf="showPassword"
                class="cx-icon icon-md pe-auto"
                [type]="filledIconTypes.ICON_EYE_FILL"
              >
              </cx-icon>
              <cx-icon
                *ngIf="!showPassword"
                class="cx-icon icon-md pe-auto"
                [type]="filledIconTypes.ICON_EYE_SLASH_FILL"
              >
              </cx-icon>
            </span>
          </div>

          <label>
            <span class="label-content">{{
              'loginForm.password.label' | cxTranslate
            }}</span>
            <input
              class="form-control form-control-lg"
              id="password"
              aria-required="true"
              [type]="showPassword ? 'text' : 'password'"
              formControlName="password"
              (input)="clearLoginError($event)"
            />
            <cx-form-errors
              [control]="$any(form).get('password')"
            ></cx-form-errors>
          </label>

          <div class="form-actions">
            <a
              *ngIf="!isUserDisabled"
              [routerLink]="{ cxRoute: 'forgotPassword' } | cxUrl"
              class="body-text-lg"
            >
              {{ 'loginForm.forgotPassword' | cxTranslate }}
            </a>

            <div *ngIf="isUserDisabled" class="disabledUserMsgContainer">
              {{ 'fffLogin.disabledUserMsg' | cxTranslate }}
              <span
                (click)="openLiveChat()"
                class="text-underline cursor-pointer"
              >
                {{ 'fffLogin.liveChatMsg' | cxTranslate }}
              </span>
              {{ 'fffLogin.callMsg' | cxTranslate }}
            </div>

            <button
              type="submit"
              class="btn btn-primary btn-md"
              [disabled]="form.disabled || isUserDisabled"
            >
              {{ 'fffLogin.login' | cxTranslate }}
            </button>

            <p class="text-center">
              <span [innerHTML]="'fffLogin.needBioSupply' | cxTranslate"></span>
              <a
                class="text-center"
                [innerHTML]="'fffLogin.register' | cxTranslate"
                (click)="registerSubmit()"
              ></a>
            </p>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
