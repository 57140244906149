<ng-container *ngIf="b2bunit$ | async as b2bunit">
  <ng-container
    *ngIf="b2bunit.currentB2BUnit && !b2bunit.currentB2BUnit.jeffersonUnit"
  >
    <div class="mini-cart d-flex btn-xs" (click)="openDrawer()">
      <cx-icon
        class="cx-icon cart-icon"
        [type]="outlinedIconTypes.ICON_CART"
      ></cx-icon>
      <span>{{ eligibleEntries.length }}</span>
    </div>
  </ng-container>
</ng-container>

<!-- <div class="drawer-container">
  <fff-drawer #Drawer>
  </fff-drawer>
</div> -->
