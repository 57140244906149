import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { OccEndpointsService } from '@spartacus/core';
import { Observable } from 'rxjs';
import { fffContactUsOccEndpointsConfig } from 'src/app/fff-config/fff-endpoints.config';
import { FffContactUsData, FffContactUsResponse } from 'src/app/models/fff-contact-us-data.model';
import { FffDepartmentsResponse } from 'src/app/models/fff-contact-us-reason.model';

@Injectable({
  providedIn: 'root'
})
export class ContactUsService {

  getRequestHeaders(): HttpHeaders {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    return headers;
  }

  constructor(private http: HttpClient, protected occEndpoints: OccEndpointsService) { }

  getDepartments(): Observable<FffDepartmentsResponse> {
    let url = this.occEndpoints.buildUrl(fffContactUsOccEndpointsConfig.DEPARTMENTS);
    return this.http.get(url, { headers: this.getRequestHeaders() });
  }

  submit(data: FffContactUsData): Observable<FffContactUsResponse> {
    const url = this.occEndpoints.buildUrl(fffContactUsOccEndpointsConfig.SUBMIT);
    return this.http.post(url, data, { headers: this.getRequestHeaders() });
  }

}
