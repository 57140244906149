import { Injectable } from '@angular/core';
import { BasePageMetaResolver, ProductSearchService, RoutingService, SearchPageMetaResolver, TranslationService } from '@spartacus/core';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { mergeMap, switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class FffSearchPageMetaResolver extends SearchPageMetaResolver {

  constructor(
    protected routingService: RoutingService,
    protected productSearchService: ProductSearchService,
    protected translation: TranslationService,
    protected basePageMetaResolver: BasePageMetaResolver
  ) {
    super(routingService, productSearchService, translation, basePageMetaResolver);
  }

  resolveTitle(): Observable<string> {
    const sources = [this.total$, this.query$];
    return combineLatest(sources).pipe(
      switchMap(([count, query]) => {
        if (count == 0) {
          return new BehaviorSubject('');
        }
        return this.translation
          .translate('pageMetaResolver.search.default_title')
          .pipe(
            mergeMap((defaultQuery) =>
              this.translation.translate('pageMetaResolver.search.title', {
                count,
                query: query || defaultQuery,
              })
            )
          )
      }
      )
    );
  }
}
