import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SpinnerModule } from '@spartacus/storefront';
import { FffPaymentsBridgeComponent } from './fff-payments-bridge.component';

@NgModule({
  declarations: [FffPaymentsBridgeComponent],
  imports: [CommonModule, RouterModule, SpinnerModule],
  exports: [FffPaymentsBridgeComponent],
})
export class FffPaymentsBridgeModule {}
