<div class="d-flex justify-content-center w-100">
  <h1>{{ 'fffCheckout.orderRequestPageTitle' | cxTranslate }}</h1>
</div>
<ng-container *ngIf="activeSite$ | async as activeSite">
  <ng-container *ngIf="profile$ | async as profile">
    <ng-container *ngIf="products$ | async as products; else loading">
      <div class="d-flex w-100">
        <div class="quick-order-table-body w-75">
          <div class="cx-quick-order-table-wrapper">
            <div class="cx-quick-order-table-row" *ngFor="let p of products; let i = index">
              <fff-product-list-item [activeSite]="activeSite" [product]="p" [user]="profile.user"
                                     [b2bunit]="profile.selected"></fff-product-list-item>
            </div>
          </div>
        </div>
        <ng-container *ngIf="cart$ | async as cart">
          <div class="cart-summary-border w-25">
            <fff-jefferson-cart-summary [cart]="cart" [profile]="profile"></fff-jefferson-cart-summary>
          </div>
        </ng-container>
      </div>
    </ng-container>
  </ng-container>
</ng-container>

<ng-template #loading>
  <fff-cart-loader></fff-cart-loader>
</ng-template>
