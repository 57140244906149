/// <reference types="@types/google.maps" />
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class FffGoogleMapService {
  private autocompleteService: google.maps.places.AutocompleteService;
  private geocoderService: google.maps.Geocoder;

  constructor() {
    this.autocompleteService = new google.maps.places.AutocompleteService();
    this.geocoderService = new google.maps.Geocoder();
  }

  getPlacePredictions(
    input: string
  ): Observable<google.maps.places.AutocompletePrediction[]> {
    return new Observable((observer: any) => {
      const request = {
        input: input,
        componentRestrictions: { country: 'us' }, // Restrict to United States
      };

      this.autocompleteService.getPlacePredictions(
        request,
        (predictions, status) => {
          if (
            status === google.maps.places.PlacesServiceStatus.OK &&
            predictions
          ) {
            observer.next(predictions);
            observer.complete();
          } else {
            observer.error(status);
          }
        }
      );
    });
  }

  getPlaceDetails(placeId: string): Observable<google.maps.GeocoderResult> {
    return new Observable(observer => {
      this.geocoderService.geocode({ placeId }, (results, status) => {
        if (
          status === google.maps.GeocoderStatus.OK &&
          results &&
          results.length > 0
        ) {
          observer.next(results[0]);
          observer.complete();
        } else {
          observer.error(status);
        }
      });
    });
  }
}
