import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { I18nModule } from '@spartacus/core';
import { IconModule } from '@spartacus/storefront';
import { FffProductListActualStrengthComponent } from './fff-product-list-actual-strength.component';


@NgModule({
  declarations: [FffProductListActualStrengthComponent],
  imports: [
    CommonModule,
    I18nModule,
    IconModule
  ],
  exports: [FffProductListActualStrengthComponent],
  providers: [],
})
export class FffProductListActualStrengthModule { }
