export const fffAllocations = {
  fffAllocations: {
    title: 'Allocations',
    productName: 'Product Name',
    sku: 'SKU',
    ndc: 'NDC',
    monthlyAlloc: 'Monthly Allocation',
    monthlyRemain: 'Monthly Remaining',
    annualAlloc: 'Annual Allocation',
    annualRemain: 'Annual Remaining',
    exportCsv: 'Export CSV',
    customer: 'Account Number',
    filter: 'Filter',
  },
};

export const fffAllocationsFilters = {
  fffAllocationsFilters: {
    searchBySku: 'Search By SKU',
    searchByProductName: 'Search By Product Name',
  },
};

export const fffAllocationsTabs = {
  fffAllocationsTabs: {
    currentAllocations: 'Current Allocations',
    interactiveAllocations: 'Interactive Allocations',
    allocationsHistory: 'Allocations History',
    newCommitmentRequest: 'New Commitment Request',
    approveCommitmentRequest: 'Approve Commitment Request',
    allocationsTabsDropdownTitle: 'Allocations',
  },
};

export const fffAllocationsHistory = {
  fffAllocationsHistory: {
    productName: 'Product Name',
    account: 'Account',
    sku: 'SKU',
    ndc: 'NDC',
    dateModified: 'Date Modified',
    modifiedBy: 'Modified By',
    qtyChanged: 'Qty Changed',
    actions: 'Actions',
  },
};

export const fffAllocationsHistoryFilter = {
  fffAllocationsHistoryFilter: {
    title: 'Filter Allocations',
    searchBySkuOrNdc: 'Search By SKU or NDC',
    searchByAccount: 'Search By Account Number',
    date: 'Date',
    dateFrom: 'From',
    dateTo: 'To',
    apply: 'Apply',
  },
};

export const fffInteractiveAllocations = {
  fffInteractiveAllocations: {
    productName: 'Product Name',
    sku: 'SKU',
    ndc: 'NDC',
    size: 'Size',
    action: 'Action',
    update: 'Update',
    type: 'Allocation Type',
    hard: 'Hard',
    soft: 'Soft',
    title: 'Allocations',
  },
};

export const fffUpdateAllocations = {
  fffUpdateAllocations: {
    title: 'Update Allocations',
    amountAvailableToReallocate: 'Amount Available to Reallocate: {{amount}}',
    remainingQty: '{{attentionName}}',
    infoMessage: 'Applies to this month only',
    amountReallocated: 'Amount Reallocated: {{amount}}',
    searchByAccountNumber: 'Search by Account Number and Name',
    accountTitle: '{{accountId}} · {{accountName}}',
    attentionName: 'ATTN: {{attentionName}}',
    currentAllocation: 'Remaining Allocation',
    quantityToAdjust: 'Quantity to Adjust',
    submit: 'Submit',
    cancel: 'Cancel',
  },
};

export const fffUpdateAllocationsPopUp = {
  fffUpdateAllocationsPopUp: {
    title: 'Allocations Updated',
    titleError: 'Allocation Update Failed',
    defaultMessage: 'Your allocations have been updated',
    acknowledge: 'Acknowledge',
  },
};
