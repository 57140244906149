<div class="d-flex flex-wrap p-0 p-lg-3 col-12 py-4 border-separator" *ngFor="let item of items">
  <div class="col-12 px-0 d-flex flex-wrap">
    <div class="col-2 px-0">
      <cx-media [container]="getData(item.product!)" format="cart"></cx-media>
    </div>
    <div class="col-10 col-lg-3">
      <p class="mb-0 body-text-demi title h4">{{ item?.product?.name }}</p>
      <p class="mb-0">{{ item?.product?.manufacturer }}</p>
      <p class="mb-0">{{ 'fffReturnEntry.ndc' | cxTranslate }} {{ item?.product?.ndc }}</p>
      <p class="mb-0">{{ 'fffReturnEntry.sku' | cxTranslate }} {{ item?.product?.code }}</p>
      <p class="mb-0">{{ 'fffReturnEntry.account' | cxTranslate }} {{ account }}</p>
      <p class="mb-0">{{ 'fffReturnEntry.batchNumber' | cxTranslate }} {{ item.batch }}</p>
    </div>
    <div class="col-12 col-lg-7 text-right px-0 pt-4 pt-lg-0" *ngIf="displayQuantitySection">
      <p class="mb-0 body-text-demi title body-text-xl" *ngIf="displayConfirmationEntryQty;else displayDetailsEntry">
        {{ 'fffReturnEntry.qty' | cxTranslate: {quantity: item?.quantity, unit: item.uom } }}
      </p>
      <ng-template #displayDetailsEntry>
        <div class="row">
          <div class="col-12 p-0">
            <p class="mb-0 body-text-demi title body-text-xl">
              {{ 'fffReturnEntry.returnableQuantity' | cxTranslate: {quantity: item.returnedQuantity, unit:
              item.product.unit } }}
            </p>
          </div>
          <div class="col-12 p-0">
            <p class="mb-0 body-text-demi title body-text-xl">
              {{ 'fffReturnEntry.orderQuantity' | cxTranslate: {quantity: item.quantity, unit: item.product.unit } }}
            </p>
          </div>
        </div>
      </ng-template>
    </div>
  </div>
</div>
