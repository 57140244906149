import {
  ActionReducerMap, MetaReducer,
} from '@ngrx/store';
import { storeFreeze } from 'ngrx-store-freeze';
import { environment } from '../../environments/environment';
import { GlobalMessageType } from '@spartacus/core';
import {
  GlobalBannerMessage,
  GlobalBannerMessageEntities,
  GlobalBannerMessageState,
} from './../models/fff-global-message.model';
import { GlobalBannerActions } from '@app/actions';
export const SET_CURRENT_B2B_UNIT = 'SET_CURRENT_B2B_UNIT';

export const SET_CUSTOMER_SPLIT_BILLER = 'SET_CUSTOMER_SPLIT_BILLER';

export const SET_USER = 'SET_USER';

export const ADD_PRODUCT_FOCUSED_CART = 'ADD_PRODUCT_FOCUSED_CART';

export const FILTER_PRICELIST_BY_CATEGORY = 'FILTER_PRICELIST_BY_CATEGORY';
export const FILTER_PRICELIST_BY_CATEGORY_APPLIED = 'FILTER_PRICELIST_BY_CATEGORY_APPLIED';

export const ADD_PRODUCT_FOCUSED = 'ADD_PRODUCT_FOCUSED';
export const CLEAR_PRODUCT_FOCUSED = 'CLEAR_PRODUCT_FOCUSED';

export const ADD_SELECTED_MY_ACCOUNT_NAVNODE = 'ADD_SELECTED_MY_ACCOUNT_NAVNODE';

export const UPDATED_CART = 'UPDATED_CART';

export const PRODUCT_STATUS = 'PRODUCT_STATUS';

export const ORDER_CONFIRMATION_DATA = 'ORDER_CONFIRMATION_DATA';

export const RETURN_CONFIRMATION_DATA = 'RETURN_CONFIRMATION_DATA';

export const PRODUCT_STRENGTH = 'PRODUCT_STRENGTH';

export const PRODUCT_SPECIAL_PRICE = 'PRODUCT_SPECIAL_PRICE';

export const PRODUCT_ADMIN_SET = 'PRODUCT_ADMIN_SET';

export const ENTRY_PO = 'ENTRY_PO';

export interface State {

}

export interface B2BUnitState {
  B2BUnit: {
    readonly currentB2BUnit: any;
    readonly listB2BUnit: any;
  };
}

export interface CustomerState {
  readonly splitBillerAuto: any;
}

export interface FFFUser {
  readonly user: any;
}

export interface FilterState {
  readonly priceListByCategory: any;
  readonly priceListByCategoryApplied: any;
}

export interface ProductFocusState {
  readonly productList: any;
}

export interface CartIncomingChangesState {
  readonly cartEntriesData: any;
}

export interface MyAccountNavState {
  readonly selectedNav: any;
}

export interface UpdatedCartState {
  readonly entry: any;
}

export interface ProductStockState {
  readonly data: any;
}

export interface OrderConfirmationState {
  readonly data: any;
}

export interface ReturnConfirmationState {
  readonly data: any;
}

export interface ProductStrengthState {
  readonly data: any;
}

export interface ProductSpecialPriceState {
  readonly data: any;
}

export interface ProductAdminSet {
  readonly data: any;
}

export interface EntryPoState {
  readonly data: any;
}

// Import reducers here so we don't have to declare indivisual reducers in app.module.
export const reducers: ActionReducerMap<State> = {
  B2BUnit: selectB2bUnitReducer,
  Filters: selectFilterReducer,
  ProductsFocus: addProductFocused,
  IncomingCartUpdates: cartReducer,
  MyAccountNavState: selectMyAccountNavReducer,
  UpdatedCartState: selectUpdatedCartState,
  ProductStockState: selectProductStatus,
  OrderConfirmationState: selectOrderConfirmationState,
  ReturnConfirmationState: selectReturnConfirmationState,
  Customer: customerReducer,
  ProductStrength: selectProductStrength,
  ProductSpecialPrice: selectProductSpecialPrice,
  AdminSet: selectAdminSet,
  EntryPo: selectEntryPo,
  FFF_GLOBAL_MESSAGE: getGlobalMessages,
  FFFUser:fffUser,
};

export const metaReducers: MetaReducer<State>[] = !environment.production ? [storeFreeze] : [];


export function selectB2bUnitReducer(state: B2BUnitState, action: any) {
  switch (action.type) {
  case SET_CURRENT_B2B_UNIT:
    return { ...state, currentB2BUnit: action.payload };
  default:
    return state;
  }
}

export function cartReducer(state: CartIncomingChangesState, a: any) {


  const action = JSON.parse(JSON.stringify(a));
  switch (action.type) {

  case ADD_PRODUCT_FOCUSED_CART:
    let cartEntriesData: any = {};
    if (state) {
      cartEntriesData = JSON.parse(JSON.stringify(state.cartEntriesData));
    }
    let key = action.payload.entry.key;
    if (action.payload.action == 'DELETE') {
      delete cartEntriesData[key];
    } else {
      cartEntriesData[key] = action.payload;
    }
    return { ...state, cartEntriesData: cartEntriesData };
  default:
    return state;

  }

}

export function customerReducer(state: CustomerState, action: any) {
  switch (action.type) {
  case SET_CUSTOMER_SPLIT_BILLER:
    return { ...state, splitBillerAuto: action.payload };
  default:
    return state;
  }
}

export function fffUser (state: FFFUser, a: any) {
  const action = JSON.parse(JSON.stringify(a));
  switch (action.type) {
  case SET_USER:
    return { ...state, user: action.payload };
  default:
    return state;
  }
}

export function selectFilterReducer(state: any = '', a: any) {
  const action = JSON.parse(JSON.stringify(a));
  switch (action.type) {
  case FILTER_PRICELIST_BY_CATEGORY:
    return { ...state.Filter, priceListByCategory: action.payload };
  case FILTER_PRICELIST_BY_CATEGORY_APPLIED:
    return { ...state.Filter, priceListByCategoryApplied: action.payload };
  default:
    return state;
  }
}

export function addProductFocused(state: ProductFocusState, a: any) {
  const action = JSON.parse(JSON.stringify(a));
  switch (action.type) {

  case ADD_PRODUCT_FOCUSED: {
    let listProd = state ? JSON.parse(JSON.stringify(state.productList)) : [];
    const existProd = listProd.find((p: any) => p.key == action.payload.key);
    if (existProd) {
      existProd.price = action.payload.price;
      existProd.quantity = action.payload.quantity;
      existProd.unit = action.payload.unit;
      existProd.yconnector = action.payload.yconnector;
      existProd.adminSet = action.payload.adminSet;
      existProd.dropShip = action.payload.dropShip;
    } else {
      listProd.push(action.payload);
    }

    if ('CLEAR' == action.payload.action) {
      listProd = listProd.filter((p: any) => !p.key.endsWith(action.payload.key));
    }
    return { ...state, productList: listProd };
  }
  default:
    return state;

  }
}

export function selectMyAccountNavReducer(state: MyAccountNavState, action: any) {
  switch (action.type) {
  case ADD_SELECTED_MY_ACCOUNT_NAVNODE:
    return { ...state, selectedNav: action.payload };
  default:
    return state;
  }
}

export function selectUpdatedCartState(state: UpdatedCartState, action: any) {
  switch (action.type) {
  case UPDATED_CART:
    return { ...state, entry: action.payload };
  default:
    return state;
  }
}

export function selectProductStatus(state: ProductStockState, action: any) {
  switch (action.type) {
  case PRODUCT_STATUS:
    let data = state ? JSON.parse(JSON.stringify(state.data)) : {};
    if (!data[action.payload.code]) {
      data[action.payload.code] = {};
    }
    data[action.payload.code] = { code: action.payload.code, status: action.payload.status };
    return { ...state, data: data };
  default:
    return state;
  }
}

export function selectOrderConfirmationState(state: OrderConfirmationState, action: any) {
  switch (action.type) {
  case ORDER_CONFIRMATION_DATA:
    return { ...state, data: action.payload };
  default:
    return state;
  }
}

export function selectReturnConfirmationState(state: ReturnConfirmationState, action: any) {
  switch (action.type) {
  case RETURN_CONFIRMATION_DATA:
    return { ...state, data: action.payload };
  default:
    return state;
  }
}

export function selectProductStrength(state: ProductStrengthState, action: any) {
  switch (action.type) {
  case PRODUCT_STRENGTH:
    let data = state ? JSON.parse(JSON.stringify(state.data)) : {};
    if (!data[action.payload.key]) {
      data[action.payload.key] = {};
    }
    data[action.payload.key] = { key: action.payload.key, data: action.payload.data };
    return { ...state, data: data };
  default:
    return state;
  }
}

export function selectProductSpecialPrice(state: ProductSpecialPriceState, action: any) {
  switch (action.type) {
  case PRODUCT_SPECIAL_PRICE:
    let data = state ? JSON.parse(JSON.stringify(state.data)) : {};
    if (!data[action.payload.key]) {
      data[action.payload.key] = {};
    }
    data[action.payload.key] = { key: action.payload.key, data: action.payload.data };
    return { ...state, data: data };
  default:
    return state;
  }
}

export function selectAdminSet(state: ProductSpecialPriceState, action: any) {
  switch (action.type) {
  case PRODUCT_ADMIN_SET:
    let data = state ? JSON.parse(JSON.stringify(state.data)) : {};
    if (!data[action.payload.key]) {
      data[action.payload.key] = {};
    }
    data[action.payload.key] = { key: action.payload.key, data: action.payload.data };
    return { ...state, data: data };
  default:
    return state;
  }
}

export function selectEntryPo(state: EntryPoState, action: any) {
  switch (action.type) {
  case ENTRY_PO:
    let data = state ? JSON.parse(JSON.stringify(state.data)) : {};
    if (!data[action.payload.key]) {
      data[action.payload.key] = {};
    }

    data[action.payload.key] = { key: action.payload.key, data: action.payload.data };

    if ('CLEAR' == action.payload.action) {
      data = {};
    }
    return { ...state, data: data };
  default:
    return state;
  }
}

export const initialState: GlobalBannerMessageState = {
  entities: {},
};

export function getGlobalMessages( state = initialState, action: any): GlobalBannerMessageState {
  switch (action.type) {
  case GlobalBannerActions.ADD_BANNER_MESSAGE: {
    const message: GlobalBannerMessage = action.payload;

    if (state.entities[message.type] === undefined) {
      return {
        ...state,
        entities: {
          ...state.entities,
          [message.type]: [{
            id: message.id,
            text: message.text,
            type: message.type,
            isHTML: message.isHTML,
            isPersistent: message.isPersistent,
            isTranslatable: message.isTranslatable,
            prefix: message.prefix,
          }],
        },
      };
    } else {
      const currentMessages: GlobalBannerMessage[] = state.entities[message.type];
      return {
        ...state,
        entities: {
          ...state.entities,
          [message.type]: [...currentMessages, {
            id: message.id,
            text: message.text,
            type: message.type,
            isHTML: message.isHTML,
            isPersistent: message.isPersistent,
            isTranslatable: message.isTranslatable,
            prefix: message.prefix,
          }],
        },
      };
    }
  }
  case GlobalBannerActions.REMOVE_BANNER_MESSAGE: {
    const msg: GlobalBannerMessage = action.payload;
    const msgType: GlobalMessageType = action.payload.type;
    if (
      Object.keys(state.entities).length === 0 ||
        !state.entities[msgType]
    ) {
      return state;
    }

    const messages = [...state.entities[msgType]];
    if(!msg.isPersistent){
      messages.forEach((message, i) => {
        if(message.id === msg.id) {
          messages.splice(i, 1);
        }
      });
    }

    return {
      ...state,
      entities: {
        ...state.entities,
        [msgType]: messages,
      },
    };
  }

  case GlobalBannerActions.REMOVE_BANNER_MESSAGES_BY_TYPE: {
    const entities: GlobalBannerMessageEntities = {
      ...state.entities,
      [action.payload]: [],
    };
    return {
      ...state,
      entities,
    };
  }
  }

  return state;
}
