import { Component, Input } from '@angular/core';
import { FffProfile } from '@app/models/fff-profile.model';
import { FffDrawerService } from '@app/shared/drawer/fff-drawer.service';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { FffNewReportAccountSelectDrawerComponent } from '../fff-new-report-account-select-drawer/fff-new-report-account-select-drawer.component';

@Component({
  selector: 'fff-new-report-account-select',
  templateUrl: './fff-new-report-account-select.component.html',
  styleUrls: ['./fff-new-report-account-select.component.scss'],
})
export class FffNewReportAccountSelectComponent {
  @Input()
  isMultiAccount!: boolean;
  @Input() profile!: FffProfile;

  fffSelectAccountDrawer: NgbModalRef | null = null;

  constructor(
    private modalService: NgbModal,
    private drawerService: FffDrawerService
  ) {}

  openDrawer() {
    if (!this.fffSelectAccountDrawer) {
      this.drawerService.closeDrawer();
      this.fffSelectAccountDrawer = this.modalService.open(
        FffNewReportAccountSelectDrawerComponent,
        {
          modalDialogClass: 'fff-drawer',
          windowClass: 'fff-window',
          animation: true,
        }
      );
      this.fffSelectAccountDrawer.componentInstance.profile = this.profile;
      this.fffSelectAccountDrawer.componentInstance.isMultiAccount =
        this.isMultiAccount;
      this.fffSelectAccountDrawer.result
        .then(() => {
          this.cleanDrawer();
        })
        .catch(() => {
          this.cleanDrawer();
        });
    }
  }

  private cleanDrawer() {
    this.fffSelectAccountDrawer = null;
  }
}
