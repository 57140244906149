import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { FffCommunicationService } from '@app/fff-enterprise/fff-common-services/fff-communication.service';
import { FffPrebookService } from '@app/fff-enterprise/fff-common-services/fff-prebook.service';
import { FFFActiveCartService } from '@app/fff-enterprise/fff-custom-cart/fff-active-cart-service';
import { FffApplicationConfigService } from '@app/shared/services/fff-application-config.service';
import { MultiCartService } from '@spartacus/cart/base/core';
import { OrderEntry } from '@spartacus/cart/base/root';
import { BaseSiteService, RoutingService } from '@spartacus/core';
// import { BaseSiteService, MultiCartService, OrderEntry, RoutingService } from '@spartacus/core';
import { FffPrebookCartService } from '@app/fff-enterprise/fff-prebook-category/services/fff-prebook-cart.service';
import { fffPrebook } from '@assets/translations/en/fff-prebook';
import { combineLatest, Observable, of, Subject, Subscription } from 'rxjs';
import { filter, map, switchMap, take, takeUntil } from 'rxjs/operators';
import { CUSTOM_ICON_TYPE } from 'src/app/models/fff-custom-icons.model';

@Component({
  selector: 'fff-prebook-cart-complete-page',
  templateUrl: './fff-prebook-cart-complete-page.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FffPrebookCartCompletePageComponent implements OnInit, OnDestroy {
  customIconTypes = CUSTOM_ICON_TYPE;

  private unsubscribe$: Subject<void> = new Subject<void>();
  activeCart$ = this.activeCartService.getActive();
  entries$ = this.activeCartService
    .getEntries()
    .pipe(takeUntil(this.unsubscribe$));
  activeSite$ = this.baseSiteService.getActive().pipe(take(1));
  isStableCart$ = this.activeCartService
    .isStable()
    .pipe(takeUntil(this.unsubscribe$));

  cartData$: Observable<any> = this.getCartData();
  subs = new Subscription();
  orderSummaryTitle!: string;

  updatableEntries$ = this.fffPrebookCartService.getUpdatableEntries();

  constructor(
    private fffCommunicationService: FffCommunicationService,
    private activeCartService: FFFActiveCartService,
    private multiCartService: MultiCartService,
    protected routingService: RoutingService,
    private baseSiteService: BaseSiteService,
    private fffApplicationConfigService: FffApplicationConfigService,
    private preBookService: FffPrebookService,
    private fffPrebookCartService: FffPrebookCartService
  ) {}

  ngOnInit(): void {
    this.activeCart$
      .pipe(
        filter(cart => !!cart?.code),
        take(1),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((cart: any) => {
        this.activeCartService
          .getUpdatedCartATP(cart.code!)
          .pipe(take(1), takeUntil(this.unsubscribe$))
          .subscribe(cart =>
            this.multiCartService.loadCart({
              cartId: cart.code,
              userId: 'current',
              extraData: { active: true },
            })
          );
      });

    this.subs.add(
      this.fffApplicationConfigService.loadApplicationProperties().subscribe()
    );
    this.preBookService.getAppProperties();
    this.preBookService.getSeasonData().subscribe(data => {
      this.orderSummaryTitle = data + ' ' + fffPrebook.orderSummary;
    });

    this.subs.add(this.fffPrebookCartService.resetUpdatableEntries());
  }

  protected getCartData(): Observable<any> {
    const priceData$ = this.activeCart$
      .pipe(
        switchMap(cart => {
          if (cart && cart.code) {
            return this.fffCommunicationService.getCartPrices(cart.code);
          } else {
            return of({});
          }
        })
      )
      .pipe(take(1));

    const stockData$ = this.entries$
      .pipe(
        switchMap((entries: OrderEntry[]) => {
          if (entries.length > 0) {
            const productCodes = [
              ...new Set(entries.map(entry => entry.product?.code)),
            ];
            const observables = productCodes.map(p =>
              this.fffCommunicationService
                .getStockAndAlloc(p)
                .pipe(
                  map(resultData => {
                    return { productCode: p, result: resultData };
                  })
                )
                .pipe(take(1))
                .pipe(takeUntil(this.unsubscribe$))
            );
            return combineLatest(observables);
          } else {
            return of([]);
          }
        })
      )
      .pipe(take(1));

    return combineLatest([priceData$, stockData$]).pipe(
      map(([priceData, stockData]) => {
        return {
          priceData: priceData,
          stockData: stockData,
        };
      })
    );
  }

  goToHomePage(): void {
    this.routingService.go('/');
  }

  ngOnDestroy(): void {
    if (this.unsubscribe$) {
      this.unsubscribe$.unsubscribe();
    }
    this.subs.unsubscribe();
    this.activeCartService.clearAfterStableActions();
  }
}
