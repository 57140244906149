import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { FffCheckoutService } from '@app/fff-enterprise/fff-common-services/fff-checkout.service';
import { FffCommunicationService } from '@app/fff-enterprise/fff-common-services/fff-communication.service';
import { SHIPPING_CONDITIONS_INCO_TYPES } from '@app/models/fff-checkout.model';
import { OUTLINED_ICON_TYPE } from '@app/models/fff-outline-icons.model';
import { FffProfile } from '@app/models/fff-profile.model';
import { ENTRY_PO, EntryPoState } from '@app/reducers';
import { FffDrawerService } from '@app/shared/drawer/fff-drawer.service';
import { FFFCommonFunctions } from '@app/shared/fff-common-functions';
import { FffUserAccountService } from '@app/shared/services/fff-user-account.service';
import { BASE_URL_KEYS } from '@config/content/constants';
import { FFFCart } from '@model/fff-cart-data.model';
import { Store } from '@ngrx/store';
import { ActiveCartFacade } from '@spartacus/cart/base/root';
import { BaseSiteService } from '@spartacus/core';
import { CmsComponentData } from '@spartacus/storefront';
import moment from 'moment-timezone';
import { take } from 'rxjs/operators';
import { FffEstimatedDeliveryComponent } from './fff-estimated-delivery/fff-estimated-delivery.component';
@Component({
  selector: 'fff-shipping-select',
  templateUrl: './fff-shipping-select.component.html',
})
export class FffShippingSelectComponent implements OnInit {
  constructor(
    private component: CmsComponentData<any>,
    public fffActiveCartService: ActiveCartFacade,
    public checkoutService: FffCheckoutService,
    public changeDetectorRef: ChangeDetectorRef,
    public communicationService: FffCommunicationService,
    public drawerService: FffDrawerService,
    private storeEntryPo: Store<EntryPoState>,
    public baseSiteService: BaseSiteService,
    private fffAccountService: FffUserAccountService
  ) {}

  @Input() item: any;
  @Input() pos: any;
  @Input() poNumber: any;
  @Input() cart: FFFCart | undefined;

  @Output() checkout: any = new EventEmitter<any>();
  @Input() poRules: any = {};

  currentTime: any = new Date().getTime();
  orderStartTime: any;
  orderCutOffTime: any;
  deliveryOptions: any[] = [];
  loadingOptions: boolean = false;
  outlinedIconTypes = OUTLINED_ICON_TYPE;
  arrivalDate: any;
  baseArrivalDate: any;
  firstArrivalDate: any;
  baseShipDate: any;
  currentCart: any;
  baseSite: string | undefined;
  upgradeOptions: any = [];
  openDropdown: boolean = false;
  dropdownChanged = true;
  checkedOption: any;
  BASE_URL_KEYS = BASE_URL_KEYS;
  mainPoNumber: string = '';
  shippingWindows: any[] = [];
  currentShippingWindowsData: any = {};
  profile: FffProfile | undefined;
  isOfVaccineTypeI = FFFCommonFunctions.isOfVaccineType;

  async ngOnInit() {
    this.baseSiteService.getActive().subscribe(res => {
      this.baseSite = res;
    });

    this.fffActiveCartService.getActive().subscribe(res => {
      this.currentCart = res.code;
    });

    this.item = { ...this.item };
    this.item.sameDayShipping = false;
    this.baseShipDate = this.getShippingDates('shipDate');
    this.baseArrivalDate = this.getShippingDates('arrivalDate');
    this.firstArrivalDate = this.baseArrivalDate;

    this.component.data$.subscribe(res => {
      this.orderStartTime = +res.orderStartTime;
      this.orderCutOffTime = +res.orderCutOffTime;
    });

    this.setMainPoNumberByDefaultInShippingWindows();

    this.checkoutService.checkoutForm.subscribe((res: any) => {
      if (res && res.status !== 'INVALID') {
        this.mainPoNumber = res.value.poNumber;
        this.setMainPoNumberByDefaultInShippingWindows();
      }
    });

    this.storeEntryPo
      .select((state: any) => {
        return state.EntryPo;
      })
      .subscribe(shippingWindows => {
        if (shippingWindows.data[this.item.entryNumber]) {
          let swData = shippingWindows.data[this.item.entryNumber].data;
          this.currentShippingWindowsData = swData.prebookShippingWindows;
        }
      });
    this.fffAccountService.getProfile().subscribe(res => {
      this.profile = res;
    });
  }

  private setMainPoNumberByDefaultInShippingWindows(): void {
    this.shippingWindows = [];
    this.item.product?.productPreBookConfiguration?.shippingWindows.forEach(
      (sw: any) => {
        let shWindow = { ...sw };
        shWindow.poNumber = this.mainPoNumber;
        shWindow.entryNumber = this.item.entryNumber;
        shWindow.productCode = this.item.product.code;
        this.shippingWindows.push(shWindow);
      }
    );
  }

  onSameDayCheckboxCheck(event: any, item: any) {
    this.updateShipTodayStatus(item);
    this.triggerDropdown(event);
    this.calculateArrivalDate();
  }

  getShippingDates(shipOrArrival: any) {
    //which variables do I have to look for based on if we are looking for the shipDate or the arrivalDate

    let dictionary: any = {
      shipDate: ['shippingWindowStartDate', 'shipDate'],
      arrivalDate: ['shippingWindowEndDate', 'estArrivalDate'],
    };

    let e = dictionary[shipOrArrival];

    if (!this.cart?.prebookCart && this.isRSVType() && this.item[e[1]]) {
      return this.item[e[1]];
    } else if (this.hasProductPreBookConfiguration()) {
      return (
        this.item.product.productPreBookConfiguration.shippingWindows &&
        this.item.product.productPreBookConfiguration.shippingWindows.length >
          0 &&
        this.item.product.productPreBookConfiguration.shippingWindows[0][e[0]]
      );
    } else if (this.item[e[1]]) {
      return this.item[e[1]];
    }
    return 'TBD';
  }

  async getPossibleShippingMethods() {
    this.deliveryOptions = [];
    this.loadingOptions = true;
    let date = this.getShippingDate();
    let possibleUpgrades: any = [];
    let shippingMethodCode = this.item.shippingMethodCode;
    try {
      possibleUpgrades = await this.checkoutService
        .getUpgradeShippingOptions(this.item.product.code, shippingMethodCode)
        .toPromise();
    } catch (e) {
      console.log(e);
    }

    //formatting the upgrade options to look like [this.item.shippingMethodCode, 'F2']
    this.upgradeOptions = possibleUpgrades.upgradeCode
      ? possibleUpgrades.upgradeCode.map((e: any) => {
          return e.code;
        })
      : [];

    if (this.canDisplayShipTodayCheckbox(this.item)) {
      this.upgradeOptions.push(this.item.shippingMethodCode);
    }

    const r = await this.checkoutService
      .getShippingOptions({
        cartId: this.currentCart,
        position: this.item.entryNumber,
        date,
        warehouse: this.item.warehouse,
        uom: this.item.uom,
        inco: SHIPPING_CONDITIONS_INCO_TYPES.COL,
      })
      .toPromise();

    if (
      this.item.shippingMethodCode.startsWith('P') &&
      this.item &&
      this.item.product &&
      this.item.product.schdCatType &&
      this.item.product.schdCatType.replace(' ', '').startsWith('C')
    ) {
      if (
        this.upgradeOptions &&
        this.upgradeOptions.find((code: any) => code.startsWith('P')) &&
        shippingMethodCode.startsWith('P')
      ) {
        shippingMethodCode = shippingMethodCode.replace('P', 'F');
        this.upgradeOptions = this.upgradeOptions.map((code: any) =>
          code.startsWith('P') ? code.replace('P', 'F') : code
        );
      }
    }

    //remove the options which are not included in the list
    if (shippingMethodCode) {
      //finding the base shipping method, removing it and putting it first.
      let baseShippingMethod = r.results.find(
        (elem: any) => elem.shippingCodeSAP === shippingMethodCode
      );
      if (baseShippingMethod) {
        r.results.splice(r.results.indexOf(baseShippingMethod), 1);
        r.results.unshift(baseShippingMethod);
      }
      this.deliveryOptions = r.results.filter((elem: any) =>
        this.isPossibleDelivery(elem)
      );
    }

    //we no longer need to refetch the options if we click on the dropdown, so we set dropdownChanged to false.
    this.dropdownChanged = false;
    this.loadingOptions = false;
    this.changeDetectorRef.detectChanges();
  }

  isPossibleDelivery(deliveryOption: any): boolean {
    if (this.upgradeOptions && this.upgradeOptions.length > 0) {
      return this.upgradeOptions.includes(deliveryOption.shippingCodeSAP);
    }
    return true;
  }

  getShippingDate() {
    let today = this.currentTime;
    let tomorrow = new Date().getTime() + 86400 * 1000; //milliseconds in a day.
    let shippingDate = today;

    if (today > this.orderStartTime && today < this.orderCutOffTime) {
      shippingDate = this.item.sameDayShipping ? today : tomorrow;
    }

    if (today > this.orderCutOffTime) {
      shippingDate = tomorrow;
    }

    let pt = this.transformUnixTimeToPSTDate(shippingDate);
    let removeDashesAndSlashes = new RegExp(/[\/-]/g);
    let date = pt.split(',')[0].replace(removeDashesAndSlashes, '');

    return date;
  }

  transformUnixTimeToPSTDate(time: number): string {
    const pstTime = moment.tz(time, 'America/Los_Angeles').format('YYYYMMDD');
    return pstTime;
  }

  check(delivery: any, item: any, i: any) {
    const shippingCodeSAP = delivery.shippingCodeSAP || i;
    return (
      this.checkedOption ===
      'radio-' + item.entryNumber + '-sm' + shippingCodeSAP
    );
  }

  isBetweenOrderCutoffTime(): boolean {
    return this.currentTime <= this.orderCutOffTime;
  }

  changeCheckStatus(d: any, item: any, i: any) {
    if (!d) {
      return;
    }
    //item.isSameDayShippingDisabled = false;
    const shippingCodeSAP = d.shippingCodeSAP || i;
    const isShipTodayEnabled = this.canDisplayShipTodayCheckbox(item);

    if (isShipTodayEnabled) {
      // If ship-today checkbox is not checked, auto-select the checkbox & disable it
      if (!item.sameDayShipping) {
        item.sameDayShipping = true;
        item.isSameDayShippingDisabled = true;
      }

      // On de-select the shipping option, un-check the ship-today checkbox before making a request
      if (
        this.checkedOption ===
        'radio-' + item.entryNumber + '-sm' + shippingCodeSAP
      ) {
        item.sameDayShipping = false;
      }

      this.changeDetectorRef.markForCheck();
    }

    const shipToday = isShipTodayEnabled
      ? item.sameDayShipping
        ? true
        : false
      : null;

    //if there's a checked item and emit values to product description and cart overview, remove it, and viceversa.
    if (
      this.checkedOption ===
      'radio-' + item.entryNumber + '-sm' + shippingCodeSAP
    ) {
      this.checkedOption = null;

      if (isShipTodayEnabled && item.isSameDayShippingDisabled) {
        item.isSameDayShippingDisabled = false;
      }

      this.arrivalDate = this.baseArrivalDate;
      this.checkout.emit({
        price: '$0.00',
        entryNumber: item.entryNumber,
      });
      this.communicationService
        .updateShippingCost(
          this.currentCart,
          item.entryNumber,
          0,
          null,
          shipToday
        )
        .subscribe(res => {
          this.postUpdateShipping(res);
        });
    } else {
      this.checkedOption =
        'radio-' + item.entryNumber + '-sm' + shippingCodeSAP;
      let moment = d.estimatedDeliveryDate.split('(')[1].split(')')[0];
      this.arrivalDate = new Date(+moment);
      this.checkout.emit({
        price: '$' + d.internalCost,
        entryNumber: item.entryNumber,
      });
      let shippingMethodCode: string = d.shippingCodeSAP;
      if (
        this.item.shippingMethodCode.startsWith('P') &&
        this.item &&
        this.item.product &&
        this.item.product.schdCatType &&
        this.item.product.schdCatType.replace(' ', '').startsWith('C')
      ) {
        if (
          this.upgradeOptions &&
          this.upgradeOptions.find((code: any) => code.startsWith('F')) &&
          shippingMethodCode.startsWith('F')
        ) {
          shippingMethodCode = shippingMethodCode.replace('F', 'P');
        }
      }

      this.communicationService
        .updateShippingCost(
          this.currentCart,
          item.entryNumber,
          d.internalCost,
          shippingMethodCode,
          shipToday
        )
        .subscribe(res => {
          this.postUpdateShipping(res);
        });
    }

    this.changeDetectorRef.markForCheck();
  }

  postUpdateShipping(response: any) {
    this.communicationService.sendUpdateCartMessage();
    this.fffActiveCartService.reloadActiveCart();
    this.updateEstArrivalDate(response);
  }

  private updateEstArrivalDate(responseData: any): void {
    this.baseArrivalDate = responseData.estArrivalDate;
    this.changeDetectorRef.detectChanges();
  }

  calculateArrivalDate() {
    if (this.item.sameDayShipping) {
      if (
        this.profile &&
        this.profile.selected &&
        this.profile.selected.checkCustomerDays &&
        this.profile.selected.customerDays
      ) {
        const customerDays = this.profile.selected.customerDays;
        const currentDayOfWeek = new Date().getDay();
        const weekdays = customerDays.split(',').map(day => day.trim());
        const todayWeekday = this.getDayOfWeekAsString(currentDayOfWeek);
        if (
          weekdays &&
          weekdays.length > 0 &&
          !weekdays.includes(todayWeekday)
        ) {
          // If today's weekday is not in customerDays, increase estimated day by one day
          const arrivalDate: Date = new Date(this.baseArrivalDate);
          arrivalDate.setDate(arrivalDate.getDate() + 1);
          const updatedDateString = this.formatDate(arrivalDate);
          this.baseArrivalDate = updatedDateString;
          // Recursively call the function until a matching weekday is found
          //return this.calculateArrivalDate();
        }
      }
    } else {
      this.baseArrivalDate = this.firstArrivalDate;
    }
  }

  private getDayOfWeekAsString(dayIndex: number): string {
    const weekdays = [
      'Sunday',
      'Monday',
      'Tuesday',
      'Wednesday',
      'Thursday',
      'Friday',
      'Saturday',
    ];
    return weekdays[dayIndex];
  }
  private formatDate(date: Date): string {
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are zero based
    const day = date.getDate().toString().padStart(2, '0');
    return `${year}-${month}-${day}`;
  }

  sameDayShippingCheck() {
    return (
      this.currentTime > this.orderStartTime &&
      this.currentTime < this.orderCutOffTime &&
      !this.item.sameDayShipping
    );
  }

  viewDates(entryNumber: number) {
    let deliveryObj: any = {
      qty: this.item.quantity,
      po: this.item.po,
      isOverThreshold: false,
      entryNumber: entryNumber,
      basicUOM: this.item.uom,
    };

    if (this.isOverThreshold()) {
      deliveryObj.prebook = {
        ...this.item.product.productPreBookConfiguration,
      };
      deliveryObj.isOverThreshold = true;

      if (deliveryObj.prebook?.shippingWindows) {
        if (this.objectHasData(this.currentShippingWindowsData)) {
          let shippingWindows: any[] = [];
          this.currentShippingWindowsData.forEach((sw: any) => {
            shippingWindows.push({ ...sw });
          });
          // Fix quantities
          this.shippingWindows = this.splitQuantitiesByShippingWindow(
            this.item.quantity,
            this.shippingWindows
          );
          deliveryObj.prebook.shippingWindows = shippingWindows;
        } else {
          // Fix quantities
          this.shippingWindows = this.splitQuantitiesByShippingWindow(
            this.item.quantity,
            this.shippingWindows
          );
          deliveryObj.prebook.shippingWindows = this.shippingWindows;
        }
      }
    } else {
      if (this.objectHasData(this.currentShippingWindowsData)) {
        let dataForSingleItem = this.currentShippingWindowsData.find(
          (shippingWindow: any) =>
            shippingWindow.entryNumber === this.item.entryNumber
        );
        if (dataForSingleItem.poNumber) {
          deliveryObj.po = dataForSingleItem.poNumber;
        } else {
          deliveryObj.po = this.mainPoNumber;
        }
      } else {
        deliveryObj.po = this.mainPoNumber;
      }
    }

    this.drawerService.setContent({
      title: 'Estimated delivery window',
      component: FffEstimatedDeliveryComponent as Component,
      class: 'minicart-drawer',
      drawerClass: 'fff-drawer-estimated-delivery-windows',
      animation: 'SideRTL',
      data: {
        baseShipDate: this.baseShipDate,
        baseArrivalDate: this.baseArrivalDate,
        delivery: deliveryObj,
        poRules: this.poRules,
      },
    });

    this.drawerService.openDrawer();
    this.drawerService.getDrawerResponse
      .pipe(take(1))
      .subscribe((result: any) => {
        let prebookShippingWindows: any = {
          prebookShippingWindows: [],
        };

        if (result.delivery.isOverThreshold) {
          prebookShippingWindows.prebookShippingWindows =
            result.delivery.prebook.shippingWindows;
        } else {
          let shippingWindowUsed = {
            shippingWindowEndDate: result.baseArrivalDate,
            shippingWindowStartDate: result.baseShipDate,
            poNumber: result.delivery.po,
            entryNumber: this.item.entryNumber,
            productCode: this.item.product.code,
          };
          prebookShippingWindows.prebookShippingWindows = [shippingWindowUsed];
          this.item.po = result.delivery.po;
        }

        this.storeEntryPo.dispatch({
          type: ENTRY_PO,
          payload: {
            key: result.delivery.entryNumber,
            action: 'ADD',
            data: prebookShippingWindows,
          },
        });
      });
  }

  private objectHasData(data: any): boolean {
    return data && Object.keys(data).length > 0;
  }

  isOverThreshold() {
    return (
      this.item.product.productPreBookConfiguration &&
      this.item.product.productPreBookConfiguration.shippingWindowThreshold <
        this.item.quantity
    );
  }

  async triggerDropdown(e: any) {
    this.dropdownChanged = true;
    this.openDropdown = e;

    if (e) {
      //get the new options, wait for them, and add the default option.
      await this.getPossibleShippingMethods();
      //find the default shipping option, which by requirement, is always the base one
      const defaultOption = this.deliveryOptions[0];
      //set it as the default checked one.
      this.changeCheckStatus(
        defaultOption,
        this.item,
        this.deliveryOptions.indexOf(defaultOption)
      );
      this.changeDetectorRef.markForCheck();
    }

    if (!e) {
      //no need to wait for the shipping options to clear the shipping options :)
      this.clearShippingOptions();
      await this.getPossibleShippingMethods();
    }
  }

  toggleDropdown() {
    this.openDropdown = !this.openDropdown;

    if (this.dropdownChanged) {
      this.getPossibleShippingMethods();
    }
  }

  clearShippingOptions() {
    this.checkedOption = null;
    this.arrivalDate = this.baseArrivalDate;
    this.checkout.emit({
      price: '$0.00',
      entryNumber: this.item.entryNumber,
    });

    this.communicationService
      .updateShippingCost(this.currentCart, this.item.entryNumber, 0)
      .subscribe(res => {
        this.communicationService.sendUpdateCartMessage();
      });
  }

  updateShipTodayStatus(item: any) {
    this.communicationService
      .updateShippingCost(
        this.currentCart,
        this.item.entryNumber,
        null,
        null,
        item.sameDayShipping,
        this.baseArrivalDate
      )
      .subscribe(res => {
        this.postUpdateShipping(res);
      });
  }

  canDisplayDropdownAndCheckbox(item: any, isOfCheckboxType: boolean) {
    const isShipTodayCheckboxVisible = item.overweight
      ? !item.overweightPastCutoff
      : item.showShipToday;
    const canDisplay = isOfCheckboxType
      ? isShipTodayCheckboxVisible
      : !item.overweightPastCutoff;
    const isValidStatus =
      ['IN_TRANSIT', 'ON_DEMAND', 'DROP_SHIP'].includes(
        item.sapStockLevelStatus
      ) === false;
    const isNotRapidCommit = this.cart && !this.cart.rapidCommit;

    return (
      this.baseSite === BASE_URL_KEYS.BIOSUPPLY &&
      item.totalPrice?.value > 0.01 &&
      canDisplay &&
      isValidStatus &&
      isNotRapidCommit
    );
  }

  canDisplayShipTodayCheckbox(item: any) {
    return this.canDisplayDropdownAndCheckbox(item, true);
  }

  canDisplayShippingOptionsDropdown(item: any) {
    return this.canDisplayDropdownAndCheckbox(item, false);
  }

  splitQuantitiesByShippingWindow(
    quantity: number,
    shippingWindows: any
  ): any[] {
    let res = 0;
    let shippingWindowsCalculated: any[] = [];
    if (shippingWindows && shippingWindows.length > 0) {
      shippingWindows.forEach((sw: any) => {
        sw = { ...sw };
        let calculatedValue = (quantity * sw.shippingWindowPercentage) / 100;
        sw.quantityCalculated = Math.floor(calculatedValue);
        res += calculatedValue - sw.quantityCalculated;
        shippingWindowsCalculated.push(sw);
      });
      shippingWindowsCalculated[0].quantityCalculated += res;
    }
    return shippingWindowsCalculated;
  }

  isDeliveryWindowVisible(): boolean {
    var isDeliveryWindowVisible = false;
    if (this.hasProductPreBookConfiguration()) {
      if (this.isRSVType()) {
        if (
          this.cart?.prebookCart &&
          this.baseSite === BASE_URL_KEYS.MY_FLU_VACCINE
        ) {
          isDeliveryWindowVisible = true;
        }
      } else {
        isDeliveryWindowVisible = true;
      }
    }
    return isDeliveryWindowVisible;
  }

  hasProductPreBookConfiguration(): boolean {
    return (
      this.item.product.productPreBookConfiguration &&
      !!this.item.product.productPreBookConfiguration.active
    );
  }

  isRSVType(): boolean {
    return this.item?.product?.categories?.find(
      (category: any) =>
        category?.code === 'VACCNVACCN000P2400' ||
        category?.name === 'Vaccines - RSV'
    );
  }
}
