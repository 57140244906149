import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class FffUserPriceListService {
  private _areFiltersLoaded$ = new BehaviorSubject<boolean>(false);
  private _productTypes: any[] = [];
  private _loading$ = new BehaviorSubject<boolean>(false);

  private _appliedFilters: string[] = [];

  constructor() {}

  areFiltersLoaded() {
    return this._areFiltersLoaded$.asObservable();
  }

  toggleFiltersState(isLoaded: boolean) {
    this._areFiltersLoaded$.next(isLoaded);
  }

  isLoading() {
    return this._loading$.asObservable();
  }

  toggleLoadingState(loading: boolean) {
    this._loading$.next(loading);
  }

  getProductTypes(): any[] {
    return this._productTypes;
  }

  setProductTypes(options: any[]) {
    this._productTypes = [...options];
  }

  getAppliedFilters(): string[] {
    return this._appliedFilters;
  }

  setAppliedFilters(appliedFilters: string[] = []) {
    this._appliedFilters = [...appliedFilters];
  }

  clearAppliedFilters(): void {
    this._appliedFilters = [];
  }
}
