<div class="d-flex flex-column justify-content-between h-100">

    <form [formGroup]="form">

        <div class="orderHistoryContainer">

            <label class="summary-row position-relative">
                <section class="filter-box">
                    <label class="searchbox btn-sm w-100 mb-0 d-flex align-items-center" for="search">
                        <input id="search" autocomplete="off"
                            [placeholder]="'fffApprovalsFilterHistory.onPlaceHolder' | cxTranslate"
                            formControlName="b2bunit">
                        <div class="search-icon">
                            <cx-icon class="cx-icon icon-sm" [type]="outlinedIconTypes.ICON_SEARCH"></cx-icon>
                        </div>
                    </label>
                </section>
            </label>

            <p class="mb-0 approval-filter mt-4 requesteedBy">{{'fffApprovalsFilterHistory.action' | cxTranslate}}
            </p>
            <select class="form-control form-control-md form-select" formControlName="action">
                <option value="All">{{'fffApprovalsFilterHistory.all' | cxTranslate}}</option>
                <option value="APPROVED">{{'fffApprovalsFilterHistory.approved' | cxTranslate}}</option>
                <option value="REJECTED">{{'fffApprovalsFilterHistory.rejected' | cxTranslate}}</option>
            </select>

        </div>
    </form>

    <div class="minicart-footer-container">
        <button class="btn btn-primary btn-xs w-100" (click)="apply()">
            {{'fffApprovalsFilterHistory.apply' | cxTranslate}}
        </button>
    </div>


</div>