
import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FFFCartService } from '@app/fff-enterprise/fff-common-services/fff-cart.service';
import { CUSTOM_ICON_TYPE } from '@app/models/fff-custom-icons.model';
import { FILLED_ICON_TYPE } from '@app/models/fff-filled-icons.model';
import { OUTLINED_ICON_TYPE } from '@app/models/fff-outline-icons.model';
import { ORDER_CONFIRMATION_DATA, OrderConfirmationState } from '@app/reducers';
import { FffUserAccountService } from '@app/shared/services/fff-user-account.service';
import { FffCommunicationService } from '@enterprise/fff-common-services/fff-communication.service';
import { FFFActiveCartService } from '@enterprise/fff-custom-cart/fff-active-cart-service';
import {
  FFF_CART_REMOVE_ENTRY_SUCCESS
} from '@enterprise/fff-custom-cart/fff-cart-entry.action';
import { FffProfile } from '@model/fff-profile.model';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { MultiCartFacade } from '@spartacus/cart/base/root';
import { RoutingService, User } from '@spartacus/core';
import { UserProfileFacade } from '@spartacus/user/profile/root';
import { Observable, Subject, combineLatest, of } from 'rxjs';
import { switchMap, take, takeUntil } from 'rxjs/operators';


@Component({
  selector: 'fff-jefferson-cart-summary',
  templateUrl: './fff-jefferson-cart-summary.component.html',
})
export class FffJeffersonCartSummaryComponent implements OnInit, OnDestroy {

  @Input() cart: any;
  @Input() profile: FffProfile | undefined;
  private unsubscribe$: Subject<void> = new Subject<void>();
  obligatoryPo = true;
  user$ = this.userProfile.get();
  isDuplicate$: Observable<any> | undefined;

  profile$ = this.fffAccountService.getProfile();
  poRules: any;
  poNumber: string = '';
  maxLength: any;
  jeffersonCheckoutForm: FormGroup | undefined;
  regex: any;
  regexNoSpecial = new RegExp(`^[A-Za-z0-9- ]+$`);

  loading: boolean = false;
  outlinedIconTypes = OUTLINED_ICON_TYPE;
  filledIconTypes = FILLED_ICON_TYPE;

  customIconTypes = CUSTOM_ICON_TYPE;
  constructor(
    protected formBuilder: FormBuilder,
    protected userProfile: UserProfileFacade,
    private fffCartService: FFFCartService,
    private routingService: RoutingService,
    private store: Store<OrderConfirmationState>,
    private fffCommunicationService: FffCommunicationService,
    private multiCartService: MultiCartFacade,
    private actions$: Actions,
    private fffActiveCartService: FFFActiveCartService,
    private fffAccountService: FffUserAccountService,
    private changeDetectorRef: ChangeDetectorRef,
  ) { }

  ngOnInit() {
    this.checkJeffersonRemovedEntry();
    this.profile$.subscribe((account) => {
      if (account) {
        this.fffCommunicationService.getPOValidation(account.selected.uid).subscribe((res: any) => {
          if (res) {
            this.poRules = res;
            this.changeDetectorRef.detectChanges();
          }
        });
      }
    });
    this.maxLength = this.poRules && this.poRules.maxCharacters ? this.poRules.maxCharacters : 20;

    let validators = [Validators.maxLength(this.maxLength), Validators.pattern(this.regexNoSpecial)];
    validators.push(Validators.pattern(this.regex), Validators.required);
    this.jeffersonCheckoutForm = this.formBuilder.group({
      poNumber: ['', validators]
    });
  }
  openCustomCarePhone() {
    if (window.innerWidth <= 767) {
      window.open('tel:+8008437477');
    }
  }

  submitJeffersonHealthOrder(cartCode: string, poNumber: any) {
    this.fffCommunicationService.placeJeffersonHealthOrder(cartCode, poNumber.value)
      .pipe(
        switchMap((res) => {
          if (!res) {
            return of(undefined);
          }
          return this.fffCartService.createCart('current').pipe(
            switchMap((newCart) => {
              if (newCart && newCart.code) {
                this.updateCurrentCart(newCart.code);
                return of(res);
              }
              return of(undefined);
            })
          );
        })
      )
      .subscribe((res) => {
        if (res) {
          res.orderRequests.forEach((order: any) => order.type = 'ORDER_REQUEST');
          this.goToOrderConfirmation({ orderRequests: res.orderRequests, orders: [], quotes: [] });
        }
      });
  }
  updateCurrentCart(cartCode: string) {
    this.multiCartService.loadCart({ cartId: cartCode, userId: 'current', extraData: { active: true } });
  }
  private goToOrderConfirmation(response: any): void {
    this.store.dispatch({ type: ORDER_CONFIRMATION_DATA, payload: response });
    this.fffCommunicationService.loadNotifications()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((notifications: any) => {
        this.fffCommunicationService.setNotifications(notifications);
      });
    this.routingService.go(['/order-confirmation']);
  }
  checkJeffersonRemovedEntry() {
    combineLatest([
      this.fffActiveCartService.getCartEvents()
        .pipe(
          takeUntil(this.unsubscribe$),
          ofType(FFF_CART_REMOVE_ENTRY_SUCCESS),
          take(1)
        ),
      this.fffActiveCartService.getRemovedEntry().pipe(takeUntil(this.unsubscribe$)),
    ])
      .subscribe(() => {
        if (this.profile && this.profile.selected.jeffersonUnit) {
          window.location.reload();
        }
      });
  }
  checkPoNumber(user: User): void {
    const poNumber = this.jeffersonCheckoutForm?.get('poNumber')?.value;
    if (poNumber) {
      this.isDuplicate$ = this.fffCommunicationService.getPoNumber(user, poNumber);
    }
  }
  formatStartingPOs(str: string) {
    return str.split(',').join(', ').replace(/(,\s)(?!.*,\s)/, ' or ');
  }
  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
