<button
  class="btn btn-primary btn-md px-4"
  [ngClass]="{ 'w-100': isPLPPage }"
  *ngIf="product && !product.notOrderable; else notOrderableTemplate"
  [class.updating]="loading"
  [disabled]="
    loading ||
    (globalCartRestriction$ | async) ||
    !readyToBuy ||
    verifyCartRestrictions() ||
    !product.purchasable ||
    invalidQuantities() ||
    invalidQtyAllocation() ||
    invalidBatchQuantities() ||
    isInvalidStrength(product) ||
    isProductSoldOut() ||
    differentSeason() ||
    rsvWithPrebookCart()
  "
  (click)="
    product && product.addToCartNotice && product.addToCartNotice !== ''
      ? showAddToCartNoticeComponent()
      : addToCart()
  "
>
  <span *ngIf="rapidCommitIsSuperCat$ | async; else orderNow">{{
    'fffProduct.lblReserveNow' | cxTranslate
  }}</span>
  <ng-template #orderNow>
    <span *ngIf="!isProductSoldOut(); else isSoldOut">{{
      'fffProduct.addToCart' | cxTranslate
    }}</span>
    <ng-template #isSoldOut>
      <span>{{ 'fffProduct.soldOut' | cxTranslate }}</span>
    </ng-template>
  </ng-template>
  <cx-icon
    *ngIf="loading"
    class="cx-icon icon-md ml-2"
    [type]="outlinedIconTypes.ICON_ARROW_REPEAT"
  ></cx-icon>
</button>

<ng-template #notOrderableTemplate>
  <button
    class="btn btn-primary btn-md px-4"
    (click)="showProductNotOrderableComponent()"
  >
    <span>{{ 'fffProduct.learnMore' | cxTranslate }}</span>
  </button>
</ng-template>
