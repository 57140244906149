import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { FFFActiveCartService } from '@app/fff-enterprise/fff-custom-cart/fff-active-cart-service';
import { FffRingCentralChatBotService } from '@app/fff-enterprise/fff-service-third-party/fff-ring-central-chat-bot.service';
import { FILLED_ICON_TYPE } from '@app/models/fff-filled-icons.model';
import { FffProfile } from '@app/models/fff-profile.model';
import { FffUserAccountService } from '@shared/services/fff-user-account.service';
import { CartItemListComponent } from '@spartacus/cart/base/components';
import {
  MultiCartFacade,
  OrderEntry,
  SelectiveCartFacade,
} from '@spartacus/cart/base/root';
import { UserIdService } from '@spartacus/core';
import { Observable } from 'rxjs';
import { map, startWith, tap } from 'rxjs/operators';
import { OUTLINED_ICON_TYPE } from 'src/app/models/fff-outline-icons.model';

@Component({
  selector: 'fff-cart-item-list',
  templateUrl: './fff-cart-item-list.component.html',
})
export class FffCartItemListComponent
  extends CartItemListComponent
  implements OnInit
{
  @Input() activeSite: string = '';
  @Input() prices: any[] = [];
  @Input() stock: any[] = [];
  @Input()
  isPrebook: boolean = false;
  eligibleProducts!: any[];
  nonEligibleProducts!: any[];
  outlinedIconTypes = OUTLINED_ICON_TYPE;
  filledIconTypes = FILLED_ICON_TYPE;
  isExpanded: boolean = true;
  profile$: Observable<FffProfile | undefined> =
    this.fffAccountService.getProfile();
  cartData: any = {};
  priceData: any[] = [];

  constructor(
    protected fffActiveCartService: FFFActiveCartService,
    selectiveCartService: SelectiveCartFacade,
    userIdService: UserIdService,
    multiCartService: MultiCartFacade,
    private fffAccountService: FffUserAccountService,
    cd: ChangeDetectorRef,
    private chatbotService: FffRingCentralChatBotService
  ) {
    super(
      fffActiveCartService,
      selectiveCartService,
      userIdService,
      multiCartService,
      cd
    );
  }

  ngOnInit() {
    super.ngOnInit();
    this.filterEligibleItems(this.items);
  }

  filterEligibleItems(items: any) {
    this.eligibleProducts = items.filter(
      (item: any) => item.product.isExcluded
    );
    this.nonEligibleProducts = items.filter(
      (item: any) => !item.product.isExcluded
    );
    this.cd.detectChanges();
  }

  getQuantity(c: any) {
    return c.get('quantity');
  }

  getControl(item: OrderEntry): Observable<UntypedFormGroup> {
    return this.form.get(this.getControlName(item))!.valueChanges.pipe(
      startWith(null),
      tap(value => {
        if (item.updateable && value && !this.readonly) {
          if (this.selectiveCartService && this.options.isSaveForLater) {
            this.selectiveCartService.updateEntry(
              value.entryNumber,
              value.quantity
            );
          } else if (this.cartId && this.userId) {
            this.multiCartService?.updateEntry(
              this.userId,
              this.cartId,
              value.entryNumber,
              value.quantity
            );
          } else {
            this.activeCartService.updateEntry(
              value.entryNumber,
              value.quantity
            );
          }
        }
      }),
      map(() => <UntypedFormGroup>this.form.get(this.getControlName(item)))
    );
  }
  openLiveChat(): void {
    this.chatbotService.openChatBot();
  }
  toggleExpand() {
    this.isExpanded = !this.isExpanded;
  }
}
