import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { I18nModule } from '@spartacus/core';
import { MediaModule } from '@spartacus/storefront';
import { FffMediaComponent } from './fff-media/fff-media.component';

@NgModule({
  declarations: [
    FffMediaComponent,
  ],
  imports: [
    CommonModule,
    I18nModule,
    MediaModule
  ],
  exports: [
    FffMediaComponent,
  ],
})
export class FffMediaModule { }
