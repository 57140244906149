import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { fffCSOSConfig } from '@app/fff-config/fff-endpoints.config';
import { FffLegisym } from '@app/models/fff-legisym.model';
import { FffProfile } from '@app/models/fff-profile.model';
import { FffUserAccountService } from '@app/shared/services/fff-user-account.service';
import { BASE_URL_KEYS } from '@config/content/constants';
import { environment } from '@env/environment';
import { CsAgentAuthService } from '@spartacus/asm/root';
import {
  BaseSiteService,
  GlobalMessageService,
  GlobalMessageType,
  OccEndpointsService,
  TranslationService,
} from '@spartacus/core';
import { BehaviorSubject, Observable, Subscription, combineLatest } from 'rxjs';
import { tap } from 'rxjs/operators';
import { FffB2BUnitService } from '../fff-common-services/fff-b2b-unit-service';
import { FffInvoicePaymentService } from '../fff-common-services/fff-invoice-payment.service';
import { FffNewReportsService } from '../fff-new-reports/fff-new-reports.service';
@Component({
  selector: 'fff-profile-management',
  templateUrl: './fff-profile-management.component.html',
})
export class FffProfileManagementComponent implements OnInit, OnDestroy {
  private subscriptions: Subscription = new Subscription();
  isUpdating$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  isInvoicePaymentEnabled!: boolean;
  legisymData$: BehaviorSubject<FffLegisym | undefined> = new BehaviorSubject<
    FffLegisym | undefined
  >(undefined);
  profile$: Observable<FffProfile | undefined> = this.fffUserAccountService
    .getProfile()
    .pipe(
      tap(res => {
        this.isUpdating$.next(false);
      })
    );

  private messages: {
    success: string;
    error: string;
  } = {
    success: '',
    error: '',
  };

  BASE_URL_KEYS = BASE_URL_KEYS;

  activeSite: string = '';
  subs = new Subscription();
  disableWallet!: boolean;

  constructor(
    private fffUserAccountService: FffUserAccountService,
    private occEndpointService: OccEndpointsService,
    protected globalMessageService: GlobalMessageService,
    private fffB2BUnitService: FffB2BUnitService,
    private baseSiteService: BaseSiteService,
    private translation: TranslationService,
    private fffInvoicePaymentService: FffInvoicePaymentService,
    private fffNewReportsService: FffNewReportsService,
    private csAgentAuthService: CsAgentAuthService,
    private changeDetectorRef: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.isUpdating$.next(true);

    this.baseSiteService.getActive().subscribe(res => {
      this.activeSite = res;
    });
    this.fffNewReportsService.getApplicationProperties().subscribe(data => {
      this.isInvoicePaymentEnabled =
        data && data.invoicesPaymentEnabled == 'true';
    });
    combineLatest([
      this.translation.translate(
        'fffAccount.fffProfileManagement.updating.success'
      ),
      this.translation.translate(
        'fffAccount.fffProfileManagement.updating.error'
      ),
    ]).subscribe(([successMsg, errorMsg]) => {
      this.messages.success = successMsg;
      this.messages.error = errorMsg;
    });
    this.fffInvoicePaymentService.initialize();
    this.subs.add(
      this.csAgentAuthService
        .isCustomerSupportAgentLoggedIn()
        .pipe(
          tap(isCustomerSupportAgentLoggedIn => {
            this.disableWallet = !!isCustomerSupportAgentLoggedIn;
            this.changeDetectorRef.detectChanges();
          })
        )
        .subscribe()
    );
  }

  reloadAccount() {
    this.fffUserAccountService.loadProfile();
  }

  setUpdating(loading: boolean) {
    this.isUpdating$.next(loading);
  }

  showMessageResult(success: boolean) {
    this.globalMessageService.remove(GlobalMessageType.MSG_TYPE_ERROR);
    if (success) {
      this.globalMessageService.add(
        this.messages.success,
        GlobalMessageType.MSG_TYPE_CONFIRMATION,
        5000
      );
    } else {
      this.globalMessageService.add(
        this.messages.error,
        GlobalMessageType.MSG_TYPE_ERROR,
        5000
      );
    }
    this.profile$ = this.fffUserAccountService
      .getProfile()
      .pipe(tap(() => this.isUpdating$.next(false)));
  }

  certificateLoginToken(account: any): void {
    this.subscriptions.add(
      this.fffB2BUnitService
        .certificateLoginToken(account.user, account.b2b)
        .subscribe(
          certificated => {
            const legisymData: FffLegisym = {
              url: environment.legisymGetToken.url,
              method: fffCSOSConfig.getToken.method,
              serviceContract: certificated.csosServiceContractToken,
              loginToken: certificated.csosLoginToken,
              action: fffCSOSConfig.actions.certStore.name,
              returnURL: this.occEndpointService.buildUrl(
                fffCSOSConfig.actions.certStore.redirectUrl
              ),
            };
            this.legisymData$.next(legisymData);
          },
          error => console.log(error)
        )
    );
  }

  ngOnDestroy(): void {
    this.subscriptions?.unsubscribe();
  }
}
