import {Component, ElementRef, Input, OnDestroy, OnInit, Optional, ViewChild} from '@angular/core';
import { CmsComponentData, SearchBoxComponent, SearchBoxComponentService } from '@spartacus/storefront';
import {AuthService, CmsSearchBoxComponent, RoutingService, WindowRef} from '@spartacus/core';
import {Observable, Subject, Subscription} from 'rxjs';
import { OUTLINED_ICON_TYPE } from '../../../models/fff-outline-icons.model';
import {debounceTime, take, takeUntil} from "rxjs/operators";

@Component({
  selector: 'fff-search-box',
  templateUrl: 'fff-search-box.component.html',
})

export class FffSearchBoxComponent extends SearchBoxComponent implements OnInit, OnDestroy {
  @ViewChild('searchInput') searchInput: ElementRef | undefined;

  private unsubscribe$: Subject<void> = new Subject<void>();
  outlinedIconTypes = OUTLINED_ICON_TYPE;

  constructor(
    protected searchBoxComponentService: SearchBoxComponentService,
    @Optional()
    protected componentData: CmsComponentData<CmsSearchBoxComponent>,
    protected winRef: WindowRef,
    protected routingService: RoutingService,
    private authService: AuthService
  ){
    super(searchBoxComponentService, componentData, winRef, routingService);
  }

  ngOnInit() {
    super.ngOnInit();
    this.checkLoggedStatusToClearSearchBoxInput();
  }

  checkLoggedStatusToClearSearchBoxInput() {
    this.authService.isUserLoggedIn()
      .pipe(
        takeUntil(this.unsubscribe$),
        debounceTime(100)
      )
      .subscribe((loggedIn: boolean) => {
        if(loggedIn) {
          if(this.searchInput){
            this.clear(this.searchInput.nativeElement);
            this.close({} as UIEvent, true);
          }
        }
      });
  }
  getUrlForProduct(product: any): string {
    const name = product.name.replace(/[\s/()]/g,'-');
    return '/product/'+product.code+'/'+name;
  }
  ngOnDestroy() {
    super.ngOnDestroy();
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  selectSuggestion(e: any){
    this.chosenWord = e.target.outerText
  }
}
