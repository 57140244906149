import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { FffCommunicationService } from '@app/fff-enterprise/fff-common-services/fff-communication.service';
import { FffPrebookService } from '@app/fff-enterprise/fff-common-services/fff-prebook.service';
import { FILLED_ICON_TYPE } from '@app/models/fff-filled-icons.model';
import { OUTLINED_ICON_TYPE } from '@app/models/fff-outline-icons.model';
import {
  PreBookCategories,
  PreBookStages,
} from '@app/shared/services/enum.service';
import { fffPrebook } from '@assets/translations/en/fff-prebook';
import { RoutingService } from '@spartacus/core';
import { UserAccountFacade } from '@spartacus/user/account/root';
import { Observable, Subject, Subscription } from 'rxjs';
import { debounceTime, switchMap, take, tap } from 'rxjs/operators';
import { FffPrebookCartService } from '../services/fff-prebook-cart.service';

@Component({
  selector: 'fff-prebook-seasonal-overview',
  templateUrl: './fff-prebook-season-overview.component.html',
  styleUrls: ['./fff-prebook-season-overview.component.scss'],
})
export class FffPrebookSeasonOverviewComponent implements OnInit, OnDestroy {
  @ViewChild('breakdown') breakdownEl!: ElementRef;

  outlinedIconTypes = OUTLINED_ICON_TYPE;
  filledIconTypes = FILLED_ICON_TYPE;
  season: string = '';
  suggestedQtyLabel: string = '';
  isSuggestedQtyAvailable: boolean = false;
  suggestedQty: number = 0;
  currentYearQty: number = 0;
  totalQuantity: number = 0;
  stageData: any;
  prebookCategory!: string;
  pageId!: string;
  isQtySufficient!: boolean;
  saving: boolean = false;
  todaysQuantity: number = 0;
  unsubscribe$: Subject<boolean> = new Subject<boolean>();
  subs = new Subscription();
  labelTodaysOrder!: string;
  labelTotal!: string;
  hideSuggestion!: boolean;
  skipAndSaving: boolean = false;
  showBreakup: boolean = false;
  todaysTotalDoses: number = 0;
  suggestedTotalDoses: number = 0;
  currentYearDoses: number = 0;
  totalDosesForCurrentYear: number = 0;

  userData$: Observable<any> = this.userAccountDetails.get();
  b2bUnitData$: Observable<any> = this.userData$.pipe(
    switchMap(user => {
      return this.communicationService.getCurrentAccount(user);
    })
  );

  get isBreakupVisible() {
    return this.pageId === PreBookStages.flu;
  }

  constructor(
    public preBookService: FffPrebookService,
    private communicationService: FffCommunicationService,
    private userAccountDetails: UserAccountFacade,
    private cdr: ChangeDetectorRef,
    private routingService: RoutingService,
    private fffPrebookCartService: FffPrebookCartService
  ) {}

  ngOnInit(): void {
    this.preBookService.getAppProperties();
    this.preBookService.getSeasonData().subscribe(data => {
      this.season = data;
      this.labelTotal =
        fffPrebook.totalOrder + ' ' + this.season + ' ' + fffPrebook.season;
    });
    this.labelTodaysOrder = fffPrebook.todaysOrder;
    this.subs.add(
      this.routingService.getRouterState().subscribe(data => {
        if (
          data.state &&
          data.state.queryParams &&
          data.state.queryParams.category &&
          data.state.queryParams.category !== this.pageId
        ) {
          this.pageId = data.state.queryParams.category;
          switch (this.pageId) {
            case PreBookStages.flu: {
              this.suggestedQtyLabel = fffPrebook.previousQty;
              this.prebookCategory = PreBookCategories.flu;
              break;
            }
            case PreBookStages.covid: {
              this.suggestedQtyLabel = fffPrebook.suggestedQty;
              this.prebookCategory = PreBookCategories.covid;
              break;
            }
            case PreBookStages.rsv: {
              this.prebookCategory = PreBookCategories.rsv;
              this.hideSuggestion = true;
              break;
            }
            default: {
              return;
            }
          }
          this.fetchSeasonalData(this.prebookCategory);

          this.loadTotals();
        }
      })
    );
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();

    this.unsubscribe$.next(false);
    this.unsubscribe$.complete();
    this.todaysQuantity = 0;
    this.todaysTotalDoses = 0;
  }

  fetchSeasonalData(category: string): void {
    this.subs.add(
      this.b2bUnitData$.subscribe((b2bUnit: any) => {
        if (b2bUnit && b2bUnit.crossUpSellingRecommendations) {
          this.stageData = b2bUnit.crossUpSellingRecommendations.filter(
            (data: any) => {
              return data.categoryName == category;
            }
          )[0];

          this.suggestedQty =
            this.pageId == PreBookStages.covid
              ? this.fffPrebookCartService.getFluTotalQuantity()
              : this.stageData.previousSeasonBoxesOrdered;
          this.suggestedTotalDoses =
            this.pageId == PreBookStages.covid
              ? this.fffPrebookCartService.getTotalFlueDoses()
              : this.stageData?.previousSeasonDosesOrdered
              ? this.stageData?.previousSeasonDosesOrdered
              : this.suggestedQty * 10;
          this.isSuggestedQtyAvailable = this.suggestedQty !== 0;
          this.currentYearQty = this.stageData.currentSeasonBoxesOrdered;
          this.currentYearDoses = this.stageData?.currentSeasonDosesOrdered
            ? this.stageData?.currentSeasonDosesOrdered
            : this.currentYearQty * 10;
          this.totalQuantity = this.currentYearQty + this.todaysQuantity;
          this.totalDosesForCurrentYear =
            this.currentYearDoses + this.todaysTotalDoses;
          if (this.pageId == PreBookStages.flu) {
            this.fffPrebookCartService.setFluTotalQuantity(this.totalQuantity);
            this.fffPrebookCartService.setTotalFluDoses(
              this.totalDosesForCurrentYear
            );
          }
          if (this.suggestedQty != null && this.currentYearQty != null) {
            this.setSuggestionColor(
              this.suggestedQty,
              this.currentYearQty,
              this.pageId
            );
            this.cdr.detectChanges();
          }
        }
      })
    );
  }

  proceedToNext() {
    this.saving = true;
    this.cdr.markForCheck();
    this.fffPrebookCartService
      .addToCart(
        this.unsubscribe$,
        {
          pageId: this.pageId,
          redirectToNextPage: true,
        },
        this.pageId
      )
      .pipe(take(1))
      .subscribe(() => {
        this.saving = false;
        this.cdr.markForCheck();
      });
  }

  loadTotals() {
    // Reset the today's quantity value
    this.todaysQuantity = 0;
    this.todaysTotalDoses = 0;
    this.cdr.markForCheck();
    this.subs.add(
      this.fffPrebookCartService
        .getUpdatableEntriesTotalByType(this.pageId)
        .pipe(
          debounceTime(300),
          tap(quantityInfo => {
            if (this.todaysQuantity !== quantityInfo.totalQuantity) {
              this.todaysQuantity = quantityInfo.totalQuantity || 0;
              this.todaysTotalDoses = quantityInfo.totalDoses || 0;
              this.totalQuantity = this.currentYearQty + this.todaysQuantity;
              this.totalDosesForCurrentYear =
                this.currentYearDoses + this.todaysTotalDoses;
              if (this.pageId == PreBookStages.flu) {
                this.fffPrebookCartService.setFluTotalQuantity(
                  this.totalQuantity
                );
                this.fffPrebookCartService.setTotalFluDoses(
                  this.totalDosesForCurrentYear
                );
              }
              this.setSuggestionColor(
                this.suggestedQty,
                this.totalQuantity,
                this.pageId
              );
            }
            this.cdr.markForCheck();
          })
        )
        .subscribe()
    );
  }

  setSuggestionColor(
    suggestedQty: number,
    currentQty: number,
    stageId: string
  ) {
    if (stageId && stageId == PreBookStages.rsv) {
      this.isQtySufficient = currentQty > 0;
    } else {
      this.isQtySufficient = currentQty >= suggestedQty;
    }
  }
  proceedToCheckout() {
    this.skipAndSaving = true;
    this.cdr.markForCheck();
    this.fffPrebookCartService
      .addToCart(
        this.unsubscribe$,
        {
          pageId: this.pageId,
          redirectToCart: true,
        },
        this.pageId
      )
      .pipe(take(1))
      .subscribe(() => {
        this.skipAndSaving = false;
        this.cdr.markForCheck();
      });
  }

  toggleBreakup() {
    this.toggleBreakupDisplay(!this.showBreakup);
  }

  toggleBreakupDisplay(display: boolean) {
    this.showBreakup = display;
    this.cdr.markForCheck();

    setTimeout(() => {
      this.handleLayoutChanges();
    }, 100);
  }

  openBreakup() {
    this.toggleBreakupDisplay(true);
  }

  closeBreakup() {
    this.toggleBreakupDisplay(false);
  }

  handleLayoutChanges() {
    const prebookSeasonEl = document.querySelector(
      '.PrebookRecommendationSection'
    );

    const filterEl = document.querySelector(
      '.ProductLeftRefinements'
    ) as HTMLDivElement;

    if (!prebookSeasonEl || !filterEl) {
      return;
    }

    const breakdownEl = this.breakdownEl?.nativeElement as HTMLDivElement;
    const prebookSeasonHeight = prebookSeasonEl?.clientHeight || 0;
    const breakdownHeight = breakdownEl?.clientHeight || 0;

    let diff = breakdownHeight - prebookSeasonHeight;

    if (diff < 0) {
      diff = 0;
    }

    filterEl.style.marginTop = `${diff}px`;
  }
}
