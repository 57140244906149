import { BehaviorSubject } from 'rxjs';
interface Dictionary<T> {
  [key: string]: T;
}

export class FFFCommonFunctions {
  static prices: Dictionary<any[]> = {};
  static prices$: BehaviorSubject<Dictionary<any[]>> = new BehaviorSubject<
    Dictionary<any[]>
  >(this.prices);

  static getAltPrice(priceMap: any, altUnit: string): any {
    if (priceMap['EU']) {
      return priceMap['EU'];
    } else if (priceMap['IU']) {
      return priceMap['IU'];
    } else if (priceMap[altUnit]) {
      return priceMap[altUnit];
    }

    return {};
  }

  static isOfVaccineType(item: any): boolean {
    return !!item?.product?.categories?.find(
      (category: any) =>
        category?.code === 'VACCNVACCN000P2000' ||
        category?.name === 'Vaccines - Influenza'
    );
  }

  static isAutoSplitBilling(
    currentB2bUnitIsSplitBiller: boolean,
    customerIsSplitBiller: boolean,
    item: any
  ): boolean {
    return !!(
      currentB2bUnitIsSplitBiller &&
      customerIsSplitBiller &&
      item &&
      !item.manual
    );
  }

  static roundPriceToTwoDecimals(value: number): number {
    return Math.round((value + Number.EPSILON) * 100) / 100;
  }

  static updatePrices(
    pricesData: any,
    product: any,
    isAutoSplitBilling: boolean
  ) {
    let price: any[] = [];
    let basicCP: any;
    const stockResponse = pricesData?.prices?.sort((a: any, b: any) =>
      a.accountType.localeCompare(b.accountType)
    );

    if (isAutoSplitBilling && stockResponse.length >= 3) {
      const priceA = stockResponse
        .filter((value: { accountType: string }) => value.accountType === 'A')
        .pop();
      basicCP = this.roundPriceToTwoDecimals(
        +priceA.price.basicCP.replace(/-/g, '')
      );
      price.push(this.calculatePriceMap(priceA, product));
    } else {
      price = stockResponse;
      price?.forEach((prc: any) => {
        if (prc.price) {
          if (prc.accountType === 'A') {
            basicCP = this.roundPriceToTwoDecimals(
              +prc.price.basicCP.replace(/-/g, '')
            );
          }
          this.calculatePriceMap(prc, product);
        }
      });
    }
    this.prices[product.code] = price;
    this.prices$.next(this.prices);
    return { price, basicCP };
  }

  static isAltPrice(altPrice: any): boolean {
    return 'EU' === altPrice.altUOM || 'IU' === altPrice.altUOM;
  }

  static calculatePriceMap(prc: any, product: any): any {
    prc.key = prc.accountType + '__' + prc.price.productCode;
    prc.warehouse = `p_warehouse_${prc.accountType}`;
    prc.price.priceMap = {};
    if (Math.round(+prc.price.moq) > 1) {
      prc.price.toAltPrice.results.forEach((altPrice: any) => {
        if (+altPrice.altMinOrdQty === 1) {
          prc.price.basicUOM = altPrice.altUOM;
          prc.price.priceMap[prc.price.basicUOM] = this.calculateUOMPriceMap(
            prc.price.priceMap,
            prc.price.basicUOM,
            altPrice.altMinOrdQty,
            altPrice.altDiscPrice,
            '0.0000',
            altPrice.altFETax
          );
          prc.price.priceMap[prc.price.basicUOM].originalValue =
            prc.price.priceMap[prc.price.basicUOM].value;
          prc.price.priceMap[prc.price.basicUOM].discount = this.parseDiscount(
            altPrice.altDiscAmt
          );
        } else {
          prc.price.priceMap[prc.price.basicUOM] = this.calculateUOMPriceMap(
            prc.price.priceMap,
            prc.price.basicUOM,
            prc.price.minOrderQuantity,
            prc.price.basicDiscountPrice,
            '0.0000',
            prc.price.feTax
          );
          prc.price.priceMap[prc.price.basicUOM].originalValue =
            prc.price.priceMap[prc.price.basicUOM].value;
          prc.price.priceMap[prc.price.basicUOM].discount = this.parseDiscount(
            prc.price.basicDiscamt
          );
          prc.price.priceMap[prc.price.basicUOM].mdu = prc.price.mdu;
        }
      });
    } else {
      // Basic unit price
      if (Math.round(+prc.price.minOrderQuantity) === 1) {
        prc.price.priceMap[prc.price.basicUOM] = this.calculateUOMPriceMap(
          prc.price.priceMap,
          prc.price.basicUOM,
          prc.price.minOrderQuantity,
          prc.price.basicDiscountPrice,
          '0.0000',
          prc.price.feTax
        );
        prc.price.priceMap[prc.price.basicUOM].originalValue =
          prc.price.priceMap[prc.price.basicUOM].value;
        prc.price.priceMap[prc.price.basicUOM].discount = this.parseDiscount(
          prc.price.basicDiscamt
        );
        prc.price.toAltPrice.results.forEach((altPrice: any) => {
          if (this.isAltPrice(altPrice)) {
            prc.price.priceMap[altPrice.altUOM] = this.calculateUOMPriceMap(
              prc.price.priceMap,
              altPrice.altUOM,
              altPrice.altMinOrdQty,
              altPrice.altDiscPrice,
              '0.0000',
              altPrice.altFETax
            );
            prc.price.priceMap[altPrice.altUOM].originalValue =
              prc.price.priceMap[altPrice.altUOM].value;
            prc.price.priceMap[altPrice.altUOM].discount = this.parseDiscount(
              altPrice.altDiscAmt
            );
            prc.price.priceMap[altPrice.altUOM].altUOM = altPrice.altUOM;
            prc.price.priceMap[altPrice.altUOM].altDiscPrice =
              altPrice.altDiscPrice;
          }
        });
      } else if (Math.round(+prc.price.minOrderQuantity) > 1) {
        if (!prc.price.originalBasicUom) {
          prc.price.originalBasicUom = prc.price.basicUOM;
        }
        prc.price.toAltPrice.results.forEach((altPrice: any) => {
          if (+altPrice.altMinOrdQty === 1) {
            prc.price.basicUOM = altPrice.altUOM;
            prc.price.priceMap[prc.price.basicUOM] = this.calculateUOMPriceMap(
              prc.price.priceMap,
              prc.price.basicUOM,
              altPrice.altMinOrdQty,
              altPrice.altDiscPrice,
              '0.0000',
              altPrice.altFETax
            );
            prc.price.priceMap[prc.price.basicUOM].originalValue =
              prc.price.priceMap[prc.price.basicUOM].value;
            prc.price.priceMap[prc.price.basicUOM].discount =
              this.parseDiscount(altPrice.altDiscAmt);

            /* CC-3187: add additional conversion between base price and alt price when minOrderQuantity > 1 */
            prc.price.priceMap[prc.price.basicUOM].additionalConversion =
              '1 ' +
              altPrice.altUOM +
              ' = ' +
              Math.round(+prc.price.minOrderQuantity) +
              ' ' +
              prc.price.originalBasicUom;
          }
        });
        prc.price.toAltPrice.results.forEach((altPrice: any) => {
          if (this.isAltPrice(altPrice)) {
            prc.price.priceMap[altPrice.altUOM] = this.calculateUOMPriceMap(
              prc.price.priceMap,
              altPrice.altUOM,
              altPrice.altMinOrdQty,
              altPrice.altDiscPrice,
              '0.0000',
              altPrice.altFETax
            );
            prc.price.priceMap[altPrice.altUOM].originalValue =
              prc.price.priceMap[altPrice.altUOM].value;
            prc.price.priceMap[altPrice.altUOM].discount = this.parseDiscount(
              altPrice.altDiscAmt
            );
            prc.price.priceMap[altPrice.altUOM].altUOM = altPrice.altUOM;
            prc.price.priceMap[altPrice.altUOM].altDiscPrice =
              altPrice.altDiscPrice;
          }
        });
      }
    }
    /* CC-2609: if product has Alt Unit set in backoffice, and alt price not set after going through logic above,
    try to set alt price in price map with the response that has the same alt unit as set in backoffice */
    if (product?.altUnit && !prc.price.priceMap[product?.altUnit]) {
      prc.price?.toAltPrice?.results.forEach((altPrice: any) => {
        if (altPrice.altUOM === product.altUnit) {
          prc.price.priceMap[altPrice.altUOM] = this.calculateUOMPriceMap(
            prc.price.priceMap,
            altPrice.altUOM,
            altPrice.altMinOrdQty,
            altPrice.altDiscPrice,
            '0.0000',
            altPrice.altFETax
          );
          prc.price.priceMap[altPrice.altUOM].originalValue =
            prc.price.priceMap[altPrice.altUOM].value;
          prc.price.priceMap[altPrice.altUOM].discount = this.parseDiscount(
            altPrice.altDiscAmt
          );
          prc.price.priceMap[altPrice.altUOM].altUOM = altPrice.altUOM;
          prc.price.priceMap[altPrice.altUOM].altDiscPrice =
            altPrice.altDiscPrice;
        }
      });
    }
    return prc;
  }

  static calculateUOMPriceMap(
    prcMap: any,
    priceUnit: any,
    minOrderQty: string,
    cp: string,
    discAmt: string,
    FEtax: string
  ) {
    prcMap[priceUnit] = {
      value: FFFCommonFunctions.roundPriceToTwoDecimals(
        +cp.replace(/-/g, '') - +discAmt.replace(/-/g, '')
      ),
      min: +minOrderQty.replace(/-/g, ''),
      tax: +FEtax.replace(/-/g, ''),
      discount: +discAmt.replace(/-/g, ''),
    };
    return prcMap[priceUnit];
  }

  static parseDiscount(discount: string): number {
    if (discount) {
      return FFFCommonFunctions.roundPriceToTwoDecimals(
        +discount.replace(/-/g, '')
      );
    }
    return 0;
  }

  static getVaccineAltPrice(priceData: any): any {
    return (
      priceData?.price?.toAltPrice?.results?.find(
        (r: any) => r.altUOM === 'DS'
      ) || {}
    );
  }
}
