<div class="container">
  <div class="row register-container col-xs-12 col-md-8 d-flex justify-content-center align-items-center m-auto px-0">
    <cx-spinner class="overlay" *ngIf="isUpdating$ | async"></cx-spinner>
    <div class="col-md-12 d-flex justify-content-center align-items-center register-header register-background-content">
      <div>
        <h3 class="h3">{{ 'fffRegister.reset.header' | cxTranslate }}</h3>
      </div>
    </div>

    <div class="col-md-12 register-content">
      <form *ngIf="token$ | async as token" (ngSubmit)="onSubmit(token)" [formGroup]="form">

        <div class="col-lg-10 col-md-12 m-auto d-flex flex-wrap">

          <div class="col-md-6 col-sm-12">
            <span class="prefix-icon" (click)="enablePassword()">
              <cx-icon *ngIf="!showNewPassword" class="cx-icon icon-md pe-auto"
                [type]="filledIconTypes.ICON_EYE_SLASH_FILL">
              </cx-icon>
              <cx-icon *ngIf="showNewPassword" class="cx-icon icon-md pe-auto" [type]="filledIconTypes.ICON_EYE_FILL">
              </cx-icon>
            </span>

            <label>
              <span class="label-content">{{
                'fffRegister.reset.newPassword.label' | cxTranslate
                }}</span>
              <input aria-required="true" class="form-control form-control-lg" id="password"
                [placeholder]="'fffRegister.reset.newPassword.placeHolder' | cxTranslate"
                [type]="showNewPassword ? 'text' : 'password'" formControlName="password" />
              <cx-form-errors [control]="$any(form).get('password')"></cx-form-errors>
            </label>
          </div>

          <div class="col-md-6 col-sm-12">
            <span class="prefix-icon" (click)="enablePasswordConfirmation()">
              <cx-icon *ngIf="!showNewPasswordConfirmation" class="cx-icon icon-md pe-auto"
                [type]="filledIconTypes.ICON_EYE_SLASH_FILL">
              </cx-icon>
              <cx-icon *ngIf="showNewPasswordConfirmation" class="cx-icon icon-md pe-auto"
                [type]="filledIconTypes.ICON_EYE_FILL">
              </cx-icon>
            </span>

            <label>
              <span class="label-content">{{
                'fffRegister.reset.confirmPassword.label' | cxTranslate
                }}</span>
              <input class="form-control form-control-lg" id="passwordConfirm" aria-required="true"
                [placeholder]="'fffRegister.reset.confirmPassword.placeHolder' | cxTranslate"
                [type]="showNewPasswordConfirmation ? 'text' : 'password'" formControlName="passwordConfirm" />
              <cx-form-errors [control]="$any(form).get('passwordConfirm')"></cx-form-errors>
            </label>
          </div>

          <div class="col-12 my-4">
            <label class="password-requirements password-rule"
              [innerHTML]="'fffRegister.reset.passwordRules' | cxTranslate"></label>
          </div>

          <div class="form-actions col-12 py-0">
            <button type="submit" class="btn btn-primary btn-md" [disabled]="form.disabled">
              {{ 'fffRegister.reset.submit' | cxTranslate }}
            </button>
          </div>

        </div>
      </form>
    </div>

  </div>
</div>