import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { RoutingService } from '@spartacus/core';
import { CUSTOM_ICON_TYPE } from 'src/app/models/fff-custom-icons.model';

@Component({
  selector: 'fff-empty-cart',
  templateUrl: './fff-empty-cart.component.html',
})
export class FffEmptyCartComponent {
  customIconTypes = CUSTOM_ICON_TYPE;
  isPrebookCart!: boolean;

  constructor(
    protected routingService: RoutingService,
    private activatedRoute: ActivatedRoute,
    public router: Router
  ) {}

  ngOnInit(): void {
    if (this.activatedRoute.snapshot.queryParams['prebook']) {
      this.isPrebookCart = true;
    }
  }

  goToHomePage(): void {
    if (this.isPrebookCart) {
      this.router.navigateByUrl(
        '/categories?category=vaccines-influenza&prebook=true&sortCode=relevance'
      );
    } else {
      this.routingService.go('/');
    }
  }
}
