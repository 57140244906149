<div class="container py-5">
  <div
    class="row register-container col-xs-12 col-md-8 d-flex justify-content-center align-items-center m-auto px-0"
  >
    <div class="col-12 px-0" *ngIf="showFormRequest$ | async">
      <div
        class="col-md-12 d-flex justify-content-center align-items-center register-header register-background-content"
      >
        <div>
          <h3 class="h3">{{ 'fffContactUs.header' | cxTranslate }}</h3>
        </div>
      </div>

      <div class="col-md-12 register-content contact-us">
        <form (ngSubmit)="onSubmit()" [formGroup]="form">
          <div class="col-xs-12 col-md-9 m-auto form-fields">
            <label>
              <span class="label-content">{{
                'fffContactUs.contactUsForm.firstName' | cxTranslate
              }}</span>
              <input
                class="form-control form-control-lg"
                id="firstName"
                aria-required="true"
                type="text"
                formControlName="firstName"
              />
              <cx-form-errors
                [control]="$any(form).get('firstName')"
              ></cx-form-errors>
            </label>

            <label>
              <span class="label-content">{{
                'fffContactUs.contactUsForm.lastName' | cxTranslate
              }}</span>
              <input
                class="form-control form-control-lg"
                id="lastName"
                aria-required="true"
                type="text"
                formControlName="lastName"
              />
              <cx-form-errors
                [control]="$any(form).get('lastName')"
              ></cx-form-errors>
            </label>

            <label>
              <span class="label-content">{{
                'fffContactUs.contactUsForm.emailAddress' | cxTranslate
              }}</span>
              <input
                class="form-control form-control-lg"
                id="email"
                aria-required="true"
                type="text"
                formControlName="email"
              />
              <cx-form-errors
                [control]="$any(form).get('email')"
              ></cx-form-errors>
            </label>

            <label>
              <span class="label-content">{{
                'fffContactUs.contactUsForm.accountNumber.label' | cxTranslate
              }}</span>
              <input
                class="form-control form-control-lg"
                id="accountNumber"
                aria-required="true"
                type="text"
                formControlName="accountNumber"
              />
              <cx-form-errors
                [control]="$any(form).get('accountNumber')"
                prefix="formErrors.accountNumber"
              ></cx-form-errors>
            </label>
            

            <label>
              <span class="label-content">{{
                'fffContactUs.contactUsForm.department.label' | cxTranslate
              }}</span>

              <div *ngIf="departments$ | async as departments">
                <select
                  class="form-control form-control-lg form-select"
                  id="departmentCode"
                  aria-required="true"
                  formControlName="departmentCode"
                >
                  <option value="" disabled selected>
                    {{
                      'fffContactUs.contactUsForm.department.placeholder'
                        | cxTranslate
                    }}
                  </option>
                  <option
                    [value]="department.code"
                    *ngFor="let department of departments.contactUsReasons"
                  >
                    {{ department.description }}
                  </option>
                </select>
              </div>

              <cx-form-errors
                [control]="$any(form).get('reasonCode')"
              ></cx-form-errors>
            </label>

            <label class="comments">
              <span class="label-content">{{
                'fffContactUs.contactUsForm.comments' | cxTranslate
              }}</span>
              <textarea
                class="form-control form-control-lg"
                id="comments"
                aria-required="true"
                type="email"
                formControlName="comments"
                maxlength="2000"
              ></textarea>
              <cx-form-errors
                [control]="$any(form).get('comments')"
              ></cx-form-errors>
              <div class="text-right count">
                <span> {{ getCountNumber() }} / 2000</span>
              </div>
            </label>

            <div class="form-actions py-0">
              <button
                type="submit"
                class="btn btn-primary btn-md"
                [disabled]="form.disabled"
              >
                {{ 'fffContactUs.send' | cxTranslate }}
              </button>

              <span
                [innerHTML]="'fffContactUs.immediateSupport' | cxTranslate"
              ></span>
            </div>
          </div>
        </form>
      </div>
    </div>

    <div class="col-12 px-0 contact-us" *ngIf="!(showFormRequest$ | async)">
      <div class="col-12 register-content register-background-content">
        <div class="confirmation">
          <div class="col-md-12 text-center">
            <cx-icon
              class="cx-icon icon-lg mb-4"
              [type]="outlinedIconTypes.ICON_ENVELOPE"
            ></cx-icon>
            <h3 class="h3">
              {{ 'fffContactUsConfirmation.header' | cxTranslate }}
            </h3>
          </div>

          <div class="col-md-12 confirmation-text text-center m-auto">
            <p>{{ 'fffContactUsConfirmation.message' | cxTranslate }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
