<div class="global-banner-messages-container" *ngIf="bannerMessages$ | async as messages">
  <div class="global-success-message d-flex justify-content-center" *ngFor="let confMsg of messages[messageType.MSG_TYPE_CONFIRMATION];let i = index" >
    <span class="alert-icon">
      <cx-icon [type]="FILLED_ICON_TYPE.ICON_CHECK_CIRCLE_FILL"></cx-icon>
    </span>
    <ng-container *ngIf="!confMsg.isHTML">
      <span *ngIf="confMsg.prefix">{{confMsg.prefix}}&nbsp;</span>
      <span *ngIf="confMsg.text.raw">{{ confMsg.isTranslatable ? (confMsg.text.raw| cxTranslate):confMsg.text.raw }}</span>
    </ng-container>
    <ng-container *ngIf="confMsg.isHTML">
      <div *ngIf="confMsg.text.raw" [innerHTML]="confMsg.text.raw | fffSafeHtml"></div>
    </ng-container>
  </div>
  <div class="global-info-message d-flex justify-content-center" *ngFor="let infoMsg of messages[messageType.MSG_TYPE_INFO]; let i = index">
    <span class="alert-icon">
      <cx-icon [type]="FILLED_ICON_TYPE.ICON_QUESTION_OCTAGON_FILL"></cx-icon>
    </span>
    <ng-container *ngIf="!infoMsg.isHTML">
      <span *ngIf="infoMsg.prefix">{{infoMsg.prefix}}&nbsp;</span>
      <span *ngIf="infoMsg.text.raw">{{ infoMsg.isTranslatable ? (infoMsg.text.raw| cxTranslate):infoMsg.text.raw }}</span>
    </ng-container>
    <ng-container *ngIf="infoMsg.isHTML">
      <div *ngIf="infoMsg.text.raw" [innerHTML]="infoMsg.text.raw | fffSafeHtml"></div>
    </ng-container>
  </div>
  <div class="global-warning-message d-flex justify-content-center" *ngFor="let warningMsg of messages[messageType.MSG_TYPE_WARNING];let i = index">
    <span class="alert-icon">
      <cx-icon [type]="FILLED_ICON_TYPE.ICON_EXCLAMATION_OCTAGON_FILL"></cx-icon>
    </span>
    <ng-container *ngIf="!warningMsg.isHTML">
      <span *ngIf="warningMsg.prefix">{{warningMsg.prefix}}&nbsp;</span>
      <span *ngIf="warningMsg.text.raw">{{ warningMsg.isTranslatable ? (warningMsg.text.raw| cxTranslate):warningMsg.text.raw }}</span>
    </ng-container>
    <ng-container *ngIf="warningMsg.isHTML">
      <div *ngIf="warningMsg.text.raw" [innerHTML]="warningMsg.text.raw | fffSafeHtml"></div>
    </ng-container>
  </div>
  <div class="global-error-message d-flex justify-content-center" *ngFor="let errorMsg of messages[messageType.MSG_TYPE_ERROR]; let i = index">
    <span class="alert-icon">
      <cx-icon [type]="FILLED_ICON_TYPE.ICON_X_OCTAGON_FILL"></cx-icon>
    </span>
    <ng-container *ngIf="!errorMsg.isHTML">
      <span *ngIf="errorMsg.prefix">{{errorMsg.prefix}}&nbsp;</span>
      <span *ngIf="errorMsg.text.raw">{{errorMsg.isTranslatable ? (errorMsg.text.raw| cxTranslate):errorMsg.text.raw }}</span>
    </ng-container>
    <ng-container *ngIf="errorMsg.isHTML">
      <div *ngIf="errorMsg.text.raw" [innerHTML]="errorMsg.text.raw | fffSafeHtml"></div>
    </ng-container>
  </div>
</div>

