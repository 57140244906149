import { HttpClient } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { B2BUnitState } from '@app/reducers';
import { Store } from '@ngrx/store';
import {
  AuthActions,
  AuthRedirectService,
  AuthRedirectStorageService,
  AuthStorageService,
  OAuthLibWrapperService,
  OCC_USER_ID_CURRENT,
  OccEndpointsService,
  RoutingService,
  StateWithClientAuth,
  UserIdService,
} from '@spartacus/core';
import { Observable, forkJoin, of } from 'rxjs';
import { concatMap, debounceTime, take } from 'rxjs/operators';
import { FFF_ROUTES } from 'src/app/fff-config/content/constants';

@Injectable({
  providedIn: 'root',
})
export class FffAuthenticationService {
  isUserDisabled = new EventEmitter();
  constructor(
    public routingService: RoutingService,
    protected store: Store<StateWithClientAuth>,
    protected userIdService: UserIdService,
    protected oAuthLibWrapperService: OAuthLibWrapperService,
    protected authStorageService: AuthStorageService,
    protected authRedirectService: AuthRedirectService,
    protected occEndpoints: OccEndpointsService,
    private http: HttpClient,
    protected authRedirectStorageService: AuthRedirectStorageService,
    private storeB2B: Store<B2BUnitState>
  ) {}

  async loginWithCredentials(userId: string, password: string): Promise<void> {
    try {
      await this.oAuthLibWrapperService.authorizeWithPasswordFlow(
        userId,
        password
      );
      // OCC specific user id handling. Customize when implementing different endpoint
      this.userIdService.setUserId(OCC_USER_ID_CURRENT);

      this.store.dispatch(new AuthActions.Login());

      this.redirect();
    } catch {}
  }

  emitUserDisabledEvent() {
    this.isUserDisabled.emit();
  }

  redirect() {
    this.storeB2B
      .select(state => state.B2BUnit)
      .pipe(take(1))
      .pipe(
        concatMap(b2b => {
          return forkJoin([
            of(b2b),
            this.authRedirectStorageService.getRedirectUrl().pipe(take(1)),
          ]);
        }),
        debounceTime(100)
      )
      .subscribe(([b2bunit, redirectUrl]) => {
        this.clearRedirectUrl();
        if (
          b2bunit &&
          b2bunit.currentB2BUnit &&
          b2bunit.currentB2BUnit.jeffersonUnit
        ) {
          this.routingService.goByUrl(FFF_ROUTES.JEFFERSON_REQUEST_PAGE);
        } else if (history.state.resettingPassword) {
          this.routingService.goByUrl('/');
        } else if (redirectUrl) {
          this.routingService.goByUrl(redirectUrl);
        } else {
          this.routingService.goByUrl('/');
        }
        this.clearRedirectUrl();
      });
  }
  protected clearRedirectUrl(): void {
    this.authRedirectStorageService.setRedirectUrl(undefined);
  }

  isMigratedUserFirstLogin(email: string): Observable<any> {
    const endpoint = this.occEndpoints.buildUrl(
      `/account/isMigratedUserFirstLogin?emailAddress=${email}`
    );
    return this.http.get(endpoint);
  }
}
