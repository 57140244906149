<ng-container *ngIf="activeSite$ | async as activeSite">
  <ng-container *ngIf="product$ | async as productData">

    <div class="container product-info-container">

      <div class="col-12 d-flex flex-wrap product-description">
        <h1 class="col-12 px-0 detailsSec1-pdp" *ngIf="productData.detailsSec1" [innerHTML]="productData?.detailsSec1">
        </h1>
        <p class="col-12 px-0 itemDescription" *ngIf="productData.detailsSec2" [innerHTML]="productData?.detailsSec2">
        </p>
      </div>


      <div class="product-details">

        <div class="col-12 section" *ngIf="activeSite === BASE_URL_KEYS.BIOSUPPLY">
          <div class="col-12 d-flex flex-wrap header cursor-pointer"
            (click)="ndcInformationSectionExpand = !ndcInformationSectionExpand">
            <h3>{{ 'fffProduct.headingNdcInfo' | cxTranslate }}</h3>
            <cx-icon class="cx-icon icon-sm icon-chevron-up ml-auto" *ngIf="ndcInformationSectionExpand"
              [type]="outlinedIconTypes.ICON_CHEVRON_UP"></cx-icon>
            <cx-icon class="cx-icon icon-sm icon-chevron-up ml-auto" *ngIf="!ndcInformationSectionExpand"
              [type]="outlinedIconTypes.ICON_CHEVRON_DOWN"></cx-icon>
          </div>
          <div class="col-12 content" [class.d-block]="ndcInformationSectionExpand"
            [class.d-none]="!ndcInformationSectionExpand">
            <div *ngFor="let field of getNdcFields(productData.classifications)">
              <p *ngIf="field.featureValues[0].value" class="itemDescription">
                {{field.name}}: {{field.featureValues[0].value}}
              </p>
            </div>
          </div>
        </div>

        <div class="col-12 section">
          <div class="col-12 d-flex flex-wrap header cursor-pointer"
            (click)="indicatorsSectionExpand = !indicatorsSectionExpand">
            <h3>{{ 'fffProduct.headingIndications' | cxTranslate }}</h3>
            <cx-icon class="cx-icon icon-sm icon-chevron-up ml-auto" *ngIf="indicatorsSectionExpand"
              [type]="outlinedIconTypes.ICON_CHEVRON_UP"></cx-icon>
            <cx-icon class="cx-icon icon-sm icon-chevron-up ml-auto" *ngIf="!indicatorsSectionExpand"
              [type]="outlinedIconTypes.ICON_CHEVRON_DOWN"></cx-icon>
          </div>
          <div class="col-12 content" [class.d-block]="indicatorsSectionExpand"
            [class.d-none]="!indicatorsSectionExpand">
            <span [innerHTML]="productData.detailsIndicators"></span>
          </div>
          <div class="d-flex justify-content-center">
            <a class="btn btn-secondary btn-md prescribingInformation" target="_blank"
              [href]="productData.prescribingInfoUrl">{{
              'fffProduct.details.prescribingInformation' |
              cxTranslate }}</a>
          </div>
        </div>

        <div class="col-12 section" *ngIf="activeSite === BASE_URL_KEYS.BIOSUPPLY">
          <div class="col-12 d-flex flex-wrap header cursor-pointer"
            (click)="prescribingInfoSectionExpand = !prescribingInfoSectionExpand">
            <h3>{{ 'fffProduct.headingRightNowInv' | cxTranslate }}</h3>
            <cx-icon class="cx-icon icon-sm icon-chevron-up ml-auto" *ngIf="prescribingInfoSectionExpand"
              [type]="outlinedIconTypes.ICON_CHEVRON_UP"></cx-icon>
            <cx-icon class="cx-icon icon-sm icon-chevron-up ml-auto" *ngIf="!prescribingInfoSectionExpand"
              [type]="outlinedIconTypes.ICON_CHEVRON_DOWN"></cx-icon>
          </div>
          <div class="col-12 content" [class.d-block]="prescribingInfoSectionExpand"
            [class.d-none]="!prescribingInfoSectionExpand">
            <span [innerHTML]="productData?.invProgram"></span>
          </div>
        </div>

      </div>
    </div>

  </ng-container>
</ng-container>