<div class="container">
  <div class="d-flex justify-content-between top-title">
    <div class="d-flex">
      <h3 class="pr-4">{{ 'fffReports.title' | cxTranslate }}</h3>
    </div>
  </div>

  <hr class="sep-table-title" />

  <form *ngIf="reportForm" [formGroup]="reportForm">
    <div class="form-container">
      <div class="form-children p-1">
        <div>
          <h4 style="margin-bottom: 1.625rem">
            {{ 'fffReports.reportType' | cxTranslate }}
          </h4>
          <ng-select
            [items]="['Order History', 'Invoice History']"
            formControlName="reportType"
          ></ng-select>
          <cx-form-errors
            [control]="reportForm.get('reportType')"
          ></cx-form-errors>
        </div>

        <div class="mt-4">
          <h4 class="mb-2">{{ 'fffReports.email' | cxTranslate }}</h4>
          <p class="mb-2">{{ 'fffReports.emailExplain' | cxTranslate }}</p>
          <input formControlName="email" class="w-100 form-control" />
          <cx-form-errors [control]="reportForm.get('email')"></cx-form-errors>
        </div>
      </div>

      <div class="form-children p-1">
        <div>
          <h4 class="mb-2">Date range</h4>

          <div class="d-flex">
            <div class="w-50 mr-1 position-relative">
              <span>{{ 'fffReports.from' | cxTranslate }}</span>
              <input
                formControlName="fromDate"
                class="w-100 form-control"
                id="dateFrom"
                type="datetime"
                autocomplete="off"
                ngbDatepicker
                #from="ngbDatepicker"
                required
              />
              <div (click)="from.toggle()">
                <cx-icon
                  class="icon-sm calendar-icons"
                  [type]="outlinedIconTypes.ICON_CALENDAR_EVENT"
                ></cx-icon>
                <cx-form-errors
                  [control]="reportForm.get('fromDate')"
                ></cx-form-errors>
              </div>
            </div>

            <div class="w-50 ml-1 position-relative">
              <span>{{ 'fffReports.to' | cxTranslate }}</span>
              <input
                formControlName="toDate"
                class="w-100 form-control"
                id="dateTo"
                autocomplete="off"
                ngbDatepicker
                #to="ngbDatepicker"
                required
              />
              <div (click)="to.toggle()">
                <cx-icon
                  class="icon-sm calendar-icons"
                  [type]="outlinedIconTypes.ICON_CALENDAR_EVENT"
                ></cx-icon>
                <cx-form-errors
                  [control]="reportForm.get('toDate')"
                ></cx-form-errors>
              </div>
            </div>
          </div>

          <div class="mt-4" *ngIf="reportForm.get('reportType').value">
            <h4 class="mb-2">{{ 'fffReports.status' | cxTranslate }}</h4>

            <div class="d-lg-flex">
              <ng-container
                *ngFor="
                  let status of availableStatus[
                    reportForm.get('reportType').value
                  ]
                "
              >
                <div class="mr-2">
                  <input
                    class="checkbox checkbox-sm"
                    type="checkbox"
                    value=""
                    [id]="status.id"
                    (change)="onStatusSelection(status, $event)"
                  />
                  <label class="checkbox-label d-flex" [for]="status.id">
                    {{ status.label }}
                  </label>
                </div>
              </ng-container>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="w-100 d-flex justify-content-end">
      <button class="btn btn-primary submit-button" (click)="onSubmit()">
        {{ 'fffReports.submit' | cxTranslate }}
      </button>
    </div>
  </form>
</div>
