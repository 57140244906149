export const fffQuickOrder = {
  fffQuickOrder: {
    orderAll: 'Order all',
    orderNow: 'Order Now',
    formDescription: 'You can add up to 10 valid SKUs at a time.',
    needHelp: 'Need help?',
    downloadTemplate: 'Download a template',
    importEntriesSubtitle: 'You can upload new orders for BioSupply here.',
    chooseFile: 'Choose a file',
    upload: 'Upload',
    viewAndEditCart: 'View and Edit Cart',
    viewAndEditSavedCart: 'View/Edit/Create Saved Cart',
    submitRequest: 'Submit Request',
    customCare: 'Wow! Customer Care (800) 843-7477',
    subtotal: 'Subtotal',
    clearSearch: 'Clear',
    add: '+ Add',
    productDetails: 'Product Details',
    uploadAnOrderFile: 'Upload an order file',
    searchForProducts: 'Search for products',
    qty: 'Qty:',
    account: 'Account:',
    yourCart: 'Your cart',
    continueShopping: 'Continue shopping',
    noItems: 'You have no items',
    inYourShoppingCart: 'in your shopping cart',
    remove: 'Remove',
    uploadSuccessMessage: 'The file has been uploaded successfully',
    modalSuccessTitleMessage: 'File Upload',
    modalButton: 'OK',
    uploadErrorTitleMessage: 'The following errors have occurred',
    uploadErrorMessage: 'Error placing quick order for store number {{storeNumber}}',
  },
};
