import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { FFFReturnsService } from '@enterprise/fff-common-services/fff-returns.service';
import { OUTLINED_ICON_TYPE } from '@model/fff-outline-icons.model';
import { FffProfile } from '@model/fff-profile.model';
import { FffReturn } from '@model/fff-return.model';
import { CustomFormValidators } from '@spartacus/storefront';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'fff-fet-returns-request-form',
  templateUrl: 'fff-fet-returns-request-form.component.html',
})
export class FffFetReturnsRequestFormComponent implements OnInit, OnDestroy {
  @Output() emitEditMode = new EventEmitter<boolean>();
  @Output() personalDetails = new EventEmitter<FffReturn>();
  @Input() profile: FffProfile | undefined;
  @Input() entries: FffReturn | undefined;
  private unsubscribe$ = new Subject<void>();
  triggeredCartSubmit$ = this.fffReturnService.getEmitTriggeredSubmitReturnRequest().pipe(takeUntil(this.unsubscribe$));
  outlinedIconTypes = OUTLINED_ICON_TYPE;
  editMode: boolean = false;
  contactInformationData: any = {};
  DEFAULT_PO_NUMBER: string = 'FLU FET RETURN';
  contactInformationForm: UntypedFormGroup = this.formBuilder.group({
    name: ['', [Validators.required]],
    phone: ['', [Validators.required]],
    email: ['', [Validators.required, CustomFormValidators.emailValidator]],
    poNumber: ['', [Validators.required, Validators.maxLength(20)]],
  });
  constructor(
    private formBuilder: UntypedFormBuilder,
    private fffReturnService: FFFReturnsService
  ) {
  }
  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  ngOnInit() {
    if(this.profile) {
      this.contactInformationData = {
        name: this.profile.user.name,
        phone: this.profile.user.contactNumber,
        email: this.profile.user.uid,
        poNumber: this.DEFAULT_PO_NUMBER,
      };
      this.personalDetails.emit(this.contactInformationData as FffReturn);
    }
    this.triggeredCartSubmit$.subscribe((value) => {
      if(this.editMode)
        this.contactInformationForm.markAllAsTouched();
    });
  }

  changeEditMode(): void {
    if (!this.editMode) {
      this.contactInformationForm.patchValue(this.contactInformationData);
      this.contactInformationForm.enable();
    } else {
      if (!this.contactInformationForm.valid) {
        this.contactInformationForm.markAllAsTouched();
        return;
      }
      this.contactInformationData = this.contactInformationForm.value;
      this.contactInformationForm.disable();
      this.personalDetails.emit(this.contactInformationData as FffReturn);
    }
    this.editMode = !this.editMode;
    this.emitEditMode.emit(this.editMode);
  }
}
