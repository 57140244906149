import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FffGoogleAnalyticsService } from './fff-google-analytics.service';
import { FffRingCentralChatBotService } from './fff-ring-central-chat-bot.service';


@NgModule({
  declarations: [],
  imports: [
    CommonModule,
  ],
  providers: [
    FffGoogleAnalyticsService,
    FffRingCentralChatBotService,
  ],
  exports: [],
})
/**
 * Custom third party service module.
 * implements Google Analytics service and Ring Central Chat bot.
 */
export class FffServiceThirdPartyModule { }
