import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import {
  AbstractControl,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { FffOrderService } from '@app/fff-enterprise/fff-common-services/fff-order-service';
import { ToastService } from '@app/shared/services/toast.service';
import { FffOrderFacade } from '@enterprise/fff-common-services/fff-order-facade';
import { FffDrawerService } from '@shared/drawer/fff-drawer.service';
import { CustomFormValidators } from '@spartacus/storefront';
import { take } from 'rxjs/operators';

@Component({
  selector: 'fff-resend-order-confirmation-drawer.component',
  templateUrl: './fff-resend-order-confirmation-drawer.component.html',
})
export class FffResendOrderConfirmationDrawerComponent implements OnInit {
  emailsForm: UntypedFormGroup = {} as UntypedFormGroup;
  orders: string[] = [];
  submitting: boolean = false;

  constructor(
    private drawerService: FffDrawerService,
    private formBuilder: UntypedFormBuilder,
    private orderFacade: FffOrderFacade,
    private toastService: ToastService,
    private fffOrderService: FffOrderService,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.emailsForm = this.formBuilder.group({
      emails: ['', [Validators.required]],
    });
    this.drawerService
      .getContent()
      .pipe(take(1))
      .subscribe((res: any) => {
        this.orders = (res.data || '').trim().split(',');
      });
  }

  sendEmails(): void {
    if (this.emailsForm.invalid) {
      this.emailsForm.markAllAsTouched();
      return;
    }

    if (
      this.emailsForm.get('emails')?.value !== '' &&
      this.checkEmail() &&
      !!this.orders.length
    ) {
      this.submitting = true;
      this.cd.markForCheck();
      this.orderFacade
        .resendEmail(
          this.orders.toString(),
          this.emailsForm.get('emails')?.value
        )
        .subscribe(
          (res: any) => {
            if (res) {
              this.drawerService.closeDrawer();
              this.toastService.showSuccess(
                'fffOrderHistory.resendConfirmations.message'
              );
              this.fffOrderService.confirmationsSent.emit();
            }
            this.submitting = false;
            this.cd.markForCheck();
          },
          () => {
            this.submitting = false;
            this.cd.markForCheck();
          }
        );
    }
  }

  checkEmail(): boolean {
    const emailInputValue = this.emailsForm.get('emails')?.value;
    const emails = emailInputValue.replace(/ /g, '').split(',');
    const emailsErrors = !emails.some((email: any) => {
      const validator = CustomFormValidators.emailValidator({
        value: email,
      } as AbstractControl);
      if (validator) {
        this.emailsForm.get('emails')?.setErrors(validator);
        return true;
      }
      return false;
    });
    return emailsErrors;
  }
}
