import { ImageGroup, Images } from '@spartacus/core';

export const getProductImage = function(imagesProduct: Images = {}, isFromUrl: string = ''): ImageGroup | ImageGroup[] | boolean {
  if (Array.isArray(imagesProduct['PRIMARY'])) {
    return imagesProduct['PRIMARY'][0];
  } else if (imagesProduct['PRIMARY']) {
    return imagesProduct['PRIMARY'];
  } else if (Array.isArray(imagesProduct['GALLERY'])) {
    return imagesProduct['GALLERY'][0];
  } else if (Array.isArray(imagesProduct)) {
    const imagesPrimary = imagesProduct.find(image => image.imageType === 'PRIMARY' && image.format === 'product');
    return imagesProduct.find(image => image.imageType ===  (!imagesPrimary ? 'GALLERY' : 'PRIMARY') && image.format === 'product');
  }

  return false;
};
