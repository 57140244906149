<div class="d-flex flex-wrap body-text-lg">

  <div class="content-close-icon cursor-pointer ml-auto d-flex justify-content-between p-2">
    <cx-icon class="cx-icon icon-lg" [type]="outlinedIconTypes.ICON_X" (click)="close()"></cx-icon>
  </div>

  <div class="p-5" [innerHtml]="addToCartNotice | fffSafeHtml"></div>

  <div class="col-12 modal-actions">
    <button class="btn btn-primary col-12" (click)="continueAddToCart()">{{
      'fffProduct.acknowledgeAndProceed' | cxTranslate }}</button>
  </div>

</div>
