import { Component } from '@angular/core';
import { CmsBreadcrumbsComponent, CmsService, Page, PageMetaService, TranslationService } from '@spartacus/core';
import { BreadcrumbComponent, CmsComponentData } from '@spartacus/storefront';
import { Observable, Subject } from 'rxjs';

@Component({
  selector: 'fff-breadcrumb',
  templateUrl: './fff-breadcrumb.component.html',
})
export class FffBreadcrumbComponent extends BreadcrumbComponent {

  suggestions: string = '';
  subscribe$: Subject<Boolean> = new Subject<Boolean>();

  constructor(
    public component: CmsComponentData<CmsBreadcrumbsComponent>,
    public cmsService: CmsService,
    protected pageMetaService: PageMetaService,
    translation: TranslationService) {

    super(component, pageMetaService, translation);
  }

  page$: Observable<Page> = this.cmsService.getCurrentPage()

}
