import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  INVOICE_PAYMENT_STEPS,
  InvoiceCreditCard,
  InvoicePaymentCapturePayload,
} from '@app/models/fff-invoice.model';
import { FffUser } from '@app/models/fff-user.model';
import { OccEndpointsService } from '@spartacus/core';
import { UserProfileFacade } from '@spartacus/user/profile/root';
import { BehaviorSubject, Observable, Subject, combineLatest } from 'rxjs';
import { map, switchMap, take, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class FffInvoicePaymentService {
  private selectedCreditCard$ = new BehaviorSubject<InvoiceCreditCard | null>(
    null
  );
  private selectedCreditCardOnGoBack!: InvoiceCreditCard;
  private currentStep$ = new BehaviorSubject<number>(
    INVOICE_PAYMENT_STEPS.SELECT_CARD
  );
  private successfulInvoices$ = new BehaviorSubject<any[]>([]);
  private failureInvoices$ = new BehaviorSubject<any[]>([]);
  private savedCards$ = new BehaviorSubject<InvoiceCreditCard[]>([]);
  private newCards$ = new BehaviorSubject<InvoiceCreditCard[]>([]);
  private invoices$ = new BehaviorSubject<any[]>([]);
  approvalCode: string = '';
  transactionId: string = '';
  private newCreditCardAdded: boolean = false;
  private printSubject = new Subject<void>();
  processingFee: number = 0;
  grandTotal: number = 0;
  numberOfInvoicesWithProcessingFee: number = 0;
  totalFailedTransactions: number = 0;
  totalSuccessTransactions: number = 0;
  cardIconMapping: any = {
    VISA: 'visa-card',
    MC: 'master-card',
    AMEX: 'amex-card',
    DISCOVER: 'discover-card',
    DISC: 'discover-card',
  };

  constructor(
    private http: HttpClient,
    protected occEndpoints: OccEndpointsService,
    private userProfileFacade: UserProfileFacade
  ) {}

  initialize(config: any = {}) {
    this.approvalCode = '';
    this.transactionId = '';
    this.newCreditCardAdded = !!config.newCreditCardAdded;
    if (!this.newCreditCardAdded) {
      this.selectedCreditCard$.next(null);
    }
    this.savedCards$.next([]);
    this.currentStep$.next(INVOICE_PAYMENT_STEPS.SELECT_CARD);
    this.loadSavedCards();
  }

  setInvoices(invoices: any[]) {
    this.invoices$.next(invoices);
  }

  getInvoices(): Observable<any[]> {
    return this.invoices$.asObservable();
  }
  getCurrentInvoices(): any[] {
    return this.invoices$.getValue();
  }

  setSelectedCreditCard(card: InvoiceCreditCard | null) {
    this.selectedCreditCard$.next(card);
  }

  getSelectedCreditCard() {
    return this.selectedCreditCard$.asObservable();
  }
  setSelectedCreditCardOnGoBack(card: any) {
    this.selectedCreditCardOnGoBack = card;
  }
  getSelectedCreditCardOnGoBack() {
    return this.selectedCreditCardOnGoBack;
  }

  getCurrentStep() {
    return this.currentStep$.asObservable();
  }

  setCurrentStep(step: number): void {
    this.currentStep$.next(step);
  }
  getSuccessfulInvoices() {
    return this.successfulInvoices$.asObservable();
  }

  setSuccessfulInvoices(invoices: any[]): void {
    this.successfulInvoices$.next(invoices);
  }
  getFailureInvoices() {
    return this.failureInvoices$.asObservable();
  }
  setProcessingFee(processingFee: number): void {
    this.processingFee = Number(processingFee.toFixed(2));
  }
  getProcessingFee() {
    return this.processingFee;
  }
  setGrandTotal(grandTotal: number): void {
    this.grandTotal = grandTotal;
  }
  getGrandTotal() {
    return this.grandTotal;
  }
  setNumberOfInvoicesWithProcessingFee(
    numberOfInvoicesWithProcessingFee: number
  ): void {
    this.numberOfInvoicesWithProcessingFee = numberOfInvoicesWithProcessingFee;
  }
  getNumberOfInvoicesWithProcessingFee(): number {
    return this.numberOfInvoicesWithProcessingFee;
  }
  setTotalSuccessTransactions(totalSuccessTransactions: number): void {
    this.totalSuccessTransactions = totalSuccessTransactions;
  }
  getTotalSuccessTransactions(): number {
    return this.totalSuccessTransactions;
  }
  setTotalFailureTransactions(totalFailedTransactions: number): void {
    this.totalFailedTransactions = totalFailedTransactions;
  }
  getTotalFailureTransactions(): number {
    return this.totalFailedTransactions;
  }

  setFailureInvoices(invoices: any[]): void {
    this.failureInvoices$.next(invoices);
  }

  getSavedCards(): Observable<InvoiceCreditCard[]> {
    return combineLatest([
      this.savedCards$.asObservable(),
      this.newCards$.asObservable(),
    ]).pipe(map(([savedCards, newCards]) => [...savedCards, ...newCards]));
  }

  loadSavedCards() {
    this.getPaymentDetails().subscribe((res: any) => {
      const savedCards = res?.payments || [];
      this.processCards(savedCards);
      this.savedCards$.next(savedCards);
    });
  }

  setNewCard(card: InvoiceCreditCard) {
    this.processSingleCard(card);
    const cards = this.newCards$.value || [];
    cards.push({
      ...card,
      selected: true,
    });
    let processedNewCards = cards;
    if (!card.oneTimeUse) {
      processedNewCards = this.removeDuplicationForNewlyAddedCard(cards);
    }
    this.processCards(processedNewCards);
    this.newCards$.next(processedNewCards);
    if (card.oneTimeUse) {
      this.setSelectedCreditCard(card);
    }
  }
  removeDuplicationForNewlyAddedCard(cards: any[]): any[] {
    const tokenGroups = cards.reduce((acc, item) => {
      if (!acc[item.cardToken]) {
        acc[item.cardToken] = [];
      }
      acc[item.cardToken].push(item);
      return acc;
    }, {});

    const duplicateTokens = Object.keys(tokenGroups).filter(
      token => tokenGroups[token].length > 1
    );
    let processedCards = cards;
    if (duplicateTokens && duplicateTokens.length > 0) {
      cards.forEach(card => {
        if (
          card.cardToken == duplicateTokens[duplicateTokens.length - 1] &&
          card.paymentUUID !== undefined &&
          card.paymentUUID !== null
        ) {
          this.setSelectedCreditCard(card);
          processedCards = cards.filter(
            card =>
              (card.paymentUUID !== undefined && card.paymentUUID !== null) ||
              card.oneTimeUse
          );
          return processedCards;
        }
      });
    }

    return processedCards;
  }

  clearNewCards() {
    this.newCards$.next([]);
  }

  processSingleCard(card: InvoiceCreditCard, index: number = 0) {
    card.index = index;
    card.icon = card?.cardType?.code
      ? this.cardIconMapping[card.cardType.code]
      : '';

    if (!this.newCreditCardAdded) {
      card.selected = !!card.defaultPayment;
    }
  }

  processCards(savedCards: InvoiceCreditCard[]) {
    savedCards.forEach((card, index) => {
      card.index = index;
      card.icon = card?.cardType?.code
        ? this.cardIconMapping[card.cardType.code]
        : '';

      if (!this.newCreditCardAdded) {
        card.selected = !!card.defaultPayment;
      }
      const selectedCard = this.getSelectedCreditCardOnGoBack();
      if (selectedCard) {
        this.setSelectedCreditCard(selectedCard);
      } else if (card.selected) {
        this.setSelectedCreditCard(card);
      }
    });
  }

  // APIs
  private getRequestHeaders(): HttpHeaders {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    return headers;
  }

  getOrgUser() {
    const url = this.occEndpoints.buildUrl(`orgUsers/current`);
    return this.http.get<FffUser>(url, {
      headers: this.getRequestHeaders(),
      params: { fields: 'DEFAULT' },
    });
  }

  getPaymentDetails() {
    const url = this.occEndpoints.buildUrl(`users/current/paymentdetails`);
    return this.http.get(url, {
      headers: this.getRequestHeaders(),
      params: { saved: true, fields: 'DEFAULT' },
    });
  }

  setDefaultCreditCard(payload: any, paymentUUID: any) {
    const url = this.occEndpoints.buildUrl(
      `users/current/payments/${paymentUUID}`
    );
    return this.http
      .patch(url, payload, {
        headers: this.getRequestHeaders(),
      })
      .pipe(
        tap(() => {
          this.loadSavedCards();
        })
      );
  }

  savePaymentDetails(payload: any) {
    const url = this.occEndpoints.buildUrl(
      `users/current/payments/savePaymentDetails`
    );
    return this.http.post(url, payload, {
      headers: this.getRequestHeaders(),
      params: { fields: 'DEFAULT' },
    });
  }

  updatePaymentDetails(payload: any, paymentUUID: string) {
    const url = this.occEndpoints.buildUrl(
      `users/current/payments/${paymentUUID}`
    );
    return this.http.patch(url, payload, {
      headers: this.getRequestHeaders(),
      params: { fields: 'DEFAULT' },
    });
  }

  getRegions() {
    const url = this.occEndpoints.buildUrl(`/countries/US/regions`);
    return this.http.get(url, {
      headers: this.getRequestHeaders(),
    });
  }

  getSessionToken(payload: any) {
    const url = this.occEndpoints.buildUrl(
      `users/current/payments/sessiontoken`
    );
    return this.http.post(url, payload, {
      headers: this.getRequestHeaders(),
    });
  }

  capturePayment(payload: InvoicePaymentCapturePayload) {
    return this.userProfileFacade.get().pipe(
      take(1),
      switchMap(user => {
        const url = this.occEndpoints.buildUrl(
          `users/${user?.uid}/payments/capture-payment`
        );
        return this.http.post(url, payload, {
          headers: this.getRequestHeaders(),
        });
      })
    );
  }

  deleteSavedCard(cardToken: string, paymentUUID: string) {
    const url = this.occEndpoints.buildUrl(
      `users/current/payments/${paymentUUID}`
    );
    const options = {
      headers: this.getRequestHeaders(),
      body: { cardToken: cardToken },
      params: { fields: 'DEFAULT' },
    };
    return this.http.delete(url, options);
  }
  emitPrintEvent() {
    this.printSubject.next();
  }

  getPrintEvent() {
    return this.printSubject.asObservable();
  }
}
