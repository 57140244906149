<div class="container">
  <nav>
    <span *ngFor="let crumb of crumbs$ | async as crumbs; let i = index">
      <a class="crumbs body-text-sm" [routerLink]="crumb.link" [innerHTML]="crumb.label">
      </a>
      <span class="pl-1 pr-1" *ngIf="i !== (crumbs.length - 1)">/</span>
    </span>
  </nav>

  <ng-container *ngIf="page$ | async as page">
    <ng-container *ngIf="title$ | async as title">
      <h1 *ngIf="page.pageId !== 'productDetails'" class="title h3">{{title}}</h1>
    </ng-container>
  </ng-container>

</div>