<div class="card personalDetailsCard" fffDisableDiv [isDisabled]="isASMUser">
  <div class="card-header">
    <h4>
      {{ 'fffAccount.fffProfileManagement.wallet.wallet' | cxTranslate }}
    </h4>
  </div>
  <div class="profile-wallet-container">
    <div>
      <h4 class="wallet-saved-cards-title">
        {{
          'fffAccount.fffProfileManagement.wallet.savedCreditCards'
            | cxTranslate
        }}
      </h4>
    </div>
    <div
      class="fff-select-credit-card__list mb-4"
      *ngIf="!loading; else loadingTemplate"
    >
      <div
        class="fff-select-credit-card__item"
        *ngFor="let card of savedCards; let i = index"
      >
        <div
          class="main-content wallet-credit-card fff-select-credit-card__action fff-select-credit-card__action--added-card"
          [ngClass]="{
            'fff-select-credit-card__action--selected':
              card.index === (selectedCreditCard$ | async)?.index
          }"
          (mouseenter)="showCardActionContent(i)"
          (mouseleave)="hideCardActionContent(i)"
        >
          <div class="card-content" [ngStyle]="cardContentStyles[i]">
            <div class="fff-select-credit-card__action-header">
              <span> </span>
              <div
                class="fff-select-credit-card__action-cc-image fff-select-credit-card__action-cc-image--{{
                  card.icon
                }}"
              ></div>
            </div>
            <div class="fff-select-credit-card__action-body">
              <span>{{
                card?.cardToken | fffCreditCardMask : card?.cardType?.code
              }}</span>
            </div>
            <div class="fff-select-credit-card__action-footer pt-4">
              <span *ngIf="!isASMUser"
                >{{ card.expiryMonth }}/{{ card.expiryYear }}</span
              >
              <span *ngIf="isASMUser">**/**</span>
            </div>
          </div>
          <div
            class="card-action-content"
            [ngStyle]="cardActionStyles[i]"
            *ngIf="showCardActions[i]"
          >
            <div
              class="edit-section d-flex flex-column"
              (mouseenter)="editHovered = true"
              (mouseleave)="editHovered = false"
              [ngClass]="{ actionHovered: editHovered }"
            >
              <!-- Edit content -->
              <cx-icon
                class="cx-icon icon-sm pl-2 cx-icon-info"
                [ngClass]="{ editHovered: editHovered }"
                [type]="filledIconTypes.ICON_PENCIL_FILL"
                (click)="editCard(card)"
              ></cx-icon>
              <div class="card-action-font">
                {{
                  'fffAccount.fffProfileManagement.wallet.edit' | cxTranslate
                }}
              </div>
            </div>
            <div class="divider"></div>
            <div
              class="delete-section d-flex flex-column"
              (mouseenter)="deleteHovered = true"
              (mouseleave)="deleteHovered = false"
              [ngClass]="{ actionHovered: deleteHovered }"
            >
              <!-- Delete content -->
              <cx-icon
                class="cx-icon icon-sm pl-2 cx-icon-info"
                [ngClass]="{ deleteHovered: deleteHovered }"
                [type]="filledIconTypes.ICON_TRASH_FILL"
                (click)="deleteCard(card.cardToken, card.paymentUUID)"
              ></cx-icon>
              <div class="card-action-font">
                {{
                  'fffAccount.fffProfileManagement.wallet.delete' | cxTranslate
                }}
              </div>
            </div>
          </div>
        </div>
        <div class="d-flex justify-content-between ml-2 mr-2">
          <div class="expired-font">
            <label *ngIf="isDateExpired(card.expiryMonth, card.expiryYear)">
              {{
                'fffAccount.fffProfileManagement.wallet.expired' | cxTranslate
              }}
            </label>
          </div>
          <div>
            <button
              type="button default-font"
              class="btn btn-link p-0"
              *ngIf="!!card.defaultPayment"
            >
              {{ 'fffInvoicePayment.default' | cxTranslate }}
            </button>
            <button
              *ngIf="
                !card.defaultPayment &&
                !isDateExpired(card.expiryMonth, card.expiryYear)
              "
              type="button"
              class="btn btn-link p-0 default-font"
              (click)="setAsDefault(card)"
              [disabled]="loading"
            >
              {{ 'fffInvoicePayment.setDefault' | cxTranslate }}
            </button>
          </div>
        </div>
      </div>
      <div class="fff-select-credit-card__item">
        <button
          [disabled]="loading"
          type="button"
          class="wallet-credit-card fff-select-credit-card__action fff-select-credit-card__action--new-card"
          (click)="openAddNewCardDialog()"
        >
          <div class="card-content" [ngStyle]="cardContentStyles">
            <div class="fff-cc-icon">
              <cx-icon [type]="outlineIcons.ICON_PLUS_CIRCLE"></cx-icon>
            </div>
            <div class="fff-cc-label">
              {{
                'fffAccount.fffProfileManagement.wallet.addNewCard'
                  | cxTranslate
              }}
            </div>
          </div>
        </button>
      </div>
    </div>
  </div>
</div>

<ng-template #loadingTemplate>
  <div class="cx-spinner">
    <cx-spinner></cx-spinner>
  </div>
</ng-template>
