import { CommonModule, DecimalPipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { AuthGuard, ConfigModule, I18nModule } from '@spartacus/core';
import {
  GenericLinkModule,
  IconModule,
} from '@spartacus/storefront';
import { FffDrawerModule } from 'src/app/shared/drawer/fff-drawer.module';
import { FffUserPriceListComponent } from './fff-user-price-list.component';

import {
  FffExportedDocumentMessageComponent,
} from '@enterprise/fff-user-price-list/fff-exported-document-message/fff-exported-document-message';
import { FffTableModule } from 'src/app/shared/table/fff-table.module';
import { FffFilterProductTypePipe } from './fff-filter-product-type.pipe';
import { FffUserPriceListDrawerApplyComponent } from './fff-user-price-list-drawer-apply.component';
import { FffUserPriceListDrawerComponent } from './fff-user-price-list-drawer.component';
import { FFFLoadersModule } from '@app/shared/components/fff-loaders/fff-loaders.module';


@NgModule({
  declarations: [
    FffUserPriceListComponent,
    FffUserPriceListDrawerComponent,
    FffUserPriceListDrawerApplyComponent,
    FffExportedDocumentMessageComponent,
    FffFilterProductTypePipe,
  ],
  imports: [
    CommonModule,
    FormsModule,
    IconModule,
    GenericLinkModule,
    I18nModule,
    FffDrawerModule,
    FffTableModule,
    FFFLoadersModule,
    ConfigModule.withConfig({
      cmsComponents: {
        MyPriceListComponentFlex: {
          component: FffUserPriceListComponent,
          guards: [AuthGuard],
          disableSSR: true,
        },
      },
    }),
  ],
  providers:[DecimalPipe],
})
export class FffUserPriceListModule { }
