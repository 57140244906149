import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { gridBreakpointsLG } from '@app/fff-config/content/constants';
import { FffInvoicePaymentService } from '@app/fff-enterprise/fff-common-services/fff-invoice-payment.service';
import { INVOICE_PAYMENT_STEPS } from '@app/models/fff-invoice.model';
import { Subscription } from 'rxjs';

interface ITableState {
  data: any[];
  total: number;
  loading: boolean;
  current: number;
}

@Component({
  selector: 'fff-invoice-payment-entries',
  templateUrl: './fff-invoice-payment-entries.component.html',
  styleUrls: ['./fff-invoice-payment-entries.component.scss'],
})
export class FffInvoicePaymentEntriesComponent
  implements OnChanges, OnInit, OnDestroy
{
  @Input() invoices: any[] = [];

  @Output() removed = new EventEmitter<string>();

  private readonly DEFAULT_FIELDS = [
    { title: 'fffInvoiceHistory.invoice', field: 'invoiceNumber' },
    { title: 'fffInvoiceHistory.invoiceDate', field: 'invoiceDate' },
    { title: 'fffInvoiceHistory.invoiceDueDate', field: 'dueDate' },
    { title: 'fffInvoiceHistory.po', field: 'poNumber' },
    {
      title: 'fffInvoiceHistory.manufacturer',
      field: 'manufacturer',
    },
    {
      title: 'fffInvoiceHistory.actions.actions',
      field: 'actions',
      hidden: true,
    },
    { title: 'fffInvoiceHistory.amount', field: 'grandTotal' },
  ];

  mobileSize: any = gridBreakpointsLG;
  columnsGrid: any = {
    desktop: '1fr 1fr 1fr 1fr 2fr 1fr 1fr',
    mobile: '1fr 1fr',
  };
  fields: any[] = [];
  invoicesTableStateWithProcessingFee: ITableState = {
    data: [],
    total: 0,
    loading: false,
    current: 0,
  };
  invoicesTableStateWithoutProcessingFee: ITableState = {
    data: [],
    total: 0,
    loading: false,
    current: 0,
  };

  currentStep: number = 0;

  subs = new Subscription();
  totalAmountForInvoicesWithProcessingFee: number = 0;
  totalAmountForInvoicesWithoutProcessingFee: number = 0;
  processingFee: number = 0;
  get isFirstStep(): boolean {
    return this.currentStep === INVOICE_PAYMENT_STEPS.SELECT_CARD;
  }

  constructor(
    private fffInvoicePaymentService: FffInvoicePaymentService,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit(): void {}

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['invoices']) {
      this.init();
    }
  }

  init() {
    this.subs.add(
      this.fffInvoicePaymentService.getCurrentStep().subscribe(step => {
        this.currentStep = step;
        this.loadTable();
      })
    );
  }

  loadTable() {
    this.formatFields();
    this.setTableState();
  }

  formatFields() {
    this.columnsGrid.desktop = '';
    this.fields = this.DEFAULT_FIELDS.map(item => {
      if (item.field !== 'actions') {
        return item;
      }
      return {
        ...item,
        hidden: !this.isFirstStep,
      };
    }).filter(field => !field.hidden);
    this.fields.forEach(item => {
      let gridValue = '1fr ';
      if (['manufacturer'].includes(item.field)) {
        gridValue = '2fr ';
      }
      if (['poNumber'].includes(item.field)) {
        gridValue = '1.5fr ';
      }
      this.columnsGrid.desktop += gridValue;
    });
  }

  setTableState() {
    const defaultTableState: ITableState = {
      data: [],
      total: 0,
      loading: false,
      current: 0,
    };

    // Filter invoices based on surcharge eligibility
    const invoicesWithProcessingFee = this.invoices.filter(
      (invoice: any) => invoice.surchargeECheckPaymentEligible
    );

    this.totalAmountForInvoicesWithProcessingFee = invoicesWithProcessingFee
      ?.map(v => +v.grandTotal || 0)
      .reduce((prev, curr) => prev + curr, 0);
    this.processingFee = this.totalAmountForInvoicesWithProcessingFee * 0.03;

    if (invoicesWithProcessingFee?.length > 0) {
      this.fffInvoicePaymentService.setNumberOfInvoicesWithProcessingFee(
        invoicesWithProcessingFee?.length
      );
      this.fffInvoicePaymentService.setProcessingFee(this.processingFee);
    } else {
      this.fffInvoicePaymentService.setNumberOfInvoicesWithProcessingFee(0);
      this.fffInvoicePaymentService.setProcessingFee(0);
    }
    const invoicesWithoutProcessingFee = this.invoices.filter(
      (invoice: any) => !invoice.surchargeECheckPaymentEligible
    );

    this.totalAmountForInvoicesWithoutProcessingFee =
      invoicesWithoutProcessingFee
        ?.map(v => +v.grandTotal || 0)
        .reduce((prev, curr) => prev + curr, 0);

    this.invoicesTableStateWithProcessingFee = {
      ...defaultTableState,
      data: invoicesWithProcessingFee,
      total: invoicesWithProcessingFee.length,
    };

    this.invoicesTableStateWithoutProcessingFee = {
      ...defaultTableState,
      data: invoicesWithoutProcessingFee,
      total: invoicesWithoutProcessingFee.length,
    };
    this.fffInvoicePaymentService.setGrandTotal(
      this.totalAmountForInvoicesWithProcessingFee +
        this.totalAmountForInvoicesWithoutProcessingFee +
        this.processingFee
    );

    this.cd.markForCheck();
  }

  onRemoveInvoice(invoiceNumber: string) {
    this.removed.emit(invoiceNumber);
  }
}
