import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { I18nModule } from '@spartacus/core';
import { FormErrorsModule, GenericLinkModule, IconModule } from '@spartacus/storefront';
import { FffDrawerModule } from 'src/app/shared/drawer/fff-drawer.module';
import { FffSaveCartDrawerBodyComponent } from './fff-save-cart-drawer-body.component';
import { FffSaveCartDrawerFooterComponent } from './fff-save-cart-drawer-footer.component';
import { FffSaveCartDrawerHeaderComponent } from './fff-save-cart-drawer-header.component';


@NgModule({
  declarations: [
    FffSaveCartDrawerBodyComponent,
    FffSaveCartDrawerHeaderComponent,
    FffSaveCartDrawerFooterComponent
  ],
  imports: [
    CommonModule,
    IconModule,
    GenericLinkModule,
    I18nModule,
    FormsModule,
    ReactiveFormsModule,
    FormErrorsModule,
    FffDrawerModule
  ],
})
export class FffSaveCartDrawerModule { }
