import { Component, Input, TemplateRef } from '@angular/core';

@Component({
  selector: 'fff-return-user-details-header',
  templateUrl: './fff-return-user-details-header.component.html',
})
export class FffReturnUserDetailsHeaderComponent {
  @Input() accountNumber: string = '';
  @Input() accountName: string = '';
  @Input() email: string = '';
  @Input() contactName: string = '';
  @Input() phoneNumber: string = '';
  @Input() footer: TemplateRef<any> | undefined;
  @Input() deliveryTerms: string = '';
  @Input() paymentTerms: string = '';
}
