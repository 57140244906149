import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { OUTLINED_ICON_TYPE } from '@app/models/fff-outline-icons.model';
import { FffProfile } from '@app/models/fff-profile.model';
import { FffUserAccountService } from '@app/shared/services/fff-user-account.service';
import { AuthService } from '@spartacus/core';
import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { FffDrawerService } from 'src/app/shared/drawer/fff-drawer.service';
import { FFF_MINICART } from 'src/assets/translations/en/fff-register';

@Component({
  selector: 'fff-hamburger-header',
  templateUrl: './fff-hamburger-header.component.html',
})
export class FffHamburgerHeaderComponent implements OnInit {

  profile$: Observable<FffProfile | undefined> = of(undefined);
  miniCart: any = FFF_MINICART.FFF_DATA;
  iconClose = OUTLINED_ICON_TYPE.ICON_X;
  constructor(private router: Router,
    private auth: AuthService,
    private fffUserAccountService: FffUserAccountService,
    private drawerService: FffDrawerService) {
  }

  ngOnInit(): void {
    this.profile$ = this.auth.isUserLoggedIn().pipe(
      switchMap((isUserLoggedIn) => {
        if (isUserLoggedIn) {
          return this.fffUserAccountService.getProfile();
        } else {
          this.fffUserAccountService.removeProfile();
          return of(undefined);
        }
      })
    );
  }

  goToPage(url: string) {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.onSameUrlNavigation = 'reload';
    this.drawerService.closeDrawer();
    this.router.navigate([url]);
  }

  public closePopover(): void {
    this.drawerService.closeDrawer();
  }
}
