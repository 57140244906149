import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { FFFLoadersModule } from '@app/shared/components/fff-loaders/fff-loaders.module';
import { FffAddToFavoritesModule } from '@enterprise/fff-favorites/shared-components/fff-add-to-favorites/fff-add-to-favorites.module';
import { FffTooltipModule } from '@shared/tooltip/tooltip.module';
import { AddToCartModule } from '@spartacus/cart/base/components/add-to-cart';
import { I18nModule, UrlModule } from '@spartacus/core';
import { IconModule, MediaModule } from '@spartacus/storefront';
import { FffProductAllocationModule } from 'src/app/fff-enterprise/fff-product-allocation/fff-product-allocation.module';
import { FffProductBadgeInfoModule } from 'src/app/fff-enterprise/fff-product/fff-product-badge-info/fff-product-badge-info.module';
import { FffProductItemInfoModule } from 'src/app/fff-enterprise/fff-product/fff-product-item-info/fff-product-item-info.module';
import { FffProductMoreInfoModule } from 'src/app/fff-enterprise/fff-product/fff-product-more-info/fff-product-more-info.module';
import { FffProductModule } from 'src/app/fff-enterprise/fff-product/fff-product.module';
import { FffSplitBillingModule } from '../../../fff-product/fff-split-billing/fff-split-billing.module';
import { FffProductListActualStrengthModule } from '../fff-product-list-actual-strength/fff-product-list-actual-strength.module';
import { FffProductListItemComponent } from './fff-product-list-item.component';

@NgModule({
  declarations: [FffProductListItemComponent],
  imports: [
    UrlModule,
    BrowserModule,
    RouterModule,
    CommonModule,
    I18nModule,
    FffProductAllocationModule,
    AddToCartModule,
    MediaModule,
    FffProductMoreInfoModule,
    FffProductBadgeInfoModule,
    FffProductItemInfoModule,
    FffSplitBillingModule,
    FffProductModule,
    FffAddToFavoritesModule,
    FffTooltipModule,
    FffProductListActualStrengthModule,
    FFFLoadersModule,
    IconModule,
  ],
  exports: [
    FffProductListItemComponent,
    AddToCartModule,
    FffProductItemInfoModule,
    FffSplitBillingModule,
  ],
  providers: [],
})
/**
 * Export FffProductList Module.
 * Contains changes for fff-enterprise.
 */
export class FffProductListItemModule {}
