<div class="d-none d-lg-block container" *ngIf="dataNav$ | async as data">
  <div class="col-10 d-flex flex-wrap m-auto px-0">
    <ng-container
      *ngFor="let child of data.navigationNode.children; index as i"
    >
      <div
        class="account-nav-content d-flex flex-wrap m-auto"
        *ngIf="accountNav && accountNav[i].url && isEligibleNode(child.title)"
      >
        <div
          class="item d-flex m-auto cursor-pointer body-text-lg justify-center align-items-center"
          [class.selected]="isNodeSelected(child)"
          (click)="selectMyAccountNav(child)"
          *ngIf="notifications$ | async as notifications"
        >
          {{ child.title }}

          <div
            *ngIf="fetchMedals(child.title, notifications) as fetchedMedal"
            class="badge text-center"
          >
            {{ fetchedMedal }}
          </div>
        </div>
      </div>
    </ng-container>
  </div>
</div>

<div
  class="d-block d-lg-none account-nav-content"
  *ngIf="dataNav$ | async as data"
>
  <div class="col-10 m-auto px-0 pb-2">
    <div class="d-flex flex-wrap m-auto" (click)="getShowMenu()">
      <div
        class="item my-account-text m-auto d-flex flex-wrap cursor-pointer body-text-lg"
      >
        <span *ngIf="selectedNode">{{ selectedNode }}</span>
        <span *ngIf="!selectedNode">
          {{ 'fffAccount.myAccount' | cxTranslate }}
        </span>
        <cx-icon
          class="cx-icon"
          [type]="outlinedIconTypes.ICON_CHEVRON_DOWN"
        ></cx-icon>
      </div>
    </div>

    <div
      class="col-12 position-absolute mt-2"
      [class.d-none]="!showMenuDropDown"
      *ngIf="notifications$ | async as notifications"
    >
      <div class="col-10 m-auto menu-content">
        <div
          class="d-flex flex-wrap m-auto divider"
          *ngFor="let child of data.navigationNode.children"
        >
          <div
            class="item d-flex m-auto cursor-pointer body-text-lg justify-center align-items-center"
            (click)="selectMyAccountNav(child)"
          >
            {{ child.title }}

            <div
              *ngIf="fetchMedals(child.title, notifications) as fetchedMedal"
              class="badge text-center"
            >
              {{ fetchedMedal }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
