<div class="d-flex flex-wrap body-text-lg">

  <div class="content-close-icon cursor-pointer ml-auto d-flex justify-content-between p-2" (click)="close()">
    <cx-icon class="cx-icon icon-lg" [type]="outlinedIconTypes.ICON_X">
    </cx-icon>
  </div>

  <ng-container *ngIf="errors.length > 0; else validForm">

    <div class="col-12 text-center content-header">
      <cx-icon class="cx-icon icon-lg pb-3" [type]="outlinedIconTypes.ICON_SHIELD_EXCLAMATION"></cx-icon>
      <span class="col-12 text-center">{{ 'fffCheckoutPlaceOrder.header' | cxTranslate }}</span>
    </div>


    <div class="col-12 text-center cx-dialog-body modal-body content-body">

      <p class="mb-3">
        <b>{{'fffReports.errorsFound' | cxTranslate}}:</b>
      </p>

      <ul class="error-list-reports">
        <li *ngFor="let error of errors">
          <div class="mb-2"> {{ error | cxTranslate }}</div>
        </li>
      </ul>

    </div>

  </ng-container>


  <ng-template #validForm>

    <div class="col-12 text-center content-header">
      <cx-icon class="cx-icon icon-lg pb-3" [type]="outlinedIconTypes.ICON_SHIELD_CHECK"></cx-icon>
      <span class="col-12 text-center">Success</span>
    </div>


    <div class="col-12 text-center cx-dialog-body modal-body content-body">

      <p class="mb-3">
        <b>{{'fffReports.submitted' | cxTranslate}}</b>
      </p>

    </div>

  </ng-template>


</div>