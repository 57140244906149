import { Component, Input } from '@angular/core';

import { RoutingService } from '@spartacus/core';
import { CUSTOM_ICON_TYPE } from 'src/app/models/fff-custom-icons.model';
import { FffDrawerService } from 'src/app/shared/drawer/fff-drawer.service';

@Component({
  selector: 'fff-mini-cart-empty',
  templateUrl: './fff-mini-cart-empty.component.html',
})
export class FffMiniCartEmptyComponent {
  @Input() showActions: boolean = true;

  constructor(
    private routingService: RoutingService,
    private drawerService: FffDrawerService
  ) {}

  customIconTypes = CUSTOM_ICON_TYPE;

  goToHomePage() {
    this.drawerService.closeDrawer();
    this.routingService.go('/');
  }
}
