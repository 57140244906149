import { NgModule, Optional, SkipSelf } from '@angular/core';
import { FFFI18nModule } from './i18n/i18n.module';

@NgModule({
  imports: [FFFI18nModule.forRoot()],
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    if (parentModule) {
      throw new Error(
        'CoreModule is already loaded. Import it in the AppModule only'
      );
    }
  }
}
