import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FffSsoBridgePageComponent } from './fff-sso-bridge-page/fff-sso-bridge-page.component';

@NgModule({
  declarations: [FffSsoBridgePageComponent],
  imports: [CommonModule],
  exports: [FffSsoBridgePageComponent],
})
export class FffSsoModule {}
