import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { gridBreakpointsLG } from '@app/fff-config/content/constants';
import { fffCSOSConfig } from '@app/fff-config/fff-endpoints.config';
import { defaultPageSize } from '@app/fff-config/pagination/pagination.config';
import { FffB2BUnitService } from '@app/fff-enterprise/fff-common-services/fff-b2b-unit-service';
import { FffCommunicationService } from '@app/fff-enterprise/fff-common-services/fff-communication.service';
import { FffOrderFacade } from '@app/fff-enterprise/fff-common-services/fff-order-facade';
import { FffOrderService } from '@app/fff-enterprise/fff-common-services/fff-order-service';
import { FffExportedDocumentMessageComponent } from '@app/fff-enterprise/fff-user-price-list/fff-exported-document-message/fff-exported-document-message';
import { FILLED_ICON_TYPE } from '@app/models/fff-filled-icons.model';
import { OUTLINED_ICON_TYPE } from '@app/models/fff-outline-icons.model';
import { FffTagItem } from '@app/shared/components/form/fff-form-controls/fff-tags/fff-tags.model';
import { FffDrawerService } from '@app/shared/drawer/fff-drawer.service';
import { FffResendOrderConfirmationDrawerComponent } from '@enterprise/fff-order/fff-order-history/fff-resend-order-confirmation-drawer/fff-resend-order-confirmation-drawer.component';
import { environment } from '@env/environment';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AsmEnablerService } from '@spartacus/asm/root';
import {
  OccEndpointsService,
  RoutingService,
  TranslationService,
  WindowRef,
} from '@spartacus/core';
import { OrderFacade } from '@spartacus/order/root';
import { ExportCsvFileService, ExportFileOptions } from '@spartacus/storefront';
import { UserAccountFacade } from '@spartacus/user/account/root';
import { BehaviorSubject, Observable, Subject, of } from 'rxjs';
import { map, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { FFFLegisymMessagePopupComponent } from '../fff-legisym-message-popup/fff-legisym-message-popup.component';
import { FffOrderHistoryFilterComponent } from './fff-order-history-filter/fff-order-history-filter.component';

@Component({
  selector: 'fff-order-history-list',
  templateUrl: './fff-order-history-list.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FffOrderHistoryListComponent implements OnInit, OnDestroy {
  filledIconTypes = FILLED_ICON_TYPE;
  outlinedIconTypes = OUTLINED_ICON_TYPE;
  columnsGrid: any = {
    desktop: '1fr 1fr 1fr 1fr 5fr',
    mobile: '1fr 1fr',
  };
  fields: any = [
    { title: 'fffOrderHistory.order', field: 'order' },
    { title: 'fffOrderHistory.orderDate', field: 'orderDate' },
    { title: 'fffOrderHistory.status', field: 'sapOrderStatus' },
    { title: 'fffOrderHistory.po', field: 'purchaseOrderNumber' },
    { title: 'fffOrderHistory.action', field: 'action' },
  ];
  orderFilterSubscription: any;
  filter: any = {};
  hasFilter: boolean = false;
  showTooltip: boolean = false;
  currentPage: number = 0;
  signOrderResult$: BehaviorSubject<any> = new BehaviorSubject<any>(undefined);
  selectedFilters: FffTagItem[] = [];

  private PAGE_SIZE = 10;
  private SORT_TYPE: string = 'byDate';
  private CONSIGNMENT_SHIPPED_STATUS: string = 'SHIPPED';
  private SIGN_ORDER_FORM_ID = 'goExpress222SignOrderForm';
  private PRINT_ORDER_FORM_ID = 'goExpress222PrintForm';
  private RECEIVE_ORDER_FORM_ID = 'goExpress222ReceiveForm';
  private FIELD_CURRENT_ORDER_ID = 'currentOrderId';
  private FIELD_PAGE = 'page';

  orderHistoryList$: Observable<any> | undefined;
  ordersRes: any;

  currentOrderId: string = '';
  mobileSize: any = gridBreakpointsLG;
  legisymDataSO: any = {};
  legisymDataPR: any = {};
  legisymDataRC: any = {};
  userData: any = {};
  userData$: Observable<any> = this.userAccountDetails.get();
  private unsubscribe$: Subject<void> = new Subject<void>();
  b2bUnitData$: Observable<any> = this.userData$.pipe(
    switchMap(user => {
      this.userData = user;
      this.validateInitialData(user);
      return this.communicationService.getCurrentAccount(user);
    })
  );
  selectedOrders: string[] = [];

  isAsmEnabled:boolean = this.asmEnableService.isEnabled()

  constructor(
    protected routing: RoutingService,
    protected orderFacade: OrderFacade,
    private fffOrderService: FffOrderService,
    private fffOrderFacade: FffOrderFacade,
    public windowRef: WindowRef,
    public drawerService: FffDrawerService,
    public orderService: FffOrderService,
    private fffB2bUnitService: FffB2BUnitService,
    private occEndpoints: OccEndpointsService,
    private userAccountDetails: UserAccountFacade,
    private communicationService: FffCommunicationService,
    private activatedRoute: ActivatedRoute,
    private modalService: NgbModal,
    public translationService: TranslationService,
    public cdRef: ChangeDetectorRef,
    public router: Router,
    public exportCSVService: ExportCsvFileService,
    public asmEnableService:AsmEnablerService
  ) {}

  ngOnInit() {
    this.selectedOrders = [];
    sessionStorage.removeItem('orderHistoryFilter');

    this.activatedRoute.queryParams
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(queryParams => {
        this.filter.csosOrderStatuses = queryParams['requiresSignature']
          ? 'OPEN'
          : null;
        this.userData$.pipe(take(1)).subscribe(res => {
          this.validateInitialData(res);
        });
      });

    this.orderService.confirmationsSent
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(() => {
        this.selectedOrders = [];
        this.orderHistoryList$ = of(this.ordersRes).pipe(
          map(res => ({
            ...res,
            orders: (res.orders || []).map((order: any) => ({
              ...order,
              selected: false,
            })),
          }))
        );
        this.cdRef.markForCheck();
      });
  }

  private validateInitialData(userData: any): void {
    if (userData) {
      if (this.activatedRoute.snapshot.queryParams[this.FIELD_PAGE]) {
        this.currentPage =
          this.activatedRoute.snapshot.queryParams[this.FIELD_PAGE];
      }
      setTimeout(() => {
        this.fetchOrders(
          { sortCode: '', currentPage: this.currentPage },
          userData.uid
        );
      }, 2000);

      this.orderFilterSubscription = this.orderService.messageSource.subscribe(
        async resp => {
          if (Object.keys(resp).length > 0) {
            this.filter = resp;
            this.setFiltersForDisplay(resp);
            this.hasFilter = true;
            this.selectedOrders = [];
            this.fetchOrders(
              { sortCode: '', currentPage: this.currentPage },
              userData.uid
            );
          }
        }
      );

      if (
        this.activatedRoute.snapshot.queryParams[this.FIELD_CURRENT_ORDER_ID]
      ) {
        this.currentOrderId =
          this.activatedRoute.snapshot.queryParams[this.FIELD_CURRENT_ORDER_ID];
        this.updatedSignStatus(this.currentOrderId);
      }
    }
  }

  formatDate(dateString: string): string {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  }

  // Function to process the selected filters
  setFiltersForDisplay(data: any) {
    const result = [];

    if (data.statuses) {
      const statuses = data.statuses.split(',');
      if (statuses && statuses.length > 0) {
        statuses.forEach((status: any) => {
          const id = 'statuses';
          result.push({ id, displayValue: status });
        });
      }
    }

    if (data.startDate) {
      result.push({
        id: 'startDate',
        displayValue: `From: ${this.formatDate(data.startDate)}`,
      });
    }
    if (data.endDate) {
      result.push({
        id: 'endDate',
        displayValue: `To: ${this.formatDate(data.endDate)}`,
      });
    }

    // Pushing csosOrderStatuses
    if (data.csosOrderStatuses) {
      const csosOrderStatuses = data.csosOrderStatuses.split(',');
      if (csosOrderStatuses && csosOrderStatuses.length > 0) {
        if (csosOrderStatuses.includes('OPEN')) {
          result.push({
            id: 'csosOrderStatuses',
            displayValue: 'Require Signature',
          });
        }
        if (csosOrderStatuses.includes('COMPLETED')) {
          result.push({ id: 'csosOrderStatuses', displayValue: 'Signed' });
        }
      }
    }

    this.selectedFilters = result;
    this.cdRef.detectChanges();
  }

  removeSelectedStatus(
    appliedStatuses: string,
    statusToRemove: string
  ): string {
    let statuses = appliedStatuses.split(',');

    statuses = statuses.filter((word: any) => word.trim() !== statusToRemove);

    let result = statuses.join(',');

    result = result.replace(/(^,|,$|,{2,})/g, '');

    return result;
  }
  removeItem(item: FffTagItem, index: number) {
    this.selectedFilters.splice(index, 1);
    const updatedFilter = this.filter;
    let o = sessionStorage.getItem('orderHistoryFilter');
    let parsedFilter = o ? JSON.parse(o) : null;
    if (
      item.id == 'startDate' ||
      (item.id == 'endDate' && updatedFilter[item.id])
    ) {
      updatedFilter[item.id] = undefined;
      const sessionStorageKey = item.id == 'startDate' ? 'fromDate' : 'toDate';
      if (parsedFilter.hasOwnProperty(sessionStorageKey)) {
        delete parsedFilter[sessionStorageKey];
      }
    }
    if (
      item.id == 'csosOrderStatuses' ||
      (item.id == 'statuses' && updatedFilter[item.id])
    ) {
      const key =
        item.id == 'statuses'
          ? item.displayValue
          : item.displayValue == 'Signed'
          ? 'COMPLETED'
          : 'OPEN';
      const updatedStatuses = this.removeSelectedStatus(
        updatedFilter[item.id],
        key
      );
      updatedFilter[item.id] = updatedStatuses;
      let sessionStorageKey = '';
      if (item.id == 'statuses') {
        sessionStorageKey =
          item.displayValue == 'Open'
            ? 'open'
            : item.displayValue == 'Fully Shipped'
            ? 'fullyShipped'
            : 'partiallyShipped';
      } else {
        sessionStorageKey =
          item.displayValue == 'Signed' ? 'signed' : 'requiresSignature';
      }
      if (sessionStorageKey && parsedFilter[sessionStorageKey]) {
        parsedFilter[sessionStorageKey] = false;
      }
    }
    sessionStorage.setItem('orderHistoryFilter', JSON.stringify(parsedFilter));
    this.filter = updatedFilter;
    this.hasFilter = true;
    this.selectedOrders = [];
    this.fetchOrders(
      { sortCode: '', currentPage: this.currentPage },
      this.userData.uid
    );
    this.cdRef.detectChanges();
  }

  clearAllFilters() {
    sessionStorage.removeItem('orderHistoryFilter');
    this.selectedFilters = [];
    this.filter = {};
    this.hasFilter = false;
    this.selectedOrders = [];
    this.fetchOrders(
      { sortCode: '', currentPage: this.currentPage },
      this.userData.uid
    );
    this.cdRef.detectChanges();
  }

  getTableSizeFromPagination(data: any): number {
    if (data && data.pagination && data.pagination.pageSize) {
      return data.pagination.pageSize;
    }
    return defaultPageSize;
  }

  hasData(orderHistory: any): boolean {
    return orderHistory.orders.length > 0;
  }

  getTableData(orderHistory: any | undefined) {
    if (orderHistory && orderHistory.orders) {
      return {
        data: orderHistory.orders,
        total: orderHistory.pagination.totalResults,
        loading: false,
        current: orderHistory.pagination.currentPage,
      };
    } else {
      return { data: [], total: 0, loading: false, current: 0 };
    }
  }

  getData(eventChange: any) {
    this.currentPage = eventChange.page - 1;
    this.cdRef.markForCheck();

    const event: { sortCode: string; currentPage: number } = {
      sortCode: this.SORT_TYPE,
      currentPage: eventChange.page - 1,
    };
    this.fetchOrders(event, this.userData.uid);
  }

  async fetchOrders(
    event: { sortCode: string; currentPage: number },
    userId: string
  ) {
    this.orderHistoryList$ = this.fffOrderFacade
      .loadOrderList(
        userId,
        this.PAGE_SIZE,
        event.currentPage,
        this.filter,
        this.userData?.orgUnit?.uid
      )
      .pipe(
        tap(res => {
          this.ordersRes = res;
        }),
        map(res => ({
          ...res,
          orders: (res.orders || []).map((order: any) => ({
            ...order,
            selected: this.selectedOrders.includes(order.code),
          })),
        }))
      );
    this.cdRef.detectChanges();
  }

  goToOrderDetails(orderId: string): void {
    this.routing.go([`/my-account/order/${orderId}`], {
      queryParams: { page: this.currentPage },
    });
  }

  hasShippingInformation(item: any): boolean {
    const shippingConsignments = item.consignments.filter(
      (c: any) => this.CONSIGNMENT_SHIPPED_STATUS == c.status
    );
    return shippingConsignments.length > 0;
  }

  getShippingInformation(item: any): any[] {
    return item.consignments.filter(
      (c: any) => this.CONSIGNMENT_SHIPPED_STATUS == c.status
    );
  }

  openTrackingTab(url: string): void {
    if (this.windowRef.isBrowser()) {
      this.windowRef.nativeWindow?.open(url, '_blank');
    }
  }

  resendConfirmation(orderId: string): void {
    this.drawerService.setContent({
      title: 'Send Order Confirmation Copy',
      component: FffResendOrderConfirmationDrawerComponent,
      animation: 'SideRTL',
      class: 'order_history',
      data: orderId,
    });
    this.drawerService.openDrawer();
  }

  resendBulkConfirmation(): void {
    this.drawerService.setContent({
      title: 'Resend Order Confirmation',
      component: FffResendOrderConfirmationDrawerComponent,
      animation: 'SideRTL',
      class: 'order_history',
      data: this.selectedOrders.toString(),
    });
    this.drawerService.openDrawer();
  }

  signOrder(order: any, b2bUnitData: any): void {
    this.signCsosOrder(order.code)
      .pipe(take(1))
      .subscribe((response: any) => {
        if (response.csosLoginToken && response.csosOrderId) {
          const redirectUrl = `${fffCSOSConfig.actions.signOrder.redirectUrl}?${this.FIELD_CURRENT_ORDER_ID}=${order.code}&${this.FIELD_PAGE}=${this.currentPage}`;
          this.legisymDataSO = this.buildLegisymRequest(
            response.csosOrderId,
            order.code,
            response.csosLoginToken,
            fffCSOSConfig.actions.signOrder.name,
            redirectUrl
          );
          this.cdRef.detectChanges();
          this.callForm(this.SIGN_ORDER_FORM_ID);
        } else {
          this.showLegisymPopUp(
            'fffOrderHistory.legisymMessages.errorGetToken'
          );
        }
      });
  }

  private showLegisymPopUp(message: string): void {
    const modalRef = this.modalService.open(FFFLegisymMessagePopupComponent, {
      centered: true,
      size: 'md',
      backdrop: 'static',
      modalDialogClass: 'modal-container',
    });
    modalRef.componentInstance.message$ =
      this.translationService.translate(message);
  }

  showLegysymSignResponse(): void {
    this.showLegisymPopUp('fffOrderHistory.legisymMessages.errorSignOrder');
  }

  openToolTip(): void {
    this.showTooltip = true;
  }

  closeToolTip(): void {
    this.showTooltip = true;
  }

  isSameCurrentOrderId(orderId: string, signOrderResult: any): boolean {
    return (
      signOrderResult &&
      signOrderResult.some(
        (order: any) =>
          order.orderID == orderId &&
          'OPEN' === order.csosOrderStatus &&
          !order.csosOrderSignStatus
      )
    );
  }

  isSignedOrder(orderId: string, signOrderResult: any): boolean {
    return (
      signOrderResult &&
      signOrderResult.some(
        (order: any) =>
          order.orderID == orderId &&
          'COMPLETED' === order.csosOrderStatus &&
          order.csosOrderSignStatus
      )
    );
  }

  updatedSignStatus(currentOrderId: string): void {
    this.orderService
      .updateCsosOrder(this.userData.uid, currentOrderId)
      .subscribe(response => {
        this.signOrderResult$.next(response);
      });
  }

  print(csosOrderId: string, orderId: string, b2bUnitData: any): void {
    this.getLegisymToken(b2bUnitData.uid).subscribe(response => {
      const redirectUrl = `${fffCSOSConfig.actions.printOrder.redirectUrl}?${this.FIELD_PAGE}=${this.currentPage}`;

      this.legisymDataPR = this.buildLegisymRequest(
        csosOrderId,
        orderId,
        response,
        fffCSOSConfig.actions.printOrder.name,
        redirectUrl
      );
      this.cdRef.detectChanges();
      this.callForm(this.PRINT_ORDER_FORM_ID);
    });
  }

  receive(csosOrderId: string, orderId: string, b2bUnitData: any): void {
    this.getLegisymToken(b2bUnitData.uid).subscribe(response => {
      const redirectUrl = `${fffCSOSConfig.actions.receiveOrder.redirectUrl}?${this.FIELD_PAGE}=${this.currentPage}`;

      this.legisymDataRC = this.buildLegisymRequest(
        csosOrderId,
        orderId,
        response,
        fffCSOSConfig.actions.receiveOrder.name,
        redirectUrl
      );
      this.cdRef.detectChanges();
      this.callForm(this.RECEIVE_ORDER_FORM_ID);
    });
  }

  openFilterDrawer(): void {
    this.drawerService.setContent({
      title: 'Filter Orders',
      component: FffOrderHistoryFilterComponent as Component,
      animation: 'SideRTL',
      class: 'order_history',
    });
    this.drawerService.openDrawer();
  }

  getLegisymToken(b2bUnitId: string): Observable<any> {
    return this.fffB2bUnitService.certificateLoginToken(
      this.userData.uid,
      b2bUnitId
    );
  }

  signCsosOrder(orderId: string): Observable<any> {
    return this.orderService.getCsosOrderId(this.userData.uid, orderId);
  }

  buildLegisymRequest(
    csosOrderId: string,
    orderId: string,
    legisymTokenData: any,
    action: string,
    redirectUrl: string
  ): any {
    return {
      url: environment.legisymGetToken.url,
      method: fffCSOSConfig.getToken.method,
      serviceContract: legisymTokenData.csosServiceContractToken,
      loginToken: legisymTokenData.csosLoginToken,
      action: action,
      returnURL: this.occEndpoints.buildUrl(redirectUrl),
      xmlFile: '',
      csosOrderId: csosOrderId,
      orderId: orderId,
    };
  }

  callForm(formId: string): void {
    var myForm = <HTMLFormElement>document.getElementById(formId);
    myForm.submit();
  }

  ngOnDestroy() {
    this.unsubscribe$.unsubscribe();
  }

  async exportCSV() {
    this.openExportedDocumentSuccess();
    let r = await this.parseExportInfo();
    let options: ExportFileOptions = {
      fileName: 'orderList',
      extension: 'csv',
      type: 'multipart/form-data',
    };
    this.exportCSVService.download(r, ',', options);
  }

  openExportedDocumentSuccess() {
    this.modalService.open(FffExportedDocumentMessageComponent, {
      centered: true,
      backdrop: 'static',
    });
  }

  async parseExportInfo() {
    let orderList: any = await this.fffOrderService
      .getAllOrdersForCSV()
      .toPromise();

    let data = orderList.orders.map((orderItem: any) => {
      return {
        ...orderItem,
        orderDate: orderItem.placed.split('T')[0],
        order: orderItem.code,
      };
    });

    let fileNames: any[] = [];

    let fieldsForCSV = this.fields.filter(
      (elem: any) => elem.field != 'action'
    );

    fieldsForCSV.forEach((f: any) => {
      this.translationService.translate(f.title).subscribe(res => {
        fileNames.push(res);
      });
    });

    let exportFileEntries: string[][] = [];

    //Reserved for titles
    let fileTitles = fieldsForCSV.map((e: { field: any }) => {
      return e.field;
    });

    exportFileEntries[0] = fileNames;

    data.forEach((e: any, index: any) => {
      exportFileEntries[index + 1] = [];

      fileTitles.forEach((key: any) => {
        exportFileEntries[index + 1].push(e[key] ? e[key] : ' ');
      });
    });

    return exportFileEntries;
  }

  goToReportsPage() {
    this.router.navigateByUrl('my-account/reports');
  }

  onResendConfirmationSelectionChange(checked: boolean, order: any) {
    if (checked) {
      this.selectedOrders.push(order.code);
    } else {
      const index = this.selectedOrders.indexOf(order.code);
      if (index !== -1) {
        this.selectedOrders.splice(index, 1);
      }
    }
    this.cdRef.markForCheck();
  }
}
