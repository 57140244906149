import { Component } from '@angular/core';
import { FffCommunicationService } from '@app/fff-enterprise/fff-common-services/fff-communication.service';
import { BaseSiteService } from '@spartacus/core';
import { UserAccountFacade } from '@spartacus/user/account/root';
import { Observable } from 'rxjs';
import { switchMap, take } from 'rxjs/operators';
import { BASE_URL_KEYS } from '@config/content/constants';


@Component({
  selector: 'fff-checkout-header',
  templateUrl: './fff-checkout-header.component.html',
})
export class FffCheckoutHeaderComponent {

  userData$: Observable<any> = this.userAccountDetails.get();
  b2bUnitData$: Observable<any> = this.userData$.pipe(
    switchMap(user => {
      return this.communicationService.getCurrentAccount(user);
    })
  );
  isBiosupply: any;

  constructor(
    private userAccountDetails: UserAccountFacade,
    private communicationService: FffCommunicationService,
    public baseSiteService: BaseSiteService
  ) { }

  ngOnInit(){
    this.baseSiteService.getActive().pipe(take(1)).subscribe((site) => {
      this.isBiosupply = site == BASE_URL_KEYS.BIOSUPPLY;
    });
  }

  getShippingAddress(b2bUnitData: any): string {
    if (b2bUnitData.addresses) {
      const shippingAddress = b2bUnitData.addresses.filter((address: any) => address.shippingAddress);
      return shippingAddress.length > 0 ? shippingAddress[0].formattedAddress : '';
    }
    return '';
  }

}


