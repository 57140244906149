<div class="container">
  <div>
    <div class="d-flex">
      <h3 class="pr-4">{{ 'fffSavedCarts.title' | cxTranslate }}</h3>
      <!-- <button class="btn btn-secondary btn-xs">
        {{ 'fffSavedCarts.filter' | cxTranslate }}
        <cx-icon class="cx-icon icon-sm" [type]="outlinedIconTypes.ICON_FILTER">
        </cx-icon>
      </button> -->
    </div>

    <hr class="sep-table-title" />
  </div>

  <div *ngIf="dataList$ | async as dataList; else loadingTemplate">
    <ng-container *ngIf="dataList.length === 0; else dataTable">
      <div class="d-flex flex-wrap">
        <span class="col-12 text-center empty-cart-message">
          {{ 'fffSavedCarts.emptyCartMessage' | cxTranslate }}
        </span>
        <button class="btn btn-primary m-auto" (click)="goToHomePage()">
          {{ 'fffSavedCarts.goToHomePage' | cxTranslate }}
        </button>
      </div>
    </ng-container>

    <ng-template #dataTable>
      <fff-table
        [hasPaginator]="false"
        [data]="getTableData(dataList)"
        [fields]="fields"
        [hasTemplate]="true"
        [mobileSize]="mobileSize"
        [itemTemplate]="savedCartItem"
        [columnsGrid]="columnsGrid"
        [customClass]="'user-price-list'"
      >
      </fff-table>
    </ng-template>
  </div>
</div>

<ng-template #savedCartItem let-item="item">
  <div
    class="saved-cart-item td"
    [style.grid-template-columns]="columnsGrid.desktop"
  >
    <div class="px-0 col-6 col-lg-12">
      <div class="d-flex flex-wrap">
        <div class="col-12 px-0 d-block d-lg-none pb-2 pt-3">
          {{ 'fffSavedCarts.name' | cxTranslate }}
        </div>
        <div class="d-inline-flex">
          <span class="action-label" (click)="goToDetailCart(item.code)">{{
            item.name
          }}</span>
          <cx-icon
            class="cx-icon icon-sm px-2"
            [type]="outlinedIconTypes.ICON_CHEVRON_COMPACT_RIGHT"
          ></cx-icon>
        </div>
      </div>
    </div>

    <div class="px-0 col-6 col-lg-12">
      <div class="col-12 px-0 d-block d-lg-none pb-2 pt-3">
        {{ 'fffSavedCarts.description' | cxTranslate }}
      </div>
      <div>{{ item.description }}</div>
    </div>

    <div class="px-0 col-6 col-lg-12">
      <div class="col-12 px-0 d-block d-lg-none pb-2 pt-3">
        {{ 'fffSavedCarts.dateSaved' | cxTranslate }}
      </div>
      <div>{{ item.saveTime | date : 'MM/dd/yyyy' }}</div>
    </div>

    <div class="px-0 col-6 col-lg-12">
      <div class="col-12 px-0 d-block d-lg-none pb-2 pt-3">
        {{ 'fffSavedCarts.numberItems' | cxTranslate }}
      </div>
      <div>
        {{ item.entries.length }} {{ 'fffSavedCarts.items' | cxTranslate }}
      </div>
    </div>

    <div class="col-12 px-0 d-none d-lg-flex flex-wrap">
      <div
        class="action-label"
        *ngIf="!loading"
        (click)="createOrder(item.code)"
      >
        {{ 'fffSavedCarts.createOrder' | cxTranslate }}
      </div>
      <div class="pl-3 pr-2" *ngIf="loading">
        {{ 'fffSavedCarts.createOrder' | cxTranslate }}
      </div>
      <div
        class="px-2 action-label"
        (click)="goToEditSavedCartDetails(item.code)"
      >
        {{ 'fffSavedCarts.editCart' | cxTranslate }}
      </div>
      <div
        class="px-2 action-label"
        (click)="deleteCart(item.code, item?.name)"
      >
        {{ 'fffSavedCarts.delete' | cxTranslate }}
      </div>
    </div>

    <div class="col-12 d-flex d-lg-none flex-wrap py-3">
      <div class="col-12 px-2">
        <button
          type="button"
          class="btn btn-tertiary btn-xs w-100 mb-2"
          (click)="createOrder(item.code)"
        >
          {{ 'fffSavedCarts.createOrder' | cxTranslate }}
        </button>
      </div>
      <div class="col-12 px-2">
        <button
          type="button"
          class="btn btn-tertiary btn-xs w-100 mb-2"
          (click)="goToEditSavedCartDetails(item.code)"
        >
          {{ 'fffSavedCarts.editCart' | cxTranslate }}
        </button>
      </div>
      <div class="col-12 px-2 d-flex">
        <button
          type="button"
          class="btn btn-tertiary btn-xs w-100 m-auto"
          (click)="deleteCart(item.code, item?.name)"
        >
          {{ 'fffSavedCarts.deleteCart' | cxTranslate }}
        </button>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #loadingTemplate>
  <fff-table-loader [fields]="'field'.split('')"></fff-table-loader>
</ng-template>
