import { HttpUrlEncodingCodec } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FffGtmEventService } from '@app/spartacus/features/tracking/gmt/event';

import { Store } from '@ngrx/store';
import { FacetValue, ProductsState, PRODUCT_FEATURE, StateWithProduct } from '@spartacus/core';
import { DeviceDetectorService } from 'ngx-device-detector';
import { BehaviorSubject, combineLatest, Observable, Subscription } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { FffFacet } from 'src/app/models/fff-facet.model';
import { OUTLINED_ICON_TYPE } from 'src/app/models/fff-outline-icons.model';
import { FffSearchBreadcrumb } from 'src/app/models/fff-product.model';
import { FffProductListComponentService } from '../../fff-product-list-component.service';

@Component({
  selector: 'fff-facet-filter-drawer-body',
  templateUrl: './fff-facet-filter-drawer-body.component.html',
})
export class FffFacetFilterDrawerBodyComponent implements OnInit, OnDestroy {

  private sub: Subscription = new Subscription();
  activeFacetValueCode: string = '';
  initialQuery: string = '';
  isCollapsed = true;
  protected queryCodec: HttpUrlEncodingCodec;
  searchResult$!: Observable<ProductsState>;
  filtersSelected!: FffSearchBreadcrumb[];
  visibleFacets$!: Observable<FffFacet[]>;
  productState$: Observable<ProductsState>;
  facets: FffFacet[] = [];
  isOpenFilter$ = this.productListComponentService.isFilterOpen$;

  outlinedIconTypes = OUTLINED_ICON_TYPE;

  constructor(
    private activatedRoute: ActivatedRoute,
    private productListComponentService: FffProductListComponentService,
    private deviceService: DeviceDetectorService,
    private store: Store<StateWithProduct>,
    protected fffGtmEventService: FffGtmEventService
  ) {
    this.queryCodec = new HttpUrlEncodingCodec();
    this.productState$ = this.store.select(PRODUCT_FEATURE);
  }

  ngOnInit(): void {
    this.sub = this.activatedRoute.params.subscribe(params => {
      this.activeFacetValueCode = params.categoryCode || params.brandCode || 'Refurbished';
    });

    this.searchResult$ = this.productState$.pipe(
      tap((state) => {
        let searchResult = state.search?.results;
        // set initial query (for clear all functionality) - breadcrumbs first value is category or brand
        if (searchResult?.breadcrumbs) {
          this.initialQuery = this.getInitialQuery(searchResult.breadcrumbs[0]);
          this.filtersSelected = this.getAppliedFilters(searchResult.breadcrumbs);
        }
        // updating expanded for collapsible menu
        searchResult?.facets?.map(facet => ({
          ...facet,
          expanded: false
        }));
      })
    );

    this.visibleFacets$ = combineLatest([
      this.searchResult$,
      new BehaviorSubject(false)
    ]).pipe(
      map(([searchResult, hideCategoryFacet]) => {
        const facets = searchResult.search.results.facets || [];
        return facets.filter(facet => {
          if (hideCategoryFacet && facet.category) {
            return false;
          }
          return facet.visible && facet.name !== this.activeFacetValueCode && facet.name !== 'allCategories';
        });
      })
    );

    this.visibleFacets$.subscribe(data => {
      this.facets = data.map(dataInfo => ({ ...dataInfo, expanded: true, showMore: false, moreFiveItems: dataInfo.values && dataInfo.values?.length > 5 }));
      this.facets = this.facets.map((facet, i) => ({ ...facet, expanded: i > 2 }));
    });
  }

  getAppliedFilters(breadcrumbs: FffSearchBreadcrumb[]) {
    return breadcrumbs.slice(1);
  }

  getInitialQuery(breadcrumb: FffSearchBreadcrumb): string {
    if (breadcrumb?.truncateQuery?.query?.value && breadcrumb?.removeQuery?.query) {
      return (breadcrumb.truncateQuery) ?
        breadcrumb.truncateQuery.query.value :
        breadcrumb.removeQuery.query.value + ':' + breadcrumb.facetCode + ':' + breadcrumb.facetValueCode;
    } else {
      return '';
    }
  }

  toggleValue(query: string | undefined): void {
    this.fffGtmEventService.registerFacetChangedEvent();
    this.productListComponentService.setQuery(
      this.queryCodec.decodeValue(query!)
    );
  }

  clearFacets() {
    this.toggleValue(this.initialQuery);
    return false;
  }

  ngOnDestroy() {
    if (this.sub) {
      this.sub.unsubscribe();
    }
  }

  toggleFilter(filterValue: boolean) {
    this.productListComponentService.toggleFilterDisplayInMobile(filterValue);
  }

  isExpanded(value: boolean | undefined): boolean {
    if (value)
      return value;
    return false;
  }

  getItemsSelectedCount(facetName: string | undefined, facets: FffSearchBreadcrumb[]): number {
    if (facetName) {
      return facets.filter(f => f.facetName === facetName).length;
    } else {
      return 0;
    }
  }

  getFacetValues(values: FacetValue[], showMore: boolean, moreFiveItems: boolean): FacetValue[] {
    if (moreFiveItems) {
      return (showMore) ? values : values.slice(0, 5);
    } else {
      return values;
    }
  }

}




