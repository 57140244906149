import { AbstractControl, UntypedFormControl } from '@angular/forms';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';

export default class FffNewReportValidators {
  static emailValidator(
    control: UntypedFormControl
  ): { [key: string]: boolean } | null {
    const emails = control.value ? control.value.split(',') : [];

    for (let email of emails) {
      if (!/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email.trim())) {
        return { invalidEmail: true };
      }
    }
    return null;
  }

  static dateValidator(
    control: AbstractControl
  ): { [key: string]: boolean } | null {
    const fromControl = control.get('fromDate');
    const toControl = control.get('toDate');

    const fromDate = new NgbDate(
      fromControl?.value?.year,
      fromControl?.value?.month,
      fromControl?.value?.day
    );

    const toDate = new NgbDate(
      toControl?.value?.year,
      toControl?.value?.month,
      toControl?.value?.day
    );

    if (fromDate && toDate) {
      if (fromDate.after(toDate)) {
        fromControl?.setErrors({ fffInvalidDateRange: true });
        toControl?.setErrors({ fffInvalidDateRange: true });
        return { fffInvalidDateRange: true };
      }
    }

    fromControl?.setErrors(null);
    toControl?.setErrors(null);
    return null;
  }
}
