import { DecimalPipe } from '@angular/common';
import {
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { BASE_URL_KEYS } from '@app/fff-config/content/constants';
import { FffCommunicationService } from '@app/fff-enterprise/fff-common-services/fff-communication.service';
import { ApplicationProperties } from '@app/fff-enterprise/fff-util/fff-models/application-properties.model';
import { OUTLINED_ICON_TYPE } from '@app/models/fff-outline-icons.model';
import { FffProduct } from '@app/models/fff-product.model';
import { FffProfile } from '@app/models/fff-profile.model';
import { FffTagItem } from '@app/shared/components/form/fff-form-controls/fff-tags/fff-tags.model';
import { FffUserAccountService } from '@app/shared/services/fff-user-account.service';
import { FffExportedDocumentMessageComponent } from '@enterprise/fff-user-price-list/fff-exported-document-message/fff-exported-document-message';
import {
  NgbDateStruct,
  NgbModal,
  NgbModalRef,
} from '@ng-bootstrap/ng-bootstrap';
import {
  AuthService,
  BaseSiteService,
  TranslationService,
} from '@spartacus/core';
import { ExportCsvFileService, ExportFileOptions } from '@spartacus/storefront';
import { jsPDF } from 'jspdf';
import autoTable from 'jspdf-autotable';
import { cloneDeep, xorWith } from 'lodash';
import { Observable, Subject, Subscription, of } from 'rxjs';
import { finalize, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import {
  NewReport,
  NewReportFormData,
  ReportFormatTypes,
  ReportFrequencyTypes,
  ReportRangeTypes,
  ReportStatusTypes,
  ReportTypes,
} from '../fff-new-reports.model';
import { FffNewReportsService } from '../fff-new-reports.service';
import FffNewReportValidators from '../fff-new-reports.utils';

@Component({
  selector: 'fff-new-report-filters',
  templateUrl: './fff-new-report-filters.component.html',
})
export class FffNewReportFiltersComponent implements OnInit, OnDestroy {
  @ViewChild('successModal') successModalRef!: NgbModalRef;
  @ViewChild('warningModal') warningModalRef!: NgbModalRef;

  outlinedIconTypes = OUTLINED_ICON_TYPE;
  reportType: string = '';
  tomorrow: NgbDateStruct = this.getTodayDate();
  reportData: any;

  form = new UntypedFormGroup({
    reportID: new UntypedFormControl(''),
    reportType: new UntypedFormControl(ReportTypes.ALLOCATIONS, [
      Validators.required,
    ]),
    reportFrequency: new UntypedFormControl(ReportFrequencyTypes.MONTHLY, [
      Validators.required,
    ]),
    accounts: new UntypedFormControl('', [Validators.required]),
  });

  baseSite: string = '';
  loading: boolean = false;
  submitting: boolean = false;
  allowAccountsMultiSelect = true;
  allReportTypes = ReportTypes;
  reportFormatTypes = ReportFormatTypes;
  reportFrequencyTypes = ReportFrequencyTypes;
  editable: boolean = false;
  successMessageI18nKey: string = 'fffReports.submitted';

  selectedAccountTags: FffTagItem[] = [];
  selectedProductGroupTags: FffTagItem[] = [];
  selectedProductsTags: FffTagItem[] = [];
  profile$: Observable<FffProfile | undefined> = of(undefined);
  productList!: FffProduct[];
  productGroupList!: any[];
  isAllocationsEnabled!: boolean;
  isInvoiceHistoryEnabled!: boolean;
  isOrderHistoryEnabled!: boolean;
  isPricesEnabled!: boolean;
  isTypesAvailable!: boolean;
  isFourDecimalAccount!: boolean;
  isFormLoaded!: boolean;

  statuses: any[] = [];
  reportTypes = this.fffNewReportsService.reportTypes;
  reportRangeOptions = this.fffNewReportsService.monthlyRangeOptions;
  readonly frequencyOptions = this.fffNewReportsService.frequencyOptions;
  readonly reportFormatOptions = this.fffNewReportsService.reportFormatOptions;
  readonly monthlyRangeOptions = this.fffNewReportsService.monthlyRangeOptions;
  readonly weeklyRangeOptions = this.fffNewReportsService.weeklyRangeOptions;

  private _reportFormatSub!: Subscription;
  private _reportFrequencySub!: Subscription;
  private unsubscribe$: Subject<void> = new Subject<void>();

  fields: any[] = [
    { title: 'fffPriceList.productType', field: 'category' },
    {
      title: 'fffPriceList.productAndManufacturer',
      field: 'name,manufacturer',
    },
    { title: 'fffPriceList.skuAndNdc', field: 'productCode,ndc' },
    { title: 'fffPriceList.productSize', field: 'size' },
    { title: 'fffPriceList.accountNumber', field: 'accountNumber' },
    {
      title: 'fffPriceList.price',
      field: 'priceColumn,altPriceColumn,basicDiscountPrice',
    },
  ];
  csvFields: any[] = [
    { title: 'fffPriceList.productType', field: 'category' },
    { title: 'fffPriceList.productName', field: 'name' },
    { title: 'fffPriceList.productManufacturer', field: 'manufacturer' },
    { title: 'fffPriceList.sku', field: 'productCode' },
    { title: 'fffPriceList.ndc', field: 'ndc' },
    { title: 'fffPriceList.productSize', field: 'size' },
    { title: 'fffPriceList.accountNumber', field: 'accountNumber' },
    { title: 'fffPriceList.price', field: 'priceColumn' },
    { title: 'fffPriceList.altPrice', field: 'altPriceColumn' },
    { title: 'fffPriceList.netPrice', field: 'basicDiscountPrice' },
  ];

  get isEmailFormat(): boolean {
    if (!this.form.contains('reportFormat')) {
      return true;
    }
    return ![ReportFormatTypes.PDF, ReportFormatTypes.CSV].includes(
      this.form.controls?.['reportFormat'].value
    );
  }

  constructor(
    private modalService: NgbModal,
    private fffNewReportsService: FffNewReportsService,
    private communicationService: FffCommunicationService,
    private baseSiteService: BaseSiteService,
    private cd: ChangeDetectorRef,
    private auth: AuthService,
    private fffUserAccountService: FffUserAccountService,
    private exportCSVService: ExportCsvFileService,
    private decimalPipe: DecimalPipe,
    private translationService: TranslationService
  ) {}

  ngOnInit() {
    this.init();
    this.communicationService
      .getApplicationProperties()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((properties: ApplicationProperties) => {
        if (properties?.fffPricesFourDecimalAccounts) {
          this.fffUserAccountService
            .getProfile()
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe((profile: any) => {
              this.isFourDecimalAccount =
                properties.fffPricesFourDecimalAccounts.indexOf(
                  profile?.selected?.uid
                ) >= 0;
            });
        }
      });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
    this._reportFormatSub?.unsubscribe();
    this._reportFrequencySub?.unsubscribe();
  }

  init() {
    this.toggleLoading(true);
    this.baseSiteService
      .getActive()
      .pipe(
        take(1),
        tap(site => {
          this.baseSite = site;
          this.fffNewReportsService
            .getApplicationProperties()
            .subscribe(data => {
              this.isAllocationsEnabled =
                data &&
                data.ecommerceReportingAllocationsSchedulesEnabled === 'true';
              this.isInvoiceHistoryEnabled =
                data &&
                data.ecommerceReportingInvoicehistorySchedulesEnabled ===
                  'true';
              this.isOrderHistoryEnabled =
                data &&
                data.ecommerceReportingOrderhistorySchedulesEnabled === 'true';
              this.isPricesEnabled =
                data &&
                data.ecommerceReportingPricesSchedulesEnabled === 'true';
              this.initSiteSpecificForm();
              this.initEvents();
            });
        }),
        finalize(() => {
          this.toggleLoading(false);
        })
      )
      .subscribe();

    this.fffNewReportsService.editReport
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(report => {
        document.getElementById('new-report-form')?.scrollIntoView({
          behavior: 'smooth',
        });
        this.editable = true;
        this.reportData = cloneDeep(report);
        this.loadFormByReportType(report.reportType);
        this.resetPastReportDate();
      });

    this.profile$ = this.auth.isUserLoggedIn().pipe(
      switchMap(isUserLoggedIn => {
        if (isUserLoggedIn) {
          return this.fffUserAccountService.getProfile();
        } else {
          this.fffUserAccountService.removeProfile();
          return of(undefined);
        }
      })
    );

    this.fffNewReportsService.getProductsForReport().subscribe(res => {
      if (res.products && res.products.length > 0) {
        this.productList = res.products;
      }
    });

    this.fffNewReportsService.getProductGroupsForReport().subscribe(res => {
      if (res.categories && res.categories.length > 0) {
        this.productGroupList = res.categories;
      }
    });
  }

  loadEnabledReportTypes() {
    if (!this.isAllocationsEnabled) {
      this.reportTypes = this.reportTypes.filter(
        item => item.id !== ReportTypes.ALLOCATIONS
      );
    }
    if (!this.isInvoiceHistoryEnabled) {
      this.reportTypes = this.reportTypes.filter(
        item => item.id !== ReportTypes.INVOICE_HISTORY
      );
    }
    if (!this.isOrderHistoryEnabled) {
      this.reportTypes = this.reportTypes.filter(
        item => item.id !== ReportTypes.ORDER_HISTORY
      );
    }
    if (!this.isPricesEnabled) {
      this.reportTypes = this.reportTypes.filter(
        item => item.id !== ReportTypes.PRICES
      );
    }
  }

  initSiteSpecificForm() {
    const isMfv = this.baseSite === BASE_URL_KEYS.MY_FLU_VACCINE;
    if (isMfv) {
      this.reportTypes = this.fffNewReportsService.reportTypes.slice(1);
    }
    this.loadEnabledReportTypes();
    if (this.reportTypes && this.reportTypes.length > 0) {
      this.isTypesAvailable = true;
      const initialReportType = isMfv
        ? this.reportTypes.some(type => type.id === ReportTypes.INVOICE_HISTORY)
          ? ReportTypes.INVOICE_HISTORY
          : this.reportTypes[0].id
        : this.reportTypes.some(type => type.id === ReportTypes.ALLOCATIONS)
        ? ReportTypes.ALLOCATIONS
        : this.reportTypes[0].id;
      this.form.patchValue({
        reportType: initialReportType,
        reportFrequency: ReportFrequencyTypes.MONTHLY,
      });
      this.loadFormByReportType(initialReportType);
    } else {
      this.isTypesAvailable = false;
    }
  }

  toggleLoading(loading: boolean = false) {
    this.loading = loading;
    this.cd.markForCheck();
  }

  toggleSubmitting(submitting: boolean = false) {
    this.submitting = submitting;
    this.cd.markForCheck();
  }

  initEvents() {
    this.loadReportFrequencyEvents();

    this.fffNewReportsService
      .getSelectedAccounts()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(selectedAccounts => {
        this.selectedAccountTags = selectedAccounts;
        this.form.patchValue({
          accounts: this.selectedAccountTags.map(a => a.id),
        });
        this.cd.markForCheck();
      });

    this.fffNewReportsService
      .getSelectedProducts()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(selectedProducts => {
        this.selectedProductsTags = selectedProducts;
        if (this.form.contains('products')) {
          this.form.patchValue({
            products: this.selectedProductsTags.map(a => a.id),
          });
        }
        this.cd.markForCheck();
      });

    this.fffNewReportsService
      .getSelectedProductGroups()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(selectedProductGroups => {
        this.selectedProductGroupTags = selectedProductGroups;
        if (this.form.contains('productGroups')) {
          this.form.patchValue({
            productGroups: this.selectedProductGroupTags.map(a => a.id),
          });
        }
        this.cd.markForCheck();
      });
  }

  loadReportFrequencyEvents() {
    this._reportFrequencySub?.unsubscribe();
    this._reportFrequencySub = this.form.controls?.[
      'reportFrequency'
    ]?.valueChanges.subscribe(val => {
      this.handleReportFrequencyChange();
    });
  }

  loadReportFormatEvents() {
    this._reportFormatSub?.unsubscribe();
    this._reportFormatSub = this.form.controls?.[
      'reportFormat'
    ]?.valueChanges.subscribe(val => {
      this.handleReportFormatChange();
    });
  }

  private mapDateToNgbDateStruct(time: number): NgbDateStruct {
    const date = new Date(time);
    return {
      year: date.getFullYear(),
      month: date.getMonth() + 1,
      day: date.getDate(),
    };
  }

  getTodayDate(): NgbDateStruct {
    return this.mapDateToNgbDateStruct(Date.now() + 24 * 60 * 60 * 1000);
  }

  clearDynamicFormFields(oldReportType: string, newReportType: string) {
    if (!oldReportType) {
      return;
    }

    const oldFields =
      this.fffNewReportsService.reportFieldsMapping[oldReportType] || [];
    const newFields =
      this.fffNewReportsService.reportFieldsMapping[newReportType] || [];

    const fields = xorWith(newFields, oldFields);
    fields.forEach(field => {
      if (this.form.contains(field)) {
        this.form.removeControl(field);
      }
    });
  }

  loadAllocationsForm() {
    this.form.addControl('reportRange', new UntypedFormControl('', []));
    if (this.editable) {
      this.form.addControl(
        'reportDate',
        new UntypedFormControl('', [Validators.required])
      );
      this.form.removeControl('firstReportDate');
    } else {
      this.form.addControl(
        'firstReportDate',
        new UntypedFormControl('', [Validators.required])
      );
    }
    this.form.addControl(
      'products',
      new UntypedFormControl('', [Validators.required])
    );
    this.form.addControl(
      'emailIds',
      new UntypedFormControl('', [
        Validators.required,
        FffNewReportValidators.emailValidator,
      ])
    );
  }

  loadOrderHistoryForm() {
    this.form.addControl('reportRange', new UntypedFormControl('', []));
    if (this.editable) {
      this.form.addControl(
        'reportDate',
        new UntypedFormControl('', [Validators.required])
      );
      this.form.removeControl('firstReportDate');
    } else {
      this.form.addControl(
        'firstReportDate',
        new UntypedFormControl('', [Validators.required])
      );
    }
    this.form.addControl(
      'fromDate',
      new UntypedFormControl('', [Validators.required])
    );
    this.form.addControl(
      'toDate',
      new UntypedFormControl('', [Validators.required])
    );
    this.form.addControl(
      'status',
      new UntypedFormControl([], [Validators.required])
    );
    this.form.addControl(
      'emailIds',
      new UntypedFormControl('', [
        Validators.required,
        FffNewReportValidators.emailValidator,
      ])
    );
  }

  loadInvoiceHistoryForm() {
    this.form.addControl('reportRange', new UntypedFormControl('', []));
    if (this.editable) {
      this.form.addControl(
        'reportDate',
        new UntypedFormControl('', [Validators.required])
      );
      this.form.removeControl('firstReportDate');
    } else {
      this.form.addControl(
        'firstReportDate',
        new UntypedFormControl('', [Validators.required])
      );
    }
    this.form.addControl(
      'fromDate',
      new UntypedFormControl('', [Validators.required])
    );
    this.form.addControl(
      'toDate',
      new UntypedFormControl('', [Validators.required])
    );
    this.form.addControl(
      'status',
      new UntypedFormControl([], [Validators.required])
    );
    this.form.addControl(
      'emailIds',
      new UntypedFormControl('', [
        Validators.required,
        FffNewReportValidators.emailValidator,
      ])
    );
  }

  loadPricesForm() {
    this.form.addControl(
      'reportFormat',
      new UntypedFormControl({ value: '', disabled: this.editable }, [
        Validators.required,
      ])
    );
    this.form.addControl('reportRange', new UntypedFormControl('', []));
    if (this.editable) {
      this.form.addControl(
        'reportDate',
        new UntypedFormControl('', [Validators.required])
      );
      this.form.removeControl('firstReportDate');
    } else {
      this.form.addControl(
        'firstReportDate',
        new UntypedFormControl('', [Validators.required])
      );
    }
    this.form.addControl(
      'productGroups',
      new UntypedFormControl('', [Validators.required])
    );
    this.form.addControl(
      'emailIds',
      new UntypedFormControl('', [
        Validators.required,
        FffNewReportValidators.emailValidator,
      ])
    );
  }

  handleReportTypeChange(newReportType: string) {
    if (!newReportType) {
      return;
    }

    this.loadFormByReportType(newReportType);
  }

  cancelEdit() {
    this.resetForm();
    this.fffNewReportsService.refreshResults();
    document.getElementById('recurring-reports-list')?.scrollIntoView({
      behavior: 'smooth',
    });
  }

  loadFormByReportType(newReportType: string) {
    if (!newReportType) {
      return;
    }

    this.isFormLoaded = true;
    this.form.controls?.['reportType']?.[
      this.editable ? 'disable' : 'enable'
    ]();

    if (!this.form.contains('reportFrequency')) {
      this.form.addControl(
        'reportFrequency',
        new UntypedFormControl(ReportFrequencyTypes.MONTHLY, [
          Validators.required,
        ])
      );
    }

    this.form.controls?.['reportFrequency']?.[
      this.editable ? 'disable' : 'enable'
    ]();

    this.clearDynamicFormFields(this.reportType, newReportType);

    this.reportType = newReportType;
    this.statuses = this.fffNewReportsService.getAvailableStatusesByType(
      this.reportType
    );

    if (newReportType === ReportTypes.ALLOCATIONS) {
      this.loadAllocationsForm();
    }

    if (newReportType === ReportTypes.ORDER_HISTORY) {
      this.loadOrderHistoryForm();
    }

    if (newReportType === ReportTypes.INVOICE_HISTORY) {
      this.loadInvoiceHistoryForm();
    }

    if (newReportType === ReportTypes.PRICES) {
      this.loadPricesForm();
      this.loadReportFormatEvents();
    }

    this.loadReportFrequencyEvents();

    this.handleDateRangeValidators();

    // Set default values
    this.handleReportFormatChange();
    this.handleReportFrequencyChange();
    this.handleSelectAccountsView();

    // Reset dynamic fields data
    this.resetAccountsField();
    this.resetStatusField();
    this.resetProductsField();
    this.resetProductGroupsField();

    if (this.editable) {
      this.loadReportFormData();
    }

    this.form.markAsPristine();
    this.form.markAsUntouched();
    this.form.updateValueAndValidity();
    this.cd.markForCheck();
  }

  isFieldAvailable(field: string): boolean {
    return !!this.fffNewReportsService.reportFieldsMapping[
      this.reportType
    ].includes(field);
  }

  isWeeklyOrMonthly(): boolean {
    return [ReportFrequencyTypes.MONTHLY, ReportFrequencyTypes.WEEKLY].includes(
      this.form.get('reportFrequency')?.value
    );
  }

  handleDateRangeValidators() {
    if (this.form.contains('fromDate')) {
      this.form.setValidators(FffNewReportValidators.dateValidator);
    } else {
      this.form.removeValidators(FffNewReportValidators.dateValidator);
    }
  }

  handleReportFrequencyChange() {
    this.handleReportRangeView();
    this.handleDateRangeView();
    this.handleFirstReportDateView();
  }

  handleReportFormatChange() {
    this.handleFrequencyView();
    this.handleReportRangeView();
    this.handleEmailRecipientsView();
    this.handleFirstReportDateView();
  }

  handleSelectAccountsView(): void {
    this.allowAccountsMultiSelect = (
      [
        ReportTypes.ORDER_HISTORY,
        ReportTypes.INVOICE_HISTORY,
        ReportTypes.ALLOCATIONS,
      ] as string[]
    ).includes(this.reportType);
    this.cd.markForCheck();
  }

  handleReportRangeView() {
    const field = 'reportRange';

    if (!this.isFieldAvailable(field)) {
      return;
    }

    const isFieldVisible = this.isEmailFormat && this.isWeeklyOrMonthly();
    const isFieldExists = this.form.contains(field);

    if (isFieldVisible && !isFieldExists) {
      this.form.addControl(
        field,
        new UntypedFormControl('', [Validators.required])
      );
    }

    if (!isFieldVisible && isFieldExists) {
      this.form.removeControl(field);
    }

    let control = this.form.controls?.[field];
    let frequency = this.form.controls?.['reportFrequency']?.value;

    if (frequency === ReportFrequencyTypes.MONTHLY) {
      this.reportRangeOptions = this.fffNewReportsService.monthlyRangeOptions;
      control?.patchValue(ReportRangeTypes.PAST_30_DAYS);
    }

    if (frequency === ReportFrequencyTypes.WEEKLY) {
      this.reportRangeOptions = this.fffNewReportsService.weeklyRangeOptions;
      control?.patchValue(ReportRangeTypes.PAST_7_DAYS);
    }

    this.form.updateValueAndValidity();
    this.cd.markForCheck();
  }

  handleDateRangeView() {
    if (!this.isFieldAvailable('fromDate')) {
      return;
    }

    this.form.removeControl('fromDate');
    this.form.removeControl('toDate');
    if (
      this.form.getRawValue()?.reportFrequency === ReportFrequencyTypes.ONCE
    ) {
      this.form.addControl(
        'fromDate',
        new UntypedFormControl('', [Validators.required])
      );
      this.form.addControl(
        'toDate',
        new UntypedFormControl('', [Validators.required])
      );
    }

    this.form.updateValueAndValidity();
    this.cd.markForCheck();
  }

  handleFirstReportDateView() {
    const field = this.editable ? 'reportDate' : 'firstReportDate';

    if (!this.isFieldAvailable(field)) {
      return;
    }

    const isFieldVisible = this.isWeeklyOrMonthly() && this.isEmailFormat;
    const isFieldExists = this.form.contains(field);

    if (isFieldVisible && !isFieldExists) {
      this.form.addControl(
        field,
        new UntypedFormControl('', [Validators.required])
      );
    }

    if (!isFieldVisible && isFieldExists) {
      this.form.removeControl(field);
    }

    this.form.updateValueAndValidity();
    this.cd.markForCheck();
  }

  handleFrequencyView(): void {
    if (!this.form?.contains('reportFormat')) {
      return;
    }

    const isReportFrequencyExists = this.form.contains('reportFrequency');

    if (this.isEmailFormat && !isReportFrequencyExists) {
      this.form.addControl(
        'reportFrequency',
        new UntypedFormControl(ReportFrequencyTypes.MONTHLY, [
          Validators.required,
        ])
      );
    }

    if (!this.isEmailFormat && isReportFrequencyExists) {
      this.form.removeControl('reportFrequency');
    }

    this.form.updateValueAndValidity();
    this.cd.markForCheck();
  }

  handleEmailRecipientsView(): void {
    const field = 'emailIds';

    if (!this.isFieldAvailable(field)) {
      return;
    }

    if (!this.form?.contains('reportFormat')) {
      return;
    }

    if (this.isEmailFormat) {
      this.form.controls[field].addValidators([Validators.required]);
    } else {
      this.form.controls[field].clearValidators();
    }

    this.cd.markForCheck();
  }

  onRemoveAccount(item: FffTagItem) {
    this.selectedAccountTags = this.selectedAccountTags.filter(
      account => account.id !== item.id
    );
    this.fffNewReportsService.setSelectedAccounts(this.selectedAccountTags);
    this.form.controls['accounts'].markAsTouched();
  }

  onRemoveProductGroup(item: FffTagItem) {
    this.selectedProductGroupTags = this.selectedProductGroupTags.filter(
      productGroup => productGroup.code !== item.id
    );
    this.fffNewReportsService.setSelectedProductGroups(
      this.selectedProductGroupTags
    );
    this.form.controls['productGroups'].markAsTouched();
  }

  onRemoveProduct(item: FffTagItem) {
    this.selectedProductsTags = this.selectedProductsTags.filter(
      product => product.sku !== item.id
    );
    this.fffNewReportsService.setSelectedProducts(this.selectedProductsTags);
    this.form.controls['products'].markAsTouched();
  }

  updateSelectedAccounts(accounts: string[]) {
    this.selectedAccountTags = accounts.map(acc => ({
      id: acc,
      displayValue: acc,
    }));
  }

  updateSelectedProductGroups(groups: any[]) {
    this.selectedProductsTags = groups.map((group: any) => ({
      id: group?.code,
      displayValue: group?.name,
    }));
  }

  updateSelectedProducts(products: any[]) {
    this.selectedProductsTags = products.map((product: any) => ({
      id: product?.code,
      displayValue: product?.name,
    }));
  }

  openSuccessModal(message: string) {
    if (!this.successModalRef) {
      return;
    }

    this.successMessageI18nKey = message;
    this.cd.markForCheck();

    this.modalService.open(this.successModalRef, {
      centered: true,
      animation: true,
      backdrop: 'static',
      windowClass: 'fff-modal fff-modal-alert',
    });
  }

  openWarningModal() {
    if (!this.warningModalRef) {
      return;
    }

    this.modalService.open(this.warningModalRef, {
      centered: true,
      animation: true,
      backdrop: 'static',
      windowClass: 'fff-modal fff-modal-alert',
    });
  }

  resetAccountsField() {
    this.form.controls['accounts'].reset();
    this.selectedAccountTags = [];
    this.fffNewReportsService.setSelectedAccounts([]);
  }
  resetPastReportDate() {
    if (this.form.contains('reportDate')) {
      const reportDateControl = this.form.get('reportDate');

      if (reportDateControl && reportDateControl.value) {
        const { day, month, year } = reportDateControl.value;

        const reportDateValue = new Date(year, month - 1, day); // month is 0-based in Date constructor

        const currentDate = new Date();

        if (
          !isNaN(reportDateValue.getTime()) &&
          reportDateValue < currentDate
        ) {
          reportDateControl.reset();
        }
      }
    }
  }

  resetStatusField() {
    if (this.form.contains('status')) {
      this.form.controls['status'].reset();
    }
  }

  resetProductsField() {
    if (this.form.contains('products')) {
      this.form.controls['products'].reset();
    }
    this.selectedProductsTags = [];
    this.fffNewReportsService.setSelectedProducts([]);
  }

  resetProductGroupsField() {
    if (this.form.contains('productGroups')) {
      this.form.controls['productGroups'].reset();
    }
    this.selectedProductGroupTags = [];
    this.fffNewReportsService.setSelectedProductGroups([]);
  }

  resetForm() {
    this.form.reset();
    this.selectedAccountTags = [];
    this.selectedProductGroupTags = [];
    this.selectedProductsTags = [];
    this.editable = false;
    this.initSiteSpecificForm();
    this.cd.markForCheck();
  }

  convertToFormData(data: NewReport): NewReportFormData {
    let {
      reportType,
      reportID,
      reportFormat,
      reportFrequency,
      reportRange,
      accounts,
      products,
      emailIds,
      status,
      productGroups,
      firstReportDate,
      reportDate,
      fromDate,
      toDate,
    } = data;

    const selectedStatuses: string[] = status ? status?.split(',') : [];
    const isAllSelected = this.fffNewReportsService
      .getAvailableStatusesByType(reportType)
      .filter(item => item.id !== ReportStatusTypes.ALL)
      .map(item => item.id)
      .every(id => selectedStatuses.includes(id));

    if (isAllSelected) {
      selectedStatuses.unshift(ReportStatusTypes.ALL);
    }
    return {
      reportType,
      reportID,
      ...(reportFormat && {
        reportFormat,
      }),
      reportFrequency,
      emailIds: (emailIds || '').trim(),
      accounts: accounts ? accounts?.split(',') : [],
      ...(products && {
        products,
      }),
      ...(status && {
        status: selectedStatuses,
      }),
      ...(reportRange && {
        reportRange,
      }),
      productGroups: productGroups ? productGroups?.split(',') : [],
      ...(firstReportDate && {
        firstReportDate: this.mapDateToNgbDateStruct(
          new Date(firstReportDate).getTime()
        ),
      }),
      ...(reportDate && {
        reportDate: this.mapDateToNgbDateStruct(new Date(reportDate).getTime()),
      }),
      ...(fromDate && {
        fromDate: this.mapDateToNgbDateStruct(new Date(fromDate).getTime()),
      }),
      ...(toDate && {
        toDate: this.mapDateToNgbDateStruct(new Date(toDate).getTime()),
      }),
    };
  }

  ngbDateToDate(date: NgbDateStruct): string {
    return `${date.year}-${date.month}-${date.day}`;
  }

  loadReportFormData() {
    const formData = this.convertToFormData(this.reportData);
    this.form.patchValue(formData);

    const { accounts, productGroups, products } = formData;

    if (accounts) {
      const formattedAccounts = accounts.map(acc => ({
        id: acc,
        displayValue: acc,
      }));
      this.selectedAccountTags = formattedAccounts;
      this.fffNewReportsService.setSelectedAccounts(formattedAccounts);
    }

    if (productGroups?.length) {
      const formattedItems = productGroups.map(item => ({
        id: item,
        displayValue: item,
      }));
      this.selectedProductGroupTags = formattedItems;
      this.fffNewReportsService.setSelectedProductGroups(formattedItems);
    }

    if (products?.length) {
      const formattedItems = products.map(item => ({
        id: item.sku,
        displayValue: item.name,
      }));
      this.selectedProductGroupTags = formattedItems;
      this.fffNewReportsService.setSelectedProducts(formattedItems);
    }

    this.cd.markForCheck();
  }

  getFormattedPayload(payload: any) {
    if (payload.emailIds) {
      payload.emailIds = payload.emailIds.trim();
    }

    if (payload.accounts) {
      payload.accounts = payload.accounts.toString();
    }

    if (payload.products) {
      payload.products = payload.products.map((sku: any) => ({ sku }));
    }

    if (payload.productGroups) {
      payload.productGroups = payload.productGroups.toString();
    }

    if (payload.status) {
      payload.status = (payload.status as string[])
        .filter(s => s !== ReportStatusTypes.ALL)
        .toString();
    }

    if (payload.fromDate) {
      payload.fromDate = this.ngbDateToDate(payload.fromDate);
    }

    if (payload.toDate) {
      payload.toDate = this.ngbDateToDate(payload.toDate);
    }

    if (payload.firstReportDate) {
      payload.firstReportDate = this.ngbDateToDate(payload.firstReportDate);
    }

    if (payload.reportDate) {
      payload.reportDate = this.ngbDateToDate(payload.reportDate);
    }

    return payload;
  }

  submitNewReport() {
    this.toggleSubmitting(true);

    const payload: any = this.getFormattedPayload(this.form.getRawValue());
    delete payload.reportID;
    if ('PDF_DOWNLOAD' === payload.reportFormat) {
      this.exportPDF(payload);
      this.toggleSubmitting(false);
      this.resetForm();
    } else if ('CSV_DOWNLOAD' === payload.reportFormat) {
      this.exportCSV(payload);
      this.toggleSubmitting(false);
      this.resetForm();
    } else {
      this.communicationService.sendNewReport(payload).subscribe(
        () => {
          this.toggleSubmitting(false);
          this.openSuccessModal(
            this.isWeeklyOrMonthly()
              ? 'fffReports.edited'
              : 'fffReports.submitted'
          );
          this.resetForm();
          this.fffNewReportsService.refreshResults();
        },
        () => {
          this.toggleSubmitting(false);
          this.openWarningModal();
        }
      );
    }
  }

  updateReport() {
    this.toggleSubmitting(true);
    const payload: any = this.getFormattedPayload(this.form.getRawValue());
    this.fffNewReportsService.updateReport(payload.reportID, payload).subscribe(
      () => {
        this.toggleSubmitting(false);
        this.openSuccessModal('fffReports.edited');
        this.resetForm();
        this.fffNewReportsService.refreshResults();
      },
      () => {
        this.toggleSubmitting(false);
        this.openWarningModal();
      }
    );
  }

  onSubmit() {
    this.isFormLoaded = false;
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      return;
    }

    if (this.editable) {
      this.updateReport();
    } else {
      this.submitNewReport();
    }
  }

  async exportCSV(formData: any) {
    this.openExportedDocumentSuccess();
    let r = await this.parseExportInfo(formData, 'csv');
    let options: ExportFileOptions = {
      fileName: 'priceList',
      extension: 'csv',
      type: 'multipart/form-data',
    };
    this.exportCSVService.download(r, ',', options);
  }

  async exportPDF(formData: any) {
    this.openExportedDocumentSuccess();
    let doc = new jsPDF();
    let exportInfo = await this.parseExportInfo(formData, 'pdf');
    let head = exportInfo[0];
    let body = [];
    for (let i = 1; i < exportInfo.length; i++) {
      body.push(exportInfo[i]);
    }

    let columnStyles: any = {};
    columnStyles = {
      0: { fontStyle: 'normal', cellWidth: 20 },
      1: { fontStyle: 'normal', cellWidth: 40 },
      2: { fontStyle: 'normal', cellWidth: 25 },
      3: { fontStyle: 'normal', cellWidth: 20 },
      4: { fontStyle: 'normal', cellWidth: 20 },
      5: { fontStyle: 'normal', cellWidth: 25 },
      6: { cellWidth: 25 },
    };

    autoTable(doc, {
      head: [head],
      body: body,
      styles: {
        fontSize: 10,
        fontStyle: 'normal',
        cellPadding: 2,
      },
      headStyles: {
        fontSize: 7,
        fontStyle: 'bold',
      },
      bodyStyles: {
        fontSize: 6,
      },
      columnStyles: columnStyles,
    });

    doc.save('priceList.pdf');
  }

  async parseExportInfo(formData: any, fileType?: string) {
    let data: any[] = [];
    let d = await this.communicationService
      .getFullUserPriceList(
        formData.accounts,
        formData.productGroups.split(',')
      )
      .toPromise();
    //formatting the data as we want it to be exported
    data = this.transformAltPricesArrayIntoProperty(d.results);
    let fileNames: any[] = [];

    //adding a copy because I don't want to modify the original frontend list.
    let fields = fileType === 'pdf' ? [...this.fields] : [...this.csvFields];

    const spliceIndex = fileType === 'pdf' ? 6 : 10;
    fields.splice(spliceIndex, 0, {
      title: 'fffPriceList.contractProduct',
      field: 'contractedMaterial',
    });

    //adding the titles.
    fields.forEach((f: any) => {
      this.translationService.translate(f.title).subscribe(res => {
        fileNames.push(res);
      });
    });

    let exportFileEntries: string[][] = [];
    let fileTitles = fields.map((e: { field: any }) => {
      return e.field;
    });
    exportFileEntries[0] = fileNames;

    data.forEach((e: any, index: any) => {
      //+1 because we already added the titles.
      exportFileEntries[index + 1] = [];

      fileTitles.forEach((key: any, labelIndex: any) => {
        //based on the titles, we add the data.
        const keys = key.split(',').map((key: any) => key.trim());
        if (fileType === 'pdf') {
          let valueToPush = '';

          const separator = '\n';
          if (keys && keys.length > 0) {
            if (labelIndex === 5) {
              const priceLabels: string[] = ['Price', 'Alt Price', 'Net Price'];
              const pricesArray: string[] = [];

              keys.forEach((key: any) => {
                if (key && e.csvFormat[key]) {
                  pricesArray.push(e.csvFormat[key]);
                }
              });
              if (priceLabels.length === keys.length) {
                for (let i = 0; i < priceLabels.length; i++) {
                  const priceSeparator =
                    i === priceLabels.length - 1 ? '' : separator;
                  valueToPush += `${priceLabels[i]}: ${pricesArray[i]} ${priceSeparator}`;
                }
              }
            } else if (labelIndex === 6) {
              keys.forEach((key: any) => {
                if (key && e.csvFormat[key] && e.csvFormat[key]) {
                  valueToPush = 'YES';
                }
              });
            } else {
              keys.forEach((key: any, index: any) => {
                if (key && e.csvFormat[key]) {
                  const standardSeparator = index === 0 ? '' : separator;
                  valueToPush =
                    valueToPush + standardSeparator + e.csvFormat[key];
                }
              });
            }
          }
          exportFileEntries[index + 1].push(valueToPush ? valueToPush : ' ');
        } else {
          if (labelIndex === 10) {
            keys.forEach((key: any) => {
              if (key && e.csvFormat[key] && e.csvFormat[key]) {
                exportFileEntries[index + 1].push('YES');
              }
            });
          } else {
            exportFileEntries[index + 1].push(
              e.csvFormat[key] ? e.csvFormat[key] : ' '
            );
          }
        }
      });
    });

    return exportFileEntries;
  }

  openExportedDocumentSuccess() {
    this.modalService.open(FffExportedDocumentMessageComponent, {
      centered: true,
      backdrop: 'static',
    });
  }

  transformAltPricesArrayIntoProperty(dataStructArray: any[]): any[] {
    dataStructArray?.forEach((tableRow: any) => {
      if (Math.round(+tableRow.moq) > 1) {
        this.processTableRowAlt(tableRow);
      } else {
        this.processTableRowBasic(tableRow);
      }

      this.processNetPrice(tableRow);

      this.formatForCSV(tableRow);

      if (!tableRow.priceColumn) tableRow.priceColumn = '$0.00';
      if (!tableRow.altPriceColumn)
        tableRow.altPriceColumn = tableRow.priceColumn;
      if (!tableRow.basicDiscountPrice) tableRow.basicDiscountPrice = '$0.00';
    });
    return dataStructArray;
  }
  processTableRowAlt(tableRow: any) {
    const altPriceList = tableRow.toAltPrice.results;
    const hybrisAltUOM = tableRow.hybrisAltUOM;
    const foundAltPrice = altPriceList.find(
      (altPrice: any) => altPrice.altUOM === hybrisAltUOM
    );
    const price = altPriceList.find(
      (altPrice: any) => altPrice.altMinOrdQty === 1
    );

    tableRow.priceColumn = this.formatPrice(
      price?.basicCP,
      '',
      price?.basicUOM
    ); //alt price
    tableRow.altPriceColumn = this.formatPrice(
      foundAltPrice?.altCP,
      '',
      foundAltPrice?.altUOM
    ); //price
  }

  processTableRowBasic(tableRow: any) {
    const altPriceList = tableRow.toAltPrice.results;
    const hybrisAltUOM = tableRow.hybrisAltUOM;
    const foundAltPrice = altPriceList.find(
      (altPrice: any) => altPrice.altUOM === hybrisAltUOM
    );
    const price = altPriceList.find(
      (altPrice: any) => altPrice.altMinOrdQty === 1
    );

    tableRow.altPriceColumn = foundAltPrice
      ? this.formatPrice(
          foundAltPrice?.altCP,
          foundAltPrice?.altOMP,
          foundAltPrice?.altUOM
        )
      : this.formatPrice(
          tableRow.basicCP,
          tableRow.basicOMP,
          tableRow.basicUOM
        );

    if (Math.round(+tableRow.minOrderQuantity) === 1) {
      tableRow.priceColumn = this.formatPrice(
        tableRow.basicCP,
        tableRow.basicOMP,
        tableRow.basicUOM
      );
    } else if (Math.round(+tableRow.minOrderQuantity) > 1) {
      tableRow.priceColumn = this.formatPrice(
        price?.altCP,
        price?.altOMP,
        price?.altUOM
      );
    }
  }

  formatForCSV(tableRow: any) {
    tableRow.csvFormat = {
      priceColumn: tableRow.priceColumn?.split('/')[0] || '$0.00',
      altPriceColumn:
        tableRow.altPriceColumn?.split('/')[0] ||
        tableRow.priceColumn?.split('/')[0],
      basicDiscountPrice: tableRow.basicDiscountPrice?.split('/')[0] || '$0.00',
      ndc: tableRow.ndc,
      category: tableRow.category,
      name: tableRow.name,
      size: tableRow.size,
      manufacturer: tableRow.manufacturer,
      productCode: tableRow.productCode,
      accountNumber: tableRow.accountNumber,
      altUOM: tableRow.priceColumn?.split('/')[1],
      contractUOM:
        tableRow.altPriceColumn?.split('/')[1] ||
        tableRow.priceColumn?.split('/')[1],
      netUOM: tableRow.basicDiscountPrice?.split('/')[1],
      contractedMaterial: tableRow.contractedMaterial,
    };
  }

  roundPriceToDecimals(value: string): string {
    const decimalPlaces = this.isFourDecimalAccount ? 10000 : 100;
    const price = this.decimalPipe.transform(
      Math.round((+value + Number.EPSILON) * decimalPlaces) / decimalPlaces,
      this.isFourDecimalAccount ? '1.4-4' : '1.2-2'
    );
    return price ? price : '$0.00';
  }

  formatPrice(price: string, omp: string, uom: string): string {
    if (!price && !omp) {
      return '';
    }
    let formattedPrice =
      '$' +
      (price !== '' && +price > 0
        ? this.roundPriceToDecimals(price)
        : omp !== ''
        ? this.roundPriceToDecimals(omp)
        : '0.00');
    formattedPrice += uom ? '/' + uom : '';
    return formattedPrice;
  }
  processNetPrice(tableRow: any) {
    const altPriceList = tableRow.toAltPrice.results;
    const hybrisAltUOM = tableRow.hybrisAltUOM;
    const foundAltPrice = altPriceList.find(
      (altPrice: any) => altPrice.altUOM === hybrisAltUOM
    );

    tableRow.basicDiscountPrice = foundAltPrice
      ? this.formatPrice(foundAltPrice.altDiscPrice, '', hybrisAltUOM)
      : this.formatPrice(tableRow.basicDiscountPrice, '', tableRow.basicUOM);
  }
}
