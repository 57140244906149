import {
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { FffNewReportsService } from '@app/fff-enterprise/fff-new-reports/fff-new-reports.service';
import { FffB2bUnit } from '@app/models/fff-b2b-unit.model';
import { FffProfile } from '@app/models/fff-profile.model';
import { FffUserAccountService } from '@app/shared/services/fff-user-account.service';
import { FffGtmEventService } from '@app/spartacus/features/tracking/gmt/event';
import {
  NgbActiveModal,
  NgbModal,
  NgbModalRef,
} from '@ng-bootstrap/ng-bootstrap';
import { CsAgentAuthService } from '@spartacus/asm/root';
import { ActiveCartFacade, Cart, OrderEntry } from '@spartacus/cart/base/root';
import { GlobalMessageService, GlobalMessageType } from '@spartacus/core';
import { BehaviorSubject, Subject, Subscription, forkJoin } from 'rxjs';
import { debounceTime, switchMap, take, tap } from 'rxjs/operators';
import { OUTLINED_ICON_TYPE } from 'src/app/models/fff-outline-icons.model';
import { FffCommunicationService } from '../../fff-common-services/fff-communication.service';
import { FffChangeAccountWarningModalComponent } from './fff-change-account-warning-modal/fff-change-account-warning-modal.component';

@Component({
  selector: 'fff-change-account-drawer',
  templateUrl: './fff-change-account-drawer.component.html',
})
export class FffChangeAccountDrawerComponent
  implements OnInit, OnDestroy, OnChanges
{
  @Input() profile!: FffProfile;
  iconClose = OUTLINED_ICON_TYPE.ICON_X;
  selectedUnit!: FffB2bUnit;
  outlinedIconTypes = OUTLINED_ICON_TYPE;
  cart: Cart | null = null;
  cartEntries: OrderEntry[] = [];
  fffWarningModal: NgbModalRef | null = null;
  loading = false;
  ready = false;
  private subscription: Subscription = new Subscription();
  oldCartId: string = '';

  txtQueryChanged: Subject<string> = new Subject<string>();
  accounts: FffB2bUnit[] = [];
  searchQueryCopy: string = '';
  pageSize: number = 50;
  accountsList: any = { data: [], total: 0, loading: false, current: 0 };
  accountListData$: BehaviorSubject<any> = new BehaviorSubject<any>(undefined);
  isASMUser!: boolean;
  searchTextInput = new UntypedFormControl('');

  constructor(
    private communicationService: FffCommunicationService,
    private globalMessageService: GlobalMessageService,
    private fffActiveCartService: ActiveCartFacade,
    private modalService: NgbModal,
    private ActiveModalService: NgbActiveModal,
    private fffGtmEventService: FffGtmEventService,
    private cd: ChangeDetectorRef,
    private newReportService: FffNewReportsService,
    private csAgentAuthService: CsAgentAuthService,
    private fffUserAccountService: FffUserAccountService
  ) {}

  ngOnInit(): void {
    this.init();
    this.getAccountsData({ page: 0, size: this.pageSize });
    this.searchAccounts();
    this.subscription.add(
      this.fffActiveCartService.getActive().subscribe(cart => {
        this.cart = cart;
      })
    );

    this.subscription.add(
      this.csAgentAuthService
        .isCustomerSupportAgentLoggedIn()
        .pipe(
          tap(isCustomerSupportAgentLoggedIn => {
            this.isASMUser = !!isCustomerSupportAgentLoggedIn;
            if (!this.isASMUser) {
              this.searchAccounts();
            }
            this.cd.detectChanges();
          })
        )
        .subscribe()
    );
  }

  searchAccounts() {
    this.subscription.add(
      this.searchTextInput.valueChanges
        .pipe(
          debounceTime(500),
          switchMap(searchText => {
            this.loading = true;
            return this.newReportService.getAccountsByPage(0, searchText);
          })
        )
        .subscribe(
          (res: any) => this.handleAccountDataSuccess(res),
          (error: any) => this.handleAccountDataError(error)
        )
    );
  }

  handleAccountDataSuccess(res: any) {
    if (res.b2bunits && res.b2bunits.length > 0) {
      const total = res?.pagination?.totalResults
        ? res.pagination.totalResults
        : 1;

      this.accountsList = {
        data: res.b2bunits,
        total: total,
        loading: false,
        current: this.accountsList.current,
      };
      this.emitNewAccountListData(this.accountsList);
      this.loading = false;
    } else {
      this.accountsList = {
        data: [],
        total: 0,
        loading: false,
        current: this.accountsList.current,
      };
      this.emitNewAccountListData(this.accountsList);
      this.loading = false;
    }
  }

  handleAccountDataError(error: any) {
    this.accountsList = {
      data: [],
      total: 0,
      loading: false,
      current: this.accountsList.current,
    };
    this.emitNewAccountListData(this.accountsList);
    this.loading = false;
  }

  getAccountsData(page: any, isFromPaginator?: boolean) {
    this.loading = true;
    const currentPage =
      isFromPaginator && page.page ? page.page - 1 : page.page;
    this.newReportService
      .getAccountsByPage(currentPage, this.searchTextInput.value)
      .subscribe(
        (res: any) => this.handleAccountDataSuccess(res),
        (error: any) => this.handleAccountDataError(error)
      );
    this.cd.detectChanges();
  }

  emitNewAccountListData(data: any): void {
    this.accountListData$.next(data);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.profile && changes?.profile?.currentValue) {
      this.init();
      this.searchAccounts();
    }
  }

  init() {
    this.selectedUnit = this.profile.selected;
    this.cd.markForCheck();
  }

  clear() {
    this.searchTextInput.patchValue('');
    this.searchAccounts();
  }

  changeAccount(cartId: string) {
    this.oldCartId = cartId;
    if (!this.cart || !this.cart.entries || this.cart.entries.length === 0) {
      this.changeB2BUserAccount();
    } else {
      this.openCartWarningDrawer();
    }
  }

  private changeB2BUserAccount() {
    this.loading = true;

    forkJoin([
      this.communicationService.postSwitchB2BUnit({
        uid: this.profile.user.uid,
        activeId: this.selectedUnit.uid,
        cartCode: this.cart?.code,
      }),
      this.communicationService.postSwitchB2BUnitUserAccount({
        activeId: this.selectedUnit.uid,
      }),
    ])
      .pipe(take(1))
      .subscribe(
        ([res1, res2]) => {
          this.fffGtmEventService.userAccountChangedEvent(this.selectedUnit);
          this.globalMessageService.add(
            res1,
            GlobalMessageType.MSG_TYPE_INFO,
            5000
          );
          this.globalMessageService.add(
            res2,
            GlobalMessageType.MSG_TYPE_INFO,
            5000
          );
          this.close();
          this.fffUserAccountService.loadUserState();
          setTimeout(() => {
            // Reload the current page, this will reset the new info
            document.body.innerHTML = '';
            window.location.href = '/';
          }, 0);
        },
        error => {
          console.error(error);
        }
      );
  }

  private openCartWarningDrawer() {
    if (!this.fffWarningModal) {
      this.fffWarningModal = this.modalService.open(
        FffChangeAccountWarningModalComponent,
        {
          animation: true,
          backdrop: 'static',
        }
      );
      this.fffWarningModal.componentInstance.profile = this.profile;
      this.fffWarningModal.componentInstance.cart = this.cart;
      this.fffWarningModal.result
        .then(result => {
          if (result) {
            this.changeB2BUserAccount();
          }
          this.fffWarningModal = null;
        })
        .catch(() => {
          this.fffWarningModal = null;
        });
    }
  }

  hasShippingAddress(b2bUnitData: any): boolean {
    if (b2bUnitData.addresses) {
      return b2bUnitData.addresses.some(
        (address: any) => address.shippingAddress
      );
    }
    return false;
  }

  getShippingAddress(b2bUnitData: any): any {
    const shippingAddressList = b2bUnitData.addresses.filter(
      (address: any) => address.shippingAddress
    );
    let shippingAddress =
      shippingAddressList.length > 0 ? shippingAddressList[0] : null;

    if (shippingAddress) {
      const regionName = shippingAddress.region?.name
        ? shippingAddress.region?.name
        : '';
      const data =
        this.addAddresField(shippingAddress.line1, '<br/>') +
        this.addAddresField(shippingAddress.line2, '<br/>') +
        this.addAddresField(shippingAddress.town, ', ') +
        this.addAddresField(regionName, '') +
        '<br/>' +
        this.addAddresField(shippingAddress.postalCode, '');
      return data;
    }
    return {};
  }

  private addAddresField(field: string, separator: string): string {
    if (field) {
      return field + separator;
    }
    return '';
  }

  close() {
    this.ActiveModalService.dismiss();
  }

  selectB2bUnit(unit: FffB2bUnit) {
    this.selectedUnit = unit;
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }
}
