import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnInit,
} from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { FffCartService } from '@app/fff-enterprise/fff-common-services/fff-cart-service';
import { FffCheckoutService } from '@app/fff-enterprise/fff-common-services/fff-checkout.service';
import { FffCommunicationService } from '@app/fff-enterprise/fff-common-services/fff-communication.service';
import { FffOrderFacade } from '@app/fff-enterprise/fff-common-services/fff-order-facade';
import { FffB2bUnit } from '@app/models/fff-b2b-unit.model';
import { FILLED_ICON_TYPE } from '@app/models/fff-filled-icons.model';
import {
  EntryPoState,
  ORDER_CONFIRMATION_DATA,
  OrderConfirmationState,
} from '@app/reducers';
import { ToastService } from '@app/shared/services/toast.service';
import { FffGtmEventService } from '@app/spartacus/features/tracking/gmt/event';
import { BASE_URL_KEYS } from '@config/content/constants';
import { FFFCart } from '@model/fff-cart-data.model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import {
  ActiveCartFacade,
  Cart,
  MultiCartFacade,
  OrderEntry,
} from '@spartacus/cart/base/root';
import { CheckoutOrderSummaryComponent } from '@spartacus/checkout/base/components';
import { BaseSiteService, RoutingService, WindowRef } from '@spartacus/core';
import { UserAccountFacade } from '@spartacus/user/account/root';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { OUTLINED_ICON_TYPE } from 'src/app/models/fff-outline-icons.model';
import { FffCheckoutErrorPlacingOrderComponent } from '../fff-checkout-modals/fff-checkout-error-placing-order/fff-checkout-error-placing-order.component';
import { FffCheckoutPrepaymentComponent } from '../fff-checkout-modals/fff-checkout-prepayment/fff-checkout-prepayment.component';
import { FffCheckoutRecurringOrdersPopupComponent } from '../fff-checkout-modals/fff-checkout-recurring-orders-popup/fff-checkout-recurring-orders-popup.component';

@Component({
  selector: 'fff-checkout-summary',
  templateUrl: './fff-checkout-summary.component.html',
})
export class FffCheckoutSummaryComponent
  extends CheckoutOrderSummaryComponent
  implements OnInit, AfterViewInit
{
  outlinedIconTypes = OUTLINED_ICON_TYPE;
  filledIconTypes = FILLED_ICON_TYPE;
  acceptTermsConditions: boolean = false;

  userWithPrepaymentStatus: boolean = true;

  activeSite$ = this.baseSiteService.getActive();
  BASE_URL_KEYS = BASE_URL_KEYS;
  cartId: string = '';
  cart$: Observable<FFFCart> = this.fffActiveCartService.getActive();
  entries: OrderEntry[] = [];
  cartChangedSuscriptionRef: any;
  placeOrderText: string = 'fffOrderSummary.placeOrder';
  recurringOrdersForm: UntypedFormGroup | undefined;
  isRecurringOrder: boolean = false;

  private PRE_PAYMENT_TYPE: string = 'PP';

  b2bUnitData$: Observable<any> = this.userAccountDetails.get().pipe(
    switchMap(user => {
      return this.communicationService.getCurrentAccount(user);
    })
  );

  loading: boolean = false;

  shippingCost: any;
  checkoutForm: any;
  invalidForm: boolean = false;
  shippingWindowsData: any = {};

  constructor(
    private userAccountDetails: UserAccountFacade,
    private communicationService: FffCommunicationService,
    protected fffActiveCartService: ActiveCartFacade,
    private modalService: NgbModal,
    private elementRef: ElementRef,
    public windowRef: WindowRef,
    protected routingService: RoutingService,
    private fffOrderFacade: FffOrderFacade,
    public fffCheckoutService: FffCheckoutService,
    private store: Store<OrderConfirmationState>,
    private baseSiteService: BaseSiteService,
    private cdRef: ChangeDetectorRef,
    private fffCartService: FffCartService,
    private multiCartService: MultiCartFacade,
    private storeEntryPo: Store<EntryPoState>,
    protected fffGtmEventService: FffGtmEventService,
    private toastService: ToastService
  ) {
    super(fffActiveCartService);
  }

  ngOnInit(): void {
    this.cart$.subscribe((cart: Cart) => {
      this.cartId = cart.code!;
      this.entries = cart.entries!;
      this.cdRef.detectChanges();
    });

    this.cartChangedSuscriptionRef =
      this.communicationService.messageSource.subscribe(res => {
        this.cart$ = this.fffActiveCartService.getActive();
        this.cdRef.detectChanges();
      });

    this.fffCheckoutService.checkoutForm.subscribe((res: any) => {
      if (res) {
        this.invalidForm = res.status == 'INVALID';
        this.cdRef.detectChanges();
      }
    });

    this.fffCheckoutService.recurringOrdersForm.subscribe(
      (recurringOrdersForm: any) => {
        if (recurringOrdersForm) {
          this.recurringOrdersForm = recurringOrdersForm;
          recurringOrdersForm
            .get('isRecurringOrder')
            ?.valueChanges.subscribe((isRecurringOrder: boolean) => {
              this.isRecurringOrder = isRecurringOrder;
              this.placeOrderText = isRecurringOrder
                ? 'fffOrderSummary.saveRecurringOrder'
                : 'fffOrderSummary.placeOrder';
            });
        }
      }
    );

    this.storeEntryPo
      .select((state: any) => {
        return state.EntryPo;
      })
      .subscribe(shippingWindows => {
        if (
          shippingWindows?.data &&
          Object.keys(shippingWindows?.data).length > 0
        ) {
          this.shippingWindowsData = shippingWindows.data;
        }
      });
  }

  ngOnDestroy() {
    this.cartChangedSuscriptionRef.unsubscribe();
  }

  ngAfterViewInit(): void {
    if (this.elementRef.nativeElement.querySelector('.terms-conditions')) {
      this.elementRef.nativeElement
        .querySelector('.terms-conditions')
        .addEventListener('click', this.goToTermsOfUse.bind(this));
    }
  }

  goToTermsOfUse(): void {
    if (this.windowRef.isBrowser()) {
      this.baseSiteService.getActive().subscribe(activeSite => {
        activeSite === 'biosupply'
          ? this.windowRef.nativeWindow?.open(
              'https://www.fffenterprises.com/site/terms-of-sale.html',
              '_blank'
            )
          : this.windowRef.nativeWindow?.open(
              'https://www.myfluvaccine.com/site/terms-of-sale.html',
              '_blank'
            );
      });
    }
  }

  isUserWithPrepaymentStatus(b2bUnitData: any): boolean {
    return this.PRE_PAYMENT_TYPE == b2bUnitData.paymentTerms;
  }

  createReplenishmentOrder(recurringOrdersForm: any): void {
    if (recurringOrdersForm && !recurringOrdersForm.valid) {
      recurringOrdersForm.markAllAsTouched();
      this.fffCheckoutService.hasErrors.next(true);
      return;
    }

    const recurringOrdersData = recurringOrdersForm?.value;
    const checkoutFormData = this.fffCheckoutService.checkoutForm.value;

    if (checkoutFormData && !checkoutFormData.valid) {
      checkoutFormData.markAllAsTouched();
      this.fffCheckoutService.hasErrors.next(true);
      return;
    }

    const formatedReplenishmentStartDate = `${recurringOrdersData.orderStartDate.year}-${recurringOrdersData.orderStartDate.month}-${recurringOrdersData.orderStartDate.day}T08:00:00+0000`;

    const requestReplenishmentOrder: any = {
      recurrencePeriod: recurringOrdersData.frequency,
      replenishmentStartDate: formatedReplenishmentStartDate,
    };
    const purchaseOrderNumber = checkoutFormData?.value.poNumber;

    if (checkoutFormData?.value.referenceName) {
      requestReplenishmentOrder.referenceName =
        checkoutFormData?.value.referenceName;
    }
    if (checkoutFormData?.value.additionalEmailConf) {
      requestReplenishmentOrder.adConfirmationEmail =
        checkoutFormData?.value.additionalEmailConf;
    }
    if (recurringOrdersData.frequencyWeeklyOn) {
      requestReplenishmentOrder.daysOfWeek = [
        recurringOrdersData.frequencyWeeklyOn,
      ];
    }

    if (recurringOrdersData.frequencyMonthlyOnDay) {
      requestReplenishmentOrder.nthDayOfMonth =
        recurringOrdersData.frequencyMonthlyOnDay;
    }

    if (recurringOrdersData.frequencyWeeklyWeeks) {
      requestReplenishmentOrder.numberOfWeeks =
        recurringOrdersData.frequencyWeeklyWeeks;
    }

    this.loading = true;
    this.cdRef.markForCheck();
    const replenishmentOrder$ = this.fffOrderFacade.createReplenishmentOrder(
      this.cartId,
      this.acceptTermsConditions,
      purchaseOrderNumber,
      requestReplenishmentOrder
    );
    replenishmentOrder$.subscribe(
      response => {
        // this.showReplenishmentOrderPopUp();
        this.loadNewCart();
        this.routingService.go(['/my-account/recurring-orders']);
        this.toastService.showSuccess(
          'fffOrderSummary.messages.saveRecurringOrder',
          {
            delay: 5000,
            i18nParams: {
              recurringOrder: response?.code,
            },
          }
        );
      },
      error => {
        this.showReplenishmentOrderPopUp(error);
      }
    );
  }

  private showReplenishmentOrderPopUp(errorData?: any): void {
    this.loading = false;
    this.cdRef.detectChanges();
    const modalRef = this.modalService.open(
      FffCheckoutRecurringOrdersPopupComponent,
      {
        centered: true,
        size: 'md',
        backdrop: 'static',
        modalDialogClass: 'modal-container',
      }
    );

    if (errorData) {
      modalRef.componentInstance.hasErrorData = true;
      let errorMessage = '';
      errorData.error.errors.forEach(
        (err: any) => (errorMessage += err.message)
      );
      modalRef.componentInstance.errorData = { code: errorMessage };
    }

    modalRef.result.then(result => {
      if (result.goToHomePage) {
        this.loadNewCart();
        this.routingService.go(['/']);
      }
    });
  }

  placeOrder(b2bUnitData: FffB2bUnit): void {
    const recurringOrdersForm =
      this.fffCheckoutService.recurringOrdersForm.value;
    const recurringOrdersData = recurringOrdersForm?.value;
    if (recurringOrdersData && recurringOrdersData.isRecurringOrder) {
      this.createReplenishmentOrder(recurringOrdersForm);
    } else {
      this.placeOrderStandardOrder(b2bUnitData);
    }
  }

  placeOrderStandardOrder(b2bUnitData: FffB2bUnit): void {
    let checkoutForm = this.fffCheckoutService.checkoutForm.value;

    if (checkoutForm && !checkoutForm.valid) {
      checkoutForm.markAllAsTouched();
      this.fffCheckoutService.hasErrors.next(true);
      return;
    }
    const checkoutFormData = checkoutForm?.value;
    const contactMethod = checkoutFormData.contactMethod;
    delete checkoutFormData.contactMethod;

    this.loading = true;
    this.cdRef.markForCheck();
    const placeOrder$ = this.fffOrderFacade.placeOrder(
      this.cartId,
      this.acceptTermsConditions,
      checkoutFormData,
      this.buildShippingWindows(this.shippingWindowsData),
      contactMethod
    );
    placeOrder$.subscribe(
      response => {
        if (response) {
          const ordersWithError = response.orders.filter(
            (order: any) => !order.isSuccess
          );
          const quotesWithError = response.quotes.filter(
            (quote: any) => !quote.isSuccess
          );
          const ordersSuccess = response.orders.filter(
            (order: any) => order.isSuccess
          );
          const quotesSuccess = response.quotes.filter(
            (quote: any) => quote.isSuccess
          );

          if (
            ordersWithError.length > 0 ||
            quotesWithError.length > 0 ||
            (ordersSuccess.length == 0 && quotesSuccess.length == 0)
          ) {
            this.showErrorPlacingOrder(response);
          } else {
            this.fffGtmEventService.placeOrder(ordersSuccess, b2bUnitData);
            this.fffCheckoutService.checkoutForm.value
              ?.get('poNumber')
              ?.reset();
            this.goToOrderConfirmation(response);
          }
        }
      },
      error => {
        this.showErrorPlacingOrder(error);
      }
    );
  }

  private buildShippingWindows(shippingWindowsData: any): any {
    let preBookShippingWindows: any[] = [];
    for (const key in shippingWindowsData) {
      const element = shippingWindowsData[key];
      element.data.prebookShippingWindows.forEach((sw: any) => {
        if (
          sw.poNumber &&
          this.isValidForCart(sw.entryNumber, sw.productCode)
        ) {
          preBookShippingWindows.push(sw);
        }
      });
    }
    return { preBookShippingWindows: preBookShippingWindows };
  }

  private isValidForCart(entryNumber: number, productCode: string): boolean {
    return this.entries.some(
      entry =>
        productCode == entry.product?.code && entryNumber == entry.entryNumber
    );
  }

  private goToOrderConfirmation(response: any): void {
    response.orders.forEach((order: any) => (order.type = 'ORDER'));
    response.quotes.forEach((order: any) => (order.type = 'QUOTE'));
    this.store.dispatch({ type: ORDER_CONFIRMATION_DATA, payload: response });
    this.loadNewCart();
    this.routingService.go(['/order-confirmation']);
  }

  prepaymentOrder(b2bUnitData: FffB2bUnit): void {
    this.showPrepaymentOrder(b2bUnitData);
  }

  getDataShowErrorPlacingOrder(response: any, field: string): any[] {
    const errorCodes: string[] = [];
    if (response[field]) {
      response[field].forEach((tx: any) => {
        if (!tx.isSuccess) {
          errorCodes.push(tx.integrationResponseMessage);
        }
      });
    }
    return errorCodes;
  }

  showErrorPlacingOrder(response: any): void {
    this.loading = false;
    this.cdRef.detectChanges();
    const modalRef = this.modalService.open(
      FffCheckoutErrorPlacingOrderComponent,
      {
        centered: true,
        size: 'md',
        backdrop: 'static',
        modalDialogClass: 'modal-container',
      }
    );
    const ordersErrorCodes: string[] = this.getDataShowErrorPlacingOrder(
      response,
      'orders'
    );
    const quotesErrorCodes: string[] = this.getDataShowErrorPlacingOrder(
      response,
      'quotes'
    );
    const ordersSuccess = response.orders?.filter(
      (order: any) => order.isSuccess
    );
    const quotesSuccess = response.quotes?.filter(
      (quote: any) => quote.isSuccess
    );

    const errorCodes = `${ordersErrorCodes.toString()} ${quotesErrorCodes.toString()}`;

    modalRef.componentInstance.hasErrorData =
      ordersErrorCodes.length > 0 || quotesErrorCodes.length > 0;
    modalRef.componentInstance.hasSuccessData =
      ordersSuccess?.length > 0 || quotesSuccess?.length > 0;
    modalRef.componentInstance.errorData = { code: errorCodes };

    modalRef.result.then(result => {
      if (result.continueOrderConfirmation) {
        this.goToOrderConfirmation(response);
      }
    });
  }

  showPrepaymentOrder(b2bUnitData: FffB2bUnit) {
    const modalRef = this.modalService.open(FffCheckoutPrepaymentComponent, {
      centered: true,
      size: 'md',
      backdrop: 'static',
      modalDialogClass: 'modal-container',
    });

    modalRef.result.then(result => {
      if (result && result.accept) {
        this.placeOrder(b2bUnitData);
      }
    });
  }

  private loadNewCart(): void {
    try {
      this.fffCartService
        .getActiveCart('current', 'current')
        .subscribe(newCart => {
          if (newCart && newCart.code) {
            this.multiCartService.loadCart({
              cartId: newCart.code,
              userId: 'current',
              extraData: { active: true },
            });
          }
        });
    } catch (error) {}
  }
}
