import { Component, Inject, OnInit } from '@angular/core';
import { OUTLINED_ICON_TYPE } from '@app/models/fff-outline-icons.model';
import { NgbActiveModal, NgbCalendar } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'fff-reports-modal',
  templateUrl: './fff-reports-modal.component.html',
})
export class FffReportsModalComponent implements OnInit {
  constructor(
    @Inject('validations') public validations: any,
    public ngbCalendar: NgbCalendar,
    private modalService: NgbActiveModal
  ) {}

  outlinedIconTypes = OUTLINED_ICON_TYPE;
  errors: any[] = [];

  ngOnInit(): void {
    let { formValue, statusList } = this.validations;

    if (formValue.get('email').errors?.invalidEmail) {
      this.errors.push('fffReports.emailIssue');
    }

    if (formValue.get('email').errors?.maxFourEmail) {
      this.errors.push('fffReports.maxAllowed');
    }

    if (formValue.get('email').errors?.required) {
      this.errors.push('fffReports.required');
    }

    if (!formValue.get('reportType').valid) {
      this.errors.push('fffReports.reportTypeIssue');
    }
    if (
      !formValue.get('toDate').valid ||
      !formValue.get('fromDate').valid ||
      formValue.errors?.invalidDate
    ) {
      this.errors.push('fffReports.dateIssue');
    }
    if (statusList.length === 0) {
      this.errors.push('fffReports.statusIssue');
    }
  }

  close() {
    this.modalService.dismiss();
  }
}
