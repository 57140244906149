import { Component, OnInit } from '@angular/core';
import { FffDrawerService } from 'src/app/shared/drawer/fff-drawer.service';


@Component({
  selector: 'fff-facet-filter-drawer-header',
  templateUrl: './fff-facet-filter-drawer-header.component.html',
})
export class FffFacetFilterDrawerHeaderComponent implements OnInit {

  constructor(
    private drawerService: FffDrawerService,

  ) { }

  ngOnInit(): void {
  }

  public closeDrawer(): void {
    this.drawerService.closeDrawer();
  }
}

