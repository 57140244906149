import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { FffSplideModule } from '@app/shared/components/splide/splide.module';
import {
  CmsConfig,
  I18nModule,
  UrlModule,
  provideConfig,
} from '@spartacus/core';
import { MediaModule, RoutingModule } from '@spartacus/storefront';
import { FFFRecommendedProductsComponent } from './fff-recommended-products/fff-recommended-products.component';

@NgModule({
  declarations: [FFFRecommendedProductsComponent],
  imports: [
    CommonModule,
    HttpClientModule,
    FffSplideModule,
    I18nModule,
    RouterModule,
    UrlModule,
    RoutingModule,
    MediaModule,
  ],
  providers: [
    provideConfig(<CmsConfig>{
      cmsComponents: {
        FFFCheckoutProductRecommendationsComponent: {
          component: FFFRecommendedProductsComponent,
        },
      },
    }),
  ],
})
export class FffRecommendedProductsModule {}
