import { NgModule } from '@angular/core';
import { customIconConfig } from '@app/fff-config/icon/icon.config';
import { customLayoutConfig } from '@app/fff-config/layout/layout.config';
import { customPaginationConfig } from '@app/fff-config/pagination/pagination.config';
import { translationChunksConfig, translations } from '@spartacus/assets';
import {
  FeaturesConfig,
  I18nConfig,
  OccConfig,
  RoutingConfig,
  provideConfig,
} from '@spartacus/core';
import { defaultB2bOccConfig } from '@spartacus/setup';
import {
  defaultCmsContentProviders,
  layoutConfig,
  mediaConfig,
} from '@spartacus/storefront';
import { environment } from 'src/environments/environment';

@NgModule({
  declarations: [],
  imports: [],
  providers: [
    provideConfig(layoutConfig),
    provideConfig(mediaConfig),
    ...defaultCmsContentProviders,
    provideConfig(defaultB2bOccConfig),
    // provideConfig(defaultB2bCheckoutConfig),
    provideConfig(customLayoutConfig),
    provideConfig(customIconConfig),
    provideConfig(customPaginationConfig),
    provideConfig(<OccConfig>{
      backend: {
        occ: {
          baseUrl: environment.occBaseUrl,
        },
      },
      asm: {
        agentSessionTimer: {
          startingDelayInSeconds: 3600,
        },
      },
    }),
    provideConfig(<I18nConfig>{
      i18n: {
        resources: translations,
        chunks: translationChunksConfig,
        fallbackLang: 'en',
      },
    }),
    provideConfig(<FeaturesConfig>{
      features: {
        level: '4.2',
      },
    }),
    provideConfig(<RoutingConfig>{
      routing: {
        routes: {
          ssoLogin: {
            paths: ['sso'],
            authFlow: true,
          },
          prebookCategories: {
            paths: ['categories'],
            protected: false,
          },
        },
      },
    }),
  ],
})
export class SpartacusConfigurationModule {}
