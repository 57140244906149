import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Favorite } from '@app/models/fff-favorites.model';
import { ToastService } from '@app/shared/services/toast.service';
import { FffDrawerService } from '@shared/drawer/fff-drawer.service';
import { OccEndpointsService, TranslationService, UserIdService } from '@spartacus/core';
import { BehaviorSubject, Observable, Subject, combineLatest, merge } from 'rxjs';
import {
  exhaustMap,
  map,
  shareReplay,
  switchMap,
  take,
  tap
} from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class FffFavoritesService {

  favoriteAddedSubject$: Subject<any> = new Subject<any>();
  removeFromFavorites$: Subject<any> = new Subject<string>();
  loadListOfFavorites$: BehaviorSubject<any> = new BehaviorSubject<string>('');

  listOfFavorites$: Observable<Favorite[]> = merge(
    this.loadListOfFavorites$,
    this.favoriteAddedSubject$,
    this.removeFromFavorites$
  )
    .pipe(
      switchMap(() => this.getListOfFavorites()),
      shareReplay({
        bufferSize: 1,
        refCount: true
      })
    );

  constructor(
    protected http: HttpClient,
    protected occEndpoints: OccEndpointsService,
    protected drawerService: FffDrawerService,
    protected translation: TranslationService,
    private userIdService: UserIdService,
    protected toastService: ToastService
  ) {}

  addToFavorites(productCode: string, productName: string): void{
    this.userIdService.getUserId().pipe(
      exhaustMap((userId) => {
        const url = this.occEndpoints.buildUrl(`/account/favorites/add/${productCode}`, {
          queryParams: { userId },
        });
        return this.http.post(url, {});
      }),
      switchMap(() => {
        return combineLatest([
          this.translation.translate('fffProduct.addedToFavorites'),
          this.translation.translate('fffProduct.addedProductToFavoritesMessage', { productName }),
        ]);
      }),
      map(([header, paragraph]) => {
        return { header, paragraph };
      }),
      tap(({ header, paragraph }) => {
        this.toastService.showSuccess('fffFavourite.favouriteAddedMsg', {
          delay: 3000,
          i18nParams: {
            productName: productName,
          },
        });
        this.favoriteAddedSubject$.next('');
      }),
      take(1)
    ).subscribe();
  }

  removeFromFavorites(productCode: string, productName: string): void{
    this.userIdService.getUserId().pipe(
      exhaustMap((userId) => {
        const url = this.occEndpoints.buildUrl(`/account/favorites/remove/${productCode}`, {
          queryParams: { userId },
        });
        return this.http.delete(url, {});
      }),
      switchMap(() => {
        return combineLatest([
          this.translation.translate('fffProduct.removedFromFavorites'),
          this.translation.translate('fffProduct.removedProductFromFavoritesMessage', { productName }),
        ]);
      }),
      map(([header, paragraph]) => {
        return { header, paragraph };
      }),
      tap(({ header, paragraph }) => {
        this.toastService.showSuccess('fffFavourite.favouriteRemovedMsg', {
          delay: 3000,
          i18nParams: {
            productName: productName,
          },
        });
        this.removeFromFavorites$.next('');
      }),
      take(1)
    ).subscribe();
  }

  private getListOfFavorites(): Observable<Favorite[]> {
    const url = this.occEndpoints.buildUrl('/account/favorites/', {
      queryParams: { page: 1, pageSize: 1000 },
    });
    return this.http.get(url, {}).pipe(
      map((favoriteInfo: any) => {
        return favoriteInfo?.entries ? favoriteInfo.entries : [];
      })
    );
  }

  loadListOfFavorites(): void {
    this.loadListOfFavorites$.next('');
  }

  openDrawer(title: string, paragraph: string) {
    this.drawerService.setContent({
      title: title,
      paragraph: paragraph,
      animation: 'SideRTL',
    });
    this.drawerService.openDrawer();
  }
}
