import { Component, OnInit } from '@angular/core';
import { GlobalMessageService, GlobalMessageType } from '@spartacus/core';
import { UserAccountFacade } from '@spartacus/user/account/root';

@Component({
  selector: 'fff-navigation-bar',
  template: ''
})
export class FffNavigationBarComponent implements OnInit {

  constructor(private userAccountDetails: UserAccountFacade, private globalMessageService: GlobalMessageService) {
  }
  ngOnInit(): void {
    this.userAccountDetails
      .get()
      .subscribe((userData: any) => {
        if (userData && userData?.currentB2BUnit?.blockCode?.message) {
          this.globalMessageService.add(
            userData.currentB2BUnit.blockCode.message,
            GlobalMessageType.MSG_TYPE_ERROR
          );
        }
      });
  }

}
