import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FffCommunicationInterceptorService } from './fff-communication-interceptor.service';
import { FffCommunicationService } from './fff-communication.service';
import { FffSearchPageMetaResolver } from './fff-search-page-meta.resolver';
import { FffAuthenticationService } from './fff-user-authentication.service';
import { FffSiteContextService } from './fff-site-context.service';

@NgModule({
  declarations: [],
  imports: [CommonModule],
  providers: [
    FffCommunicationService,
    FffCommunicationInterceptorService,
    FffAuthenticationService,
    FffSiteContextService,
    FffSearchPageMetaResolver,
  ],
})
/**
 * Common Service Module
 */
export class FffCommonServicesModule {}
