<div class="modal-body">
  <div class="icon">
    <cx-icon [type]="OUTLINED_ICON_TYPE.ICON_INFO_CIRCLE"></cx-icon>
  </div>
  <div class="message">
    <ng-container>
      {{ 'fffPrebook.enterNotificationMessage' | cxTranslate : { season } }}
    </ng-container>
  </div>
</div>
<div class="modal-footer">
  <button
    class="btn btn-primary col-12"
    [disabled]="loading"
    (click)="acknowledge()"
  >
    {{ 'fffPrebook.acknowledgeAndProceed' | cxTranslate }}
  </button>
</div>
