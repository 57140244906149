<div class="card personalDetailsCard">
  <div class="card-header">
    <h4>
      {{
        'fffAccount.fffProfileManagement.reports.recurringreports' | cxTranslate
      }}
    </h4>
  </div>
  <div class="body-text">
    <div>
      <table *ngIf="reportCounts" class="reportCountTable">
        <thead>
          <tr>
            <th>
              {{
                'fffAccount.fffProfileManagement.reports.reportType'
                  | cxTranslate
              }}
            </th>
            <th class="text-right">
              {{
                'fffAccount.fffProfileManagement.reports.quantity' | cxTranslate
              }}
            </th>
          </tr>
        </thead>
        <tbody>
          <ng-container *ngIf="isBioSupply$ | async">
            <tr>
              <td>
                {{
                  'fffAccount.fffProfileManagement.reports.allocations'
                    | cxTranslate
                }}
              </td>
              <td class="text-right">
                {{ reportCounts.allocations ? reportCounts.allocations : 0 }}
              </td>
            </tr>
        </ng-container>
          <tr>
            <td>
              {{
                'fffAccount.fffProfileManagement.reports.orderHistory'
                  | cxTranslate
              }}
            </td>
            <td class="text-right">
              {{ reportCounts.orderHistory ? reportCounts.orderHistory : 0 }}
            </td>
            
          </tr>
          <tr>
            <td>
              {{
                'fffAccount.fffProfileManagement.reports.invoiceHistory'
                  | cxTranslate
              }}
            </td>
            <td class="text-right">
              {{ reportCounts.invoiceHistory ? reportCounts.invoiceHistory : 0 }}
            </td>
          </tr>
          <tr>
            <td>
              {{
                'fffAccount.fffProfileManagement.reports.prices' | cxTranslate
              }}
            </td>
            <td class="text-right">
              {{ reportCounts.prices ? reportCounts.prices : 0 }}
            </td>
          </tr>
        </tbody>
      </table>
      <div class="manage-reports-btn">
        <button class="btn btn-primary" (click)="goToReportsPage()">
          {{
            'fffAccount.fffProfileManagement.reports.managereports'
              | cxTranslate
          }}
        </button>
      </div>
    </div>
  </div>
</div>
