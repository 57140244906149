<div class="fff-select-credit-card__container">
  <h6 class="fff-invoice-payment-content-block__title mt-0 mb-2 fw-bold">
    {{ 'fffInvoicePayment.paymentMethod' | cxTranslate }}
  </h6>
</div>

<div
  class="fff-select-credit-card__list mb-4 mt-2"
  *ngIf="!loading; else loadingTemplate"
>
  <div
    class="fff-select-credit-card__item"
    *ngFor="let card of savedCards; let i = index"
  >
    <button
      type="button"
      class="fff-select-credit-card__action fff-select-credit-card__action--added-card"
      [ngClass]="{
        'fff-select-credit-card__action--selected':
          card.paymentUUID === (selectedCreditCard$ | async)?.paymentUUID &&
          card.cardToken === (selectedCreditCard$ | async)?.cardToken
      }"
      [disabled]="loading"
      (click)="selectCard(card)"
    >
      <div class="fff-select-credit-card__action-header">
        <span *ngIf="!card?.oneTimeUse" class="text-success">{{
          'fffInvoicePayment.saved' | cxTranslate
        }}</span>
        <span *ngIf="!!card?.oneTimeUse" class="text-danger">{{
          'fffInvoicePayment.oneTimeUse' | cxTranslate
        }}</span>
        <div
          class="fff-select-credit-card__action-cc-image fff-select-credit-card__action-cc-image--{{
            card.icon
          }}"
        ></div>
      </div>
      <div class="fff-select-credit-card__action-body">
        <span>{{
          card.cardToken | fffCreditCardMask : card?.cardType?.code
        }}</span>
      </div>
      <div class="fff-select-credit-card__action-footer">
        <span>{{ card.expiryMonth }}/{{ card.expiryYear }}</span>
      </div>
      <cx-icon
        class="cx-icon icon-check-circle-filled"
        [type]="outlineIcons.ICON_CHECK"
      ></cx-icon>
    </button>
    <div class="d-flex justify-content-between ml-2 mr-2">
      <div class="expired-font">
        <label *ngIf="isDateExpired(card.expiryMonth, card.expiryYear)">
          {{ 'fffAccount.fffProfileManagement.wallet.expired' | cxTranslate }}
        </label>
      </div>
      <div>
        <button
          type="button"
          class="btn btn-link p-0"
          *ngIf="!!card.defaultPayment"
        >
          {{ 'fffInvoicePayment.default' | cxTranslate }}
        </button>
        <button
          *ngIf="
            !card.defaultPayment &&
            !card.oneTimeUse &&
            !isDateExpired(card.expiryMonth, card.expiryYear)
          "
          type="button"
          class="btn btn-link p-0"
          [disabled]="loading"
          (click)="setAsDefault(card)"
        >
          {{ 'fffInvoicePayment.setDefault' | cxTranslate }}
        </button>
      </div>
    </div>
  </div>
  <div class="fff-select-credit-card__item">
    <button
      type="button"
      class="fff-select-credit-card__action fff-select-credit-card__action--new-card"
      [disabled]="loading"
      (click)="addNewCard()"
    >
      <div class="fff-cc-icon">
        <cx-icon [type]="outlineIcons.ICON_PLUS_CIRCLE"></cx-icon>
      </div>
      <div class="fff-cc-label">
        {{ 'fffInvoicePayment.useNewCard' | cxTranslate }}
      </div>
    </button>
  </div>
</div>
<ng-template #loadingTemplate>
  <fff-cart-loader [isCartItem]="true"></fff-cart-loader>
</ng-template>
