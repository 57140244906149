import { Injectable } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import {
  BASE_SITE_URLS,
  BASE_URL_KEYS,
} from '@app/fff-config/content/constants';
import { SsoEncDecPayload } from '@app/models/fff-crypto.model';
import { FffCryptoService } from '@app/shared/services/fff-crypto.service';
import { FffUserAccountService } from '@app/shared/services/fff-user-account.service';
import { Store } from '@ngrx/store';
import { AsmComponentService } from '@spartacus/asm/components';
import { AsmService } from '@spartacus/asm/core';
import {
  AsmAuthStorageService,
  AsmEnablerService,
  CsAgentAuthService,
} from '@spartacus/asm/root';
import {
  AuthActions,
  AuthRedirectService,
  AuthStorageService,
  AuthToken,
  BaseSiteService,
  OCC_USER_ID_ANONYMOUS,
  OCC_USER_ID_CURRENT,
  StateWithClientAuth,
  UserIdService,
} from '@spartacus/core';
import { BehaviorSubject, EMPTY, combineLatest } from 'rxjs';
import { map, switchMap, take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class FffSsoService {
  private ssoRouteUrlPath = '/sso';
  private isNavigating$ = new BehaviorSubject<boolean>(false);

  constructor(
    private sanitizer: DomSanitizer,
    private store: Store<StateWithClientAuth>,
    private userIdService: UserIdService,
    private userAccountService: FffUserAccountService,
    private authStorageService: AuthStorageService,
    private asmAuthStorageService: AsmAuthStorageService,
    private authRedirectService: AuthRedirectService,
    private baseSiteService: BaseSiteService,
    private fffCryptoService: FffCryptoService,
    private router: Router,
    private asmEnablerService: AsmEnablerService,
    private csAgentAuthService: CsAgentAuthService,
    private asmComponentService: AsmComponentService,
    protected asmService: AsmService
  ) {
  }

  isNavigating() {
    return this.isNavigating$.asObservable();
  }

  getAltOriginByType(type: string): string {
    let origin = location.origin;

    // For BioSupply, return MFV URL based on environment
    if (type.includes(BASE_URL_KEYS.BIOSUPPLY)) {
      if (origin.startsWith(BASE_SITE_URLS.PRODUCTION.BIOSUPPLY)) {
        // For production environment
        return BASE_SITE_URLS.PRODUCTION.MY_FLU_VACCINE;
      } else {
        // For rest all environments
        return origin.replace(/https:\/\/(biosupply)/i, 'https://mfv');
      }
    }

    // For MFV, return BioSupply URL based on environment
    if (type.includes(BASE_URL_KEYS.MY_FLU_VACCINE)) {
      if (origin.startsWith(BASE_SITE_URLS.PRODUCTION.MY_FLU_VACCINE)) {
        // For production environment
        return BASE_SITE_URLS.PRODUCTION.BIOSUPPLY;
      } else {
        // For rest all environments
        return origin.replace(
          /https:\/\/(mfv|ordermyflu)/i,
          'https://biosupply'
        );
      }
    }

    return '';
  }

  getAltSsoRedirectPageUrl(type: string) {
    let origin = this.getAltOriginByType(type);

    if (origin.endsWith('/')) {
      origin = origin.slice(0, -1);
    }

    return origin + this.ssoRouteUrlPath;
  }

  getSsoUrl(type: string) {
    let origin = this.getAltOriginByType(type);

    if (origin.endsWith('/')) {
      origin = origin.slice(0, -1);
    }

    return this.sanitizer.bypassSecurityTrustResourceUrl(
      origin + this.ssoRouteUrlPath
    );
  }

  getToken() {
    return this.authStorageService.getToken();
  }

  getJwtPayloadByAuthToken(authToken: AuthToken): SsoEncDecPayload {
    const {
      access_token,
      refresh_token,
      access_token_stored_at,
      expires_at,
      granted_scopes,
      token_type,
    } = authToken;
    return {
      access_token,
      refresh_token,
      grant_scopes: granted_scopes,
      token_type,
      iat: +access_token_stored_at,
      ...(expires_at && {
        exp: +expires_at,
      }),
    };
  }

  getAuthTokenByJwtPayload(payload: SsoEncDecPayload): AuthToken {
    const { access_token, refresh_token, iat, exp, grant_scopes, token_type } =
      payload;
    return {
      access_token: access_token ?? '',
      refresh_token: refresh_token ?? '',
      granted_scopes: grant_scopes,
      token_type: token_type ?? '',
      access_token_stored_at: (iat ?? '')?.toString(),
      expires_at: exp?.toString(),
    };
  }

  syncLoginStateWithJwtToken(
    payload: SsoEncDecPayload,
    redirectUrl: string = '/'
  ) {
    const authToken = this.getAuthTokenByJwtPayload(payload);

    let userId = OCC_USER_ID_CURRENT;
 
    if (this.asmEnablerService.isEnabled() && payload?.userId) {
        userId = payload.userId;
    } else {
      this.logoutAsmUser();
      this.hideAsmMainUi();
    }
    this.syncLoginState(authToken, userId, redirectUrl);
  }

  logoutAsmUser() {
    this.asmAuthStorageService.switchTokenTargetToUser(); 
    this.asmAuthStorageService.clearEmulatedUserToken(); 
    this.userIdService.setUserId(OCC_USER_ID_ANONYMOUS); 
    this.store.dispatch(new AuthActions.Logout()); 
  }
  
  hideAsmMainUi() {
    this.asmComponentService.unload();
  }

  syncAsmLoginState(userToken: AuthToken, userId:string) {
    this.asmAuthStorageService.switchTokenTargetToCSAgent();
    try {
      let customerId: string | undefined = OCC_USER_ID_ANONYMOUS;

      if (userId !== undefined && userToken !== undefined) {
        this.userIdService.setUserId(userId);
        this.authStorageService.setToken(userToken);
        this.store.dispatch(new AuthActions.Login());
      } else {
        // When we can't get the customerId just end all current sessions
        this.userIdService.setUserId(OCC_USER_ID_ANONYMOUS);
        this.asmAuthStorageService.clearEmulatedUserToken();
      }
    } catch {
      this.asmAuthStorageService.switchTokenTargetToUser();
    }
  }

  syncLoginState(
    userToken: AuthToken,
    userId: string = OCC_USER_ID_CURRENT,
    redirectUrl: string = '/'
  ) {
    this.authRedirectService.setRedirectUrl(redirectUrl);
    this.userIdService.setUserId(userId);

    if (this.asmEnablerService.isEnabled()) {
      this.syncAsmLoginState(userToken, userId);
    } else {
      this.authStorageService.setToken(userToken);
      this.store.dispatch(new AuthActions.Login());
    }
  }

  markAsNavigated() {
    setTimeout(() => {
      this.isNavigating$.next(false);
    }, 7000);
  }

  navigateToOtherSite(redirectBaseSite: string, target: string = '_self') {
    this.isNavigating$.next(true);
    let currentBaseSite: string = '';
    this.baseSiteService
      .getActive()
      .pipe(
        switchMap(baseSite => {
          currentBaseSite = baseSite;

          // If same site, redirect to homepage
          if (redirectBaseSite === currentBaseSite) {
            this.router.navigate(['/']);
            this.isNavigating$.next(false);
            return EMPTY;
          }

          return combineLatest([
            this.userAccountService
              .getProfile()
              .pipe(map(value => value?.user)),
            this.csAgentAuthService.isCustomerSupportAgentLoggedIn(),
            this.getToken().pipe(take(1)),
          ]);
        }),
        take(1)
      )
      .subscribe(([currentUser, isCustomerSupportAgentLoggedIn, authToken]) => {
        let altHomepageUrl = this.getAltOriginByType(currentBaseSite);

        if (this.asmEnablerService.isEnabled()) {
          altHomepageUrl += '?asm=true';
        }

        // If not logged-in, redirect to alt homepage
        if (!currentUser && !isCustomerSupportAgentLoggedIn) {
          this.openUrl(altHomepageUrl, target);
          return;
        }

        let userId: string = currentUser
          ? currentUser.uid
          : OCC_USER_ID_ANONYMOUS;

        // If logged-in, redirect to sso page with token
        let ssoRedirectPageUrl = this.getAltSsoRedirectPageUrl(currentBaseSite);
        const payload: SsoEncDecPayload =
          this.getJwtPayloadByAuthToken(authToken);
        payload.userId = userId;
        const token = this.fffCryptoService.encrypt(payload);
        ssoRedirectPageUrl += `?token=${token}`;
        if (this.asmEnablerService.isEnabled()) {
          ssoRedirectPageUrl += '&asm=true';
        } else {
          ssoRedirectPageUrl += '&asm=false';
        } 
        this.openUrl(ssoRedirectPageUrl, target);
        this.markAsNavigated();
      });
  }

  redirectToDynamicPageOnOtherSite(
    redirectUrl?: string,
    target: string = '_self'
  ) {
    this.isNavigating$.next(true);
    combineLatest([
      this.baseSiteService.getActive(),
      this.userAccountService.getProfile().pipe(map(value => value?.user)),
      this.getToken().pipe(take(1)),
    ])
      .pipe(take(1))
      .subscribe(([currentBaseSite, currentUser, authToken]) => {
        const ssoRedirectPageUrl =
          this.getAltSsoRedirectPageUrl(currentBaseSite);
        const payload: SsoEncDecPayload =
          this.getJwtPayloadByAuthToken(authToken);
        payload.userId = currentUser?.uid;

        const token = this.fffCryptoService.encrypt(payload);
        let url = `${ssoRedirectPageUrl}?token=${token}`;

        if (this.asmEnablerService.isEnabled()) {
          url += '&asm=true';
        }

        if (redirectUrl) {
          url += `&redirectUrl=${btoa(redirectUrl)}`;
        }

        this.openUrl(url, target);
        this.markAsNavigated();
      });
  }

  openUrl(url: string, target: string): void {
    try {
      const { hostname } = new URL(url);
      window.open(url, target);
    } catch {
      this.router.navigate([url]);
    }
  }
}
