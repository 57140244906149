import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Injector,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { FffAllocationService } from '@app/fff-enterprise/fff-common-services/fff-allocation.service';
import { FffCommunicationService } from '@app/fff-enterprise/fff-common-services/fff-communication.service';
import { FffPrebookService } from '@app/fff-enterprise/fff-common-services/fff-prebook.service';
import { FFFActiveCartService } from '@app/fff-enterprise/fff-custom-cart/fff-active-cart-service';
import { FffMiniCartContentComponent } from '@app/fff-enterprise/fff-header/fff-mini-cart/fff-mini-cart-content/fff-mini-cart-content.component';
import { FffMiniCartHeaderAddedComponent } from '@app/fff-enterprise/fff-header/fff-mini-cart/fff-mini-cart-header-added/fff-mini-cart-header-added.component';
import { FffPrebookCartService } from '@app/fff-enterprise/fff-prebook-category/services/fff-prebook-cart.service';
import {
  FFFOrderEntry,
  FFFProductInputEntry,
} from '@app/models/fff-cart-data.model';
import { OUTLINED_ICON_TYPE } from '@app/models/fff-outline-icons.model';
import { VACCINES_CATEGORY_TYPES } from '@app/models/fff-product.model';
import { FffProfile } from '@app/models/fff-profile.model';
import {
  ADD_PRODUCT_FOCUSED,
  ProductAdminSet,
  ProductFocusState,
  ProductSpecialPriceState,
  ProductStockState,
  ProductStrengthState,
} from '@app/reducers';
import { FFFCommonFunctions } from '@app/shared/fff-common-functions';
import { FffUserAccountService } from '@app/shared/services/fff-user-account.service';
import { BASE_URL_KEYS } from '@config/content/constants';
import { FffAddToCartNoticeComponent } from '@enterprise/fff-product/fff-add-to-cart-notice/fff-add-to-cart-notice.component';
import { FffB2bUnit } from '@model/fff-b2b-unit.model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { FffGlobalCartRestrictionsService } from '@shared/services/fff-global-cart-restrictions.service';
import { AddToCartComponent } from '@spartacus/cart/base/components/add-to-cart';
import {
  BaseSiteService,
  CmsAddToCartComponent,
  EventService,
  RoutingService,
  isNotUndefined,
} from '@spartacus/core';
import { CmsComponentData, CurrentProductService } from '@spartacus/storefront';
import { BehaviorSubject, Observable, Subject, Subscription } from 'rxjs';
import { filter, map, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { FffDrawerService } from 'src/app/shared/drawer/fff-drawer.service';
import { FffProductNotOrderableComponent } from '../fff-product-not-orderable/fff-product-not-orderable.component';

@Component({
  selector: 'fff-add-to-cart',
  templateUrl: './fff-add-to-cart.component.html',
})
export class FffProductAddToCartComponent
  extends AddToCartComponent
  implements OnInit, OnDestroy
{
  @Input() stockAndAlloc: any | undefined;
  @Input() product: any;
  @Output() cartErrorEventEmitter: EventEmitter<string> =
    new EventEmitter<string>();
  @Output() itemAddedToCart = new EventEmitter<boolean>();
  @Input() b2bunit: FffB2bUnit | undefined;
  @Input() pricesData: any;
  @Input() isPLPPage: boolean = false;
  unsubscribe$: Subject<boolean> = new Subject<boolean>();
  totalStock!: number;
  cart: any;
  stockData: any = {};
  readyToBuy: boolean = false;
  productEntries: any[] = [];
  account: any;
  currentActiveSite: string = '';
  globalCartRestriction$: Observable<boolean> = new Observable<boolean>();
  specialPriceSelected: any = {};
  productStrengthSelected: any = {};
  loading: boolean = false;
  preBookEnable: boolean = false;
  fluSeasonCurrent: string = '';

  rapidCommitIsSuperCat$: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);

  outlinedIconTypes = OUTLINED_ICON_TYPE;
  adminSetsData: any = {};
  // isSplitBillerAccount: boolean = false;
  profile: FffProfile | undefined;
  profile$: Observable<FffProfile | undefined> = this.fffAccountService
    .getProfile()
    .pipe(
      tap(user => {
        this.profile = user;
        this.cd.markForCheck();
      })
    );

  get isSplitBillerAccount(): boolean {
    return this.profile?.selected?.autoSplitBillerActive === true;
  }

  get isSplitBiller(): boolean {
    return !!this.b2bunit?.splitBiller;
  }

  get isDirectOrder(): boolean {
    return (
      FFFCommonFunctions.isAutoSplitBilling(
        this.b2bunit?.splitBiller || false,
        this.isSplitBillerAccount,
        this.product
      ) && this.pricesData?.prices?.length < 3
    );
  }

  constructor(
    private drawerService: FffDrawerService,
    protected mService: NgbModal,
    currentProductService: CurrentProductService,
    cd: ChangeDetectorRef,
    private fffActiveCartService: FFFActiveCartService,
    private store: Store<ProductFocusState>,
    private storeStock: Store<ProductStockState>,
    private baseSiteService: BaseSiteService,
    public communicationService: FffCommunicationService,
    private storeProductStrength: Store<ProductStrengthState>,
    private storeProductSpecialPrice: Store<ProductSpecialPriceState>,
    private globalCartRestrictionService: FffGlobalCartRestrictionsService,
    private storeAdminSet: Store<ProductAdminSet>,
    private fffAllocationService: FffAllocationService,
    private injector: Injector,
    private fffAccountService: FffUserAccountService,
    private preBookService: FffPrebookService,
    eventService: EventService,
    private routingService: RoutingService,
    component: CmsComponentData<CmsAddToCartComponent>,
    private fffPrebookCartService: FffPrebookCartService
  ) {
    super(
      currentProductService,
      cd,
      fffActiveCartService,
      component,
      eventService
    );

    this.store
      .select((state: any) => {
        return state.ProductsFocus;
      })
      .subscribe(focusState => {
        if (!focusState || !this.product || focusState.productList == null) {
          this.readyToBuy = false;
          return;
        }
        this.productEntries = focusState.productList.filter(
          (c: any) => c.code == this.product.code && c.quantity > 0
        );
        this.readyToBuy = this.productEntries.length > 0;
      });

    this.storeProductStrength
      .select((state: any) => {
        return state.ProductStrength;
      })
      .subscribe(productStrength => {
        if (
          productStrength &&
          productStrength.data[this.product?.code] &&
          productStrength.data[this.product?.code].data
        ) {
          this.productStrengthSelected =
            productStrength.data[this.product?.code].data;
        }
      });

    this.storeProductSpecialPrice
      .select((state: any) => {
        return state.ProductSpecialPrice;
      })
      .subscribe(specialPrice => {
        if (
          specialPrice &&
          specialPrice.data[this.product?.code] &&
          specialPrice.data[this.product?.code].data
        ) {
          this.specialPriceSelected =
            specialPrice.data[this.product?.code].data;
        }
      });
    this.storeAdminSet
      .select((state: any) => {
        return state.AdminSet;
      })
      .pipe(filter(isNotUndefined))
      .subscribe(adminSet => {
        this.adminSetsData = adminSet.data;
      });
    this.storeStock
      .select((state: any) => {
        return state.ProductStockState;
      })
      .subscribe(stockState => {
        if (stockState) {
          this.stockData = stockState.data;
        }
      });
  }
  cartSubscriptionRef: Subscription = new Subscription();
  ngOnInit() {
    super.ngOnInit();
    this.baseSiteService
      .getActive()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(site => {
        this.currentActiveSite = site;
      });
    this.rapidCommitIsSuperCat();

    this.globalCartRestriction$ =
      this.globalCartRestrictionService.getGlobalRestriction();

    this.communicationService.messageSource.subscribe(res => {
      this.cartSubscriptionRef = this.fffActiveCartService
        .getActive()
        .subscribe(res => {
          this.cart = res;
        });
    });
    this.profile$.pipe(takeUntil(this.unsubscribe$)).subscribe();

    this.parseStockResult(this.stockAndAlloc);
    this.cd.detectChanges();
  }

  invalidQtyAllocation(): boolean {
    if (!this.stockAndAlloc) {
      return false;
    }
    let cartQty = 0;
    let totalQuantity = 0;

    this.cart?.entries?.forEach((e: any) => {
      if (this.product.code === e.product?.code) {
        cartQty += parseInt(e.quantity!);
      }
    });

    this.productEntries?.forEach((e: any) => {
      totalQuantity += parseInt(e.quantity);
    });

    totalQuantity += cartQty;
    const allocation = this.stockAndAlloc;
    const hasMaterial = allocation.material;
    const uom = this.productEntries.find(
      (e: any) => e.code == this.product.code
    )?.uom;
    const allocCompareValue = this.fffAllocationService.transformUnits(
      this.fffAllocationService.getAllocationCompareValue(allocation),
      allocation,
      uom,
      this.pricesData.prices[0]
    );
    return !!(
      allocCompareValue !== null &&
      allocCompareValue >= 0 &&
      totalQuantity > 0 &&
      allocCompareValue < totalQuantity &&
      hasMaterial
    );
  }

  parseStockResult(stockResponse: any) {
    const stockAmounts = stockResponse
      ? stockResponse.results?.map((a: any) => a.zoverallStock)
      : [];
    this.totalStock = stockAmounts?.reduce(
      (acc: number, cur: string) => acc + Number(cur),
      0
    );
  }

  verifyCartRestrictions() {
    if (this.product && this.product.purchasableInfo) {
      if (!this.product.purchasableInfo.isScheduleValid) {
        this.cartErrorEventEmitter.emit('fffAddCart.schedule.unauthorized');
        return true;
      }
      if (this.currentActiveSite === BASE_URL_KEYS.MY_FLU_VACCINE) {
        if (this?.cart?.id) {
          if (!this.product.fluSeason || this.cart?.fluSeason === '') {
            return false;
          }
          if (this.product.fluSeason !== this.cart?.fluSeason) {
            return true;
          }
        } else {
          return false;
        }
      }
      if (
        (this.cart.rapidCommit &&
          !this.checkProductIsInRapidCommitCategory(this.product)) ||
        (this.cart?.entries?.length > 0 &&
          !this.cart.rapidCommit &&
          this.checkProductIsInRapidCommitCategory(this.product))
      ) {
        return true;
      }
      return false;
    }
    return true;
  }
  checkProductIsInRapidCommitCategory(product: any): boolean {
    if (!product) return false;
    if (!product.categories) return false;

    return product.categories.some((category: any) => {
      if (category.code === 'RAPIDCOMMIT') {
        return true;
      }
      return false;
    });
  }
  differentSeason() {
    return (
      this.product.fluSeason &&
      this.cart.fluSeason &&
      this.cart.fluSeason != this.product.fluSeason
    );
  }

  showProductNotOrderableComponent() {
    const modalRef = this.mService.open(FffProductNotOrderableComponent, {
      centered: true,
      size: 'md',
      backdrop: 'static',
      modalDialogClass: 'modal-container',
    });
  }

  showAddToCartNoticeComponent() {
    const modalRef = this.mService.open(FffAddToCartNoticeComponent, {
      centered: true,
      size: 'md',
      backdrop: 'static',
      modalDialogClass: 'modal-container',
    });
    modalRef.componentInstance.addToCartNotice = this.product.addToCartNotice;
    modalRef.result.then(data => {
      if (data && data.addToCart) {
        this.addToCart();
      }
    });
  }
  addToCart(): void {
    const testAddEntries: FFFOrderEntry[] = [];

    if (!this.productEntries || this.productEntries.length === 0) return;

    this.productEntries.forEach((e: any) => {
      const tieredPricing = this.pricesData.prices.find(
        (price: any) => e.unit === price.accountType || e.unit === 'AUTO'
      );
      let parsedTiers = tieredPricing?.price.toMatScale.results.map(
        (result: any) => {
          return {
            quantity: parseInt(result.scaleQty),
            price: result.scaleDiscAmt,
          };
        }
      );

      let entry: FFFProductInputEntry = {
        key: e.key,
        unit: e.unit,
        code: e.code,
        taxValue: e.taxValue,
        discountValue: e.discountValue,
        uom: e.uom,
        altUom: e.altUom,
        unitConversionFactor: e.unitConversionFactor,
        mduConversionFactor: e.mduConversionFactor,
        quantity: e.quantity,
        dropShip: this.product.dropShip,
        strength:
          this.productStrengthSelected &&
          this.productStrengthSelected.data &&
          this.productStrengthSelected.data.batchStrength
            ? this.productStrengthSelected.data.batchStrength
            : '',
        batch:
          this.specialPriceSelected &&
          this.specialPriceSelected.batch &&
          'undefined' !== this.specialPriceSelected.batch
            ? this.specialPriceSelected.batch
            : '',
        expirationDate:
          this.specialPriceSelected && this.specialPriceSelected.expirationDate
            ? this.specialPriceSelected.expirationDate
            : '',
        sapStockLevelStatus: e.sapStockLevelStatus,
        adminSet:
          this.adminSetsData &&
          this.adminSetsData[e.code] &&
          this.adminSetsData[e.code].data.adminSet
            ? this.adminSetsData[e.code].data.adminSet
            : false,
        yconnector:
          this.adminSetsData &&
          this.adminSetsData[e.code] &&
          this.adminSetsData[e.code].data.yConnector
            ? this.adminSetsData[e.code].data.yConnector
            : false,
        altUnitConversion: e.altUnitConversion,
        tieredPricing: parsedTiers,
        altDiscPrice: e.altDiscPrice,
        directOrder: this.isDirectOrder,
        minOrderQuantity: e.minOrderQuantity,
      };

      testAddEntries.push({
        key: e.key,
        quantity: e.quantity,
        accountType: e.unit,
        warehouse: this.getWarehouse(e),
        code: e.code,
        product: {
          code: e.code,
        },
        basePrice: {
          value: e.price,
        },
        taxValue: e.taxValue,
        discountValue: e.discountValue,
        uom: e.uom,
        altUom: e.altUom,
        dropShip: this.product.dropShip,
        mduConversionFactor: e.mduConversionFactor,
        unitConversionFactor: e.unitConversionFactor,
        sapStockLevelStatus: this.stockData[e.code]
          ? this.stockData[e.code].status
          : '',
        strength:
          this.productStrengthSelected &&
          this.productStrengthSelected.data &&
          this.productStrengthSelected.data.batchStrength
            ? this.productStrengthSelected.data.batchStrength
            : '',
        batch:
          this.specialPriceSelected &&
          this.specialPriceSelected.batch &&
          'undefined' !== this.specialPriceSelected.batch
            ? this.specialPriceSelected.batch
            : '',
        expirationDate:
          this.specialPriceSelected && this.specialPriceSelected.expirationDate
            ? this.specialPriceSelected.expirationDate
            : '',
        adminSet:
          this.adminSetsData &&
          this.adminSetsData[e.code] &&
          this.adminSetsData[e.code].data.adminSet
            ? this.adminSetsData[e.code].data.adminSet
            : false,
        yconnector:
          this.adminSetsData &&
          this.adminSetsData[e.code] &&
          this.adminSetsData[e.code].data.yConnector
            ? this.adminSetsData[e.code].data.yConnector
            : false,
        altUnitConversion: e.altUnitConversion,
        tieredPricing: parsedTiers,
        altDiscPrice: e.altDiscPrice,
        directOrder: this.isDirectOrder,
        minOrderQuantity: e.minOrderQuantity,
      });
      this.communicationService.sendUpdateCartMessage(e.code);
      this.cd.detectChanges();
      this.store.dispatch({
        type: ADD_PRODUCT_FOCUSED,
        payload: entry,
      });
    });

    this.loading = true;
    this.fffActiveCartService
      .getEntries()
      .pipe(
        take(1),
        map(entries => this.mapEntriesWithNewEntries(entries)),
        switchMap(() =>
          this.fffActiveCartService.addNewEntries(
            testAddEntries,
            this.unsubscribe$
          )
        )
      )
      .subscribe(() => {
        this.loading = false;
        this.cd.detectChanges();
        this.preBookService.getApplicationProperties().subscribe(data => {
          const appProperties = data;
          this.fluSeasonCurrent = data.mfvPrebookCurrentSeason;
          this.preBookEnable = data?.mfvRespiratoryPrebookFormEnabled == 'true';
          if (
            this.preBookEnable &&
            this.fluSeasonCurrent === this.product.fluSeason
          ) {
            this.fffActiveCartService
              .updateActiveCartPrebookFlag(true)
              .pipe(
                take(1),
                tap(() => {
                  this.routingService.go(['/categories'], {
                    queryParams: {
                      category: VACCINES_CATEGORY_TYPES.INFLUENZA,
                      prebook: true,
                    },
                  });
                })
              )
              .subscribe();
            return;
          }
          if (this.b2bunit && !this.b2bunit.jeffersonUnit) {
            this.openMiniCartDrawer();
          }
          this.itemAddedToCart.emit(true)
        });
      });
  }
  mapEntriesWithNewEntries(entries: FFFOrderEntry[]): FFFOrderEntry[] {
    let numberOfEntriesBeforeAdd = 0;
    entries.map(entry => (numberOfEntriesBeforeAdd += entry?.quantity!));
    return entries;
  }
  openMiniCartDrawer() {
    this.drawerService.setContent({
      drawerHeader: FffMiniCartHeaderAddedComponent as Component,
      animation: 'SideRTL',
      component: FffMiniCartContentComponent as Component,
      class: 'minicart-drawer',
    });
    this.drawerService.openDrawer();
  }
  invalidQuantities(): boolean {
    let disableButton = true;
    for (let index = 0; index < this.productEntries.length; index++) {
      const entry = this.productEntries[index];
      disableButton = entry.quantity <= 0 || entry.quantity < entry?.moq;
      if (disableButton) {
        return true;
      }
    }

    return disableButton;
  }

  invalidBatchQuantities(): boolean {
    let disableButton = false;
    if (this.specialPriceSelected && this.specialPriceSelected.batchStock) {
      let totalQuantity = 0;

      this.cart?.entries?.forEach((e: any) => {
        if (
          this.product.code === e.product?.code &&
          this.specialPriceSelected.batch == e.batch
        ) {
          totalQuantity += parseInt(e.quantity!);
        }
      });

      this.productEntries?.forEach((e: any) => {
        totalQuantity += parseInt(e.quantity);
      });

      for (let index = 0; index < this.productEntries.length; index++) {
        const entry = this.productEntries[index];
        if (entry.quantity && this.specialPriceSelected.batchStock) {
          disableButton =
            parseInt(entry.quantity) >
            parseInt(this.specialPriceSelected.batchStock);
          if (disableButton) {
            return true;
          }
        }
      }

      if (totalQuantity > this.specialPriceSelected.batchStock) {
        disableButton = true;
      }
    }

    return disableButton;
  }

  private totalInCartByStrength(strength: string): number {
    let totalInCart = 0;
    this.cart?.entries.forEach((e: any) => {
      if (this.product.code == e.product.code && strength == e.strength) {
        totalInCart += parseInt(e.quantity);
      }
    });
    return totalInCart;
  }

  isInvalidStrength(product: any): boolean {
    if (
      this.product?.requireBatchSelect &&
      (!this.specialPriceSelected ||
        !this.specialPriceSelected.batch ||
        this.specialPriceSelected.batch === 'undefined')
    ) {
      return true;
    }

    if (
      this.product?.assayIndicator &&
      this.productStrengthSelected?.data?.batchStrength &&
      this.specialPriceSelected.totalStockAvailable
    ) {
      let totalQuantity = this.totalInCartByStrength(
        this.productStrengthSelected?.data?.batchStrength
      );

      for (let index = 0; index < this.productEntries.length; index++) {
        const entry = this.productEntries[index];
        if (entry.quantity && this.specialPriceSelected.totalStockAvailable) {
          const disableButton =
            parseInt(entry.quantity) >
            parseInt(this.specialPriceSelected.totalStockAvailable);
          totalQuantity += parseInt(entry.quantity);
          if (disableButton) {
            return true;
          }
        }
      }

      if (totalQuantity > this.specialPriceSelected.totalStockAvailable) {
        return true;
      }
    }

    if (product.assayIndicator && this.productEntries) {
      const entriesWithoutStrength = this.productEntries.filter(
        (entry: any) => !entry.strength || '' === entry.strength
      );
      return entriesWithoutStrength.length > 0;
    }
    return false;
  }

  ngOnDestroy() {
    this.cartSubscriptionRef?.unsubscribe();
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();
  }

  getWarehouse(product: any) {
    const plantsAlloc = this.stockAndAlloc?.custToMatInv?.results[0];

    let plants = [];

    if (plantsAlloc) {
      plants = plantsAlloc.plants.split('|');
    } else {
      return product.warehouse.split('p_warehouse_')[1];
    }

    if (plantsAlloc.defaultPlant) {
      let f = plants.find((p: any) => plantsAlloc?.defaultPlant == p);
      return f ? f : plants[0];
    } else {
      return plants[0];
    }
  }

  isProductSoldOut() {
    return this.currentActiveSite === BASE_URL_KEYS.MY_FLU_VACCINE
      ? this.product.soldOut ||
          (this.product.showAsSoldOutIfExcluded &&
            this.pricesData?.prices?.some(
              (p: any) => p.showAsSoldOutIfExcluded
            ))
      : false;
  }

  rapidCommitIsSuperCat(): void {
    this.product.categories?.forEach((category: any) => {
      if (category.code === 'RAPIDCOMMIT') {
        this.rapidCommitIsSuperCat$.next(true);
      }
    });
  }

  rsvWithPrebookCart(): boolean {
    return this.preBookService.rsvWithPrebookCart(this.cart, this.product);
  }
}
