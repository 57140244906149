import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Route, Router } from '@angular/router';
import { FffOrderService } from '@app/fff-enterprise/fff-common-services/fff-order-service';
import { OUTLINED_ICON_TYPE } from '@app/models/fff-outline-icons.model';
import { FffDrawerService } from '@app/shared/drawer/fff-drawer.service';

@Component({
  selector: 'fff-order-history-filter',
  templateUrl: './fff-order-history-filter.component.html',
})
export class FffOrderHistoryFilterComponent implements OnInit {

  constructor(public drawerService: FffDrawerService , public orderService: FffOrderService , public activatedRoute: ActivatedRoute , private router: Router) { }

  outlinedIconTypes = OUTLINED_ICON_TYPE;

  code: any;
  fromDate: any;
  toDate: any;

  open: boolean = false;
  partiallyShipped: boolean = false;
  fullyShipped: boolean = false;

  requiresSignature: boolean = false;
  signed: boolean = false;

  ngOnInit(): void {

    let o = sessionStorage.getItem('orderHistoryFilter');
    let parsedFilter = o ? JSON.parse(o) : null;

    if(parsedFilter){
      this.open = parsedFilter.open;
      this.partiallyShipped = parsedFilter.partiallyShipped;
      this.code = parsedFilter.code;
      this.fromDate = parsedFilter.fromDate ? JSON.parse(parsedFilter.fromDate) : null;
      this.toDate = parsedFilter.toDate ? JSON.parse(parsedFilter.toDate) : null;
      this.requiresSignature = parsedFilter.requiresSignature;
      this.signed = parsedFilter.signed;
      this.fullyShipped = parsedFilter.fullyShipped;
    }

    if(this.activatedRoute.snapshot.queryParams['requiresSignature']){
      this.requiresSignature = true;
    }

  }

  isApplyDisabled(){

    if(this.fromDate && this.toDate){
      const toDateParsed = new Date(this.toDate.year, this.toDate.month - 1, this.toDate.day).getTime();
      const fromDateParsed = new Date(this.fromDate.year, this.fromDate.month - 1, this.fromDate.day).getTime();

      if(toDateParsed < fromDateParsed){
        return true;
      }
    }

    return false;

  }

  removeParameter(){
    this.router.navigate([], {
      queryParams: {
        'requiresSignature': null,
      },
      queryParamsHandling: 'merge'
    })
  }

  applyFilter(){

    let statuses = [];
    this.open ? statuses.push('Open') : null;
    this.partiallyShipped ? statuses.push('Partially Shipped') : null;
    this.fullyShipped ? statuses.push('Fully Shipped') : null;

    let csosOrderStatuses = [];
    this.requiresSignature ? csosOrderStatuses.push('OPEN') : null;
    this.signed ? csosOrderStatuses.push('COMPLETED') : null;

    let o = {
      code: this.code,
      startDate: this.parseDateToString(this.fromDate),
      endDate: this.parseDateToString(this.toDate),
      statuses: statuses.join(','),
      csosOrderStatuses: csosOrderStatuses.join(','),
    };

    let savedStatus = {
      open: this.open,
      partiallyShipped: this.partiallyShipped,
      fullyShipped: this.fullyShipped,
      requiresSignature: this.requiresSignature,
      signed: this.signed,
      code: this.code,
      fromDate: JSON.stringify(this.fromDate),
      toDate: JSON.stringify(this.toDate),
    };

    sessionStorage.setItem('orderHistoryFilter',JSON.stringify(savedStatus));
    this.drawerService.closeDrawer({ updated: true });
    this.orderService.sendFilterMessage(o);

  }

  ngOnDestroy(){
    this.orderService.sendFilterMessage({});
  }

  parseDateToString(d: any){
    if(!d){
      return;
    }
    return d.year.toString() + '-' + d.month.toString() + '-' + d.day.toString();
  }

}
