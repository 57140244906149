import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import { OUTLINED_ICON_TYPE } from '@model/fff-outline-icons.model';
import { UntypedFormBuilder, UntypedFormGroup, NgForm } from '@angular/forms';
import { FffDrawerService } from '@shared/drawer/fff-drawer.service';
import { FffOrderService } from '@enterprise/fff-common-services/fff-order-service';
import { take } from 'rxjs/operators';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'fff-returns-list-filter',
  templateUrl: './fff-returns-list-filter.component.html',
})
export class FffReturnsListFilterComponent implements OnInit {
  outlinedIconTypes = OUTLINED_ICON_TYPE;
  filtersForm: UntypedFormGroup = {} as UntypedFormGroup;
  constructor(
    private drawerService: FffDrawerService,
    private orderService: FffOrderService,
    private formBuilder: UntypedFormBuilder,
  ) {
  }
  ngOnInit() {
    this.filtersForm = this.formBuilder.group({
      code: [''],
      progress: [''],
      complete: [''],
      startDate: [''],
      endDate: [''],
    });
    this.drawerService.getContent().pipe(take(1)).subscribe((res: any)=> {
      if(Object.keys(res.data).length > 0) {
        this.filtersForm.setValue(res.data);
      }
    });
  }
  applyFilters(form: UntypedFormGroup) {
    const params = this.mapFormValuesIntoQueryParams(form);
    this.orderService.sendFilterMessage({ raw: form.value, params: params });
    this.drawerService.closeDrawer();
  }
  mapFormValuesIntoQueryParams(form: UntypedFormGroup): any {
    const statuses: any[] = [];

    if(form.get('progress')?.value && form.get('progress')?.value !== '') {
      statuses.push('In Process');
    }
    if(form.get('complete')?.value && form.get('complete')?.value !== '') {
      statuses.push('Complete');
    }
    let formValues = {
      code: form.get('code')?.value,
      statuses: statuses,
      startDate: this.mapNgbDateStructToDateString(form.get('startDate')?.value),
      endDate: this.mapNgbDateStructToDateString(form.get('endDate')?.value),
    };

    if(!formValues.code)
      delete formValues.code;

    if(!formValues.startDate)
      delete formValues.startDate;

    if(!formValues.endDate)
      delete formValues.endDate;

    return formValues;
  }
  mapNgbDateStructToDateString(date: NgbDateStruct): string | undefined {
    if(!date)
      return undefined;

    return date.year + '-' + date.month + '-' + date.day;
  }
}
