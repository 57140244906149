import { Component, OnInit } from '@angular/core';
import { FffCommunicationService } from '@app/fff-enterprise/fff-common-services/fff-communication.service';
import { AuthService } from '@spartacus/core';
import { Observable } from 'rxjs';
import { FffDrawerService } from 'src/app/shared/drawer/fff-drawer.service';
import { OUTLINED_ICON_TYPE } from '../../../models/fff-outline-icons.model';
import { FffMyAccountDrawerBodyComponent } from '../fff-my-account/fff-my-account-drawer-body.component';
import { FffMyAccountDrawerHeaderComponent } from '../fff-my-account/fff-my-account-drawer-header.component';

@Component({
  selector: 'fff-top-bar',
  templateUrl: 'fff-top-bar.component.html',
})

export class FffTopBarComponent implements OnInit {
  userLoggedIn$: Observable<boolean> | undefined;
  outlinedIconTypes = OUTLINED_ICON_TYPE;
  notifications$ = this.communicationService.getNotifications();

  constructor(
    private auth: AuthService,
    private drawerService: FffDrawerService,
    private communicationService: FffCommunicationService
  ) { }

  ngOnInit(): void {
    this.userLoggedIn$ = this.auth.isUserLoggedIn();
  }

  openDrawer() {
    this.drawerService.setContent({
      drawerHeader: FffMyAccountDrawerHeaderComponent as Component,
      animation: 'SideRTL',
      component: FffMyAccountDrawerBodyComponent as Component,
    });
    this.drawerService.openDrawer();
  }

  fetchMedals(notifications: any): number {
    return notifications?.totalCount ?? 0;
  }
  
}
