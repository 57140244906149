export const fffAccount = {
  fffAccount: {
    myAccount: 'My Account',
    contactPhone: '800-843-7477',
    back: 'Back',
    fffChangeAccount: {
      title: 'Change Account',
      searchByAccount: 'Search by Account Name or Number',
      account: 'Account',
      name: 'Name',
      terms: 'Terms',
      notes: 'Notes',
      apply: 'Apply',
      address: 'Address',
      attentionName: 'Attention Name',
      greetings: 'Welcome',
      warningModal: {
        title: 'Warning',
        message:
          'The products in your cart will be removed when switching accounts. Are you sure you would like to proceed?',
        proceed: 'Proceed',
      },
      noAccountsFound: 'No accounts to display.',
      searchToFindAnAccount: 'Please search for an account',
    },
    fffProfileManagement: {
      hi: 'Hi,',
      associatedAccounts: 'Associated Accounts:',
      changeAccount: 'Change Account',
      splitBilling: 'Split Billing',
      splitBillingAuto: 'Auto',
      splitBillingManual: 'Manual',
      personalDetails: 'Personal Details',
      update: 'Update',
      accountDetails: 'Account Details',
      name: 'Name',
      gpo: 'GPO',
      address: 'Address',
      billing: 'Billing',
      shipping: 'Shipping',
      tradingPartner: 'Trading Partner',
      requestChangeAddress: 'Request Address Change',
      subscribeBioVision: 'Subscribe to BioVsion Reports',
      subscribeReports: 'Subscribe to Reports',
      reports: {
        recurringreports: 'Recurring Reports',
        reportType: 'Report type',
        quantity: 'Qty',
        allocations: 'Allocations',
        orderHistory: 'Order History',
        invoiceHistory: 'Invoice History',
        prices: 'Prices',
        managereports: 'Manage Reports',
      },
      csos: {
        deaLicense: 'DEA',
        stateLicense: 'STATE',
        csos: 'CSOS',
        actions: {
          verifyUserId: 'Verify User ID',
          verifyAccount: 'Verify Account',
          manageCertificate: 'Manage Certificate',
        },
        messages: {
          missingDEALicense: 'DEA license required for place CSOS orders',
          accountNeedVerification: 'Your account needs to be verified.',
          userIdNeedVerification: 'Your User ID needs to be verified.',
          yourLicenseLabel: 'Your CSOS license',
          yourLicenseLabelExp: 'Your CSOS license with',
          expiredOn: 'expired on',
          uploadNewCertificate: 'Please upload a new certificate below.',
          expiredMessage: 'Expired Contact Customer Service',
        },
      },
      updating: {
        error: 'There was an issue updating your profile.',
        success: 'Your profile has been updated successfully.',
      },
      wallet: {
        wallet: 'Wallet',
        savedCreditCards: 'Saved Credit Cards',
        addNewCard: 'Add New Card',
        addCreditCard: 'Add Credit Card',
        edit: 'EDIT',
        delete: 'DELETE',
        expired: 'EXPIRED',
        editCardDialog: {
          modalTitle: 'Edit Credit Card Information',
          saveChanges: 'Save Changes',
          cancel: 'Cancel',
          creditCardUpdatedConfirmation:
            '<strong>Success - </strong> Your credit card changes have been saved.',
        },
        deletePopup: {
          yesDelete: 'Yes delete',
          cancel: 'Cancel',
          confirmationMsg:
            'Are you sure you would like to delete you credit card ending in ',
          successMessageSplit1: 'Your credit card ending in ',
          successMessageSplit2: ' has been deleted.',
          success: 'Success - ',
        },
        creditCardAddedConfirmation:
          '<strong>Success - </strong> Your credit card ending in {{ cardToken }} has been added',
      },
    },
    fffRequestChangeAddress: {
      title: 'Request Address Change',
      shipping: 'Shipping',
      billing: 'Billing',
      currentShippinAdress: 'Current Shipping Address',
      address: 'Address',
      building: 'Building, Suite, ETC',
      optional: 'Optional',
      city: 'City',
      state: 'State',
      zipCode: 'Zip Code',
      phoneNumber: 'Phone Number',
      deaNumber: 'DEA Number',
      mustMatchShippingAddress: 'Must match shipping address',
      submitChangeRequest: 'Submit Change Request',
      enterAddressLine2: 'Enter Address Line 2',
      saveChanges: 'Save Changes',
    },
    fffUpdatePersonalInfo: {
      title: 'Update Personal Info',
      firstName: 'First Name',
      lastName: 'Last Name',
      phoneNumber: 'Phone Number',
      email: 'Email',
      changePassword: 'Change Password',
      currentPassword: 'Current Password',
      newPassword: 'Create New Password',
      confirmPassword: 'Confirm New Password',
      myAccountUpdatedConfirmation:
        '<strong>Success - </strong> Your personal details have been updated',
    },
    fffVerifyAccountPopup: {
      confirmationText: 'Your account has been verified successfully.',
      close: 'Close',
    },
    fffVerifyUserPopup: {
      confirmationText: 'Your User ID has been verified successfully.',
      close: 'Close',
    },
  },
};
