import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgbToastModule } from '@ng-bootstrap/ng-bootstrap';
import { IconModule } from '@spartacus/storefront';
import { FffGlobalToastsComponent } from './fff-global-toasts/fff-global-toasts.component';

@NgModule({
  declarations: [FffGlobalToastsComponent],
  imports: [CommonModule, NgbToastModule, IconModule],
  exports: [FffGlobalToastsComponent],
})
export class FffGlobalToastsModule {}
