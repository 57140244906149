import { HttpErrorResponse } from '@angular/common/http';
import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { GlobalMessageService, GlobalMessageType } from '@spartacus/core';
import { BehaviorSubject } from 'rxjs';
import { RegisterService } from '../fff-register.service';

@Component({
  selector: 'fff-account-confirmation',
  templateUrl: './fff-account-confirmation.component.html'
})
export class FffAccountConfirmationComponent {
  isLoading$ = new BehaviorSubject(false);
  validUser: boolean | undefined;
  formattingComplete: boolean;

  constructor(private activatedRoute: ActivatedRoute, private registerService: RegisterService, private globalMessageService: GlobalMessageService) {
    const encoded = encodeURIComponent(this.activatedRoute.snapshot.queryParams['token']);
    this.formattingComplete = false;
    this.registerService.verifyUserEmail(encoded)
      .subscribe((res: any) => {
        if (res) {
          this.validUser = true;
          this.formattingComplete = true;
        } else {
          this.validUser = false;
          this.formattingComplete = true;
        }
      },
        (error: HttpErrorResponse) => { this.handleErrors(error); }
      );
  }

  handleErrors(error: HttpErrorResponse) {
    this.formattingComplete = true;
    this.globalMessageService.add(
      { key: 'httpHandlers.genericError' },
      GlobalMessageType.MSG_TYPE_ERROR
    );
  }
}
