import { Injectable } from '@angular/core';
import { FffB2bUnit } from '@app/models/fff-b2b-unit.model';
import { FffCartModifications } from '@app/models/fff-cart-modification.model';
import { FffGTMData } from '@app/models/fff-gtm-data.model';
import { FffProfile } from '@app/models/fff-profile.model';
import { OrderEntry } from '@spartacus/cart/base/root';
import {
  AuthService, Breadcrumb, createFrom,
  EventService, LogoutEvent, ProductSearchPage, ProductSearchService
} from '@spartacus/core';
import { NavigationEvent } from '@spartacus/storefront';
import { Observable } from 'rxjs';
import { filter, map, pairwise } from 'rxjs/operators';
import {
  FffCartAddEntrySuccessEvent,
  FffCartRemoveEntrySuccessEvent,
  FffFacetChangedEvent,
  FffLoginEvent,
  FffLogoutEvent,
  FffNavigationEvent,
  FffOrderPlacedEvent, FffUserAccountChangedEvent, FffUserAccountChangeEvent
} from './gtm.event';

@Injectable({
  providedIn: 'root',
})
export class FffGtmEventService {
  protected recentlyLoggedIn: boolean = false;
  protected facetToggled: Breadcrumb | null = null;
  protected gtmData: FffGTMData = {
    name: '',
    number: '',
    terms: '',
  };
  protected localData: FffGTMData = {
    name: '',
    number: '',
    terms: '',
  };

  protected unregister1: any;
  protected unregister2: any;
  protected unregister3: any;
  protected unregister4: any;

  constructor(
    protected eventService: EventService,
    protected authService: AuthService,
    protected productSearchService: ProductSearchService)
  {
  }

  init(profile: FffProfile | undefined){
    if (profile){
      this.gtmData = this.parseGtmData(profile);
      this.register();

      if (this.isRecentlyLoggedIn()){
        this.userLoggedInEvent(this.gtmData);
        this.recentlyLoggedIn = false;
      }
    } else {
      this.cleanGTMData();
      this.unregister();
    }
  }

  setRecentlyLoggedIn(loggedIn: boolean){
    this.recentlyLoggedIn = loggedIn;
  }

  private isRecentlyLoggedIn(){
    return this.recentlyLoggedIn;
  }

  private parseGtmData(profile: FffProfile): FffGTMData{
    const gtmData: FffGTMData = {
      name: profile.selected.name ? profile.selected.name : '',
      number: profile.selected.uid ? profile.selected.uid : '',
      terms: profile.selected.paymentTermsDesc ? profile.selected.paymentTermsDesc : '',
    };
    this.localData = gtmData;
    return gtmData;
  }

  private register(){
    this.unregister1 = this.eventService.register(FffNavigationEvent, this.buildNavigation());
    this.unregister2 = this.eventService.register(FffLogoutEvent, this.buildLogout());
    this.unregister3 = this.eventService.register(FffUserAccountChangedEvent, this.buildUserAccountChanged());
    this.unregister4 = this.eventService.register(FffFacetChangedEvent, this.buildFacetChanged());
  }

  registerFacetChangedEvent(){
    this.facetToggled = null;
  }

  private unregister(){
    if (this.unregister1) this.unregister1();
    if (this.unregister2) this.unregister2();
    if (this.unregister3) this.unregister3();
    if (this.unregister4) this.unregister4();
  }

  private buildNavigation(): Observable<FffNavigationEvent> {
    return this.eventService.get(NavigationEvent).pipe(
      map((event: NavigationEvent) => {
        return createFrom(FffNavigationEvent, {
          ...event,
          accountName: this.gtmData && this.gtmData.name ? this.gtmData.name : '',
          accountNumber: this.gtmData && this.gtmData.number ? this.gtmData.number : '',
          accountTerm: this.gtmData && this.gtmData.terms ? this.gtmData.terms : '',
        });
      })
    );
  }

  private buildLogout(): Observable<FffLogoutEvent> {
    return this.eventService.get(LogoutEvent).pipe(
      map(() => {
        const data = this.localData;
        return createFrom(FffLogoutEvent, {
          accountName: data && data.name ? data.name : '' ,
          accountNumber: data && data.number ? data.number : '' ,
          accountTerm: data && data.terms ? data.terms : '' ,
        });
      })
    );
  }

  private buildUserAccountChanged(): Observable<FffUserAccountChangedEvent> {
    return this.eventService.get(FffUserAccountChangeEvent).pipe(
      map((event: FffUserAccountChangeEvent) => {
        return createFrom(FffUserAccountChangedEvent, {
          ...event,
        });
      })
    );
  }

  private buildFacetChanged(): Observable<FffFacetChangedEvent | undefined>{

    return this.productSearchService.getResults().pipe(
      pairwise(),
      filter(([prev, curr]) => this.compareSearchResults(prev, curr)),
      map(([prev, curr]) => {
        const toggled =
          this.getToggledBreadcrumb(curr.breadcrumbs, prev.breadcrumbs) ||
          this.getToggledBreadcrumb(prev.breadcrumbs, curr.breadcrumbs);
        if (toggled) {
          return createFrom(FffFacetChangedEvent, {
            code: toggled.facetCode ? toggled.facetCode : '',
            name: toggled.facetName,
            valueCode: toggled.facetValueCode,
            valueName: toggled.facetValueName,
            selected: curr && prev && curr.breadcrumbs && prev.breadcrumbs
              ? curr.breadcrumbs.length > prev.breadcrumbs.length
              : false,
            accountName: this.gtmData && this.gtmData.name ? this.gtmData.name : '',
            accountNumber: this.gtmData && this.gtmData.number ? this.gtmData.number : '',
            accountTerm: this.gtmData && this.gtmData.terms ? this.gtmData.terms : '',
          });
        }
      })
    );
  }

  private compareSearchResults(
    prev: ProductSearchPage,
    curr: ProductSearchPage
  ): boolean {
    let result = false;
    if (prev && Object.keys(prev).length !== 0) {
      const sameFreeTextSearch = prev.freeTextSearch !== '' && prev.freeTextSearch === curr.freeTextSearch;
      let sameCategoryRoot = curr.breadcrumbs && prev.breadcrumbs &&
        prev.breadcrumbs[0]?.facetCode === curr.breadcrumbs[0]?.facetCode &&
        prev.breadcrumbs[0]?.facetValueCode === curr.breadcrumbs[0]?.facetValueCode;

      sameCategoryRoot = sameCategoryRoot ? true : false;
      result = sameFreeTextSearch || sameCategoryRoot;
    }
    return result;
  }

  private getToggledBreadcrumb(
    bc1: Breadcrumb[] | undefined,
    bc2: Breadcrumb[] | undefined
  ): Breadcrumb | undefined {
    if (bc1 && bc2 && bc1.length - bc2.length === 1) {
      return bc1.find(
        (x) =>
          !bc2.find(
            (y) =>
              y.facetCode === x.facetCode &&
              y.facetValueCode === x.facetValueCode
          )
      );
    }
    return undefined;
  }

  async addToCartAddEntrySuccessEvent(modifications: FffCartModifications, cartId: string){
    for (const modification of modifications.cartModifications) {
      const gtmEntry = new FffCartAddEntrySuccessEvent();
      gtmEntry.ecommerce = {
        currency: modification.entry?.basePrice?.currencyIso,
        value: modification.entry?.basePrice?.value,
        items: [{
          item_id: modification.entry?.product?.code,
          item_name: modification.entry?.product?.name,
        }],
      };

      this.eventService.dispatch(gtmEntry);
    };
  }

  removeEntrySuccessEvent(entry: OrderEntry){
    const gtmEntry = new FffCartRemoveEntrySuccessEvent();
    gtmEntry.ecommerce = {
      currency: entry?.basePrice?.currencyIso,
      value: entry?.basePrice?.value,
      items: [{
        item_id: entry?.product?.code,
        item_name: entry?.product?.name,
      }],
    };
    this.eventService.dispatch(gtmEntry);
  }

  userLoggedInEvent(gtmData: FffGTMData){
    const gtmLogin = new FffLoginEvent();
    gtmLogin.accountName = gtmData.name;
    gtmLogin.accountNumber = gtmData.number;
    gtmLogin.accountTerm = gtmData.terms;
    this.eventService.dispatch(gtmLogin);
  }

  userAccountChangedEvent(unit: FffB2bUnit){
    const gtmEntry = new FffUserAccountChangeEvent();
    gtmEntry.accountName = unit.name;
    gtmEntry.accountNumber = unit.uid;
    gtmEntry.accountTerm = unit.paymentTerms ? unit.paymentTerms : '';
    this.eventService.dispatch(gtmEntry);
  }
  placeOrder(orders: any, unit: FffB2bUnit){

    for (const record of orders) {
      const gtmEntry = new FffOrderPlacedEvent();
      gtmEntry.ecommerce = {
        currency: record.order.totalPrice.currencyIso,
        value: record.order.totalPrice.value,
        transaction_id: record.order.code,
        items: record.order.entries.map((entry: any) => {
          return {item_id : entry.product.code , item_name: entry.product.name }
        })
      }

      this.eventService.dispatch(gtmEntry);
    }
  }

  private cleanGTMData(){
    this.gtmData.name = '';
    this.gtmData.number = '';
    this.gtmData.terms = '';
  }
}
