<span *ngIf="profile">
  <span *ngIf="profile.accounts && profile.accounts.length === 1; else multiple">
    #{{ this.profile.selected.uid }}
  </span>
  <ng-template #multiple>
    <a (click)="openDrawer()" class="id" [class.single-account]="false">
      #{{ this.profile.selected.uid }}
    </a>
  </ng-template>

  <!-- <ng-container *ngIf="profile.selected?.uid">
    <a (click)="openDrawer()" class="id" [class.single-account]="false">
      #{{ this.profile.selected.uid }}
    </a>
  </ng-container> -->

  <!-- <div *ngIf="!isMobile" class="drawer-container">
    <fff-drawer #Drawer>
    </fff-drawer>
  </div> -->
</span>
