import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { FFFRecurringOrderEntry } from '@app/models/fff-cart-data.model';
import { OccEndpointsService } from '@spartacus/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class FffRecurringOrderDetailsService {
  editPOForm = new BehaviorSubject<UntypedFormGroup | undefined>(undefined);
  constructor(private http: HttpClient, private occ: OccEndpointsService) {}

  editRecurringOrderEntries(
    cartCode: string,
    cartUpdateEntries: FFFRecurringOrderEntry[]
  ) {
    let url = this.occ.buildUrl(`/orgUsers/current/carts/${cartCode}/entries/`);

    return this.http.patch(url, { cartUpdateEntries });
  }

  editRecurringOrderPOnumber(cartCode: string, poNumber: string) {
    let url = this.occ.buildUrl(
      `/orgUsers/current/carts/${cartCode}/updatePurchaseOrderNumber/`
    );

    return this.http.patch(url, { purchaseOrderNumber: poNumber });
  }
}
