import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import {
  FffGlobalBannerMessageComponent,
} from '@enterprise/fff-header/fff-global-banner-message/fff-global-banner-message.component';
import { IconModule } from '@spartacus/storefront';
import { FffProductMoreInfoModule } from '@enterprise/fff-product/fff-product-more-info/fff-product-more-info.module';
import { I18nModule } from '@spartacus/core';
import { EffectsModule } from '@ngrx/effects';
import {
  GlobalBannerMessageEffect,
} from '@enterprise/fff-header/fff-global-banner-message/effects/fff-global-banner-message.effects';




@NgModule({
  declarations: [FffGlobalBannerMessageComponent],
  imports: [
    CommonModule,
    IconModule,
    FffProductMoreInfoModule,
    I18nModule,
    EffectsModule.forFeature([GlobalBannerMessageEffect]),
  ],
  exports: [FffGlobalBannerMessageComponent],
})
export class FffGlobalBannerMessageModule { }
