import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FffInputValidationUtilsModule } from '@app/shared/components/input-validation-utils/input-validation-utils.module';
import { FffTableModule } from '@app/shared/table/fff-table.module';
import { FffReturnDetailsComponent } from '@enterprise/fff-returns/fff-return-details/fff-return-details.component';
import { FffReturnSendRGACopyComponent } from '@enterprise/fff-returns/fff-return-send-rga-copy/fff-return-send-rga-copy.component';
import { FffReturnUserDetailsHeaderComponent } from '@enterprise/fff-returns/fff-return-user-details-header/fff-return-user-details-header.component';
import { FffReturnsListFilterComponent } from '@enterprise/fff-returns/fff-returns-filter/fff-returns-list-filter.component';
import { FffReturnsFooterComponent } from '@enterprise/fff-returns/fff-returns-footer/fff-returns-footer.component';
import { NgbDatepickerModule } from '@ng-bootstrap/ng-bootstrap';
import { CmsConfig, I18nModule, provideDefaultConfig } from '@spartacus/core';
import {
  FormErrorsModule,
  IconModule,
  MediaModule,
  SpinnerModule,
} from '@spartacus/storefront';
import { FffReturnsConfirmationComponent } from './fff-returns-confirmation/fff-returns-confirmation.component';
import { FffReturnsEntryComponent } from './fff-returns-entry/fff-returns-entry.component';
import { FffReturnsErrorRequestPopupComponent } from './fff-returns-error-request-popup/fff-returns-error-request-popup.component';
import { FffReturnsListComponent } from './fff-returns-list/fff-returns-list.component';
import { FffReturnsRequestComponent } from './fff-returns-request/fff-returns-request.component';
import { FFFLoadersModule } from "../../shared/components/fff-loaders/fff-loaders.module";

@NgModule({
  declarations: [
    FffReturnsListComponent,
    FffReturnsListFilterComponent,
    FffReturnUserDetailsHeaderComponent,
    FffReturnsRequestComponent,
    FffReturnsConfirmationComponent,
    FffReturnsEntryComponent,
    FffReturnsErrorRequestPopupComponent,
    FffReturnDetailsComponent,
    FffReturnSendRGACopyComponent,
    FffReturnsFooterComponent,
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    FffTableModule,
    SpinnerModule,
    I18nModule,
    IconModule,
    NgbDatepickerModule,
    MediaModule,
    FormErrorsModule,
    FffInputValidationUtilsModule,
    FFFLoadersModule
],
  exports: [
    FffReturnsErrorRequestPopupComponent,
    FffReturnsFooterComponent,
    FffReturnUserDetailsHeaderComponent,
    FffReturnsEntryComponent,
  ],
  providers: [
    provideDefaultConfig(<CmsConfig>{
      cmsComponents: {
        FFFReturnsListComponent: {
          component: FffReturnsListComponent,
        },
        FFFReturnRequestComponent: {
          component: FffReturnsRequestComponent,
        },
        FFFReturnConfirmationComponent: {
          component: FffReturnsConfirmationComponent,
        },
        FFFReturnDetailsComponent: {
          component: FffReturnDetailsComponent,
        },
        FFFReturnsFooterComponent: {
          component: FffReturnsFooterComponent,
        },
      },
    }),
  ],
})
export class FffReturnsModule {}
