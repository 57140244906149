import { Injectable } from '@angular/core';
import { FffContactMethod } from '@model/fff-contact-method.model';
import { Observable } from 'rxjs';
import { FffOrderService } from './fff-order-service';

@Injectable({
  providedIn: 'root',
})
export class FffOrderFacade {
  constructor(private fffOrderService: FffOrderService) {}

  getOrderDetails(orderId: string): Observable<any> {
    return this.fffOrderService.getOrderDetails(orderId);
  }

  getRecurringOrderDetails(cartCode: string): Observable<any> {
    return this.fffOrderService.getRecurringOrderDetails(cartCode);
  }

  skipRecurringOrder(cartCode: string): Observable<any> {
    return this.fffOrderService.skipRecurringOrder(cartCode);
  }

  cancelRecurringOrder(cartCode: string): Observable<any> {
    return this.fffOrderService.updateRecurringOrder(cartCode, 'CANCELLED');
  }
  activateOrInactivateRecurringOrder(
    cartCode: string,
    istoActivate: boolean
  ): Observable<any> {
    const actionCode = istoActivate ? 'ACTIVE' : 'INACTIVE';
    return this.fffOrderService.updateRecurringOrder(cartCode, actionCode);
  }

  resendEmail(orderId: string, emails?: string): Observable<any> {
    return this.fffOrderService.resendEmail(orderId, emails);
  }

  loadOrderList(
    userId: string,
    pageSize: number,
    currentPage: number,
    filters: any,
    account: string,
    orderType?: string
  ): Observable<any> {
    return this.fffOrderService.getOrderHistoryFiltered(
      filters,
      currentPage,
      pageSize,
      userId,
      account,
      orderType
    );
  }
  loadRecurringOrderList(
    userId: string,
    pageSize: number,
    currentPage: number,
    filters: any
  ) {
    return this.fffOrderService.getRecurringOrdersHistory(
      userId,
      pageSize,
      currentPage,
      filters
    );
  }
  placeOrder(
    cartId: string,
    termsChecked: boolean,
    checkoutData: any,
    shippingWindowsData: any,
    contactMethod?: string
  ): Observable<any> {
    return this.fffOrderService.placeOrder(
      cartId,
      termsChecked,
      checkoutData,
      shippingWindowsData,
      contactMethod
    );
  }

  createReplenishmentOrder(
    cartId: string,
    termsChecked: boolean,
    purchaseOrderNumber: string,
    checkoutData: any
  ): Observable<any> {
    return this.fffOrderService.createReplenishmentOrder(
      cartId,
      termsChecked,
      purchaseOrderNumber,
      checkoutData
    );
  }
  getContactMethods(): Observable<FffContactMethod[]> {
    return this.fffOrderService.getContactMethods();
  }
}
