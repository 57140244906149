import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { I18nModule } from '@spartacus/core';
import { SpinnerModule, IconModule } from '@spartacus/storefront';
import { FffNeedMoreDialogComponent } from './fff-product-allocation/fff-need-more-dialog/fff-need-more-dialog.component';
import { FffProductAllocationComponent } from './fff-product-allocation/fff-product-allocation.component';



@NgModule({
  declarations: [
    FffProductAllocationComponent,
    FffNeedMoreDialogComponent
  ],
  imports: [
    CommonModule,
    I18nModule,
    SpinnerModule,
    IconModule
  ],
  exports: [
    FffProductAllocationComponent
  ],
})
export class FffProductAllocationModule { }
