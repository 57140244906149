import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CmsConfig, ConfigModule, I18nModule } from '@spartacus/core';
import { EmptyContentComponent } from './empty-content.component';
import { OrderCutoffTimeComponent } from './order-cutoff-time/order-cutoff-time.component';
import { IconModule } from '@spartacus/storefront';



@NgModule({
  declarations: [
    OrderCutoffTimeComponent,
    EmptyContentComponent
  ],
  imports: [
    CommonModule,
    I18nModule,
    ConfigModule.withConfig({
      cmsComponents: {
        CMSTimeParagraphComponent: {
          component: OrderCutoffTimeComponent,
        },
      },
    } as CmsConfig),
    IconModule,
  ]
})
export class FffAddonsModule { }
