import { Component, Input, SimpleChanges } from '@angular/core';
import { getProductImage } from '@assets/utils/fff-product-utils';

@Component({
  selector: 'fff-products-loader',
  templateUrl: './fff-products-loader.component.html',
})
export class FFFProductsLoaderComponent {
  @Input()
  isPLPPage: boolean = false;
  @Input() product!: any;
  imageObject: any;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.product) {
      this.imageObject = getProductImage(this.product?.images);
    }
  }
}
