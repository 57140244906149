<div class="modal-body">
  <div class="icon">
    <cx-icon [type]="iconWarning"></cx-icon>
  </div>
  <div class="message">
    {{ messageSplit1 }}
    {{ season }}
    {{ messageSplit2 }}
    {{ season }}
    {{ messageSplit3 }}
  </div>
</div>
<div class="modal-footer">
  <button
    class="btn btn-primary col-12"
    [disabled]="loading"
    (click)="proceed()"
  >
    {{ proceedBtnText }}
  </button>

  <button class="btn btn-secondary col-12" (click)="close()">
    {{ cancelBtnText }}
  </button>
</div>
