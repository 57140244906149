import { VACCINES_CATEGORY_TYPES } from '@app/models/fff-product.model';

// Prebook Category's SEO Codes mapping is maintained below
export const PREBOOK_CATEGORIES_SEO_CODE_MAPPING: { [key: string]: string } = {
  [VACCINES_CATEGORY_TYPES.INFLUENZA]: 'VACCNVACCN000P2000',
  [VACCINES_CATEGORY_TYPES.COVID]: 'VACCNVACCN000P2300',
  [VACCINES_CATEGORY_TYPES.RSV]: 'VACCNVACCN000P2400',
};

// Maintain all other Category's SEO codes mapping here
export const CATEGORIES_SEO_CODE_MAPPING: { [key: string]: string } = {
  albumin: 'BIOLGALBUM000B1000',
  antithrombin: 'BIOLGANTHR',
  ancillaries: 'ANCILANCIL',
  biologics: 'BIOLG',
  coagulation: 'BIOLGCOAGU',
  'hyper-immune-globulin': 'BIOLGHYPER',
  'immune-globulin': 'BIOLGIMGLB',
  'controlled-substances': 'CNTRLSPHAR',
  'controlled-channel': 'CONTROLLEDCHANNEL',
  'other-pharmaceuticals': 'PHARMPHARM000P6000',
  'anti-viral': 'PHARMPHARM000P9000',
  phamaceuticals: 'PHARMSPHAR',
  'brand-pharmaceuticals': 'PHARMSPHAR000P3000',
  orthopedic: 'PHARMSPHAR000P3100',
  ophthalmology: 'PHARMSPHAR000P3300',
  dermatology: 'PHARMSPHAR000P3400',
  oncology: 'PHARMSPHAR000P7000',
  biosimilar: 'PHARMSPHAR000P7500',
  'womens-health': 'PHARMSPHAR000W1000',
  providegx: 'PROVIDEGX',
  'rapid-commit': 'RAPIDCOMMIT',
  biosurgical: 'SURGLSURGL000P8000',
  vaccines: 'VACCN',
  'vaccines-influenza': 'VACCNVACCN000P2000',
  'vaccines-pediatric': 'VACCNVACCN000P2100',
  'vaccines-adult': 'VACCNVACCN000P2200',
  'vaccines-covid': 'VACCNVACCN000P2300',
  'vaccines-rsv': 'VACCNVACCN000P2400',
};
