import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { Product, ProductService } from '@spartacus/core';
import { CurrentProductService } from '@spartacus/storefront';
import { BehaviorSubject, of } from 'rxjs';
import { FffCommunicationService } from 'src/app/fff-enterprise/fff-common-services/fff-communication.service';
@Component({
  selector: 'fff-product-extra-info',
  templateUrl: './fff-product-extra-info.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FffProductExtraInfoComponent implements OnInit {
  public isCollapsed = true;
  @Input()
  product!: Product;
  totalPurchase = 0;
  annualCommitment = 0;
  isBaseProduct$ = new BehaviorSubject([false, '']);
  isLoading$ = new BehaviorSubject(false);
  @Input()
  isLoggedIn: Boolean = true;

  constructor(
    protected productService: ProductService,
    protected communicationService: FffCommunicationService,
    protected currentProductService: CurrentProductService,
    private readonly cd: ChangeDetectorRef) { }

  ngOnInit(): void {

  }

  /**
   * Get product detail informations
   */
  getProductDetails() {
    if (!this.isCollapsed) {
      this.isLoading$.next(true);
      this.productService.get(this.product?.code!).pipe(
      ).subscribe((product: Product | undefined) => {
        if (!product) {return}
        if (product?.baseProduct) {
          const redirectCode = product.variantOptions ? product.variantOptions[0].code : '';
          this.isBaseProduct$.next([product.baseProduct, redirectCode!]);
          this.isBaseProduct$.complete();
          return this.productService.get(redirectCode!).pipe().subscribe();
        }
        this.isBaseProduct$.complete();
        this.product = product;
        if (product) {
          this.totalPurchase = this.product.customerAllocation?.consumedAllocation ? this.product.customerAllocation?.consumedAllocation : 0; // This should be total number purchased
          this.annualCommitment = this.product.customerAllocation?.annualTotalCollection ? this.product.customerAllocation?.annualTotalCollection : 0; // This should be annual commitment
        }
        this.isLoading$.next(false);
        this.cd.markForCheck();
        return of(product);
      });
    }

  }

}
