import { Component, Input } from '@angular/core';
import { FffInvoicePaymentService } from '@app/fff-enterprise/fff-common-services/fff-invoice-payment.service';
import { OUTLINED_ICON_TYPE } from '@app/models/fff-outline-icons.model';

@Component({
  selector: 'fff-invoice-payment-confirmation-entries',
  templateUrl: './fff-invoice-payment-confirmation-entries.component.html',
})
export class FffInvoicePaymentConfirmationEntriesComponent {
  @Input() invoices: any;
  successfulInvoiceData!: any[];
  failureInvoiceData!: any[];
  successfulInvoices!: any[];
  failureInvoices!: any[];
  totalAmountForSuccess: number = 0;
  totalAmountForFailure: number = 0;
  outlinedIconTypes = OUTLINED_ICON_TYPE;
  constructor(private fffInvoicePaymentService: FffInvoicePaymentService) {}

  ngOnInit() {
    this.loadInvoiceResponse();
  }

  loadInvoiceResponse(): void {
    // Fetch successful invoices
    this.fffInvoicePaymentService
      .getSuccessfulInvoices()
      .subscribe((successInvoices: any[]) => {
        this.successfulInvoiceData = successInvoices ?? [];
        this.fffInvoicePaymentService.setTotalSuccessTransactions(
          this.successfulInvoiceData.length
        );
        this.updateInvoices(true);
      });

    // Fetch failure invoices
    this.fffInvoicePaymentService
      .getFailureInvoices()
      .subscribe((failureInvoices: any[]) => {
        this.failureInvoiceData = failureInvoices ?? [];
        this.fffInvoicePaymentService.setTotalFailureTransactions(
          this.failureInvoiceData.length
        );
        this.updateInvoices(false);
      });
  }

  private updateInvoices(isFromSuccess: boolean): void {
    if (this.invoices && this.invoices.length > 0) {
      if (isFromSuccess) {
        // Handle successful invoices
        if (
          this.successfulInvoiceData &&
          this.successfulInvoiceData.length > 0
        ) {
          const successfulInvoiceNumbersSet = new Set(
            this.successfulInvoiceData.map(i => i.invoiceNumber)
          );
          this.successfulInvoices = this.invoices
            .filter((invoice: any) =>
              successfulInvoiceNumbersSet.has(invoice.invoiceNumber)
            )
            .map((invoice: any) => {
              // Find the corresponding transaction ID
              const transaction = this.successfulInvoiceData.find(
                t => t.invoiceNumber === invoice.invoiceNumber
              );
              return {
                ...invoice,
                transactionId: transaction ? transaction.transactionId : null,
              };
            });

          this.totalAmountForSuccess = this.successfulInvoices
            .map(v => +v.grandTotal || 0)
            .reduce((prev, curr) => prev + curr, 0);
        } else {
          this.successfulInvoices = [];
          this.totalAmountForSuccess = 0;
        }
      } else {
        // Handle failure invoices
        if (this.failureInvoiceData && this.failureInvoiceData.length > 0) {
          const failureInvoiceNumbersSet = new Set(
            this.failureInvoiceData.map(i => i.invoiceNumber)
          );
          this.failureInvoices = this.invoices.filter((invoice: any) =>
            failureInvoiceNumbersSet.has(invoice.invoiceNumber)
          );
          this.totalAmountForFailure = this.failureInvoices
            .map(v => +v.grandTotal || 0)
            .reduce((prev, curr) => prev + curr, 0);
        } else {
          this.failureInvoices = [];
          this.totalAmountForFailure = 0;
        }
      }
    } else {
      // Handle case where there are no invoices
      this.successfulInvoices = [];
      this.failureInvoices = [];
      this.totalAmountForSuccess = 0;
      this.totalAmountForFailure = 0;
    }
  }
}
