import { Component, Inject, OnInit } from '@angular/core';
import { OUTLINED_ICON_TYPE } from '@app/models/fff-outline-icons.model';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'fff-tiered-modal',
  templateUrl: './tiered-modal.component.html',
})
export class TieredModalComponent implements OnInit {

  constructor(@Inject('tieredPricing') public tieredPricing: any, public modalService: NgbActiveModal) { }

  outlinedIconTypes = OUTLINED_ICON_TYPE;

  ngOnInit(): void {

    this.tieredPricing.prices.forEach((element: any) => {
      this.getIntervalStrings(element.price);
    });

  }

  getIntervalStrings(account: any) {
    let numbers = account.toMatScale.results.map((result: any) => result.scaleQty);
    const intervals: any = [];

    for (let i = 0; i < numbers.length; i++) {
      //transform the string into a number
      numbers[i] = +numbers[i];
      //if it's the last number on the list, add 'n+'
      if (i === numbers.length - 1) {
        intervals.push(`${numbers[i]}+`);
        continue;
      }

      //if the difference between the next number and the current number is 1, add n
      if (numbers[i + 1] - numbers[i] === 1) {
        intervals.push(`${numbers[i]}`);
        continue;
      }

      else {
        intervals.push(`${numbers[i]} - ${numbers[i + 1] - 1}`);
      }

    }

    return intervals;

  }

  close(): void {
    this.modalService.close();
  }


}
