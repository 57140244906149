import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  FffReturn,
  FffReturnEntry,
  FffReturnEntryValidateResponse,
} from '@app/models/fff-return.model';
import { OccEndpointsService } from '@spartacus/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class FFFReturnsService {
  private emitFETReturnEntry = new Subject<FffReturnEntry>();
  private emitFETReturnsFormReset = new Subject<boolean>();
  private emitTriggeredSubmitReturnRequest = new Subject<boolean>();
  public emitProductQuantitiesData = new BehaviorSubject<any>({});

  getEmitFETReturnEntry(): Observable<FffReturnEntry> {
    return this.emitFETReturnEntry.asObservable();
  }
  emitReturnEntry(entry: FffReturnEntry): void {
    this.emitFETReturnEntry.next(entry);
  }
  getEmitTriggeredSubmitReturnRequest(): Observable<boolean> {
    return this.emitTriggeredSubmitReturnRequest.asObservable();
  }
  emitTriggeredSubmitValue(value: boolean): void {
    this.emitTriggeredSubmitReturnRequest.next(value);
  }
  getEmitFETReturnsFormReset(): Observable<boolean> {
    return this.emitFETReturnsFormReset.asObservable();
  }
  emitFetReturnsFormReset(value: boolean): void {
    this.emitFETReturnsFormReset.next(value);
  }
  getRequestHeaders(): HttpHeaders {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    return headers;
  }

  constructor(
    private http: HttpClient,
    protected occEndpoints: OccEndpointsService
  ) {}

  getReturns(userId: string, isFET?: boolean): Observable<FffReturn> {
    const returnType = isFET ? '?returnType=FET' : '';
    const url = `/orgUsers/${userId}/returns` + returnType;
    return this.http.get<FffReturn>(this.occEndpoints.buildUrl(url), {
      headers: this.getRequestHeaders(),
    });
  }

  validateBatchNumber(
    userId: string,
    batchId: string,
    quantity: number,
    product: string,
    account: string
  ): Observable<FffReturnEntryValidateResponse> {
    const url = `/orgUsers/${userId}/returns/validateBatch?batchId=${batchId}&quantity=${quantity}&product=${product}&account=${account}`;
    return this.http.get<FffReturnEntryValidateResponse>(
      this.occEndpoints.buildUrl(url),
      { headers: this.getRequestHeaders() }
    );
  }

  submitReturn(userId: string, returnData: FffReturn): Observable<any> {
    const url = `/orgUsers/${userId}/returns/submit`;
    return this.http.post(this.occEndpoints.buildUrl(url), returnData, {
      headers: this.getRequestHeaders(),
    });
  }
  validateFETBatchNumber(
    userId: string,
    batchId: string,
    quantity: number,
    product: string,
    account: string
  ): Observable<boolean> {
    const url = `/orgUsers/${userId}/returns/validateFET?batchId=${batchId}&quantity=${quantity}&product=${product}&account=${account}`;
    return this.http.get<boolean>(this.occEndpoints.buildUrl(url), {
      headers: this.getRequestHeaders(),
    });
  }

  submitFETReturn(userId: string, returnData: FffReturn): Observable<any> {
    const url = `/orgUsers/${userId}/returns/submitFET`;
    return this.http.post(this.occEndpoints.buildUrl(url), returnData, {
      headers: this.getRequestHeaders(),
    });
  }
}
