import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import {
  FffAddToFavoritesModule
} from '@enterprise/fff-favorites/shared-components/fff-add-to-favorites/fff-add-to-favorites.module';
import { FffTooltipModule } from '@shared/tooltip/tooltip.module';
import { CmsConfig, ConfigModule, I18nModule, UrlModule } from '@spartacus/core';
import { CarouselModule, IconModule, MediaModule, StarRatingModule } from '@spartacus/storefront';
import { FffProductAllocationModule } from '../fff-product-allocation/fff-product-allocation.module';
import { FffProductListItemModule } from '../fff-product-list-page/fff-product-list/fff-product-list-item/fff-product-list-item.module';
import { FffProductBadgeInfoModule } from '../fff-product/fff-product-badge-info/fff-product-badge-info.module';
import { FffProductModule } from '../fff-product/fff-product.module';
import { FffSplitBillingModule } from '../fff-product/fff-split-billing/fff-split-billing.module';
import { FffProductImagesComponent } from './fff-product-images/fff-product-images.component';
import { FffProductInfoComponent } from './fff-product-info/fff-product-info.component';
import { FffProductIntroComponent } from './fff-product-intro/fff-product-intro.component';
import { FffProductSummaryComponent } from './fff-product-summary/fff-product-summary.component';
import { FFFLoadersModule } from '@app/shared/components/fff-loaders/fff-loaders.module';

@NgModule({
  declarations: [FffProductImagesComponent, FffProductIntroComponent, FffProductSummaryComponent, FffProductInfoComponent],
  imports: [
    UrlModule,
    BrowserModule,
    CommonModule,
    I18nModule,
    IconModule,
    MediaModule,
    CarouselModule,
    StarRatingModule,
    FffProductListItemModule,
    FffProductBadgeInfoModule,
    FffProductAllocationModule,
    FffSplitBillingModule,
    FffProductModule,
    FffAddToFavoritesModule,
    FFFLoadersModule,
    ConfigModule.withConfig({
      cmsComponents: {
        ProductImagesComponent: {
          component: FffProductImagesComponent,
        },
        ProductIntroComponent: {
          component: FffProductIntroComponent,
        },
        ProductSummaryComponent: {
          component: FffProductSummaryComponent,
        },
        CMSTabParagraphContainer: {
          component: FffProductInfoComponent,
        },
      },
    } as CmsConfig),
    FffTooltipModule,
  ],
  providers: [],
})

export class FffProductDetailModule { }
