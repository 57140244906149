import { Injectable } from '@angular/core';
import { FFFCart } from "@model/fff-cart-data.model";
import { UserIdService } from '@spartacus/core';

import { Cart } from '@spartacus/cart/base/root';
import { Observable } from 'rxjs';
import { switchMap, withLatestFrom } from 'rxjs/operators';
import { FFFActiveCartService } from '../fff-custom-cart/fff-active-cart-service';
import { FffCartService } from './fff-cart-service';

@Injectable({
  providedIn: 'root',
})
export class FffCartFacade {

  constructor(
    private fffActiveCartService: FFFActiveCartService,
    protected userIdService: UserIdService,
    private fffCartService: FffCartService
  ) { }

  getOrRestoreCart(): Observable<Cart> {
    const user$ = this.userIdService.getUserId();
    return user$
      .pipe(
        switchMap(
          (userId) => {
            return this.fffCartService.getOrRestoreCart(userId);
          })
      );
  }

  addCartEntry(data: any, cart: any) {
    return this.fffCartService.addCartEntry(cart, data);
  }


  updateEntry(data: any, entryNumber: number): Observable<any> {
    const activeCart$ = this.fffActiveCartService.getActive();
    return activeCart$.pipe(
      withLatestFrom(this.userIdService.getUserId())
    ).pipe(
      switchMap(
        ([cart, userId]) => {
          return this.fffCartService.updateEntry(userId, cart.code!, entryNumber, data);
        })
    );
  }

  updateCartEntry(data: any, entryNumber: number, cartCode: string): Observable<any> {
    return this.fffCartService.updateEntry('current', cartCode, entryNumber, data);
  }

  deleteEntry(entryNumber: number | undefined): Observable<any> {
    const activeCart$ = this.fffActiveCartService.getActive();
    return activeCart$.pipe(
      withLatestFrom(this.userIdService.getUserId())
    ).pipe(
      switchMap(
        ([cart, userId]) => {
          return this.fffCartService.deleteEntry(userId, cart.code!, entryNumber!);
        })
    );
  }

  getActiveCart(): Observable<FFFCart> {
    return this.fffActiveCartService.getActive();
  }

  // Validate by cart id or current cart for user
  getActive(): Observable<any> {
    const activeCart$ = this.fffActiveCartService.getActiveCartId();
    return activeCart$.pipe(
      withLatestFrom(this.userIdService.getUserId())
    ).pipe(
      switchMap(
        ([cartCode, userId]) => {
          if (cartCode) {
            return this.fffCartService.getActiveCart(userId, cartCode);
          } else {
            return this.fffCartService.getActiveCart(userId, 'current');
          }
        })
    );
  }

  getCartById(cartId: string, userId: any): Observable<any> {
    return this.fffCartService.getActiveCart(userId, cartId);
  }

}
