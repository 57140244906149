import { ChangeDetectionStrategy, Component } from '@angular/core';
import { CmsNavigationComponent } from '@spartacus/core';
import {
  CmsComponentData,
  NavigationNode,
  NavigationService,
} from '@spartacus/storefront';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
@Component({
  selector: 'cx-navigation',
  templateUrl: './navigation.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FffNavigationComponent {
  node$: Observable<NavigationNode> = this.service.createNavigation(
    this.componentData.data$
  );

  name$: Observable<string | undefined> = this.componentData.data$.pipe(
    map(d => d?.navigationNode?.title)
  );

  styleClass$: Observable<string | undefined> = this.componentData.data$.pipe(
    map(d => d?.styleClass)
  );

  constructor(
    protected componentData: CmsComponentData<CmsNavigationComponent>,
    protected service: NavigationService
  ) {}
}
