import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FffDrawerComponent } from './fff-drawer.component';
import {
  NgbAccordionModule,
  NgbCollapseModule,
  NgbPopoverModule
} from '@ng-bootstrap/ng-bootstrap';
import { IconModule } from '@spartacus/storefront';
import { FffDrawerClickOutsideDirective } from './directives/fff-drawer-click-outside.directive';
import { I18nModule } from '@spartacus/core';

@NgModule({
  declarations: [
    FffDrawerComponent,
    FffDrawerClickOutsideDirective,
  ],
  exports: [FffDrawerComponent],
  imports: [
    CommonModule,
    NgbPopoverModule,
    IconModule,
    NgbCollapseModule,
    NgbAccordionModule,
    I18nModule,
  ],
})
export class FffDrawerModule {}
