<div class="container py-5">

  <div class="row register-container col-xs-12 col-md-8 d-flex justify-content-center align-items-center m-auto px-0">

    <div class="col-12 register-content register-background-content">

      <div class="confirmation">
        <div class="col-md-12 text-center">
          <cx-icon class="cx-icon icon-lg mb-4" [type]="outlinedIconTypes.ICON_ENVELOPE"></cx-icon>
          <h3 class="h3">{{ 'fffVerification.header' | cxTranslate }}</h3>
        </div>

        <div class="col-md-12 confirmation-text text-center m-auto">
          <p class="pb-4" [innerHTML]="'fffVerification.text1' | cxTranslate"></p>
          <span [innerHTML]="'fffVerification.text2' | cxTranslate"></span>
        </div>
      </div>

    </div>

  </div>

</div>