import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { fffTranslationsFactory } from '@assets/translations/fff-translations.factory';
import { NgbPopoverModule, NgbToastModule } from '@ng-bootstrap/ng-bootstrap';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import {
  ActiveCartService,
  CartAdapter,
  CartValidationAdapter,
  CartVoucherAdapter,
} from '@spartacus/cart/base/core';
import {
  OccCartAdapter,
  OccCartValidationAdapter,
  OccCartVoucherAdapter,
} from '@spartacus/cart/base/occ';
import { SavedCartService } from '@spartacus/cart/saved-cart/core';
import {
  AuthStatePersistenceService,
  CategoryPageMetaResolver,
  ConfigModule,
  I18nModule,
  OccCmsPageNormalizer,
  ProductPageMetaResolver,
  UrlModule,
  errorHandlers,
  provideConfig,
} from '@spartacus/core';
import {
  CmsPageGuard,
  CmsPageGuardService,
  NavigationService,
  OutletModule,
  PageLayoutModule,
  PageSlotModule,
  SeoMetaService,
  StorefrontComponentModule,
} from '@spartacus/storefront';
import { UserAccountModule } from '@spartacus/user';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AppInterceptor } from './app.interceptor';
import {
  customCartConfig,
  fffCartProviders,
} from './fff-config/cart/cart.config';
import { RefreshTokenExpiryHandler } from './fff-enterprise/fff-auth/user-auth/http-interceptors/refresh-token-expiry.handler';
import { CustomCategoryPageMetaResolver } from './fff-enterprise/fff-common-services/fff-category-page-meta-resolver';
import { CustomPageMetaResolver } from './fff-enterprise/fff-common-services/fff-product-page-meta-resolver';
import { FffSeoMetaService } from './fff-enterprise/fff-common-services/fff-seo-service';
import { FffSavedCartService } from './fff-enterprise/fff-common-services/saved-cart.service';
import { FFFCartEntryEffects } from './fff-enterprise/fff-custom-cart/fff-cart-entry.effect';
import { FffOccCartAdapter } from './fff-enterprise/fff-custom-cart/occ-cart.adapter';
import { FffEnterpriseModule } from './fff-enterprise/fff-enterprise.module';
import { FffNavigationService } from './fff-enterprise/fff-navigation/navigation/navigation.service';
import { FffPaymentsBridgeModule } from './fff-enterprise/fff-payments/fff-payments-bridge/fff-payments-bridge.module';
import { FffPrebookAlertNotificationModule } from './fff-enterprise/fff-prebook-category/fff-prebook-alert-notification/fff-prebook-alert-notification.module';
import { scrollPositionProvider } from './fff-enterprise/fff-providers/_index';
import { fffResolvers } from './fff-enterprise/fff-resolvers/_index';
import { FffScrollToTopModule } from './fff-enterprise/fff-scroll-to-top/fff-scroll-to-top.module';
import { FffSsoModule } from './fff-enterprise/fff-sso/fff-sso.module';
import { metaReducers, reducers } from './reducers';
import { FffGlobalToastsModule } from './shared/components/fff-global-toasts/fff-global-toasts.module';
import { FffAuthStatePersistenceService } from './shared/services/fff-auth-state-persistence.service';
import { FffCmsPageGuardService } from './shared/services/fff-cms-page-guard.service';
import { FffCmsPageGuard } from './shared/services/fff-cms-page.guard';
import { CustomOccCmsPageNormalizer } from './shared/services/fff-occ-cms-page-normalizer';
import { CoreModule } from './spartacus/core/core.module';
import { SpartacusModule } from './spartacus/spartacus.module';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    AppRoutingModule,
    UserAccountModule,
    StorefrontComponentModule,
    OutletModule,
    PageLayoutModule,
    PageSlotModule,
    EffectsModule.forRoot([]),
    SpartacusModule,
    CoreModule,
    ConfigModule.withConfigFactory(fffTranslationsFactory),
    // BrowserTransferStateModule,
    FffEnterpriseModule,
    FffPrebookAlertNotificationModule,
    StoreModule.forRoot(reducers, { metaReducers }),
    I18nModule,
    UrlModule,
    NgbPopoverModule,
    EffectsModule.forFeature([FFFCartEntryEffects]),
    FffSsoModule,
    FffPaymentsBridgeModule,
    FffScrollToTopModule,
    NgbToastModule,
    FffGlobalToastsModule,
  ],
  providers: [
    scrollPositionProvider,
    ...errorHandlers,
    ...fffResolvers,
    ActiveCartService,
    {
      provide: ProductPageMetaResolver,
      useClass: CustomPageMetaResolver,
    },
    {
      provide: CategoryPageMetaResolver,
      useClass: CustomCategoryPageMetaResolver,
    },
    {
      provide: SeoMetaService,
      useClass: FffSeoMetaService,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useExisting: AppInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useExisting: RefreshTokenExpiryHandler,
      multi: true,
    },
    ...fffCartProviders,
    provideConfig(customCartConfig),
    {
      provide: OccCmsPageNormalizer,
      useExisting: CustomOccCmsPageNormalizer,
      multi: false,
    },
    {
      provide: CartVoucherAdapter,
      useClass: OccCartVoucherAdapter,
    },
    {
      provide: CartAdapter,
      useClass: OccCartAdapter,
    },
    {
      provide: CartValidationAdapter,
      useClass: OccCartValidationAdapter,
    },
    {
      provide: CmsPageGuard,
      useClass: FffCmsPageGuard,
      multi: false,
    },
    {
      provide: CmsPageGuardService,
      useClass: FffCmsPageGuardService,
      multi: false,
    },
    {
      provide: NavigationService,
      useClass: FffNavigationService,
      multi: false,
    },
    {
      provide: AuthStatePersistenceService,
      useClass: FffAuthStatePersistenceService,
      multi: false,
    },
    {
      provide: SavedCartService,
      useClass: FffSavedCartService,
      multi: false,
    },
    {
      provide: OccCartAdapter,
      useClass: FffOccCartAdapter,
      multi: false,
    },
  ],

  bootstrap: [AppComponent],
})
export class AppModule {}
