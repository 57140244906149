import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, interval, Observable, of, Subject } from 'rxjs';
import { AuthService, CmsService, isNotUndefined, PageContext, PageType } from '@spartacus/core';
import { debounceTime, filter, map, shareReplay, switchMap, takeUntil } from 'rxjs/operators';
import { Router } from '@angular/router';

interface CutOffMessage {
  content: string;
  timeEnd: boolean;
}
@Injectable({
  providedIn: 'root',
})
export class FffDisplayShippingBannerService implements OnDestroy{
  private timeEndValueChanged: boolean = true;
  private startedCountdown = false;
  private countdownInterval: Observable<CutOffMessage> | undefined;
  private unsubscribe$: Subject<void> = new Subject<void>();
  private _shippingBannerIsDisplayed: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private section1A$ = this.cmsService.getPage(new PageContext('homepage', PageType.CONTENT_PAGE)).pipe(
    debounceTime(250),
    switchMap(page=> {
      if(page && page.slots && page.slots.Section1A && page.slots.Section1A.components) {
        const uid = page.slots.Section1A.components[0].uid;
        if(uid){
          return this.cmsService.getComponentData(uid, new PageContext('homepage', PageType.CONTENT_PAGE));
        }
        return of({ uid: undefined } );
      }
      return of({ uid: undefined } );
    }),
    filter(isNotUndefined)
  ).pipe(debounceTime(100));
  countdownDataEmitter: Subject<CutOffMessage> = new Subject<CutOffMessage>();
  constructor(private cmsService: CmsService) {
    this.section1A$.subscribe((value) => {
      if(value && value.uid){
        if(!this.startedCountdown) {
          this.startedCountdown = true;
          this.countdownCutoffTime(value);
          this._shippingBannerIsDisplayed.next(false);
        }
      }else {
        this.startedCountdown = false;
        this._shippingBannerIsDisplayed.next(true);
      }
    });
  }
  countdownCutoffTime(cmsComponentData: any){
    if(!this.countdownInterval) {
      this.countdownInterval = interval(1000).pipe(
        map((x) => {
          const data = this.calcTimeDiff(cmsComponentData);
          if(data.timeEnd !== this.timeEndValueChanged){
            this.timeEndValueChanged = data.timeEnd;
            this.startedCountdown = false;
            this.emitShippingBannerIsDisplayed(this.timeEndValueChanged);
          }
          return data;
        }),
        shareReplay(1)
      );
      this.countdownInterval.pipe(takeUntil(this.unsubscribe$)).subscribe((v) => this.countdownDataEmitter.next(v));
    }
  }
  getCountdownInterval(): Observable<CutOffMessage> {
    return this.countdownDataEmitter;
  }
  ngOnDestroy(){
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
  private calcTimeDiff(cmsComponentData: any): CutOffMessage {
    if(cmsComponentData.orderCutOffTime) {
      const orderCutOffTime = (cmsComponentData.orderCutOffTime).valueOf();
      const milliSecondsInASecond = 1000;
      const hoursInADay = 24;
      const minutesInAnHour = 60;
      const secondsInAMinute = 60;
      const timeDifference = orderCutOffTime - Date.now();

      const hoursToOrderCutOffTime = Math.floor(
        (timeDifference /
          (milliSecondsInASecond * minutesInAnHour * secondsInAMinute)) %
        hoursInADay
      );

      const minutesToOrderCutOffTime = Math.floor(
        (timeDifference / (milliSecondsInASecond * minutesInAnHour)) %
        secondsInAMinute
      );

      const secondsToOrderCutOffTime =
        Math.floor(timeDifference / milliSecondsInASecond) % secondsInAMinute;

      let newString = `<strong>${hoursToOrderCutOffTime}h ${minutesToOrderCutOffTime}m ${secondsToOrderCutOffTime}s</strong>`;
      return  {
        content: cmsComponentData.content?.replace('{0}', newString),
        timeEnd: this.timeEnded(hoursToOrderCutOffTime, minutesToOrderCutOffTime, secondsToOrderCutOffTime),
      };
    }

    return {
      content: cmsComponentData.content,
      timeEnd: false,
    };
  }

  timeEnded(hour: number, min: number, sec: number): boolean {
    if(hour < 0 && min < 0 && sec < 0) {
      return true;
    }
    return false;
  }
  emitShippingBannerIsDisplayed(value: boolean): void {
    this._shippingBannerIsDisplayed.next(value);
  }
  getShippingBannerIsDisplayed(): Observable<boolean> {
    return this._shippingBannerIsDisplayed;
  }
}
