<ng-container *ngIf="activeSite$ | async as activeSite">
  <ng-container *ngIf="profile$ | async as profile">
    <ng-container *ngIf="recurringOrderDetails$ | async as recurringOrder">
      <ng-container *ngIf="user$ | async as user">
        <div class="container">
          <fff-return-user-details-header
            [accountNumber]="'' + recurringOrder.unit.uid"
            [accountName]="recurringOrder.unit.name"
            [email]="profile.uid"
            [deliveryTerms]="recurringOrder.unit.deliveryTermsDesc"
            [paymentTerms]="recurringOrder.unit.paymentTermsDesc"
            [footer]="sectionTwo"
          ></fff-return-user-details-header>
          <div class="d-flex flex-wrap py-4">
            <div class="pl-0 col-12 col-lg-9">
              <div class="col-12 summary">
                <div class="row">
                  <div class="col-12">
                    <div class="header text-center">
                      <h3 class="h3">
                        {{
                          'fffCheckoutOrderConfirmation.recurringOrder.recurringOrderId'
                            | cxTranslate
                        }}:&nbsp;#{{ recurringOrder.code }}
                      </h3>
                    </div>
                  </div>
                </div>
                <ng-container *ngIf="isEditable">
                  <form *ngIf="editPOForm" [formGroup]="editPOForm">
                    <div class="row m-0">
                      <div class="col-lg-4 col-sm-12 pl-auto pl-lg-0">
                        <div class="d-flex flex-column">
                          <div class="d-flex justify-content-between">
                            <p class="mb-0 body-text-lg">
                              {{ 'fffCheckout.poNumber' | cxTranslate }} *
                            </p>
                          </div>
                          <input
                            class="form-control form-control-md"
                            type="text"
                            formControlName="poNumber"
                            (keyup)="onPOchange()"
                          />
                          <p class="mb-0 checkout-input-info-text">
                            <span *ngIf="poRules.startsWith"
                              >{{ 'fffCheckout.legend1' | cxTranslate }}
                              {{ maxLength }}
                              {{ 'fffCheckout.legend2' | cxTranslate }}
                              {{ formatStartingPOs(poRules.startsWith) }}.</span
                            >
                            <span *ngIf="!poRules.startsWith"
                              >{{ 'fffCheckout.legend3' | cxTranslate
                              }}{{ maxLength }}
                              {{ 'fffCheckout.legend4' | cxTranslate }}</span
                            >
                          </p>

                          <cx-form-errors
                            aria-live="assertive"
                            aria-atomic="true"
                            [control]="$any(editPOForm).get('poNumber')"
                          >
                          </cx-form-errors>
                        </div>
                      </div>
                    </div></form
                ></ng-container>
                <ng-container *ngIf="!isEditable">
                  <div class="col-12 text-left">
                    <span class="body-text-demi-lg"
                      >{{ 'fffCheckoutOrderConfirmation.po' | cxTranslate }}#
                      {{ recurringOrder.purchaseOrderNumber }}</span
                    >
                  </div>
                </ng-container>

                <div
                  *ngIf="isCartEmpty"
                  class="text-center px-3 py-4 border-top mt-4"
                >
                  <span
                    [innerHTML]="
                      'fffCheckoutOrderConfirmation.recurringOrder.deleteRecurringOrder'
                        | cxTranslate
                    "
                  ></span>
                </div>
                <div class="row">
                  <div class="col-12">
                    <ng-container *ngFor="let item of recurringOrder.entries">
                      <div
                        class="d-flex flex-wrap p-0 p-lg-3 col-12 py-4 border-separator"
                        *ngIf="!item.addedToDeletion"
                      >
                        <div class="col-12 my-4">
                          <div class="entry-divider"></div>
                        </div>
                        <div class="col-12 px-0 d-flex flex-wrap">
                          <div class="col-3 px-0">
                            <cx-media
                              [container]="getData(item.product!)"
                              format="cart"
                            ></cx-media>
                          </div>
                          <div class="col-9 col-lg-5">
                            <p
                              class="mb-0 body-text-demi title h4 text-brand-primary-80"
                            >
                              {{ item?.product?.name }}
                            </p>
                            <p class="mb-0">
                              {{ item?.product?.manufacturer }}
                            </p>
                            <p class="mb-0">
                              {{ 'fffReturnEntry.ndc' | cxTranslate }}
                              {{ item?.product?.ndc }}
                            </p>
                            <p class="mb-0">
                              {{ 'fffReturnEntry.sku' | cxTranslate }}
                              {{ item?.product?.code }}
                            </p>
                            <p class="mb-0" *ngIf="item.accountType === 'AUTO'">
                              {{ 'fffReturnEntry.account' | cxTranslate }}
                              {{
                                'fffProduct.lblAccountAutoSplitBiller'
                                  | cxTranslate
                              }}
                            </p>
                            <p class="mb-0" *ngIf="item.accountType !== 'AUTO'">
                              {{ 'fffReturnEntry.account' | cxTranslate }}
                              {{
                                recurringOrder.unit.uid +
                                  (item.accountType !== 'S'
                                    ? item.accountType
                                    : '')
                              }}
                            </p>
                          </div>
                          <div class="col-12 col-lg-4">
                            <!-- Default quantity label -->
                            <ng-container *ngIf="!isEditable">
                              <div class="text-right entry-accent">
                                {{
                                  'fffCheckoutOrderConfirmation.recurringOrder.qty'
                                    | cxTranslate
                                }}
                                {{ item?.quantity }} {{ item?.uom }}
                              </div>
                              <ng-container
                                *ngTemplateOutlet="
                                  priceDetails;
                                  context: { item: item }
                                "
                              >
                              </ng-container>
                            </ng-container>

                            <!-- Display editable quantity field -->
                            <ng-container *ngIf="isEditable">
                              <div
                                class="d-flex align-items-center align-items-lg-end flex-column justify-content-center text-center"
                              >
                                <div class="input-price mt-3 mt-lg-0">
                                  <div class="qty body-text-sm">
                                    {{
                                      'fffCheckoutOrderConfirmation.recurringOrder.qty'
                                        | cxTranslate
                                    }}
                                  </div>
                                  <div class="input-item position-relative">
                                    <fff-tooltip
                                      *ngIf="isInvalidEntry(item)"
                                      [text]="
                                        'fffSavedCartDetail.invalidQuantityTooltipText'
                                          | cxTranslate
                                      "
                                      class="warn-tooltip"
                                      (closed)="item.canShowTooltip = false"
                                    ></fff-tooltip>
                                    <input
                                      class="text-center"
                                      [(ngModel)]="item.quantity"
                                      maxlength="4"
                                      name="quantity"
                                      (focus)="item.canShowTooltip = true"
                                      (ngModelChange)="
                                        validateEntryQuantity(item)
                                      "
                                    />
                                    {{ item?.uom }}
                                  </div>
                                  <ng-container
                                    *ngTemplateOutlet="
                                      priceDetails;
                                      context: { item: item }
                                    "
                                  >
                                  </ng-container>
                                  <button
                                    type="button"
                                    class="delete-action mx-auto mt-2 cursor-pointer btn btn-xxs text-center text-danger"
                                    (click)="
                                      deleteEntry(removeAlertModal, item)
                                    "
                                  >
                                    {{ 'common.delete' | cxTranslate }}
                                  </button>
                                </div>
                              </div>
                            </ng-container>
                          </div>
                        </div>
                      </div>
                    </ng-container>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12 col-lg-3 px-0">
              <div class="d-flex flex-wrap actions">
                <!-- Actions: Cart Details Page -->
                <ng-container *ngIf="!isEditable">
                  <button
                    class="col-12 btn btn-secondary"
                    (click)="editRecurringOrder(recurringOrder.code)"
                  >
                    {{
                      'fffCheckoutOrderConfirmation.recurringOrder.editRecurringOrder'
                        | cxTranslate
                    }}
                  </button>
                </ng-container>

                <!-- Actions: Edit Cart Details Page -->
                <ng-container *ngIf="isEditable">
                  <button
                    class="col-12 btn btn-primary"
                    (click)="saveDetails(recurringOrder?.code)"
                    [disabled]="isUpdating || !isModified"
                  >
                    {{ 'fffSavedCartDetail.saveChanges' | cxTranslate }}
                  </button>

                  <span class="col-12 text-center">{{
                    'fffSavedCartDetail.or' | cxTranslate
                  }}</span>
                  <button
                    class="col-12 btn btn-secondary"
                    (click)="goToRecurringOrdersDetails(recurringOrder?.code)"
                  >
                    {{ 'fffSavedCartDetail.cancel' | cxTranslate }}
                  </button>
                </ng-container>
                <span class="body-text-demi accent-color">{{
                  'fffSavedCartDetail.customerCare' | cxTranslate
                }}</span>
              </div>
            </div>
          </div>
          <fff-returns-footer></fff-returns-footer>
        </div>
        <ng-template #sectionTwo>
          <div class="row mt-3 pt-3 border-top mx-0">
            <div class="col-lg-6 col-sm-12 p-0">
              <p class="mb-0">
                <span class="body-text-demi">{{
                  'fffCheckoutOrderConfirmation.recurringOrder.orderPlacedBy'
                    | cxTranslate
                }}</span
                >&nbsp;
                <span>{{ recurringOrder.user.name }}</span>
              </p>
              <p class="mb-0">
                <span class="body-text-demi">{{
                  'fffCheckoutOrderConfirmation.recurringOrder.frequencyOfOrder'
                    | cxTranslate
                }}</span
                >&nbsp;
                <span>{{
                  clearTimeInSchedule(recurringOrder.trigger.displayTimeTable)
                }}</span>
              </p>
            </div>
            <div class="col-lg-6 col-sm-12">
              <p class="mb-0">
                <span class="body-text-demi">{{
                  'fffCheckoutOrderConfirmation.recurringOrder.orderStartDate'
                    | cxTranslate
                }}</span
                >&nbsp;
                <span>{{
                  recurringOrder.firstDate | cxDate : 'shortDate'
                }}</span>
              </p>
              <p class="mb-0">
                <span class="body-text-demi">{{
                  'fffCheckoutOrderConfirmation.recurringOrder.nextOrderDate'
                    | cxTranslate
                }}</span
                >&nbsp;
                <span>{{
                  recurringOrder.trigger
                    ? (recurringOrder.trigger.activationTime
                      | cxDate : 'shortDate')
                    : ''
                }}</span>
              </p>
            </div>
          </div>
        </ng-template>
      </ng-container>
    </ng-container>
  </ng-container>
</ng-container>
<ng-template #removeAlertModal let-modal>
  <div class="modal-body">
    <div class="title">{{ 'fffSavedCartDetail.notice' | cxTranslate }}</div>
    <div class="message">
      {{ 'fffSavedCartDetail.removeItemMessage' | cxTranslate }}
    </div>
  </div>
  <div class="modal-footer d-flex flex-column">
    <button
      type="button"
      class="btn btn-primary w-100"
      (click)="modal.dismiss(true)"
    >
      {{ 'fffSavedCartDetail.acknowledgeAndProceed' | cxTranslate }}
    </button>
    <button
      type="button"
      class="btn btn-secondary w-100"
      (click)="modal.dismiss(false)"
    >
      {{ 'fffSavedCartDetail.cancel' | cxTranslate }}
    </button>
  </div>
</ng-template>

<ng-template #priceDetails let-item="item">
  <div class="d-flex justify-content-end mt-3">
    <ng-container
      *ngIf="
        item?.unitConversionFactor && item?.altUom && !isOfVaccineTypeI(item)
      "
    >
      <div class="volume body-text-sm d-inline-block">
        1 {{ item?.uom }} = {{ item?.unitConversionFactor }}
        {{ item?.altUom }}
      </div>
    </ng-container>
    <ng-container *ngIf="item?.altUnitConversion">
      <div class="volume body-text-sm d-inline-block">
        <span
          *ngIf="
            item?.unitConversionFactor &&
            item?.altUom &&
            !isOfVaccineTypeI(item)
          "
        >
          ,&nbsp;
        </span>
        {{ item?.altUnitConversion }}
      </div>
    </ng-container>

    <ng-container *ngIf="isOfVaccineTypeI(item)">
      <div class="body-text-xs mt-1 text-lowercase">
        1 {{ item.uom }} = 10 DS
      </div>
    </ng-container>
  </div>
</ng-template>
