import { Component } from '@angular/core';
import { OUTLINED_ICON_TYPE } from '@model/fff-outline-icons.model';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'fff-exported-document-message',
  templateUrl: './fff-exported-document-message.html',
})

export class FffExportedDocumentMessageComponent {
  OUTLINED_ICON_TYPE = OUTLINED_ICON_TYPE;
  constructor(private modalService: NgbActiveModal) {
  }
  close() {
    this.modalService.close(false);
  }
}
