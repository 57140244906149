import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { FffDrawerModule } from '@app/shared/drawer/fff-drawer.module';
import { FffTableModule } from '@app/shared/table/fff-table.module';
import { FffTooltipModule } from '@app/shared/tooltip/tooltip.module';
import { NgbModalModule, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import {
  AuthGuard,
  CmsConfig,
  FeaturesConfigModule,
  I18nModule,
  OccConfig,
  OccProductAdapter,
  RoutingConfig,
  RoutingModule,
  UrlModule,
  provideConfig,
  provideDefaultConfig,
} from '@spartacus/core';
// import { CarouselModule, CartCouponModule, CartSharedModule, CartValidationWarningsModule, IconModule, ItemCounterModule, MediaModule, OutletModule, ProgressButtonModule, PromotionsModule, SpinnerModule } from '@spartacus/storefront';
import { FffInputValidationUtilsModule } from '@app/shared/components/input-validation-utils/input-validation-utils.module';
import {
  CartCouponModule,
  CartSharedModule,
  CartValidationWarningsModule,
} from '@spartacus/cart/base/components';
import { SavedCartService } from '@spartacus/cart/saved-cart/core';
import { SavedCartFacade } from '@spartacus/cart/saved-cart/root';
import {
  CarouselModule,
  CmsPageGuard,
  IconModule,
  ItemCounterModule,
  MediaModule,
  OutletModule,
  PageLayoutComponent,
  ProgressButtonModule,
  PromotionsModule,
  SpinnerModule,
} from '@spartacus/storefront';
import { FffEntryModule } from '../fff-entry/fff-entry.module';
import { FffPrebookCategoryModule } from '../fff-prebook-category/fff-prebook-category.module';
import { FffProductModule } from '../fff-product/fff-product.module';
import { FffSplitBillingModule } from '../fff-product/fff-split-billing/fff-split-billing.module';
import { FffCartCompletePageComponent } from './fff-cart-complete-page/fff-cart-complete-page.component';
import { FffCartDetailsComponent } from './fff-cart-details/fff-cart-details.component';
import { FFFCartHeaderMessageComponent } from './fff-cart-header-message/fff-cart-header-message.component';
import { FffCartItemListComponent } from './fff-cart-item-list/fff-cart-item-list.component';
import { FffCartItemComponent } from './fff-cart-item/fff-cart-item.component';
import { FffCartSplitBillingComponent } from './fff-cart-split-billing/fff-cart-split-billing.component';
import { FffCartSummaryComponent } from './fff-cart-summary/fff-cart-summary.component';
import { FffCartTotalsComponent } from './fff-cart-totals/fff-cart-totals.component';
import { FffEmptyCartComponent } from './fff-empty-cart/fff-empty-cart.component';
import { FffPrebookCartCompletePageComponent } from './fff-prebook-cart-complete-page/fff-prebook-cart-complete-page.component';
import { FffPrebookCartDetailsComponent } from './fff-prebook-cart-details/fff-prebook-cart-details.component';
import { FFFRecommendedProductsComponent } from './fff-recommended-products/fff-recommended-products.component';
import { FffSaveCartDrawerModule } from './fff-save-cart-drawer/fff-save-cart-drawer.module';
import { FffRestoreSavedCartDrawerBodyComponent } from './fff-saved-cart/fff-restore-saved-cart-drawer/fff-restore-saved-cart-drawer-body.component';
import { FffRestoreSavedCartDrawerFooterComponent } from './fff-saved-cart/fff-restore-saved-cart-drawer/fff-restore-saved-cart-drawer-footer.component';
import { FffRestoreSavedCartDrawerHeaderComponent } from './fff-saved-cart/fff-restore-saved-cart-drawer/fff-restore-saved-cart-drawer-header.component';
import { FFFSavedCartDeleteConfirmationComponent } from './fff-saved-cart/fff-saved-cart-delete-confirmation/fff-saved-cart-delete-confirmation.component';
import { FffSavedCartDetailComponent } from './fff-saved-cart/fff-saved-cart-detail/fff-saved-cart-detail.component';
import { FffSavedCartEmptyComponent } from './fff-saved-cart/fff-saved-cart-empty/fff-saved-cart-empty.component';
import { FffSavedCartListComponent } from './fff-saved-cart/fff-saved-cart-list/fff-saved-cart-list.component';
import { FFFSavedCartMessagePopupComponent } from './fff-saved-cart/fff-saved-cart-message-popup/fff-saved-cart-message-popup.component';
import { FFFLoadersModule } from '@app/shared/components/fff-loaders/fff-loaders.module';

@NgModule({
  declarations: [
    FffEmptyCartComponent,
    FffCartTotalsComponent,
    FffCartSummaryComponent,
    FffEmptyCartComponent,
    FffCartDetailsComponent,
    FffCartItemListComponent,
    FffCartItemComponent,
    FffSavedCartListComponent,
    FffSavedCartDetailComponent,
    FffRestoreSavedCartDrawerBodyComponent,
    FffRestoreSavedCartDrawerHeaderComponent,
    FffRestoreSavedCartDrawerFooterComponent,
    FffCartSplitBillingComponent,
    FFFRecommendedProductsComponent,
    FFFCartHeaderMessageComponent,
    FffCartCompletePageComponent,
    FFFSavedCartMessagePopupComponent,
    FffSavedCartEmptyComponent,
    FffPrebookCartCompletePageComponent,
    FffPrebookCartDetailsComponent,
    FFFSavedCartDeleteConfirmationComponent,
  ],
  imports: [
    UrlModule,
    BrowserModule,
    CommonModule,
    I18nModule,
    MediaModule,
    IconModule,
    ProgressButtonModule,
    FffSplitBillingModule,
    CartSharedModule,
    CartCouponModule,
    PromotionsModule,
    FeaturesConfigModule,
    CartValidationWarningsModule,
    FffProductModule,
    ItemCounterModule,
    NgbModalModule,
    OutletModule,
    FffEntryModule,
    FffSaveCartDrawerModule,
    FffTableModule,
    FffDrawerModule,
    FormsModule,
    CarouselModule,
    FffTooltipModule,
    NgbModule,
    SpinnerModule,
    FffPrebookCategoryModule,
    FffInputValidationUtilsModule,
    FFFLoadersModule,
    RoutingModule,
    RouterModule.forChild([
      {
        // @ts-ignore
        path: null,
        component: PageLayoutComponent,
        data: { cxRoute: 'editSavedCartsDetails', isEditPage: true },
        canActivate: [AuthGuard, CmsPageGuard],
      },
    ]),
  ],
  exports: [FffEmptyCartComponent, FFFSavedCartMessagePopupComponent],
  providers: [
    OccProductAdapter,
    provideDefaultConfig(<CmsConfig>{
      cmsComponents: {
        EmptyCartFlexComponent: {
          component: FffEmptyCartComponent,
        },
        CartComponent: {
          component: FffCartDetailsComponent,
        },
        CartTotalsComponent: {
          component: FffCartTotalsComponent,
        },
        FFFAccountSavedCartHistoryComponent: {
          component: FffSavedCartListComponent,
        },
        FFFSavedCartDetailsOverviewComponent: {
          component: FffSavedCartDetailComponent,
        },
        CartSuggestionComponent: {
          component: FFFRecommendedProductsComponent,
        },
        FFFCartHeaderMessagingComponent: {
          component: FFFCartHeaderMessageComponent,
        },
        FFFCartCompletePageComponent: {
          component: FffCartCompletePageComponent,
        },
        FFFPrebookCartCompletePageComponent: {
          component: FffPrebookCartCompletePageComponent,
        },
      },
    }),
    provideConfig(<OccConfig>{
      backend: {
        occ: {
          endpoints: {
            deleteCart: 'users/${userId}/carts/${cartId}',
          },
        },
      },
    }),
    provideConfig(<RoutingConfig>{
      routing: {
        routes: {
          editSavedCartsDetails: {
            paths: ['my-account/saved-cart/:savedCartId/edit'],
            paramsMapping: { savedCartId: 'savedCartId' },
          },
        },
      },
    }),

    SavedCartService,
    {
      provide: SavedCartFacade,
      useExisting: SavedCartService,
    },
  ],
})
export class FffCartModule {}
