import { Component } from '@angular/core';
import { OUTLINED_ICON_TYPE } from '@app/models/fff-outline-icons.model';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'fff-product-not-orderable',
  templateUrl: './fff-product-not-orderable.component.html',
})
export class FffProductNotOrderableComponent {
  outlinedIconTypes = OUTLINED_ICON_TYPE;

  constructor(private modalService: NgbActiveModal) {}

  close(): void {
    this.modalService.close({ addToCart: false });
  }
}
