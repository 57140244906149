export const fffReturns = {
  fffReturns: {
    title: 'Returns',
    noReturns: 'You have no returns in your Returns list at this time',
    noProducts: 'You have no products eligible to return',
    searchByFluReturnId: 'Search by Flu Return #',
    new: 'New',
    inProcess: 'In Process',
    complete: 'Complete',
    fluReturn: 'Flu Return #',
    fluRGACreationDate: 'Flu RGA Creation Date',
    rga: 'RGA',
    rgaTitle: 'Returns Goods Authorization',
    fetTitle: 'Federal Excise Tax Returns Authorization',
    returnStatus: 'Return Status',
    sendCopyToEmail: 'Send RGA Copy to Email',
    enterMultipleEmails: 'Enter multiple emails here separated by comma',
    fffRequest: {
      title: 'Request a Return',
      stepOne: '1. Verify Contact Information',
      stepTwo: '2. Pick a Product',
      qtyAdded: 'Qty: {{quantity}}/{{unit}}',
      qtyEligible: 'Qty: {{quantity}}/{{unit}} eligible for Standard Return',
      qtyOrdered: '{{quantity}}/{{unit}} ordered',
      qtyDisclaimer:
        'Any qty entered greater than {{quantity}}/{{unit}} will receive FET credit only.',
      summaryTitle: 'Your Return ({{returnListLength}} Items)',
      remove: 'Remove',
      batchNumber: 'Lot #',
      quantity: 'Qty',
      addProductTitle: 'Enter Lot & Quantity',
      addProductLabel: 'Only full boxes of products are eligible for return.',
      addProductButton: 'Add to Request',
      cancelRequest: 'Cancel Request',
      submitRequest: 'Submit Request',
      supportText: 'Wow! Customer Care (800) 843-7477',
    },
    name: 'Name',
    phone: 'Phone',
    email: 'Email',
    poNumber: 'PO #',
    ndc: 'NDC',
    sku: 'SKU',
    account: 'Account',
    batchNumber: 'Lot Number',
  },
};

export const fffReturnConfirmation = {
  fffReturnConfirmation: {
    header: 'Return Request Submitted',
    subtitle:
      "We are sending an e-mail to {{email}} with the details of your return request. If the email hasn't arrived within 24 hours, please check your spam folder to see if the email we sent was routed there.",
    returnNumber: 'Return: #{{code}}',
    poNumber: 'PO # {{po}}',
    returnRequested: 'Return Requested',
    footer:
      'If you have questions or concerns regarding your return, contact Wow! Customer Care using Live Chat or by calling (800) 843-7477',
  },
};

export const fffReturnEntry = {
  fffReturnEntry: {
    qty: 'Qty: {{quantity}}/{{unit}}',
    returnableQuantity: 'Returned QTY: {{quantity}}/{{unit}}',
    orderQuantity: 'Requested Return QTY: {{quantity}}/{{unit}}',
    ndc: 'NDC',
    sku: 'SKU',
    account: 'Account:',
    batchNumber: 'Lot #',
  },
};

export const fffReturnError = {
  fffReturnError: {
    header: 'Error',
    messageWithErrorReference:
      'An error occurred while submitting your order. Please contact Wow! Customer Care at (800) 843-7477 and reference',
    message:
      'An error occurred while submitting your order. Please contact Wow! Customer Care at (800) 843-7477',
    errorCode: 'Error Reference Code: ',
  },
};
export const fffReturnsFooter = {
  fffReturnsFooter: {
    questionsOrConcerns: 'If you have questions or concerns regarding your ',
    return: 'return,',
    recurringOrder: 'Recurring Order,',
    contactWowCustomerCare: 'contact Wow! Customer Care using ',
    chat: 'Live Chat',
    orByCalling: 'or by calling (800) 843-7477',
  },
};
