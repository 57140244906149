<div class="col-12 px-0">
  <div class="container-close-icon pt-0 px-0">
    <cx-icon class="cx-icon icon-lg cursor-pointer" [type]="outlinedIconTypes.ICON_X" (click)="closeDrawer()"></cx-icon>
  </div>
  <h3 class="popover-title mb-0 px-4">{{ 'fffUpdateAllocations.title' | cxTranslate }}</h3>
  <div class="col-12 px-0 py-2">
    <p class="body-text-sm mb-0 px-4">
      {{ 'fffUpdateAllocations.amountAvailableToReallocate' | cxTranslate: { amount: amountAvailableToReallocate } }}
    </p>
    <p class="body-text-sm mb-0 px-4" [class.warning]="(amountReallocated$ | async)! > 0"
      [class.error]="(amountReallocated$ | async)! < 0">
      {{ 'fffUpdateAllocations.amountReallocated' | cxTranslate: { amount: amountReallocated$ | async } }}
    </p>
    <p class="body-text-sm mb-0 px-4 info-message">
      {{ 'fffUpdateAllocations.infoMessage' | cxTranslate: { amount: amountAvailableToReallocate } }}
    </p>
    <p class="body-text-sm mb-0 px-4">
      {{ productCode }} - {{ productName }}
    </p>
  </div>
</div>