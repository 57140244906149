export const fffPriceList = {
  fffPriceList: {
    productType: 'Product Type',
    productName: 'Product Name',
    sku: 'SKU',
    ndc: 'NDC',
    price: 'Price',
    altPrice: 'Alt Price',
    netPrice: 'Net Price',
    accountNumber: 'Account #',
    showing: 'Showing',
    of: 'of',
    title: 'Price List',

    titleDrawer: 'Filter Products',
    apply: 'Apply',
    filter: 'Filter',

    downloadPdf: 'Download PDF',
    exportCsv: 'Export CSV',
    continue: 'Continue',
    exportedDocumentSuccessfully:
      'The export request has been successfully submitted and will be downloaded via your browser when complete',
    productSize: 'Size',
    productManufacturer: 'Manufacturer',
    altUOM: 'Alt UOM',
    netUOM: 'Net UOM',
    contractUOM: 'UOM',
    contractPrice: 'Price',
    productAndManufacturer: 'Product / Manufacturer',
    skuAndNdc: 'SKU / NDC',
    contractProduct: 'Contract Product',
  },
};
