import { Component, Input } from '@angular/core';

@Component({
  selector: 'fff-content-loader',
  templateUrl: './fff-content-loader.component.html',
})
export class FFFContentLoaderComponent {
  @Input()
  containerClassName: string = '';
  @Input()
  bodyClassName: string = '';
}
