import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { I18nModule } from '@spartacus/core';
import { IconModule } from '@spartacus/storefront';
import { FffFetInfoTooltipComponent } from './fff-fet-info-tooltip/fff-fet-info-tooltip.component';
import { FffTooltipComponent } from './fff-tooltip/fff-tooltip.component';

@NgModule({
  declarations: [FffTooltipComponent, FffFetInfoTooltipComponent],
  imports: [CommonModule, I18nModule, NgbModule, IconModule],
  exports: [FffTooltipComponent, FffFetInfoTooltipComponent],
})
export class FffTooltipModule {}
