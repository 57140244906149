export const fffMiniCart = {
  fffMiniCart: {
    added: 'Added to Cart!',
    pluralItemCart: 'items in Cart',
    singleItemCart: 'item in cart',
    singleItem: 'item',
    pluralItem: 'items',
    cart: 'Your cart',
    checkout: 'Checkout'
  }
};
