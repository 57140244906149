<div class="col-12 px-0">
  <div class="col-12">
    <button class="btn btn-primary col-12"
      [disabled]="invalidForm() || invalidAmount(amountReallocated$ | async) || loading"
      (click)="submitAllocationUpdate()" [class.updating]="loading">
      {{ 'fffUpdateAllocations.submit' | cxTranslate }}
      <cx-icon *ngIf="loading" class="cx-icon icon-md ml-3" [type]="outlinedIconTypes.ICON_ARROW_REPEAT"></cx-icon>
    </button>
  </div>

  <div class="col-12 py-2">
    <button class="btn btn-secondary col-12" (click)="closeDrawer(false)">
      {{ 'fffUpdateAllocations.cancel' | cxTranslate }}
    </button>
  </div>
</div>