<div class="success-message" *ngIf="showMessage">
  <div class="d-flex">
    <div>
      <cx-icon
        class="cx-icon icon-check-circle-filled"
        [type]="outlineIcons.ICON_CHECK"
      ></cx-icon>
    </div>
    <div class="ml-2">
      <b
        >{{
          'fffAccount.fffProfileManagement.wallet.deletePopup.success'
            | cxTranslate
        }}
      </b>
      {{
        'fffAccount.fffProfileManagement.wallet.deletePopup.successMessageSplit1'
          | cxTranslate
      }}
      {{ cardEndingDigits }}
      {{
        'fffAccount.fffProfileManagement.wallet.deletePopup.successMessageSplit2'
          | cxTranslate
      }}
    </div>
  </div>
</div>
