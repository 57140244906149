import { Component } from '@angular/core';
import { OUTLINED_ICON_TYPE } from '@app/models/fff-outline-icons.model';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { RoutingService } from '@spartacus/core';

@Component({
  selector: 'fff-user-first-login-popup',
  templateUrl: './fff-user-first-login-popup.component.html'
})
export class FffUserFirstLoginPopupComponent {

  outlinedIconTypes = OUTLINED_ICON_TYPE;

  constructor(
    private modalService: NgbActiveModal,
    private routingService: RoutingService
  ) { }

  close() {
    this.modalService.close();
  }

  goToForgotPassword(): void {
    this.modalService.close({ accept: true });
    this.routingService.go(['/login/forgot-password']);
  }

}
