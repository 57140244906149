import { NgModule } from '@angular/core';
import { provideConfig } from '@spartacus/core';
import { BaseTmsModule, TmsConfig } from '@spartacus/tracking/tms/core';
import { GtmModule } from '@spartacus/tracking/tms/gtm';
import {
  FffCartAddEntrySuccessEvent,
  FffCartRemoveEntrySuccessEvent,
  FffFacetChangedEvent,
  FffGtmEventModule,
  FffLoginEvent,
  FffLogoutEvent,
  FffNavigationEvent,
  FffOrderPlacedEvent,
  FffUserAccountChangedEvent,
  FffUserAccountChangeEvent
} from './gmt/event';
import { FffGtmCollectorService } from './gmt/service/gtm-collector.service';

@NgModule({
  declarations: [],
  imports: [
    BaseTmsModule.forRoot(),
    GtmModule,
    FffGtmEventModule,
  ],
  providers: [
    provideConfig(<TmsConfig>{
      tagManager: {
        gtm: {
          collector: FffGtmCollectorService,
          events: [
            FffCartAddEntrySuccessEvent,
            FffCartRemoveEntrySuccessEvent,
            FffFacetChangedEvent,
            FffLoginEvent,
            FffLogoutEvent,
            FffNavigationEvent,
            FffOrderPlacedEvent,
            FffUserAccountChangeEvent,
            FffUserAccountChangedEvent,
          ],
        },
      },
    }),
  ],
})
export class TagManagementFeatureModule { }
