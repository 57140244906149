<ng-container *ngIf="product$ | async as product">
  <div class="d-flex flex-wrap">
    <fff-product-badge-info
      class="col-12"
      [product]="product"
      [isSplitBillerAccount]="profile?.selected?.autoSplitBillerActive === true"
      [pricesData]="product.pricesData"
    ></fff-product-badge-info>

    <div class="col-12 product-name my-3">{{ product?.name }}</div>

    <div class="col-12 product-info">
      <p>{{ product.manufacturer }}</p>
      <p>{{ 'fffProduct.lblNdc' | cxTranslate }} {{ product.ndc }}</p>
      <p>{{ 'fffProduct.lblSku' | cxTranslate }} {{ product.code }}</p>
    </div>
  </div>
</ng-container>
