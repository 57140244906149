import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FffCommunicationService } from '@app/fff-enterprise/fff-common-services/fff-communication.service';
import { OUTLINED_ICON_TYPE } from '@app/models/fff-outline-icons.model';
import {
  ADD_SELECTED_MY_ACCOUNT_NAVNODE,
  MyAccountNavState,
} from '@app/reducers';
import { FffUserAccountService } from '@app/shared/services/fff-user-account.service';
import { Store } from '@ngrx/store';
import { CmsComponentData, NavigationService } from '@spartacus/storefront';
import { Observable, Subscription } from 'rxjs';

@Component({
  selector: 'fff-my-account-navigation',
  templateUrl: 'fff-my-account-navigation.component.html',
})
export class FffMyAccountNavigationComponent implements OnInit, OnDestroy {
  selectedNode: string = '';
  showMenuDropDown: boolean = false;
  outlinedIconTypes = OUTLINED_ICON_TYPE;
  accountNav: any;
  public dataNav$: Observable<any> = this.component.data$;
  notifications$ = this.communicationService.getNotifications();
  subs = new Subscription();
  isEligibleForNewReports!: boolean;

  constructor(
    protected component: CmsComponentData<any>,
    private router: Router,
    private store: Store<MyAccountNavState>,
    private navigationService: NavigationService,
    private communicationService: FffCommunicationService,
    private cd: ChangeDetectorRef,
    private fffUserAccountService: FffUserAccountService
  ) {}

  ngOnInit() {
    this.checkNewReportsEligibility();
    this.subs.add(
      this.navigationService
        .getNavigationNode(this.dataNav$)
        .subscribe(data => {
          this.accountNav = data.children;
          this.cd.markForCheck();
        })
    );
  }

  checkNewReportsEligibility() {
    this.fffUserAccountService.getProfile().subscribe(res => {
      const profile = res;
      if (profile?.selected?.uid) {
        this.communicationService
          .getAppProperties()
          .subscribe(applicationProperties => {
            if (applicationProperties?.ecommerceReportingCustomersEnabled) {
              const reportEnabledAccounts =
                applicationProperties?.ecommerceReportingCustomersEnabled;
              if (
                reportEnabledAccounts == 'ALL' ||
                reportEnabledAccounts.includes(profile.selected.uid)
              ) {
                this.isEligibleForNewReports = true;
                this.cd.detectChanges();
              }
            }
          });
      }
    });
  }
  isEligibleNode(title: string) {
    if (title !== 'New Reports') {
      return true;
    } else {
      return this.isEligibleForNewReports;
    }
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  async selectMyAccountNav(child: any): Promise<void> {
    this.store.dispatch({
      type: ADD_SELECTED_MY_ACCOUNT_NAVNODE,
      payload: child.title,
    });

    this.showMenuDropDown = false;
    let foundNode = this.accountNav.find(
      (elem: any) => elem.title === child.title
    );
    this.router.navigate([foundNode.url]);
  }

  isNodeSelected(child: any) {
    let foundNode = this.accountNav.find(
      (elem: any) => elem.title === child.title
    );
    return this.router.url === foundNode.url;
  }

  fetchMedals(nodeTitle: any, notifications: any): number {
    if (nodeTitle === 'Approvals') {
      return notifications?.approvalRequestCount ?? 0;
    }

    if (nodeTitle === 'CSOS') {
      return notifications?.csosRequestCount ?? 0;
    }

    return 0;
  }

  getShowMenu(): void {
    this.showMenuDropDown = !this.showMenuDropDown;
  }
}
