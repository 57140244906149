import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { OUTLINED_ICON_TYPE } from 'src/app/models/fff-outline-icons.model';


@Component({
  selector: 'fff-legisym-message-popup',
  templateUrl: './fff-legisym-message-popup.component.html'
})
export class FFFLegisymMessagePopupComponent {

  outlinedIconTypes = OUTLINED_ICON_TYPE;
  message$: Observable<string> | undefined;

  constructor(
    private modalService: NgbActiveModal
  ) { }

  close() {
    this.modalService.close();
  }

}
