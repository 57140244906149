import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { gridBreakpointsLG } from '@config/content/constants';
import { FffOrderFacade } from '@enterprise/fff-common-services/fff-order-facade';
import { FffOrderService } from '@enterprise/fff-common-services/fff-order-service';
import {
  FffReturnsListFilterComponent,
} from '@enterprise/fff-returns/fff-returns-filter/fff-returns-list-filter.component';
import { FILLED_ICON_TYPE } from '@model/fff-filled-icons.model';
import { OUTLINED_ICON_TYPE } from '@model/fff-outline-icons.model';
import { FffProfile } from '@model/fff-profile.model';
import { FffDrawerService } from '@shared/drawer/fff-drawer.service';
import { FffUserAccountService } from '@shared/services/fff-user-account.service';
import { RoutingService } from '@spartacus/core';
import { Observable, Subject, of } from 'rxjs';
import { map, switchMap, takeUntil } from 'rxjs/operators';
import { defaultPageSize } from 'src/app/fff-config/pagination/pagination.config';

@Component({
  selector: 'fff-returns-list',
  templateUrl: './fff-returns-list.component.html',
})
export class FffReturnsListComponent implements OnInit, OnDestroy {

  filledIconTypes = FILLED_ICON_TYPE;
  outlinedIconTypes = OUTLINED_ICON_TYPE;
  profile: FffProfile | undefined;
  private unsubscribe$: Subject<void> = new Subject<void>();
  returnList$: Observable<any> = this.fffAccountService.getProfile().pipe(
    switchMap((profile) => {
      this.profile = profile;
      if(profile){
        const pageChange = this.updateCurrentPageAndFetchReturnOrders(profile, { page: 1 }).pipe(map((res) => {
          res.profile = profile;
          return res;
        }));
        if(pageChange)
          return pageChange;
      }
      return of(undefined);
    }),
    takeUntil(this.unsubscribe$)
  );
  fields: any = [
    { title: 'fffReturns.fluReturn', field: 'order' },
    { title: 'fffReturns.fluRGACreationDate', field: 'placed' },
    { title: 'fffOrderHistory.status', field: 'sapOrderStatus' },
  ];
  columnsGrid: any = {
    desktop: '1fr 1fr 1fr',
    mobile: '1fr 1fr',
  };
  defaultPageSize = defaultPageSize;
  mobileSize: any = gridBreakpointsLG;
  currentPage: number = 0;
  drawerResponse: any = {
    raw: {},
    params: {},
  };
  constructor(
    private fffAccountService: FffUserAccountService,
    private drawerService: FffDrawerService,
    private fffOrderFacade: FffOrderFacade,
    private orderService: FffOrderService,
    private routing: RoutingService,
    private cdr: ChangeDetectorRef,
  ) {}
  ngOnInit(): void {
    this.orderService.messageSource.pipe(takeUntil(this.unsubscribe$)).subscribe((res) => {
      if(this.profile){
        this.drawerResponse = res;
        this.pageChange(this.profile, { page: 1 });
        this.cdr.markForCheck();
      }
    });
  }
  updateCurrentPageAndFetchReturnOrders(profile: FffProfile, event: any): Observable<any> {
    this.currentPage = event.page - 1;
    const currentPage = this.currentPage;
    return this.fetchReturnOrders(profile.user.uid, profile.selected.uid, currentPage);
  }
  fetchReturnOrders(uid: string, b2bUnitUid: string, page: number): Observable<any> {
    return this.fffOrderFacade.loadOrderList(uid, this.defaultPageSize, page, this.drawerResponse.params, b2bUnitUid, 'ZRE');
  }
  pageChange(profile: FffProfile | undefined, event: any){
    if(profile) {
      this.returnList$ = this.updateCurrentPageAndFetchReturnOrders(profile, event);
    }
  }
  openFilterDrawer(): void {
    this.drawerService.setContent({
      title: 'Filter Returns',
      component: FffReturnsListFilterComponent,
      animation: 'SideRTL',
      class: 'order_history',
      data: this.drawerResponse.raw,
    });
    this.drawerService.openDrawer();
  }
  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
    this.orderService.sendFilterMessage({});
  }
  goToHomePage(): void {
    this.routing.go(['/']);
  }
  viewReturnDetails(returnId: string): void {
    this.routing.go(['/my-account/return/', returnId]);
  }
}
