import { Component } from '@angular/core';
import { FffUserAccountService } from '@shared/services/fff-user-account.service';
import { switchMap, tap } from 'rxjs/operators';
import { FFFReturnsService } from '@enterprise/fff-common-services/fff-returns.service';
import { of } from 'rxjs';
import { FffProfile } from '@model/fff-profile.model';
import { RoutingService } from '@spartacus/core';
import { FffReturn } from '@model/fff-return.model';

@Component({
  selector: 'fff-fet-returns-request',
  templateUrl: 'fff-fet-returns-request.component.html',
})
export class FffFetReturnsRequestComponent {
  profile: FffProfile | undefined;
  fetReturnsEntries$ = this.fffAccountService.getProfile().pipe(
    switchMap((profile) => {
      if(profile) {
        this.profile = profile;
        return this.fffReturnsService.getReturns(profile.user.uid, true)
          .pipe(tap(data => {
            if (data && data.returnEntries) {
              data.returnEntries.forEach(entry => entry.showAdd = false);
            }
          }));
      }
      return of(undefined);
    })
  );
  editMode: boolean = false;
  personalData: FffReturn | undefined;
  constructor(
    private fffAccountService: FffUserAccountService,
    private fffReturnsService: FFFReturnsService,
    private routingService: RoutingService
  ) {
  }
  isEditingMode(value: boolean) {
    this.editMode = value;
  }
  personalDetails(value: FffReturn) {
    this.personalData = value;
  }
  goToHomePage(): void {
    this.routingService.go(['/']);
  }
}
