<ng-container *ngIf="isFavorite$ | async; else notFavorite">
  <button
    class="btn btn-md removeFromFavs px-4"
    [ngClass]="{ 'w-100 mt-2': isPLPPage }"
    (click)="removeFromFavorites()"
  >
    {{ 'fffProduct.favorite' | cxTranslate }}
    <cx-icon
      class="cx-icon icon-sm pl-2"
      [type]="filledIconTypes.ICON_STAR_FILL"
    ></cx-icon>
  </button>
</ng-container>
<ng-template #notFavorite>
  <button
    class="btn btn-secondary btn-md px-4"
    [ngClass]="{ 'w-100 mt-2': isPLPPage }"
    (click)="addToFavorites()"
  >
    {{ 'fffProduct.favorite' | cxTranslate }}
    <cx-icon
      class="cx-icon icon-sm pl-2"
      [type]="outlinedIconTypes.ICON_STAR"
    ></cx-icon>
  </button>
</ng-template>
