<ng-container
  class="expand-collapse-container"
  *ngIf="nonEligibleProducts && nonEligibleProducts.length > 0"
>
  <div class="content" [ngClass]="{ expanded: isExpanded }">
    <div class="unavailavle-info-header mb-4 pb-2">
      {{
        nonEligibleProducts.length > 1
          ? ('fffCart.unavailableItemsMessage' | cxTranslate)
          : ('fffCart.unavailableItemMessage' | cxTranslate)
      }}
    </div>
    <div class="unavailavle-header d-flex mb-2">
      <div>{{ 'fffCart.unavailableItems' | cxTranslate }}</div>

      <div class="unavailableItemsInfo">
        <cx-icon
          class="cx-icon icon-sm"
          [type]="filledIconTypes.ICON_QUESTION_CIRCLE_FILL"
          [ngbTooltip]="unavailableItemsTooltipContent"
          triggers="click"
          placement="top"
          ngbTooltipClass="tooltip-custom"
        ></cx-icon>
        <ng-template #unavailableItemsTooltipContent>
          <div class="unavailableItemsTooltip">
            <span class="contactSupportLink" (click)="openLiveChat()">{{
              'fffCart.contactSupport' | cxTranslate
            }}</span
            >{{ 'fffCart.unavailableTooltipMsg' | cxTranslate }}
          </div>
          <div><div class="pointer"></div></div>
        </ng-template>
      </div>
    </div>
    <div [ngClass]="!isPrebook ? 'fff-item-list-cart' : ''">
      <div
        class="cx-item-list-row"
        *ngFor="let item of nonEligibleProducts; let i = index"
      >
        <div
          *ngIf="getControl(item) | async as control"
          class="cx-item-list-items"
          [class.is-changed]="getQuantity(control).disabled"
        >
          <fff-cart-item
            [activeSite]="activeSite"
            [profile]="profile$ | async"
            [item]="item"
            [quantityControl]="getQuantity(control)"
            [readonly]="readonly"
            [promotionLocation]="promotionLocation"
            [options]="options"
            [priceData]="prices[i] ? prices[i] : {}"
            [allocationsForCart]="stock"
            [isUnavailableItem]="true"
          >
          </fff-cart-item>
        </div>
      </div>
    </div>
  </div>
  <div class="horizontal-line" [ngClass]="{ showCollapsedline: !isExpanded }">
    <button
      class="expandBtn"
      (click)="toggleExpand()"
      [ngClass]="{ expanded: isExpanded }"
    >
      {{
        isExpanded
          ? ('fffCart.hideUnavailableItems' | cxTranslate)
          : ('fffCart.showUnavailableItems' | cxTranslate)
      }}
    </button>
  </div>
</ng-container>
<div
  class="cart-items-header mb-3"
  [ngClass]="{ cartHeaderMargin: nonEligibleProducts.length > 0 }"
>
  {{ 'fffCart.cartItems' | cxTranslate }}
</div>
<div [ngClass]="!isPrebook ? 'fff-item-list-cart' : ''">
  <div
    class="cx-item-list-row"
    *ngFor="let item of eligibleProducts; let i = index"
  >
    <div
      *ngIf="getControl(item) | async as control"
      class="cx-item-list-items"
      [class.is-changed]="getQuantity(control).disabled"
    >
      <fff-cart-item
        [activeSite]="activeSite"
        [profile]="profile$ | async"
        [item]="item"
        [quantityControl]="getQuantity(control)"
        [readonly]="readonly"
        [promotionLocation]="promotionLocation"
        [options]="options"
        [priceData]="prices[i] ? prices[i] : {}"
        [allocationsForCart]="stock"
      >
      </fff-cart-item>
    </div>
  </div>
</div>
