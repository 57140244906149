<div *ngIf="isVisible">
  <div>
    <div class="bubble">
      <div
        class="w-100 ; justify-content-end d-flex close-container"
        *ngIf="!alwaysVisible"
      >
        <div class="cursor-pointer" (click)="isVisible = false; closed.emit()">
          x
        </div>
      </div>
      <div class="text-tooltip">
        <span>{{ isTranslatableLabel ? (text | cxTranslate) : text }}</span>
      </div>
    </div>
  </div>

  <div>
    <div class="pointer"></div>
  </div>
</div>
