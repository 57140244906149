<div class="d-flex flex-wrap body-text-lg">

  <div class="content-close-icon cursor-pointer ml-auto d-flex justify-content-between p-2">
    <cx-icon class="cx-icon icon-lg" [type]="outlinedIconTypes.ICON_X" (click)="close()"></cx-icon>
  </div>

  <div class="col-12 text-center content-header">
    <cx-icon *ngIf="!hasErrorData" class="cx-icon icon-lg pb-3 text-brand-primary-80"
      [type]="outlinedIconTypes.ICON_CHECK_CIRCLE_2"></cx-icon>
    <cx-icon *ngIf="hasErrorData" class="cx-icon icon-lg pb-3 text-brand-primary-80"
      [type]="outlinedIconTypes.ICON_EXCLAMATION_CIRCLE"></cx-icon>
    <span class="col-12 text-center text-brand-primary-80" *ngIf="!hasErrorData">
      {{ 'fffRecurringOrdersPopUp.titleOk' | cxTranslate }}
    </span>
    <span class="col-12 text-center text-brand-primary-80" *ngIf="hasErrorData">
      {{ 'fffRecurringOrdersPopUp.titleError' | cxTranslate }}
    </span>
  </div>


  <div class="col-12 text-center cx-dialog-body modal-body content-body">
    <p class="mb-0" *ngIf="!hasErrorData">{{ 'fffRecurringOrdersPopUp.message' | cxTranslate }}</p>
    <p class="mb-0" *ngIf="hasErrorData">{{ errorData.code }}</p>
  </div>

</div>