/* List of endpoints for FFF */


export const END_POINTS = {
  REGISTER: '/account/create-customer',
  VALIDATE_FFF_ACCOUNT: '/account/check',
  RESEND_EMAIL: '/account/resend-email',
  REGISTER_VERIFY_EMAIL: '/account/verify-email',
  VERIFYEMAIL: '/email-confirmation',
  LOGIN_MSG_URL: 'users/current/newMessages',
};

export const fffOccEndpointsConfig = {
  productSearch: 'products/search?fields=DEFAULT',
  // productSearch: 'products/search?fields=ANONYMOUS',
  REGISTER: '/b2bUsers',
};

export const fffContactUsOccEndpointsConfig = {
  DEPARTMENTS: '/contactus/departments',
  SUBMIT: '/contactus/submit',
};

export const fffCSOSStatusOccEndpointsConfig = {
  csosOrders: '/csosOrderDetails?fields=FULL',
};


export const fffInvoiceEndpointsConfig = {
  downloadPDF: '/getInvoicePDF',
};

export const fffCSOSConfig = {
  getToken: {
    method: 'post',
  },
  actions: {
    certStore: {
      name: 'CertStore',
      redirectUrl: '/redirect-profile',
    },
    signOrder: {
      name: 'SignOrder',
      redirectUrl: '/redirect-orders',
    },
    printOrder: {
      name: 'printOrder',
      redirectUrl: '/redirect-orders',
    },
    receiveOrder: {
      name: 'ReceiveOrder',
      redirectUrl: '/redirect-orders',
    },
  },
};

export const fffAllocationsOccEndpointsConfig = {
  HISTORY: 'allocations/allocationHistory',
  INTERACTIVE_ALLOCATIONS: 'allocations/interactiveAllocations',
  INTERACTIVE_ALLOCATIONS_SUBMIT: 'allocations/interactiveAllocations/submit',
};
