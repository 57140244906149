import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { I18nModule } from '@spartacus/core';
import {
  GenericLinkModule,
  IconModule
} from '@spartacus/storefront';
import { FffDrawerModule } from 'src/app/shared/drawer/fff-drawer.module';
import { FffChangeAccountDrawerModule } from '../fff-change-account/fff-change-account-drawer.module';
import { FffMyAccountDrawerBodyComponent } from './fff-my-account-drawer-body.component';
import { FffMyAccountDrawerHeaderComponent } from './fff-my-account-drawer-header.component';



@NgModule({
  declarations: [
    FffMyAccountDrawerBodyComponent,
    FffMyAccountDrawerHeaderComponent
  ],
  imports: [
    CommonModule,
    IconModule,
    GenericLinkModule,
    I18nModule,
    FffDrawerModule,
    FffChangeAccountDrawerModule
  ],
})
export class FffMyAccountDrawerModule { }
