import { Component, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
} from '@angular/forms';
import { FffCommunicationService } from '@app/fff-enterprise/fff-common-services/fff-communication.service';
import { FILLED_ICON_TYPE } from '@app/models/fff-filled-icons.model';
import { OUTLINED_ICON_TYPE } from '@app/models/fff-outline-icons.model';
import { FFFCustomFormValidators } from '@app/shared/components/form/fff-form-validators';
import { FffDrawerService } from '@app/shared/drawer/fff-drawer.service';
import { FffUserAccountService } from '@app/shared/services/fff-user-account.service';
import { ToastService } from '@app/shared/services/toast.service';
import { CsAgentAuthService } from '@spartacus/asm/root';
import { RoutingService } from '@spartacus/core';
import { CustomFormValidators } from '@spartacus/storefront';
import { Subject } from 'rxjs';
import { take, tap } from 'rxjs/operators';

@Component({
  selector: 'fff-update-personal-info',
  templateUrl: './fff-update-personal-info.component.html',
})
export class FffUpdatePersonalInfoComponent implements OnInit {
  updatePersonalInfoForm: UntypedFormGroup | undefined;

  destroy$: Subject<boolean> = new Subject<boolean>();
  public account: any | undefined;
  public node: any | undefined;
  public user: any | undefined;
  public outlinedIconTypes = OUTLINED_ICON_TYPE;
  filledIconTypes = FILLED_ICON_TYPE;
  public isOpen: Boolean = false;
  public showCurrentPassword: boolean = false;
  public showNewPassword: boolean = false;
  public showConfirmPassword: boolean = false;
  hasAgentLogged: boolean = false;

  constructor(
    protected fb: UntypedFormBuilder,
    protected router: RoutingService,
    private drawerService: FffDrawerService,
    private communicationService: FffCommunicationService,
    private fffUserAccountService: FffUserAccountService,
    private csAgentAuthService: CsAgentAuthService,
    private toastService: ToastService
  ) {}

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
    this.updatePersonalInfoForm!.get;
  }

  ngOnInit(): void {
    this.communicationService.getPersonalInfo().subscribe((data: any) => {
      if (data) {
        this.updatePersonalInfoForm = this.fb.group(
          {
            firstName: [
              { value: data.firstName, disabled: false },
              FFFCustomFormValidators.nameValidator
            ],
            lastName: [
              { value: data.lastName, disabled: false },
              FFFCustomFormValidators.nameValidator
            ],
            phoneNumber: [
              { value: data.contactNumber, disabled: false },
              FFFCustomFormValidators.phoneValidator,
            ],
            email: [
              { value: data.email, disabled: true },
              CustomFormValidators.emailValidator,
            ],
            currentPassword: [''],
            newPassword: [''],
            confirmPassword: [''],
          },
          {
            validators: CustomFormValidators.passwordsMustMatch(
              'newPassword',
              'confirmPassword'
            ),
          }
        );
        this.node = data;
      }
    });

    this.csAgentAuthService
      .isCustomerSupportAgentLoggedIn()
      .pipe(
        take(1),
        tap((isCustomerSupportAgentLoggedIn: any) => {
          this.hasAgentLogged = !!isCustomerSupportAgentLoggedIn;
        })
      )
      .subscribe();
  }

  submitForm() {
    if (this.updatePersonalInfoForm!.valid) {
      const newRequestData = this.collectDataFromRegisterForm(
        this.updatePersonalInfoForm!.value
      );
      const registerAddrRequest$ =
        this.communicationService.updatePersonalInfo(newRequestData);
      registerAddrRequest$.subscribe(
        () => {
          this.fffUserAccountService.loadUserState();
          this.drawerService.closeDrawer({ 
            updated: true,
          });

          this.toastService.showSuccess(
            'fffAccount.fffUpdatePersonalInfo.myAccountUpdatedConfirmation',
            {
              delay: 3000,
            }
          );
        },
        () => this.handleError(this.updatePersonalInfoForm!)
      );
    } else {
      this.updatePersonalInfoForm!.markAllAsTouched();
    }
  }

  private handleError(updatePersonalInfoForm: UntypedFormGroup): void {
    updatePersonalInfoForm
      .get('currentPassword')
      ?.setErrors({ currentPasswordInvalid: true });
  }

  openChangePassword() {
    this.isOpen = !this.isOpen;

    if (this.isOpen) {
      this.updatePersonalInfoForm
        ?.get('currentPassword')
        ?.addValidators(FFFCustomFormValidators.passwordValidator);
      this.updatePersonalInfoForm
        ?.get('currentPassword')
        ?.updateValueAndValidity();
      this.updatePersonalInfoForm
        ?.get('newPassword')
        ?.addValidators(FFFCustomFormValidators.passwordValidator);
      this.updatePersonalInfoForm?.get('newPassword')?.updateValueAndValidity();
      this.updatePersonalInfoForm
        ?.get('confirmPassword')
        ?.addValidators(FFFCustomFormValidators.passwordValidator);
      this.updatePersonalInfoForm
        ?.get('confirmPassword')
        ?.updateValueAndValidity();
    } else {
      this.updatePersonalInfoForm
        ?.get('currentPassword')
        ?.removeValidators(FFFCustomFormValidators.passwordValidator);
      this.updatePersonalInfoForm
        ?.get('currentPassword')
        ?.updateValueAndValidity();
      this.updatePersonalInfoForm
        ?.get('newPassword')
        ?.removeValidators(FFFCustomFormValidators.passwordValidator);
      this.updatePersonalInfoForm?.get('newPassword')?.updateValueAndValidity();
      this.updatePersonalInfoForm
        ?.get('confirmPassword')
        ?.removeValidators(FFFCustomFormValidators.passwordValidator);
      this.updatePersonalInfoForm
        ?.get('confirmPassword')
        ?.updateValueAndValidity();
    }
  }

  enableCurrentPassword(): void {
    this.showCurrentPassword = !this.showCurrentPassword;
  }

  enableNewPassword(): void {
    this.showNewPassword = !this.showNewPassword;
  }

  enableConfirmPassword(): void {
    this.showConfirmPassword = !this.showConfirmPassword;
  }

  collectDataFromRegisterForm(formData: any): any {
    const { firstName, lastName, phoneNumber, currentPassword, newPassword } =
      formData;
    return {
      changePassword: this.isOpen,
      firstName: firstName,
      lastName: lastName,
      phoneNumber: phoneNumber,
      oldPassword: currentPassword,
      password: newPassword,
      fffAccountNumber: this.node.orgUnit.uid,
      uid: this.node.uid,
    };
  }

  public errorForm(field: string): UntypedFormControl {
    return this.updatePersonalInfoForm!.get(field) as UntypedFormControl;
  }
}
