import { ChangeDetectorRef, Component, Input } from '@angular/core';
import { BASE_URL_KEYS } from '@config/content/constants';
import { OUTLINED_ICON_TYPE } from 'src/app/models/fff-outline-icons.model';
import { FffCommunicationService } from '../../fff-common-services/fff-communication.service';

@Component({
  selector: 'fff-product-details',
  templateUrl: './fff-product-details.component.html',
})
export class FffProductDetailsComponent {
  @Input() product: any | undefined;
  @Input() allocation: any;
  @Input() activeSite: string | undefined;
  @Input()
  ndcFields: any;
  allocResults: any;

  outlinedIconTypes = OUTLINED_ICON_TYPE;
  barSize = 224;
  hideDetails: boolean = true;
  barProp: number = 0;
  BASE_URL_KEYS = BASE_URL_KEYS;
  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private communicationService: FffCommunicationService
  ) {}

  amountOfFieldsShown = 4;

  getBarProp(a: number, b: number) {
    if (a == null || b == null || +a == 0 || +b == 0) {
      return 0;
    }
    return (+a / +b) * this.barSize;
  }

  async ngOnInit() {
    let allocExists =
      Object.keys(this.allocation ? this.allocation : {}).length > 0;

    if (allocExists) {
      this.allocResults = this.allocation ? this.allocation : {};
      this.barProp =
        +this.allocResults?.yearlyAllocatedQty -
        +this.allocResults?.yearlyRemainingQty;
      this.changeDetectorRef.detectChanges();
    }
  }

  showDetails() {
    this.hideDetails = !this.hideDetails;
  }
}
