import { Component, OnInit } from '@angular/core';
import { OUTLINED_ICON_TYPE } from '@app/models/fff-outline-icons.model';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'fff-checkout-recurring-orders-popup',
  templateUrl: './fff-checkout-recurring-orders-popup.component.html',
})
export class FffCheckoutRecurringOrdersPopupComponent implements OnInit {

  hasErrorData: boolean = false;
  errorData: any = {};

  outlinedIconTypes = OUTLINED_ICON_TYPE;

  constructor(
    private modalService: NgbActiveModal,
  ) { }

  ngOnInit(): void { }

  goToHomePage(): void {
    this.modalService.close({ goToHomePage: true });
  }

  close(): void {
    this.modalService.close({ goToHomePage: !this.hasErrorData });
  }

}
