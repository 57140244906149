<div class="nav-accordion-wrap" *ngIf="items">
    <div *ngIf="!child; else subMenu">

      <div *ngIf="userLoggedIn && myAccount"
        class="heading main-heading body-text-demi-xl d-flex justify-content-between" (click)="openSubMenu(myAccount)">
        <div class="myAccount align-items-left d-flex">
          <h5>{{myAccount.title}}</h5>

          <div *ngIf="fetchMedals(myAccount.title , notifications) as fetchedMedal" class="badge text-center">
            {{ fetchedMedal }}
          </div>

        </div>
        <cx-icon [type]="outlinedIconTypes.ICON_CHEVRON_RIGHT"></cx-icon>
      </div>

      <ng-container *ngIf="items">
        <div class="accordion" *ngFor="let item of items.children">
          <div *ngIf="item.children">
            <div class="heading main-heading body-text-demi-xl d-flex justify-content-between"
              (click)="openSubMenu(item)">
              <h5>{{item.title}}</h5> 
              <cx-icon [type]="outlinedIconTypes.ICON_CHEVRON_RIGHT"></cx-icon>
            </div>
          </div>
          <div *ngIf="!item.children && item.url" class="container-main-heading">
            <cx-generic-link [url]="item.url!" (click)="closeDrawer()">
              <div class="heading main-heading body-text-demi-xl d-flex justify-content-between">
                <h5>{{item.title}}</h5>
                <cx-icon [type]="outlinedIconTypes.ICON_CHEVRON_RIGHT"></cx-icon>
              </div>
            </cx-generic-link>
          </div>
        </div>
      </ng-container>

      <div *ngIf="footer">
        <div class="footerSection">
          <div class="hamburgerFooter body-text-lg">
            {{ 'fffAccount.contactPhone' | cxTranslate }}
          </div>
          <div *ngFor="let option of footer.children">
            <div *ngIf="option.children">
              <div class="hamburgerFooter body-text-demi-xl d-flex justify-content-between"
                (click)="openSubMenu(option)">
                <h5>{{option.title}}</h5>
              </div>
            </div>
            <div *ngIf="!option.children && option.url" class="container-main-heading">
              <cx-generic-link [url]="option.url!" (click)="closeDrawer()">
                <div class="hamburgerFooter body-text-demi-xl d-flex justify-content-between">
                  <h5>{{option.title}}</h5>2
                </div>
              </cx-generic-link>
            </div>
          </div>
          <cx-generic-link [url]="fFFEnterprisesLink.url!" (click)="closeDrawer()">
            <div class="siteLinks body-text-lg">
              <em class="uil uil-arrow-up-right"></em>
              <cx-icon class="cx-icon-mobile icon-md mr-2" [type]="outlinedIconTypes.ICON_ARROW_UP_RIGHT"></cx-icon>
              {{fFFEnterprisesLink.linkName}}
            </div>
          </cx-generic-link>
          <cx-generic-link [url]="bioSupplyLink.url!" (click)="closeDrawer()"
            *ngIf="activeSite === BASE_URL_KEYS.MY_FLU_VACCINE">
            <div class="siteLinks body-text-lg">
              <em class="uil uil-arrow-up-right"></em>
              <cx-icon class="cx-icon-mobile icon-md mr-2" [type]="outlinedIconTypes.ICON_ARROW_UP_RIGHT"></cx-icon>
              {{bioSupplyLink.linkName}}
            </div>
          </cx-generic-link>
          <cx-generic-link [url]="mfvLink.url!" (click)="closeDrawer()" *ngIf="activeSite === BASE_URL_KEYS.BIOSUPPLY">
            <div class="siteLinks body-text-lg">
              <em class="uil uil-arrow-up-right"></em>
              <cx-icon class="cx-icon-mobile icon-md mr-2 " [type]="outlinedIconTypes.ICON_ARROW_UP_RIGHT"></cx-icon>
              {{mfvLink.linkName}}
            </div>
          </cx-generic-link>
        </div>
      </div>
    </div>
  </div>

<ng-template #subMenu>

    <div class="back-btn body-text-xl " (click)="back()">
      <div class="back-text align-items-left d-flex">
        <cx-icon class="cx-icon" id="back-icon" [type]="outlinedIconTypes.ICON_CHEVRON_LEFT"></cx-icon>
        {{ 'fffAccount.back' | cxTranslate }}
      </div>
    </div>
    <h5 class="parentSubMenu body-text-demi-xl">{{child!.title}}</h5>
    <div *ngFor="let option of child!.children!">
      <cx-generic-link [url]="option.url!" (click)="closeDrawer()">
        <div class="subMenu body-text-lg d-flex">
          {{ option.title }}

          <div *ngIf="fetchMedals(option.title , notifications) as fetchedMedal" class="badge text-center">
            {{ fetchedMedal }}
          </div>

        </div>
      </cx-generic-link>
    </div>


  </ng-template>
