import { Component, Input } from '@angular/core';

@Component({
  selector: 'fff-table-loader',
  templateUrl: './fff-table-loader.component.html',
})
export class FFFTableLoaderComponent {
  @Input()
  fields: any[] = [];
}
