import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FffUser } from '@app/models/fff-user.model';
import { FffDrawerService } from '@app/shared/drawer/fff-drawer.service';
import { TranslationService, WindowRef } from '@spartacus/core';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { FffUpdatePersonalInfoComponent } from '../fff-update-personal-info/fff-update-personal-info.component';

@Component({
  selector: 'fff-profile-detail-personal',
  templateUrl: './fff-profile-detail-personal.component.html',
})
export class FffProfileDetailPersonalComponent implements OnInit, OnDestroy {
  @Input() user!: FffUser;
  @Output() isUpdating: EventEmitter<boolean> = new EventEmitter<boolean>(false);
  @Output() setResult: EventEmitter<boolean> = new EventEmitter<boolean>(false);
  drawerTitle = '';
  private subscription: Subscription = new Subscription();
  constructor(
    private drawerService: FffDrawerService,
    private windowRef: WindowRef,
    private translationService: TranslationService
  ) { }

  ngOnInit(): void {
    this.subscription.add(this.translationService.translate('fffAccount.fffUpdatePersonalInfo.title')
      .subscribe(title => {
        this.drawerTitle = title;
      })
    );
  }

  openUpdatePersonalDetailsDrawer() {
    this.drawerService.setContent({
      title: this.drawerTitle,
      paragraph: '',
      class: 'navigation-mobile',
      animation: 'SideRTL',
      component: FffUpdatePersonalInfoComponent as Component,
    });

    this.drawerService.openDrawer();
    this.subscription = this.drawerService.getDrawerResponse
      .pipe(take(1))
      .subscribe(
        (result: any) => {
          this.setResult.emit(!!result?.updated);
          this.isUpdating.emit(false);
          this.removeChatBootFrame();
        },
        () => {
          this.setResult.emit(false);
          this.isUpdating.emit(false);
          this.removeChatBootFrame();
        }
      );
  }

  private removeChatBootFrame(): void {
    this.windowRef.document.querySelectorAll('.ie-div-position-customer-chat').forEach(el => el.remove());
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }
}
