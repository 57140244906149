import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { I18nModule, UrlModule } from '@spartacus/core';
import { FffProductListRefinementModule } from './fff-product-list-refinement/fff-product-list-refinement.module';
import { FffProductListModule } from './fff-product-list/fff-product-list.module';

@NgModule({
  declarations: [
  ],
  imports: [
    UrlModule,
    BrowserModule,
    CommonModule,
    I18nModule,
    FffProductListModule,
    FffProductListRefinementModule
  ],
  providers: []
})
/**
 * Export FffProductList Module.
 * Contains changes for fff-enterprise.
 */
export class FffProductListPageModule { }
