import { fffFetReturns } from '@assets/translations/en/fff-fet-returns';
import {
  fffReturnConfirmation,
  fffReturnEntry,
  fffReturnError,
  fffReturns,
  fffReturnsFooter,
} from '@assets/translations/en/fff-returns';
import { fffAccount } from './fff-account';
import {
  fffAllocations,
  fffAllocationsFilters,
  fffAllocationsHistory,
  fffAllocationsHistoryFilter,
  fffAllocationsTabs,
  fffInteractiveAllocations,
  fffUpdateAllocations,
  fffUpdateAllocationsPopUp,
} from './fff-allocations';
import { fffApprovals } from './fff-approvals';
import {
  fffApprovalsFilterHistory,
  fffApprovalsHistory,
} from './fff-approvals-history';
import { asm } from './fff-asm';
import {
  cart,
  fffAddCart,
  fffCart,
  fffCartHeader,
  fffCartSummary,
  fffEmptySearch,
  fffOrderSummary,
  fffSaveCart,
  fffSuggestedProducts,
} from './fff-cart';
import {
  fffCheckout,
  fffCheckoutPlaceOrder,
  fffCheckoutPrepayment,
  fffRecurringOrders,
  fffRecurringOrdersPopUp,
  fffShipDrawer,
  fffShippingSelector,
} from './fff-checkout';
import { common } from './fff-common';
import { fffContactUs, fffContactUsConfirmation } from './fff-contact-us';
import {
  fffInternalServerPage,
  fffNotFoundPage,
  fffSearchEmptyListPage,
} from './fff-error-pages';
import { fffFacetFilter } from './fff-facet-filter';
import { fffFavourite } from './fff-favourite';
import {
  fffInvoiceFilterHistory,
  fffInvoiceHistory,
  fffInvoiceHistoryDetail,
  fffInvoicePayment,
} from './fff-invoice';
import { fffMiniCart } from './fff-minicart';
import {
  fffCheckoutOrderConfirmation,
  fffOrderHistory,
  fffOrderHistoryDetail,
  fffOrderHistoryFilter,
} from './fff-order';
import {
  fffForgotPassword,
  fffForgotPasswordConfirmation,
} from './fff-password';
import { fffPrebook } from './fff-prebook';
import { fffPriceList } from './fff-price-list';
import {
  fffProduct,
  fffShortDated,
  fffSpecialPrice,
  fffStrength,
} from './fff-product';
import { fffQuickOrder } from './fff-quick-order';
import {
  fffConfirmation,
  fffFirstLoginPopup,
  fffLogin,
  fffRegister,
  fffRegisterConfirmation,
  fffVerification,
} from './fff-register';
import { fffReports } from './fff-reports';
import {
  fffRestoreSavedCartDrawer,
  fffSavedCartDetail,
  fffSavedCarts,
} from './fff-saved-carts';
import { fffSearch } from './fff-search';

export const en = {
  common,
  cart,
  asm,
  fffCart,
  fffAddCart,
  fffCheckoutOrderConfirmation,
  fffCartHeader,
  fffCartSummary,
  fffSaveCart,
  fffSuggestedProducts,
  fffOrderSummary,
  fffProduct,
  fffFacetFilter,
  fffLogin,
  fffRegister,
  fffVerification,
  fffConfirmation,
  fffRegisterConfirmation,
  fffAccount,
  fffSearch,
  fffForgotPassword,
  fffForgotPasswordConfirmation,
  fffCheckout,
  fffCheckoutPlaceOrder,
  fffCheckoutPrepayment,
  fffNotFoundPage,
  fffInternalServerPage,
  fffSearchEmptyListPage,
  fffContactUs,
  fffContactUsConfirmation,
  fffQuickOrder,
  fffPriceList,
  fffSavedCarts,
  fffSavedCartDetail,
  fffRestoreSavedCartDrawer,
  fffEmptySearch,
  fffMiniCart,
  fffOrderHistoryDetail,
  fffOrderHistory,
  fffShippingSelector,
  fffInvoiceHistory,
  fffInvoiceHistoryDetail,
  fffInvoiceFilterHistory,
  fffInvoicePayment,
  fffOrderHistoryFilter,
  fffFirstLoginPopup,
  fffStrength,
  fffShortDated,
  fffSpecialPrice,
  fffApprovals,
  fffApprovalsHistory,
  fffApprovalsFilterHistory,
  fffAllocations,
  fffAllocationsTabs,
  fffAllocationsHistory,
  fffAllocationsHistoryFilter,
  fffAllocationsFilters,
  fffUpdateAllocations,
  fffInteractiveAllocations,
  fffShipDrawer,
  fffReturns,
  fffReturnConfirmation,
  fffReturnEntry,
  fffReturnError,
  fffReturnsFooter,
  fffFetReturns,
  fffUpdateAllocationsPopUp,
  fffRecurringOrders,
  fffRecurringOrdersPopUp,
  fffReports,
  fffPrebook,
  fffFavourite
};
