import { Component } from '@angular/core';
import { FFFCartService } from '@app/fff-enterprise/fff-common-services/fff-cart.service';
import { OUTLINED_ICON_TYPE } from '@app/models/fff-outline-icons.model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SavedCartFacade } from '@spartacus/cart/saved-cart/root';
import { RoutingService } from '@spartacus/core';
import { UserProfileFacade } from '@spartacus/user/profile/root';
import { filter, switchMap, take } from 'rxjs/operators';
import { FffDrawerService } from 'src/app/shared/drawer/fff-drawer.service';
import { FFFSavedCartMessagePopupComponent } from '../fff-saved-cart-message-popup/fff-saved-cart-message-popup.component';
import { FffRestoreSavedCartDrawerService } from './fff-restore-saved-cart-drawer.service';

@Component({
  selector: 'fff-restore-saved-cart-drawer-footer',
  templateUrl: './fff-restore-saved-cart-drawer-footer.component.html',
})
export class FffRestoreSavedCartDrawerFooterComponent {
  outlinedIconTypes = OUTLINED_ICON_TYPE;
  loading: boolean = false;

  constructor(
    private drawerService: FffDrawerService,
    protected routing: RoutingService,
    protected savedCartService: SavedCartFacade,
    private userProfileFacade: UserProfileFacade,
    protected fffCartService: FFFCartService,
    private restoreSavedCartDrawerService: FffRestoreSavedCartDrawerService,
    private modalService: NgbModal
  ) {}

  public restoreCart(): void {
    const savedCart = this.restoreSavedCartDrawerService.savedCart.value;
    if (savedCart) {
      this.loading = true;
      const cloneCartResponse$ = this.userProfileFacade.get().pipe(
        take(1),
        switchMap(user => {
          return this.fffCartService.cloneCart(user?.uid!, savedCart.code!);
        })
      );

      cloneCartResponse$.pipe(take(1)).subscribe(
        (cart: any) => {
          this.savedCartService.restoreSavedCart(cart.savedCartData.code);
          this.savedCartService
            .getRestoreSavedCartProcessSuccess()
            .pipe(
              filter(state => !!state),
              take(1)
            )
            .subscribe(
              res => {
                this.loading = false;
                this.closeDrawer();
                this.routing.go([`/cart`]);
              },
              () => this.showMessagePopup()
            );
        },
        () => this.showMessagePopup()
      );
    }
  }

  private showMessagePopup(): void {
    this.loading = false;
    this.modalService.open(FFFSavedCartMessagePopupComponent, {
      centered: true,
      size: 'md',
      backdrop: 'static',
      modalDialogClass: 'modal-container',
    });
  }

  public closeDrawer(): void {
    this.drawerService.closeDrawer();
  }
}
