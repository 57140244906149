import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { I18nModule, UrlModule } from '@spartacus/core';
import { IconModule } from '@spartacus/storefront';
import { FffDrawerModule } from 'src/app/shared/drawer/fff-drawer.module';
import { FffMyAccountDrawerModule } from '../fff-my-account/fff-my-account-drawer.module';
import { FffTopBarComponent } from './fff-top-bar.component';

@NgModule({
  declarations: [FffTopBarComponent],
  imports: [
    CommonModule,
    UrlModule,
    I18nModule,
    IconModule,
    RouterModule,
    FffDrawerModule,
    FffMyAccountDrawerModule,
  ],
  exports: [FffTopBarComponent],
})
export class FffTopBarModule { }
