import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { RETURN_CONFIRMATION_DATA, ReturnConfirmationState } from "@app/reducers";
import { getProductImage } from '@assets/utils/fff-product-utils';
import { FFFReturnsService } from '@enterprise/fff-common-services/fff-returns.service';
import {
  FffReturnsErrorRequestPopupComponent
} from "@enterprise/fff-returns/fff-returns-error-request-popup/fff-returns-error-request-popup.component";
import { OUTLINED_ICON_TYPE } from '@model/fff-outline-icons.model';
import { FffProfile } from '@model/fff-profile.model';
import { FffReturn, FffReturnEntry } from '@model/fff-return.model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from "@ngrx/store";
import { Product, RoutingService } from '@spartacus/core';
import { MediaContainer } from '@spartacus/storefront';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'fff-fet-returns-request-cart',
  templateUrl: 'fff-fet-returns-request-cart.component.html',
})
export class FffFetReturnsRequestCartComponent implements OnInit, OnDestroy {
  @Input() editingMode: boolean = false;
  @Input() profile: FffProfile | undefined;
  @Input() personalData: FffReturn | undefined;
  private unsubscribe$ = new Subject<void>();
  entriesObserver$ = this.fffReturnService.getEmitFETReturnEntry()
    .pipe(
      takeUntil(this.unsubscribe$)
    );
  loading$ = new Subject();
  entries: FffReturnEntry[] = [];
  DEFAULT_PO_NUMBER: string = 'FLU FET RETURN';
  outlinedIconTypes = OUTLINED_ICON_TYPE;
  refreshList$ = new Subject<FffReturnEntry[]>();
  productData: any = {};

  constructor(
    private store: Store<ReturnConfirmationState>,
    private fffReturnService: FFFReturnsService,
    private modalService: NgbModal,
    private routingService: RoutingService
  ) { }
  ngOnInit() {
    this.entriesObserver$.subscribe((entry) => {
      if (entry) {
        this.checkIfEntryAlreadyExistsInEntriesList(entry);
      }
    });
    this.fffReturnService.emitProductQuantitiesData.subscribe((productData: any) => this.productData = productData);
  }
  getData(product: Product) {
    return getProductImage(product.images) as MediaContainer;
  }
  checkIfEntryAlreadyExistsInEntriesList(newEntry: FffReturnEntry): void {
    const exists = this.entries.find((entry) =>
      entry.product.code === newEntry.product.code &&
      entry.fffAccountNumber === newEntry.fffAccountNumber &&
      entry.batchId === newEntry.batchId);

    exists ?
      this.updateEntryQuantity(newEntry)
      :
      this.addNewEntry(newEntry);
  }
  addNewEntry(newEntry: FffReturnEntry): void {
    this.entries.push(newEntry);
    this.refreshList$.next(this.entries);
  }

  removeItem(index: number, entryToRemove: FffReturnEntry): void {
    const key = entryToRemove.batchId + '_' + entryToRemove.product.code + '_' + entryToRemove.fffAccountNumber;
    if (this.productData[key]) {
      this.entries.splice(index, 1);
      this.productData[key] = { totalQuantity: 0 };
      this.fffReturnService.emitProductQuantitiesData.next(this.productData);
    }
  }

  updateEntryQuantity(newEntry: FffReturnEntry): void {
    this.entries.forEach((entry) => {
      if (entry.product.code === newEntry.product.code &&
        entry.fffAccountNumber === newEntry.fffAccountNumber &&
        entry.batchId === newEntry.batchId) {
        entry.quantity = newEntry.quantity;
      }
    });

    this.refreshList$.next(this.entries);
  }
  submitRequest(): void {
    if (this.editingMode) {
      this.fffReturnService.emitTriggeredSubmitValue(true);
      return;
    }
    if (this.profile && this.personalData) {
      let newFetReturn = { ...this.personalData };
      newFetReturn.returnEntries = this.entries;
      this.loading$.next(true);
      this.fffReturnService.submitFETReturn(this.profile.user.uid, newFetReturn).subscribe(response => {
        if (response) {
          const ordersWithError = response.orders.filter((order: any) => !order.isSuccess);
          const ordersSuccess = response.orders.filter((order: any) => order.isSuccess);

          if (ordersWithError.length > 0 || ordersSuccess.length === 0) {
            this.showErrorReturnRequest(response);

          } else {
            this.goToReturnConfirmation(response);
          }
        } else {
          this.showErrorReturnRequest(response);
        }
      }, error => {
        this.showErrorReturnRequest(error);
      });
    }
  }
  private goToReturnConfirmation(response: any): void {
    this.store.dispatch({ type: RETURN_CONFIRMATION_DATA, payload: response });
    this.loading$.next(false);
    this.routingService.go(['/my-account/returns/confirmation']);
  }
  private showErrorReturnRequest(response: any): void {
    this.loading$.next(false);

    const modalRef = this.modalService.open(FffReturnsErrorRequestPopupComponent, {
      centered: true,
      size: 'md',
      backdrop: 'static',
      modalDialogClass: 'modal-container',
    });
    const ordersErrorCodes: string[] = this.getErrorsFromRequest(response);

    modalRef.componentInstance.hasErrorData = ordersErrorCodes.length > 0;
    modalRef.componentInstance.errorData = { code: `${ordersErrorCodes.toString()}` };
  }
  private getErrorsFromRequest(response: any): any[] {
    const errorCodes: string[] = [];
    if (response && response.orders) {
      response.orders.forEach((tx: any) => {
        if (!tx.isSuccess) {
          errorCodes.push(tx.integrationResponseMessage);
        }
      });
    }
    return errorCodes;
  }
  cancelRequest() {
    this.entries = [];
    this.refreshList$.next([]);
    this.fffReturnService.emitFetReturnsFormReset(true);
  }
  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
