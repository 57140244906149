<div
  class="fff-prebook-header__body"
  [ngClass]="{
    'fff-prebook-header__body--disabled': saving,
    stepsIndicatorBar: isScrolledDown,
    asmPrebookHeader: isASMUser
  }"
>
  <div
    class="line-background"
    [ngClass]="{
      bgLinenScrollDown: isScrolledDown && isASMUser
    }"
  ></div>
  <div
    class="progress-line"
    #progressLine
    [ngClass]="{
      progressLinenScrollDown: isScrolledDown && isASMUser
    }"
  ></div>
  <a
    href="#"
    class="fff-prebook-header__item step"
    #stepIcon
    *ngFor="let item of navItems; let i = index"
    [ngClass]="{
      active: item.isReachedStep,
      stepsIndicatorOnScrollDown: isScrolledDown,
      asmStepsIndicatorOnScrollDown: isScrolledDown && isASMUser
    }"
    (click)="navigateToPage($event, item)"
  >
    <div class="fff-prebook-header__item-icon">
      <ng-container *ngIf="item.icon.visible; else stepsTemplate">
        <img
          [attr.src]="item.icon.src"
          [attr.alt]="i + 1"
          width="45"
          class="fff-prebook-header__item-icon-image fff-prebook-header__item-icon-image--default"
        />
        <img
          [attr.src]="item.icon.activeSrc"
          [attr.alt]="i + 1"
          width="45"
          class="fff-prebook-header__item-icon-image fff-prebook-header__item-icon-image--active"
        />
      </ng-container>
      <ng-template #stepsTemplate>
        {{ i + 1 }}
      </ng-template>
    </div>
    <div class="fff-prebook-header__item-label">
      {{ item.labelI18n | cxTranslate }}
    </div>
  </a>
</div>
