<ng-container *ngIf="!cart || !cart.entries || cart.entries.length==0">
  <h3 class="cart-summary-title">{{ 'fffQuickOrder.yourCart'| cxTranslate }} (0)</h3>
  <hr>
  <div class="d-flex align-items-center flex-column no-items">
    <h4 class="text-center">{{ 'fffQuickOrder.noItems'| cxTranslate }}<br>{{ 'fffQuickOrder.inYourShoppingCart'|
      cxTranslate }}</h4>
    <cx-icon class="cx-icon icon-sm" [type]="customIconTypes.ORDER_HISTORY"></cx-icon>
    <button class="btn btn-primary">{{ 'fffQuickOrder.continueShopping'| cxTranslate }}</button>

    <div class="custom-care w-100">
      <p><b> {{ 'fffQuickOrder.customCare'| cxTranslate }} </b></p>
    </div>

  </div>
</ng-container>

<ng-container *ngIf="!(!cart || !cart.entries || cart.entries.length==0) && profile">
  <h3 class="cart-summary-title">{{ 'fffQuickOrder.yourCart'| cxTranslate }} ({{cart.totalItems}})</h3>
  <hr>
  <ng-container *ngFor="let entry of cart.entries">

    <fff-cart-item [entry]="entry" [accountUid]="profile.selected.uid"></fff-cart-item>

  </ng-container>

  <div class="entry subtotal-section">

    <ng-container *ngIf="user$ | async as user">
      <form *ngIf="jeffersonCheckoutForm" [formGroup]="jeffersonCheckoutForm">
        <div class="d-flex flex-column" *ngIf="isDuplicate$ | async else newPoNumber">
          <div class="d-flex justify-content-between">
            <p class="mb-0 body-text-lg">{{'fffCheckout.poNumber' | cxTranslate}}</p>
            <p class="mb-0 body-text-xxs">Required</p>
          </div>
          <input class="form-control form-control-md warning-input" type="text" formControlName="poNumber"
            (blur)="checkPoNumber(user)">
          <p class="warningPoNumber body-text-sm d-flex">
            <cx-icon class="cx-icon icon-xs pe-auto warning-icon " [type]="filledIconTypes.ICON_CIRCLE_FILL">
            </cx-icon>
            {{'fffCheckout.poDuplicate' | cxTranslate}}
          </p>
        </div>
        <ng-template #newPoNumber>
          <div class="d-flex justify-content-between">
            <p class="mb-0 body-text-lg">{{'fffCheckout.poNumber' | cxTranslate}}</p>
            <p *ngIf="!obligatoryPo" class="mb-0 body-text-xxs">{{'fffCheckout.optional' | cxTranslate}}</p>
            <p class="mb-0 body-text-xxs">Required</p>
          </div>
          <input class="form-control form-control-md" type="text" formControlName="poNumber"
            (blur)="checkPoNumber(user)">
          <p class="mb-0" style="font-size: 11px">
            <span *ngIf="poRules.startsWith">{{'fffCheckout.legend1' | cxTranslate}} {{maxLength}}
              {{'fffCheckout.legend2'
              |
              cxTranslate}} {{formatStartingPOs(poRules.startsWith)}}.</span>
            <span *ngIf="!poRules.startsWith">{{'fffCheckout.legend3' | cxTranslate}}{{maxLength}}
              {{'fffCheckout.legend4'
              |
              cxTranslate}}</span>
          </p>

          <cx-form-errors aria-live="assertive" aria-atomic="true"
            [control]="$any(jeffersonCheckoutForm).get('poNumber')">
          </cx-form-errors>
        </ng-template>
      </form>
    </ng-container>
    <div class="d-flex justify-content-between mb-3">
      <p class="body-text-demi-lg">{{ 'fffQuickOrder.subtotal'| cxTranslate }}</p>
      <p class="body-text-demi-lg">{{cart.subTotal.formattedValue}}</p>
    </div>
    <div class="cx-dialog-buttons mb-3">
      <button class="btn btn-primary btn-lg m-auto"
        (click)="submitJeffersonHealthOrder(cart.code, $any(jeffersonCheckoutForm).get('poNumber'))"
        [class.updating]="loading" [disabled]="loading || jeffersonCheckoutForm?.invalid">
        {{ 'fffQuickOrder.submitRequest'| cxTranslate }}
        <cx-icon *ngIf="loading" class="cx-icon icon-md ml-3" [type]="outlinedIconTypes.ICON_ARROW_REPEAT"></cx-icon>
      </button>
    </div>
    <p (click)="openCustomCarePhone()" class="d-flex justify-content-center align-items-center m-top-3"><b> {{
        'fffQuickOrder.customCare'| cxTranslate }} </b></p>
  </div>


</ng-container>