import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CmsConfig, ConfigModule, I18nConfig, I18nModule, provideConfig, UrlModule } from '@spartacus/core';
import { IconModule } from '@spartacus/storefront';
import { fffTranslationOverwrites } from 'src/assets/translations/en/fff-register';
import { FffErrorPagesRoutingModule } from './fff-error-pages-routing.module';
import { FffInternalServerComponent } from './fff-internal-server/fff-internal-server.component';
import { FffNotFoundComponent } from './fff-not-found/fff-not-found.component';


@NgModule({
  declarations: [
    FffNotFoundComponent,
    FffInternalServerComponent
  ],
  providers: [
    provideConfig(<I18nConfig>{
      i18n: {
        resources: fffTranslationOverwrites,
      },
    })
  ],
  imports: [
    CommonModule,
    IconModule,
    I18nModule,
    UrlModule,
    RouterModule,
    FffErrorPagesRoutingModule,
    ConfigModule.withConfig({
      cmsComponents: {
        PageNotFoundFlexComponent: {
          component: FffNotFoundComponent
        },
      },
    } as CmsConfig),
  ],
  exports: [FffNotFoundComponent]
})
export class FffErrorPagesModule { }
