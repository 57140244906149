<div *ngIf="allocationsList$ | async as allocationHistoryList; else loadingTemplate">

  <fff-table [hasPaginator]="true" [data]="getTableData(allocationHistoryList)" [fields]="fields" [hasTemplate]="true"
    [itemTemplate]="allocationItem" [columnsGrid]="columnsGrid" (pageChange)="pageChange($event)"
    [customClass]="'user-price-list'" [size]="getTableSizeFromPagination(allocationHistoryList)">
  </fff-table>

  <ng-template #allocationItem let-item="item">
    <div class="interactive-allocations-item td">

      <div class="px-0 col-12 order-2 order-lg-1">
        <div class="col-12 px-0 d-block d-lg-none pb-2 pt-3">{{ 'fffInteractiveAllocations.productName' | cxTranslate }}
        </div>
        <div>{{ item.productName }}</div>
      </div>

      <div class="px-0 col-6 col-lg-12 order-4">
        <div class="d-lg-block">
          <div class="col-12 px-0 d-block d-lg-none pb-2 pt-3">{{ 'fffInteractiveAllocations.size' | cxTranslate }}
          </div>
          <div>{{ item.productSize }}</div>
        </div>
      </div>
      <div class="px-0 col-6 col-lg-12 order-4">
        <div class="d-lg-block">
          <div class="col-12 px-0 d-block d-lg-none pb-2 pt-3">{{ 'fffInteractiveAllocations.sku' | cxTranslate }}</div>
          <div>{{ ('fffInteractiveAllocations.' + (+(item?.action) > 1 ? 'hard' : 'soft')) | cxTranslate }}</div>
        </div>
      </div>
      <div class="px-0 col-6 col-lg-12 order-4">
        <div class="d-lg-block">
          <div class="col-12 px-0 d-block d-lg-none pb-2 pt-3">{{ 'fffInteractiveAllocations.sku' | cxTranslate }}</div>
          <div>{{ item.productCode }}</div>
        </div>
      </div>
      <div class="px-0 col-6 col-lg-12 order-4">
        <div class="d-lg-block">
          <div class="col-12 px-0 d-block d-lg-none pb-2 pt-3">{{ 'fffInteractiveAllocations.ndc' | cxTranslate }}</div>
          <div>{{ item.productNdc }}</div>
        </div>
      </div>
      <div class="px-0 col-lg-12 order-4">
        <div class="d-lg-block">
          <div class="col-12 px-0 d-none d-lg-none pb-2 pt-3">{{ 'fffInteractiveAllocations.action' | cxTranslate }}
          </div>
          <button class="btn btn-tertiary update-allocations-button text-left line p-0 w-100 mt-3 mt-lg-0"
            (click)="updateAllocations(item)">
            <span class="text-underline">{{ 'fffInteractiveAllocations.update' | cxTranslate
              }}&nbsp;<wbr>{{'fffInteractiveAllocations.title' | cxTranslate}}</span>
          </button>
        </div>
      </div>
    </div>
  </ng-template>

</div>


<ng-template #loadingTemplate>
  <fff-table-loader [fields]="fields"></fff-table-loader>
</ng-template>