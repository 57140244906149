<ng-container *ngIf="(total$ | async) === 0">
  <div class="container py-3">
    <div class="row d-flex justify-content-center align-items-center m-auto px-0 pt-5">

      <div class="col-md-12 d-flex flex-wrap justify-content-center align-items-center">
        <h3 class="col-12 h3 text-center" *ngIf="resolveMessage() | async as headerMessage;">{{ headerMessage }}</h3>
        <cx-icon class="col-12 cx-icon icon-xl pe-auto" [type]="customIconTypes.ICON_PAGE_NOT_FOUND"></cx-icon>
      </div>

      <div class="col-xs-12 col-md-8">
        <div class="col-12 mt-4 mb-5">
          <label class="actions text-center">{{ 'fffSearchEmptyListPage.message' | cxTranslate }}</label>
        </div>

        <div class="col-12 mb-5 d-flex justify-content-center">
          <button class="btn btn-primary btn-md" (click)="goToHomePage()">{{ 'fffSearchEmptyListPage.returnHomePage' |
            cxTranslate }}</button>
        </div>
      </div>

    </div>
  </div>
  <cx-page-slot position="Section5"></cx-page-slot>
</ng-container>
