import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FffCommunicationService } from '@app/fff-enterprise/fff-common-services/fff-communication.service';
import { FffOrderFacade } from '@app/fff-enterprise/fff-common-services/fff-order-facade';
import { FFFOrderEntry } from '@app/models/fff-cart-data.model';
import { FILLED_ICON_TYPE } from '@app/models/fff-filled-icons.model';
import { OUTLINED_ICON_TYPE } from '@app/models/fff-outline-icons.model';
import { BASE_URL_KEYS } from '@config/content/constants';
import { FffResendOrderConfirmationDrawerComponent } from '@enterprise/fff-order/fff-order-history/fff-resend-order-confirmation-drawer/fff-resend-order-confirmation-drawer.component';
import { FffDrawerService } from '@shared/drawer/fff-drawer.service';
import { BaseSiteService, RoutingService, WindowRef } from '@spartacus/core';
import { MediaContainer } from '@spartacus/storefront';
import { UserAccountFacade } from '@spartacus/user/account/root';
import { Observable } from 'rxjs';
import { distinctUntilChanged, map, switchMap } from 'rxjs/operators';
import { getProductImage } from 'src/assets/utils/fff-product-utils';

@Component({
  selector: 'fff-order-history-detail',
  templateUrl: './fff-order-history-detail.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FffOrderHistoryDetailComponent {
  outlinedIconTypes = OUTLINED_ICON_TYPE;
  filledIconTypes = FILLED_ICON_TYPE;
  showWarning: boolean = true;
  confirmationSend: boolean = false;
  private CONSIGNMENT_SHIPPED_STATUS: string = 'SHIPPED';
  activeSite$ = this.baseSiteService.getActive();
  BASE_URL_KEYS = BASE_URL_KEYS;
  orderDetail$ = this.routing
    .getRouterState()
    .pipe(
      map(routingData => routingData.state.params.orderCode),
      distinctUntilChanged()
    )
    .pipe(
      switchMap(orderCode => {
        return this.orderFacade.getOrderDetails(orderCode);
      })
    );

  userData$: Observable<any> = this.userAccountDetails.get();
  b2bUnitData$: Observable<any> = this.userData$.pipe(
    switchMap(user => {
      return this.communicationService.getCurrentAccount(user);
    })
  );

  constructor(
    private baseSiteService: BaseSiteService,
    private userAccountDetails: UserAccountFacade,
    private communicationService: FffCommunicationService,
    protected routing: RoutingService,
    private activatedRoute: ActivatedRoute,
    private orderFacade: FffOrderFacade,
    public windowRef: WindowRef,
    public drawerService: FffDrawerService
  ) {
    let currentPage = 0;
    if (this.activatedRoute.snapshot.queryParams['page']) {
      currentPage = this.activatedRoute.snapshot.queryParams['page'];
    }

    history.pushState(null, '', location.href);
    window.onpopstate = function () {
      history.pushState(null, '', location.href);
      window.location.href = `/my-account/orders?page=${currentPage}`;
    };
  }

  getShippingAddress(b2bUnitData: any): string {
    if (b2bUnitData.shippingAddress) {
      return b2bUnitData.shippingAddress.formattedAddress;
    }
    return '';
  }

  getEntries(orderDetail: any): FFFOrderEntry[] {
    return orderDetail.entries;
  }

  getData(product: any) {
    return getProductImage(product.images) as MediaContainer;
  }

  hasShippingInformation(item: any): boolean {
    var shippingConsignments = item.consignments?.filter(
      (c: any) => this.CONSIGNMENT_SHIPPED_STATUS == c.status
    );
    return shippingConsignments?.length > 0;
  }

  getShippingInformation(item: any): any[] {
    return item.consignments.filter(
      (c: any) => this.CONSIGNMENT_SHIPPED_STATUS == c.status
    );
  }

  openTrackingTab(url: string): void {
    if (this.windowRef.isBrowser()) {
      this.windowRef.nativeWindow?.open(url, '_blank');
    }
  }

  resendConfirmation(orderId: string): void {
    this.confirmationSend = true;
    this.drawerService.setContent({
      title: 'Send Order Confirmation Copy',
      component: FffResendOrderConfirmationDrawerComponent,
      animation: 'SideRTL',
      class: 'order_history',
      data: orderId,
    });
    this.drawerService.openDrawer();
  }

  parseDate(date: string) {
    return +date.split('/Date(')[1].split(')/')[0];
  }

  calculateEstStartDate(estArrivalDate: any): Date {
    if (estArrivalDate) {
      let estStartDate = new Date(estArrivalDate);
      estStartDate.setHours(0);
      estStartDate.setDate(estStartDate.getDate() - 14);
      return estStartDate;
    }
    return new Date();
  }

  getPaymentTerms(order: any): string {
    if (order.entries && order.entries.length > 0) {
      return order.entries[0].paymentTermsDescItem;
    }
    return '';
  }
  getTax(item: any): number {
    if (item.taxValue) {
      return item.quantity * item.taxValue;
    }
    return 0;
  }
}
