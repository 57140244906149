import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { FffInvoicePaymentService } from '@app/fff-enterprise/fff-common-services/fff-invoice-payment.service';
import { INVOICE_PAYMENT_STEPS } from '@app/models/fff-invoice.model';
import { OUTLINED_ICON_TYPE } from '@app/models/fff-outline-icons.model';
import { FffUser } from '@app/models/fff-user.model';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';

@Component({
  selector: 'fff-invoice-payment-drawer',
  templateUrl: './fff-invoice-payment-drawer.component.html',
  styleUrls: ['./fff-invoice-payment-drawer.component.scss'],
})
export class FffInvoicePaymentDrawerComponent implements OnInit {
  @Input() invoices: any[] = [];
  @Input() newCreditCardAdded: boolean = false;

  outlinedIconTypes = OUTLINED_ICON_TYPE;

  totalInvoices: number = 0;
  contactUser!: FffUser;
  totalAmount: number = 0;

  currentStep: number = 0;
  steps = INVOICE_PAYMENT_STEPS;
  subs = new Subscription();
  printConfirmationClick!: Subscription;

  constructor(
    private fffInvoicePaymentService: FffInvoicePaymentService,
    private activeModalService: NgbActiveModal,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.fffInvoicePaymentService.setInvoices(this.invoices);
    this.loadContactInfo();
    this.updateInvoicesDetails();

    this.subs.add(
      this.fffInvoicePaymentService.getCurrentStep().subscribe(step => {
        this.currentStep = step;
        this.cd.markForCheck();
      })
    );
    this.fffInvoicePaymentService.getPrintEvent().subscribe(() => {
      this.printConfirmation();
    });
  }
  printConfirmation() {
    const printContents = document.getElementById('printSection')!.innerHTML;
    const popupWin = window.open('', '_blank', 'width=800,height=600');

    if (!popupWin) {
      console.error('Popup blocked. Please allow popups for this site.');
      return;
    }

    const styles = Array.from(document.styleSheets)
      .map(styleSheet => {
        try {
          return Array.from(styleSheet.cssRules)
            .map(rule => rule.cssText)
            .join('');
        } catch (e) {
          console.warn('Could not access stylesheet rules', styleSheet, e);
          return '';
        }
      })
      .join('\n');

    popupWin.document.open();
    popupWin.document.write(`
        <html>
          <head>
            <title>Print</title>
            <style>
              ${styles}
            </style>
            <style>
              @media print {
                body * {
                  visibility: hidden;
                }
                #printSection, #printSection * {
                  visibility: visible;
                }
                .notPrintable {
                  display: none;
                }
                .confirmation-title {
                  color: 004d8a;
                }
                .confirmation-popup-card-type {
                  color: #5f5f5f;
                  font-size: 14px,
                  font-weight: normal;
                }
                .payment-status {
                  color: #5f5f5f;
                }
                .rowForPrint {
                  height: 15px;
                  max-height: 15px;
                  min-height: unset;
                }
                .totalAmntForPrint {
                  position: relative;
                  left: 30px;
                  font-size: 11px;
                  text-align: left;
                }  
                td {
                  font-size: 11px;
                  padding-left: 2px
                }
                th {
                  font-size: 11px;
                  padding-left: 2px
                }  
                tr {
                  height: 15px;
                  max-height: 15px;
                  min-height: unset;
                }
               
                #printSection {
                  position: absolute;
                  left: 0;
                  top: 0;
                  width: 100%;
                }
                html, body {
                  overflow: visible !important;
                }
              }
            </style>
          </head>
          <body>
            <div id="printSection">${printContents}</div>
          </body>
        </html>
    `);
    popupWin.document.close();
    popupWin.focus();

    setTimeout(() => {
      popupWin.print();
      popupWin.close();
    }, 500);
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  closeAndReset() {
    this.activeModalService.close({
      reset: true,
      paymentCompleted: this.currentStep === this.steps.CONFIRMATION,
    });
  }

  dismiss() {
    this.activeModalService.dismiss();
  }

  navigateToSelectCard() {
    this.fffInvoicePaymentService.setCurrentStep(
      INVOICE_PAYMENT_STEPS.SELECT_CARD
    );
  }

  loadContactInfo() {
    this.fffInvoicePaymentService.getOrgUser().subscribe(res => {
      this.contactUser = res;
      this.cd.markForCheck();
    });
  }

  updateInvoicesDetails() {
    this.totalInvoices = this.invoices.length;
    this.totalAmount = this.invoices
      .map(v => +v.grandTotal || 0)
      .reduce((prev, curr) => prev + curr, 0);

    this.cd.markForCheck();
  }

  onInvoiceRemoved(invoiceNumber: string) {
    this.invoices = this.invoices.filter(
      (i: any) => i.invoiceNumber !== invoiceNumber
    );

    this.fffInvoicePaymentService.setInvoices(this.invoices);

    if (!this.invoices.length) {
      this.closeAndReset();
    }

    this.updateInvoicesDetails();
  }
}
