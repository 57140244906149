<div class="d-flex justify-content-center" *ngIf="!entry">
  <cx-icon class="cx-icon" [type]="outlinedIconTypes.ICON_CLOCK"></cx-icon
  >&nbsp;{{ 'fffCart.loading' | cxTranslate }}
</div>

<ng-container *ngIf="entry">
  <ng-container>
    <div class="d-flex cart-price-container price-row pt-2 pb-2 pl-3 pr-3">
      <div class="d-flex flex-wrap row-split-billing pb-3">
        <div
          class="col-4 px-0 flex-grow-1 col-unit-quantity input-price d-flex align-items-center flex-column justify-content-center"
        >
          <div class="qty body-text-sm">
            {{ 'fffProduct.lblQty' | cxTranslate }}
          </div>
          <div class="input-item">
            <fff-tooltip
              *ngIf="isInvalidQty(entry, entry.quantity)"
              [text]="
                tooltipMessage !== '' && tooltipMessage.includes('qty')
                  ? (tooltipMessage
                    | cxTranslate
                      : { mdu: priceData.price.mdu | number : '1.0-0' })
                  : (tooltipMessage | cxTranslate : { min: min, uom: basicUOM })
              "
              class="warn-tooltip"
            ></fff-tooltip>
            <fff-tooltip
              class="warn-tooltip"
              *ngIf="checkAllocation()"
              text="{{ 'fffProduct.exceedsAlloc' | cxTranslate }}"
            ></fff-tooltip>

            <div>
              <input
                class="text-center"
                [class.cart-updated-input-box]="showCartUpdatedIcon"
                [(ngModel)]="entry.quantity"
                (ngModelChange)="entryQuantityChange($event)"
                (blur)="updateQuantity($event, entry)"
                (keydown.enter)="isPrebookCart && updateQuantity($event, entry)"
                maxlength="4"
                type="text"
                id="qtyItem"
                fffAllowOnlyNumbers
                [disabled]="!e.product.purchasable"
              />
              <i
                *ngIf="showCartUpdatedIcon"
                class="fa fa-xs fa-check text-success icon"
              ></i>
            </div>
          </div>
        </div>

        <div class="col-4 px-0 flex-grow-1 col-unit-price">
          <div>&nbsp;</div>
          <p class="body-text mb-0 eu-price pl-3">
            {{ entry.basePrice?.formattedValue }}/{{ entry?.uom }}
          </p>
          <ng-container *ngIf="entry?.altDiscPrice && entry?.altUom">
            <p class="body-text eu-price pl-3">
              ${{ entry?.altDiscPrice }}/{{ entry?.altUom }}
            </p>
          </ng-container>
        </div>

        <div class="col-4 px-0 col-total-price">
          <div class="text-right">
            {{ 'orderCost.total' | cxTranslate }}
          </div>
          <p class="body-text mb-0 vl-price text-right body-text-demi pl-2">
            {{ entry.totalPrice.formattedValue }}
          </p>
        </div>
      </div>
    </div>
  </ng-container>
</ng-container>
