<div class="col-md-12 register-content contact-us">
  <form [formGroup]="form">

    <div class="col-12 m-auto px-4">
      <label class="summary-row">
        <span class="body-text">{{
          'fffSaveCart.form.cartName' | cxTranslate
          }}</span>
        <input class="form-control form-control-sm" id="cartName" aria-required="true" type="text"
          formControlName="cartName" [placeholder]="'fffSaveCart.form.cartNamePlaceholder' | cxTranslate" />
        <cx-form-errors [control]="$any(form).get('cartName')"></cx-form-errors>
      </label>


      <label class="summary-row">
        <span class="body-text">{{
          'fffSaveCart.form.cartDescription' | cxTranslate
          }}</span>
        <textarea class="form-control form-control-sm body-text" id="cartDescription" aria-required="true" type="email"
          formControlName="cartDescription" maxlength="500"
          [placeholder]="'fffSaveCart.form.cartDescriptionPlaceholder' | cxTranslate"></textarea>
        <cx-form-errors [control]="$any(form).get('cartDescription')"></cx-form-errors>
      </label>

      <div class="summary-row body-text-sm">
        <input class="checkbox checkbox-sm" type="checkbox" id="removeItems" formControlName="removeItems">
        <label class="checkbox-label" for="removeItems">
          {{ 'fffSaveCart.form.removeItems' | cxTranslate }}
        </label>
      </div>

      <div class="summary-row body-text-sm">
        <span>
          {{ 'fffSaveCart.form.info' | cxTranslate }}
        </span>
      </div>
    </div>

  </form>
</div>