import { Injectable } from '@angular/core';
import { SeoMetaService } from '@spartacus/storefront';

@Injectable({
  providedIn: 'root',
})

export class FffSeoMetaService extends SeoMetaService {

  override set description(value: string | undefined) {
    //It seems that setting the description isn't possible on spartacus, so I've killed that functionality so I can implement it myself. 
    //please take a look at fff-product-page-meta-resolver.ts and fff-category-page-meta-resolver.ts 
    return;
  }

}
