import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { FffSavedCartModule } from '@app/spartacus/features/cart/saved-cart/saved-cart.module';
import { FffProductListItemModule } from '@enterprise/fff-product-list-page/fff-product-list/fff-product-list-item/fff-product-list-item.module';
import { AddToCartModule } from '@spartacus/cart/base/components/add-to-cart';
import { QuickOrderListModule } from '@spartacus/cart/quick-order/components';
import {
  CmsConfig,
  ConfigModule,
  I18nModule,
  UrlModule,
} from '@spartacus/core';
import {
  FormErrorsModule,
  IconModule,
  MediaModule,
  ProgressButtonModule,
  SpinnerModule,
} from '@spartacus/storefront';
import { FffCartButtonModule } from '../fff-header/fff-mini-cart/fff-mini-cart.module';
import { FffProductAllocationModule } from '../fff-product-allocation/fff-product-allocation.module';
import { FffProductModule } from '../fff-product/fff-product.module';
import { FffSplitBillingModule } from '../fff-product/fff-split-billing/fff-split-billing.module';
import { FffQuickOrderModule } from '../fff-quick-order/fff-quick-order.module';
import { FffJeffersonCartSummaryComponent } from './fff-jefferson-cart-summary/fff-jefferson-cart-summary.component';
import { FffJeffersonPageComponent } from './fff-jefferson-page/fff-jefferson-page.component';
import { FFFLoadersModule } from '@app/shared/components/fff-loaders/fff-loaders.module';

@NgModule({
  declarations: [FffJeffersonPageComponent, FffJeffersonCartSummaryComponent],
  imports: [
    CommonModule,
    ConfigModule.withConfig({
      cmsComponents: {
        JeffersonPageComponent: {
          component: FffJeffersonPageComponent,
        },
      },
    } as CmsConfig),
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    IconModule,
    I18nModule,
    RouterModule,
    FormErrorsModule,
    MediaModule,
    UrlModule,
    QuickOrderListModule,
    FffProductModule,
    AddToCartModule,
    FffCartButtonModule,
    FffQuickOrderModule,
    FffSplitBillingModule,
    FffProductAllocationModule,
    ProgressButtonModule,
    FffProductListItemModule,
    FffSavedCartModule,
    FFFLoadersModule,
    SpinnerModule,
  ],
})
export class FffJeffersonPageModule {}
