import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { OccEndpointsService, Product } from '@spartacus/core';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class FffRecommendedProductsService {
  constructor(private http: HttpClient, private occ: OccEndpointsService) {}

  getProductRecommendations(cartId: string) {
    const url = this.occ.buildUrl(
      `/orgUsers/current/carts/${cartId}/productRecommendations`
    );
    return this.http
      .get<{ products: Product[] }>(url)
      .pipe(map(res => res?.products || []));
  }
}
