import { Component, TemplateRef, ViewChild } from '@angular/core';

@Component({
  selector: 'fff-splide-slide',
  template:
    '<ng-template #slideContent><ng-content></ng-content></ng-template>',
})
export class FffSplideSlideComponent {
  @ViewChild('slideContent')
  public slideContent!: TemplateRef<any>;
}
