import { Component } from '@angular/core';
import { FffRingCentralChatBotService } from '@enterprise/fff-service-third-party/fff-ring-central-chat-bot.service';
import { RoutingService } from '@spartacus/core';
import { take } from 'rxjs/operators';

@Component({
  selector: 'fff-returns-footer',
  templateUrl: './fff-returns-footer.component.html',
})
export class FffReturnsFooterComponent {
  route$ = this.routing.getRouterState().pipe(take(1));
  constructor(
    private chatbotService: FffRingCentralChatBotService,
    private routing: RoutingService
  ) {
  }
  openLiveChat(): void {
    this.chatbotService.openChatBot();
  }
}
