<div class="product-badge" *ngIf="product">
  <div class="badge-container">
    <ng-container *ngIf="product.labels">
      <div class="product-type" *ngFor="let label of product.labels">
        <span class="type">{{ label }}</span>
      </div>
    </ng-container>
    <div
      *ngIf="(product.manualOnly || showManualOnlyBadge) && activeSite$ | async"
      class="product-type"
    >
      <span class="type">{{
        'fffProduct.badge.manualOnly' | cxTranslate
      }}</span>
    </div>
    <div *ngIf="shouldDisplayContractBadge" class="product-type">
      <span class="type">{{
        'fffProduct.badge.contractProduct' | cxTranslate
      }}</span>
    </div>
    <div
      *ngIf="product.categories && hasProvideGXCategory"
      class="product-type providegx-badge"
    >
      <span class="type">{{ 'fffProduct.badge.provideGX' | cxTranslate }}</span>
    </div>
  </div>
</div>
