<div class="container">

  <div class="user-price-list-header" *ngIf="selectedTab$ | async as selectedAllocationNavLink">

    <div class="d-flex justify-content-between top-title">
      <div class="d-flex">
        <h3 class="m-auto pr-3"> {{ 'fffAllocations.title' | cxTranslate }} </h3>
        <button class="btn btn-secondary btn-xs m-auto" (click)="openFilterDrawer(selectedAllocationNavLink)"
          *ngIf="selectedAllocationNavLink !== fffAllocationNavNodes.currentAllocations">
          {{ 'fffAllocations.filter' | cxTranslate }}
          <cx-icon class="cx-icon icon-sm pl-2" [type]="outlinedIconTypes.ICON_FILTER">
          </cx-icon>
        </button>
      </div>

      <div class="d-flex btn-container" (click)="downloadCSV()"
        *ngIf="selectedAllocationNavLink === fffAllocationNavNodes.currentAllocations">
        <a class="d-flex align-items-center export-btn disabled">
          <span>{{ 'fffPriceList.exportCsv' | cxTranslate }}</span>
          <cx-icon class="cx-icon icon-sm" [type]="outlinedIconTypes.ICON_DOWNLOAD">
          </cx-icon>
        </a>
      </div>

    </div>
    <hr class="sep-table-title">
    <fff-allocations-tabs (selectedNewNavLink)="emitNewSelectedTab($event)"></fff-allocations-tabs>
  </div>

  <ng-container [ngComponentOutlet]="getComponent(selectedTab$ | async)"></ng-container>

</div>