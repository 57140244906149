<div class="row pl-3 mr-0 item-info-container">
  <div class="row w-100 img-container">
    <div class="image col-6 col-lg-4">
      <cx-media
        [container]="imageObject"
        [alt]="product?.summary!"
        format="product"
      ></cx-media>
    </div>
    <div class="col-lg-8 col-6 product-detail">
      <div class="manufacturer">{{ product?.manufacturer }}</div>
      <div>{{ 'fffProduct.lblNdc' | cxTranslate }} {{ product?.ndc }}</div>
      <div>{{ 'fffProduct.lblSku' | cxTranslate }} {{ product?.code }}</div>
      <div
        class="tiered-pricing-link"
        (click)="displayTieredPricing()"
        *ngIf="
          product.tieredPricing && shouldDisplayTieredPriceLabel && !shortDated
        "
      >
        {{ 'fffProduct.showTieredPricing' | cxTranslate }}
      </div>
      <div *ngIf="product?.includedProduct" class="inc_product">
        {{ product?.includedProduct }}
      </div>
      <div
        class="volume body-text-sm mt-4"
        *ngIf="(prices$ | async)![product.code] as price"
      >
        <ng-container
          *ngIf="!isOfVaccineTypeI(product); else vaccineUomTemplate"
        >
          <ng-container
            *ngIf="
              getAltPriceI(price[0].price.priceMap, product?.altUnit) &&
              getAltPriceI(price[0].price.priceMap, product?.altUnit)?.min &&
              getAltPriceI(price[0].price.priceMap, product?.altUnit)?.altUOM
            "
          >
            <span>
              1 {{ price[0].price.basicUOM }} =
              {{ getAltPriceI(price[0].price.priceMap, product?.altUnit)?.min }}
              {{
                getAltPriceI(price[0].price.priceMap, product?.altUnit)?.altUOM
              }}
            </span>
          </ng-container>
        </ng-container>
        <ng-template #vaccineUomTemplate>
          <span class="text-nowrap">
            1 {{ price[0].price.basicUOM }} = 10 DS
          </span>
        </ng-template>
        <ng-container
          *ngIf="
            price[0].price.priceMap[price[0].price.basicUOM]
              ?.additionalConversion
          "
        >
          <span
            *ngIf="
              getAltPriceI(price[0].price.priceMap, product?.altUnit) &&
              getAltPriceI(price[0].price.priceMap, product?.altUnit)?.min &&
              getAltPriceI(price[0].price.priceMap, product?.altUnit)?.altUOM
            "
            >,&nbsp;
          </span>
          {{
            price[0].price.priceMap[price[0].price.basicUOM]
              .additionalConversion
          }}
        </ng-container>
      </div>
    </div>
  </div>

  <fff-product-admin-set-y-connector
    class="col-12 px-0"
    [product]="product"
    [itemAddedToCart]="itemAddedToCart"
  >
  </fff-product-admin-set-y-connector>
</div>
