import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { NgbCollapseModule } from '@ng-bootstrap/ng-bootstrap';
import { CmsConfig, ConfigModule, I18nModule, UrlModule } from '@spartacus/core';
import { IconModule } from '@spartacus/storefront';
import { FffDrawerModule } from 'src/app/shared/drawer/fff-drawer.module';
import { FffFacetFilterDrawerModule } from '../fff-product-list/fff-facet-filter-drawer/fff-facet-filter-drawer.module';
import { FffProductListModule } from '../fff-product-list/fff-product-list.module';
import { FffProductListRefinementComponent } from './fff-product-list-refinement.component';

@NgModule({
  declarations: [
    FffProductListRefinementComponent
  ],
  imports: [
    UrlModule,
    BrowserModule,
    CommonModule,
    I18nModule,
    IconModule,
    FffDrawerModule,
    FffFacetFilterDrawerModule,
    FffProductListModule,
    NgbCollapseModule,
    ConfigModule.withConfig({
      cmsComponents: {
        ProductRefinementComponent: {
          component: FffProductListRefinementComponent,
        }
      },
    } as CmsConfig),
  ],
  providers: []
})
/**
 * Export FffProductList Module.
 * Contains changes for fff-enterprise.
 */
export class FffProductListRefinementModule { }
