<div class="recommended-products" *ngIf="!!products?.length">
  <div class="recommended-products__header">
    <h3>{{ 'fffProduct.recommendedProducts' | cxTranslate }}</h3>
  </div>

  <div class="recommended-products__body">
    <fff-splide
      [options]="options"
      [ngClass]="{ 'fff-splide--disable-arrow': products.length <= 4 }"
    >
      <fff-splide-slide *ngFor="let item of products">
        <div class="recommended-product-item text-center">
          <div class="recommended-product-item__image">
            <cx-media [container]="getData(item?.images)"></cx-media>
          </div>
          <div class="recommended-product-item__body text-center">
            <div class="entry-name">
              <div class="entry-accent recommended-product-name">{{ item?.name }}</div>
            </div>
            <div class="manufacturer-name">{{ item?.manufacturer }}</div>
            <div>
              {{ 'fffProduct.lblNdc' | cxTranslate }}
              {{ item?.ndc }}
            </div>
            <div>
              {{ 'fffProduct.lblSku' | cxTranslate }}
              {{ item?.code }}
            </div>
          </div>
          <div class="recommended-product-item__footer">
            <ng-container
              *ngIf="item?.site !== this.currentSite; else sameSiteButton"
            >
              <a
                href="#"
                class="btn btn-primary btn-sm btn-recommended-product-action"
                (click)="openAltSiteUrl($event, item)"
                >{{ 'fffProduct.viewProduct' | cxTranslate }}</a
              >
            </ng-container>

            <ng-template #sameSiteButton>
              <a
                [routerLink]="item?.url"
                class="btn btn-primary btn-sm btn-recommended-product-action"
                >{{ 'fffProduct.viewProduct' | cxTranslate }}</a
              >
            </ng-template>
          </div>
        </div>
      </fff-splide-slide>
    </fff-splide>
  </div>
</div>
