import { Injectable } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class FffInvoiceListDrawerService {

  currentFilters = new BehaviorSubject<any | undefined>(undefined);

  filtersForm = new BehaviorSubject<UntypedFormGroup | undefined>(undefined);

  constructor() { }

}
