import { CommonModule } from '@angular/common';
import { ChangeDetectorRef, Component } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AsmBindCartComponent, AsmComponentService, AsmComponentsModule } from '@spartacus/asm/components';
import { AsmBindCartFacade } from '@spartacus/asm/root';
import { ActiveCartFacade, MultiCartFacade } from '@spartacus/cart/base/root';
import { SavedCartFacade } from '@spartacus/cart/saved-cart/root';
import { GlobalMessageService, I18nModule, RoutingService } from '@spartacus/core';
import { IconModule, LaunchDialogService } from '@spartacus/storefront';

@Component({
  selector: 'cx-asm-bind-cart',
  standalone: true,
  imports:[FormsModule,ReactiveFormsModule,AsmComponentsModule,I18nModule,CommonModule, IconModule],
  templateUrl: './asm-bind-cart.component.html'
})
export class FFFAsmBindCartComponent extends AsmBindCartComponent  {
 

  constructor(
    protected globalMessageService: GlobalMessageService,
    protected activeCartFacade: ActiveCartFacade,
    protected multiCartFacade: MultiCartFacade,
    protected asmBindCartFacade: AsmBindCartFacade,
    protected launchDialogService: LaunchDialogService,
    protected savedCartFacade: SavedCartFacade,
    protected cdr: ChangeDetectorRef,
    protected asmComponentService?: AsmComponentService,
    protected routing?: RoutingService,
  ) {
    super(globalMessageService,activeCartFacade,multiCartFacade,asmBindCartFacade,launchDialogService,savedCartFacade,asmComponentService, routing)
  }

  resetInput() {
    if (!this.cartId.value) {
      this.cartId.setValue(this.activeCartId);
    }
  }

  ngOnInit(): void {
    this.subscribeForDeeplinkCart();
    this.subscription.add(
      this.activeCartFacade.getActiveCartId().subscribe((response) => {
        this.activeCartId = response ?? '';
        this.cartId.setValue(this.deepLinkCartId || this.activeCartId);
        this.cdr.detectChanges();
      })
    );
  }


}
