import {
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { FffInvoicePaymentService } from '@app/fff-enterprise/fff-common-services/fff-invoice-payment.service';
import { FILLED_ICON_TYPE } from '@app/models/fff-filled-icons.model';
import {
  INVOICE_PAYMENT_STEPS,
  InvoiceCreditCard,
  InvoicePaymentCapturePayload,
} from '@app/models/fff-invoice.model';
import { FffUser } from '@app/models/fff-user.model';
import { FffUserAccountService } from '@app/shared/services/fff-user-account.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { map, switchMap, take } from 'rxjs/operators';

@Component({
  selector: 'fff-invoice-payment-top-header',
  templateUrl: './fff-invoice-payment-top-header.component.html',
  styleUrls: ['./fff-invoice-payment-top-header.component.scss'],
})
export class FffInvoicePaymentTopHeaderComponent implements OnInit, OnDestroy {
  @Input() totalInvoices: number = 0;
  @Input() contactUser!: FffUser;

  currentStep: number = 0;
  steps = INVOICE_PAYMENT_STEPS;
  subs = new Subscription();
  selectedCard: InvoiceCreditCard | null = null;
  submitting: boolean = false;
  successInvoices: any[] = [];
  failureInvoices: any[] = [];
  filledIconTypes = FILLED_ICON_TYPE;
  acceptProcessingFeeCondition: boolean = false;
  account$ = this.fffUserAccountService
    .getProfile()
    .pipe(map(value => value?.selected));

  constructor(
    private fffUserAccountService: FffUserAccountService,
    private fffInvoicePaymentService: FffInvoicePaymentService,
    private activeModalService: NgbActiveModal,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.subs.add(
      this.fffInvoicePaymentService.getCurrentStep().subscribe(step => {
        this.currentStep = step;
        this.cd.markForCheck();
      })
    );

    this.subs.add(
      this.fffInvoicePaymentService.getSelectedCreditCard().subscribe(res => {
        this.selectedCard = res;
        this.cd.markForCheck();
      })
    );
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  cancel() {
    this.activeModalService.close({ reset: true });
  }

  toggleSubmitting(submitting: boolean) {
    this.submitting = submitting;
    this.cd.markForCheck();
  }

  reviewPayment() {
    this.fffInvoicePaymentService.setSelectedCreditCardOnGoBack(
      this.selectedCard
    );
    this.fffInvoicePaymentService.setCurrentStep(INVOICE_PAYMENT_STEPS.REVIEW);
  }

  payInvoices() {
    this.fffInvoicePaymentService.getInvoices().pipe(take(1));
    const payload: InvoicePaymentCapturePayload = {
      cardToken: (this.selectedCard?.cardToken || '').trim(),
      total: this.fffInvoicePaymentService.getGrandTotal(),
      invoices: [],
      ps2000Data: (this.selectedCard?.ps2000Data || '').trim(),
      surchargeConsent: this.getNumberOfInvoicesWithProcessingFee() > 0,
    };
    this.toggleSubmitting(true);
    this.fffInvoicePaymentService
      .getInvoices()
      .pipe(
        take(1),
        switchMap(invoices => {
          payload.invoices = invoices.map(invoice => ({
            invoiceNumber: invoice.invoiceNumber,
            invoiceAmount: invoice.grandTotal,
            manufacturer: invoice.primaryManufacturer,
          }));
          return this.fffInvoicePaymentService.capturePayment(payload);
        })
      )
      .subscribe(
        (res: any) => {
          this.fffInvoicePaymentService.approvalCode = res?.approvalCode;
          //this.fffInvoicePaymentService.transactionId = res?.transactionId;
          if (res.transactions) {
            this.processTransactionResponse(res.transactions);
          }
          this.fffInvoicePaymentService.setCurrentStep(
            INVOICE_PAYMENT_STEPS.CONFIRMATION
          );
          this.toggleSubmitting(false);
        },
        () => {
          this.toggleSubmitting(false);
          this.activeModalService.close({ errorOccurred: true });
        }
      );
  }

  processTransactionResponse(transactions: any[]): void {
    this.successInvoices = [];
    this.failureInvoices = [];

    if (transactions && transactions.length > 0) {
      transactions.forEach(transaction => {
        if (
          transaction.result &&
          transaction.result === '0' &&
          transaction.invoices
        ) {
          const invoiceNumbers = transaction.invoices.split(',');
          invoiceNumbers.forEach((invoiceNumber: any) => {
            this.successInvoices.push({
              invoiceNumber: invoiceNumber,
              transactionId: transaction.transactionId,
            });
          });
        } else if (transaction.errorCode && transaction.invoices) {
          const invoiceNumbers = transaction.invoices.split(',');
          invoiceNumbers.forEach((invoiceNumber: any) => {
            this.failureInvoices.push({
              invoiceNumber: invoiceNumber,
              transactionId: transaction.transactionId,
            });
          });
        }
      });

      this.fffInvoicePaymentService.setSuccessfulInvoices(this.successInvoices);
      this.fffInvoicePaymentService.setFailureInvoices(this.failureInvoices);
    }
  }
  scrollToBottom(): void {
    const element = document.getElementById('totalSection');
    if (element) {
      element.scrollIntoView({
        behavior: 'smooth',
      });
    }
  }
  getProcessingFee() {
    return this.fffInvoicePaymentService.getProcessingFee();
  }
  getNumberOfInvoicesWithProcessingFee(): number {
    return this.fffInvoicePaymentService.getNumberOfInvoicesWithProcessingFee();
  }
  getGrandTotal(): number {
    return this.fffInvoicePaymentService.getGrandTotal();
  }
  getTotalSuccessTransactions(): number {
    return this.fffInvoicePaymentService.getTotalSuccessTransactions();
  }
  getTotalFailureTransactions(): number {
    return this.fffInvoicePaymentService.getTotalFailureTransactions();
  }
}
