<div class="hamburgerHeader">
  <div
    *ngIf="profile$ | async as profile; else login"
    class="account-container"
  >
    <div class="container-close-icon d-flex justify-content-between">
      <cx-icon
        class="cx-icon icon-lg"
        [type]="iconClose"
        (click)="closePopover()"
      ></cx-icon>
    </div>
    <div class="isLogin d-flex justify-content-between">
      <div
        class="greetings body-text-lg align-items-center"
        *ngIf="profile.user"
      >
        {{ miniCart.greetings }} {{ profile.user.firstName }}
      </div>
      <div class="id body-text-lg">
        <fff-change-account-selector [isMobile]="true" [profile]="profile">
        </fff-change-account-selector>
      </div>
    </div>
  </div>
  <ng-template #login>
    <div class="notLogin d-flex justify-content-between">
      <div class="container-close-icon d-flex">
        <cx-icon
          class="cx-icon icon-lg"
          [type]="iconClose"
          (click)="closePopover()"
        ></cx-icon>
      </div>
      <div class="buttons d-flex">
        <button
          class="registerButton btn btn-secondary d-flex"
          (click)="goToPage('register-account')"
        >
          {{ miniCart.register }}
        </button>
        <button
          class="btn btn-primary d-flex"
          (click)="goToPage('login')"
          id="loginButton"
        >
          {{ miniCart.login }}
        </button>
      </div>
    </div>
  </ng-template>
</div>
