import { Injectable } from '@angular/core';
import { FFFOrderEntry } from '@app/models/fff-cart-data.model';
import { MultiCartService } from '@spartacus/cart/base/core';
// import { MultiCartService } from '@spartacus/core';
import { FFFCartAddEntry, FFFCartRemoveEntry, FFFCartUpdateEntry } from './fff-cart-entry.action';

@Injectable({
  providedIn: 'root',
})
export class FFFMultiCartService extends MultiCartService {

  addEntries(
    userId: string,
    cartId: string,
    orderEntries: FFFOrderEntry[]
  ): void {
    this.store.dispatch(
      new FFFCartAddEntry({
        userId,
        cartId,
        productCode: '',
        quantity: 1,
        orderEntries: orderEntries
      })
    );
  }

  updateEntry(userId: string, cartId: string, entryNumber: number, quantity: number): void {
    if (quantity > 0) {
      this.store.dispatch(
        new FFFCartUpdateEntry({
          userId,
          cartId,
          entryNumber: `${entryNumber}`,
          quantity: quantity,
        })
      );
    } else {
      this.removeEntry(userId, cartId, entryNumber);
    }
  }

  removeEntry(userId: string, cartId: string, entryNumber: number): void {
    this.store.dispatch(
      new FFFCartRemoveEntry({
        userId,
        cartId,
        entryNumber: `${entryNumber}`,
      })
    );
  };

}

