<section class="account-search-box">
  <label class="searchbox" for="search">
    <input id="search" autocomplete="off" placeholder="Account Search..." [(ngModel)]="searchText">
    <div *ngIf="!searchText" class="search-icon">
      <cx-icon class="cx-icon" [type]="outlinedIconTypes.ICON_SEARCH"></cx-icon>
    </div>
    <button *ngIf="searchText" class="reset">
      <cx-icon class="cx-icon" [type]="outlinedIconTypes.ICON_X_CIRCLE" (click)="searchText='';"></cx-icon>
    </button>
  </label>
</section>
<ng-container *ngIf="data$ | async as data;">
  <div class="account-result-card" *ngFor="let node of data.unitNodes | searchFilter: searchText">
    <div class="header">
      <h4>{{node.name}}</h4>
      <h6>{{node.accountType}}</h6>
    </div>
    <div class="body">
      <div>
        <p>{{node.address?.titleCode | titlecase }} {{node.address?.firstName}} {{node.address?.lastName}}</p>
        <p><strong>Id : </strong>{{node.address?.id}}</p>
        <p><strong>email : </strong>{{node.address?.email}}</p>
      </div>
      <div>
        <label>Address: </label>
        <p>{{node.address?.line1}}</p>
        <p>{{node.address?.town}}, {{node.address?.country?.isocode}}</p>
        <p>{{node.address?.postalCode}}</p>
      </div>
    </div>
    <div class="footer">
      <div class="footer-message-holder">
        <label>{{node.blockCode?.code}}</label>
        <p>{{node.blockCode?.message}}</p>
      </div>
      <div class="footer-button-holder">
        <input type="button" value="Activate" (click)="triggerActivate(node);">
      </div>
    </div>
  </div>
  <div *ngIf="(data.unitNodes | searchFilter: searchText).length === 0">
    <p>We could not find any result in the accounts.</p>
  </div>
</ng-container>