import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { I18nModule } from '@spartacus/core';
import { FormErrorsModule, IconModule } from '@spartacus/storefront';
import { FffRequestChangeAddressDrawerComponent } from './fff-request-change-address-drawer.component';




@NgModule({
  declarations: [
    FffRequestChangeAddressDrawerComponent,
  ],
  imports: [
    CommonModule,
    I18nModule,
    ReactiveFormsModule,
    FormsModule,
    NgSelectModule,
    FormErrorsModule,
    IconModule
  ]

})
export class FffRequestChangeAddressModule { }
