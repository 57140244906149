export const environment = {
  production: false,
  occBaseUrl:
    'https://api.d1.fffenterprises.com',
  occPrefix: '/fffocc/v2/',
  prefix: '/fffocc/v2/',
  ssrCache: true,
  ssrCacheSize: 20,
  concurrency: 20,
  timeout: 6000,
  ttl: 300000,
  siteControlDomain:
    'api.d1.fffenterprises.com',
  googleAnalyticsKey: 'UA-2542116-23',
  googleAnalyticsUrl: 'https://www.googletagmanager.com/gtag/js?id=',
  chatClientUrl:
    'https://home-c48.nice-incontact.com/inContact/ChatClient/js/embed.min.js',
  chatClientServerHost: 'https://home-c48.nice-incontact.com',
  chatClientPoc: 'fe5459ff-ade8-4b57-b74e-13f13a48f49d',
  munchkinUrl: 'https://munchkin.marketo.net/munchkin.js',
  cookieConsentUrl: 'https://cdn.cookielaw.org/scripttemplates/otSDKStub.js',
  cookieConsentDataDomainScript: 'f0694ea3-16da-4704-a1d8-4acde93428b0',
  contactUsPhoneNumber: '+1-800-843-7477',
  registerNewAccountEmail: 'salesopspecialty@fffenterprises.com',
  gtmIdBioSupply: 'GTM-PWKJM92',
  gtmIdMvn: 'GTM-KGD738D',
  customCarePhone: '+8008437477',
  legisymGetToken: {
    url: 'https://demo.dev.express222.com/branding/request.php',
  },
  smartEditAllowedOrigins:
    '*.c1bvy70fdz-fffenterp2-d1-public.model-t.cc.commerce.ondemand.com:443',
  sso: {
    jwt: {
      secret: '4tun@5@vet50rti6us_5@n@j2312212320@',
      sub: 'FFF_BIOMFV2024',
    },
  },
};
