export enum FILLED_ICON_TYPE {
  ICON_BRIEFCASE_FILL = 'ICON_BRIEFCASE_FILL',
  ICON_EMOJI_HEART_EYES_FILL = 'ICON_EMOJI_HEART_EYES_FILL',
  ICON_BADGE_3D_FILL = 'ICON_BADGE_3D_FILL',
  ICON_MODEM_FILL = 'ICON_MODEM_FILL',
  ICON_SHIELD_FILL_CHECK = 'ICON_SHIELD_FILL_CHECK',
  ICON_EXCLAMATION_TRIANGLE_FILL = 'ICON_EXCLAMATION_TRIANGLE_FILL',
  ICON_OCTAGON_FILL = 'ICON_OCTAGON_FILL',
  ICON_DIAMOND_FILL = 'ICON_DIAMOND_FILL',
  ICON_ARROW_UP_CIRCLE_FILL = 'ICON_ARROW_UP_CIRCLE_FILL',
  ICON_FILE_PLUS_FILL = 'ICON_FILE_PLUS_FILL',
  ICON_PERSON_DASH_FILL = 'ICON_PERSON_DASH_FILL',
  ICON_DASH_SQUARE_FILL = 'ICON_DASH_SQUARE_FILL',
  ICON_STOPLIGHTS_FILL = 'ICON_STOPLIGHTS_FILL',
  ICON_ARROW_UP_RIGHT_SQUARE_FILL = 'ICON_ARROW_UP_RIGHT_SQUARE_FILL',
  ICON_CHAT_LEFT_QUOTE_FILL = 'ICON_CHAT_LEFT_QUOTE_FILL',
  ICON_CALENDAR3_RANGE_FILL = 'ICON_CALENDAR3_RANGE_FILL',
  ICON_FILE_LOCK_FILL = 'ICON_FILE_LOCK_FILL',
  ICON_FILE_EARMARK_MUSIC_FILL = 'ICON_FILE_EARMARK_MUSIC_FILL',
  ICON_DIAGRAM_3_FILL = 'ICON_DIAGRAM_3_FILL',
  ICON_CLOUD_LIGHTNING_RAIN_FILL = 'ICON_CLOUD_LIGHTNING_RAIN_FILL',
  ICON_MIC_MUTE_FILL = 'ICON_MIC_MUTE_FILL',
  ICON_DIAGRAM_2_FILL = 'ICON_DIAGRAM_2_FILL',
  ICON_CLIPBOARD2_DATA_FILL = 'ICON_CLIPBOARD2_DATA_FILL',
  ICON_PLAY_FILL = 'ICON_PLAY_FILL',
  ICON_SIGNPOST_2_FILL = 'ICON_SIGNPOST_2_FILL',
  ICON_ENVELOPE_EXCLAMATION_FILL = 'ICON_ENVELOPE_EXCLAMATION_FILL',
  ICON_FILE_ZIP_FILL = 'ICON_FILE_ZIP_FILL',
  ICON_TRASH2_FILL = 'ICON_TRASH2_FILL',
  ICON_EXPLICIT_FILL = 'ICON_EXPLICIT_FILL',
  ICON_TRASH3_FILL = 'ICON_TRASH3_FILL',
  ICON_CLIPBOARD_CHECK_FILL = 'ICON_CLIPBOARD_CHECK_FILL',
  ICON_SEND_X_FILL = 'ICON_SEND_X_FILL',
  ICON_EMOJI_NEUTRAL_FILL = 'ICON_EMOJI_NEUTRAL_FILL',
  ICON_CHAT_RIGHT_QUOTE_FILL = 'ICON_CHAT_RIGHT_QUOTE_FILL',
  ICON_CALENDAR_X_FILL = 'ICON_CALENDAR_X_FILL',
  ICON_CLOUD_SLEET_FILL = 'ICON_CLOUD_SLEET_FILL',
  ICON_PERSON_PLUS_FILL = 'ICON_PERSON_PLUS_FILL',
  ICON_FILE_PERSON_FILL = 'ICON_FILE_PERSON_FILL',
  ICON_FILE_RULED_FILL = 'ICON_FILE_RULED_FILL',
  ICON_EXCLAMATION_SQUARE_FILL = 'ICON_EXCLAMATION_SQUARE_FILL',
  ICON_IMAGE_FILL = 'ICON_IMAGE_FILL',
  ICON_SKIP_BACKWARD_CIRCLE_FILL = 'ICON_SKIP_BACKWARD_CIRCLE_FILL',
  ICON_CHAT_TEXT_FILL = 'ICON_CHAT_TEXT_FILL',
  ICON_VOLUME_UP_FILL = 'ICON_VOLUME_UP_FILL',
  ICON_FILE_CHECK_FILL = 'ICON_FILE_CHECK_FILL',
  ICON_SKIP_END_FILL = 'ICON_SKIP_END_FILL',
  ICON_CLIPBOARD2_HEART_FILL = 'ICON_CLIPBOARD2_HEART_FILL',
  ICON_CUP_FILL = 'ICON_CUP_FILL',
  ICON_TV_FILL = 'ICON_TV_FILL',
  ICON_PIN_ANGLE_FILL = 'ICON_PIN_ANGLE_FILL',
  ICON_BRIGHTNESS_ALT_LOW_FILL = 'ICON_BRIGHTNESS_ALT_LOW_FILL',
  ICON_CHAT_SQUARE_QUOTE_FILL = 'ICON_CHAT_SQUARE_QUOTE_FILL',
  ICON_BRUSH_FILL = 'ICON_BRUSH_FILL',
  ICON_TERMINAL_FILL = 'ICON_TERMINAL_FILL',
  ICON_CAPSLOCK_FILL = 'ICON_CAPSLOCK_FILL',
  ICON_FILE_EARMARK_BREAK_FILL = 'ICON_FILE_EARMARK_BREAK_FILL',
  ICON_CLOUD_CHECK_FILL = 'ICON_CLOUD_CHECK_FILL',
  ICON_DPAD_FILL = 'ICON_DPAD_FILL',
  ICON_WALLET_FILL = 'ICON_WALLET_FILL',
  ICON_GRID_3X2_GAP_FILL = 'ICON_GRID_3X2_GAP_FILL',
  ICON_ENVELOPE_SLASH_FILL = 'ICON_ENVELOPE_SLASH_FILL',
  ICON_MIC_FILL = 'ICON_MIC_FILL',
  ICON_BALLOON_FILL = 'ICON_BALLOON_FILL',
  ICON_CLOUD_DOWNLOAD_FILL = 'ICON_CLOUD_DOWNLOAD_FILL',
  ICON_ARCHIVE_FILL = 'ICON_ARCHIVE_FILL',
  ICON_BOX2_FILL = 'ICON_BOX2_FILL',
  ICON_BAG_CHECK_FILL = 'ICON_BAG_CHECK_FILL',
  ICON_ENVELOPE_HEART_FILL = 'ICON_ENVELOPE_HEART_FILL',
  ICON_PUZZLE_FILL = 'ICON_PUZZLE_FILL',
  ICON_NODE_MINUS_FILL = 'ICON_NODE_MINUS_FILL',
  ICON_CHAT_DOTS_FILL = 'ICON_CHAT_DOTS_FILL',
  ICON_DICE_1_FILL = 'ICON_DICE_1_FILL',
  ICON_CPU_FILL = 'ICON_CPU_FILL',
  ICON_SKIP_FORWARD_CIRCLE_FILL = 'ICON_SKIP_FORWARD_CIRCLE_FILL',
  ICON_MUSIC_PLAYER_FILL = 'ICON_MUSIC_PLAYER_FILL',
  ICON_CALCULATOR_FILL = 'ICON_CALCULATOR_FILL',
  ICON_CREDIT_CARD_FILL = 'ICON_CREDIT_CARD_FILL',
  ICON_QUESTION_SQUARE_FILL = 'ICON_QUESTION_SQUARE_FILL',
  ICON_STOPWATCH_FILL = 'ICON_STOPWATCH_FILL',
  ICON_FILE_X_FILL = 'ICON_FILE_X_FILL',
  ICON_ASPECT_RATIO_FILL = 'ICON_ASPECT_RATIO_FILL',
  ICON_FILTER_CIRCLE_FILL = 'ICON_FILTER_CIRCLE_FILL',
  ICON_CALENDAR2_CHECK_FILL = 'ICON_CALENDAR2_CHECK_FILL',
  ICON_PEACE_FILL = 'ICON_PEACE_FILL',
  ICON_PATCH_QUESTION_FILL = 'ICON_PATCH_QUESTION_FILL',
  ICON_CARET_DOWN_FILL = 'ICON_CARET_DOWN_FILL',
  ICON_BELL_SLASH_FILL = 'ICON_BELL_SLASH_FILL',
  ICON_FILE_EARMARK_MEDICAL_FILL = 'ICON_FILE_EARMARK_MEDICAL_FILL',
  ICON_BELL_FILL = 'ICON_BELL_FILL',
  ICON_PLUS_SQUARE_FILL = 'ICON_PLUS_SQUARE_FILL',
  ICON_FILE_EARMARK_POST_FILL = 'ICON_FILE_EARMARK_POST_FILL',
  ICON_GEO_ALT_FILL = 'ICON_GEO_ALT_FILL',
  ICON_ERASER_FILL = 'ICON_ERASER_FILL',
  ICON_FILE_LOCK2_FILL = 'ICON_FILE_LOCK2_FILL',
  ICON_FLAG_FILL = 'ICON_FLAG_FILL',
  ICON_PIGGY_BANK_FILL = 'ICON_PIGGY_BANK_FILL',
  ICON_ARROW_LEFT_CIRCLE_FILL = 'ICON_ARROW_LEFT_CIRCLE_FILL',
  ICON_SIGNPOST_SPLIT_FILL = 'ICON_SIGNPOST_SPLIT_FILL',
  ICON_PALETTE_FILL = 'ICON_PALETTE_FILL',
  ICON_BUG_FILL = 'ICON_BUG_FILL',
  ICON_PERSON_CHECK_FILL = 'ICON_PERSON_CHECK_FILL',
  ICON_GRID_3X3_GAP_FILL = 'ICON_GRID_3X3_GAP_FILL',
  ICON_CALENDAR_MONTH_FILL = 'ICON_CALENDAR_MONTH_FILL',
  ICON_MOUSE_FILL = 'ICON_MOUSE_FILL',
  ICON_USB_MICRO_FILL = 'ICON_USB_MICRO_FILL',
  ICON_TROPHY_FILL = 'ICON_TROPHY_FILL',
  ICON_CALENDAR_FILL = 'ICON_CALENDAR_FILL',
  ICON_EAR_FILL = 'ICON_EAR_FILL',
  ICON_COLLECTION_FILL = 'ICON_COLLECTION_FILL',
  ICON_X_SQUARE_FILL = 'ICON_X_SQUARE_FILL',
  ICON_MENU_BUTTON_WIDE_FILL = 'ICON_MENU_BUTTON_WIDE_FILL',
  ICON_CLOUD_HAZE_FILL = 'ICON_CLOUD_HAZE_FILL',
  ICON_DEVICE_SSD_FILL = 'ICON_DEVICE_SSD_FILL',
  ICON_FILE_EARMARK_ARROW_DOWN_FILL = 'ICON_FILE_EARMARK_ARROW_DOWN_FILL',
  ICON_OPTICAL_AUDIO_FILL = 'ICON_OPTICAL_AUDIO_FILL',
  ICON_ENVELOPE_PAPER_FILL = 'ICON_ENVELOPE_PAPER_FILL',
  ICON_CARET_DOWN_SQUARE_FILL = 'ICON_CARET_DOWN_SQUARE_FILL',
  ICON_CALENDAR2_DAY_FILL = 'ICON_CALENDAR2_DAY_FILL',
  ICON_EGG_FILL = 'ICON_EGG_FILL',
  ICON_VOLUME_DOWN_FILL = 'ICON_VOLUME_DOWN_FILL',
  ICON_GEAR_FILL = 'ICON_GEAR_FILL',
  ICON_BADGE_CC_FILL = 'ICON_BADGE_CC_FILL',
  ICON_CALENDAR_EVENT_FILL = 'ICON_CALENDAR_EVENT_FILL',
  ICON_SUNSET_FILL = 'ICON_SUNSET_FILL',
  ICON_DROPLET_FILL = 'ICON_DROPLET_FILL',
  ICON_USB_C_FILL = 'ICON_USB_C_FILL',
  ICON_FILE_ARROW_DOWN_FILL = 'ICON_FILE_ARROW_DOWN_FILL',
  ICON_ARROW_DOWN_RIGHT_CIRCLE_FILL = 'ICON_ARROW_DOWN_RIGHT_CIRCLE_FILL',
  ICON_CLOUD_RAIN_FILL = 'ICON_CLOUD_RAIN_FILL',
  ICON_PROJECTOR_FILL = 'ICON_PROJECTOR_FILL',
  ICON_CALENDAR2_RANGE_FILL = 'ICON_CALENDAR2_RANGE_FILL',
  ICON_ARROW_RIGHT_SQUARE_FILL = 'ICON_ARROW_RIGHT_SQUARE_FILL',
  ICON_KANBAN_FILL = 'ICON_KANBAN_FILL',
  ICON_FILE_EARMARK_IMAGE_FILL = 'ICON_FILE_EARMARK_IMAGE_FILL',
  ICON_TABLET_LANDSCAPE_FILL = 'ICON_TABLET_LANDSCAPE_FILL',
  ICON_POSTAGE_FILL = 'ICON_POSTAGE_FILL',
  ICON_EASEL2_FILL = 'ICON_EASEL2_FILL',
  ICON_EASEL3_FILL = 'ICON_EASEL3_FILL',
  ICON_FILE_FONT_FILL = 'ICON_FILE_FONT_FILL',
  ICON_PLAY_BTN_FILL = 'ICON_PLAY_BTN_FILL',
  ICON_DICE_6_FILL = 'ICON_DICE_6_FILL',
  ICON_CLOUD_MINUS_FILL = 'ICON_CLOUD_MINUS_FILL',
  ICON_CLOUD_ARROW_UP_FILL = 'ICON_CLOUD_ARROW_UP_FILL',
  ICON_HAND_THUMBS_UP_FILL = 'ICON_HAND_THUMBS_UP_FILL',
  ICON_QUESTION_CIRCLE_FILL = 'ICON_QUESTION_CIRCLE_FILL',
  ICON_FILTER_SQUARE_FILL = 'ICON_FILTER_SQUARE_FILL',
  ICON_SKIP_START_CIRCLE_FILL = 'ICON_SKIP_START_CIRCLE_FILL',
  ICON_CALENDAR_HEART_FILL = 'ICON_CALENDAR_HEART_FILL',
  ICON_CHAT_LEFT_HEART_FILL = 'ICON_CHAT_LEFT_HEART_FILL',
  ICON_COLLECTION_PLAY_FILL = 'ICON_COLLECTION_PLAY_FILL',
  ICON_BADGE_VO_FILL = 'ICON_BADGE_VO_FILL',
  ICON_HANDBAG_FILL = 'ICON_HANDBAG_FILL',
  ICON_SUIT_SPADE_FILL = 'ICON_SUIT_SPADE_FILL',
  ICON_HEARTBREAK_FILL = 'ICON_HEARTBREAK_FILL',
  ICON_PLUS_CIRCLE_FILL = 'ICON_PLUS_CIRCLE_FILL',
  ICON_FILE_MINUS_FILL = 'ICON_FILE_MINUS_FILL',
  ICON_SHIELD_LOCK_FILL = 'ICON_SHIELD_LOCK_FILL',
  ICON_CLIPBOARD2_FILL = 'ICON_CLIPBOARD2_FILL',
  ICON_FILE_EASEL_FILL = 'ICON_FILE_EASEL_FILL',
  ICON_TELEPHONE_FILL = 'ICON_TELEPHONE_FILL',
  ICON_BRIGHTNESS_HIGH_FILL = 'ICON_BRIGHTNESS_HIGH_FILL',
  ICON_CLOUD_FILL = 'ICON_CLOUD_FILL',
  ICON_SHIFT_FILL = 'ICON_SHIFT_FILL',
  ICON_BADGE_AR_FILL = 'ICON_BADGE_AR_FILL',
  ICON_CALENDAR2_PLUS_FILL = 'ICON_CALENDAR2_PLUS_FILL',
  ICON_ARROW_LEFT_SQUARE_FILL = 'ICON_ARROW_LEFT_SQUARE_FILL',
  ICON_CREDIT_CARD_2_FRONT_FILL = 'ICON_CREDIT_CARD_2_FRONT_FILL',
  ICON_HEART_PULSE_FILL = 'ICON_HEART_PULSE_FILL',
  ICON_TAG_FILL = 'ICON_TAG_FILL',
  ICON_CHAT_RIGHT_HEART_FILL = 'ICON_CHAT_RIGHT_HEART_FILL',
  ICON_CLOCK_FILL = 'ICON_CLOCK_FILL',
  ICON_STICKIES_FILL = 'ICON_STICKIES_FILL',
  ICON_BOOKMARK_X_FILL = 'ICON_BOOKMARK_X_FILL',
  ICON_USB_MINI_FILL = 'ICON_USB_MINI_FILL',
  ICON_GEO_FILL = 'ICON_GEO_FILL',
  ICON_CARET_RIGHT_SQUARE_FILL = 'ICON_CARET_RIGHT_SQUARE_FILL',
  ICON_CLIPBOARD_MINUS_FILL = 'ICON_CLIPBOARD_MINUS_FILL',
  ICON_PHONE_FILL = 'ICON_PHONE_FILL',
  ICON_FOLDER_SYMLINK_FILL = 'ICON_FOLDER_SYMLINK_FILL',
  ICON_X_CIRCLE_FILL = 'ICON_X_CIRCLE_FILL',
  ICON_BRIGHTNESS_LOW_FILL = 'ICON_BRIGHTNESS_LOW_FILL',
  ICON_POSTCARD_HEART_FILL = 'ICON_POSTCARD_HEART_FILL',
  ICON_LOCK_FILL = 'ICON_LOCK_FILL',
  ICON_CHAT_SQUARE_HEART_FILL = 'ICON_CHAT_SQUARE_HEART_FILL',
  ICON_BALLOON_HEART_FILL = 'ICON_BALLOON_HEART_FILL',
  ICON_CLOUD_RAIN_HEAVY_FILL = 'ICON_CLOUD_RAIN_HEAVY_FILL',
  ICON_CLIPBOARD_DATA_FILL = 'ICON_CLIPBOARD_DATA_FILL',
  ICON_CARET_RIGHT_FILL = 'ICON_CARET_RIGHT_FILL',
  ICON_EMOJI_WINK_FILL = 'ICON_EMOJI_WINK_FILL',
  ICON_SHIELD_FILL = 'ICON_SHIELD_FILL',
  ICON_QUESTION_DIAMOND_FILL = 'ICON_QUESTION_DIAMOND_FILL',
  ICON_FILE_PLAY_FILL = 'ICON_FILE_PLAY_FILL',
  ICON_BOOKMARK_STAR_FILL = 'ICON_BOOKMARK_STAR_FILL',
  ICON_QUESTION_OCTAGON_FILL = 'ICON_QUESTION_OCTAGON_FILL',
  ICON_BAR_CHART_LINE_FILL = 'ICON_BAR_CHART_LINE_FILL',
  ICON_ARROW_DOWN_RIGHT_SQUARE_FILL = 'ICON_ARROW_DOWN_RIGHT_SQUARE_FILL',
  ICON_USB_FILL = 'ICON_USB_FILL',
  ICON_ARROW_RIGHT_CIRCLE_FILL = 'ICON_ARROW_RIGHT_CIRCLE_FILL',
  ICON_CHAT_RIGHT_FILL = 'ICON_CHAT_RIGHT_FILL',
  ICON_HDD_FILL = 'ICON_HDD_FILL',
  ICON_SUNRISE_FILL = 'ICON_SUNRISE_FILL',
  ICON_CALENDAR2_DATE_FILL = 'ICON_CALENDAR2_DATE_FILL',
  ICON_DISPLAY_FILL = 'ICON_DISPLAY_FILL',
  ICON_TRIANGLE_FILL = 'ICON_TRIANGLE_FILL',
  ICON_PERSON_FILL = 'ICON_PERSON_FILL',
  ICON_BAG_FILL = 'ICON_BAG_FILL',
  ICON_BADGE_HD_FILL = 'ICON_BADGE_HD_FILL',
  ICON_LIGHTBULB_OFF_FILL = 'ICON_LIGHTBULB_OFF_FILL',
  ICON_FILE_EARMARK_WORD_FILL = 'ICON_FILE_EARMARK_WORD_FILL',
  ICON_CALENDAR3_WEEK_FILL = 'ICON_CALENDAR3_WEEK_FILL',
  ICON_KEYBOARD_FILL = 'ICON_KEYBOARD_FILL',
  ICON_BUCKET_FILL = 'ICON_BUCKET_FILL',
  ICON_SKIP_BACKWARD_FILL = 'ICON_SKIP_BACKWARD_FILL',
  ICON_PIN_MAP_FILL = 'ICON_PIN_MAP_FILL',
  ICON_ARROW_UP_SQUARE_FILL = 'ICON_ARROW_UP_SQUARE_FILL',
  ICON_CALENDAR_PLUS_FILL = 'ICON_CALENDAR_PLUS_FILL',
  ICON_BOOKMARK_HEART_FILL = 'ICON_BOOKMARK_HEART_FILL',
  ICON_CLIPBOARD2_X_FILL = 'ICON_CLIPBOARD2_X_FILL',
  ICON_ARROW_UP_RIGHT_CIRCLE_FILL = 'ICON_ARROW_UP_RIGHT_CIRCLE_FILL',
  ICON_DOOR_OPEN_FILL = 'ICON_DOOR_OPEN_FILL',
  ICON_DASH_CIRCLE_FILL = 'ICON_DASH_CIRCLE_FILL',
  ICON_HEART_FILL = 'ICON_HEART_FILL',
  ICON_SEND_PLUS_FILL = 'ICON_SEND_PLUS_FILL',
  ICON_CART_FILL = 'ICON_CART_FILL',
  ICON_CLOUD_FOG2_FILL = 'ICON_CLOUD_FOG2_FILL',
  ICON_PAUSE_BTN_FILL = 'ICON_PAUSE_BTN_FILL',
  ICON_MOON_FILL = 'ICON_MOON_FILL',
  ICON_SAVE_FILL = 'ICON_SAVE_FILL',
  ICON_EXCLAMATION_OCTAGON_FILL = 'ICON_EXCLAMATION_OCTAGON_FILL',
  ICON_FILE_EARMARK_DIFF_FILL = 'ICON_FILE_EARMARK_DIFF_FILL',
  ICON_SEND_SLASH_FILL = 'ICON_SEND_SLASH_FILL',
  ICON_EXCLAMATION_DIAMOND_FILL = 'ICON_EXCLAMATION_DIAMOND_FILL',
  ICON_LIGHTNING_CHARGE_FILL = 'ICON_LIGHTNING_CHARGE_FILL',
  ICON_CLOUD_SUN_FILL = 'ICON_CLOUD_SUN_FILL',
  ICON_FILE_EARMARK_EXCEL_FILL = 'ICON_FILE_EARMARK_EXCEL_FILL',
  ICON_FILE_SLIDES_FILL = 'ICON_FILE_SLIDES_FILL',
  ICON_VOLUME_MUTE_FILL = 'ICON_VOLUME_MUTE_FILL',
  ICON_SEND_DASH_FILL = 'ICON_SEND_DASH_FILL',
  ICON_DISC_FILL = 'ICON_DISC_FILL',
  ICON_FILE_EARMARK_BINARY_FILL = 'ICON_FILE_EARMARK_BINARY_FILL',
  ICON_LAYERS_FILL = 'ICON_LAYERS_FILL',
  ICON_CLOUD_ARROW_DOWN_FILL = 'ICON_CLOUD_ARROW_DOWN_FILL',
  ICON_BAG_PLUS_FILL = 'ICON_BAG_PLUS_FILL',
  ICON_EXCLAMATION_CIRCLE_FILL = 'ICON_EXCLAMATION_CIRCLE_FILL',
  ICON_SKIP_END_CIRCLE_FILL = 'ICON_SKIP_END_CIRCLE_FILL',
  ICON_HAND_INDEX_FILL = 'ICON_HAND_INDEX_FILL',
  ICON_BASKET_FILL = 'ICON_BASKET_FILL',
  ICON_BRIGHTNESS_ALT_HIGH_FILL = 'ICON_BRIGHTNESS_ALT_HIGH_FILL',
  ICON_INBOXES_FILL = 'ICON_INBOXES_FILL',
  ICON_CALENDAR_DATE_FILL = 'ICON_CALENDAR_DATE_FILL',
  ICON_BADGE_WC_FILL = 'ICON_BADGE_WC_FILL',
  ICON_MOON_STARS_FILL = 'ICON_MOON_STARS_FILL',
  ICON_BAG_DASH_FILL = 'ICON_BAG_DASH_FILL',
  ICON_CALENDAR2_MINUS_FILL = 'ICON_CALENDAR2_MINUS_FILL',
  ICON_PENCIL_FILL = 'ICON_PENCIL_FILL',
  ICON_BOOKMARKS_FILL = 'ICON_BOOKMARKS_FILL',
  ICON_FILE_EARMARK_CODE_FILL = 'ICON_FILE_EARMARK_CODE_FILL',
  ICON_SIGNPOST_FILL = 'ICON_SIGNPOST_FILL',
  ICON_HDMI_FILL = 'ICON_HDMI_FILL',
  ICON_PHONE_LANDSCAPE_FILL = 'ICON_PHONE_LANDSCAPE_FILL',
  ICON_MENU_BUTTON_FILL = 'ICON_MENU_BUTTON_FILL',
  ICON_EMOJI_SUNGLASSES_FILL = 'ICON_EMOJI_SUNGLASSES_FILL',
  ICON_DOOR_CLOSED_FILL = 'ICON_DOOR_CLOSED_FILL',
  ICON_CREDIT_CARD_2_BACK_FILL = 'ICON_CREDIT_CARD_2_BACK_FILL',
  ICON_FILE_TEXT_FILL = 'ICON_FILE_TEXT_FILL',
  ICON_CLOUD_DRIZZLE_FILL = 'ICON_CLOUD_DRIZZLE_FILL',
  ICON_TELEPHONE_MINUS_FILL = 'ICON_TELEPHONE_MINUS_FILL',
  ICON_CARET_UP_SQUARE_FILL = 'ICON_CARET_UP_SQUARE_FILL',
  ICON_LAPTOP_FILL = 'ICON_LAPTOP_FILL',
  ICON_FILE_EARMARK_ZIP_FILL = 'ICON_FILE_EARMARK_ZIP_FILL',
  ICON_KEY_FILL = 'ICON_KEY_FILL',
  ICON_CLOUD_UPLOAD_FILL = 'ICON_CLOUD_UPLOAD_FILL',
  ICON_SAVE2_FILL = 'ICON_SAVE2_FILL',
  ICON_CLIPBOARD_FILL = 'ICON_CLIPBOARD_FILL',
  ICON_PLUG_FILL = 'ICON_PLUG_FILL',
  ICON_MOTHERBOARD_FILL = 'ICON_MOTHERBOARD_FILL',
  ICON_EJECT_FILL = 'ICON_EJECT_FILL',
  ICON_BADGE_TM_FILL = 'ICON_BADGE_TM_FILL',
  ICON_CAMERA_FILL = 'ICON_CAMERA_FILL',
  ICON_CHAT_SQUARE_FILL = 'ICON_CHAT_SQUARE_FILL',
  ICON_FILE_EARMARK_RULED_FILL = 'ICON_FILE_EARMARK_RULED_FILL',
  ICON_REPLY_FILL = 'ICON_REPLY_FILL',
  ICON_CHAT_QUOTE_FILL = 'ICON_CHAT_QUOTE_FILL',
  ICON_SHIELD_FILL_MINUS = 'ICON_SHIELD_FILL_MINUS',
  ICON_SAFE_FILL = 'ICON_SAFE_FILL',
  ICON_CAMERA_VIDEO_FILL = 'ICON_CAMERA_VIDEO_FILL',
  ICON_FILE_EARMARK_CHECK_FILL = 'ICON_FILE_EARMARK_CHECK_FILL',
  ICON_HDD_RACK_FILL = 'ICON_HDD_RACK_FILL',
  ICON_MOUSE2_FILL = 'ICON_MOUSE2_FILL',
  ICON_MOUSE3_FILL = 'ICON_MOUSE3_FILL',
  ICON_SHIELD_FILL_PLUS = 'ICON_SHIELD_FILL_PLUS',
  ICON_GIFT_FILL = 'ICON_GIFT_FILL',
  ICON_CLIPBOARD2_MINUS_FILL = 'ICON_CLIPBOARD2_MINUS_FILL',
  ICON_FILE_BREAK_FILL = 'ICON_FILE_BREAK_FILL',
  ICON_SAFE2_FILL = 'ICON_SAFE2_FILL',
  ICON_STOP_BTN_FILL = 'ICON_STOP_BTN_FILL',
  ICON_FILE_EARMARK_LOCK_FILL = 'ICON_FILE_EARMARK_LOCK_FILL',
  ICON_FILE_EARMARK_FILL = 'ICON_FILE_EARMARK_FILL',
  ICON_CARET_LEFT_SQUARE_FILL = 'ICON_CARET_LEFT_SQUARE_FILL',
  ICON_EMOJI_KISS_FILL = 'ICON_EMOJI_KISS_FILL',
  ICON_CALENDAR_CHECK_FILL = 'ICON_CALENDAR_CHECK_FILL',
  ICON_BOOTSTRAP_FILL = 'ICON_BOOTSTRAP_FILL',
  ICON_CALENDAR2_WEEK_FILL = 'ICON_CALENDAR2_WEEK_FILL',
  ICON_FILE_EARMARK_PLUS_FILL = 'ICON_FILE_EARMARK_PLUS_FILL',
  ICON_PIN_FILL = 'ICON_PIN_FILL',
  ICON_DEVICE_HDD_FILL = 'ICON_DEVICE_HDD_FILL',
  ICON_NODE_PLUS_FILL = 'ICON_NODE_PLUS_FILL',
  ICON_HDD_STACK_FILL = 'ICON_HDD_STACK_FILL',
  ICON_DICE_3_FILL = 'ICON_DICE_3_FILL',
  ICON_DICE_2_FILL = 'ICON_DICE_2_FILL',
  ICON_ARROW_DOWN_CIRCLE_FILL = 'ICON_ARROW_DOWN_CIRCLE_FILL',
  ICON_UMBRELLA_FILL = 'ICON_UMBRELLA_FILL',
  ICON_BOOMBOX_FILL = 'ICON_BOOMBOX_FILL',
  ICON_TICKET_FILL = 'ICON_TICKET_FILL',
  ICON_WEBCAM_FILL = 'ICON_WEBCAM_FILL',
  ICON_CALENDAR_RANGE_FILL = 'ICON_CALENDAR_RANGE_FILL',
  ICON_FILE_ARROW_UP_FILL = 'ICON_FILE_ARROW_UP_FILL',
  ICON_BOX2_HEART_FILL = 'ICON_BOX2_HEART_FILL',
  ICON_BANDAID_FILL = 'ICON_BANDAID_FILL',
  ICON_TELEPHONE_PLUS_FILL = 'ICON_TELEPHONE_PLUS_FILL',
  ICON_CLIPBOARD_PLUS_FILL = 'ICON_CLIPBOARD_PLUS_FILL',
  ICON_INFO_CIRCLE_FILL = 'ICON_INFO_CIRCLE_FILL',
  ICON_EMOJI_SMILE_FILL = 'ICON_EMOJI_SMILE_FILL',
  ICON_POSTCARD_FILL = 'ICON_POSTCARD_FILL',
  ICON_ENVELOPE_OPEN_FILL = 'ICON_ENVELOPE_OPEN_FILL',
  ICON_HEXAGON_FILL = 'ICON_HEXAGON_FILL',
  ICON_X_DIAMOND_FILL = 'ICON_X_DIAMOND_FILL',
  ICON_SKIP_START_BTN_FILL = 'ICON_SKIP_START_BTN_FILL',
  ICON_CALENDAR2_EVENT_FILL = 'ICON_CALENDAR2_EVENT_FILL',
  ICON_X_OCTAGON_FILL = 'ICON_X_OCTAGON_FILL',
  ICON_EYE_SLASH_FILL = 'ICON_EYE_SLASH_FILL',
  ICON_FILE_EARMARK_PDF_FILL = 'ICON_FILE_EARMARK_PDF_FILL',
  ICON_LAMP_FILL = 'ICON_LAMP_FILL',
  ICON_FILE_MUSIC_FILL = 'ICON_FILE_MUSIC_FILL',
  ICON_FUNNEL_FILL = 'ICON_FUNNEL_FILL',
  ICON_PEOPLE_FILL = 'ICON_PEOPLE_FILL',
  ICON_ALARM_FILL = 'ICON_ALARM_FILL',
  ICON_FILE_EARMARK_PPT_FILL = 'ICON_FILE_EARMARK_PPT_FILL',
  ICON_FORWARD_FILL = 'ICON_FORWARD_FILL',
  ICON_CLOUD_FOG_FILL = 'ICON_CLOUD_FOG_FILL',
  ICON_HDD_NETWORK_FILL = 'ICON_HDD_NETWORK_FILL',
  ICON_CALENDAR2_MONTH_FILL = 'ICON_CALENDAR2_MONTH_FILL',
  ICON_SHIELD_FILL_EXCLAMATION = 'ICON_SHIELD_FILL_EXCLAMATION',
  ICON_PAUSE_CIRCLE_FILL = 'ICON_PAUSE_CIRCLE_FILL',
  ICON_PLAY_CIRCLE_FILL = 'ICON_PLAY_CIRCLE_FILL',
  ICON_SUIT_CLUB_FILL = 'ICON_SUIT_CLUB_FILL',
  ICON_TREE_FILL = 'ICON_TREE_FILL',
  ICON_SEND_CHECK_FILL = 'ICON_SEND_CHECK_FILL',
  ICON_CALENDAR_WEEK_FILL = 'ICON_CALENDAR_WEEK_FILL',
  ICON_CLOUD_HAZE2_FILL = 'ICON_CLOUD_HAZE2_FILL',
  ICON_UNLOCK_FILL = 'ICON_UNLOCK_FILL',
  ICON_PENTAGON_FILL = 'ICON_PENTAGON_FILL',
  ICON_SKIP_BACKWARD_BTN_FILL = 'ICON_SKIP_BACKWARD_BTN_FILL',
  ICON_PATCH_CHECK_FILL = 'ICON_PATCH_CHECK_FILL',
  ICON_ARROW_DOWN_LEFT_CIRCLE_FILL = 'ICON_ARROW_DOWN_LEFT_CIRCLE_FILL',
  ICON_CARET_LEFT_FILL = 'ICON_CARET_LEFT_FILL',
  ICON_FILE_POST_FILL = 'ICON_FILE_POST_FILL',
  ICON_CLIPBOARD_X_FILL = 'ICON_CLIPBOARD_X_FILL',
  ICON_USB_PLUG_FILL = 'ICON_USB_PLUG_FILL',
  ICON_CLOUD_SNOW_FILL = 'ICON_CLOUD_SNOW_FILL',
  ICON_TICKET_DETAILED_FILL = 'ICON_TICKET_DETAILED_FILL',
  ICON_EMOJI_LAUGHING_FILL = 'ICON_EMOJI_LAUGHING_FILL',
  ICON_TELEPHONE_INBOUND_FILL = 'ICON_TELEPHONE_INBOUND_FILL',
  ICON_BADGE_4K_FILL = 'ICON_BADGE_4K_FILL',
  ICON_TELEPHONE_X_FILL = 'ICON_TELEPHONE_X_FILL',
  ICON_FILE_MEDICAL_FILL = 'ICON_FILE_MEDICAL_FILL',
  ICON_ENVELOPE_X_FILL = 'ICON_ENVELOPE_X_FILL',
  ICON_CLOUDY_FILL = 'ICON_CLOUDY_FILL',
  ICON_ARROW_THROUGH_HEART_FILL = 'ICON_ARROW_THROUGH_HEART_FILL',
  ICON_BOOKMARK_CHECK_FILL = 'ICON_BOOKMARK_CHECK_FILL',
  ICON_CHECK_SQUARE_FILL = 'ICON_CHECK_SQUARE_FILL',
  ICON_PERSON_LINES_FILL = 'ICON_PERSON_LINES_FILL',
  ICON_SLASH_SQUARE_FILL = 'ICON_SLASH_SQUARE_FILL',
  ICON_ARROW_UP_LEFT_CIRCLE_FILL = 'ICON_ARROW_UP_LEFT_CIRCLE_FILL',
  ICON_HOSPITAL_FILL = 'ICON_HOSPITAL_FILL',
  ICON_CALENDAR3_EVENT_FILL = 'ICON_CALENDAR3_EVENT_FILL',
  ICON_CLOUD_LIGHTNING_FILL = 'ICON_CLOUD_LIGHTNING_FILL',
  ICON_TAGS_FILL = 'ICON_TAGS_FILL',
  ICON_FILE_IMAGE_FILL = 'ICON_FILE_IMAGE_FILL',
  ICON_FILE_RICHTEXT_FILL = 'ICON_FILE_RICHTEXT_FILL',
  ICON_CURSOR_FILL = 'ICON_CURSOR_FILL',
  ICON_CAMERA_VIDEO_OFF_FILL = 'ICON_CAMERA_VIDEO_OFF_FILL',
  ICON_DISPLAYPORT_FILL = 'ICON_DISPLAYPORT_FILL',
  ICON_SQUARE_FILL = 'ICON_SQUARE_FILL',
  ICON_GRID_1X2_FILL = 'ICON_GRID_1X2_FILL',
  ICON_CLOUD_HAIL_FILL = 'ICON_CLOUD_HAIL_FILL',
  ICON_SKIP_FORWARD_BTN_FILL = 'ICON_SKIP_FORWARD_BTN_FILL',
  ICON_SHIELD_SLASH_FILL = 'ICON_SHIELD_SLASH_FILL',
  ICON_FILE_EARMARK_PERSON_FILL = 'ICON_FILE_EARMARK_PERSON_FILL',
  ICON_STAR_FILL = 'ICON_STAR_FILL',
  ICON_BOOKMARK_DASH_FILL = 'ICON_BOOKMARK_DASH_FILL',
  ICON_PIP_FILL = 'ICON_PIP_FILL',
  ICON_BADGE_SD_FILL = 'ICON_BADGE_SD_FILL',
  ICON_EYE_FILL = 'ICON_EYE_FILL',
  ICON_ENVELOPE_DASH_FILL = 'ICON_ENVELOPE_DASH_FILL',
  ICON_FILE_EARMARK_LOCK2_FILL = 'ICON_FILE_EARMARK_LOCK2_FILL',
  ICON_AWARD_FILL = 'ICON_AWARD_FILL',
  ICON_SEND_EXCLAMATION_FILL = 'ICON_SEND_EXCLAMATION_FILL',
  ICON_BOOKMARK_PLUS_FILL = 'ICON_BOOKMARK_PLUS_FILL',
  ICON_CART_X_FILL = 'ICON_CART_X_FILL',
  ICON_ENVELOPE_PLUS_FILL = 'ICON_ENVELOPE_PLUS_FILL',
  ICON_CLOUDS_FILL = 'ICON_CLOUDS_FILL',
  ICON_FILE_BAR_GRAPH_FILL = 'ICON_FILE_BAR_GRAPH_FILL',
  ICON_FILE_FILL = 'ICON_FILE_FILL',
  ICON_STICKY_FILL = 'ICON_STICKY_FILL',
  ICON_HAND_THUMBS_DOWN_FILL = 'ICON_HAND_THUMBS_DOWN_FILL',
  ICON_CHAT_FILL = 'ICON_CHAT_FILL',
  ICON_CHAT_HEART_FILL = 'ICON_CHAT_HEART_FILL',
  ICON_COMPASS_FILL = 'ICON_COMPASS_FILL',
  ICON_SUIT_HEART_FILL = 'ICON_SUIT_HEART_FILL',
  ICON_CLIPBOARD_HEART_FILL = 'ICON_CLIPBOARD_HEART_FILL',
  ICON_CART_DASH_FILL = 'ICON_CART_DASH_FILL',
  ICON_EMOJI_ANGRY_FILL = 'ICON_EMOJI_ANGRY_FILL',
  ICON_HOUSE_HEART_FILL = 'ICON_HOUSE_HEART_FILL',
  ICON_BOOKMARK_FILL = 'ICON_BOOKMARK_FILL',
  ICON_STOP_CIRCLE_FILL = 'ICON_STOP_CIRCLE_FILL',
  ICON_ARROW_DOWN_LEFT_SQUARE_FILL = 'ICON_ARROW_DOWN_LEFT_SQUARE_FILL',
  ICON_ENVELOPE_OPEN_HEART_FILL = 'ICON_ENVELOPE_OPEN_HEART_FILL',
  ICON_CHAT_RIGHT_DOTS_FILL = 'ICON_CHAT_RIGHT_DOTS_FILL',
  ICON_EMOJI_DIZZY_FILL = 'ICON_EMOJI_DIZZY_FILL',
  ICON_RECORD_CIRCLE_FILL = 'ICON_RECORD_CIRCLE_FILL',
  ICON_TABLET_FILL = 'ICON_TABLET_FILL',
  ICON_FILE_EARMARK_SLIDES_FILL = 'ICON_FILE_EARMARK_SLIDES_FILL',
  ICON_MORTARBOARD_FILL = 'ICON_MORTARBOARD_FILL',
  ICON_CART_PLUS_FILL = 'ICON_CART_PLUS_FILL',
  ICON_HOUSE_DOOR_FILL = 'ICON_HOUSE_DOOR_FILL',
  ICON_CHAT_RIGHT_TEXT_FILL = 'ICON_CHAT_RIGHT_TEXT_FILL',
  ICON_SLASH_CIRCLE_FILL = 'ICON_SLASH_CIRCLE_FILL',
  ICON_BACKSPACE_REVERSE_FILL = 'ICON_BACKSPACE_REVERSE_FILL',
  ICON_ARROW_UP_LEFT_SQUARE_FILL = 'ICON_ARROW_UP_LEFT_SQUARE_FILL',
  ICON_FILE_BINARY_FILL = 'ICON_FILE_BINARY_FILL',
  ICON_PERSON_BADGE_FILL = 'ICON_PERSON_BADGE_FILL',
  ICON_CHECK_CIRCLE_FILL = 'ICON_CHECK_CIRCLE_FILL',
  ICON_CLIPBOARD2_PLUS_FILL = 'ICON_CLIPBOARD2_PLUS_FILL',
  ICON_ENVELOPE_FILL = 'ICON_ENVELOPE_FILL',
  ICON_WRENCH_ADJUSTABLE_CIRCLE_FILL = 'ICON_WRENCH_ADJUSTABLE_CIRCLE_FILL',
  ICON_FILE_EARMARK_RICHTEXT_FILL = 'ICON_FILE_EARMARK_RICHTEXT_FILL',
  ICON_BADGE_VR_FILL = 'ICON_BADGE_VR_FILL',
  ICON_FILE_EARMARK_FONT_FILL = 'ICON_FILE_EARMARK_FONT_FILL',
  ICON_LIGHTBULB_FILL = 'ICON_LIGHTBULB_FILL',
  ICON_NUT_FILL = 'ICON_NUT_FILL',
  ICON_CLOUD_SLASH_FILL = 'ICON_CLOUD_SLASH_FILL',
  ICON_TRASH_FILL = 'ICON_TRASH_FILL',
  ICON_CIRCLE_FILL = 'ICON_CIRCLE_FILL',
  ICON_PATCH_EXCLAMATION_FILL = 'ICON_PATCH_EXCLAMATION_FILL',
  ICON_BAG_HEART_FILL = 'ICON_BAG_HEART_FILL',
  ICON_CART_CHECK_FILL = 'ICON_CART_CHECK_FILL',
  ICON_SIM_FILL = 'ICON_SIM_FILL',
  ICON_BADGE_AD_FILL = 'ICON_BADGE_AD_FILL',
  ICON_ENVELOPE_CHECK_FILL = 'ICON_ENVELOPE_CHECK_FILL',
  ICON_FILE_EARMARK_PLAY_FILL = 'ICON_FILE_EARMARK_PLAY_FILL',
  ICON_SPEAKER_FILL = 'ICON_SPEAKER_FILL',
  ICON_HOUSE_FILL = 'ICON_HOUSE_FILL',
  ICON_CALENDAR_MINUS_FILL = 'ICON_CALENDAR_MINUS_FILL',
  ICON_BAR_CHART_FILL = 'ICON_BAR_CHART_FILL',
  ICON_THUNDERBOLT_FILL = 'ICON_THUNDERBOLT_FILL',
  ICON_FILE_EARMARK_EASEL_FILL = 'ICON_FILE_EARMARK_EASEL_FILL',
  ICON_RECORD_BTN_FILL = 'ICON_RECORD_BTN_FILL',
  ICON_FILE_EARMARK_MINUS_FILL = 'ICON_FILE_EARMARK_MINUS_FILL',
  ICON_SUN_FILL = 'ICON_SUN_FILL',
  ICON_GRID_FILL = 'ICON_GRID_FILL',
  ICON_POSTAGE_HEART_FILL = 'ICON_POSTAGE_HEART_FILL',
  ICON_CHAT_LEFT_FILL = 'ICON_CHAT_LEFT_FILL',
  ICON_STOP_FILL = 'ICON_STOP_FILL',
  ICON_PEN_FILL = 'ICON_PEN_FILL',
  ICON_CLIPBOARD2_CHECK_FILL = 'ICON_CLIPBOARD2_CHECK_FILL',
  ICON_SHARE_FILL = 'ICON_SHARE_FILL',
  ICON_CLOUD_PLUS_FILL = 'ICON_CLOUD_PLUS_FILL',
  ICON_RSS_FILL = 'ICON_RSS_FILL',
  ICON_USB_DRIVE_FILL = 'ICON_USB_DRIVE_FILL',
  ICON_FILE_EARMARK_BAR_GRAPH_FILL = 'ICON_FILE_EARMARK_BAR_GRAPH_FILL',
  ICON_FILE_EARMARK_X_FILL = 'ICON_FILE_EARMARK_X_FILL',
  ICON_EMOJI_SMILE_UPSIDE_DOWN_FILL = 'ICON_EMOJI_SMILE_UPSIDE_DOWN_FILL',
  ICON_VOLUME_OFF_FILL = 'ICON_VOLUME_OFF_FILL',
  ICON_BOOK_FILL = 'ICON_BOOK_FILL',
  ICON_SEND_FILL = 'ICON_SEND_FILL',
  ICON_SKIP_FORWARD_FILL = 'ICON_SKIP_FORWARD_FILL',
  ICON_FILE_DIFF_FILL = 'ICON_FILE_DIFF_FILL',
  ICON_SUIT_DIAMOND_FILL = 'ICON_SUIT_DIAMOND_FILL',
  ICON_CLOUD_MOON_FILL = 'ICON_CLOUD_MOON_FILL',
  ICON_SHIELD_FILL_X = 'ICON_SHIELD_FILL_X',
  ICON_CHAT_SQUARE_TEXT_FILL = 'ICON_CHAT_SQUARE_TEXT_FILL',
  ICON_PHONE_VIBRATE_FILL = 'ICON_PHONE_VIBRATE_FILL',
  ICON_PRINTER_FILL = 'ICON_PRINTER_FILL',
  ICON_ENVELOPE_PAPER_HEART_FILL = 'ICON_ENVELOPE_PAPER_HEART_FILL',
  ICON_BINOCULARS_FILL = 'ICON_BINOCULARS_FILL',
  ICON_BASKET2_FILL = 'ICON_BASKET2_FILL',
  ICON_BASKET3_FILL = 'ICON_BASKET3_FILL',
  ICON_FILE_PDF_FILL = 'ICON_FILE_PDF_FILL',
  ICON_EMOJI_FROWN_FILL = 'ICON_EMOJI_FROWN_FILL',
  ICON_TICKET_PERFORATED_FILL = 'ICON_TICKET_PERFORATED_FILL',
  ICON_LIGHTNING_FILL = 'ICON_LIGHTNING_FILL',
  ICON_FILE_SPREADSHEET_FILL = 'ICON_FILE_SPREADSHEET_FILL',
  ICON_CHAT_SQUARE_DOTS_FILL = 'ICON_CHAT_SQUARE_DOTS_FILL',
  ICON_CALENDAR2_HEART_FILL = 'ICON_CALENDAR2_HEART_FILL',
  ICON_FILE_PPT_FILL = 'ICON_FILE_PPT_FILL',
  ICON_PIE_CHART_FILL = 'ICON_PIE_CHART_FILL',
  ICON_CALENDAR_DAY_FILL = 'ICON_CALENDAR_DAY_FILL',
  ICON_FILE_WORD_FILL = 'ICON_FILE_WORD_FILL',
  ICON_CALENDAR3_FILL = 'ICON_CALENDAR3_FILL',
  ICON_CALENDAR2_FILL = 'ICON_CALENDAR2_FILL',
  ICON_CAMERA_REELS_FILL = 'ICON_CAMERA_REELS_FILL',
  ICON_BACKSPACE_FILL = 'ICON_BACKSPACE_FILL',
  ICON_EMOJI_EXPRESSIONLESS_FILL = 'ICON_EMOJI_EXPRESSIONLESS_FILL',
  ICON_CARET_UP_FILL = 'ICON_CARET_UP_FILL',
  ICON_FILE_EARMARK_ARROW_UP_FILL = 'ICON_FILE_EARMARK_ARROW_UP_FILL',
  ICON_SKIP_START_FILL = 'ICON_SKIP_START_FILL',
  ICON_ARROW_DOWN_SQUARE_FILL = 'ICON_ARROW_DOWN_SQUARE_FILL',
  ICON_DICE_4_FILL = 'ICON_DICE_4_FILL',
  ICON_DICE_5_FILL = 'ICON_DICE_5_FILL',
  ICON_FILE_CODE_FILL = 'ICON_FILE_CODE_FILL',
  ICON_CHAT_LEFT_DOTS_FILL = 'ICON_CHAT_LEFT_DOTS_FILL',
  ICON_TELEPHONE_FORWARD_FILL = 'ICON_TELEPHONE_FORWARD_FILL',
  ICON_TELEPHONE_OUTBOUND_FILL = 'ICON_TELEPHONE_OUTBOUND_FILL',
  ICON_EASEL_FILL = 'ICON_EASEL_FILL',
  ICON_INFO_SQUARE_FILL = 'ICON_INFO_SQUARE_FILL',
  ICON_FILE_EARMARK_TEXT_FILL = 'ICON_FILE_EARMARK_TEXT_FILL',
  ICON_HEPTAGON_FILL = 'ICON_HEPTAGON_FILL',
  ICON_HAND_INDEX_THUMB_FILL = 'ICON_HAND_INDEX_THUMB_FILL',
  ICON_CHAT_LEFT_TEXT_FILL = 'ICON_CHAT_LEFT_TEXT_FILL',
  ICON_SKIP_END_BTN_FILL = 'ICON_SKIP_END_BTN_FILL',
  ICON_FILE_EARMARK_SPREADSHEET_FILL = 'ICON_FILE_EARMARK_SPREADSHEET_FILL',
  ICON_CALENDAR2_X_FILL = 'ICON_CALENDAR2_X_FILL',
  ICON_SEARCH_HEART_FILL = 'ICON_SEARCH_HEART_FILL',
  ICON_RECORD_FILL = 'ICON_RECORD_FILL',
  ICON_PATCH_MINUS_FILL = 'ICON_PATCH_MINUS_FILL',
  ICON_SD_CARD_FILL = 'ICON_SD_CARD_FILL',
  ICON_REPLY_ALL_FILL = 'ICON_REPLY_ALL_FILL',
  ICON_FILE_EXCEL_FILL = 'ICON_FILE_EXCEL_FILL',
  ICON_INBOX_FILL = 'ICON_INBOX_FILL',
  ICON_PATCH_PLUS_FILL = 'ICON_PATCH_PLUS_FILL',
  ICON_CLIPBOARD2_PULSE_FILL = 'ICON_CLIPBOARD2_PULSE_FILL',
  ICON_BADGE_8K_FILL = 'ICON_BADGE_8K_FILL',
  ICON_FOLDER_FILL = 'ICON_FOLDER_FILL',
  ICON_BAG_X_FILL = 'ICON_BAG_X_FILLl'
}


export const FILLED_ICON_SYMBOLS = {
  ICON_BRIEFCASE_FILL: 'icon-briefcase-fill',
  ICON_EMOJI_HEART_EYES_FILL: 'icon-emoji-heart-eyes-fill',
  ICON_BADGE_3D_FILL: 'icon-badge-3d-fill',
  ICON_MODEM_FILL: 'icon-modem-fill',
  ICON_SHIELD_FILL_CHECK: 'icon-shield-fill-check',
  ICON_EXCLAMATION_TRIANGLE_FILL: 'icon-exclamation-triangle-fill',
  ICON_OCTAGON_FILL: 'icon-octagon-fill',
  ICON_DIAMOND_FILL: 'icon-diamond-fill',
  ICON_ARROW_UP_CIRCLE_FILL: 'icon-arrow-up-circle-fill',
  ICON_FILE_PLUS_FILL: 'icon-file-plus-fill',
  ICON_PERSON_DASH_FILL: 'icon-person-dash-fill',
  ICON_DASH_SQUARE_FILL: 'icon-dash-square-fill',
  ICON_STOPLIGHTS_FILL: 'icon-stoplights-fill',
  ICON_ARROW_UP_RIGHT_SQUARE_FILL: 'icon-arrow-up-right-square-fill',
  ICON_CHAT_LEFT_QUOTE_FILL: 'icon-chat-left-quote-fill',
  ICON_CALENDAR3_RANGE_FILL: 'icon-calendar3-range-fill',
  ICON_FILE_LOCK_FILL: 'icon-file-lock-fill',
  ICON_FILE_EARMARK_MUSIC_FILL: 'icon-file-earmark-music-fill',
  ICON_DIAGRAM_3_FILL: 'icon-diagram-3-fill',
  ICON_CLOUD_LIGHTNING_RAIN_FILL: 'icon-cloud-lightning-rain-fill',
  ICON_MIC_MUTE_FILL: 'icon-mic-mute-fill',
  ICON_DIAGRAM_2_FILL: 'icon-diagram-2-fill',
  ICON_CLIPBOARD2_DATA_FILL: 'icon-clipboard2-data-fill',
  ICON_PLAY_FILL: 'icon-play-fill',
  ICON_SIGNPOST_2_FILL: 'icon-signpost-2-fill',
  ICON_ENVELOPE_EXCLAMATION_FILL: 'icon-envelope-exclamation-fill',
  ICON_FILE_ZIP_FILL: 'icon-file-zip-fill',
  ICON_TRASH2_FILL: 'icon-trash2-fill',
  ICON_EXPLICIT_FILL: 'icon-explicit-fill',
  ICON_TRASH3_FILL: 'icon-trash3-fill',
  ICON_CLIPBOARD_CHECK_FILL: 'icon-clipboard-check-fill',
  ICON_SEND_X_FILL: 'icon-send-x-fill',
  ICON_EMOJI_NEUTRAL_FILL: 'icon-emoji-neutral-fill',
  ICON_CHAT_RIGHT_QUOTE_FILL: 'icon-chat-right-quote-fill',
  ICON_CALENDAR_X_FILL: 'icon-calendar-x-fill',
  ICON_CLOUD_SLEET_FILL: 'icon-cloud-sleet-fill',
  ICON_PERSON_PLUS_FILL: 'icon-person-plus-fill',
  ICON_FILE_PERSON_FILL: 'icon-file-person-fill',
  ICON_FILE_RULED_FILL: 'icon-file-ruled-fill',
  ICON_EXCLAMATION_SQUARE_FILL: 'icon-exclamation-square-fill',
  ICON_IMAGE_FILL: 'icon-image-fill',
  ICON_SKIP_BACKWARD_CIRCLE_FILL: 'icon-skip-backward-circle-fill',
  ICON_CHAT_TEXT_FILL: 'icon-chat-text-fill',
  ICON_VOLUME_UP_FILL: 'icon-volume-up-fill',
  ICON_FILE_CHECK_FILL: 'icon-file-check-fill',
  ICON_SKIP_END_FILL: 'icon-skip-end-fill',
  ICON_CLIPBOARD2_HEART_FILL: 'icon-clipboard2-heart-fill',
  ICON_CUP_FILL: 'icon-cup-fill',
  ICON_TV_FILL: 'icon-tv-fill',
  ICON_PIN_ANGLE_FILL: 'icon-pin-angle-fill',
  ICON_BRIGHTNESS_ALT_LOW_FILL: 'icon-brightness-alt-low-fill',
  ICON_CHAT_SQUARE_QUOTE_FILL: 'icon-chat-square-quote-fill',
  ICON_BRUSH_FILL: 'icon-brush-fill',
  ICON_TERMINAL_FILL: 'icon-terminal-fill',
  ICON_CAPSLOCK_FILL: 'icon-capslock-fill',
  ICON_FILE_EARMARK_BREAK_FILL: 'icon-file-earmark-break-fill',
  ICON_CLOUD_CHECK_FILL: 'icon-cloud-check-fill',
  ICON_DPAD_FILL: 'icon-dpad-fill',
  ICON_WALLET_FILL: 'icon-wallet-fill',
  ICON_GRID_3X2_GAP_FILL: 'icon-grid-3x2-gap-fill',
  ICON_ENVELOPE_SLASH_FILL: 'icon-envelope-slash-fill',
  ICON_MIC_FILL: 'icon-mic-fill',
  ICON_BALLOON_FILL: 'icon-balloon-fill',
  ICON_CLOUD_DOWNLOAD_FILL: 'icon-cloud-download-fill',
  ICON_ARCHIVE_FILL: 'icon-archive-fill',
  ICON_BOX2_FILL: 'icon-box2-fill',
  ICON_BAG_CHECK_FILL: 'icon-bag-check-fill',
  ICON_ENVELOPE_HEART_FILL: 'icon-envelope-heart-fill',
  ICON_PUZZLE_FILL: 'icon-puzzle-fill',
  ICON_NODE_MINUS_FILL: 'icon-node-minus-fill',
  ICON_CHAT_DOTS_FILL: 'icon-chat-dots-fill',
  ICON_DICE_1_FILL: 'icon-dice-1-fill',
  ICON_CPU_FILL: 'icon-cpu-fill',
  ICON_SKIP_FORWARD_CIRCLE_FILL: 'icon-skip-forward-circle-fill',
  ICON_MUSIC_PLAYER_FILL: 'icon-music-player-fill',
  ICON_CALCULATOR_FILL: 'icon-calculator-fill',
  ICON_CREDIT_CARD_FILL: 'icon-credit-card-fill',
  ICON_QUESTION_SQUARE_FILL: 'icon-question-square-fill',
  ICON_STOPWATCH_FILL: 'icon-stopwatch-fill',
  ICON_FILE_X_FILL: 'icon-file-x-fill',
  ICON_ASPECT_RATIO_FILL: 'icon-aspect-ratio-fill',
  ICON_FILTER_CIRCLE_FILL: 'icon-filter-circle-fill',
  ICON_CALENDAR2_CHECK_FILL: 'icon-calendar2-check-fill',
  ICON_PEACE_FILL: 'icon-peace-fill',
  ICON_PATCH_QUESTION_FILL: 'icon-patch-question-fill',
  ICON_CARET_DOWN_FILL: 'icon-caret-down-fill',
  ICON_BELL_SLASH_FILL: 'icon-bell-slash-fill',
  ICON_FILE_EARMARK_MEDICAL_FILL: 'icon-file-earmark-medical-fill',
  ICON_BELL_FILL: 'icon-bell-fill',
  ICON_PLUS_SQUARE_FILL: 'icon-plus-square-fill',
  ICON_FILE_EARMARK_POST_FILL: 'icon-file-earmark-post-fill',
  ICON_GEO_ALT_FILL: 'icon-geo-alt-fill',
  ICON_ERASER_FILL: 'icon-eraser-fill',
  ICON_FILE_LOCK2_FILL: 'icon-file-lock2-fill',
  ICON_FLAG_FILL: 'icon-flag-fill',
  ICON_PIGGY_BANK_FILL: 'icon-piggy-bank-fill',
  ICON_ARROW_LEFT_CIRCLE_FILL: 'icon-arrow-left-circle-fill',
  ICON_SIGNPOST_SPLIT_FILL: 'icon-signpost-split-fill',
  ICON_PALETTE_FILL: 'icon-palette-fill',
  ICON_BUG_FILL: 'icon-bug-fill',
  ICON_PERSON_CHECK_FILL: 'icon-person-check-fill',
  ICON_GRID_3X3_GAP_FILL: 'icon-grid-3x3-gap-fill',
  ICON_CALENDAR_MONTH_FILL: 'icon-calendar-month-fill',
  ICON_MOUSE_FILL: 'icon-mouse-fill',
  ICON_USB_MICRO_FILL: 'icon-usb-micro-fill',
  ICON_TROPHY_FILL: 'icon-trophy-fill',
  ICON_CALENDAR_FILL: 'icon-calendar-fill',
  ICON_EAR_FILL: 'icon-ear-fill',
  ICON_COLLECTION_FILL: 'icon-collection-fill',
  ICON_X_SQUARE_FILL: 'icon-x-square-fill',
  ICON_MENU_BUTTON_WIDE_FILL: 'icon-menu-button-wide-fill',
  ICON_CLOUD_HAZE_FILL: 'icon-cloud-haze-fill',
  ICON_DEVICE_SSD_FILL: 'icon-device-ssd-fill',
  ICON_FILE_EARMARK_ARROW_DOWN_FILL: 'icon-file-earmark-arrow-down-fill',
  ICON_OPTICAL_AUDIO_FILL: 'icon-optical-audio-fill',
  ICON_ENVELOPE_PAPER_FILL: 'icon-envelope-paper-fill',
  ICON_CARET_DOWN_SQUARE_FILL: 'icon-caret-down-square-fill',
  ICON_CALENDAR2_DAY_FILL: 'icon-calendar2-day-fill',
  ICON_EGG_FILL: 'icon-egg-fill',
  ICON_VOLUME_DOWN_FILL: 'icon-volume-down-fill',
  ICON_GEAR_FILL: 'icon-gear-fill',
  ICON_BADGE_CC_FILL: 'icon-badge-cc-fill',
  ICON_CALENDAR_EVENT_FILL: 'icon-calendar-event-fill',
  ICON_SUNSET_FILL: 'icon-sunset-fill',
  ICON_DROPLET_FILL: 'icon-droplet-fill',
  ICON_USB_C_FILL: 'icon-usb-c-fill',
  ICON_FILE_ARROW_DOWN_FILL: 'icon-file-arrow-down-fill',
  ICON_ARROW_DOWN_RIGHT_CIRCLE_FILL: 'icon-arrow-down-right-circle-fill',
  ICON_CLOUD_RAIN_FILL: 'icon-cloud-rain-fill',
  ICON_PROJECTOR_FILL: 'icon-projector-fill',
  ICON_CALENDAR2_RANGE_FILL: 'icon-calendar2-range-fill',
  ICON_ARROW_RIGHT_SQUARE_FILL: 'icon-arrow-right-square-fill',
  ICON_KANBAN_FILL: 'icon-kanban-fill',
  ICON_FILE_EARMARK_IMAGE_FILL: 'icon-file-earmark-image-fill',
  ICON_TABLET_LANDSCAPE_FILL: 'icon-tablet-landscape-fill',
  ICON_POSTAGE_FILL: 'icon-postage-fill',
  ICON_EASEL2_FILL: 'icon-easel2-fill',
  ICON_EASEL3_FILL: 'icon-easel3-fill',
  ICON_FILE_FONT_FILL: 'icon-file-font-fill',
  ICON_PLAY_BTN_FILL: 'icon-play-btn-fill',
  ICON_DICE_6_FILL: 'icon-dice-6-fill',
  ICON_CLOUD_MINUS_FILL: 'icon-cloud-minus-fill',
  ICON_CLOUD_ARROW_UP_FILL: 'icon-cloud-arrow-up-fill',
  ICON_HAND_THUMBS_UP_FILL: 'icon-hand-thumbs-up-fill',
  ICON_QUESTION_CIRCLE_FILL: 'icon-question-circle-fill',
  ICON_FILTER_SQUARE_FILL: 'icon-filter-square-fill',
  ICON_SKIP_START_CIRCLE_FILL: 'icon-skip-start-circle-fill',
  ICON_CALENDAR_HEART_FILL: 'icon-calendar-heart-fill',
  ICON_CHAT_LEFT_HEART_FILL: 'icon-chat-left-heart-fill',
  ICON_COLLECTION_PLAY_FILL: 'icon-collection-play-fill',
  ICON_BADGE_VO_FILL: 'icon-badge-vo-fill',
  ICON_HANDBAG_FILL: 'icon-handbag-fill',
  ICON_SUIT_SPADE_FILL: 'icon-suit-spade-fill',
  ICON_HEARTBREAK_FILL: 'icon-heartbreak-fill',
  ICON_PLUS_CIRCLE_FILL: 'icon-plus-circle-fill',
  ICON_FILE_MINUS_FILL: 'icon-file-minus-fill',
  ICON_SHIELD_LOCK_FILL: 'icon-shield-lock-fill',
  ICON_CLIPBOARD2_FILL: 'icon-clipboard2-fill',
  ICON_FILE_EASEL_FILL: 'icon-file-easel-fill',
  ICON_TELEPHONE_FILL: 'icon-telephone-fill',
  ICON_BRIGHTNESS_HIGH_FILL: 'icon-brightness-high-fill',
  ICON_CLOUD_FILL: 'icon-cloud-fill',
  ICON_SHIFT_FILL: 'icon-shift-fill',
  ICON_BADGE_AR_FILL: 'icon-badge-ar-fill',
  ICON_CALENDAR2_PLUS_FILL: 'icon-calendar2-plus-fill',
  ICON_ARROW_LEFT_SQUARE_FILL: 'icon-arrow-left-square-fill',
  ICON_CREDIT_CARD_2_FRONT_FILL: 'icon-credit-card-2-front-fill',
  ICON_HEART_PULSE_FILL: 'icon-heart-pulse-fill',
  ICON_TAG_FILL: 'icon-tag-fill',
  ICON_CHAT_RIGHT_HEART_FILL: 'icon-chat-right-heart-fill',
  ICON_CLOCK_FILL: 'icon-clock-fill',
  ICON_STICKIES_FILL: 'icon-stickies-fill',
  ICON_BOOKMARK_X_FILL: 'icon-bookmark-x-fill',
  ICON_USB_MINI_FILL: 'icon-usb-mini-fill',
  ICON_GEO_FILL: 'icon-geo-fill',
  ICON_CARET_RIGHT_SQUARE_FILL: 'icon-caret-right-square-fill',
  ICON_CLIPBOARD_MINUS_FILL: 'icon-clipboard-minus-fill',
  ICON_PHONE_FILL: 'icon-phone-fill',
  ICON_FOLDER_SYMLINK_FILL: 'icon-folder-symlink-fill',
  ICON_X_CIRCLE_FILL: 'icon-x-circle-fill',
  ICON_BRIGHTNESS_LOW_FILL: 'icon-brightness-low-fill',
  ICON_POSTCARD_HEART_FILL: 'icon-postcard-heart-fill',
  ICON_LOCK_FILL: 'icon-lock-fill',
  ICON_CHAT_SQUARE_HEART_FILL: 'icon-chat-square-heart-fill',
  ICON_BALLOON_HEART_FILL: 'icon-balloon-heart-fill',
  ICON_CLOUD_RAIN_HEAVY_FILL: 'icon-cloud-rain-heavy-fill',
  ICON_CLIPBOARD_DATA_FILL: 'icon-clipboard-data-fill',
  ICON_CARET_RIGHT_FILL: 'icon-caret-right-fill',
  ICON_EMOJI_WINK_FILL: 'icon-emoji-wink-fill',
  ICON_SHIELD_FILL: 'icon-shield-fill',
  ICON_QUESTION_DIAMOND_FILL: 'icon-question-diamond-fill',
  ICON_FILE_PLAY_FILL: 'icon-file-play-fill',
  ICON_BOOKMARK_STAR_FILL: 'icon-bookmark-star-fill',
  ICON_QUESTION_OCTAGON_FILL: 'icon-question-octagon-fill',
  ICON_BAR_CHART_LINE_FILL: 'icon-bar-chart-line-fill',
  ICON_ARROW_DOWN_RIGHT_SQUARE_FILL: 'icon-arrow-down-right-square-fill',
  ICON_USB_FILL: 'icon-usb-fill',
  ICON_ARROW_RIGHT_CIRCLE_FILL: 'icon-arrow-right-circle-fill',
  ICON_CHAT_RIGHT_FILL: 'icon-chat-right-fill',
  ICON_HDD_FILL: 'icon-hdd-fill',
  ICON_SUNRISE_FILL: 'icon-sunrise-fill',
  ICON_CALENDAR2_DATE_FILL: 'icon-calendar2-date-fill',
  ICON_DISPLAY_FILL: 'icon-display-fill',
  ICON_TRIANGLE_FILL: 'icon-triangle-fill',
  ICON_PERSON_FILL: 'icon-person-fill',
  ICON_BAG_FILL: 'icon-bag-fill',
  ICON_BADGE_HD_FILL: 'icon-badge-hd-fill',
  ICON_LIGHTBULB_OFF_FILL: 'icon-lightbulb-off-fill',
  ICON_FILE_EARMARK_WORD_FILL: 'icon-file-earmark-word-fill',
  ICON_CALENDAR3_WEEK_FILL: 'icon-calendar3-week-fill',
  ICON_KEYBOARD_FILL: 'icon-keyboard-fill',
  ICON_BUCKET_FILL: 'icon-bucket-fill',
  ICON_SKIP_BACKWARD_FILL: 'icon-skip-backward-fill',
  ICON_PIN_MAP_FILL: 'icon-pin-map-fill',
  ICON_ARROW_UP_SQUARE_FILL: 'icon-arrow-up-square-fill',
  ICON_CALENDAR_PLUS_FILL: 'icon-calendar-plus-fill',
  ICON_BOOKMARK_HEART_FILL: 'icon-bookmark-heart-fill',
  ICON_CLIPBOARD2_X_FILL: 'icon-clipboard2-x-fill',
  ICON_ARROW_UP_RIGHT_CIRCLE_FILL: 'icon-arrow-up-right-circle-fill',
  ICON_DOOR_OPEN_FILL: 'icon-door-open-fill',
  ICON_DASH_CIRCLE_FILL: 'icon-dash-circle-fill',
  ICON_HEART_FILL: 'icon-heart-fill',
  ICON_SEND_PLUS_FILL: 'icon-send-plus-fill',
  ICON_CART_FILL: 'icon-cart-fill',
  ICON_CLOUD_FOG2_FILL: 'icon-cloud-fog2-fill',
  ICON_PAUSE_BTN_FILL: 'icon-pause-btn-fill',
  ICON_MOON_FILL: 'icon-moon-fill',
  ICON_SAVE_FILL: 'icon-save-fill',
  ICON_EXCLAMATION_OCTAGON_FILL: 'icon-exclamation-octagon-fill',
  ICON_FILE_EARMARK_DIFF_FILL: 'icon-file-earmark-diff-fill',
  ICON_SEND_SLASH_FILL: 'icon-send-slash-fill',
  ICON_EXCLAMATION_DIAMOND_FILL: 'icon-exclamation-diamond-fill',
  ICON_LIGHTNING_CHARGE_FILL: 'icon-lightning-charge-fill',
  ICON_CLOUD_SUN_FILL: 'icon-cloud-sun-fill',
  ICON_FILE_EARMARK_EXCEL_FILL: 'icon-file-earmark-excel-fill',
  ICON_FILE_SLIDES_FILL: 'icon-file-slides-fill',
  ICON_VOLUME_MUTE_FILL: 'icon-volume-mute-fill',
  ICON_SEND_DASH_FILL: 'icon-send-dash-fill',
  ICON_DISC_FILL: 'icon-disc-fill',
  ICON_FILE_EARMARK_BINARY_FILL: 'icon-file-earmark-binary-fill',
  ICON_LAYERS_FILL: 'icon-layers-fill',
  ICON_CLOUD_ARROW_DOWN_FILL: 'icon-cloud-arrow-down-fill',
  ICON_BAG_PLUS_FILL: 'icon-bag-plus-fill',
  ICON_EXCLAMATION_CIRCLE_FILL: 'icon-exclamation-circle-fill',
  ICON_SKIP_END_CIRCLE_FILL: 'icon-skip-end-circle-fill',
  ICON_HAND_INDEX_FILL: 'icon-hand-index-fill',
  ICON_BASKET_FILL: 'icon-basket-fill',
  ICON_BRIGHTNESS_ALT_HIGH_FILL: 'icon-brightness-alt-high-fill',
  ICON_INBOXES_FILL: 'icon-inboxes-fill',
  ICON_CALENDAR_DATE_FILL: 'icon-calendar-date-fill',
  ICON_BADGE_WC_FILL: 'icon-badge-wc-fill',
  ICON_MOON_STARS_FILL: 'icon-moon-stars-fill',
  ICON_BAG_DASH_FILL: 'icon-bag-dash-fill',
  ICON_CALENDAR2_MINUS_FILL: 'icon-calendar2-minus-fill',
  ICON_PENCIL_FILL: 'icon-pencil-fill',
  ICON_BOOKMARKS_FILL: 'icon-bookmarks-fill',
  ICON_FILE_EARMARK_CODE_FILL: 'icon-file-earmark-code-fill',
  ICON_SIGNPOST_FILL: 'icon-signpost-fill',
  ICON_HDMI_FILL: 'icon-hdmi-fill',
  ICON_PHONE_LANDSCAPE_FILL: 'icon-phone-landscape-fill',
  ICON_MENU_BUTTON_FILL: 'icon-menu-button-fill',
  ICON_EMOJI_SUNGLASSES_FILL: 'icon-emoji-sunglasses-fill',
  ICON_DOOR_CLOSED_FILL: 'icon-door-closed-fill',
  ICON_CREDIT_CARD_2_BACK_FILL: 'icon-credit-card-2-back-fill',
  ICON_FILE_TEXT_FILL: 'icon-file-text-fill',
  ICON_CLOUD_DRIZZLE_FILL: 'icon-cloud-drizzle-fill',
  ICON_TELEPHONE_MINUS_FILL: 'icon-telephone-minus-fill',
  ICON_CARET_UP_SQUARE_FILL: 'icon-caret-up-square-fill',
  ICON_LAPTOP_FILL: 'icon-laptop-fill',
  ICON_FILE_EARMARK_ZIP_FILL: 'icon-file-earmark-zip-fill',
  ICON_KEY_FILL: 'icon-key-fill',
  ICON_CLOUD_UPLOAD_FILL: 'icon-cloud-upload-fill',
  ICON_SAVE2_FILL: 'icon-save2-fill',
  ICON_CLIPBOARD_FILL: 'icon-clipboard-fill',
  ICON_PLUG_FILL: 'icon-plug-fill',
  ICON_MOTHERBOARD_FILL: 'icon-motherboard-fill',
  ICON_EJECT_FILL: 'icon-eject-fill',
  ICON_BADGE_TM_FILL: 'icon-badge-tm-fill',
  ICON_CAMERA_FILL: 'icon-camera-fill',
  ICON_CHAT_SQUARE_FILL: 'icon-chat-square-fill',
  ICON_FILE_EARMARK_RULED_FILL: 'icon-file-earmark-ruled-fill',
  ICON_REPLY_FILL: 'icon-reply-fill',
  ICON_CHAT_QUOTE_FILL: 'icon-chat-quote-fill',
  ICON_SHIELD_FILL_MINUS: 'icon-shield-fill-minus',
  ICON_SAFE_FILL: 'icon-safe-fill',
  ICON_CAMERA_VIDEO_FILL: 'icon-camera-video-fill',
  ICON_FILE_EARMARK_CHECK_FILL: 'icon-file-earmark-check-fill',
  ICON_HDD_RACK_FILL: 'icon-hdd-rack-fill',
  ICON_MOUSE2_FILL: 'icon-mouse2-fill',
  ICON_MOUSE3_FILL: 'icon-mouse3-fill',
  ICON_SHIELD_FILL_PLUS: 'icon-shield-fill-plus',
  ICON_GIFT_FILL: 'icon-gift-fill',
  ICON_CLIPBOARD2_MINUS_FILL: 'icon-clipboard2-minus-fill',
  ICON_FILE_BREAK_FILL: 'icon-file-break-fill',
  ICON_SAFE2_FILL: 'icon-safe2-fill',
  ICON_STOP_BTN_FILL: 'icon-stop-btn-fill',
  ICON_FILE_EARMARK_LOCK_FILL: 'icon-file-earmark-lock-fill',
  ICON_FILE_EARMARK_FILL: 'icon-file-earmark-fill',
  ICON_CARET_LEFT_SQUARE_FILL: 'icon-caret-left-square-fill',
  ICON_EMOJI_KISS_FILL: 'icon-emoji-kiss-fill',
  ICON_CALENDAR_CHECK_FILL: 'icon-calendar-check-fill',
  ICON_BOOTSTRAP_FILL: 'icon-bootstrap-fill',
  ICON_CALENDAR2_WEEK_FILL: 'icon-calendar2-week-fill',
  ICON_FILE_EARMARK_PLUS_FILL: 'icon-file-earmark-plus-fill',
  ICON_PIN_FILL: 'icon-pin-fill',
  ICON_DEVICE_HDD_FILL: 'icon-device-hdd-fill',
  ICON_NODE_PLUS_FILL: 'icon-node-plus-fill',
  ICON_HDD_STACK_FILL: 'icon-hdd-stack-fill',
  ICON_DICE_3_FILL: 'icon-dice-3-fill',
  ICON_DICE_2_FILL: 'icon-dice-2-fill',
  ICON_ARROW_DOWN_CIRCLE_FILL: 'icon-arrow-down-circle-fill',
  ICON_UMBRELLA_FILL: 'icon-umbrella-fill',
  ICON_BOOMBOX_FILL: 'icon-boombox-fill',
  ICON_TICKET_FILL: 'icon-ticket-fill',
  ICON_WEBCAM_FILL: 'icon-webcam-fill',
  ICON_CALENDAR_RANGE_FILL: 'icon-calendar-range-fill',
  ICON_FILE_ARROW_UP_FILL: 'icon-file-arrow-up-fill',
  ICON_BOX2_HEART_FILL: 'icon-box2-heart-fill',
  ICON_BANDAID_FILL: 'icon-bandaid-fill',
  ICON_TELEPHONE_PLUS_FILL: 'icon-telephone-plus-fill',
  ICON_CLIPBOARD_PLUS_FILL: 'icon-clipboard-plus-fill',
  ICON_INFO_CIRCLE_FILL: 'icon-info-circle-fill',
  ICON_EMOJI_SMILE_FILL: 'icon-emoji-smile-fill',
  ICON_POSTCARD_FILL: 'icon-postcard-fill',
  ICON_ENVELOPE_OPEN_FILL: 'icon-envelope-open-fill',
  ICON_HEXAGON_FILL: 'icon-hexagon-fill',
  ICON_X_DIAMOND_FILL: 'icon-x-diamond-fill',
  ICON_SKIP_START_BTN_FILL: 'icon-skip-start-btn-fill',
  ICON_CALENDAR2_EVENT_FILL: 'icon-calendar2-event-fill',
  ICON_X_OCTAGON_FILL: 'icon-x-octagon-fill',
  ICON_EYE_SLASH_FILL: 'icon-eye-slash-fill',
  ICON_FILE_EARMARK_PDF_FILL: 'icon-file-earmark-pdf-fill',
  ICON_LAMP_FILL: 'icon-lamp-fill',
  ICON_FILE_MUSIC_FILL: 'icon-file-music-fill',
  ICON_FUNNEL_FILL: 'icon-funnel-fill',
  ICON_PEOPLE_FILL: 'icon-people-fill',
  ICON_ALARM_FILL: 'icon-alarm-fill',
  ICON_FILE_EARMARK_PPT_FILL: 'icon-file-earmark-ppt-fill',
  ICON_FORWARD_FILL: 'icon-forward-fill',
  ICON_CLOUD_FOG_FILL: 'icon-cloud-fog-fill',
  ICON_HDD_NETWORK_FILL: 'icon-hdd-network-fill',
  ICON_CALENDAR2_MONTH_FILL: 'icon-calendar2-month-fill',
  ICON_SHIELD_FILL_EXCLAMATION: 'icon-shield-fill-exclamation',
  ICON_PAUSE_CIRCLE_FILL: 'icon-pause-circle-fill',
  ICON_PLAY_CIRCLE_FILL: 'icon-play-circle-fill',
  ICON_SUIT_CLUB_FILL: 'icon-suit-club-fill',
  ICON_TREE_FILL: 'icon-tree-fill',
  ICON_SEND_CHECK_FILL: 'icon-send-check-fill',
  ICON_CALENDAR_WEEK_FILL: 'icon-calendar-week-fill',
  ICON_CLOUD_HAZE2_FILL: 'icon-cloud-haze2-fill',
  ICON_UNLOCK_FILL: 'icon-unlock-fill',
  ICON_PENTAGON_FILL: 'icon-pentagon-fill',
  ICON_SKIP_BACKWARD_BTN_FILL: 'icon-skip-backward-btn-fill',
  ICON_PATCH_CHECK_FILL: 'icon-patch-check-fill',
  ICON_ARROW_DOWN_LEFT_CIRCLE_FILL: 'icon-arrow-down-left-circle-fill',
  ICON_CARET_LEFT_FILL: 'icon-caret-left-fill',
  ICON_FILE_POST_FILL: 'icon-file-post-fill',
  ICON_CLIPBOARD_X_FILL: 'icon-clipboard-x-fill',
  ICON_USB_PLUG_FILL: 'icon-usb-plug-fill',
  ICON_CLOUD_SNOW_FILL: 'icon-cloud-snow-fill',
  ICON_TICKET_DETAILED_FILL: 'icon-ticket-detailed-fill',
  ICON_EMOJI_LAUGHING_FILL: 'icon-emoji-laughing-fill',
  ICON_TELEPHONE_INBOUND_FILL: 'icon-telephone-inbound-fill',
  ICON_BADGE_4K_FILL: 'icon-badge-4k-fill',
  ICON_TELEPHONE_X_FILL: 'icon-telephone-x-fill',
  ICON_FILE_MEDICAL_FILL: 'icon-file-medical-fill',
  ICON_ENVELOPE_X_FILL: 'icon-envelope-x-fill',
  ICON_CLOUDY_FILL: 'icon-cloudy-fill',
  ICON_ARROW_THROUGH_HEART_FILL: 'icon-arrow-through-heart-fill',
  ICON_BOOKMARK_CHECK_FILL: 'icon-bookmark-check-fill',
  ICON_CHECK_SQUARE_FILL: 'icon-check-square-fill',
  ICON_PERSON_LINES_FILL: 'icon-person-lines-fill',
  ICON_SLASH_SQUARE_FILL: 'icon-slash-square-fill',
  ICON_ARROW_UP_LEFT_CIRCLE_FILL: 'icon-arrow-up-left-circle-fill',
  ICON_HOSPITAL_FILL: 'icon-hospital-fill',
  ICON_CALENDAR3_EVENT_FILL: 'icon-calendar3-event-fill',
  ICON_CLOUD_LIGHTNING_FILL: 'icon-cloud-lightning-fill',
  ICON_TAGS_FILL: 'icon-tags-fill',
  ICON_FILE_IMAGE_FILL: 'icon-file-image-fill',
  ICON_FILE_RICHTEXT_FILL: 'icon-file-richtext-fill',
  ICON_CURSOR_FILL: 'icon-cursor-fill',
  ICON_CAMERA_VIDEO_OFF_FILL: 'icon-camera-video-off-fill',
  ICON_DISPLAYPORT_FILL: 'icon-displayport-fill',
  ICON_SQUARE_FILL: 'icon-square-fill',
  ICON_GRID_1X2_FILL: 'icon-grid-1x2-fill',
  ICON_CLOUD_HAIL_FILL: 'icon-cloud-hail-fill',
  ICON_SKIP_FORWARD_BTN_FILL: 'icon-skip-forward-btn-fill',
  ICON_SHIELD_SLASH_FILL: 'icon-shield-slash-fill',
  ICON_FILE_EARMARK_PERSON_FILL: 'icon-file-earmark-person-fill',
  ICON_STAR_FILL: 'icon-star-fill',
  ICON_BOOKMARK_DASH_FILL: 'icon-bookmark-dash-fill',
  ICON_PIP_FILL: 'icon-pip-fill',
  ICON_BADGE_SD_FILL: 'icon-badge-sd-fill',
  ICON_EYE_FILL: 'icon-eye-fill',
  ICON_ENVELOPE_DASH_FILL: 'icon-envelope-dash-fill',
  ICON_FILE_EARMARK_LOCK2_FILL: 'icon-file-earmark-lock2-fill',
  ICON_AWARD_FILL: 'icon-award-fill',
  ICON_SEND_EXCLAMATION_FILL: 'icon-send-exclamation-fill',
  ICON_BOOKMARK_PLUS_FILL: 'icon-bookmark-plus-fill',
  ICON_CART_X_FILL: 'icon-cart-x-fill',
  ICON_ENVELOPE_PLUS_FILL: 'icon-envelope-plus-fill',
  ICON_CLOUDS_FILL: 'icon-clouds-fill',
  ICON_FILE_BAR_GRAPH_FILL: 'icon-file-bar-graph-fill',
  ICON_FILE_FILL: 'icon-file-fill',
  ICON_STICKY_FILL: 'icon-sticky-fill',
  ICON_HAND_THUMBS_DOWN_FILL: 'icon-hand-thumbs-down-fill',
  ICON_CHAT_FILL: 'icon-chat-fill',
  ICON_CHAT_HEART_FILL: 'icon-chat-heart-fill',
  ICON_COMPASS_FILL: 'icon-compass-fill',
  ICON_SUIT_HEART_FILL: 'icon-suit-heart-fill',
  ICON_CLIPBOARD_HEART_FILL: 'icon-clipboard-heart-fill',
  ICON_CART_DASH_FILL: 'icon-cart-dash-fill',
  ICON_EMOJI_ANGRY_FILL: 'icon-emoji-angry-fill',
  ICON_HOUSE_HEART_FILL: 'icon-house-heart-fill',
  ICON_BOOKMARK_FILL: 'icon-bookmark-fill',
  ICON_STOP_CIRCLE_FILL: 'icon-stop-circle-fill',
  ICON_ARROW_DOWN_LEFT_SQUARE_FILL: 'icon-arrow-down-left-square-fill',
  ICON_ENVELOPE_OPEN_HEART_FILL: 'icon-envelope-open-heart-fill',
  ICON_CHAT_RIGHT_DOTS_FILL: 'icon-chat-right-dots-fill',
  ICON_EMOJI_DIZZY_FILL: 'icon-emoji-dizzy-fill',
  ICON_RECORD_CIRCLE_FILL: 'icon-record-circle-fill',
  ICON_TABLET_FILL: 'icon-tablet-fill',
  ICON_FILE_EARMARK_SLIDES_FILL: 'icon-file-earmark-slides-fill',
  ICON_MORTARBOARD_FILL: 'icon-mortarboard-fill',
  ICON_CART_PLUS_FILL: 'icon-cart-plus-fill',
  ICON_HOUSE_DOOR_FILL: 'icon-house-door-fill',
  ICON_CHAT_RIGHT_TEXT_FILL: 'icon-chat-right-text-fill',
  ICON_SLASH_CIRCLE_FILL: 'icon-slash-circle-fill',
  ICON_BACKSPACE_REVERSE_FILL: 'icon-backspace-reverse-fill',
  ICON_ARROW_UP_LEFT_SQUARE_FILL: 'icon-arrow-up-left-square-fill',
  ICON_FILE_BINARY_FILL: 'icon-file-binary-fill',
  ICON_PERSON_BADGE_FILL: 'icon-person-badge-fill',
  ICON_CHECK_CIRCLE_FILL: 'icon-check-circle-fill',
  ICON_CLIPBOARD2_PLUS_FILL: 'icon-clipboard2-plus-fill',
  ICON_ENVELOPE_FILL: 'icon-envelope-fill',
  ICON_WRENCH_ADJUSTABLE_CIRCLE_FILL: 'icon-wrench-adjustable-circle-fill',
  ICON_FILE_EARMARK_RICHTEXT_FILL: 'icon-file-earmark-richtext-fill',
  ICON_BADGE_VR_FILL: 'icon-badge-vr-fill',
  ICON_FILE_EARMARK_FONT_FILL: 'icon-file-earmark-font-fill',
  ICON_LIGHTBULB_FILL: 'icon-lightbulb-fill',
  ICON_NUT_FILL: 'icon-nut-fill',
  ICON_CLOUD_SLASH_FILL: 'icon-cloud-slash-fill',
  ICON_TRASH_FILL: 'icon-trash-fill',
  ICON_CIRCLE_FILL: 'icon-circle-fill',
  ICON_PATCH_EXCLAMATION_FILL: 'icon-patch-exclamation-fill',
  ICON_BAG_HEART_FILL: 'icon-bag-heart-fill',
  ICON_CART_CHECK_FILL: 'icon-cart-check-fill',
  ICON_SIM_FILL: 'icon-sim-fill',
  ICON_BADGE_AD_FILL: 'icon-badge-ad-fill',
  ICON_ENVELOPE_CHECK_FILL: 'icon-envelope-check-fill',
  ICON_FILE_EARMARK_PLAY_FILL: 'icon-file-earmark-play-fill',
  ICON_SPEAKER_FILL: 'icon-speaker-fill',
  ICON_HOUSE_FILL: 'icon-house-fill',
  ICON_CALENDAR_MINUS_FILL: 'icon-calendar-minus-fill',
  ICON_BAR_CHART_FILL: 'icon-bar-chart-fill',
  ICON_THUNDERBOLT_FILL: 'icon-thunderbolt-fill',
  ICON_FILE_EARMARK_EASEL_FILL: 'icon-file-earmark-easel-fill',
  ICON_RECORD_BTN_FILL: 'icon-record-btn-fill',
  ICON_FILE_EARMARK_MINUS_FILL: 'icon-file-earmark-minus-fill',
  ICON_SUN_FILL: 'icon-sun-fill',
  ICON_GRID_FILL: 'icon-grid-fill',
  ICON_POSTAGE_HEART_FILL: 'icon-postage-heart-fill',
  ICON_CHAT_LEFT_FILL: 'icon-chat-left-fill',
  ICON_STOP_FILL: 'icon-stop-fill',
  ICON_PEN_FILL: 'icon-pen-fill',
  ICON_CLIPBOARD2_CHECK_FILL: 'icon-clipboard2-check-fill',
  ICON_SHARE_FILL: 'icon-share-fill',
  ICON_CLOUD_PLUS_FILL: 'icon-cloud-plus-fill',
  ICON_RSS_FILL: 'icon-rss-fill',
  ICON_USB_DRIVE_FILL: 'icon-usb-drive-fill',
  ICON_FILE_EARMARK_BAR_GRAPH_FILL: 'icon-file-earmark-bar-graph-fill',
  ICON_FILE_EARMARK_X_FILL: 'icon-file-earmark-x-fill',
  ICON_EMOJI_SMILE_UPSIDE_DOWN_FILL: 'icon-emoji-smile-upside-down-fill',
  ICON_VOLUME_OFF_FILL: 'icon-volume-off-fill',
  ICON_BOOK_FILL: 'icon-book-fill',
  ICON_SEND_FILL: 'icon-send-fill',
  ICON_SKIP_FORWARD_FILL: 'icon-skip-forward-fill',
  ICON_FILE_DIFF_FILL: 'icon-file-diff-fill',
  ICON_SUIT_DIAMOND_FILL: 'icon-suit-diamond-fill',
  ICON_CLOUD_MOON_FILL: 'icon-cloud-moon-fill',
  ICON_SHIELD_FILL_X: 'icon-shield-fill-x',
  ICON_CHAT_SQUARE_TEXT_FILL: 'icon-chat-square-text-fill',
  ICON_PHONE_VIBRATE_FILL: 'icon-phone-vibrate-fill',
  ICON_PRINTER_FILL: 'icon-printer-fill',
  ICON_ENVELOPE_PAPER_HEART_FILL: 'icon-envelope-paper-heart-fill',
  ICON_BINOCULARS_FILL: 'icon-binoculars-fill',
  ICON_BASKET2_FILL: 'icon-basket2-fill',
  ICON_BASKET3_FILL: 'icon-basket3-fill',
  ICON_FILE_PDF_FILL: 'icon-file-pdf-fill',
  ICON_EMOJI_FROWN_FILL: 'icon-emoji-frown-fill',
  ICON_TICKET_PERFORATED_FILL: 'icon-ticket-perforated-fill',
  ICON_LIGHTNING_FILL: 'icon-lightning-fill',
  ICON_FILE_SPREADSHEET_FILL: 'icon-file-spreadsheet-fill',
  ICON_CHAT_SQUARE_DOTS_FILL: 'icon-chat-square-dots-fill',
  ICON_CALENDAR2_HEART_FILL: 'icon-calendar2-heart-fill',
  ICON_FILE_PPT_FILL: 'icon-file-ppt-fill',
  ICON_PIE_CHART_FILL: 'icon-pie-chart-fill',
  ICON_CALENDAR_DAY_FILL: 'icon-calendar-day-fill',
  ICON_FILE_WORD_FILL: 'icon-file-word-fill',
  ICON_CALENDAR3_FILL: 'icon-calendar3-fill',
  ICON_CALENDAR2_FILL: 'icon-calendar2-fill',
  ICON_CAMERA_REELS_FILL: 'icon-camera-reels-fill',
  ICON_BACKSPACE_FILL: 'icon-backspace-fill',
  ICON_EMOJI_EXPRESSIONLESS_FILL: 'icon-emoji-expressionless-fill',
  ICON_CARET_UP_FILL: 'icon-caret-up-fill',
  ICON_FILE_EARMARK_ARROW_UP_FILL: 'icon-file-earmark-arrow-up-fill',
  ICON_SKIP_START_FILL: 'icon-skip-start-fill',
  ICON_ARROW_DOWN_SQUARE_FILL: 'icon-arrow-down-square-fill',
  ICON_DICE_4_FILL: 'icon-dice-4-fill',
  ICON_DICE_5_FILL: 'icon-dice-5-fill',
  ICON_FILE_CODE_FILL: 'icon-file-code-fill',
  ICON_CHAT_LEFT_DOTS_FILL: 'icon-chat-left-dots-fill',
  ICON_TELEPHONE_FORWARD_FILL: 'icon-telephone-forward-fill',
  ICON_TELEPHONE_OUTBOUND_FILL: 'icon-telephone-outbound-fill',
  ICON_EASEL_FILL: 'icon-easel-fill',
  ICON_INFO_SQUARE_FILL: 'icon-info-square-fill',
  ICON_FILE_EARMARK_TEXT_FILL: 'icon-file-earmark-text-fill',
  ICON_HEPTAGON_FILL: 'icon-heptagon-fill',
  ICON_HAND_INDEX_THUMB_FILL: 'icon-hand-index-thumb-fill',
  ICON_CHAT_LEFT_TEXT_FILL: 'icon-chat-left-text-fill',
  ICON_SKIP_END_BTN_FILL: 'icon-skip-end-btn-fill',
  ICON_FILE_EARMARK_SPREADSHEET_FILL: 'icon-file-earmark-spreadsheet-fill',
  ICON_CALENDAR2_X_FILL: 'icon-calendar2-x-fill',
  ICON_SEARCH_HEART_FILL: 'icon-search-heart-fill',
  ICON_RECORD_FILL: 'icon-record-fill',
  ICON_PATCH_MINUS_FILL: 'icon-patch-minus-fill',
  ICON_SD_CARD_FILL: 'icon-sd-card-fill',
  ICON_REPLY_ALL_FILL: 'icon-reply-all-fill',
  ICON_FILE_EXCEL_FILL: 'icon-file-excel-fill',
  ICON_INBOX_FILL: 'icon-inbox-fill',
  ICON_PATCH_PLUS_FILL: 'icon-patch-plus-fill',
  ICON_CLIPBOARD2_PULSE_FILL: 'icon-clipboard2-pulse-fill',
  ICON_BADGE_8K_FILL: 'icon-badge-8k-fill',
  ICON_FOLDER_FILL: 'icon-folder-fill',
  ICON_BAG_X_FILL: 'icon-bag-x-fill',
}
