<div class="card accountCard">
  <div class="card-header">
    <h4>
      {{ 'fffAccount.fffProfileManagement.accountDetails' | cxTranslate }}
    </h4>
  </div>
  <div class="card-body accountCardBody">
    <div class="accountDetail justify-content-between">
      <div class="container-fluid" *ngIf="selected">
        <div class="row">
          <div class="col-lg-6 col-12">
            <div class="container-fluid">
              <div class="row">
                <div class="col-lg-4 col-auto">
                  <div class="accountDetailItem body-text-demi">
                    {{ 'fffAccount.fffProfileManagement.name' | cxTranslate }}
                  </div>
                </div>
                <div class="col-lg-8 col-auto">
                  <div class="body-text">
                    {{ selected.name }}
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-4 col-auto">
                <div class="addressType">
                  <div class="accountDetailItem body-text-demi">
                    {{
                      'fffAccount.fffProfileManagement.billing' | cxTranslate
                    }}&nbsp;
                  </div>
                  <div class="accountDetailItem body-text-demi">
                    {{
                      'fffAccount.fffProfileManagement.address' | cxTranslate
                    }}
                  </div>
                </div>
              </div>
              <div class="col-lg-8 col-auto">
                <div class="body-text" *ngIf="billingAddress">
                  {{ billingAddress.line1 }} <br />
                  {{ billingAddress.line2 }} <br />
                  {{ billingAddress.town }},
                  {{ billingAddress.region?.name }}
                  {{ billingAddress.postalCode }}<br />
                  {{ billingAddress.country?.isocode }} <br />
                  {{ billingAddress.phone }}
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-4 col-auto">
                <div class="accountDetailItem body-text-demi">
                  {{
                    'fffAccount.fffProfileManagement.tradingPartner'
                      | cxTranslate
                  }}
                </div>
              </div>
              <div class="col-lg-8 col-auto">
                <div class="body-text">
                  {{ selected.tradingPartn }}
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-6 col-12">
            <div class="row">
              <div class="col-lg-4 col-auto">
                <div class="accountDetailItem body-text-demi">
                  {{ 'fffAccount.fffProfileManagement.gpo' | cxTranslate }}
                </div>
              </div>
              <div class="col-lg-8 col-auto">
                <div class="body-text">{{ selected.gpoName }}</div>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-4 col-auto">
                <div class="addressType">
                  <div class="accountDetailItem body-text-demi">
                    {{
                      'fffAccount.fffProfileManagement.shipping' | cxTranslate
                    }}&nbsp;
                  </div>
                  <div class="accountDetailItem body-text-demi">
                    {{
                      'fffAccount.fffProfileManagement.address' | cxTranslate
                    }}
                  </div>
                </div>
              </div>
              <div class="col-lg-8 col-auto">
                <div class="body-text" *ngIf="shippingAddress">
                  {{ shippingAddress.line1 }} <br />
                  {{ shippingAddress.line2 }} <br />
                  {{ shippingAddress.town }},
                  {{ shippingAddress.region?.name }}
                  {{ shippingAddress.postalCode }}<br />
                  {{ shippingAddress.country?.isocode }} <br />
                  {{ shippingAddress.phone }}
                </div>
              </div>
            </div>
            <div *ngIf="selected.attentionName" class="row">
              <div class="col-lg-4 col-auto">
                <div class="accountDetailItem body-text-demi">
                  {{
                    'fffAccount.fffChangeAccount.attentionName' | cxTranslate
                  }}
                </div>
              </div>
              <div class="col-lg-8 col-auto">
                <div class="body-text">{{ selected.attentionName }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--    <a class="cardLink" (click)="openChangeAddressDrawer()">-->
    <!--      {{'fffAccount.fffProfileManagement.requestChangeAddress'|cxTranslate}}-->
    <!--    </a>-->
  </div>
</div>
