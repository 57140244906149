import { ChangeDetectorRef, Component } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { FffCartFacade } from '@app/fff-enterprise/fff-common-services/fff-cart-facade';
import { FffCommunicationService } from '@app/fff-enterprise/fff-common-services/fff-communication.service';
import { FffRingCentralChatBotService } from '@app/fff-enterprise/fff-service-third-party/fff-ring-central-chat-bot.service';
import { FffUserAccountService } from '@app/shared/services/fff-user-account.service';
import { FffGtmEventService } from '@app/spartacus/features/tracking/gmt/event';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { ActiveCartFacade } from '@spartacus/cart/base/root';
import { SavedCartFacade } from '@spartacus/cart/saved-cart/root';
import {
  AuthRedirectService,
  AuthRedirectStorageService,
  AuthService,
  AuthStorageService,
  GlobalMessageService,
  GlobalMessageType,
  OAuthLibWrapperService,
  RoutingService,
  StateWithClientAuth,
  UserIdService,
} from '@spartacus/core';
import {
  LoginFormComponent,
  LoginFormComponentService,
} from '@spartacus/user/account/components';
import { BehaviorSubject, Subscription, from } from 'rxjs';
import { tap, withLatestFrom } from 'rxjs/operators';
import { FILLED_ICON_TYPE } from 'src/app/models/fff-filled-icons.model';
import { FffAuthenticationService } from '../../fff-common-services/fff-user-authentication.service';
import { FffUserFirstLoginPopupComponent } from '../fff-user-first-login-popup/fff-user-first-login-popup.component';

@Component({
  selector: 'fff-login',
  templateUrl: './fff-login.component.html',
})
export class FffLoginComponent extends LoginFormComponent {
  userAccountDetails: any;
  protected busy$ = new BehaviorSubject(false);
  filledIconTypes = FILLED_ICON_TYPE;
  showPassword: boolean = false;
  isUserDisabledEvent!: Subscription;
  isUserDisabled!: boolean;

  constructor(
    protected service: LoginFormComponentService,
    public routingService: RoutingService,
    public fffActiveCartService: ActiveCartFacade,
    protected globalMessageService: GlobalMessageService,
    protected store: Store<StateWithClientAuth>,
    protected userIdService: UserIdService,
    protected oAuthLibWrapperService: OAuthLibWrapperService,
    protected authStorageService: AuthStorageService,
    protected authRedirectService: AuthRedirectService,
    protected authRedirectStorageService: AuthRedirectStorageService,
    protected authenticationService: FffAuthenticationService,
    protected modalService: NgbModal,
    protected auth: AuthService,
    protected fffUserAccountService: FffUserAccountService,
    protected savedCartService: SavedCartFacade,
    protected fffCartFacade: FffCartFacade,
    protected gtmService: FffGtmEventService,
    private communicationService: FffCommunicationService,
    private chatbotService: FffRingCentralChatBotService,
    public cdr: ChangeDetectorRef
  ) {
    super(service);
  }
  form: UntypedFormGroup = this.service.form;
  ngOnInit(): void {
    this.isUserDisabledEvent =
      this.authenticationService.isUserDisabled.subscribe(() => {
        this.showDisabledUserMessage();
      });
  }

  openLiveChat(): void {
    this.chatbotService.openChatBot();
  }

  showDisabledUserMessage() {
    this.isUserDisabled = true;
    this.cdr.detectChanges();
  }
  registerSubmit() {
    this.routingService.go(['/register-account']);
  }

  onSubmit(): void {
    this.loginUser();
  }

  loginUser() {
    if (!this.form.valid) {
      this.form.markAllAsTouched();
      return;
    }

    this.busy$.next(true);

    from(
      this.authenticationService.loginWithCredentials(
        this.form.value.userId.toLowerCase(),
        this.form.value.password
      )
    )
      .pipe(
        withLatestFrom(this.auth.isUserLoggedIn()),
        tap(([_, isLoggedIn]) =>
          this.onSuccess(isLoggedIn, this.form.value.userId)
        )
      )
      .subscribe();
  }

  clearLoginError(e: any) {
    this.form.get('userId')?.setErrors({ loginError: null });
    this.form.get('userId')?.updateValueAndValidity();

    this.form.get('password')?.setErrors({ loginError: null });
    this.form.get('password')?.updateValueAndValidity();
    this.isUserDisabled = false;
  }

  protected onSuccess(isLoggedIn: boolean, email: string): void {
    if (isLoggedIn) {
      this.globalMessageService.remove(GlobalMessageType.MSG_TYPE_ERROR);
      this.fffUserAccountService.isLoggedInMsg$.next(true);
      this.form.reset();
      this.gtmService.setRecentlyLoggedIn(true);
      this.fffUserAccountService.loadProfile();
      this.validateCart();
      this.getConfig();
    } else {
      this.validateIsMigratedUserFirstLogin(email);
    }

    this.busy$.next(false);
  }

  private validateCart(): void {
    this.fffCartFacade.getActive().subscribe(cart => {
      // If response has no cart id, then need a new cart
      if (!cart.code) {
        this.fffCartFacade.getOrRestoreCart().subscribe(data => {
          if (data.code) {
            this.savedCartService.restoreSavedCart(data.code);
          }
        });
      }
      this.fffActiveCartService.reloadActiveCart();
    });
  }

  private getConfig(): void {
    let configMap: any = {};
    const configKeys: string[] = [
      'storefront.startup.cache.priceSingle.enabled',
      'storefront.startup.cache.priceCart.enabled',
      'storefront.startup.cache.priceList.enabled',
      'storefront.startup.cache.invSet.enabled',
      'storefront.startup.cache.invList.enabled',
      'storefront.startup.cache.custAccounts.enabled',
      'storefront.startup.cache.productSearch.enabled',
      'storefront.startup.cache.product.enabled',
    ];

    this.communicationService.getConfigDetails().subscribe(config => {
      configKeys.forEach(key => {
        if (config[key]) {
          configMap[key] = config[key];
        }
      });

      localStorage.setItem('configMap', JSON.stringify(configMap));
    });
  }

  enablePassword(): void {
    this.showPassword = !this.showPassword;
  }

  validateIsMigratedUserFirstLogin(email: string): void {
    const response$ =
      this.authenticationService.isMigratedUserFirstLogin(email);
    response$.subscribe((data: boolean) => {
      if (data) {
        const modalRef = this.modalService.open(
          FffUserFirstLoginPopupComponent,
          {
            centered: true,
            size: 'md',
            modalDialogClass: 'modal-container',
          }
        );
      } else if (!this.isUserDisabled) {
        this.form.get('userId')?.setErrors({ loginError: true });
        this.form.get('password')?.setErrors({ loginError: true });
      }
    });
  }
}
