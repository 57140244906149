import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IconModule } from '@spartacus/storefront';
import { I18nModule } from '@spartacus/core';
import {
  FffGlobalNotificationComponent,
} from '@enterprise/fff-header/fff-global-notification/fff-global-notification.component';

@NgModule({
  declarations: [FffGlobalNotificationComponent],
  imports: [
    CommonModule,
    IconModule,
    I18nModule,
  ],
  exports: [FffGlobalNotificationComponent],
})
export class FffGlobalNotificationModule { }
