<ng-container *ngIf="activeSite$ | async as activeSite">
  <ng-container *ngIf="!urlReceived; else runscriptMode">
    <div *ngIf="loading$ | async" class="spinner">
      <cx-spinner></cx-spinner>
    </div>
    <ng-container *ngIf="simple$ | async as data">
      <ng-container [ngSwitch]="data.bannerType">
        <ng-container *ngSwitchCase="'1'">
          <div
            (click)="
              openUrlLink(data.banner?.urlLink); $event.stopPropagation()
            "
            class="cursor-pointer"
            (mouseenter)="changeContent = true"
            (mouseleave)="changeContent = false"
          >
            <a
              (click)="
                openUrlLink(data.banner?.urlLink); $event.stopPropagation()
              "
            >
              <cx-icon
                class="section1-img"
                [hidden]="data.active && !changeContent"
                [type]="data.active?.activeIcon"
              >
              </cx-icon>
              <cx-icon
                class="section1-img"
                [hidden]="data.active && changeContent"
                [type]="data.active?.icon"
              >
              </cx-icon>
              <div
                class="headline h1"
                *ngIf="data.banner?.headline"
                [innerHTML]="data.banner?.headline"
              ></div>
            </a>
          </div>
        </ng-container>

        <ng-container *ngSwitchCase="'2'">
          <ng-container *ngIf="data.banner?.media">
            <cx-media
              [title]="data.banner?.headline"
              [container]="data.banner.media"
            >
            </cx-media>
          </ng-container>
          <div class="content-container">
            <div
              class="headline"
              *ngIf="data.banner?.headline"
              [innerHTML]="data.banner.headline"
            ></div>
            <div
              class="content"
              *ngIf="data.banner?.content"
              [innerHTML]="data.banner.content"
            ></div>
          </div>
        </ng-container>

        <ng-container *ngSwitchCase="'3'">
          <div (click)="openUrlLink(data.banner?.urlLink)" class="generic-link">
            <a [target]="getTarget(data.banner)">
              <p
                class="headline"
                *ngIf="data.banner?.headline"
                [innerHTML]="data.banner?.headline"
              ></p>
              <cx-icon class="cx-icon">
                <svg>
                  <use [attr.xlink:href]="data.banner?.iconStyle"></use>
                </svg>
              </cx-icon>
            </a>
          </div>
        </ng-container>

        <ng-container *ngSwitchCase="'4'">
          <cx-icon class="cx-icon">
            <svg>
              <use [attr.xlink:href]="data.banner?.iconStyle"></use>
            </svg>
          </cx-icon>
          <div
            class="content"
            *ngIf="data.banner?.content"
            [innerHTML]="data.banner?.content"
          ></div>
        </ng-container>
        <ng-container *ngSwitchCase="'5'">
          <div class="banner rotatingBanner">
            <div *ngIf="data.banner?.media">
              <cx-media
                [title]="data.banner?.headline"
                [container]="data.banner.media"
              >
              </cx-media>
            </div>
            <div
              class="banner__canvas d-flex align-items-center justify-content-center"
              (click)="openUrlLink(data.banner?.urlLink)"
            >
              <div class="container">
                <div
                  class="headline text-container"
                  *ngIf="data.banner?.headline"
                  [innerHTML]="data.banner?.headline"
                  #headLineContainer
                  [ngStyle]="{ 'font-size': headlineFontSize }"
                ></div>
                <div
                  class="content"
                  *ngIf="data.banner?.content"
                  [innerHTML]="data.banner?.content"
                  #contentContainer
                  [ngStyle]="{ 'font-size': contentFontSize }"
                ></div>
              </div>
            </div>
          </div>
        </ng-container>

        <ng-container *ngSwitchDefault>
          <div class="banner">
            <div *ngIf="data.banner?.media">
              <fff-media
                [title]="data.banner.headline"
                [container]="data.banner.media"
              >
              </fff-media>
            </div>
            <div
              class="banner__canvas d-flex align-items-center justify-content-center"
              (click)="openUrlLink(data.banner?.urlLink)"
            >
              <div class="container">
                <div
                  class="headline"
                  *ngIf="data.banner?.headline"
                  [innerHTML]="data.banner?.headline"
                ></div>
                <div
                  class="content"
                  *ngIf="data.banner?.content"
                  [innerHTML]="data.banner?.content"
                ></div>
              </div>
            </div>
          </div>
        </ng-container>
      </ng-container>
    </ng-container>
  </ng-container>
  <ng-template #runscriptMode>
    <div class="banner" *ngIf="simple$ | async as data">
      <div *ngIf="data.banner?.media">
        <fff-media
          [title]="data.banner.headline"
          [container]="data.banner.media"
        >
        </fff-media>
      </div>
      <div
        class="banner__canvas d-flex align-items-center justify-content-center"
      >
        <div class="container">
          <div class="ad-banner" [innerHtml]="urlReceived"></div>
        </div>
      </div>
    </div>
  </ng-template>
</ng-container>
