import { Component, Input, OnChanges, OnInit } from '@angular/core';

@Component({
  selector: 'fff-progress-bar',
  template: `
    <div class="progress-bar-container">
      <div
        class="progress-bar {{ color }}"
        [ngStyle]="{ width: progress + '%' }"
      ></div>
    </div>
  `,
})
export class FffProgressBarComponent implements OnInit, OnChanges {
  @Input()
  progress: number = 0;
  @Input()
  total: number = 0;
  color!: string;

  constructor() {}

  showProgressBarData() {
    //if we don't have a total aka no requirement, it's 100%.
    if (this.total === 0) {
      this.total = this.progress;
    } else if (!this.total) {
      this.total = 100;
    }
    //if the progress is greater than the total, it's also 100%.
    if (this.progress > this.total) {
      this.progress = 100;
      this.total = 100;
    }
    this.progress = (this.progress / this.total) * 100;
    this.color = this.progress > 0 ? 'blue' : 'light-gray';
  }

  ngOnInit(): void {
    this.showProgressBarData();
  }

  ngOnChanges(): void {
    this.showProgressBarData();
  }
}
