import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FFFLoadersModule } from '@app/shared/components/fff-loaders/fff-loaders.module';
import { NgbAccordionModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import {
  AuthGuard,
  CmsConfig,
  I18nModule,
  provideDefaultConfig,
} from '@spartacus/core';
import { IconModule, SpinnerModule } from '@spartacus/storefront';
import { FffApprovalSentComponent } from './fff-approval-sent/fff-approval-sent.component';
import { FffApprovalsComponent } from './fff-approvals.component';
import { FilterApprovalsHistoryComponent } from './filter-approvals-history/filter-approvals-history.component';
import { FilterApprovalsComponent } from './filter-approvals/filter-approvals.component';

@NgModule({
  declarations: [
    FffApprovalsComponent,
    FilterApprovalsComponent,
    FffApprovalSentComponent,
    FilterApprovalsHistoryComponent,
  ],
  imports: [
    CommonModule,
    I18nModule,
    IconModule,
    NgbAccordionModule,
    FormsModule,
    NgSelectModule,
    ReactiveFormsModule,
    FFFLoadersModule,
    SpinnerModule,
  ],
  providers: [
    provideDefaultConfig(<CmsConfig>{
      cmsComponents: {
        ApprovalsPageComponent: {
          component: FffApprovalsComponent,
          guards: [AuthGuard],
        },
      },
    }),
  ],
})
export class FffApprovalsModule {}
