import { EntityMeta } from "@spartacus/core/src/state/utils/entity";
import { EntityProcessesLoaderAction } from "@spartacus/core/src/state/utils/entity-processes-loader";
import { ProcessesLoaderMeta } from "@spartacus/core/src/state/utils/processes-loader";

export class FFFEntityProcessesIncrementAction implements EntityProcessesLoaderAction {
  type = "[ENTITY] PROCESSES INCREMENT";
  readonly meta: any;
  constructor(entityType: string, id: string | string[]) {
    this.meta = entityProcessesIncrementMeta(entityType, id);
  }
}

export class FFFEntityProcessesDecrementAction implements EntityProcessesLoaderAction {
  type = '[ENTITY] PROCESSES DECREMENT';
  readonly meta: any;
  constructor(entityType: string, id: string | string[]) {
    this.meta = entityProcessesDecrementMeta(entityType, id);
  }
}

export function entityProcessesIncrementMeta(
  entityType: string,
  id: string | string[]
): any {
  return {
    ...processesIncrementMeta(entityType),
    ...entityMeta(entityType, id),
  };
}

export function entityProcessesDecrementMeta(
  entityType: string,
  id: string | string[]
): any {
  return {
    ...processesDecrementMeta(entityType),
    ...entityMeta(entityType, id),
  };
}

export function processesIncrementMeta(
  entityType: string
): ProcessesLoaderMeta {
  return {
    entityType: entityType,
    loader: {},
    processesCountDiff: 1,
  };
}

export function processesDecrementMeta(
  entityType: string
): ProcessesLoaderMeta {
  return {
    entityType: entityType,
    loader: {},
    processesCountDiff: -1,
  };
}

export function entityMeta(type: string, id: string | string[]): EntityMeta {
  return {
    entityType: type,
    entityId: id,
  };
}