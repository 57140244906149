<div class="container p-lg-4 py-4">
  <div class="user-info-container p-lg-3">
    <div class="row">
      <div class="col-12 d-flex justify-content-between">
        <span class="body-text-demi-xl h4">{{
          'fffReturns.fffRequest.stepOne' | cxTranslate
        }}</span>
        <cx-icon
          class="cx-icon icon-md mb-3 cursor-pointer"
          *ngIf="!editMode"
          (click)="changeEditMode()"
          [type]="outlinedIconTypes.ICON_PENCIL"
        >
        </cx-icon>
        <!-- <cx-icon class="cx-icon icon-md mb-3 cursor-pointer" *ngIf="editMode"
                 (click)="changeEditMode()" [type]="outlinedIconTypes.ICON_SAVE">
        </cx-icon> -->
        <button
          class="btn btn-primary btn-xs"
          *ngIf="editMode"
          (click)="changeEditMode()"
        >
          Save
        </button>
      </div>
    </div>
    <div class="row border-separator pb-3">
      <div class="col-12">
        <div class="row user-personal-information">
          <ng-container *ngIf="!editMode; else displayForm">
            <div class="col-lg-4 col-12">
              <span class="body-text-demi">{{
                'fffReturns.name' | cxTranslate
              }}</span>
              &nbsp;
              <span>{{ contactInformationData.name }}</span>
            </div>
            <div class="col-lg-4 col-12 mt-lg-0 mt-2">
              <span class="body-text-demi">{{
                'fffReturns.phone' | cxTranslate
              }}</span>
              &nbsp;
              <span>{{ contactInformationData.phone }}</span>
            </div>
            <div class="col-lg-4 col-12 mt-lg-0 mt-2">
              <span class="body-text-demi">{{
                'fffReturns.email' | cxTranslate
              }}</span>
              &nbsp;
              <span>{{ contactInformationData.email }}</span>
            </div>
            <div class="col-lg-12 pt-lg-2 mt-lg-0 mt-2">
              <span class="body-text-demi">{{
                'fffReturns.poNumber' | cxTranslate
              }}</span>
              &nbsp;
              <span>{{ contactInformationData.poNumber }}</span>
            </div>
          </ng-container>
          <ng-template #displayForm>
            <form
              [formGroup]="contactInformationForm"
              class="col-12 px-0 d-flex flex-wrap"
            >
              <div class="d-flex flex-column col-lg-4 col-12">
                <div class="d-flex justify-content-between">
                  <p class="mb-0 body-text-md body-text-demi">
                    {{ 'fffReturns.name' | cxTranslate }} &#42;
                  </p>
                </div>
                <input
                  class="form-control form-control-md"
                  type="text"
                  formControlName="name"
                />
                <cx-form-errors
                  aria-live="assertive"
                  aria-atomic="true"
                  [control]="$any(contactInformationForm).get('name')"
                ></cx-form-errors>
              </div>

              <div class="d-flex flex-column col-lg-4 col-12">
                <div class="d-flex justify-content-between">
                  <p class="mb-0 body-text-md body-text-demi">
                    {{ 'fffReturns.phone' | cxTranslate }} &#42;
                  </p>
                </div>
                <input
                  class="form-control form-control-md"
                  type="text"
                  formControlName="phone"
                />
                <cx-form-errors
                  aria-live="assertive"
                  aria-atomic="true"
                  [control]="$any(contactInformationForm).get('phone')"
                ></cx-form-errors>
              </div>

              <div class="d-flex flex-column col-lg-4 col-12">
                <div class="d-flex justify-content-between">
                  <p class="mb-0 body-text-md body-text-demi">
                    {{ 'fffReturns.email' | cxTranslate }} &#42;
                  </p>
                </div>
                <input
                  class="form-control form-control-md"
                  type="text"
                  formControlName="email"
                />
                <cx-form-errors
                  aria-live="assertive"
                  aria-atomic="true"
                  [control]="$any(contactInformationForm).get('email')"
                ></cx-form-errors>
              </div>

              <div class="d-flex flex-column col-lg-4 col-12">
                <div class="d-flex justify-content-between">
                  <p class="mb-0 body-text-md body-text-demi">
                    {{ 'fffReturns.poNumber' | cxTranslate }} &#42;
                  </p>
                </div>
                <input
                  class="form-control form-control-md"
                  type="text"
                  formControlName="poNumber"
                />
                <cx-form-errors
                  aria-live="assertive"
                  aria-atomic="true"
                  [control]="$any(contactInformationForm).get('poNumber')"
                ></cx-form-errors>
              </div>
            </form>
          </ng-template>
        </div>
      </div>
    </div>
  </div>
  <div class="products-entries mt-4 px-lg-3">
    <div class="row">
      <div class="col-12">
        <h4 class="body-text-demi-xl h4">
          {{ 'fffReturns.fffRequest.stepTwo' | cxTranslate }}
        </h4>
      </div>
    </div>

    <div class="container m-0 products-entries-container p-0 p-lg-3">
      <ng-container *ngIf="entries && profile">
        <div
          class="row mx-0 mt-lg-0"
          *ngFor="let entry of entries.returnEntries; let index = index"
          [ngClass]="index > 0 ? 'mt-3' : ''"
        >
          <div class="col-12 p-0">
            <fff-fet-returns-request-entry
              [entry]="entry"
              [index]="index"
              [userId]="profile.user.uid"
            ></fff-fet-returns-request-entry>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</div>
