import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { FffInteractiveAllocationsEntry } from '@app/models/fff-allocations.model';
import { OUTLINED_ICON_TYPE } from '@app/models/fff-outline-icons.model';
import { FFFUser } from '@app/reducers';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { FffAllocationsService } from '../../fff-allocations.service';
import { FffUpdateInteractiveAllocationsPopupComponent } from '../fff-update-interactive-allocations-popup/fff-update-interactive-allocations-popup.component';
import { FffUpdateInteractiveAllocationsService } from '../fff-update-interactive-allocations.service';

@Component({
  selector: 'fff-update-interactive-allocations-footer',
  templateUrl: './fff-update-interactive-allocations-footer.component.html',
})
export class FffUpdateInteractiveAllocationsFooterComponent implements OnInit {

  ALLOCATION_UPDATE_ACTION: string = 'UPDATE';

  outlinedIconTypes = OUTLINED_ICON_TYPE;
  amountReallocated$: Observable<number | undefined> = this.fffUpdateInteractiveAllocationsService.amountReallocated$;
  interactiveAllocationsList: FffInteractiveAllocationsEntry[] = [];
  userData: FFFUser | undefined;
  loading: boolean = false;
  updateAllocationsGroupForm: UntypedFormGroup | undefined;

  @Input() data: any = {};

  constructor(
    private fffUpdateInteractiveAllocationsService: FffUpdateInteractiveAllocationsService,
    private fffAllocationsService: FffAllocationsService,
    private modalService: NgbModal,
    private activeModalService: NgbActiveModal
  ) { }

  ngOnInit(): void {
    if (this.data) {
      this.interactiveAllocationsList = this.data.interactiveAllocationsList;
      this.userData = this.data.userData;
    }
    this.fffUpdateInteractiveAllocationsService.updateAllocationsGroupForm$.subscribe(data => this.updateAllocationsGroupForm = data);
  }

  invalidAmount(data: any): boolean {
    return data == undefined || data != 0;
  }

  submitAllocationUpdate(): void {

    if (this.updateAllocationsGroupForm && !this.updateAllocationsGroupForm.valid) {
      this.updateAllocationsGroupForm.markAllAsTouched();
      return;
    }

    this.loading = true;
    let allocationUpdateValue = this.fffUpdateInteractiveAllocationsService.updateAllocationsGroup$.value;
    if (allocationUpdateValue) {
      delete allocationUpdateValue.searchFilter;

      const submitAllocationsData: FffInteractiveAllocationsEntry[] = [];
      for (const key in allocationUpdateValue) {
        const element = allocationUpdateValue[key];
        if (element.deltaQty != null) {
          let result = this.interactiveAllocationsList.find(item => item.productCode == element.productCode && item.account == element.account);
          if (result) {
            delete result.expand;
            result.deltaQty = element.deltaQty;
            result.action = this.ALLOCATION_UPDATE_ACTION;
            submitAllocationsData.push(result);
          }
        }
      }

      this.fffAllocationsService.postInteractiveAllocationsList(this.userData?.user?.uid!, submitAllocationsData)
        .subscribe((response: any) => {
          this.loading = false;
          let errorMessage = '';
          response.results.forEach((element: any) => {
            if (element.message) {
              errorMessage += element.message + ', ';
            }
          });
          const hasErrorData = !!errorMessage && errorMessage?.length > 0;
          this.showPopUp(hasErrorData, errorMessage);
        }, () => {
          this.loading = false;
          this.showPopUp(true, '');
        });
    }
  }

  invalidForm(): boolean {
    let hasErrrors = false;
    for (const controlkey in this.updateAllocationsGroupForm?.controls) {
      if ('searchFilter' != controlkey) {
        const control = this.updateAllocationsGroupForm?.controls[controlkey];
        const currentAllocation = control?.get('currentAllocation')?.value;
        const deltaQty = control?.get('deltaQty')?.value;
        if (currentAllocation) {
          if (deltaQty < (currentAllocation * -1)) {
            hasErrrors = true;
          }
        }
      }
    }
    return !this.updateAllocationsGroupForm || !this.updateAllocationsGroupForm?.valid || hasErrrors;
  }

  private showPopUp(hasErrorData: boolean, errorDescription: string): void {
    const modalRef = this.modalService.open(FffUpdateInteractiveAllocationsPopupComponent, {
      centered: true,
      size: 'md',
      backdrop: 'static',
      modalDialogClass: 'modal-container',
    });

    modalRef.componentInstance.hasErrorData = hasErrorData;
    modalRef.componentInstance.titleMessage = hasErrorData ? 'fffUpdateAllocationsPopUp.titleError' : 'fffUpdateAllocationsPopUp.title';
    modalRef.componentInstance.errorDescription = errorDescription;


    modalRef.dismissed.subscribe(() => this.closeDrawer(!hasErrorData));
    modalRef.result.then(() => this.closeDrawer(!hasErrorData));
  }

  closeDrawer(refreshList: boolean): void {
    this.fffUpdateInteractiveAllocationsService.amountReallocated$.next(undefined);
    this.activeModalService.close({ refreshList: refreshList });
  }

}
