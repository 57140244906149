<div class="container">
  <div>
    <div class="d-flex">
      <h3 class="pr-4">{{ 'fffReturns.title' | cxTranslate }}</h3>
      <button class="btn btn-secondary btn-xs" (click)="openFilterDrawer()">
        {{ 'fffOrderHistory.filter' | cxTranslate }}
        <cx-icon class="cx-icon icon-sm" [type]="outlinedIconTypes.ICON_FILTER">
        </cx-icon>
      </button>
    </div>

    <hr class="sep-table-title">
  </div>
  <div *ngIf="returnList$ | async as returnList; else loadingTemplate">
    <ng-container *ngIf="returnList.orders && returnList.orders.length > 0; else emptyMessage">
      <fff-table [hasPaginator]="true" [data]="{ 'data': returnList.orders, 'total': returnList.pagination.totalResults, loading: false, current: returnList.pagination.currentPage }" [fields]="fields" [hasTemplate]="true"
                 [mobileSize]="mobileSize" [columnsGrid]="columnsGrid" [itemTemplate]="returnsTemplate"
                 (pageChange)="pageChange(profile, $event)" [customClass]="'user-price-list'"
                 [size]="(returnList.pagination && returnList.pagination.pageSize) ? returnList.pagination.pageSize: defaultPageSize">
      </fff-table>
      <ng-template #returnsTemplate let-item="item">
        <div class="return-history-item td" [style.grid-template-columns]="columnsGrid.desktop">

          <div class="px-0 col-6 col-lg-12 order-0">
            <div class="col-12 px-0 d-block d-lg-none pb-2 pt-3">{{ 'fffReturns.fluReturn' | cxTranslate }}</div>
            <span class="desktop-item-code">{{ item.code }}</span>
            <span class="mobile-item-code cursor-pointer" (click)="viewReturnDetails(item.code)">
              {{ item.code }}
              <cx-icon class="cx-icon icon-sm px-2" [type]="outlinedIconTypes.ICON_CHEVRON_RIGHT"></cx-icon>
            </span>
          </div>

          <div class="px-0 col-6 col-lg-12 order-2 order-lg-1">
            <div class="col-12 px-0 d-block d-lg-none pb-2 pt-3">{{ 'fffReturns.fluRGACreationDate' | cxTranslate }}</div>
            <div>{{ item.placed | date: 'MM/dd/yyyy' }}</div>
          </div>
          <div class="status-column px-0 col-12 col-lg-12 order-3 order-lg-2">
            <div class="status-container col-12 px-0 d-block">
              <span class="status-title pb-2">{{ 'fffOrderHistory.status' | cxTranslate }}</span>
              <div class="d-flex justify-content-between">
                <span>{{ item.sapOrderStatus | titlecase }}</span>
                <div class="view-button-container cursor-pointer" (click)="viewReturnDetails(item.code)">
                  <span class="action-label">{{ 'fffOrderHistory.actions.view' | cxTranslate }}</span>
                  <cx-icon class="cx-icon icon-sm px-2" [type]="outlinedIconTypes.ICON_CHEVRON_COMPACT_RIGHT"></cx-icon>
                </div>
              </div>
              <div class="mobile-view-button-container d-lg-none mt-4">
                <button type="button" class="btn btn-tertiary w-100" (click)="viewReturnDetails(item.code)">
                  <span>{{ 'fffOrderHistory.actions.view' | cxTranslate }}</span>
                </button>
              </div>
            </div>
          </div>

        </div>
      </ng-template>
    </ng-container>

    <ng-template #emptyMessage>
      <div class="text-center mt-5">
        <h4 class="h4 mb-4">{{'fffReturns.noReturns' | cxTranslate}}</h4>
        <button class="btn btn-primary m-auto" (click)="goToHomePage()">{{'fffSavedCarts.goToHomePage' | cxTranslate}}</button>
      </div>
    </ng-template>
  </div>
</div>
<ng-template #loadingTemplate>
  <fff-table-loader [fields]="fields"></fff-table-loader>
</ng-template>
