import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import {
  FffProductListItemModule
} from '@enterprise/fff-product-list-page/fff-product-list/fff-product-list-item/fff-product-list-item.module';
import { AuthGuard, CmsConfig, I18nModule, provideDefaultConfig } from '@spartacus/core';
import { FffFavoritesListComponent } from './fff-favorites-list.component';

@NgModule({
  declarations: [FffFavoritesListComponent],
  imports: [ CommonModule, FffProductListItemModule, I18nModule ],
  providers: [
    provideDefaultConfig(<CmsConfig>{
      cmsComponents: {
        FavoritesComponent: {
          component: FffFavoritesListComponent,
          guards: [AuthGuard],
        }
      }
    })
  ]
})
export class FffFavoritesListModule { }
