import { NgModule } from '@angular/core';
import {
  FffFavoritesListModule
} from '@enterprise/fff-favorites/fff-favorites-list/fff-favorites-list.module';
import { FffProductModule } from '@enterprise/fff-product/fff-product.module';


@NgModule({
  imports: [
    FffFavoritesListModule,
    FffProductModule
  ],
  providers: [],
})
export class FffFavoritesModule { }
