<div *ngIf="model">
  <ng-container *ngIf="model?.pagination?.totalResults! > 0">
    <!--Product List Information-->
    <div class="plp" *ngIf="profile$ | async as profile">
      <cx-page-slot position="PrebookAcipSection"></cx-page-slot>
      <ng-container *ngIf="showPagination$ | async">
        <div class="row">
          <div class="col-12 d-lg-block d-none">
            <fff-product-sort [user]="profile.user"></fff-product-sort>
          </div>
        </div>
      </ng-container>
      <div class="pt-2 pl-4 pr-0 pr-3 product-list">
        <!--Product List Item Component-->
        <ng-container *ngIf="activeSite$ | async as activeSite">
          <ng-container *ngIf="profile.user; else anonymous">
            <ng-container
              *ngFor="
                let product of displayedProducts;
                let i = index;
                trackBy: trackByFn
              "
            >
              <fff-product-list-item
                [product]="product"
                [user]="profile.user"
                [activeSite]="activeSite"
                [b2bunit]="profile.selected"
                [prebookFormEnabled]="(isPrebookFormEnabled$ | async) ?? false"
              >
              </fff-product-list-item>
            </ng-container>
          </ng-container>
          <ng-template #anonymous>
            <ng-container
              *ngFor="let product of model?.products; let i = index"
            >
              <fff-product-list-item
                [product]="product"
                [activeSite]="activeSite"
              >
              </fff-product-list-item>
            </ng-container>
          </ng-template>
          <ng-container
            *ngIf="
              userLoggedIn
                ? (showPagination$ | async) &&
                  productBoxes?.length == model?.products?.length
                : true
            "
          >
            <div class="d-flex justify-content-center mt-4">
              {{ 'fffPriceList.showing' | cxTranslate }}
              {{ currentPage * pageSize + 1 }} -
              {{
                currentPage + 1 === totalPages
                  ? totalResults
                  : (currentPage + 1) * pageSize
              }}
              {{ 'fffPriceList.of' | cxTranslate }} {{ totalResults }}
            </div>
            <cx-pagination
              class="mt-3"
              [pagination]="model.pagination!"
              (viewPageEvent)="changePage($event)"
              [defaultPage]="model.pagination?.currentPage!"
            ></cx-pagination>
          </ng-container>
        </ng-container>
      </div>
    </div>
  </ng-container>
</div>
