import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FffCommunicationService } from '@app/fff-enterprise/fff-common-services/fff-communication.service';
import { FffUserAccountService } from '@app/shared/services/fff-user-account.service';
import { FFFCustomValidators } from '@app/shared/validators/fff-custom-validators';
import { CmsService } from '@spartacus/core';
import { NavigationNode, NavigationService } from '@spartacus/storefront';
import { take } from 'rxjs/operators';
import { FffDrawerService } from 'src/app/shared/drawer/fff-drawer.service';

@Component({
  selector: 'fff-my-account-drawer-body',
  templateUrl: './fff-my-account-drawer-body.component.html',
})
export class FffMyAccountDrawerBodyComponent implements OnInit {

  node: NavigationNode | undefined;

  constructor(
    private cmsService: CmsService,
    private navigationService: NavigationService,
    private drawerService: FffDrawerService,
    private communicationService: FffCommunicationService,
    private fffUserAccountService: FffUserAccountService,
    private cdRef: ChangeDetectorRef
  ) { }

  notifications$ = this.communicationService.getNotifications();
  unfilteredNodes: any;

  ngOnInit(): void {
    this.navigationService.getNavigationNode(this.cmsService.getComponentData('MyAccountComponent')).pipe(take(1)).subscribe((data) => {
      this.filterItems(data);
    })
  }

  filterItems(unfilteredNode: any) {
    this.fffUserAccountService.getProfile().subscribe((profile) => {
      let isApprover = profile?.user.roles?.includes('b2bapprovergroup'); 

      if (!isApprover) {
        this.filterCategory('Approvals' , unfilteredNode);
      }

      if (!FFFCustomValidators.validateLicense(profile?.selected) && profile?.selected.primaryLicenseType !== 'DEA') {
        this.filterCategory('CSOS', unfilteredNode);
      }

      this.node = unfilteredNode;
      this.cdRef.detectChanges();
    })
  }

  filterCategory(category: string, nodes: any) {
    if (!nodes?.children) {
      return;
    }
    nodes.children = nodes.children?.filter((e: any) => e.title !== category);
  }

  public closeDrawer() {
    this.drawerService.closeDrawer();
  }

  fetchMedals(nodeTitle : any , notifications: any): number {
    if (nodeTitle === 'My Account') {
      return notifications?.totalCount ?? 0;
    }
    
    if (nodeTitle === 'Approvals') {
      return notifications?.approvalRequestCount ?? 0;
    }

    if (nodeTitle === 'CSOS') {
      return notifications?.csosRequestCount ?? 0;
    }

    return 0;
  }
}
