import { Component, Input } from '@angular/core';
import { OUTLINED_ICON_TYPE } from '@app/models/fff-outline-icons.model';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Translatable } from '@spartacus/core';

@Component({
  selector: 'fff-basic-modal',
  templateUrl: './fff-basic-modal.component.html',
})
export class FffBasicModalComponent {

  @Input() title: string = '';
  @Input() messages: Translatable[] = [];
  @Input() success = undefined;
  @Input() button: string = '';

  warningIcon = OUTLINED_ICON_TYPE.ICON_INFO_CIRCLE;
  successIcon = OUTLINED_ICON_TYPE.ICON_CHECK;
  closeIcon = OUTLINED_ICON_TYPE.ICON_X;
  constructor(
    protected modalService: NgbActiveModal) { }

  close() {
    this.modalService.close();
  }
}
