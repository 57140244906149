import { ChangeDetectorRef, Component, Input } from '@angular/core';
import { FffInvoicePaymentService } from '@app/fff-enterprise/fff-common-services/fff-invoice-payment.service';
import { FffAddCreditCardDialogComponent } from '@app/fff-enterprise/fff-invoice-history/fff-add-credit-card/fff-add-credit-card-dialog/fff-add-credit-card-dialog.component';
import { FffEditCreditCardDialogComponent } from '@app/fff-enterprise/fff-invoice-history/fff-edit-credit-card/fff-edit-credit-card-dialog.component';
import { FILLED_ICON_TYPE } from '@app/models/fff-filled-icons.model';
import { InvoiceCreditCard } from '@app/models/fff-invoice.model';
import { OUTLINED_ICON_TYPE } from '@app/models/fff-outline-icons.model';
import { ToastService } from '@app/shared/services/toast.service';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { clone } from 'lodash';
import { Subscription } from 'rxjs';
import { FffDeleteCardSuccessMessageComponent } from './fff-delete-card-success-message/fff-delete-card-success-message.component';
import { FffDeleteSavedCardConfirmationPopupComponent } from './fff-delete-saved-card-confirmation-popup/fff-delete-saved-card-confirmation-popup.component';

@Component({
  selector: 'fff-profile-wallet',
  templateUrl: './fff-profile-wallet.component.html',
})
export class FffProfileWalletComponent {
  @Input() isASMUser!: boolean;
  outlineIcons = OUTLINED_ICON_TYPE;
  cardIconMapping: any = {
    VISA: 'visa-card',
    MC: 'master-card',
    AMEX: 'amex-card',
    DISCOVER: 'discover-card',
  };
  showCardActions: { [key: number]: boolean } = {};
  cardActionStyles: { [key: number]: any } = {};
  cardContentStyles: { [key: number]: any } = {};
  deleteHovered!: boolean;
  editHovered!: boolean;
  filledIconTypes = FILLED_ICON_TYPE;
  loading: boolean = false;
  savedCards: InvoiceCreditCard[] = [];
  selectedCreditCard$ = this.fffInvoicePaymentService.getSelectedCreditCard();
  fffSuccessMsg: NgbModalRef | null = null;
  subs = new Subscription();
  isCardsLoaded!: boolean;

  constructor(
    private fffInvoicePaymentService: FffInvoicePaymentService,
    private cd: ChangeDetectorRef,
    private modalService: NgbModal,
    private toastService: ToastService
  ) {}

  ngOnInit(): void {
    this.loadSavedCards();
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  toggleLoading(loading: boolean) {
    this.loading = loading;
    this.cd.markForCheck();
  }

  loadSavedCards() {
    this.toggleLoading(true);
    this.isCardsLoaded = true;
    this.subs.add(
      this.fffInvoicePaymentService.getSavedCards().subscribe(
        cards => {
          this.savedCards = cards || [];
          if (this.isCardsLoaded) {
            this.toggleLoading(false);
          }
        },
        () => {
          this.toggleLoading(false);
        }
      )
    );
  }
  showCardActionContent(index: any) {
    this.showCardActions[index] = true;
    this.cardActionStyles[index] = {
      opacity: '0.6', // Adjust the opacity as needed
    };
    this.cardContentStyles[index] = {
      opacity: '0.4', // Adjust the opacity as needed
    };
    this.cd.detectChanges();
  }

  hideCardActionContent(index: any) {
    this.showCardActions[index] = false;
    this.cardActionStyles[index] = {};
    this.cardContentStyles[index] = {
      opacity: '1', // Adjust the opacity as needed
    };
    this.cd.detectChanges();
  }
  deleteCard(cardToken: any, paymentUUID: any) {
    this.isCardsLoaded = false;
    let modelRef = this.modalService.open(
      FffDeleteSavedCardConfirmationPopupComponent,
      {
        animation: true,
        backdrop: 'static',
        centered: true,
        windowClass: 'fff-modal fff-delete-card-modal',
      }
    );
    modelRef.componentInstance.cardToken = cardToken;
    modelRef.componentInstance.paymentUUID = paymentUUID;
    modelRef.result.then(data => {
      if (data && data.isDeleted) {
        this.toggleLoading(true);
        setTimeout(() => {
          this.fffInvoicePaymentService.loadSavedCards();
          this.isCardsLoaded = true;
        }, 0);
        this.cd.detectChanges();
        this.fffSuccessMsg = this.modalService.open(
          FffDeleteCardSuccessMessageComponent,
          {
            backdrop: false,
            keyboard: false,
            windowClass: 'fff-delete-card-success',
          }
        );
        this.fffSuccessMsg.componentInstance.cardToken = cardToken;
      }
      this.cd.markForCheck();
    });
  }
  isAmexCard(card: InvoiceCreditCard): boolean {
    return card?.cardType?.code == 'AMEX';
  }

  openAddNewCardDialog() {
    this.isCardsLoaded = false;
    const modalRef = this.modalService.open(FffAddCreditCardDialogComponent, {
      centered: false,
      size: 'xl',
      windowClass: 'fff-modal fff-modal-invoice-payment',
      keyboard: false,
      backdrop: 'static',
    });
    modalRef.componentInstance.titleI18nKey =
      'fffAccount.fffProfileManagement.wallet.addCreditCard';
    modalRef.componentInstance.saveCreditCard = true;
    modalRef.componentInstance.isTransactionFlow = false;

    modalRef.closed.subscribe(res => {
      if (res.creditCardAdded) {
        this.toastService.showSuccess(
          'fffAccount.fffProfileManagement.wallet.creditCardAddedConfirmation',
          {
            delay: 5000,
            i18nParams: {
              cardToken: (res.cardToken || '').slice(-4),
            },
          }
        );
        this.modalService.dismissAll();
        this.toggleLoading(true);
        setTimeout(() => {
          this.fffInvoicePaymentService.loadSavedCards();
          this.isCardsLoaded = true;
        }, 0);
      }
    });
  }

  editCard(card: InvoiceCreditCard) {
    this.isCardsLoaded = false;
    const modalRef = this.modalService.open(FffEditCreditCardDialogComponent, {
      centered: false,
      size: 'xl',
      windowClass: 'fff-modal fff-modal-invoice-payment',
      keyboard: false,
      backdrop: 'static',
    });

    modalRef.componentInstance.cardData = clone(card);

    modalRef.closed.subscribe(res => {
      if (res.updateSuccess) {
        this.toggleLoading(true);
        setTimeout(() => {
          this.fffInvoicePaymentService.loadSavedCards();
          this.isCardsLoaded = true;
        }, 0);
      }
    });
  }
  setAsDefault(card: InvoiceCreditCard) {
    this.toggleLoading(true);
    this.isCardsLoaded = false;
    this.fffInvoicePaymentService
      .setDefaultCreditCard(
        {
          cardToken: card?.cardToken,
          defaultPayment: true,
        },
        card.paymentUUID
      )
      .subscribe(
        () => {
          this.isCardsLoaded = true;
        },
        () => {
          this.isCardsLoaded = true;
        }
      );
  }
  isDateExpired(month: any, year: any): boolean {
    const monthNumber: number = parseInt(month, 10);
    const currentYearLastTwoDigits: number = new Date().getFullYear() % 100;
    const currentMonth: number = new Date().getMonth() + 1;
    const yearNumber: number = parseInt(year, 10);

    if (
      yearNumber < currentYearLastTwoDigits ||
      (yearNumber === currentYearLastTwoDigits && monthNumber < currentMonth)
    ) {
      return true;
    } else {
      return false;
    }
  }
}
