import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { DisableDivDirective } from './disable-div-directive.directive';

@NgModule({
  declarations: [DisableDivDirective],
  imports: [CommonModule],
  exports: [DisableDivDirective],
})
export class FffSharedDirectivesModule {}
