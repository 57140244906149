<ng-container *ngIf="notifications$ | async as notifications"> 

  <div class="my-account align-items-center">
    <div class="account-btn d-flex body-text-demi-xl align-items-center">
      {{ 'fffAccount.myAccount' | cxTranslate }}
    </div>
  </div>
  
  <nav aria-label="navAriaLabel">
    <ul>
      <ng-container *ngFor="let child of node?.children">
        <ng-container *ngTemplateOutlet="nav; context: { node: child, depth: 0 }">
        </ng-container>
      </ng-container>
    </ul>
  </nav>
  
  <ng-template #nav let-node="node">
    <cx-generic-link *ngIf="node.url" [url]="node.url" class="body-text-xl" (click)="closeDrawer()">
      <div class="my-account-nav-bar body-text-lg d-flex" >
        {{ node.title }}
  
        <div *ngIf="fetchMedals(node.title , notifications) as fetchedMedal" class="badge text-center">
          {{ fetchedMedal }}
        </div>
  
      </div>
    </cx-generic-link>
  </ng-template>
  
</ng-container>
