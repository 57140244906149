<div
  class="row ml-0 mr-0 cart-item-container"
  [ngClass]="{ warning: existWarning }"
  *ngIf="profile"
>
  <!-- Expired License Warning -->
  <div class="col-md-12 col-sm-12 license-warning" *ngIf="existWarning">
    <div class="d-flex">
      <div class="d-flex">
        <cx-icon
          class="cx-icon icon-lg icon-warning"
          [type]="outlinedIconTypes.ICON_EXCLAMATION_TRIANGLE"
        ></cx-icon>
      </div>
      <div class="w-100 pl-4 pr-3">
        <p class="body-text-xl mb-0 d-inline">
          {{ 'orderCost.expiredLicense' | cxTranslate }}
        </p>
        &nbsp;
        <a class="body-text-xl mb-0 d-inline" href="">{{
          'orderCost.uploadNow' | cxTranslate
        }}</a>
      </div>
      <div class="mr-3" (click)="existWarning = false">
        <cx-icon class="cx-icon" [type]="outlinedIconTypes.ICON_X"></cx-icon>
      </div>
    </div>
  </div>

  <div
    class="col-md-6 col-sm-12"
    [ngClass]="{ deleted: deleted, unavailableItem: isUnavailableItem }"
  >
    <fff-entry-simple
      [item]="itemDetails"
      [b2BUnitSplitBiller]="
        profile.selected ? profile.selected.splitBiller : false
      "
      [customerSplitBiller]="
        profile.selected ? profile.selected.autoSplitBillerActive : false
      "
      [accountUid]="profile.selected ? profile.selected.uid : ''"
      [isUnavailableItem]="isUnavailableItem"
    ></fff-entry-simple>
  </div>
  <div
    *ngIf="!isUnavailableItem"
    class="col-md-6 col-sm-12"
    [ngClass]="{ deleted: deleted }"
  >
    <ng-container *ngIf="stockData$ | async as stockData; else loading">
      <div
        class="d-flex flex-column justify-content-between align-items-center p-2 split-billing"
      >
        <fff-cart-split-billing
          [activeSite]="activeSite"
          [e]="itemDetails"
          [user]="profile.user"
          [allocation]="getStockInfo(stockData)"
          [priceData]="priceData"
        ></fff-cart-split-billing>
        <div
          class="d-flex w-100 justify-content-between px-2 pt-2 pb-0 align-items-center"
        >
          <h5 class="body-text-xl" *ngIf="!getHideStockIndicator(itemDetails)">
            <b
              *ngIf="STOCK_STATUS.IN_STOCK === getStockStatus(itemDetails)"
              class="in-stock"
              >{{ 'fffProduct.lblInStock' | cxTranslate }}</b
            >

            <ng-container *ngIf="STOCK_STATUS.IN_TRANSIT === getStockStatus(itemDetails)">
              <b  class="in-transit">{{'fffProduct.lblInTransit' | cxTranslate }}</b>
              <p><small>{{'fffProduct.lblOutOfStockNote' | cxTranslate }}</small></p>
           </ng-container>
            <b
              *ngIf="STOCK_STATUS.ON_DEMAND === getStockStatus(itemDetails)"
              class="on-demand"
              >{{ 'fffProduct.lblOnDemand' | cxTranslate }}</b
            >
            <b
              *ngIf="STOCK_STATUS.DROP_SHIP === getStockStatus(itemDetails)"
              class="in-stock"
              >{{ 'fffProduct.lblDropShip' | cxTranslate }}</b
            >
          </h5>
          <a
            class="body-text-sm remove-from-cart mr-3 ml-auto"
            (click)="remove(itemDetails)"
          >
            {{ 'fffQuickOrder.remove' | cxTranslate }}
          </a>
        </div>
        <div class="align-self-baseline pl-2">
          <ng-container
            *ngIf="
              itemDetails?.unitConversionFactor &&
              itemDetails?.altUom &&
              !isOfVaccineTypeI(itemDetails)
            "
          >
            <div class="volume body-text-sm d-inline-block">
              1 {{ itemDetails?.uom }} = {{ itemDetails?.unitConversionFactor }}
              {{ itemDetails?.altUom }}
            </div>
          </ng-container>
          <ng-container *ngIf="itemDetails?.altUnitConversion">
            <div class="volume body-text-sm d-inline-block">
              <span
                *ngIf="
                  itemDetails?.unitConversionFactor &&
                  itemDetails?.altUom &&
                  !isOfVaccineTypeI(itemDetails)
                "
              >
                ,&nbsp;
              </span>
              {{ itemDetails?.altUnitConversion }}
            </div>
          </ng-container>

          <ng-container *ngIf="isOfVaccineTypeI(itemDetails)">
            <div class="body-text-xs mt-1 text-lowercase">
              1 {{ itemDetails.uom }} = 10 DS
            </div>
          </ng-container>
        </div>
      </div>
    </ng-container>
  </div>
</div>

<ng-template #loading>
  <fff-cart-loader [isCartItem]="true"></fff-cart-loader>
</ng-template>
