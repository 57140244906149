import { Component, OnInit } from '@angular/core';
import { WindowRef } from '@spartacus/core';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'fff-cookie-consent',
  template: '',
})
/**
 * Creates a cookie concent component for adding custom changes
 */
export class FffCookieConsentComponent implements OnInit {
  private regCheckPerformance = /,C0002,/;
  private regCheckFunctional = /,C0003,/;
  private regCheckTargeting = /,C0004,/;
  private regCheckSocial = /,C0005,/;

  /**
   * Takes window service as param
   */
  constructor(private windowRef: WindowRef) { }

  /**
   * initialize the component
   */
  ngOnInit(): void {
    this.loadJsFile();
    (<any>this.windowRef)['OptanonWrapper'] = this.OptanonWrapper;
  }

  /**
   * Load the third party JS file
   */
  private loadJsFile() {
    try {
      const node = this.windowRef.document.createElement('script');
      node.async = true;
      node.src = environment.cookieConsentUrl;
      node.type = 'text/javascript';
      node.setAttribute('charset', 'UTF-8');
      node.setAttribute('data-domain-script', environment.cookieConsentDataDomainScript);
      this.windowRef.document.head.appendChild(node);
    } catch (err) {
      console.error('Cookie consent intialization failed with this error message : ');
      console.error(err);
    }
  }

  /**
   * load IFrames
   */
  public OptanonWrapper() {
    const OptanonActiveGroups = (<any>this.windowRef)['OptanonActiveGroups'];

    if (OptanonActiveGroups.match(this.regCheckTargeting))
      loadIframe(this.windowRef.document.getElementsByClassName('optanon-category-C0004'));
    if (OptanonActiveGroups.match(this.regCheckFunctional))
      loadIframe(this.windowRef.document.getElementsByClassName('optanon-category-C0003'));
    if (OptanonActiveGroups.match(this.regCheckPerformance))
      loadIframe(this.windowRef.document.getElementsByClassName('optanon-category-C0002'));
    if (OptanonActiveGroups.match(this.regCheckSocial))
      loadIframe(this.windowRef.document.getElementsByClassName('optanon-category-C0005'));

    /**
     * will load the iframes
     * @param {elements} elements is list of html elements
     */
    function loadIframe(elements: any) {
      for (let i = 0; i < elements.length; i++) {
        elements[i].tagName === 'IFRAME' ?
          elements[i].setAttribute('src', elements[i].dataset.src) :
          -1;
      }
    }
  }
}
