import { Injectable } from '@angular/core';
import {
  AuthConfigService, GlobalMessageService, OAuthFlow, RoutingService
} from '@spartacus/core';
import { ForgotPasswordComponentService } from '@spartacus/user/profile/components';
import { UserPasswordFacade } from '@spartacus/user/profile/root';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class FffForgotPasswordService extends ForgotPasswordComponentService {

  email = new BehaviorSubject('');

  constructor(
    protected userPasswordService: UserPasswordFacade,
    protected routingService: RoutingService,
    protected authConfigService: AuthConfigService,
    protected globalMessage: GlobalMessageService
  ) {
    super(userPasswordService, routingService, authConfigService, globalMessage);
  }

  protected redirect() {
    if (
      this.authConfigService.getOAuthFlow() ===
      OAuthFlow.ResourceOwnerPasswordFlow
    ) {
      this.routingService.go('/forgot-password-confirmation');
    }
  }
}
