import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

declare const gtag: Function;

@Injectable({
  providedIn: 'root',
})
/**
 * Creates Google Analytics Service
 */
export class FffGoogleAnalyticsService {
  /**
   * @param {router} router is imported from '@angular/router'
   */
  constructor(private router: Router) { }

  /**
   * Logs the entry for every event to be registered in google analytics.
   * @param {eventName} eventName is name of the event to be register to google analytics
   * @param {params} params to be passed
   */
  public logEntry(eventName: string, params: {}) {
    gtag('event', eventName, params);
  }

  /**
   * Initialize google analytics js.
   */
  public init(data: any): void {
    this.listenForRouteChanges(data['storefront.startup.googleAnalyticsKey']);
    try {
      const loadGoogleTagManager = document.createElement('script');
      loadGoogleTagManager.async = true;
      loadGoogleTagManager.src = data['storefront.startup.googleAnalyticsUrl'] + data['storefront.startup.googleAnalyticsKey'];
      document.head.appendChild(loadGoogleTagManager);
      const initiateTagManager = document.createElement('script');
      initiateTagManager.innerHTML =
        `window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());
        gtag('config', '` +
        data['storefront.startup.googleAnalyticsKey'] +
        `', {'send_page_view': false});`;
      document.head.appendChild(initiateTagManager);
    } catch (err) {
      console.error('Google analytics intialization failed with this error message : ');
      console.error(err);
    }
  }

  /**
   * Listen to the route changes from angular routes.
   */
  private listenForRouteChanges(key: string) {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        gtag('config', key, {
          page_path: event.urlAfterRedirects,
        });
      }
    });
  }
}
