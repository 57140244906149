import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnInit,
  Renderer2,
} from '@angular/core';
import { Router } from '@angular/router';
import { FffCommunicationService } from '@app/fff-enterprise/fff-common-services/fff-communication.service';
import { FFFCustomValidators } from '@app/shared/validators/fff-custom-validators';
import { CmsService, WindowRef } from '@spartacus/core';
import {
  HamburgerMenuService,
  NavigationService,
  NavigationUIComponent,
} from '@spartacus/storefront';
import { Subject } from 'rxjs';
@Component({
  selector: 'fff-my-account',
  templateUrl: './fff-my-account.component.html',
})
export class FffMyAccountComponent
  extends NavigationUIComponent
  implements OnInit
{
  @Input() profile: any;

  constructor(
    router: Router,
    renderer: Renderer2,
    elemRef: ElementRef,
    hamburgerMenuService: HamburgerMenuService,
    winRef: WindowRef,
    public navigationService: NavigationService,
    public cmsService: CmsService,
    public cdRef: ChangeDetectorRef,
    public communicationService: FffCommunicationService
  ) {
    super(router, renderer, elemRef, hamburgerMenuService, winRef);
  }

  notifications$ = this.communicationService.getNotifications();
  notifications: any;
  unsubscribe$: Subject<void> = new Subject<void>();

  ngOnInit() {
    console.log(this.node);
  }

  fetchMedals(nodeTitle: any, notifications: any): number {
    if (nodeTitle === 'My Account') {
      return notifications?.totalCount ?? 0;
    }

    if (nodeTitle === 'Approvals') {
      return notifications?.approvalRequestCount ?? 0;
    }

    if (nodeTitle === 'CSOS') {
      return notifications?.csosRequestCount ?? 0;
    }

    return 0;
  }

  filterCategory(category: string) {
    if (!this.node?.children) {
      return;
    }

    this.node.children[0].children = this.node.children[0].children?.filter(
      e => e.title !== category
    );
  }

  filterNodes() {
    if (this.profile?.user) {
      let isApprover = this.profile?.user?.roles?.includes('b2bapprovergroup');
      if (this.profile?.selected?.uid) {
        this.communicationService
          .getAppProperties()
          .subscribe(applicationProperties => {
            if (applicationProperties?.ecommerceReportingCustomersEnabled) {
              const reportEnabledAccounts =
                applicationProperties?.ecommerceReportingCustomersEnabled;
              if (
                reportEnabledAccounts !== 'ALL' &&
                !reportEnabledAccounts.includes(this.profile.selected.uid)
              ) {
                this.filterCategory('New Reports');
              }
            }
          });
      }

      if (!isApprover) {
        this.filterCategory('Approvals');
      }

      if (
        !FFFCustomValidators.validateLicense(this.profile?.selected) &&
        !this.profile?.selected?.deaLicense &&
        new Date(this.profile?.selected?.deaExpiryDate ?? null) < new Date()
      ) {
        this.filterCategory('CSOS');
      }
    }

    return this.node;
  }
}
