import { Component, Input, OnInit } from '@angular/core';
import { getProductImage } from '@assets/utils/fff-product-utils';
import { Product } from '@spartacus/core';
import { MediaContainer } from '@spartacus/storefront';

@Component({
  selector: 'fff-returns-entry',
  templateUrl: './fff-returns-entry.component.html',
})
export class FffReturnsEntryComponent implements OnInit {

  @Input() items: any[] = [];
  @Input() account: string = '';
  @Input() displayConfirmationEntryQty: boolean = false;
  @Input() displayQuantitySection: boolean = true;

  constructor() { }

  ngOnInit(): void { }

  getData(product: Product) {
    return getProductImage(product.images) as MediaContainer;
  }

}
