<div *ngIf="loading$ | async; else quickOrderForm">
  <fff-content-loader
    containerClassName="height-large"
    bodyClassName="d-flex price-row pt-2 pb-2 pl-3 pr-3"
  >
  </fff-content-loader>
</div>
<ng-template #quickOrderForm>
  <form
    *ngIf="form"
    [formGroup]="form"
    (submit)="uploadFile()"
    class="form-load-files"
  >
    <div class="quick-order-container">
      <div class="quick-order-main">
        <p class="cx-import-entries-subtitle">
          {{ 'fffQuickOrder.importEntriesSubtitle' | cxTranslate }}
        </p>

        <div
          class="upload-container"
          [ngClass]="{ 'disabled-section': (loading$ | async) }"
        >
          <fff-file-upload
            class="quick-order w-100"
            [formControl]="$any(form.get('file'))"
            [accept]="allowedExtensionTypes"
            [multiple]="false"
            [clean]="clean$ | async"
            (update)="fileChanged($event)"
          >
            <div
              class="input-search d-flex w-100 align-items-center form-control-md"
            >
              <cx-icon
                class="cx-icon icon-sm"
                [type]="iconTypes.SEARCH"
              ></cx-icon>
              <p class="body-text-sm">
                {{ 'fffQuickOrder.chooseFile' | cxTranslate }}
              </p>
            </div>
          </fff-file-upload>

          <div class="button-container upload-desktop">
            <button
              class="btn btn-primary btn-upload"
              type="submit"
              [disabled]="!(processed$ | async)"
            >
              {{ 'importEntriesDialog.upload' | cxTranslate }}
            </button>
          </div>
        </div>

        <div class="button-container upload-mobile">
          <button
            class="btn btn-primary btn-upload"
            type="submit"
            [disabled]="!(processed$ | async)"
          >
            {{ 'importEntriesDialog.upload' | cxTranslate }}
          </button>
        </div>

        <cx-form-errors
          [control]="$any(form.get('file'))"
          prefix="formErrors.quickOrderFile"
        >
        </cx-form-errors>

        <p class="mb-0 mt-4 body-text-sm">
          {{ 'fffQuickOrder.needHelp' | cxTranslate }}
        </p>
        <a
          class="mb-0 body-text-sm d-flex download-template"
          [href]="downloadLink"
        >
          <cx-icon
            class="cx-icon icon-sm"
            [type]="outlineTypes.ICON_DOWNLOAD"
          ></cx-icon>
          &nbsp;{{ 'fffQuickOrder.downloadTemplate' | cxTranslate }}
        </a>
      </div>
    </div>
  </form>
</ng-template>
