import { Injectable } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { getCategoryCodeByName } from '@app/fff-config/category/fff-category.utils';
import { BASE_URL_KEYS } from '@app/fff-config/content/constants';
import { VACCINES_CATEGORY_TYPES } from '@app/models/fff-product.model';
import { Actions } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import {
  ActivatedRouterStateSnapshot,
  BaseSiteService,
  CurrencyService,
  LanguageService,
  OCC_USER_ID_CURRENT,
  ProductSearchPage,
  ProductSearchService,
  RouterState,
  RoutingService,
  SearchConfig,
  UserIdService,
} from '@spartacus/core';
import { ViewConfig } from '@spartacus/storefront';
import {
  BehaviorSubject,
  Observable,
  Subscription,
  combineLatest,
  using,
} from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
  pluck,
  shareReplay,
  take,
  tap,
} from 'rxjs/operators';
import { isValidPrebookCategory } from '../fff-prebook-category/services/fff-prebook-category.utils';

interface ProductListRouteParams {
  brandCode?: string;
  categoryCode?: string;
  query?: string;
}

interface PrebookSearchQueryConfig {
  sort: string;
  keys: string[];
  values: string[];
}

export interface FffSearchConfig {
  fields?: string;
}

declare module '@spartacus/core' {
  interface SearchConfig extends FffSearchConfig {}
}

interface SearchCriteria {
  currentPage?: number;
  pageSize?: number;
  sortCode?: string;
  query?: string;
  category?: string;
  prebook?: string;
}

export const REFURBISHED_FACET_CODE = 'refurbished';

@Injectable({ providedIn: 'root' })
export class FffProductListComponentService extends ProductSearchService {
  hideCategoryFacet$ = new BehaviorSubject(false);
  protected defaultPageSize = 10;
  protected sub: Subscription = new Subscription();
  protected readonly RELEVANCE_CATEGORY = ':newest:allCategories:';
  protected readonly RELEVANCE_BRAND = ':relevance:brand:';
  protected readonly RELEVANCE_REFURBISHED = `:relevance:${REFURBISHED_FACET_CODE}:true:`;
  userId!: string;
  isFilterOpen$ = new BehaviorSubject(false);
  isSortOpen$ = new BehaviorSubject(false);
  baseSite: string = '';

  readonly model$: Observable<ProductSearchPage> = using(
    () => this.searchByRouting$.subscribe(),
    () => this.searchResults$
  ).pipe(shareReplay({ bufferSize: 1, refCount: true }));
  readonly PREBOOK_FLU_SEASON = 2025;
  readonly NON_PREBOOK_FLU_SEASON = 2024;

  constructor(
    protected routing: RoutingService,
    protected activatedRoute: ActivatedRoute,
    protected currencyService: CurrencyService,
    protected languageService: LanguageService,
    protected router: Router,
    protected viewConfig: ViewConfig,
    protected store: Store<any>,
    protected userIdService: UserIdService,
    private baseSiteService: BaseSiteService,
    private _actions$: Actions
  ) {
    super(store);

    this.defaultPageSize =
      viewConfig?.view?.infiniteScroll?.productLimit || this.defaultPageSize;

    this.baseSiteService
      .getActive()
      .pipe(take(1))
      .subscribe(site => (this.baseSite = site));
  }

  protected searchByRouting$: Observable<ActivatedRouterStateSnapshot> =
    combineLatest([
      this.routing.getRouterState().pipe(
        distinctUntilChanged((x, y) => {
          return x.state.url === y.state.url;
        })
      ),
      ...this.siteContext,
    ]).pipe(
      debounceTime(0),
      map(([routerState, ..._context]) => (routerState as RouterState).state),
      tap((state: ActivatedRouterStateSnapshot) => {
        const criteria = this.getCriteriaFromRoute(
          state.params,
          state.queryParams
        );
        this.userIdService.getUserId().subscribe(user => {
          this.userId = user;

          // Set custom query for prebook category pages
          if (state.url.startsWith('/categories')) {
            let categoryName = state.queryParams['category'];
            let categoryCode = getCategoryCodeByName(categoryName);
            const isPrebookCategory = isValidPrebookCategory(categoryName);
            const prebook =
              state.queryParams['prebook'] == true ||
              state.queryParams['prebook'] == 'true';
            const isMfv = this.baseSite === BASE_URL_KEYS.MY_FLU_VACCINE;

            if (categoryCode && !criteria.query?.includes(`:${categoryCode}`)) {
              criteria.query = `:newest:allCategories:${categoryCode}:`;

              if (
                isMfv &&
                isPrebookCategory &&
                categoryName != VACCINES_CATEGORY_TYPES.RSV
              ) {
                criteria.query += `fluSeason:${
                  prebook
                    ? this.PREBOOK_FLU_SEASON
                    : this.NON_PREBOOK_FLU_SEASON
                }`;
              }
            }
          }

          this.search(
            criteria?.query || '',
            this.getSearchConfig(criteria, user)
          );
        });
      })
    );

  private get siteContext(): Observable<string>[] {
    return [this.languageService.getActive(), this.currencyService.getActive()];
  }

  private searchResults$: Observable<ProductSearchPage> =
    this.getResults().pipe(
      filter(searchResult => Object.keys(searchResult).length > 0)
    );

  // Here should be used interface RouterState FROM Spartacus instead of Angular
  searchByRoutingGuard$: Observable<[RouterState, string, string]> =
    this.searchByRoutingGuard();

  /**
   * This stream should be used only on the Product Listing Page.
   *
   * It not only emits search results, but also performs a search on every change
   * of the route (i.e. route params or query params).
   *
   * When a user leaves the PLP route, the PLP component unsubscribes from this stream
   * so no longer the search is performed on route change.
   */
  //readonly model$: Observable<ProductSearchPage> = this.modelSearchPage(this.searchByRouting$);
  readonly modelWithGuard$: Observable<ProductSearchPage> =
    this.modelSearchPage(this.searchByRoutingGuard$);

  getCriteriaFromRoute(
    routeParams: ProductListRouteParams,
    queryParams: SearchCriteria
  ): SearchCriteria {
    return {
      query: queryParams.query
        ? queryParams.query.replace(/\+/g, ' ')
        : this.getQueryFromRouteParams(routeParams),
      pageSize: (queryParams.prebook || '').toString().toLowerCase() === 'true' ? 12 : this.defaultPageSize,
      currentPage: queryParams.currentPage,
      sortCode: queryParams.sortCode,
    };
  }

  toggleFilterDisplayInMobile(value: boolean) {
    this.isFilterOpen$.next(value);
  }

  toggleSortDisplayInMobile(value: boolean) {
    this.isSortOpen$.next(value);
  }

  modelSearchPage(searchType: any): Observable<ProductSearchPage> {
    return combineLatest([this.searchResults$, searchType]).pipe(
      pluck(0),
      shareReplay({ bufferSize: 1, refCount: true })
    );
  }

  private searchByRoutingGuard(): Observable<[RouterState, string, string]> {
    return combineLatest([
      this.routing.getRouterState().pipe(
        distinctUntilChanged((x, y) => {
          // router emits new value also when the anticipated `nextState` changes
          // but we want to perform search only when current url changes
          return x.state.url === y.state.url;
        })
      ),
      // also trigger search on site context changes
      this.languageService.getActive(),
      this.currencyService.getActive(),
    ]);
  }

  private getQueryFromRouteParams({
    brandCode,
    categoryCode,
    query,
  }: ProductListRouteParams) {
    if (query) {
      return query;
    }
    if (categoryCode) {
      return this.RELEVANCE_CATEGORY + categoryCode;
    }
    if (brandCode) {
      return this.RELEVANCE_BRAND + brandCode;
    }
    // return this.RELEVANCE; // return relevance without refurbished selected
    return this.RELEVANCE_REFURBISHED;
  }

  private getSearchConfig(
    criteria: SearchCriteria,
    userInfo: string
  ): SearchConfig {
    const result: SearchConfig = {
      currentPage: criteria.currentPage ? criteria.currentPage : 0,
      pageSize: criteria.pageSize ? criteria.pageSize : 0,
      fields: userInfo === OCC_USER_ID_CURRENT ? 'DEFAULT' : 'ANONYMOUS',
    };

    if (criteria.sortCode) {
      result.sort = criteria.sortCode;
    }

    // drop empty keys
    // Object.keys(result).forEach(key => !result[key] && delete result[key]);

    return result;
  }

  setQuery(query: string): void {
    this.setQueryParams({ query, currentPage: undefined });
  }

  viewPage(pageNumber: number): void {
    this.setQueryParams({ currentPage: pageNumber });
  }

  /**
   * Get items from a given page without using navigation
   */
  getPageItems(pageNumber: number): void {
    this.routing
      .getRouterState()
      .subscribe(route => {
        let routeParams : ProductListRouteParams = {categoryCode:''}
        if(route.state.params && !route.state.params.categoryCode && route.state.queryParams.category){
          let categoryName = route.state.queryParams.category;
          let categoryCode = getCategoryCodeByName(categoryName);
          if(categoryCode){
            routeParams.categoryCode = categoryCode
          }
        }
        const selectedParams = routeParams.categoryCode ? routeParams : route.state.params;
        const routeCriteria = this.getCriteriaFromRoute(
          selectedParams,
          route.state.queryParams
        );
        const criteria = {
          ...routeCriteria,
          currentPage: pageNumber,
        };
        this.search(
          criteria?.query || '',
          this.getSearchConfig(criteria, this.userId)
        );
      })
      .unsubscribe();
  }

  sort(sortCode: string): void {
    this.setQueryParams({ sortCode });
  }

  private setQueryParams(queryParams: SearchCriteria): void {
    this.router.navigate([], {
      queryParams,
      queryParamsHandling: 'merge',
      relativeTo: this.activatedRoute,
    });
  }

  getPrebookCategoryQuery(
    categoryName: string,
    queryParams: Params = {}
  ): string {
    let categoryCode = getCategoryCodeByName(categoryName);
    let query = `:relevance:category:${categoryCode}:`;
    const isPrebookCategory = isValidPrebookCategory(categoryName);
    const prebook =
      queryParams['prebook'] == true || queryParams['prebook'] == 'true';

    if (
      this.baseSite === BASE_URL_KEYS.MY_FLU_VACCINE &&
      isPrebookCategory &&
      categoryName != VACCINES_CATEGORY_TYPES.RSV
    ) {
      query += `fluSeason:${
        prebook ? this.PREBOOK_FLU_SEASON : this.NON_PREBOOK_FLU_SEASON
      }`;
    }

    return query;
  }
}
