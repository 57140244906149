import { HttpClient, HttpHeaders } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { OccEndpointsService } from '@spartacus/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class FffOrderService {
  constructor(
    private http: HttpClient,
    protected occEndpoints: OccEndpointsService
  ) {}

  messageSource = new BehaviorSubject<Object>({});

  confirmationsSent = new EventEmitter();

  sendFilterMessage(message: any) {
    this.messageSource.next(message);
  }

  getRequestHeaders(): HttpHeaders {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    return headers;
  }

  getOrderDetails(orderId: string): Observable<any> {
    const url = this.occEndpoints.buildUrl(
      `/users/current/orders/${orderId}?fields=DEFAULT`
    );
    return this.http.get(url, { headers: this.getRequestHeaders() });
  }
  getRecurringOrderDetails(cartCode: string): Observable<any> {
    const url = this.occEndpoints.buildUrl(
      `/replenishmentOrders/${cartCode}?fields=DEFAULT`
    );
    return this.http.get(url, { headers: this.getRequestHeaders() });
  }
  resendEmail(orderId: string, emails?: string): Observable<any> {
    const url = this.occEndpoints.buildUrl(
      `/${orderId}/resendConfirmation?code=${orderId}&emailIds=${emails}`
    );
    return this.http.get(url, { headers: this.getRequestHeaders() });
  }

  getOrderHistoryFiltered(
    filter: any,
    page: number,
    size: number,
    userId: string,
    account: string,
    orderType?: string
  ): Observable<any> {
    let baseUrl = `/users/${userId}/orders?account=${account}&currentPage=${page}&fields=DEFAULT&pageSize=${size}`;
    if (orderType) {
      baseUrl += '&orderType=' + orderType;
    }
    let filters = '';

    Object.keys(filter).forEach((key, index) => {
      if (filter[key]) {
        filters = filters + '&' + key + '=' + filter[key];
      }
    });

    const url = this.occEndpoints.buildUrl(baseUrl + filters);
    return this.http.get(url, { headers: this.getRequestHeaders() });
  }
  getRecurringOrdersHistory(
    userId: string,
    pageSize: number,
    currentPage: number,
    filter: any
  ) {
    let baseUrl =
      '/users/' +
      userId +
      '/replenishmentOrders?currentPage=' +
      currentPage +
      '&fields=DEFAULT&pageSize=' +
      pageSize;
    let filters = '';

    Object.keys(filter).forEach(key => {
      if (filter[key]) {
        filters = filters + '&' + key + '=' + filter[key];
      }
    });

    const url = this.occEndpoints.buildUrl(baseUrl + filters);
    return this.http.get(url, { headers: this.getRequestHeaders() });
  }

  skipRecurringOrder(cartCode: string) {
    const url = this.occEndpoints.buildUrl(
      `/replenishmentOrders/skip?cartId=${cartCode}`
    );
    return this.http.post(url, { headers: this.getRequestHeaders() });
  }

  updateRecurringOrder(cartCode: string, actionCode: string) {
    const data = { recurringOrderStatus: actionCode };
    const url = this.occEndpoints.buildUrl(`/replenishmentOrders/${cartCode}`);
    return this.http.patch(url, data, { headers: this.getRequestHeaders() });
  }

  placeOrder(
    cartId: string,
    termsChecked: boolean,
    data: any,
    shippingWindowsData: any,
    contactMethod?: string
  ): Observable<any> {
    const url = this.occEndpoints.buildUrl(
      `/orders?adConfirmationEmail=${data.additionalEmailConf}&cartId=${cartId}&fields=DEFAULT&purchaseOrderNumber=${data.poNumber}&referenceName=${data.referenceName}&termsChecked=${termsChecked}` +
        (contactMethod ? '&contactMethodCode=' + contactMethod : '')
    );
    return this.http.post(url, shippingWindowsData, {
      headers: this.getRequestHeaders(),
    });
  }

  createReplenishmentOrder(
    cartId: string,
    termsChecked: boolean,
    purchaseOrderNumber: string,
    data: any
  ): Observable<any> {
    const url = this.occEndpoints.buildUrl(
      `/replenishmentOrders?cartId=${cartId}&fields=DEFAULT&termsChecked=${termsChecked}&purchaseOrderNumber=${purchaseOrderNumber}`
    );
    return this.http.post(url, data, { headers: this.getRequestHeaders() });
  }

  getCsosOrderId(userId: string, orderId: string): Observable<any> {
    const url = this.occEndpoints.buildUrl(
      `/users/${userId}/orders/${orderId}/signCsosOrder`
    );
    return this.http.get(url, { headers: this.getRequestHeaders() });
  }

  updateCsosOrder(userId: string, orderId: string): Observable<any> {
    const url = this.occEndpoints.buildUrl(
      `/users/${userId}/orders/${orderId}/updateCsosOrderSignStatus`
    );
    return this.http.get(url, { headers: this.getRequestHeaders() });
  }

  getAllOrdersForCSV() {
    const url = this.occEndpoints.buildUrl(
      `/users/current/orders/csv-download`
    );
    return this.http.get(url, { headers: this.getRequestHeaders() });
  }
  getContactMethods(): Observable<any> {
    const url = this.occEndpoints.buildUrl('/orgUsers/current/contactMethods');
    return this.http.get(url, { headers: this.getRequestHeaders() });
  }
}
