import { DecimalPipe } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FILLED_ICON_TYPE } from '@app/models/fff-filled-icons.model';
import { FffUserAccountService } from '@app/shared/services/fff-user-account.service';
import { FffExportedDocumentMessageComponent } from '@enterprise/fff-user-price-list/fff-exported-document-message/fff-exported-document-message';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { TranslationService } from '@spartacus/core';
import { ExportCsvFileService, ExportFileOptions } from '@spartacus/storefront';
import { jsPDF } from 'jspdf';
import autoTable from 'jspdf-autotable';
import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { OUTLINED_ICON_TYPE } from 'src/app/models/fff-outline-icons.model';
import {
  B2BUnitState,
  FILTER_PRICELIST_BY_CATEGORY_APPLIED,
  FilterState,
} from 'src/app/reducers';
import { FffDrawerService } from 'src/app/shared/drawer/fff-drawer.service';
import { FffCommunicationService } from '../fff-common-services/fff-communication.service';
import { ApplicationProperties } from '../fff-util/fff-models/application-properties.model';
import { FffUserPriceListDrawerComponent } from './fff-user-price-list-drawer.component';
import { FffUserPriceListService } from './fff-user-price-list.service';

@Component({
  selector: 'fff-user-price-list',
  templateUrl: './fff-user-price-list.component.html',
})
export class FffUserPriceListComponent implements OnInit, OnDestroy {
  b2bunit: B2BUnitState['B2BUnit'] = {} as B2BUnitState['B2BUnit'];
  selected: any = null;
  userPriceList: any = { data: [], total: 0, loading: false, current: 0 };
  columnsGrid: any = {
    desktop: '1.4fr 2fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr',
    mobile: '1fr 1fr',
  };
  outlinedIconTypes = OUTLINED_ICON_TYPE;

  priceListData$: BehaviorSubject<any> = new BehaviorSubject<any>(undefined);
  pageSize: number = 10;
  loading$ = this.fffUserPriceListService.isLoading();
  private unsubscribe$: Subject<void> = new Subject<void>();
  FILLED_ICON_TYPE = FILLED_ICON_TYPE;
  isFourDecimalAccount!: boolean;

  fields: any[] = [
    { title: 'fffPriceList.productType', field: 'category' },
    {
      title: 'fffPriceList.productAndManufacturer',
      field: 'name,manufacturer',
    },
    { title: 'fffPriceList.skuAndNdc', field: 'productCode,ndc' },
    { title: 'fffPriceList.productSize', field: 'size' },
    { title: 'fffPriceList.accountNumber', field: 'accountNumber' },
    {
      title: 'fffPriceList.price',
      field: 'priceColumn,altPriceColumn,basicDiscountPrice',
    },
  ];
  csvFields: any[] = [
    { title: 'fffPriceList.productType', field: 'category' },
    { title: 'fffPriceList.productName', field: 'name' },
    { title: 'fffPriceList.productManufacturer', field: 'manufacturer' },
    { title: 'fffPriceList.sku', field: 'productCode' },
    { title: 'fffPriceList.ndc', field: 'ndc' },
    { title: 'fffPriceList.productSize', field: 'size' },
    { title: 'fffPriceList.accountNumber', field: 'accountNumber' },
    { title: 'fffPriceList.price', field: 'priceColumn' },
    { title: 'fffPriceList.altPrice', field: 'altPriceColumn' },
    { title: 'fffPriceList.netPrice', field: 'basicDiscountPrice' },
  ];

  constructor(
    private communicationService: FffCommunicationService,
    protected drawerService: FffDrawerService,
    private store: Store<FilterState>,
    private translationService: TranslationService,
    private exportCSVService: ExportCsvFileService,
    private storeB2B: Store<B2BUnitState>,
    private decimalPipe: DecimalPipe,
    private modalService: NgbModal,
    private fffUserAccountService: FffUserAccountService,
    private fffUserPriceListService: FffUserPriceListService
  ) {}

  ngOnInit() {
    this.storeB2B
      .select(state => state.B2BUnit)
      .subscribe(b2bUnitId => {
        if (b2bUnitId && b2bUnitId.currentB2BUnit) {
          this.b2bunit = b2bUnitId;
        }
      });

    this.getApplicationProperties();
    this.getData({ page: 1, size: this.pageSize });
    this.store
      .select((state: any) => state.Filters)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((e: FilterState) => {
        if (e.priceListByCategoryApplied) {
          this.selected = e.priceListByCategoryApplied;

          this.userPriceList.current = 0;
          this.getData({ page: 1, size: this.pageSize });

          this.emitNewPriceListData(this.userPriceList);
        }
      });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
    this.store.dispatch({
      type: FILTER_PRICELIST_BY_CATEGORY_APPLIED,
      payload: undefined,
    });
    this.fffUserPriceListService.toggleFiltersState(false);
    this.fffUserPriceListService.clearAppliedFilters();
  }

  getApplicationProperties() {
    this.communicationService
      .getApplicationProperties()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((properties: ApplicationProperties) => {
        if (properties?.fffPricesFourDecimalAccounts) {
          this.fffUserAccountService
            .getProfile()
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe((profile: any) => {
              this.isFourDecimalAccount =
                properties.fffPricesFourDecimalAccounts.indexOf(
                  profile?.selected?.uid
                ) >= 0;
            });
        }
      });
  }

  getData(page: any) {
    this.fffUserPriceListService.toggleLoadingState(true);
    this.communicationService
      .getUserPriceList(page.page, page.size, this.selected)
      .subscribe(
        (res: any) => {
          this.fffUserPriceListService.toggleLoadingState(false);
          const total = res.results && res.results.length > 0 ? res.total : 0;

          this.userPriceList = {
            data: res.results,
            total: total,
            loading: false,
            current: this.userPriceList.current,
          };
          this.userPriceList.data = this.transformAltPricesArrayIntoProperty(
            this.userPriceList.data
          );
          this.emitNewPriceListData(this.userPriceList);
        },
        (error: any) => {
          this.fffUserPriceListService.toggleLoadingState(false);
          this.userPriceList = {
            data: [],
            total: 0,
            loading: false,
            current: this.userPriceList.current,
          };
          this.emitNewPriceListData(this.userPriceList);
        }
      );
  }

  emitNewPriceListData(data: any): void {
    this.priceListData$.next(data);
  }

  openDrawer() {
    this.translationService
      .translate('fffPriceList.titleDrawer')
      .subscribe(title => {
        this.drawerService.setContent({
          title: title,
          animation: 'SideRTL',
          component: FffUserPriceListDrawerComponent as Component,
          class: 'price-list-drawer',
        });
        this.drawerService.openDrawer();
      });
  }

  async parseExportInfo(fileType: string) {
    let data: any[] = [];

    let appliedFilters = this.fffUserPriceListService.getAppliedFilters();
    let d = await this.communicationService
      .getFullUserPriceList(this.b2bunit.currentB2BUnit.uid, appliedFilters)
      .toPromise();
    //formatting the data as we want it to be exported
    data = this.transformAltPricesArrayIntoProperty(d.results);
    let fileNames: any[] = [];

    //adding a copy because I don't want to modify the original frontend list.
    let fields = fileType == 'pdf' ? [...this.fields] : [...this.csvFields];
    const spliceIndex = fileType == 'pdf' ? 6 : 10;
    fields.splice(spliceIndex, 0, {
      title: 'fffPriceList.contractProduct',
      field: 'contractedMaterial',
    });

    //adding the titles.
    fields.forEach((f: any) => {
      this.translationService.translate(f.title).subscribe(res => {
        fileNames.push(res);
      });
    });

    let exportFileEntries: string[][] = [];
    let fileTitles = fields.map((e: { field: any }) => {
      return e.field;
    });
    exportFileEntries[0] = fileNames;

    data.forEach((e: any, index: any) => {
      //+1 because we already added the titles.
      exportFileEntries[index + 1] = [];

      fileTitles.forEach((key: any, labelIndex: any) => {
        //based on the titles, we add the data.
        const keys = key.split(',').map((key: any) => key.trim());
        if (fileType == 'pdf') {
          let valueToPush = '';
          const separator = '\n';
          if (keys && keys.length > 0) {
            if (labelIndex == 5) {
              const priceLabels: string[] = ['Price', 'Alt Price', 'Net Price'];
              const pricesArray: string[] = [];

              keys.forEach((key: any) => {
                if (key && e.csvFormat[key]) {
                  pricesArray.push(e.csvFormat[key]);
                }
              });
              if (priceLabels.length === keys.length) {
                for (let i = 0; i < priceLabels.length; i++) {
                  const priceSeparator =
                    i == priceLabels.length - 1 ? '' : separator;
                  valueToPush += `${priceLabels[i]}: ${pricesArray[i]} ${priceSeparator}`;
                }
                // Remove the last ' | ' from the string
              }
            } else if (labelIndex == 6) {
              keys.forEach((key: any) => {
                if (key && e.csvFormat[key] && e.csvFormat[key] == true) {
                  valueToPush = 'YES';
                }
              });
            } else {
              keys.forEach((key: any, index: any) => {
                if (key && e.csvFormat[key]) {
                  const standardSeparator = index == 0 ? '' : separator;
                  valueToPush =
                    valueToPush + standardSeparator + e.csvFormat[key];
                }
              });
            }
          }
          exportFileEntries[index + 1].push(valueToPush ? valueToPush : ' ');
        } else {
          if (labelIndex == 10) {
            keys.forEach((key: any) => {
              if (key && e.csvFormat[key] && e.csvFormat[key] == true) {
                exportFileEntries[index + 1].push('YES');
              }
            });
          } else {
            exportFileEntries[index + 1].push(
              e.csvFormat[key] ? e.csvFormat[key] : ' '
            );
          }
        }
      });
    });

    return exportFileEntries;
  }

  formatPrice(price: string, omp: string, uom: string): string {
    if (!price && !omp) {
      return '';
    }
    let formattedPrice =
      '$' +
      (price !== '' && +price > 0
        ? this.roundPriceToDecimals(price)
        : omp !== ''
        ? this.roundPriceToDecimals(omp)
        : '0.00');
    formattedPrice += uom ? '/' + uom : '';
    return formattedPrice;
  }

  transformAltPricesArrayIntoProperty(dataStructArray: any[]): any[] {
    dataStructArray.forEach((tableRow: any) => {
      if (Math.round(+tableRow.moq) > 1) {
        this.processTableRowAlt(tableRow);
      } else {
        this.processTableRowBasic(tableRow);
      }

      this.processNetPrice(tableRow);

      this.formatForCSV(tableRow);

      if (!tableRow.priceColumn) tableRow.priceColumn = '$0.00';
      if (!tableRow.altPriceColumn)
        tableRow.altPriceColumn = tableRow.priceColumn;
      if (!tableRow.basicDiscountPrice) tableRow.basicDiscountPrice = '$0.00';
    });
    return dataStructArray;
  }

  processNetPrice(tableRow: any) {
    const altPriceList = tableRow.toAltPrice.results;
    const hybrisAltUOM = tableRow.hybrisAltUOM;
    const foundAltPrice = altPriceList.find(
      (altPrice: any) => altPrice.altUOM === hybrisAltUOM
    );

    tableRow.basicDiscountPrice = foundAltPrice
      ? this.formatPrice(foundAltPrice.altDiscPrice, '', hybrisAltUOM)
      : this.formatPrice(tableRow.basicDiscountPrice, '', tableRow.basicUOM);
  }

  processTableRowAlt(tableRow: any) {
    const altPriceList = tableRow.toAltPrice.results;
    const hybrisAltUOM = tableRow.hybrisAltUOM;
    const foundAltPrice = altPriceList.find(
      (altPrice: any) => altPrice.altUOM === hybrisAltUOM
    );
    const price = altPriceList.find(
      (altPrice: any) => altPrice.altMinOrdQty == 1
    );

    tableRow.priceColumn = this.formatPrice(
      price?.basicCP,
      '',
      price?.basicUOM
    ); //alt price
    tableRow.altPriceColumn = this.formatPrice(
      foundAltPrice?.altCP,
      '',
      foundAltPrice?.altUOM
    ); //price
  }

  processTableRowBasic(tableRow: any) {
    const altPriceList = tableRow.toAltPrice.results;
    const hybrisAltUOM = tableRow.hybrisAltUOM;
    const foundAltPrice = altPriceList.find(
      (altPrice: any) => altPrice.altUOM === hybrisAltUOM
    );
    const price = altPriceList.find(
      (altPrice: any) => altPrice.altMinOrdQty == 1
    );

    tableRow.altPriceColumn = foundAltPrice
      ? this.formatPrice(
          foundAltPrice?.altCP,
          foundAltPrice?.altOMP,
          foundAltPrice?.altUOM
        )
      : this.formatPrice(
          tableRow.basicCP,
          tableRow.basicOMP,
          tableRow.basicUOM
        );

    if (Math.round(+tableRow.minOrderQuantity) === 1) {
      tableRow.priceColumn = this.formatPrice(
        tableRow.basicCP,
        tableRow.basicOMP,
        tableRow.basicUOM
      );
    } else if (Math.round(+tableRow.minOrderQuantity) > 1) {
      tableRow.priceColumn = this.formatPrice(
        price?.altCP,
        price?.altOMP,
        price?.altUOM
      );
    }
  }

  formatForCSV(tableRow: any) {
    tableRow.csvFormat = {
      priceColumn: tableRow.priceColumn?.split('/')[0] || '$0.00',
      altPriceColumn:
        tableRow.altPriceColumn?.split('/')[0] ||
        tableRow.priceColumn?.split('/')[0],
      basicDiscountPrice: tableRow.basicDiscountPrice?.split('/')[0] || '$0.00',
      ndc: tableRow.ndc,
      category: tableRow.category,
      name: tableRow.name,
      size: tableRow.size,
      manufacturer: tableRow.manufacturer,
      productCode: tableRow.productCode,
      accountNumber: tableRow.accountNumber,
      altUOM: tableRow.priceColumn?.split('/')[1],
      contractUOM:
        tableRow.altPriceColumn?.split('/')[1] ||
        tableRow.priceColumn?.split('/')[1],
      netUOM: tableRow.basicDiscountPrice?.split('/')[1],
      contractedMaterial: tableRow.contractedMaterial,
    };
  }

  roundPriceToDecimals(value: string): string {
    const decimalPlaces = this.isFourDecimalAccount ? 10000 : 100;
    const price = this.decimalPipe.transform(
      Math.round((+value + Number.EPSILON) * decimalPlaces) / decimalPlaces,
      this.isFourDecimalAccount ? '1.4-4' : '1.2-2'
    );
    return price ? price : '$0.00';
  }
  async exportCSV() {
    this.openExportedDocumentSuccess();
    let r = await this.parseExportInfo('csv');
    let options: ExportFileOptions = {
      fileName: 'priceList',
      extension: 'csv',
      type: 'multipart/form-data',
    };
    this.exportCSVService.download(r, ',', options);
  }

  async exportPDF() {
    this.openExportedDocumentSuccess();
    let doc = new jsPDF();
    let exportInfo = await this.parseExportInfo('pdf');
    let head = exportInfo[0];
    let body = [];
    for (let i = 1; i < exportInfo.length; i++) {
      body.push(exportInfo[i]);
    }

    interface ColumnStyle {
      fontStyle?: 'normal';
      columnWidth?: number;
    }
    let columnStyles: any = {};

    columnStyles = {
      0: { fontStyle: 'normal', columnWidth: 20 },
      1: { fontStyle: 'normal', columnWidth: 40 },
      2: { fontStyle: 'normal', columnWidth: 25 },
      3: { fontStyle: 'normal', columnWidth: 20 },
      4: { fontStyle: 'normal', columnWidth: 20 },
      5: { fontStyle: 'normal', columnWidth: 25 },
      6: { columnWidth: 25 },
    };

    autoTable(doc, {
      head: [head],
      body: body,
      styles: {
        fontSize: 10,
        fontStyle: 'normal',
        cellPadding: 2,
      },
      headStyles: {
        fontSize: 7,
        fontStyle: 'bold',
      },
      bodyStyles: {
        fontSize: 6,
      },
      columnStyles: columnStyles,
    });

    doc.save('priceList.pdf');
  }
  isAltPrice(altPrice: any): boolean {
    return 'EU' === altPrice.altUOM || 'IU' === altPrice.altUOM;
  }
  openExportedDocumentSuccess() {
    this.modalService.open(FffExportedDocumentMessageComponent, {
      centered: true,
      backdrop: 'static',
    });
  }
}
