import { Injectable } from '@angular/core';
import { getCategoryCodeByName } from '@app/fff-config/category/fff-category.utils';
import { FffCommunicationService } from '@app/fff-enterprise/fff-common-services/fff-communication.service';
import { FFFActiveCartService } from '@app/fff-enterprise/fff-custom-cart/fff-active-cart-service';
import {
  FFFOrderEntry,
  FFFProductInputEntry,
} from '@app/models/fff-cart-data.model';
import { PrebookAddToCartRedirectConfig } from '@app/models/fff-prebook.model';
import { VACCINES_CATEGORY_TYPES } from '@app/models/fff-product.model';
import { FffUserAccountService } from '@app/shared/services/fff-user-account.service';
import { PageType, RoutingService } from '@spartacus/core';
import { BehaviorSubject, Observable, combineLatest, of } from 'rxjs';
import { filter, map, startWith, switchMap, take, tap } from 'rxjs/operators';
import {
  PREBOOK_CART_LABEL,
  getNextCategoryName,
  isPrebookEnabledCategory,
} from './fff-prebook-category.utils';

interface DosePerBoxExceptionsMap {
  [code: string]: number;
}

interface QuantityInfo {
  totalQuantity: number;
  totalDoses: number;
}

@Injectable({
  providedIn: 'root',
})
export class FffPrebookCartService {
  private updatableEntries$ = new BehaviorSubject<FFFOrderEntry[]>([]);
  private stockData$ = new BehaviorSubject<{ [key: string]: any }>({});
  private pricesData$ = new BehaviorSubject<{ [key: string]: any }>({});

  private stockData: { [key: string]: any } = {};
  private pricesData: { [key: string]: any } = {};
  private fluTotalQty: number = 0;
  private totalFluDoses: number = 0;
  private dosePerBoxExceptionsMap: DosePerBoxExceptionsMap = {
    DEFAULT: 10,
  };

  constructor(
    private fffActiveCartService: FFFActiveCartService,
    private userAccountService: FffUserAccountService,
    private routingService: RoutingService,
    private fffCommunicationService: FffCommunicationService
  ) {
    this.loadDosePerBoxExceptions();
  }

  initPrebookCart() {
    // Calling user Details API
    this.userAccountService.getProfile().pipe(take(1)).subscribe();

    // Sets prebookCart flag to true
    this.fffActiveCartService
      .updateActiveCartPrebookFlag(true)
      .pipe(take(1))
      .subscribe();
  }
  private loadDosePerBoxExceptions(): void {
    this.fffCommunicationService
      .getAppProperties()
      .subscribe(applicationProperties => {
        if (
          applicationProperties &&
          applicationProperties.mfvPrebookRsvDoseInfo
        ) {
          this.dosePerBoxExceptionsMap = {
            ...this.dosePerBoxExceptionsMap,
            ...this.parseDosePerBoxExceptions(
              applicationProperties.mfvPrebookRsvDoseInfo
            ),
          };
          const a = 123;
        } else {
          console.warn('No data received from properties API');
        }
      });
  }

  private parseDosePerBoxExceptions(data: string): DosePerBoxExceptionsMap {
    return data.split(',').reduce((map, entry) => {
      const [code, dose] = entry.split('-');
      if (code && dose) {
        map[code.trim()] = +dose;
      }
      return map;
    }, {} as DosePerBoxExceptionsMap);
  }

  getUpdatableEntries(): Observable<FFFOrderEntry[]> {
    return this.updatableEntries$.asObservable();
  }

  getUpdatableEntriesByCode(code: string) {
    return this.getUpdatableEntries().pipe(
      map(entries =>
        entries.filter(
          (e: any) => !!e.categories.find((cat: any) => cat?.code == code)
        )
      )
    );
  }

  removeUpdatableEntry(productCode: string): void {
    let entries = this.updatableEntries$.value || [];

    const index = entries.findIndex(e => e.code == productCode);

    if (index !== -1) {
      entries.slice(index, 1);
      this.updatableEntries$.next(entries);
    }
  }

  setUpdatableEntry(entry: FFFProductInputEntry): void {
    let entries = this.updatableEntries$.value || [];

    const quantity = entry.quantity ? +entry.quantity : 0;

    const index = entries.findIndex(e => e.code == entry.code);

    if (index === -1) {
      entries.push({ ...entry, quantity });
    } else {
      entries[index] = { ...entry, quantity };
    }
    this.updatableEntries$.next(entries);
  }

  resetUpdatableEntries(): void {
    this.updatableEntries$.next([]);
  }

  getUpdatableEntriesTotalQuantity(): Observable<number> {
    return this.getUpdatableEntries().pipe(
      startWith([]),
      map(entries =>
        (entries || [])
          .map(({ quantity }) => quantity || 0)
          .reduce((a, b) => a + b, 0)
      )
    );
  }

  // Updatable Entires for Prebook Category Pages
  getUpdatableEntriesByType(
    type: VACCINES_CATEGORY_TYPES
  ): Observable<FFFOrderEntry[]> {
    return this.getUpdatableEntries().pipe(
      map(entries =>
        entries.filter(
          (value: any) =>
            !!value?.categories?.find(
              (cat: any) => cat.code === getCategoryCodeByName(type)
            )
        )
      )
    );
  }

  // Active Cart Entires
  getEntriesByType(type: VACCINES_CATEGORY_TYPES): Observable<FFFOrderEntry[]> {
    return this.fffActiveCartService
      .getEntries()
      .pipe(
        map(entries =>
          entries.filter(
            (value: FFFOrderEntry) =>
              !!value.product?.categories?.find(
                cat => cat.code === getCategoryCodeByName(type)
              )
          )
        )
      );
  }

  getEntriesTotalByType(type: any): Observable<QuantityInfo> {
    return this.getEntriesByType(type).pipe(
      startWith([]), // Emit an empty array initially if there are no entries
      map(entries => {
        return entries.reduce(
          (acc, entry: FFFOrderEntry) => {
            const quantity = entry.quantity || 0;
            const productCode = entry?.product?.code || 'DEFAULT'; // Default to 'DEFAULT' if no code
            const doseCount = this.dosePerBoxExceptionsMap[productCode] || 10; // Default to 10 if code not in map
            acc.totalQuantity += quantity;
            acc.totalDoses += quantity * doseCount;
            return acc;
          },
          { totalQuantity: 0, totalDoses: 0 } // Initial accumulator values
        );
      })
    );
  }

  // getUpdatableEntriesTotalByType(type: any): Observable<number> {
  //   return this.getUpdatableEntriesByType(type).pipe(
  //     startWith([]),
  //     map(entries =>
  //       entries.map(e => e.quantity || 0).reduce((a, b) => a + b, 0)
  //     )
  //   );
  // }
  getUpdatableEntriesTotalByType(type: any): Observable<QuantityInfo> {
    return this.getUpdatableEntriesByType(type).pipe(
      startWith([]), // Emit an empty array initially if there are no entries
      map(entries => {
        return entries.reduce(
          (acc, entry: FFFOrderEntry) => {
            const quantity = entry.quantity || 0;
            const productCode = entry.code || 'DEFAULT'; // Default to 'DEFAULT' if no code
            const doseCount = this.dosePerBoxExceptionsMap[productCode] || 10; // Default to 10 if code not in map
            acc.totalQuantity += quantity;
            acc.totalDoses += quantity * doseCount;
            return acc;
          },
          { totalQuantity: 0, totalDoses: 0 } // Initial accumulator values
        );
      })
    );
  }

  getAllStockData() {
    return this.stockData$.asObservable();
  }

  getAllPricesData() {
    return this.pricesData$.asObservable();
  }

  setStockDataByProduct(code: string, data: any) {
    this.stockData[code] = { ...data };
    this.stockData$.next(this.stockData);
  }

  setPricesDataByProduct(code: string, data: any) {
    this.pricesData[code] = { ...data };
    this.pricesData$.next(this.pricesData);
  }

  addToCart(
    unsubscribe$: Observable<boolean>,
    redirectConfig: PrebookAddToCartRedirectConfig,
    currentPageId: string
  ) {
    const activeCartEntriesObs$ = this.fffActiveCartService
      .getEntries()
      .pipe(take(1));
    const code = getCategoryCodeByName(currentPageId);
    const updatableEntriesObs$ = this.getUpdatableEntriesByCode(code).pipe(
      take(1)
    );

    return combineLatest([activeCartEntriesObs$, updatableEntriesObs$]).pipe(
      take(1),
      switchMap(([activeEntries, updatableEntries]) => {
        const navigateToHomeWithPrebookFalseObs$ = this.fffActiveCartService
          .updateActiveCartPrebookFlag(false)
          .pipe(tap(() => this.redirectToHome()));

        // When not products in cart & navigating to homepage
        if (redirectConfig.redirectToHome) {
          // Check if cart is empty, update prebookCart=false
          if (!activeEntries.length) {
            return navigateToHomeWithPrebookFalseObs$;
          }

          // Check if cart has only RSV products, update prebookCart=false
          // if (this.hasOnlyRsvProducts(activeEntries)) {
          //   return navigateToHomeWithPrebookFalseObs$;
          // }
        }

        // When no entries to update, redirect as per config
        if (!updatableEntries?.length) {
          this.handleAddToCartRedirection(redirectConfig);
          return of({});
        }

        // Handle rest of the actions: nav-items, skip checkout, next button
        return this.addEntriesToCart(updatableEntries, unsubscribe$);
      }),
      tap(() => this.handleAddToCartRedirection(redirectConfig))
    );
  }

  addEntriesToCart(
    updatableEntries: any[] = [],
    unsubscribe$: Observable<boolean>
  ) {
    const testAddEntries: FFFOrderEntry[] = [];

    updatableEntries.forEach((e: any) => {
      const tieredPricing = this.pricesData?.[e.code]?.prices?.find(
        (price: any) => e.unit === price.accountType || e.unit === 'AUTO'
      );
      let parsedTiers = tieredPricing?.price.toMatScale.results.map(
        (result: any) => {
          return {
            quantity: parseInt(result.scaleQty),
            price: result.scaleDiscAmt,
          };
        }
      );

      testAddEntries.push({
        key: e.key,
        quantity: e.quantity,
        // e.unit
        accountType: e.unit,
        warehouse: this.getWarehouse(e),
        code: e.code,
        product: {
          code: e.code,
        },
        basePrice: {
          value: e.price,
        },
        taxValue: e.taxValue,
        discountValue: e.discountValue,
        uom: e.uom,
        altUom: e.altUom,
        dropShip: e.dropShip,
        mduConversionFactor: e.mduConversionFactor,
        unitConversionFactor: e.unitConversionFactor,
        sapStockLevelStatus: this.stockData[e.code]
          ? this.stockData[e.code].status
          : '',
        strength: e.strength,
        batch: e.batch,
        expirationDate: e.expirationDate,
        adminSet: e.adminSet,
        yconnector: e.yconnector,
        altUnitConversion: e.altUnitConversion,
        tieredPricing: parsedTiers,
        altDiscPrice: e.altDiscPrice,
        ...(e?.entryNumber !== undefined && {
          entryNumber: e?.entryNumber,
        }),
      });
    });

    return this.fffActiveCartService.getEntries().pipe(
      take(1),
      map(entries => this.mapEntriesWithNewEntries(entries)),
      switchMap(() =>
        this.fffActiveCartService.addNewEntries(testAddEntries, unsubscribe$)
      )
    );
  }

  mapEntriesWithNewEntries(entries: FFFOrderEntry[]): FFFOrderEntry[] {
    let numberOfEntriesBeforeAdd = 0;
    entries.map(entry => (numberOfEntriesBeforeAdd += entry?.quantity!));
    return entries;
  }

  getStockInfo(stockData: any = {}): any {
    return stockData?.results?.[0] || {};
  }

  getWarehouse(product: any) {
    const plantsAlloc = this.getStockInfo(this.stockData[product.code])
      ?.custToMatInv?.results[0];

    let plants = [];

    if (plantsAlloc) {
      plants = plantsAlloc.plants.split('|');
    } else {
      return product.warehouse.split('p_warehouse_')[1];
    }

    if (plantsAlloc.defaultPlant) {
      let f = plants.find((p: any) => plantsAlloc?.defaultPlant == p);
      return f ? f : plants[0];
    } else {
      return plants[0];
    }
  }

  redirectToHome() {
    this.routingService.go(['/']);
  }

  redirecToPrebookCart() {
    this.routingService.go(['/my-cart'], { queryParams: { prebook: true } });
  }

  redirectToNextStep(category: string) {
    if (!category) {
      return this.redirecToPrebookCart();
    }

    const nextCategory = getNextCategoryName(category);

    if (nextCategory === category) {
      return this.redirecToPrebookCart();
    }

    this.routingService.go(['/categories'], {
      queryParams: { category: nextCategory, prebook: true },
    });
  }

  handleAddToCartRedirection(redirectConfig: PrebookAddToCartRedirectConfig) {
    const { pageId, redirectToCart, redirectToHome, redirectToNextPage } =
      redirectConfig;

    // Redirect to prebook cart
    if (redirectToCart) {
      return this.redirecToPrebookCart();
    }

    // Redirect to homepage
    if (redirectToHome) {
      this.redirectToHome();
      return;
    }

    // Redirect to next category page
    if (redirectToNextPage) {
      return this.redirectToNextStep(pageId);
    }

    // Redirect to category
    return this.routingService.go(['/categories'], {
      queryParams: { category: pageId, prebook: true },
    });
  }

  hasOnlyRsvProducts(entries: FFFOrderEntry[]): boolean {
    return entries.every(
      (value: any) =>
        !!value?.product?.categories?.find(
          (cat: any) =>
            cat.code === getCategoryCodeByName(VACCINES_CATEGORY_TYPES.RSV)
        )
    );
  }

  getFluTotalQuantity(): number {
    return this.fluTotalQty;
  }

  setFluTotalQuantity(value: number): void {
    this.fluTotalQty = value;
  }

  getTotalFlueDoses(): number {
    return this.totalFluDoses;
  }

  setTotalFluDoses(value: number): void {
    this.totalFluDoses = value;
  }

  updatePrebookCartAndRedirectToHome() {
    return this.fffActiveCartService.takeActive().pipe(
      take(1),
      switchMap(cart => {
        if (!cart?.code) {
          this.redirectToHome();
          return of({});
        }

        const navigateToHomeWithPrebookFalseObs$ = this.fffActiveCartService
          .updateActiveCartPrebookFlag(false)
          .pipe(tap(() => this.redirectToHome()));

        // Check if cart is empty, update prebookCart=false
        if (!cart?.entries?.length) {
          return navigateToHomeWithPrebookFalseObs$;
        }

        this.redirectToHome();
        return of({});
      })
    );
  }

  isPrebookCategoryPage(): Observable<boolean> {
    return this.routingService.getRouterState().pipe(
      filter(value => !!value.state?.context?.id),
      map(
        value =>
          value.state.context.type === PageType.CATEGORY_PAGE &&
          isPrebookEnabledCategory(value.state.queryParams)
      )
    );
  }

  isPrebookCartPage(): Observable<boolean> {
    return this.routingService.getRouterState().pipe(
      filter(value => !!value.state?.context?.id),
      map(value => value.state.context.id === PREBOOK_CART_LABEL)
    );
  }

  isPrebookPage(): Observable<boolean> {
    return combineLatest([
      this.isPrebookCartPage(),
      this.isPrebookCategoryPage(),
    ]).pipe(
      map(
        ([isPrebookCartPage, isPrebookCategoryPage]) =>
          isPrebookCartPage || isPrebookCategoryPage
      )
    );
  }
}
