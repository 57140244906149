import { NgModule } from '@angular/core';
import {
  SavedCartConnector,
  SavedCartEventsModule,
  SavedCartService,
} from '@spartacus/cart/saved-cart/core';
import { SavedCartFacade } from '@spartacus/cart/saved-cart/root';
import { FffSavedCartStoreModule } from './store/saved-cart-store.module';
@NgModule({
  imports: [FffSavedCartStoreModule, SavedCartEventsModule],
  providers: [
    SavedCartConnector,
    SavedCartService,
    {
      provide: SavedCartFacade,
      useExisting: SavedCartService,
    },
  ],
})
export class FffSavedCartCoreModule {}
