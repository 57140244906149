import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbDatepickerModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { CmsConfig, I18nModule, provideDefaultConfig } from '@spartacus/core';
import { FormErrorsModule, IconModule } from '@spartacus/storefront';
import { FffNewReportsModule } from '../fff-new-reports/fff-new-reports.module';
import { FffReportsModalComponent } from './fff-reports-modal/fff-reports-modal.component';
import { FffReportsComponent } from './fff-reports.component';

@NgModule({
  declarations: [FffReportsComponent, FffReportsModalComponent],
  imports: [
    CommonModule,
    I18nModule,
    IconModule,
    NgSelectModule,
    NgbDatepickerModule,
    FormsModule,
    NgSelectModule,
    ReactiveFormsModule,
    FormErrorsModule,
    FffNewReportsModule,
  ],
  providers: [
    provideDefaultConfig(<CmsConfig>{
      cmsComponents: {
        ReportsComponentFlex: {
          component: FffReportsComponent,
        },
      },
    }),
  ],
})
export class FffReportsModule {}
