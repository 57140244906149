import { Component, Input } from '@angular/core';
import { Favorite } from '@app/models/fff-favorites.model';
import { FffCommunicationService } from '@enterprise/fff-common-services/fff-communication.service';
import { FffFavoritesService } from '@enterprise/fff-common-services/fff-favorites.service';
import { FffProfile } from "@model/fff-profile.model";
import { FffUserAccountService } from "@shared/services/fff-user-account.service";
import { BaseSiteService, RoutingService } from "@spartacus/core";
import { Observable } from 'rxjs';

@Component({
  selector: 'fff-favorites-list',
  templateUrl: './fff-favorites-list.component.html'
})
export class FffFavoritesListComponent {

  listOfFavorites$: Observable<Favorite[]> = this.fffFavoritesService.listOfFavorites$;
  activeSite$ = this.baseSiteService.getActive();
  profile$: Observable<FffProfile | undefined> = this.fffAccountService.getProfile();

  @Input() product: any | undefined;

  constructor(private fffFavoritesService: FffFavoritesService,
              private communicationService: FffCommunicationService,
              private fffAccountService: FffUserAccountService,
              private baseSiteService: BaseSiteService, 
              private routing: RoutingService) {
  }

  goToHomePage(): void {
    this.routing.go('/');
  }
}
