import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { NgbCollapseModule } from '@ng-bootstrap/ng-bootstrap';
import { I18nModule, UrlModule } from '@spartacus/core';
import { SpinnerModule } from '@spartacus/storefront';
import { FffSafeHtmlPipe } from 'src/app/shared/pipes/fff-safe-html.pipe';
import { FffProgressBarComponent } from '../fff-progress-bar/fff-progress-bar.component';
import { FffProductExtraInfoComponent } from './fff-product-extra-info/fff-product-extra-info.component';
import { FFFLoadersModule } from "../../../shared/components/fff-loaders/fff-loaders.module";

@NgModule({
    declarations: [
        FffProductExtraInfoComponent,
        FffProgressBarComponent,
        FffSafeHtmlPipe
    ],
    imports: [
    UrlModule,
    BrowserModule,
    CommonModule,
    I18nModule,
    SpinnerModule,
    NgbCollapseModule,
    FFFLoadersModule
],
    exports: [FffProductExtraInfoComponent, FffSafeHtmlPipe],
    providers: []
})
export class FffProductMoreInfoModule { }
