import { Component, OnInit } from '@angular/core';
import { UserAccountFacade } from '@spartacus/user/account/root';
import { take } from 'rxjs/operators';
import { FffDrawerService } from 'src/app/shared/drawer/fff-drawer.service';
import { FFF_MINICART } from 'src/assets/translations/en/fff-register';
import { OUTLINED_ICON_TYPE } from '../../../models/fff-outline-icons.model';


@Component({
  selector: 'fff-my-account-drawer-header',
  templateUrl: './fff-my-account-drawer-header.component.html',
})
export class FffMyAccountDrawerHeaderComponent implements OnInit {

  public userName: string | undefined;
  public userType: string | undefined;
  public miniCart: any = FFF_MINICART.FFF_DATA;
  public outlinedIconTypes = OUTLINED_ICON_TYPE;

  constructor(
    private userAccount: UserAccountFacade,
    private drawerService: FffDrawerService,

  ) { }

  ngOnInit(): void {
    this.userAccount.get().pipe(take(1)).subscribe((event: any) => {
      this.userType = event?.currentB2BUnit?.uid;
      this.userName = event?.firstName;
    });
  }

  public closeDrawer(): void {
    this.drawerService.closeDrawer();
  }
}

