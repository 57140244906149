import { Component, Input, OnInit } from '@angular/core';
import { OUTLINED_ICON_TYPE } from '@app/models/fff-outline-icons.model';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { FffUpdateInteractiveAllocationsService } from '../fff-update-interactive-allocations.service';

@Component({
  selector: 'fff-update-interactive-allocations-header',
  templateUrl: './fff-update-interactive-allocations-header.component.html',
})
export class FffUpdateInteractiveAllocationsHeaderComponent implements OnInit {

  outlinedIconTypes = OUTLINED_ICON_TYPE;
  @Input() amountAvailableToReallocate: number = 0;
  amountReallocated$: Observable<number | undefined> = this.fffUpdateInteractiveAllocationsService.amountReallocated$;
  @Input() productCode: string = '';
  @Input() productName: string = '';

  constructor(
    private modalService: NgbActiveModal,
    private fffUpdateInteractiveAllocationsService: FffUpdateInteractiveAllocationsService,
  ) { }

  ngOnInit(): void { }

  closeDrawer(): void {
    this.fffUpdateInteractiveAllocationsService.amountReallocated$.next(undefined);
    this.modalService.close({ refreshList: false });
  }

}
