import { HttpClient } from '@angular/common/http';
import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import {
  BANNER_IMAGES,
  BASE_URL_KEYS,
} from '@app/fff-config/content/constants';
import { CustomPage } from '@app/fff-enterprise/fff-util/fff-models/custom-page.model';
import {
  BaseSiteService,
  CmsBannerComponent,
  CmsService,
  SemanticPathService,
} from '@spartacus/core';
import { BannerComponent, CmsComponentData } from '@spartacus/storefront';
import { DeviceDetectorService } from 'ngx-device-detector';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { BannerComponentTypes } from 'src/app/fff-config/banner-component/bannerComponent.config';

export interface FffSimpleResponsiveBanner {
  banner: any;
  bannerType: string;
  active: any;
}
@Component({
  selector: 'fff-simple-responsive-card',
  templateUrl: './fff-simple-responsive-card.component.html',
})
export class FffSimpleResponsiveCardComponent
  extends BannerComponent
  implements OnInit
{
  private intervalId: any;
  private readonly rotationInterval = 5000; // Rotation interval of banner
  private currentIndex = 0;
  constructor(
    protected component: CmsComponentData<CmsBannerComponent>,
    urlService: SemanticPathService,
    private router: Router,
    private baseSiteService: BaseSiteService,
    public cmsService: CmsService,
    private http: HttpClient,
    private cd: ChangeDetectorRef,
    private deviceService: DeviceDetectorService
  ) {
    super(component, urlService, cmsService);
  }

  loading$ = new BehaviorSubject<boolean>(false);
  simple: FffSimpleResponsiveBanner | undefined;
  simple$: Observable<FffSimpleResponsiveBanner> = this.component.data$.pipe(
    switchMap(data => this.getBannerData(data))
  );
  changeContent = false;
  bannerComponentTypes = BannerComponentTypes;
  activeSite$ = this.baseSiteService.getActive();
  BASE_URL_KEYS = BASE_URL_KEYS;
  page$: Observable<CustomPage> = this.cmsService.getCurrentPage();
  urlReceived: any;
  @ViewChild('headLineContainer') headLineContainer!: ElementRef;
  headlineFontSize: string = '4em';
  @ViewChild('contentContainer') contentContainer!: ElementRef;
  contentFontSize: string = '4em';
  data: any;
  ngOnInit(): void {
    this.loading$.next(true);
    this.page$.subscribe((res: any) => {
      if (!res?.advertisingScript) {
        this.urlReceived = null;
        this.cd.markForCheck();
        return;
      }
      this.http.get(res?.advertisingScript).subscribe((val: any) => {
        this.urlReceived = val?.AdServing?.AdCode;
        this.cd.markForCheck();
      });
    });
    // this.http.get(this.scriptReceived).subscribe((res: any) => {
    //   console.log(res);
    //   this.scriptData = res.AdServing.AdCode;
    // })
    this.simple$.subscribe(fetchedData => {
      this.data = fetchedData;

      setTimeout(() => {
        this.adjustFontSize(
          this.headLineContainer,
          this.data?.banner?.headline,
          'headline'
        );
        this.adjustFontSize(
          this.contentContainer,
          this.data?.banner?.content,
          'content'
        );
      }, 0);
    });
  }

  private adjustFontSize(
    containerRef: ElementRef | undefined,
    text: string | undefined,
    type: 'headline' | 'content'
  ): void {
    if (!containerRef || !text) return;

    const container = containerRef.nativeElement;
    const maxHeight = container.offsetHeight;
    const maxWidth = container.offsetWidth;
    const isDesktop = this.deviceService.isDesktop();

    let size = type === 'headline' ? (isDesktop ? 4 : 1) : isDesktop ? 3 : 1;
    const minSize =
      type === 'headline' ? (isDesktop ? 1 : 0.5) : isDesktop ? 1 : 0.5;
    const step = 0.01;

    const context = document.createElement('canvas').getContext('2d');
    if (!context) return;

    while (size > minSize) {
      context.font = `${size}em Arial`;
      const textWidth = context.measureText(text).width;
      const textHeight = size * 1.2;

      if (textWidth <= maxWidth && textHeight * 2 <= maxHeight) {
        break;
      }

      size -= step;
    }

    if (type === 'headline') {
      this.headlineFontSize = `${size}em`;
    } else {
      this.contentFontSize = `${size}em`;
    }
  }
  getBannerData(data: any): Observable<FffSimpleResponsiveBanner> {
    this.styleClasses = data?.styleClasses ? data.styleClasses : '';
    let bannerType = '';
    if (BannerComponentTypes.HOMEPAGE_QUICK_LINKS.includes(data?.uid)) {
      bannerType = '1';
    } else if (this.styleClasses?.includes('two-column-banner')) {
      bannerType = '2';
    } else if (this.styleClasses?.includes('popular-category-banner')) {
      bannerType = '3';
    } else if (this.styleClasses?.includes('simplify-orders-banner')) {
      bannerType = '4';
    } else if (data?.uid.includes('rotatingBannerHome')) {
      bannerType = '5';
    }

    const simpleBanner: FffSimpleResponsiveBanner = {
      bannerType: bannerType,
      banner: data,
      active: this.getTopHeaderDisplay_icon(data),
    };
    this.simple = simpleBanner;
    this.loading$.next(false);
    return of(simpleBanner);
  }

  private getTopHeaderDisplay_icon(data: any): any {
    const active = BANNER_IMAGES.section_images.findIndex(
      (el: any) => el.title === data?.uid
    );
    if (active !== -1) return BANNER_IMAGES.section_images[active];
  }

  private getQueryParams(query: string) {
    if (!query) {
      return {};
    }
    try {
      return JSON.parse(
        '{"' + query.replace(/&/g, '","').replace(/=/g, '":"') + '"}',
        function (key, value) {
          return key === '' ? value : decodeURIComponent(value);
        }
      );
    } catch (e) {
      return {};
    }
  }

  openUrlLink(linkToPage: string) {
    let [url, query] = linkToPage.split('?');
    let queryParams = this.getQueryParams(query);
    if (this.simple?.active && !linkToPage.includes('https')) {
      this.router.navigate([url], { queryParams });
    } else {
      const check_Url = () => {
        try {
          new URL(linkToPage);
          return true;
        } catch {
          return false;
        }
      };
      check_Url()
        ? window.open(linkToPage, '_blank')
        : this.router.navigate([url], {
            queryParams,
          });
    }
  }
  ngAfterViewInit(): void {
    this.startRotation();
  }

  ngOnDestroy(): void {
    this.stopRotation();
  }

  private startRotation(): void {
    this.intervalId = setInterval(() => {
      this.rotateCarousel();
    }, this.rotationInterval);
  }

  private rotateCarousel(): void {
    const indicators = document.querySelectorAll('.indicators button');
    const totalElements = indicators.length;
    const openedSectionDiv = document.querySelectorAll(
      '.asm-results,.minicart-drawer'
    );
    const isAnyItemOpen = openedSectionDiv && openedSectionDiv.length > 0;
    if (this.currentIndex >= totalElements) {
      this.currentIndex = 0;
    }
    if (indicators && indicators.length > 0) {
      indicators.forEach((button: any, index: number) => {
        if (index === this.currentIndex && !isAnyItemOpen) {
          button.click();
        }
      });
    }
    if (!isAnyItemOpen) {
      this.currentIndex++;
    }
  }

  private stopRotation(): void {
    clearInterval(this.intervalId);
  }
}
