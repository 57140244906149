<ngb-datepicker
  #dp
  (dateSelect)="onDateSelection($event)"
  [startDate]="getStartDate()"
  [dayTemplate]="t"
  outsideDays="hidden"
  [navigation]="'arrows'"
  [weekdays]="true"
  [displayMonths]="displayMonths"
  [contentTemplate]="c"
></ngb-datepicker>

<ng-template #t let-date let-focused="focused">
  <span
    class="custom-day"
    [class.range]="isRange(date, index)"
    [class.faded]="isInside(date, index)"
  >
    {{ date.day }}
  </span>
</ng-template>

<ng-template #c let-dp>
  <div class="p-2">
    <div class="custom-month-grid">
      <div *ngFor="let month of dp.state.months; track: month">
        <div class="p-1 fff-month-label" *ngIf="displayDynamicMonths">
          {{ dp.i18n.getMonthFullName(month.month) }} {{ month.year }}
        </div>
        <ngb-datepicker-month
          class="border rounded"
          [month]="month"
        ></ngb-datepicker-month>
      </div>
    </div>
  </div>
</ng-template>
