import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { ACCOUNT_STATUS } from '@app/fff-config/content/constants';
import { fffCSOSConfig } from '@app/fff-config/fff-endpoints.config';
import { FffB2BUnitService } from '@app/fff-enterprise/fff-common-services/fff-b2b-unit-service';
import { FffB2bUnit } from '@app/models/fff-b2b-unit.model';
import { FILLED_ICON_TYPE } from '@app/models/fff-filled-icons.model';
import { FffLegisym } from '@app/models/fff-legisym.model';
import { FffUser } from '@app/models/fff-user.model';
import { environment } from '@env/environment';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CsAgentAuthService } from '@spartacus/asm/root';
import {
  OccEndpointsService,
  TranslationService,
  WindowRef,
} from '@spartacus/core';
import { BehaviorSubject, Observable, Subject, of } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import { FFFCsosVerifyAccountPopupComponent } from '../fff-csos-verify-account-popup/fff-csos-verify-account-popup.component';
import { FFFCsosVerifyUserPopupComponent } from '../fff-csos-verify-user-popup/fff-csos-verify-user-popup.component';
import { FFFCustomValidators } from '@app/shared/validators/fff-custom-validators';

@Component({
  selector: 'fff-profile-detail-csos',
  templateUrl: './fff-profile-detail-csos.component.html',
})
export class FffProfileDetailCsosComponent implements OnInit, OnDestroy {
  @Input() selected!: FffB2bUnit;
  @Input() user!: FffUser;
  @Output() reloadAccount: EventEmitter<void> = new EventEmitter<void>();
  @Output() isUpdating: EventEmitter<boolean> = new EventEmitter<boolean>(
    false
  );
  @Output() setResult: EventEmitter<boolean> = new EventEmitter<boolean>(false);

  legisym$: BehaviorSubject<FffLegisym> = new BehaviorSubject<FffLegisym>({});
  csosStatus = '';
  constant = ACCOUNT_STATUS;
  isExpiredLicense: boolean = false;
  filledIconTypes = FILLED_ICON_TYPE;
  private unsubscribe$ = new Subject<void>();
  hasAgentLogged: boolean = false;

  constructor(
    private fffB2BUnitService: FffB2BUnitService,
    public cdRef: ChangeDetectorRef,
    private windowRef: WindowRef,
    private modalService: NgbModal,
    public translationService: TranslationService,
    private occEndpointService: OccEndpointsService,
    private csAgentAuthService: CsAgentAuthService
  ) {}

  ngOnInit(): void {
    if (!this.getLicense() || '' === this.getLicense()) {
      this.csosStatus = this.constant.WITHOUT_LICENSE;
    } else if (!this.selected.tradePartnerCreated) {
      this.csosStatus = this.constant.FIRST_USER_IN_ACCOUNT;
    } else {
      if (
        !this.user.tradePartnerUserName ||
        '' === this.user.tradePartnerUserName
      ) {
        this.csosStatus = this.constant.USER_NEED_VERIFICATION;
      } else {
        this.csosStatus = this.constant.ACCOUNT_HAS_TRADE_PARTNER;
        this.isExpiredLicense = FFFCustomValidators.validateLicense(this.selected);
      }
    }

    this.csAgentAuthService
      .isCustomerSupportAgentLoggedIn()
      .pipe(
        takeUntil(this.unsubscribe$),
        tap((isCustomerSupportAgentLoggedIn: any) => {
          this.hasAgentLogged = !!isCustomerSupportAgentLoggedIn;
        })
      )
      .subscribe();
  }

  getLicenseType(): Observable<string> {
    if (this.selected?.deaLicense) {
      return this.translationService.translate(
        'fffAccount.fffProfileManagement.csos.deaLicense'
      );
    } else if (this.selected?.stateLicense) {
      return this.translationService.translate(
        'fffAccount.fffProfileManagement.csos.stateLicense'
      );
    }
    return of('');
  }

  getLicense(): string {
    if (this.selected?.deaLicense) {
      return this.selected?.deaLicense;
    } else if (this.selected?.stateLicense) {
      return this.selected?.stateLicense;
    }
    return '';
  }

  verifyAccount(): void {
    this.isUpdating.emit(true);
    const tradePartnerRequest = {
      companyName: this.selected.name,
      gpo: this.selected.gpo,
    };

    this.fffB2BUnitService
      .tradePartner(this.user.uid, this.selected.uid, tradePartnerRequest)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (response: any) => {
          if (response && response.isSuccess) {
            this.showConfirmationPopUp(FFFCsosVerifyAccountPopupComponent);
            this.removeChatBootFrame();
            this.setResult.emit(true);
            this.reloadAccount.emit();
          } else {
            this.setResult.emit(false);
          }
          this.isUpdating.emit(false);
        },
        () => {
          this.setResult.emit(false);
          this.isUpdating.emit(false);
        }
      );
  }

  verifyUserId(): void {
    this.isUpdating.emit(true);
    const tradePartnerRequest = {
      companyName: this.selected.name,
      gpo: this.selected.gpo,
    };

    this.fffB2BUnitService
      .tradePartner(this.user.uid, this.selected.uid, tradePartnerRequest)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (response: any) => {
          if (response && response.isSuccess) {
            this.showConfirmationPopUp(FFFCsosVerifyUserPopupComponent);
            this.removeChatBootFrame();
            this.setResult.emit(true);
            this.reloadAccount.emit();
          } else {
            this.setResult.emit(false);
          }
          this.isUpdating.emit(false);
        },
        () => {
          this.setResult.emit(false);
          this.isUpdating.emit(false);
        }
      );
  }

  showConfirmationPopUp(component: any): void {
    const modalRef = this.modalService.open(component, {
      centered: true,
      size: 'md',
      backdrop: 'static',
      modalDialogClass: 'modal-container',
    });

    modalRef.result.then(result => {
      window.location.reload();
    });
  }

  manageCertificate() {
    this.fffB2BUnitService
      .certificateLoginToken(this.user.uid, this.selected.uid)
      .subscribe(
        certificated => {
          const legisymData: FffLegisym = {
            url: environment.legisymGetToken.url,
            method: fffCSOSConfig.getToken.method,
            serviceContract: certificated.csosServiceContractToken,
            loginToken: certificated.csosLoginToken,
            action: fffCSOSConfig.actions.certStore.name,
            returnURL: this.occEndpointService.buildUrl(
              fffCSOSConfig.actions.certStore.redirectUrl
            ),
          };
          this.legisym$.next(legisymData);
          this.cdRef.detectChanges();
          this.sendForm();
        },
        error => console.log(error)
      );
  }

  private sendForm(): void {
    var myForm = <HTMLFormElement>document.getElementById('goExpress222Form');
    myForm.submit();
    this.removeChatBootFrame();
    this.reloadAccount.emit();
  }

  private removeChatBootFrame(): void {
    this.windowRef.document
      .querySelectorAll('.ie-div-position-customer-chat')
      .forEach(el => el.remove());
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
