<div class="drawerHeader">
  <div class="container-close-icon d-flex justify-content-between">
    <cx-icon class="cx-icon icon-lg" [type]="outlinedIconTypes.ICON_X" (click)="closeDrawer()"></cx-icon>
  </div>
  <div class="login d-flex justify-content-between body-text-lg">
    {{miniCart.greetings}} {{ userName }}
    <fff-change-account-selector [isMobile]="true"></fff-change-account-selector>
  </div>
  <div class="back-btn body-text-xl " (click)="closeDrawer()">
    <div class="back-text align-items-left d-flex">
      <cx-icon class="cx-icon" [type]="outlinedIconTypes.ICON_CHEVRON_LEFT"></cx-icon>
      {{ 'fffAccount.back' | cxTranslate }}
    </div>
  </div>
</div>