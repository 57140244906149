import {
  Injectable
} from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class FffGlobalCartRestrictionsService {
  private _globalRestriction$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  constructor() {}

  emitGlobalCartRestriction(value: boolean): void {
    this._globalRestriction$.next(value);
  }
  getGlobalRestriction(): Observable<boolean> {
    return this._globalRestriction$;
  }
}
