import {
  ChangeDetectorRef,
  Component,
  HostListener,
  OnDestroy,
  OnInit,
  QueryList,
  ViewChildren,
} from '@angular/core';
import { FffCommunicationService } from '@app/fff-enterprise/fff-common-services/fff-communication.service';
import { FFFActiveCartService } from '@app/fff-enterprise/fff-custom-cart/fff-active-cart-service';
import { FffPrebookCartService } from '@app/fff-enterprise/fff-prebook-category/services/fff-prebook-cart.service';
import { isPrebookEnabledCategory } from '@app/fff-enterprise/fff-prebook-category/services/fff-prebook-category.utils';
import { FffProduct } from '@app/models/fff-product.model';
import { FffProfile } from '@app/models/fff-profile.model';
import { FffApplicationConfigService } from '@app/shared/services/fff-application-config.service';
import { FffUserAccountService } from '@app/shared/services/fff-user-account.service';
import {
  AuthService,
  BaseSiteService,
  PageType,
  PaginationModel,
  Product,
  ProductSearchPage,
  ProductSearchService,
  ProductService,
  RoutingService,
  User,
} from '@spartacus/core';
import { ViewConfig, ViewModes } from '@spartacus/storefront';
import { UserAccountService } from '@spartacus/user/account/core';
import { DeviceDetectorService } from 'ngx-device-detector';
import {
  BehaviorSubject,
  Observable,
  Subject,
  Subscription,
  combineLatest,
  of,
} from 'rxjs';
import { filter, map, switchMap, take } from 'rxjs/operators';
import { FffProductListComponentService } from '../fff-product-list-component.service';
import { FffProductListItemComponent } from './fff-product-list-item/fff-product-list-item.component';

interface FffPlpProduct extends Product, FffProduct {}

interface FffProductSearchPage extends ProductSearchPage {
  products?: FffPlpProduct[];
}

@Component({
  selector: 'fff-product-list',
  templateUrl: './fff-product-list.component.html',
})
export class FffProductListComponent implements OnInit, OnDestroy {
  @ViewChildren(FffProductListItemComponent)
  productBoxes!: QueryList<FffProductListItemComponent>;
  private unsubscribe$: Subject<any> = new Subject<any>();
  isInfiniteScroll!: boolean;

  model: FffProductSearchPage | undefined;

  viewMode$ = new BehaviorSubject<ViewModes>(ViewModes.Grid);
  products: any;
  user: User | undefined;
  deviceInfo = {};
  isMobile = false;

  displayedProducts: any[] = [];
  productsToLoad: number = 3;
  currentIndex: number = 0;
  previousScrollTop: number = 0;
  isLoading: boolean = false;
  retryInProgress: boolean = false;

  profile$: Observable<FffProfile | undefined> =
    this.fffAccountService.getProfile();
  activeSite$ = this.baseSiteService.getActive();
  currentPage: number = 1;
  pageSize: number = 10;
  totalPages: number = 1;
  totalResults: number = 1;
  categoryDescription: any;

  isPrebookCategoryPage$ = this.routingService.getRouterState().pipe(
    filter(value => !!value.state?.context?.id),
    map(
      value =>
        value.state.context.type === PageType.CATEGORY_PAGE &&
        isPrebookEnabledCategory(value.state.queryParams)
    )
  );
  applicationProperties$ =
    this.applicationConfigService.getApplicationProperties();
  isPrebookFormEnabled$ = combineLatest([
    this.applicationProperties$.pipe(
      map(value => !!value?.mfvRespiratoryPrebookFormEnabled)
    ),
    this.isPrebookCategoryPage$,
  ]).pipe(
    map(
      ([mfvRespiratoryPrebookFormEnabled, isPrebookCategoryPage]) =>
        mfvRespiratoryPrebookFormEnabled && isPrebookCategoryPage
    )
  );
  showPagination$ = this.isPrebookFormEnabled$.pipe(map(enabled => !enabled));
  subs = new Subscription();
  userLoggedIn = false;
  constructor(
    private productListComponentService: FffProductListComponentService,
    protected productService: ProductService,
    public scrollConfig: ViewConfig,
    public userAccountService: UserAccountService,
    private deviceService: DeviceDetectorService,
    protected productSearchService: ProductSearchService,
    private baseSiteService: BaseSiteService,
    private fffAccountService: FffUserAccountService,
    private cdr: ChangeDetectorRef,
    private applicationConfigService: FffApplicationConfigService,
    private communicationService: FffCommunicationService,
    private routingService: RoutingService,
    private fffPrebookCartService: FffPrebookCartService,
    private cartService: FFFActiveCartService,
    private authService: AuthService
  ) {}

  ngOnInit(): void {
    // check if the user is logged in or not and set the var
    this.authService.isUserLoggedIn().subscribe(x => {
      this.userLoggedIn = x;
    });
    this.subs.add(
      this.productListComponentService.model$
        .pipe(
          switchMap(model => {
            if (!this.userLoggedIn) {
              // If the user isn't logged in return the list of products
              return of(model);
            }

            this.fffPrebookCartService.resetUpdatableEntries();

            return this.cartService.takeActive().pipe(
              take(1),
              map(cart => {
                // Modify the model based on the cart entries
                if (!cart?.code) {
                  return model;
                }

                let entries: any[] = cart?.entries || [];
                let modifiedModel = JSON.parse(JSON.stringify(model));
                let entriesMap: { [key: string]: any } = {};

                entries.forEach(e => {
                  if (e.product?.code) {
                    entriesMap[e.product.code] = e;
                  }
                });

                modifiedModel.products?.forEach((p: any) => {
                  if (p?.code && entriesMap[p.code]) {
                    p.quantity = entriesMap[p.code].quantity;
                    p.entryNumber = entriesMap[p.code].entryNumber;
                  }
                });

                return modifiedModel;
              }),
              switchMap(modifiedModel =>
                this.isPrebookFormEnabled$.pipe(
                  take(1), // Ensure we only take the latest value
                  map(enabled => (enabled ? modifiedModel : model))
                )
              )
            );
          })
        )
        .subscribe(model => {
          this.model = model;
          this.clearLoadedProducts();
          this.loadMoreProducts();
          if (this.model && this.model.pagination) {
            this.updatePaginationValues(this.model.pagination);
          }
          this.cdr.detectChanges();
        })
    );

    this.deviceInfo = this.deviceService.getDeviceInfo();
    this.isMobile =
      this.deviceService.isMobile() || this.deviceService.isTablet();
    this.isInfiniteScroll = this.scrollConfig?.view?.infiniteScroll?.active
      ? this.scrollConfig?.view?.infiniteScroll?.active
      : false;
    this.productListComponentService.clearResults();
    this.authService.isUserLoggedIn().subscribe(loggedIn => {
      if (loggedIn) {
        this.subs.add(
          this.applicationConfigService.loadApplicationProperties().subscribe()
        );
      }
    });
  }

  loadMoreProducts() {
    if (this.model?.products) {
      if (this.isLoading || this.currentIndex >= this.model.products.length) {
        return; // Prevent loading more if already loading or all products are loaded
      }

      this.isLoading = true;

      const newProducts = this.model.products.slice(
        this.currentIndex,
        this.currentIndex + this.productsToLoad
      );
      this.displayedProducts = this.displayedProducts.concat(newProducts);
      this.currentIndex += this.productsToLoad;

      this.isLoading = false;
    }
  }
  clearLoadedProducts() {
    this.displayedProducts = [];
    this.currentIndex = 0;
    this.isLoading = false;
    this.cdr.detectChanges();
  }

  trackByFn(index: number, item: any) {
    return item.id;
  }

  @HostListener('window:scroll', [])
  onScroll() {
    const scrollTop =
      window.pageYOffset ||
      document.documentElement.scrollTop ||
      document.body.scrollTop ||
      0;
    const windowHeight = window.innerHeight;
    const documentHeight = document.documentElement.scrollHeight;
    const isMobile = window.innerWidth < 768;
    const mobileThreshold = 1200;
    const desktopThreshold = 800;

    const threshold = isMobile ? mobileThreshold : desktopThreshold;
    const scrollPosition = scrollTop + windowHeight;

    // Detect scroll up
    if (scrollTop < this.previousScrollTop) {
      this.handleScrollUp();
    } else {
      // Check if the user has scrolled near the bottom (with threshold)
      if (scrollPosition + threshold >= documentHeight && !this.isLoading) {
        this.loadMoreProducts();
      }
    }

    // Update the previous scroll position
    this.previousScrollTop = scrollTop;
  }

  handleScrollUp() {
    if (!this.retryInProgress) {
      this.retryInProgress = true;
      this.retryVisibleFailedRequests().finally(() => {
        this.retryInProgress = false;
      });
    }
  }

  async retryVisibleFailedRequests() {
    for (const box of this.productBoxes.toArray()) {
      const element = box.elementRef.nativeElement; // Get the element reference of the corresponding product's component
      if (box.isProductAPIFailed && this.isInViewport(element)) {
        await box.getPricingInfo();
      }
    }
  }

  //method to check whether the product is in the viewport while scrolling up
  isInViewport(element: HTMLElement): boolean {
    const rect = element.getBoundingClientRect();
    return (
      rect.top >= 0 &&
      rect.left >= 0 &&
      rect.bottom <=
        (window.innerHeight || document.documentElement.clientHeight) &&
      rect.right <= (window.innerWidth || document.documentElement.clientWidth)
    );
  }

  toggleFilter(filterValue: boolean) {
    this.productListComponentService.toggleFilterDisplayInMobile(filterValue);
  }

  changePage(pageNumber: any): void {
    this.productListComponentService.getPageItems(pageNumber);
  }

  setViewMode(mode: ViewModes): void {
    this.viewMode$.next(mode);
  }

  updatePaginationValues(paginationModel: PaginationModel): void {
    this.currentPage = paginationModel.currentPage
      ? paginationModel.currentPage
      : 0;
    this.totalResults = paginationModel.totalResults
      ? paginationModel.totalResults
      : 1;
    this.pageSize = paginationModel.pageSize ? paginationModel.pageSize : 10;
    if (paginationModel.totalPages)
      this.totalPages = paginationModel.totalPages;
  }

  ngOnDestroy(): void {
    this.unsubscribe$.unsubscribe();
    this.subs.unsubscribe();
    this.clearLoadedProducts();
  }
}
