<div class="header-details p-4">
  <div class="row">
    <div class="col-lg-6 col-sm-12">
      <p class="mb-0">
        <span class="body-text-demi">{{'fffCheckout.accountNumber' | cxTranslate}}</span>&nbsp;
        <span>{{ accountNumber}}</span>
      </p>
      <p class="mb-0">
        <span class="body-text-demi">{{'fffCheckout.company' | cxTranslate}}</span>&nbsp;
        <span>{{ accountName }}</span>
      </p>
      <p class="mb-0">
        <span class="body-text-demi">{{'fffCheckout.emailAddress' | cxTranslate }}</span>&nbsp;
        <span>{{ email}}</span>
      </p>
    </div>

    <div class="col-lg-6 col-sm-12" *ngIf="!footer">
      <p class="mb-0">
        <span class="body-text-demi">{{'fffInvoiceHistoryDetail.contactName' | cxTranslate }}</span>&nbsp;
        <span>{{ contactName }}</span>
      </p>
      <p class="mb-0">
        <span class="body-text-demi">{{'fffAccount.fffRequestChangeAddress.phoneNumber' | cxTranslate }}</span>&nbsp;
        <span>{{ phoneNumber }}</span>
      </p>
    </div>

    <div class="col-lg-6 col-sm-12" *ngIf="footer">
      <p class="mb-0">
        <span class="body-text-demi">{{'fffCheckout.deliveryTerms' | cxTranslate }}</span>&nbsp;
        <span>{{ deliveryTerms }}</span>
      </p>
      <p class="mb-0">
        <span class="body-text-demi">{{'fffCheckout.paymentTerms' | cxTranslate }}</span>&nbsp;
        <span>{{ paymentTerms }}</span>
      </p>
    </div>
  </div>
  <ng-container *ngIf="footer">
    <ng-container *ngTemplateOutlet="footer"></ng-container>
  </ng-container>
</div>
