import { Injectable } from "@angular/core";
import { FFFOrderEntry } from "@app/models/fff-cart-data.model";
import { CartEntryConnector } from "@spartacus/cart/base/core";
import { CartModification } from "@spartacus/cart/base/root";
import { Observable } from "rxjs";
import { FFFCartEntryAdapter } from "./fff-cart-entry.adapter";

@Injectable({
  providedIn: 'root',
})
export class FFFCartEntryConnector extends CartEntryConnector {

  constructor(protected fffAdapter: FFFCartEntryAdapter) {
    super(fffAdapter);
  }

  public add(
    userId: string,
    cartId: string,
    productCode: string,
    quantity: number,
    pickupStore?: string,
    orderEntries?: FFFOrderEntry[]
  ): Observable<CartModification> {
    if (orderEntries && orderEntries.length > 0) {
      return this.fffAdapter.addFFF(userId, cartId, orderEntries);
    } else {
      return this.fffAdapter.add(userId, cartId, productCode, quantity);
    }
  }

}
