import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FffMediaModule } from '@app/shared/media/fff-media.module';
import { CmsConfig, ConfigModule } from '@spartacus/core';
import {
  GenericLinkModule,
  IconModule,
  MediaModule,
  OutletPosition,
  provideOutlet,
  SpinnerModule,
} from '@spartacus/storefront';
import { FffSimpleResponsiveCardComponent } from './fff-simple-responsive-card/fff-simple-responsive-card.component';
import { FffSimpleResponsiveSpacerComponent } from './fff-simple-responsive-spacer/fff-simple-responsive-spacer.component';

@NgModule({
  declarations: [
    FffSimpleResponsiveCardComponent,
    FffSimpleResponsiveSpacerComponent,
  ],
  imports: [
    CommonModule,
    IconModule,
    SpinnerModule,
    ConfigModule.withConfig({
      cmsComponents: {
        SimpleResponsiveBannerComponent: {
          component: FffSimpleResponsiveCardComponent,
        },
        BannerComponent: {
          component: FffSimpleResponsiveCardComponent,
        },
      },
    } as CmsConfig),
    GenericLinkModule,
    FffMediaModule,
    MediaModule,
  ],
  providers: [
    provideOutlet({
      id: 'Section1',
      position: OutletPosition.BEFORE,
      component: FffSimpleResponsiveSpacerComponent,
    }),
    provideOutlet({
      id: 'Section1',
      position: OutletPosition.AFTER,
      component: FffSimpleResponsiveSpacerComponent,
    }),
  ],
})
export class FffSimpleResponsiveModule {}
