<div
  class="csos-status-table row col-12 d-flex justify-content-center align-items-center m-auto px-0"
>
  <h3>CSOS Status Visibility</h3>
  <table class="table table-hover">
    <thead class="text-center">
      <tr>
        <th scope="col">Order Number</th>
        <th scope="col">Customer Number</th>
        <th scope="col">Date Ordered</th>
        <th scope="col">Tracking ID</th>
        <th scope="col">CSOS ID</th>
        <th scope="col">Date Signed</th>
      </tr>
    </thead>
    <tbody class="text-center">
      <tr *ngFor="let data of csosData">
        <th scope="row">{{ data?.orderCode }}</th>
        <td>{{ data?.customer }}</td>
        <td>{{ data?.orderDate }}</td>
        <td>{{ data?.trackingId }}</td>
        <td>{{ data?.csosId }}</td>
        <td>{{ data?.signedDate ? data?.signedDate : 'Not Signed' }}</td>
      </tr>
    </tbody>
  </table>
</div>
