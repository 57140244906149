import { Directive, ElementRef, EventEmitter, HostListener, Output } from '@angular/core';

@Directive({
  selector: '[fffDrawerClickOutside]',
})
export class FffDrawerClickOutsideDirective {

  @Output() fffDrawerClickOutside = new EventEmitter<void>();

  constructor(private elementRef: ElementRef) {
  }

  @HostListener('document:click', ['$event.target'])
  public onClick(target: any) {
    const clickedInside = this.elementRef?.nativeElement?.contains(target);
    if (!clickedInside && document?.contains(target)) {
      this.fffDrawerClickOutside.emit();
    }
  }
}
