import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { NgbCollapseModule } from '@ng-bootstrap/ng-bootstrap';
import {
  I18nModule,
  OccConfig,
  UrlModule,
  provideConfig,
} from '@spartacus/core';
import {
  IconModule,
  ItemCounterModule,
  MediaModule,
} from '@spartacus/storefront';
import { FffProductAdminSetYConnectorComponent } from './fff-product-admin-set-y-connector/fff-product-admin-set-y-connector.component';
import { FffProductDetailsComponent } from './fff-product-details/fff-product-details.component';
import { FffProductStockStatusComponent } from './fff-product-stock-status/fff-product-stock-status.component';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FffTooltipModule } from '@app/shared/tooltip/tooltip.module';
import { FffAddToCartNoticeComponent } from '@enterprise/fff-product/fff-add-to-cart-notice/fff-add-to-cart-notice.component';
import { FffProductMoreInfoModule } from '@enterprise/fff-product/fff-product-more-info/fff-product-more-info.module';
import { FffProductAddToCartComponent } from './fff-add-to-cart/fff-add-to-cart.component';
import { FffProductActualStrengthComponent } from './fff-product-actual-strength/fff-product-actual-strength.component';
import { FffProductNotOrderableComponent } from './fff-product-not-orderable/fff-product-not-orderable.component';
import { FffProductShortDatedPopupComponent } from './fff-product-short-dated-popup/fff-product-short-dated-popup.component';

@NgModule({
  declarations: [
    FffProductStockStatusComponent,
    FffProductDetailsComponent,
    FffProductAdminSetYConnectorComponent,
    FffProductAddToCartComponent,
    FffProductActualStrengthComponent,
    FffProductShortDatedPopupComponent,
    FffAddToCartNoticeComponent,
    FffProductNotOrderableComponent,
  ],
  imports: [
    UrlModule,
    BrowserModule,
    CommonModule,
    I18nModule,
    MediaModule,
    IconModule,
    NgbCollapseModule,
    RouterModule,
    ItemCounterModule,
    ReactiveFormsModule,
    FormsModule,
    FffTooltipModule,
    FffProductMoreInfoModule,
  ],
  exports: [
    FffProductStockStatusComponent,
    FffProductDetailsComponent,
    FffProductAdminSetYConnectorComponent,
    FffProductAddToCartComponent,
    FffProductActualStrengthComponent,
    FffProductShortDatedPopupComponent,
  ],
  providers: [
    provideConfig({
      backend: {
        occ: {
          endpoints: {
            product: {
              details:
                'products/${productCode}?fields=averageRating,stock(DEFAULT),description,availableForPickup,code,url,price(DEFAULT),numberOfReviews,manufacturer,categories(FULL),priceRange,multidimensional,tags,images(FULL),showAsSoldOutIfExcluded',
            },
            productSearch:
              'products/search?fields=products(code,name,summary,ndc,configurable,configuratorType,multidimensional,price(FULL),images(DEFAULT),stock(FULL),averageRating,variantOptions),facets,breadcrumbs,pagination(DEFAULT),sorts(DEFAULT),freeTextSearch,currentQuery,keywordRedirectUrl',
          },
        },
      },
    } as OccConfig),
  ],
})
export class FffProductModule {}
