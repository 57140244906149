import { NgModule } from '@angular/core';
import {
  FffAccountGlobalRestrictionsCheckerComponent,
} from '@enterprise/fff-header/fff-account-global-restrictions/fff-account-global-restrictions-checker.component';




@NgModule({
  declarations: [FffAccountGlobalRestrictionsCheckerComponent],
  exports: [FffAccountGlobalRestrictionsCheckerComponent],
})
export class FffAccountGlobalRestrictionsCheckerModule { }
