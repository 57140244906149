import { Component } from '@angular/core';
import { ProductSearchService, RoutingService, TranslationService } from '@spartacus/core';
import { combineLatest, Observable } from 'rxjs';
import { filter, map, mergeMap, switchMap } from 'rxjs/operators';
import { CUSTOM_ICON_TYPE } from 'src/app/models/fff-custom-icons.model';

@Component({
  selector: 'fff-empty-list',
  templateUrl: './fff-empty-list.component.html',
})
export class FffEmptyListComponent {

  customIconTypes = CUSTOM_ICON_TYPE;

  total$: Observable<number | undefined> = this.productSearchService
    .getResults()
    .pipe(
      filter((data) => !!data?.pagination),
      map((results) => results.pagination?.totalResults)
    );

  protected query$: Observable<string> = this.routingService
    .getRouterState()
    .pipe(map((state) => state.state.params['query']));

  constructor(
    protected routingService: RoutingService,
    protected translation: TranslationService,
    protected productSearchService: ProductSearchService,
  ) { }

  resolveMessage(): Observable<string> {
    const sources = [this.query$];
    return combineLatest(sources).pipe(
      switchMap(([query]) =>
        this.translation
          .translate('fffSearchEmptyListPage.header')
          .pipe(
            mergeMap((defaultQuery) =>
              this.translation.translate('fffSearchEmptyListPage.header', {
                query: query || defaultQuery,
              })
            )
          )
      )
    );
  }

  goToHomePage(): void {
    this.routingService.go('/');
  }

}
