import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CmsConfig, I18nModule, provideConfig } from '@spartacus/core';
import {
  IconModule,
  OutletPosition,
  provideOutlet,
} from '@spartacus/storefront';
import { FffPrebookAcipRecommendationComponent } from './fff-prebook-acip-recommendation/fff-prebook-acip-recommendation.component';
import { FffPrebookCheckoutRecommendationComponent } from './fff-prebook-checkout-recommendation/fff-prebook-checkout-recommendation.component';
import { FffPrebookExitConfirmationModalComponent } from './fff-prebook-exit-confirmation-modal/fff-prebook-exit-confirmation-modal.component';
import { FffPrebookHeaderModule } from './fff-prebook-header/fff-prebook-header.module';
import { FffPrebookSeasonOverviewComponent } from './fff-prebook-season-overview/fff-prebook-season-overview.component';
import { FffPrebookSiteLogoComponent } from './fff-prebook-site-logo/fff-prebook-site-logo.component';
import { FffPrebookTitleComponent } from './fff-prebook-title/fff-prebook-title.component';

@NgModule({
  declarations: [
    FffPrebookTitleComponent,
    FffPrebookSeasonOverviewComponent,
    FffPrebookAcipRecommendationComponent,
    FffPrebookCheckoutRecommendationComponent,
    FffPrebookExitConfirmationModalComponent,
    FffPrebookSiteLogoComponent,
  ],
  imports: [CommonModule, FffPrebookHeaderModule, I18nModule, IconModule],
  exports: [FffPrebookCheckoutRecommendationComponent],
  providers: [
    provideConfig(<CmsConfig>{
      cmsComponents: {
        // MFVPrebookFluTitleComponent: {
        //   component: FffPrebookTitleComponent,
        // },
        MFVPrebookFluRecommendationComponent: {
          component: FffPrebookSeasonOverviewComponent,
        },
        MFVPrebookCovidRecommendationComponent: {
          component: FffPrebookSeasonOverviewComponent,
        },
        MFVPrebookRSVRecommendationComponent: {
          component: FffPrebookSeasonOverviewComponent,
        },
        MFVPrebookAcipWrapperComponent: {
          component: FffPrebookAcipRecommendationComponent,
        },
      },
    }),
    provideOutlet({
      id: 'SiteLogo',
      component: FffPrebookSiteLogoComponent,
      position: OutletPosition.BEFORE,
    }),
  ],
})
export class FffPrebookCategoryModule {}
