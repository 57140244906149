export const fffLogin = {
  fffLogin: {
    loginForm: {
      emailAddress: 'Email Address',
    },
    header: 'Login to Order',
    login: 'Login',
    needBioSupply: 'Need a BioSupply Account? ',
    register: ' Register here',
    disabledUserMsg: 'Too many login attempts, your account has been locked.',
    liveChatMsg: 'Live chat with Wow! Customer Care',
    callMsg: 'or call (800) 843-7477 to unlock your account.',
  },
};
export const FFF_MINICART = {
  FFF_DATA: {
    login: 'Login',
    register: 'Register',
    myAccount: 'My Account',
    greetings: 'Welcome, ',
  },
};
export const fffRegister = {
  fffRegister: {
    register: {
      firstName: 'First Name',
      lastName: 'Last Name',
      emailAddress: 'Email',
      phone: 'Phone',
      placeholder: {
        firstName: 'First Name',
        lastName: 'Last Name',
        emailAddress: 'Email',
        phone: 'Phone',
      },
      password: {
        label: 'Create Password',
        placeholder: 'Password',
      },
      confirmPassword: {
        label: 'Confirm Password',
        placeholder: 'Confirm Password',
      },
    },
    header: 'Register an Account',
    accNumber:
      'FFF Account Number <strong><i>(Please exclude A,B or W when entering your account number)</i></strong>',
    needAccount: 'Need an FFF account?',
    bioSupplyLogin: 'Need a BioSupply Login?',
    mfvSupplyLogin: 'Need a MyFluVaccine Login?',
    actions: `<span class="text-center">
                <p class="mb-0">
                  <a class="download-form-account cursor-pointer text-underline">Download the new account form</a>, fill and email to
                </p>
                <p class="my-0">
                  <a class="send-email cursor-pointer text-underline">salesopspecialty@fffenterprises.com</a> or fax to (951) 240-4504
                </p>
              </span>`,
    phoneNumber: 'Phone Number',
    passwordInstructions: `<span>Password must: </span>
                              <ul>
                                <li>Be a minimum of 8 characters</li>
                                <li>Include at least one uppercase letter (A-Z)</li>
                                <li>Include at least one number (0-9)</li>
                                <li>Include at least one special character</li>
                              </ul> `,
    createAccount: 'Create Login',
    alreadyRegistered: 'Already Registered? ',
    loginHere: ' Login Here',
    reset: {
      header: 'Reset Password',
      newPassword: {
        label: 'Create New Password',
        placeHolder: 'New Password',
      },
      confirmPassword: {
        label: 'Confirm New Password',
        placeHolder: 'Confirm New Password',
      },
      passwordRules: `<span>Password must: </span>
                              <ul>
                                <li>Be a minimum of 8 characters</li>
                                <li>Include at least one lowercase letter (a-z)</li>
                                <li>Include at least one uppercase letter (A-Z)</li>
                                <li>Include at least one number (0-9)</li>
                                <li>Include at least one special character</li>
                              </ul> `,
      submit: 'Submit',
    },
  },
};
export const fffVerification = {
  fffVerification: {
    header: 'Verification Link Sent to Your Email',
    text1: `<p>We've sent an account verification link to your email address.</p>
            <p>To complete registration, please click on the link given in the email.</p>`,
    text2: `<p>If you didn't receive the email, check your spam folder,</p>
            <p>or <a class="resent-email cursor-pointer text-underline text-white">click here</a> to resend the verification email.</p>`,
  },
};

export const fffConfirmation = {
  fffConfirmation: {
    header: 'Thank You!',
    inValidUserHeader: 'Sorry!',
    linkText: `We've verified your email address.
   <p>You will receive a follow-up email indicating your account is active once confirmed by an FFF Enterprises administrator.</p>`,
    inValidUserLinkText:
      '<p>Unfortunately your account details could not be validated, please register again or call customer service.</p>',
  },
};

export const fffRegisterConfirmation = {
  fffRegisterConfirmation: {
    header: 'Thank You!',
    linkText: `<p class="pb-4">We've verified your email address.</p>
               <p>Your account is now successfully set up.</p>
               <p>Please log in with the credentials you provided.  Thank you!</p>`,
  },
};

export const fffTranslationOverwrites = {
  en: {
    common: {
      formErrors: {
        cxInvalidPersonName: 'Use letters A-Z only',
        cxInvalidPhoneNumber:
          'Invalid number, please input a valid phone number',
        cxInvalidPass: 'This is not a valid password format',
        minlength: ' This is not a valid password format',
        invalidAccountNumber:
          'The account you entered is not a valid account. Please try again or if you would like to setup a account with FFF click on the new account form above',
        invalidFFFAccount:
          'The account number entered was a Payer Account only. Please enter your FFF Sold to account number.',
        abwMessage:
          'Please exclude A,B or W at the end of your account number.  Please try again or if you would like to setup an account with FFF click on the new account form above',
        loginError: 'Invalid username or password',
        emailAlreadyExists: 'Email address already exists. Please try again.',
        errorRegisterUser:
          'Error during user registration. {{validationFails}}',
        currentPasswordInvalid: 'Current password entered is not valid',
        errorSaveCart: 'Error saving the Cart.',
        errorCloneCart: 'Error saving the Cart with same items.',
        pattern: 'Wrong PO# formatting',
        maxLength: 'Maximum PO# length exceeded',
        ngbDate: 'Invalid date',
        invalidEmail: 'Invalid email formatting.',
        maxFourEmail: 'No more than 4 emails.',
        accountNumber: {
          pattern: 'This is not a valid account number format.',
        },
      },
      httpHandlers: {
        CustomerAlreadyExistsError: 'Customer Already Exists',
        genericError: 'Something went wrong, please try again later.',
      },
    },
  },
};

export const fffFirstLoginPopup = {
  fffFirstLoginPopup: {
    header: 'First Login',
    message: `<p>Thank you for choosing BioSupply / MyFluVaccine!</p>
    <p>To place product orders on our upgraded website, you must first reset your password.</p>
    <p> Please click on the Go to Forgot Password button below and enter your email address.
    You will receive an email with a link to reset your password.</p>
    <p>You may use the same password for BioSupply and MyFluVaccine.</p>`,
    action: 'Go to Forgot Password',
  },
};
