import {
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import {
  PRODUCT_FEATURE,
  StateWithProduct,
  User,
  isNotUndefined,
} from '@spartacus/core';
import { Observable, Subscription } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { FffProductListComponentService } from '../../fff-product-list-component.service';

@Component({
  selector: 'fff-product-sort',
  templateUrl: './fff-product-sort.component.html',
})
export class FffProductSortComponent implements OnInit, OnDestroy {
  updatedSortValues: any;
  sortOption!: string;
  @Input()
  user: User | undefined;
  model$: Observable<any> = this.store.select(PRODUCT_FEATURE);
  subs = new Subscription();

  constructor(
    private productListComponentService: FffProductListComponentService,
    private activatedRoute: ActivatedRoute,
    private store: Store<StateWithProduct>,
    private cdRef: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.model$
      .pipe(
        map(model => {
          return model?.search?.results?.sorts ? model : undefined;
        }),
        filter(isNotUndefined)
      )
      .subscribe(model => {
        let page = model?.search?.results;
        this.updatedSortValues = page?.sorts?.filter(
          (item: any) => item.code !== 'topRated'
        );
        if (!this.user) {
          this.updatedSortValues = this.updatedSortValues?.filter(
            (item: { code: any }) => !item.code.includes('price')
          );
        }

        this.handleInitialSortState();
      });
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  sortList(sortCode: string): void {
    this.productListComponentService.sort(sortCode);
  }

  handleInitialSortState() {
    const sortCode = this.updatedSortValues?.find(
      (item: any) => !!item.selected
    )?.code;
    this.sortOption = sortCode || 'relevance';
  }
}
