<div class="input-checkbox-list d-flex">
  <div
    class="input-checkbox-list__option"
    *ngFor="let item of items; let i = index"
  >
    <div class="mr-2 position-relative overflow-hidden">
      <input
        class="checkbox checkbox-sm"
        type="checkbox"
        [attr.name]="inputName"
        [checked]="item.selected"
        value="{{ item.value }}"
        [attr.id]="inputId + item.id + i"
        [disabled]="disabled"
        (change)="onToggle(item)"
      />
      <label
        class="checkbox-label d-flex"
        [attr.for]="inputId + item.id + i"
        [innerHTML]="
          item?.labelI18nKey ? (item.labelI18nKey | cxTranslate) : item?.label
        "
      ></label>
    </div>
  </div>
</div>
