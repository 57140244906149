<div class="container">
  <div>
    <div class="d-flex">
      <h3 class="pr-4">
        {{
          'fffCheckoutOrderConfirmation.recurringOrder.recurringOrders'
            | cxTranslate
        }}
      </h3>
    </div>
  </div>
  <hr class="sep-table-title" />
  <div *ngIf="recurringOrders$ | async as recurringOrder; else loadingTemplate">
    <ng-container
      *ngIf="
        recurringOrder.replenishmentOrders &&
          recurringOrder.replenishmentOrders.length > 0;
        else emptyMessage
      "
    >
      <fff-table
        [hasPaginator]="true"
        [data]="{
          data: recurringOrder.replenishmentOrders,
          total: recurringOrder.pagination.totalResults,
          loading: false,
          current: recurringOrder.pagination.currentPage
        }"
        [fields]="fields"
        [hasTemplate]="true"
        [mobileSize]="mobileSize"
        [columnsGrid]="columnsGrid"
        [itemTemplate]="returnsTemplate"
        (pageChange)="pageChange($event)"
        [customClass]="'user-price-list'"
        [size]="
          recurringOrder.pagination && recurringOrder.pagination.pageSize
            ? recurringOrder.pagination.pageSize
            : defaultPageSize
        "
      >
      </fff-table>
      <ng-template #returnsTemplate let-item="item">
        <div class="recurring-order-history-item td">
          <div class="px-0 col-6 col-lg-12 order-0">
            <div class="col-12 px-0 d-block d-lg-none pb-2 pt-3">
              {{
                'fffCheckoutOrderConfirmation.recurringOrder.recurringOrderId'
                  | cxTranslate
              }}
            </div>
            <span
              class="desktop-item-code cursor-pointer text-underline pr-2"
              (click)="viewRecurringOrderDetails(item.code)"
            >
              {{ item.code }}
              <cx-icon
                class="cx-icon icon-sm px-1 position-absolute"
                [type]="outlinedIconTypes.ICON_CHEVRON_RIGHT"
              ></cx-icon>
            </span>
          </div>
          <div class="px-0 col-6 col-lg-12 order-1 order-lg-1">
            <div class="col-12 px-0 d-block d-lg-none pb-2 pt-3">
              {{ 'fffReturns.poNumber' | cxTranslate }}
            </div>
            <div class="pr-2">{{ item.purchaseOrderNumber }}</div>
          </div>
          <div class="px-0 col-6 col-lg-12 order-2 order-lg-1 text-break">
            <div class="col-12 px-0 d-block d-lg-none pb-2 pt-3">
              {{ 'fffReturns.email' | cxTranslate }}
            </div>
            <div class="pr-2">{{ item.user.uid }}</div>
          </div>
          <div class="px-0 col-12 col-lg-12 order-3 order-lg-1">
            <div class="col-12 px-0 d-block d-lg-none pb-2 pt-3">
              {{
                'fffCheckoutOrderConfirmation.recurringOrder.orderStartDate'
                  | cxTranslate
              }}
            </div>
            <div class="pr-2">{{ item.firstDate | date : 'MM/dd/yyyy' }}</div>
          </div>
          <div class="px-0 col-6 col-lg-12 order-4 order-lg-1">
            <div class="col-12 px-0 d-block d-lg-none pb-2 pt-3">
              {{
                'fffCheckoutOrderConfirmation.recurringOrder.schedule'
                  | cxTranslate
              }}
            </div>
            <div class="pr-2">
              {{ formatTimeInSchedule(item.trigger?.displayTimeTable) }}
            </div>
          </div>
          <div class="px-0 col-6 col-lg-12 order-5 order-lg-1">
            <div class="col-12 px-0 d-block d-lg-none pb-2 pt-3">
              {{
                'fffCheckoutOrderConfirmation.recurringOrder.nextOrderDate'
                  | cxTranslate
              }}
            </div>
            <div class="pr-2">
              {{ item.trigger.activationTime | date : 'MM/dd/yyyy' }}
            </div>
          </div>

          <div class="col-12 order-6 order-lg-1 d-lg-flex pl-0">
            <div class="status-column pr-lg-2">
              <div class="status-container col-12 px-0">
                <div
                  class="view-button-container cursor-pointer d-none d-lg-block"
                  (click)="
                    skipOrCancelRecurringOrder(
                      item.code,
                      true,
                      item?.trigger?.activationTime
                    )
                  "
                >
                  <span class="action-label text-underline">{{
                    'fffCheckoutOrderConfirmation.recurringOrder.skip'
                      | cxTranslate
                  }}</span>
                </div>
                <div class="mobile-view-button-container d-lg-none mt-4">
                  <button
                    type="button"
                    class="btn btn-tertiary w-100"
                    (click)="
                      skipOrCancelRecurringOrder(
                        item.code,
                        true,
                        item?.trigger?.activationTime
                      )
                    "
                  >
                    <span class="action-label">{{
                      'fffCheckoutOrderConfirmation.recurringOrder.skip'
                        | cxTranslate
                    }}</span>
                  </button>
                </div>
              </div>
            </div>
            <div class="status-column pr-lg-2">
              <div class="status-container col-12 px-0">
                <div
                  class="view-button-container cursor-pointer d-lg-block d-none"
                  (click)="editRecurringOrder(item.code)"
                >
                  <span class="action-label text-underline">{{
                    'fffSaveCart.actions.edit' | cxTranslate
                  }}</span>
                </div>
                <div class="mobile-view-button-container d-lg-none mt-2">
                  <button
                    type="button"
                    class="btn btn-tertiary w-100"
                    (click)="editRecurringOrder(item.code)"
                  >
                    <span class="action-label">{{
                      'fffSaveCart.actions.edit' | cxTranslate
                    }}</span>
                  </button>
                </div>
              </div>
            </div>
            <div class="status-column">
              <div class="status-container col-12 px-0">
                <div
                  class="view-button-container cursor-pointer d-lg-block d-none"
                  (click)="skipOrCancelRecurringOrder(item.code, false)"
                >
                  <span class="action-label text-underline">{{
                    'fffSaveCart.actions.cancel' | cxTranslate
                  }}</span>
                </div>
                <div class="mobile-view-button-container d-lg-none mt-2">
                  <button
                    type="button"
                    class="btn btn-tertiary w-100"
                    (click)="skipOrCancelRecurringOrder(item.code, false)"
                  >
                    <span class="action-label">{{
                      'fffSaveCart.actions.cancel' | cxTranslate
                    }}</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="px-0 col-6 col-lg-12 order-7 order-lg-1">
            <div class="col-12 px-0 d-block d-lg-none pb-2 pt-3">
              {{
                'fffCheckoutOrderConfirmation.recurringOrder.status'
                  | cxTranslate
              }}
            </div>
            <div
              class="d-flex statusIcon cursor-pointer"
              (click)="
                activateOrInactivateRecurringOrder(item.code, !item.active)
              "
            >
              <div
                [ngClass]="{
                'activeBtn': item.active,
                'inactiveBtn': !item.active,
              }"
              ></div>
              <div
                [ngClass]="{
                'activeText': item.active,
                'inactiveText': !item.active,
              }"
              >
                {{
                  item.active
                    ? ('fffCheckoutOrderConfirmation.recurringOrder.resumed'
                      | cxTranslate)
                    : ('fffCheckoutOrderConfirmation.recurringOrder.paused'
                      | cxTranslate)
                }}
              </div>
            </div>
          </div>
        </div>
      </ng-template>
    </ng-container>

    <ng-template #emptyMessage>
      <div class="text-center mt-5">
        <h4 class="h4 mb-4">
          {{
            'fffCheckoutOrderConfirmation.recurringOrder.noRecurringOrders'
              | cxTranslate
          }}
        </h4>
        <button class="btn btn-primary m-auto" (click)="goToHomePage()">
          {{ 'fffSavedCarts.goToHomePage' | cxTranslate }}
        </button>
      </div>
    </ng-template>
  </div>
</div>
<ng-template #loadingTemplate>
  <fff-table-loader [fields]="fields"></fff-table-loader>
</ng-template>
