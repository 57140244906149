import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FffSplideSlideComponent } from './splide-slide/splide-slide.component';
import { FffSplideComponent } from './splide/splide.component';

@NgModule({
  declarations: [FffSplideComponent, FffSplideSlideComponent],
  imports: [CommonModule],
  exports: [FffSplideComponent, FffSplideSlideComponent],
})
export class FffSplideModule {}
