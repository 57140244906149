import { Component, OnInit } from '@angular/core';
import { FffUserAccountService } from '@app/shared/services/fff-user-account.service';
import { FFFCustomValidators } from '@app/shared/validators/fff-custom-validators';
import { BASE_URL_KEYS } from '@config/content/constants';
import { AuthService, BaseSiteService, CmsService } from '@spartacus/core';
import { NavigationNode, NavigationService } from '@spartacus/storefront';
import { take } from 'rxjs/operators';
import { OUTLINED_ICON_TYPE } from 'src/app/models/fff-outline-icons.model';
import { FffDrawerService } from 'src/app/shared/drawer/fff-drawer.service';
import { FffCommunicationService } from '../fff-common-services/fff-communication.service';

@Component({
  selector: 'fff-navigation-accordion',
  templateUrl: './fff-navigation-accordion.component.html',
})
export class FffNavigationAccordionComponent implements OnInit {
  public items!: NavigationNode;
  public child: NavigationNode | undefined;
  public footer!: NavigationNode;
  public outlinedIconTypes = OUTLINED_ICON_TYPE;
  public myAccount!: NavigationNode;
  userLoggedIn: boolean | undefined;
  activeSite: string = '';
  bioSupplyLink: any;
  mfvLink: any;
  fFFEnterprisesLink: any;
  BASE_URL_KEYS = BASE_URL_KEYS;
  notifications: any;
  componentData: any = {
    biosupply: 'BiosupplyCategoryNavComponent',
    mfv: 'MFVCategoryNavComponent',
  };
  constructor(
    private auth: AuthService,
    protected navigationService: NavigationService,
    protected cmsService: CmsService,
    private drawerService: FffDrawerService,
    private baseSiteService: BaseSiteService,
    private communicationService: FffCommunicationService,
    private fffUserAccountService: FffUserAccountService
  ) {}

  ngOnInit(): void {
    this.baseSiteService
      .getActive()
      .pipe(take(1))
      .subscribe(res => {
        this.activeSite = res;
        this.navigationService
          .getNavigationNode(
            this.cmsService.getComponentData(
              this.componentData[this.activeSite]
            )
          )
          .pipe(take(1))
          .subscribe(data => {
            this.items = data;
          });
      });

    this.navigationService
      .getNavigationNode(
        this.cmsService.getComponentData('FFFUtilityNavComponent')
      )
      .pipe(take(1))
      .subscribe(data => {
        this.footer = data;
      });

    this.navigationService
      .getNavigationNode(this.cmsService.getComponentData('MyAccountComponent'))
      .pipe(take(1))
      .subscribe(data => {
        this.myAccount = data;
        this.filterItems();
      });

    this.cmsService
      .getComponentData('BioSupplyLink')
      .pipe(take(1))
      .subscribe(data => {
        this.bioSupplyLink = data;
      });

    this.cmsService
      .getComponentData('MyFluVaccineLink')
      .pipe(take(1))
      .subscribe(data => {
        this.mfvLink = data;
      });

    this.cmsService
      .getComponentData('FFFEnterprisesLink')
      .pipe(take(1))
      .subscribe(data => {
        this.fFFEnterprisesLink = data;
      });

    this.auth
      .isUserLoggedIn()
      .pipe(take(1))
      .subscribe(data => {
        this.userLoggedIn = data;
        if (this.userLoggedIn)
          this.communicationService
            .getNotifications()
            .subscribe(notifications => {
              this.notifications = notifications;
            });
      });
  }

  filterItems() {
    this.fffUserAccountService.getProfile().subscribe((profile: any) => {
      let isApprover = profile?.user.roles?.includes('b2bapprovergroup');

      if (!isApprover) {
        this.filterCategory('Approvals');
      }

      if (
        !FFFCustomValidators.validateLicense(profile?.selected) &&
        !profile?.selected?.deaLicense &&
        new Date(profile?.selected?.deaExpiryDate ?? null) < new Date()
      ) {
        this.filterCategory('CSOS');
      }
    });
  }

  filterCategory(category: string) {
    if (!this.myAccount.children) {
      return;
    }
    this.myAccount.children = this.myAccount.children?.filter(
      e => e.title !== category
    );
  }

  openSubMenu(node?: NavigationNode): void {
    this.child = node;
  }

  closeDrawer() {
    this.drawerService.closeDrawer();
  }

  back() {
    this.child = undefined;
  }

  fetchMedals(nodeTitle: any, notifications: any): number {
    if (nodeTitle === 'My Account') {
      return notifications?.totalCount ?? 0;
    }

    if (nodeTitle === 'Approvals') {
      return notifications?.approvalRequestCount ?? 0;
    }

    if (nodeTitle === 'CSOS') {
      return notifications?.csosRequestCount ?? 0;
    }

    return 0;
  }
}
