import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { OccEndpointsService } from '@spartacus/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class FffPrebookService {
  private seasonDataSubject = new BehaviorSubject<string>('');
  private previousSeasonDataSubject = new BehaviorSubject<string>('');

  constructor(
    private http: HttpClient,
    protected occEndpoints: OccEndpointsService,
    private activatedRoute: ActivatedRoute
  ) {}

  getAppProperties(): void {
    const url = this.occEndpoints.buildUrl(`applicationProperties`);
    this.http.get(url, { headers: this.getRequestHeaders() }).subscribe(
      res => {
        const appProperties: any = res;
        if (
          appProperties &&
          appProperties.mfvPrebookCurrentSeason &&
          parseInt(appProperties.mfvPrebookCurrentSeason, 10)
        ) {
          const seasonYear = parseInt(
            appProperties.mfvPrebookCurrentSeason,
            10
          );
          const yearRange = `${seasonYear - 1}-${seasonYear}`;
          this.seasonDataSubject.next(yearRange);
          const prevYearRange = `${seasonYear - 2}-${seasonYear - 1}`;
          this.previousSeasonDataSubject.next(prevYearRange);
        }
      },
      error => {
        console.error('Failed to application properties', error);
      }
    );
  }

  getApplicationProperties(): Observable<any> {
    const url = this.occEndpoints.buildUrl(`applicationProperties`);
    return this.http.get(url, { headers: this.getRequestHeaders() });
  }

  getSeasonData(): Observable<string> {
    return this.seasonDataSubject.asObservable();
  }
  getPreviousSeasonData(): Observable<string> {
    return this.previousSeasonDataSubject.asObservable();
  }

  getRequestHeaders(): HttpHeaders {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    return headers;
  }

  rsvWithPrebookCart(cart: any, product: any): boolean {
    let differentSeasonRSV = false;
    if (
      !this.isPrebookForm() &&
      cart?.prebookCart &&
      cart?.totalItems > 0 &&
      this.isRSVType(product)
    ) {
      differentSeasonRSV = true;
    }
    return differentSeasonRSV;
  }

  isRSVType(product: any): boolean {
    return product?.categories?.find(
      (category: any) =>
        category?.code === 'VACCNVACCN000P2400' ||
        category?.name === 'Vaccines - RSV'
    );
  }

  isPrebookForm(): boolean {
    var isPrebookForm = false;
    const prebook = this.activatedRoute.snapshot.queryParamMap.get('prebook');
    if (prebook && prebook === 'true') {
      isPrebookForm = true;
    }
    return isPrebookForm;
  }
}
