import { Params } from '@angular/router';
import { PREBOOK_CATEGORIES_SEO_CODE_MAPPING } from '@app/fff-config/category/fff-category-codes.config';
import { FFFOrderEntry } from '@app/models/fff-cart-data.model';
import { VACCINES_CATEGORY_TYPES } from '@app/models/fff-product.model';

export const PREBOOK_CART_LABEL = 'prebookCartPage';

export const ORDERED_VACCINE_STEPS: string[] = [
  VACCINES_CATEGORY_TYPES.INFLUENZA,
  VACCINES_CATEGORY_TYPES.COVID,
  VACCINES_CATEGORY_TYPES.RSV,
];

export const getNextCategoryName = (name: string): string => {
  let index = ORDERED_VACCINE_STEPS.indexOf(name);
  let nextIndex = index + 1;
  const len = ORDERED_VACCINE_STEPS.length;

  if (nextIndex > len - 1) {
    nextIndex = len - 1;
  }
  if (index === -1) {
    return ORDERED_VACCINE_STEPS[0];
  }
  return ORDERED_VACCINE_STEPS[nextIndex];
};

export const isValidPrebookCategory = (name: string): boolean => {
  return Object.keys(PREBOOK_CATEGORIES_SEO_CODE_MAPPING).includes(name);
};

export const isPrebookEnabledCategory = (queryParams: Params): boolean => {
  return (
    isValidPrebookCategory(queryParams['category']) &&
    (queryParams['prebook'] == true || queryParams['prebook'] == 'true')
  );
};

export const getEntriesByCategoryCode = (
  entries: FFFOrderEntry[],
  code: string
): FFFOrderEntry[] => {
  return (entries || []).filter(
    (value: FFFOrderEntry) =>
      !!value.product?.categories?.find(cat => cat.code === code)
  );
};
