<div class="container" id="new-report-form" *ngIf="isTypesAvailable">
  <div class="d-flex justify-content-between top-title">
    <div class="d-flex mb-4 align-items-end">
      <ng-container *ngIf="editable; else defaultTitle">
        <h3 class="new-report-form-title pr-3">
          {{ 'fffReports.editReport' | cxTranslate }}
        </h3>
        <span>{{
          'fffReports.editReportId'
            | cxTranslate : { reportId: form.value?.reportID }
        }}</span>
      </ng-container>
    </div>
  </div>

  <ng-template #defaultTitle>
    <h3 class="new-report-form-title">
      {{ 'fffReports.title' | cxTranslate }}
    </h3>
  </ng-template>

  <form [formGroup]="form" (ngSubmit)="onSubmit()" *ngIf="!loading">
    <div class="row mb-5">
      <!-- Left column -->
      <div class="col-12 col-md-6 report-filters-col report-filters-col--left">
        <div class="form-group">
          <label
            class="report-field-label form-label form-label--lg form-label--required"
            for="nr-field-type"
            >{{ 'fffReports.fields.reportType.label' | cxTranslate }}</label
          >
          <ng-select
            labelForId="nr-field-type"
            [items]="reportTypes"
            [searchable]="false"
            [clearable]="false"
            bindLabel="label"
            bindValue="id"
            formControlName="reportType"
            (change)="
              handleReportTypeChange(form.get('reportType')?.value ?? '')
            "
          ></ng-select>
          <cx-form-errors [control]="form.get('reportType')"></cx-form-errors>
        </div>

        <div class="form-group" *ngIf="isFieldAvailable('reportFormat')">
          <label
            class="report-field-label form-label form-label--lg form-label--required"
            for="nr-field-reportFormat"
          >
            {{ 'fffReports.fields.reportFormat.label' | cxTranslate }}
          </label>
          <fff-radio-list-control
            formControlName="reportFormat"
            [items]="reportFormatOptions"
          ></fff-radio-list-control>
          <cx-form-errors [control]="form.get('reportFormat')"></cx-form-errors>
        </div>

        <div class="form-group" *ngIf="isEmailFormat">
          <label
            class="report-field-label form-label form-label--lg form-label--required"
            for="nr-field-frequency"
            >{{ 'fffReports.fields.frequency.label' | cxTranslate }}</label
          >
          <ng-select
            labelForId="nr-field-frequency"
            [items]="frequencyOptions"
            formControlName="reportFrequency"
            [searchable]="false"
            [clearable]="false"
            bindLabel="label"
            bindValue="id"
          ></ng-select>
          <cx-form-errors
            [control]="form.get('reportFrequency')"
          ></cx-form-errors>
        </div>

        <div class="row date-range-wrapper-mobile">
          <div
            class="col-12 col-md-6 wrapper-flex"
            *ngIf="
              isFieldAvailable('reportRange') &&
              isWeeklyOrMonthly() &&
              isEmailFormat
            "
          >
            <div class="form-group">
              <label
                class="report-field-label form-label form-label--lg form-label--required"
                for="nr-field-reportRange"
                >{{
                  'fffReports.fields.reportRange.label' | cxTranslate
                }}</label
              >
              <ng-select
                labelForId="nr-field-reportRange"
                [items]="reportRangeOptions"
                formControlName="reportRange"
                [searchable]="false"
                [clearable]="false"
                bindLabel="label"
                bindValue="id"
              ></ng-select>
              <cx-form-errors
                [control]="form.get('reportRange')"
              ></cx-form-errors>
            </div>
          </div>

          <div
            class="col-12 col-md-6 wrapper-flex"
            *ngIf="
              isFieldAvailable('firstReportDate') &&
              isWeeklyOrMonthly() &&
              isEmailFormat &&
              !editable
            "
          >
            <div class="form-group">
              <label
                class="report-field-label form-label form-label--lg form-label--required"
                for="nr-field-firstReportDate"
                >{{
                  'fffReports.fields.firstReportDate.label' | cxTranslate
                }}</label
              >
              <div class="position-relative">
                <input
                  formControlName="firstReportDate"
                  class="w-100 form-control"
                  id="nr-field-firstReportDate"
                  type="datetime"
                  autocomplete="off"
                  ngbDatepicker
                  #firstReportDate="ngbDatepicker"
                  required
                  [minDate]="tomorrow"
                />
                <div class="text-primary" (click)="firstReportDate.toggle()">
                  <cx-icon
                    class="icon-sm calendar-icons"
                    [type]="outlinedIconTypes.ICON_CALENDAR3"
                  ></cx-icon>
                </div>
              </div>
              <cx-form-errors
                [control]="form.get('firstReportDate')"
              ></cx-form-errors>
            </div>
          </div>
          <div
            class="col-12 col-md-6 wrapper-flex"
            *ngIf="
              isFieldAvailable('reportDate') &&
              isWeeklyOrMonthly() &&
              editable &&
              isEmailFormat
            "
          >
            <div class="form-group">
              <label
                class="report-field-label form-label form-label--lg form-label--required"
                for="nr-field-reportDate"
                >{{ 'fffReports.fields.reportDate.label' | cxTranslate }}</label
              >
              <div class="position-relative">
                <input
                  formControlName="reportDate"
                  class="w-100 form-control"
                  id="nr-field-reportDate"
                  type="datetime"
                  autocomplete="off"
                  ngbDatepicker
                  #reportDate="ngbDatepicker"
                  required
                  [minDate]="tomorrow"
                />
                <div class="text-primary" (click)="reportDate.toggle()">
                  <cx-icon
                    class="icon-sm calendar-icons"
                    [type]="outlinedIconTypes.ICON_CALENDAR3"
                  ></cx-icon>
                </div>
              </div>
              <cx-form-errors
                [control]="form.get('reportDate')"
              ></cx-form-errors>
            </div>
          </div>
        </div>

        <div
          class="form-group"
          *ngIf="isFieldAvailable('fromDate') && !isWeeklyOrMonthly()"
        >
          <label
            class="report-field-label form-label form-label--lg form-label--required"
            for="nr-date-range-from"
            >{{ 'fffReports.fields.dateRange.label' | cxTranslate }}</label
          >

          <div class="d-flex">
            <div class="w-50 mr-1 position-relative">
              <input
                [placeholder]="'fffReports.from' | cxTranslate"
                formControlName="fromDate"
                class="w-100 form-control"
                id="nr-date-range-from"
                type="datetime"
                autocomplete="off"
                ngbDatepicker
                #from="ngbDatepicker"
                required
              />
              <div class="text-primary" (click)="from.toggle()">
                <cx-icon
                  class="icon-sm calendar-icons"
                  [type]="outlinedIconTypes.ICON_CALENDAR3"
                ></cx-icon>
                <cx-form-errors
                  [control]="form.get('fromDate')"
                ></cx-form-errors>
              </div>
            </div>

            <div class="w-50 ml-1 position-relative">
              <input
                [placeholder]="'fffReports.to' | cxTranslate"
                formControlName="toDate"
                class="w-100 form-control"
                id="dateTo"
                autocomplete="off"
                ngbDatepicker
                #to="ngbDatepicker"
                required
              />
              <div class="text-primary" (click)="to.toggle()">
                <cx-icon
                  class="icon-sm calendar-icons"
                  [type]="outlinedIconTypes.ICON_CALENDAR3"
                ></cx-icon>
                <cx-form-errors [control]="form.get('toDate')"></cx-form-errors>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Right column -->
      <div class="col-12 col-md-6 report-filters-col report-filters-col--right">
        <div class="form-group" *ngIf="isFieldAvailable('accounts')">
          <div class="d-flex justify-content-between align-items-center pb-2">
            <div>
              <label
                class="report-field-label form-label form-label--lg form-label--required mb-0"
                for="nr-field-accounts"
              >
                {{
                  allowAccountsMultiSelect
                    ? ('fffReports.fields.accounts.label' | cxTranslate)
                    : ('fffReports.fields.account.label' | cxTranslate)
                }}
              </label>
            </div>
            <div
              *ngIf="profile$ | async as profile"
              class="select-item-container-desktop"
            >
              <fff-new-report-account-select
                [profile]="profile"
                [isMultiAccount]="allowAccountsMultiSelect"
              ></fff-new-report-account-select>
            </div>
          </div>
          <fff-tags
            [multiple]="allowAccountsMultiSelect"
            [items]="selectedAccountTags"
            (removed)="onRemoveAccount($event)"
          ></fff-tags>
          <div
            *ngIf="profile$ | async as profile"
            class="select-item-container-mobile"
          >
            <fff-new-report-account-select
              [profile]="profile"
              [isMultiAccount]="allowAccountsMultiSelect"
            ></fff-new-report-account-select>
          </div>
          <cx-form-errors [control]="form.get('accounts')"></cx-form-errors>
        </div>
        <div class="form-group" *ngIf="isFieldAvailable('productGroups')">
          <div class="d-flex justify-content-between align-items-center pb-2">
            <div>
              <label
                class="report-field-label form-label form-label--lg form-label--required"
                for="nr-field-productGroups"
                >{{
                  'fffReports.fields.productGroups.label' | cxTranslate
                }}</label
              >
            </div>
            <div class="select-item-container-desktop">
              <fff-new-report-product-group-select
                [products]="productList"
                [productGroups]="productGroupList"
                [isMultiAccount]="true"
              ></fff-new-report-product-group-select>
            </div>
          </div>

          <fff-tags
            [multiple]="true"
            [items]="selectedProductGroupTags"
            (removed)="onRemoveProductGroup($event)"
          ></fff-tags>
          <div class="select-item-container-mobile">
            <fff-new-report-product-group-select
              [products]="productList"
              [productGroups]="productGroupList"
              [isMultiAccount]="true"
            ></fff-new-report-product-group-select>
          </div>

          <cx-form-errors
            [control]="form.get('productGroups')"
          ></cx-form-errors>
        </div>
        <div class="form-group" *ngIf="isFieldAvailable('products')">
          <div class="d-flex justify-content-between align-items-center pb-2">
            <div>
              <label
                class="report-field-label form-label form-label--lg form-label--required"
                for="nr-field-products"
                >{{ 'fffReports.fields.products.label' | cxTranslate }}</label
              >
            </div>
            <div class="select-item-container-desktop">
              <fff-new-report-product-select
                [products]="productList"
                [isMultiAccount]="true"
              ></fff-new-report-product-select>
            </div>
          </div>
          <fff-tags
            [multiple]="true"
            [items]="selectedProductsTags"
            (removed)="onRemoveProduct($event)"
          ></fff-tags>
          <div class="select-item-container-mobile">
            <fff-new-report-product-select
              [products]="productList"
              [isMultiAccount]="true"
            ></fff-new-report-product-select>
          </div>
          <cx-form-errors [control]="form.get('products')"></cx-form-errors>
        </div>
        <div class="form-group" *ngIf="isFieldAvailable('status')">
          <label
            class="report-field-label form-label form-label--lg form-label--required"
            for="nr-field-type"
          >
            {{
              reportType === allReportTypes.ORDER_HISTORY
                ? ('fffReports.fields.orderStatus.label' | cxTranslate)
                : ('fffReports.fields.invoiceStatus.label' | cxTranslate)
            }}
          </label>

          <fff-order-status-control
            formControlName="status"
            [items]="statuses"
            inputId="nr-status"
            inputName="nr-status"
          ></fff-order-status-control>
          <cx-form-errors
            [control]="form.get('status')"
            *ngIf="!isFormLoaded"
          ></cx-form-errors>
        </div>

        <div
          class="form-group"
          *ngIf="isFieldAvailable('emailIds') && isEmailFormat"
        >
          <label
            class="report-field-label form-label form-label--lg form-label--required"
            for="nr-field-emailIds"
            >{{ 'fffReports.fields.email.label' | cxTranslate }}</label
          >
          <p class="mb-2">
            {{ 'fffReports.fields.email.caption' | cxTranslate }}
          </p>
          <input formControlName="emailIds" class="w-100 form-control" />
          <cx-form-errors [control]="form.get('emailIds')"></cx-form-errors>
        </div>
      </div>
    </div>

    <div class="w-100 d-flex justify-content-center">
      <ng-container [ngSwitch]="form.value?.reportFormat">
        <ng-container *ngSwitchCase="reportFormatTypes.PDF">
          <button
            type="submit"
            class="btn btn-primary submit-button"
            [disabled]="submitting"
          >
            {{ 'fffReports.downloadPdfReport' | cxTranslate }}
          </button>
        </ng-container>
        <ng-container *ngSwitchCase="reportFormatTypes.CSV">
          <button
            type="submit"
            class="btn btn-primary submit-button"
            [disabled]="submitting"
          >
            {{ 'fffReports.downloadCsvReport' | cxTranslate }}
          </button>
        </ng-container>
        <ng-container *ngSwitchCase="reportFormatTypes.EMAIL">
          <ng-container
            *ngIf="
              form.value?.reportFrequency === reportFrequencyTypes.ONCE;
              else saveReportButton
            "
          >
            <ng-container [ngTemplateOutlet]="sendReportButton"></ng-container>
          </ng-container>
        </ng-container>
        <ng-container *ngSwitchDefault>
          <ng-container
            *ngIf="
              form.value?.reportFrequency === reportFrequencyTypes.ONCE;
              else saveReportButton
            "
          >
            <ng-container [ngTemplateOutlet]="sendReportButton"></ng-container>
          </ng-container>
        </ng-container>
      </ng-container>
    </div>
  </form>
</div>

<ng-template #successModal let-modal>
  <div class="modal-header">
    <cx-icon
      (click)="modal.close()"
      [type]="outlinedIconTypes.ICON_X"
    ></cx-icon>
  </div>
  <div class="modal-body">
    <div class="icon">
      <cx-icon [type]="outlinedIconTypes.ICON_SHIELD_CHECK"></cx-icon>
    </div>
    <div class="title">
      {{ 'fffReports.successModal.title' | cxTranslate }}
    </div>
    <div class="message">
      {{ successMessageI18nKey | cxTranslate }}
    </div>
  </div>
</ng-template>

<ng-template #warningModal let-modal>
  <div class="modal-header">
    <cx-icon
      (click)="modal.close()"
      [type]="outlinedIconTypes.ICON_X"
    ></cx-icon>
  </div>
  <div class="modal-body pb-4">
    <div class="icon">
      <cx-icon
        class="text-warning"
        [type]="outlinedIconTypes.ICON_EXCLAMATION_TRIANGLE"
      ></cx-icon>
    </div>
    <div
      class="message mb-3"
      [innerHTML]="'fffReports.warningModal.text' | cxTranslate"
    ></div>
    <button
      class="btn btn-primary btn-modal-action"
      type="button"
      (click)="modal.close()"
    >
      {{ 'fffReports.warningModal.ok' | cxTranslate }}
    </button>
  </div>
</ng-template>

<ng-template #saveReportButton>
  <div class="d-flex reports-btn-container-mobile">
    <div>
      <button
        *ngIf="editable"
        type="submit"
        (click)="cancelEdit()"
        class="btn btn-secondary cancel-btn"
      >
        {{ 'fffReports.cancel' | cxTranslate }}
      </button>
    </div>
    <div>
      <button
        type="submit"
        class="btn btn-primary form-submit-btn"
        [disabled]="submitting"
      >
        {{ 'fffReports.saveReport' | cxTranslate }}
      </button>
    </div>
  </div>
</ng-template>

<ng-template #sendReportButton>
  <div class="d-flex reports-btn-container-mobile">
    <div>
      <button
        *ngIf="editable"
        type="submit"
        (click)="cancelEdit()"
        class="btn btn-secondary cancel-btn"
      >
        {{ 'fffReports.cancel' | cxTranslate }}
      </button>
    </div>
    <div>
      <button
        type="submit"
        class="btn btn-primary form-submit-btn"
        [disabled]="submitting"
      >
        {{ 'fffReports.sendReport' | cxTranslate }}
      </button>
    </div>
  </div>
</ng-template>
