<div class="d-flex w-100 justify-content-end text-brand-primary-80 close-drawer">
    <cx-icon class="cx-icon icon-lg" [type]="outlinedIconTypes.ICON_X" (click)="closeDrawer()"></cx-icon>
</div>
<div class="minicart-header-container text-brand-primary-80" *ngIf="cartData$ | async as cartData">
    <h3 class="d-flex m-0">
        {{'fffMiniCart.added' | cxTranslate}}
    </h3>
    {{ cartData.entries?.length }}
    <span class="m-0" *ngIf="cartData.entries && cartData.entries.length == 1"> {{'fffMiniCart.singleItemCart' |
        cxTranslate}}</span>
    <span class="m-0" *ngIf="cartData.entries && cartData.entries.length != 1">{{'fffMiniCart.pluralItemCart' |
        cxTranslate}}</span>
</div>