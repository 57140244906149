import {
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { FffInvoicePaymentService } from '@app/fff-enterprise/fff-common-services/fff-invoice-payment.service';
import { InvoiceCreditCard } from '@app/models/fff-invoice.model';
import { OUTLINED_ICON_TYPE } from '@app/models/fff-outline-icons.model';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';

@Component({
  selector: 'fff-select-credit-card',
  templateUrl: './fff-select-credit-card.component.html',
  styleUrls: ['./fff-select-credit-card.component.scss'],
})
export class FffSelectCreditCardComponent implements OnInit, OnDestroy {
  outlineIcons = OUTLINED_ICON_TYPE;
  cardIconMapping: any = {
    VISA: 'visa-card',
    MC: 'master-card',
    AMEX: 'amex-card',
    DISCOVER: 'discover-card',
  };

  @Input()
  newCreditCardAdded!: boolean;
  loading: boolean = false;
  isCardsLoaded!: boolean;
  savedCards: InvoiceCreditCard[] = [];
  selectedCreditCard$ = this.fffInvoicePaymentService.getSelectedCreditCard();

  subs = new Subscription();

  constructor(
    private fffInvoicePaymentService: FffInvoicePaymentService,
    private activeModalService: NgbActiveModal,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.loadSavedCards();
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  toggleLoading(loading: boolean) {
    this.loading = loading;
    this.cd.markForCheck();
  }

  loadSavedCards() {
    this.toggleLoading(true);

    setTimeout(() => {
      this.fffInvoicePaymentService.initialize({
        newCreditCardAdded: this.newCreditCardAdded,
      });

      this.isCardsLoaded = true;
    }, 0);

    this.fffInvoicePaymentService.getSavedCards().subscribe(
      cards => {
        this.savedCards = cards || [];
        this.savedCards = this.removeDuplicationForNewlyAddedCard(
          this.savedCards
        );

        if (this.isCardsLoaded) {
          this.toggleLoading(false);
        }
      },
      () => {
        this.toggleLoading(false);
      }
    );
  }

  removeDuplicationForNewlyAddedCard(cards: any[]): any[] {
    const tokenGroups = cards.reduce((acc, item) => {
      if (!acc[item.cardToken]) {
        acc[item.cardToken] = [];
      }
      acc[item.cardToken].push(item);
      return acc;
    }, {});

    const duplicateTokens = Object.keys(tokenGroups).filter(
      token => tokenGroups[token].length > 1
    );
    let processedCards = cards;

    if (duplicateTokens && duplicateTokens.length > 0) {
      //checking if there was a card selected manully
      const alreadySelectedCard =
        this.fffInvoicePaymentService.getSelectedCreditCardOnGoBack();
      if (alreadySelectedCard) {
        this.fffInvoicePaymentService.setSelectedCreditCard(
          alreadySelectedCard
        );
      } else {
        //Checking if the last added card is oneTimeuse card and if it is selected
        if (
          !(
            cards[cards.length - 1].oneTimeUse &&
            cards[cards.length - 1].selected
          )
        ) {
          cards.forEach(card => {
            if (
              card.cardToken == duplicateTokens[duplicateTokens.length - 1] &&
              card.paymentUUID !== undefined &&
              card.paymentUUID !== null &&
              !card.oneTimeUse
            ) {
              this.fffInvoicePaymentService.setSelectedCreditCard(card);
            }
          });
        }
      }

      processedCards = cards.filter(
        card =>
          (card.paymentUUID !== undefined && card.paymentUUID !== null) ||
          card.oneTimeUse
      );
    }

    return processedCards;
  }

  selectCard(card: InvoiceCreditCard) {
    this.fffInvoicePaymentService.setSelectedCreditCard(card);
  }

  setAsDefault(card: InvoiceCreditCard) {
    this.toggleLoading(true);
    this.isCardsLoaded = false;
    this.fffInvoicePaymentService
      .setDefaultCreditCard(
        {
          cardToken: card?.cardToken,
          defaultPayment: true,
        },
        card.paymentUUID
      )
      .subscribe(
        () => {
          this.isCardsLoaded = true;
        },
        () => {
          this.isCardsLoaded = true;
        }
      );
  }
  isDateExpired(month: any, year: any): boolean {
    const monthNumber: number = parseInt(month, 10);
    const currentYearLastTwoDigits: number = new Date().getFullYear() % 100;
    const currentMonth: number = new Date().getMonth() + 1;
    const yearNumber: number = parseInt(year, 10);

    if (
      yearNumber < currentYearLastTwoDigits ||
      (yearNumber === currentYearLastTwoDigits && monthNumber < currentMonth)
    ) {
      return true;
    } else {
      return false;
    }
  }

  addNewCard() {
    this.isCardsLoaded = false;
    this.activeModalService.close({
      initiateAddNewCard: true,
    });
  }
}
