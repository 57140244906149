export const fffNotFoundPage = {
  fffNotFoundPage: {
    header: 'We are sorry, but the page you are looking for cannot be found.',
    actions: `<ul>
                <li>If you typed the URL directly, please make sure the spelling is correct.</li>
                <li>If you clicked on a link to get here, we must have moved the content.</li>
                <li>Please try our store search box above to search for an item.</li>
                <li>If you are not sure how you got here, <a class="link text-underline go-back">go back</a> to the previous page or return to our <a class="link text-underline" href="/">store homepage</a>.</li>
              </ul> `,
  },
};

export const fffInternalServerPage = {
  fffInternalServerPage: {
    header: 'We are sorry, this page isn’t working.',
    host: 'url.fffenterprises.com',
    errorMessage: 'is currently unable to handle this request.',
    errorCode: 'HTTP Error 500',
  },
};

export const fffSearchEmptyListPage = {
  fffSearchEmptyListPage: {
    header: 'No results found for "{{query}}"',
    message: 'Try searching again using a different spelling or keyword.',
    returnHomePage: 'Return to Homepage'
  },
};