<button
  type="button"
  class="btn-scroll-top"
  *ngIf="showButton"
  (click)="scrollToTop($event)"
>
  <cx-icon
    class="cx-icon icon-lg text-white"
    [type]="outlinedIconTypes.CARET_DOWN"
  ></cx-icon>
</button>
