import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { FffCheckoutService } from '@app/fff-enterprise/fff-common-services/fff-checkout.service';
import { OUTLINED_ICON_TYPE } from '@app/models/fff-outline-icons.model';
import { NgbCalendar, NgbDate, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { ActiveCartFacade } from '@spartacus/cart/base/root';

@Component({
  selector: 'fff-checkout-recurring-orders',
  templateUrl: './fff-checkout-recurring-orders.component.html',
})
export class FffCheckoutRecurringOrdersComponent implements OnInit {

  outlinedIconTypes = OUTLINED_ICON_TYPE;

  isInvalidCart: boolean = false;
  minDateTomorrow = FffCheckoutRecurringOrdersComponent.mapDateToNgbDateStruct(Date.now() + 86400000);
  // date now returns current ms, and we add 86400 * 1000 since we are working with ms and not seconds
  // resulting in 'tomorrow'
  recurringOrdersForm: UntypedFormGroup = this.formBuilder.group({
    isRecurringOrder: [false],
    orderStartDate: ['', [Validators.required]],
    frequency: ['', [Validators.required]],
    frequencyWeeklyOn: [''],
    frequencyWeeklyWeeks: [''],
    frequencyMonthlyOnDay: [''],
  });

  isDisabled: any;
  disabledDates: number[] = [28, 29, 30, 31];
  disabledDaysOfWeek: number[] = [5, 6, 7];
  selectedDate: number = 0;
  selectedDay: String = '';

  constructor(
    protected formBuilder: UntypedFormBuilder,
    private cartService: ActiveCartFacade,
    public fffCheckoutService: FffCheckoutService,
    private calendar: NgbCalendar,
  ) { }

  ngOnInit(): void {
    this.fffCheckoutService.recurringOrdersForm.next(this.recurringOrdersForm);

    this.recurringOrdersForm.get('isRecurringOrder')?.valueChanges.subscribe((isRecurringOrder: boolean) => {
      if (isRecurringOrder) {
        this.cartService.getEntries().subscribe((entries: any) =>
          this.isInvalidCart = entries.some((entry: any) => entry.strength || entry.batch || (entry.shippingCost && entry.shippingCost > 0))
        );
      } else {
        this.isInvalidCart = false;
        this.recurringOrdersForm.reset({ isRecurringOrder: false }, { emitEvent: false });
      }
    });

    this.recurringOrdersForm.get('frequency')?.valueChanges.subscribe((frequency: any) => {
      if (frequency == 'WEEKLY') {
        this.recurringOrdersForm.get('frequencyWeeklyOn')?.setValidators([Validators.required]);
        this.recurringOrdersForm.get('frequencyWeeklyWeeks')?.setValidators([Validators.required]);
        this.recurringOrdersForm.get('frequencyMonthlyOnDay')?.setValidators([]);
        this.recurringOrdersForm.get('frequencyMonthlyOnDay')?.reset();

        this.recurringOrdersForm.get('frequencyWeeklyOn')?.setValue(this.selectedDay);
      } else if (frequency == 'MONTHLY') {
        this.recurringOrdersForm.get('frequencyMonthlyOnDay')?.setValidators([Validators.required]);
        this.recurringOrdersForm.get('frequencyWeeklyOn')?.setValidators([]);
        this.recurringOrdersForm.get('frequencyWeeklyWeeks')?.setValidators([]);
        this.recurringOrdersForm.get('frequencyWeeklyOn')?.reset();
        this.recurringOrdersForm.get('frequencyWeeklyWeeks')?.reset();

        this.recurringOrdersForm.get('frequencyMonthlyOnDay')?.setValue(this.selectedDate);
      }
    });

    this.recurringOrdersForm.get('orderStartDate')?.valueChanges.subscribe((orderStartDate: any) => {
      const selectedDate = new Date();
      selectedDate.setFullYear(orderStartDate.year, (orderStartDate.month - 1), orderStartDate.day);
      this.selectedDate = orderStartDate.day;
      this.recurringOrdersForm.get('frequencyMonthlyOnDay')?.setValue(this.selectedDate);
      switch (selectedDate.getDay()) {
        case 1: {
          this.selectedDay = 'MONDAY';
          break;
        }
        case 2: {
          this.selectedDay = 'TUESDAY';
          break;
        }
        case 3: {
          this.selectedDay = 'WEDNESDAY';
          break;
        }
        case 4: {
          this.selectedDay = 'THURSDAY';
          break;
        }
        default: {
          this.selectedDay = '';
          return;
        }
      }
      this.recurringOrdersForm.get('frequencyWeeklyOn')?.setValue(this.selectedDay);
    });

    this.isDisabled = (date: NgbDateStruct) => {
      return this.disabledDates.includes(date.day)
        || this.disabledDaysOfWeek.includes(this.calendar.getWeekday(new NgbDate(date.year, date.month, date.day)));
    };
  }
  private static mapDateToNgbDateStruct(time: number): NgbDateStruct {
    const date = new Date(time);
    return { year: date.getFullYear(), month: date.getMonth() + 1, day: date.getDate() };
  }
}
