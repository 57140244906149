import {
  ChangeDetectorRef,
  Component,
  HostListener,
  Input,
  OnInit,
} from '@angular/core';
import { FffMediaService } from '@app/shared/services/fff-media.service';
import { MediaContainer } from '@spartacus/storefront';

@Component({
  selector: 'fff-media',
  templateUrl: './fff-media.component.html',
})
export class FffMediaComponent implements OnInit {
  availableContainer: any = null;

  @Input()
  container!: MediaContainer;
  @Input()
  title!: string;

  constructor(
    private fffMediaService: FffMediaService,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.onResize(null);
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.filtred(this.container);
    this.cd.detectChanges();
  }

  filtred(container: any) {
    const bestResolution = this.fffMediaService.selectBestFormat(container);
    if (bestResolution) {
      const bestCode = bestResolution.code;
      this.availableContainer = { bestCode: container[bestCode] };
    } else {
      this.availableContainer = {};
    }
  }
}
