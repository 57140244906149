import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { FffTooltipModule } from '@app/shared/tooltip/tooltip.module';
import { NgbDatepickerModule, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FffDrawerModule } from '@shared/drawer/fff-drawer.module';
import { CheckoutPaymentMethodModule } from '@spartacus/checkout/base/components';
import {
  CmsConfig,
  I18nModule,
  UrlModule,
  provideConfig,
  provideDefaultConfig,
} from '@spartacus/core';
import { FormErrorsModule, IconModule } from '@spartacus/storefront';
import { FffEntryModule } from '../fff-entry/fff-entry.module';
import { FffCheckoutBillingInfoComponent } from './fff-checkout-billing-info/fff-checkout-billing-info.component';
import { FffCheckoutCartSummaryComponent } from './fff-checkout-cart-summary/fff-checkout-cart-summary.component';
import { DeliveryCalendarComponent } from './fff-checkout-cart-summary/fff-shipping-select/fff-estimated-delivery/delivery-calendar/delivery-calendar.component';
import { FffEstimatedDeliveryComponent } from './fff-checkout-cart-summary/fff-shipping-select/fff-estimated-delivery/fff-estimated-delivery.component';
import { FffShippingDatesComponent } from './fff-checkout-cart-summary/fff-shipping-select/fff-shipping-dates/fff-shipping-dates.component';
import { FffShippingSelectComponent } from './fff-checkout-cart-summary/fff-shipping-select/fff-shipping-select.component';
import { FffCheckoutHeaderComponent } from './fff-checkout-header/fff-checkout-header.component';
import { FffCheckoutErrorPlacingOrderComponent } from './fff-checkout-modals/fff-checkout-error-placing-order/fff-checkout-error-placing-order.component';
import { FffCheckoutPrepaymentComponent } from './fff-checkout-modals/fff-checkout-prepayment/fff-checkout-prepayment.component';
import { FffCheckoutRecurringOrdersPopupComponent } from './fff-checkout-modals/fff-checkout-recurring-orders-popup/fff-checkout-recurring-orders-popup.component';
import { FffCheckoutRecurringOrdersComponent } from './fff-checkout-recurring-orders/fff-checkout-recurring-orders.component';
import { FffCheckoutSummaryComponent } from './fff-checkout-summary/fff-checkout-summary.component';
import { FffCheckoutComponent } from './fff-checkout/fff-checkout.component';
import { FFFLoadersModule } from '@app/shared/components/fff-loaders/fff-loaders.module';

@NgModule({
  declarations: [
    FffCheckoutComponent,
    FffCheckoutBillingInfoComponent,
    FffCheckoutCartSummaryComponent,
    FffCheckoutSummaryComponent,
    FffCheckoutHeaderComponent,
    FffCheckoutPrepaymentComponent,
    FffCheckoutErrorPlacingOrderComponent,
    FffShippingSelectComponent,
    FffEstimatedDeliveryComponent,
    DeliveryCalendarComponent,
    FffShippingDatesComponent,
    FffCheckoutRecurringOrdersComponent,
    FffCheckoutRecurringOrdersPopupComponent,
  ],
  imports: [
    UrlModule,
    BrowserModule,
    CommonModule,
    FormsModule,
    CheckoutPaymentMethodModule,
    // PaymentTypeModule
    I18nModule,
    ReactiveFormsModule,
    IconModule,
    FormErrorsModule,
    FffEntryModule,
    NgbDatepickerModule,
    FffDrawerModule,
    FffTooltipModule,
    FFFLoadersModule,
    NgbModule,
  ],
  exports: [],
  providers: [
    provideDefaultConfig(<CmsConfig>{
      cmsComponents: {
        CheckoutFlexComponent: {
          component: FffCheckoutComponent,
        },
        CheckoutOrderSummaryComponent: {
          component: FffCheckoutSummaryComponent,
        },
      },
    }),
    provideConfig({
      routing: {
        routes: {
          checkout: {
            paths: ['checkout-single'],
          },
        },
      },
    }),
  ],
})
export class FffCheckoutModule {}
