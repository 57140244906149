import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { FffSaveCartDrawerService } from './fff-save-cart-drawer.service';


@Component({
  selector: 'fff-save-cart-drawer-body',
  templateUrl: './fff-save-cart-drawer-body.component.html',
})
export class FffSaveCartDrawerBodyComponent implements OnInit {

  form: UntypedFormGroup = this.fb.group(
    {
      cartName: ['', [Validators.required]],
      cartDescription: [''],
      removeItems: [false]
    }
  );

  constructor(protected fb: UntypedFormBuilder,
    private saveCartDrawerService: FffSaveCartDrawerService
  ) { }

  ngOnInit(): void {
    this.saveCartDrawerService.saveCartForm.next(this.form);
  }

}




