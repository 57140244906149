<div class="modal-header">
  <cx-icon (click)="close()" [type]="iconClose"></cx-icon>
</div>
<div class="modal-body">
  <div class="icon">
      <cx-icon [type]="iconWarning"></cx-icon>
  </div>
  <div class="title">
      {{ 'fffAccount.fffChangeAccount.warningModal.title' | cxTranslate }}
  </div>
  <div class="message">
      {{ 'fffAccount.fffChangeAccount.warningModal.message' | cxTranslate }}
  </div>
</div>
<div class="modal-footer">
  <button class="btn btn-primary col-12" (click)="proceed()">
  {{ 'fffAccount.fffChangeAccount.warningModal.proceed' | cxTranslate }}
  </button>

  <button class="btn btn-secondary col-12" (click)="close()">
      {{ 'common.cancel' | cxTranslate }}
  </button>
</div>
