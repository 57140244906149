import {
  GlobalMessageType,
  Translatable,
} from '@spartacus/core';

export const FFF_GLOBAL_MESSAGE = 'FFF_GLOBAL_MESSAGE';

export interface StateWithGlobalBannerMessage {
  [FFF_GLOBAL_MESSAGE]: GlobalBannerMessageState;
}
export interface GlobalBannerMessageState {
  entities: GlobalBannerMessageEntities;
}
export interface GlobalBannerMessageEntities {
  [messageType: string]: GlobalBannerMessage[];
}
export interface GlobalBannerMessage {
  id: string;
  text: Translatable;
  type: GlobalMessageType;
  timeout?: number;
  isHTML?: boolean;
  isPersistent?: boolean;
  isTranslatable?: boolean;
  prefix?: string;
}
