export interface FFFShippingConditionsPayload {
  cartId?: string, 
  position?: number,
  date?: string, 
  warehouse?: string,
  uom?: string,
  inco?: string
}

export enum SHIPPING_CONDITIONS_INCO_TYPES {
  COL= 'COL',
  PRE= 'PRE'
}