import { Injectable } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { BasePageMetaResolver, PageLinkService, ProductPageMetaResolver, ProductService, RoutingService, TranslationService, isNotNullable } from '@spartacus/core';
import { CurrentProductService } from '@spartacus/storefront';
import { Observable, of } from 'rxjs';
import { filter, switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class CustomPageMetaResolver extends ProductPageMetaResolver  {
    
  constructor(routingService: RoutingService, 
    productService: ProductService, 
    translation: TranslationService, 
    basePageMetaResolver: BasePageMetaResolver, 
    pageLinkService: PageLinkService,
    public ngMeta: Meta,
    public currentProductService: CurrentProductService)
  {
    super(routingService, productService, translation, basePageMetaResolver, pageLinkService);
  }

  product$: Observable<any> = this.currentProductService.getProduct('DEFAULT').pipe(filter(isNotNullable));

  resolveTitle(): Observable<string> {
    return this.product$.pipe(
      switchMap((product: any) => {
        //ideally, using "resolveDescription" would do the trick in here, but it seems that it isn't working, so I do it manually
        this.ngMeta.removeTag('name="description"');
        this.ngMeta.addTag({ name: 'description', content: product.seoDescription || '' })
        return of(product?.seoTitle || product?.name + ' | ' + product?.code );
      })
    );
  }


}