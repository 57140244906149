import { Component, Input } from '@angular/core';

@Component({
  selector: 'fff-cart-loader',
  templateUrl: './fff-cart-loader.component.html',
})
export class FFFCartLoaderComponent {
  @Input()
  isCartItem: boolean = false;
}
