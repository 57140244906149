<ng-container *ngIf="isCustomerEmulationSessionInProgress$ | async">
  <div class="cx-asm-customerInfo">
    <label class="cx-asm-name">{{ customer.name }}</label>
    <label class="cx-asm-uid">{{ customer.uid }}</label>
  </div>
  <cx-asm-bind-cart></cx-asm-bind-cart>
  <button
    *ngIf="isAsmCustomer360Configured && customer"
    #asmCustomer360Launcher
    class="cx-360-button"
    (click)="openAsmCustomer360()"
  >
    {{ 'asm.asmCustomer360Button' | cxTranslate }}
  </button>
  <button formcontrolname="logoutCustomer" (click)="logoutCustomer()">
    {{ 'asm.endSession' | cxTranslate }}
  </button>
</ng-container>
