<div class="d-flex flex-wrap summary-totals">
  <h3 class="col-12 px-0">{{ 'orderCost.orderSummary' | cxTranslate }}</h3>

  <div class="col-12 px-0" *ngIf="cart">
    <div class="d-flex flex-wrap summary-row">
      <div class="col-6 px-0 summary-label">
        {{ 'orderCost.subtotal' | cxTranslate }}
      </div>
      <div class="col-6 px-0 summary-amount text-right">
        {{ cart.subTotal?.formattedValue }}
      </div>
    </div>

    <div
      class="d-flex flex-wrap summary-row"
      *ngIf="cart?.totalDiscounts?.value! > 0"
    >
      <div class="col-6 px-0 summary-label">
        {{ 'orderCost.discount' | cxTranslate }}
      </div>
      <div class="col-6 px-0 summary-amount text-right">
        ({{ cart.totalDiscounts?.formattedValue }})
      </div>
    </div>

    <div class="d-flex flex-wrap summary-row">
      <div class="col-6 px-0 summary-label">
        {{ 'orderCost.shipping' | cxTranslate }}
      </div>
      <div class="col-6 px-0 summary-amount text-right">
        <div
          class="free-delivery"
          *ngIf="
            cart.prebookCart &&
              (!cart?.deliveryCost || cart?.deliveryCost?.value === 0);
            else noFreeDeliveryMsg
          "
        >
          <span class="free-delivery-text">
            {{ 'orderCost.freeDeliveryText' | cxTranslate }}</span
          >
          <span class="free-delivery-subtext free-delivery-ht-height">
            {{ 'orderCost.freeDeliveryPaid' | cxTranslate }}</span
          >
        </div>
        <ng-template #noFreeDeliveryMsg>
          <span
            [ngClass]="{
              'shipping-cost': (cart.deliveryCost?.value ?? 0) > 0
            }"
          >
            {{
              cart.deliveryCost?.value
                ? '$' + (cart.deliveryCost?.value | number : '1.2-2')
                : ''
            }}
          </span>
        </ng-template>
      </div>
    </div>

    <div
      class="d-flex flex-wrap summary-row justify-content-between"
      *ngIf="cart.totalTax?.value !== 0"
    >
      <div class="px-0 summary-label fet-tax-label">
        {{ 'orderCost.salesTax' | cxTranslate }}
        <fff-fet-info-tooltip></fff-fet-info-tooltip>
      </div>
      <div class="col-4 px-0 summary-amount text-right">
        {{ cart.totalTax?.formattedValue }}
      </div>
    </div>

    <div class="d-flex flex-wrap summary-row">
      <div class="col-12 px-0 summary-divider"></div>
    </div>

    <div class="d-flex flex-wrap summary-row">
      <div class="col-6 px-0 summary-label summary-total">
        {{ 'orderCost.total' | cxTranslate }}
      </div>
      <div class="col-6 px-0 summary-amount summary-total text-right">
        {{ cart.totalPriceWithTax?.formattedValue }}
      </div>
    </div>
    <ng-container>
      <div
        class="d-flex flex-wrap summary-row"
        *ngIf="cart?.totalDiscounts?.value! > 0"
      >
        <div class="col-12 px-0 summary-label">
          {{ 'orderCost.saved' | cxTranslate }}
          <span class="discount-value">{{
            cart.totalDiscounts?.formattedValue
          }}</span>
          {{ 'orderCost.withFFFDiscount' | cxTranslate }}
        </div>
      </div>
    </ng-container>

    <div class="d-flex flex-wrap summary-row">
      <div class="col-12 px-0 summary-divider"></div>
    </div>
    <ng-container *ngIf="activeSite === BASE_URL_KEYS.BIOSUPPLY">
      <div
        class="d-flex flex-wrap summary-row summary-note"
        *ngIf="existsAutoSplitBillerEntry"
      >
        <cx-icon
          class="col-1 px-0 cx-icon icon-sm"
          [type]="outlinedIconTypes.ICON_INFO_CIRCLE"
        ></cx-icon>
        <span class="col-11 px-0">{{
          'fffCartSummary.note' | cxTranslate
        }}</span>
      </div>
    </ng-container>
  </div>
</div>
