import { Component, Input } from '@angular/core';
import { OUTLINED_ICON_TYPE } from '@app/models/fff-outline-icons.model';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-fff-saved-cart-delete-confirmation',
  templateUrl: './fff-saved-cart-delete-confirmation.component.html',
})
export class FFFSavedCartDeleteConfirmationComponent {
  outlinedIconTypes = OUTLINED_ICON_TYPE;
  @Input() savedCartName!: string;

  constructor(private modalService: NgbActiveModal) {}

  close() {
    this.modalService.close();
  }

  confirmDelete() {
    this.modalService.close({ isDeleteConfirmed: true });
  }
}
