import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnInit,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { ActiveCartFacade } from '@spartacus/cart/base/root';
import { CmsComponentData } from '@spartacus/storefront';
import { BehaviorSubject, Observable } from 'rxjs';
import { B2BUnitState, UpdatedCartState } from 'src/app/reducers';
import { FffDrawerService } from 'src/app/shared/drawer/fff-drawer.service';
import { OUTLINED_ICON_TYPE } from '../../../models/fff-outline-icons.model';
import { FffMiniCartContentComponent } from './fff-mini-cart-content/fff-mini-cart-content.component';
import { FffMiniCartHeaderComponent } from './fff-mini-cart-header/fff-mini-cart-header.component';
import { FffMiniCartDrawerService } from './fff-save-cart-drawer.service';

@Component({
  selector: 'fff-mini-cart',
  templateUrl: './fff-mini-cart.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CartButtonComponent implements OnInit {
  public data$: Observable<any> = this.componentData.data$;
  public result: any;
  outlinedIconTypes = OUTLINED_ICON_TYPE;
  entries: any[] = [];
  eligibleEntries: any[] = [];
  amountOfItems = new BehaviorSubject(0);
  itemsForDrawer: number = 0;
  b2bunit$:
    | Observable<{ readonly currentB2BUnit: any; readonly listB2BUnit: any }>
    | undefined;
  constructor(
    private componentData: CmsComponentData<any>,
    private cd: ChangeDetectorRef,
    private drawerService: FffDrawerService,
    private fffActiveCartService: ActiveCartFacade,
    private cartEvent: Store<UpdatedCartState>,
    private fffMiniCartDrawerService: FffMiniCartDrawerService,
    private storeB2BUnit: Store<B2BUnitState>
  ) {
    this.updateEntries();
    this.cartEvent
      .select((state: any) => {
        return state.UpdatedCartState;
      })
      .subscribe(focusState => {
        this.updateEntries();
      });
  }
  ngOnInit() {
    this.b2bunit$ = this.storeB2BUnit.select(state => state.B2BUnit);
  }

  openDrawer() {
    this.setDrawer();
    this.drawerService.openDrawer();
  }

  updateEntries(): void {
    const activeCart$ = this.fffActiveCartService.getActive();
    activeCart$.subscribe(data => {
      if (data.entries) {
        this.fffMiniCartDrawerService.cartData$.next(data);
        this.itemsForDrawer = data.entries.length;
        this.entries = data.entries;
        this.eligibleEntries = this.entries?.filter(
          (item: any) => item.product.isExcluded
        );
        this.cd.markForCheck();
      } else {
        // this.fffActiveCartService.addEntries([]);
      }
    });
  }

  setDrawer() {
    this.drawerService.setContent({
      drawerHeader: FffMiniCartHeaderComponent as Component,
      component: FffMiniCartContentComponent as Component,
      animation: 'SideRTL',
      class: 'minicart-drawer',
    });
  }
}
