import { Component, forwardRef, Input, OnChanges, SimpleChanges } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { FileUploadComponent } from '@spartacus/storefront';

@Component({
  selector: 'fff-file-upload',
  templateUrl: './fff-file-upload.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FffFileUploadComponent),
      multi: true,
    },
  ],
})
export class FffFileUploadComponent extends FileUploadComponent implements ControlValueAccessor, OnChanges {
  @Input() clean: boolean | null = false;

  ngOnChanges(changes: SimpleChanges){
    if (changes.clean.currentValue){
      this.fileInput.nativeElement.value = '';
    }
  }
}
