import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import {
  FffQuickOrderFile,
  FffQuickOrderFileRow,
  FffQuickUploadOrderResponse,
} from '@app/models/fff-quick-order-file.model';
import { FffBasicModalComponent } from '@app/shared/components/modal/fff-basic-modal/fff-basic-modal.component';
import {
  FileExtensionEnum,
  PathFileEnum,
} from '@app/shared/services/enum.service';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import {
  ImportEntriesFormComponent,
  ImportProductsFromCsvService,
} from '@spartacus/cart/import-export/components';
import { ImportExportConfig } from '@spartacus/cart/import-export/core';
import { Translatable } from '@spartacus/core';
import {
  FilesFormValidators,
  ICON_TYPE,
  ImportCsvFileService,
  LaunchDialogService,
} from '@spartacus/storefront';
import { BehaviorSubject, Subscription } from 'rxjs';
import { shareReplay } from 'rxjs/operators';
import { OUTLINED_ICON_TYPE } from 'src/app/models/fff-outline-icons.model';
import { WorkBook, WorkSheet, read, utils } from 'xlsx';
import { FffQuickOrderService } from '../fff-quick-order.service';

@Component({
  selector: 'fff-import-entries-form',
  templateUrl: './fff-import-entries-form.component.html',
  providers: [FffQuickOrderService],
})
export class FffImportEntriesFormComponent
  extends ImportEntriesFormComponent
  implements OnInit, OnDestroy
{
  limit = 2000;
  iconTypes = ICON_TYPE;
  outlineTypes = OUTLINED_ICON_TYPE;
  downloadLink = PathFileEnum.quickOrderTemplateFile;
  allowedExtensionTypes: string[] = [
    FileExtensionEnum.xls,
    FileExtensionEnum.xlsx,
  ];
  clean$ = new BehaviorSubject<boolean>(false);
  loading$ = new BehaviorSubject<boolean>(false);
  processed$ = new BehaviorSubject<boolean>(false);
  successIcon = OUTLINED_ICON_TYPE.ICON_CHECK;
  fffBasicModal: NgbModalRef | null = null;
  private files: FffQuickOrderFile[] = [];
  private subscriptions: Subscription = new Subscription();

  constructor(
    protected launchDialogService: LaunchDialogService,
    protected importToCartService: ImportProductsFromCsvService,
    protected importCsvService: ImportCsvFileService,
    protected filesFormValidators: FilesFormValidators,
    protected importExportConfig: ImportExportConfig,
    protected fffQuickOrderService: FffQuickOrderService,
    protected modalService: NgbModal,
    private router: Router
  ) {
    super(
      launchDialogService,
      importToCartService,
      importCsvService,
      filesFormValidators,
      importExportConfig
    );
  }

  ngOnInit() {
    super.ngOnInit();
    this.form = new UntypedFormGroup({});
    this.form.setControl(
      'file',
      new UntypedFormControl('', [
        Validators.required,
        this.filesFormValidators.maxSize(this.maxSize),
      ])
    );
  }

  fileChanged(files: FileList | null) {
    if (!files) {
      return;
    }
    this.files = [];
    this.processed$.next(false);
    this.clean$.next(false);
    if (this.form.get('file')?.hasError) {
      this.form.get('file')?.setErrors(null);
    }
    files && files.length > 0 ? files[0] : undefined;
    const reader: FileReader = new FileReader();
    reader.onload = (e: any) => {
      this.readFile(e);
    };
    reader.readAsArrayBuffer(files[0]);
  }

  private readFile(e: any) {
    const ab: ArrayBuffer = e.target.result;
    const wb: WorkBook = read(ab);

    const wsName: string = wb.SheetNames[0];
    const ws: WorkSheet = wb.Sheets[wsName];

    const data = <any>utils.sheet_to_json(ws, { header: 1 });
    if (data && data.length > 2) {
      this.parseFile(data);
    } else {
      this.openModal(false, [{ key: 'formErrors.quickOrderFile.empty' }]);
    }
  }

  uploadFile() {
    this.loading$.next(true);
    this.clean$.next(true);

    this.fffQuickOrderService
      .sendFile(this.files)
      .pipe(shareReplay(1))
      .subscribe((response: any) => {
        this.loading$.next(false);
        this.router.navigate(['/my-account/quickorder-status']);
      });
    //this.subscriptions.add(this.fffQuickOrderService.sendFile(this.files))

    //this.openModal(true, [ { key: 'fffQuickOrder.uploadSuccessMessage' } ]);
    // setTimeout(() => {
    //   window.location.href = '/my-account/quickorder-status';
    // }, 5000);

    /*this.subscriptions.add(this.fffQuickOrderService.sendFile(this.files)
      .subscribe((quickUploadResponse) => {
        const orderErrors = quickUploadResponse?.orders?.filter(
          (order) => {
            return !order.isSuccess;
          }
        );

        const quoteErrors = quickUploadResponse?.quotes?.filter(
          (order) => {
            return !order.isSuccess;
          }
        );

        if ((orderErrors && orderErrors.length > 0) || (quoteErrors && quoteErrors.length > 0)){

          const entries = orderErrors && orderErrors.length > 0 ?
            orderErrors : quoteErrors && quoteErrors.length > 0 ?
              quoteErrors : [];

          this.openModal(false, this.getErrorMessages(entries));
        } else {
          this.openModal(true, [ { key: 'fffQuickOrder.uploadSuccessMessage' } ]);
        }
        this.loading$.next(false);

      },
      () => {
        this.loading$.next(false);
        this.openModal(false, [ { key: 'formErrors.quickOrderFile.error' } ]);
      })
    );
    */
  }

  private openModal(success: boolean, messages: Translatable[]) {
    if (!this.fffBasicModal) {
      this.fffBasicModal = this.modalService.open(FffBasicModalComponent, {
        animation: true,
        backdrop: 'static',
      });
      this.fffBasicModal.componentInstance.title = success
        ? 'fffQuickOrder.modalSuccessTitleMessage'
        : 'fffQuickOrder.uploadErrorTitleMessage';
      this.fffBasicModal.componentInstance.button = 'fffQuickOrder.modalButton';
      this.fffBasicModal.componentInstance.messages = messages;
      this.fffBasicModal.componentInstance.success = success;
      this.fffBasicModal.result
        .then(() => {
          this.files = [];
          this.processed$.next(false);
          this.clean$.next(true);
          this.fffBasicModal = null;
        })
        .catch(() => {
          this.fffBasicModal = null;
        });
    }
  }

  private getErrorMessages(entries: FffQuickUploadOrderResponse[]) {
    const errorMessages: Translatable[] = [];
    for (let order of entries) {
      for (let message of order.errorCodeMessageMap.entry) {
        const errorMsg = message.value;
        const storeNumber = errorMsg.substring(
          errorMsg.indexOf('[') + 1,
          errorMsg.lastIndexOf(']')
        );
        errorMessages.push({
          key: 'fffQuickOrder.uploadErrorMessage',
          params: { storeNumber: storeNumber },
        });
      }
    }
    return errorMessages;
  }

  private parseFile(data: any[]) {
    const rows = data.filter((row: string[]) => {
      return row.length > 0;
    });

    if (rows.length > 1) {
      const total = rows.length - 1;
      if (total > this.limit) {
        this.openModal(false, [{ key: 'formErrors.quickOrderFile.maxLength' }]);
      } else {
        this.files = this.createQuickOrderFile(rows);
        this.processed$.next(true);
      }
    } else {
      this.openModal(false, [{ key: 'formErrors.quickOrderFile.empty' }]);
    }
  }

  private createQuickOrderFile(rows: any[]): FffQuickOrderFile[] {
    const quickOrderFiles: FffQuickOrderFile[] = [];
    for (let i = 1; i < rows.length; i++) {
      const quickOrderFile: FffQuickOrderFile = {
        storeNumber: rows[i][0],
        PO: rows[i][1],
        ReqDelDate: rows[i][2],
        orderEntries: [],
      };

      for (let j = 3; j < rows[i].length; j++) {
        let qty = rows[i][j];
        if (typeof qty === 'undefined') {
          qty = 0;
        }
        let entry: FffQuickOrderFileRow = {
          code: rows[0][j],
          quantity: Number.parseInt(qty.toString(), 10),
        };
        quickOrderFile.orderEntries.push(entry);
      }
      quickOrderFiles.push(quickOrderFile);
    }
    return quickOrderFiles;
  }

  ngOnDestroy(): void {
    this.subscriptions?.unsubscribe();
  }
}
