import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FffAllocationService } from '@app/fff-enterprise/fff-common-services/fff-allocation.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FffNeedMoreDialogComponent } from './fff-need-more-dialog/fff-need-more-dialog.component';

@Component({
  selector: 'fff-product-allocation',
  templateUrl: './fff-product-allocation.component.html',
})
export class FffProductAllocationComponent implements OnInit {
  @Input() product: any;
  @Input() priceData: any;
  @Input() allocation: any;
  allocResults: any;
  hardAlloc: boolean | undefined;
  hasMaterial: any;
  allocated: any = 0;
  remaining: any = 0;
  isRapidCommit: boolean = false;
  isProvideGX: boolean = false;
  isInRapidCommitCategory = false;
  constructor(
    private modalService: NgbModal,
    private changeDetectorRef: ChangeDetectorRef,
    private allocationService: FffAllocationService,
    private router: Router
  ) {}

  ngOnInit(): void {
    if (
      this.router.url.includes('RapidCommit') ||
      this.router.url.includes('RAPIDCOMMIT')
    )
      this.isInRapidCommitCategory = true;

    this.isRapidCommit = this.product.categories
      ? this.product.categories.find(
          (category: any) => category.code == 'RAPIDCOMMIT'
        )
      : false;
    this.isProvideGX = this.product.categories
      ? this.product.categories.find(
          (category: any) => category.code == 'PROVIDEGX'
        )
      : false;

    if (this.allocation) {
      this.allocResults = { ...this.allocation };
      this.setAllocDisplayValues();
      this.hardAlloc = this.isHardAlloc();
      this.hasMaterial = this.allocResults.material;
      this.changeDetectorRef.detectChanges();
    }
  }

  needMoreDialogBox() {
    const modalRef = this.modalService.open(FffNeedMoreDialogComponent, {
      centered: true,
      size: 'lg',
    });
    modalRef.componentInstance.isHardAllocation = this.isHardAlloc();
    modalRef.componentInstance.isPGX = this.isPGX();
  }

  isHardAlloc() {
    return this.allocResults.action == 2;
  }

  isPGX() {
    return !!this.product.categories.find(
      (category: any) => category?.code === 'PROVIDEGX'
    );
  }

  floatToInt(stringNumber: number) {
    return Math.floor(stringNumber);
  }

  setAllocDisplayValues() {
    let actionCSY = this.allocResults.actionCSY;
    let action = this.allocResults.action;
    //transforming the allocated qty
    this.allocated = this.allocationService.transformUnits(
      +this.allocResults.allocatedQty,
      this.allocResults,
      this.priceData.pricesData.prices[0].price.basicUOM,
      this.priceData.pricesData.prices[0]
    );

    if (
      action == 2 ||
      (action == 1 && actionCSY == 2) ||
      (action == 1 && actionCSY == 1)
    ) {
      let remainingUnconverted =
        +this.allocResults.yearlyAllocatedQty > 0
          ? Math.min(
              +this.allocResults.yearlyRemainingQty,
              +this.allocResults.remainingQty
            )
          : +this.allocResults.remainingQty;
      this.remaining = this.allocationService.transformUnits(
        remainingUnconverted,
        this.allocResults,
        this.priceData.pricesData.prices[0].price.basicUOM,
        this.priceData.pricesData.prices[0]
      );
    }
  }
}
