import { ChangeDetectorRef, Component, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { FffSsoService } from '@app/fff-enterprise/fff-sso/fff-sso.service';
import { OUTLINED_ICON_TYPE } from '@app/models/fff-outline-icons.model';
import { BaseSiteService } from '@spartacus/core';
import {
  CmsComponentData,
  SkipLinkComponent,
  SkipLinkService,
} from '@spartacus/storefront';
import { Observable, Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import {
  BASE_URL_KEYS,
  SPARTACUS_CMS_TYPECODE,
} from '../../../fff-config/content/constants';

@Component({
  selector: 'fff-skip-links',
  templateUrl: './fff-skip-links.component.html',
})
export class SkipLinksComponent extends SkipLinkComponent implements OnDestroy {
  public data$: Observable<any> = this.componentData.data$;
  public ccd_result: any;
  public fff_quickLink_name: any;
  public page_url: any;
  public active_item: String = '';
  public FFF_TYPECODES = SPARTACUS_CMS_TYPECODE;
  outlinedIconTypes = OUTLINED_ICON_TYPE;
  private subs = new Subscription();
  isNavigating: boolean = false;
  baseSite: string = '';

  constructor(
    skipLinkService: SkipLinkService,
    private componentData: CmsComponentData<any>,
    private router: Router,
    private baseSiteService: BaseSiteService,
    private fffSsoService: FffSsoService,
    private changeDetectorRef: ChangeDetectorRef
  ) {
    super(skipLinkService);
    this.getBaseUrl_data();
    this.getActiveScss_class();
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  getBaseUrl_data() {
    this.baseSiteService
      .getActive()
      .pipe(take(1))
      .subscribe(site => {
        this.baseSite = site;
      });
    /* Getting Links data from server
     */

    this.subs.add(
      this.data$.subscribe(event => {
        this.ccd_result = event;
        this.fff_quickLink_name = this.ccd_result.linkName;
      })
    );

    this.page_url =
      this.router?.['location']?._platformLocation?.location?.origin;
    if (
      this.page_url?.includes(BASE_URL_KEYS.BIOSUPPLY) &&
      this.ccd_result.uid === SPARTACUS_CMS_TYPECODE.CMS_CONTEXTLINKS_BIO
    )
      this.active_item = this.ccd_result.uid;
    else if (
      this.page_url?.includes(BASE_URL_KEYS.MY_FLU_VACCINE) &&
      this.ccd_result.uid === SPARTACUS_CMS_TYPECODE.CMS_CONTEXTLINKS_MFV
    )
      this.active_item = this.ccd_result.uid;
  }

  getActiveScss_class(): boolean {
    let active_class =
      this.ccd_result.uid === SPARTACUS_CMS_TYPECODE.CMS_SITELINKS_RESOURCE ||
      this.ccd_result.uid === SPARTACUS_CMS_TYPECODE.CMS_SITELINKS_SERVICE ||
      this.ccd_result.uid === SPARTACUS_CMS_TYPECODE.CMS_SITELINKS_SUPPORT
        ? true
        : false;
    return active_class;
  }
  handleNodeNavigationState() {
    this.isNavigating = true;
    this.changeDetectorRef.markForCheck();
  }

  openUrl(): void {
    if (
      this.ccd_result.uid ===
      SPARTACUS_CMS_TYPECODE.CMS_SITELINKS_SUPPORTPHONENUMBER
    ) {
      return;
    }

    if (this.ccd_result.uid === SPARTACUS_CMS_TYPECODE.CMS_CONTEXTLINKS_MFV) {
      if (this.baseSite !== BASE_URL_KEYS.MY_FLU_VACCINE) {
        this.handleNodeNavigationState();
      }

      this.fffSsoService.navigateToOtherSite(
        BASE_URL_KEYS.MY_FLU_VACCINE,
        '_self'
      );
      return;
    }

    if (this.ccd_result.uid === SPARTACUS_CMS_TYPECODE.CMS_CONTEXTLINKS_BIO) {
      if (this.baseSite !== BASE_URL_KEYS.BIOSUPPLY) {
        this.handleNodeNavigationState();
      }

      this.fffSsoService.navigateToOtherSite(BASE_URL_KEYS.BIOSUPPLY, '_self');
      return;
    }

    try {
      const { hostname } = new URL(this.ccd_result.url);
      window.open(this.ccd_result.url, '_blank');
    } catch {
      this.router.navigate([this.ccd_result.url]);
    }
  }
}
