import { Location } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({ name: 'fffSafeHtml' })
export class FffSafeHtmlPipe implements PipeTransform {
    constructor(private sanitized: DomSanitizer, private location: Location) { }
    transform(value: any, bypassSecurity?: boolean) {
        if(value === null || value === undefined) {
          return '';
        }
        value = value.replace(/href="#/g, 'href="' + this.location.path() + '#');
        if (!bypassSecurity) {
          return value;
        }
        return this.sanitized.bypassSecurityTrustHtml(value);
    }
}
