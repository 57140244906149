import { Component } from '@angular/core';
import { CUSTOM_ICON_TYPE } from 'src/app/models/fff-custom-icons.model';

@Component({
  selector: 'fff-internal-server',
  templateUrl: './fff-internal-server.component.html'
})
export class FffInternalServerComponent {

  customIconTypes = CUSTOM_ICON_TYPE;

  constructor() { }

}
