import {
  Component,
  EventEmitter,
  Injector,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { ProductSpecialPriceState } from '@app/reducers';
import { FFFCommonFunctions } from '@app/shared/fff-common-functions';
import { getProductImage } from '@assets/utils/fff-product-utils';
import { MAX_DIFF_DAYS } from '@config/content/constants';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TieredModalComponent } from './tiered-modal/tiered-modal.component';

@Component({
  selector: 'fff-product-item-info',
  templateUrl: './fff-product-item-info.component.html',
})
export class FffProductItemInfoComponent
  implements OnInit, OnDestroy, OnChanges
{
  @Input() product!: any;
  @Input() pricesData: any;
  @Input() itemAddedToCart!: EventEmitter<boolean>;
  getPrimaryImage = getProductImage;
  imageObject: any;
  private unsubscribe$ = new Subject<void>();
  shortDated: boolean = false;
  shouldDisplayTieredPriceLabel: boolean = false;
  prices$ = FFFCommonFunctions.prices$;
  isOfVaccineTypeI = FFFCommonFunctions.isOfVaccineType;
  getAltPriceI = FFFCommonFunctions.getAltPrice;

  constructor(
    private modalService: NgbModal,
    private injector: Injector,
    private storeProductSpecialPrice: Store<ProductSpecialPriceState>
  ) {}

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  ngOnInit() {
    this.storeProductSpecialPrice
      .select((state: any) => {
        return state.ProductSpecialPrice;
      })
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(specialPrice => {
        if (specialPrice?.data && this.product) {
          let option = specialPrice.data[this.product.code]?.data;
          if (option)
            this.shortDated =
              option.batch &&
              option.batch !== 'undefined' &&
              option.diffDays <= MAX_DIFF_DAYS;
        }
        this.shouldDisplayTieredPriceLabel = this.verifyToMatScaleResultsLength(
          this.pricesData
        );
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.product.currentValue) {
      this.imageObject = getProductImage(this.product?.images);
    }
  }

  private verifyToMatScaleResultsLength(pricesData: any): boolean {
    const foundResults = pricesData.prices.some((price: any) => {
      const results: any[] = price.price.toMatScale.results;
      return results.length !== 0;
    });
    return this.product.tieredPricing && foundResults;
  }

  displayTieredPricing() {
    this.modalService.open(TieredModalComponent, {
      centered: true,
      size: 'md',
      modalDialogClass: 'modal-container',
      injector: Injector.create({
        providers: [{ provide: 'tieredPricing', useValue: this.pricesData }],
        parent: this.injector,
      }),
    });
  }
}
