import { IconConfig, IconResourceType } from '@spartacus/storefront';
import { CUSTOM_ICON_SYMBOLS, CUSTOM_ICON_TYPE } from 'src/app/models/fff-custom-icons.model';
import { FILLED_ICON_SYMBOLS, FILLED_ICON_TYPE } from 'src/app/models/fff-filled-icons.model';
import { OUTLINED_ICON_SYMBOLS, OUTLINED_ICON_TYPE } from 'src/app/models/fff-outline-icons.model';

export const customIconConfig: IconConfig = {
  icon: {
    symbols: {
      ...
      CUSTOM_ICON_SYMBOLS,
      ...
      FILLED_ICON_SYMBOLS,
      ...
      OUTLINED_ICON_SYMBOLS
    },
    resources: [
      {
        type: IconResourceType.SVG,
        url: './assets/icons/fff-custom-symbol-defs.svg',
        types: Object.values(CUSTOM_ICON_TYPE)
      },
      {
        type: IconResourceType.SVG,
        url: './assets/icons/bootstrap-filled-symbol-defs.svg',
        types: Object.values(FILLED_ICON_TYPE)
      },
      {
        type: IconResourceType.SVG,
        url: './assets/icons/bootstrap-outline-symbol-defs.svg',
        types: Object.values(OUTLINED_ICON_TYPE)
      }
    ]
  }
};