import { HttpClient, HttpHeaders } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { FffCommunicationService } from '@app/fff-enterprise/fff-common-services/fff-communication.service';
import { FffTagItem } from '@app/shared/components/form/fff-form-controls/fff-tags/fff-tags.model';
import { OccEndpointsService, UserIdService } from '@spartacus/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import {
  NewReportFilterOption,
  ReportFormatTypes,
  ReportFrequencyTypes,
  ReportRangeTypes,
  ReportStatusTypes,
  ReportTypes,
} from './fff-new-reports.model';

@Injectable({
  providedIn: 'root',
})
export class FffNewReportsService {
  readonly reportTypes: NewReportFilterOption[] = [
    {
      id: ReportTypes.ALLOCATIONS,
      label: 'Allocations',
    },
    {
      id: ReportTypes.INVOICE_HISTORY,
      label: 'Invoice History',
    },
    {
      id: ReportTypes.ORDER_HISTORY,
      label: 'Order History',
    },
    {
      id: ReportTypes.PRICES,
      label: 'Prices',
    },
  ];

  readonly frequencyOptions: NewReportFilterOption[] = [
    {
      id: ReportFrequencyTypes.ONCE,
      label: 'Once',
    },
    {
      id: ReportFrequencyTypes.WEEKLY,
      label: 'Weekly',
    },
    {
      id: ReportFrequencyTypes.MONTHLY,
      label: 'Monthly',
    },
  ];

  readonly reportFormatOptions: NewReportFilterOption[] = [
    {
      id: ReportFormatTypes.EMAIL,
      label: 'Email',
    },
    {
      id: ReportFormatTypes.PDF,
      label: 'PDF Download',
    },
    {
      id: ReportFormatTypes.CSV,
      label: 'CSV Download',
    },
  ];

  readonly monthlyRangeOptions: NewReportFilterOption[] = [
    {
      id: ReportRangeTypes.PAST_30_DAYS,
      label: 'Past 30 Days',
    },
    {
      id: ReportRangeTypes.PREVIOUS_MONTH,
      label: 'Previous Month',
    },
    {
      id: ReportRangeTypes.MONTH_TO_DATE,
      label: 'Month to Date',
    },
  ];

  readonly weeklyRangeOptions: NewReportFilterOption[] = [
    {
      id: ReportRangeTypes.PAST_7_DAYS,
      label: 'Past 7 Days',
    },
    {
      id: ReportRangeTypes.PREVIOUS_WEEK,
      label: 'Previous Week',
    },
    {
      id: ReportRangeTypes.WEEK_TO_DATE,
      label: 'Week to Date',
    },
  ];

  readonly reportFieldsMapping: { [reportType: string]: string[] } = {
    [ReportTypes.ALLOCATIONS]: [
      'reportRange',
      'firstReportDate',
      'reportDate',
      'accounts',
      'products',
      'emailIds',
    ],
    [ReportTypes.ORDER_HISTORY]: [
      'reportRange',
      'firstReportDate',
      'reportDate',
      'fromDate',
      'toDate',
      'accounts',
      'status',
      'emailIds',
    ],
    [ReportTypes.INVOICE_HISTORY]: [
      'reportRange',
      'firstReportDate',
      'reportDate',
      'fromDate',
      'toDate',
      'accounts',
      'status',
      'emailIds',
    ],
    [ReportTypes.PRICES]: [
      'firstReportDate',
      'reportDate',
      'accounts',
      'reportFormat',
      'productGroups',
      'emailIds',
    ],
  };

  readonly statusesMapping: any = {
    [ReportTypes.ORDER_HISTORY]: [
      { id: ReportStatusTypes.ALL, label: 'All' },
      { id: ReportStatusTypes.OPEN, label: 'Open' },
      { id: ReportStatusTypes.PARTIALLY_SHIPPED, label: 'Partially Shipped' },
      { id: ReportStatusTypes.FULLY_SHIPPED, label: 'Fully Shipped' },
    ],
    [ReportTypes.INVOICE_HISTORY]: [
      { id: ReportStatusTypes.ALL, label: 'All' },
      { id: ReportStatusTypes.OPEN, label: 'Open' },
      { id: ReportStatusTypes.PAID, label: 'Paid' },
    ],
  };

  refresh = new EventEmitter();
  editReport = new EventEmitter();

  private selectedAccountsSubject: BehaviorSubject<FffTagItem[]> =
    new BehaviorSubject<FffTagItem[]>([]);
  selectedAccounts$: Observable<FffTagItem[]> =
    this.selectedAccountsSubject.asObservable();
  private selectedProductsSubject: BehaviorSubject<FffTagItem[]> =
    new BehaviorSubject<FffTagItem[]>([]);
  selectedProducts$: Observable<FffTagItem[]> =
    this.selectedProductsSubject.asObservable();
  private selectedProductGroupsSubject: BehaviorSubject<FffTagItem[]> =
    new BehaviorSubject<FffTagItem[]>([]);
  selectedProductGroups$: Observable<FffTagItem[]> =
    this.selectedProductGroupsSubject.asObservable();

  constructor(
    protected occEndpoints: OccEndpointsService,
    private http: HttpClient,
    protected userIdService: UserIdService,
    private fffCommunicationService: FffCommunicationService
  ) {}

  sendEditReportEvent(report: any) {
    this.editReport.emit(report);
  }

  getAvailableStatusesByType(type: string): any[] {
    return this.statusesMapping[type] || [];
  }

  refreshResults() {
    this.refresh.emit();
  }
  setSelectedAccounts(selectedAccountTags: FffTagItem[]) {
    this.selectedAccountsSubject.next(selectedAccountTags);
  }

  getSelectedAccounts(): Observable<FffTagItem[]> {
    return this.selectedAccounts$;
  }
  setSelectedProducts(selectedProductTags: FffTagItem[]) {
    this.selectedProductsSubject.next(selectedProductTags);
  }

  getSelectedProducts(): Observable<FffTagItem[]> {
    return this.selectedProducts$;
  }
  setSelectedProductGroups(selectedProductGroupTags: FffTagItem[]) {
    this.selectedProductGroupsSubject.next(selectedProductGroupTags);
  }

  getSelectedProductGroups(): Observable<FffTagItem[]> {
    return this.selectedProductGroups$;
  }
  getRequestHeaders(): HttpHeaders {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    return headers;
  }

  getProductsForReport(): Observable<any> {
    return this.fffCommunicationService.getPersonalInfo().pipe(
      switchMap(user => {
        const url = this.occEndpoints.buildUrl(
          `/products?categorycode=PROVIDEGX&fields=DEFAULT&userId=${user.uid}&matexclusion=true`
        );
        return this.http.get(url, {
          headers: this.getRequestHeaders(),
        });
      })
    );
  }

  getProductGroupsForReport(): Observable<any> {
    return this.fffCommunicationService.getPersonalInfo().pipe(
      switchMap(user => {
        const url = this.occEndpoints.buildUrl(
          `/categories?fields=DEFAULT&userId=${user.uid}&matexclusion=true`
        );
        return this.http.get(url, {
          headers: this.getRequestHeaders(),
        });
      })
    );
  }

  getReports(): Observable<any> {
    const user$ = this.userIdService.getUserId();
    return user$.pipe(
      switchMap(userId => {
        const url = `/users/${userId}/reports`;
        return this.http.get(this.occEndpoints.buildUrl(url), {
          headers: this.getRequestHeaders(),
        });
      })
    );
  }
  getReportCounts(): Observable<any> {
    const user$ = this.userIdService.getUserId();
    return user$.pipe(
      switchMap(userId => {
        const url = `/users/${userId}/reports/recurringreportscount?fields=DEFAULT`;
        return this.http.get(this.occEndpoints.buildUrl(url), {
          headers: this.getRequestHeaders(),
        });
      })
    );
  }
  cancelRecurringReport(reportId: string): Observable<any> {
    const user$ = this.userIdService.getUserId();
    return user$.pipe(
      switchMap(userId => {
        const url = `/users/${userId}/reports/${reportId}`;
        return this.http.patch(
          this.occEndpoints.buildUrl(url),
          { reportStatus: 'CANCELLED' },
          {
            headers: this.getRequestHeaders(),
            responseType: 'text',
          }
        );
      })
    );
  }

  updateReport(reportId: string, formData: any) {
    const url = this.occEndpoints.buildUrl(
      `/users/current/reports/${reportId}`
    );
    return this.http.put(url, formData);
  }

  getApplicationProperties(): Observable<any> {
    const url = this.occEndpoints.buildUrl('applicationProperties');
    return this.http.get(url, { headers: this.getRequestHeaders() });
  }
  getAccountsByPage(currentPage: any, searchText: string): Observable<any> {
    return this.fffCommunicationService.getPersonalInfo().pipe(
      switchMap(user => {
        let updatedSearchText = searchText;
        if (searchText && searchText.includes('#')) {
          updatedSearchText = searchText.replace('#', '%23');
        }
        const url = `/users/${user.uid}/account/search-accounts?currentPage=${currentPage}&fields=DEFAULT&query=${updatedSearchText}`;
        return this.http.get(this.occEndpoints.buildUrl(url), {
          headers: this.getRequestHeaders(),
        });
      })
    );
  }
}
