import { Injectable } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FffUpdateInteractiveAllocationsService {

  amountReallocated$ = new BehaviorSubject<number | undefined>(undefined);
  updateAllocationsGroup$ = new BehaviorSubject<any | undefined>(undefined);
  updateAllocationsGroupForm$ = new BehaviorSubject<UntypedFormGroup | undefined>(undefined);

  constructor() { }
}
