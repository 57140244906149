import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { CmsConfig, ConfigModule, I18nConfig, I18nModule, provideConfig, UrlModule } from '@spartacus/core';
import { FormErrorsModule, IconModule, SpinnerModule } from '@spartacus/storefront';
import { fffTranslationOverwrites } from 'src/assets/translations/en/fff-register';
import { FffForgotPasswordConfirmationComponent } from './fff-forgot-password-confirmation/fff-forgot-password-confirmation.component';
import { FffForgotPasswordComponent } from './fff-forgot-password/fff-forgot-password.component';
import { FffPasswordResetComponent } from './fff-password-reset/fff-password-reset.component';
import {UserPasswordService, UserProfileAdapter, UserProfileConnector} from "@spartacus/user/profile/core";
import {ResetPasswordModule} from "@spartacus/user/profile/components";
import {FffPasswordResetService} from "@shared/services/fff-reset-password-service";
import {UserProfileModule} from "@spartacus/user";



@NgModule({
  declarations: [
    FffPasswordResetComponent,
    FffForgotPasswordComponent,
    FffForgotPasswordConfirmationComponent
  ],
  providers: [
    UserPasswordService,
    provideConfig(<I18nConfig>{
      i18n: {
        resources: fffTranslationOverwrites,
      },
    }),
  ],
  imports: [
    CommonModule,
    UserProfileModule,
    ConfigModule.withConfig({
      cmsComponents: {
        ResetPasswordComponent: {
          component: FffPasswordResetComponent,
        },
        ForgotPasswordComponent: {
          component: FffForgotPasswordComponent
        }
      },
    } as CmsConfig),
    IconModule,
    SpinnerModule,
    FormsModule,
    ReactiveFormsModule,
    SpinnerModule,
    I18nModule,
    FormErrorsModule,
    UrlModule,
    RouterModule,
  ]
})
export class FffPasswordModule { }
