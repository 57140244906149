import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { I18nModule } from '@spartacus/core';
import { IconModule } from '@spartacus/storefront';
import { FffPrebookAlertNotificationModalComponent } from './fff-prebook-alert-notification-modal.component';

@NgModule({
  declarations: [FffPrebookAlertNotificationModalComponent],
  imports: [CommonModule, IconModule, I18nModule],
  exports: [FffPrebookAlertNotificationModalComponent],
})
export class FffPrebookAlertNotificationModule {}
