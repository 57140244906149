import { ChangeDetectorRef, Component } from '@angular/core';
import { FffPrebookService } from '@app/fff-enterprise/fff-common-services/fff-prebook.service';
import { OUTLINED_ICON_TYPE } from '@app/models/fff-outline-icons.model';
import { fffPrebook } from '@assets/translations/en/fff-prebook';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { RoutingService } from '@spartacus/core';
import { Subject, Subscription } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
  take,
  tap,
} from 'rxjs/operators';
import { FffPrebookCartService } from '../services/fff-prebook-cart.service';

@Component({
  selector: 'fff-prebook-exit-confirmation-modal',
  templateUrl: './fff-prebook-exit-confirmation-modal.component.html',
  styleUrls: ['./fff-prebook-exit-confirmation-modal.component.scss'],
})
export class FffPrebookExitConfirmationModalComponent {
  iconWarning = OUTLINED_ICON_TYPE.ICON_INFO_CIRCLE;
  iconClose = OUTLINED_ICON_TYPE.ICON_X;
  season: string = '';
  loading: boolean = false;
  private pageId: string = '';
  subs = new Subscription();
  unsubscribe$: Subject<void> = new Subject<void>();
  messageSplit1!: string;
  messageSplit2!: string;
  messageSplit3!: string;
  proceedBtnText!: string;
  cancelBtnText!: string;

  constructor(
    protected modalService: NgbActiveModal,
    protected routingService: RoutingService,
    public preBookService: FffPrebookService,
    private cd: ChangeDetectorRef,
    private fffPrebookCartService: FffPrebookCartService
  ) {}

  ngOnInit(): void {
    this.preBookService.getSeasonData().subscribe(data => {
      this.season = data;
    });
    this.messageSplit1 = fffPrebook.exitConfirmationModal.messageSplit1;
    this.messageSplit2 = fffPrebook.exitConfirmationModal.messageSplit2;
    this.messageSplit3 = fffPrebook.exitConfirmationModal.messageSplit3;
    this.proceedBtnText = fffPrebook.exitConfirmationModal.leave;
    this.cancelBtnText = fffPrebook.exitConfirmationModal.stay;
    this.subs.add(
      this.routingService
        .getRouterState()
        .pipe(
          debounceTime(300),
          filter(value => !!value.state?.context?.id),
          map(value => value.state.context.id),
          distinctUntilChanged(),
          tap(pageId => {
            this.pageId = pageId;
          })
        )
        .subscribe()
    );
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();

    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  proceed() {
    this.addToCart();
  }

  close() {
    this.modalService.close(false);
  }

  addToCart() {
    this.loading = true;
    this.cd.markForCheck();
    this.fffPrebookCartService
      .updatePrebookCartAndRedirectToHome()
      .pipe(take(1))
      .subscribe(() => {
        this.loading = false;
        this.modalService.close(false);
        this.routingService.go('/');
        this.cd.markForCheck();
      });
  }
}
