import { Component, HostListener, OnInit } from '@angular/core';
import { HamburgerMenuComponent, HamburgerMenuService } from '@spartacus/storefront';
import { FffDrawerService } from 'src/app/shared/drawer/fff-drawer.service';
import { FffHamburgerHeaderComponent } from './fff-hamburger-header.component';
import { FffNavigationAccordionComponent } from './fff-navigation-accordion.component';

@Component({
  selector: 'fff-hamburger-menu',
  templateUrl: './fff-hamburger-menu.component.html',
})
export class FffHamburgerMenuComponent extends HamburgerMenuComponent implements OnInit {
  text = '';
  displayMenuIcon: boolean | undefined;
  elementNavigation: any;

  constructor(
    private drawerService: FffDrawerService,
    private hamburgerService: HamburgerMenuService,
    hamburgerMenuService: HamburgerMenuService
  ) {
    super(hamburgerMenuService);
  }

  @HostListener('document:scroll', ['$event.target'])
  onScrollEvent(event: any): void {
    this.displayMenuIcon = !!(event?.scrollingElement?.scrollTop > 0);
  }

  customToggle() {
    this.elementNavigation = document.querySelectorAll('.NavigationBar')[0];
    const hasClass = this.elementNavigation.classList.contains('showMenu');

    if (hasClass) {
      this.elementNavigation.classList.remove('showMenu');
    } else {
      this.elementNavigation.classList.add('showMenu');
    }
    this.hamburgerService.toggle();
  }

  toggle() {
    this.drawerService.setContent({
      drawerHeader: FffHamburgerHeaderComponent as Component,
      component: FffNavigationAccordionComponent as Component,
      class: 'navigation-mobile',
      animation: 'slideLTR',
    });
    this.drawerService.openDrawer();
  }

  ngOnInit() {
  }
}
