import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class FffAllocationService {

  constructor() { }

  getAllocationCompareValue(allocation: any){

    if(allocation.action == '2'){
      return (allocation.yearlyAllocatedQty > 0 ? Math.min(allocation.yearlyRemainingQty,allocation.remainingQty) : allocation.remainingQty)
    } 

    if(allocation.actionCSY == '2' && allocation.action != '2'){
      return allocation.yearlyRemainingQty
    } 
    
    return null;
  }


  transformUnits(numberToTransform: number , allocation: any, uom: any, priceData: any){
    if(numberToTransform != null){
      return Math.floor(allocation.allocatedUOM != uom ? +numberToTransform / priceData.price.minOrderQuantity : numberToTransform)
    }
    return null;
  }

}
