<div *ngIf="allocResults && hasMaterial" class="item-status lt-spacing">
  <ng-container *ngIf="!isRapidCommit">
    <p *ngIf="hardAlloc" class="mb-0 body-text-demi-xl">
      {{ 'fffProduct.hardAlloc' | cxTranslate }}:
    </p>
    <p *ngIf="!hardAlloc" class="mb-0 body-text-demi-xl">
      {{ 'fffProduct.softAlloc' | cxTranslate }}:
    </p>
  </ng-container>

  <ng-container>
    <p class="mb-0 body-text-demi-xl">
      {{
        (isInRapidCommitCategory ? 'fffProduct.orderLimit' : 'fffProduct.alloc')
          | cxTranslate
      }}: {{ allocated | number : '1.0-0' }}
      {{
        allocResults._displayUnit
          ? allocResults.allocatedUOM
          : isProvideGX
          ? product?.altUnit
          : ''
      }}
    </p>
    <p class="mb-0 body-text-demi-xl" *ngIf="!isInRapidCommitCategory">
      {{ 'fffProduct.remaining' | cxTranslate }}:
      {{ remaining | number : '1.0-0' }}
      {{
        allocResults._displayUnit
          ? allocResults.allocatedUOM
          : isProvideGX
          ? product?.altUnit
          : ''
      }}
    </p>
  </ng-container>
</div>

<a
  *ngIf="allocResults && hasMaterial && !isRapidCommit"
  class="need-more body-text-demi-xl"
  (click)="needMoreDialogBox()"
  >{{ 'fffProduct.lblNeedMore' | cxTranslate }}
</a>
