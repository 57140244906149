import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { OccEndpointsService } from '@spartacus/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, take, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class FffProductService {
  private _pricesData$ = new BehaviorSubject<any>({});

  constructor(
    private http: HttpClient,
    protected occEndpoints: OccEndpointsService
  ) {}

  getRequestHeaders(): HttpHeaders {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    return headers;
  }

  getPricesData(productCode: string) {
    return this._pricesData$
      .asObservable()
      .pipe(map(data => data?.[productCode] || { prices: [] }));
  }

  setPricesData(productCode: string, data: any) {
    let pricesData = {};
    this._pricesData$
      .pipe(
        take(1),
        tap(oldData => {
          if (!oldData) {
            oldData = {};
          }
          pricesData = {
            ...oldData,
            [productCode]: { ...data },
          };
          this._pricesData$.next(pricesData);
        })
      )
      .subscribe();
  }

  getProductExclusionData(product: string): Observable<any> {
    const url = this.occEndpoints.buildUrl(
      `/products/${product}?fields=DEFAULT,averageRating,images(FULL,galleryIndex),classifications,manufacturer,numberOfReviews,categories(FULL),baseOptions,baseProduct,variantOptions,variantType,code,name,summary,price(formattedValue,DEFAULT),stock(DEFAULT),description,availableForPickup,url,priceRange,multidimensional,tags,showAsSoldOutIfExcluded`
    );
    return this.http.get(url, { headers: this.getRequestHeaders() });
  }
}
