<div class="modal-header">
  <cx-icon (click)="close()" [type]="closeIcon"></cx-icon>
</div>
<div class="modal-body">
  <div class="icon">
      <cx-icon [type]="success ? successIcon : warningIcon"></cx-icon>
  </div>
  <div class="title">
      {{ title | cxTranslate }}
  </div>
  <div *ngFor="let message of messages">
    <p class="message">
      {{ message | cxTranslate }}
    </p>
  </div>
</div>
<div class="modal-footer">

  <button class="btn btn-primary col-12" (click)="close()">
      {{ button | cxTranslate }}
  </button>
</div>
