<div class="d-lg-block d-none refinement-filter">
  <div class="facet-container" *ngIf="total$ | async as totalCount">
    <div class="filter-container" *ngIf="totalCount > 0">
      <div
        class="heading d-flex align-content-start flex-wrap"
        [class.heading-active-facets]="filtersSelected.length! > 0"
      >
        <span
          >{{ 'fffFacetFilter.filter' | cxTranslate }}
          <span *ngIf="filtersSelected?.length! > 0">
            ({{ filtersSelected.length! }})
          </span></span
        >
        <a
          *ngIf="filtersSelected?.length! > 0"
          class="cursor-pointer text-underline ml-auto clear-filter"
          (click)="clearFacets()"
          >{{ 'fffFacetFilter.clearAll' | cxTranslate }}</a
        >
      </div>

      <div
        class="d-flex flex-wrap active-facets"
        *ngIf="filtersSelected?.length! > 0"
      >
        <ng-container *ngIf="showActions$ | async">
          <button
            *ngFor="let selectedFilter of filtersSelected"
            class="btn btn-active-facet btn-xs text-nowrap"
            (click)="toggleValue(selectedFilter?.removeQuery?.query?.value)"
          >
            {{ selectedFilter?.facetValueName }}
            <cx-icon class="cx-icon icon-sm" [type]="outlinedIconTypes.ICON_X">
            </cx-icon>
          </button>
        </ng-container>
        <ng-container *ngIf="!(showActions$ | async)">
          <button
            *ngFor="let selectedFilter of filtersSelected"
            class="btn btn-active-facet btn-xs text-nowrap"
          >
            {{ selectedFilter?.facetValueName }}
          </button>
        </ng-container>
      </div>
    </div>

    <ng-container *ngIf="visibleFacets$ | async as visibleFacets">
      <div class="facet-list" *ngIf="visibleFacets">
        <div class="facet-info" *ngFor="let facet of facets">
          <div
            class="col-12 facet-name d-flex flex-wrap"
            (click)="facet.expanded = !facet.expanded"
            [attr.aria-expanded]="!facet.expanded"
            aria-controls="collapseFilter"
            [class.facet-name-open]="!facet.expanded"
          >
            <span class="facet-name-btn d-flex">
              {{ facet?.name }}
            </span>

            <cx-icon
              *ngIf="facet.expanded"
              class="cx-icon d-flex ml-auto cursor-pointer"
              [type]="outlinedIconTypes.ICON_CHEVRON_DOWN"
            >
            </cx-icon>
            <cx-icon
              *ngIf="!facet.expanded"
              class="cx-icon d-flex ml-auto cursor-pointer"
              [type]="outlinedIconTypes.ICON_CHEVRON_UP"
            >
            </cx-icon>
          </div>

          <div
            #collapse="ngbCollapse"
            [ngbCollapse]="isExpanded(facet.expanded)"
            class="facet-value-list"
          >
            <ng-container
              *ngFor="
                let facetInfo of getFacetValues(
                  facet?.values!,
                  facet.showMore!,
                  facet.moreFiveItems!
                )
              "
            >
              <div class="col-12 facet-value checkbox-container">
                <input
                  class="checkbox checkbox-md"
                  type="checkbox"
                  [checked]="facetInfo?.selected"
                  (change)="toggleValue(facetInfo?.query?.query?.value)"
                  id="{{ facetInfo?.name }}"
                  name="{{ facetInfo?.name }}"
                />

                <label class="checkbox-label" for="{{ facetInfo?.name }}">
                  {{ facetInfo?.name }}
                </label>
              </div>
            </ng-container>

            <div
              class="col-12 facet-value cursor-pointer"
              *ngIf="facet.moreFiveItems"
              (click)="facet.showMore = !facet.showMore"
            >
              <span class="showMore d-flex" *ngIf="!facet.showMore"
                >{{ 'fffFacetFilter.viewMore' | cxTranslate }}
                <cx-icon
                  class="cx-icon icon-sm pl-1"
                  [type]="outlinedIconTypes.ICON_CHEVRON_DOWN"
                >
                </cx-icon>
              </span>
              <span class="showMore d-flex" *ngIf="facet.showMore"
                >{{ 'fffFacetFilter.viewLess' | cxTranslate }}
                <cx-icon
                  class="cx-icon icon-sm pl-1"
                  [type]="outlinedIconTypes.ICON_CHEVRON_UP"
                ></cx-icon>
              </span>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
</div>

<div class="d-lg-none refinement-filter">
  <div class="filter-container">
    <div class="col-12 d-flex flex-wrap active-facets">
      <div class="col-6 pl-0">
        <button
          class="col-12 p-0 btn btn-facet-action btn-sm"
          (click)="openDrawer()"
        >
          <span
            >{{ 'fffFacetFilter.filter' | cxTranslate }}
            <span *ngIf="filtersSelected?.length! > 0">
              ({{ filtersSelected.length! }})
            </span></span
          >
        </button>
      </div>

      <div class="col-6 pr-0">
        <fff-product-sort></fff-product-sort>
      </div>
    </div>

    <div class="col-12 active-facets">
      <div class="position-relative">
        <div
          class="col-12 p-0 d-flex position-absolute active-facets-sm"
          *ngIf="filtersSelected?.length! > 0"
        >
          <button
            *ngFor="let selectedFilter of filtersSelected"
            class="btn btn-active-facet btn-xs text-nowrap"
            (click)="toggleValue(selectedFilter?.removeQuery?.query?.value)"
          >
            {{ selectedFilter?.facetValueName }}
            <cx-icon class="cx-icon icon-sm" [type]="outlinedIconTypes.ICON_X">
            </cx-icon>
          </button>
        </div>
      </div>
    </div>

    <div class="heading d-flex align-content-start flex-wrap">
      <a
        *ngIf="filtersSelected?.length! > 0"
        class="cursor-pointer text-underline clear-filter"
        (click)="clearFacets()"
        >{{ 'fffFacetFilter.clearAll' | cxTranslate }}</a
      >
    </div>
  </div>
</div>
