import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FffUtilModule } from '@app/fff-enterprise/fff-util/fff-util.module';
import { FffTableModule } from '@app/shared/table/fff-table.module';
import { I18nModule } from '@spartacus/core';
import {
  GenericLinkModule,
  IconModule,
} from '@spartacus/storefront';
import { FffDrawerModule } from 'src/app/shared/drawer/fff-drawer.module';
import { FffChangeAccountDrawerComponent } from './fff-change-account-drawer.component';
import { FffChangeAccountSelectorComponent } from './fff-change-account-selector.component';
import { FffChangeAccountWarningModalComponent } from './fff-change-account-warning-modal/fff-change-account-warning-modal.component';
import { FFFLoadersModule } from '@app/shared/components/fff-loaders/fff-loaders.module';

@NgModule({
  declarations: [
    FffChangeAccountDrawerComponent,
    FffChangeAccountSelectorComponent,
    FffChangeAccountWarningModalComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    IconModule,
    GenericLinkModule,
    FffUtilModule,
    I18nModule,
    FffDrawerModule,
    FFFLoadersModule,
    FffTableModule,
    ReactiveFormsModule,
  ],
  exports: [FffChangeAccountSelectorComponent],
})
export class FffChangeAccountDrawerModule {}
