<ng-container *ngIf="activeSite$ | async as activeSite">
  <ng-container *ngIf="cart$ | async as cart">
    <ng-container *ngIf="entries$ | async as entries">
      <h2 class="cx-total d-none">
        {{
          'orderCost.yourShoppingCart' | cxTranslate : { items: entries.length }
        }}
      </h2>

      <div
        *ngIf="
          cart && cart.totalItems && cart.totalItems > 0;
          else loading
        "
        class="cart-details-wrapper"
      >
        <ng-container *cxFeatureLevel="'4.2'">
          <cx-cart-validation-warnings></cx-cart-validation-warnings>
        </ng-container>

        <fff-cart-item-list
          [isPrebook]="false"
          [activeSite]="activeSite"
          [items]="entries"
          [cartIsLoading]="!(cartLoaded$ | async)"
          [promotionLocation]="promotionLocation"
          [prices]="prices"
          [stock]="stock"
        ></fff-cart-item-list>
      </div>
    </ng-container>
  </ng-container>
</ng-container>

<ng-template #loading>
  <fff-cart-loader [isCartItem]="true"></fff-cart-loader>
</ng-template>
