<div class="d-flex top-bar-header justify-content-between">
  <div>
    {{ 'fffAccount.contactPhone' | cxTranslate }}
  </div>
  <ng-container *ngIf="userLoggedIn$ | async; else anonymousUser">
    <div
      class="account-btn d-flex"
      (click)="openDrawer()"
      *ngIf="notifications$ | async as notifications"
    >
      {{ 'fffAccount.myAccount' | cxTranslate }}
      <span
        class="account-badge body-text-demi-xs d-flex justify-content-center align-items-center"
        *ngIf="fetchMedals(notifications) as fetchedMedal"
        >{{ fetchedMedal }}</span
      >
      <cx-icon
        class="cx-icon"
        [type]="outlinedIconTypes.ICON_CHEVRON_DOWN"
      ></cx-icon>
    </div>
  </ng-container>
  <ng-template #anonymousUser>
    <div routerLink="/login" class="account-btn">
      {{ 'fffLogin.login' | cxTranslate }}
    </div>
  </ng-template>
</div>
<!-- <fff-drawer #Drawer></fff-drawer> -->
