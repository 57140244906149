<div class="card CSOSCard">
  <div class="card-header">
    <h4>{{ 'fffAccount.fffProfileManagement.csos.csos' | cxTranslate }}</h4>
  </div>
  <div class="card-body CSOSCardBody body-text">
    <div class="CSOSLicense" *ngIf="constant.WITHOUT_LICENSE === csosStatus">
      <p>
        {{
          'fffAccount.fffProfileManagement.csos.messages.missingDEALicense'
            | cxTranslate
        }}
      </p>
    </div>

    <div
      class="CSOSLicense"
      *ngIf="constant.FIRST_USER_IN_ACCOUNT === csosStatus"
    >
      <p>
        {{
          'fffAccount.fffProfileManagement.csos.messages.accountNeedVerification'
            | cxTranslate
        }}
      </p>
    </div>

    <div
      class="CSOSLicense"
      *ngIf="constant.USER_NEED_VERIFICATION === csosStatus"
    >
      <p>
        {{
          'fffAccount.fffProfileManagement.csos.messages.userIdNeedVerification'
            | cxTranslate
        }}
      </p>
    </div>

    <div
      class="CSOSLicense"
      *ngIf="
        constant.ACCOUNT_HAS_TRADE_PARTNER === csosStatus && !isExpiredLicense
      "
    >
      <p class="mb-0">
        {{
          'fffAccount.fffProfileManagement.csos.messages.yourLicenseLabel'
            | cxTranslate
        }}
      </p>
      <p class="mb-0" *ngIf="getLicenseType() | async as licenseType">
        {{ licenseType }} #{{ getLicense() }}
      </p>
    </div>

    <div
      class="CSOSLicense"
      *ngIf="
        constant.ACCOUNT_HAS_TRADE_PARTNER === csosStatus && isExpiredLicense
      "
    >
      <p class="expired-license d-flex flex-wrap mb-3">
        <cx-icon
          class="cx-icon icon-xs warning-icon pr-1"
          [type]="filledIconTypes.ICON_CIRCLE_FILL"
        >
        </cx-icon
        ><span>{{
          'fffAccount.fffProfileManagement.csos.messages.expiredMessage'
            | cxTranslate
        }}</span>
      </p>
      <p class="mb-0">
        {{
          'fffAccount.fffProfileManagement.csos.messages.yourLicenseLabelExp'
            | cxTranslate
        }}
      </p>
      <p class="mb-0">
        <span class="body-text-demi"
          >{{ selected.primaryLicenseType }} #{{ selected.deaLicense }}</span
        >
        {{
          'fffAccount.fffProfileManagement.csos.messages.expiredOn'
            | cxTranslate
        }}
      </p>
      <p class="mb-0">
        {{ selected.deaExpiryDate | date : 'MM/dd/yyyy' }}.
        <span>{{
          'fffAccount.fffProfileManagement.csos.messages.uploadNewCertificate'
            | cxTranslate
        }}</span>
      </p>
    </div>

    <button
      class="btn btn-primary d-flex"
      (click)="verifyAccount()"
      *ngIf="constant.FIRST_USER_IN_ACCOUNT === csosStatus"
      [disabled]="
        constant.FIRST_USER_IN_ACCOUNT !== csosStatus || hasAgentLogged
      "
    >
      {{
        'fffAccount.fffProfileManagement.csos.actions.verifyAccount'
          | cxTranslate
      }}
    </button>

    <button
      class="btn btn-primary d-flex my-2"
      (click)="verifyUserId()"
      *ngIf="constant.ACCOUNT_HAS_TRADE_PARTNER !== csosStatus"
      [disabled]="
        constant.USER_NEED_VERIFICATION !== csosStatus || hasAgentLogged
      "
    >
      {{
        'fffAccount.fffProfileManagement.csos.actions.verifyUserId'
          | cxTranslate
      }}
    </button>

    <button
      class="btn btn-primary d-flex"
      (click)="manageCertificate()"
      [disabled]="
        constant.ACCOUNT_HAS_TRADE_PARTNER !== csosStatus || hasAgentLogged
      "
    >
      {{
        'fffAccount.fffProfileManagement.csos.actions.manageCertificate'
          | cxTranslate
      }}
    </button>
  </div>
</div>

<div *ngIf="legisym$ | async as legisym">
  <form
    #form
    id="goExpress222Form"
    class="m-auto"
    name="goExpress222"
    [action]="legisym.url"
    [method]="legisym.method"
    target="_blank"
  >
    <div>
      <input
        class="col-12"
        type="hidden"
        name="ServiceContract"
        [value]="legisym.serviceContract"
      />
      <input
        class="col-12"
        type="hidden"
        name="loginToken"
        [value]="legisym.loginToken"
      />
      <input
        class="col-12"
        type="hidden"
        name="Action"
        [value]="legisym.action"
      />
      <input
        class="col-12"
        type="hidden"
        name="returnURL"
        [value]="legisym.returnURL"
      />
    </div>
  </form>
</div>
