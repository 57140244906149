import { PaginationConfig } from '@spartacus/storefront';

export const customPaginationConfig: PaginationConfig = {
  pagination: {
    addNext: true,
    addPrevious: true,
    nextLabel: '',
    previousLabel: ''
  }
};

export const defaultPageSize: number = 10;
