<ng-container *ngIf="cart$ | async as cart">
  <ng-container *ngIf="entries$ | async as entries">
    <div class="summary-container">
      <fff-cart-summary
        [cart]="cart"
        [activeSite]="activeSite"
        [entries]="entries"
      ></fff-cart-summary>

      <div
        class="summary-actions d-flex flex-wrap justify-content-center align-items-center m-auto"
      >
        <div class="col-12">
          <button
            type="button"
            *ngIf="entries.length"
            class="btn btn-primary btn-block"
            (click)="handleCheckoutRedirection()"
          >
            {{ 'fffCartSummary.checkout' | cxTranslate }}
          </button>
        </div>
        <ng-container *ngIf="activeSite === BASE_URL_KEYS.BIOSUPPLY">
          <div class="col-12 text-center summary-text">
            <span>{{ 'fffCartSummary.text' | cxTranslate }}</span>
          </div>

          <div class="col-12">
            <button
              class="btn btn-secondary col-12"
              (click)="handleOpenSaveCartDrawer()"
            >
              {{ 'fffCartSummary.saveCart' | cxTranslate }}
            </button>
          </div>
        </ng-container>

        <div class="col-12 text-center summary-customer-support">
          <span>{{ 'fffCartSummary.supportText' | cxTranslate }}</span>
        </div>
      </div>
    </div>
  </ng-container>
</ng-container>
