import { AbstractControl, ValidationErrors } from "@angular/forms";

export class FFFCustomFormValidators {
    static nameValidator(control: AbstractControl): ValidationErrors | null {
        const name = control.value as string;
        // Allows Multiple Words, Apostrophe and Hyphen
        return name && (!name.length || name.match('^[A-Za-z][A-Za-z\'\-]+([\ A-Za-z][A-Za-z\'\-]+)*$'))
          ? null
          : { cxInvalidPersonName: true };
    }

    static phoneValidator(control: AbstractControl): ValidationErrors | null {
      const PHONE_PATTERN = '^[\+]?[(]?[0-9]{3}[)]?[\-\ \.]?[0-9]{3}[\-\ \.]?[0-9]{4,6}$';
      const phoneNumber = control.value as string;
      return phoneNumber &&
        (!phoneNumber.length || phoneNumber.match(PHONE_PATTERN))
        ? null
        : { cxInvalidPhoneNumber: true };
    }
    
    static passwordValidator(control: AbstractControl): ValidationErrors | null {
      const PASSWORD_PATTERN =
        '^(?=.*?[a-z])(?=.*?[A-Z])(?=.*?[0-9])(?=.*[!@#$%^&*]).{8,}$';
      const password = control.value as string;
      return password && (!password.length || password.match(PASSWORD_PATTERN))
        ? null
        : { cxInvalidPass: true };
    }
}
