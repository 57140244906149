import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FFFOrderEntry } from '@app/models/fff-cart-data.model';
import { FffGtmEventService } from '@app/spartacus/features/tracking/gmt/event';
// import { CartModification, CART_MODIFICATION_NORMALIZER, ConverterService, OccCartEntryAdapter, OccEndpointsService } from '@spartacus/core';
import { OccCartEntryAdapter } from '@spartacus/cart/base/occ';
import { CART_MODIFICATION_NORMALIZER, CartModification } from '@spartacus/cart/base/root';
import { ConverterService, OccEndpointsService } from '@spartacus/core';
import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';
import { FFFCartEntryAdapter } from './fff-cart-entry.adapter';

@Injectable()
export class FFFOccCartEntryAdapter extends OccCartEntryAdapter implements FFFCartEntryAdapter {

  constructor(
    protected http: HttpClient,
    protected occEndpointsService: OccEndpointsService,
    protected converterService: ConverterService,
    protected fffGtmEventService: FffGtmEventService
  ) {
    super(http, occEndpointsService, converterService);
  }

  public addFFF(
    userId: string,
    cartId: string,
    orderEntries?: FFFOrderEntry[]
  ): Observable<CartModification> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    if (!orderEntries) {
      return of({});
    }

    let url = this.occEndpointsService.buildUrl('addEntries', {
      urlParams: { userId, cartId },
    });

    const toAdd = { orderEntries: orderEntries };
    return this.http
      .post<CartModification>(url, toAdd, { headers })
      .pipe(
        tap((modifications: any) => {
          this.fffGtmEventService.addToCartAddEntrySuccessEvent(modifications, cartId);
        }),
        this.converterService.pipeable(CART_MODIFICATION_NORMALIZER)
      );
  }
}
